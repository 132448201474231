import React, { Component } from 'react';
import { connect } from 'react-redux';

//Api Actions
import {
  createVIPanelUser,
  upsertVIPanelUser,
  deleteVIPanelUser,
  getVIPanelUsersList,
  getAdminUsersList,
} from '../../actions/AdminSection';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericDataTable from '../../components/common/GenericDataTable';
import GenericTabs from '../../components/common/GenericTabs';
import Avatar from '../../components/common/Avatar';
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../components/private/SuccErrNotification';

//Functional common components

//Common packages
import {
  Button,
  Pagination,
  Modal,
  TextInput,
  Select,
  SelectItem,
  InlineNotification,
} from 'carbon-components-react';
import moment from 'moment';

class VIPanelMembers extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'panel-member', name: 'Video Interview Panel Users', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      activePage: 1,
      itemsPerPage: 10,
      isOpenPanelMemberModal: false,
      isOpenDelPanelMemberModal: false,
    };
  }

  componentDidMount() {
    this.handleGetFilteredUsersList();
  }

  handleGetFilteredUsersList = () => {
    const { activePage, itemsPerPage } = this.state;
    const panelData = {
      activePage,
      itemsPerPage,
    };
    this.props.getVIPanelUsersList(panelData).then((d) => {
      if (d && !d.error) {
        const data = {
          activePage: 1,
          itemsPerPage: 1000,
          searchKey: null,
        };
        this.props.getAdminUsersList(data).then((res) => {
          if (res && !res.error) {
            const { usersList, panelMembers } = this.props;
            let panelUserIds = [];
            let totalPercent = 0;
            if (
              panelMembers &&
              Array.isArray(panelMembers) &&
              panelMembers.length > 0
            ) {
              panelMembers.forEach((p) => {
                const { percentage } = p || {};
                totalPercent += percentage && Number(percentage);
              });
              panelUserIds = panelMembers.map(
                (member) => member && member.userId
              );
            }
            let filteredUsersList = [];
            if (usersList && Array.isArray(usersList) && usersList.length > 0) {
              filteredUsersList = usersList.filter(
                (user) =>
                  user &&
                  !panelUserIds.includes(user.userId && Number(user.userId))
              );
            }
            this.setState({
              filteredUsersList,
              totalPercent,
            });
          }
        });
      }
    });
  };

  handleGetApiData = () => {
    const { activePage, itemsPerPage } = this.state;
    const data = {
      activePage,
      itemsPerPage,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.getVIPanelUsersList(data).then((res) => {
          if (res && !res.error) {
            this.setState({
              loading: false,
            });
          }
        });
      }
    );
  };

  activeAddPanelMemberModal = (panelId, userId, percentage) => {
    this.setState(
      {
        isOpenPanelMemberModal: true,
        panelId,
        userId,
        percentage,
      },
      () => {
        const { usersList } = this.props;
        if (panelId) {
          this.setState({
            filteredUsersList: usersList,
          });
        } else {
          this.handleGetFilteredUsersList();
        }
      }
    );
  };

  activeDeletePanelMemModal = (panelId) => {
    this.setState({
      isOpenDelPanelMemberModal: true,
      panelId,
    });
  };

  dismissDeletePanelMemModal = () => {
    this.setState({
      isOpenDelPanelMemberModal: false,
      panelId: null,
    });
  };

  dismissAddPanelMemberModal = () => {
    this.setState(
      {
        isOpenPanelMemberModal: false,
        panelId: null,
        userId: null,
        percentage: null,
      },
      () => {
        this.handleGetFilteredUsersList();
      }
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitAddPanelMemberModal = () => {
    const { panelId, userId, percentage, totalPercent } = this.state;
    const { panelMembers } = this.props;
    let filteredPanelList = [];
    if (
      panelMembers &&
      Array.isArray(panelMembers) &&
      panelMembers.length > 0
    ) {
      filteredPanelList = panelMembers.filter((r) => r.panelId != panelId);
    }
    let isPercentvalid = false;
    if (!panelId) {
      if (!percentage) {
        isPercentvalid = true;
      } else if (
        (totalPercent && Number(totalPercent)) +
          (percentage && Number(percentage)) >
        100
      ) {
        isPercentvalid = true;
      } else {
        isPercentvalid = false;
      }
    } else if (panelId) {
      let totalCurrPercent = 0;
      if (
        filteredPanelList &&
        Array.isArray(filteredPanelList) &&
        filteredPanelList.length > 0
      ) {
        filteredPanelList.forEach((p) => {
          const { percentage: propPercent } = p || {};
          totalCurrPercent += propPercent && Number(propPercent);
        });
      }
      if (!percentage) {
        isPercentvalid = true;
      } else if (
        (totalCurrPercent && Number(totalCurrPercent)) +
          (percentage && Number(percentage)) >
        100
      ) {
        isPercentvalid = true;
      } else {
        isPercentvalid = false;
      }
    }
    this.setState(
      {
        isValidUserId: userId ? false : true,
        isValidPercent: isPercentvalid,
      },
      () => {
        const { isValidUserId, isValidPercent } = this.state;
        if (!isValidPercent && !isValidUserId) {
          const data = {
            userId,
            percentage,
          };
          if (panelId) {
            this.props
              .upsertVIPanelUser(data, panelId)
              .then((res) => {
                this.handleResApiCall(res);
              })
              .catch((error) => {
                console.log('Error', error);
                this.setState({
                  errorNotif: true,
                  successNotif: false,
                });
              });
          } else {
            this.props
              .createVIPanelUser(data)
              .then((res) => {
                this.handleResApiCall(res);
              })
              .catch((error) => {
                console.log('Error', error);
                this.setState({
                  errorNotif: true,
                  successNotif: false,
                });
              });
          }
        }
      }
    );
  };

  handleResApiCall = (res) => {
    const { panelId } = this.state;
    if (res && !res.error) {
      this.dismissAddPanelMemberModal();
      this.dismissDeletePanelMemModal();
      this.handleGetApiData();
      this.setState({
        successNotif: true,
        notifMsg: panelId
          ? 'Your details have been updated successfully'
          : 'Your details have been created successfully',
      });
    } else {
      this.dismissAddPanelMemberModal();
      this.dismissDeletePanelMemModal();
      this.setState({
        errorNotif: true,
        successNotif: false,
      });
    }
  };

  handleSubmitDeletePanelMem = () => {
    const { panelId } = this.state;
    this.props.deleteVIPanelUser(panelId).then((res) => {
      this.handleResApiCall(res);
    });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  render() {
    const {
      activePage,
      filteredUsersList,
      isOpenPanelMemberModal,
      userId,
      panelId,
      isValidPercent,
      isValidUserId,
      percentage,
      successNotif,
      notifMsg,
      errorNotif,
      isOpenDelPanelMemberModal,
      loading,
    } = this.state;
    const { panelMembers, count } = this.props;
    const otherActionsBtn = [];
    if (
      filteredUsersList &&
      Array.isArray(filteredUsersList) &&
      filteredUsersList.length > 0
    ) {
      otherActionsBtn.push(
        <Button
          kind="primary"
          onClick={() => {
            this.activeAddPanelMemberModal(null);
          }}>
          Add User
        </Button>
      );
    }
    return (
      <div>
        <BackRow paths={this.paths} />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        <div>
          <GenericDataTable
            otherActions={otherActionsBtn}
            searchable
            onSearchInputChange={this.handleSearchSubmit}
            rows={
              panelMembers &&
              Array.isArray(panelMembers) &&
              panelMembers.length > 0
                ? panelMembers.map((data, idx) => {
                    const {
                      createdAt,
                      percentage,
                      userinfo,
                      panelId: propPanelId,
                      userId: propUserId,
                    } = data;
                    const { firstname, lastname, email } = userinfo || {};
                    return {
                      isExpanded: false,
                      id: propPanelId,
                      header_0: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Name:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div className="d-flex align-items-center">
                              <div className="xpa-avatar-width">
                                <Avatar
                                  user={{
                                    firstname,
                                    lastname,
                                    picture: null,
                                  }}
                                  classNameInfo="rounded-circle"
                                  size="30"
                                />
                              </div>
                              <div className="ml-2 text-capitalize bx--type-zeta">
                                {firstname} {lastname}
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Email:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>{email}</div>
                          </div>
                        </div>
                      ),
                      header_2: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Percentage:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>{percentage}</div>
                          </div>
                        </div>
                      ),
                      header_3: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Created At:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>{moment(createdAt).fromNow()}</div>
                          </div>
                        </div>
                      ),
                      header_4: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Action:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div className="d-flex justify-content-lg-center">
                              <div>
                                <Button
                                  onClick={() => {
                                    this.activeAddPanelMemberModal(
                                      propPanelId,
                                      propUserId,
                                      percentage
                                    );
                                  }}
                                  kind="tertiary"
                                  size="small">
                                  Edit
                                </Button>
                              </div>
                              <div className="ml-2">
                                <Button
                                  onClick={() =>
                                    this.activeDeletePanelMemModal(propPanelId)
                                  }
                                  kind="danger"
                                  size="small">
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
            headers={[
              {
                key: 'header_0',
                header: 'Name',
              },
              {
                key: 'header_1',
                header: 'Email',
                className: 'text-center',
              },
              {
                key: 'header_2',
                header: 'Percentage',
                className: 'text-center',
              },
              {
                key: 'header_3',
                header: 'created At',
                className: 'text-center',
              },
              {
                key: 'header_4',
                header: 'Action',
                className: 'text-center',
              },
            ]}
          />
          {count > 0 && (
            <Pagination
              onChange={this.handlePaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              page={activePage}
              totalItems={count}
            />
          )}
          {count <= 0 && (
            <NoContentBox message="There are no user created for your current selection" />
          )}
        </div>
        <Modal
          open={isOpenPanelMemberModal}
          modalHeading={panelId ? 'Edit Panel Details' : 'Add Panel Details'}
          modalLabel="Action"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          iconDescription="Close the modal"
          onRequestClose={this.dismissAddPanelMemberModal}
          onRequestSubmit={this.handleSubmitAddPanelMemberModal}>
          <div className="d-flex">
            <div className="w-100">
              <Select
                name="userId"
                id="userId"
                onChange={this.handleChange}
                value={userId}
                className="w-100 xpa-width"
                invalid={isValidUserId}
                invalidText="Please select valid user"
                labelText="Select User">
                <SelectItem text="Select" key={0} />
                {filteredUsersList &&
                  Array.isArray(filteredUsersList) &&
                  filteredUsersList.length > 0 &&
                  filteredUsersList.map((user) => {
                    const { userId: propUserId, email, firstname, lastname } =
                      user || {};
                    return (
                      <SelectItem
                        text={`${firstname} ${lastname} (${email})`}
                        key={propUserId}
                        id={propUserId}
                        value={propUserId}
                      />
                    );
                  })}
              </Select>
            </div>
            <div className="ml-2 w-100">
              <TextInput
                name="percentage"
                id="percentage"
                value={percentage}
                placeholder="80"
                onChange={this.handleChange}
                invalid={isValidPercent}
                invalidText="Please enter valid percent"
                labelText="Percent"
              />
            </div>
          </div>
        </Modal>
        <Modal
          open={isOpenDelPanelMemberModal}
          modalHeading="Delete User"
          modalLabel="Action"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          danger
          iconDescription="Close the modal"
          onRequestClose={this.dismissDeletePanelMemModal}
          onRequestSubmit={this.handleSubmitDeletePanelMem}>
          Are you sure you want to delete this user?
        </Modal>
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  panelMembers: state.AdminSection.panelMembers,
  count: state.AdminSection.panelMembersCount,
  usersList: state.AdminSection.usersList,
  loading: state.AdminSection.loading,
});

const mapDispatchToProps = {
  createVIPanelUser,
  upsertVIPanelUser,
  deleteVIPanelUser,
  getVIPanelUsersList,
  getAdminUsersList,
};

export default connect(mapStateToProps, mapDispatchToProps)(VIPanelMembers);
