import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DataTable, Search } from 'carbon-components-react';
import './GenericDataTable.css';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableToolbarSearch,
  TableToolbarContent,
  TableToolbarAction,
  TableExpandedRow,
  TableSelectAll,
  TableExpandHeader,
  TableExpandRow,
  TableSelectRow,
} = DataTable;

class GenericDataTable extends Component {
  makeMobileCards = (rows) => {
    const { extras = {} } = this.props;
    const rowsCards =
      rows &&
      Array.isArray(rows) &&
      rows.map((obj, n) => {
        const { id } = obj || {};
        const ele = Object.assign({}, obj);
        delete ele.id;
        return (
          <div key={n} name={id} className="bx--tile mb-1 css-sm-tile-b">
            {ele &&
              typeof ele === 'object' &&
              Object.keys(ele).map((name, i) =>
                name == 'key' ? (
                  <span />
                ) : (
                  <div
                    className={`${name} ${
                      extras[name] ? extras[name].className : ''
                    }`}
                    title={name.toLowerCase()}
                    key={i}>
                    {ele[name]}
                  </div>
                )
              )}
          </div>
        );
      });
    return rowsCards;
  };

  render() {
    const {
      rows,
      selectable,
      searchable,
      expandable,
      headers,
      batchActions,
      otherActions,
      title,
      expandedRows,
      customSorting,
      batchActionClick,
      onSearchInputChange,
      className,
      rowClass = '',
      noHeaders = false,
      history: { push },
      tableStyle = {},
      trClass = '',
      tdClass = '',
      tbodyClass = '',
      isSortable = false,
      placeHolderText,
      tableWrapClassName,
    } = this.props;
    const customSortBy = (header, sortBy) => {
      sortBy(header.key);
      customSorting({ propName: header.colpropName });
    };
    const customSortRow = (
      cellA,
      cellB,
      { sortDirection, sortStates, locale }
    ) => {
      return 0;
    };
    const header = [{ key: 'header', header: 'Header' }];
    const rowData = this.makeMobileCards(rows);

    const getDisabledStatus = (row) => {
      let rowContentsArr = row && row.id && row.id.split('_');
      if (
        rowContentsArr &&
        Array.isArray(rowContentsArr) &&
        rowContentsArr.length > 0
      ) {
        if (rowContentsArr[2] && rowContentsArr[3] == 'true') {
          return true;
        }
        return false;
      }
      return false;
    };

    return (
      <div>
        <div className="d-none d-lg-block">
          <DataTable
            rows={rows}
            headers={headers}
            sortRow={customSortRow}
            isSortable={isSortable}
            render={({
              rows,
              headers,
              getHeaderProps,
              getRowProps,
              getTableProps,
              getSelectionProps,
              getBatchActionProps,
              onInputChange,
              expandRow,
              sortBy,
              selectedRows,
            }) => {
              // {`${batchActions ? 'd-block d-lg-flex d-md-flex' : ''}`}
              return (
                <TableContainer title={title} className={className}>
                  {(searchable ||
                    (otherActions && otherActions.length > 0) ||
                    selectable) && (
                    <TableToolbar>
                      {batchActions && (
                        <TableBatchActions {...getBatchActionProps()}>
                          {batchActions &&
                            batchActions.map((eachBatchAction, key) => (
                              <TableBatchAction
                                key={key}
                                icon={eachBatchAction.icon}
                                iconDescription="Search"
                                onClick={(e) =>
                                  batchActionClick(
                                    selectedRows,
                                    eachBatchAction.key
                                  )
                                }>
                                {eachBatchAction.label}
                              </TableBatchAction>
                            ))}
                        </TableBatchActions>
                      )}
                      {searchable && (
                        <TableToolbarSearch
                          expanded
                          onChange={onSearchInputChange}
                          placeHolderText={placeHolderText || 'Filter Table'}
                          className="csc-table-search-expandable"
                        />
                      )}
                      <TableToolbarContent>
                        {otherActions &&
                          otherActions.map((eachAction) => eachAction)}
                      </TableToolbarContent>
                    </TableToolbar>
                  )}
                  <div className={tableWrapClassName}>
                    <Table style={tableStyle} {...getTableProps()}>
                      {noHeaders ? null : (
                        <TableHead>
                          <TableRow>
                            {expandable && <TableExpandHeader />}
                            {selectable && (
                              <TableSelectAll {...getSelectionProps()} />
                            )}
                            {headers.map((header) => (
                              <TableHeader
                                {...getHeaderProps({ header })}
                                onClick={(e) => {
                                  customSortBy(header, sortBy);
                                }}
                                className={
                                  header.className ? header.className : ''
                                }>
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody className={tbodyClass}>
                        {rows.map((row, index) =>
                          expandable ? (
                            <React.Fragment key={row.id}>
                              <TableExpandRow {...getRowProps({ row })}>
                                {row.cells.map((cell, cellIndex) => (
                                  <TableCell
                                    className={
                                      headers[cellIndex] &&
                                      headers[cellIndex].className
                                        ? headers[cellIndex].className
                                        : ''
                                    }
                                    key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                ))}
                              </TableExpandRow>
                              {row && row.isExpanded && (
                                <TableExpandedRow colSpan={headers.length + 1}>
                                  {expandedRows[index]}
                                </TableExpandedRow>
                              )}
                            </React.Fragment>
                          ) : (
                            <TableRow key={`${row.id}`} className={trClass}>
                              {selectable && (
                                <TableSelectRow
                                  {...getSelectionProps({
                                    row,
                                    disabled: getDisabledStatus(row),
                                  })}
                                />
                              )}
                              {row.cells.map((cell, cellIndex) => (
                                <TableCell
                                  colSpan={
                                    headers[cellIndex] &&
                                    headers[cellIndex].colSpan
                                      ? headers[cellIndex].colSpan
                                      : 1
                                  }
                                  className={
                                    headers[cellIndex] &&
                                    headers[cellIndex].className
                                      ? `${headers[cellIndex].className} ${tdClass}`
                                      : `${tdClass}`
                                  }
                                  key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              ))}
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </TableContainer>
              );
            }}
          />
        </div>

        <div className="d-lg-none">
          <div className="d-flex flex-column mb-2">
            <div>
              {searchable === true && (
                <Search
                  id="search-1"
                  labelText=""
                  name="searchKey"
                  placeHolderText="Search"
                  onChange={onSearchInputChange}
                />
              )}
            </div>
            <div className="d-flex flex-column mb-2 mt-3">
              {otherActions &&
                Array.isArray(otherActions) &&
                otherActions.length > 0 &&
                otherActions.map((value) => {
                  return <div className="mt-2">{value}</div>;
                })}
            </div>
          </div>
          {rowData && rowData.length > 0 && rowData}
        </div>
      </div>
    );
  }
}

export default withRouter(GenericDataTable);
