import React from 'react';

const handleGetBehavioralIndicators = (name, score) => {
  let indicator = '';
  switch (name) {
    case 'Learning Agility':
      if (score > 0 && score <= 3) {
        indicator =
          'Struggles to retain information and takes a long time to acquire new skills. Fails to adapt ways of working based on new knowledge.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Acquires and retains information and skills at a pace comparable to the average person in the norm group.  At times finds it difficult to apply this to new situations.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Acquires, retains and masters new skills and knowledge rapidly. Applies this to adapt ways of working according to the situation.';
      } else if (score === 10) {
        indicator =
          'Acquires and applies new skills and knowledge rapidly, but may become bored when experiencing a lack of mental simulation and will be distracted to look for new challenges in other areas.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Struggles to retain information and takes a long time to acquire
                new skills. Fails to adapt ways of working based on new
                knowledge.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Acquires and retains information and skills at a pace comparable
                to the average person in the norm group. At times finds it
                difficult to apply this to new situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Acquires, retains and masters new skills and knowledge rapidly.
                Applies this to adapt ways of working according to the
                situation.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Acquires and applies new skills and knowledge rapidly, but may
                become bored when experiencing a lack of mental simulation and
                will be distracted to look for new challenges in other areas.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Processing Capacity':
      if (score > 0 && score <= 3) {
        indicator =
          'Struggles to retain large amounts of information in short-term memory. Unable to apply information for cognitive tasks like learning and decision making.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Retains a moderate amount of information in short-term memory. Performs adequately when required to apply information for cognitive tasks like learning and decision making.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Accurately retains large amounts of information in short-term memory. Enables better performance in many cognitive tasks like learning and decision making.';
      } else if (score === 10) {
        indicator =
          'Retains a lot information in short-term memory for optimal performance in cognitive tasks like learning, but may become bored when experiencing a lack of mental simulation and will be distracted to look for new challenges in other areas.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Struggles to retain large amounts of information in short-term
                memory. Unable to apply information for cognitive tasks like
                learning and decision making.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Retains a moderate amount of information in short-term memory.
                Performs adequately when required to apply information for
                cognitive tasks like learning and decision making.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Accurately retains large amounts of information in short-term
                memory. Enables better performance in many cognitive tasks like
                learning and decision making.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Retains a lot information in short-term memory for optimal
                performance in cognitive tasks like learning, but may become
                bored when experiencing a lack of mental simulation and will be
                distracted to look for new challenges in other areas.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Processing Consistency':
      if (score > 0 && score <= 3) {
        indicator =
          'Varies widely in quality of speed and accuracy when making decisions that involves responding to information.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Has some variation in quality of speed and accuracy (i.e., not always consistent) when making decisions that involves responding to information.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Consistent in responding accurately and quickly when reacting to information to allow for quality decision making across situations.';
      } else if (score === 10) {
        indicator =
          'Consistent and quick in responding to information for decision making, but may become bored when experiencing a lack of mental simulation and will be distracted to look for new challenges in other areas.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Varies widely in quality of speed and accuracy when making
                decisions that involves responding to information.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Has some variation in quality of speed and accuracy (i.e., not
                always consistent) when making decisions that involves
                responding to information.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Consistent in responding accurately and quickly when reacting to
                information to allow for quality decision making across
                situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Consistent and quick in responding to information for decision
                making, but may become bored when experiencing a lack of mental
                simulation and will be distracted to look for new challenges in
                other areas.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Processing Speed':
      if (score > 0 && score <= 3) {
        indicator =
          'Takes longer time to make decisions that require processing of complex rules.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Demonstrates some ability to process rules and make decisions, but performance drops when rules becomes increasingly complex.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Capable of making decisions quickly and accurately in tasks that involves complex rules.';
      } else if (score === 10) {
        indicator =
          'Accurate and fast in complex rule-based tasks, but may become bored when experiencing a lack of mental simulation and will be distracted to look for new challenges in other areas.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes longer time to make decisions that require processing of
                complex rules.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Demonstrates some ability to process rules and make decisions,
                but performance drops when rules becomes increasingly complex.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Capable of making decisions quickly and accurately in tasks that
                involves complex rules.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Accurate and fast in complex rule-based tasks, but may become
                bored when experiencing a lack of mental simulation and will be
                distracted to look for new challenges in other areas.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Executive Function':
      if (score > 0 && score <= 3) {
        indicator =
          'Fails to switch flexibly and think about different concepts. Struggles to perform accurately and efficiently in complex tasks.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Takes some time to switch between thinking about different concepts in a complex task. Performance is comparable to the average person in the norm group.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Efficient and flexible in switching between thinking about different concepts. Maintains optimal performance in complex tasks.';
      } else if (score === 10) {
        indicator =
          'Efficient in thinking and switching between concepts in complex tasks, but may become bored when experiencing a lack of mental simulation and will be distracted to look for new challenges in other areas.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Fails to switch flexibly and think about different concepts.
                Struggles to perform accurately and efficiently in complex
                tasks.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes some time to switch between thinking about different
                concepts in a complex task. Performance is comparable to the
                average person in the norm group.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Efficient and flexible in switching between thinking about
                different concepts. Maintains optimal performance in complex
                tasks.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Efficient in thinking and switching between concepts in complex
                tasks, but may become bored when experiencing a lack of mental
                simulation and will be distracted to look for new challenges in
                other areas.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Concentration':
      if (score > 0 && score <= 3) {
        indicator =
          'Fails to focus in distracting environments. Unable to maintain optimal mental performance on tasks for an extended period of time.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Has some strategies to help remain focused for some time, but struggles to concentrate over an extended period of time when distractions are present.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Maintains clear focus in distracting environments. Performs at an optimal level for an extended period of time.';
      } else if (score === 10) {
        indicator =
          'Maintains focus on specific task in distracting environment, but may fail to notice potentially important external events because of intense concentration on certain tasks.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Fails to focus in distracting environments. Unable to maintain
                optimal mental performance on tasks for an extended period of
                time.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Has some strategies to help remain focused for some time, but
                struggles to concentrate over an extended period of time when
                distractions are present.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Maintains clear focus in distracting environments. Performs at
                an optimal level for an extended period of time.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Maintains focus on specific task in distracting environment, but
                may fail to notice potentially important external events because
                of intense concentration on certain tasks.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Performance Under Pressure':
      if (score > 0 && score <= 3) {
        indicator =
          'Becomes anxious when faced with challenging or adverse situations. Makes errors or allows quality to be compromised.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Achieves an acceptable level of quality when working under pressure. Can cope with challenging tasks and adversity to a moderate extent, but may occasionally allow performance and quality to be compromised by pressure faced.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Maintains optimal performance and quality under pressure. Copes with challenging tasks or moments of adversity with ease.';
      } else if (score === 10) {
        indicator =
          'Seeks challenge and pressure and have optimal performance under pressure, but performance may fall to a suboptimal level during periods of relative calm.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Becomes anxious when faced with challenging or adverse
                situations. Makes errors or allows quality to be compromised.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Achieves an acceptable level of quality when working under
                pressure. Can cope with challenging tasks and adversity to a
                moderate extent, but may occasionally allow performance and
                quality to be compromised by pressure faced.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Maintains optimal performance and quality under pressure. Copes
                with challenging tasks or moments of adversity with ease.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Seeks challenge and pressure and have optimal performance under
                pressure, but performance may fall to a suboptimal level during
                periods of relative calm.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Ownership and Responsibility':
      if (score > 0 && score <= 3) {
        indicator =
          'Does not take responsibility for their mistakes and depends fully on others to correct the mistakes.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Take some responsibility for their mistakes and relies on guidance from others at times.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes full responsibility of their mistakes. Makes decisions and solves problems without depending on others to correct them.';
      } else if (score === 10) {
        indicator =
          'Takes full responsibility of their mistakes without depending on others, but that means that they may be unwilling to seek out or accept help from others in difficult situations. Overly controlling of correcting mistakes.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Does not take responsibility for their mistakes and depends
                fully on others to correct the mistakes.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Take some responsibility for their mistakes and relies on
                guidance from others at times.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes full responsibility of their mistakes. Makes decisions and
                solves problems without depending on others to correct them.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes full responsibility of their mistakes without depending on
                others, but that means that they may be unwilling to seek out or
                accept help from others in difficult situations. Overly
                controlling of correcting mistakes.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Determination':
      if (score > 0 && score <= 3) {
        indicator =
          'Lacks the motivation and persistence to complete difficult tasks or overcome difficult challenges. May give up in these situations.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Makes some effort to overcome difficult challenges but struggles if these tasks stretch over an extended time or become overly challenging.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Remains self-motivated and persistent when achieving a difficult task in both the long and short term. Exerts additional effort to overcome new challenges.';
      } else if (score === 10) {
        indicator =
          'Self-motivated and exerts additional effort to overcome challenges, but may fail to move on when a goal becomes unattainable. Demonstrates a compulsive need for achieving perfection.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Lacks the motivation and persistence to complete difficult tasks
                or overcome difficult challenges. May give up in these
                situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Makes some effort to overcome difficult challenges but struggles
                if these tasks stretch over an extended time or become overly
                challenging.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Remains self-motivated and persistent when achieving a difficult
                task in both the long and short term. Exerts additional effort
                to overcome new challenges.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Self-motivated and exerts additional effort to overcome
                challenges, but may fail to move on when a goal becomes
                unattainable. Demonstrates a compulsive need for achieving
                perfection.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Sensitivity To Loss':
      if (score > 0 && score <= 3) {
        indicator =
          'Takes unnecessary risks even when there is a high chance of failure and fails to consider the potential negative outcomes of a situation.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Considers some of the potential negative consequences of a situation, especially when there is a chance of failure or punishment. Takes some action to ensure a positive outcome.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Carefully considers the potential negative consequences of a situation due to the fear of negative outcomes before taking action. Takes safer actions which ensure more favourable outcomes.';
      } else if (score === 10) {
        indicator =
          'Considers the potential negative consequences of a situation before taking action, but may end up being overwhelmed by the possibility of negative outcomes and hence struggles to take action.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes unnecessary risks even when there is a high chance of
                failure and fails to consider the potential negative outcomes of
                a situation.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Considers some of the potential negative consequences of a
                situation, especially when there is a chance of failure or
                punishment. Takes some action to ensure a positive outcome.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Carefully considers the potential negative consequences of a
                situation due to the fear of negative outcomes before taking
                action. Takes safer actions which ensure more favourable
                outcomes.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Considers the potential negative consequences of a situation
                before taking action, but may end up being overwhelmed by the
                possibility of negative outcomes and hence struggles to take
                action.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Resilience':
      if (score > 0 && score <= 3) {
        indicator =
          'Dwells excessively on setbacks and struggles to remain goal-focused.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Takes some time to recover from setbacks. Makes some attempt to learn from the setback.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Bounces back quickly and positively from setbacks or negative results. Reflects and learns from the mistakes they have made.';
      } else if (score === 10) {
        indicator =
          'Bounces back quickly from setbacks, but may end up not taking enough time to reflect on setbacks in order to learn from them.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Dwells excessively on setbacks and struggles to remain
                goal-focused.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes some time to recover from setbacks. Makes some attempt to
                learn from the setback.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Bounces back quickly and positively from setbacks or negative
                results. Reflects and learns from the mistakes they have made.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Bounces back quickly from setbacks, but may end up not taking
                enough time to reflect on setbacks in order to learn from them.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Sensitivity To Reward':
      if (score > 0 && score <= 3) {
        indicator =
          'Does not exert additional effort in return for external rewards. Performance varies regardless of rewards.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Exerts a moderate amount of effort to accomplish a desired outcome in return for an extrinsic reward, but not to the point that it would exceed the goal or target.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Exerts additional effort to accomplish a desired outcome in return for an extrinsic reward; is goal-driven and hits or exceeds the target.';
      } else if (score === 10) {
        indicator =
          'Exerts additional effort to exceed targets in exchange for extrinsic reward, but the focus on the reward may be excessive to the detriment of other important factors (e.g people, quality). Might become demotivated and when there is a lack of extrinsic rewards.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Does not exert additional effort in return for external rewards.
                Performance varies regardless of rewards.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Exerts a moderate amount of effort to accomplish a desired
                outcome in return for an extrinsic reward, but not to the point
                that it would exceed the goal or target.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Exerts additional effort to accomplish a desired outcome in
                return for an extrinsic reward; is goal-driven and hits or
                exceeds the target.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Exerts additional effort to exceed targets in exchange for
                extrinsic reward, but the focus on the reward may be excessive
                to the detriment of other important factors (e.g people,
                quality). Might become demotivated and when there is a lack of
                extrinsic rewards.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Self-discipline':
      if (score > 0 && score <= 3) {
        indicator =
          'Has a disorganised approach to work, lacking personal order and control. Tends to miss deadlines. May overlook details, reducing the overall quality of work.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Adopts some structure to work, but may not be consistently applied across situations.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Has a strong degree of personal organisation and a disciplined approach to work. Can consistently meet deadlines. Is highly detail-oriented, taking time to research and plan for tasks.';
      } else if (score === 10) {
        indicator =
          'Strives for high levels of organisation and perfection, but may cause them to struggle in disordered environments.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Has a disorganised approach to work, lacking personal order and
                control. Tends to miss deadlines. May overlook details, reducing
                the overall quality of work.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Adopts some structure to work, but may not be consistently
                applied across situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Has a strong degree of personal organisation and a disciplined
                approach to work. Can consistently meet deadlines. Is highly
                detail-oriented, taking time to research and plan for tasks.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Strives for high levels of organisation and perfection, but may
                cause them to struggle in disordered environments.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Proactivity':
      if (score > 0 && score <= 3) {
        indicator =
          'Procrastinates. Fails to identify what needs to be done so fails to take action.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Demonstrates some action-orientation by commencing tasks with little delay and attempts to work consistently to deliver results on time, but may occasionally need prompts by others.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes initiative and demonstrates a high degree of action-orientation by commencing tasks quickly and working consistently to deliver results on time.';
      } else if (score === 10) {
        indicator =
          'Commences on tasks quickly and working consistently to deliver results on time, but it may go to the extent of acting too quickly thereby failing to see more effective or efficient approaches.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Procrastinates. Fails to identify what needs to be done so fails
                to take action.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Demonstrates some action-orientation by commencing tasks with
                little delay and attempts to work consistently to deliver
                results on time, but may occasionally need prompts by others.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes initiative and demonstrates a high degree of
                action-orientation by commencing tasks quickly and working
                consistently to deliver results on time.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Commences on tasks quickly and working consistently to deliver
                results on time, but it may go to the extent of acting too
                quickly thereby failing to see more effective or efficient
                approaches.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Innovation Potential':
      if (score > 0 && score <= 3) {
        indicator =
          'Sticks to tried-and-tested approaches. Does not consider different perspectives and unable to create novel or more efficient solutions.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Considers some alternative perspectives for solutions but is generally cautious towards novelty. May alternate between tried-and-tested and novel approaches.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes an experimental approach. Explores a wide range of perspectives to come up with novel and efficient solutions.';
      } else if (score === 10) {
        indicator =
          'Experiments and comes up with novel solutions, but may become bored when engaging in repetitive tasks with little room for innovation. Disrupts efficiency when attempting to innovate even though existing methods prove to work well.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Sticks to tried-and-tested approaches. Does not consider
                different perspectives and unable to create novel or more
                efficient solutions.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Considers some alternative perspectives for solutions but is
                generally cautious towards novelty. May alternate between
                tried-and-tested and novel approaches.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes an experimental approach. Explores a wide range of
                perspectives to come up with novel and efficient solutions.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Experiments and comes up with novel solutions, but may become
                bored when engaging in repetitive tasks with little room for
                innovation. Disrupts efficiency when attempting to innovate even
                though existing methods prove to work well.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Rational Decision-Making Style':
      if (score > 0 && score <= 3) {
        indicator =
          'Makes impulsive decisions based on intuition. Fails to take an objective approach to decision making by considering logic and reason.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to weigh up the pros and cons of a potential outcome, but may at times fall back on the initial intuitive response.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes a highly objective and critical approach to decision-making by carefully weighing up the pros and cons of a situation.';
      } else if (score === 10) {
        indicator =
          'Weighs pros and cons of situation objectively, but may ignore emotional outcomes of decisions which may be important.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Makes impulsive decisions based on intuition. Fails to take an
                objective approach to decision making by considering logic and
                reason.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to weigh up the pros and cons of a potential outcome,
                but may at times fall back on the initial intuitive response.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes a highly objective and critical approach to
                decision-making by carefully weighing up the pros and cons of a
                situation.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Weighs pros and cons of situation objectively, but may ignore
                emotional outcomes of decisions which may be important.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Rational Decision Making Style':
      if (score > 0 && score <= 3) {
        indicator =
          'Makes impulsive decisions based on intuition. Fails to take an objective approach to decision making by considering logic and reason.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to weigh up the pros and cons of a potential outcome, but may at times fall back on the initial intuitive response.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes a highly objective and critical approach to decision-making by carefully weighing up the pros and cons of a situation.';
      } else if (score === 10) {
        indicator =
          'Weighs pros and cons of situation objectively, but may ignore emotional outcomes of decisions which may be important.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Makes impulsive decisions based on intuition. Fails to take an
                objective approach to decision making by considering logic and
                reason.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to weigh up the pros and cons of a potential outcome,
                but may at times fall back on the initial intuitive response.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes a highly objective and critical approach to
                decision-making by carefully weighing up the pros and cons of a
                situation.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Weighs pros and cons of situation objectively, but may ignore
                emotional outcomes of decisions which may be important.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Managing Uncertainty':
      if (score > 0 && score <= 3) {
        indicator =
          'Fails to progress and make decisions in ambiguous situations.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to make decisions in unpredictable environments. Takes some positive steps towards remaining productive when things change, but may struggle when disruptions persist.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Makes fast and efficient decisions in unpredictable environments. Remains highly productive when faced with unforeseeable distruptions.';
      } else if (score === 10) {
        indicator =
          'Productive even in unpredictable environments, but may pursue disruption excessively and that may result in inefficiency and adverse consequences.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Fails to progress and make decisions in ambiguous situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to make decisions in unpredictable environments. Takes
                some positive steps towards remaining productive when things
                change, but may struggle when disruptions persist.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Makes fast and efficient decisions in unpredictable
                environments. Remains highly productive when faced with
                unforeseeable distruptions.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Productive even in unpredictable environments, but may pursue
                disruption excessively and that may result in inefficiency and
                adverse consequences.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Deliberation':
      if (score > 0 && score <= 3) {
        indicator =
          'Acts impulsively when making decisions, with little consideration of the full ramifications of actions.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Makes decisions with some control and planning. Involves some consideration of impact of actions, but not thoroughly.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Acts in a highly controlled manner, taking time to carefully consider the impact of decisions by weighing up the pros and cons before taking action.';
      } else if (score === 10) {
        indicator =
          'Makes decisions with great control and careful planning, but may be unable to make a decision or take too long to make a decision due to excessive consideration of the pros and cons.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Acts impulsively when making decisions, with little
                consideration of the full ramifications of actions.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Makes decisions with some control and planning. Involves some
                consideration of impact of actions, but not thoroughly.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Acts in a highly controlled manner, taking time to carefully
                consider the impact of decisions by weighing up the pros and
                cons before taking action.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Makes decisions with great control and careful planning, but may
                be unable to make a decision or take too long to make a decision
                due to excessive consideration of the pros and cons.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Future Orientation':
      if (score > 0 && score <= 3) {
        indicator =
          'Acts with the benefit of immediate gains in mind, without considering the longer term ramifications of their actions.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Considers some of the longer-term impacts and outcomes of the decision, whilst working through the more immediate actions.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Demonstrates sound long-term orientation in decision-making; impact of decisions taken now are considered carefully in terms of their future benefits.';
      } else if (score === 10) {
        indicator =
          'Focuses deeply on the long-term impacts, but may lose sight of the present and may seem out of touch with reality of the here and now.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Acts with the benefit of immediate gains in mind, without
                considering the longer term ramifications of their actions.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Considers some of the longer-term impacts and outcomes of the
                decision, whilst working through the more immediate actions.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Demonstrates sound long-term orientation in decision-making;
                impact of decisions taken now are considered carefully in terms
                of their future benefits.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Focuses deeply on the long-term impacts, but may lose sight of
                the present and may seem out of touch with reality of the here
                and now.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Need For Structure':
      if (score > 0 && score <= 3) {
        indicator =
          'Takes a free-flowing approach to completing tasks. May struggle to perform in structured environment. Actively seeks ambiguity and risk.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Makes some structured plans when approaching tasks. Can perform adequately in structured environments. Willing to take moderate risks but may choose to be cautious in high risk situations.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes a highly structured approach to tasks. Performs well in predictable, stable and safe settings. Prefers to take the safer option in uncertain situations.';
      } else if (score === 10) {
        indicator =
          'Introduces a lot of structure to tasks that allows optimal performance in predictable settings, but that means they may not be able to take a risk when needed.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes a free-flowing approach to completing tasks. May struggle
                to perform in structured environment. Actively seeks ambiguity
                and risk.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Makes some structured plans when approaching tasks. Can perform
                adequately in structured environments. Willing to take moderate
                risks but may choose to be cautious in high risk situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes a highly structured approach to tasks. Performs well in
                predictable, stable and safe settings. Prefers to take the safer
                option in uncertain situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Introduces a lot of structure to tasks that allows optimal
                performance in predictable settings, but that means they may not
                be able to take a risk when needed.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Risk propensity':
      if (score > 0 && score <= 3) {
        indicator =
          'Avoids making decisions involving risk. Selects safer options that may mean missed opportunities for reward or personal growth.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Approaches risky situations and decisions with caution. Alternates between options which favour security and options which favour risks.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes risks that have potential beneficial outcomes, and does so with confidence. Motivated to take risks by potential rewards or incentives.';
      } else if (score === 10) {
        indicator =
          'Takes risks with confidence, but may go to the point of taking risks even when they will lead to negative results. Might be seen as impulsive.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Avoids making decisions involving risk. Selects safer options
                that may mean missed opportunities for reward or personal
                growth.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Approaches risky situations and decisions with caution.
                Alternates between options which favour security and options
                which favour risks.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes risks that have potential beneficial outcomes, and does so
                with confidence. Motivated to take risks by potential rewards or
                incentives.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes risks with confidence, but may go to the point of taking
                risks even when they will lead to negative results. Might be
                seen as impulsive.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Risk-Propensity':
      if (score > 0 && score <= 3) {
        indicator =
          'Avoids making decisions involving risk. Selects safer options that may mean missed opportunities for reward or personal growth.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Approaches risky situations and decisions with caution. Alternates between options which favour security and options which favour risks.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes risks that have potential beneficial outcomes, and does so with confidence. Motivated to take risks by potential rewards or incentives.';
      } else if (score === 10) {
        indicator =
          'Takes risks with confidence, but may go to the point of taking risks even when they will lead to negative results. Might be seen as impulsive.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Avoids making decisions involving risk. Selects safer options
                that may mean missed opportunities for reward or personal
                growth.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Approaches risky situations and decisions with caution.
                Alternates between options which favour security and options
                which favour risks.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes risks that have potential beneficial outcomes, and does so
                with confidence. Motivated to take risks by potential rewards or
                incentives.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes risks with confidence, but may go to the point of taking
                risks even when they will lead to negative results. Might be
                seen as impulsive.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Risk Propensity':
      if (score > 0 && score <= 3) {
        indicator =
          'Avoids making decisions involving risk. Selects safer options that may mean missed opportunities for reward or personal growth.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Approaches risky situations and decisions with caution. Alternates between options which favour security and options which favour risks.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes risks that have potential beneficial outcomes, and does so with confidence. Motivated to take risks by potential rewards or incentives.';
      } else if (score === 10) {
        indicator =
          'Takes risks with confidence, but may go to the point of taking risks even when they will lead to negative results. Might be seen as impulsive.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Avoids making decisions involving risk. Selects safer options
                that may mean missed opportunities for reward or personal
                growth.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Approaches risky situations and decisions with caution.
                Alternates between options which favour security and options
                which favour risks.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes risks that have potential beneficial outcomes, and does so
                with confidence. Motivated to take risks by potential rewards or
                incentives.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes risks with confidence, but may go to the point of taking
                risks even when they will lead to negative results. Might be
                seen as impulsive.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Creativity':
      if (score > 0 && score <= 3) {
        indicator =
          'Struggles to generate alternative or new ideas. Maintains a conventional way of thinking based entirely on prior experience.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to generate alternative or new ideas when necessary but may find it hard to detach from conventional ways of thinking as a norm. May alternate between conventional and new ways of thinking.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Thinks "outside the box". Has an abstract trail of thought when coming up with ideas, while still considering the practical aspects.';
      } else if (score === 10) {
        indicator =
          'Thinks "outside of the box", but may generate ideas that are so abstract, there is little consideration of the practical aspects.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Struggles to generate alternative or new ideas. Maintains a
                conventional way of thinking based entirely on prior experience.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to generate alternative or new ideas when necessary but
                may find it hard to detach from conventional ways of thinking as
                a norm. May alternate between conventional and new ways of
                thinking.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Thinks "outside the box". Has an abstract trail of thought when
                coming up with ideas, while still considering the practical
                aspects.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Thinks "outside of the box", but may generate ideas that are so
                abstract, there is little consideration of the practical
                aspects.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Curiosity':
      if (score > 0 && score <= 3) {
        indicator =
          'Extremely limited in exploration of a new idea or subject. Relies on what is already known.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to explore new ideas and alternative perspectives, but may at times fall back on their original perspective.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Highly eager to explore new ideas and subjects using a range of sources. Is open to considering and adopting alternative perspectives.';
      } else if (score === 10) {
        indicator =
          'Eager to explore alternative ideas and perspectives, but may find it hard to stick to one perspective or approach.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Extremely limited in exploration of a new idea or subject.
                Relies on what is already known.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to explore new ideas and alternative perspectives, but
                may at times fall back on their original perspective.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Highly eager to explore new ideas and subjects using a range of
                sources. Is open to considering and adopting alternative
                perspectives.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Eager to explore alternative ideas and perspectives, but may
                find it hard to stick to one perspective or approach.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Novelty Seeking':
      if (score > 0 && score <= 3) {
        indicator =
          'Does not enjoy and performs poorly in new situations which present them with a multitude of different options. Actively selects safer options to avoid risk.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Willing to try out new situations which present them with a multitude of different options, but performance may not be the most optimal. Willing to explore alternative options with a moderate level of risk.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Enjoys and works well in new situations which present them with a multitude of different options. Actively seeks variety and the opportunity to try new methods.';
      } else if (score === 10) {
        indicator =
          'Continuously seeks new challenges or experiences but may end up taking unnecessary risks and jeopardising delivery of a solution. May be disengaged or bored without new challenges or experiences.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Does not enjoy and performs poorly in new situations which
                present them with a multitude of different options. Actively
                selects safer options to avoid risk.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Willing to try out new situations which present them with a
                multitude of different options, but performance may not be the
                most optimal. Willing to explore alternative options with a
                moderate level of risk.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Enjoys and works well in new situations which present them with
                a multitude of different options. Actively seeks variety and the
                opportunity to try new methods.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Continuously seeks new challenges or experiences but may end up
                taking unnecessary risks and jeopardising delivery of a
                solution. May be disengaged or bored without new challenges or
                experiences.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Optimism':
      if (score > 0 && score <= 3) {
        indicator =
          'Focuses on the negative aspects of the situation. Avoids challenges due to doubt of a positive outcome.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to take on a positive outlook but remains critical in some aspects. Willing to take on on some challenges but may not always believe that actions will have a positive outcome.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Has an enthusiastic and positive outlook. Takes on challenges and sets ambitious goals in pursuit of a favourable outcome.';
      } else if (score === 10) {
        indicator =
          'Has a positive outlook and sets ambitious targets, but may go to the point of being unrealistic in expectations. May be completely unprepared for challenges due to focusing too much on the positive side of things.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Focuses on the negative aspects of the situation. Avoids
                challenges due to doubt of a positive outcome.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to take on a positive outlook but remains critical in
                some aspects. Willing to take on on some challenges but may not
                always believe that actions will have a positive outcome.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Has an enthusiastic and positive outlook. Takes on challenges
                and sets ambitious goals in pursuit of a favourable outcome.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Has a positive outlook and sets ambitious targets, but may go to
                the point of being unrealistic in expectations. May be
                completely unprepared for challenges due to focusing too much on
                the positive side of things.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Self-Monitoring':
      if (score > 0 && score <= 3) {
        indicator =
          'Fails to adjust themselves in a way that is aligned to the environment or situation. Perceived as disruptive or uncompromising.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to adjust behavior to align with the situation or environment, but may not always be successul and hence may face the risk of group disapproval.';
      } else if (score > 6 && score <= 9) {
        indicator =
          "Demonstrates high self-awareness and actively adjusts one's behaviour to align with social situations where there is potential for group disapproval.";
      } else if (score === 10) {
        indicator =
          'Adjusts behaviors to align with social situations with great flexibility to the point that they may be perceived as disingenuous and unwilling to disrupt social harmony at any cost.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Fails to adjust themselves in a way that is aligned to the
                environment or situation. Perceived as disruptive or
                uncompromising.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to adjust behavior to align with the situation or
                environment, but may not always be successul and hence may face
                the risk of group disapproval.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Demonstrates high self-awareness and actively adjusts one's
                behaviour to align with social situations where there is
                potential for group disapproval.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Adjusts behaviors to align with social situations with great
                flexibility to the point that they may be perceived as
                disingenuous and unwilling to disrupt social harmony at any
                cost.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Self Monitoring':
      if (score > 0 && score <= 3) {
        indicator =
          'Fails to adjust themselves in a way that is aligned to the environment or situation. Perceived as disruptive or uncompromising.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to adjust behavior to align with the situation or environment, but may not always be successul and hence may face the risk of group disapproval.';
      } else if (score > 6 && score <= 9) {
        indicator =
          "Demonstrates high self-awareness and actively adjusts one's behaviour to align with social situations where there is potential for group disapproval.";
      } else if (score === 10) {
        indicator =
          'Adjusts behaviors to align with social situations with great flexibility to the point that they may be perceived as disingenuous and unwilling to disrupt social harmony at any cost.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Fails to adjust themselves in a way that is aligned to the
                environment or situation. Perceived as disruptive or
                uncompromising.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to adjust behavior to align with the situation or
                environment, but may not always be successul and hence may face
                the risk of group disapproval.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Demonstrates high self-awareness and actively adjusts one's
                behaviour to align with social situations where there is
                potential for group disapproval.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Adjusts behaviors to align with social situations with great
                flexibility to the point that they may be perceived as
                disingenuous and unwilling to disrupt social harmony at any
                cost.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Social Dominance':
      if (score > 0 && score <= 3) {
        indicator =
          'Lacks the confidence to share their opinions with the group. Rarely attempts to direct the group. May be easily demoralised by negative feedback or when challenged by others.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Demonstrates some assertiveness and confidence, but may back down when there is a need to challenge a group decision or in the face of more extreme negative feedback.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Self-assured and assertive. Directs the conversation to allow decision to be challenged and remains self-assured in the face of negative feedback.';
      } else if (score === 10) {
        indicator =
          'Self-assured when being challenged but may be perceived as overly defensive. Assertive in a group setting but may come off as being forceful. May fail to listen to others.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Lacks the confidence to share their opinions with the group.
                Rarely attempts to direct the group. May be easily demoralised
                by negative feedback or when challenged by others.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Demonstrates some assertiveness and confidence, but may back
                down when there is a need to challenge a group decision or in
                the face of more extreme negative feedback.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Self-assured and assertive. Directs the conversation to allow
                decision to be challenged and remains self-assured in the face
                of negative feedback.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Self-assured when being challenged but may be perceived as
                overly defensive. Assertive in a group setting but may come off
                as being forceful. May fail to listen to others.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Sociability':
      if (score > 0 && score <= 3) {
        indicator =
          'Shows little interest in interacting with other people, and thus make little attempt to establish a role within the team or contribute to the team.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Shows some interest in interacting with people, and hence can occasionally take on a clear role within a team, but will at other times prefer solo tasks that involve less interaction. Can make some contribution towards the team which is well-received by others.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Shows great interest in working collaboratively as part of a team and hence establishing a key role. Builds effective relationships through positive interactions and contribution.';
      } else if (score === 10) {
        indicator =
          'Builds effective relationships and collaborates within a team, but may seek constant interaction within other team members to the point that it can become a distraction to the efforts of the team.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Shows little interest in interacting with other people, and thus
                make little attempt to establish a role within the team or
                contribute to the team.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Shows some interest in interacting with people, and hence can
                occasionally take on a clear role within a team, but will at
                other times prefer solo tasks that involve less interaction. Can
                make some contribution towards the team which is well-received
                by others.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Shows great interest in working collaboratively as part of a
                team and hence establishing a key role. Builds effective
                relationships through positive interactions and contribution.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Builds effective relationships and collaborates within a team,
                but may seek constant interaction within other team members to
                the point that it can become a distraction to the efforts of the
                team.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Politeness':
      if (score > 0 && score <= 3) {
        indicator =
          'Speaks bluntly and acts in a way that can negatively impact or inconvenience others. Becomes easily frustrated and confrontational when challenged.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to be moderately respectful and tactful towards others. Is willing to create conflict sometimes where necessary in pursuit of an aim, but avoids conflict in other occasions.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Acts in a respectful and considerate way towards others. Adopts a non-confrontational stance even when provoked.';
      } else if (score === 10) {
        indicator =
          'Is respectful, tactful and non-confrontational, but may end up not offering any constructive criticisms or counterarguments in a heated discussion.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Speaks bluntly and acts in a way that can negatively impact or
                inconvenience others. Becomes easily frustrated and
                confrontational when challenged.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to be moderately respectful and tactful towards others.
                Is willing to create conflict sometimes where necessary in
                pursuit of an aim, but avoids conflict in other occasions.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Acts in a respectful and considerate way towards others. Adopts
                a non-confrontational stance even when provoked.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Is respectful, tactful and non-confrontational, but may end up
                not offering any constructive criticisms or counterarguments in
                a heated discussion.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Altruism':
      if (score > 0 && score <= 3) {
        indicator =
          "Skeptical of others' motives. Acts in a way that is aligned with their own goals and interests.";
      } else if (score > 3 && score <= 6) {
        indicator =
          'Considers the needs of others at times. Puts the same level of priority on the personal needs versus the needs of others.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes action that is aligned with the goals and needs of others, by prioritising them over their personal goals and needs.';
      } else if (score === 10) {
        indicator =
          'Prioritises the goals and needs of others, but may go to the point of exclusively acting according to the needs of others and jeopardising self progress.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Skeptical of others' motives. Acts in a way that is aligned with
                their own goals and interests.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Considers the needs of others at times. Puts the same level of
                priority on the personal needs versus the needs of others.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes action that is aligned with the goals and needs of others,
                by prioritising them over their personal goals and needs.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Prioritises the goals and needs of others, but may go to the
                point of exclusively acting according to the needs of others and
                jeopardising self progress.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Authenticity':
      if (score > 0 && score <= 3) {
        indicator =
          'Projects a false representation of their values when challenged. Adapts to situations with the intention of manipulating others for personal gain.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Attempts to maintain some consistency in the values and beliefs in their actions. Adapts in situations to influence outcomes in their favour at times.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Takes pride in conveying consistent and transparent values. Responds to challenges by sensitively projecting a genuine representation of themselves.';
      } else if (score === 10) {
        indicator =
          'Conveys values and beliefs consistently across situations, but may be perceived as being insensitive to others’ values and beliefs as they are unable to mask own values to any extent at all.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Projects a false representation of their values when challenged.
                Adapts to situations with the intention of manipulating others
                for personal gain.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Attempts to maintain some consistency in the values and beliefs
                in their actions. Adapts in situations to influence outcomes in
                their favour at times.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Takes pride in conveying consistent and transparent values.
                Responds to challenges by sensitively projecting a genuine
                representation of themselves.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Conveys values and beliefs consistently across situations, but
                may be perceived as being insensitive to others’ values and
                beliefs as they are unable to mask own values to any extent at
                all.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Self-Belief':
      if (score > 0 && score <= 3) {
        indicator =
          'Seeks constant reassurance and validation in challenging situations. Lacks confidence and belief in their own abilities.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Demonstrates an average level of confidence and belief in their own abilities that is comparable to the average person in the norm group. Tends to seek some additional support before taking action in challenging situations.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Thrives in new and challenging situations. Demonstrates high levels of confidence and has a strong belief in their own abilities.';
      } else if (score === 10) {
        indicator =
          'Thrives in challenging situations and is extremely self-confident, but may go to the point of overestimating their ability or underestimating the difficulty of a task. May not take in constructive feedback and may come across as arrogant.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Seeks constant reassurance and validation in challenging
                situations. Lacks confidence and belief in their own abilities.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Demonstrates an average level of confidence and belief in their
                own abilities that is comparable to the average person in the
                norm group. Tends to seek some additional support before taking
                action in challenging situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Thrives in new and challenging situations. Demonstrates high
                levels of confidence and has a strong belief in their own
                abilities.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Thrives in challenging situations and is extremely
                self-confident, but may go to the point of overestimating their
                ability or underestimating the difficulty of a task. May not
                take in constructive feedback and may come across as arrogant.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Emotional Stability':
      if (score > 0 && score <= 3) {
        indicator =
          'Emotions fluctuate when working in challenging or stressful situations.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Is sometimes capable of controlling their emotions and maintaining self-control when working under stressful conditions, but may struggle when the situations become overly challenging or when stress persists over an extended period of time.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Maintains control of their emotions when working in challenging situations.';
      } else if (score === 10) {
        indicator =
          'Maintains control of their emotions when working in challenging situations, but may be less likely to learn from mistakes as they are less sensitive to stress.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Emotions fluctuate when working in challenging or stressful
                situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Is sometimes capable of controlling their emotions and
                maintaining self-control when working under stressful
                conditions, but may struggle when the situations become overly
                challenging or when stress persists over an extended period of
                time.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Maintains control of their emotions when working in challenging
                situations.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Maintains control of their emotions when working in challenging
                situations, but may be less likely to learn from mistakes as
                they are less sensitive to stress.
              </div>
            </div>
          </div>
        );
      }
      break;
    case 'Emotion Recognition':
      if (score > 0 && score <= 3) {
        indicator =
          'Inaccurately recognises emotions and struggles to develop or maintain positive interpersonal relationships.';
      } else if (score > 3 && score <= 6) {
        indicator =
          'Recognises and understands some emotions of others, which helps to develop or maintain positive interpersonal relationships adequately.';
      } else if (score > 6 && score <= 9) {
        indicator =
          'Accurately recognises and interprets emotions. Interacts well with others and builds constructive relationships.';
      } else if (score === 10) {
        indicator =
          'Recognises and understands the emotions of others with great accuracy, but may make them become very sensitive to emotions.';
      } else if (!score) {
        indicator = (
          <div>
            <div className="bx--row align-items-center mt-2">
              <h6 className="bx--col-lg-1">1 - 3</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Inaccurately recognises emotions and struggles to develop or
                maintain positive interpersonal relationships.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">4 - 6</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Recognises and understands some emotions of others, which helps
                to develop or maintain positive interpersonal relationships
                adequately.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">7 - 9</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Accurately recognises and interprets emotions. Interacts well
                with others and builds constructive relationships.
              </div>
            </div>
            <div className="mt-2 bx--row align-items-center">
              <h6 className="bx--col-lg-1">10</h6>
              <div className="ml-2 bx--col-lg-10 css-int-ques-line-h">
                Recognises and understands the emotions of others with great
                accuracy, but may make them become very sensitive to emotions.
              </div>
            </div>
          </div>
        );
      }
      break;
    default:
      break;
  }
  return indicator;
};

const handleGetTraitDefinition = (trait) => {
  let definition = '';
  switch (trait) {
    case 'Innovation Potential':
      definition =
        'The tendency to come up with new, effective and/or efficient solutions or approaches to problems.';
      break;
    case 'Processing Capacity':
      definition = 'The ability to work with large amounts of information.';
      break;
    case 'Emotional Stability':
      definition =
        'The ability to remain calm, balanced even under stressful situations; infrequently experience negative emotions.';
      break;
    case 'Performance Under Pressure':
      definition = 'Produces quality outcomes  when operating under pressure.';
      break;
    case 'Learning Agility':
      definition =
        'The ability to absorb and learn new information quickly and re-apply new learning effectively.';
      break;
    case 'Self-Monitoring':
      definition =
        'Actively adjusts one’s behaviour in order to fit in with a social group and to avoid disapproval, such that behaviors are aligned to the situation.';
      break;
    case 'Self Monitoring':
      definition =
        'Actively adjusts one’s behaviour in order to fit in with a social group and to avoid disapproval, such that behaviors are aligned to the situation.';
      break;
    case 'Social Dominance':
      definition =
        'Level of self-assuredness when interacting with others and the tendency to exert control in groups; seeks leadership positions and the tendency to direct others.';
      break;
    case 'Ownership and Responsibility':
      definition =
        'The tendency to perceive an outcome as dependent on own actions rather than external circumstances; locus of control: accountability for own action and a belief that one can influence events and outcomes .';
      break;
    case 'Rational Decision Making Style':
      definition =
        'The tendency to be thoughtful, objective and critical when making decisions.';
      break;
    case 'Determination':
      definition =
        'Remains driven over a long period of time; reflected by a high degree of achievement-striving, self-motivation, persistence and self-efficacy.';
      break;
    case 'Processing Consistency':
      definition =
        'The ability to produce  consistently high performance when responding to incoming information.';
      break;
    case 'Processing Speed':
      definition =
        'The tendency to complete complex tasks accurately in less time.';
      break;
    case 'Executive Function':
      definition =
        'The ability to shift accurately and rapidly between different objectives in complex tasks. Includes the ability to  manage complex information with ease.';
      break;
    case 'Concentration':
      definition =
        'The ability to remain focused  and maintain optimal performance for a prolonged period of time.';
      break;
    case 'Sensitivity To Loss':
      definition =
        'The tendency to be fearful or anxious of negative outcomes or reinforcement before taking action.';
      break;
    case 'Resilience':
      definition = 'The capacity to recover quickly from setbacks.';
      break;
    case 'Sensitivity To Reward':
      definition = 'Motivated by possible rewards.';
      break;
    case 'Self-discipline':
      definition =
        "Organised and disciplined in one's work; characterised by a great degree of consistency and consideration in approaching situations, and punctuality in meeting deadlines.";
      break;
    case 'Proactivity':
      definition =
        'The tendency to complete tasks without delay and with a consistent approach; demonstrates a greater degree of action-orientation as opposed to an inclination to procrastinate.';
      break;
    case 'Rational Decision-Making Style':
      definition =
        'Thoughtful, objective and critical when making decisions; decisions are made based on facts and not intuition.';
      break;
    case 'Rational Decision Making Style':
      definition =
        'Thoughtful, objective and critical when making decisions; decisions are made based on facts and not intuition.';
      break;
    case 'Managing Uncertainty':
      definition =
        'The ability to remain productive in ambiguous situations; an ability to consider multiple perspectives, cope with conflicting directions and remain productive.';
      break;
    case 'Deliberation':
      definition =
        'Carefully considers actions before responding which may require the management of multiple, complex ideas.';
      break;
    case 'Future Orientation':
      definition =
        'The extent to which one prioritises long-term benefits over short-term rewards in decision-making.';
      break;
    case 'Need For Structure':
      definition =
        'Preference for safety in ambiguious situations; tendency to be cautious and choose safer options in uncertain situations.';
    case 'Risk propensity':
      definition =
        'Level of comfort in making decisions that have a risk component.';
      break;
    case 'Risk Propensity':
      definition =
        'Level of comfort in making decisions that have a risk component.';
      break;
    case 'Risk-Propensity':
      definition =
        'Level of comfort in making decisions that have a risk component.';
      break;
    case 'Creativity':
      definition =
        'Imaginative and being able to come up with new ideas, alternatives or possibilities; characterised by a vivid imagination, strong artistic interests, and an intense experience of a range of emotions.';
      break;
    case 'Curiosity':
      definition =
        'A tendency to seek intellectual stimulation and exploration of alternative and multiple perspectives; tolerant of differing points of view and preparedness to challenge assumptions.';
      break;
    case 'Novelty Seeking':
      definition =
        'Strong drive to explore new and exciting activities; characterised by the tendency to become bored more quickly and will push the limits of a situation by taking risks.';
      break;
    case 'Optimism':
      definition = 'Views situations and choices with a positive outlook.';
      break;
    case 'Sociability':
      definition =
        'Feels energised and thrive in the presence of others; interested in social interaction and susceptible to boredom when lacking social stimulation.';
      break;
    case 'Politeness':
      definition =
        'Acts with respect towards others and avoids conflict; preference for social harmony and respect for authority.';
      break;
    case 'Altruism':
      definition =
        'Shows concern for others, reflected by kind gestures, active listening and interpersonal warmth.';
      break;
    case 'Authenticity':
      definition =
        'Conveys true internal values and beliefs consistently as opposed to actively managing impressions for self-beneficial goals.';
      break;
    case 'Self-Belief':
      definition =
        'Feels assured about own capabilities and potential; self-confidence.';
      break;
    case 'Emotion Recognition':
      definition =
        'The ability to accurately identify and interpret facial expressions and emotions of others.';
      break;
    default:
      break;
  }
  return definition;
};

const handleGetInterviewQuestions = (name) => {
  let question = '';
  switch (name) {
    case 'Learning Agility':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were required to learn
            and apply a new skill in a short period of time. What was the
            situation and the outcome?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What were the challenges you faced and what do
            you think you could have done better?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What strategies did you use and how effective
            do you think these strategies were?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different outcome (i.e., failed/succeeded to learn quickly)
            and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Processing Capacity':
      question = (
        <div className="mt-1 css-gba-ques">
          No interview questions applicable for higher Cognition traits
        </div>
      );
      break;
    case 'Processing Consistency':
      question = (
        <div className="mt-1 css-gba-ques">
          No interview questions applicable for higher Cognition traits
        </div>
      );
      break;
    case 'Processing Speed':
      question = (
        <div className="mt-1 css-gba-ques">
          No interview questions applicable for higher Cognition traits
        </div>
      );
      break;
    case 'Executive Function':
      question = (
        <div className="mt-1 css-gba-ques">
          No interview questions applicable for higher Cognition traits
        </div>
      );
      break;
    case 'Concentration':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you have had to maintain
            focus on a task for an extended period of time in a distracting
            environment. What was the situation and the outcome?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What were the challenges you faced and what do
            you think you could have done better?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What strategies did you use and how effective
            do you think these strategies were?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different outcome (i.e., failed/succeeded to maintain focus)
            and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Performance Under Pressure':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were working on a
            challenging task and there was a lot of pressure. What was the
            situation and the outcome?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What challenges did you face and how did you
            attempt to overcome them?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: How difficult was it for you to manage the
            situation and how was your performance affected?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different outcome (i.e., performance was affected/unaffected
            by pressure) and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Ownership and Responsibility':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you made a mistake. What
            was the situation and what did you do after that?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Who else did you involve in that situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What do you think you could have done better
            in that situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: If you were to face another similar situation
            again, would you choose to correct the mistake yourself or would you
            seek the help of others to do so? Explain your choice.
          </div>
        </div>
      );
      break;
    case 'Determination':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time where you were faced with a
            difficult task or project, which would last over an extended period
            of time. What was the situation and what was the outcome?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What were the challenges you faced and what do
            you think you could have done better?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: How did you remain motivated?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you where
            you had a totally different outcome (i.e., managed/failed to
            complete a difficult project) and what do you think contributed to
            the difference?
          </div>
        </div>
      );
      break;
    case 'Sensitivity To Loss':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you took on a project in
            which the outcomes would affect you significantly. What was the
            situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How did you decide on the best approach?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What were some of the consequences that you
            took into consideration?
          </div>
          <div className="mt-1 css-gba-ques">
            {' '}
            Interview Question 3: What were some of the negative consequences
            that you could face in the project and did those negative
            consequences affect you? How so?
          </div>
        </div>
      );
      break;
    case 'Resilience':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you faced a setback when
            working on a challenging task. What was the situation and what did
            you do after the incident?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How did you recover from the setback?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What were your takeaways from that situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different outcome (i.e., took a much shorter/longer time to
            recover from the setback) and what do you think contributed to the
            difference?
          </div>
        </div>
      );
      break;
    case 'Sensitivity To Reward':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you have been motivated to
            achieve a challenging target or goal. What was the situation and
            what was the outcome?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What motivated you to achieve the target or
            goal? How close to the target did you get?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What types of rewards typically motivate
            yourself to get closer or even exceed the target?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: If you were to face another similar situation
            again, but this time the motivators you mentioned earlier are
            changed (i.e., extrinsic motivators are included/excluded), do you
            think you will put in the same amount of effort in the situation?
            Why?
          </div>
        </div>
      );
      break;
    case 'Self-discipline':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time you undertook a project that
            demanded personal organisation and a disciplined approach to work.
            What was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How do you typically organise your tasks to
            meet deadlines?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What do you typically do to ensure consistency
            in the quality of your work?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you where
            you had a totally different outcome (i.e., managed/failed to be
            organised) and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Proactivity':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time where you started a new
            project/initiative. What was the project/initiative about and what
            did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How did you know what needed to be done and
            when?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: Elaborate in detail how you get started on the
            task - the process you went through to commence the task.
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Tell me about a time when you did not start on
            a task at the first instance. What was the situation and what do you
            think you could have done better?
          </div>
        </div>
      );
      break;
    case 'Innovation Potential':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were engaged in a task
            where you could take a new, innovative approach or a tried and
            tested approach. What was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: If you were to face another similar situation
            again, would you change your approach? Please explain your decision.
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: (If candidate's response to previous question
            is No, the approach will not change) In what kinds of situations
            would you see yourself changing the approach?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: What would inform your decision (e.g., what
            kind of information would you need) on the approach to use?
          </div>
        </div>
      );
      break;
    case 'Rational Decision-Making Style':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time where you had to make an
            objective decision in a situation that had a potentially huge impact
            on others. What was the situation and how did you make the final
            decision?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What information did you gather and consider
            to make the decision?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What strategies did you use and how effective
            do you think these strategies were?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Imagine a situation whereby you were tasked to
            make an important decision and you instinctively already had a
            decision in mind. What would you do in that situation and how would
            you go about making a final decision?
          </div>
        </div>
      );
      break;
    case 'Rational Decision Making Style':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time where you had to make an
            objective decision in a situation that had a potentially huge impact
            on others. What was the situation and how did you make the final
            decision?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What information did you gather and consider
            to make the decision?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What strategies did you use and how effective
            do you think these strategies were?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Imagine a situation whereby you were tasked to
            make an important decision and you instinctively already had a
            decision in mind. What would you do in that situation and how would
            you go about making a final decision?
          </div>
        </div>
      );
      break;
    case 'Managing Uncertainty':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when your schedule or plan was
            disrupted by unforeseen circumstances. What was the situation and
            what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Did you manage to overcome the situation and
            what strategies did you use?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What was the final outcome? What do you think
            you could have done better?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you where
            you had a totally different outcome (i.e., managed/failed to
            overcome the disruption) and what do you think contributed to the
            difference?
          </div>
        </div>
      );
      break;
    case 'Deliberation':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you had to make an
            important decision. What was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What information did you have to consider?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: How quickly did you come to your final
            decision?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Tell me about another time when you had to
            make a quick decision on something important. What was the situation
            and what did you do?
          </div>
        </div>
      );
      break;
    case 'Future Orientation':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time where you had to carefully
            consider the long-term impact of a decision you had to make. What
            was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What were the challenges you faced and what do
            you think you could have done better?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What information did you take into account and
            how did you come to the final decision?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you where
            you had a totally different outcome (i.e., managed/failed to
            consider the long-term impact of a decision) and what do you think
            contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Need For Structure':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were given a task with
            vague instructions on how to approach it. What was the situation and
            what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Did you achieve the end goal and how did you
            manage to do so?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What were the challenges you faced and what do
            you think you could have done better?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Which do you generally prefer - tasks that
            have little structure or tasks that have more structure? Explain
            your choice.
          </div>
        </div>
      );
      break;
    case 'Risk propensity':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you had to make a decision
            which involved a considerable amount of risk. What was the situation
            and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Did you end up taking the risk and what was
            the motivation that influenced your final choice?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What were the potential outcomes that could
            have resulted from the situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different decision (i.e., decided against/for taking the
            risk) and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Risk Propensity':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you had to make a decision
            which involved a considerable amount of risk. What was the situation
            and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Did you end up taking the risk and what was
            the motivation that influenced your final choice?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What were the potential outcomes that could
            have resulted from the situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different decision (i.e., decided against/for taking the
            risk) and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Risk-Propensity':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you had to make a decision
            which involved a considerable amount of risk. What was the situation
            and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Did you end up taking the risk and what was
            the motivation that influenced your final choice?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What were the potential outcomes that could
            have resulted from the situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different decision (i.e., decided against/for taking the
            risk) and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Creativity':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were asked to
            brainstorm for ideas. What was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What were the challenges you faced?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What strategies did you use and how effective
            do you think these strategies were?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: If you were to face another similar situation
            again, and you could choose between coming up with something
            entirely new versus something conventional, what would your choice
            be? Explain your choice.
          </div>
        </div>
      );
      break;
    case 'Curiosity':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you had to explore a new
            topic or subject area. What was the situation and how did you go
            about doing it?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What were the challenges you faced and what do
            you think you could have done better?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: What strategies did you use and how effective
            do you think these strategies were?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: If you were to face another similar situation
            again, but this time you actually can choose if you want to explore
            the new topic, would you take it up? Explain your choice.
          </div>
        </div>
      );
      break;
    case 'Novelty Seeking':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were working on a
            project and someone proposed a radically new idea that required you
            to step out of your comfort zone. What was the situation and what
            did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What were the risks involved in that
            situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: Did you adopt the idea and why?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            choice to step out of your comfort zone, but didn't do it. What made
            you decide on not stepping outside your comfort zone?
          </div>
        </div>
      );
      break;
    case 'Optimism':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you are working on a
            project and you faced some negative events that posed a challenge to
            the completion of the project. What was the situation and what were
            your thoughts when the negative events occurred?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How did you feel about meeting the goals of
            the project after the negative events occurred?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: Tell me about another time when you could not
            envision a positive outcome for a project you were working on. What
            was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Why did you think that the outcome would be
            negative?
          </div>
        </div>
      );
      break;
    case 'Self-Monitoring':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Think about a time when you belonged to a group which
            was important to you and you wanted to fit in, but you had a
            differing idea from others in the group. What was the situation and
            what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How did you decide whether or not to stand by
            your opinion?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: How did you gather clues about the situation
            and how did you react towards it?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different decision (i.e., decided against/for standing by
            your opinion) and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Self Monitoring':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Think about a time when you belonged to a group which
            was important to you and you wanted to fit in, but you had a
            differing idea from others in the group. What was the situation and
            what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How did you decide whether or not to stand by
            your opinion?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: How did you gather clues about the situation
            and how did you react towards it?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different decision (i.e., decided against/for standing by
            your opinion) and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Social Dominance':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time you were part of a group and a
            decision needed to be made, but the views of the group members
            varied. What was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Did you have your own views in that situation
            and what were they?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: Did you convey your views to the group and how
            did you do so?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: What was the final outcome?
          </div>
        </div>
      );
      break;
    case 'Sociability':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you had to work closely
            with others as part of a team to achieve a specific goal or
            objective, but there were no clearly defined roles within the team.
            What was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How did you feel working as part of that team
            and what role did you end up taking on?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: Tell me about another time where you had to
            work completely independently or alone on a project. What was the
            situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Which do you generally prefer - to work in a
            team or work independently? Explain your choice.
          </div>
        </div>
      );
      break;
    case 'Politeness':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when there was tension within a
            group you belong to. What was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Did you do anything to manage the situation,
            and if so, what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: How did you decide whether or not to step in
            and manage the situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: On hindsight, would you have done things
            differently? How and why?
          </div>
        </div>
      );
      break;
    case 'Altruism':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were faced with a
            situation whereby you could only either prioritise your own needs vs
            the needs of another person. What was the situation and what did you
            do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: What were the challenges you faced?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: How did you decide what the priority was?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: On hindsight, would you have done things
            differently? How and why?
          </div>
        </div>
      );
      break;
    case 'Authenticity':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you have stuck by your
            personal values even though it could have potentially held you back
            from a personal accomplishment. What was the situation and what did
            you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Why did you take the action you did?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: Tell me about another time when you needed to
            sacrifice your personal values in order to influence the outcome of
            a situation. What was the situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: What do you think contributed to the
            differences across the two situations?
          </div>
        </div>
      );
      break;
    case 'Self-Belief':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were tasked to take on
            and complete a challenging project or activity. What was the
            situation and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How confident were you in your abilities in
            that situation?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: Did you go to anyone to seek support or
            assurance to ensure the successful completion of the task? If so,
            who did you go to?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different feeling (i.e., being less/more confident about
            yourself) and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Emotional Stability':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a time when you were in a stressful
            situation. What was the situation and how did you feel?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: Did you do anything to manage your emotions,
            and if so, what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: On hindsight, would you have done things
            differently? How and why?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you had a
            totally different outcome (i.e., emotions were managed well/poorly)
            and what do you think contributed to the difference?
          </div>
        </div>
      );
      break;
    case 'Emotion Recognition':
      question = (
        <div>
          <div className="mt-1 css-gba-ques">
            Main Question: Tell me about a situation whereby you noticed people
            getting emotional at something or someone. What was the situation
            and what did you do?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 1: How did you recognise the people's emotions?
            Was there any strategy used?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 2: How did you respond to the situation and how
            did you decide that was the most appropriate response?
          </div>
          <div className="mt-1 css-gba-ques">
            Interview Question 3: Think about another instance where you where
            you failed to notice or recognise people's emotions. What do you
            think contributed to that outcome?
          </div>
        </div>
      );
      break;
    default:
      break;
  }
  return question;
};

export {
  handleGetBehavioralIndicators,
  handleGetTraitDefinition,
  handleGetInterviewQuestions,
};
