import React, { Component, Fragment } from 'react';
import PublicHeaderMock from './PublicHeaderMock';

//Common UI Components
import BatchCounts from '../../components/private/BatchCounts';
import NivoBarChart from '../../components/common/NivoBarChart';
import NivoPieChart from '../../components/common/NivoPieChart';
import GenericTabs from '../../components/common/GenericTabs';
import DashboardAnalytics from '../../components/private/DashboardAnalytics';
import DashboardMock from '../../containers/public/DashboardMock';
import BatchCountMock from '../../containers/public/BatchCountMock';

//Common packages
import { Tile } from 'carbon-components-react';
import './MockUi2.css';

class BatchInfMock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isSideNavExpanded: false,
    };
  }

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  handleTabClick = (tabIndex) => {
    this.setState({
      selectedTab: tabIndex,
    });
  };

  render() {
    const { history } = this.props;
    const { push } = history || {};
    const { selectedTab, isSideNavExpanded } = this.state;
    const analyticsInfo = {
      yoyComparision: [
        {
          key: 2017,
          interviewed: 240,
          offered: 100,
          accepted: 90,
        },
        {
          key: 2018,
          interviewed: 270,
          offered: 100,
          accepted: '95',
        },
        {
          key: 2020,
          interviewed: 280,
          offered: 100,
          accepted: '96',
        },
        {
          key: 2021,
          interviewed: 250,
          offered: 100,
          accepted: 94,
        },
      ],
      ethnicityData: [
        {
          id: 'Offered',
          color: '#4A8DDC',
          data: [
            {
              y: 70,
              x: 'Chinese',
              color: '#4A8DDC',
            },
            {
              y: 23,
              x: 'Malay',
              color: '#4A8DDC',
            },
            {
              y: 5,
              x: 'Indian',
              color: '#4A8DDC',
            },
            {
              y: 2,
              x: 'Others',
              color: '#4A8DDC',
            },
          ],
        },
      ],
    };
    return (
      <div>
        <PublicHeaderMock
          onClickSideNavExpand={this.onClickSideNavExpand}
          isSideNavExpanded={isSideNavExpanded}
          push={push}
          history={history}
        />
        <div className="bx--grid mt-5 xpa--ht-70">
          <GenericTabs
            selected={selectedTab}
            tabContent={[
              {
                label: 'Current Batch (2021)',
                value: '',
                onClick: () => {
                  this.handleTabClick(0);
                },
              },
              {
                label: '2020',
                value: '',
                onClick: () => {
                  this.handleTabClick(1);
                },
              },
              {
                label: '2019',
                value: '',
                onClick: () => {
                  this.handleTabClick(2);
                },
              },
            ]}
          />
          <div className="mt-2 mb-2">
            <DashboardAnalytics
              analyticsInfo={analyticsInfo}
              showYoyComparision
            />
          </div>
          {selectedTab !== null && (
            <Fragment>
              <div className="h5 f-w-b bx--ty-gamma font-weight-light mb-2 mt-2">
                Daily Report
              </div>
              <Tile>
                <div className="bx--row align-items-center">
                  <div className="bx--col-lg-3">
                    <div>
                      <div className="xpa-name-overflow xpa-link mt-1">
                        <h4>
                          Batch{' '}
                          {selectedTab === 0
                            ? '2021'
                            : selectedTab === 1
                            ? '2020'
                            : '2019'}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="bx--col-lg-8">
                    <BatchCounts
                      push={push}
                      offered={100}
                      interviewed={192}
                      accepted={85}
                      acceptanceRate={85}
                      total={192}
                      batchId={1}
                      redirectToMock
                    />
                  </div>
                </div>
              </Tile>

              <Tile className="mt-2">
                <div className="mt-2 bx--row">
                  <div className="bx--col-lg-4">
                    <div className=" graphs-height">
                      <NivoPieChart
                        margin={{ top: 15, right: 10, bottom: 35, left: 10 }}
                        heading="2021"
                        headingClass="text-center h5 f-w-b"
                        data={[
                          {
                            id: 'Not Offered',
                            label: 'Not Offered',
                            value: 92,
                          },
                          {
                            id: 'Accepted',
                            label: 'Accepted',
                            value: 85,
                          },
                          {
                            id: 'Not Accepted',
                            label: 'Not Accepted',
                            value: 7,
                          },
                        ]}
                        legendTranslateY={106}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="css-border-graph">
                        <div className="bx--row">
                          <div className="bx--col-lg-9 bx--col-md-6 text-right">
                            Total Interviewed:
                          </div>{' '}
                          <div>192</div>
                        </div>
                        <div className="bx--row mt-2">
                          <div className="bx--col-lg-9 bx--col-md-6 text-right">
                            Acceptance Rate:
                          </div>{' '}
                          <div>85%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bx--col-lg-4 mt-3">
                    <div className="graphs-height">
                      <NivoPieChart
                        margin={{ top: 15, right: 10, bottom: 35, left: 10 }}
                        heading="2020 (corresponding stage)"
                        headingClass="text-center h5 f-w-b"
                        data={[
                          {
                            id: 'Not Offered',
                            label: 'Not Offered',
                            value: 145,
                          },
                          {
                            id: 'Accepted',
                            label: 'Accepted',
                            value: 44,
                          },
                          {
                            id: 'Not Accepted',
                            label: 'Not Accepted',
                            value: 1,
                          },
                        ]}
                        legendTranslateY={106}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="css-border-graph">
                        <div className="bx--row">
                          <div className="bx--col-lg-9 bx--col-md-6 text-right">
                            Total Interviewed:
                          </div>{' '}
                          <div>135</div>
                        </div>
                        <div className="bx--row mt-2">
                          <div className="bx--col-lg-9 bx--col-md-6 text-right">
                            Acceptance Rate:
                          </div>{' '}
                          <div>97%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bx--col-lg-4 mt-3">
                    <div className="graphs-height">
                      <NivoPieChart
                        margin={{ top: 15, right: 10, bottom: 35, left: 10 }}
                        heading="2020 (total)"
                        headingClass="text-center h5 f-w-b"
                        data={[
                          {
                            id: 'Not Offered',
                            label: 'Not Offered',
                            value: 182,
                          },
                          {
                            id: 'Accepted',
                            label: 'Accepted',
                            value: 92,
                          },
                          {
                            id: 'Not Accepted',
                            label: 'Not Accepted',
                            value: 8,
                          },
                        ]}
                        legendTranslateY={106}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="css-border-graph">
                        <div className="bx--row">
                          <div className="bx--col-lg-9 bx--col-md-6 text-right">
                            Total Interviewed:
                          </div>{' '}
                          <div>280</div>
                        </div>
                        <div className="bx--row mt-2">
                          <div className="bx--col-lg-9 bx--col-md-6 text-right">
                            Acceptance Rate:
                          </div>{' '}
                          <div>92%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tile>
              {/* <Tile className="mt-2">
                <div className="bx--col-lg-12 bx--col-md-12">
                  <div className="batch-info-g-h">
                    <NivoBarChart
                      heading="Scholarship scheme by Accepted / Pending / Declined"
                      data={[
                        {
                          key: 'PSC Scholarship',
                          accepted: 15,
                          declined: 2,
                          pending: 5,
                        },
                        {
                          key: 'Singapore Armed Forces',
                          accepted: 15,
                          declined: 1,
                          pending: 2,
                        },
                        {
                          key: 'Singapore Police Force',
                          accepted: 15,
                          declined: 1,
                          pending: 1,
                        },
                        {
                          key: 'Engineering',
                          accepted: 10,
                          declined: 1,
                          pending: 1,
                        },
                        {
                          key: 'Foreign Service',
                          accepted: 10,
                          declined: 0,
                          pending: 1,
                        },
                        {
                          key: 'Legal Service',
                          accepted: 5,
                          declined: 0,
                          pending: 0,
                        },
                        {
                          key: 'Medicine / Dentistry',
                          accepted: 5,
                          declined: 0,
                          pending: 0,
                        },
                        {
                          key: 'Public Finance',
                          accepted: 5,
                          declined: 0,
                          pending: 0,
                        },
                        {
                          key: 'Teaching Service',
                          accepted: 5,
                          declined: 0,
                          pending: 0,
                        },
                      ]}
                      enableLabel
                      enableGridY={false}
                      enableGridX={false}
                      margin={{ top: 0, right: 30, bottom: 100, left: 20 }}
                      keys={['accepted', 'pending', 'declined']}
                      legendDirection="bottom-right"
                      axisTop={null}
                      showLegend
                      enableGridY={false}
                      enableGridX={false}
                      maxValue={25}
                      layout="horizontal"
                      groupMode="stacked"
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -34,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={null}
                    />
                  </div>
                </div>
              </Tile> */}
              {/* <Tile className="mt-2">
                <div className="bx--row">
                  <div className="bx--col-lg-6 bx--col-md-6">
                    <div className="batch-info-g-h">
                      <NivoBarChart
                        heading="Candidate shortfall by scholarship scheme"
                        subHeaderLegend="(Target - Accepted)"
                        data={[
                          {
                            key: 'PSC Scholarship',
                            shortfall: 20,
                            color: 'green',
                          },
                          {
                            key: 'Singapore Armed Forces',
                            shortfall: 5,
                            color: '#4A8DDC',
                          },
                          {
                            key: 'Singapore Police Force',
                            shortfall: 20,
                            color: '#4A8DDC',
                          },
                          {
                            key: 'Engineering',
                            shortfall: 10,
                            color: '#4A8DDC',
                          },
                          {
                            key: 'Foreign Service',
                            shortfall: 10,
                            color: '#4A8DDC',
                          },
                          {
                            key: 'Legal Service',
                            shortfall: 15,
                            color: '#4A8DDC',
                          },
                          {
                            key: 'Medicine / Dentistry',
                            shortfall: 15,
                            color: '#4A8DDC',
                          },
                          {
                            key: 'Public Finance',
                            shortfall: 15,
                            color: '#4A8DDC',
                          },
                          {
                            key: 'Teaching Service',
                            shortfall: 3,
                            color: '#4A8DDC',
                          },
                        ]}
                        enableLabel
                        enableGridY={false}
                        enableGridX={false}
                        margin={{ top: 5, right: 30, bottom: 100, left: 150 }}
                        keys={['shortfall']}
                        legendDirection="bottom-right"
                        axisTop={null}
                        showLegend
                        targetNeg
                        maxValue={25}
                        layout="horizontal"
                        groupMode="stacked"
                        axisRight={null}
                        axisBottom={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: -34,
                          legend: '',
                          legendPosition: 'middle',
                          legendOffset: 32,
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: '',
                          legendPosition: 'middle',
                          legendOffset: -40,
                        }}
                      />
                    </div>
                  </div>
                  <div className="bx--col-lg-6 bx--col-md-6">
                    <div className="batch-info-g-h">
                      <NivoBarChart
                        heading="Candidate shortfall by country"
                        subHeaderLegend="(Target - Accepted)"
                        data={[
                          {
                            key: 'Singapore',
                            shortfall: 27,
                          },
                          {
                            key: 'United Kingdom',
                            shortfall: 30,
                          },
                          {
                            key: 'USA',
                            shortfall: 20,
                          },
                          {
                            key: 'Others',
                            shortfall: 24,
                          },
                        ]}
                        enableLabel
                        margin={{ top: 0, right: 30, bottom: 100, left: 150 }}
                        keys={['shortfall']}
                        legendDirection="bottom-right"
                        axisTop={null}
                        showLegend
                        enableGridY={false}
                        enableGridX={false}
                        maxValue={35}
                        layout="horizontal"
                        groupMode="stacked"
                        axisRight={null}
                        axisBottom={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: -34,
                          legend: '',
                          legendPosition: 'middle',
                          legendOffset: 32,
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: '',
                          legendPosition: 'middle',
                          legendOffset: -40,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Tile> */}
              <Tile className="mt-2">
                <h4 className="mt-2 mb-2">
                  Overall Exercise Statistics (Year-on-year comparison)
                </h4>
                <table className="css-table-batch-info css-overall-excersises-w">
                  <thead>
                    <th className="text-left css-overall-w css-batch-metric-border-r css-total-f-sz pt-2 pb-2 pl-1 css-batch-info-head">
                      Status
                    </th>
                    <th className="css-overall-w css-batch-metric-border-r css-total-f-sz css-batch-info-head">
                      2021
                    </th>
                    <th className="css-overall-w css-batch-metric-border-r css-total-f-sz css-batch-info-head">
                      2020 (Corresponding Stage)
                    </th>
                    <th className="css-overall-w css-batch-metric-border-r css-total-f-sz css-batch-info-head">
                      2020 (total)
                    </th>
                  </thead>
                  <tbody className="css-table-batch-info">
                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w">
                        Interviewed
                      </td>
                      <td className="css-batch-metric-border-r">192</td>
                      <td className="css-batch-metric-border-r">135</td>
                      <td>280</td>
                    </tr>
                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w">
                        Offered PSC Scholarship (Undergraduate, Mid-term,
                        Masters)
                      </td>
                      <td className="align-mid css-batch-metric-border-r">
                        100
                      </td>
                      <td className="align-mid css-batch-metric-border-r">
                        45
                      </td>
                      <td className="align-mid">100</td>
                    </tr>
                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w">
                        Accepted
                      </td>
                      <td className="css-batch-metric-border-r">85</td>
                      <td className="css-batch-metric-border-r">44</td>
                      <td>92</td>
                    </tr>
                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w">
                        Acceptance Rate (%)
                      </td>
                      <td className="css-batch-metric-border-r">85</td>
                      <td className="css-batch-metric-border-r">97</td>
                      <td>92</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </Tile>
              <Tile className="mt-2">
                <h4 className="mt-2 mb-2">By Scholarship Scheme</h4>
                <table className="css-table-batch-info w-100">
                  <thead>
                    <th className="text-left css-table-head css-batch-metric-border-r"></th>

                    <th className="css-table-head" colSpan={8}>
                      2021 batch
                    </th>
                    <th
                      rowSpan={2}
                      className="css-batch-table-execercise-w css-table-head"></th>
                  </thead>
                  <thead>
                    <th
                      className="css-metric-table-head text-left css-batch-metric-border-r"
                      rowSpan={2}>
                      Scheme
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head css-table-head">
                      Target
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Offered
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Accepted
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Declined
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Pending
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Acceptance Rate (%)
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      2nd Rounder
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      2nd Rounder- KIV
                    </th>
                    <th className="css-metric-table-head">2020 Awarded</th>
                  </thead>
                  <tbody className="css-table-batch-info">
                    <tr>
                      <td className="text-left css-total-f-w css-batch-metric-border-r">
                        Public Administration
                      </td>
                      <td className="css-total-f-w">35</td>
                      <td className="css-total-f-w">15</td>
                      <td className="css-total-f-w">15</td>
                      <td className="css-total-f-w">2</td>
                      <td className="css-total-f-w">5</td>
                      <td></td>
                      <td className="css-total-f-w">5</td>
                      <td className="css-total-f-w">2</td>
                      <td className="css-total-f-w">15</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        PSC Scholarship
                      </td>
                      <td>35</td>
                      <td>15</td>
                      <td>15</td>
                      <td>2</td>
                      <td>5</td>
                      <td></td>
                      <td>5</td>
                      <td>2</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w css-batch-metric-border">
                        Uniformed Services
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        55
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        30
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        30
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        2
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        3
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        2
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        25
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Singapore Armed Forces
                      </td>
                      <td>20</td>
                      <td>15</td>
                      <td>15</td>
                      <td>1</td>
                      <td>2</td>
                      <td></td>
                      <td>2</td>
                      <td>1</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Singapore Police Force
                      </td>
                      <td>35</td>
                      <td>15</td>
                      <td>15</td>
                      <td>1</td>
                      <td>1</td>
                      <td></td>
                      <td>3</td>
                      <td>1</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td className="text-left css-total-f-w css-batch-metric-border-r css-batch-metric-border">
                        Professional Services
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        102
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        55
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        40
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        2
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        1
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        1
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        51
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Engineering
                      </td>
                      <td>20</td>
                      <td>10</td>
                      <td>10</td>
                      <td>1</td>
                      <td>1</td>
                      <td></td>
                      <td>1</td>
                      <td>1</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Foreign Service
                      </td>
                      <td>20</td>
                      <td>10</td>
                      <td>10</td>
                      <td>0</td>
                      <td>1</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Legal Service
                      </td>
                      <td>20</td>
                      <td>5</td>
                      <td>5</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Medicine / Dentistry
                      </td>
                      <td>20</td>
                      <td>5</td>
                      <td>5</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Public Finance
                      </td>
                      <td>20</td>
                      <td>15</td>
                      <td>5</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Teaching Service
                      </td>
                      <td>2</td>
                      <td>10</td>
                      <td>5</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>1</td>
                    </tr>
                    <tr className="css-total-bg">
                      <td className="text-left css-total-f-sz css-batch-metric-border css-batch-metric-border-r">
                        Total
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        192
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        100
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        85
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        10
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        15
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        91
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left css-total-f-w css-batch-metric-border-r css-batch-metric-border">
                        PSC Master's Scholarship
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        91
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tile>
              <Tile className="mt-2">
                <h4 className="mt-2 mb-2">By Country</h4>
                <table className="css-table-batch-info w-100">
                  <thead>
                    <th className="text-left css-table-head css-batch-metric-border-r"></th>

                    <th className="css-table-head" colSpan={8}>
                      2021 excercise
                    </th>
                    <th
                      rowSpan={2}
                      className="css-batch-table-execercise-w css-table-head"></th>
                  </thead>
                  <thead>
                    <th
                      className="css-metric-table-head text-left css-batch-metric-border-r"
                      rowSpan={2}>
                      Country
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head css-table-head">
                      Target
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Offered
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Accepted
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Declined
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Pending
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Acceptance Rate (%)
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      2nd Rounder
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      2nd Rounder- KIV
                    </th>
                    <th className="css-metric-table-head">2020 Awarded</th>
                  </thead>
                  <tbody className="css-table-batch-info">
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Singapore
                      </td>
                      <td>52</td>
                      <td>25</td>
                      <td>25</td>
                      <td>0</td>
                      <td>5</td>
                      <td></td>
                      <td>5</td>
                      <td>3</td>
                      <td>25</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        United Kingdom
                      </td>
                      <td>50</td>
                      <td>29</td>
                      <td>20</td>
                      <td>2</td>
                      <td>3</td>
                      <td></td>
                      <td>3</td>
                      <td>2</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        USA
                      </td>
                      <td>50</td>
                      <td>30</td>
                      <td>30</td>
                      <td>2</td>
                      <td>0</td>
                      <td></td>
                      <td>2</td>
                      <td>0</td>
                      <td>20</td>
                    </tr>

                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Others
                      </td>
                      <td>40</td>
                      <td>16</td>
                      <td>16</td>
                      <td>1</td>
                      <td>2</td>
                      <td></td>
                      <td>5</td>
                      <td>0</td>
                      <td>26</td>
                    </tr>

                    <tr className="css-total-bg">
                      <td className="text-left css-total-f-sz css-batch-metric-border css-batch-metric-border-r">
                        Total
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        192
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        100
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        85
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        10
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        15
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        91
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tile>
              <Tile className="mt-2">
                <h4 className="mt-2 mb-2">By Course</h4>
                <table className="css-table-batch-info w-100">
                  <thead>
                    <th className="text-left css-table-head css-batch-metric-border-r"></th>

                    <th className="css-table-head" colSpan={8}>
                      2021 excercise
                    </th>
                    <th
                      rowSpan={2}
                      className="css-batch-table-execercise-w css-table-head"></th>
                  </thead>
                  <thead>
                    <th
                      className="css-metric-table-head text-left css-batch-metric-border-r"
                      rowSpan={2}>
                      Course
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head css-table-head">
                      Target
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Offered
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Accepted
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Declined
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Pending
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Acceptance Rate (%)
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      2nd Rounder
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      2nd Rounder- KIV
                    </th>
                    <th className="css-metric-table-head">2020 Awarded</th>
                  </thead>
                  <tbody className="css-table-batch-info">
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Humanities Social Sciences
                      </td>
                      <td className="align-mid" rowSpan={9}>
                        N.A.
                      </td>
                      <td>16</td>
                      <td>16</td>
                      <td>2</td>
                      <td>2</td>
                      <td></td>
                      <td>2</td>
                      <td>2</td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Liberal Arts
                      </td>
                      {/* <td></td> */}
                      <td>20</td>
                      <td>20</td>
                      <td>0</td>
                      <td>2</td>
                      <td></td>
                      <td>2</td>
                      <td>3</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Science / Mathematics
                      </td>
                      {/* <td></td> */}
                      <td>25</td>
                      <td>25</td>
                      <td>0</td>
                      <td>2</td>
                      <td></td>
                      <td>2</td>
                      <td>0</td>
                      <td>25</td>
                    </tr>

                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Engineering & ICT
                      </td>
                      {/* <td></td> */}
                      <td>10</td>
                      <td>10</td>
                      <td>2</td>
                      <td>2</td>
                      <td></td>
                      <td>2</td>
                      <td>0</td>
                      <td>10</td>
                    </tr>

                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Law
                      </td>
                      {/* <td></td> */}
                      <td>2</td>
                      <td>2</td>
                      <td>1</td>
                      <td>1</td>
                      <td></td>
                      <td>2</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>

                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Economics / PPE
                      </td>
                      {/* <td></td> */}
                      <td>7</td>
                      <td>7</td>
                      <td>0</td>
                      <td>1</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Finance/Business/Accountancy
                      </td>
                      {/* <td></td> */}
                      <td>8</td>
                      <td>2</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Medicine / Dentistry
                      </td>
                      {/* <td></td> */}
                      <td>2</td>
                      <td>2</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Others
                      </td>
                      {/* <td></td> */}
                      <td>10</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>2</td>
                      <td>0</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Pending
                      </td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr className="css-total-bg">
                      <td className="text-left css-total-f-sz css-batch-metric-border css-batch-metric-border-r">
                        Total
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        192
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        100
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        85
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        10
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        15
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        91
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tile>
              <Tile className="mt-2">
                <h4 className="mt-2 mb-2">By School</h4>
                <table className="css-table-batch-info w-100">
                  <thead>
                    <th
                      className="css-metric-table-head text-left css-batch-metric-border-r"
                      rowSpan={2}>
                      Scheme
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head css-table-head">
                      Interviewed
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Offered
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Accepted
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Declined
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Pending
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      Acceptance Rate (%)
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      2nd Rounder
                    </th>
                    <th className="css-batch-table-execercise-w css-table-head">
                      2nd Rounder- KIV
                    </th>
                    <th className="css-metric-table-head css-batch-table-execercise-w">
                      2020 Awarded
                    </th>
                  </thead>
                  <tbody className="css-table-batch-info">
                    <tr>
                      <td className="text-left css-total-f-w css-batch-metric-border-r">
                        HCI/RI
                      </td>
                      <td className="css-total-f-w">102</td>
                      <td className="css-total-f-w">60</td>
                      <td className="css-total-f-w">45</td>
                      <td className="css-total-f-w">3</td>
                      <td className="css-total-f-w">10</td>
                      <td></td>
                      <td className="css-total-f-w">10</td>
                      <td className="css-total-f-w">5</td>
                      <td className="css-total-f-w">40</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        HCI
                      </td>
                      <td>50</td>
                      <td>52</td>
                      <td>22</td>
                      <td>2</td>
                      <td>5</td>
                      <td></td>
                      <td>5</td>
                      <td>2</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        RI
                      </td>
                      <td>35</td>
                      <td>32</td>
                      <td>23</td>
                      <td>1</td>
                      <td>5</td>
                      <td></td>
                      <td>5</td>
                      <td>3</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w css-batch-metric-border">
                        Other IP/Specialised Schools
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        37
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        20
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        20
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        20
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        ACS (I)
                      </td>
                      <td>12</td>
                      <td>4</td>
                      <td>4</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        DHS
                      </td>
                      <td>3</td>
                      <td>2</td>
                      <td>2</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        EJC
                      </td>
                      <td>2</td>
                      <td>2</td>
                      <td>2</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        NJC
                      </td>
                      <td>6</td>
                      <td>2</td>
                      <td>2</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>1</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        NUSHS
                      </td>
                      <td>4</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        RVHS
                      </td>
                      <td>3</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        SJI
                      </td>
                      <td>3</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        SOTA
                      </td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        SSS
                      </td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        TJC
                      </td>
                      <td>2</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>

                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w css-batch-metric-border">
                        Non-IP Schools
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        25
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        13
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        13
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        2
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        21
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        VJC
                      </td>
                      <td>5</td>
                      <td>4</td>
                      <td>4</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        ACJC
                      </td>
                      <td>4</td>
                      <td>1</td>
                      <td>1</td>
                      <td>2</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        ASRJC
                      </td>
                      <td>2</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        CJC
                      </td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        IJC
                      </td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        JPJC
                      </td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        MI
                      </td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        NYJC
                      </td>
                      <td>3</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        SAJC
                      </td>
                      <td>2</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        TMJC
                      </td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        YJC
                      </td>
                      <td>2</td>
                      <td>2</td>
                      <td>2</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>

                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w css-batch-metric-border">
                        Other Institutions
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        9
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        4
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        4
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        SJI (International)
                      </td>
                      <td>6</td>
                      <td>2</td>
                      <td>2</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Others
                      </td>
                      <td>3</td>
                      <td>2</td>
                      <td>2</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>3</td>
                    </tr>

                    <tr>
                      <td className="text-left css-batch-metric-border-r css-total-f-w css-batch-metric-border">
                        Polytechnics
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        19
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        3
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        3
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        0
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Nanyang
                      </td>
                      <td>2</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Ngee Ann
                      </td>
                      <td>3</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Republic
                      </td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Singapore
                      </td>
                      <td>9</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td className="text-right css-batch-metric-border-r">
                        Temasek
                      </td>
                      <td>4</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td></td>
                      <td>0</td>
                      <td>0</td>
                      <td>1</td>
                    </tr>

                    <tr className="css-total-bg">
                      <td className="text-left css-total-f-sz css-batch-metric-border css-batch-metric-border-r">
                        Total
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        192
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        100
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        85
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        10
                      </td>
                      <td className="css-total-f-w css-batch-metric-border"></td>
                      <td className="css-total-f-w css-batch-metric-border">
                        15
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        5
                      </td>
                      <td className="css-total-f-w css-batch-metric-border">
                        91
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tile>
              {/* <Tile className="mt-2">
                <div className="batch-info-g-h">
                  <NivoBarChart
                    heading="By Country"
                    data={[
                      {
                        key: 'Singapore',
                        target: 65,
                        offered: 35,
                        accepted: 35,
                        declined: 5,
                        pending: 5,
                        '2nd rounder': 10,
                        '2nd rounder kiv': 10,
                        '2020 awarded': 35,
                      },
                      {
                        key: 'United Kingdom',
                        target: 60,
                        offered: 29,
                        accepted: 29,
                        declined: 10,
                        pending: 10,
                        '2nd rounder': 20,
                        '2nd rounder kiv': 20,
                        '2020 awarded': 20,
                      },
                      {
                        key: 'USA',
                        target: 62,
                        offered: 30,
                        accepted: 30,
                        declined: 6,
                        pending: 9,
                        '2nd rounder': 20,
                        '2nd rounder kiv': 20,
                        '2020 awarded': 20,
                      },
                      {
                        key: 'Others',
                        target: 63,
                        offered: 16,
                        accepted: 16,
                        declined: 10,
                        pending: 10,
                        '2nd rounder': 16,
                        '2nd rounder kiv': 16,
                        '2020 awarded': 16,
                      },
                    ]}
                    enableLabel
                    margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                    keys={[
                      'target',
                      'offered',
                      'accepted',
                      'declined',
                      'pending',
                      '2nd rounder',
                      '2nd rounder kiv',
                      '2020 awarded',
                    ]}
                    legendDirection="bottom-right"
                    axisTop={null}
                    showLegend
                    layout="horizontal"
                    groupMode="stacked"
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -34,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                  />
                </div>
              </Tile>
              <Tile className="mt-2">
                <div className="batch-info-g-h">
                  <NivoBarChart
                    heading="By Course"
                    data={[
                      {
                        key: 'Humanities Social Sciences',
                        offered: 16,
                        accepted: 16,
                        declined: 2,
                        pending: 2,
                        '2nd rounder': 16,
                        '2nd rounder kiv': 16,
                        '2020 awarded': 16,
                      },
                      {
                        key: 'Liberal Arts',
                        offered: 20,
                        accepted: 20,
                        declined: 0,
                        pending: 0,
                        '2nd rounder': 20,
                        '2nd rounder kiv': 20,
                        '2020 awarded': 20,
                      },
                      {
                        key: 'Science / Mathematics',
                        offered: 25,
                        accepted: 25,
                        declined: 0,
                        pending: 0,
                        '2nd rounder': 20,
                        '2nd rounder kiv': 20,
                        '2020 awarded': 25,
                      },
                      {
                        key: 'Engineering & ICT',
                        offered: 10,
                        accepted: 10,
                        declined: 5,
                        pending: 5,
                        '2nd rounder': 10,
                        '2nd rounder kiv': 10,
                        '2020 awarded': 10,
                      },
                      {
                        key: 'Law',
                        offered: 2,
                        accepted: 2,
                        declined: 10,
                        pending: 10,
                        '2nd rounder': 2,
                        '2nd rounder kiv': 2,
                        '2020 awarded': 2,
                      },
                      {
                        key: 'Economics / PPE',
                        offered: 7,
                        accepted: 7,
                        declined: 5,
                        pending: 5,
                        '2nd rounder': 5,
                        '2nd rounder kiv': 6,
                        '2020 awarded': 7,
                      },
                      {
                        key: 'Finance/Business/Accountancy',
                        offered: 8,
                        accepted: 8,
                        declined: 5,
                        pending: 5,
                        '2nd rounder': 8,
                        '2nd rounder kiv': 8,
                        '2020 awarded': 7,
                      },
                      {
                        key: 'Medicine / Dentistry',
                        offered: 2,
                        accepted: 2,
                        declined: 8,
                        pending: 8,
                        '2nd rounder': 2,
                        '2nd rounder kiv': 2,
                        '2020 awarded': 2,
                      },
                      {
                        key: 'Others',
                        offered: 10,
                        accepted: 10,
                        declined: 5,
                        pending: 5,
                        '2nd rounder': 10,
                        '2nd rounder kiv': 10,
                        '2020 awarded': 10,
                      },
                    ]}
                    enableLabel
                    margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                    keys={[
                      'target',
                      'offered',
                      'accepted',
                      'declined',
                      'pending',
                      '2nd rounder',
                      '2nd rounder kiv',
                      '2020 awarded',
                    ]}
                    legendDirection="bottom-right"
                    axisTop={null}
                    showLegend
                    layout="horizontal"
                    groupMode="stacked"
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -34,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                  />
                </div>
              </Tile> */}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default BatchInfMock;
