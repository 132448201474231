import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Tile,
  Button,
  InlineNotification,
  FileUploaderButton,
  Pagination,
  Select,
  SelectItem,
  RadioButton,
} from 'carbon-components-react';
import BackRow from '../../components/common/BackRow';
import 'react-datepicker/dist/react-datepicker.css';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import {
  uploadCandidateReportEmails,
  uploadPscPeerFeedBackReportEmails,
} from '../../actions/Emails';
import GenericTabs from '../../components/common/GenericTabs';
import SampleCandidateReportEmailsTemp from '../../assets/files/Candidate_Report_Emails_Template.xlsx';
import SampleFeedbackReportTemp from '../../assets/files/Feedback_Report_Email_Template.xlsx';
import GenericDataTable from '../../components/common/GenericDataTable';
import { getHighLevelReportLogs } from '../../actions/HighLevelReports';
import { baseUrl, v2BaseUrl } from '../../utils/config';
import moment from 'moment';
import NoContentBox from '../../components/common/NoContentBox';
import { lowerAndTrim } from '../../utils/helpers';
import { SelectionYearDropdown } from '../../components/common/SelectionYearDropdown';

const reportTypes = [
  { label: 'Candidate Report', value: 'CANDIDATE_REPORT' },
  { label: 'PSC Peer Feedback Report', value: 'PSC_PEER_FEEDBACK_REPORT' },
];

class CandidateReportEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      reportType: reportTypes?.[0]?.value,
      selReportTypeInUploadStat: reportTypes?.[0]?.value,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { currentYear } = user || {};
    this.setState({ selectionYear: currentYear });
  }

  handleTabsClick = (selectedTab) => {
    if (selectedTab == 1) this.handleGetApiReport();
    this.setState({
      selectedTab,
      successNotif: false,
      errorNotif: false,
    });
  };

  handleGetApiReport = () => {
    const { activePage, itemsPerPage, searchKey, selReportTypeInUploadStat } =
      this.state;
    const data = {
      activePage,
      itemsPerPage,
      searchKey,
      report_name: selReportTypeInUploadStat,
    };

    this.props.getHighLevelReportLogs(data);
  };

  handleUploadCandReportEmails = (element) => {
    const { uploadCandidateReportEmails, uploadPscPeerFeedBackReportEmails } =
      this.props;
    const { selectionYear, reportType } = this.state;
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('attachment', fileData[0]);
    currFile.append('selectionYear', selectionYear && Number(selectionYear));
    const action =
      reportType === 'CANDIDATE_REPORT'
        ? uploadCandidateReportEmails
        : uploadPscPeerFeedBackReportEmails;

    if (isFileAllowed) {
      action(currFile)
        .then((res) => {
          const { error, message } = res || {};
          if (res && !error && message) {
            this.setState({
              successNotif: true,
              errorNotif: false,
              notifMsg: message,
            });
          } else {
            this.setState({
              successNotif: false,
              errorNotif: true,
              errMsg:
                error?.message || message ||
                'An Error has ocurred while processing your request, Please try again later',
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
            successNotif: false,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          successNotif: false,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetApiReport();
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleToggleClick = (reportType) => {
    this.setState(
      {
        selReportTypeInUploadStat: reportType,
      },
      () => {
        this.handleGetApiReport();
      }
    );
  };

  render() {
    const {
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      selectedTab,
      activePage,
      selectionYear,
      reportType,
      selReportTypeInUploadStat,
    } = this.state || {};
    const { reportLogs, count, user } = this.props;
    const { currentYear } = user || {};

    let tabContent = [
      {
        label: 'Upload',
        value: 'upload',
        onClick: () => {
          this.handleTabsClick(0);
        },
      },
      {
        label: 'Upload Status',
        value: 'upload-status',
        onClick: () => {
          this.handleTabsClick(1);
        },
      },
    ];

    const tableHeaders = [
      {
        key: 'header_0',
        header: 'Uploaded BY',
      },
      {
        key: 'header_1',
        header: 'File Link',
      },
      {
        key: 'header_2',
        header: 'Status',
      },
      {
        key: 'header_3',
        header: 'Date',
      },
    ];

    if (selReportTypeInUploadStat === 'PSC_PEER_FEEDBACK_REPORT')
      tableHeaders.push({ key: 'header_4', header: 'Download Link' });

    const innerTableViewHeaders = [
      {
        key: 'header_0',
        header: 'Serial No',
      },
      {
        key: 'header_1',
        header: 'Total Records',
      },
      {
        key: 'header_2',
        header: 'Name',
      },
      {
        key: 'header_3',
        header: 'Is Email Sent',
      },
      {
        key: 'header_4',
        header: 'TO',
      },
      {
        key: 'header_5',
        header: 'CC',
      },
    ];

    if (selReportTypeInUploadStat === 'CANDIDATE_REPORT') {
      innerTableViewHeaders.push(
        {
          key: 'header_6',
          header: 'Included Files',
        },
        {
          key: 'header_7',
          header: 'Errored Files',
        }
      );
    }

    if (selReportTypeInUploadStat === 'PSC_PEER_FEEDBACK_REPORT') {
      innerTableViewHeaders.push(
        {
          key: 'header_8',
          header: 'Report File',
        },
        {
          key: 'header_9',
          header: 'Password',
        }
      );
    }

    return (
      <div>
        <BackRow
          paths={[
            { id: 'csc', name: 'ARS', href: '/app/csc' },
            {
              id: 'emails',
              name: 'Emails',
              href: '',
            },
          ]}
        />

        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />

        <div className="mb-3">
          <GenericTabs selected={selectedTab} tabContent={tabContent} />
        </div>

        {selectedTab === 0 && (
          <Tile>
            <div className="mt-2 w-50">
              <Select
                className="xpa-width"
                name="reportType"
                id="reportType"
                onChange={this.handleChange}
                labelText="Report Type"
                value={reportType}>
                <SelectItem text="Select" disabled key="0" />
                {reportTypes.map(({ label, value }) => {
                  return (
                    <SelectItem
                      text={label}
                      key={label}
                      id={label}
                      value={value}
                    />
                  );
                })}
              </Select>
            </div>

            <div>
              <SelectionYearDropdown
                currentYear={currentYear}
                selectionYear={selectionYear}
                handleChange={this.handleChange}
                mainClassName="mt-3 w-50"
                titleClassName="xpa-width"
              />
            </div>

            {reportType && (
              <div className="d-flex mt-3">
                <div className="text-right">
                  <FileUploaderButton
                    buttonLabel="Upload"
                    labelText="Upload"
                    buttonKind="primary"
                    accept={['.xlsx']}
                    disableLabelChanges
                    name="file"
                    onChange={(e) => this.handleUploadCandReportEmails(e)}
                  />
                  <div className="small mt-2">.xlsx format is allowed</div>
                </div>
                <div className="ml-2">
                  <Button
                    className="txt-dec-none"
                    kind="primary"
                    href={
                      reportType === 'CANDIDATE_REPORT'
                        ? SampleCandidateReportEmailsTemp
                        : SampleFeedbackReportTemp
                    }>
                    Download Template
                  </Button>
                </div>
              </div>
            )}
          </Tile>
        )}

        {selectedTab === 1 && (
          <div>
            <div className="d-flex">
              {reportTypes.map(({ label, value }, idx) => {
                return (
                  <div
                    className={`d-flex justify-content-left mt-2 mb-3 ${
                      idx > 0 ? 'ml-4' : ''
                    }`}>
                    <RadioButton
                      name={label}
                      labelText={label}
                      value={value}
                      onChange={() => this.handleToggleClick(value)}
                      checked={selReportTypeInUploadStat === value}
                    />
                  </div>
                );
              })}
            </div>
            <GenericDataTable
              searchable={false}
              onSearchInputChange={this.handleSearchSubmit}
              rows={
                reportLogs && Array.isArray(reportLogs) && reportLogs.length > 0
                  ? reportLogs.map((data, idx) => {
                      const {
                        reportdocumentsId,
                        userinfo,
                        uploadDate,
                        status,
                        location,
                        isAzureLocationValid,
                        additionalInfo,
                      } = data;
                      const { firstname, lastname } = userinfo || {};
                      const { zippedPDFs } = additionalInfo || {};

                      let downloadedUrl = `${v2BaseUrl}/commonHelper/file/${zippedPDFs?.password}`;
                      return {
                        isExpanded: false,
                        id: reportdocumentsId,
                        header_0: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Uploaded By:
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                {firstname} {lastname}
                              </div>
                            </div>
                          </div>
                        ),
                        header_1: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              File Link:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {isAzureLocationValid === true ? (
                                <Button kind="ghost" size="small">
                                  <a
                                    target="_blank"
                                    href={`${baseUrl}/reportdocuments/view?_=${new Date().getTime()}&location=${encodeURIComponent(
                                      location
                                    )}`}
                                    rel="noreferrer">
                                    View
                                  </a>
                                </Button>
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                        ),
                        header_2: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Status:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <div
                                className={`text-capitalize ${
                                  ['success', 'completed'].includes(
                                    lowerAndTrim(status)
                                  )
                                    ? 'bx--tag bx--tag--green text-capitalize'
                                    : status === 'inprogress'
                                    ? 'bx--tag bx--tag--yellow'
                                    : 'bx--tag bx--tag--red text-capitalize'
                                }`}>
                                {status === 'inprogress'
                                  ? 'In Progress'
                                  : status}
                              </div>
                            </div>
                          </div>
                        ),
                        header_3: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Date:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {moment(uploadDate).fromNow()}
                            </div>
                          </div>
                        ),
                        header_4: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Download Link:
                            </div>

                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6 ml-0 pl-0">
                              {zippedPDFs?.password ? (
                                <Button
                                  kind="ghost"
                                  size="small"
                                  className="ml-0 pl-0">
                                  <a
                                    className="ml-0 pl-0"
                                    target="_blank"
                                    href={downloadedUrl}
                                    rel="noreferrer">
                                    Download Zip File
                                  </a>
                                </Button>
                              ) : (
                                <div className="ml-5">-</div>
                              )}
                            </div>
                          </div>
                        ),
                      };
                    })
                  : []
              }
              headers={tableHeaders}
              expandable
              expandedRows={
                reportLogs &&
                Array.isArray(reportLogs) &&
                reportLogs.length > 0 &&
                reportLogs.map((data) => {
                  const { error, additionalInfo } = data || {};
                  const { records, invalidTestDatesInfo, pdfReports } =
                    additionalInfo || {};
                  const finalRecords =
                    selReportTypeInUploadStat === 'PSC_PEER_FEEDBACK_REPORT'
                      ? pdfReports
                      : records;

                  return (
                    <div className="mt-1 mx-4">
                      {error ? (
                        <div className="d-flex">
                          <div className="">Error Message:</div>
                          <div className="ml-2 invalid-msg small text-capitalize">
                            {error}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {finalRecords &&
                          Array.isArray(finalRecords) &&
                          finalRecords.length > 0 ? (
                            <GenericDataTable
                              searchable={false}
                              rows={finalRecords.map((data, idx) => {
                                const {
                                  serialNo,
                                  nric,
                                  totalRecords,
                                  emailDeliveryInfo,
                                  filesInfo,
                                  fileInfo,
                                  candidateName,
                                } = data;

                                const {
                                  isSent,
                                  to: toEmails,
                                  cc: ccEmails,
                                } = emailDeliveryInfo || {};

                                const {
                                  success: filesSuccessed,
                                  fail: filesFailed,
                                } = filesInfo || {};

                                const {
                                  filename,
                                  encryptedPassword,
                                  password,
                                } = fileInfo || {};

                                return {
                                  isExpanded: false,
                                  id: `${serialNo}-${nric}`,
                                  header_0: (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        Serial No:
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                          {serialNo}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                  header_1: (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        Total Records:
                                      </div>
                                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                        {totalRecords}
                                      </div>
                                    </div>
                                  ),
                                  header_2: (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        Name:
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                          {candidateName}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                  header_3: (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        Is Email Sent:
                                      </div>
                                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                        {isSent ? 'Yes' : 'No'}
                                      </div>
                                    </div>
                                  ),
                                  header_4: (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        TO:
                                      </div>
                                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                        {toEmails &&
                                        Array.isArray(toEmails) &&
                                        toEmails.length > 0 ? (
                                          <div>
                                            {toEmails?.map((itm) => {
                                              return (
                                                <div className="d-flex mr-2 flex-wrap">
                                                  {itm}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          '-'
                                        )}
                                      </div>
                                    </div>
                                  ),
                                  header_5: (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        CC:
                                      </div>
                                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                        {ccEmails &&
                                        Array.isArray(ccEmails) &&
                                        ccEmails.length > 0 ? (
                                          <div>
                                            {ccEmails?.map((itm) => {
                                              return (
                                                <div className="d-flex mr-2 flex-wrap">
                                                  {itm}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          '-'
                                        )}
                                      </div>
                                    </div>
                                  ),
                                  header_6: selReportTypeInUploadStat ===
                                    'CANDIDATE_REPORT' && (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        Included Files:
                                      </div>
                                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                        {filesSuccessed &&
                                        Array.isArray(filesSuccessed) &&
                                        filesSuccessed.length > 0 ? (
                                          <div>
                                            {filesSuccessed?.map((itm) => {
                                              const { reportName } = itm || {};
                                              return (
                                                <div className="d-flex mr-2 flex-wrap">
                                                  {reportName}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          '-'
                                        )}
                                      </div>
                                    </div>
                                  ),
                                  header_7: selReportTypeInUploadStat ===
                                    'CANDIDATE_REPORT' && (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        Errored Files:
                                      </div>
                                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                        {filesFailed &&
                                        Array.isArray(filesFailed) &&
                                        filesFailed.length > 0 ? (
                                          <div>
                                            {filesFailed?.map((itm) => {
                                              const { reportName, message } =
                                                itm || {};
                                              return (
                                                <div className="d-flex mr-2 flex-wrap">
                                                  {reportName} - {message}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        ) : (
                                          '-'
                                        )}
                                      </div>
                                    </div>
                                  ),
                                  header_8: selReportTypeInUploadStat ===
                                    'PSC_PEER_FEEDBACK_REPORT' && (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        Report File
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6 ml-0 pl-0">
                                          {encryptedPassword ? (
                                            <Button
                                              kind="ghost"
                                              size="small"
                                              className="ml-0 pl-0">
                                              <a
                                                className="ml-0 pl-0"
                                                target="_blank"
                                                href={`${v2BaseUrl}/commonHelper/file/${encryptedPassword}`}
                                                rel="noreferrer">
                                                {filename ??
                                                  'Download Report file'}
                                              </a>
                                            </Button>
                                          ) : (
                                            <div className="ml-5">-</div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                  header_9: selReportTypeInUploadStat ===
                                    'PSC_PEER_FEEDBACK_REPORT' && (
                                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                        Password:
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                          {password}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                };
                              })}
                              headers={innerTableViewHeaders}
                            />
                          ) : (
                            <>
                              {invalidTestDatesInfo &&
                              Array.isArray(invalidTestDatesInfo) &&
                              invalidTestDatesInfo.length > 0 ? (
                                <div className="d-flex">
                                  <div className="">Error Message:</div>
                                  <div className="ml-2 invalid-msg small text-capitalize">
                                    {invalidTestDatesInfo?.[0]}
                                  </div>
                                </div>
                              ) : (
                                '-'
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })
              }
            />
            {count > 0 && (
              <Pagination
                onChange={this.handlePaginationChange}
                pageSizes={[10, 20, 30, 40, 50]}
                page={activePage}
                totalItems={count}
              />
            )}
            {(!reportLogs ||
              (reportLogs &&
                Array.isArray(reportLogs) &&
                reportLogs.length <= 0)) && (
              <NoContentBox message="There are no records for your current selection." />
            )}
          </div>
        )}

        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Emails.loading || state.HighLevelReports.loading,
  user: state.x0paDuser.user,
  reportLogs: state.HighLevelReports.reportLogs,
  count: state.HighLevelReports.count,
});

const mapDispatchToProps = {
  uploadCandidateReportEmails,
  getHighLevelReportLogs,
  uploadPscPeerFeedBackReportEmails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateReportEmails);
