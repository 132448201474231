import React from 'react';
import NoContentBox from '../../components/common/NoContentBox';
import GenericDataTable from '../../components/common/GenericDataTable';
import {
  Button,
  Pagination,
  Select,
  SelectItem,
  Tile,
  SelectItemGroup,
} from 'carbon-components-react';

const getUserListItems = (usersList) => {
  if (usersList && Array.isArray(usersList) && usersList.length > 0) {
    return usersList.map((itm) => {
      const { userId, userinfo } = itm || {};
      const { firstname = '', lastname = '' } = userinfo || {};
      return (
        <SelectItem
          text={`${firstname} ${lastname}`}
          key={userId}
          id={userId}
          value={userId}
        />
      );
    });
  } else {
    return <SelectItem text="N/A" key="N/A" disabled />;
  }
};

const CandidateListCommon = (props) => {
  const {
    candidateData,
    handleSearchSubmit,
    push,
    activePage,
    handlePaginationChange,
    candidateCount,
    jobApplFilters,
    year,
    handleFilterDropDown,
    hideSearchYear,
    handleAddCandidatesToCompareAppl,
    handleRemoveCandidatesFromCompare,
    roleId,
    videoStatus,
    assignedTo,
    videoStatusList,
    assignedToList,
    scenariosList,
    scenario,
  } = props || {};
  const localStorageItem = localStorage.getItem('application-ids') || [];
  let className = '';
  if (roleId == 7) {
    className = 'text-left w-25';
  } else {
    className = 'text-left w-25';
  }
  let headers = [];
  if (hideSearchYear) {
    headers = [
      { key: 'header_0', header: 'Name', className: 'text-left w-15' },
      { key: 'header_1', header: 'NRIC', className: 'text-left w-15' },
      { key: 'header_2', header: 'School', className: 'text-left w-15' },
      { key: 'header_7', header: 'Action', className: 'text-left w-15' },
    ];
  } else {
    headers = [
      { key: 'header_0', header: 'Name', className: 'text-left w-15' },
      { key: 'header_1', header: 'NRIC', className: 'text-left w-15' },
      { key: 'header_2', header: 'School', className: 'text-left w-15' },
    ];

    if (roleId != 7 && roleId != 6) {
      headers.push({
        key: 'header_3',
        header: 'Video Interview Status',
        className: 'text-left w-15',
      });
    }

    if (roleId == 6 || roleId == 3) {
      headers.push(
        {
          key: 'header_4',
          header: 'Video Status',
          className: 'text-left w-15',
        },
        { key: 'header_5', header: 'Assigned To', className: 'text-left w-15' }
      );
    }

    headers.push({
      key: 'header_6',
      header: 'Scenario',
      className: 'text-left  w-15',
    });

    if (roleId != 7) {
      headers.push({
        key: 'header_7',
        header: 'Action',
        className: 'text-left w-15',
      });
    }
  }

  let finalJobApplFilterData = [];
  if (
    jobApplFilters &&
    Array.isArray(jobApplFilters) &&
    jobApplFilters.length > 0
  ) {
    finalJobApplFilterData = jobApplFilters.sort((a, b) => {
      if (a && b) {
        if (a.year < b.year) {
          return 1;
        }
        if (a.year > b.year) {
          return -1;
        }
        return 0;
      }
    });
  }

  let cscUsersList = [];
  let secUsersList = [];
  let chairmanUsersList = [];
  let cscRaterList = [];

  if (
    assignedToList &&
    Array.isArray(assignedToList) &&
    assignedToList.length > 0
  ) {
    cscUsersList = assignedToList.filter((itm) => itm?.stageId == 1);
    secUsersList = assignedToList.filter((itm) => itm?.stageId == 2);
    chairmanUsersList = assignedToList.filter((itm) => itm?.stageId == 3);
    cscRaterList = assignedToList.filter((itm) => itm?.isStaticMember);
  }

  let otherActionsList = [];

  if (roleId == 6 || roleId == 3) {
    otherActionsList.push(
      <div className="mr-3">
        <Select
          placeHolderText="Video Status"
          name="videoStatus"
          id="videoStatus"
          onChange={handleFilterDropDown}
          value={videoStatus}
          hideLabel
          className="xpa-width w-100">
          <SelectItem
            text="Video Status"
            key="Video Status"
            value="Video Status"
            disabled
            hidden
          />
          <SelectItem text="Select" disabled />
          {videoStatusList &&
            Array.isArray(videoStatusList) &&
            videoStatusList.length > 0 &&
            videoStatusList.map((itm, idx) => {
              return <SelectItem text={itm} key={itm} id={itm} value={itm} />;
            })}
        </Select>
      </div>,
      <div className="mr-3">
        <Select
          name="assignedTo"
          id="assignedTo"
          onChange={handleFilterDropDown}
          value={assignedTo}
          hideLabel
          className="xpa-width w-100">
          <SelectItem
            text="Assigned To"
            key="Assigned To"
            value="Assigned To"
            disabled
            hidden
          />
          <SelectItemGroup label="CSC">
            {getUserListItems(cscUsersList)}
          </SelectItemGroup>
          <SelectItemGroup label="Secy/Dir">
            {getUserListItems(secUsersList)}
          </SelectItemGroup>
          <SelectItemGroup label="Chairman/CM">
            {getUserListItems(chairmanUsersList)}
          </SelectItemGroup>
          <SelectItemGroup label="CSC Rater">
            {getUserListItems(cscRaterList)}
          </SelectItemGroup>
        </Select>
      </div>
    );
  }

  if (roleId == 1 || roleId == 6) {
    otherActionsList.push(
      <div className="mr-3">
        <Select
          placeHolderText="Scenario"
          name="scenario"
          id="scenario"
          onChange={handleFilterDropDown}
          value={scenario}
          hideLabel
          className="xpa-width w-100">
          <SelectItem
            text="Scenario"
            key="Scenario"
            value="Scenario"
            disabled
            hidden
          />
          <SelectItem text="Select" disabled />
          {scenariosList &&
            Array.isArray(scenariosList) &&
            scenariosList.length > 0 &&
            scenariosList.map((itm, idx) => {
              const { scenario, scenarioId } = itm || {};
              return (
                <SelectItem
                  text={scenario}
                  key={scenarioId}
                  id={scenarioId}
                  value={scenarioId}
                />
              );
            })}
          <SelectItem text="All" key="All" id="All" value="All" />
        </Select>
      </div>
    );
  }

  otherActionsList.push(
    <div className="">
      <Select
        name="year"
        id="year"
        onChange={handleFilterDropDown}
        value={year}
        hideLabel
        className="xpa-width w-100"
        labelText="Select Test">
        <SelectItem text="Select" key={0} disabled />
        {finalJobApplFilterData &&
          Array.isArray(finalJobApplFilterData) &&
          finalJobApplFilterData.length > 0 &&
          finalJobApplFilterData.map((r, idx) => {
            const { year: propYear, count, batch_id } = r || {};
            return (
              idx <= 10 && (
                <SelectItem
                  text={`Selection Year ${propYear} (${count})`}
                  key={batch_id}
                  id={batch_id}
                  value={propYear}
                />
              )
            );
          })}
      </Select>
    </div>
  );

  return (
    <div className={`${roleId == 6 ? 'cand-list-table' : ''}`}>
      <GenericDataTable
        tbodyClass="candidate-background-color"
        placeHolderText="Search by Name / NRIC"
        otherActions={hideSearchYear || roleId == 7 ? [] : otherActionsList}
        searchable={hideSearchYear !== true}
        onSearchInputChange={handleSearchSubmit}
        headers={headers}
        rows={
          candidateData &&
          Array.isArray(candidateData) &&
          candidateData.length > 0
            ? candidateData.map((interview, idx) => {
                const {
                  application,
                  videoInterview,
                  schoolDetails,
                  // videoIntApplnObj,
                  videoStatus,
                  assignedList,
                  scenarioInfo,
                } = interview || {};
                const { company: school, companyRel } =
                  (schoolDetails &&
                    Array.isArray(schoolDetails) &&
                    schoolDetails.length > 0 &&
                    schoolDetails[0]) ||
                  {};
                const { displayName: schoolName2 } = companyRel || {};
                const { displayName: schoolName } = school || {};
                const { profile, batch, appId, selectionYear } =
                  (application &&
                    Array.isArray(application) &&
                    application.length > 0 &&
                    application[0]) ||
                  [];
                const { status } =
                  (videoInterview &&
                    Array.isArray(videoInterview) &&
                    videoInterview.length > 0 &&
                    videoInterview[0]) ||
                  [];
                const { fullName, nric } = profile || {};
                const nricSubStr =
                  nric &&
                  nric.toString() &&
                  nric.toString().length >= 6 &&
                  nric.toString().substring(1, 5);
                const finalNric =
                  nric &&
                  nricSubStr &&
                  nric.toString().replace(nricSubStr, 'xxxx');
                const { scenario, scenarioCode } = scenarioInfo || {};
                return {
                  isExpanded: false,
                  id: `row_${idx}`,
                  header_0: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Name:
                      </div>
                      <div className="xpa-link f-w-b h6 mb-0 text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        <a
                          className="css-cand-name"
                          href={`/app/csc/candidate/${appId}/batch/${selectionYear}/status/personal-info`}>
                          {fullName}
                        </a>
                      </div>
                    </div>
                  ),
                  header_1: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        NRIC:
                      </div>
                      <div className="text-uppercase bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                         {nric}
                      </div>
                    </div>
                  ),
                  header_2: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        School:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {(schoolName === 'Others' ||
                        schoolName === 'OtherSchool'
                          ? schoolName2
                          : schoolName) || '-'}
                      </div>
                    </div>
                  ),
                  header_3: roleId !== 7 && roleId !== 6 && (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Video Interview Status:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        <div
                          className={`${
                            status
                              ? status === 'Awaiting response'
                                ? 'bx--tag bx--tag--yellow'
                                : 'bx--tag bx--tag--green'
                              : ''
                          }`}>
                          {status || '-'}
                        </div>
                      </div>
                    </div>
                  ),
                  header_4: (roleId == 6 || roleId == 3) && (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Video Status:
                      </div>
                      <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {videoStatus || '-'}
                      </div>
                    </div>
                  ),
                  header_5: (roleId == 6 || roleId == 3) && (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Assigned To:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {assignedList &&
                        Array.isArray(assignedList) &&
                        assignedList.length > 0
                          ? assignedList.map((obj, idx) => {
                              const { userinfo } = obj || {};
                              const { firstname, lastname } = userinfo || {};
                              return (
                                <span className="text-capitalize">
                                  {firstname && lastname
                                    ? `${firstname} ${lastname}`
                                    : '-'}
                                  {idx != assignedList.length - 1 ? ', ' : ''}
                                </span>
                              );
                            })
                          : '-'}
                      </div>
                    </div>
                  ),
                  header_6: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Scenario:
                      </div>
                      <div
                        title={`${scenario || ''}`}
                        className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {scenarioCode || scenario || '-'}
                      </div>
                    </div>
                  ),
                  header_7: roleId !== 7 && (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Action:
                      </div>
                      <div className="d-flex bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        <div>
                          <Button
                            onClick={() => {
                              handleAddCandidatesToCompareAppl(appId);
                            }}
                            disabled={localStorageItem.includes(appId)}
                            kind="primary"
                            size="small">
                            Add to Compare
                          </Button>
                        </div>
                        {localStorageItem.includes(appId) && (
                          <div className="ml-2">
                            <Button
                              onClick={() => {
                                handleRemoveCandidatesFromCompare(appId);
                              }}
                              kind="danger"
                              size="small">
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  ),
                };
              })
            : []
        }
      />
      {((candidateData &&
        Array.isArray(candidateData) &&
        candidateData.length <= 0) ||
        !candidateData) && (
        <NoContentBox message="There are no candidates available for your current selection" />
      )}
      {candidateCount > 0 && (
        <Pagination
          onChange={handlePaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={activePage}
          totalItems={candidateCount}
        />
      )}
    </div>
  );
};

export default CandidateListCommon;
