import React, { FC } from 'react';
import { register } from './serviceWorker';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [installingWorker, setInstallingWorker] = React.useState(null);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    console.log('================================================');
    console.log('registration ', registration);
    console.log('onSWUpdate ', onSWUpdate);
    setShowReload(true);
    setInstallingWorker(registration.installing);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
    if (installingWorker) {
      installingWorker.postMessage({ type: 'SKIP_WAITING' });
    }

    setShowReload(false);
    window.location.reload(true);
  };

  console.log('showReload ', showReload);
  return (
    <div>
      {showReload && (
        <div
          id="xsw-update"
          className="d-flex justify-content-center align-items-center">
          <div className="font-weight-bold">
            New version of the web application is available.
          </div>
          <div className="ml-4">
            <button
              onClick={reloadPage}
              tabIndex="0"
              className="bx--btn bx--btn--sm bx--btn--primary"
              type="button">
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceWorkerWrapper;
