import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { getCurrEnvironment } from '../../containers/common/common';

const DashboardAnalyticsPowerBI = (props) => {
  const { reportsMap, embedToken, iFilter } = props || {};
  const currEnvironment = getCurrEnvironment();
  let reportId;
  if (currEnvironment === 'prod') {
    // production
    reportId = 'bba23e45-0a2e-445b-ade4-110012be2335';
  } else {
    // local & staging
    reportId = 'bc7679bc-a7cd-4ac1-95f9-29640fbfb068';
  }
  return (
    <div className="dashboard-analytics-container">
      <PowerBIEmbed
        cssClassName="powerbi-custom-class"
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual and qna
          id: reportId,
          embedUrl:
            (reportsMap &&
              reportsMap[reportId] &&
              reportsMap[reportId].embedUrl) ||
            '',
          accessToken: embedToken, // Keep as empty string, null or undefined
          tokenType: 1,
          filters: iFilter,
          settings: {
            panes: {
              filters: {
                visible: false,
              },
              pageNavigation: {
                visible: false,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default DashboardAnalyticsPowerBI;
