import React, { Component } from 'react';
import { connect } from 'react-redux';

//Api Actions
import {
  getAdminUsersList,
  resetPanelMemPassword,
} from '../../actions/AdminSection';

//Common Components
import BackRow from '../../components/common/BackRow';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../components/private/SuccErrNotification';

//Common packages
import { TextInput, Button, Tile, Modal } from 'carbon-components-react';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../../components/common/Dropdown';

class ResetPanelPassword extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'reset-panel-password', name: 'Reset Panel Password', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedPanelMember: {},
      tempPassword: '',
      isTempPassInvalid: false,
      isOpenTempPassCnfModal: false,
    };
  }

  handleGetPanelUsersApi = (value) => {
    const data = {
      roleId: 7,
      searchKey: value,
      noLimit: true,
    };
    this.props.getAdminUsersList(data);
  };

  onInputPanelMember = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[ a-zA-Z0-9!()#+.]{1,300}/.test(value)) {
      this.handleGetPanelUsersApi(value);
    } else {
      this.handleGetPanelUsersApi();
    }
  };

  handleDropdownChange = (v, metaValue, name) => {
    console.log('v ', v, metaValue, name);
    this.setState({
      [name]: metaValue,
    });
  };

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  validateInputs = () => {
    const { selectedPanelMember, tempPassword } = this.state;
    const { value: panelMemId } = selectedPanelMember || {};
    let isSelPanelMemInvalid = false;
    let isTempPassInvalid = false;
    if (!panelMemId) isSelPanelMemInvalid = true;
    if (!tempPassword || tempPassword.toString().trim() == '')
      isTempPassInvalid = true;
    this.setState({ isSelPanelMemInvalid, isTempPassInvalid });
    if (!isSelPanelMemInvalid && !isTempPassInvalid) {
      this.setState({ isOpenTempPassCnfModal: true });
    }
  };

  dismissTempPassCnfModal = () => {
    this.setState({ isOpenTempPassCnfModal: false });
  };

  handleSubmitTempPassword = () => {
    const { selectedPanelMember, tempPassword } = this.state;
    const { value: panelMemId } = selectedPanelMember || {};
    const data = {
      userId: panelMemId,
      temporaryPassword: tempPassword,
      source: 'internal-reset-password-temporary',
    };
    this.props
      .resetPanelMemPassword(data)
      .then((res) => {
        this.dismissTempPassCnfModal();
        if (res && !res.error) {
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Temporary password has been created successfully.',
              selectedPanelMember: null,
              tempPassword: '',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                  notifMsg: '',
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.dismissTempPassCnfModal();
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  render() {
    const {
      selectedPanelMember,
      isSelPanelMemInvalid,
      tempPassword,
      isTempPassInvalid,
      isOpenTempPassCnfModal,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
    } = this.state;

    const { usersList, loading } = this.props;

    return (
      <div>
        <BackRow paths={this.paths} />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
          errMsg={errMsg}
        />
        <Tile className="mt-3">
          <div className="">
            <div className="bx--label mb-2">Select panel member:</div>
            <div className="w-50">
              <Dropdown
                title=""
                placeholder="Select a panel member"
                className={`w-100 ${isSelPanelMemInvalid ? 'invalid' : ''}`}
                mainClassName="bx--form-item mb-0"
                required
                onInputChange={this.onInputPanelMember}
                options={
                  usersList && Array.isArray(usersList) && usersList.length > 0
                    ? usersList.map((data, idx) => {
                        const {
                          email = '',
                          firstname = '',
                          lastname = '',
                          userId,
                        } = data || {};
                        return {
                          label: `${firstname} ${lastname} (${email})`,
                          value: userId || '',
                        };
                      })
                    : []
                }
                onChange={(value, e, name) =>
                  this.handleDropdownChange(value, e, name)
                }
                name="selectedPanelMember"
                selectedValue={selectedPanelMember}
              />
              {isSelPanelMemInvalid && (
                <div className="invalid-msg mt-2">
                  Please select a panel member{' '}
                </div>
              )}
            </div>
          </div>
          <div className="mt-3">
            <div className="bx--label mb-2">Temporary Password:</div>
            <div className="w-25">
              <div className="bx--form-it1em" style={{ minWidth: '17rem' }}>
                <TextInput
                  type="password"
                  name="tempPassword"
                  value={tempPassword}
                  placeholder="Enter Temporary password"
                  onChange={(e) =>
                    this.handleOnChange('tempPassword', e.target.value)
                  }
                  invalid={isTempPassInvalid}
                  invalidText="Please enter valid password"
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <Button onClick={() => this.validateInputs()}>Submit</Button>
          </div>
        </Tile>
        {isOpenTempPassCnfModal && (
          <Modal
            open={isOpenTempPassCnfModal}
            modalHeading="Reset Panel Password"
            modalLabel="Action"
            primaryButtonText={loading ? 'Submitting...' : 'Submit'}
            secondaryButtonText="Cancel"
            danger
            iconDescription="Close the modal"
            onRequestClose={this.dismissTempPassCnfModal}
            onRequestSubmit={this.handleSubmitTempPassword}>
            Are you sure you want to reset password for{' '}
            {selectedPanelMember?.label || ''}?
          </Modal>
        )}
        {/* <JDProcessOverlay show={this.props.loading} message="processing..." /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.AdminSection.loading,
  usersList: state.AdminSection.usersList,
});

const mapDispatchToProps = {
  getAdminUsersList,
  resetPanelMemPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPanelPassword);
