import React from 'react';
import './Header.css';
import moment from 'moment';

const ScheduleMaintenanceHeader = (props) => {
  const { user } = props || {};
  const { scheduledmaintainanceDetails } = user || {};
  const { fromDate, toDate } = scheduledmaintainanceDetails || {};
  return (
    <div className="css-maintenance-header d-flex justify-content-center align-items-center">
      <div className="f-w-b">
        The ARS system will be undergoing scheduled maintenance to serve you
        better and will not be available on{' '}
        {fromDate && moment(fromDate).format('DD/MM/YYYY, h:mm a')} to{' '}
        {toDate && moment(toDate).format('DD/MM/YYYY, h:mm a')}. Please plan
        your transactions in advance. Thank you for your understanding.
      </div>
    </div>
  );
};

export default ScheduleMaintenanceHeader;
