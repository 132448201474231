import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//API
import {
  uploadHighLevelReports,
  getHighLevelReportLogs,
  getTestNormDetails,
  generateHighLvlReports,
  getTestsDetails,
  generateCeleryTask,
  downloadHighLevelReports,
  getDefaultNorms,
  uploadCandidateNrics,
  uploadPsychologicalRatings,
  uploadAssessmentCenterData,
  uploadPeerRatingsData,
  uploadPSBriefingDeckDoc,
} from '../../actions/HighLevelReports';
import {
  getCandidateProfileDetailsList,
  getSaticApplFilters,
} from '../../actions/CandidateList';

//Common urls for api calls
import { baseUrl } from '../../utils/config';
import candidateScreeningKeys from '../../staticdata/CandidateScreeningKeys';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericTabs from '../../components/common/GenericTabs';
import Dropdown from '../../components/common/Dropdown';
import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import CsvTableData from '../../components/common/CsvTableData';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';

//Common packages
import {
  Button,
  Select,
  SelectItem,
  InlineNotification,
  Tile,
  FileUploaderButton,
  TextInput,
  TextArea,
  RadioButton,
  Pagination,
  FileUploaderDropContainer,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
} from 'carbon-components-react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { capitalCase } from 'capital-case';
import _, { cloneDeep } from 'lodash';

import Reports_Template_PAE_PSC_PSLP from '../../assets/files/Reports_Template_PAE_PSC_PSLP.csv';
import Reports_Template_SAS from '../../assets/files/Reports_Template_SAS.csv';
import Reports_Template_ACE from '../../assets/files/Reports_Template_ACE.csv';

import './Reports.css';
import NotFound from '../../components/common/NotFound';
import { arrMap } from '../../utils/reactMapUtils';

import CandNricTemp from '../../assets/files/Cand_Nric_List_All.csv';
import PeerRatingsDataTemp from '../../assets/files/Peer_Ratings_Data_Template.xlsx';
import { SelectionYearDropdown } from '../../components/common/SelectionYearDropdown';

// const uploadDataArr = [
//   {
//     type: 'RANRA & WGCTA (PSC)',
//     id: 1,
//   },
//   {
//     type: 'Battery Raw Results (WG3)',
//     id: 2,
//   },
//   {
//     type: 'ANRA & WGIII (PSC)',
//     id: 3,
//   },
//   {
//     type: 'WGIII (PSC)',
//     id: 4,
//   },
//   {
//     type: 'ANRA (PSC)',
//     id: 5,
//   },
//   {
//     type: 'NEO Raw Data',
//     id: 6,
//   },
//   {
//     type: 'NEOPIR raw data (PSC)',
//     id: 7,
//   },
// ];

const uploadDataArr = [
  {
    type: 'PAE',
    id: 'PAE',
  },
  {
    type: 'PSC',
    id: 'PSC',
  },
  {
    type: 'PSLP',
    id: 'PSLP',
  },
  {
    type: 'SAS',
    id: 'SAS',
  },
  {
    type: 'ACE',
    id: 'ACE',
  },
];

const CandidateScreeningYears = (props) => {
  const {
    state,
    handleChangeWatsonGlaserTest,
    handleInputChange,
    handleDropdownChange,
  } = props || {};
  const { wgiiiScYears, ranraScYears, neopirScYears, watsonGlaserScYears } =
    state || {};
  const candidateScreeningYrs = [
    {
      label: 2014,
      value: 2014,
    },
    {
      label: 2015,
      value: 2015,
    },
    {
      label: 2016,
      value: 2016,
    },
    {
      label: 2017,
      value: 2017,
    },
    {
      label: 2018,
      value: 2018,
    },
    {
      label: 2019,
      value: 2019,
    },
    {
      label: 2020,
      value: 2020,
    },
    {
      label: 2021,
      value: 2021,
    },
    {
      label: 2022,
      value: 2022,
    },
    {
      label: 2023,
      value: 2023,
    },
    {
      label: 2024,
      value: 2024,
    },
    {
      label: 2025,
      value: 2025,
    },
    {
      label: 2026,
      value: 2026,
    },
  ];
  return (
    <StructuredListWrapper
      className="mb-0"
      ariaLabel="Candidate Screening"
      selection={false}>
      <StructuredListHead>
        <StructuredListRow head label={false} tabIndex={0}>
          <StructuredListCell head noWrap={false}>
            Select Test
          </StructuredListCell>
          <StructuredListCell head noWrap={false}>
            Select Validity Years
          </StructuredListCell>
        </StructuredListRow>
      </StructuredListHead>
      <StructuredListBody>
        <StructuredListRow head={false} label={false} tabIndex={0}>
          <StructuredListCell head={false} noWrap>
            Watson Glaser
          </StructuredListCell>
          <StructuredListCell head={false} noWrap={false}>
            <Dropdown
              title=""
              mainClassName="bx--form-item mb-0"
              titleClass=""
              labelClass=""
              required
              custom
              isMulti
              onInputChange={handleInputChange}
              className="w-100"
              options={candidateScreeningYrs}
              placeholder="Select Years"
              onChange={(value, e, name) =>
                handleDropdownChange(value, e, name)
              }
              name="watsonGlaserScYears"
              selectedValue={watsonGlaserScYears}
            />
          </StructuredListCell>
        </StructuredListRow>
        <StructuredListRow head={false} label={false} tabIndex={0}>
          <StructuredListCell head={false} noWrap>
            WGIII
          </StructuredListCell>
          <StructuredListCell head={false} noWrap={false}>
            <Dropdown
              title=""
              mainClassName="bx--form-item mb-0"
              titleClass=""
              labelClass=""
              required
              custom
              isMulti
              onInputChange={handleInputChange}
              className="w-100"
              options={candidateScreeningYrs}
              placeholder="Select Years"
              onChange={(value, e, name) =>
                handleDropdownChange(value, e, name)
              }
              name="wgiiiScYears"
              selectedValue={wgiiiScYears}
            />
          </StructuredListCell>
        </StructuredListRow>
        <StructuredListRow head={false} label={false} tabIndex={0}>
          <StructuredListCell head={false} noWrap>
            ANRA
          </StructuredListCell>
          <StructuredListCell head={false} noWrap={false}>
            <Dropdown
              title=""
              mainClassName="bx--form-item mb-0"
              titleClass=""
              labelClass=""
              required
              custom
              isMulti
              onInputChange={handleInputChange}
              className="w-100"
              options={candidateScreeningYrs}
              placeholder="Select Years"
              onChange={(value, e, name) =>
                handleDropdownChange(value, e, name)
              }
              name="ranraScYears"
              selectedValue={ranraScYears}
            />
          </StructuredListCell>
        </StructuredListRow>
        <StructuredListRow head={false} label={false} tabIndex={0}>
          <StructuredListCell head={false} noWrap>
            NEO
          </StructuredListCell>
          <StructuredListCell head={false} noWrap={false}>
            <Dropdown
              title=""
              mainClassName="bx--form-item mb-0"
              titleClass=""
              labelClass=""
              required
              custom
              isMulti
              onInputChange={handleInputChange}
              className="w-100"
              options={candidateScreeningYrs}
              placeholder="Select Years"
              onChange={(value, e, name) =>
                handleDropdownChange(value, e, name)
              }
              name="neopirScYears"
              selectedValue={neopirScYears}
            />
          </StructuredListCell>
        </StructuredListRow>
      </StructuredListBody>
    </StructuredListWrapper>
  );
};

// const AnnexAWGEndYear = (props) => {
//   const { handleChange, state, annexAWatsonEndYearArr } = props || {};
//   const { watsonEndYear, isValidWatsonEndYear, watsonType, watsonTest } =
//     state || {};
//   let years = [];
//   if (watsonTest === 'wgiii') {
//     years = [2020, 2021, 2022];
//   } else if (watsonTest === 'watsonGlaser') {
//     years = [2014, 2015, 2016, 2017, 2018, 2019, 2020];
//   } else {
//     years = [2014, 2015, 2016, 2017, 2018, 2019];
//   }

//   const finalYears = years.filter(
//     (y) =>
//       y !== watsonType - 1 &&
//       y !== watsonType - 2 &&
//       y !== watsonType - 3 &&
//       y !== watsonType - 4 &&
//       y !== watsonType - 5 &&
//       y !== watsonType - 6
//   );
//   return (
//     <div className="w-50">
//       <Select
//         name="watsonEndYear"
//         id="watsonEndYear"
//         onChange={handleChange}
//         value={watsonEndYear}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidWatsonEndYear}
//         invalidText="Please select valid NORM End Year"
//         labelText="Select Test">
//         <SelectItem text="Select" disabled key={0} />
//         {finalYears &&
//           Array.isArray(finalYears) &&
//           finalYears.length > 0 &&
//           finalYears.map((r) => {
//             return <SelectItem text={r} key={r} id={r} value={r} />;
//           })}
//       </Select>
//     </div>
//   );
// };

// const WatsonGlaserTestType = (props) => {
//   const { handleChange, state, watsonTestNorms } = props || {};
//   const { watsonName, isValidWatsonName, watsonTest } = state || {};
//   return (
//     <div className="w-50">
//       <Select
//         name="watsonName"
//         id="watsonName"
//         onChange={(e) => handleChange(e, 'watsonName')}
//         value={watsonName}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidWatsonName}
//         invalidText="Please select valid NORM"
//         labelText="Select Test">
//         <SelectItem text="Select" disabled key={0} />
//         <SelectItem text="PAE" key={1} value="pae" id={1} />
//         {watsonTest !== 'wgiii' && (
//           <SelectItem text="ACE" key={2} value="ace" id={2} />
//         )}
//       </Select>
//     </div>
//   );
// };

const WGIIITestNormType = (props) => {
  const { handleChange, state } = props || {};
  const { wgiiiNorm, isValidWGIIINorm } = state || {};
  return (
    <div className="w-50">
      <Select
        name="wgiiiNorm"
        id="wgiiiNorm"
        onChange={(e) => handleChange('wgiiiNorm', e.target.value)}
        value={wgiiiNorm}
        hideLabel
        className="w-100 xpa-width"
        invalid={isValidWGIIINorm}
        invalidText="Please select valid NORM"
        labelText="Select Norm">
        <SelectItem text="Select" disabled key={0} />
        <SelectItem
          text="PAE (2020 - 2024)"
          key={1}
          value="pae_2020-2024"
          id={1}
        />
        <SelectItem
          text="PAE (2020 - 2023)"
          key={2}
          value="pae_2020-2023"
          id={2}
        />
        <SelectItem
          text="PAE (2020 - 2022)"
          key={3}
          value="pae_2020-2022"
          id={3}
        />
        <SelectItem
          text="In-Service (2020)"
          key={4}
          value="publicservice_2020"
          id={4}
        />
      </Select>
    </div>
  );
};

// const WatsonGlaserTest = (props) => {
//   const { handleChange, state, watsonTestNorms } = props || {};
//   const { watsonTest, isValidWatsonTest } = state || {};
//   return (
//     <div className="w-50">
//       <Select
//         name="watsonTest"
//         id="watsonTest"
//         onChange={(e) => handleChange(e)}
//         value={watsonTest}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidWatsonTest}
//         invalidText="Please select valid NORM"
//         labelText="Select Test">
//         <SelectItem text="Select" disabled key={0} />
//         <SelectItem text="Watson Glaser" key={1} value="watsonGlaser" id={1} />
//         <SelectItem text="WGIII" key={2} value="wgiii" id={2} />
//       </Select>
//     </div>
//   );
// };

// const WatsonGlaserStartYear = (props) => {
//   const { handleChange, state, watsonTestNorms } = props || {};
//   const { watsonType, isValidWatson, watsonName, reportType } = state || {};
//   return (
//     <div className="w-50">
//       <Select
//         name="watsonType"
//         id="watsonType"
//         onChange={handleChange}
//         value={watsonType}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidWatson}
//         disabled={watsonName === 'ace' && reportType == 3}
//         invalidText="Please select valid NORM"
//         labelText="Select Test">
//         <SelectItem text="Select" disabled key={0} />
//         {watsonTestNorms &&
//           Array.isArray(watsonTestNorms) &&
//           watsonTestNorms.length > 0 &&
//           watsonTestNorms.map((r) => {
//             const { normId, testYear } = r || {};
//             return (
//               normId !== 18 && (
//                 <SelectItem
//                   text={testYear}
//                   key={normId}
//                   id={normId}
//                   value={testYear}
//                 />
//               )
//             );
//           })}
//       </Select>
//     </div>
//   );
// };

// const AnnexANeoPirendYear = (props) => {
//   const { state, handleChange, testName } = props || {};
//   const {
//     neoPirEndYear,
//     isValidNeoPirEndYear,
//     neoPirType,
//     neoPirName,
//     reportType,
//   } = state || {};

//   let years = [];
//   let isNeoPirEndYearDisabled = false;
//   if (neoPirName === 'pae') {
//     years = [2014, 2015, 2016, 2017, 2018];
//   } else if (
//     reportType == 4 &&
//     testName === 'neoPir' &&
//     neoPirName === 'public service'
//   ) {
//     // reportType == 4 => PSLP CPI
//     isNeoPirEndYearDisabled = true;
//     years = [2022];
//   } else {
//     years = [2014, 2015, 2016, 2017, 2018, 2019, 2020];
//   }
//   const finalYears = years.filter(
//     (y) =>
//       y !== neoPirType - 1 &&
//       y !== neoPirType - 2 &&
//       y !== neoPirType - 3 &&
//       y !== neoPirType - 4 &&
//       y !== neoPirType - 5 &&
//       y !== neoPirType - 6
//   );
//   return (
//     <Select
//       name="neoPirEndYear"
//       id="neoPirEndYear"
//       onChange={handleChange}
//       value={neoPirEndYear}
//       hideLabel
//       className="w-100 xpa-width"
//       invalid={isValidNeoPirEndYear}
//       invalidText="Please select valid NORM End Year"
//       labelText="Select Test"
//       disabled={isNeoPirEndYearDisabled}>
//       <SelectItem text="Select" disabled key={0} />
//       {finalYears &&
//         Array.isArray(finalYears) &&
//         finalYears.length > 0 &&
//         finalYears.map((r) => {
//           return <SelectItem text={r} key={r} id={r} value={r} />;
//         })}
//     </Select>
//   );
// };

// const AnnexARanraEndYear = (props) => {
//   const { annexARanraEndYearArr, handleChange, state } = props || {};
//   const { isValidRanraEndYear, ranraEndYear, ranraType } = state;
//   const years = [2018, 2019, 2020, 2021, 2022];
//   const finalYears = years.filter(
//     (y) =>
//       y !== ranraType - 1 &&
//       y !== ranraType - 2 &&
//       y !== ranraType - 3 &&
//       y !== ranraType - 4 &&
//       y !== ranraType - 5 &&
//       y !== ranraType - 6
//   );
//   return (
//     <div className="w-50">
//       <Select
//         name="ranraEndYear"
//         id="ranraEndYear"
//         onChange={handleChange}
//         value={ranraEndYear}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidRanraEndYear}
//         invalidText="Please select valid NORM End Year"
//         labelText="Select Test">
//         <SelectItem text="Select" disabled key={0} />
//         {finalYears &&
//           Array.isArray(finalYears) &&
//           finalYears.length > 0 &&
//           finalYears.map((r) => {
//             return <SelectItem text={r} key={r} id={r} value={r} />;
//           })}
//       </Select>
//     </div>
//   );
// };

// const RanraTestType = (props) => {
//   const { handleChange, state, ranraTestNorms } = props || {};
//   const { ranraName, isValidRanraName } = state || {};
//   return (
//     <div className="w-50">
//       <Select
//         name="ranraName"
//         id="ranraName"
//         onChange={handleChange}
//         value={ranraName}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidRanraName}
//         invalidText="Please select valid NORM"
//         labelText="Select Test">
//         <SelectItem text="Select" disabled key={0} />
//         <SelectItem text="PAE" key={1} value="pae" id={1} />
//       </Select>
//     </div>
//   );
// };

const AnraTestNormType = (props) => {
  const { handleChange, state } = props || {};
  const { anraNorm, isValidAnraNorm } = state || {};
  return (
    <div className="w-50">
      <Select
        name="anraNorm"
        id="anraNorm"
        onChange={(e) => handleChange('anraNorm', e.target.value)}
        value={anraNorm}
        hideLabel
        className="w-100 xpa-width"
        invalid={isValidAnraNorm}
        invalidText="Please select valid NORM"
        labelText="Select Norm">
        <SelectItem text="Select" disabled key={0} />
        <SelectItem
          text="PAE (2020 - 2024)"
          key={1}
          value="pae_2020-2024"
          id={1}
        />
        <SelectItem
          text="PAE (2019 - 2023)"
          key={2}
          value="pae_2019-2023"
          id={2}
        />
        <SelectItem
          text="PAE (2018 - 2022)"
          key={3}
          value="pae_2018-2022"
          id={3}
        />
      </Select>
    </div>
  );
};

// const RanraTestStartYear = (props) => {
//   const { handleChange, state, ranraTestNorms } = props || {};
//   const { ranraType, isValidRanraType } = state || {};
//   return (
//     <div className="w-50">
//       <Select
//         name="ranraType"
//         id="ranraType"
//         onChange={handleChange}
//         value={ranraType}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidRanraType}
//         invalidText="Please select valid NORM"
//         labelText="Select Test">
//         <SelectItem text="Select" disabled key={0} />
//         {ranraTestNorms &&
//           Array.isArray(ranraTestNorms) &&
//           ranraTestNorms.length > 0 &&
//           ranraTestNorms.map((r) => {
//             const { normId, normName, testYear } = r || {};
//             return (
//               <SelectItem
//                 text={testYear}
//                 key={normId}
//                 id={normId}
//                 value={testYear}
//               />
//             );
//           })}
//       </Select>
//     </div>
//   );
// };

// const NeoPirTestType = (props) => {
//   const { handleChange, state, neoPirTestNorms } = props || {};
//   const { neoPirName, isValidNeoPirName } = state || {};
//   return (
//     <div className="w-50">
//       <Select
//         name="neoPirName"
//         id="neoPirName"
//         onChange={(e) => {
//           handleChange(e, 'neoPirName');
//         }}
//         value={neoPirName}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidNeoPirName}
//         invalidText="Please select valid NORM"
//         labelText="Select Test">
//         <SelectItem text="Select" disabled key={0} />
//         <SelectItem text="PAE" key={1} value="pae" id={1} />
//         <SelectItem
//           text="PUBLIC SERVICE"
//           key={2}
//           value="public service"
//           id={2}
//         />
//         <SelectItem
//           text="UK WORKING POP"
//           key={3}
//           value="uk working pop"
//           id={3}
//         />
//       </Select>
//     </div>
//   );
// };

const NeoTestNormType = (props) => {
  const { handleChange, state } = props || {};
  const { neoNorm, isValidNeoNorm } = state || {};
  return (
    <div className="w-50">
      <Select
        name="neoNorm"
        id="neoNorm"
        onChange={(e) => handleChange('neoNorm', e.target.value)}
        value={neoNorm}
        hideLabel
        className="w-100 xpa-width"
        invalid={isValidNeoNorm}
        invalidText="Please select valid NORM"
        labelText="Select Norm">
        <SelectItem text="Select" disabled key={0} />
        <SelectItem
          text="PAE (2016 - 2018 + 2023 - 2024)"
          key={1}
          value="pae_2016-2018_2023-2024"
          id={1}
        />
        <SelectItem
          text="PAE (2015 - 2018 + 2023)"
          key={2}
          value="pae_2015-2018_2023"
          id={2}
        />
        <SelectItem
          text="In-Service (2022)"
          key={3}
          value="publicservice_2022"
          id={3}
        />
        <SelectItem
          text="UK Working Pouplation"
          key={4}
          value="ukworkingpop_2019"
          id={4}
        />
      </Select>
    </div>
  );
};

// const NeoPirStartYear = (props) => {
//   const { handleChange, state, neoPirTestNorms, testName } = props || {};
//   const { neoPirType, isValidNeoPirType, reportType, neoPirName } = state || {};

//   let finalNeoPieTestNorms = neoPirTestNorms;
//   let isNeoPirTypedisbaled = false;
//   if (
//     reportType == 4 &&
//     testName === 'neoPir' &&
//     neoPirName === 'public service'
//   ) {
//     isNeoPirTypedisbaled = true;
//     finalNeoPieTestNorms = finalNeoPieTestNorms.filter(
//       (r) => r?.testYear == 2022
//     );
//     if (finalNeoPieTestNorms?.length <= 0) {
//       finalNeoPieTestNorms = [
//         {
//           normId: 2022,
//           testYear: 2022,
//         },
//       ];
//     }
//   }

//   return (
//     <div className="w-50">
//       <Select
//         name="neoPirType"
//         id="neoPirType"
//         onChange={handleChange}
//         value={neoPirType}
//         hideLabel
//         className="w-100 xpa-width"
//         invalid={isValidNeoPirType}
//         invalidText="Please select valid NORM"
//         labelText="Select Test"
//         disabled={isNeoPirTypedisbaled}>
//         <SelectItem text="Select" disabled key={0} />
//         {finalNeoPieTestNorms &&
//           Array.isArray(finalNeoPieTestNorms) &&
//           finalNeoPieTestNorms.length > 0 &&
//           finalNeoPieTestNorms.map((r) => {
//             const { normId, testYear } = r || {};
//             return (
//               normId !== 20 && (
//                 <SelectItem
//                   text={testYear}
//                   key={normId}
//                   id={normId}
//                   value={testYear}
//                 />
//               )
//             );
//           })}
//       </Select>
//     </div>
//   );
// };

const getNormName = (val) => {
  if (val === 'publicservice') return 'public service';
  else if (val === 'ukworkingpop') return 'uk working pop';
  return val;
};

class HighLvlReports extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'reports', name: 'Reports', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      fileType: null,
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      profileArr: [],
      profilesVal: [],
      annexAWatsonEndYearArr: [],
      annexARanraEndYearArr: [],
      annexANeoPirEndYearArr: [],
      testSessionWatsonEndYearArr: [],
      testSessionRanraEndYearArr: [],
      cscHrWatsonEndYearArr: [],
      cscHrNeoPirEndYearArr: [],
      pslpCpiEndYearArr: [],
      pslpNeoPirEndYearArr: [],
      remainingProfiles: [],
      currTabType: 'upload',
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { currentYear } = user || {};
    this.props.getTestNormDetails();
    this.props.getTestsDetails();
    this.props.getSaticApplFilters();
    this.props
      .getDefaultNorms()
      .then((norms) => {
        if (norms && !norms.error) {
          const { defaultNorms } = this.props;
          let annexAWatsonEndYearArr = [];
          let annexARanraEndYearArr = [];
          let annexANeoPirEndYearArr = [];
          let testSessionWatsonEndYearArr = [];
          let testSessionRanraEndYearArr = [];
          let cscHrWatsonEndYearArr = [];
          let cscHrNeoPirEndYearArr = [];
          let pslpCpiEndYearArr = [];
          let pslpNeoPirEndYearArr = [];
          if (
            defaultNorms &&
            Array.isArray(defaultNorms) &&
            defaultNorms.length > 0
          ) {
            annexAWatsonEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'annex a' && testId == 4) {
                return true;
              }
              return false;
            });
            annexARanraEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'annex a' && testId == 1) {
                return true;
              }
              return false;
            });
            testSessionWatsonEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'test session' && testId == 4) {
                return true;
              }
              return false;
            });
            testSessionRanraEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'test session' && testId == 1) {
                return true;
              }
              return false;
            });
            cscHrWatsonEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'csc hr' && testId == 2) {
                return true;
              }
              return false;
            });
            cscHrNeoPirEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'csc hr' && testId == 3) {
                return true;
              }
              return false;
            });
            annexANeoPirEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'annex a' && testId == 3) {
                return true;
              }
              return false;
            });
            pslpCpiEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'pslp cpi' && testId == 3) {
                return true;
              }
              return false;
            });
            pslpNeoPirEndYearArr = defaultNorms.filter((norm) => {
              const { reportName, testId } = norm || {};
              if (reportName === 'pslp neo' && testId == 3) {
                return true;
              }
              return false;
            });
          }
          this.setState({
            annexAWatsonEndYearArr,
            annexARanraEndYearArr,
            annexANeoPirEndYearArr,
            testSessionWatsonEndYearArr,
            testSessionRanraEndYearArr,
            cscHrWatsonEndYearArr,
            cscHrNeoPirEndYearArr,
            pslpCpiEndYearArr,
            pslpNeoPirEndYearArr,
          });
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          errorNotif: true,
        });
      });

    this.handleCurrTabByRole();
    this.handleUpdateDefaultNorms();
    this.setState({ selectionYear: currentYear });
  }

  handleUpdateDefaultNorms = () => {
    this.handleChangeNormType('wgiiiNorm', 'pae_2020-2024');
    this.handleChangeNormType('anraNorm', 'pae_2020-2024');
    this.handleChangeNormType('neoNorm', 'pae_2015-2018_2023');
  };

  handleCurrTabByRole = () => {
    const { user } = this.props;
    const { roleId } = user || {};
    let tabContent = [];
    if (roleId) {
      if (roleId == 14) {
        tabContent = [
          {
            label: 'Upload',
            value: 'upload',
            onClick: () => {
              this.handleTabsClick('upload');
            },
          },
          {
            label: 'Upload Status',
            value: 'files',
            onClick: () => {
              this.handleTabsClick('files');
            },
          },
        ];
      } else {
        tabContent.push({
          label: 'Generate Report',
          value: 'generate',
          onClick: () => {
            this.handleTabsClick('generate');
          },
        });

        if (roleId == 1) {
          tabContent.push(
            {
              label: 'Upload',
              value: 'upload',
              onClick: () => {
                this.handleTabsClick('upload');
              },
            },
            {
              label: 'Upload Status',
              value: 'files',
              onClick: () => {
                this.handleTabsClick('files');
              },
            },
            {
              label: "President's Scholarship",
              value: 'psy-rat',
              onClick: () => {
                this.handleTabsClick('psy-rat');
              },
            }
          );
        }
      }
    }

    this.setState({ tabContent }, () =>
      this.handleTabsClick(tabContent?.[0]?.value)
    );
  };

  handleTabsClick = (tabType) => {
    const { tabContent } = this.state;

    this.setState(
      {
        selectedTab: tabContent?.findIndex((v) => v?.value === tabType) || 0,
        currTabType: tabType,
        isValidFileType: false,
      },
      () => {
        if (tabType === 'files') {
          this.handleGetApiReport();
        }
      }
    );
  };

  handleGetApiReport = (typeId) => {
    const { activePage, itemsPerPage, searchKey } = this.state;
    const data = {
      activePage,
      itemsPerPage,
      searchKey,
    };
    if (typeId) {
      data.typeId = typeId;
    }
    this.props.getHighLevelReportLogs(data);
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.ranraType == 2020) {
          this.setState({
            ranraEndYear: 2020,
          });
        }
      }
    );
  };

  handleReportChange = (e) => {
    this.handleUpdateDefaultNorms();
    this.setState({
      [e.target.name]: e.target.value,
      showTestSessionTable: false,
      showBulkUploadData: false,
    });
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    });
  };

  handleGetValidityYearsRes = () => {
    const { wgiiiScYears, ranraScYears, neopirScYears, watsonGlaserScYears } =
      this.state;
    const wgiiiScYearsArr = [];
    if (
      wgiiiScYears &&
      Array.isArray(wgiiiScYears) &&
      wgiiiScYears.length > 0
    ) {
      wgiiiScYears.forEach((result) => {
        const { value } = result || {};
        wgiiiScYearsArr.push(value);
      });
    }
    const watsonGlaserScYearsArr = [];
    if (
      watsonGlaserScYears &&
      Array.isArray(watsonGlaserScYears) &&
      watsonGlaserScYears.length > 0
    ) {
      watsonGlaserScYears.forEach((result) => {
        const { value } = result || {};
        watsonGlaserScYearsArr.push(value);
      });
    }
    const ranraScYearsArr = [];
    if (
      ranraScYears &&
      Array.isArray(ranraScYears) &&
      ranraScYears.length > 0
    ) {
      ranraScYears.forEach((result) => {
        const { value } = result || {};
        ranraScYearsArr.push(value);
      });
    }
    const neopirScYearsArr = [];
    if (
      neopirScYears &&
      Array.isArray(neopirScYears) &&
      neopirScYears.length > 0
    ) {
      neopirScYears.forEach((result) => {
        const { value } = result || {};
        neopirScYearsArr.push(value);
      });
    }
    return {
      wgiiiScYearsArr,
      watsonGlaserScYearsArr,
      ranraScYearsArr,
      neopirScYearsArr,
    };
  };

  handleUploadPsBriefingDeckDoc = (fileData) => {
    const { selectionYear } = this.state;
    const currFile = new FormData();
    currFile.append('attachment', fileData[0]);
    currFile.append('selectionYear', selectionYear && Number(selectionYear));
    this.props
      .uploadPSBriefingDeckDoc(currFile)
      .then((res) => {
        if (res && !res.error) {
          this.setState(
            {
              successNotif: true,
              notifMsg: `Your request is currently being processed, please click on files to view the status`,
              errorNotif: false,
              isValidFileType: false,
              isValidRemarks: false,
              remarks: '',
              fileType: 0,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                  notifMsg: '',
                });
              }, 3000);
            }
          );
        } else {
          this.setState(
            {
              uploadErr: true,
              isValidFileType: false,
              isValidRemarks: false,
              remarks: '',
              fileType: 0,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  uploadErr: false,
                });
              }, 5000);
            }
          );
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
          isValidFileType: false,
          isValidRemarks: false,
          remarks: '',
          fileType: 0,
        });
      });
  };

  handleSubmitUpload = (element) => {
    const { files, fileType, remarks, purpose, selectionYear } = this.state;

    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = fileType === 'ps-briefing-deck' ? ['pdf'] : ['csv'];
    const isFileAllowed = allowedTypes.includes(formattedExt);

    const { staticApplFilterInfo } = this.props;
    const { scenariosList } = staticApplFilterInfo || {};

    if (!fileType) {
      this.setState({
        isValidFileType: true,
      });
      return;
    }

    if (isFileAllowed) {
      const fileData = element.target.files;

      if (fileType === 'ps-briefing-deck') {
        this.handleUploadPsBriefingDeckDoc(fileData);
        return;
      }
      // const name = element.target.name;
      // let { selectedType = null, fileName = null } = {};
      // let { index } = this.state;

      // let fileTypeNameObj = {};
      // if (fileType) {
      //   fileTypeNameObj = uploadDataArr.find((r) => r.id == fileType);
      // }
      // const { type } = fileTypeNameObj || {};

      let fileTypeNameObj = {};
      if (fileType) {
        fileTypeNameObj = scenariosList.find((r) => r.scenarioId == fileType);
      }
      const { scenario, scenarioId } = fileTypeNameObj || {};
      // if (
      //   name !== null &&
      //   Array.isArray(files) &&
      //   files.length > Number(name)
      // ) {
      //   selectedType = files[name].selectedType;
      //   fileName = files[name].fileName;
      // }

      const currFile = new FormData();
      // const validityYears = this.handleGetValidityYearsRes();
      // const {
      //   wgiiiScYearsArr,
      //   watsonGlaserScYearsArr,
      //   ranraScYearsArr,
      //   neopirScYearsArr,
      // } = validityYears || {};
      currFile.append('excel', fileData[0]);
      // currFile.append('type', fileType);
      currFile.append('scenario', scenario);
      currFile.append('scenarioId', scenarioId);
      if (fileType == '4' || fileType == '5')
        currFile.append(
          'selectionYear',
          selectionYear && Number(selectionYear)
        );
      currFile.append('remarks', remarks);
      // currFile.append('purpose', purpose);
      // if (wgiiiScYearsArr) {
      //   currFile.append(
      //     'wgiii_validity_period',
      //     wgiiiScYearsArr.sort().toString().replaceAll(',', '-')
      //   );
      // }
      // if (watsonGlaserScYearsArr) {
      //   currFile.append(
      //     'wgcta_validity_period',
      //     watsonGlaserScYearsArr.sort().toString().replaceAll(',', '-')
      //   );
      // }
      // if (ranraScYearsArr) {
      //   currFile.append(
      //     'ranra_validity_period',
      //     ranraScYearsArr.sort().toString().replaceAll(',', '-')
      //   );
      // }
      // if (neopirScYearsArr) {
      //   currFile.append(
      //     'neopir_validity_period',
      //     neopirScYearsArr.sort().toString().replaceAll(',', '-')
      //   );
      // }

      this.props
        .uploadHighLevelReports(currFile)
        .then((res) => {
          if (res && !res.error) {
            this.setState(
              {
                successNotif: true,
                notifMsg: `${scenario} is currently being processed, please click on files to view the status`,
                errorNotif: false,
                isValidFileType: false,
                isValidRemarks: false,
                remarks: '',
                fileType: 0,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    successNotif: false,
                    notifMsg: '',
                  });
                }, 3000);
              }
            );
          } else {
            this.setState(
              {
                uploadErr: true,
                isValidFileType: false,
                isValidRemarks: false,
                remarks: '',
                fileType: 0,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    uploadErr: false,
                  });
                }, 5000);
              }
            );
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
            isValidFileType: false,
            isValidRemarks: false,
            remarks: '',
            fileType: 0,
          });
        });
    } else {
      window.alert(
        `Please upload valid file type, only ${
          fileType === 'ps-briefing-deck' ? '.pdf' : '.csv'
        } file is allowed.`
      );
    }
  };

  handleUploadPsychologicalRatings = (element) => {
    const { files, fileType, remarks } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('excel', fileData[0]);
    this.props
      .uploadPsychologicalRatings(currFile)
      .then((res) => {
        if (res && !res.error) {
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been uploaded successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  notifMsg: '',
                  successNotif: false,
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleUploadAssessmentCenter = (element) => {
    const { files, fileType, remarks } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('excel', fileData[0]);
    this.props
      .uploadAssessmentCenterData(currFile)
      .then((res) => {
        if (res && !res.error) {
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been uploaded successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  notifMsg: '',
                  successNotif: false,
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleUploadPeerRatings = (element) => {
    const { files, fileType, remarks } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('excel', fileData[0]);
    this.props
      .uploadPeerRatingsData(currFile)
      .then((res) => {
        if (res && !res.error) {
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been uploaded successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  notifMsg: '',
                  successNotif: false,
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleUploadBulkNric = (element) => {
    const { files, fileType, remarks } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('nrics', fileData[0]);
    this.props
      .uploadCandidateNrics(currFile)
      .then((res) => {
        if (res && !res.error) {
          const { profileIds } = res || {};
          let profilesVal = [];
          if (
            profileIds &&
            Array.isArray(profileIds) &&
            profileIds.length > 0
          ) {
            profilesVal = profileIds.map((prof) => {
              const { profileId, nric, fullName, genderId, dob } = prof || {};
              const d = {
                label: nric,
                value: profileId,
                gender: genderId,
                fullName,
                dob,
              };
              return d;
            });
            this.setState({
              profilesVal,
              isBulkUpload: true,
            });
          }
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleGetFileType = (scenarioId) => {
    const { staticApplFilterInfo } = this.props;
    const { scenariosList } = staticApplFilterInfo || {};
    let fileTypeNameObj = {};
    if (scenarioId) {
      fileTypeNameObj = scenariosList.find((r) => r.scenarioId == scenarioId);
    }
    const { scenario } = fileTypeNameObj || {};
    return scenario;
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetApiReport();
    });
  };

  handleFileTypeChange = (e) => {
    this.setState(
      {
        fileType: e.target.value,
      },
      () => {
        const { fileType } = this.state;
        this.handleGetApiReport(fileType);
      }
    );
  };

  handleGetNextProfiles = () => {
    let { generatedProfileIds, profilesVal } = this.state;
    let remainingProfiles = [];
    if (
      profilesVal &&
      Array.isArray(profilesVal) &&
      profilesVal.length > 0 &&
      generatedProfileIds &&
      Array.isArray(generatedProfileIds) &&
      generatedProfileIds.length > 0
    ) {
      remainingProfiles = profilesVal.filter(
        (res) => !generatedProfileIds.includes(res && res.value)
      );
    }
    const statusData = {};
    if (
      remainingProfiles &&
      Array.isArray(remainingProfiles) &&
      remainingProfiles.length > 0
    ) {
      const finalGeneratedProfiles = generatedProfileIds;
      remainingProfiles.forEach((res, idx) => {
        const { value } = res || {};
        if (idx <= 2) {
          this.handleGenerateFinalReportV2(value);
          statusData[`status-${value}`] = 'Processing';
          finalGeneratedProfiles.push(value);
        } else {
          statusData[`status-${value}`] = 'Waiting';
        }
      });
      this.setState({
        generatedProfileIds: finalGeneratedProfiles,
        ...statusData,
      });
    }
  };

  handleGenerateDownloadReport = (
    generateReportType,
    celeryData,
    fileName,
    profileId,
    error_code,
    message
  ) => {
    if (!error_code) {
      this.props
        .downloadHighLevelReports(generateReportType, celeryData)
        .then((pdf) => {
          if (pdf && !pdf.error) {
            const pdfDoc = URL.createObjectURL(pdf);
            const a = document.createElement('a');
            a.href = pdfDoc;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(pdfDoc);
              this.setState({
                showLoader: false,
              });
            }, 1000);
            if (profileId) {
              this.setState(
                {
                  [`status-${profileId}`]: 'Completed',
                },
                () => {
                  const { generatedProfileIds } = this.state;
                  let isAllProfilesCompleted = false;
                  if (
                    generatedProfileIds &&
                    Array.isArray(generatedProfileIds) &&
                    generatedProfileIds.length > 0
                  ) {
                    generatedProfileIds.forEach((prf) => {
                      if (this.state[`status-${prf}`] === 'Completed') {
                        isAllProfilesCompleted = true;
                      } else {
                        isAllProfilesCompleted = false;
                      }
                    });
                  }
                  if (isAllProfilesCompleted === true) {
                    this.handleGetNextProfiles();
                  }
                }
              );
            }
          } else {
            this.setState(
              {
                showLoader: false,
                [`status-${profileId}`]: 'Failed',
                [`err-msg-${profileId}`]: 'Error occurred while processing',
              },
              () => {
                this.handleGetNextProfilesStatusFailed(profileId);
              }
            );
          }
        })
        .catch((e) => {
          console.log('Error', e);
          this.setState(
            {
              showLoader: false,
              [`status-${profileId}`]: 'Failed',
              [`err-msg-${profileId}`]: 'Error occurred while processing',
            },
            () => {
              this.handleGetNextProfilesStatusFailed(profileId);
            }
          );
        });
    } else {
      this.setState(
        {
          [`status-${profileId}`]: 'Failed',
          [`err-msg-${profileId}`]: message,
        },
        () => {
          this.handleGetNextProfilesStatusFailed(profileId);
        }
      );
    }
  };

  handleGetNextProfilesStatusFailed = (profileId) => {
    if (profileId) {
      const { generatedProfileIds } = this.state;
      let isAllProfilesCompleted = false;
      if (
        generatedProfileIds &&
        Array.isArray(generatedProfileIds) &&
        generatedProfileIds.length > 0
      ) {
        generatedProfileIds.forEach((prf) => {
          if (
            this.state[`status-${prf}`] === 'Completed' ||
            this.state[`status-${prf}`] === 'Failed'
          ) {
            isAllProfilesCompleted = true;
          } else {
            isAllProfilesCompleted = false;
          }
        });
      }
      if (isAllProfilesCompleted === true) {
        this.handleGetNextProfiles();
      }
    }
  };

  handleGenerateBulkReports = () => {
    const { profilesVal, reportType } = this.state;
    if (profilesVal && Array.isArray(profilesVal) && profilesVal.length > 0) {
      const statusData = {};
      let generatedProfileIds = [];
      if (reportType == 2 || reportType == 7) {
        this.handleGenerateFinalReportV2();
      } else {
        profilesVal.forEach((res, index) => {
          const { value: profileId } = res || {};
          if (index <= 2) {
            this.handleGenerateFinalReportV2(profileId);
            statusData[`status-${profileId}`] = 'Processing';
            statusData.currentIndex = 1;
            generatedProfileIds.push(profileId);
          } else {
            statusData[`status-${profileId}`] = 'Waiting';
          }
          statusData[`err-msg-${profileId}`] = '-';
        });
      }
      this.setState({
        ...statusData,
        generatedProfileIds,
        showBulkUploadData: true,
        isProfileDataValid: false,
      });
    } else {
      this.setState({
        showBulkUploadData: false,
        isProfileDataValid: true,
      });
    }
  };

  handleGetPropertyKey = (key) => {
    let finalKey = '';
    if (key === 'dob') {
      finalKey = 'DOB';
    } else if (key === 'full_name') {
      finalKey = 'Name';
    } else if (key === 'neopir_test_date') {
      finalKey = 'NEOPIR Date';
    } else if (key === 'neopir_validity_period') {
      finalKey = 'NEOPIR Validity Period';
    } else if (key === 'nric_fin') {
      finalKey = 'NRIC';
    } else if (key === 'ranra_test_date') {
      finalKey = 'RANRA Test Date';
    } else if (
      key === 'ranra_validity_period' ||
      key === 'anra_validity_period'
    ) {
      finalKey = 'RANRA Validity Period';
    } else if (key === 'test_taken') {
      finalKey = 'Test Taken';
    } else if (key === 'wgcta_test_date') {
      finalKey = 'WGCTA Test Date';
    } else if (key === 'wgcta_validity_period') {
      finalKey = 'WGCTA Validity Period';
    } else if (key === 'wgiii_test_date') {
      finalKey = 'WGIII Test Date';
    } else if (key === 'wgiii_validity_period') {
      finalKey = 'WGIII Validity Period';
    } else {
      finalKey = key && key.toString().toUpperCase();
    }
    return finalKey;
  };

  // handleGenerateFinalReport = (profileId) => {
  //   const {
  //     reportType,
  //     ranraType,
  //     neoPirType,
  //     watsonType,
  //     profilesVal,
  //     watsonEndYear,
  //     ranraEndYear,
  //     neoPirEndYear,
  //     watsonName,
  //     ranraName,
  //     neoPirName,
  //     watsonTest,
  //   } = this.state;
  //   const { user } = this.props;
  //   const { userId } = user || {};
  //   const neoPirYear = neoPirType;
  //   const ranraYear = ranraType;
  //   const watsonYear = watsonType;
  //   const data = {};
  //   let type = '';
  //   const finalProfileId = !profileId
  //     ? profilesVal &&
  //       Array.isArray(profilesVal) &&
  //       profilesVal.length > 0 &&
  //       profilesVal[0].value
  //     : profileId;
  //   if (reportType == 1) {
  //     type = 'annexA';
  //     data.profile_id = finalProfileId;
  //   } else if (reportType == 2) {
  //     type = 'testSession';
  //     data.profile_id =
  //       profilesVal &&
  //       Array.isArray(profilesVal) &&
  //       profilesVal.length > 0 &&
  //       profilesVal.map((r) => r.value);
  //   } else if (reportType == 3) {
  //     type = 'cscHr';
  //     data.profile_id = finalProfileId;
  //   } else if (reportType == 4) {
  //     type = 'plspCpi';
  //     data.profile_id = finalProfileId;
  //   } else if (reportType == 5) {
  //     type = 'plspNeo';
  //     data.profile_id = finalProfileId;
  //   } else if (reportType == 6) {
  //     data.profile_id = finalProfileId;
  //     type = 'psy-test';
  //   } else if (reportType == 7) {
  //     type = 'candidate-screening';
  //     data.profile_id =
  //       profilesVal &&
  //       Array.isArray(profilesVal) &&
  //       profilesVal.length > 0 &&
  //       profilesVal.map((r) => r.value);
  //   }
  //   if (reportType == 7) {
  //     const validityYears = this.handleGetValidityYearsRes();
  //     const {
  //       wgiiiScYearsArr,
  //       watsonGlaserScYearsArr,
  //       ranraScYearsArr,
  //       neopirScYearsArr,
  //     } = validityYears || {};
  //     data.wgiii_validity_year =
  //       wgiiiScYearsArr &&
  //       Array.isArray(wgiiiScYearsArr) &&
  //       wgiiiScYearsArr.length > 0
  //         ? wgiiiScYearsArr
  //         : null;
  //     data.wgcta_validity_year =
  //       watsonGlaserScYearsArr &&
  //       Array.isArray(watsonGlaserScYearsArr) &&
  //       watsonGlaserScYearsArr.length > 0
  //         ? watsonGlaserScYearsArr
  //         : null;
  //     data.ranra_validity_year =
  //       ranraScYearsArr &&
  //       Array.isArray(ranraScYearsArr) &&
  //       ranraScYearsArr.length > 0
  //         ? ranraScYearsArr
  //         : null;
  //     data.neopi_validity_year =
  //       neopirScYearsArr &&
  //       Array.isArray(neopirScYearsArr) &&
  //       neopirScYearsArr.length > 0
  //         ? neopirScYearsArr
  //         : null;
  //   } else {
  //     if (watsonType) {
  //       const years = [
  //         2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
  //       ];
  //       const finalYears = years.filter(
  //         (y) =>
  //           y !== watsonYear &&
  //           y !== watsonEndYear &&
  //           y !== watsonYear - 1 &&
  //           y !== watsonYear - 2 &&
  //           y !== watsonYear - 3 &&
  //           y !== watsonYear - 4 &&
  //           y !== watsonYear - 5 &&
  //           y !== watsonYear - 6 &&
  //           y < watsonEndYear
  //       );
  //       if (watsonTest === 'wgiii') {
  //         const finalWgiiiYears = [
  //           watsonYear && Number(watsonYear),
  //           ...finalYears,
  //           watsonEndYear && Number(watsonEndYear),
  //         ];
  //         data.wgiii_name = watsonName;
  //         data.wgiii_year = [...new Set(finalWgiiiYears)];
  //       } else {
  //         data.wgcta_name = watsonName;
  //         if (reportType == 3 && watsonName === 'ace') {
  //           data.wgcta_year = watsonEndYear && Number(watsonEndYear);
  //         } else {
  //           data.wgcta_year = [
  //             watsonYear && Number(watsonYear),
  //             ...finalYears,
  //             watsonEndYear && Number(watsonEndYear),
  //           ];
  //         }
  //       }
  //     }
  //     if (ranraType) {
  //       const years = [
  //         2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
  //       ];
  //       const finalYears = years.filter(
  //         (y) =>
  //           y !== ranraYear &&
  //           y !== ranraEndYear &&
  //           y !== ranraYear - 1 &&
  //           y !== ranraYear - 2 &&
  //           y !== ranraYear - 3 &&
  //           y !== ranraYear - 4 &&
  //           y !== ranraYear - 5 &&
  //           y !== ranraYear - 6 &&
  //           y < ranraEndYear
  //       );
  //       let finalRanrYears = [];
  //       if (ranraEndYear == ranraYear) {
  //         finalRanrYears = [ranraYear];
  //       } else {
  //         finalRanrYears = [
  //           ranraYear && Number(ranraYear),
  //           ...finalYears,
  //           ranraEndYear && Number(ranraEndYear),
  //         ];
  //       }
  //       data.ranra_name = ranraName;
  //       data.ranra_year = [...new Set(finalRanrYears)];
  //     }
  //     if (neoPirType) {
  //       const years = [2014, 2015, 2016, 2017, 2018, 2019];
  //       const finalYears = years.filter(
  //         (y) =>
  //           y !== neoPirYear &&
  //           y !== neoPirEndYear &&
  //           y !== neoPirYear - 1 &&
  //           y !== neoPirYear - 2 &&
  //           y !== neoPirYear - 3 &&
  //           y !== neoPirYear - 4 &&
  //           y !== neoPirYear - 5 &&
  //           y < neoPirEndYear
  //       );
  //       data.neopi_name = neoPirName;
  //       if (reportType == 4 && neoPirName === 'public service') {
  //         data.neopi_year = [2022, 2022];
  //       } else {
  //         data.neopi_year = [
  //           neoPirYear && Number(neoPirYear),
  //           ...finalYears,
  //           neoPirEndYear && Number(neoPirEndYear),
  //         ];
  //       }
  //     }
  //   }

  //   this.setState(
  //     {
  //       showLoader: profileId ? false : true,
  //     },
  //     () => {
  //       this.props
  //         .generateHighLvlReports(type, data, userId)
  //         .then((res) => {
  //           if (res && !res.error) {
  //             const { task_id } = this.props || {};
  //             const interval = setInterval(() => {
  //               this.props
  //                 .generateCeleryTask(task_id)
  //                 .then((task) => {
  //                   if (task && !task.error) {
  //                     const { celeryData } = this.props || {};
  //                     const { error_code, message } = celeryData || {};
  //                     let generateReportType = '';
  //                     let fileName = `reports-${Date.now()}`;
  //                     if (reportType == 1) {
  //                       generateReportType = 'annex-a';
  //                       fileName = `Annex-A-${Date.now()}`;
  //                     } else if (reportType == 2) {
  //                       generateReportType = 'test-session-export';
  //                       fileName = `Test-Session-Export-${Date.now()}`;
  //                     } else if (reportType == 3) {
  //                       let currProfile = {};
  //                       if (
  //                         profilesVal &&
  //                         Array.isArray(profilesVal) &&
  //                         profilesVal.length > 0
  //                       ) {
  //                         currProfile = profilesVal.find(
  //                           (prof) => prof && prof.value == finalProfileId
  //                         );
  //                       }
  //                       const { fullName } = currProfile || {};
  //                       generateReportType = 'hr-report';
  //                       fileName = fullName
  //                         ? `CSC-HR-${capitalCase(fullName)}`
  //                         : 'CSC-HR';
  //                     } else if (reportType == 4) {
  //                       generateReportType = 'personality-report';
  //                       fileName = `Personality-Profile-${Date.now()}`;
  //                     } else if (reportType == 5) {
  //                       generateReportType = 'psychological-report';
  //                       fileName = `PSLP-NEO-${Date.now()}`;
  //                     } else if (reportType == 6) {
  //                       generateReportType = 'psychological-test';
  //                       fileName = `Psychological-Test-${Date.now()}`;
  //                     }
  //                     if (reportType == 1) {
  //                       let currProfile = {};
  //                       if (
  //                         profilesVal &&
  //                         Array.isArray(profilesVal) &&
  //                         profilesVal.length > 0
  //                       ) {
  //                         currProfile = profilesVal.find(
  //                           (prof) => prof && prof.value == finalProfileId
  //                         );
  //                       }
  //                       const { gender, fullName, label } = currProfile || {};
  //                       const finalData = {
  //                         decryptedGender: gender,
  //                         decryptedFullName: fullName
  //                           ? capitalCase(fullName)
  //                           : '',
  //                         decryptedNric: label,
  //                         watsonYear,
  //                         ranraYear,
  //                         ranraEndYear,
  //                         ranraName,
  //                         neoPirEndYear,
  //                         neoPirName,
  //                         neoPirYear,
  //                         watsonEndYear,
  //                         watsonName,
  //                         ...celeryData,
  //                       };
  //                       this.handleGenerateDownloadReport(
  //                         generateReportType,
  //                         finalData,
  //                         fileName,
  //                         finalProfileId,
  //                         error_code,
  //                         message
  //                       );
  //                     } else if (reportType == 3) {
  //                       let currProfile = {};
  //                       if (
  //                         profilesVal &&
  //                         Array.isArray(profilesVal) &&
  //                         profilesVal.length > 0
  //                       ) {
  //                         currProfile = profilesVal.find(
  //                           (prof) => prof && prof.value == finalProfileId
  //                         );
  //                       }
  //                       const { gender, fullName, label } = currProfile || {};
  //                       const finalData = {
  //                         ...celeryData,
  //                         decryptedGender: gender,
  //                         decryptedFullName: fullName
  //                           ? capitalCase(fullName)
  //                           : '',
  //                         decryptedNric: label,
  //                         neoPirEndYear,
  //                         neoPirName:
  //                           neoPirName === 'public service'
  //                             ? 'Public Service'
  //                             : neoPirName === 'uk working pop'
  //                             ? 'Uk Working Pop'
  //                             : neoPirName &&
  //                               neoPirName.toString().toUpperCase(),
  //                         neoPirYear,
  //                         url: `http://localhost:3000/public/csc/reports/cultural-report?task_id=${task_id}`,
  //                       };
  //                       this.handleGenerateDownloadReport(
  //                         generateReportType,
  //                         finalData,
  //                         fileName,
  //                         finalProfileId,
  //                         error_code,
  //                         message
  //                       );
  //                     } else if (reportType == 5) {
  //                       let currProfile = {};
  //                       if (
  //                         profilesVal &&
  //                         Array.isArray(profilesVal) &&
  //                         profilesVal.length > 0
  //                       ) {
  //                         currProfile = profilesVal.find(
  //                           (prof) => prof && prof.value == finalProfileId
  //                         );
  //                       }
  //                       const { gender, fullName, label } = currProfile || {};
  //                       const finalData = {
  //                         ...celeryData,
  //                         neoPirEndYear,
  //                         neoPirName:
  //                           neoPirName === 'public service'
  //                             ? 'Public Service'
  //                             : neoPirName === 'uk working pop'
  //                             ? 'Uk Working Pop'
  //                             : neoPirName &&
  //                               neoPirName.toString().toUpperCase(),
  //                         neoPirYear,
  //                         decryptedGender: gender,
  //                         decryptedFullName: fullName
  //                           ? capitalCase(fullName)
  //                           : fullName,
  //                         decryptedNric: label,
  //                         url: `http://localhost:3000/public/csc/reports/psychological-profile?task_id=${task_id}`,
  //                         outcomeUrl: `http://localhost:3000/public/csc/reports/psychological-profile-outcome?task_id=${task_id}`,
  //                       };
  //                       this.handleGenerateDownloadReport(
  //                         generateReportType,
  //                         finalData,
  //                         fileName,
  //                         finalProfileId,
  //                         error_code,
  //                         message
  //                       );
  //                     } else if (reportType == 4) {
  //                       let currProfile = {};
  //                       if (
  //                         profilesVal &&
  //                         Array.isArray(profilesVal) &&
  //                         profilesVal.length > 0
  //                       ) {
  //                         currProfile = profilesVal.find(
  //                           (prof) => prof && prof.value == finalProfileId
  //                         );
  //                       }
  //                       const { gender, fullName, label } = currProfile || {};
  //                       const finalData = {
  //                         ...celeryData,
  //                         decryptedGender: gender,
  //                         decryptedFullName: fullName
  //                           ? capitalCase(fullName)
  //                           : '',
  //                         decryptedNric: label,
  //                         neoPirEndYear,
  //                         neoPirName:
  //                           neoPirName === 'public service'
  //                             ? 'Public Service'
  //                             : neoPirName === 'uk working pop'
  //                             ? 'Uk Working Pop'
  //                             : neoPirName &&
  //                               neoPirName.toString().toUpperCase(),
  //                         neoPirYear,
  //                         url: `http://localhost:3000/public/csc/reports/personality-profile?task_id=${task_id}`,
  //                         outcomeUrl: `http://localhost:3000/public/csc/reports/personality-profile-outcome?task_id=${task_id}`,
  //                       };
  //                       this.handleGenerateDownloadReport(
  //                         generateReportType,
  //                         finalData,
  //                         fileName,
  //                         finalProfileId,
  //                         error_code,
  //                         message
  //                       );
  //                     } else if (reportType == 6) {
  //                       let currProfile = {};
  //                       if (
  //                         profilesVal &&
  //                         Array.isArray(profilesVal) &&
  //                         profilesVal.length > 0
  //                       ) {
  //                         currProfile = profilesVal.find(
  //                           (prof) => prof && prof.value == finalProfileId
  //                         );
  //                       }
  //                       const { gender, fullName, label } = currProfile || {};
  //                       const finalData = {
  //                         ...celeryData,
  //                         decryptedGender: gender,
  //                         decryptedFullName: fullName
  //                           ? capitalCase(fullName)
  //                           : '',
  //                         decryptedNric: label,
  //                         ranraName,
  //                         ranraType,
  //                         ranraEndYear,
  //                         neoPirEndYear,
  //                         neoPirName:
  //                           neoPirName === 'public service'
  //                             ? 'Public Service'
  //                             : neoPirName === 'uk working pop'
  //                             ? 'Uk Working Pop'
  //                             : neoPirName &&
  //                               neoPirName.toString().toUpperCase(),
  //                         neoPirYear,
  //                         watsonName,
  //                         watsonEndYear,
  //                         watsonYear,
  //                         url: `http://localhost:3000/public/csc/reports/psychological-profile?task_id=${task_id}`,
  //                         outcomeUrl: `http://localhost:3000/public/csc/reports/psychological-profile-outcome?task_id=${task_id}`,
  //                       };
  //                       this.handleGenerateDownloadReport(
  //                         generateReportType,
  //                         finalData,
  //                         fileName,
  //                         finalProfileId,
  //                         error_code,
  //                         message
  //                       );
  //                     } else if (reportType == 7) {
  //                       let testSessionTableData = [];
  //                       const { profilesVal } = this.state;
  //                       const csvHeader = [];
  //                       const obj = {};
  //                       if (!error_code) {
  //                         if (
  //                           celeryData &&
  //                           Array.isArray(celeryData) &&
  //                           celeryData.length > 0
  //                         ) {
  //                           testSessionTableData = celeryData.map((d) => {
  //                             const {
  //                               profile_id,
  //                               neopir_test_date,
  //                               ranra_test_date,
  //                               wgcta_test_date,
  //                               wgiii_test_date,
  //                               wgiii_validity_period,
  //                               wgcta_validity_period,
  //                               anra_validity_period,
  //                               neopir_validity_period,
  //                             } = d || {};
  //                             let filteredProfiles = {};
  //                             if (
  //                               profilesVal &&
  //                               Array.isArray(profilesVal) &&
  //                               profilesVal.length > 0
  //                             ) {
  //                               filteredProfiles = profilesVal.find(
  //                                 (pro) => pro && pro.value == profile_id
  //                               );
  //                             }
  //                             const finalAnraValidityPer =
  //                               anra_validity_period &&
  //                               Array.isArray(anra_validity_period) &&
  //                               anra_validity_period.length > 0
  //                                 ? anra_validity_period.join(' , ')
  //                                 : '';
  //                             const finalNeopirValidityPer =
  //                               neopir_validity_period &&
  //                               Array.isArray(neopir_validity_period) &&
  //                               neopir_validity_period.length > 0
  //                                 ? neopir_validity_period.join(' , ')
  //                                 : '';
  //                             const finalWgctaValPere =
  //                               wgcta_validity_period &&
  //                               Array.isArray(wgcta_validity_period) &&
  //                               wgcta_validity_period.length > 0
  //                                 ? wgcta_validity_period.join(' , ')
  //                                 : '';
  //                             const finalWgiiiValPer =
  //                               wgiii_validity_period &&
  //                               Array.isArray(wgiii_validity_period) &&
  //                               wgiii_validity_period.length > 0
  //                                 ? wgiii_validity_period.join(' , ')
  //                                 : '';
  //                             const { fullName, gender, label, dob } =
  //                               filteredProfiles || {};
  //                             const finalObj = {
  //                               ...d,
  //                               full_name: fullName
  //                                 ? capitalCase(fullName)
  //                                 : '',
  //                               gender:
  //                                 gender &&
  //                                 (gender == 1
  //                                   ? 'Male'
  //                                   : gender == 2
  //                                   ? 'Female'
  //                                   : 'Unknown'),
  //                               nric_fin: label,
  //                               dob: dob && moment(dob).format('DD/MM/YYYY'),
  //                               wgcta_test_date:
  //                                 wgcta_test_date &&
  //                                 moment(wgcta_test_date).format('DD/MM/YYYY'),
  //                               wgiii_test_date:
  //                                 wgiii_test_date &&
  //                                 moment(wgiii_test_date).format('DD/MM/YYYY'),
  //                               neopir_test_date:
  //                                 neopir_test_date &&
  //                                 moment(neopir_test_date).format('DD/MM/YYYY'),
  //                               ranra_test_date:
  //                                 ranra_test_date &&
  //                                 moment(ranra_test_date).format('DD/MM/YYYY'),
  //                               anra_validity_period: finalAnraValidityPer,
  //                               neopir_validity_period: finalNeopirValidityPer,
  //                               wgcta_validity_period: finalWgctaValPere,
  //                               wgiii_validity_period: finalWgiiiValPer,
  //                             };
  //                             return finalObj;
  //                           });
  //                           if (
  //                             candidateScreeningKeys &&
  //                             Array.isArray(candidateScreeningKeys) &&
  //                             candidateScreeningKeys.length > 0
  //                           ) {
  //                             candidateScreeningKeys.forEach((key) => {
  //                               obj.label = this.handleGetPropertyKey(key);
  //                               obj.key = key;
  //                               const finalObj = cloneDeep(obj);
  //                               csvHeader.push(finalObj);
  //                             });
  //                           }
  //                           this.setState({
  //                             showCandidateScreeningBtn: true,
  //                             testSessionTableData,
  //                             showLoader: false,
  //                             csvHeader,
  //                           });
  //                         }
  //                       } else {
  //                         this.setState({
  //                           errorNotif: true,
  //                           errMsg: message || 'Unknown error occurred',
  //                           showLoader: false,
  //                         });
  //                       }
  //                     } else if (reportType == 2) {
  //                       let testSessionTableData = [];
  //                       const { profilesVal } = this.state;
  //                       if (
  //                         celeryData &&
  //                         Array.isArray(celeryData) &&
  //                         celeryData.length > 0
  //                       ) {
  //                         testSessionTableData = celeryData.map(
  //                           (testSD, idx) => {
  //                             const {
  //                               overall_lms_code,
  //                               overall_oms_code,
  //                               overall_sgs_code,
  //                               ranra_pctl,
  //                               rn_lms_code,
  //                               rn_oms_code,
  //                               rn_sgs_code,
  //                               wg_lms_code,
  //                               wg_oms_code,
  //                               wg_sgs_code,
  //                               wgcta_pctl,
  //                               wg_pctl,
  //                               profile_id,
  //                             } = testSD || {};
  //                             let filteredProfiles = {};
  //                             if (
  //                               profilesVal &&
  //                               Array.isArray(profilesVal) &&
  //                               profilesVal.length > 0
  //                             ) {
  //                               filteredProfiles = profilesVal.find(
  //                                 (pro) => pro && pro.value == profile_id
  //                               );
  //                             }
  //                             const { fullName, gender, label } =
  //                               filteredProfiles || {};
  //                             const finalTestSessData = {
  //                               no: idx + 1,
  //                               id: idx + 1,
  //                               full_name: fullName
  //                                 ? capitalCase(fullName)
  //                                 : '',
  //                               gender: gender == 1 ? 'Male' : 'Female',
  //                               nric_fin: label,
  //                               overall_lms_code,
  //                               overall_oms_code,
  //                               overall_sgs_code,
  //                               ranra_pctl,
  //                               rn_lms_code,
  //                               rn_oms_code,
  //                               rn_sgs_code,
  //                               wg_lms_code,
  //                               wg_oms_code,
  //                               wg_sgs_code,
  //                               wgcta_pctl: wg_pctl || wgcta_pctl,
  //                             };
  //                             return finalTestSessData;
  //                           }
  //                         );
  //                       }
  //                       this.setState({
  //                         showTestSessionTable: true,
  //                         testSessionTableData,
  //                         showLoader: false,
  //                       });
  //                     }
  //                   } else {
  //                     this.setState({
  //                       [`status-${finalProfileId}`]: 'Failed',
  //                       [`err-msg-${profileId}`]:
  //                         'Error occurred while processing',
  //                     });
  //                   }
  //                 })
  //                 .catch((e) => {
  //                   console.log('Error', e);
  //                   this.setState({
  //                     [`status-${finalProfileId}`]: 'Failed',
  //                     [`err-msg-${profileId}`]:
  //                       'Error occurred while processing',
  //                   });
  //                 });
  //             }, 4000);
  //             setTimeout(() => {
  //               clearTimeout(interval);
  //             }, 4000);
  //           } else {
  //             this.setState({
  //               [`status-${finalProfileId}`]: 'Failed',
  //               [`err-msg-${profileId}`]: 'Error occurred while processing',
  //             });
  //           }
  //         })
  //         .catch((e) => {
  //           console.log('Error', e);
  //           this.setState({
  //             showLoader: false,
  //             [`status-${finalProfileId}`]: 'Failed',
  //             [`err-msg-${profileId}`]: 'Error occurred while processing',
  //           });
  //         });
  //     }
  //   );
  //   // this.setState(
  //   //   {
  //   //     isValidReportType: reportType ? false : true,
  //   //     isValidNeoPirType: neoPirType ? false : true,
  //   //     isValidRanraType: ranraType ? false : true,
  //   //     isValidWatson: watsonType ? false : true,
  //   //     isValidNeoPirName: neoPirName ? false : true,
  //   //     isValidRanraName: ranraName ? false : true,
  //   //     isValidWatsonName: watsonName ? false : true,
  //   //     isValidWatsonEndYear: watsonEndYear ? false : true,
  //   //     isValidRanraEndYear: ranraEndYear ? false : true,
  //   //     isProfileDataValid:
  //   //       profilesVal && Array.isArray(profilesVal) && profilesVal.length > 0
  //   //         ? false
  //   //         : true,
  //   //     isValidNeoPirEndYear: neoPirEndYear ? false : true,
  //   //   },
  //   //   () => {
  //   //     const {
  //   //       isValidReportType,
  //   //       isValidNeoPirType,
  //   //       isValidRanraType,
  //   //       isValidWatson,
  //   //       isValidWatsonEndYear,
  //   //       isValidRanraEndYear,
  //   //       isValidNeoPirEndYear,
  //   //       isValidWatsonName,
  //   //       isValidRanraName,
  //   //       isValidNeoPirName,
  //   //       profilesVal,
  //   //     } = this.state;
  //   //     let validFields = false;
  //   //     if (
  //   //       (reportType == 1 || reportType == 6) &&
  //   //       !isValidReportType &&
  //   //       !isValidRanraType &&
  //   //       !isValidNeoPirType &&
  //   //       !isValidWatson &&
  //   //       !isValidWatsonEndYear &&
  //   //       !isValidRanraEndYear &&
  //   //       !isValidNeoPirEndYear &&
  //   //       !isValidWatsonName &&
  //   //       !isValidRanraName &&
  //   //       !isValidNeoPirName
  //   //     ) {
  //   //       validFields = true;
  //   //     } else if (
  //   //       reportType == 2 &&
  //   //       !isValidReportType &&
  //   //       !isValidWatson &&
  //   //       !isValidWatsonEndYear &&
  //   //       !isValidRanraEndYear &&
  //   //       !isValidWatsonName &&
  //   //       !isValidRanraName &&
  //   //       !isValidRanraType
  //   //     ) {
  //   //       validFields = true;
  //   //     } else if (
  //   //       reportType == 3 &&
  //   //       !isValidWatson &&
  //   //       !isValidWatsonEndYear &&
  //   //       !isValidNeoPirEndYear &&
  //   //       !isValidWatsonName &&
  //   //       !isValidNeoPirType &&
  //   //       !isValidNeoPirName
  //   //     ) {
  //   //       validFields = true;
  //   //     } else if (
  //   //       reportType == 4 &&
  //   //       !isValidNeoPirEndYear &&
  //   //       !isValidNeoPirType &&
  //   //       !isValidNeoPirName
  //   //     ) {
  //   //       validFields = true;
  //   //     } else if (
  //   //       reportType == 5 &&
  //   //       !isValidNeoPirEndYear &&
  //   //       !isValidNeoPirType &&
  //   //       !isValidNeoPirName
  //   //     ) {
  //   //       validFields = true;
  //   //     } else {
  //   //       validFields = false;
  //   //     }
  //   //     if (validFields) {

  //   //     }
  //   //   }
  //   // );
  // };

  handleGenerateFinalReportV2 = (profileId) => {
    const {
      reportType,
      profilesVal,
      wgiii_name,
      wgiii_year,
      wgiii_display_year = [],
      ranra_name,
      ranra_year,
      ranra_display_year = [],
      neopi_name,
      neopi_year,
      neopi_display_year = [],
    } = this.state;
    const { user } = this.props;
    const { userId } = user || {};

    const data = {};
    let type = '';
    const finalProfileId = !profileId
      ? profilesVal &&
        Array.isArray(profilesVal) &&
        profilesVal.length > 0 &&
        profilesVal[0].value
      : profileId;
    if (reportType == 1) {
      type = 'annexA';
      data.profile_id = finalProfileId;
    } else if (reportType == 2) {
      type = 'testSession';
      data.profile_id =
        profilesVal &&
        Array.isArray(profilesVal) &&
        profilesVal.length > 0 &&
        profilesVal.map((r) => r.value);
    } else if (reportType == 3) {
      type = 'cscHr';
      data.profile_id = finalProfileId;
    } else if (reportType == 4) {
      type = 'plspCpi';
      data.profile_id = finalProfileId;
    } else if (reportType == 5) {
      type = 'plspNeo';
      data.profile_id = finalProfileId;
    } else if (reportType == 6) {
      data.profile_id = finalProfileId;
      type = 'psy-test';
    } else if (reportType == 7) {
      type = 'candidate-screening';
      data.profile_id =
        profilesVal &&
        Array.isArray(profilesVal) &&
        profilesVal.length > 0 &&
        profilesVal.map((r) => r.value);
    } else if (reportType == 8) {
      data.profile_id = finalProfileId;
      type = 'neopi-cpi';
    }
    if (reportType == 7) {
      const validityYears = this.handleGetValidityYearsRes();
      const {
        wgiiiScYearsArr,
        watsonGlaserScYearsArr,
        ranraScYearsArr,
        neopirScYearsArr,
      } = validityYears || {};
      data.wgiii_validity_year =
        wgiiiScYearsArr &&
        Array.isArray(wgiiiScYearsArr) &&
        wgiiiScYearsArr.length > 0
          ? wgiiiScYearsArr
          : null;
      data.wgcta_validity_year =
        watsonGlaserScYearsArr &&
        Array.isArray(watsonGlaserScYearsArr) &&
        watsonGlaserScYearsArr.length > 0
          ? watsonGlaserScYearsArr
          : null;
      data.ranra_validity_year =
        ranraScYearsArr &&
        Array.isArray(ranraScYearsArr) &&
        ranraScYearsArr.length > 0
          ? ranraScYearsArr
          : null;
      data.neopi_validity_year =
        neopirScYearsArr &&
        Array.isArray(neopirScYearsArr) &&
        neopirScYearsArr.length > 0
          ? neopirScYearsArr
          : null;
    }

    if (reportType == 2 || reportType == 6) {
      data.wgiii_name = wgiii_name;
      data.wgiii_year = wgiii_year;
      data.ranra_name = ranra_name;
      data.ranra_year = ranra_year;
      data.neopi_name = neopi_name;
      data.neopi_year = neopi_year;
    } else if (reportType == 3) {
      data.wgcta_name = 'ace';
      data.wgcta_year = 2020;
      data.neopi_name = neopi_name;
      data.neopi_year = neopi_year;
    } else if (reportType == 4 || reportType == 5 || reportType == 8) {
      data.neopi_name = neopi_name;
      data.neopi_year = neopi_year;
    }

    this.setState(
      {
        showLoader: profileId ? false : true,
      },
      () => {
        this.props
          .generateHighLvlReports(type, data, userId)
          .then((res) => {
            if (res && !res.error && !res.error_code) {
              const { task_id } = this.props || {};
              const interval = setInterval(() => {
                this.props
                  .generateCeleryTask(task_id)
                  .then((task) => {
                    if (task && !task.error) {
                      const { celeryData } = this.props || {};
                      const { error_code, message } = celeryData || {};
                      let generateReportType = '';
                      let fileName = `reports-${Date.now()}`;

                      const ranraName = ranra_name;
                      const ranraYear = arrMap(ranra_year)?.[0];
                      const ranraEndYear =
                        arrMap(ranra_year)?.[ranra_year.length - 1];
                      const neoPirName = neopi_name;
                      const neoPirYear = arrMap(neopi_year)?.[0];
                      const neoPirEndYear =
                        arrMap(neopi_year)?.[neopi_year.length - 1];
                      const wgiiiName = wgiii_name;
                      if (reportType == 1) {
                        generateReportType = 'annex-a';
                        fileName = `Annex-A-${Date.now()}`;
                      } else if (reportType == 2) {
                        generateReportType = 'test-session-export';
                        fileName = `PT-Percentiles-And-CutOff-Bandings-Export-${Date.now()}`;
                      } else if (reportType == 3) {
                        let currProfile = {};
                        if (
                          profilesVal &&
                          Array.isArray(profilesVal) &&
                          profilesVal.length > 0
                        ) {
                          currProfile = profilesVal.find(
                            (prof) => prof && prof.value == finalProfileId
                          );
                        }
                        const { fullName } = currProfile || {};
                        generateReportType = 'hr-report';
                        fileName = fullName
                          ? `ACE: PT + NEO + CPI-${capitalCase(fullName)}`
                          : 'ACE: PT + NEO + CPI';
                      } else if (reportType == 4) {
                        generateReportType = 'cultural-report';
                        fileName = `CPI-${Date.now()}`;
                      } else if (reportType == 5) {
                        generateReportType = 'psychological-report';
                        fileName = `NEO-${Date.now()}`;
                      } else if (reportType == 8) {
                        generateReportType = 'psychological-cultural-report';
                        fileName = `NEO+CPI-${Date.now()}`;
                      } else if (reportType == 6) {
                        generateReportType = 'psychological-test';
                        fileName = `Psychological-Test-AT+NEO-${Date.now()}`;
                      }
                      if (reportType == 1) {
                        let currProfile = {};
                        if (
                          profilesVal &&
                          Array.isArray(profilesVal) &&
                          profilesVal.length > 0
                        ) {
                          currProfile = profilesVal.find(
                            (prof) => prof && prof.value == finalProfileId
                          );
                        }
                        const { gender, fullName, label } = currProfile || {};
                        const finalData = {
                          decryptedGender: gender,
                          decryptedFullName: fullName
                            ? capitalCase(fullName)
                            : '',
                          decryptedNric: label,
                          ranraName,
                          ranraYear,
                          ranraEndYear,
                          neoPirName,
                          neoPirYear,
                          neoPirEndYear,
                          ...celeryData,
                        };
                        this.handleGenerateDownloadReport(
                          generateReportType,
                          finalData,
                          fileName,
                          finalProfileId,
                          error_code,
                          message
                        );
                      } else if (reportType == 3) {
                        let currProfile = {};
                        if (
                          profilesVal &&
                          Array.isArray(profilesVal) &&
                          profilesVal.length > 0
                        ) {
                          currProfile = profilesVal.find(
                            (prof) => prof && prof.value == finalProfileId
                          );
                        }
                        const { gender, fullName, label } = currProfile || {};
                        const finalData = {
                          ...celeryData,
                          decryptedGender: gender,
                          decryptedFullName: fullName
                            ? capitalCase(fullName)
                            : '',
                          decryptedNric: label,
                          neoPirEndYear,
                          neoPirName:
                            neoPirName === 'public service'
                              ? 'Public Service'
                              : neoPirName === 'uk working pop'
                              ? 'Uk Working Population'
                              : neoPirName &&
                                neoPirName.toString().toUpperCase(),
                          neoPirYear,
                          neopi_display_year,
                          url: `http://localhost:3000/public/csc/reports/cultural-report?task_id=${task_id}`,
                        };
                        this.handleGenerateDownloadReport(
                          generateReportType,
                          finalData,
                          fileName,
                          finalProfileId,
                          error_code,
                          message
                        );
                      } else if (reportType == 5) {
                        let currProfile = {};
                        if (
                          profilesVal &&
                          Array.isArray(profilesVal) &&
                          profilesVal.length > 0
                        ) {
                          currProfile = profilesVal.find(
                            (prof) => prof && prof.value == finalProfileId
                          );
                        }
                        const { gender, fullName, label } = currProfile || {};
                        const finalData = {
                          ...celeryData,
                          neoPirEndYear,
                          neoPirName:
                            neoPirName === 'public service'
                              ? 'Public Service'
                              : neoPirName === 'uk working pop'
                              ? 'Uk Working Population'
                              : neoPirName &&
                                neoPirName.toString().toUpperCase(),
                          neoPirYear,
                          neopi_display_year,
                          decryptedGender: gender,
                          decryptedFullName: fullName
                            ? capitalCase(fullName)
                            : fullName,
                          decryptedNric: label,
                          url: `http://localhost:3000/public/csc/reports/psychological-profile?task_id=${task_id}`,
                          outcomeUrl: `http://localhost:3000/public/csc/reports/psychological-profile-outcome?task_id=${task_id}`,
                        };
                        this.handleGenerateDownloadReport(
                          generateReportType,
                          finalData,
                          fileName,
                          finalProfileId,
                          error_code,
                          message
                        );
                      } else if (reportType == 4) {
                        let currProfile = {};
                        if (
                          profilesVal &&
                          Array.isArray(profilesVal) &&
                          profilesVal.length > 0
                        ) {
                          currProfile = profilesVal.find(
                            (prof) => prof && prof.value == finalProfileId
                          );
                        }
                        const { gender, fullName, label } = currProfile || {};
                        const finalData = {
                          ...celeryData,
                          decryptedGender: gender,
                          decryptedFullName: fullName
                            ? capitalCase(fullName)
                            : '',
                          decryptedNric: label,
                          neoPirEndYear,
                          neoPirName:
                            neoPirName === 'public service'
                              ? 'Public Service'
                              : neoPirName === 'uk working pop'
                              ? 'Uk Working Population'
                              : neoPirName &&
                                neoPirName.toString().toUpperCase(),
                          neoPirYear,
                          neopi_display_year,
                          url: `http://localhost:3000/public/csc/reports/cultural-report?task_id=${task_id}`,
                        };
                        this.handleGenerateDownloadReport(
                          generateReportType,
                          finalData,
                          fileName,
                          finalProfileId,
                          error_code,
                          message
                        );
                      } else if (reportType == 8) {
                        let currProfile = {};
                        if (
                          profilesVal &&
                          Array.isArray(profilesVal) &&
                          profilesVal.length > 0
                        ) {
                          currProfile = profilesVal.find(
                            (prof) => prof && prof.value == finalProfileId
                          );
                        }
                        const { gender, fullName, label } = currProfile || {};
                        const finalData = {
                          ...celeryData,
                          decryptedGender: gender,
                          decryptedFullName: fullName
                            ? capitalCase(fullName)
                            : '',
                          decryptedNric: label,
                          neoPirEndYear,
                          neoPirName:
                            neoPirName === 'public service'
                              ? 'Public Service'
                              : neoPirName === 'uk working pop'
                              ? 'Uk Working Population'
                              : neoPirName &&
                                neoPirName.toString().toUpperCase(),
                          neoPirYear,
                          neopi_display_year,
                          url: `http://localhost:3000/public/csc/reports/psychological-profile?task_id=${task_id}`,
                          outcomeUrl: `http://localhost:3000/public/csc/reports/psychological-profile-outcome?task_id=${task_id}`,
                          neopi_url: `http://localhost:3000/public/csc/reports/cultural-report?task_id=${task_id}`,
                        };
                        this.handleGenerateDownloadReport(
                          generateReportType,
                          finalData,
                          fileName,
                          finalProfileId,
                          error_code,
                          message
                        );
                      } else if (reportType == 6) {
                        let currProfile = {};
                        if (
                          profilesVal &&
                          Array.isArray(profilesVal) &&
                          profilesVal.length > 0
                        ) {
                          currProfile = profilesVal.find(
                            (prof) => prof && prof.value == finalProfileId
                          );
                        }
                        const { gender, fullName, label } = currProfile || {};
                        const finalData = {
                          ...celeryData,
                          decryptedGender: gender,
                          decryptedFullName: fullName
                            ? capitalCase(fullName)
                            : '',
                          decryptedNric: label,
                          ranraName,
                          ranraType: ranraYear,
                          ranraEndYear,
                          neoPirEndYear,
                          neoPirName:
                            neoPirName === 'public service'
                              ? 'Public Service'
                              : neoPirName === 'uk working pop'
                              ? 'UK working population'
                              : neoPirName &&
                                neoPirName.toString().toUpperCase(),
                          neoPirYear,
                          wgiiiName,
                          wgiii_display_year,
                          ranra_display_year,
                          neopi_display_year,
                          url: `http://localhost:3000/public/csc/reports/psychological-profile?task_id=${task_id}`,
                          outcomeUrl: `http://localhost:3000/public/csc/reports/psychological-profile-outcome?task_id=${task_id}`,
                        };
                        this.handleGenerateDownloadReport(
                          generateReportType,
                          finalData,
                          fileName,
                          finalProfileId,
                          error_code,
                          message
                        );
                      } else if (reportType == 7) {
                        let testSessionTableData = [];
                        const { profilesVal } = this.state;
                        const csvHeader = [];
                        const obj = {};
                        if (!error_code) {
                          if (
                            celeryData &&
                            Array.isArray(celeryData) &&
                            celeryData.length > 0
                          ) {
                            testSessionTableData = celeryData.map((d) => {
                              const {
                                profile_id,
                                neopir_test_date,
                                ranra_test_date,
                                wgcta_test_date,
                                wgiii_test_date,
                                wgiii_validity_period,
                                wgcta_validity_period,
                                anra_validity_period,
                                neopir_validity_period,
                              } = d || {};
                              let filteredProfiles = {};
                              if (
                                profilesVal &&
                                Array.isArray(profilesVal) &&
                                profilesVal.length > 0
                              ) {
                                filteredProfiles = profilesVal.find(
                                  (pro) => pro && pro.value == profile_id
                                );
                              }
                              const finalAnraValidityPer =
                                anra_validity_period &&
                                Array.isArray(anra_validity_period) &&
                                anra_validity_period.length > 0
                                  ? anra_validity_period.join(' , ')
                                  : '';
                              const finalNeopirValidityPer =
                                neopir_validity_period &&
                                Array.isArray(neopir_validity_period) &&
                                neopir_validity_period.length > 0
                                  ? neopir_validity_period.join(' , ')
                                  : '';
                              const finalWgctaValPere =
                                wgcta_validity_period &&
                                Array.isArray(wgcta_validity_period) &&
                                wgcta_validity_period.length > 0
                                  ? wgcta_validity_period.join(' , ')
                                  : '';
                              const finalWgiiiValPer =
                                wgiii_validity_period &&
                                Array.isArray(wgiii_validity_period) &&
                                wgiii_validity_period.length > 0
                                  ? wgiii_validity_period.join(' , ')
                                  : '';
                              const { fullName, gender, label, dob } =
                                filteredProfiles || {};
                              const finalObj = {
                                ...d,
                                full_name: fullName
                                  ? capitalCase(fullName)
                                  : '',
                                gender:
                                  gender &&
                                  (gender == 1
                                    ? 'Male'
                                    : gender == 2
                                    ? 'Female'
                                    : 'Unknown'),
                                nric_fin: label,
                                dob: dob && moment(dob).format('DD/MM/YYYY'),
                                wgcta_test_date:
                                  wgcta_test_date &&
                                  moment(wgcta_test_date).format('DD/MM/YYYY'),
                                wgiii_test_date:
                                  wgiii_test_date &&
                                  moment(wgiii_test_date).format('DD/MM/YYYY'),
                                neopir_test_date:
                                  neopir_test_date &&
                                  moment(neopir_test_date).format('DD/MM/YYYY'),
                                ranra_test_date:
                                  ranra_test_date &&
                                  moment(ranra_test_date).format('DD/MM/YYYY'),
                                anra_validity_period: finalAnraValidityPer,
                                neopir_validity_period: finalNeopirValidityPer,
                                wgcta_validity_period: finalWgctaValPere,
                                wgiii_validity_period: finalWgiiiValPer,
                              };
                              return finalObj;
                            });
                            if (
                              candidateScreeningKeys &&
                              Array.isArray(candidateScreeningKeys) &&
                              candidateScreeningKeys.length > 0
                            ) {
                              candidateScreeningKeys.forEach((key) => {
                                obj.label = this.handleGetPropertyKey(key);
                                obj.key = key;
                                const finalObj = cloneDeep(obj);
                                csvHeader.push(finalObj);
                              });
                            }
                            this.setState({
                              showCandidateScreeningBtn: true,
                              testSessionTableData,
                              showLoader: false,
                              csvHeader,
                            });
                          }
                        } else {
                          this.setState({
                            errorNotif: true,
                            errMsg: message || 'Unknown error occurred',
                            showLoader: false,
                          });
                        }
                      } else if (reportType == 2) {
                        let testSessionTableData = [];
                        const { profilesVal } = this.state;
                        if (
                          celeryData &&
                          Array.isArray(celeryData) &&
                          celeryData.length > 0
                        ) {
                          testSessionTableData = celeryData.map(
                            (testSD, idx) => {
                              const {
                                overall_lms_code,
                                overall_oms_code,
                                overall_sgs_code,
                                ranra_pctl,
                                rn_lms_code,
                                rn_oms_code,
                                rn_sgs_code,
                                wg_lms_code,
                                wg_oms_code,
                                wg_sgs_code,
                                wgcta_pctl,
                                wg_pctl,
                                profile_id,
                              } = testSD || {};
                              let filteredProfiles = {};
                              if (
                                profilesVal &&
                                Array.isArray(profilesVal) &&
                                profilesVal.length > 0
                              ) {
                                filteredProfiles = profilesVal.find(
                                  (pro) => pro && pro.value == profile_id
                                );
                              }
                              const { fullName, gender, label } =
                                filteredProfiles || {};
                              const finalTestSessData = {
                                no: idx + 1,
                                id: idx + 1,
                                full_name: fullName
                                  ? capitalCase(fullName)
                                  : '',
                                gender: gender == 1 ? 'Male' : 'Female',
                                nric_fin: label,
                                overall_lms_code,
                                overall_oms_code,
                                overall_sgs_code,
                                ranra_pctl,
                                rn_lms_code,
                                rn_oms_code,
                                rn_sgs_code,
                                wg_lms_code,
                                wg_oms_code,
                                wg_sgs_code,
                                wgcta_pctl: wg_pctl || wgcta_pctl,
                              };
                              return finalTestSessData;
                            }
                          );
                        }
                        this.setState({
                          showTestSessionTable: true,
                          testSessionTableData,
                          showLoader: false,
                        });
                      }
                    } else {
                      this.setState({
                        [`status-${finalProfileId}`]: 'Failed',
                        [`err-msg-${profileId}`]:
                          'Error occurred while processing',
                      });
                    }
                  })
                  .catch((e) => {
                    console.log('Error', e);
                    this.setState({
                      [`status-${finalProfileId}`]: 'Failed',
                      [`err-msg-${profileId}`]:
                        'Error occurred while processing',
                    });
                  });
              }, 4000);
              setTimeout(() => {
                clearTimeout(interval);
              }, 4000);
            } else {
              this.setState({
                [`status-${finalProfileId}`]: 'Failed',
                [`err-msg-${profileId}`]: 'Error occurred while processing',
              });
            }
          })
          .catch((e) => {
            console.log('Error', e);
            this.setState({
              showLoader: false,
              [`status-${finalProfileId}`]: 'Failed',
              [`err-msg-${profileId}`]: 'Error occurred while processing',
            });
          });
      }
    );
  };

  handleInputChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[ a-zA-Z0-9!()#+.]{1,300}/.test(value)) {
      this.props.getCandidateProfileDetailsList(value).then((res) => {
        const options =
          res &&
          Array.isArray(res) &&
          res.length > 0 &&
          res.map((one) => ({
            label: one.nric,
            value: one.profileId,
            gender: one.genderId,
            fullName: one.fullName,
            dob: one.dob,
          }));
        this.setState({
          profileArr: options || [],
        });
      });
    }
  };

  handleChangeWatsonGlaserTest = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const { watsonTest, reportType } = this.state;
        const { defaultNorms } = this.props;
        if (
          defaultNorms &&
          Array.isArray(defaultNorms) &&
          defaultNorms.length > 0
        ) {
          if (reportType == 1) {
            let filteredDefNormObj = {};
            if (watsonTest === 'wgiii') {
              filteredDefNormObj = defaultNorms.find(
                (r) => r && r.reportName === 'annex a' && r.testId == 4
              );
            } else {
              filteredDefNormObj = defaultNorms.find(
                (r) => r && r.reportName === 'annex a' && r.testId == 2
              );
            }
            const { endYear, normName, startYear } = filteredDefNormObj || {};
            this.setState({
              watsonName: normName,
              watsonType: startYear,
              watsonEndYear: endYear,
            });
          } else if (reportType == 2) {
            let filteredDefNormObj = {};
            if (watsonTest === 'wgiii') {
              filteredDefNormObj = defaultNorms.find(
                (r) => r && r.reportName === 'test session' && r.testId == 4
              );
            } else {
              filteredDefNormObj = defaultNorms.find(
                (r) => r && r.reportName === 'test session' && r.testId == 2
              );
            }
            const { endYear, normName, startYear } = filteredDefNormObj || {};
            this.setState({
              watsonName: normName,
              watsonType: startYear,
              watsonEndYear: endYear,
            });
          } else if (reportType == 6) {
            let filteredDefNormObj = {};
            if (watsonTest === 'wgiii') {
              filteredDefNormObj = defaultNorms.find(
                (r) =>
                  r && r.reportName === 'psychological test' && r.testId == 4
              );
            } else {
              filteredDefNormObj = defaultNorms.find(
                (r) =>
                  r && r.reportName === 'psychological test' && r.testId == 2
              );
            }
            const { endYear, normName, startYear } = filteredDefNormObj || {};
            this.setState({
              watsonName: normName,
              watsonType: startYear,
              watsonEndYear: endYear,
            });
          }
        }
      }
    );
  };

  updateCurrNorms = (name, value) => {
    const updateState = {};
    const tempArr = value?.split('_');
    let normName;
    let normYearArr = [];
    if (arrMap(tempArr).length > 0) {
      tempArr.forEach((itm, idx) => {
        if (idx === 0) normName = getNormName(itm);
        else {
          const tempRangeArr = itm?.split('-');
          if (arrMap(tempRangeArr).length > 1) {
            const fullRangeArr = _.range(
              Number(arrMap(tempRangeArr)?.[0]),
              Number(arrMap(tempRangeArr)?.[1]) + 1
            );
            normYearArr = normYearArr.concat(fullRangeArr);
          } else {
            normYearArr.push(Number(arrMap(tempRangeArr)?.[0]));
          }
        }
      });
    }

    const displayYearArr = ['public service', 'uk working pop'].includes(
      normName
    )
      ? [normName]
      : arrMap(tempArr).length > 0 && tempArr.slice(1);

    if (name === 'wgiiiNorm') {
      updateState.wgiii_name = normName;
      updateState.wgiii_year = normYearArr;
      updateState.wgiii_display_year = displayYearArr;
    } else if (name === 'anraNorm') {
      updateState.ranra_name = normName;
      updateState.ranra_year = normYearArr;
      updateState.ranra_display_year = displayYearArr;
    } else if (name === 'neoNorm') {
      updateState.neopi_name = normName;
      updateState.neopi_year = normYearArr;
      updateState.neopi_display_year = displayYearArr;
    }

    this.setState({ ...updateState });
  };

  handleChangeNormType = (name, val) => {
    this.setState({
      [name]: val,
    });
    this.updateCurrNorms(name, val);
  };

  render() {
    const {
      selectedTab,
      reportType,
      isValidReportType,
      fileType,
      isValidFileType,
      successNotif,
      errorNotif,
      remarks,
      activePage,
      isValidRemarks,
      ranraType,
      isValidRanraType,
      neoPirType,
      isValidNeoPirType,
      watsonType,
      isValidWatson,
      profilesVal,
      profileArr,
      testSessionTableData,
      showTestSessionTable,
      isProfileDataValid,
      notifMsg,
      annexAWatsonEndYearArr,
      annexARanraEndYearArr,
      showLoader,
      uploadErr,
      showBulkUploadData,
      watsonTest,
      purpose,
      csvHeader,
      showCandidateScreeningBtn,
      errMsg,
      tabContent = [],
      currTabType,
      selectionYear,
    } = this.state;

    const {
      reportLogs,
      count,
      testNorms,
      loading,
      user,
      staticApplFilterInfo,
    } = this.props;
    const { currentYear, roleId } = user;
    const { scenariosList } = staticApplFilterInfo || {};

    let ranraTestNorms = [];
    let neoPirTestNorms = [];
    let watsonTestNorms = [];
    if (testNorms && Array.isArray(testNorms) && testNorms.length > 0) {
      ranraTestNorms = testNorms.filter((res) => res && res.testId === 1);
      neoPirTestNorms = testNorms.filter((res) => res && res.testId === 3);
      if (watsonTest === 'wgiii') {
        watsonTestNorms = testNorms.filter((res) => res && res.testId === 4);
      } else {
        watsonTestNorms = testNorms.filter((res) => res && res.testId === 2);
      }
    }
    const reportTypesArr = [
      // {
      //   type: 'Sample Annex A',
      //   typeId: 1,
      // },
      {
        type: 'PT Percentiles & Cut-off Bandings',
        typeId: 2,
      },
      {
        type: 'ACE: PT + NEO + CPI',
        typeId: 3,
      },
      {
        type: 'CPI',
        typeId: 4,
      },
      {
        type: 'NEO',
        typeId: 5,
      },
      {
        type: 'NEO + CPI',
        typeId: 8,
      },
      {
        type: 'Psychological Report: AT + NEO',
        typeId: 6,
      },
      // {
      //   type: 'Candidate Screening',
      //   typeId: 7,
      // },
    ];
    const reportTestArr = [
      {
        type: 'Watson Glaser',
        typeId: 1,
      },
      {
        type: 'ANRA',
        typeId: 2,
      },
      {
        type: 'Neo/PIR',
        typeId: 3,
      },
    ];
    // const otherActionsBtns = [];
    // otherActionsBtns.push(
    //   <Select
    //     name="fileType"
    //     id="fileType"
    //     onChange={this.handleFileTypeChange}
    //     value={fileType}
    //     hideLabel
    //     invalid={isValidFileType}
    //     invalidText="Please select valid file name to upload"
    //     labelText="Select File Type">
    //     <SelectItem text="Select" disabled key={0} />
    //     {uploadDataArr &&
    //       Array.isArray(uploadDataArr) &&
    //       uploadDataArr.length > 0 &&
    //       uploadDataArr.map((r) => {
    //         const { id, type } = r || {};
    //         return <SelectItem text={type} key={id} id={id} value={id} />;
    //       })}
    //   </Select>
    // );

    let downloadTemplateFile = '';

    if (fileType == '2' || fileType == '4' || fileType == '5')
      downloadTemplateFile = Reports_Template_PAE_PSC_PSLP;
    else if (fileType == '6') downloadTemplateFile = Reports_Template_SAS;
    else if (fileType == '3') downloadTemplateFile = Reports_Template_ACE;

    return (
      <div>
        <BackRow paths={this.paths} />
        {successNotif === true && (
          <InlineNotification
            lowContrast
            title={
              !notifMsg
                ? 'Your files have been uploaded successfully'
                : notifMsg
            }
            iconDescription="describes the close button"
            kind="success"
            onCloseButtonClick={() => {
              this.setState({ successNotif: false });
            }}
          />
        )}
        {errorNotif === true && (
          <InlineNotification
            lowContrast
            title={
              !errMsg
                ? 'An Error has ocurred while processing your request, Please try again later'
                : errMsg
            }
            iconDescription="describes the close button"
            kind="error"
            onCloseButtonClick={() => {
              this.setState({ errorNotif: false });
            }}
          />
        )}
        {uploadErr === true && (
          <InlineNotification
            lowContrast
            title="Something went wrong. Please check the status to know more."
            iconDescription="describes the close button"
            kind="error"
            onCloseButtonClick={() => {
              this.setState({ errorNotif: false });
            }}
          />
        )}
        <div className="mb-3">
          <GenericTabs selected={selectedTab} tabContent={tabContent} />
        </div>
        {currTabType ? (
          <>
            {currTabType === 'generate' && (
              <Tile>
                <h5>Report Generation</h5>
                <hr />
                <div className="mb-3">
                  <div className="w-50">
                    <FileUploaderDropContainer
                      labelText="Click here to upload your files"
                      multiple
                      name=""
                      onChange={this.handleUploadBulkNric}
                      role=""
                      size="default"
                      tabIndex={0}
                    />
                  </div>
                  <div className="mt-2 mb-3">
                    <Button
                      className="txt-dec-none"
                      kind="primary"
                      size="small"
                      href={CandNricTemp}>
                      Download Template
                    </Button>
                  </div>

                  <div className="w-25 mt-2 mb-2 text-center">OR</div>
                  <div className="bx--label mb-2">Select Candidates</div>
                  <div className="w-50">
                    <Dropdown
                      title=""
                      mainClassName="bx--form-item mb-0"
                      titleClass=""
                      labelClass=""
                      required
                      custom
                      isMulti
                      onInputChange={this.handleInputChange}
                      className={`w-100 ${isProfileDataValid ? 'invalid' : ''}`}
                      options={profileArr}
                      placeholder="Select candidates"
                      onChange={(value, e, name) =>
                        this.handleDropdownChange(value, e, name)
                      }
                      name="profilesVal"
                      selectedValue={profilesVal}
                    />
                    {isProfileDataValid === true && (
                      <div className="invalid-msg mt-2">
                        Please select valid profile
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-50 mb-2">
                  <Select
                    name="reportType"
                    id="reportType"
                    onChange={this.handleReportChange}
                    className="xpa-width"
                    value={reportType}
                    invalid={isValidReportType}
                    invalidText="Please select valid report"
                    labelText="Select Report">
                    <SelectItem text="Select" key={0} />
                    {reportTypesArr &&
                      Array.isArray(reportTypesArr) &&
                      reportTypesArr.length > 0 &&
                      reportTypesArr.map((r) => {
                        const { typeId, type } = r || {};
                        return (
                          <SelectItem
                            text={type}
                            key={typeId}
                            id={typeId}
                            value={typeId}
                          />
                        );
                      })}
                  </Select>
                </div>

                {(reportType == 2 || reportType == 6) && (
                  <StructuredListWrapper
                    ariaLabel={
                      reportType == 2
                        ? 'PT Percentiles & Cut-off Bandings'
                        : 'Psychological Report: AT + NEO'
                    }
                    selection={false}>
                    <StructuredListHead>
                      <StructuredListRow head label={false} tabIndex={0}>
                        <StructuredListCell head noWrap={false}>
                          Test
                        </StructuredListCell>
                        <StructuredListCell head noWrap={false}>
                          Select Norm Range
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListHead>
                    <StructuredListBody>
                      <StructuredListRow
                        head={false}
                        label={false}
                        tabIndex={0}>
                        <StructuredListCell head={false} noWrap>
                          WGIII
                        </StructuredListCell>
                        <StructuredListCell head={false} noWrap={false}>
                          <WGIIITestNormType
                            handleChange={this.handleChangeNormType}
                            state={this.state}
                          />
                        </StructuredListCell>
                      </StructuredListRow>
                      <StructuredListRow
                        head={false}
                        label={false}
                        tabIndex={0}>
                        <StructuredListCell head={false} noWrap>
                          ANRA
                        </StructuredListCell>
                        <StructuredListCell head={false} noWrap={false}>
                          <AnraTestNormType
                            handleChange={this.handleChangeNormType}
                            state={this.state}
                          />
                        </StructuredListCell>
                      </StructuredListRow>
                      <StructuredListRow
                        head={false}
                        label={false}
                        onKeyDown={function noRefCheck() {}}
                        tabIndex={0}>
                        <StructuredListCell head={false} noWrap>
                          NEO
                        </StructuredListCell>
                        <StructuredListCell head={false} noWrap={false}>
                          <NeoTestNormType
                            handleChange={this.handleChangeNormType}
                            state={this.state}
                          />
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  </StructuredListWrapper>
                )}

                {reportType == 3 && (
                  <StructuredListWrapper
                    ariaLabel="ACE: PT + NEO + CPI"
                    selection={false}>
                    <StructuredListHead>
                      <StructuredListRow head label={false} tabIndex={0}>
                        <StructuredListCell head noWrap={false}>
                          Test
                        </StructuredListCell>
                        <StructuredListCell head noWrap={false}>
                          Select Norm Range
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListHead>
                    <StructuredListBody>
                      <StructuredListRow
                        head={false}
                        label={false}
                        tabIndex={0}>
                        <StructuredListCell head={false} noWrap>
                          WGIII
                        </StructuredListCell>
                        <StructuredListCell head={false} noWrap={false}>
                          <div className="w-50">
                            <Select
                              name="neoNorm"
                              id="neoNorm"
                              onChange={(e) => {}}
                              value="ace_2020"
                              hideLabel
                              className="w-100 xpa-width"
                              labelText="Select Norm"
                              disabled>
                              <SelectItem text="Select" disabled key={0} />
                              <SelectItem
                                text="ACE (2020)"
                                key={0}
                                value="ace_2020"
                                id={0}
                                disabled
                              />
                            </Select>
                          </div>
                        </StructuredListCell>
                      </StructuredListRow>
                      <StructuredListRow
                        head={false}
                        label={false}
                        onKeyDown={function noRefCheck() {}}
                        tabIndex={0}>
                        <StructuredListCell head={false} noWrap>
                          NEO
                        </StructuredListCell>
                        <StructuredListCell head={false} noWrap={false}>
                          <NeoTestNormType
                            handleChange={this.handleChangeNormType}
                            state={this.state}
                          />
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  </StructuredListWrapper>
                )}
                {(reportType == 4 || reportType == 5 || reportType == 8) && (
                  <StructuredListWrapper
                    ariaLabel={
                      reportType == 4
                        ? 'CPI'
                        : reportType == 5
                        ? 'NEO'
                        : 'NEO + CPI'
                    }
                    selection={false}>
                    <StructuredListHead>
                      <StructuredListRow head label={false} tabIndex={0}>
                        <StructuredListCell head noWrap={false}>
                          Test
                        </StructuredListCell>
                        <StructuredListCell head noWrap={false}>
                          Select Norm Range
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListHead>
                    <StructuredListBody>
                      <StructuredListRow
                        head={false}
                        label={false}
                        onKeyDown={function noRefCheck() {}}
                        tabIndex={0}>
                        <StructuredListCell head={false} noWrap>
                          NEO
                        </StructuredListCell>
                        <StructuredListCell head={false} noWrap={false}>
                          <NeoTestNormType
                            handleChange={this.handleChangeNormType}
                            state={this.state}
                          />
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  </StructuredListWrapper>
                )}
                {reportType == 7 && (
                  <CandidateScreeningYears
                    state={this.state}
                    handleInputChange={this.handleInputChange}
                    handleDropdownChange={this.handleDropdownChange}
                    handleChangeWatsonGlaserTest={
                      this.handleChangeWatsonGlaserTest
                    }
                  />
                )}
                <div className="mt-2">
                  <Button
                    onClick={this.handleGenerateBulkReports}
                    size="small"
                    kind="primary">
                    Generate
                  </Button>
                </div>
                <JDProcessOverlay show={showLoader} message="processing..." />
              </Tile>
            )}
            {currTabType === 'upload' && (
              <Tile>
                <h5 className="mb-2">Upload Files</h5>
                <hr />
                {/* code used before Oct 2023 */}
                {/* <div className="">
              <div className="w-50">
                <Select
                  name="fileType"
                  id="fileType"
                  onChange={this.handleChange}
                  value={fileType}
                  className="xpa-width"
                  invalid={isValidFileType}
                  invalidText="Please select valid file name to upload"
                  labelText="Select File Type">
                  <SelectItem text="Select" key={0} />
                  {uploadDataArr &&
                    Array.isArray(uploadDataArr) &&
                    uploadDataArr.length > 0 &&
                    uploadDataArr.map((r) => {
                      const { id, type } = r || {};
                      return (
                        <SelectItem text={type} key={id} id={id} value={id} />
                      );
                    })}
                </Select>
              </div>
              <div className="mt-2 ">
                <CandidateScreeningYears
                  state={this.state}
                  handleInputChange={this.handleInputChange}
                  handleDropdownChange={this.handleDropdownChange}
                  handleChangeWatsonGlaserTest={
                    this.handleChangeWatsonGlaserTest
                  }
                />
              </div>
              <div className="d-flex">
                <div className="mt-2 w-50">
                  <TextArea
                    name="purpose"
                    labelText="Purpose"
                    placeholder="Please enter the purpose"
                    onChange={this.handleChange}
                    value={purpose}
                  />
                </div>
                <div className="mt-2 w-50 ml-2">
                  <TextArea
                    name="remarks"
                    labelText="Remarks"
                    placeholder="Please enter remarks"
                    onChange={this.handleChange}
                    value={remarks}
                  />
                </div>
              </div>
              <div className="mt-3">
                <FileUploaderButton
                  buttonKind="primary"
                  className="bob"
                  labelText="Upload"
                  multiple
                  disableLabelChanges
                  name=""
                  onChange={this.handleSubmitUpload}
                  role="button"
                  size="default"
                  tabIndex={0}
                />
              </div>
            </div> */}

                {/* code post Oct 2023 */}
                <div className="">
                  <div className="d-flex align-items-end">
                    <div className="w-50">
                      <Select
                        name="fileType"
                        id="fileType"
                        onChange={this.handleChange}
                        value={fileType}
                        className="xpa-width"
                        invalid={isValidFileType}
                        invalidText="Please select valid file name to upload"
                        labelText={
                          <div>
                            Select File Type{' '}
                            <span className="text-danger">*</span>
                          </div>
                        }>
                        <SelectItem text="Select" key="0" />
                        {scenariosList &&
                          Array.isArray(scenariosList) &&
                          scenariosList.length > 0 &&
                          (roleId === 14
                            ? scenariosList
                                .filter((itm) => itm?.scenarioId == '6')
                                .map((r) => {
                                  const { scenarioId, scenario } = r || {};
                                  return (
                                    <SelectItem
                                      text={scenario}
                                      key={scenarioId}
                                      id={scenarioId}
                                      value={scenarioId}
                                    />
                                  );
                                })
                            : scenariosList
                                .filter((itm) => itm?.scenarioId != '1')
                                .map((r) => {
                                  const { scenarioId, scenario } = r || {};
                                  return (
                                    <SelectItem
                                      text={scenario}
                                      key={scenarioId}
                                      id={scenarioId}
                                      value={scenarioId}
                                    />
                                  );
                                }))}
                        <SelectItem
                          text="PS Briefing Deck"
                          key="ps-briefing-deck"
                          id="ps-briefing-deck"
                          value="ps-briefing-deck"
                        />
                      </Select>
                    </div>
                    {downloadTemplateFile && (
                      <div className="ml-3">
                        <Button
                          className="txt-dec-none"
                          kind="primary"
                          href={downloadTemplateFile}>
                          Download Template
                        </Button>
                      </div>
                    )}
                  </div>
                  {(fileType == '4' ||
                    fileType == '5' ||
                    fileType === 'ps-briefing-deck') && (
                    <SelectionYearDropdown
                      currentYear={currentYear}
                      selectionYear={selectionYear}
                      handleChange={this.handleChange}
                      mainClassName="mt-2 w-50"
                      titleClassName="xpa-width"
                    />
                  )}

                  <div className="d-flex">
                    {/* <div className="mt-2 w-50">
                  <TextArea
                    name="purpose"
                    labelText="Purpose"
                    placeholder="Please enter the purpose"
                    onChange={this.handleChange}
                    value={purpose}
                  />
                </div> */}
                    <div className="mt-2 w-50">
                      <TextArea
                        name="remarks"
                        labelText="Remarks"
                        placeholder="Please enter remarks"
                        onChange={this.handleChange}
                        value={remarks}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <FileUploaderButton
                      buttonKind="primary"
                      className="bob"
                      labelText="Upload"
                      multiple
                      disableLabelChanges
                      name=""
                      onChange={this.handleSubmitUpload}
                      role="button"
                      size="default"
                      tabIndex={0}
                      accept={
                        fileType === 'ps-briefing-deck' ? ['.pdf'] : ['.csv']
                      }
                    />
                    <div className="small mt-2">
                      only {fileType === 'ps-briefing-deck' ? '.pdf' : '.csv'}{' '}
                      files are allowed.
                    </div>
                  </div>
                </div>
                <JDProcessOverlay show={loading} message="processing..." />
              </Tile>
            )}
            {currTabType === 'generate' &&
              showBulkUploadData === true &&
              showTestSessionTable !== true &&
              showCandidateScreeningBtn !== true &&
              reportType != 7 &&
              reportType != 2 && (
                <Tile className="mt-2">
                  <h4 className="mb-2 mt-2">
                    Please wait while the files are being download.
                  </h4>
                  <GenericDataTable
                    otherActions={[]}
                    searchable={false}
                    expandable={false}
                    rows={
                      profilesVal &&
                      Array.isArray(profilesVal) &&
                      profilesVal.length > 0
                        ? profilesVal.map((data, idx) => {
                            const { label, value, fullName } = data;
                            let statusColor = 'bx--tag bx--tag--yellow';
                            if (
                              this.state[`status-${value}`] === 'Processing'
                            ) {
                              statusColor = 'bx--tag bx--tag--yellow';
                            } else if (
                              this.state[`status-${value}`] === 'Waiting'
                            ) {
                              statusColor = 'bx--tag bx--tag--cool-gary';
                            } else if (
                              this.state[`status-${value}`] === 'Failed'
                            ) {
                              statusColor = 'bx--tag bx--tag--red';
                            } else if (
                              this.state[`status-${value}`] === 'Completed'
                            ) {
                              statusColor = 'bx--tag bx--tag--green';
                            }
                            return {
                              isExpanded: false,
                              id: value,
                              header_0: (
                                <div className="d-flex align-items-center">
                                  <div className="text-capitalize">
                                    {fullName}
                                  </div>
                                </div>
                              ),
                              header_1: <div>{label}</div>,
                              header_2: (
                                <div className={statusColor}>
                                  {this.state[`status-${value}`]}
                                </div>
                              ),
                              header_3: (
                                <div>
                                  {this.state[`err-msg-${value}`] || '-'}
                                </div>
                              ),
                            };
                          })
                        : []
                    }
                    headers={[
                      {
                        key: 'header_0',
                        header: 'Name',
                      },
                      {
                        key: 'header_1',
                        header: 'NRIC',
                      },
                      {
                        key: 'header_2',
                        header: 'Status',
                      },
                      {
                        key: 'header_3',
                        header: 'Reason',
                      },
                    ]}
                  />
                </Tile>
              )}
            {currTabType === 'files' && (
              <div>
                <GenericDataTable
                  // otherActions={otherActionsBtns}
                  searchable={false}
                  onSearchInputChange={this.handleSearchSubmit}
                  expandedRows={
                    reportLogs &&
                    Array.isArray(reportLogs) &&
                    reportLogs.length > 0 &&
                    reportLogs.map((data) => {
                      const { error, additionalInfo } = data || {};
                      const { validityInfo, invalidTestDatesInfo } =
                        additionalInfo || {};
                      return (
                        <div>
                          {error ? (
                            <div className="d-flex">
                              <div className="">Error Message:</div>
                              <div className="ml-2 invalid-msg small text-capitalize">
                                {error}
                              </div>
                            </div>
                          ) : (
                            <div>
                              There was no error while processing the file
                            </div>
                          )}

                          {invalidTestDatesInfo &&
                            Array.isArray(invalidTestDatesInfo) &&
                            invalidTestDatesInfo.length > 0 && (
                              <>
                                <div className="mt-3 mb-2">
                                  Invalid test dates info:
                                </div>
                                <div className="custom-zebra-table">
                                  {invalidTestDatesInfo?.map((log, idx) => {
                                    return (
                                      <div className="custom-zebra-row">
                                        <div className="ml-2 small">{log}</div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            )}

                          {validityInfo &&
                            Array.isArray(validityInfo) &&
                            validityInfo.length > 0 && (
                              <>
                                <div className="mt-3 mb-2">Summary:</div>
                                <div className="custom-zebra-table">
                                  {validityInfo?.map((log, idx) => {
                                    return (
                                      <div className="custom-zebra-row">
                                        <div className="ml-2 small">{log}</div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                        </div>
                      );
                    })
                  }
                  expandable
                  rows={
                    reportLogs &&
                    Array.isArray(reportLogs) &&
                    reportLogs.length > 0
                      ? reportLogs.map((data, idx) => {
                          const {
                            reportdocumentsId,
                            remarks: propRemarks,
                            userinfo,
                            uploadDate,
                            status,
                            location,
                            scenarioId,
                            error,
                            isAzureLocationValid,
                          } = data;
                          const { firstname, lastname } = userinfo || {};
                          return {
                            isExpanded: false,
                            id: reportdocumentsId,
                            header_0: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  Uploaded By:
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                    {firstname} {lastname}
                                  </div>
                                </div>
                              </div>
                            ),
                            header_1: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  File Type:
                                </div>
                                <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                  {this.handleGetFileType(scenarioId)}
                                </div>
                              </div>
                            ),
                            header_2: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  File Link:
                                </div>
                                <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                  {isAzureLocationValid === true ? (
                                    <Button kind="ghost" size="small">
                                      <a
                                        target="_blank"
                                        href={`${baseUrl}/reportdocuments/view?_=${new Date().getTime()}&location=${encodeURIComponent(
                                          location
                                        )}`}
                                        rel="noreferrer">
                                        View
                                      </a>
                                    </Button>
                                  ) : (
                                    '-'
                                  )}
                                </div>
                              </div>
                            ),
                            header_3: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  Remarks:
                                </div>
                                <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                  {propRemarks &&
                                  propRemarks !== 'undefined' &&
                                  propRemarks !== null &&
                                  propRemarks !== undefined
                                    ? propRemarks
                                    : '-'}
                                </div>
                              </div>
                            ),
                            header_4: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  Status:
                                </div>
                                <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                  <div
                                    className={`text-capitalize ${
                                      status === 'success'
                                        ? 'bx--tag bx--tag--green text-capitalize'
                                        : status === 'inprogress'
                                        ? 'bx--tag bx--tag--yellow'
                                        : 'bx--tag bx--tag--red text-capitalize'
                                    }`}>
                                    {status === 'inprogress'
                                      ? 'In Progress'
                                      : status}
                                  </div>
                                </div>
                              </div>
                            ),
                            header_5: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  Date:
                                </div>
                                <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                  {moment(uploadDate).fromNow()}
                                </div>
                              </div>
                            ),
                          };
                        })
                      : []
                  }
                  headers={[
                    {
                      key: 'header_0',
                      header: 'Uploaded BY',
                    },
                    {
                      key: 'header_1',
                      header: 'File Type',
                    },
                    {
                      key: 'header_2',
                      header: 'File Link',
                    },
                    {
                      key: 'header_3',
                      header: 'Remarks',
                    },
                    {
                      key: 'header_4',
                      header: 'Status',
                    },
                    {
                      key: 'header_5',
                      header: 'Date',
                    },
                  ]}
                />
                {count > 0 && (
                  <Pagination
                    onChange={this.handlePaginationChange}
                    pageSizes={[10, 20, 30, 40, 50]}
                    page={activePage}
                    totalItems={count}
                  />
                )}
                {(!reportLogs ||
                  (reportLogs &&
                    Array.isArray(reportLogs) &&
                    reportLogs.length <= 0)) && (
                  <NoContentBox message="There are no user created for your current selection" />
                )}
                <JDProcessOverlay show={loading} message="processing..." />
              </div>
            )}
            {showCandidateScreeningBtn === true &&
              currTabType === 'generate' && (
                <Tile className="mt-2 mb-2">
                  <h6 className="mb-2">Candidate Screening Export</h6>
                  <div className="mt-3 f-w-b h6 mb-3">
                    Click on the following button to download the report.
                  </div>
                  <CSVLink
                    data={testSessionTableData || []}
                    headers={csvHeader}
                    filename="Candidate-Screening.csv"
                    className="bx--btn bx--btn--sm bx--btn--primary"
                    target="_blank">
                    Download
                  </CSVLink>
                </Tile>
              )}
            {showTestSessionTable === true && currTabType === 'generate' && (
              <Tile className="mt-2 mb-2">
                <h6 className="mb-2">PT Percentiles & Cut-off Bandings</h6>
                <CsvTableData
                  data={testSessionTableData}
                  headers={[
                    {
                      header: 'No',
                      key: 'no',
                    },
                    { header: 'Name', key: 'full_name' },
                    { header: 'NRIC', key: 'nric_fin' },
                    { header: 'Sex', key: 'gender' },
                    { header: 'ANRA Percentile', key: 'ranra_pctl' },
                    { header: 'OMS Status', key: 'rn_oms_code' },
                    { header: 'SGS Status', key: 'rn_sgs_code' },
                    { header: 'LMS Status', key: 'rn_lms_code' },
                    { header: 'WGIII Percentile', key: 'wgcta_pctl' },
                    { header: 'OMS Status', key: 'wg_oms_code' },
                    { header: 'SGS Status', key: 'wg_sgs_code' },
                    { header: 'LMS Status', key: 'wg_lms_code' },
                    { header: 'Overall OMS Status', key: 'overall_oms_code' },
                    { header: 'Overall SGS Status', key: 'overall_sgs_code' },
                    { header: 'Overall LMS Status', key: 'overall_lms_code' },
                  ]}
                  csvHeader={[
                    { label: 'Name', key: 'full_name' },
                    { label: 'NRIC', key: 'nric_fin' },
                    { label: 'Sex', key: 'gender' },
                    { label: 'ANRA Percentile', key: 'ranra_pctl' },
                    { label: 'OMS Status', key: 'rn_oms_code' },
                    { label: 'SGS Status', key: 'rn_sgs_code' },
                    { label: 'LMS Status', key: 'rn_lms_code' },
                    { label: 'WGIII Percentile', key: 'wgcta_pctl' },
                    { label: 'OMS Status', key: 'wg_oms_code' },
                    { label: 'SGS Status', key: 'wg_sgs_code' },
                    { label: 'LMS Status', key: 'wg_lms_code' },
                    { label: 'Overall OMS Status', key: 'overall_oms_code' },
                    { label: 'Overall SGS Status', key: 'overall_sgs_code' },
                    { label: 'Overall LMS Status', key: 'overall_lms_code' },
                  ]}
                  downloadFileName={`PT-Percentiles-And-CutOff-Bandings-Export-${Date.now()}`}
                />
              </Tile>
            )}
            {currTabType === 'psy-rat' && (
              <div>
                <Tile>
                  <h5>Assessment Center Data</h5>
                  <hr />
                  <div className="mb-3">
                    <div className="w-50">
                      <FileUploaderDropContainer
                        labelText="Click here to upload your files"
                        multiple
                        name=""
                        onChange={this.handleUploadAssessmentCenter}
                        role=""
                        size="default"
                        tabIndex={0}
                      />
                    </div>
                  </div>
                </Tile>
                <Tile className="mt-2 mb-2">
                  <h5>Peer Ratings Data</h5>
                  <hr />
                  <div className="mb-3">
                    <div className="w-50">
                      <FileUploaderDropContainer
                        labelText="Click here to upload your files"
                        multiple
                        name=""
                        onChange={this.handleUploadPeerRatings}
                        role=""
                        size="default"
                        tabIndex={1}
                      />
                    </div>
                    <div className="mt-2 mb-3">
                      <Button
                        className="txt-dec-none"
                        kind="primary"
                        size="small"
                        href={PeerRatingsDataTemp}>
                        Download Template
                      </Button>
                    </div>
                  </div>
                </Tile>
                <Tile>
                  <h5>Psychological Ratings</h5>
                  <hr />
                  <div className="mb-3">
                    <div className="w-50">
                      <FileUploaderDropContainer
                        labelText="Click here to upload your files"
                        multiple
                        name=""
                        onChange={this.handleUploadPsychologicalRatings}
                        role=""
                        size="default"
                        tabIndex={2}
                      />
                    </div>
                  </div>
                </Tile>
              </div>
            )}
          </>
        ) : (
          <>
            <NotFound roleId={roleId} />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.HighLevelReports.loading,
  reportLogs: state.HighLevelReports.reportLogs,
  count: state.HighLevelReports.count,
  candidateData: state.CandidateList.candidateData,
  testNorms: state.HighLevelReports.testNorms,
  user: state.x0paDuser.user,
  testsDetails: state.HighLevelReports.testsDetails,
  task_id: state.HighLevelReports.task_id,
  celeryData: state.HighLevelReports.celeryData,
  defaultNorms: state.HighLevelReports.defaultNorms,
  staticApplFilterInfo: state.CandidateList.staticApplFilterInfo,
});

const mapDispatchToProps = {
  uploadHighLevelReports,
  getHighLevelReportLogs,
  getCandidateProfileDetailsList,
  getTestNormDetails,
  generateHighLvlReports,
  getTestsDetails,
  generateCeleryTask,
  downloadHighLevelReports,
  getDefaultNorms,
  uploadCandidateNrics,
  uploadPsychologicalRatings,
  uploadAssessmentCenterData,
  uploadPeerRatingsData,
  getSaticApplFilters,
  uploadPSBriefingDeckDoc,
};

export default connect(mapStateToProps, mapDispatchToProps)(HighLvlReports);
