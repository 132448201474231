import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput,
  Modal,
  Tile,
  Button,
  InlineNotification,
  FileUploaderButton,
} from 'carbon-components-react';
import GenericDataTable from '../../../components/common/GenericDataTable';
import Dropdown from '../../../components/common/Dropdown';
import moment from 'moment';
import BackRow from '../../../components/common/BackRow';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import {
  getAssessmentsReportInfo,
  getAsessReportStatusById,
  getAsessReportDropdownInfo,
  uploadCandNrics,
  getCandAsessStatusThirdParty,
  getCandAsessStatusReportByUuid,
  uploadTestBookingfile,
  exportCandBookingStatus,
  uploadTestBookingUsageReport,
} from '../../../actions/Assessments';
import isEmail from 'validator/lib/isEmail';
import nric from 'nric';
import NoContentBox from '../../../components/common/NoContentBox';
import GenericTabs from '../../../components/common/GenericTabs';
import SampleCandNricTemplate from '../../../assets/files/Cand_Nric_List_Template.xlsx';
import SampleTestBookingTemplate from '../../../assets/files/Test_Booking_UploadFile_Template.xlsx';
import NotFound from '../../../components/common/NotFound';

class AssessCandidateReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      isExporting: false,
      startDate: null,
      endDate: null,
      selectedTab: 0,
      currTab: 'candidate-booking',
    };
  }

  componentDidMount() {
    this.handleGetDrpDownVals();
    const { user } = this.props;
    const { roleId } = user;
    if (roleId) {
      if (roleId == 14) this.setState({ currTab: 'candidate-booking' });
      else this.setState({ currTab: 'assessment-report' });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getOptions = (optionList) => {
    return optionList && Array.isArray(optionList) && optionList.length > 0
      ? optionList.map((itm) => {
          return {
            label: itm,
            value: itm,
          };
        })
      : [];
  };

  handleGetDrpDownVals = () => {
    this.props.getAsessReportDropdownInfo().then((res) => {
      if (res && !res.error) {
        const {
          selectionYears = [],
          scenarios = [],
          gbaTestStatus = [],
          neoTestStatus = [],
          numericalReasoningStatus = [],
          criticalThinkingStatus = [],
          neoResult = [],
          gbaResult = [],
          criticalThinkingResult = [],
          numericalReasoningResult = [],
        } = res || {};
        const orgList = res?.['Org/School'] || [];
        this.setState({
          selectionYearOptions: selectionYears?.map((itm) => {
            const { year } = itm || {};
            return {
              label: year,
              value: year,
            };
          }),
          selectedSelectionYear:
            selectionYears
              ?.filter((itm) => itm?.isCurrentYear === true)
              ?.map((itm) => {
                const { year } = itm || {};
                return {
                  label: year,
                  value: year,
                };
              })?.[0] || {},
          scenarioIdOptions: scenarios?.map((itm) => {
            const { scenarioId, scenario } = itm || {};
            return {
              label: scenario,
              value: scenarioId,
            };
          }),
          organizationOptions: orgList?.map((itm) => {
            const { companyId, companyName } = itm || {};
            return {
              label: companyName,
              value: companyId,
            };
          }),
          neoTestStatusOptions: this.getOptions(neoTestStatus),
          gbaTestStatusOptions: this.getOptions(gbaTestStatus),
          numericalReasoningStatusOptions: this.getOptions(
            numericalReasoningStatus
          ),
          criticalThinkingStatusOptions: this.getOptions(
            criticalThinkingStatus
          ),
          neoResultOptions: this.getOptions(neoResult),
          gbaResultOptions: this.getOptions(gbaResult),
          criticalThinkingResultOptions: this.getOptions(
            criticalThinkingResult
          ),
          numericalReasoningResultOptions: this.getOptions(
            numericalReasoningResult
          ),
        });
      }
    });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    });
  };

  validateDomainInput = (e) => {
    const rgExp = new RegExp(
      '^((?!-)(?!(?:www.|http://www.))[A-Za-z0-9-]' +
        '{1,63}(?<!-)\\.)' +
        '+[A-Za-z]{2,6}',
      'i'
    );
    const matches = rgExp.exec(e);
    if (matches && Array.isArray(matches) && matches.length > 0) {
      return false;
    }
    return true;
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { emailDomain } = this.state;
        if (name === 'emailDomain') {
          this.setState({
            isEmailDomainInvalid: emailDomain?.toString()?.trim()
              ? this.validateDomainInput(emailDomain)
              : false,
          });
        }
      }
    );
  };

  onDatePickerChange = (date, inputName) => {
    this.setState({ [`${inputName}`]: date, isDateRangeInvalid: false });
  };

  validateNRICInput = (nricTxt) => {
    let nricList =
      nricTxt && nricTxt.toString() && nricTxt.toString().split(',');
    const validNricList =
      nricList &&
      Array.isArray(nricList) &&
      nricList.length > 0 &&
      nricList.map((eachNric) => nric.validate((eachNric + '').trim()));

    return validNricList?.some((itm) => itm === false);
  };

  validateInputs = () => {
    const {
      selectedSelectionYear,
      selectedScenarioId,
      nricTxt,
      emailDomain,
      startDate,
      endDate,
    } = this.state;

    const invalidObj = {};
    invalidObj.isSelectionYearInvalid = selectedSelectionYear?.value == null;
    // invalidObj.isScenarioIdInvalid = selectedScenarioId?.value == null;
    invalidObj.isNricTxtInvalid = nricTxt?.toString()?.trim()
      ? this.validateNRICInput(nricTxt)
      : false;
    // invalidObj.isEmailDomainInvalid = emailDomain?.toString()?.trim()
    //   ? this.validateDomainInput(emailDomain)
    //   : false;
    invalidObj.isDateRangeInvalid =
      (startDate && endDate && startDate > endDate) || false;
    this.setState({ ...invalidObj });
    return !!Object.values(invalidObj).every((val) => val === false);
  };

  handlePreview = (exportData, shareReportViaEmail) => {
    const areAllInputsValid = this.validateInputs();
    this.setState({ areAllInputsValid }, () => {
      if (areAllInputsValid) {
        const {
          activePage,
          itemsPerPage,
          searchKey,
          selectedSelectionYear,
          selectedScenarioId,
          selectedOrganization,
          selectedNeoTestStatus,
          selectedGBATestStatus,
          selectedNumRsngTestStatus,
          selectedCritThkgTestStatus,
          emailDomain,
          inviteDate,
          shareToAddress,
          nricTxt,
          selectedLeapFilter,
          selectedUcognifyFilter,
          selectedNumReasoningFilter,
          selectedCriticalThinkingFilter,
          startDate,
          endDate,
        } = this.state;
        const data = {
          activePage,
          itemsPerPage,
          nricList: nricTxt ? nricTxt?.replace(/ /g, '')?.split(',') : [],
          selectionYear: selectedSelectionYear?.value,
          scenarioId: selectedScenarioId?.value,
          startDate: startDate && `"${moment(startDate).format('YYYY-MM-DD')}"`,
          endDate: endDate && `"${moment(endDate).format('YYYY-MM-DD')}"`,
          // emailDomain: emailDomain?.toString()?.trim(),
          neoTestStatus: selectedNeoTestStatus?.value,
          gbaTestStatus: selectedGBATestStatus?.value,
          numericalReasoningStatus: selectedNumRsngTestStatus?.value,
          criticalThinkingStatus: selectedCritThkgTestStatus?.value,
          companyId: selectedOrganization?.value,
          // inviteDate: `"${moment(inviteDate).format('YYYY-MM-DD')}"`,
          neoResult: selectedLeapFilter?.value,
          gbaResult: selectedUcognifyFilter?.value,
          numericalReasoningResult: selectedNumReasoningFilter?.value,
          criticalThinkingResult: selectedCriticalThinkingFilter?.value,
        };

        if (shareReportViaEmail) {
          data.sendEmail = true;
        }

        const updateState = {};
        if (exportData) {
          data.exportData = true;
          updateState.isExporting = true;
        }

        this.setState({ ...updateState }, () => {
          this.props
            .getAssessmentsReportInfo(data)
            .then((res) => {
              if (res && !res.error) {
                if (exportData) {
                  const { exportUuid } = res || {};
                  this.interval = setInterval(() => {
                    this.props
                      .getAsessReportStatusById(exportUuid)
                      .then((res) => {
                        if (res && !res.error) {
                          const { url } = res || {};
                          if (url) {
                            window.open(url, '_self');
                            clearInterval(this.interval);
                            this.setState({ isExporting: false });
                          }
                        } else {
                          clearInterval(this.interval);
                          this.setState({
                            isExporting: false,
                            errorNotif: true,
                            successNotif: false,
                            errMsg:
                              'An Error has ocurred while processing your request, Please try again later',
                          });
                          setTimeout(() => {
                            this.setState({
                              errMsg: '',
                              errorNotif: false,
                            });
                          }, 5000);
                        }
                      })
                      .catch((e) => {
                        this.setState({
                          isExporting: false,
                          errorNotif: true,
                          successNotif: false,
                        });
                      });
                  }, 4000);
                } else if (shareReportViaEmail) {
                  const { message } = res || {};
                  this.setState(
                    {
                      successNotif: true,
                      notifMsg: message,
                      errorNotif: false,
                    },
                    () => {
                      this.dismissSendRepViaEmailModal();
                      setTimeout(() => {
                        this.setState({
                          notifMsg: '',
                          successNotif: false,
                        });
                      }, 5000);
                    }
                  );
                } else {
                  const { data } = res || {};
                  this.setState({
                    previewDataList: data || [],
                  });
                }
              } else {
                this.dismissSendRepViaEmailModal();
              }
            })
            .catch((error) => {
              this.dismissSendRepViaEmailModal();
              console.log('Error', error);
            });
        });
      }
    });
  };

  dismissSendRepViaEmailModal = () => {
    this.setState({
      isOpenSendRepViaEmailModal: false,
    });
  };

  handleSendReportViaEmail = () => {
    const { shareToAddress } = this.state;
    let emailShare = shareToAddress;
    let emails =
      emailShare && emailShare.toString() && emailShare.toString().split(',');
    const validEmails =
      emails &&
      Array.isArray(emails) &&
      emails.length > 0 &&
      emails.map((eachEmail) => isEmail((eachEmail + '').trim()));

    const isEmailIdInValid =
      validEmails?.some((itm) => itm === false) ||
      !shareToAddress?.toString().trim();

    this.setState({ isEmailIdInValid });
    if (!isEmailIdInValid) {
      this.handlePreview(false, true);
    }
  };

  handleOpenSendReportViaEmail = () => {
    const areAllInputsValid = this.validateInputs();
    this.setState({ areAllInputsValid }, () => {
      if (areAllInputsValid) {
        this.setState({ isOpenSendRepViaEmailModal: true });
      }
    });
  };

  handleTabsClick = (tabType, tabContent = []) => {
    let selectedTab =
      (tabContent &&
        Array.isArray(tabContent) &&
        tabContent.length > 0 &&
        tabContent.findIndex((v) => tabType === v?.value)) ||
      0;

    this.setState({
      selectedTab,
      currTab: tabType,
      successNotif: false,
      errorNotif: false,
    });
  };

  handleUploadCandNricsForInputField = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('attachment', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadCandNrics(currFile)
        .then((res) => {
          const { uploadedNRICs } = res || {};
          if (
            res &&
            uploadedNRICs &&
            Array.isArray(uploadedNRICs) &&
            uploadedNRICs?.length > 0
          ) {
            this.setState({ nricTxt: uploadedNRICs.join(',') });
          } else {
            this.setState({
              isExporting: false,
              successNotif: false,
              errorNotif: true,
              errMsg:
                res?.error?.message ||
                'An Error has ocurred while processing your request, Please try again later',
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
            successNotif: false,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          successNotif: false,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleUploadCandNrics = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('attachment', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadCandNrics(currFile)
        .then((res) => {
          const { uploadedNRICs } = res || {};
          if (
            res &&
            uploadedNRICs &&
            Array.isArray(uploadedNRICs) &&
            uploadedNRICs?.length > 0
          ) {
            this.setState(
              {
                uploadedNRICs,
                successNotif: true,
                errorNotif: false,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                this.handleGetCandAssessStatus();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              successNotif: false,
              errorNotif: true,
              errMsg:
                res?.error?.message ||
                'An Error has ocurred while processing your request, Please try again later',
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
            successNotif: false,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          successNotif: false,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleUploadCandBookingFile = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('attachment', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadTestBookingfile(currFile)
        .then((res) => {
          const { response } = res || {};
          if (
            res &&
            response &&
            Array.isArray(response) &&
            response?.length > 0
          ) {
            this.setState({
              candBookingStatusList: response,
              successNotif: true,
              errorNotif: false,
              notifMsg: 'Your details have been uploaded successfully',
            });
          } else {
            this.setState({
              successNotif: false,
              errorNotif: true,
              errMsg:
                res?.error?.message ||
                'An Error has ocurred while processing your request, Please try again later',
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
            successNotif: false,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          successNotif: false,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleExportCandBookingStatus = () => {
    const { candBookingStatusList } = this.state;
    const data = {
      response: candBookingStatusList,
    };

    const updateState = { isExporting: true };
    this.setState({ ...updateState }, () => {
      this.props
        .exportCandBookingStatus(data)
        .then((res) => {
          if (res && !res.error) {
            const { fileUrl } = res || {};
            if (fileUrl) {
              window.open(fileUrl, '_self');
              this.setState({ isExporting: false });
            }
          } else {
            this.setState({
              isExporting: false,
              errorNotif: true,
              successNotif: false,
              errMsg:
                res?.error?.message ||
                'An Error has ocurred while processing your request, Please try again later',
            });
            setTimeout(() => {
              this.setState({
                errMsg: '',
                errorNotif: false,
              });
            }, 5000);
          }
        })
        .catch((e) => {
          this.setState({
            isExporting: false,
            errorNotif: true,
            successNotif: false,
          });
        });
    });
  };

  // handleUploadCandNricsForUsageReport = (element) => {
  //   const fileData = element.target.files;
  //   const currFile = new FormData();
  //   const ext = element.target.value.match(/\.([^\.]+)$/)[1];
  //   const formattedExt = ext.toString().toLowerCase();
  //   const allowedTypes = ['xlsx'];
  //   const isFileAllowed = allowedTypes.includes(formattedExt);
  //   currFile.append('attachment', fileData[0]);
  //   if (isFileAllowed) {
  //     this.props
  //       .uploadCandNrics(currFile)
  //       .then((res) => {
  //         const { uploadedNRICs } = res || {};
  //         if (
  //           res &&
  //           uploadedNRICs &&
  //           Array.isArray(uploadedNRICs) &&
  //           uploadedNRICs?.length > 0
  //         ) {
  //           this.props
  //             .uploadTestBookingUsageReport({ nricList: uploadedNRICs })
  //             .then((res) => {
  //               if (res && !res.error) {
  //                 const { fileUrl } = res || {};
  //                 if (fileUrl) {
  //                   window.open(fileUrl, '_self');
  //                 }
  //               } else {
  //                 this.setState({
  //                   errorNotif: true,
  //                   successNotif: false,
  //                   errMsg:
  //                     res?.error?.message ||
  //                     'An Error has ocurred while processing your request, Please try again later',
  //                 });
  //                 setTimeout(() => {
  //                   this.setState({
  //                     errMsg: '',
  //                     errorNotif: false,
  //                   });
  //                 }, 5000);
  //               }
  //             })
  //             .catch((e) => {
  //               this.setState({
  //                 errorNotif: true,
  //                 successNotif: false,
  //               });
  //             });
  //         } else {
  //           this.setState({
  //             successNotif: false,
  //             errorNotif: true,
  //             errMsg:
  //               res?.error?.message ||
  //               'An Error has ocurred while processing your request, Please try again later',
  //           });
  //         }
  //       })
  //       .catch((e) => {
  //         this.setState({
  //           errorNotif: true,
  //           successNotif: false,
  //         });
  //       });
  //   } else {
  //     this.setState(
  //       {
  //         errorNotif: true,
  //         successNotif: false,
  //         errMsg: 'Invalid format, Please upload a valid format.',
  //       },
  //       () => {
  //         setTimeout(() => {
  //           this.setState({
  //             errorNotif: false,
  //             errMsg: '',
  //           });
  //         }, 5000);
  //       }
  //     );
  //   }
  // };

  handleGenerateUsageReport = () => {
    this.props
      .uploadTestBookingUsageReport()
      .then((res) => {
        if (res && !res.error) {
          const { fileUrl } = res || {};
          if (fileUrl) {
            window.open(fileUrl, '_self');
          }
        } else {
          this.setState({
            errorNotif: true,
            successNotif: false,
            errMsg:
              res?.error?.message ||
              'An Error has ocurred while processing your request, Please try again later',
          });
          setTimeout(() => {
            this.setState({
              errMsg: '',
              errorNotif: false,
            });
          }, 5000);
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
          successNotif: false,
        });
      });
  };

  handleGetCandAssessStatus = (exportData) => {
    const { uploadedNRICs } = this.state;
    const { user } = this.props;
    const data = {
      nricList: uploadedNRICs,
      email: user?.email,
    };

    const updateState = {};
    if (exportData) {
      data.isExport = true;
      updateState.isExporting = true;
    }
    this.setState({ ...updateState }, () => {
      this.props
        .getCandAsessStatusThirdParty(data)
        .then((res) => {
          if (res && !res.error) {
            if (exportData) {
              const { exportUuid } = res || {};
              this.interval = setInterval(() => {
                this.props
                  .getCandAsessStatusReportByUuid(exportUuid)
                  .then((res) => {
                    if (res && !res.error) {
                      const { url } = res || {};
                      if (url) {
                        window.open(url, '_self');
                        clearInterval(this.interval);
                        this.setState({ isExporting: false });
                      }
                    } else {
                      clearInterval(this.interval);
                      this.setState({
                        isExporting: false,
                        errorNotif: true,
                        successNotif: false,
                        errMsg:
                          res?.error?.message ||
                          'An Error has ocurred while processing your request, Please try again later',
                      });
                      setTimeout(() => {
                        this.setState({
                          errMsg: '',
                          errorNotif: false,
                        });
                      }, 5000);
                    }
                  })
                  .catch((e) => {
                    this.setState({
                      isExporting: false,
                      errorNotif: true,
                      successNotif: false,
                    });
                  });
              }, 4000);
            } else {
              this.setState({ candAssessStatusList: res || [] });
            }
          } else {
            this.setState({
              isExporting: false,
              successNotif: false,
              errorNotif: true,
              errMsg:
                res?.error?.message ||
                'An Error has ocurred while processing your request, Please try again later',
            });
          }
        })
        .catch((error) => {
          console.log('Error', error);
          this.setState({
            isExporting: false,
            errorNotif: true,
            successNotif: false,
          });
        });
    });
  };

  handleExport = () => {
    this.handleGetCandAssessStatus(true);
  };

  render() {
    const {
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      selectionYearOptions,
      selectedSelectionYear,
      isSelectionYearInvalid,
      scenarioIdOptions,
      selectedScenarioId,
      isScenarioIdInvalid,
      organizationOptions,
      selectedOrganization,
      emailDomain,
      isEmailDomainInvalid,
      inviteDate,
      neoTestStatusOptions,
      selectedNeoTestStatus,
      gbaTestStatusOptions,
      selectedGBATestStatus,
      numericalReasoningStatusOptions,
      selectedNumRsngTestStatus,
      criticalThinkingStatusOptions,
      selectedCritThkgTestStatus,
      previewDataList = [],
      isOpenSendRepViaEmailModal,
      isEmailIdInValid,
      shareToAddress,
      isExporting,
      isNricTxtInvalid,
      nricTxt,
      selectedLeapFilter,
      selectedUcognifyFilter,
      selectedNumReasoningFilter,
      selectedCriticalThinkingFilter,
      neoResultOptions,
      gbaResultOptions,
      criticalThinkingResultOptions,
      numericalReasoningResultOptions,
      areAllInputsValid = true,
      startDate,
      endDate,
      isDateRangeInvalid,
      selectedTab,
      candAssessStatusList,
      currTab,
      candBookingStatusList,
      previewDataBookingList,
    } = this.state || {};

    const { user } = this.props;
    const { roleId } = user;
    let tabContent = [];
    if (roleId != 14) {
      tabContent.push(
        {
          label: 'Assessment Reports',
          value: 'assessment-report',
          onClick: () => {
            this.handleTabsClick('assessment-report', tabContent);
          },
        },
        {
          label: 'Assessment Status',
          value: 'assessment-status',
          onClick: () => {
            this.handleTabsClick('assessment-status', tabContent);
          },
        }
      );
    }

    tabContent.push(
      {
        label: 'Candidate Booking',
        value: 'candidate-booking',
        onClick: () => {
          this.handleTabsClick('candidate-booking', tabContent);
        },
      },
      {
        label: 'Usage Report',
        value: 'usage-report',
        onClick: () => {
          this.handleTabsClick('usage-report', tabContent);
        },
      }
    );

    const isCandStatusListAvailable =
      candAssessStatusList &&
      Array.isArray(candAssessStatusList) &&
      candAssessStatusList.length > 0;

    const isCandBookingStatusListAvail =
      candBookingStatusList &&
      Array.isArray(candBookingStatusList) &&
      candBookingStatusList.length > 0;

    const headers = [
      {
        key: 'header_0',
        header: 'S/No',
      },
      {
        key: 'header_1',
        header: 'Name',
      },
      {
        key: 'header_2',
        header: 'NRIC',
      },
      {
        key: 'header_3',
        header: 'PSC NEO',
      },
      {
        key: 'header_4',
        header: 'NEO',
      },
      {
        key: 'header_5',
        header: 'WGIII',
      },
      {
        key: 'header_6',
        header: 'ANRA',
      },
    ];

    const rows = isCandStatusListAvailable
      ? candAssessStatusList?.map((itm, idx) => {
          const {
            fullName,
            nric,
            gba: { status: gbaTestStatus, date: gbaTestDate } = {},
            neo: { status: neoTestStatus, date: neoTestDate } = {},
            pscNeo: { status: pscNeoStatus, date: pscNeoTestDate } = {},
            vi: { status: viTestStatus, date: viTestDate } = {},
            wgiii: { status: wgiiiTestStatus, date: wgiiiTestDate } = {},
            anra: { status: anraTestStatus, date: anraTestDate } = {},
          } = itm || {};
          return {
            id: `row_${nric}_${idx}`,
            header_0: <div className="text-capitalize ">{idx + 1}</div>,
            header_1: <div className="text-capitalize">{fullName}</div>,
            header_2: <div className="text-uppercase ">{nric || '-'}</div>,
            header_3: (
              <div className="text-capitalize ">
                {pscNeoStatus || '-'}{' '}
                {pscNeoTestDate &&
                  `(${moment(pscNeoTestDate).format('DD MMM YYYY')})`}
              </div>
            ),
            header_4: (
              <div className="text-capitalize ">
                {neoTestStatus || '-'}{' '}
                {neoTestDate &&
                  `(${moment(neoTestDate).format('DD MMM YYYY')})`}
              </div>
            ),
            header_5: (
              <div className="text-capitalize">
                {wgiiiTestStatus || '-'}{' '}
                {wgiiiTestDate &&
                  `(${moment(wgiiiTestDate).format('DD MMM YYYY')})`}
              </div>
            ),
            header_6: (
              <div className="text-capitalize">
                {anraTestStatus || '-'}{' '}
                {anraTestDate &&
                  `(${moment(anraTestDate).format('DD MMM YYYY')})`}
              </div>
            ),
          };
        })
      : [];

    const candBookingStatusHeaders = [
      {
        key: 'header_0',
        header: 'S/No',
      },
      {
        key: 'header_1',
        header: 'Name',
      },
      {
        key: 'header_2',
        header: 'NRIC',
      },
      {
        key: 'header_3',
        header: 'PSC NEO',
      },
      {
        key: 'header_4',
        header: 'NEO TEST Status',
      },
      {
        key: 'header_5',
        header: 'WGIII TEST Status',
      },
      {
        key: 'header_6',
        header: 'ANRA TEST Status',
      },
    ];

    const candBookingStatusRows = isCandBookingStatusListAvail
      ? candBookingStatusList?.map((itm, idx) => {
          const {
            name,
            nric,
            pscNeo,
            neoTestStatus,
            wgiiiTestStatus,
            anraTestStatus,
          } = itm || {};
          return {
            id: `row_${nric}_${idx}`,
            header_0: <div className="text-capitalize ">{idx + 1}</div>,
            header_1: <div className="text-capitalize">{name}</div>,
            header_2: <div className="text-uppercase ">{nric || '-'}</div>,
            header_3: <div className="text-capitalize ">{pscNeo || '-'} </div>,
            header_4: (
              <div className="text-capitalize ">{neoTestStatus || '-'} </div>
            ),
            header_5: (
              <div className="text-capitalize ">{wgiiiTestStatus || '-'} </div>
            ),
            header_6: (
              <div className="text-capitalize">{anraTestStatus || '-'} </div>
            ),
          };
        })
      : [];

    return (
      <div>
        <BackRow
          paths={[
            { id: 'csc', name: 'ARS', href: '/app/csc' },
            {
              id: 'assessment-reports',
              name: 'Assessment Reports',
              href: '',
            },
          ]}
        />
        {/* <InlineNotification
          lowContrast
          title="Please note that some filters will not be applied in Preview mode. Export the data to see the expected results."
          kind="info"
          hideCloseButton
        /> */}
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />

        <div className="mb-3">
          <GenericTabs selected={selectedTab} tabContent={tabContent} />
        </div>
        {currTab ? (
          <>
            {currTab == 'assessment-report' && (
              <>
                <Tile>
                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">
                      Selection Year <span className="text-danger">*</span>
                    </div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="selectionYear"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className={`w-100 ${
                          isSelectionYearInvalid ? 'invalid' : ''
                        }`}
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={selectionYearOptions}
                        name="selectedSelectionYear"
                        placeholder="Selection Year"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedSelectionYear}
                      />
                      {isSelectionYearInvalid && (
                        <div className="xpa-error-msg mt-1">
                          Please select selection year
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">Scenario</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="scenarioId"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className={`w-100 ${
                          isScenarioIdInvalid ? 'invalid' : ''
                        }`}
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={scenarioIdOptions}
                        name="selectedScenarioId"
                        placeholder="Scenario"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedScenarioId}
                      />
                      {/* {isScenarioIdInvalid && (
                    <div className="xpa-error-msg mt-1">
                      Please select scenario
                    </div>
                  )} */}
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">Start Date</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <DatePicker
                        selected={startDate}
                        name="startDate"
                        id="startDate"
                        onChange={(date) =>
                          this.onDatePickerChange(date, 'startDate')
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Start Date"
                        className="css-h-d-input"
                        maxDate={new Date()}
                        isClearable
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">End Date</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <div className="d-flex flex-column">
                        <div>
                          <DatePicker
                            selected={endDate}
                            name="endDate"
                            id="endDate"
                            onChange={(date) =>
                              this.onDatePickerChange(date, 'endDate')
                            }
                            dateFormat="dd/MM/yyyy"
                            placeholderText="End Date"
                            className="css-h-d-input"
                            maxDate={new Date()}
                            isClearable
                            autoComplete="off"
                          />
                        </div>

                        {isDateRangeInvalid && (
                          <div className="xpa-error-msg mt-1">
                            Please select a valid date. Start date cannot be
                            greater than end date.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">NRIC</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <div className="d-flex">
                        <div className="w-50">
                          <TextInput
                            id="nric"
                            className="mb-2"
                            labelText=""
                            hideLabel
                            name="nricTxt"
                            invalid={isNricTxtInvalid}
                            invalidText="Please enter valid NRIC"
                            placeholder="Enter comma separated NRIC"
                            onChange={(ev) => {
                              this.setState({
                                [ev.target.name]: ev.target.value,
                                isNricTxtInvalid: false,
                              });
                            }}
                            value={nricTxt}
                          />
                        </div>
                        <div className="ml-3 d-flex mt-lg-0">
                          <div className="text-right">
                            <FileUploaderButton
                              buttonLabel="Upload"
                              labelText="Upload"
                              buttonKind="primary"
                              accept={['.xlsx']}
                              disableLabelChanges
                              name="file"
                              onChange={(e) =>
                                this.handleUploadCandNricsForInputField(e)
                              }
                              className="bx--btn--sm"
                            />
                            <div className="small mt-2">
                              .xlsx format is allowed
                            </div>
                          </div>
                          <div className="ml-2">
                            <Button
                              size="small"
                              className="txt-dec-none"
                              kind="primary"
                              href={SampleCandNricTemplate}>
                              Download Template
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">Organization/School</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="organization"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={organizationOptions}
                        name="selectedOrganization"
                        placeholder="Organization/School"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedOrganization}
                      />
                    </div>
                  </div>

                  {/* <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">Email Domain</div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <TextInput
                id="emailDomain"
                name="emailDomain"
                value={emailDomain}
                onChange={this.handleChange}
                labelText=""
                hideLabel
                type="text"
                placeholder="google.com"
                invalid={isEmailDomainInvalid}
                invalidText="Please enter vaild email domain"
              />
            </div>
          </div> */}

                  {/* <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">Invite Date</div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <DatePicker
                selected={inviteDate}
                name="inviteDate"
                id="inviteDate"
                onChange={(date) => this.onDatePickerChange(date, 'inviteDate')}
                dateFormat="dd/MM/yyyy"
                placeholderText="Invite Date"
                className="css-h-d-input"
                maxDate={new Date()}
              />
            </div>
          </div> */}

                  <div className="mt-3 font-weight-bold">
                    Test Status Filters
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-1">
                    <div className="bx--col-lg-4">Neo</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="neoTestStatus"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={neoTestStatusOptions}
                        name="selectedNeoTestStatus"
                        placeholder="Neo Status"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedNeoTestStatus}
                      />
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">uCognify</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="gbaTestStatus"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={gbaTestStatusOptions}
                        name="selectedGBATestStatus"
                        placeholder="uCognify Status"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedGBATestStatus}
                      />
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">Numerical Reasoning Test</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="numReasoningTestStatus"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={numericalReasoningStatusOptions}
                        name="selectedNumRsngTestStatus"
                        placeholder="Numerical Reasoning Test Status"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedNumRsngTestStatus}
                      />
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">Critical Thinking Test</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="criticalThinkingTestStatus"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={criticalThinkingStatusOptions}
                        name="selectedCritThkgTestStatus"
                        placeholder="Critical Thinking Test Status"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedCritThkgTestStatus}
                      />
                    </div>
                  </div>

                  <div className="mt-3 font-weight-bold">
                    Test Category Filters
                  </div>
                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-1">
                    <div className="bx--col-lg-4">LEAP</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="leapFilter"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={neoResultOptions}
                        name="selectedLeapFilter"
                        placeholder="LEAP"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedLeapFilter}
                      />
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">uCognify</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="ucognifyFilter"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={gbaResultOptions}
                        name="selectedUcognifyFilter"
                        placeholder="uCognify"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedUcognifyFilter}
                      />
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">Numerical Reasoning</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="numericalReasoningFilter"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={numericalReasoningResultOptions}
                        name="selectedNumReasoningFilter"
                        placeholder="Numerical Reasoning"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedNumReasoningFilter}
                      />
                    </div>
                  </div>

                  <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                    <div className="bx--col-lg-4">Critical Thinking</div>
                    <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                      <Dropdown
                        id="criticalThinkingFilter"
                        title=""
                        custom
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={criticalThinkingResultOptions}
                        name="selectedCriticalThinkingFilter"
                        placeholder="Critical Thinking"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedCriticalThinkingFilter}
                      />
                    </div>
                  </div>

                  <div className="bx--row mb-2 mt-3">
                    {/* <div className="mx-2">
              <Button kind="tertiary" onClick={() => this.handlePreview()}>
                Preview
              </Button>
            </div> */}
                    <div className="mx-2">
                      <Button
                        kind="primary"
                        onClick={() => this.handlePreview(true)}
                        disabled={isExporting}>
                        {isExporting ? 'Exporting...' : 'Export'}
                      </Button>
                    </div>
                    <div className="mx-2">
                      <Button
                        kind="primary"
                        onClick={() => this.handlePreview(false, true)}>
                        Send Report via Email
                      </Button>
                    </div>
                  </div>

                  {!areAllInputsValid && (
                    <div className="xpa-error-msg">
                      Please check each field and fix any errors that are
                      showing.
                    </div>
                  )}
                </Tile>

                {/* <div className="mt-2">
          {previewDataList &&
            Array.isArray(previewDataList) &&
            previewDataList.length > 0 && (
              <GenericDataTable
                tableWrapClassName="x-table-scroll y-table-scroll-hidden"
                className="attendees-table"
                tbodyClass="candidate-background-color"
                otherActions={[]}
                headers={Object.keys(previewDataList?.[0])?.map((itm, idx) => {
                  return {
                    key: `header_${idx}`,
                    header: itm,
                  };
                })}
                rows={
                  previewDataList?.map((itm, idx) => {
                    const obj = {};
                    Object.keys(itm)?.forEach((key, key_idx) => {
                      obj[`header_${key_idx}`] = (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            {key}:
                          </div>
                          <div className="xpa-name-overflow text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {itm[key]}
                          </div>
                        </div>
                      );
                    });
                    return {
                      id: `row_${itm['Candidate Id']}_${idx}`,
                      key: `row_${itm['Candidate Id']}_${idx}`,
                      ...obj,
                    };
                  }) || []
                }
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
            )}
        </div> */}

                {isOpenSendRepViaEmailModal && (
                  <Modal
                    open={isOpenSendRepViaEmailModal}
                    modalHeading="Export Report Via Email"
                    modalLabel="Action"
                    primaryButtonText="Submit"
                    secondaryButtonText="Cancel"
                    iconDescription="Close the modal"
                    onRequestClose={this.dismissSendRepViaEmailModal}
                    onRequestSubmit={this.handleSendReportViaEmail}>
                    <div className="">
                      <TextInput
                        id="emailshare"
                        className="mb-2"
                        labelText="To:"
                        name="shareToAddress"
                        invalid={isEmailIdInValid}
                        invalidText="Please enter valid email address"
                        placeholder="Enter comma separated email address"
                        onChange={(ev) => {
                          this.setState({
                            [ev.target.name]: ev.target.value,
                            isEmailIdValid: false,
                          });
                        }}
                        value={shareToAddress}
                      />
                    </div>
                  </Modal>
                )}
              </>
            )}

            {currTab == 'assessment-status' && (
              <Tile>
                <div className="d-flex mt-lg-0">
                  <div className="text-right">
                    <FileUploaderButton
                      buttonLabel="Upload"
                      labelText="Upload"
                      buttonKind="primary"
                      accept={['.xlsx']}
                      disableLabelChanges
                      name="file"
                      onChange={(e) => this.handleUploadCandNrics(e)}
                    />
                    <div className="small mt-2">.xlsx format is allowed</div>
                  </div>
                  <div className="ml-2">
                    <Button
                      className="txt-dec-none"
                      kind="primary"
                      href={SampleCandNricTemplate}>
                      Download Template
                    </Button>
                  </div>
                  <div className="ml-4">
                    <Button
                      kind="primary"
                      size="sm"
                      disabled={!isCandStatusListAvailable || isExporting}
                      onClick={() => this.handleExport()}>
                      {isExporting ? 'Exporting...' : 'Export'}
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  {isCandStatusListAvailable ? (
                    <GenericDataTable
                      otherActions={[]}
                      searchable={false}
                      expandable={false}
                      rows={rows}
                      headers={headers}
                    />
                  ) : (
                    <NoContentBox message="There are no candidates for your current selection." />
                  )}
                </div>
              </Tile>
            )}
            {currTab == 'candidate-booking' && (
              <Tile>
                <div className="d-flex mt-lg-0">
                  <div className="text-right">
                    <FileUploaderButton
                      buttonLabel="Upload"
                      labelText="Upload"
                      buttonKind="primary"
                      accept={['.xlsx']}
                      disableLabelChanges
                      name="file"
                      onChange={(e) => this.handleUploadCandBookingFile(e)}
                    />
                    <div className="small mt-2">.xlsx format is allowed</div>
                  </div>
                  <div className="ml-2">
                    <Button
                      className="txt-dec-none"
                      kind="primary"
                      href={SampleTestBookingTemplate}>
                      Download Template
                    </Button>
                  </div>
                  <div className="ml-4">
                    <Button
                      kind="primary"
                      size="sm"
                      disabled={!isCandBookingStatusListAvail || isExporting}
                      onClick={() => this.handleExportCandBookingStatus()}>
                      {isExporting ? 'Exporting...' : 'Export'}
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  {isCandBookingStatusListAvail ? (
                    <GenericDataTable
                      otherActions={[]}
                      searchable={false}
                      expandable={false}
                      rows={candBookingStatusRows}
                      headers={candBookingStatusHeaders}
                    />
                  ) : (
                    <NoContentBox message="There are no candidates for your current selection." />
                  )}
                </div>
              </Tile>
            )}
            {currTab == 'usage-report' && (
              <Tile>
                <div className="d-flex mt-lg-0">
                  {/* <div className="text-right">
                <FileUploaderButton
                  buttonLabel="Upload"
                  labelText="Upload"
                  buttonKind="primary"
                  accept={['.xlsx']}
                  disableLabelChanges
                  name="file"
                  onChange={(e) => this.handleUploadCandNricsForUsageReport(e)}
                />
                <div className="small mt-2">.xlsx format is allowed</div>
              </div>
              <div className="ml-2">
                <Button
                  className="txt-dec-none"
                  kind="primary"
                  href={SampleCandNricTemplate}>
                  Download Template
                </Button>
              </div> */}
                  <Button
                    className="txt-dec-none"
                    kind="primary"
                    onClick={() => this.handleGenerateUsageReport()}>
                    Generate Report
                  </Button>
                </div>
              </Tile>
            )}
          </>
        ) : (
          <>
            <NotFound roleId={roleId} />
          </>
        )}

        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getAssessmentsReportInfo,
  getAsessReportStatusById,
  getAsessReportDropdownInfo,
  uploadCandNrics,
  getCandAsessStatusThirdParty,
  getCandAsessStatusReportByUuid,
  uploadTestBookingfile,
  exportCandBookingStatus,
  uploadTestBookingUsageReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessCandidateReports);
