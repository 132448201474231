import React, { Component } from 'react';
import PublicHeaderMock from './PublicHeaderMock';
import GenericTabs from '../../components/common/GenericTabs';
import { Tile, FileUploaderDropContainer } from 'carbon-components-react';

class MockReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSideNavExpanded: false,
    };
  }

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  handleTabClick = (tabNumber) => {
    this.setState({
      selectedTab: tabNumber,
    });
  };

  render() {
    const { history } = this.props || {};
    const { push } = history || {};
    const { isSideNavExpanded, selectedTab } = this.state;
    return (
      <div>
        <div>
          <PublicHeaderMock
            onClickSideNavExpand={this.onClickSideNavExpand}
            isSideNavExpanded={isSideNavExpanded}
            push={push}
            history={history}
          />
          <div className="bx--grid mt-5 xpa--ht-70">
            <div>
              <GenericTabs
                className="xpa-cand-info--tabs xpa-c-tabs--vertical m-0"
                selected={selectedTab}
                tabContent={[
                  {
                    label: 'PSAC & Peer Appraisal',
                    value: '',
                    onClick: () => {
                      this.handleTabClick(9);
                    },
                  },
                ]}
              />
            </div>
            <Tile className="mt-2">
              <h5>Assessment Center Data</h5>
              <hr />
              <div className="mb-3">
                <div className="w-50">
                  <FileUploaderDropContainer
                    labelText="Click here to upload your files"
                    multiple
                    name=""
                    role=""
                    size="default"
                    tabIndex={0}
                  />
                </div>
              </div>
            </Tile>
            <Tile className="mt-2">
              <h5>Peer Ratings Data</h5>
              <hr />
              <div className="mb-3">
                <div className="w-50">
                  <FileUploaderDropContainer
                    labelText="Click here to upload your files"
                    multiple
                    name=""
                    role=""
                    size="default"
                    tabIndex={0}
                  />
                </div>
              </div>
            </Tile>
            <Tile className="mt-2">
              <h5>Psychological Ratings</h5>
              <hr />
              <div className="mb-3">
                <div className="w-50">
                  <FileUploaderDropContainer
                    labelText="Click here to upload your files"
                    multiple
                    name=""
                    role=""
                    size="default"
                    tabIndex={0}
                  />
                </div>
              </div>
            </Tile>
          </div>
        </div>
      </div>
    );
  }
}

export default MockReports;
