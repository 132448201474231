import React, { Component } from 'react';
import PublicHeaderMock from './PublicHeaderMock';
import NoContentBox from '../../components/common/NoContentBox';
import GenericDataTable from '../../components/common/GenericDataTable';
import {
  Search,
  Tile,
  Button,
  Form,
  Checkbox,
  Select,
  SelectItem,
} from 'carbon-components-react';
import GenericTabs from '../../components/common/GenericTabs';

class SearchCandidatesMock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isSideNavExpanded: false,
      showTableData: true,
      candidatesList: [
        {
          fullName: 'Isaiah Chia',
          nric: 'Txxxx516I',
          batch: '2020',
          school: 'Singapore Polytechnic',
        },
        {
          fullName: 'Wee Baey Kok',
          nric: 'Txxxx532L',
          batch: '2020',
          school: 'National Junior College',
        },
        {
          fullName: 'Jacob Lim',
          nric: 'Txxxx531J',
          batch: '2019',
          school: 'NUS High School of Math and Science',
        },
        {
          fullName: 'Sophia Wong',
          nric: 'Txxxx377K',
          batch: '2018',
          school: 'St. Josephs Institution',
        },
      ],
    };
  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    const { searchKey, candidatesList } = this.state;
    this.setState(
      {
        showSearchList: true,
      },
      () => {
        if (searchKey) {
          const filtereddata = candidatesList.filter(
            (res) =>
              (res &&
                res.fullName.toString().toLowerCase().indexOf(searchKey) >=
                  0) ||
              (res &&
                res.nric.toString().toLowerCase().indexOf(searchKey) >= 0) ||
              (res &&
                res.batch.toString().toLowerCase().indexOf(searchKey) >= 0)
          );
          this.setState({
            candidatesList: filtereddata,
          });
        } else {
          this.setState({
            candidatesList: [
              {
                fullName: 'Isaiah Chia',
                nric: 'Txxxx516I',
                batch: 2020,
                school: 'Singapore Polytechnic',
              },
              {
                fullName: 'Wee Baey Kok',
                nric: 'Txxxx532L',
                batch: 2020,
                school: 'National Junior College',
              },
              {
                fullName: 'Jacob Lim',
                nric: 'Txxxx531J',
                batch: 2019,
                school: 'NUS High School of Math and Science',
              },
              {
                fullName: 'Sophia Wong',
                nric: 'Txxxx377K',
                batch: 2018,
                school: 'St. Josephs Institution',
              },
            ],
          });
        }
      }
    );
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const { searchKey } = this.state;
        if (!searchKey) {
          this.setState({
            candidatesList: [
              {
                fullName: 'Isaiah Chia',
                nric: 'Txxxx516I',
                batch: 2020,
                school: 'Singapore Polytechnic',
              },
              {
                fullName: 'Wee Baey Kok',
                nric: 'Txxxx532L',
                batch: 2020,
                school: 'National Junior College',
              },
              {
                fullName: 'Jacob Lim',
                nric: 'Txxxx531J',
                batch: 2019,
                school: 'NUS High School of Math and Science',
              },
              {
                fullName: 'Sophia Wong',
                nric: 'Txxxx377K',
                batch: 2018,
                school: 'St. Josephs Institution',
              },
            ],
          });
        }
      }
    );
  };

  handleTabClick = (tabType) => {
    let selectedTab = 0;
    if (tabType === 'cand') {
      selectedTab = 0;
    } else {
      selectedTab = 1;
    }
    this.setState({
      selectedTab,
    });
  };

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  render() {
    const { history } = this.props || {};
    const { push } = history || {};
    const {
      showSearchList,
      candidatesList,
      searchKey,
      selectedTab,
      isSideNavExpanded,
    } = this.state;
    return (
      <div>
        <PublicHeaderMock
          onClickSideNavExpand={this.onClickSideNavExpand}
          isSideNavExpanded={isSideNavExpanded}
          push={push}
          history={history}
        />
        <div className=" bx--grid mt-5 xpa--ht-70 css-search-height">
          <GenericTabs
            selected={selectedTab}
            tabContent={[
              {
                label: 'Search Candidates',
                value: '',
                onClick: () => {
                  this.handleTabClick('cand');
                },
              },
              {
                label: 'Candidate Comparision',
                value: '',
                onClick: () => {
                  this.handleTabClick('compare');
                },
              },
            ]}
          />
          {selectedTab === 0 && (
            <div className="bx--row">
              <div className="bx--col-lg-3">
                <Tile>
                  <h4>Filter</h4>
                  <hr />
                  <div className="h6 mb-2 f-w-b">CCC Tier</div>
                  <Checkbox
                    id={1}
                    labelText={<div className="h6 mt-1">1</div>}
                  />
                  <Checkbox
                    id={2}
                    labelText={<div className="h6 mt-1">2</div>}
                  />
                  <Checkbox
                    id={3}
                    labelText={<div className="h6 mt-1">3</div>}
                  />
                  <Checkbox
                    id={4}
                    labelText={<div className="h6 mt-1">4</div>}
                  />

                  <div className="h6 mb-2 mt-2 f-w-b">
                    Psychometric Test Results
                  </div>
                  <Checkbox
                    id={`Strongly-Meets-${1}`}
                    labelText={<div className="h6 mt-1">Strongly Meets</div>}
                  />
                  <Checkbox
                    id={`Meets-${2}`}
                    labelText={<div className="h6 mt-1">Meets</div>}
                  />
                  <Checkbox
                    id={`Does-not-meet-${3}`}
                    labelText={<div className="h6 mt-1">Does not meet</div>}
                  />
                  <div className="h6 mb-2 mt-2 f-w-b">GBA</div>
                  <Checkbox
                    id={`High-${1}`}
                    labelText={<div className="h6 mt-1">High Fit</div>}
                  />
                  <Checkbox
                    id={`Moderate-${2}`}
                    labelText={<div className="h6 mt-1">Moderate Fit</div>}
                  />
                  <Checkbox
                    id={`Poor-${3}`}
                    labelText={<div className="h6 mt-1">Poor Fit</div>}
                  />
                  <div className="h6 mb-2 mt-2 f-w-b">
                    Psychological Interview Rating
                  </div>
                  <Checkbox
                    id={`Excellent-${1}`}
                    labelText={<div className="h6 mt-1">Excellent</div>}
                  />
                  <Checkbox
                    id={`Strong-${2}`}
                    labelText={<div className="h6 mt-1">Strong</div>}
                  />
                  <Checkbox
                    id={`Moderate-${3}`}
                    labelText={<div className="h6 mt-1">Moderate</div>}
                  />
                  <Checkbox
                    id={`Weak-${4}`}
                    labelText={<div className="h6 mt-1">Weak</div>}
                  />
                  <Checkbox
                    id={`Very-Weak-${5}`}
                    labelText={<div className="h6 mt-1">Very Weak</div>}
                  />
                </Tile>
              </div>
              <div className="bx--col-lg-9 pl-0">
                <Tile className="">
                  <div className="d-flex flex-column align-items-center css-search-height">
                    <div className="w-100 ">
                      <h4 className="">Search Candidates</h4>
                      <Form onSubmit={this.handleSearchSubmit}>
                        <div className="bx--row justify-content-center">
                          <div className="bx--col-lg-10 mt-3">
                            <Search
                              id="search-1"
                              labelText="Search"
                              name="searchKey"
                              onChange={this.handleChange}
                              placeHolderText="Search by Candidate's Name / NRIC / Batch"
                              size="xl"
                              type="text"
                              value={searchKey}
                            />
                          </div>
                          <div className="bx--col-lg-2 mt-3">
                            <Button
                              onClick={this.handleSearchSubmit}
                              kind="primary">
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Form>

                      {showSearchList === true && (
                        <div className="mt-3">
                          <div>
                            <h4 className="mb-3">Search Results</h4>
                          </div>
                          <GenericDataTable
                            otherActions={[]}
                            tbodyClass="candidate-background-color"
                            searchable={false}
                            onSearchInputChange={this.handleSearchSubmit}
                            rows={
                              candidatesList &&
                              Array.isArray(candidatesList) &&
                              candidatesList.length > 0
                                ? candidatesList.map((interview, idx) => {
                                    const {
                                      fullName,
                                      nric,
                                      batch,
                                      school,
                                    } = interview;
                                    return {
                                      isExpanded: false,
                                      id: `row_${idx}`,
                                      header_0: (
                                        <div
                                          className="xpa-link f-w-b h6"
                                          onClick={() => {
                                            push &&
                                              push(
                                                `/public/candidate/${encodeURIComponent(
                                                  fullName
                                                )}`
                                              );
                                          }}>
                                          {fullName}
                                        </div>
                                      ),
                                      header_1: <div>{nric}</div>,
                                      header_2: <div>{school}</div>,
                                      header_3: <div>{batch}</div>,
                                      header_4: (
                                        <Button size="small">
                                          Add to Compare
                                        </Button>
                                      ),
                                    };
                                  })
                                : []
                            }
                            headers={[
                              { key: 'header_0', header: 'Name' },
                              { key: 'header_1', header: 'NRIC' },
                              { key: 'header_2', header: 'School' },
                              { key: 'header_3', header: 'Batch' },
                              { key: 'header_4', header: 'Action' },
                            ]}
                          />
                          <div>
                            {candidatesList &&
                              Array.isArray(candidatesList) &&
                              candidatesList.length <= 0 && (
                                <NoContentBox message="There are no applicants for your current selection" />
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Tile>
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <Tile>
              <h6>Candidate Comparision</h6>
              <hr />
              <div className="d-flex justify-content-around"></div>
              <table className="w-100">
                <thead>
                  <th></th>
                  <th>
                    <div className="mr-2 h4 f-w-b text-center">Zhang Li</div>
                  </th>
                  <th>
                    <div className="mr-2 h4 f-w-b text-center">Li Jie</div>
                  </th>
                  <th>
                    <div className="mr-2 h4 f-w-b text-center">Zhang Yong</div>
                  </th>
                  <th>
                    <div className="mr-2 h4 f-w-b text-center">Wang Yong</div>
                  </th>
                  <th>
                    <div className="mr-2 h4 f-w-b text-center">Liu Yang</div>
                  </th>
                  <th>
                    <div className="mr-2 h4 f-w-b text-center">
                      Wang Xiu Ying
                    </div>
                  </th>
                </thead>
                <tbody className="table-border">
                  <tr>
                    <td className="f-w-b">School</td>
                    <td className="css-main-text-c">Singapore Polytechnic</td>
                    <td className="css-main-text-c">National Junior College</td>
                    <td className="css-main-text-c">
                      National University of Singapore
                    </td>
                    <td className="css-main-text-c">St. Josephs Institution</td>
                    <td className="css-main-text-c">
                      NUS High School of Mathematics and science
                    </td>
                    <td className="css-main-text-c">YY Secondary School</td>
                  </tr>
                  <tr>
                    <td className="f-w-b">Career Preferences</td>
                    <td className="css-main-text-c">Public Administration</td>
                    <td className="css-main-text-c">Engineering</td>
                    <td className="css-main-text-c">Legal service</td>
                    <td className="css-main-text-c">Foreign service</td>
                    <td className="css-main-text-c">Public / Finance</td>
                    <td className="css-main-text-c">Medicine / Dentistry</td>
                  </tr>
                  <tr>
                    <td className="f-w-b">Critical Thinking</td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">95</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--l`}>
                          <div className="csc-pos--top-c">61</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">95</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--vl`}>
                          <div className="csc-pos--top-c">17</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--l`}>
                          <div className="csc-pos--top-c">59</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--vl`}>
                          <div className="csc-pos--top-c">20</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="f-w-b">Numerical Reasoning</td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--vl`}>
                          <div className="csc-pos--top-c">18</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">90</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--l`}>
                          <div className="csc-pos--top-c">63</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">96</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--vl`}>
                          <div className="csc-pos--top-c">21</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">96</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="f-w-b">Performance Based</td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">95</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--l`}>
                          <div className="csc-pos--top-c">61</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">95</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--vl`}>
                          <div className="csc-pos--top-c">17</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--l`}>
                          <div className="csc-pos--top-c">59</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--vl`}>
                          <div className="csc-pos--top-c">20</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="f-w-b">Future Driven</td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--vl`}>
                          <div className="csc-pos--top-c">18</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">90</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--vl`}>
                          <div className="csc-pos--top-c">21</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">96</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--h`}>
                          <div className="csc-pos--top-c">96</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="w-100 d-flex justify-content-center">
                        <div
                          className={`csc-th--block border-radius-50 csc-th--l`}>
                          <div className="csc-pos--top-c">63</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-3 d-flex">
                <div>
                  <Button>Add Parameter</Button>
                </div>
                <div className="ml-2">
                  <Select id="param" hideLabel>
                    <SelectItem label="Select" id="award" />
                    <SelectItem label="Award" id="award" />
                    <SelectItem label="Average" id="average" />
                    <SelectItem label="Leadership" id="leadership" />
                    <SelectItem label="Representation" id="representation" />
                  </Select>
                </div>
              </div>
            </Tile>
          )}
        </div>
      </div>
    );
  }
}

export default SearchCandidatesMock;
