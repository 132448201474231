import React from 'react';

const ColoredRectangle = ({ text, color }) => {
  return (
    <div
      className="text-center bold mt-1 mb-2"
      style={{
        width: 'min-content',
        fontWeight: 'bold',
        backgroundColor: color,
        borderRadius: '5px',
        padding: '1rem',
        // border: 'groove',
      }}>
      <span> {text === 'Moderate' ? 'Medium' : text}</span>
    </div>
  );
};

export default ColoredRectangle;
