import React, { useState, useRef, useEffect } from 'react';
import './ChatGPTSearchBox.css';

const lineHeight = 24;
const maxRows = 5;

const ChatGPTSearchBox = ({
  handleSubmit,
  taskId,
  isAIGeneratedPromptAvail,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [rows, setRows] = useState(1);
  const textAreaRef = useRef(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    setSending(!!taskId);
  }, [taskId]);

  useEffect(() => {
    if (isAIGeneratedPromptAvail) setInputValue('');
  }, [isAIGeneratedPromptAvail]);

  const handleOnInput = (event) => {
    const textarea = textAreaRef.current;

    // Reset rows to 1 for accurate height calculation
    if (textarea.value === '') {
      setRows(1); // Reset to 1 row when textarea is empty
      return;
    }

    // Calculate the new rows based on scrollHeight
    const newRows = Math.floor(textarea.scrollHeight / lineHeight); // Adjust 24 based on your line-height

    if (rows < 5) setRows(newRows); // Set the new row count
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const scrollToCursor = () => {
    const cursorPosition = textAreaRef?.current?.selectionStart;
    const cursorLine = inputValue
      ?.slice(0, cursorPosition)
      ?.split('\n')?.length;
    const scrollTop = (cursorLine - 1) * lineHeight;
    textAreaRef.current.scrollTop = scrollTop;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      const currentValue = inputValue;
      setInputValue(currentValue + '\n');
      setRows((prevRows) => (prevRows < maxRows ? prevRows + 1 : prevRows));
      setTimeout(() => {
        scrollToCursor();
        textAreaRef.current.focus();
      }, 0);
    }

    if (
      (event.key === 'Backspace' || event.key === 'Delete') &&
      inputValue?.length > 0
    ) {
      const selectionStart = textAreaRef?.current?.selectionStart;
      const selectionEnd = textAreaRef?.current?.selectionEnd;

      if (selectionStart === 0 && selectionEnd === inputValue?.length) {
        event.preventDefault();
        setInputValue('');
        setRows(1);
        setTimeout(() => {
          textAreaRef.current.focus();
        }, 0);
        return;
      }
    }

    if (event.key === 'Backspace') {
      const lines = inputValue?.split('\n');
      const lastLine = lines?.[lines?.length - 1];
      if (lastLine?.length === 0 && lines?.length > 1) {
        event.preventDefault();
        setInputValue(lines?.slice(0, -1).join('\n'));
        setRows(rows - 1);
        setTimeout(() => {
          textAreaRef.current.focus();
        }, 0);
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const newInputValue = inputValue + pastedText;
    const currentRows = Math.min(
      Math.floor(
        (newInputValue?.split('\n')?.length * lineHeight) / lineHeight
      ),
      maxRows
    );
    setInputValue(newInputValue);
    setRows(currentRows);
  };

  const isInputAvailable = inputValue?.trim().length > 0;

  return (
    <div className="chatGpt-search-main-div">
      <textarea
        ref={textAreaRef}
        value={inputValue}
        rows={rows}
        placeholder="How can I help?"
        onInput={handleOnInput}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="expanding-textarea pl-3"
        onPaste={handlePaste}
        style={{
          overflow: rows < 5 ? 'hidden' : 'auto',
          lineHeight: `${lineHeight}px`,
        }}
      />

      <button
        disabled={sending}
        className="chatGpt-search-button"
        onClick={() => handleSubmit(inputValue)}>
        {isInputAvailable ? (
          <i
            class="fa-solid fa-circle-up fa-2x"
            title="Submit"
            aria-label="hidden"></i>
        ) : (
          <i class="fa-regular fa-circle-up fa-2x" title="Message is Empty"></i>
        )}
      </button>
    </div>
  );
};

export default ChatGPTSearchBox;
