import React, { useState } from 'react';
//Common packages
import {
  TextInput,
  Select,
  SelectItem,
  Modal,
  Toggle,
  InlineNotification,
  Checkbox,
} from 'carbon-components-react';
import Dropdown from '../../components/common/Dropdown';

const UpdateUserDetails = (props) => {
  const {
    firstname: propFirstName,
    lastname: propLastName,
    roleId: propRoleId,
    roles,
    email,
    isValidRole,
    isValidFirstName,
    isValidLastName,
    handleSubmitEditUser,
    isOpenEditUserModal,
    dismissEditUserModal,
    finalRoles,
    active: propActive,
    showActiveNotifMsg,
    paUsersList,
    selectedPAList,
    panelMemUsersList,
    selectedPanelMemObj,
    handleDropdownChange,
    onInputUserMappingsChange,
    nric: propNric,
    isValidNric,
  } = props || {};
  const [firstname, setFirstname] = useState(propFirstName);
  const [lastname, setLastName] = useState(propLastName);
  const [roleId, setRoleId] = useState(propRoleId);
  const [nric, setNric] = useState(propNric);
  const [active, setActiveStatus] = useState(propActive);
  const [updateEmailMsg, setshowActiveNotifMsg] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState(false);

  const onRequestSubmit = () => {
    handleSubmitEditUser({
      firstname,
      lastname,
      roleId,
      active,
      updateEmailMsg,
      selectedPAList,
      selectedPanelMemObj,
      nric,
    });
  };

  const handleSetActiveStatus = (e) => {
    setActiveStatus(e);
    if (e === true) {
      setshowActiveNotifMsg(true);
    }
    if (e === false) {
      setRoleId(100);
    } else if (e === true && roleId == 100) {
      setRoleId(0);
    }
  };

  return (
    <div>
      <Modal
        open={isOpenEditUserModal}
        modalHeading="Edit User"
        modalLabel="Action"
        primaryButtonText="Submit"
        secondaryButtonText="Cancel"
        iconDescription="Close the modal"
        onRequestClose={dismissEditUserModal}
        onRequestSubmit={onRequestSubmit}
        primaryButtonDisabled={
          propRoleId == 7 && roleId == 100 && checkboxSelected !== true
        }>
        {showActiveNotifMsg === true && (
          <InlineNotification
            lowContrast
            title="User reactivation email has been triggered successfully to you."
            iconDescription="describes the close button"
            kind="success"
            onCloseButtonClick={true}
          />
        )}
        <div className="mt-2">
          <TextInput
            name="firstname"
            id="firstname"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            placeholder="Please enter first name"
            invalid={isValidFirstName}
            invalidText="Please enter valid First Name"
            labelText="First Name"
          />
        </div>
        <div className="mt-2">
          <TextInput
            name="lastname"
            id="lastname"
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Please enter last name"
            invalid={isValidLastName}
            invalidText="Please enter valid Last Name"
            labelText="Last Name"
          />
        </div>
        <div className="mt-2">
          <TextInput
            name="email"
            id="email"
            value={email}
            placeholder="janedoe@gmail.com"
            disabled
            labelText="Email"
          />
        </div>
        <div className="d-flex mt-2 w-100">
          <div className="">
            <Select
              name="roleId"
              id="roleId"
              onChange={(e) => setRoleId(e.target.value)}
              value={roleId}
              invalid={isValidRole}
              invalidText="Please select valid role"
              labelText="Select Role">
              <SelectItem text="Select" key={0} />
              {finalRoles &&
                Array.isArray(finalRoles) &&
                finalRoles.length > 0 &&
                finalRoles.map((role) => {
                  const { roleId: propRoleId, roleName } = role || {};
                  return (
                    <SelectItem
                      text={roleName}
                      key={propRoleId}
                      id={propRoleId}
                      value={propRoleId}
                      disabled={active === true && propRoleId == 100}
                    />
                  );
                })}
            </Select>
          </div>
          {roleId == 7 && (
            <div className="ml-3 w-100">
              <div className="bx--label">Select Personal Assistant/s</div>
              <Dropdown
                id="choose-personal-assisst-dd"
                title=""
                mainClassName="bx--form-item mb-0"
                titleClass=""
                labelClass=""
                custom
                isMulti
                label=""
                className="w-100"
                options={
                  paUsersList &&
                  Array.isArray(paUsersList) &&
                  paUsersList.length > 0
                    ? paUsersList.map((res) => {
                        const { userId, firstname, lastname, isTagged } =
                          res || {};
                        return {
                          label: `${firstname} ${lastname} ${
                            isTagged ? '- Is already tagged' : ''
                          }`,
                          value: userId,
                        };
                      })
                    : []
                }
                placeholder="Start typing to choose PA's"
                onChange={(value, e, name, action) =>
                  handleDropdownChange(value, e, name, action, roleId)
                }
                onInputChange={(value, cb) => {
                  onInputUserMappingsChange(value, cb, roleId);
                }}
                name="selectedPAList"
                selectedValue={selectedPAList}
              />
            </div>
          )}

          {roleId == 13 && (
            <div className="ml-3 w-100">
              <div className="bx--label">Select Panel Members</div>
              <Dropdown
                id="choose-panel-mem-dd"
                title=""
                mainClassName="bx--form-item mb-0"
                titleClass=""
                labelClass=""
                custom
                // isMulti
                label=""
                className="w-100"
                options={
                  panelMemUsersList &&
                  Array.isArray(panelMemUsersList) &&
                  panelMemUsersList.length > 0
                    ? panelMemUsersList.map((res) => {
                        const { userId, firstname, lastname, isTagged } =
                          res || {};
                        return {
                          label: `${firstname} ${lastname} ${
                            isTagged ? '- Is already tagged' : ''
                          }`,
                          value: userId,
                        };
                      })
                    : []
                }
                placeholder="Start typing to choose Panel members"
                onChange={(value, e, name, action) =>
                  handleDropdownChange(value, e, name, action, roleId)
                }
                onInputChange={(value, cb) => {
                  onInputUserMappingsChange(value, cb, roleId);
                }}
                name="selectedPanelMemObj"
                selectedValue={selectedPanelMemObj}
              />
            </div>
          )}
        </div>
        {roleId == 7 && (
          <div className="mt-2">
            <TextInput
              id="nric"
              name="nric"
              labelText="NRIC"
              placeholder="T1234567A"
              onChange={(e) => setNric(e.target.value)}
              value={nric}
              invalid={isValidNric}
              invalidText="Please enter valid NRIC"
            />
          </div>
        )}
        <div className="mt-2 mb-3">
          <div>
            <label className="bx--label">Active</label>
          </div>
          <Toggle
            toggled={active}
            labelA="No"
            labelText=""
            className="toggle-remv-margin"
            onToggle={(e) => handleSetActiveStatus(e)}
            labelB="Yes"
          />
        </div>
        {propRoleId == 7 && roleId == 100 && (
          <div className="mt-2 mb-4">
            <Checkbox
              id="checkboxSelected"
              name="checkboxSelected"
              labelText={
                <>
                  <div className="bx--type-strong">
                    Are you sure you want to proceed with this action?
                  </div>
                  <div className="">
                    When unassigning a commission member, please note that all
                    upcoming meetings associated with this member in the CMS
                    will be automatically removed, and this action is
                    irreversible. Please review carefully before proceeding.
                  </div>
                </>
              }
              onChange={(e) => {
                console.log('e qwe', e);
                setCheckboxSelected(e);
              }}
              checked={checkboxSelected}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default UpdateUserDetails;
