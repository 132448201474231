import React, { Component } from 'react';
import { connect } from 'react-redux';

//API
import {
  getAssignedPanelInterviewers,
  assignPanelMembersForADate,
  assignCandidatesToOtherRoles,
  createBreakPoints,
  getBreakStartEndTime,
  updateTimerForAppls,
  deleteBreakPoints,
} from '../../actions/CalendarSelectionBoard';
import { upsertCandidateDetailsById } from '../../actions/CandidateInfo';
import { getCandidateListData } from '../../actions/CandidateList';
import { REQUEST_GET_CANDIDATE_INTERVIEWS_TODAY } from '../../actions/actionTypes';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericTabs from '../../components/common/GenericTabs';
import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';

//Common Functional Components
import PanelPscSelectionBoard from '../../components/private/PanelPscSelectionBoard';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import TodaysAgendaInterviews from '../../components/private/TodaysAgendaInterviews';
import AgendaMetrics from '../../components/private/AgendaMetrics';
import CandidateInterviewsList from '../../components/private/CandidateInterviewsList';

//Common Packages
import moment from 'moment';
import {
  Tile,
  AccordionItem,
  Accordion,
  Modal,
  TextInput,
} from 'carbon-components-react';
import { Button } from 'carbon-components-react';
import { TrashCan32, Add24 } from '@carbon/icons-react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import PubSub from 'pubsub-js';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CandidateInfo.css';

class CalendarSelectionBoardInfo extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    {
      id: 'calendar-info',
      name: 'Calendar',
      href: '/app/csc/calendar/interviews',
    },
    { id: 'calendar-selection-board', name: 'Selection Board', href: '' },
  ];
  constructor(props) {
    super(props);
    const { match: { params: { date } = {} } = {} } = this.props;
    let defaultStartTime = new Date(date && Number(date));
    defaultStartTime.setHours(15, 0, 0, 0);
    let defaultEndTime = new Date(date && Number(date));
    defaultEndTime.setHours(15, 30, 0, 0);
    this.state = {
      selectedTab: 0,
      assignBoardMemData: [],
      assignedIdsArr: [],
      activePage: 1,
      itemsPerPage: 10,
      todayIntActivePage: 1,
      todayIntItemsPerPage: 10,
      defaultStartTime,
      defaultEndTime,
      breakTimeList: [
        {
          displayName: '',
          startTime: defaultStartTime,
          endTime: defaultEndTime,
        },
      ],
    };
    this.createRef = React.createRef();
  }

  subscriptions = [
    PubSub.subscribe('RERENDER_INTERVEIWS_LIST', () => {
      this.handleGetTodaysInterviewees();
    }),
  ];

  componentDidMount() {
    this.handleGetBoardMembers();

    const { match, location } = this.props || {};
    const { params } = match || {};
    const { date } = params || {};
    const { state } = location || {};
    const { selectedTab } = state || {};
    let startDate = new Date(date && Number(date));
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(date && Number(date));
    endDate.setHours(23, 59, 59, 999);
    const interviewStartDate = new Date(startDate).getTime();
    const interviewEndDate = new Date(endDate).getTime();
    this.setState(
      {
        interviewStartDate,
        interviewEndDate,
        selectedTab: selectedTab ? selectedTab : 0,
      },
      () => {
        this.handleGetTodaysInterviewees();
        this.handleGetBreakTime();
      }
    );
  }

  handleGetBreakTime = () => {
    const {
      interviewStartDate,
      interviewEndDate,
      defaultStartTime,
      defaultEndTime,
    } = this.state;
    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';
    this.props
      .getBreakStartEndTime(interviewStartDate, interviewEndDate, recordType)
      .then((res) => {
        if (res && !res.error) {
          const { list = [] } = res || {};
          this.setState({
            actualBreakTimeList: _.cloneDeep(list),
            breakTimeList:
              list && Array.isArray(list) && list.length > 0
                ? _.cloneDeep(list)
                : [
                    {
                      displayName: '',
                      startTime: defaultStartTime,
                      endTime: defaultEndTime,
                    },
                  ],
          });
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleGetTodaysInterviewees = () => {
    const { user } = this.props;
    const { currentYear } = user || {};
    const { interviewStartDate, interviewEndDate, activePage, itemsPerPage } =
      this.state;

    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';

    const data = {
      activePage,
      itemsPerPage,
      searchKey: null,
      interviewEndDate,
      interviewStartDate,
      isInterviewList: true,
      isCurrentUserMainInterviewer: false,
      year: currentYear,
      isTodaysInterview: true,
      recordType,
    };
    if (interviewStartDate && interviewEndDate) {
      this.props
        .getCandidateListData(
          data,
          null,
          REQUEST_GET_CANDIDATE_INTERVIEWS_TODAY
        )
        .then((res) => {
          const { list: interviewsToday } = res || {};
          let finalInterviewsTodayList = [];
          if (
            interviewsToday &&
            Array.isArray(interviewsToday) &&
            interviewsToday.length > 0
          ) {
            finalInterviewsTodayList = interviewsToday.filter((res) => {
              const { mainInterviewer, psMainInterviewer } = res || {};
              const { createdAt } =
                (mainInterviewer &&
                  Array.isArray(mainInterviewer) &&
                  mainInterviewer.length > 0 &&
                  mainInterviewer[0]) ||
                {};
              const { createdAt: psCreatedAt } =
                (psMainInterviewer &&
                  Array.isArray(psMainInterviewer) &&
                  psMainInterviewer.length > 0 &&
                  psMainInterviewer[0]) ||
                {};

              if (createdAt && recordType === 'PSC') {
                return true;
              }
              if (psCreatedAt && recordType === 'PS') {
                return true;
              }
              return false;
            });

            finalInterviewsTodayList = finalInterviewsTodayList.map(
              (res, idx) => {
                return { serialNo: idx + 1, ...res };
              }
            );
          }
          this.setState({ finalInterviewsTodayList });
        });
    }
  };

  handleTodaysIntervieweesPag = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetTodaysInterviewees();
    });
  };

  handleGetBoardMembers = () => {
    const { match } = this.props || {};
    const { params } = match || {};
    const { appId } = params || {};
    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';
    this.props
      .getAssignedPanelInterviewers({ appId, recordType })
      .then((res) => {
        if (res && !res.error) {
          const { selectionBoardUsers } = this.props || {};
          const assignBoardMemData = [];
          if (
            selectionBoardUsers &&
            Array.isArray(selectionBoardUsers) &&
            selectionBoardUsers.length > 0
          ) {
            selectionBoardUsers.forEach((memb) => {
              const { assignedId, action } = memb || {};
              const boardMemObj = {};
              boardMemObj.action = action;
              boardMemObj.assignedId = assignedId;
              assignBoardMemData.push(boardMemObj);
            });
          }
          this.setState({
            assignBoardMemData,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleTabClick = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  handleOnSelectBoardMemberToggle = (assignedId, status, boardId) => {
    const { match } = this.props;
    const { params } = match || {};
    const { date } = params || {};
    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';
    let startDate = new Date(date && Number(date));
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(date && Number(date));
    endDate.setHours(23, 59, 59, 999);
    const selectedObj = {
      action: status,
      assignedId,
      boardId,
      startDate: new Date(startDate).getTime(),
      endDate: new Date(endDate).getTime(),
      recordType,
    };
    const { assignBoardMemData = [], assignedIdsArr = [] } = this.state;
    let finalSelectedBoardMem = assignBoardMemData || [];
    let finalAssignedIdsArr = assignedIdsArr || [];
    if (finalAssignedIdsArr.includes(assignedId)) {
      finalAssignedIdsArr = assignedIdsArr;
    } else {
      assignedIdsArr.push(assignedId);
    }
    if (
      finalSelectedBoardMem &&
      Array.isArray(finalSelectedBoardMem) &&
      finalSelectedBoardMem.length > 0
    ) {
      if (finalAssignedIdsArr.includes(assignedId)) {
        const finalFilteredList = finalSelectedBoardMem.filter(
          (res) => res && res.assignedId !== assignedId
        );
        finalFilteredList.push(selectedObj);
        finalSelectedBoardMem = finalFilteredList;
      } else {
        finalSelectedBoardMem.push(selectedObj);
      }
    } else {
      finalSelectedBoardMem = [selectedObj];
    }
    this.setState({
      assignBoardMemData: finalSelectedBoardMem,
      assignedIdsArr: assignedIdsArr,
    });
  };

  handleSubmitSelectedBoardMembers = () => {
    const { assignBoardMemData } = this.state || {};
    if (
      assignBoardMemData &&
      Array.isArray(assignBoardMemData) &&
      assignBoardMemData.length > 0
    ) {
      this.props
        .assignPanelMembersForADate(assignBoardMemData)
        .then((res) => {
          if (res && !res.error) {
            this.handleGetBoardMembers();
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been updated successfully',
                errMsg: '',
                errorNotif: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    successNotif: false,
                    notifMsg: '',
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => console.log('Error', e));
    } else {
      this.createRef &&
        this.createRef.current &&
        this.createRef.current.focus &&
        this.createRef.current.focus();
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Please select valid options to submit your response',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleChangeRangeDetails = (e) => {
    const currDate = e && e[0];
    let startDate = currDate.setHours(0, 0, 0, 0);
    let endDate = currDate.setHours(23, 59, 59, 999);
    const interviewStartDate = new Date(startDate).getTime();
    const interviewEndDate = new Date(endDate).getTime();
    this.setState(
      {
        interviewStartDate,
        interviewEndDate,
      },
      () => {
        this.handleGetTodaysInterviewees();
      }
    );
  };

  handleStopCandidateEvalInt = () => {
    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';
    const data = {
      appId: this.state.appId,
    };

    if (recordType === 'PSC') {
      data['calenderInterviewAction'] = 'completed';
    } else {
      data['psCalenderInterviewAction'] = 'completed';
    }

    this.props
      .upsertCandidateDetailsById(data)
      .then((res) => {
        if (res && !res.error) {
          this.handleGetTodaysInterviewees();
          this.handleStopTimer();
          this.dismissTimerModal();
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleResetTimer = () => {
    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';
    PubSub.publish('SHOW_COUNT_DOWN');

    const d = {
      appId: this.state.appId,
      actionType: 'RESTART',
      scholarshipType: recordType,
    };
    this.props.updateTimerForAppls(d);
    this.setState(
      {
        successNotif: true,
        notifMsg: 'The interview has been reset',
        errMsg: '',
        errorNotif: false,
      },
      () => {
        this.dismissTimerModal();
        setTimeout(() => {
          this.setState({
            successNotif: false,
            notifMsg: '',
          });
        }, 5000);
      }
    );
  };

  handleStopTimer = () => {
    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';
    PubSub.publish('HIDE_COUNT_DOWN');

    const d = {
      appId: this.state.appId,
      actionType: 'STOP',
      scholarshipType: recordType,
    };
    this.props.updateTimerForAppls(d);
    this.setState(
      {
        successNotif: true,
        notifMsg: 'The interview has been stopped',
        errMsg: '',
        errorNotif: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            successNotif: false,
            notifMsg: '',
          });
        }, 5000);
      }
    );
  };

  activeTimerModal = (appId, fullName, type) => {
    this.setState({
      isOpenTimerModal: true,
      candidateName: fullName,
      appId,
      timerType: type,
    });
  };

  dismissTimerModal = () => {
    this.setState({
      isOpenTimerModal: false,
      candidateName: '',
      appId: 0,
      timerType: '',
    });
  };

  handleStartCandidateEvalInt = () => {
    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';
    PubSub.publish('SHOW_COUNT_DOWN');

    const data = {
      appId: this.state.appId,
    };

    if (recordType === 'PSC') {
      data['calenderInterviewAction'] = 'ongoing';
    } else {
      data['psCalenderInterviewAction'] = 'ongoing';
    }

    this.props
      .upsertCandidateDetailsById(data)
      .then((res) => {
        if (res && !res.error) {
          this.handleGetTodaysInterviewees();
          const d = {
            appId: this.state.appId,
            actionType: 'START',
            scholarshipType: recordType,
          };
          this.props.updateTimerForAppls(d);
          this.dismissTimerModal();
          this.setState(
            {
              successNotif: true,
              notifMsg: 'The interview has been started',
              errMsg: '',
              errorNotif: false,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                  notifMsg: '',
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleToggleClick = (roleId, appId, status) => {
    const data = {
      roleId,
      appId,
      access: status,
    };
    const { selectionBoardUsers } = this.props;
    let guestUserObj = {};
    if (
      selectionBoardUsers &&
      Array.isArray(selectionBoardUsers) &&
      selectionBoardUsers.length > 0
    ) {
      selectionBoardUsers.forEach((member) => {
        const { selectionboard, assignedId } = member || {};
        const { userinfo } = selectionboard || {};
        const { roleId: propRoleId } = userinfo || {};
        if (propRoleId == 10) {
          guestUserObj.assignedId = assignedId;
        }
      });
    }
    const { assignedId } = guestUserObj || {};
    if (roleId == 10) {
      data.assignedId = assignedId;
    }
    this.props
      .assignCandidatesToOtherRoles(data)
      .then((res) => {
        if (res && !res.error) {
          this.handleGetTodaysInterviewees();
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your data has been updated successfully',
              errMsg: '',
              errorNotif: false,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                  notifMsg: '',
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleTodaysIntervieweesPag = (data) => {
    const todayIntActivePage = data.page || 1;
    const todayIntItemsPerPage = data.pageSize || 10;
    this.setState({ todayIntActivePage, todayIntItemsPerPage }, () => {
      this.handleGetTodaysInterviewees();
    });
  };

  handleSaveBreakTime = (idx) => {
    const {
      breakTimeList,
      actualBreakTimeList,
      interviewStartDate,
      interviewEndDate,
    } = this.state;

    var { displayName, startTime, endTime, bpId } = breakTimeList[idx] || {};

    if (startTime && endTime && moment(startTime) >= moment(endTime)) {
      this.setState({
        [`showTimeErr-${idx}`]: true,
        [`showOverlappingTimeErr-${idx}`]: false,
      });
      return;
    } else if (
      startTime &&
      endTime &&
      actualBreakTimeList &&
      Array.isArray(actualBreakTimeList) &&
      actualBreakTimeList.length > 0
    ) {
      const filteredList = bpId
        ? actualBreakTimeList.filter((ele) => ele.bpId != bpId)
        : actualBreakTimeList;

      const overlappingTimeSlot = filteredList.find((itm) => {
        const { startTime: actualStartTime, endTime: actualEndTime } =
          itm || {};

        if (
          (moment(startTime) < moment(actualStartTime) &&
            moment(endTime) <= moment(actualStartTime)) ||
          moment(startTime) >= moment(actualEndTime)
        ) {
          return false;
        } else {
          return true;
        }
      });

      const { bpId: overlappedBpId, displayName } = overlappingTimeSlot || {};
      if (overlappedBpId) {
        this.setState({
          [`showTimeErr-${idx}`]: false,
          [`showOverlappingTimeErr-${idx}`]: true,
          [`overlappingTimeErrMsg-${idx}`]: displayName,
        });
        return;
      }
    }

    const updateState = {};
    if (
      breakTimeList &&
      Array.isArray(breakTimeList) &&
      breakTimeList.length > 0
    ) {
      breakTimeList.forEach((itm, idx) => {
        updateState[`showOverlappingTimeErr-${idx}`] = false;
        updateState[`showTimeErr-${idx}`] = false;
      });
    }

    this.setState(
      {
        [`showTimeErr-${idx}`]: false,
        ...updateState,
      },
      () => {
        const recordType = window.location.pathname?.includes('PSC')
          ? 'PSC'
          : 'PS';
        const data = {
          displayName,
          startTime: startTime && new Date(startTime).getTime(),
          endTime: endTime && new Date(endTime).getTime(),
          initTime:
            interviewStartDate && new Date(interviewStartDate).getTime(),
          lastTime: interviewEndDate && new Date(interviewEndDate).getTime(),
          recordType,
        };

        if (!bpId) {
          data.isNewBreakPoint = true;
        } else {
          data.bpId = bpId;
        }

        this.props
          .createBreakPoints(data)
          .then((res) => {
            if (res && !res.error) {
              this.handleGetBreakTime();
              this.setState(
                {
                  successNotif: true,
                  notifMsg: 'Your details have been updated successfully',
                  errMsg: '',
                  errorNotif: false,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      successNotif: false,
                      notifMsg: '',
                    });
                  }, 5000);
                }
              );
            } else {
              this.setState(
                {
                  errorNotif: true,
                  errMsg:
                    res && res.error && res.error.code === 'x0pa-102'
                      ? 'Another candidate is scheduled for interview during this period. Please select another break time.'
                      : 'There was an error while processing the request, Please try again later',
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      errorNotif: false,
                      errMsg: '',
                    });
                  }, 5000);
                }
              );
            }
          })
          .catch((e) => console.log('Error', e));
      }
    );
  };

  handleDeleteBreakTime = (idx) => {
    const { breakTimeList } = this.state;
    const { bpId } = breakTimeList[idx] || {};
    if (bpId) {
      this.props
        .deleteBreakPoints(bpId)
        .then((res) => {
          if (res && !res.error) {
            this.handleGetBreakTime();
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been updated successfully',
                errMsg: '',
                errorNotif: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    successNotif: false,
                    notifMsg: '',
                  });
                }, 5000);
              }
            );
          } else {
            this.setState(
              {
                errorNotif: true,
                errMsg:
                  'There was an error while processing the request, Please try again later',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    errorNotif: false,
                    errMsg: '',
                  });
                }, 5000);
              }
            );
          }
        })
        .catch((e) => console.log('Error', e));
    } else {
      const list = [...breakTimeList];
      list.splice(idx, 1);
      this.setState({
        breakTimeList: list,
      });
    }
  };

  onDatePickerChange = (date, index, name) => {
    const { match: { params: { date: paramIntvDate } = {} } = {} } = this.props;

    const dateObject = new Date(paramIntvDate && Number(paramIntvDate));
    const timeObject = date;

    const year = dateObject?.getUTCFullYear();
    const month = dateObject?.getUTCMonth();
    const day = dateObject?.getUTCDate();

    const hours = timeObject?.getUTCHours();
    const minutes = timeObject?.getUTCMinutes();
    const seconds = timeObject?.getUTCSeconds();

    const combinedDate = new Date(
      Date.UTC(year, month, day, hours, minutes, seconds)
    );

    const { breakTimeList } = this.state;
    const list = _.cloneDeep(breakTimeList);
    list[index][name] = combinedDate;
    this.setState({
      breakTimeList: list,
    });
  };

  handleInputFieldChange = (e, index) => {
    const { name, value } = (e && e.target) || {};
    const { breakTimeList } = this.state;
    const list = [...breakTimeList];
    list[index][name] = value;
    this.setState({
      breakTimeList: list,
    });
  };

  addBreakTime = () => {
    const { breakTimeList, defaultStartTime, defaultEndTime } = this.state;
    this.setState({
      breakTimeList: [
        ...breakTimeList,
        {
          displayName: '',
          startTime: defaultStartTime,
          endTime: defaultEndTime,
        },
      ],
    });
  };

  render() {
    const {
      selectionBoardUsers,
      interviewsToday,
      interviewsTodayCount,
      history,
      interviewLoading,
      loading,
      user,
      match,
    } = this.props || {};
    const { params } = match || {};
    const { date } = params || {};
    const { userId, roleId, timerObj } = user || {};
    const { push } = history || {};
    const recordType = window.location.pathname?.includes('PSC') ? 'PSC' : 'PS';
    const {
      selectedTab,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      activePage,
      todayIntActivePage,
      finalInterviewsTodayList,
      breakTimeList,
    } = this.state;
    const localizer = momentLocalizer(moment);
    const panelMembers = [];
    const mindefMhaMembers = [];
    const guestMember = [];
    const pscSecMembers = [];
    let breakStart = new Date();
    breakStart.setHours(15, 30, 0, 0);
    let breakEnd = new Date();
    breakEnd.setHours(15, 45, 0, 0);
    if (
      selectionBoardUsers &&
      Array.isArray(selectionBoardUsers) &&
      selectionBoardUsers.length > 0
    ) {
      selectionBoardUsers.forEach((member) => {
        const { selectionboard, isHidden } = member || {};
        const { userinfo } = selectionboard || {};
        const { roleId: propRoleId } = userinfo || {};
        if (propRoleId == 6 || propRoleId == 2) {
          pscSecMembers.push(member);
        } else if ((propRoleId == 8 || propRoleId == 9) && isHidden !== true) {
          mindefMhaMembers.push(member);
        } else if (propRoleId == 7) {
          panelMembers.push(member);
        } else if (isHidden !== true) {
          guestMember.push(member);
        }
      });
    }
    let eventData = [];
    if (
      interviewsToday &&
      Array.isArray(interviewsToday) &&
      interviewsToday.length > 0
    ) {
      interviewsToday.forEach((appl) => {
        const { application, mainInterviewer, psMainInterviewer } = appl || {};
        const { profile } =
          (application &&
            Array.isArray(application) &&
            application.length > 0 &&
            application[0]) ||
          {};
        const { fullName } = profile || {};
        const { createdAt } =
          (mainInterviewer &&
            Array.isArray(mainInterviewer) &&
            mainInterviewer.length > 0 &&
            mainInterviewer[0]) ||
          {};
        const { createdAt: psCreatedAt } =
          (psMainInterviewer &&
            Array.isArray(psMainInterviewer) &&
            psMainInterviewer.length > 0 &&
            psMainInterviewer[0]) ||
          {};
        if (createdAt && recordType === 'PSC') {
          eventData.push({
            title: `Interview for - ${fullName}`,
            start: new Date(createdAt),
            end: moment(createdAt).add(30, 'm').toDate(),
          });
        } else if (psCreatedAt && recordType === 'PS') {
          eventData.push({
            title: `Interview for - ${fullName}`,
            start: new Date(psCreatedAt),
            end: moment(psCreatedAt).add(30, 'm').toDate(),
          });
        }
      });
    }
    let finalPSCInterviewDate = '';
    let finalPSInterviewDate = '';
    if (
      interviewsToday &&
      Array.isArray(interviewsToday) &&
      interviewsToday.length > 0
    ) {
      interviewsToday.forEach((res) => {
        const { application } = res || {};
        const { psInterviewDay, pscInterviewDay } =
          (application &&
            Array.isArray(application) &&
            application.length > 0 &&
            application[0]) ||
          {};
        if (pscInterviewDay > 0) {
          finalPSCInterviewDate = pscInterviewDay;
        }
        if (psInterviewDay > 0) {
          finalPSInterviewDate = psInterviewDay;
        }
      });
    }
    eventData.push({
      title: 'Break',
      start: new Date(breakStart),
      end: new Date(breakEnd),
    });
    const otherActionsBtn = [];
    otherActionsBtn.push(<Button>Add Guest</Button>);

    return (
      <div>
        <BackRow paths={this.paths} />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
          errMsg={errMsg}
        />
        <Tile className="mt-3">
          <div className="d-flex flex-column">
            <div>
              <h4 className="mb-2">Interview Date: </h4>
              <div className="h6 mb-1">
                Day{' '}
                {recordType === 'PS'
                  ? finalPSInterviewDate
                  : finalPSCInterviewDate}
                : {date && moment(Number(date)).format('dddd, D MMMM YYYY')}
              </div>
            </div>
            <div className="mt-3">
              <h4 className="mb-2">Break Time: </h4>
              {breakTimeList &&
                Array.isArray(breakTimeList) &&
                breakTimeList.length > 0 &&
                breakTimeList.map((breakTime, idx) => {
                  let { displayName, startTime, endTime } = breakTime || {};
                  if (recordType === 'PSC') {
                    displayName = 'Break Time';
                  }
                  return (
                    <>
                      <div className="d-flex align-items-center">
                        <div>
                          <TextInput
                            labelText=""
                            name="displayName"
                            value={displayName || ''}
                            onChange={(e) =>
                              this.handleInputFieldChange(e, idx)
                            }
                            hideLabel
                            placeholder="Break Time Title"
                            disabled={recordType === 'PSC'}
                          />
                        </div>
                        <div className="ml-2">
                          <DatePicker
                            selected={startTime && new Date(startTime)}
                            onChange={(date) =>
                              this.onDatePickerChange(date, idx, 'startTime')
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeFormat="h:mm a"
                            disabled={roleId !== 6}
                            timeIntervals={15}
                            className="css-h-d-input"
                            timeCaption="time"
                            dateFormat="h:mm a"
                            placeholderText="Start Time"
                          />
                        </div>
                        <div className="ml-2">
                          <DatePicker
                            selected={endTime && new Date(endTime)}
                            onChange={(date) =>
                              this.onDatePickerChange(date, idx, 'endTime')
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeFormat="h:mm a"
                            timeIntervals={15}
                            disabled={roleId !== 6}
                            className="css-h-d-input"
                            timeCaption="time"
                            dateFormat="h:mm a"
                            placeholderText="End Time"
                          />
                        </div>
                        <div className="ml-2 mt-2">
                          <Button
                            disabled={
                              roleId !== 6 ||
                              !displayName ||
                              !startTime ||
                              !endTime
                            }
                            onClick={() => this.handleSaveBreakTime(idx)}
                            size="small">
                            Save
                          </Button>
                        </div>
                        {recordType === 'PS' && breakTimeList.length > 1 && (
                          <div
                            className="ml-2 "
                            style={{ cursor: 'pointer', marginTop: '1%' }}>
                            <TrashCan32
                              fill="red"
                              onClick={() => this.handleDeleteBreakTime(idx)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="mt-1 mb-2">
                        {this.state[`showTimeErr-${idx}`] && (
                          <div style={{ color: 'red' }} className="mb-2">
                            Please select a valid time slot. Start time cannot
                            be greater than or equal to end time.
                          </div>
                        )}
                        {this.state[`showOverlappingTimeErr-${idx}`] && (
                          <div style={{ color: 'red' }} className="mb-2">
                            Please select another break time. This break time is
                            being overlapped with the existing break time.
                            {/* {this.state[`overlappingTimeErrMsg-${idx}`]} */}
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              {recordType === 'PS' && breakTimeList.length < 3 && (
                <div className="mt-3">
                  <Button
                    kind="ghost"
                    onClick={() => this.addBreakTime()}
                    className="d-flex align-items-center mt-2">
                    <Add24 fill="#0f62fe" />
                    Add break time
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Tile>
        <GenericTabs
          selected={selectedTab}
          tabContent={[
            {
              label: 'Selection Board',
              value: '',
              onClick: () => {
                this.handleTabClick(0);
              },
            },
            {
              label: 'Interviews',
              value: '',
              onClick: () => {
                this.handleTabClick(1);
              },
            },
            // {
            //   label: 'Events',
            //   value: '',
            //   onClick: () => {
            //     this.handleTabClick(2);
            //   },
            // },
          ]}
        />

        {selectedTab === 0 && (
          <div>
            <input
              style={{ height: 0, opacity: 0 }}
              type="text"
              ref={this.createRef}
            />
            {roleId === 6 || roleId === 1 ? (
              <div>
                <div>
                  <PanelPscSelectionBoard
                    data={panelMembers}
                    heading="Panel Members"
                    otherActionsBtn={[]}
                    headingClassName="mb-3"
                    handleOnSelectBoardMemberToggle={
                      this.handleOnSelectBoardMemberToggle
                    }
                    state={this.state}
                    roleId={roleId}
                  />
                  <PanelPscSelectionBoard
                    data={mindefMhaMembers}
                    otherActionsBtn={[]}
                    heading="MINDEF / MHA Members"
                    headingClassName="mb-3 mt-3"
                    handleOnSelectBoardMemberToggle={
                      this.handleOnSelectBoardMemberToggle
                    }
                    state={this.state}
                    roleId={roleId}
                  />
                  <PanelPscSelectionBoard
                    data={guestMember}
                    otherActionsBtn={[]}
                    heading="Guest Member"
                    headingClassName="mb-3 mt-3"
                    handleOnSelectBoardMemberToggle={
                      this.handleOnSelectBoardMemberToggle
                    }
                    state={this.state}
                    roleId={roleId}
                  />
                  <PanelPscSelectionBoard
                    data={pscSecMembers}
                    otherActionsBtn={[]}
                    heading="Secretariat Members"
                    headingClassName="mb-3 mt-3"
                    handleOnSelectBoardMemberToggle={
                      this.handleOnSelectBoardMemberToggle
                    }
                    state={this.state}
                    roleId={roleId}
                  />
                </div>
                <div className="text-center">
                  <Button onClick={this.handleSubmitSelectedBoardMembers}>
                    Submit
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <Tile className="mb-3">
                  <AgendaMetrics selectionBoardUsers={selectionBoardUsers} />
                </Tile>
                <Accordion>
                  <AccordionItem
                    className="content-padding"
                    title={
                      <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                        Agenda
                      </div>
                    }>
                    <Tile>
                      <TodaysAgendaInterviews
                        push={push}
                        data={finalInterviewsTodayList}
                        count={interviewsTodayCount}
                        handlePaginationChange={
                          this.handleGetTodaysAgendaInterviewsPag
                        }
                        activePage={activePage}
                        hideActionColumn
                        recordType={recordType}
                        handleResetTimer={this.handleResetTimer}
                        userId={userId}
                        timerObj={timerObj}
                      />
                    </Tile>
                  </AccordionItem>
                  <AccordionItem
                    className="content-padding"
                    title={
                      <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                        Candidates
                      </div>
                    }>
                    <Tile>
                      <CandidateInterviewsList
                        data={finalInterviewsTodayList}
                        count={interviewsTodayCount}
                        push={push}
                        handlePaginationChange={
                          this.handleTodaysIntervieweesPag
                        }
                        activePage={todayIntActivePage}
                        showTime
                        state={this.state}
                        handleChange={this.handleChange}
                        handleSubmitCriteria={this.handleSubmitCriteria}
                        roleId={roleId}
                      />
                    </Tile>
                  </AccordionItem>
                </Accordion>
              </div>
            )}
          </div>
        )}
        {selectedTab === 1 && (
          <div>
            <TodaysAgendaInterviews
              push={push}
              data={finalInterviewsTodayList}
              count={interviewsTodayCount}
              handlePaginationChange={this.handleTodaysIntervieweesPag}
              activePage={activePage}
              activeTimerModal={this.activeTimerModal}
              userId={userId}
              handleStopCandidateEvalInt={this.handleStopCandidateEvalInt}
              roleId={roleId}
              propDate={date}
              handleToggleClick={this.handleToggleClick}
              recordType={recordType}
              handleResetTimer={this.handleResetTimer}
              timerObj={timerObj}
            />
          </div>
        )}
        {selectedTab === 2 && (
          <Tile>
            <div style={{ height: '700px' }}>
              <Calendar
                localizer={localizer}
                events={eventData || []}
                views={['day']}
                defaultView="day"
                startAccessor="start"
                endAccessor="end"
                onRangeChange={(e) => {
                  this.handleChangeRangeDetails(e);
                }}
                defaultDate={new Date(date && Number(date))}
              />
            </div>
          </Tile>
        )}
        {this.state.isOpenTimerModal === true && (
          <Modal
            open={this.state.isOpenTimerModal}
            modalHeading={
              <div className="text-capitalize">{this.state.candidateName}</div>
            }
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissTimerModal}
            onRequestSubmit={
              this.state.timerType === 'start'
                ? this.handleStartCandidateEvalInt
                : this.state.timerType === 'stop'
                ? this.handleStopCandidateEvalInt
                : this.handleResetTimer
            }>
            <div>
              {this.state.timerType === 'start'
                ? 'Are you sure you want to  start the timer for this candidate?'
                : this.state.timerType === 'stop'
                ? 'Are you sure you want to stop the timer for this candidate?'
                : 'Are you sure you want to reset the timer for this candidate?'}
            </div>
          </Modal>
        )}
        <JDProcessOverlay
          show={loading || interviewLoading}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.CalendarSelectionBoard.loading,
  selectionBoardUsers: state.CalendarSelectionBoard.selectionBoardUsers,
  interviewsToday: state.CandidateList.interviewsToday,
  interviewsTodayCount: state.CandidateList.interviewsTodayCount,
  interviewLoading: state.CandidateList.loading,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getAssignedPanelInterviewers,
  assignPanelMembersForADate,
  getCandidateListData,
  upsertCandidateDetailsById,
  assignCandidatesToOtherRoles,
  createBreakPoints,
  getBreakStartEndTime,
  updateTimerForAppls,
  deleteBreakPoints,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarSelectionBoardInfo);
