import React from 'react';
import NivoBarChart from '../common/NivoBarChart';
import './CandidateGBAStenGraph.css';

const CandidateGBAStenGraph = (props) => {
  const {
    data,
    keys,
    heading,
    padding,
    handleBarOnClick,
    maxValue,
    minValue,
    incBarHeight,
    isMainGBAData,
  } = props || {};
  return data && Array.isArray(data) && data.length > 0 ? (
    <div
      className={
        heading === 'Personal Style'
          ? 'css-sten-graph-personality-style-h'
          : incBarHeight
          ? 'css-thinking-st-h'
          : 'css-sten-graph-h'
      }>
      <NivoBarChart
        heading={heading}
        data={data}
        margin={{
          top: 10,
          right: 100,
          bottom: 30,
          left: 300,
        }}
        keys={keys}
        legendDirection="bottom-left"
        enableGridY={false}
        axisTop={null}
        padding={padding}
        enableLabel={isMainGBAData === false}
        showDefModal
        maxValue={maxValue}
        minValue={minValue}
        handleBarOnClick={handleBarOnClick}
        groupMode="stacked"
        userColors="darkblue"
        innerPadding={0}
        showLegend={false}
        enableTooltipKey={false}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        layout="horizontal"
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
      />
    </div>
  ) : (
    <div className="mt-2">
      <h4 className="text-capitalize">{heading}</h4>
      <h5 className="mt-2 text-center">
        There are no scores generated for {heading} details.
      </h5>
    </div>
  );
};

export default CandidateGBAStenGraph;
