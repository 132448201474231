import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCandidateAssessStatus } from '../../actions/CandidateInfo';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import NoContentBox from '../../components/common/NoContentBox';
class CandidateAssessStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  renderOnMount = () => {
    const { profileId, selectionYear } = this.props;
    if (profileId) {
      this.props.getCandidateAssessStatus(profileId, selectionYear);
    }
  };

  render() {
    const { infoLoading, assessStatusDetails = {} } = this.props;

    return (
      <div>
        <div className="mt-2 h6 font-weight-bold">Assessment Status</div>
        <hr />
        {assessStatusDetails && Object.keys(assessStatusDetails)?.length > 0 ? (
          <>
            <div
              style={{ fontSize: 13 }}
              className="bx--row m-0 text-uppercase  text-secondary font-weight-bold">
              <div className="bx--col-lg-3 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 d-lg-block d-md-block d-none">
                Assessment
              </div>
              <div className="bx--col-lg-5 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 d-lg-block d-md-block d-none">
                Status
              </div>
            </div>
            <hr className="mb-2 mt-2" />
            {Object.keys(assessStatusDetails).map((keyName, idx) => {
              const assessmentObj = assessStatusDetails[keyName] || {};
              const { testName, status, displayName } = assessmentObj || {};
              return (
                <Fragment>
                  <div className=" bx--row  m-0">
                    <div className="font-weight-bold d-flex align-items-center text-capitalize bx--col-lg-3 bx--col-md-2 bx--col-sm-12 bx--col-xs-12">
                      {displayName ?? testName}
                    </div>
                    <div className=" ml-0 bx--col-lg-5 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 xpa-name-overflow">
                      {status}
                    </div>
                  </div>
                  <hr className="mb-2 mt-2" />
                </Fragment>
              );
            })}
          </>
        ) : (
          <NoContentBox message="There are no assessment statuses available." />
        )}

        <JDProcessOverlay show={infoLoading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assessStatusDetails: state.CandidateInfo.assessStatusDetails,
  infoLoading: state.CandidateInfo.loading,
});

const mapDispatchToProps = {
  getCandidateAssessStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateAssessStatus);
