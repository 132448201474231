import React, { Component, Fragment, memo } from 'react';
import { connect } from 'react-redux';

//Api Actions
import {
  getBatchInfoDailyReports,
  uploadBatchStatistics,
  uploadPreviousBatchStatistics,
  updateCustomStatistics,
  getLastUploadedHistory,
  uploadPreviousYTDStatistics,
} from '../../actions/BatchInfo';
import { getDasboardStatistics } from '../../actions/Dashboard';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericTabs from '../../components/common/GenericTabs';
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
//Common UI Components
import BatchCounts from '../../components/private/BatchCounts';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import {
  DailyReportsGraphs,
  OverallStatistics,
  ScholarshipSchoolStatistics,
  CountryCourseStatistics,
} from '../../components/private/BatchInfoDetails';

//Common packages
import {
  Tile,
  FileUploaderButton,
  TextInput,
  Button,
} from 'carbon-components-react';
import moment from 'moment';
import { lab } from 'd3';
import _ from 'lodash';

import SelectionStatisticsTemplate from '../../assets/files/Selection_Statistics_Template.xlsx';
import YtdTemplate from '../../assets/files/Ytd_Template.xlsx';
import DailyStatsTemplate from '../../assets/files/Daily_Stats_Template.xlsx';

class BatchInfo extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'batches', name: 'Batches', href: '/app/csc/batches' },
    { id: 'batch-info', name: 'SELECTION YEAR STATISTICS', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    this.handleRedirect &&
      this.handleRedirect.current &&
      this.handleRedirect.current.focus &&
      this.handleRedirect.current.focus();
    this.props.getLastUploadedHistory();
    this.handleGetDailyReportsAndDashStats();
  }

  handleGetDailyReportsAndDashStats = () => {
    this.setState({ showLoader: true }, () => {
      const { currentYear } = this.props.user || {};
      const finalYear = this.state.finalYear ?? currentYear;
      this.props.getBatchInfoDailyReports(finalYear);
      this.props
        .getDasboardStatistics(finalYear)
        .then((res) => {
          if (res && !res.error) {
            this.handleSaveSchemeTargetAwardedVals();
            this.handleSaveCountryTargetAwardedVals();
            this.handleSaveCourseAwardedVals();
            this.handleSaveSchoolAwardedVals();
            this.setState({
              showLoader: false,
            });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        })
        .catch((e) => console.log('Error', e));
    });
  };

  handleTabClick = (tabNumber, currSelYear) => {
    const finalYear = currSelYear;
    this.setState(
      {
        selectedTab: tabNumber,
        finalYear,
      },
      () => {
        this.handleGetDailyReportsAndDashStats();
      }
    );
  };

  handleUploadPrevioudBatchStatistics = (element) => {
    const fileData = element.target.files;
    const { currentYear } = this.props.user || {};
    const year = this.state.finalYear ?? currentYear;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('statistics', fileData[0]);
    currFile.append('year', year);
    if (isFileAllowed) {
      this.setState(
        {
          isValidYear: year ? false : true,
        },
        () => {
          const { isValidYear } = this.state;
          if (!isValidYear) {
            this.props
              .uploadPreviousBatchStatistics(currFile)
              .then((res) => {
                if (res && !res.error) {
                  this.handleGetDailyReportsAndDashStats();
                  this.setState(
                    {
                      successNotif: true,
                      notifMsg: 'Your details have been uploaded successfully',
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          notifMsg: '',
                          successNotif: false,
                        });
                      }, 5000);
                    }
                  );
                } else {
                  this.setState({
                    errorNotif: true,
                  });
                }
              })
              .catch((e) => {
                this.setState({
                  errorNotif: true,
                });
              });
          }
        }
      );
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleUploadBatchStatistics = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['csv'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    const { currentYear } = this.props.user || {};
    const year = this.state.finalYear ?? currentYear;
    currFile.append('statistics', fileData[0]);
    currFile.append('year', year);
    if (isFileAllowed) {
      this.props
        .uploadBatchStatistics(currFile)
        .then((res) => {
          if (res && !res.error) {
            this.handleGetDailyReportsAndDashStats();
            this.props.getLastUploadedHistory();
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleUploadPrevioudYTDStatistics = (element) => {
    const fileData = element.target.files;
    const { currentYear } = this.props.user || {};
    const year = this.state.finalYear ?? currentYear;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('ytdMapping', fileData[0]);
    currFile.append('year', year);
    if (isFileAllowed) {
      this.props
        .uploadPreviousYTDStatistics(currFile)
        .then((res) => {
          if (res && !res.error) {
            this.handleGetDailyReportsAndDashStats();
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleEditScholarshipData = () => {
    this.setState({
      editScholarshipData: true,
    });
  };

  handleHideEditScheme = () => {
    this.setState({
      editScholarshipData: false,
    });
  };

  handleEditCountryData = () => {
    this.setState({
      editCountryData: true,
    });
  };

  handleHideEditCountry = () => {
    this.setState({
      editCountryData: false,
    });
  };

  handleEditCourseData = () => {
    this.setState({
      editCourseData: true,
    });
  };

  handleHideCounrse = () => {
    this.setState({
      editCourseData: false,
    });
  };

  handleHideSchool = () => {
    this.setState({
      editSchoolData: false,
    });
  };

  handleEditSchoolData = () => {
    this.setState({
      editSchoolData: true,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSaveSchemeTargetAwardedVals = () => {
    const { batchStatistics } = this.props;
    const { statistics } = batchStatistics || {};
    const { schemeStatistics } = statistics || {};
    const schemeObj = {};
    const keys = schemeStatistics && Object.keys(schemeStatistics);
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        if (key) {
          const subKeys =
            schemeStatistics[key] && Object.keys(schemeStatistics[key]);
          if (subKeys && Array.isArray(subKeys) && subKeys.length > 0) {
            subKeys.forEach((subkey) => {
              if (subkey) {
                const { target, awarded } =
                  (schemeStatistics[key] && schemeStatistics[key][subkey]) ||
                  {};
                schemeObj[`target-${subkey}`] = target || 0;
                schemeObj[`awarded-${subkey}`] = awarded || 0;
              }
            });
          }
        }
      });
    }
    this.setState({
      ...schemeObj,
    });
  };

  handleSaveSchoolAwardedVals = () => {
    const { batchStatistics } = this.props;
    const { statistics } = batchStatistics || {};
    const { schoolStatistics } = statistics || {};
    let schoolStatisticsKeys =
      schoolStatistics && Object.keys(schoolStatistics);
    let finalSchoolStatistics = {};
    if (
      schoolStatisticsKeys &&
      Array.isArray(schoolStatisticsKeys) &&
      schoolStatisticsKeys.length > 0
    ) {
      schoolStatisticsKeys.forEach((school) => {
        if (school === 'OtherSchool' || school === 'Other Institutions') {
          finalSchoolStatistics['Other Institutions'] = {
            ...schoolStatistics['OtherSchool'],
            ...schoolStatistics['Other Institutions'],
          };
        } else {
          finalSchoolStatistics[school] = {
            ...(schoolStatistics && schoolStatistics[school]),
          };
        }
      });
    }
    const schoolObj = {};
    const keys = finalSchoolStatistics && Object.keys(finalSchoolStatistics);
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        if (key) {
          const subKeys =
            finalSchoolStatistics[key] &&
            Object.keys(finalSchoolStatistics[key]);
          if (subKeys && Array.isArray(subKeys) && subKeys.length > 0) {
            subKeys.forEach((subkey) => {
              if (subkey) {
                const { target, awarded } =
                  (finalSchoolStatistics[key] &&
                    finalSchoolStatistics[key][subkey]) ||
                  {};
                schoolObj[`awarded-${subkey}`] = awarded || 0;
              }
            });
          }
        }
      });
    }
    this.setState({
      ...schoolObj,
    });
  };

  handleSaveCountryTargetAwardedVals = () => {
    const { batchStatistics } = this.props;
    const { statistics } = batchStatistics || {};
    const { countryStatistics, countryStatisticsOthers } = statistics || {};
    const countryKeys = countryStatistics && Object.keys(countryStatistics);
    const otherCountryKeys =
      countryStatisticsOthers && Object.keys(countryStatisticsOthers);
    const finalCountryObj = {};
    if (countryKeys && Array.isArray(countryKeys) && countryKeys.length > 0) {
      countryKeys.forEach((country) => {
        if (country && country !== 'others') {
          const { target, awarded } = countryStatistics[country];
          finalCountryObj[`target-${country}`] = target || 0;
          finalCountryObj[`awarded-${country}`] = awarded || 0;
        }
      });
    }
    if (
      otherCountryKeys &&
      Array.isArray(otherCountryKeys) &&
      otherCountryKeys.length > 0
    ) {
      otherCountryKeys.forEach((country) => {
        if (country) {
          const { target, awarded } = countryStatisticsOthers[country];
          finalCountryObj[`target-${country}`] = target || 0;
          finalCountryObj[`awarded-${country}`] = awarded || 0;
        }
      });
    }
    this.setState({
      ...finalCountryObj,
    });
  };

  handleSaveCourseAwardedVals = () => {
    const { batchStatistics } = this.props;
    const { statistics } = batchStatistics || {};
    const { courseStatistics } = statistics || {};
    const courseKeys = courseStatistics && Object.keys(courseStatistics);
    const finalCourseObj = {};
    if (courseKeys && Array.isArray(courseKeys) && courseKeys.length > 0) {
      courseKeys.forEach((course) => {
        if (course) {
          const { awarded } = courseStatistics[course];
          finalCourseObj[
            `awarded-${
              course && course.toString() && course.toString().toLowerCase()
            }-course`
          ] = awarded || 0;
        }
      });
    }
    this.setState({
      ...finalCourseObj,
    });
  };

  handleSubmitStatisticsData = (data, type) => {
    this.props
      .updateCustomStatistics(data)
      .then((res) => {
        if (res && !res.error) {
          this.handleRedirect &&
            this.handleRedirect.current &&
            this.handleRedirect.current.focus &&
            this.handleRedirect.current.focus();
          this.handleGetDailyReportsAndDashStats();
          this.setState({
            successNotif: true,
            notifMsg: `The ${type} details have been updated successfully.`,
            editScholarshipData: false,
            editCountryData: false,
            editCourseData: false,
            editSchoolData: false,
          });
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleSubmitSchoolData = () => {
    const { batchStatistics } = this.props;
    const { currentYear, statistics } = batchStatistics || {};
    const { schoolStatistics } = statistics || {};
    const { year } = currentYear || {};
    const { selectedTab } = this.state;
    const keys = schoolStatistics && Object.keys(schoolStatistics);
    const childLabels = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        const subkeys =
          key && schoolStatistics[key] && Object.keys(schoolStatistics[key]);
        if (subkeys && Array.isArray(subkeys) && subkeys.length > 0) {
          subkeys.forEach((subkey) => {
            childLabels.push(subkey);
          });
        }
      });
    }
    let finalSchoolArr = [];
    if (childLabels && Array.isArray(childLabels) && childLabels.length > 0) {
      childLabels.forEach((school) => {
        const finalObj = {
          parentScheme: 'school',
          childScheme: school,
          awarded: (this.state && this.state[`awarded-${school}`]) || 0,
          target: (this.state && this.state[`target-${school}`]) || 0,
          year,
        };
        finalSchoolArr.push(finalObj);
      });
    }
    this.handleSubmitStatisticsData(finalSchoolArr, 'School');
  };

  handleSubmitSchemeData = () => {
    const { batchStatistics } = this.props;
    const { currentYear, statistics } = batchStatistics || {};
    const { schemeStatistics } = statistics || {};
    const { year } = currentYear || {};
    const { selectedTab } = this.state;
    const keys = schemeStatistics && Object.keys(schemeStatistics);
    const childLabels = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((key) => {
        const subkeys =
          key && schemeStatistics[key] && Object.keys(schemeStatistics[key]);
        if (subkeys && Array.isArray(subkeys) && subkeys.length > 0) {
          subkeys.forEach((subkey) => {
            childLabels.push(subkey);
          });
        }
      });
    }
    let finalSchemeArr = [];
    if (childLabels && Array.isArray(childLabels) && childLabels.length > 0) {
      childLabels.forEach((scheme) => {
        const finalObj = {
          parentScheme: 'scholarship',
          childScheme: scheme,
          awarded: (this.state && this.state[`awarded-${scheme}`]) || 0,
          target: (this.state && this.state[`target-${scheme}`]) || 0,
          year,
        };
        finalSchemeArr.push(finalObj);
      });
    }
    this.handleSubmitStatisticsData(finalSchemeArr, 'Scholarship Scheme');
  };

  handleSubmitCountryData = () => {
    const { batchStatistics } = this.props;
    const { statistics, currentYear } = batchStatistics || {};
    const { countryStatistics, countryStatisticsOthers } = statistics || {};
    const { year } = currentYear || {};
    const countryKeys = countryStatistics && Object.keys(countryStatistics);
    const otherCountryKeys =
      countryStatisticsOthers && Object.keys(countryStatisticsOthers);
    const childLabels = [];
    if (countryKeys && Array.isArray(countryKeys) && countryKeys.length > 0) {
      countryKeys.forEach((country) => {
        if (country && country !== 'others') {
          childLabels.push(country);
        }
      });
    }
    if (
      otherCountryKeys &&
      Array.isArray(otherCountryKeys) &&
      otherCountryKeys.length > 0
    ) {
      otherCountryKeys.forEach((country) => {
        if (country) {
          childLabels.push(country);
        }
      });
    }
    const finalCountryArr = [];
    if (childLabels && Array.isArray(childLabels) && childLabels.length > 0) {
      childLabels.forEach((label) => {
        const finalObj = {
          parentScheme: 'country',
          childScheme: label,
          awarded: (this.state && this.state[`awarded-${label}`]) || 0,
          target: (this.state && this.state[`target-${label}`]) || 0,
          year,
        };
        finalCountryArr.push(finalObj);
      });
    }
    this.handleSubmitStatisticsData(finalCountryArr, 'Country Data');
  };

  handleSubmitCourseData = () => {
    const { batchStatistics } = this.props;
    const { statistics, currentYear } = batchStatistics || {};
    const { courseStatistics } = statistics || {};
    const { year } = currentYear || {};
    const courseKeys = courseStatistics && Object.keys(courseStatistics);
    const childLabels = [];
    if (courseKeys && Array.isArray(courseKeys) && courseKeys.length > 0) {
      courseKeys.forEach((course) => {
        if (course) {
          childLabels.push(course);
        }
      });
    }
    const finalCourseArr = [];
    if (childLabels && Array.isArray(childLabels) && childLabels.length > 0) {
      childLabels.forEach((label) => {
        const finalObj = {
          parentScheme: 'course',
          childScheme: label,
          awarded:
            (this.state &&
              this.state[
                `awarded-${
                  label && label.toString() && label.toString().toLowerCase()
                }-course`
              ]) ||
            0,
          target: 0,
          year,
        };
        finalCourseArr.push(finalObj);
      });
    }
    this.handleSubmitStatisticsData(finalCourseArr, 'Course Data');
  };

  render() {
    const {
      dailyReports,
      history,
      batchStatisticsLoading,
      loading,
      batchStatistics,
      user,
      lastUpdatedAt,
    } = this.props || {};
    const { userinfo, createdAt } = lastUpdatedAt || {};
    const { firstname, lastname } = userinfo || {};
    const { currentYear: currSelectedYear, roleId } = user || {};
    //Batch Statistics
    const { currentYear, statistics } = batchStatistics || {};
    const { details, acceptanceRate, year } = currentYear || {};
    const { accepted, interviewed, offered, total } = details || {};
    const {
      schemeStatistics,
      countryStatistics,
      courseStatistics,
      schoolStatistics,
      countryStatisticsOthers,
      courseStatisticsOthers,
    } = statistics || {};
    let schoolStatisticsKeys =
      schoolStatistics && Object.keys(schoolStatistics);
    let finalSchoolStatistics = {};
    if (
      schoolStatisticsKeys &&
      Array.isArray(schoolStatisticsKeys) &&
      schoolStatisticsKeys.length > 0
    ) {
      schoolStatisticsKeys.forEach((school) => {
        if (school === 'OtherSchool' || school === 'Other Institutions') {
          finalSchoolStatistics['Other Institutions'] = {
            ...schoolStatistics['OtherSchool'],
            ...schoolStatistics['Other Institutions'],
          };
        } else {
          finalSchoolStatistics[school] = {
            ...(schoolStatistics && schoolStatistics[school]),
          };
        }
      });
    }
    //Other props and states
    const { push } = history || {};
    const {
      selectedTab,
      successNotif,
      errorNotif,
      notifMsg,
      year: currBatchYear,
      isValidYear,
      editScholarshipData,
      editCountryData,
      editCourseData,
      editSchoolData,
      errMsg,
      showLoader,
    } = this.state;
    return (
      <div>
        <BackRow paths={this.paths} />
        <input
          style={{ height: 0, opacity: 0 }}
          type="text"
          ref={this.handleRedirect}
        />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
          errMsg={errMsg}
        />
        <GenericTabs
          selected={selectedTab}
          tabContent={_.range(currSelectedYear, 2020, -1).map((year, idx) => {
            return {
              label: `Selection Year ${year}`,
              value: '',
              onClick: () => {
                this.handleTabClick(idx, year);
              },
            };
          })}
        />
        {selectedTab === 0 && roleId === 6 && (
          <div className="d-flex justify-content-end">
            <div className="text-right">
              <FileUploaderButton
                buttonLabel="Upload"
                labelText={
                  <div className="text-center">
                    Upload
                    <div className="small">(Selection Statistics)</div>
                  </div>
                }
                buttonKind="primary"
                accept={['.csv']}
                disableLabelChanges
                name="file"
                onChange={(e) => this.handleUploadBatchStatistics(e)}
              />
              <div className="small mt-2">.csv format is allowed</div>
            </div>
            <div className="ml-3">
              <Button
                className="txt-dec-none"
                kind="primary"
                href={SelectionStatisticsTemplate}>
                Download Template
              </Button>
            </div>
          </div>
        )}
        {(selectedTab === 1 || selectedTab === 2) && roleId == 6 && (
          <div className="">
            <div className="d-lg-none d-md-none d-sm-none d-xs-none">
              <TextInput
                name="year"
                id="year"
                value={currBatchYear}
                labelText="Year"
                placeholder={currSelectedYear - 1}
                onChange={(e) => {
                  this.setState({
                    [e.target.name]: e.target.value,
                  });
                }}
                type="number"
                invalid={isValidYear}
                invalidText="Please enter valid year"
              />
            </div>
            <div className="text-right d-flex justify-content-end">
              <div>
                <FileUploaderButton
                  buttonLabel="Upload YTD"
                  labelText="Upload YTD"
                  buttonKind="tertiary"
                  accept={['.xlsx']}
                  disableLabelChanges
                  name="file"
                  onChange={(e) => this.handleUploadPrevioudYTDStatistics(e)}
                />
                <div className="small mt-2">.xlsx format is allowed</div>
              </div>
              <div className="ml-2">
                <Button
                  className="txt-dec-none"
                  kind="primary"
                  href={YtdTemplate}>
                  Download Template
                </Button>
              </div>
              <div className="ml-4">
                <FileUploaderButton
                  buttonLabel="Upload"
                  labelText="Upload"
                  buttonKind="primary"
                  accept={['.xlsx']}
                  disableLabelChanges
                  name="file"
                  onChange={(e) => this.handleUploadPrevioudBatchStatistics(e)}
                />
                <div className="small mt-2">.xlsx format is allowed</div>
              </div>
              <div className="ml-2">
                <Button
                  className="txt-dec-none"
                  kind="primary"
                  href={DailyStatsTemplate}>
                  Download Template
                </Button>
              </div>
            </div>
          </div>
        )}
        {selectedTab === 0 && (
          <div className="h5 f-w-b bx--ty-gamma font-weight-light mt-3">
            Daily Report
          </div>
        )}
        <Tile className="mb-2 mt-3">
          <div className="bx--row align-items-center">
            <div className="bx--col-lg-3">
              <div>
                <div className="xpa-name-overflow xpa-link mt-1">
                  <h4>Selection Year {year || currSelectedYear}</h4>
                </div>
              </div>
            </div>
            <div className="bx--col-lg-8">
              <BatchCounts
                acceptanceRate={acceptanceRate}
                accepted={accepted}
                interviewed={interviewed}
                offered={offered}
                selectedTab={selectedTab}
                total={total}
                push={push}
              />
            </div>
          </div>
        </Tile>
        {/* <DailyReportsGraphs
          dailyReports={dailyReports}
          year={year || currSelectedYear}
          currSelectedYear={currSelectedYear}
        /> */}
        <OverallStatistics
          dailyReports={dailyReports}
          year={year || currSelectedYear}
          currSelectedYear={currSelectedYear}
          selectedTab={selectedTab}
        />
        {roleId === 6 && (
          <Fragment>
            {editScholarshipData === true ? (
              <div className="mt-3 mb-3 text-right">
                <Button onClick={this.handleHideEditScheme} kind="secondary">
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="mt-3 mb-3 text-right">
                <Button
                  onClick={this.handleEditScholarshipData}
                  kind="tertiary">
                  Edit
                </Button>
              </div>
            )}
          </Fragment>
        )}

        <ScholarshipSchoolStatistics
          schemeStatistics={schemeStatistics}
          year={year || currSelectedYear}
          heading="By Scholarship Scheme"
          selectedTab={selectedTab}
          orderValues={[
            'Public Administration',
            'Uniformed Services',
            'Professional Services',
            "PSC Master's Scholarship",
          ]}
          editScholarshipData={editScholarshipData}
          state={this.state}
          handleChange={this.handleChange}
        />
        {editScholarshipData === true && (
          <div className="text-center mt-3 mb-3">
            <Button kind="primary" onClick={this.handleSubmitSchemeData}>
              Submit
            </Button>
          </div>
        )}
        {roleId === 6 && (
          <Fragment>
            {editCountryData === true ? (
              <div className="mt-3 mb-3 text-right">
                <Button onClick={this.handleHideEditCountry} kind="secondary">
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="mt-3 mb-3 text-right">
                <Button onClick={this.handleEditCountryData} kind="tertiary">
                  Edit
                </Button>
              </div>
            )}
          </Fragment>
        )}
        <CountryCourseStatistics
          countryStatistics={countryStatistics}
          year={year || currSelectedYear}
          heading="By Country"
          selectedTab={selectedTab}
          othersData={countryStatisticsOthers}
          editCountryData={editCountryData}
          state={this.state}
          handleChange={this.handleChange}
          orderedKeys={[
            'United Kingdom',
            'United States Of America',
            'Singapore',
            'China',
            'France',
            'Germany',
            'Japan',
            'Others',
            'Pending',
          ]}
        />
        {editCountryData === true && (
          <div className="text-center mt-3 mb-3">
            <Button kind="primary" onClick={this.handleSubmitCountryData}>
              Submit
            </Button>
          </div>
        )}
        {roleId === 6 && (
          <Fragment>
            {editCourseData === true ? (
              <div className="mt-3 mb-3 text-right">
                <Button onClick={this.handleHideCounrse} kind="secondary">
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="mt-3 mb-3 text-right">
                <Button onClick={this.handleEditCourseData} kind="tertiary">
                  Edit
                </Button>
              </div>
            )}
          </Fragment>
        )}
        <CountryCourseStatistics
          countryStatistics={courseStatistics}
          year={year || currSelectedYear}
          heading="By Course"
          isCourseTable
          selectedTab={selectedTab}
          othersData={[]}
          state={this.state}
          handleChange={this.handleChange}
          editCourseData={editCourseData}
          orderedKeys={[
            'Humanities & Social Sciences',
            'Science / Mathematics',
            'Engineering & ICT',
            'Economics / PPE',
            'Law',
            'Finance / Business / Accountancy',
            'Liberal Arts',
            'Medicine / Dentistry',
            'Others',
            'Pending',
          ]}
        />
        {editCourseData === true && (
          <div className="text-center mt-3 mb-3">
            <Button kind="primary" onClick={this.handleSubmitCourseData}>
              Submit
            </Button>
          </div>
        )}
        {roleId === 6 && (
          <Fragment>
            {editSchoolData === true ? (
              <div className="mt-3 mb-3 text-right">
                <Button onClick={this.handleHideSchool} kind="secondary">
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="mt-3 mb-3 text-right">
                <Button onClick={this.handleEditSchoolData} kind="tertiary">
                  Edit
                </Button>
              </div>
            )}
          </Fragment>
        )}
        <ScholarshipSchoolStatistics
          schemeStatistics={finalSchoolStatistics}
          year={year || currSelectedYear}
          heading="By School"
          selectedTab={selectedTab}
          orderValues={[
            'HCI/RI',
            'Integrated Programme (IP) and Specialised Schools',
            'Non-IP Schools',
            'Other Institutions',
            'Polytechnics',
          ]}
          editSchoolData={editSchoolData}
          handleChange={this.handleChange}
          state={this.state}
        />
        {editSchoolData === true && (
          <div className="text-center mt-3 mb-3">
            <Button kind="primary" onClick={this.handleSubmitSchoolData}>
              Submit
            </Button>
          </div>
        )}
        <div className="mb-5">
          <Tile className="mt-2 mb-2">
            <h6>Last Uploaded By:</h6>
            <div className="d-flex mt-2 mb-2">
              <div className="text-capitalize f-w-b">
                {firstname} {lastname}
              </div>
              <div className="ml-3">
                {createdAt ? moment(createdAt).format('DD/MM/YYYY') : '-'}
              </div>
            </div>
          </Tile>
        </div>
        {/* <NoContentBox message="There are no details for your current selection" /> */}
        <JDProcessOverlay
          show={showLoader || loading}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dailyReports: state.BatchInfo.dailyReports,
  loading: state.BatchInfo.loading,
  batchStatisticsLoading: state.BatchInfo.loading,
  batchStatistics: state.Dashboard.batchStatistics,
  user: state.x0paDuser.user,
  lastUpdatedAt: state.BatchInfo.lastUpdatedAt,
});

const mapDispatchToProps = {
  getBatchInfoDailyReports,
  getDasboardStatistics,
  uploadBatchStatistics,
  uploadPreviousBatchStatistics,
  updateCustomStatistics,
  getLastUploadedHistory,
  uploadPreviousYTDStatistics,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchInfo);
