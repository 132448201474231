import callApi from './callApi';
import { v2BaseUrl } from '../utils/config';
import {
  REQUEST_CANDIDATE_REPORT_EMAILS,
  REQUEST_PSC_PEER_FEEDBACK_EMAILS,
} from './actionTypes';

const uploadCandidateReportEmails = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/candidateReport/upload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CANDIDATE_REPORT_EMAILS,
    url,
    method: 'POST',
    body: data,
  };
  return callApi(params);
};

const uploadPscPeerFeedBackReportEmails = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/pscpeerfeedbackreport/send-report?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_PSC_PEER_FEEDBACK_EMAILS,
    url,
    method: 'POST',
    body: data,
  };
  return callApi(params);
};

export { uploadCandidateReportEmails, uploadPscPeerFeedBackReportEmails };
