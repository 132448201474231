import React from 'react';

//Functional Components
import NoContentBox from '../common/NoContentBox';

//Common Packages
import { Pagination } from 'carbon-components-react';
import { roundNumber } from '../../containers/common/common';
import _ from 'lodash';

//CSS Files
import './PSSummaryOfScores.css';

const PSAssessmentCenterData = (props) => {
  const {
    psListData,
    psacActivePage,
    psacItemsPerPage,
    handlePSACPaginationChange,
  } = props || {};
  const { psacRecords } = psListData || {};
  const { list, totalCount } = psacRecords || {};
  const psacSkip =
    psacActivePage === 0 ? 0 : (psacActivePage - 1) * psacItemsPerPage;
  let finalPSListData = [];
  if (list && Array.isArray(list) && list.length > 0) {
    finalPSListData = list.filter((res) => {
      const {
        psac_leadership_qualities_rating: leadershipQualitiesRating,
        psac_intellectual_qualities_rating: intellectualQualitiesRating,
        psac_character_values_rating: characterValuesRating,
        psac_people_skills_rating: peopleSkillsRating,
        psac_personal_effectiveness_rating: personalEffectivenessRating,
        psac_communication_skills_rating: communicationSkillsRating,
      } = res || {};
      if (
        leadershipQualitiesRating > 0 ||
        intellectualQualitiesRating > 0 ||
        characterValuesRating > 0 ||
        peopleSkillsRating > 0 ||
        personalEffectivenessRating > 0 ||
        communicationSkillsRating > 0
      ) {
        return true;
      }
      return false;
    });

    finalPSListData = finalPSListData?.map((res) => {
      const {
        fullName,
        psac_leadership_qualities_rating: leadershipQualitiesRating,
        psac_intellectual_qualities_rating: intellectualQualitiesRating,
        psac_character_values_rating: characterValuesRating,
        psac_people_skills_rating: peopleSkillsRating,
        psac_personal_effectiveness_rating: personalEffectivenessRating,
        psac_communication_skills_rating: communicationSkillsRating,
        customRank,
      } = res || {};

      const total =
        (leadershipQualitiesRating && Number(leadershipQualitiesRating)) +
          (intellectualQualitiesRating && Number(intellectualQualitiesRating)) +
          (characterValuesRating && Number(characterValuesRating)) +
          (peopleSkillsRating && Number(peopleSkillsRating)) +
          (personalEffectivenessRating && Number(personalEffectivenessRating)) +
          (communicationSkillsRating && Number(communicationSkillsRating)) || 0;
      const mean = total > 0 ? total / 6 : 0;
      return {
        fullName,
        leadershipQualitiesRating,
        intellectualQualitiesRating,
        characterValuesRating,
        peopleSkillsRating,
        personalEffectivenessRating,
        communicationSkillsRating,
        customRank,
        total,
        mean,
        roundedMean: roundNumber(mean),
      };
    });

    finalPSListData = _.orderBy(
      finalPSListData,
      ['roundedMean', 'fullName'],
      ['desc', 'asc']
    );
  }

  return (
    <div className="mt-2">
      <h4 className="mt-2 mb-2">Assessment Centre</h4>
      <div className="mt-2 mb-3">(Ranked by Mean AC Score)</div>
      <div className="overflow-scroll">
        <table className="css-table-batch-info w-100 css-statistics-zoom-auto">
          <thead className="css-total-bg vertical-align-middle">
            <tr>
              <th
                className="csc-scores-rank-w css-batch-metric-border-r css-metric-table-head vertical-align-middle"
                rowspan="2">
                Rank
              </th>
              <th
                className="css-batch-metric-border-r css-metric-table-head text-left pl-2 vertical-align-middle csc-cand-name-w-psac"
                rowspan="2">
                Name
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Leadership
              </th>
              <th className="css-batch-metric-border-r css-traits-border-b   css-table-head csc-traits-w vertical-align-middle">
                Intellectual
              </th>
              <th className="css-batch-metric-border-r css-traits-border-b   css-table-head csc-traits-w vertical-align-middle">
                Character &amp; Values
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                People
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Comms
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Personal Effectiveness
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Total
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Mean
              </th>
            </tr>
          </thead>
          {finalPSListData &&
            Array.isArray(finalPSListData) &&
            finalPSListData.length > 0 && (
              <tbody>
                {finalPSListData.map((res, index) => {
                  const {
                    fullName,
                    leadershipQualitiesRating,
                    intellectualQualitiesRating,
                    characterValuesRating,
                    peopleSkillsRating,
                    personalEffectivenessRating,
                    communicationSkillsRating,
                    customRank,
                    total,
                    mean,
                  } = res || {};

                  return (
                    <tr className="text-center vertical-align-middle css-table-row-h">
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r">
                        {customRank}
                      </td>
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r css-total-f-w pl-2 text-left">
                        <div className="csc-cand-name-f-sz text-capitalize">
                          {fullName}
                        </div>
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(leadershipQualitiesRating) <= 3 &&
                          leadershipQualitiesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(leadershipQualitiesRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(intellectualQualitiesRating) <= 3 &&
                          intellectualQualitiesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(intellectualQualitiesRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(characterValuesRating) <= 3 &&
                          characterValuesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(characterValuesRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(peopleSkillsRating) <= 3 &&
                          peopleSkillsRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(peopleSkillsRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(communicationSkillsRating) <= 3 &&
                          communicationSkillsRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(communicationSkillsRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(personalEffectivenessRating) <= 3 &&
                          personalEffectivenessRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(personalEffectivenessRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(total) <= 3 && total > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(total, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(mean) <= 3 && mean > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(mean, '-')}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
        </table>
      </div>
      {((finalPSListData &&
        Array.isArray(finalPSListData) &&
        finalPSListData.length <= 0) ||
        !finalPSListData) && (
        <NoContentBox
          hideTile
          message="There are no details available for your current selection"
        />
      )}
      {totalCount > 0 && (
        <Pagination
          onChange={handlePSACPaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={psacActivePage}
          totalItems={totalCount}
          pageSize={psacItemsPerPage}
        />
      )}
      {finalPSListData &&
        Array.isArray(finalPSListData) &&
        finalPSListData.length > 0 && (
          <div>
            <div class="mb-2 mt-3 text-justify" style={{ lineHeight: 1.3 }}>
              <span class="f-w-b">Banding Scale</span> - 7 (Very Strong), 6
              (Strong), 5 (More than Satisfactory), 4 (Satisfactory), 3 (Less
              than Satisfactory), 2 (Weak), 1 (Very Weak)
            </div>
            <div class="css-default-text-color f-w-b mb-2 mt-2">
              Ratings of 3 and below are shaded.
            </div>
          </div>
        )}

      <hr />
    </div>
  );
};

export default PSAssessmentCenterData;
