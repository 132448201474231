import React, { Component } from 'react';
import { connect } from 'react-redux';
import NoContentBox from '../../components/common/NoContentBox';
import { Accordion, AccordionItem, Modal } from 'carbon-components-react';
import CandidateGBAStenDetails from '../../components/private/CandidateGBAStenDetails';
import { getGBADetails } from '../../actions/CandidateInfo';
import {
  handleGetBehavioralIndicators,
  handleGetInterviewQuestions,
  handleGetTraitDefinition,
} from '../../components/private/GBAQuesDef';
import '../public/MockUi2.css';

class CandidateGBADetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAccrVal: '',
    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }
  
  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  renderOnMount = () => {
    const { profileId } = this.props;
    if (profileId) {
      this.props.getGBADetails(profileId);
    }
  };

  handleOpenAccr = (name) => {
    this.setState({
      isOpenAccrVal: name,
    });
  };

  handleBarOnClick = (data) => {
    const { key } = data || {};
    this.setState({
      isOpenModal: true,
      definition: handleGetTraitDefinition(key),
      heading: key,
    });
  };

  render() {
    const { gbaDetails } = this.props;
    const { isOpenAccrVal } = this.state;
    const { details } = gbaDetails || {};
    const { assessment_results } = details || {};
    const mainTraits = [
      'Processing Capacity',
      'Processing Consistency',
      'Learning Agility',
      'Innovation Potential',
      'Rational Decision Making Style',
      'Performance Under Pressure',
      'Determination',
      'Ownership and Responsibility',
      'Emotional Stability',
      'Self Monitoring',
      'Social Dominance',
    ];
    let filteredGbaTraits = [];

    let gbaMainTraitsData = [];
    if (
      assessment_results &&
      Array.isArray(assessment_results) &&
      assessment_results.length > 0
    ) {
      gbaMainTraitsData = assessment_results.filter(
        (r) => r && r.type !== 'percentile'
      );
    }
    const referenceTraits = [
      'Learning Agility',
      'Processing Capacity',
      'Processing Consistency',
      'Processing Speed',
      'Executive Function',
      'Concentration',
      'Performance Under Pressure',
      'Ownership and Responsibility',
      'Determination',
      'Sensitivity To Loss',
      'Resilience',
      'Sensitivity To Reward',
      'Self-discipline',
      'Proactivity',
      'Innovation Potential',
      'Rational Decision-Making Style',
      'Managing Uncertainty',
      'Deliberation',
      'Future Orientation',
      'Need For Structure',
      'Risk Propensity',
      'Creativity',
      'Curiosity',
      'Novelty Seeking',
      'Optimism',
      'Self-Monitoring',
      'Social Dominance',
      'Sociability',
      'Politeness',
      'Altruism',
      'Authenticity',
      'Self-Belief',
      'Emotional Stability',
      'Emotion Recognition',
    ];
    const sortedReferenceTraits = referenceTraits.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return (
      <div>
        <div className="mt-2 h6 font-weight-bold">Traits of Interest</div>
        <hr />
        {gbaMainTraitsData &&
        Array.isArray(gbaMainTraitsData) &&
        gbaMainTraitsData.length > 0 ? (
          <Accordion className="css-gba-accr-z" align='start'>
            {mainTraits &&
              Array.isArray(mainTraits) &&
              mainTraits.length > 0 &&
              mainTraits.map((trait) => {
                return gbaMainTraitsData.map((data) => {
                  const { name, score_sten } = data || {};
                  return (
                    trait === name && (
                      <AccordionItem
                        className="gba-accor-content mt-2"
                        title={
                          <div className="bx--row align-items-center">
                            <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-5">
                              {name}
                            </div>
                            <div className="d-flex justify-content-center mb-1 ml-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={
                                    res === score_sten
                                      ? `csc-th--block csc-score-color--${res} mr-2`
                                      : 'csc-th--block css-gba-color mr-2'
                                  }>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        }>
                        <div className="h6 mb-2">{name}</div>
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="font-weight-bold">{name}</span>{' '}
                            (Cognitive Score):
                          </div>{' '}
                        </div>
                        <hr />
                        <div>
                          <div className="font-weight-bold mb-2">
                            Definition:
                          </div>{' '}
                          <div>{handleGetTraitDefinition(name)}</div>
                        </div>
                        <div className="font-weight-bold mt-2">
                          Behavioural Indicators:
                        </div>
                        <div className="mt-2">
                          {handleGetBehavioralIndicators(name, score_sten)}
                        </div>
                        <div className="font-weight-bold mt-2 mb-2">
                          Interview Questions
                        </div>
                        {handleGetInterviewQuestions(name)}
                      </AccordionItem>
                    )
                  );
                });
              })}
          </Accordion>
        ) : (
          <NoContentBox
            hideTile
            message="There are no GBA results for your current selection"
          />
        )}

        <div className="h6 f-w-b mt-4 mb-4">Full 34 Traits</div>
        {gbaMainTraitsData &&
        Array.isArray(gbaMainTraitsData) &&
        gbaMainTraitsData.length > 0 ? (
          <CandidateGBAStenDetails
            handleOpenAccr={this.handleOpenAccr}
            isOpenAccrVal={isOpenAccrVal}
            gbaMainTraitsData={gbaMainTraitsData}
            handleBarOnClick={this.handleBarOnClick}
          />
        ) : (
          <NoContentBox
            hideTile
            message="There are no GBA scores results for your current selection"
          />
        )}

        <div className="h6 f-w-b mt-4 mb-4">GBA Traits Reference</div>
        <Accordion>
          {sortedReferenceTraits.map((res) => {
            return (
              <AccordionItem
                className="gba-accor-content mt-2"
                title={
                  <div className="bx--row align-items-center">
                    <div className="h6 font-weight-bold mb-1 bx--col-lg-12 bx--col-md-12">
                      {res}
                    </div>
                  </div>
                }>
                <div className="h6 mb-2">{res}</div>
                <hr />
                <div>
                  <div className="font-weight-bold mb-2">Definition:</div>{' '}
                  <div>{handleGetTraitDefinition(res)}</div>
                </div>
                <div className="mt-2 mb-2">
                  <div className="font-weight-bold mb-2">
                    Behavioural Indicators:
                  </div>{' '}
                  <div>{handleGetBehavioralIndicators(res, false)}</div>
                </div>
                <div className="font-weight-bold mt-2 mb-2">
                  Interview Questions
                </div>
                {handleGetInterviewQuestions(res)}
              </AccordionItem>
            );
          })}
        </Accordion>

        <div className="h6 f-w-b mt-4 mb-4">Additional Traits</div>
        <Accordion>
          <AccordionItem
            className="content-padding mt-2"
            title={
              <div className="bx--row align-items-center">
                <div className="h6 font-weight-bold mb-1 bx--col-lg-12 bx--col-md-12">
                  Empathy
                </div>
              </div>
            }>
            <div style={{ lineHeight: 1.3 }}>
              <div className="h6 mb-2">Empathy</div>
              <hr />
              <div>
                <div className="f-w-b mb-2">Definition:</div>{' '}
                <div>
                  Understands and is concerned about the experience of others;
                  Supports the emotional needs of others
                </div>
              </div>
              <div className="mt-3">
                <div className=" mb-2">
                  Include elements of Behavioural Flexibility:
                </div>{' '}
                <div>
                  Appropriately adjusts behaviour to meet needs of
                  situation/audience; takes others’ feelings into consideration
                  when interacting with them
                </div>
              </div>
              <div className="mt-3">
                <div className="mb-2">Includes some aspects of Courage:</div>{' '}
                <div>
                  Acts in a manner consistent with one’s beliefs and values.
                </div>
              </div>

              <div className="mt-3">
                <div className="mb-2">Suggested Scenarios and Questions:</div>{' '}
                <div className="f-w-b">
                  I will be reading a hypothetical situation to you. Imagine
                  yourself to be in the situation and tell me how you would
                  respond… [
                  <span style={{ fontStyle: 'italic' }}>Read Scenario</span>]
                </div>
              </div>

              <div className="mt-3">
                <div className="mb-2">
                  Follow up questions [where necessary]:
                </div>{' '}
                <div className=" mb-2 bx--row m-0">
                  <div>i.</div>{' '}
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12 ml-2 pl-3">
                    What are the key considerations for the respective
                    stakeholders? How do you think they are feeling?
                  </div>
                </div>
                <div className=" mb-2 bx--row m-0">
                  <div>ii.</div>{' '}
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12 pl-4">
                    To what extent do you find this situation difficult and why?
                  </div>
                </div>
                <div className=" mb-2 bx--row m-0">
                  <div>iii.</div>{' '}
                  <div className="bx--col-lg-11 ml-1 f-w-b bx--col-md-10 bx--col-sm-12">
                    Why would you take your suggested course of action? How
                    would you convey this to the person making the appeal?
                  </div>
                </div>
                <div className=" mb-2 bx--row m-0">
                  <div>iv.</div>
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 ml-1 bx--col-sm-12">
                    What other possible courses of action did you consider? Why
                    did you choose your approach over the other possibilities?
                  </div>
                </div>
                <div className=" mb-2 bx--row m-0">
                  <div>v.</div>{' '}
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12 ml-2">
                    What are some challenges you think you would face in taking
                    this course of action (if any)?
                  </div>
                </div>
                <div className="mb-2">
                  <div className="bx--row m-0">
                    <div>vi.</div>{' '}
                    <div className="bx--col-lg-11 bx--col-md-10 bx--col-sm-12">
                      [
                      <span style={{ fontStyle: 'italic' }}>
                        If candidates request for more information
                      </span>
                      ]:
                    </div>
                  </div>
                  <div className="mt-2  bx--row m-0 ml-5">
                    <div>a.</div>{' '}
                    <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                      How would this information affect your decision?
                    </div>
                  </div>
                  <div className="mt-2 bx--row m-0 ml-5">
                    <div>b.</div>{' '}
                    <div className="bx--col-lg-11 bx--col-md-10 bx--col-sm-12">
                      [
                      <span style={{ fontStyle: 'italic' }}>
                        If candidate is non-committal
                      </span>
                      ]{' '}
                      <span className="f-w-b">
                        If you do not have this information but have to decide
                        on one course of action based on what you know in this
                        situation, what would you choose to do or say to the
                        person?
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="bx--row m-0">
                    <div>vii.</div>{' '}
                    <div className="bx--col-lg-11 bx--col-md-10 bx--col-sm-12">
                      [
                      <span style={{ fontStyle: 'italic' }}>
                        If candidates did not request for further information
                      </span>
                      ]:
                    </div>
                  </div>
                  <div className="mt-2 bx--row  m-0 ml-5">
                    <div>a.</div>{' '}
                    <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                      What additional information would you have needed to make
                      a better decision / to make you more confident about your
                      decision?
                    </div>
                  </div>
                  <div className="mt-2 bx--row  m-0 ml-5">
                    <div>b.</div>{' '}
                    <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                      How would this information affect your decision?
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="csc-f-dec-u">Scenario 1</div>
              <div className="mt-1 mb-3 f-w-b css-l-h text-justify mr-2">
                You are in charge of a portfolio which involves administration
                of early childhood development centres. A young single mother is
                sitting in front of you and appealing for her 3-month old infant
                to be admitted to the only infant care facility in her
                neighbourhood. The infant care does not currently have
                vacancies. The mother has stated that she is the sole
                breadwinner; that it is not conducive to bring an infant to her
                workplace nor are there infant care facilities near her
                workplace. She also does not have childcare support from family
                nor friends.
              </div>

              <div className="mb-3 f-w-b css-l-h text-justify mr-2">
                How would you respond to her?
              </div>
              <div className="csc-f-dec-u">Scenario 2</div>
              <div className="mt-1 mb-3 f-w-b css-l-h text-justify mr-2">
                Singapore will be entering a lockdown to contain the spread of
                the CoViD-19 virus and the airports will be closed for the
                foreseeable future. You are working in a government department
                that handles appeals from foreign workers based in Singapore.
                You have decided to assign the last plane ticket to Bangladesh
                to a foreign worker who needs to get back for an arranged
                marriage, failing which, he will lose his bride. You have
                verbally informed him of the outcome of his appeal but have yet
                to process it.
              </div>
              <div className="mb-3 f-w-b css-l-h text-justify mr-2">
                Another foreign worker is now sitting in front of you to appeal
                his case which was previously been turned down by your
                colleague. He is a terminally-ill patient who has ‘one month
                tops’ to live and would like to return to his country so that he
                could spend his remaining days with his family. He is desperate
                for your assistance to be placed on the last flight out of
                Singapore. How would you respond to him?
              </div>
              <div>
                <span className="csc-f-dec-u">Scenario 3</span> (Note:
                President’s Scholarship nominees were tested on the following
                scenario in “Meeting with a Citizen”)
              </div>
              <div className="mt-1 mb-3 f-w-b css-l-h text-justify mr-2">
                You may recall that you interacted with a citizen who was a
                hawker during the assessment centre. The hawker had approached
                you to appeal for their driving licence renewal fees and fines
                to be waived; and for their impounded vehicle to be released to
                them.
              </div>
              <div className="mb-1 f-w-b css-l-h text-justify mr-2">
                How did you respond to the situation?
              </div>
              <div className="mb-1 f-w-b css-l-h text-justify mr-2">
                How did you think the hawker was feeling?
              </div>
              <div className="mb-1 f-w-b css-l-h text-justify mr-2">
                To what extent did you find the situation difficult and why?
              </div>

              <div className="mt-5">
                <div className="mb-1 f-w-b css-l-h text-justify mr-2">
                  Additional information if necessary:
                </div>
                <div className=" mb-2 bx--row m-0">
                  <div>1.</div>
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                    Citizen was found to have an invalid driver’s license “due
                    to expiration” during a routine police check.
                  </div>
                </div>

                <div className=" mb-2 bx--row m-0">
                  <div>2.</div>
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                    Citizen was detained and minivan impounded.
                  </div>
                </div>

                <div className=" mb-2 bx--row m-0">
                  <div>3.</div>
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                    Citizen maintained he was unaware of the ‘new’ renewal
                    requirement.
                  </div>
                </div>

                <div className=" mb-2 bx--row m-0">
                  <div>4.</div>
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                    Citizen was charged with “driving without a valid charge”
                    and ordered to pay a fine of $500.
                  </div>
                </div>

                <div className=" mb-2 bx--row m-0">
                  <div>5.</div>
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                    Under existing policy, Citizen has to pay a ‘late renewal’
                    penalty charge of $150; along with the basic processing
                    charge of $100.
                  </div>
                </div>

                <div className=" mb-2 bx--row m-0">
                  <div>6.</div>
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                    The desk staff processing the renewal had informed the
                    Citizen that there are no exceptions and citizen has to pay
                    a total of $750 to renew his licence.
                  </div>
                </div>

                <div className=" mb-2 bx--row m-0">
                  <div>7.</div>
                  <div className="bx--col-lg-11 f-w-b bx--col-md-10 bx--col-sm-12">
                    Citizen and the desk staff got into a heated argument and
                    Citizen had asked to see the supervisor (you).
                  </div>
                </div>
              </div>
            </div>
          </AccordionItem>
        </Accordion>
        <Modal
          open={this.state.isOpenModal}
          modalHeading={this.state.heading}
          modalLabel="Action"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          passiveModal
          iconDescription="Close the modal"
          onRequestClose={() => {
            this.setState({ isOpenModal: false, heading: '' });
          }}
          onRequestSubmit={() => {}}>
          <div>
            <h6>{this.state.definition}</h6>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gbaDetails: state.CandidateInfo.gbaDetails,
});

const mapDispatchToProps = {
  getGBADetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateGBADetails);
