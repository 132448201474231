import React from 'react';
import CandidateGBAStenGraph from './CandidateGBAStenGraph';

const gbaData = (gbaMainTraitsData, keys, zScore) => {
  let data = [];
  if (
    gbaMainTraitsData &&
    Array.isArray(gbaMainTraitsData) &&
    gbaMainTraitsData.length > 0
  ) {
    data = gbaMainTraitsData.filter((r) => r && keys.includes(r.name));
  }
  let actualData = [];
  if (data && Array.isArray(data) && data.length > 0) {
    actualData = data.map((r) => r && r.name);
  }
  let remainingKeys = [];
  if (actualData && Array.isArray(actualData) && actualData.length > 0) {
    remainingKeys = keys.filter((key) => key && !actualData.includes(key));
  } else {
    remainingKeys = keys;
  }

  let graphDataMap = new Map();
  if (data && Array.isArray(data) && data.length > 0) {
    data.forEach((d) => {
      const { name, score_sten, score } = d || {};
      let finalScore = 0;
      if (zScore === true) {
        finalScore = score && Number(score);
      } else {
        finalScore = score_sten && Number(score_sten);
      }
      graphDataMap.set(name, {
        key: name,
        [name]: finalScore,
      });
    });
  }

  if (
    remainingKeys &&
    Array.isArray(remainingKeys) &&
    remainingKeys.length > 0
  ) {
    remainingKeys.forEach((key) => {
      graphDataMap.set(key, {
        key,
        [key]: 0,
      });
    });
  }
  let graphData = [];
  keys.forEach((key) => {
    graphData.push(graphDataMap.get(key));
  });
  return graphData.reverse() || [];
};

const graphBarPadding = (gbaMainTraitsData, keys, zScore) => {
  const data = gbaData(gbaMainTraitsData, keys, zScore);
  let padding = 0;
  if (data && Array.isArray(data) && data.length > 0) {
    if (data.length === 1) {
      padding = 0.8;
    } else if (data.length > 2 && data.length <= 4) {
      padding = 1;
    } else {
      padding = 0.3;
    }
  }

  return padding;
};

const cognitiveKeys = [
  'Learning Agility',
  'Processing Capacity',
  'Processing Consistency',
  'Processing Speed',
  'Executive Function',
  'Concentration',
];
const driveKeys = [
  'Performance Under Pressure',
  'Ownership and Responsibility',
  'Determination',
  'Sensitivity To Loss',
  'Resilience',
  'Sensitivity To Reward',
  'Self-discipline',
  'Proactivity',
];
const thinkingStyleKeys = [
  'Innovation Potential',
  'Rational Decision Making Style',
  'Managing Uncertainty',
  'Future Orientation',
  'Need For Structure',
  'Risk Propensity',
  'Creativity',
  'Curiosity',
  'Deliberation',
  'Novelty Seeking',
  'Optimism',
];
const interpersonalKeys = [
  'Self Monitoring',
  'Social Dominance',
  'Sociability',
  'Politeness',
  'Altruism',
  'Authenticity',
  'Self-Belief',
];
const personalKeys = ['Emotional Stability', 'Emotion Recognition'];

const CandidateStenGraph = (props) => {
  const { handleBarOnClick, gbaMainTraitsData, type, isMainGBAData } =
    props || {};
  return (
    <div>
      {(isMainGBAData === false ? type === 'cognitive-drive' : true) && (
        <div>
          <CandidateGBAStenGraph
            handleBarOnClick={handleBarOnClick}
            heading="Cognitive"
            maxValue={10}
            minValue={0}
            keys={cognitiveKeys}
            data={gbaData(gbaMainTraitsData, cognitiveKeys)}
            padding={graphBarPadding(gbaMainTraitsData, cognitiveKeys)}
            isMainGBAData={isMainGBAData}
          />
          <CandidateGBAStenGraph
            handleBarOnClick={handleBarOnClick}
            heading="Drive"
            keys={driveKeys}
            maxValue={10}
            minValue={0}
            data={gbaData(gbaMainTraitsData, driveKeys)}
            padding={graphBarPadding(gbaMainTraitsData, driveKeys)}
            isMainGBAData={isMainGBAData}
          />
        </div>
      )}
      {(isMainGBAData === false ? type === 'thinking-style' : true) && (
        <div>
          <CandidateGBAStenGraph
            handleBarOnClick={handleBarOnClick}
            heading="Thinking Style"
            maxValue={10}
            minValue={0}
            incBarHeight
            keys={thinkingStyleKeys}
            data={gbaData(gbaMainTraitsData, thinkingStyleKeys)}
            padding={graphBarPadding(gbaMainTraitsData, thinkingStyleKeys)}
            isMainGBAData={isMainGBAData}
          />
        </div>
      )}
      {(isMainGBAData === false
        ? type === 'interpersonal-personal-style'
        : true) && (
        <div>
          <CandidateGBAStenGraph
            handleBarOnClick={handleBarOnClick}
            heading="Interpersonal"
            keys={interpersonalKeys}
            maxValue={10}
            minValue={0}
            data={gbaData(gbaMainTraitsData, interpersonalKeys)}
            padding={graphBarPadding(gbaMainTraitsData, interpersonalKeys)}
            isMainGBAData={isMainGBAData}
          />
          <CandidateGBAStenGraph
            handleBarOnClick={handleBarOnClick}
            heading="Personal Style"
            keys={personalKeys}
            maxValue={10}
            minValue={0}
            data={gbaData(gbaMainTraitsData, personalKeys)}
            padding={graphBarPadding(gbaMainTraitsData, personalKeys)}
            isMainGBAData={isMainGBAData}
          />
        </div>
      )}
    </div>
  );
};

const CandidateZScoreGraph = (props) => {
  const { handleBarOnClick, gbaMainTraitsData } = props || {};
  return (
    <div>
      <CandidateGBAStenGraph
        handleBarOnClick={handleBarOnClick}
        heading="Cognitive"
        keys={cognitiveKeys}
        maxValue={3.5}
        minValue={-3.5}
        data={gbaData(gbaMainTraitsData, cognitiveKeys, true)}
        padding={graphBarPadding(gbaMainTraitsData, cognitiveKeys, true)}
      />
      <CandidateGBAStenGraph
        handleBarOnClick={handleBarOnClick}
        heading="Drive"
        keys={driveKeys}
        maxValue={3.5}
        minValue={-3.5}
        data={gbaData(gbaMainTraitsData, driveKeys, true)}
        padding={graphBarPadding(gbaMainTraitsData, driveKeys, true)}
      />
      <CandidateGBAStenGraph
        handleBarOnClick={handleBarOnClick}
        heading="Thinking Style"
        keys={thinkingStyleKeys}
        maxValue={3.5}
        incBarHeight
        minValue={-3.5}
        data={gbaData(gbaMainTraitsData, thinkingStyleKeys, true)}
        padding={graphBarPadding(gbaMainTraitsData, thinkingStyleKeys, true)}
      />
      <CandidateGBAStenGraph
        handleBarOnClick={handleBarOnClick}
        heading="Interpersonal"
        keys={interpersonalKeys}
        maxValue={3.5}
        minValue={-3.5}
        data={gbaData(gbaMainTraitsData, interpersonalKeys, true)}
        padding={graphBarPadding(gbaMainTraitsData, interpersonalKeys, true)}
      />
      <CandidateGBAStenGraph
        handleBarOnClick={handleBarOnClick}
        heading="Personal Style"
        keys={personalKeys}
        maxValue={3.5}
        minValue={-3.5}
        data={gbaData(gbaMainTraitsData, personalKeys, true)}
        padding={graphBarPadding(gbaMainTraitsData, personalKeys, true)}
      />
    </div>
  );
};

export { CandidateStenGraph, CandidateZScoreGraph };
