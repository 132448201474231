const behavioralTraits = [
  {
    trait: 'Boldness',
    definition: 'Presence and confidence',
    behavioralIndicators: [
      {
        minScore: 1,
        maxScore: 3,
        label: 'Low (Sten 1-3)',
        descPoints: [
          'Struggles to speak up.',
          'Tentative in expressing own views and opinions.',
          "Avoids limelight and stays in the 'background'.",
        ],
        toDo: `Probe further on how s/he asserts own views and persuades others.`,
        interviewQuestions: [
          'Describe a time when you had a different idea from others. What was the situation and what did you do? What prompted you to do this?',
        ],
      },
      {
        minScore: 4,
        maxScore: 6,
        label: 'Moderate (Sten 4-6)',
        descPoints: [
          'Allows others to take the lead but would assert own views where needed.',
          'Takes charge of the situation when feels confident to do so.',
        ],
        toDo: 'Probe further on the circumstances when he/she prefers to assert own views and take charge of situations.',
        interviewQuestions: [
          'Do you prefer to be the first to speak or wait to be invited to speak? Why?',
          'When would you prefer to be the first to speak? Why?',
        ],
      },
      {
        minScore: 7,
        maxScore: 9,
        label: 'High (Sten 7-9)',
        descPoints: [
          'Takes charge of discussions and is forthcoming with own views and opinions.',
          'Projects confidence in own abilities.',
        ],
        toDo: 'Probe further on how he/she reacts to opposition.',
        interviewQuestions: [
          'How important is it for you to express your opinions on issues? Why?',
          'What do you do when others disagree with your ideas? Give us an example.How important is it for you to express your opinions on issues? Why?',
        ],
      },
      {
        maxScore: 10,
        label: 'Extreme High (Sten 10)',
        descPoints: [
          'Confident of own abilities and asserts personal views.',
          'Enjoys taking charge of situations.',
          'May come across as domineering and arrogant.',
        ],
        toDo: 'Probe further on how he/she reacts to opposition.',
        interviewQuestions: [
          'Have you ever held back your views? When did you do this and why?',
          'What do you do when others disagree with your ideas? Give us an example.',
        ],
      },
    ],
  },
  {
    trait: 'Curiosity',
    definition: 'Drawn to ideas and possibilities',
    behavioralIndicators: [
      {
        minScore: 1,
        maxScore: 3,
        label: 'Low (Sten 1-3)',
        desc: 'Narrow range of intellectual interests. Prefers conventional ideas and tried-and-tested approaches. Holds deeply entrenched beliefs and values that are difficult to shift.',
        descPoints: [
          'Narrow range of intellectual interests.',
          'Prefers conventional ideas and tried-and-tested approaches.',
          'Holds deeply entrenched beliefs and values that are difficult to shift.',
        ],
        toDo: 'Probe further on sources of inspiration and what he/she values in conventional ideas.',
        interviewQuestions: [
          'Tell us about a recent book, article, or podcast you came across which you found thought-provoking or inspiring. Why was it thought-provoking or inspiring?',
          `Would you say you are more of an 'idealist' or a 'realist'? How would an idealist (or realist) like you contribute to the future of Singapore?`,
        ],
      },
      {
        minScore: 4,
        maxScore: 6,
        label: 'Moderate (Sten 4-6)',
        descPoints: [
          'Shows curiosity and interest in areas of practical or immediate application.',
          'Balances alternative perspectives with own preference for tried-and-tested approaches.',
        ],
        toDo: 'Probe further on sources of inspiration and how he/she responds to different ideas.',
        interviewQuestions: [
          'Tell us about a recent book, article, or podcast you came across which you found thought-provoking or inspiring. Why was it thought-provoking or inspiring?',
          'When you first hear about a new idea, what would convince you to try it out?',
        ],
      },
      {
        minScore: 7,
        maxScore: 9,
        label: 'High (Sten 7-9)',
        descPoints: [
          'Has a broad range of intellectual interests.',
          'Naturally inquisitive.',
          'Open to new ideas and embraces different perspectives.',
          'Enjoys intellectual discussions and thinking about possibilities.',
        ],
        toDo: `Probe further on how he/she balances between exploring new ideas and implementing what's practical.`,
        interviewQuestions: [
          `Would you say you are more of an 'idealist' or a 'realist'? How would an idealist (or realist) like you, contribute to the future of Singapore?`,
          `What do you find yourself being curious about? Why?`,
        ],
      },
      {
        maxScore: 10,
        label: 'Extreme High (Sten 10)',
        descPoints: [
          'Has a broad range of intellectual interests.',
          'Highly inquisitive.',
          'Open to new ideas and embraces diversity in perspectives.',
          'Enjoys intellectual discussions and exploring possibilities.',
        ],
        toDo: `Probe further on how he/she balances between exploring new ideas and implementing what's practical.`,
        interviewQuestions: [
          `Would you say you are more of an 'idealist' or a 'realist'? How has this preference work for you and against you?`,
          'How would an idealist (or realist) like you, contribute to the future of Singapore?',
        ],
      },
    ],
  },
  {
    trait: 'Drive',
    definition:
      'Sense of belief and urgency to stay focused on achieving outcomes',
    behavioralIndicators: [
      {
        minScore: 1,
        maxScore: 3,
        label: 'Low (Sten 1-3)',
        descPoints: [
          'Lacks urgency to start tasks and takes things at own pace.',
          'Procrastinates when overwhelmed.',
          'Has little desire for ambitious outcomes.',
        ],
        toDo: 'Probe further on how he/she maintains focus to complete tasks on to desirable standards.',
        interviewQuestions: [
          'People take different approaches to complete tasks. Some prefer a relaxed and flexible approach while others may focus on being timely and expedient. What is your typical approach? How has this preference work for you and against you?',
          'What are some feedback you received about the way you approach tasks?',
        ],
      },
      {
        minScore: 4,
        maxScore: 6,
        label: 'Moderate (Sten 4-6)',
        desc: 'Maintains a comfortable pace. Sets reasonable standards. Moderate desire to achieve ambitious goals. May require external motivation to see tasks through to completion.',
        descPoints: [
          'Maintains a comfortable pace.',
          'Sets reasonable standards.',
          'Moderate desire to achieve ambitious goals.',
          'May require external motivation to see tasks through to completion.',
        ],
        toDo: 'Probe further on how he/she maintains focus to complete tasks on to desirable standards.',
        interviewQuestions: [
          'Have you been involved in a highly demanding project? Why do you say it was highly demanding and what did you do to stay on track to deliver the outcomes?',
          'What are some feedback you have received about the way you approach tasks?',
        ],
      },
      {
        minScore: 7,
        maxScore: 9,
        label: 'High (Sten 7-9)',
        descPoints: [
          'Driven to complete tasks.',
          'Strives to achieve high standards.',
          'Stays focused on accomplishing ambitious goals.',
          'Seeks out opportunities to stay engaged and busy.',
        ],
        toDo: 'Probe further on how he/she pays attention to other aspects of project success, beyond outcomes.',
        interviewQuestions: [
          'Have you been involved in a highly demanding project? Why do you say it was highly demanding and what did you do to stay on track to deliver the outcomes?',
          'What are some feedback you received about the way you approach tasks?',
        ],
      },
      {
        maxScore: 10,
        label: 'Extreme High (Sten 10)',
        descPoints: [
          'Desires to achieve high standards.',
          'Focuses on accomplishing ambitious goals.',
          'Seeks out opportunities to stay engaged and busy.',
          'May impose similarly high standards on others which could be stressful on them.',
        ],
        toDo: 'Probe further on how he/she pays attention to other aspects of project success, beyond outcomes.',
        interviewQuestions: [
          'What are your responsibilities when you are tasked with a project?',
          'How do you feel and react when your team member takes a more flexible and relaxed approach to complete tasks?',
        ],
      },
    ],
  },
  {
    trait: 'Empathy',
    definition: 'Sensitive to the feelings of others and cares for others.',
    behavioralIndicators: [
      {
        minScore: 1,
        maxScore: 3,
        label: 'Low (Sten 1-3)',
        descPoints: [
          "Displays little regard for others' needs.",
          "Seldom acknowledges others' feelings.",
          'May appear cold, unfeeling or self-centred.',
        ],
        toDo: 'Probe further on how he/she manages the interpersonal aspects of working with others.',
        interviewQuestions: [
          'How important are the feelings of others and how much attention should you pay to it when you making a decision?',
          'Tell us about a situation where you noticed a person getting emotional. What was the situation and what did you do?',
        ],
      },
      {
        minScore: 4,
        maxScore: 6,
        label: 'Moderate (Sten 4-6)',
        descPoints: [
          'Shows some concern and sympathy for others.',
          'Recognises the range of emotions others may have.',
          'Offers help to those in need but will also consider the rational and objective aspects before making a decision.',
        ],
        toDo: 'Probe further on how he/she manages the interpersonal aspects of working with others.',
        interviewQuestions: [
          'How important are the feelings of others and how much attention should you pay to it when you making a decision?',
          'Tell us about a situation where you noticed a person getting emotional. What was the situation and what did you do?',
        ],
      },
      {
        minScore: 7,
        maxScore: 9,
        label: 'High (Sten 7-9)',
        descPoints: [
          "Sensitive to others' feelings.",
          'Considers others" needs and well-being.',
          'Offers help to those in need readily.',
        ],
        toDo: 'The panel may ask about how he/she balances between being empathetic and making unpopular decisions.',
        interviewQuestions: [
          'Tell us about a time where you had to make an unpopular but necessary decision. What was the situation and what was your experience?',
          'If you had a choice, would you rely more, or less, on emotions in your decision making? Why?',
        ],
      },
      {
        maxScore: 10,
        label: 'Extreme High (Sten 10)',
        descPoints: [
          'Highly sympathetic towards others.',
          "Places others' needs above one's own.",
          "May be excessively involved in others' affairs.",
          'Finds it difficult to make unpopular but necessary decisions.',
        ],
        toDo: 'The panel may ask about how he/she reacts to making unpopular / tough decisions.',
        interviewQuestions: [
          'Tell us about a time where you had to make an unpopular but necessary decision. What was the situation and how was your experience?',
          'If you had a choice, would you rely more, or less, on emotions in your decision making? Why?',
        ],
      },
    ],
  },
  {
    trait: 'Resilience',
    definition: 'Composed and bounces back in the face of setbacks or failures',
    behavioralIndicators: [
      {
        minScore: 1,
        maxScore: 3,
        label: 'Low (Sten 1-3)',
        descPoints: [
          'Easily flustered in unexpected situations.',
          'Discouraged by failure.',
          'Struggles with setbacks.',
          'Finds it difficult to get back on track without the support of others.',
        ],
        toDo: 'Probe further on how he/she copes with setbacks and failures.',
        interviewQuestions: [
          'Tell us about the most recent time you failed. What was the situation and why do you think you failed? How do you pick yourself up after experiencing failure?',
          'Who are your support network when you need help? What do these people offer you?',
        ],
      },
      {
        minScore: 4,
        maxScore: 6,
        label: 'Moderate (Sten 4-6)',
        descPoints: [
          'Manages challenges independently and would seek help when needed.',
          'May take some time to recover from setbacks.',
          'Experiences discouraging thoughts occasionally.',
        ],
        toDo: 'Probe further on how he/she copes with setbacks and failures.',
        interviewQuestions: [
          'How do you pick yourself up after experiencing a major setback or failure?',
          'Who are your support network when you need help? What do these people offer you?',
        ],
      },
      {
        minScore: 7,
        maxScore: 9,
        label: 'High (Sten 7-9)',
        descPoints: [
          'Unfazed by unexpected circumstances.',
          'Copes well in stressful situations.',
          'Bounces back easily from setbacks.',
          'Sees opportunities in crises.',
        ],
        toDo: 'Probe further on how he/she recognises stress and helps others cope with setbacks.',
        interviewQuestions: [
          'Tell us about a time when your team suffered a major setback. How did others react? What did you do?',
          'How do you know when you are stressed? What do you do about it?',
        ],
      },
      {
        maxScore: 10,
        label: 'Extreme High (Sten 10)',
        descPoints: [
          'Feels confident to overcome setbacks.',
          'Thrives in stressful situations.',
          'Rarely affected by setbacks.',
          'Sees opportunities in crises.',
        ],
        toDo: 'Probe further on how he/she recognises stress and helps others cope with setbacks.',
        interviewQuestions: [
          'Tell us about a time when your team suffered a major setback. How did others react? What did you do?',
          'How do you know when you are stressed? What do you do about it?',
        ],
      },
    ],
  },
];

export default behavioralTraits;
