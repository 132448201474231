import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
} from 'carbon-components-react';
import SideNavListMock from './SideNavListMock';
import { Logout16 } from '@carbon/icons-react';
import Countdown from 'react-countdown';
import Timer from 'react-compound-timer';

import '../../headers/Header.css';

const Completionist = () => {
  return (
    <div
      className="ml-2"
      style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>
      -
      <Timer>
        <Timer.Minutes />:
        <Timer.Seconds />
      </Timer>
    </div>
  );
};

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <div style={{ fontSize: '20px', fontWeight: 'bold' }} className="ml-2">
        {minutes}:{seconds}
      </div>
    );
  }
};

const PublicHeaderMock = (props) => {
  const {
    push,
    onClickSideNavExpand,
    isSideNavExpanded,
    history,
    showCountDown,
    candidateName,
  } = props || {};
  const pathname = window.location.pathname;
  return (
    <Header aria-label="CSC">
      <HeaderMenuButton
        aria-label="Open menu"
        onClick={onClickSideNavExpand}
        isActive={false}
      />
      <HeaderName
        onClick={() => {
          push && push('/');
        }}
        href="javascript:void(0)"
        prefix="">
        <h2 className="text-light font-weight-bold">ARS</h2>
      </HeaderName>
      <SkipToContent />
      <div className="d-md-block d-sm-block d-xs-block d-xl-none d-lg-none">
        <SideNavListMock
          history={history}
          isSideNavExpanded={isSideNavExpanded}
        />
      </div>
      <HeaderNavigation aria-label="CSC" className="ml-0 pl-0">
        <HeaderMenuItem key={1} element={Link} to="/public/dashboard/1">
          <div
            style={
              pathname === '/public/dashboard/1'
                ? { borderBottom: '2px solid white' }
                : {}
            }>
            Dashboard
          </div>
        </HeaderMenuItem>

        <HeaderMenuItem key={2} element={Link} to="/public/batchinfo">
          <div
            style={
              pathname === '/public/batchinfo'
                ? { borderBottom: '2px solid white' }
                : {}
            }>
            Batch
          </div>
        </HeaderMenuItem>
        <HeaderMenuItem
          key={2}
          element={Link}
          to="/public/list/agenda/interviews">
          <div
            style={
              pathname === '/public/list/agenda/interviews'
                ? { borderBottom: '2px solid white' }
                : {}
            }>
            Interviews
          </div>
        </HeaderMenuItem>
        <HeaderMenuItem key={4} element={Link} to="/public/list/candidate">
          <div
            style={
              pathname === '/public/list/candidate'
                ? { borderBottom: '2px solid white' }
                : {}
            }>
            Candidates
          </div>
        </HeaderMenuItem>
        <HeaderMenuItem
          key={3}
          element={Link}
          to="/public/list/candidate/search">
          <div
            style={
              pathname === '/public/list/candidate/search'
                ? { borderBottom: '2px solid white' }
                : {}
            }>
            Search
          </div>
        </HeaderMenuItem>
        <HeaderMenuItem key={5} element={Link} to="/public/agenda">
          <div
            style={
              pathname === '/public/agenda' ||
              pathname === '/public/agenda/info'
                ? { borderBottom: '2px solid white' }
                : {}
            }>
            Calendar
          </div>
        </HeaderMenuItem>
        <HeaderMenuItem key={6} element={Link} to="/public/reports/mock">
          <div
            style={
              pathname === '/public/reports/mock'
                ? { borderBottom: '2px solid white' }
                : {}
            }>
            Reports
          </div>
        </HeaderMenuItem>
      </HeaderNavigation>
      <HeaderGlobalBar>
        {showCountDown === true && (
          <div
            style={{ backgroundColor: 'white', width: '300px', height: '50px' }}
            className="mt-1 text-center d-flex align-items-center justify-content-center">
            <div className="mt-2 f-w-b h6">{candidateName}</div>
            <Countdown date={Date.now() + 9000} renderer={renderer} />
          </div>
        )}
        <HeaderGlobalAction
          className="logout-height"
          aria-label="Logout"
          onClick={() => {
            window.sessionStorage.removeItem('is-user-loggedin');
            window.location.href = `${window.location.origin}/public/dashboard/1`;
          }}
          href="/public/dashboard/1">
          Logout
        </HeaderGlobalAction>
      </HeaderGlobalBar>
    </Header>
  );
};

export default PublicHeaderMock;
