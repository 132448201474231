import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './NotFound.css';
import NotFoundImg from '../../assets/images/NotFound.png';
import AccessDenied from '../../assets/images/AccessDenied.png';

const NotFound = ({ roleId, restricted }) => {
  let dashboardLink = '/';
  switch (roleId) {
    case 4:
      dashboardLink = '/app/roboroy';
      break;
    case 3:
      dashboardLink = '/app/roboroy/candidate';
      break;
    case 11:
    case 12:
    case 13:
      dashboardLink = '/app/csc/cms/meeting';
      break;
    case 14:
      dashboardLink = '/app/csc/assessments/reports';
      break;
    default:
    //
  }
  return (
    <div className="xpa-align-items bx--tile" style={{ margin: '2em' }}>
      {restricted ? (
        <>
          <img
            className="xpa-image-size"
            src={AccessDenied}
            alt="Not Found Image"
          />
          <h5>Access Forbidden</h5>
        </>
      ) : (
        <>
          <img
            className="xpa-image-size"
            src={NotFoundImg}
            alt="Not Found Image"
          />
          <h5>How did you end up here?</h5>
          <div style={{ marginTop: '1em' }}>
            <Link to={dashboardLink} className="bx--btn bx--btn--primary">
              Go to{' '}
              {roleId == 11 || roleId == 12 || roleId == 13
                ? 'meetings'
                : roleId == 14
                ? 'assessments'
                : 'dashboard'}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(NotFound);
