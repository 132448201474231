import { isPublicRoute } from '../utils/common';

const errorResponse = actionType => `ERROR_${actionType}`;
const successResponse = actionType => `SUCCESS_${actionType}`;

const createAction = (type, payload) => ({
  type,
  payload,
});

// calls endpoint
const callApi = params => {
  const { dispatch, actionType, url, headers, ...otherParams } = params;
  dispatch({ type: actionType });
  let finalHeaders = headers;
  const fetchOptions = {
    ...otherParams,
    headers: finalHeaders,
    credentials: 'include',
    mode: 'cors',
  };

  return fetch(url, fetchOptions)
    .then(response => {
      const actionName = actionType.split('REQUEST_')[1];
      const errorResponseType = errorResponse(actionName);
      if (response.ok) {
        const successResponseType = successResponse(actionName);
        if (
          params &&
          params.responseType &&
          params.responseType === 'text/html'
        ) {
          return response
            .text()
            .then(resp => dispatch(createAction(successResponseType, resp)));
        }
        if (
          params &&
          params.responseType &&
          params.responseType === 'application/pdf'
        ) {
          const ctype = response.headers.get('Content-Type');
          if (ctype.includes('application/json')) {
            return response.json().then(resp => {
              dispatch(createAction(errorResponseType, resp));
              return Promise.resolve(resp);
            });
          }
          return response.blob().then(resp => {
            dispatch(createAction(successResponseType, resp));
            return Promise.resolve(resp);
          });
        }
        return response.json().then(resp => {
          dispatch(createAction(successResponseType, resp));
          return Promise.resolve(resp);
        });
      }
      return response
        .json()
        .then(resp => {
          dispatch(createAction(errorResponseType, { error: resp }));
          return Promise.reject(resp);
        })
        .catch(resp => {
          dispatch(createAction(errorResponseType, { error: resp }));
          return Promise.reject(resp);
        });
    })
    .catch((err) => {
      const { error, code } = err || {};
      const originPath = '/';
      const currentPath = window.location.pathname;
      if (isPublicRoute(currentPath)) {
        return err;
      }
      if (code && error) {
        if (
          error === 'Unauthorized' &&
          code === 'X0PA-401-1' &&
          originPath !== currentPath
        ) {
          window.location.href = window.location.origin;
        }
      }
      return err;
    });
};

export default callApi;