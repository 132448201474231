import React from 'react';

//Common packages
import {
  Tile,
  Checkbox,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';

const filterData = [
  {
    label: 'Logged Info',
    key: 'logged-info',
    values: [
      {
        auditLogLabel: 'Log In',
        auditLogKey: 'login',
      },
      {
        auditLogLabel: 'Log Out',
        auditLogKey: 'logout',
      },
      {
        auditLogLabel: 'Reset Password',
        auditLogKey: 'reset-password',
      },
      {
        auditLogLabel: 'Password Change',
        auditLogKey: 'password-change',
      },
    ],
  },
  {
    label: 'User Panel Activity',
    key: 'user-panel-activity',
    values: [
      {
        auditLogLabel: 'Create',
        auditLogKey: 'create-user',
      },
      {
        auditLogLabel: 'Edit',
        auditLogKey: 'edit-user',
      },
      {
        auditLogLabel: 'Edit Confirm',
        auditLogKey: 'edit-user-confirmation',
      },
      {
        auditLogLabel: 'Delete',
        auditLogKey: 'delete-user',
      },
    ],
  },
  {
    label: 'Interview Assignment',
    key: 'interview-assignment',
    values: [
      {
        auditLogLabel: 'Interview Assigned',
        auditLogKey: 'interviewassignedgraph',
      },
    ],
  },
  {
    label: 'Selection Board',
    key: 'selection-board',
    values: [
      {
        auditLogLabel: 'Selection Board Create',
        auditLogKey: 'selectionboard-agenda-create',
      },
      {
        auditLogLabel: 'Selection Board Update',
        auditLogKey: 'selectionboard-agenda-update',
      },
    ],
  },
  {
    label: 'GBA Records',
    key: 'gba-records',
    values: [
      {
        auditLogLabel: 'GBA Create',
        auditLogKey: 'gba-create',
      },
      {
        auditLogLabel: 'GBA Update',
        auditLogKey: 'gba-update',
      },
    ],
  },
  {
    label: 'File Upload',
    key: 'file-upload',
    values: [
      {
        auditLogLabel: 'Reports Tab File Upload',
        auditLogKey: 'reports-tab-file-upload',
      },
      {
        auditLogLabel: 'Peer File Upload',
        auditLogKey: 'peer-file-upload',
      },
      {
        auditLogLabel: 'PSAC File Upload',
        auditLogKey: 'psac-file-upload',
      },
      {
        auditLogLabel: 'Statistics File Upload',
        auditLogKey: 'statistics-file-upload',
      },
      {
        auditLogLabel: 'YTD File Upload',
        auditLogKey: 'ytd-file-upload',
      },
      {
        auditLogLabel: 'Psychological Report Upload',
        auditLogKey: 'psychological-report-upload',
      },
      {
        auditLogLabel: 'Reports Tab File Deleted',
        auditLogKey: 'reports-tab-file-deleted',
      },
    ],
  },
  {
    label: 'Video Presentation',
    key: 'video-presentation',
    values: [
      {
        auditLogLabel: 'Evaluation Create',
        auditLogKey: 'video-candidate-eval-create',
      },
      {
        auditLogLabel: 'Evaluation Update',
        auditLogKey: 'video-candidate-eval-update',
      },
    ],
  },
  {
    label: 'Secretary Comments',
    key: 'secretary-comments',
    values: [
      {
        auditLogLabel: 'PSSB Create',
        auditLogKey: 'pssb-secy-comments-create',
      },
      {
        auditLogLabel: 'PSSB Update',
        auditLogKey: 'pssb-secy-comments-update',
      },
      {
        auditLogLabel: 'PSSB Delete',
        auditLogKey: 'pssb-secy-comments-delete',
      },
      {
        auditLogLabel: 'PS Create',
        auditLogKey: 'ps-secy-comments-create',
      },
      {
        auditLogLabel: 'PS Update',
        auditLogKey: 'ps-secy-comments-update',
      },
      {
        auditLogLabel: 'PS Delete',
        auditLogKey: 'ps-secy-comments-delete',
      },
    ],
  },
  {
    label: 'Evaluation Form Submission',
    key: 'evaluation-form',
    values: [
      {
        auditLogLabel: 'PSSB Create',
        auditLogKey: 'pssb-eval-create',
      },
      {
        auditLogLabel: 'PSSB Update',
        auditLogKey: 'pssb-eval-update',
      },
      {
        auditLogLabel: 'PS Create',
        auditLogKey: 'ps-eval-create',
      },
      {
        auditLogLabel: 'PS Update',
        auditLogKey: 'ps-eval-update',
      },
    ],
  },
  {
    label: 'Private Comments',
    key: 'private-comments',
    values: [
      {
        auditLogLabel: 'PSSB Create',
        auditLogKey: 'pssb-comments-create',
      },
      {
        auditLogLabel: 'PSSB Update',
        auditLogKey: 'pssb-comments-update',
      },
      {
        auditLogLabel: 'PSSB Delete',
        auditLogKey: 'pssb-comments-delete',
      },
      {
        auditLogLabel: 'PS Create',
        auditLogKey: 'ps-comments-create',
      },
      {
        auditLogLabel: 'PS Update',
        auditLogKey: 'ps-comments-update',
      },
      {
        auditLogLabel: 'PS Delete',
        auditLogKey: 'ps-comments-delete',
      },
    ],
  },
  {
    label: 'CMS Meetings',
    key: 'cms-meetings',
    values: [
      {
        auditLogLabel: 'Meeting Create',
        auditLogKey: 'cms-meeting-creation',
      },
      {
        auditLogLabel: 'Meeting Update',
        auditLogKey: 'cms-meeting-updation',
      },
      {
        auditLogLabel: 'Meeting Cancel',
        auditLogKey: 'cms-meeting-cancellation',
      },
      {
        auditLogLabel: 'Meeting Delete',
        auditLogKey: 'cms-meeting-deletion',
      },
    ],
  },
];

const AuditLogFilterFacets = (props) => {
  const {
    leftFilterValues = [],
    handleOnChangeCheckboxFilter,
    leftFilterType,
  } = props || {};

  return (
    <Tile>
      <div className="font-weight-bold mb-2">Filters</div>
      {filterData &&
        Array.isArray(filterData) &&
        filterData.map((filter, filterIdx) => {
          const { label, values, key } = filter || {};
          return (
            <div className="">
              <Accordion className="">
                <AccordionItem
                  open={key === 'logged-info'}
                  title={<h5 className="mt-1 text-dark">{label}</h5>}>
                  {values.map((val, idx) => {
                    const { auditLogLabel, auditLogKey } = val || {};
                    return (
                      <Checkbox
                        id={`${idx}-${auditLogKey}`}
                        key={`${idx}-${auditLogKey}`}
                        name={`${idx}-${auditLogKey}`}
                        labelText={
                          <div className="h6 font-weight-light">
                            {auditLogLabel}
                          </div>
                        }
                        onChange={(e) =>
                          handleOnChangeCheckboxFilter(
                            e,
                            filterData,
                            filterIdx,
                            idx,
                            auditLogKey
                          )
                        }
                        checked={
                          leftFilterValues?.[filterIdx]?.[idx] ||
                          (leftFilterType === 'login' &&
                            auditLogKey === 'login')
                        }
                      />
                    );
                  })}
                </AccordionItem>
              </Accordion>
            </div>
          );
        })}
    </Tile>
  );
};

export default AuditLogFilterFacets;
