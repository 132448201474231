import callApi from './callApi';

//Common urls for api calls
import { baseUrl, regularUrl } from '../utils/config';

//Action types for all api calls
import {
  REQUEST_GET_BATCH_DAILY_REPORTS,
  REQUEST_UPLOAD_BATCH_STATISTICS,
  REQUEST_UPLOAD_PREVIOUS_BATCH_STATISTICS,
  REQUEST_UPDATE_CUSTOM_STATISTICS,
  REQUEST_LAST_UPLOADED_HISTORY,
  REQUEST_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS
} from './actionTypes';

//Get call for batch list information
const getBatchInfoDailyReports = (year) => (dispatch) => {
  const url = `${baseUrl}/jobs/dailyReports?_=${new Date().getTime()}&year=${year}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_BATCH_DAILY_REPORTS,
  };
  return callApi(params);
};

const uploadBatchStatistics = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/importStatistics?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_BATCH_STATISTICS,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const uploadPreviousBatchStatistics = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/importPreviousRecords?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_PREVIOUS_BATCH_STATISTICS,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const uploadPreviousYTDStatistics = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/importYTDMapping?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const updateCustomStatistics = (data) => (dispatch) => {
  const url = `${baseUrl}/customstatistics?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPDATE_CUSTOM_STATISTICS,
    url,
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
    method: 'POST',
  };
  return callApi(params);
};

//Get call for batch list information
const getLastUploadedHistory = () => (dispatch) => {
  const url = `${baseUrl}/statisticsuploadhistory?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_LAST_UPLOADED_HISTORY,
  };
  return callApi(params);
};

export {
  getBatchInfoDailyReports,
  uploadBatchStatistics,
  uploadPreviousBatchStatistics,
  updateCustomStatistics,
  getLastUploadedHistory,
  uploadPreviousYTDStatistics
};
