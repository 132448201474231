import callApi from './callApi';

import { v2BaseUrl } from '../utils/config';

import {
  REQUEST_GET_CMS_MEETINGS,
  REQUEST_GET_CMS_MEETING_BY_ID,
  REQUEST_CREATE_CMS_MEETING,
  REQUEST_DELETE_CMS_MEETING_BY_ID,
  REQUEST_UPLOAD_CMS_MEETINGS_DATA,
  REQUEST_GET_COMM_MEM_BY_ID,
  REQUEST_DOWNLOAD_MEETING_TEMP,
  REQUEST_EXPORT_CMS_MEETINGS,
  REQUEST_BULK_DELETE_CMS_MEETING_BY_IDS,
  REQUEST_GET_MEETINGS_REPORT,
} from './actionTypes';

const upsertCMSMeeting = (data) => (dispatch) => {
  const { meetingId } = (data && data?.[0]) || {};
  const url = `${v2BaseUrl}/meeting`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: meetingId ? 'PATCH' : 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_CMS_MEETING,
  };

  return callApi(params);
};

const getCMSMeetings = (data) => (dispatch) => {
  const {
    activePage = 1,
    itemsPerPage = 10,
    searchKey,
    startDate,
    endDate,
    exportData,
    meetingTypeList,
    meetingStatusList,
  } = data || {};

  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/meeting?_=${new Date().getTime()}`;
  url += `&limit=${itemsPerPage}`;
  url += `&skip=${skip}`;

  if (startDate) {
    url += `&startTime=${startDate}`;
  }
  if (endDate) {
    url += `&endTime=${endDate}`;
  }

  if (searchKey) {
    url += `&searchKey="${searchKey}"`;
  }

  if (
    meetingTypeList &&
    Array.isArray(meetingTypeList) &&
    meetingTypeList.length > 0
  ) {
    url += `&meetingType=[${meetingTypeList.map((itm) => `"${itm}"`)}]`;
  }

  if (
    meetingStatusList &&
    Array.isArray(meetingStatusList) &&
    meetingStatusList.length > 0
  ) {
    url += `&status=[${meetingStatusList.map((itm) => `"${itm}"`)}]`;
  }

  if (exportData) {
    url += `&export=${exportData}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CMS_MEETINGS,
  };
  return callApi(params);
};

const exportCMSMeetings = (data) => (dispatch) => {
  const {
    activePage = 1,
    itemsPerPage = 10,
    searchKey,
    startDate,
    endDate,
    exportData,
    meetingTypeList,
    meetingStatusList,
  } = data || {};

  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/meeting/export?_=${new Date().getTime()}`;
  url += `&limit=${itemsPerPage}`;
  url += `&skip=${skip}`;

  if (startDate) {
    url += `&startTime=${startDate}`;
  }
  if (endDate) {
    url += `&endTime=${endDate}`;
  }

  if (searchKey) {
    url += `&searchKey="${searchKey}"`;
  }

  if (
    meetingTypeList &&
    Array.isArray(meetingTypeList) &&
    meetingTypeList.length > 0
  ) {
    url += `&meetingType=[${meetingTypeList.map((itm) => `"${itm}"`)}]`;
  }

  if (
    meetingStatusList &&
    Array.isArray(meetingStatusList) &&
    meetingStatusList.length > 0
  ) {
    url += `&status=[${meetingStatusList.map((itm) => `"${itm}"`)}]`;
  }

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_EXPORT_CMS_MEETINGS,
  };
  return callApi(params);
};

const getCMSMeetingById =
  ({ meetingIds }) =>
  (dispatch) => {
    let url = `${v2BaseUrl}/meeting?meetingIds=[${meetingIds}]&_=${new Date().getTime()}`;
    const params = {
      headers: {
        'content-type': 'application/json',
      },
      dispatch,
      method: 'GET',
      url,
      actionType: REQUEST_GET_CMS_MEETING_BY_ID,
    };
    return callApi(params);
  };

const deletCMSMeetingById = (meetingId) => (dispatch) => {
  if (!meetingId) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${v2BaseUrl}/meeting/${meetingId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_CMS_MEETING_BY_ID,
  };
  return callApi(params);
};

const bulkDeletCMSMeetingByIds = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/meeting`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
    actionType: REQUEST_BULK_DELETE_CMS_MEETING_BY_IDS,
  };
  return callApi(params);
};

const uploadCMSMeetingsData = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/meeting/fileUpload?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_CMS_MEETINGS_DATA,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const getCommissionMemByRoleId = (roleIds, noLimit) => (dispatch) => {
  let url = `${v2BaseUrl}/userinfo?_=${new Date().getTime()}&roleIds=[${roleIds}]`;
  if (noLimit) {
    url += `&noLimit=${noLimit}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_COMM_MEM_BY_ID,
  };
  return callApi(params);
};

const downloadMetingTemplate = () => (dispatch) => {
  let url = `${v2BaseUrl}/meeting/template?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_DOWNLOAD_MEETING_TEMP,
  };
  return callApi(params);
};

const getMeetingsReportInfo = (data) => (dispatch) => {
  const {
    activePage = 1,
    itemsPerPage = 10,
    searchKey,
    reportType,
    attendanceStatus,
    meetingStatus,
    meetingType,
    userIds,
    startDate,
    endDate,
    exportData, 
    panelUserIds,
    excludedPanelUserIds,
  } = data || {};

  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/meeting/report?_=${new Date().getTime()}`;
  url += `limit=${itemsPerPage}`;
  url += `skip=${skip}`;
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }

  if (reportType) {
    url += `&reportType=${reportType}`;
  }

  if (startDate) {
    url += `&startTime=${startDate}`;
  }

  if (endDate) {
    url += `&endTime=${endDate}`;
  }

  if (
    attendanceStatus &&
    Array.isArray(attendanceStatus) &&
    attendanceStatus.length > 0
  ) {
    url += `&attendanceStatus=[${attendanceStatus.map((itm) => `"${itm}"`)}]`;
  }

  if (
    meetingStatus &&
    Array.isArray(meetingStatus) &&
    meetingStatus.length > 0
  ) {
    url += `&meetingStatus=[${meetingStatus.map((itm) => `"${itm}"`)}]`;
  }

  if (meetingType && Array.isArray(meetingType) && meetingType.length > 0) {
    url += `&meetingType=[${meetingType.map((itm) => `"${itm}"`)}]`;
  }

  if (userIds && Array.isArray(userIds) && userIds.length > 0) {
    url += `&userIds=[${userIds.map((itm) => `"${itm}"`)}]`;
  }

  if (panelUserIds && Array.isArray(panelUserIds) && panelUserIds.length > 0) {
    url += `&panelUserIds=[${panelUserIds.map((itm) => `"${itm}"`)}]`;
  }

  if (
    excludedPanelUserIds &&
    Array.isArray(excludedPanelUserIds) &&
    excludedPanelUserIds.length > 0
  ) {
    url += `&excludedPanelUserIds=[${excludedPanelUserIds.map(
      (itm) => `"${itm}"`
    )}]`;
  }

  if (exportData) {
    url += `&export=${exportData}`;
  }

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_MEETINGS_REPORT,
  };
  return callApi(params);
};

export {
  getCMSMeetings,
  upsertCMSMeeting,
  exportCMSMeetings,
  getCMSMeetingById,
  deletCMSMeetingById,
  uploadCMSMeetingsData,
  getCommissionMemByRoleId,
  downloadMetingTemplate,
  bulkDeletCMSMeetingByIds,
  getMeetingsReportInfo,
};
