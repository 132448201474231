import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  SideNav,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SideNavMenuItem,
} from 'carbon-components-react';

class SideNavListMock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isSideNavExpanded } = this.props;
    return (
      <Fragment>
        <SideNav aria-label="Side navigation" expanded={isSideNavExpanded}>
          <SideNavItems>
            <SideNavMenuItem
              className="labels-color"
              element={Link}
              to="/public/dashboard/1"
              href="javascript:void(0)">
              Dashboard
            </SideNavMenuItem>
            <SideNavMenuItem
              className="labels-color"
              element={Link}
              to="/public/batchinfo"
              href="javascript:void(0)">
              Batch
            </SideNavMenuItem>
            <SideNavMenuItem
              className="labels-color"
              element={Link}
              to="/public/list/candidate/search"
              href="javascript:void(0)">
              Search Candidates
            </SideNavMenuItem>
            <SideNavMenuItem
              className="labels-color"
              element={Link}
              to="/public/list/candidate"
              href="javascript:void(0)">
              Candidates
            </SideNavMenuItem>
          </SideNavItems>
        </SideNav>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
  taskStatus: state.taskStatus,
});

export default connect(mapStateToProps, {})(SideNavListMock);
