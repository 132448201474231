import {
  REQUEST_GET_BATCH_DAILY_REPORTS,
  SUCCESS_GET_BATCH_DAILY_REPORTS,
  ERROR_GET_BATCH_DAILY_REPORTS,
  REQUEST_UPLOAD_BATCH_STATISTICS,
  SUCCESS_UPLOAD_BATCH_STATISTICS,
  ERROR_UPLOAD_BATCH_STATISTICS,
  REQUEST_UPLOAD_PREVIOUS_BATCH_STATISTICS,
  SUCCESS_UPLOAD_PREVIOUS_BATCH_STATISTICS,
  ERROR_UPLOAD_PREVIOUS_BATCH_STATISTICS,
  REQUEST_UPDATE_CUSTOM_STATISTICS,
  SUCCESS_UPDATE_CUSTOM_STATISTICS,
  ERROR_UPDATE_CUSTOM_STATISTICS,
  REQUEST_LAST_UPLOADED_HISTORY,
  SUCCESS_LAST_UPLOADED_HISTORY,
  ERROR_LAST_UPLOADED_HISTORY,
  REQUEST_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS,
  SUCCESS_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS,
  ERROR_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  dailyReports: {},
  lastUpdatedAt: {},
};

const BatchInfo = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_BATCH_DAILY_REPORTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_BATCH_DAILY_REPORTS:
      const { dailyReport } = payload || {};
      const { pieChart } = dailyReport || {};
      return {
        ...state,
        dailyReports: pieChart,
        loading: false,
        error: null,
      };
    case ERROR_GET_BATCH_DAILY_REPORTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_BATCH_STATISTICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_BATCH_STATISTICS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_BATCH_STATISTICS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_PREVIOUS_BATCH_STATISTICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_PREVIOUS_BATCH_STATISTICS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_PREVIOUS_BATCH_STATISTICS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_CUSTOM_STATISTICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_CUSTOM_STATISTICS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPDATE_CUSTOM_STATISTICS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_LAST_UPLOADED_HISTORY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_LAST_UPLOADED_HISTORY:
      return {
        ...state,
        lastUpdatedAt: payload,
        loading: false,
        error: null,
      };
    case ERROR_LAST_UPLOADED_HISTORY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
      case REQUEST_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case SUCCESS_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS:
        return {
          ...state,
          lastUpdatedAt: payload,
          loading: false,
          error: null,
        };
      case ERROR_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS:
        return {
          ...state,
          loading: false,
          error: payload.error,
        };
    default:
      return state;
  }
};

export default BatchInfo;
