import {
  REQUEST_GET_CANDIDATE_NOTES,
  SUCCESS_GET_CANDIDATE_NOTES,
  ERROR_GET_CANDIDATE_NOTES,
  REQUEST_CREATE_NEW_CANDIDATE_NOTE,
  SUCCESS_CREATE_NEW_CANDIDATE_NOTE,
  ERROR_CREATE_NEW_CANDIDATE_NOTE,
  REQUEST_UPSERT_CANDIDATE_NOTES,
  SUCCESS_UPSERT_CANDIDATE_NOTES,
  ERROR_UPSERT_CANDIDATE_NOTES,
  REQUEST_DELETE_CANDIDATE_NOTES,
  SUCCESS_DELETE_CANDIDATE_NOTES,
  ERROR_DELETE_CANDIDATE_NOTES,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  candidateNotes: [],
};

const CandidateNotes = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_CANDIDATE_NOTES:
      return {
        ...state,

        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_NOTES:
      return {
        ...state,
        candidateNotes: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_NOTES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_NEW_CANDIDATE_NOTE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_NEW_CANDIDATE_NOTE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_NEW_CANDIDATE_NOTE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_CANDIDATE_NOTES:
      return {
        ...state,

        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_CANDIDATE_NOTES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_CANDIDATE_NOTES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_CANDIDATE_NOTES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_CANDIDATE_NOTES:
      return {
        ...state,
        list: payload,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_CANDIDATE_NOTES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default CandidateNotes;
