import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ToggleSmall,
  Select,
  SelectItem,
  TextInput,
  FileUploaderButton,
  Tile,
} from 'carbon-components-react';
import {
  getAsessmentScenarios,
  updatetAsessmentScenarios,
  uploadSchoolsList,
  uploadEmailDomainsList,
  getUploadedEmailDomains,
} from '../../../actions/Assessments';

import BackRow from '../../../components/common/BackRow';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import GenericTabs from '../../../components/common/GenericTabs';
import SampleSchoolsTemplate from '../../../assets/files/Schools_Organisations_List_Template.xlsx';
import SampleEmailDomainsTemplate from '../../../assets/files/Email_Domains_List_Template.xlsx';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './AssessSettings.css';
import GenericDataTable from '../../../components/common/GenericDataTable';
import NoContentBox from '../../../components/common/NoContentBox';

class AssessSettings extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'assess-settings', name: 'Assessments Settings', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  componentDidMount() {
    this.props.getAsessmentScenarios();
  }

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleToggleClick = (e, scenarioId, testName) => {
    this.setState({ [`${scenarioId}-${testName}`]: e });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitAssessSettings = () => {
    const { assessScenarios } = this.props;
    const data = [];
    if (
      assessScenarios &&
      Array.isArray(assessScenarios) &&
      assessScenarios.length > 0
    ) {
      const paeScenarioObj = assessScenarios.find(
        (itm) => itm?.scenario == 'PAE'
      );
      const {
        scenarioId: paeScenarioId,
        isAccessCodeValid: paeIsAccessCodeValid,
        accessCode: paeAccessCode,
        enableExpiry: paeEnableExpiry,
        expiryDate,
      } = paeScenarioObj || {};
      const isAccessCodeValid =
        this.state[`${paeScenarioId}-isAccessCodeValid`] ??
        paeIsAccessCodeValid;
      const accesscodeInput =
        this.state[`${paeScenarioId}-accessCode`] ?? paeAccessCode;
      const isAccessCodeInputInvalid = isAccessCodeValid
        ? accesscodeInput == null || accesscodeInput?.trim()?.length <= 0
        : false;

      const enableExpiry =
        this.state[`${paeScenarioId}-enableExpiry`] ?? paeEnableExpiry;
      const expiryDateInput =
        this.state[`${paeScenarioId}-expiryDate`] ?? expiryDate;
      const isExpiryDateInvalid = enableExpiry
        ? expiryDateInput == null
        : false;

      this.setState({
        [`${paeScenarioId}-accessCode-isInvalid`]: isAccessCodeInputInvalid,
        [`${paeScenarioId}-expiryDate-isInvalid`]: isExpiryDateInvalid,
      });

      if (isAccessCodeInputInvalid || isExpiryDateInvalid) {
        return;
      }

      assessScenarios.forEach((assessScenarioObj) => {
        const { scenarioId } = assessScenarioObj || {};
        const obj = {};
        for (const [key, value] of Object.entries(assessScenarioObj)) {
          if (this.state[`${scenarioId}-${key}`] != null) {
            obj[key] = this.state[`${scenarioId}-${key}`];
          } else {
            obj[key] = assessScenarioObj[key];
          }
        }
        data.push(obj);
      });
    }

    this.props
      .updatetAsessmentScenarios(data)
      .then((res) => {
        if (res && !res.error) {
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Settings have been updated successfully',
            },
            () => {
              setTimeout(() => {
                this.handleCloseNotifBtn();
              }, 5000);
            }
          );
        }
      })
      .catch((error) => {
        console.log('Error', error);
        this.setState({
          errorNotif: true,
          successNotif: false,
        });
      });
  };

  onDatePickerChange = (date, scenarioId, testName) => {
    this.setState({ [`${scenarioId}-${testName}`]: date });
  };

  activeURLSettingsModal = (
    scenarioId,
    displayName,
    accessCode,
    isAccessCodeValid
  ) => {
    this.setState({
      isOpenSettingsModal: true,
      currScenarioId: scenarioId,
      currScenarioName: displayName,
      currAccessCode: accessCode,
      currIsAccessCodeValid: isAccessCodeValid,
    });
  };

  dismissURLSettingsModal = () => {
    this.setState({ isOpenSettingsModal: false });
  };

  handleGetEmailDomains = () => {
    this.props.getUploadedEmailDomains().then((res) => {
      if (res && !res.error) {
        const { allowed3rdPartyDomains } = res || {};
        this.setState({ emailDomainsList: allowed3rdPartyDomains || [] });
      }
    });
  };

  handleTabClick = (tab) => {
    this.setState(
      {
        selectedTab: tab,
      },
      () => {
        const { selectedTab } = this.state;
        if (selectedTab === 2) {
          this.handleGetEmailDomains();
        }
      }
    );
  };

  handleUploadSchoolsList = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('excel', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadSchoolsList(currFile)
        .then((res) => {
          if (res && !res.error) {
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleUploadEmailDomainsList = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('attachment', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadEmailDomainsList(currFile)
        .then((res) => {
          if (res && !res.error) {
            this.handleGetEmailDomains();
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  render() {
    const { loading, assessScenarioLocations, assessScenarios } = this.props;
    const {
      successNotif,
      errorNotif,
      notifMsg,
      isExpiryDateInvalid,
      selectedTab,
      emailDomainsList,
    } = this.state;
    return (
      <div>
        <BackRow paths={this.paths} />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        <div className="mb-3">
          <GenericTabs
            selected={selectedTab}
            tabContent={[
              {
                label: 'Scenario Settings',
                value: '',
                onClick: () => {
                  this.handleTabClick(0);
                },
              },
              {
                label: 'Upload Schools/Org',
                value: '',
                onClick: () => {
                  this.handleTabClick(1);
                },
              },
              {
                label: 'Upload Email Domains',
                value: '',
                onClick: () => {
                  this.handleTabClick(2);
                },
              },
            ]}
          />
        </div>

        {selectedTab === 0 && (
          <div>
            <table className="table-border w-100 table-zoom-auto">
              <thead className="text-center heading-align">
                <th className=" css-s-no-w font-size-x-lg">No.</th>
                <th className=" font-size-x-lg " style={{ width: '12rem' }}>
                  <div className="font-size-x-lg">Scenario</div>
                </th>
                <th className=" results-min-width">
                  <div>uCognify</div>
                </th>
                <th className=" results-min-width">
                  <div>Send Indiv NEO Report</div>
                </th>
                <th className=" results-min-width">
                  <div>CPI</div>
                </th>
                <th className=" results-min-width">
                  <div>LEAP</div>
                </th>
                <th className=" results-min-width">
                  <div>ARS Video</div>
                </th>
                <th className=" results-min-width">
                  <div>Location</div>
                </th>
                <th className=" results-min-width">
                  <div>PSC Applicant Feedback</div>
                </th>
                <th className=" results-min-width">
                  <div>Customize Applicant Feedback</div>
                </th>
                {/* <th className=" results-min-width">
                  <div>Test Result Validity</div>
                </th> */}
              </thead>
              <tbody className="table-border">
                {assessScenarios &&
                  Array.isArray(assessScenarios) &&
                  assessScenarios.map((res, idx) => {
                    const {
                      scenarioId,
                      scenario,
                      displayName,
                      scenarioType,
                      ucognify,
                      neoProfile,
                      cpi,
                      leap,
                      arsVideo,
                      location,
                      feedback,
                      customizeFeedback,
                      validity,
                      accessCode,
                      isAccessCodeValid,
                      enableExpiry,
                      expiryDate,
                    } = res || {};
                    return (
                      <tr className="text-left">
                        <td>{scenarioId}</td>
                        <td>
                          <div>
                            <div className="d-flex font-weight-bold mb-2 h5">
                              {displayName}{' '}
                            </div>
                            {Number(scenarioId) == 5 && (
                              <>
                                <div className="ml-1 bx--row align-items-center">
                                  <div className="">Link validity</div>
                                  <div
                                    className=""
                                    style={{
                                      marginLeft: '1.1rem',
                                      marginTop: '-1rem',
                                    }}>
                                    <ToggleSmall
                                      id={`${scenarioId}-isAccessCodeValid`}
                                      name={`${scenarioId}-isAccessCodeValid`}
                                      className=""
                                      labelText=""
                                      labelA="Inactive"
                                      labelB="Active"
                                      toggled={
                                        this.state[
                                          `${scenarioId}-isAccessCodeValid`
                                        ] ?? isAccessCodeValid
                                      }
                                      onToggle={(e) =>
                                        this.handleToggleClick(
                                          e,
                                          scenarioId,
                                          'isAccessCodeValid'
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="ml-1 mt-2 bx--row align-items-center">
                                  <div className="">Access Code</div>
                                  <div className="ml-2 w-50">
                                    <TextInput
                                      size="sm"
                                      id={`${scenarioId}-accessCode`}
                                      name={`${scenarioId}-accessCode`}
                                      labelText=""
                                      placeholder="Enter Access Code"
                                      onChange={this.handleChange}
                                      value={
                                        this.state[
                                          `${scenarioId}-accessCode`
                                        ] ?? accessCode
                                      }
                                      invalid={
                                        this.state[
                                          `${scenarioId}-accessCode-isInvalid`
                                        ]
                                      }
                                      invalidText="Please enter access code"
                                    />
                                  </div>
                                </div>
                                <hr />
                                <div className="ml-1 mt-2 bx--row align-items-center">
                                  <div className="">Enable Expiry</div>
                                  <div
                                    className=""
                                    style={{
                                      marginLeft: '1.1rem',
                                      marginTop: '-1rem',
                                    }}>
                                    <ToggleSmall
                                      id={`${scenarioId}-enableExpiry`}
                                      name={`${scenarioId}-enableExpiry`}
                                      className=""
                                      labelText=""
                                      labelA="No"
                                      labelB="Yes"
                                      toggled={
                                        this.state[
                                          `${scenarioId}-enableExpiry`
                                        ] ?? enableExpiry
                                      }
                                      onToggle={(e) =>
                                        this.handleToggleClick(
                                          e,
                                          scenarioId,
                                          'enableExpiry'
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="ml-1 mt-2 bx--row align-items-center">
                                  <div className="">Expiry date</div>
                                  <div className="ml-3">
                                    <DatePicker
                                      selected={
                                        this.state[
                                          `${scenarioId}-expiryDate`
                                        ] ??
                                        (expiryDate && new Date(expiryDate))
                                      }
                                      name={`${scenarioId}-expiryDate`}
                                      id={`${scenarioId}-expiryDate`}
                                      onChange={(date) =>
                                        this.onDatePickerChange(
                                          date,
                                          scenarioId,
                                          'expiryDate'
                                        )
                                      }
                                      className={`expiry-date-input ${
                                        this.state[
                                          `${scenarioId}-expiryDate-isInvalid`
                                        ]
                                          ? 'xpa-invalid'
                                          : ''
                                      }`}
                                      dateFormat="dd/MM/yyyy"
                                      popperPlacement="top-start"
                                      autoComplete="off"
                                    />
                                    {this.state[
                                      `${scenarioId}-expiryDate-isInvalid`
                                    ] && (
                                      <div className="xpa-invalid-text">
                                        Please select expiry date
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                        <td className=" csc-radio-btn-m">
                          <ToggleSmall
                            id={`${scenarioId}-ucognify`}
                            className="toggle-remv-margin mt-2"
                            labelText=""
                            labelA="No"
                            labelB="Yes"
                            toggled={
                              this.state[`${scenarioId}-ucognify`] ?? ucognify
                            }
                            onToggle={(e) =>
                              this.handleToggleClick(e, scenarioId, 'ucognify')
                            }
                          />
                        </td>
                        <td className=" csc-radio-btn-m">
                          <ToggleSmall
                            id={`${scenarioId}-neoProfile`}
                            className="toggle-remv-margin mt-2"
                            labelText=""
                            labelA="No"
                            labelB="Yes"
                            toggled={
                              this.state[`${scenarioId}-neoProfile`] ??
                              neoProfile
                            }
                            onToggle={(e) =>
                              this.handleToggleClick(
                                e,
                                scenarioId,
                                'neoProfile'
                              )
                            }
                          />
                        </td>
                        <td className=" csc-radio-btn-m">
                          <ToggleSmall
                            id={`${scenarioId}-cpi`}
                            className="toggle-remv-margin mt-2"
                            labelText=""
                            labelA="No"
                            labelB="Yes"
                            toggled={this.state[`${scenarioId}-cpi`] ?? cpi}
                            onToggle={(e) =>
                              this.handleToggleClick(e, scenarioId, 'cpi')
                            }
                          />
                        </td>
                        <td className=" csc-radio-btn-m">
                          <ToggleSmall
                            id={`${scenarioId}-leap`}
                            className="toggle-remv-margin mt-2"
                            labelText=""
                            labelA="No"
                            labelB="Yes"
                            toggled={this.state[`${scenarioId}-leap`] ?? leap}
                            onToggle={(e) =>
                              this.handleToggleClick(e, scenarioId, 'leap')
                            }
                          />
                        </td>
                        <td className=" csc-radio-btn-m">
                          <ToggleSmall
                            id={`${scenarioId}-arsVideo`}
                            className="toggle-remv-margin mt-2"
                            labelText=""
                            labelA="No"
                            labelB="Yes"
                            toggled={
                              this.state[`${scenarioId}-arsVideo`] ?? arsVideo
                            }
                            onToggle={(e) =>
                              this.handleToggleClick(e, scenarioId, 'arsVideo')
                            }
                          />
                        </td>
                        <td className=" csc-radio-btn-m">
                          <Select
                            labelText=""
                            id={`${scenarioId}-location`}
                            name={`${scenarioId}-location`}
                            value={
                              this.state[`${scenarioId}-location`] ?? location
                            }
                            onChange={this.handleChange}>
                            <SelectItem disabled text="Select" value="sel" />
                            {assessScenarioLocations &&
                              Array.isArray(assessScenarioLocations) &&
                              assessScenarioLocations.map((itm) => {
                                const { key, value } = itm || {};
                                return <SelectItem text={key} value={value} />;
                              })}
                          </Select>
                        </td>
                        <td className=" csc-radio-btn-m">
                          <ToggleSmall
                            id={`${scenarioId}-feedback`}
                            className="toggle-remv-margin mt-2"
                            labelText=""
                            labelA="No"
                            labelB="Yes"
                            toggled={
                              this.state[`${scenarioId}-feedback`] ?? feedback
                            }
                            onToggle={(e) =>
                              this.handleToggleClick(e, scenarioId, 'feedback')
                            }
                          />
                        </td>
                        <td className=" csc-radio-btn-m">
                          <ToggleSmall
                            id={`${scenarioId}-customizeFeedback`}
                            className="toggle-remv-margin mt-2"
                            labelText=""
                            labelA="No"
                            labelB="Yes"
                            toggled={
                              this.state[`${scenarioId}-customizeFeedback`] ??
                              customizeFeedback
                            }
                            onToggle={(e) =>
                              this.handleToggleClick(
                                e,
                                scenarioId,
                                'customizeFeedback'
                              )
                            }
                          />
                        </td>
                        {/* <DatePicker
                          selected={
                            this.state[`${scenarioId}-endDate`] ??
                            (endDate && new Date(endDate)) ??
                            new Date()
                          }
                          name={`${scenarioId}-endDate`}
                          id={`${scenarioId}-endDate`}
                          onChange={(date) =>
                            this.onDatePickerChange(date, scenarioId, 'endDate')
                          }
                          className="scenario-date-input"
                          dateFormat="dd/MM/yyyy"
                          popperPlacement="left-start"
                          // placeholderText="Start Time"
                        /> */}
                        {/* <td className="">
                          <div className="assess-date-inp-time">
                            <Select
                              labelText=""
                              id={`${scenarioId}-validity`}
                              name={`${scenarioId}-validity`}
                              value={
                                this.state[`${scenarioId}-validity`] ?? validity
                              }
                              onChange={this.handleChange}>
                              <SelectItem disabled text="Select" value="sel" />
                              <SelectItem text="1 year" value={1} />
                              <SelectItem text="2 years" value={2} />
                              <SelectItem text="3 years" value={3} />
                            </Select>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="mt-2 text-center">
              <Button
                kind="primary"
                size="small"
                onClick={this.handleSubmitAssessSettings}>
                Submit
              </Button>
            </div>
          </div>
        )}

        {selectedTab === 1 && (
          <Tile>
            <h5 className="mb-2">Upload Schools/Organizations</h5>
            <hr />
            <div className="d-flex">
              <div className="text-right">
                <FileUploaderButton
                  buttonLabel="Upload"
                  labelText="Upload"
                  buttonKind="primary"
                  accept={['.xlsx']}
                  disableLabelChanges
                  name="file"
                  onChange={(e) => this.handleUploadSchoolsList(e)}
                />
                <div className="small mt-2">.xlsx format is allowed</div>
              </div>
              <div className="ml-2">
                <Button
                  className="txt-dec-none"
                  kind="primary"
                  href={SampleSchoolsTemplate}>
                  Download Template
                </Button>
              </div>
            </div>
          </Tile>
        )}

        {selectedTab === 2 && (
          <div>
            <Tile>
              <h5 className="mb-2">Upload Email Domains</h5>
              <hr />
              <div className="d-flex">
                <div className="text-right">
                  <FileUploaderButton
                    buttonLabel="Upload"
                    labelText="Upload"
                    buttonKind="primary"
                    accept={['.xlsx']}
                    disableLabelChanges
                    name="file"
                    onChange={(e) => this.handleUploadEmailDomainsList(e)}
                  />
                  <div className="small mt-2">.xlsx format is allowed</div>
                </div>
                <div className="ml-2">
                  <Button
                    className="txt-dec-none"
                    kind="primary"
                    href={SampleEmailDomainsTemplate}>
                    Download Template
                  </Button>
                </div>
              </div>
            </Tile>

            <div className="mt-3">
              {emailDomainsList &&
              Array.isArray(emailDomainsList) &&
              emailDomainsList.length > 0 ? (
                <GenericDataTable
                  className=""
                  tbodyClass="candidate-background-color"
                  headers={[
                    {
                      key: 'header_0',
                      header: 'S/No',
                      className: '',
                    },
                    {
                      key: 'header_1',
                      header: 'Email Domain',
                      className: 'w-75',
                    },
                  ]}
                  rows={
                    emailDomainsList.map((emailDomain, idx) => {
                      return {
                        isExpanded: true,
                        id: `row_${emailDomain}_${idx}`,
                        key: `row_${emailDomain}_${idx}`,
                        header_0: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              S/No:
                            </div>
                            <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {idx + 1}
                            </div>
                          </div>
                        ),
                        header_1: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Email Domain:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {emailDomain || '-'}
                            </div>
                          </div>
                        ),
                      };
                    }) || []
                  }
                  selectable={false}
                  searchable={false}
                  onSearchInputChange={() => {}}
                  expandable={false}
                  batchActions={false}
                />
              ) : (
                <>
                  <NoContentBox message="Currently there are no email domains." />
                </>
              )}
            </div>
          </div>
        )}
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
  assessScenarios: state.Assessments.assessScenarios,
  assessScenarioLocations: state.Assessments.assessScenarioLocations,
});

const mapDispatchToProps = {
  getAsessmentScenarios,
  updatetAsessmentScenarios,
  uploadSchoolsList,
  uploadEmailDomainsList,
  getUploadedEmailDomains,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessSettings);
