import React, { Component } from 'react';
import { connect } from 'react-redux';
import NoContentBox from '../../components/common/NoContentBox';
import { Accordion, AccordionItem, Modal } from 'carbon-components-react';
import CandidateGBAStenDetails from '../../components/private/CandidateGBAStenDetails';
import { getLEAPDetails } from '../../actions/CandidateInfo';
import {
  handleGetBehavioralIndicators,
  handleGetInterviewQuestions,
  handleGetTraitDefinition,
} from '../../components/private/GBAQuesDef';
import '../public/MockUi2.css';
import behavioralTraits from '../../components/private/static/BehavioralTraits';

class CandidateLEAPDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAccrVal: '',
    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  renderOnMount = () => {
    const { profileId } = this.props;
    // if (profileId) {
    //   this.props.getLEAPDetails(profileId);
    // }
  };

  handleOpenAccr = (name) => {
    this.setState({
      isOpenAccrVal: name,
    });
  };

  handleBarOnClick = (data) => {
    const { key } = data || {};
    this.setState({
      isOpenModal: true,
      definition: handleGetTraitDefinition(key),
      heading: key,
    });
  };

  getStyles = (res, stenScore, colourCode) => {
    if (res == stenScore) {
      return {
        background: colourCode,
      };
    }
  };

  getBehvIndWithStenScore = (indicatorsList, stenScore) => {
    return indicatorsList?.find((indicator) => {
      const { minScore, maxScore } = indicator || {};
      if (
        minScore &&
        maxScore &&
        stenScore >= minScore &&
        stenScore <= maxScore
      ) {
        return true;
      } else if (maxScore && stenScore == maxScore) {
        return true;
      }

      return false;
    });
  };

  render() {
    const { isOpenAccrVal } = this.state;
    const { leapScores } = this.props;
    const finalLeapScore =
      leapScores?.find((itm) => itm?.label?.toLowerCase() === 'leap')
        ?.stenScore || '-';

    return (
      <div>
        <div className="mt-2 h6 font-weight-bold">
          LEAP Score ({finalLeapScore})
        </div>
        <hr />
        {behavioralTraits &&
        Array.isArray(behavioralTraits) &&
        behavioralTraits.length > 0 ? (
          <Accordion className="css-gba-accr-z" align='start'>
            {behavioralTraits.map((data) => {
              const { trait, definition, behavioralIndicators } = data || {};
              const traitObj = leapScores?.find(
                (itm) => itm?.label?.toLowerCase() == trait?.toLowerCase()
              );
              const { colourCode, stenScore } = traitObj || {};
              const isStenScoreInRange =
                Number(stenScore ?? 0) > 0 && Number(stenScore ?? 0) < 11;

              const behvIndWithCurrStenScore = this.getBehvIndWithStenScore(
                behavioralIndicators,
                Number(stenScore ?? 0)
              );
              const {
                label,
                descPoints,
                interviewQuestions = [],
                toDo,
              } = behvIndWithCurrStenScore || {};

              return (
                <AccordionItem
                  className="gba-accor-content mt-2"
                  title={
                    <div className="bx--row align-items-center">
                      <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-5">
                        {trait}
                      </div>
                      <div className="d-flex justify-content-center mb-1 ml-3">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                          <div
                            className="csc-th--block css-gba-color mr-2"
                            style={this.getStyles(res, stenScore, colourCode)}>
                            <div className="csc-pos--top">{res}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  }>
                  <div>
                    <div>
                      <div className="d-flex mb-2 align-items-center">
                        <div className="font-weight-bold">Definition:</div>
                        <div className="ml-3">{definition}</div>
                      </div>
                    </div>
                    {isStenScoreInRange && (
                      <>
                        <div className="mt-2 mb-2">
                          <div className="font-weight-bold mb-2">
                            Behavioural Indicators
                          </div>
                          <div>
                            {descPoints &&
                              Array.isArray(descPoints) &&
                              descPoints.length > 0 && (
                                <ul>
                                  {descPoints.map((descPoint) => {
                                    return (
                                      <li
                                        className="mt-2"
                                        style={{ listStyle: 'inside' }}
                                        key={descPoint}>
                                        {descPoint}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                          </div>
                        </div>

                        <div>
                          <div className="font-weight-bold mb-2">
                            Interview Questions
                          </div>
                          <div>{toDo}</div>
                        </div>

                        {interviewQuestions &&
                          Array.isArray(interviewQuestions) &&
                          interviewQuestions.length > 0 &&
                          interviewQuestions.map((ques, idx) => {
                            return (
                              <div className="mt-1 css-gba-ques">
                                <span
                                  className="font-weight-bold"
                                  style={{ fontSize: '0.875rem' }}>
                                  {idx + 1}.
                                </span>
                                <span className="ml-2">{ques}</span>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                </AccordionItem>
              );
            })}
          </Accordion>
        ) : (
          <NoContentBox
            hideTile
            message="There are no LEAP results for your current selection"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  leapDetails: state.CandidateInfo.leapDetails,
});

const mapDispatchToProps = {
  getLEAPDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateLEAPDetails);
