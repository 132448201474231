import React, { Fragment } from 'react';
import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
} from 'carbon-components-react';

const BatchCountMock = (props) => {
  const { fullYear, hideMainContent } = props || {};
  return (
    <Fragment>
      <StructuredListWrapper
        ariaLabel="Batch"
        className="mb-0"
        selection={false}>
        <StructuredListHead>
          <StructuredListRow head label={false} tabIndex={0}>
            <StructuredListCell head noWrap={false}>
              <h4>Batch</h4>
            </StructuredListCell>
            <StructuredListCell head noWrap={false}>
              <h4 className="text-center"> Total to be interviewed</h4>
            </StructuredListCell>
            <StructuredListCell head noWrap={false}>
              <h4 className="text-center">Interviewed so far</h4>
            </StructuredListCell>
            <StructuredListCell head noWrap={false}>
              <h4 className="text-center">Offered</h4>
            </StructuredListCell>
            <StructuredListCell head noWrap={false}>
              <h4 className="text-center">Accepted</h4>
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        {hideMainContent !== true ? (
          <StructuredListBody>
            <StructuredListRow head={false} label={false} tabIndex={0}>
              <StructuredListCell head={false} noWrap>
                <div className="mr-1 bx--type-delta f-w-b font-weight-light xpa-link">
                  2021 (YTD)
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  300
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  75 (25%)
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  30 (40%)
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  5 (16%)
                </div>
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow head={false} label={false} tabIndex={0}>
              <StructuredListCell head={false} noWrap>
                <div className="mr-1 bx--type-delta f-w-b font-weight-light xpa-link">
                  2020 (YTD)
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  300
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  65 (21%)
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  33 (50%)
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  7 (21%)
                </div>
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow head={false} label={false} tabIndex={0}>
              <StructuredListCell head={false} noWrap>
                <div className="mr-1 bx--type-delta f-w-b font-weight-light xpa-link">
                  2020 (Full Year)
                </div>
              </StructuredListCell>
              <StructuredListCell
                head={false}
                noWrap={false}></StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  280
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  100
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  92
                </div>
              </StructuredListCell>
            </StructuredListRow>
          </StructuredListBody>
        ) : (
          <StructuredListBody>
            <StructuredListRow head={false} label={false} tabIndex={0}>
              <StructuredListCell head={false} noWrap>
                <div className="mr-1 bx--type-delta f-w-b font-weight-light xpa-link">
                  {fullYear} (Full Year)
                </div>
              </StructuredListCell>
              <StructuredListCell
                head={false}
                noWrap={false}></StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  280
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  100
                </div>
              </StructuredListCell>
              <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  92
                </div>
              </StructuredListCell>
            </StructuredListRow>
          </StructuredListBody>
        )}
      </StructuredListWrapper>
    </Fragment>
  );
};

export default BatchCountMock;
