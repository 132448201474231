import callApi from './callApi';

//Common urls for api calls
import { baseUrl, regularUrl } from '../utils/config';

//Action types for all api calls
import {
  REQUEST_GET_DASHBOARD_ANALYTICS,
  REQUEST_GET_SECOND_ROUNDERS,
  REQUEST_PBI_TOKENS
} from './actionTypes';

//Get call for dashboard analytics
const getDasboardStatistics = (year) => (dispatch) => {
  let url = `${baseUrl}/jobs/batchStatistics?_=${new Date().getTime()}&`;
  if (year) {
    url += `year=${year}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_DASHBOARD_ANALYTICS,
  };
  return callApi(params);
};

//Get call for dashboard analytics
const getSecondRounders = (data) => (dispatch) => {
  const { batchId, year, searchKey } = data || {};
  let url = `${baseUrl}/applications/secondrounders?_=${new Date().getTime()}`;
  if (batchId) {
    url += `&batchId=${batchId}`;
  }
  if (year) {
    url += `&year=${year}`;
  }
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_SECOND_ROUNDERS,
  };
  return callApi(params);
};

const getPBITokens = () => (dispatch) => {
  const url = `${baseUrl}/analytics/pbitokens?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_PBI_TOKENS,
    canUseToken: true,
  };
  return callApi(params);
};

export { getDasboardStatistics, getSecondRounders, getPBITokens };
