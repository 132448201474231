import React, { Fragment } from 'react';

//Common components
import NivoPieChart from '../../components/common/NivoPieChart';
import NoContentBox from '../../components/common/NoContentBox';
// Common Packages
import { TextInput, Tile } from 'carbon-components-react';
import moment from 'moment';

const getUniqueListBy = (arr, key) => {
  if (arr && Array.isArray(arr) && arr.length > 0) {
    return [
      ...new Map(
        arr.map((item) => [
          item && item[key] && item[key].toString().toLowerCase(),
          item,
        ])
      ).values(),
    ];
  }
  return [];
};

const DailyReportsGraphs = (props) => {
  const { dailyReports, currSelectedYear, year } = props || {};
  const { current, previous, ytdPrevious } = dailyReports || {};
  // Current Year
  const {
    total: currTotal,
    notaccepted: currYrNotAccepted,
    accepted: currYrAccepted,
    interviewed: currYrInterviewed,
    offered: currYrOffered,
  } =
    (current && Array.isArray(current) && current.length > 0 && current[0]) ||
    {};
  const currYearNotOffered =
    (currYrInterviewed && Number(currYrInterviewed)) -
      (currYrOffered && Number(currYrOffered)) || 0;
  const currYearAcceptanceRate =
    ((currYrAccepted && Number(currYrAccepted)) /
      (currYrOffered && Number(currYrOffered))) *
    100;

  //Previous Year - Corresponding
  const {
    total: prevCorresTotal,
    notaccepted: prevCorresYrNotAccepted,
    accepted: prevCorresYrAccepted,
    interviewed: prevCorresYrInterviewed,
    offered: prevCorresYrOffered,
  } =
    (previous &&
      Array.isArray(previous) &&
      previous.length > 0 &&
      previous[0]) ||
    {};
  const prevCorresYearNotOffered =
    (prevCorresYrInterviewed && Number(prevCorresYrInterviewed)) -
      (prevCorresYrOffered && Number(prevCorresYrOffered)) || 0;
  const prevCorresYearAcceptanceRate =
    ((prevCorresYrAccepted && Number(prevCorresYrAccepted)) /
      (prevCorresYrOffered && Number(prevCorresYrOffered))) *
    100;

  //Previous Year - Total
  const {
    total: prevTotalTotal,
    notaccepted: prevTotalYrNotAccepted,
    accepted: prevTotalYrAccepted,
    interviewed: prevTotalYrInterviewed,
    offered: prevTotalYrOffered,
  } =
    (ytdPrevious &&
      Array.isArray(ytdPrevious) &&
      ytdPrevious.length > 0 &&
      ytdPrevious[0]) ||
    {};
  const prevTotalYearNotOffered =
    (prevTotalYrInterviewed && Number(prevTotalYrInterviewed)) -
      (prevTotalYrOffered && Number(prevTotalYrOffered)) || 0;
  const prevTotalYearAcceptanceRate =
    ((prevTotalYrAccepted && Number(prevTotalYrAccepted)) /
      (prevTotalYrOffered && Number(prevTotalYrOffered))) *
    100;
  return (
    <Tile className="mt-2">
      <div className="mt-2 bx--row justify-content-around">
        <div
          className={
            currYearNotOffered > 0 ||
            currYrAccepted > 0 ||
            currYrNotAccepted > 0
              ? 'bx--col-lg-6 mt-3'
              : ''
          }>
          <div className=" graphs-height">
            {currYearNotOffered > 0 ||
            currYrAccepted > 0 ||
            currYrNotAccepted > 0 ? (
              <NivoPieChart
                margin={{ top: 15, right: 10, bottom: 35, left: 10 }}
                heading={year}
                headingClass="text-center h5 f-w-b"
                data={[
                  {
                    id: 'Not Offered',
                    label: 'Not Offered',
                    value: currYearNotOffered || 0,
                  },
                  {
                    id: 'Accepted',
                    label: 'Accepted',
                    value: currYrAccepted || 0,
                  },
                  {
                    id: 'Not Accepted',
                    label: 'Not Accepted',
                    value: currYrNotAccepted || 0,
                  },
                ]}
                legendTranslateY={106}
              />
            ) : (
              <div className="text-center">
                <h4>{currSelectedYear}</h4>
                <div className="h6 mt-3">
                  There are no details available for your current selection
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <div className="css-border-graph">
              <div className="bx--row">
                <div className="bx--col-lg-9 bx--col-md-6 text-right">
                  Total Interviewed:
                </div>{' '}
                <div>{currTotal || 0}</div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-9 bx--col-md-6 text-right">
                  Acceptance Rate:
                </div>{' '}
                <div>
                  {(currYearAcceptanceRate &&
                    Math.round(Number(currYearAcceptanceRate))) ||
                    0}
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className={
            prevCorresYearNotOffered > 0 ||
            prevCorresYrAccepted > 0 ||
            prevCorresYrNotAccepted > 0
              ? 'bx--col-lg-4 mt-3'
              : 'bx--col-lg-4'
          }>
          <div className="graphs-height">
            {prevCorresYearNotOffered > 0 ||
            prevCorresYrAccepted > 0 ||
            prevCorresYrNotAccepted > 0 ? (
              <NivoPieChart
                margin={{ top: 15, right: 10, bottom: 35, left: 10 }}
                heading={`${moment().format('YYYY') - 1} (corresponding stage)`}
                headingClass="text-center h5 f-w-b"
                data={[
                  {
                    id: 'Not Offered',
                    label: 'Not Offered',
                    value: prevCorresYearNotOffered || 0,
                  },
                  {
                    id: 'Accepted',
                    label: 'Accepted',
                    value: prevCorresYrAccepted || 0,
                  },
                  {
                    id: 'Not Accepted',
                    label: 'Not Accepted',
                    value: prevCorresYrNotAccepted || 0,
                  },
                ]}
                legendTranslateY={106}
              />
            ) : (
              <div>
                <h4>
                  {`${moment().format('YYYY') - 1} (corresponding stage)`}
                </h4>
                <div className="h6 mt-3">
                  There are no details available for your current selection
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <div className="css-border-graph">
              <div className="bx--row">
                <div className="bx--col-lg-9 bx--col-md-6 text-right">
                  Total Interviewed:
                </div>{' '}
                <div>{prevCorresTotal || 0}</div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-9 bx--col-md-6 text-right">
                  Acceptance Rate:
                </div>{' '}
                <div>{prevCorresYearAcceptanceRate || 0}%</div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className={
            prevTotalYearNotOffered > 0 ||
            prevTotalYrAccepted > 0 ||
            prevTotalYrNotAccepted > 0
              ? 'bx--col-lg-6 mt-3'
              : 'bx--col-lg-6'
          }>
          <div className="graphs-height">
            {prevTotalYearNotOffered > 0 ||
            prevTotalYrAccepted > 0 ||
            prevTotalYrNotAccepted > 0 ? (
              <NivoPieChart
                margin={{ top: 15, right: 10, bottom: 35, left: 10 }}
                heading={`${year - 1} (Total)`}
                headingClass="text-center h5 f-w-b"
                data={[
                  {
                    id: 'Not Offered',
                    label: 'Not Offered',
                    value: prevTotalYearNotOffered || 0,
                  },
                  {
                    id: 'Accepted',
                    label: 'Accepted',
                    value: prevTotalYrAccepted || 0,
                  },
                  {
                    id: 'Not Accepted',
                    label: 'Not Accepted',
                    value: prevTotalYrNotAccepted || 0,
                  },
                ]}
                legendTranslateY={106}
              />
            ) : (
              <div className="text-center">
                <h4>{`${currSelectedYear - 1} (Total)`}</h4>
                <div className="h6 mt-3">
                  There are no details available for your current selection
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <div className="css-border-graph">
              <div className="bx--row">
                <div className="bx--col-lg-9 bx--col-md-6 text-right">
                  Total Interviewed:
                </div>{' '}
                <div>{prevTotalTotal || 0}</div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-9 bx--col-md-6 text-right">
                  Acceptance Rate:
                </div>{' '}
                <div>
                  {(prevTotalYearAcceptanceRate &&
                    Math.round(Number(prevTotalYearAcceptanceRate))) ||
                    0}
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tile>
  );
};

const OverallStatistics = (props) => {
  const { dailyReports, currSelectedYear, year, selectedTab } = props || {};
  const keys = dailyReports && Object.keys(dailyReports);
  const interviewedData = {
    label: 'Interviewed',
  };
  const offeredData = {
    label: "Offered PSC Scholarship (Undergraduate, Mid-term, Master's)",
  };
  const acceptedData = {
    label: 'Accepted',
  };
  const acceptanceRateData = {
    label: 'Acceptance Rate (%)',
  };
  const finalOverallStatistics = [];
  if (keys && Array.isArray(keys) && keys.length > 0) {
    keys.forEach((key) => {
      const statistics = (dailyReports && dailyReports[key]) || {};
      const { accepted, interviewed, offered } = statistics || {};
      const acceptanceRate =
        ((accepted && Number(accepted)) / (offered && Number(offered))) * 100 ||
        0;
      interviewedData[key] = interviewed || 0;
      offeredData[key] = offered || 0;
      acceptedData[key] = accepted || 0;
      acceptanceRateData[key] =
        (acceptanceRate && Math.round(Number(acceptanceRate))) || 0;
    });
  }
  finalOverallStatistics.push(interviewedData);
  finalOverallStatistics.push(offeredData);
  finalOverallStatistics.push(acceptedData);
  finalOverallStatistics.push(acceptanceRateData);
  return (
    <Tile className="mt-2">
      <h4 className="mt-2 mb-2">
        Overall Exercise Statistics{' '}
        {selectedTab === 0 ? '(Year-on-year comparison)' : null}
      </h4>
      <table className="css-table-batch-info css-overall-excersises-w">
        <thead>
          <th className="text-left css-overall-w css-batch-metric-border-r css-total-f-sz pt-2 pb-2 pl-1 css-batch-info-head">
            Status
          </th>
          <th className="css-overall-w css-batch-metric-border-r css-total-f-sz css-batch-info-head">
            {year} {selectedTab === 1 ? '(Total)' : null}
          </th>
          {/* <th className="css-overall-w css-batch-metric-border-r css-total-f-sz css-batch-info-head">
            {`${moment().format('YYYY') - 1} (Corresponding Stage)`}
          </th> */}
          {selectedTab === 0 && (
            <th className="css-overall-w css-batch-metric-border-r css-total-f-sz css-batch-info-head">
              {`${year - 1} (Total)`}
            </th>
          )}
        </thead>
        <tbody>
          {finalOverallStatistics &&
            Array.isArray(finalOverallStatistics) &&
            finalOverallStatistics.length > 0 &&
            finalOverallStatistics.map((data, idx) => {
              const { label, current, previous, ytdPrevious } = data || {};
              return (
                <tr className={idx === 0 && 'css-batch-metric-border'}>
                  <td className="text-left css-batch-metric-border-r css-total-f-w vertical-align-middle">
                    {label}
                  </td>
                  <td className="css-batch-metric-border-r vertical-align-middle">
                    {current}
                  </td>
                  {/* <td className="css-batch-metric-border-r vertical-align-middle">
                    {ytdPrevious}
                  </td> */}
                  {selectedTab === 0 && (
                    <td className="vertical-align-middle">{previous}</td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </Tile>
  );
};

const ScholarshipSchoolStatistics = (props) => {
  const {
    schemeStatistics,
    year,
    heading,
    selectedTab,
    orderValues,
    editScholarshipData,
    handleChange,
    state,
    editSchoolData,
  } = props || {};
  const schemeKeys = schemeStatistics && Object.keys(schemeStatistics);
  const newDataVals = new Map();
  if (schemeKeys && Array.isArray(schemeKeys) && schemeKeys.length > 0) {
    schemeKeys.forEach((key) => {
      if (key) {
        const subKeys =
          schemeStatistics[key] && Object.keys(schemeStatistics[key]);
        const subDataArrVals = new Map();

        let sortedSubKeys = [];
        if (subKeys && Array.isArray(subKeys) && subKeys.length > 0) {
          sortedSubKeys = subKeys.sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          });
          subKeys.forEach((subKey) => {
            subDataArrVals.set(
              subKey,
              schemeStatistics &&
                schemeStatistics[key] &&
                schemeStatistics[key][subKey]
            );
          });
        }
        let subValuesOrder = {};
        if (
          sortedSubKeys &&
          Array.isArray(sortedSubKeys) &&
          sortedSubKeys.length > 0
        ) {
          sortedSubKeys.forEach((sortedSubKey) => {
            if (sortedSubKey !== 'others') {
              subValuesOrder[sortedSubKey] =
                subDataArrVals && subDataArrVals.get(sortedSubKey);
            } else {
              subValuesOrder[sortedSubKey] =
                subDataArrVals && subDataArrVals.get(sortedSubKey);
            }
          });
        }
        if (key === 'OtherSchool' || key === 'Other Institutions') {
          const subValsKeys = subValuesOrder && Object.keys(subValuesOrder);
          let finalSubValuesOrder = {};
          let OtherSubValuesOrder = {};
          if (
            subValsKeys &&
            Array.isArray(subValsKeys) &&
            subValsKeys.length > 0
          ) {
            subValsKeys.forEach((subKeys) => {
              if (subKeys !== 'others') {
                finalSubValuesOrder[subKeys] =
                  subKeys && subValuesOrder[subKeys];
              } else {
                OtherSubValuesOrder[subKeys] =
                  subKeys && subValuesOrder[subKeys];
              }
            });
          }
          newDataVals.set('Other Institutions', {
            ...finalSubValuesOrder,
            ...OtherSubValuesOrder,
          });
        } else {
          newDataVals.set(key, subValuesOrder);
        }
      }
    });
  }
  const finalOrderedVals = {};
  if (orderValues && Array.isArray(orderValues) && orderValues.length > 0) {
    orderValues.forEach((key) => {
      finalOrderedVals[key] = newDataVals.get(key);
    });
  }
  const keys = finalOrderedVals && Object.keys(finalOrderedVals);
  const totalScholarshipsData = {
    label: 'Total',
    accepted: 0,
    awarded: 0,
    declined: 0,
    pending: 0,
    interviewed: 0,
    second_rounder: 0,
    second_rounder_kiv: 0,
    target: 0,
    offered: 0,
    labelClass:
      'text-left css-total-f-sz css-batch-metric-border css-batch-metric-border-r',
    rowClass: 'css-total-f-w css-batch-metric-border',
  };
  const schemeData = [];
  if (keys && Array.isArray(keys) && keys.length > 0) {
    keys.forEach((mainKey) => {
      if (mainKey) {
        const subkeys =
          finalOrderedVals &&
          finalOrderedVals[mainKey] &&
          Object.keys(finalOrderedVals[mainKey]);
        const subLabelObj = finalOrderedVals && finalOrderedVals[mainKey];
        let mainAcceptedValue = 0;
        let mainAwardedValue = 0;
        let mainDeclinedValue = 0;
        let mainPendingValue = 0;
        let mainSecondRounderValue = 0;
        let mainSecondRounderKivValue = 0;
        let mainTargetValue = 0;
        let mainOfferedValue = 0;
        if (subkeys && Array.isArray(subkeys) && subkeys.length > 0) {
          subkeys.forEach((subLabel) => {
            if (subLabel) {
              const {
                accepted = 0,
                awarded = 0,
                declined = 0,
                pending = 0,
                target = 0,
                offered = 0,
                interviewed = 0,
              } = subLabelObj[subLabel] || {};
              const second_rounder_kiv =
                (subLabelObj &&
                  subLabelObj[subLabel] &&
                  subLabelObj[subLabel]['2nd Rounder KIV']) ||
                0;
              const second_rounder =
                (subLabelObj &&
                  subLabelObj[subLabel] &&
                  subLabelObj[subLabel]['2nd Rounder']) ||
                0;
              const withdrew =
                subLabelObj &&
                subLabelObj[subLabel] &&
                subLabelObj[subLabel]['withdrew acceptance'];
              const finalDeclined =
                (declined && Number(declined)) +
                  (withdrew && Number(withdrew)) || 0;
              const offeredValue = !offered
                ? (accepted && Number(accepted)) +
                    (pending && Number(pending)) +
                    (declined && Number(declined)) +
                    (withdrew && Number(withdrew)) || 0
                : offered;
              mainAcceptedValue += accepted && Number(accepted);
              mainAwardedValue += awarded && Number(awarded);
              mainDeclinedValue += finalDeclined && Number(finalDeclined);
              mainPendingValue += pending && Number(pending);
              mainSecondRounderValue +=
                second_rounder && Number(second_rounder);
              mainSecondRounderKivValue +=
                second_rounder_kiv && Number(second_rounder_kiv);
              mainTargetValue +=
                heading === 'By Scholarship Scheme'
                  ? target && Number(target)
                  : interviewed && Number(interviewed);
              mainOfferedValue += offeredValue && Number(offeredValue);

              schemeData.push({
                label: mainKey,
                accepted: mainAcceptedValue || 0,
                awarded: mainAwardedValue || 0,
                declined: mainDeclinedValue || 0,
                pending: mainPendingValue || 0,
                second_rounder: mainSecondRounderValue || 0,
                second_rounder_kiv: mainSecondRounderKivValue || 0,
                target: mainTargetValue || 0,
                offered: mainOfferedValue || 0,
                labelClass:
                  'text-left css-total-f-w css-batch-metric-border-r css-batch-metric-border',
                rowClass:
                  'css-total-f-w css-batch-metric-border text-secondary',
              });
              schemeData.push({
                label: subLabel,
                accepted: (accepted && Number(accepted)) || 0,
                awarded: (awarded && Number(awarded)) || 0,
                declined: (finalDeclined && Number(finalDeclined)) || 0,
                pending: (pending && Number(pending)) || 0,
                second_rounder: (second_rounder && Number(second_rounder)) || 0,
                second_rounder_kiv:
                  (second_rounder_kiv && Number(second_rounder_kiv)) || 0,
                target:
                  (heading === 'By Scholarship Scheme'
                    ? target && Number(target)
                    : interviewed && Number(interviewed)) || 0,
                offered: (offeredValue && Number(offeredValue)) || 0,
              });
              totalScholarshipsData.awarded +=
                (awarded && Number(awarded)) || 0;
              totalScholarshipsData.accepted +=
                (accepted && Number(accepted)) || 0;
              totalScholarshipsData.declined +=
                (finalDeclined && Number(finalDeclined)) || 0;
              totalScholarshipsData.pending +=
                (pending && Number(pending)) || 0;
              totalScholarshipsData.second_rounder +=
                (second_rounder && Number(second_rounder)) || 0;
              totalScholarshipsData.second_rounder_kiv +=
                (second_rounder_kiv && Number(second_rounder_kiv)) || 0;
              totalScholarshipsData.target +=
                (heading === 'By Scholarship Scheme'
                  ? target && Number(target)
                  : interviewed && Number(interviewed)) || 0;
              totalScholarshipsData.offered +=
                (offeredValue && Number(offeredValue)) || 0;
            }
          });
        } else {
          schemeData.push({
            label: mainKey,
            accepted: mainAcceptedValue || 0,
            awarded: mainAwardedValue || 0,
            declined: mainDeclinedValue || 0,
            pending: mainPendingValue || 0,
            second_rounder: mainSecondRounderValue || 0,
            second_rounder_kiv: mainSecondRounderKivValue || 0,
            target: mainTargetValue || 0,
            offered: mainOfferedValue || 0,
            labelClass:
              'text-left css-total-f-w css-batch-metric-border-r css-batch-metric-border',
            rowClass: 'css-total-f-w css-batch-metric-border text-secondary',
          });
        }
      }
    });
  }
  schemeData.push(totalScholarshipsData);
  const finalSchemeStatistics = getUniqueListBy(schemeData, 'label');
  return (
    <Tile className="mt-2">
      <h4 className="mt-2 mb-2">{heading}</h4>
      {keys && Array.isArray(keys) && keys.length > 0 ? (
        <table className="css-table-batch-info w-100 css-statistics-zoom-auto">
          <thead>
            <th className="text-left css-table-head css-batch-metric-border-r"></th>

            <th className="css-table-head" colSpan={selectedTab === 0 ? 8 : 8}>
              {year} {selectedTab === 0 ? 'Exercise' : 'Excercise (Total)'}
            </th>
            <th
              rowSpan={2}
              className="css-batch-table-execercise-w css-table-head"></th>
          </thead>
          <thead>
            <th
              className="css-metric-table-head text-left css-batch-metric-border-r"
              rowSpan={2}>
              Scheme
            </th>
            <th className="css-batch-table-execercise-w css-table-head css-table-head">
              {heading === 'By Scholarship Scheme' ? 'Target' : 'Interviewed'}
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              Offered
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              Accepted
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              Declined / Withdrew
            </th>
            {(selectedTab === 0 || selectedTab === 1) && (
              <th className="css-batch-table-execercise-w css-table-head">
                Pending
              </th>
            )}

            <th className="css-batch-table-execercise-w css-table-head">
              Acceptance Rate (%)
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              2nd Rounder
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              2nd Rounder- KIV
            </th>
            <th className="css-metric-table-head">{year - 1} Awarded</th>
          </thead>
          <tbody className="css-table-batch-info">
            {finalSchemeStatistics &&
              Array.isArray(finalSchemeStatistics) &&
              finalSchemeStatistics.length > 0 &&
              finalSchemeStatistics.map((data) => {
                const {
                  accepted,
                  awarded,
                  declined,
                  pending,
                  second_rounder,
                  second_rounder_kiv,
                  target,
                  offered,
                  label,
                  labelClass,
                  rowClass,
                } = data || {};
                let finalLabel = label;
                if (
                  label === 'Integrated Programme (IP) and Specialised Schools'
                ) {
                  finalLabel = 'Other IP/Specialised Schools';
                } else if (label === 'psc scholarship') {
                  finalLabel = 'PSC Scholarship';
                } else if (label === "psc master's scholarship") {
                  finalLabel = "PSC Master's Scholarship";
                } else if (label === 'singapore police forces') {
                  finalLabel = 'Singapore Police Force';
                } else if (
                  label === 'nus high school of mathematics and science'
                ) {
                  finalLabel = 'NUS High School of Mathematics and Science';
                } else if (label === 'school of the arts') {
                  finalLabel = 'School of The Arts';
                }
                const acceptanceRate =
                  ((accepted && Number(accepted)) /
                    (offered && Number(offered))) *
                    100 || 0;
                return (
                  <tr
                    className={`${
                      editScholarshipData === true || editSchoolData === true
                        ? 'css-table-row-min-h'
                        : ''
                    } ${label === 'Total' ? 'css-total-bg' : ''}`}>
                    <td
                      className={`${
                        labelClass
                          ? `${
                              label === 'Total' ? '' : 'text-secondary'
                            } ${labelClass}`
                          : `text-right css-batch-metric-border-r ${
                              finalLabel ===
                                'NUS High School of Mathematics and Science' ||
                              finalLabel === 'School of The Arts'
                                ? ''
                                : 'text-capitalize'
                            }`
                      } ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {finalLabel}
                    </td>
                    <td
                      className={`${!rowClass ? '' : rowClass} ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {editScholarshipData === true && !rowClass ? (
                        <TextInput
                          name={`target-${label}`}
                          id={`target-${label}`}
                          value={state && state[`target-${label}`]}
                          onChange={handleChange}
                          hideLabel
                          placeholder={target}
                        />
                      ) : (
                        <div>{target}</div>
                      )}
                    </td>
                    <td
                      className={`${!rowClass ? '' : rowClass} ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {offered}
                    </td>
                    <td
                      className={`${!rowClass ? '' : rowClass} ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {accepted}
                    </td>
                    <td
                      className={`${!rowClass ? '' : rowClass} ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {declined}
                    </td>
                    {(selectedTab === 0 || selectedTab === 1) && (
                      <td
                        className={`${!rowClass ? '' : rowClass} ${
                          editScholarshipData === true ||
                          editSchoolData === true
                            ? 'vertical-align-middle'
                            : ''
                        }`}>
                        {pending}
                      </td>
                    )}
                    <td
                      className={`${!rowClass ? '' : rowClass} ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {(acceptanceRate && Math.round(Number(acceptanceRate))) ||
                        0}
                    </td>
                    <td
                      className={`${!rowClass ? '' : rowClass} ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {second_rounder}
                    </td>
                    <td
                      className={`${!rowClass ? '' : rowClass} ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {second_rounder_kiv}
                    </td>
                    <td
                      className={`${!rowClass ? '' : rowClass} ${
                        editScholarshipData === true || editSchoolData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {(editScholarshipData === true ||
                        editSchoolData === true) &&
                      !rowClass ? (
                        <TextInput
                          name={`awarded-${label}`}
                          id={`awarded-${label}`}
                          value={state && state[`awarded-${label}`]}
                          onChange={handleChange}
                          hideLabel
                          placeholder={awarded}
                        />
                      ) : (
                        <div>{awarded}</div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <NoContentBox
          hideTile
          message="There are no details available for your current selection"
        />
      )}
    </Tile>
  );
};

const CountryCourseStatistics = (props) => {
  const {
    countryStatistics,
    year,
    heading,
    isCourseTable,
    selectedTab,
    othersData,
    editCountryData,
    state,
    handleChange,
    editCourseData,
    orderedKeys,
  } = props || {};
  let dataValsOrder = new Map();
  const dataKeys = countryStatistics && Object.keys(countryStatistics);
  if (dataKeys && Array.isArray(dataKeys) && dataKeys.length > 0) {
    dataKeys.forEach((key) => {
      dataValsOrder.set(
        key && key.toString() && key.toString().toLowerCase(),
        countryStatistics && countryStatistics[key]
      );
    });
  }
  let finalDataObj = {};
  if (orderedKeys && Array.isArray(orderedKeys) && orderedKeys.length > 0) {
    orderedKeys.forEach((key) => {
      const finalKey = key && key.toString() && key.toString().toLowerCase();
      finalDataObj[finalKey] =
        dataValsOrder && dataValsOrder.get(finalKey)
          ? dataValsOrder.get(finalKey)
          : {
              accepted: 0,
              awarded: 0,
              declined: 0,
              pending: 0,
              target: 0,
              offered: 0,
            };
    });
  }
  const keys = finalDataObj && Object.keys(finalDataObj);
  const othersDataKeys = othersData && Object.keys(othersData);
  const countryStatisticsArr = [];
  const totalCountriesData = {
    label: 'Total',
    accepted: 0,
    awarded: 0,
    declined: 0,
    pending: 0,
    interviewed: 0,
    second_rounder: 0,
    second_rounder_kiv: 0,
    target: 0,
    offered: 0,
    labelClass:
      'text-left css-total-f-sz css-batch-metric-border css-batch-metric-border-r',
    rowClass: 'css-total-f-w css-batch-metric-border',
  };
  const otherValueData = {
    label: 'Others',
    accepted: 0,
    awarded: 0,
    declined: 0,
    pending: 0,
    interviewed: 0,
    second_rounder: 0,
    second_rounder_kiv: 0,
    target: 0,
    offered: 0,
    labelClass:
      'text-left css-total-f-w css-batch-metric-border-r css-batch-metric-border',
    rowClass: 'css-total-f-w css-batch-metric-border text-secondary',
  };
  const pendingValueData = {
    label: 'pending',
    accepted: 0,
    awarded: 0,
    declined: 0,
    pending: 0,
    interviewed: 0,
    second_rounder: 0,
    second_rounder_kiv: 0,
    target: 0,
    offered: 0,
  };
  if (keys && Array.isArray(keys) && keys.length > 0) {
    keys.forEach((key) => {
      if (key) {
        const finalKey = key && key.toString() && key.toString().toLowerCase();
        const {
          accepted = 0,
          awarded = 0,
          declined = 0,
          pending = 0,
          target = 0,
          offered = 0,
        } = finalDataObj[finalKey] || {};
        const second_rounder_kiv =
          (finalDataObj &&
            finalDataObj[finalKey] &&
            finalDataObj[finalKey]['2nd Rounder KIV']) ||
          0;
        const second_rounder =
          (finalDataObj &&
            finalDataObj[finalKey] &&
            finalDataObj[finalKey]['2nd Rounder']) ||
          0;
        const withdrew =
          finalDataObj &&
          finalDataObj[finalKey] &&
          finalDataObj[finalKey]['withdrew acceptance'];
        const finalDeclined =
          (declined && Number(declined)) + (withdrew && Number(withdrew)) || 0;
        const offeredValue = !offered
          ? (accepted && Number(accepted)) +
              (pending && Number(pending)) +
              (declined && Number(declined)) +
              (withdrew && Number(withdrew)) || 0
          : offered;
        if (
          key &&
          key.toString() &&
          key.toString().toLowerCase() === 'others' &&
          heading === 'By Country'
        ) {
          otherValueData.accepted = (accepted && Number(accepted)) || 0;
          otherValueData.awarded = (awarded && Number(awarded)) || 0;
          otherValueData.declined =
            (finalDeclined && Number(finalDeclined)) || 0;
          otherValueData.pending = (pending && Number(pending)) || 0;
          otherValueData.second_rounder =
            (second_rounder && Number(second_rounder)) || 0;
          otherValueData.second_rounder_kiv =
            (second_rounder_kiv && Number(second_rounder_kiv)) || 0;
          otherValueData.target = (target && Number(target)) || 0;
          otherValueData.offered = (offeredValue && Number(offeredValue)) || 0;
          countryStatisticsArr.push(otherValueData);
        } else if (
          key &&
          key.toString() &&
          key.toString().toLowerCase() === 'pending' &&
          heading === 'By Country'
        ) {
          pendingValueData.accepted = (accepted && Number(accepted)) || 0;
          pendingValueData.awarded = (awarded && Number(awarded)) || 0;
          pendingValueData.declined =
            (finalDeclined && Number(finalDeclined)) || 0;
          pendingValueData.pending = (pending && Number(pending)) || 0;
          pendingValueData.second_rounder =
            (second_rounder && Number(second_rounder)) || 0;
          pendingValueData.second_rounder_kiv =
            (second_rounder_kiv && Number(second_rounder_kiv)) || 0;
          pendingValueData.target = (target && Number(target)) || 0;
          pendingValueData.offered =
            (offeredValue && Number(offeredValue)) || 0;
        } else {
          countryStatisticsArr.push({
            label: finalKey,
            accepted: (accepted && Number(accepted)) || 0,
            awarded: (awarded && Number(awarded)) || 0,
            declined: (finalDeclined && Number(finalDeclined)) || 0,
            pending: (pending && Number(pending)) || 0,
            second_rounder: (second_rounder && Number(second_rounder)) || 0,
            second_rounder_kiv:
              (second_rounder_kiv && Number(second_rounder_kiv)) || 0,
            target: (target && Number(target)) || 0,
            offered: (offeredValue && Number(offeredValue)) || 0,
          });
        }

        totalCountriesData.awarded += (awarded && Number(awarded)) || 0;
        totalCountriesData.accepted += (accepted && Number(accepted)) || 0;
        totalCountriesData.declined +=
          (finalDeclined && Number(finalDeclined)) || 0;
        totalCountriesData.pending += (pending && Number(pending)) || 0;
        totalCountriesData.second_rounder +=
          (second_rounder && Number(second_rounder)) || 0;
        totalCountriesData.second_rounder_kiv +=
          (second_rounder_kiv && Number(second_rounder_kiv)) || 0;
        totalCountriesData.target += (target && Number(target)) || 0;
        totalCountriesData.offered +=
          (offeredValue && Number(offeredValue)) || 0;
      }
    });
  }

  let sortedOtherDataKeys = [];
  if (
    othersDataKeys &&
    Array.isArray(othersDataKeys) &&
    othersDataKeys.length > 0
  ) {
    sortedOtherDataKeys = othersDataKeys.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  }
  if (
    sortedOtherDataKeys &&
    Array.isArray(sortedOtherDataKeys) &&
    sortedOtherDataKeys.length > 0
  ) {
    sortedOtherDataKeys.forEach((otherKey) => {
      if (otherKey) {
        const {
          accepted = 0,
          awarded = 0,
          declined = 0,
          pending = 0,
          target = 0,
          offered = 0,
        } = othersData[otherKey] || {};
        const second_rounder_kiv =
          (othersData &&
            othersData[otherKey] &&
            othersData[otherKey]['2nd Rounder KIV']) ||
          0;
        const second_rounder =
          (othersData &&
            othersData[otherKey] &&
            othersData[otherKey]['2nd Rounder']) ||
          0;
        const withdrew =
          othersData &&
          othersData[otherKey] &&
          othersData[otherKey]['withdrew acceptance'];
        const finalDeclined =
          (declined && Number(declined)) + (withdrew && Number(withdrew)) || 0;
        const offeredValue = !offered
          ? (accepted && Number(accepted)) +
              (pending && Number(pending)) +
              (declined && Number(declined)) +
              (withdrew && Number(withdrew)) || 0
          : offered;
        const finalObject = {
          label: otherKey,
          accepted: (accepted && Number(accepted)) || 0,
          awarded: (awarded && Number(awarded)) || 0,
          declined: (finalDeclined && Number(finalDeclined)) || 0,
          pending: (pending && Number(pending)) || 0,
          second_rounder: (second_rounder && Number(second_rounder)) || 0,
          second_rounder_kiv:
            (second_rounder_kiv && Number(second_rounder_kiv)) || 0,
          target: (target && Number(target)) || 0,
          offered: (offeredValue && Number(offeredValue)) || 0,
        };
        if (otherKey !== 'pending') {
          countryStatisticsArr.push(finalObject);
        } else {
          countryStatisticsArr.push(finalObject);
        }
      }
    });
  }
  if (heading === 'By Country') {
    countryStatisticsArr.push(pendingValueData);
  }
  countryStatisticsArr.push(totalCountriesData);
  return (
    <Tile className="mt-2">
      <h4 className="mt-2 mb-2">{heading}</h4>
      {keys && Array.isArray(keys) && keys.length > 0 ? (
        <table className="css-table-batch-info w-100 css-statistics-zoom-auto">
          <thead>
            <th className="text-left css-table-head css-batch-metric-border-r"></th>

            <th className="css-table-head" colSpan={selectedTab === 0 ? 8 : 8}>
              {year} {selectedTab === 0 ? 'Exercise' : 'Excercise (Total)'}
            </th>
            <th
              rowSpan={2}
              className="css-batch-table-execercise-w css-table-head"></th>
          </thead>
          <thead>
            <th
              className="css-metric-table-head text-left css-batch-metric-border-r"
              rowSpan={2}>
              {heading === 'By Country' ? 'Country' : 'Course'}
            </th>
            <th className="css-batch-table-execercise-w css-table-head css-table-head">
              Target
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              Offered
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              Accepted
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              Declined / Withdrew
            </th>
            {(selectedTab === 0 || selectedTab === 1) && (
              <th className="css-batch-table-execercise-w css-table-head">
                Pending
              </th>
            )}

            <th className="css-batch-table-execercise-w css-table-head">
              Acceptance Rate (%)
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              2nd Rounder
            </th>
            <th className="css-batch-table-execercise-w css-table-head">
              2nd Rounder- KIV
            </th>
            <th className="css-metric-table-head">{year - 1} Awarded</th>
          </thead>
          <tbody className="css-table-batch-info">
            {countryStatisticsArr &&
              Array.isArray(countryStatisticsArr) &&
              countryStatisticsArr.length > 0 &&
              countryStatisticsArr.map((data, idx) => {
                const {
                  label,
                  accepted,
                  awarded,
                  declined,
                  pending,
                  second_rounder,
                  second_rounder_kiv,
                  offered,
                  target,
                  labelClass,
                  rowClass,
                } = data || {};
                let finalLabel = '';
                if (label === 'engineering & ict') {
                  finalLabel = 'Engineering & ICT';
                } else if (label === 'economics / ppe') {
                  finalLabel = 'Economics / PPE';
                } else if (label === 'united states of america') {
                  finalLabel = 'United States of America';
                } else {
                  finalLabel = label;
                }
                const acceptanceRate =
                  ((accepted && Number(accepted)) /
                    (offered && Number(offered))) *
                    100 || 0;
                return (
                  <tr
                    className={`${label === 'Total' ? 'css-total-bg' : ''} ${
                      editCountryData === true || editCourseData === true
                        ? 'css-table-row-min-h'
                        : ''
                    }`}>
                    <td
                      className={`${
                        !labelClass
                          ? `text-right css-batch-metric-border-r ${
                              finalLabel === 'United States of America'
                                ? ''
                                : 'text-capitalize'
                            }`
                          : `${labelClass} ${
                              finalLabel === 'United States of America'
                                ? ''
                                : 'text-capitalize'
                            }  ${label === 'Total' ? '' : 'text-secondary'}`
                      } ${
                        editCountryData === true || editCourseData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {finalLabel}
                      {finalLabel === 'france' ||
                      finalLabel === 'germany' ||
                      finalLabel === 'japan'
                        ? '*'
                        : null}
                    </td>
                    {isCourseTable ? (
                      <Fragment>
                        {idx === 0 && (
                          <td
                            className="align-mid"
                            rowSpan={countryStatisticsArr.length - 1}>
                            N.A.
                          </td>
                        )}
                        {idx === countryStatisticsArr.length - 1 && (
                          <td
                            className={
                              !rowClass
                                ? ''
                                : 'css-total-f-w css-batch-metric-border'
                            }>
                            {target}
                          </td>
                        )}
                      </Fragment>
                    ) : (
                      <td
                        className={
                          !rowClass
                            ? ''
                            : 'css-total-f-w css-batch-metric-border'
                        }>
                        {editCountryData === true && !rowClass ? (
                          <TextInput
                            name={`target-${label}`}
                            id={`target-${label}`}
                            value={state && state[`target-${label}`]}
                            onChange={handleChange}
                            hideLabel
                            placeholder={target}
                          />
                        ) : (
                          <div>{target}</div>
                        )}
                      </td>
                    )}
                    <td
                      className={`${
                        !rowClass ? '' : 'css-total-f-w css-batch-metric-border'
                      } ${
                        editCountryData === true || editCourseData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {offered}
                    </td>
                    <td
                      className={`${
                        !rowClass ? '' : 'css-total-f-w css-batch-metric-border'
                      } ${
                        editCountryData === true || editCourseData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {accepted}
                    </td>

                    <td
                      className={`${
                        !rowClass ? '' : 'css-total-f-w css-batch-metric-border'
                      } ${
                        editCountryData === true || editCourseData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {declined}
                    </td>
                    {(selectedTab === 0 || selectedTab === 1) && (
                      <td
                        className={`${
                          !rowClass
                            ? ''
                            : 'css-total-f-w css-batch-metric-border'
                        } ${
                          editCountryData === true || editCourseData === true
                            ? 'vertical-align-middle'
                            : ''
                        }`}>
                        {pending}
                      </td>
                    )}
                    <td
                      className={`${
                        !rowClass ? '' : 'css-total-f-w css-batch-metric-border'
                      } ${
                        editCountryData === true || editCourseData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {(acceptanceRate && Math.round(Number(acceptanceRate))) ||
                        0}
                    </td>
                    <td
                      className={`${
                        !rowClass ? '' : 'css-total-f-w css-batch-metric-border'
                      } ${
                        editCountryData === true || editCourseData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {second_rounder}
                    </td>
                    <td
                      className={`${
                        !rowClass ? '' : 'css-total-f-w css-batch-metric-border'
                      } ${
                        editCountryData === true || editCourseData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {second_rounder_kiv}
                    </td>
                    <td
                      className={`${
                        !rowClass ? '' : 'css-total-f-w css-batch-metric-border'
                      } ${
                        editCountryData === true || editCourseData === true
                          ? 'vertical-align-middle'
                          : ''
                      }`}>
                      {(editCountryData === true || editCourseData === true) &&
                      !rowClass ? (
                        <TextInput
                          name={
                            editCourseData === true
                              ? `awarded-${label}-course`
                              : `awarded-${label}`
                          }
                          id={
                            editCourseData === true
                              ? `awarded-${label}-course`
                              : `awarded-${label}`
                          }
                          value={
                            state &&
                            state[
                              editCourseData === true
                                ? `awarded-${label}-course`
                                : `awarded-${label}`
                            ]
                          }
                          onChange={handleChange}
                          hideLabel
                          placeholder={awarded}
                        />
                      ) : (
                        <div>{awarded}</div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <NoContentBox
          hideTile
          message="There are no details available for your current selection"
        />
      )}
      {heading === 'By Country' && (
        <div className="f-w-b css-text-color mt-3">
          *In total, there is a target of {selectedTab === 0 ? 5 : 6} for offers
          to study in France, Germany and Japan.
        </div>
      )}
    </Tile>
  );
};

export {
  DailyReportsGraphs,
  OverallStatistics,
  ScholarshipSchoolStatistics,
  CountryCourseStatistics,
};
