import callApi from './callApi';

//Common urls for api calls
import { baseUrl, regularUrl } from '../utils/config';
import {
  REQUEST_GET_CANDIDATE_PROFILE_DETAILS,
  REQUEST_GET_JOB_APPL_FILTERS,
  REQUEST_ASSIGN_CAND_TO_INT_STAGE,
  REQUEST_GET_STATIC_APPL_FILTERS,
  REQUEST_UPLOAD_CANDIDATES_DATA,
} from './actionTypes';

//Get call for batch list information
const getCandidateListData = (data, appId, actionType) => (dispatch) => {
  const {
    batchId,
    year,
    interviewStartDate,
    interviewEndDate,
    isInterviewList,
    activePage,
    itemsPerPage,
    isCurrentUserMainInterviewer,
    isCompleted,
    searchKey,
    leadership_ccatier,
    representation_ccatier,
    pm_wg,
    pm_ranra,
    pb_gba,
    fd_gba,
    pi_leadership_qualities,
    pi_intellectual_qualities,
    pi_character_and_values,
    pi_interpersonal_skills,
    pi_resilience,
    isTodaysInterview,
    getOnlyNecessaryDetail,
    recordType,
    stageId,
    videoStatusSearch,
    assignedToSearchId,
    appIds,
    isCscRatingsInOldFormat,
    scenarioId,
  } = data || {};
  let url = '';
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  if (!appId) {
    url = `${baseUrl}/applications?_=${new Date().getTime()}`;
  } else {
    url = `${baseUrl}/applications/${appId}?_=${new Date().getTime()}`;
  }

  if (batchId) {
    url += `&batchId=${batchId}`;
  }
  if (isInterviewList) {
    url += `&isInterviewList=${isInterviewList}`;
  }
  if (getOnlyNecessaryDetail) {
    url += `&getOnlyNecessaryDetail=${getOnlyNecessaryDetail}`;
  }
  if (
    isCurrentUserMainInterviewer !== null &&
    isCurrentUserMainInterviewer !== undefined
  ) {
    url += `&isCurrentUserMainInterviewer=${isCurrentUserMainInterviewer}`;
  }
  if (isCompleted !== null && isCompleted !== undefined) {
    url += `&isCompleted=${isCompleted}`;
  }
  if (year) {
    url += `&year=${year}`;
  }
  if (interviewStartDate) {
    url += `&interviewStartDate=${interviewStartDate}`;
  }
  if (isTodaysInterview) {
    url += `&isTodaysInterview=${isTodaysInterview}`;
  }
  if (interviewEndDate) {
    url += `&interviewEndDate=${interviewEndDate}`;
  }
  if (stageId) {
    url += `&stageId=${stageId}`;
  }
  if (videoStatusSearch) {
    url += `&videoStatusSearch=${videoStatusSearch}`;
  }
  if (assignedToSearchId) {
    url += `&assignedToSearchId=${assignedToSearchId}`;
  }
  if (isCscRatingsInOldFormat) {
    url += `&isCscRatingsInOldFormat=${isCscRatingsInOldFormat}`;
  }
  if (scenarioId) url += `&scenarioId=${scenarioId}`;

  let facetFilters = [];
  if (leadership_ccatier) {
    facetFilters.push({ leadership_ccatier: leadership_ccatier });
  }
  if (pm_wg) {
    facetFilters.push({ pm_wg: pm_wg });
  }
  if (representation_ccatier) {
    facetFilters.push({ representation_ccatier: representation_ccatier });
  }
  if (pm_ranra) {
    facetFilters.push({ pm_ranra: pm_ranra });
  }
  if (pb_gba) {
    facetFilters.push({ pb_gba: pb_gba });
  }
  if (fd_gba) {
    facetFilters.push({ fd_gba: fd_gba });
  }
  if (pi_leadership_qualities) {
    facetFilters.push({ pi_leadership_qualities: pi_leadership_qualities });
  }
  if (pi_intellectual_qualities) {
    facetFilters.push({ pi_intellectual_qualities: pi_intellectual_qualities });
  }
  if (pi_character_and_values) {
    facetFilters.push({ pi_character_and_values: pi_character_and_values });
  }
  if (pi_interpersonal_skills) {
    facetFilters.push({ pi_interpersonal_skills: pi_interpersonal_skills });
  }
  if (pi_resilience) {
    facetFilters.push({ pi_resilience: pi_resilience });
  }
  if (facetFilters && Array.isArray(facetFilters) && facetFilters.length > 0) {
    url += `&facetFilters=${encodeURIComponent(JSON.stringify(facetFilters))}`;
  }
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }

  if (recordType) {
    url += `&recordType=${recordType}`;
  }

  if (appIds && Array.isArray(appIds) && appIds.length > 0) {
    const appIdsFilter = [{ appIdList: appIds }];
    url += `&filterArr=${encodeURIComponent(JSON.stringify(appIdsFilter))}`;
  }

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    actionType,
    url,
  };
  return callApi(params);
};

//Get call for batch list information
const getCandidateProfileDetailsList = (search) => (dispatch) => {
  let url = `${baseUrl}/profiles?_=${new Date().getTime()}`;
  if (search) {
    url += `&search=${search}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    actionType: REQUEST_GET_CANDIDATE_PROFILE_DETAILS,
    url,
  };
  return callApi(params);
};

//Get call for job appl list information
const getJobApplFilters = () => (dispatch) => {
  let url = `${baseUrl}/applications/jobApplnFacets?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    actionType: REQUEST_GET_JOB_APPL_FILTERS,
    url,
  };
  return callApi(params);
};

const assignApplsToIntStage = (data) => (dispatch) => {
  let url = `${baseUrl}/applications/assignApplnToIntStage?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    actionType: REQUEST_ASSIGN_CAND_TO_INT_STAGE,
    url,
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getSaticApplFilters = () => (dispatch) => {
  let url = `${baseUrl}/applications/staticApplnFilters?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    actionType: REQUEST_GET_STATIC_APPL_FILTERS,
    url,
  };
  return callApi(params);
};

const uploadCandidatesData = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/nricSearch?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_CANDIDATES_DATA,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

export {
  getCandidateListData,
  getCandidateProfileDetailsList,
  getJobApplFilters,
  assignApplsToIntStage,
  getSaticApplFilters,
  uploadCandidatesData,
};
