import React from 'react';
import { Button, InlineNotification, Loading } from 'carbon-components-react';

const JDProcessOverlay = ({ show, message }) =>
  show ? (
    <div className="">
      <Loading className="d-inline-block" withOverlay />
    </div>
  ) : null;

export default JDProcessOverlay;
