import axios from 'axios';

const xapi = async (config) => {
  try {
    if(!config.headers) {
      config.headers = {
        "content-type": "application/json",
      };
    }
    const res = await axios(config);
    const { data } = res || {};
    return data;
  } catch (error) {
    const { message } = error || {};
    return {
      error: {
        message: message || "Error occured while processing",
      },
    };
  }
};

export {
  xapi,
};