import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, InlineNotification, Tile } from 'carbon-components-react';
import { generateYearlyApplData } from '../../actions/AdminSection';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import BackRow from '../../components/common/BackRow';
import { SelectionYearDropdown } from '../../components/common/SelectionYearDropdown';

class YearlyDataExport extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'yearly-data-export', name: 'Yearly Data Export', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { user } = this.props;
    const { currentYear } = user || {};
    this.setState({ selectionYear: currentYear });
  }

  handleGenerateYearlyApplData = () => {
    const { selectionYear } = this.state;
    this.props
      .generateYearlyApplData({ selectionYear })
      .then((res) => {
        if (res && !res.error) {
          this.setState({
            successNotif: true,
            uploadErr: false,
            notifMsg: res.message,
          });
        } else {
          this.setState({
            uploadErr: true,
            successNotif: false,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          uploadErr: true,
          successNotif: false,
        });
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { uploadErr, successNotif, notifMsg, selectionYear } = this.state;
    const { loading, user } = this.props;
    const { currentYear } = user || {};

    return (
      <div>
        <BackRow paths={this.paths} />
        {successNotif === true && (
          <InlineNotification
            lowContrast
            title={notifMsg}
            iconDescription="describes the close button"
            kind="success"
            onCloseButtonClick={() => {
              this.setState({ successNotif: false });
            }}
          />
        )}
        {uploadErr === true && (
          <InlineNotification
            lowContrast
            title="Something went wrong. Please try again later"
            iconDescription="describes the close button"
            kind="error"
            onCloseButtonClick={() => {
              this.setState({ uploadErr: false });
            }}
          />
        )}
        <div className="f-w-b h6 mb-2">Generate Yearly Applications Data</div>
        <Tile>
          <SelectionYearDropdown
            currentYear={currentYear}
            selectionYear={selectionYear}
            handleChange={this.handleChange}
            mainClassName="mt-2 w-50"
            titleClassName="xpa-width"
            showAllSelectionYears={true}
          />
          <Button
            className="mt-3"
            onClick={() => this.handleGenerateYearlyApplData()}
            size="small"
            kind="primary">
            Generate Report
          </Button>
        </Tile>
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.AdminSection.loading,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  generateYearlyApplData,
};

export default connect(mapStateToProps, mapDispatchToProps)(YearlyDataExport);
