import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//API
import {
  getReportTypesByAppId,
  uploadCandidateReports,
  deleteCandidateReports,
  getViewReports,
} from '../../actions/CandidateInfo';
import { baseUrl } from '../../utils/config';
//Common Functional Components
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import { Button, FileUploaderButton, Modal } from 'carbon-components-react';
import { WarningAlt32 } from '@carbon/icons-react';

import SuccErrNotification from '../../components/private/SuccErrNotification';

class CandidateReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
      isOpenDelReportModal: false,
    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  renderOnMount = () => {
    const { appId } = this.props;
    if (appId) {
      this.props
        .getReportTypesByAppId(appId)
        .then((res) => {
          if (res && !res.error) {
            this.setState({
              reportTypes: res,
            });
          } else {
            this.setState({
              reportTypes: [],
            });
          }
        })
        .catch((e) => {
          console.log('Error', e);
          this.setState({
            reportTypes: [],
          });
        });
    }
  };

  handleUploadReportTypes = (element, reporttypeId) => {
    const fileData = element.target.files;
    const { appId } = this.props;
    const currFile = new FormData();
    currFile.append('file', fileData[0]);
    currFile.append('reportId', reporttypeId);
    currFile.append('appId', appId);
    this.props
      .uploadCandidateReports(currFile)
      .then((res) => {
        if (res && !res.error) {
          this.renderOnMount();
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been uploaded successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  notifMsg: '',
                  successNotif: false,
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleGetReportTypeLabel = (type) => {
    let reportType = '';
    if (
      type ===
        'CertificateofAcademicQualificationObtained-IB/NUSHighSch/Polytechnic/OtherHighSchDiploma' ||
      type ===
        'CertificateofAcademicQualification-IB/NUSHighSch/Polytechnic/OtherHighSchDiploma' ||
      type === 'AcadQualCert_UGM' ||
      type === 'AcadQualCert_UG' ||
      type === 'AcadQualCert_M'
    ) {
      reportType =
        'Certificate of Academic Qualification Obtained- IB or NUS High Sch or Polytechnic or Other High Sch Diploma';
    } else if (
      type === 'PreTertiaryResult(Pre)' ||
      type === 'PreTertiaryResult(Post)' ||
      type === 'PreTertiaryResults(Pre)' ||
      type === 'PreTertiaryResults(Post)'
    ) {
      reportType =
        'Certificates of Academic Qualifications Obtained (e.g. GCE O/A-Level, IB/NUS High Sch/Polytechnic/Other High Sch Diploma)';
    } else if (type === 'SARReport') {
      reportType = 'School Assessment Report';
    } else if (
      type === 'CertificateofAcademicQualificationObtained-GCEA-Level' ||
      type === 'CertificateofAcademicQualificationObtained:GCEA-Level' ||
      type === 'CertificateofAcademicQualification-GCEA-Level' ||
      type === 'GCEA-LevelCert_UG' ||
      type === 'GCEA-LevelCert_UGM' ||
      type === 'GCEA-LevelCert_M'
    ) {
      reportType = 'GCE A-Level Certificates';
    } else if (
      type === 'CertificateofAcademicQualificationObtained-GCEO-Level' ||
      type === 'CertificateofAcademicQualificationObtained:GCEO-Level' ||
      type === 'CertificateofAcademicQualification-GCEO-Level' ||
      type === 'GCEO-LevelCert_UG' ||
      type === 'GCEO-LevelCert_UGM' ||
      type === 'GCEO-LevelCert_M'
    ) {
      reportType = 'GCE O-Level Certificates';
    } else if (
      type === 'CertificateofAcademicQualificationObtained-GCEN-Level' ||
      type === 'CertificateofAcademicQualificationObtained:GCEN-Level' ||
      type === 'CertificateofAcademicQualification-GCEN-Level' ||
      type === 'GCEN-LevelCert_UG' ||
      type === 'GCEN-LevelCert_UGM' ||
      type === 'GCEN-LevelCert_M'
    ) {
      reportType = 'GCE N-Level Certificates';
    } else if (type === 'Sec4AcadReport') {
      reportType =
        'Secondary 4 Year End School Academic Reports (including results and teachers comments)';
    } else if (type === 'PreTertiaryCCACIP') {
      reportType = 'Pre-tertiary CCA and CIP Records';
    } else if (type === 'Sec4CCACIP') {
      reportType =
        'Secondary 4 Co-Curricular Activities (CCA) and Community Involvement Programme (CIP)';
    } else if (type === 'UniversityCCA') {
      reportType = 'University CCA Records';
    } else if (type === 'EmpTest') {
      reportType = 'Employment Testimonials';
    } else if (type === 'Internship') {
      reportType = 'Testimonials For Public Sector Internships';
    } else if (type === 'NSCert') {
      reportType = 'NS Certificate';
    } else if (type === 'UniversityResults') {
      reportType = 'University Results';
    } else if (type === 'PreTertiaryResult(Post)') {
      reportType = 'PreTertiary Result (Post)';
    } else if (
      type === 'OtherDocument1' ||
      type === 'PSC_OtherDocument1' ||
      type === 'PS_OtherDocument1'
    ) {
      reportType = 'Other Supporting Documents 1';
    } else if (
      type === 'OtherDocument2' ||
      type === 'PSC_OtherDocument2' ||
      type === 'PS_OtherDocument2'
    ) {
      reportType = 'Other Supporting Documents 2';
    } else if (type === 'PS_SummarySheet' || type === 'PSC_SummarySheet') {
      reportType = 'Summary Sheet';
    } else if (type === 'PSC_Agenda' || type === 'PS_Agenda') {
      reportType = 'Agenda';
    } else if (type === 'PS_SummarySheetForPIACPARating') {
      reportType = 'Summary Sheet for PIACPA Rating';
    } else if (type === 'PSC_ScoringSheet') {
      reportType = 'Scoring Sheet';
    } else {
      reportType = type;
    }
    return reportType;
  };

  activeDeleteCandidateReportModal = (appId, reportId) => {
    this.setState({
      appId,
      reportId,
      isOpenDeleteCandidateReportModal: true,
    });
  };

  dismissDeleteCandidateReportModal = () => {
    this.setState({
      appId: 0,
      reportId: 0,
      isOpenDeleteCandidateReportModal: false,
    });
  };

  handleDeleteCandidateReport = () => {
    const { appId, reportId } = this.state;
    this.props
      .deleteCandidateReports(appId, reportId)
      .then((res) => {
        this.dismissDeleteCandidateReportModal();
        if (res && !res.error) {
          this.renderOnMount();
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Your details have been updated successfully',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  notifMsg: '',
                  successNotif: false,
                });
              }, 5000);
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
        });
      });
  };

  activeDeleteReportModal = (appId, reporttypeId) => {
    this.setState(
      {
        isOpenDelReportModal: true,
        showWaitMsg: true,
      },
      () => {
        this.props.getViewReports(appId, reporttypeId).then((res) => {
          if (res && res.error) {
            this.setState(
              {
                showErrorMsg: true,
              },
              () => {
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            );
          } else {
            this.setState({
              showWaitMsg: false,
              redirectionUrl: `${baseUrl}/reporttypes/view?_=${new Date().getTime()}&appId=${appId}&reportId=${reporttypeId}`,
            });
          }
        });
      }
    );
  };

  dismissDeleteReportModal = () => {
    this.setState({
      isOpenDelReportModal: false,
    });
  };

  render() {
    const { loading, user, gbaDetails } = this.props || {};
    const { reportTypes } = this.state;
    const { details } = gbaDetails || {};
    const { report_urls } = details || {};
    const gbaCandidateReport =
      (report_urls &&
        Array.isArray(report_urls) &&
        report_urls.length > 0 &&
        report_urls[0]) ||
      '';
    const { roleId } = user || {};
    const {
      notifMsg,
      successNotif,
      errorNotif,
      isOpenDelReportModal,
      showErrorMsg,
      showWaitMsg,
      redirectionUrl,
      isOpenDeleteCandidateReportModal,
    } = this.state;
    let filteredReportTypes = [];
    let candSupportingReports = [];
    let candPSCReports = [];
    let candPSReports = [];
    if (reportTypes && Array.isArray(reportTypes) && reportTypes.length > 0) {
      candSupportingReports = reportTypes.filter((report) => {
        const { reporttypesName } = report || {};
        if (
          reporttypesName &&
          (reporttypesName.includes('PSC_') || reporttypesName.includes('PS_'))
        ) {
          return false;
        }
        return true;
      });
    }
    if (reportTypes && Array.isArray(reportTypes) && reportTypes.length > 0) {
      candPSCReports = reportTypes.filter((report) => {
        const { reporttypesName, appId } = report || {};
        if (reporttypesName && reporttypesName.includes('PSC_')) {
          if (
            (roleId === 2 ||
              roleId == 7 ||
              roleId === 8 ||
              roleId === 9 ||
              roleId === 10) &&
            appId
          ) {
            return true;
          } else if (
            roleId !== 7 &&
            roleId !== 8 &&
            roleId !== 9 &&
            roleId !== 10 &&
            roleId !== 2
          ) {
            return true;
          }
          return false;
        }
        return false;
      });
    }
    if (reportTypes && Array.isArray(reportTypes) && reportTypes.length > 0) {
      candPSReports = reportTypes.filter((report) => {
        const { reporttypesName, appId } = report || {};
        if (reporttypesName && reporttypesName.includes('PS_')) {
          if (
            (roleId === 2 ||
              roleId == 7 ||
              roleId === 8 ||
              roleId === 9 ||
              roleId === 10) &&
            appId
          ) {
            return true;
          } else if (
            roleId !== 7 &&
            roleId !== 8 &&
            roleId !== 9 &&
            roleId !== 10 &&
            roleId !== 2
          ) {
            return true;
          }
          return false;
        }
        return false;
      });
    }
    if (
      roleId === 2 ||
      roleId == 7 ||
      roleId === 8 ||
      roleId === 9 ||
      roleId === 10
    ) {
      if (
        candSupportingReports &&
        Array.isArray(candSupportingReports) &&
        candSupportingReports.length > 0
      ) {
        filteredReportTypes = reportTypes.filter((report) => {
          const { reporttypesName, appId } = report || {};
          if (
            reporttypesName &&
            !reporttypesName.includes('PSC_') &&
            !reporttypesName.includes('PS_') &&
            appId
          ) {
            return true;
          }
          return false;
        });
      }
    } else {
      filteredReportTypes = candSupportingReports;
    }
    return (
      <div className="some-content">
        <div className="mt-2 h6 font-weight-bold">Reports</div>
        <hr />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        <div>
          <div className="mb-3 font-weight-bold h6">
            Candidate Supporting Documents
          </div>
          <GenericDataTable
            className=""
            tbodyClass="candidate-background-color"
            otherActions={[]}
            headers={[
              { key: 'header_0', header: 'Report', className: 'w-85' },
              {
                key: 'header_1',
                header: 'Action',
              },
            ]}
            rows={
              filteredReportTypes &&
              Array.isArray(filteredReportTypes) &&
              filteredReportTypes.length > 0
                ? filteredReportTypes.map((res, idx) => {
                    const { reporttypeId, reporttypesName, appId } = res || {};
                    let hideDeleteButton = false;
                    if (reporttypesName === 'GBA Candidate Report') {
                      hideDeleteButton = true;
                    }
                    return {
                      isExpanded: false,
                      id: reporttypeId,
                      header_0: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Report:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                            <div className="">
                              {this.handleGetReportTypeLabel(reporttypesName)}
                            </div>
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                            <div className="d-flex">
                              {(roleId === 1 ||
                                roleId === 6 ||
                                roleId === 3) && (
                                <div>
                                  <FileUploaderButton
                                    buttonLabel="Upload"
                                    size="small"
                                    labelText="Upload"
                                    buttonKind="secondary"
                                    accept={['.pdf']}
                                    disableLabelChanges
                                    name="file"
                                    onChange={(e) =>
                                      this.handleUploadReportTypes(
                                        e,
                                        reporttypeId
                                      )
                                    }
                                  />
                                </div>
                              )}
                              {hideDeleteButton === true &&
                              gbaCandidateReport ? (
                                <div className="ml-2">
                                  <Button
                                    kind="primary"
                                    size="small"
                                    target="_blank"
                                    href={gbaCandidateReport}>
                                    View
                                  </Button>
                                </div>
                              ) : (
                                <Fragment>
                                  {appId && (
                                    <div className="ml-2">
                                      <Button
                                        kind="primary"
                                        size="small"
                                        // onClick={() => {
                                        //   this.activeDeleteReportModal(
                                        //     appId,
                                        //     reporttypeId
                                        //   );
                                        // }}
                                        target="_blank"
                                        href={`${baseUrl}/reporttypes/view?_=${new Date().getTime()}&appId=${appId}&reportId=${reporttypeId}`}>
                                        View
                                      </Button>
                                    </div>
                                  )}
                                </Fragment>
                              )}
                              {appId &&
                                (roleId === 1 ||
                                  roleId === 6 ||
                                  roleId === 3) &&
                                !hideDeleteButton && (
                                  <div className="ml-2">
                                    <Button
                                      size="small"
                                      onClick={() => {
                                        this.activeDeleteCandidateReportModal(
                                          appId,
                                          reporttypeId
                                        );
                                      }}
                                      kind="danger">
                                      Delete
                                    </Button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
            selectable={false}
            searchable={false}
            onSearchInputChange={() => {}}
            expandable={false}
            batchActions={false}
          />
          {((filteredReportTypes &&
            Array.isArray(filteredReportTypes) &&
            filteredReportTypes.length <= 0) ||
            (filteredReportTypes && filteredReportTypes.error)) && (
            <NoContentBox message="There are no reports available for your current selection" />
          )}
        </div>
        {
          <Fragment>
            <div>
              <div className="mb-3 mt-3 font-weight-bold h6">PSC Reports</div>
              <GenericDataTable
                className=""
                tbodyClass="candidate-background-color"
                otherActions={[]}
                headers={[
                  { key: 'header_0', header: 'Report', className: 'w-85' },
                  {
                    key: 'header_1',
                    header: 'Action',
                  },
                ]}
                rows={
                  candPSCReports &&
                  Array.isArray(candPSCReports) &&
                  candPSCReports.length > 0
                    ? candPSCReports.map((res, idx) => {
                        const { reporttypeId, reporttypesName, appId } =
                          res || {};
                        return {
                          isExpanded: false,
                          id: reporttypeId,
                          header_0: (
                            <div className="bx--row align-items-center mt-2 mt-xl-0">
                              <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                Report:
                              </div>
                              <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                <div className="">
                                  {this.handleGetReportTypeLabel(
                                    reporttypesName
                                  )}
                                </div>
                              </div>
                            </div>
                          ),
                          header_1: (
                            <div className="bx--row align-items-center mt-2 mt-xl-0">
                              <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                                <div className="d-flex">
                                  {(roleId === 1 ||
                                    roleId === 6 ||
                                    roleId === 3) && (
                                    <div>
                                      <FileUploaderButton
                                        buttonLabel="Upload"
                                        size="small"
                                        labelText="Upload"
                                        buttonKind="secondary"
                                        accept={['.pdf']}
                                        disableLabelChanges
                                        name="file"
                                        onChange={(e) =>
                                          this.handleUploadReportTypes(
                                            e,
                                            reporttypeId
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  {appId && (
                                    <div className="ml-2">
                                      <Button
                                        kind="primary"
                                        size="small"
                                        onClick={() => {
                                          this.activeDeleteReportModal(
                                            appId,
                                            reporttypeId
                                          );
                                        }}>
                                        View
                                      </Button>
                                    </div>
                                  )}
                                  {appId &&
                                    (roleId === 1 ||
                                      roleId === 6 ||
                                      roleId === 3) && (
                                      <div className="ml-2">
                                        <Button
                                          size="small"
                                          onClick={() => {
                                            this.activeDeleteCandidateReportModal(
                                              appId,
                                              reporttypeId
                                            );
                                          }}
                                          kind="danger">
                                          Delete
                                        </Button>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          ),
                        };
                      })
                    : []
                }
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
              {candPSCReports &&
                Array.isArray(candPSCReports) &&
                candPSCReports.length <= 0 && (
                  <NoContentBox message="There are no reports available for your current selection" />
                )}
            </div>
            <div>
              <div className="mb-3 mt-3 font-weight-bold h6">
                President's Scholarship Reports
              </div>
              <GenericDataTable
                className=""
                tbodyClass="candidate-background-color"
                otherActions={[]}
                headers={[
                  { key: 'header_0', header: 'Report', className: 'w-85' },
                  {
                    key: 'header_1',
                    header: 'Action',
                  },
                ]}
                rows={
                  candPSReports &&
                  Array.isArray(candPSReports) &&
                  candPSReports.length > 0
                    ? candPSReports.map((res, idx) => {
                        const { reporttypeId, reporttypesName, appId } =
                          res || {};
                        return {
                          isExpanded: false,
                          id: reporttypeId,
                          header_0: (
                            <div className="bx--row align-items-center mt-2 mt-xl-0">
                              <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                Report:
                              </div>
                              <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                <div className="">
                                  {this.handleGetReportTypeLabel(
                                    reporttypesName
                                  )}
                                </div>
                              </div>
                            </div>
                          ),
                          header_1: (
                            <div className="bx--row align-items-center mt-2 mt-xl-0">
                              <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                                <div className="d-flex">
                                  {(roleId === 1 ||
                                    roleId === 6 ||
                                    roleId === 3) && (
                                    <div>
                                      <FileUploaderButton
                                        buttonLabel="Upload"
                                        size="small"
                                        labelText="Upload"
                                        buttonKind="secondary"
                                        accept={['.pdf']}
                                        disableLabelChanges
                                        name="file"
                                        onChange={(e) =>
                                          this.handleUploadReportTypes(
                                            e,
                                            reporttypeId
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  {appId && (
                                    <div className="ml-2">
                                      <Button
                                        kind="primary"
                                        size="small"
                                        onClick={() => {
                                          this.activeDeleteReportModal(
                                            appId,
                                            reporttypeId
                                          );
                                        }}>
                                        View
                                      </Button>
                                    </div>
                                  )}
                                  {appId &&
                                    (roleId === 1 ||
                                      roleId === 6 ||
                                      roleId === 3) && (
                                      <div className="ml-2">
                                        <Button
                                          size="small"
                                          onClick={() => {
                                            this.activeDeleteCandidateReportModal(
                                              appId,
                                              reporttypeId
                                            );
                                          }}
                                          kind="danger">
                                          Delete
                                        </Button>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          ),
                        };
                      })
                    : []
                }
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
              {candPSReports &&
                Array.isArray(candPSReports) &&
                candPSReports.length <= 0 && (
                  <NoContentBox message="There are no reports available for your current selection" />
                )}
            </div>
          </Fragment>
        }

        {isOpenDelReportModal && (
          <Modal
            open={isOpenDelReportModal}
            modalHeading="Download Report"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            danger
            passiveModal
            iconDescription="Close the modal"
            onRequestClose={this.dismissDeleteReportModal}
            onRequestSubmit={() => {}}>
            {showErrorMsg === true && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div className="mb-3 text-center">
                    <WarningAlt32 fill="red" width="48px" height="48px" />
                  </div>
                  <div className="h5 f-w-b">
                    Document may have been deleted. The page will refresh
                    automatically. now.
                  </div>
                </div>
              </div>
            )}
            {showWaitMsg === true && (
              <div className="text-center bx--type-gamma f-w-b css-text-color">
                Downloading Report
              </div>
            )}
            {redirectionUrl && !showWaitMsg && (
              <div>
                <div className="font-weight-bold h6 mb-2">
                  Click here to view the report
                </div>
                <div>
                  <Button size="small" href={redirectionUrl} target="_blank">
                    View
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        )}
        {isOpenDeleteCandidateReportModal && (
          <Modal
            open={isOpenDeleteCandidateReportModal}
            modalHeading="Delete Report"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            danger
            iconDescription="Close the modal"
            onSecondarySubmit={this.dismissDeleteCandidateReportModal}
            onRequestClose={this.dismissDeleteCandidateReportModal}
            onRequestSubmit={this.handleDeleteCandidateReport}>
            <div className="mt-2 mb-2">
              Are you sure you want to delete this report?
            </div>
          </Modal>
        )}
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
  loading: state.CandidateInfo.loading,
  reportTypes: state.CandidateInfo.reportTypes,
});

const mapDispatchToProps = {
  getReportTypesByAppId,
  uploadCandidateReports,
  deleteCandidateReports,
  getViewReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateReports);
