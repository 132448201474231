import React from 'react';

//Functional Components
import NoContentBox from '../common/NoContentBox';

//Common Packages
import { Pagination } from 'carbon-components-react';
import {
  roundNumber,
  roundNumberFromTwoDecimals,
} from '../../containers/common/common';

//CSS Files
import './PSSummaryOfScores.css';

const PSPeerRatingsData = (props) => {
  const {
    psListData,
    peerActivePage,
    peerItemsPerPage,
    handlePeerPaginationChange,
    peerDetailsById,
  } = props || {};
  const { avgCohort, cvCohort, iqCohort, isCohort, lsCohort, stCohort } =
    peerDetailsById || {};
  const { peerRecords } = psListData || {};
  const { list, totalCount } = peerRecords || {};
  let finalPSListData = [];
  if (list && Array.isArray(list) && list.length > 0) {
    finalPSListData = list.filter((res) => {
      const {
        peer_ls_peer: leadershipQualitiesRating,
        peer_io_peer: intellectualQualitiesRating,
        peer_cv_peer: characterValuesRating,
        peer_is_peer: interPersonalSkills,
        peer_st_peer: stressToleranceRating,
      } = res || {};
      if (
        leadershipQualitiesRating > 0 ||
        intellectualQualitiesRating > 0 ||
        characterValuesRating > 0 ||
        interPersonalSkills > 0 ||
        stressToleranceRating > 0
      ) {
        return true;
      }
      return false;
    });
  }
  const peerSkip =
    peerActivePage === 0 ? 0 : (peerActivePage - 1) * peerItemsPerPage;
  return (
    <div className="mt-2">
      <h4 className="mt-2 mb-2">Peer Ratings</h4>
      <div className="mt-2 mb-3">(Ranked by Mean Ratings, Max = 6.0)</div>
      <div className="overflow-scroll">
        <table className="css-table-batch-info w-100 css-statistics-zoom-auto">
          <thead className="css-total-bg vertical-align-middle">
            <tr>
              <th
                className="csc-scores-rank-w css-batch-metric-border-r css-metric-table-head vertical-align-middle"
                rowspan="2">
                Rank
              </th>
              <th
                className="css-batch-metric-border-r css-metric-table-head text-left pl-2 vertical-align-middle csc-cand-name-w-peer"
                rowspan="2">
                Name
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                <div>
                  <div>Leadership</div>
                  <div className="mt-1">({roundNumber(lsCohort)})</div>
                </div>
              </th>
              <th className="css-batch-metric-border-r css-traits-border-b   css-table-head csc-traits-w vertical-align-middle">
                <div>
                  <div>Intellectual</div>
                  <div className="mt-1">({roundNumber(iqCohort)})</div>
                </div>
              </th>
              <th className="css-batch-metric-border-r css-traits-border-b   css-table-head csc-traits-w vertical-align-middle">
                <div>
                  <div>Interpersonal</div>
                  <div className="mt-1">({roundNumber(isCohort)})</div>
                </div>
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                <div>
                  <div>Character &amp; Values</div>
                  <div className="mt-1">({roundNumber(cvCohort)})</div>
                </div>
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                <div>
                  <div>Stress Tolerance &amp; Coping Ability</div>
                  <div className="mt-1">({roundNumber(stCohort)})</div>
                </div>
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                <div>Mean</div>
                <div className="mt-1">
                  ({roundNumber(avgCohort)})
                </div>
              </th>
            </tr>
          </thead>
          {finalPSListData &&
            Array.isArray(finalPSListData) &&
            finalPSListData.length > 0 && (
              <tbody>
                {finalPSListData.map((res, index) => {
                  const {
                    fullName,
                    peer_ls_peer: leadershipQualitiesRating,
                    peer_io_peer: intellectualQualitiesRating,
                    peer_cv_peer: characterValuesRating,
                    peer_is_peer: interPersonalSkills,
                    peer_st_peer: stressToleranceRating,
                    customRank,
                  } = res || {};
                  const total =
                    (leadershipQualitiesRating &&
                      Number(leadershipQualitiesRating)) +
                      (intellectualQualitiesRating &&
                        Number(intellectualQualitiesRating)) +
                      (characterValuesRating && Number(characterValuesRating)) +
                      (interPersonalSkills && Number(interPersonalSkills)) +
                      (stressToleranceRating &&
                        Number(stressToleranceRating)) || 0;
                  const mean = total > 0 ? total / 5 : 0;
                  return (
                    <tr className="text-center vertical-align-middle css-table-row-h">
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r">
                        {customRank}
                      </td>
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r css-total-f-w pl-2 text-left">
                        <div className="csc-cand-name-f-sz text-capitalize">
                          {fullName || '-'}
                        </div>
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(leadershipQualitiesRating) <
                            roundNumber(lsCohort) &&
                          leadershipQualitiesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(leadershipQualitiesRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(intellectualQualitiesRating) <
                            roundNumber(iqCohort) &&
                          intellectualQualitiesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(intellectualQualitiesRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(interPersonalSkills) <
                            roundNumber(isCohort) && interPersonalSkills > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(interPersonalSkills, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(characterValuesRating) <
                            roundNumber(cvCohort) && characterValuesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(characterValuesRating, '-')}
                      </td>

                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(stressToleranceRating) <
                            roundNumber(stCohort) && stressToleranceRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(stressToleranceRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(mean) <
                            roundNumberFromTwoDecimals(avgCohort) && mean > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(mean, '-')}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
        </table>
      </div>
      {((finalPSListData &&
        Array.isArray(finalPSListData) &&
        finalPSListData.length <= 0) ||
        !finalPSListData) && (
        <NoContentBox
          hideTile
          message="There are no details available for your current selection"
        />
      )}
      {totalCount > 0 && (
        <Pagination
          onChange={handlePeerPaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={peerActivePage}
          totalItems={totalCount}
          pageSize={peerItemsPerPage}
        />
      )}
      {finalPSListData &&
        Array.isArray(finalPSListData) &&
        finalPSListData.length > 0 && (
          <div
            className="css-default-text-color f-w-b mb-2 mt-3 text-justify"
            style={{ lineHeight: 1.3 }}>
            ( ) refers to cohort mean. Ratings below cohort mean are shaded.
          </div>
        )}
      <hr />
    </div>
  );
};

export default PSPeerRatingsData;
