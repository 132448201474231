import React, { Component } from 'react';
import { connect } from 'react-redux';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericTabs from '../../components/common/GenericTabs';
import FilterFacets from '../../components/private/FilterFacets';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../components/private/SuccErrNotification';
//Common Packages
import {
  Tile,
  Search,
  Form,
  Button,
  FileUploaderButton,
} from 'carbon-components-react';

import {
  getCandidateListData,
  uploadCandidatesData,
} from '../../actions/CandidateList';
import SampleCandidateTemp from '../../assets/files/Candidate_Template_ARS_v1.xlsx';
import { REQUEST_GET_CANDIDATE_SEARCH_LIST } from '../../actions/actionTypes';

//Functional common components
import CandidateListCommon from '../../components/private/CandidateListCommon';

//Common Class Components
import CompareApplications from './CompareApplications';

class SearchCandidatesList extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'search-info', name: 'Search Candidates', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      activePage: 1,
      itemsPerPage: 10,
      showSearchList: false,
    };
  }

  handleGetCandidateListApi = (appIdsToBeFiltered) => {
    const {
      activePage,
      itemsPerPage,
      searchKey,
      year,
      leadership_ccatier,
      representation_ccatier,
      pm_wg,
      pm_ranra,
      pb_gba,
      fd_gba,
      pi_leadership_qualities,
      pi_intellectual_qualities,
      pi_character_and_values,
      pi_interpersonal_skills,
      pi_resilience,
      award_gba,
      avg_gba,
    } = this.state;
    const data = {
      activePage,
      itemsPerPage,
      searchKey,
      appIds: appIdsToBeFiltered,
    };
    if (year) {
      data.year = year;
    }
    if (
      leadership_ccatier &&
      Array.isArray(leadership_ccatier) &&
      leadership_ccatier.length > 0
    ) {
      data.leadership_ccatier = leadership_ccatier;
    }
    if (
      representation_ccatier &&
      Array.isArray(representation_ccatier) &&
      representation_ccatier.length > 0
    ) {
      data.representation_ccatier = representation_ccatier;
    }
    if (pm_wg && Array.isArray(pm_wg) && pm_wg.length > 0) {
      data.pm_wg = pm_wg;
    }
    if (pm_ranra && Array.isArray(pm_ranra) && pm_ranra.length > 0) {
      data.pm_ranra = pm_ranra;
    }
    if (pb_gba && Array.isArray(pb_gba) && pb_gba.length > 0) {
      data.pb_gba = pb_gba;
    }
    if (fd_gba && Array.isArray(fd_gba) && fd_gba.length > 0) {
      data.fd_gba = fd_gba;
    }
    if (award_gba && Array.isArray(award_gba) && award_gba.length > 0) {
      data.award_gba = award_gba;
    }
    if (avg_gba && Array.isArray(avg_gba) && avg_gba.length > 0) {
      data.avg_gba = avg_gba;
    }
    if (
      pi_leadership_qualities &&
      Array.isArray(pi_leadership_qualities) &&
      pi_leadership_qualities.length > 0
    ) {
      data.pi_leadership_qualities = pi_leadership_qualities;
    }
    if (
      pi_intellectual_qualities &&
      Array.isArray(pi_intellectual_qualities) &&
      pi_intellectual_qualities.length > 0
    ) {
      data.pi_intellectual_qualities = pi_intellectual_qualities;
    }
    if (
      pi_character_and_values &&
      Array.isArray(pi_character_and_values) &&
      pi_character_and_values.length > 0
    ) {
      data.pi_character_and_values = pi_character_and_values;
    }
    if (
      pi_interpersonal_skills &&
      Array.isArray(pi_interpersonal_skills) &&
      pi_interpersonal_skills.length > 0
    ) {
      data.pi_interpersonal_skills = pi_interpersonal_skills;
    }
    if (
      pi_resilience &&
      Array.isArray(pi_resilience) &&
      pi_resilience.length > 0
    ) {
      data.pi_resilience = pi_resilience;
    }
    this.props.getCandidateListData(
      data,
      null,
      REQUEST_GET_CANDIDATE_SEARCH_LIST
    );
  };

  handleTabClick = (tabNumber) => {
    this.setState({
      selectedTab: tabNumber,
    });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        showSearchList: true,
      },
      () => {
        this.handleGetCandidateListApi();
      }
    );
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetCandidateListApi();
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOnChangeCheckboxFilter = (e, traitKey, key) => {
    let filterArr = this.state[key] || [];
    if (e === true) {
      if (filterArr && Array.isArray(filterArr) && filterArr.length > 0) {
        filterArr.push(traitKey);
      } else {
        filterArr = [traitKey];
      }
    } else if (e === false) {
      filterArr = filterArr.filter((r) => r !== traitKey);
    }
    this.setState(
      {
        [key]: filterArr,
        showSearchList: true,
      },
      () => {
        this.handleGetCandidateListApi();
      }
    );
  };

  handleClearFilters = () => {
    this.setState(
      {
        leadership_ccatier: [],
        representation_ccatier: [],
        pm_wg: [],
        pm_ranra: [],
        pb_gba: [],
        fd_gba: [],
        pi_leadership_qualities: [],
        pi_intellectual_qualities: [],
        pi_character_and_values: [],
        pi_interpersonal_skills: [],
        pi_resilience: [],
        award_gba: [],
        avg_gba: [],
      },
      () => {
        this.handleGetCandidateListApi();
      }
    );
  };

  handleAddCandidatesToCompareAppl = (appId) => {
    const localStorageItem = localStorage.getItem('application-ids');
    const parsedLocalStorageItem =
      (localStorageItem && JSON.parse(localStorageItem)) || [];
    if (
      parsedLocalStorageItem &&
      Array.isArray(parsedLocalStorageItem) &&
      parsedLocalStorageItem.length > 0
    ) {
      parsedLocalStorageItem.push(appId);
      localStorage.setItem(
        'application-ids',
        JSON.stringify(parsedLocalStorageItem)
      );
    } else {
      localStorage.setItem('application-ids', JSON.stringify([appId]));
    }
    this.setState(
      {
        successNotif: true,
        notifMsg: 'The candidate is added successfully to the compared list.',
      },
      () => {
        this.handleGetCandidateListApi();
        setTimeout(() => {
          this.setState({
            successNotif: false,
            notifMsg: null,
          });
        }, 5000);
      }
    );
  };

  handleRemoveCandidatesFromCompare = (appId) => {
    const localStorageItem = localStorage.getItem('application-ids');
    const parsedLocalStorageItem =
      (localStorageItem && JSON.parse(localStorageItem)) || [];
    if (
      parsedLocalStorageItem &&
      Array.isArray(parsedLocalStorageItem) &&
      parsedLocalStorageItem.length > 0
    ) {
      let finalParsedAPpIds = parsedLocalStorageItem.filter(
        (res) => res !== appId
      );
      localStorage.setItem(
        'application-ids',
        JSON.stringify(finalParsedAPpIds)
      );
    } else {
      localStorage.removeItem('application-ids');
    }
    this.setState(
      {
        successNotif: true,
        notifMsg:
          'The candidate is successfully removed from the compared list.',
      },
      () => {
        this.handleGetCandidateListApi();
        setTimeout(() => {
          this.setState({
            successNotif: false,
            notifMsg: null,
          });
        }, 5000);
      }
    );
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleUploadCandidates = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('fileData', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadCandidatesData(currFile)
        .then((res) => {
          if (res && !res.error) {
            const { list } = res || {};
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                this.handleGetCandidateListApi(list);
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  render() {
    const { candidateData, candidateCount, loading } = this.props;
    const {
      selectedTab,
      activePage,
      searchKey,
      showSearchList,
      successNotif,
      errorNotif,
      notifMsg,
    } = this.state;
    return (
      <div>
        <BackRow paths={this.paths} />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-3">
          <GenericTabs
            selected={selectedTab}
            tabContent={[
              {
                label: 'Search Candidates',
                value: '',
                onClick: () => {
                  this.handleTabClick(0);
                },
              },
              {
                label: 'Candidate Comparision',
                value: '',
                onClick: () => {
                  this.handleTabClick(1);
                },
              },
            ]}
          />
          <div className="d-flex mt-3 mt-lg-0">
            <div className="text-right">
              <FileUploaderButton
                buttonLabel="Upload"
                labelText="Upload"
                buttonKind="primary"
                accept={['.xlsx']}
                disableLabelChanges
                name="file"
                onChange={(e) => this.handleUploadCandidates(e)}
              />
              <div className="small mt-2">.xlsx format is allowed</div>
            </div>
            <div className="ml-2">
              <Button
                size="small"
                className="txt-dec-none"
                kind="primary"
                href={SampleCandidateTemp}>
                Download Template
              </Button>
            </div>
          </div>
        </div>

        {selectedTab === 0 && (
          <div className="bx--row">
            <div className="bx--col-lg-3 mt-2 bx--col-md-3 bx--col-xs-12 bx--col-sm-12">
              <FilterFacets
                handleOnChangeCheckboxFilter={this.handleOnChangeCheckboxFilter}
                state={this.state}
                handleClearFilters={this.handleClearFilters}
              />
            </div>
            <div className="bx--col-lg-9 bx--col-md-5 bx--col-xs-12 bx--col-sm-12 mt-2">
              <Tile className="css-search-height">
                <h4 className="">Search Candidates</h4>
                <Form onSubmit={this.handleSearchSubmit}>
                  <div className="bx--row justify-content-center">
                    <div className="bx--col-lg-10 mt-3">
                      <Search
                        id="search-1"
                        labelText="Search"
                        name="searchKey"
                        onChange={this.handleChange}
                        placeHolderText="Search by Candidate's Name / NRIC / Batch"
                        size="xl"
                        type="text"
                        value={searchKey}
                      />
                    </div>
                    <div className="bx--col-lg-2 mt-3">
                      <Button onClick={this.handleSearchSubmit} kind="primary">
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
                <div className="mt-2">
                  {showSearchList === true && (
                    <CandidateListCommon
                      candidateData={candidateData}
                      activePage={activePage}
                      candidateCount={candidateCount}
                      handlePaginationChange={this.handlePaginationChange}
                      hideSearchYear
                      handleAddCandidatesToCompareAppl={
                        this.handleAddCandidatesToCompareAppl
                      }
                      handleRemoveCandidatesFromCompare={
                        this.handleRemoveCandidatesFromCompare
                      }
                    />
                  )}
                </div>
              </Tile>
            </div>
          </div>
        )}
        {selectedTab === 1 && <CompareApplications />}
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  candidateData: state.CandidateList.candidateSearchData,
  candidateCount: state.CandidateList.candidateSearchCount,
  loading: state.CandidateList.loading,
});

const mapDispatchToProps = {
  getCandidateListData,
  uploadCandidatesData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchCandidatesList);
