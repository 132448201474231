import React, { Fragment } from 'react';
import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  TooltipIcon,
} from 'carbon-components-react';
import { Information20 } from '@carbon/icons-react';
import './CurrentBatchInfo.css';

const CurrentBatchInfo = (props) => {
  const { fullYear, hideMainContent, batchStatistics, selectedTab } =
    props || {};
  const { currentYear, previousYear, ytdPrevYear } = batchStatistics || {};
  const {
    year: currYr,
    details: currYearDetails,
    acceptanceRate: currAcceptanceRate,
  } = currentYear || {};
  const {
    total: currTotal,
    offered: currOffered,
    interviewed: currInterviewed,
    accepted: currAccepted,
  } = currYearDetails || {};
  const currInterviewdRate =
    ((currInterviewed && Number(currInterviewed)) /
      (currTotal && Number(currTotal))) *
    100;
  const currOfferedRate =
    ((currOffered && Number(currOffered)) /
      (currInterviewed && Number(currInterviewed))) *
    100;
  const { year: prevYearFull, details: prevYearFullDetails } =
    previousYear || {};
  const {
    interviewed: prevYearFullInterviewed,
    offered: prevYearFullOffered,
    accepted: prevYearFullAccepted,
    total: prevYearInterviewedSoFarDetails,
  } = prevYearFullDetails || {};
  const {
    year: prevYear,
    details: prevYearDetails,
    interviewed: prevYearInt,
    offered: prevYearOffered,
    accepted: prevYearAccepted,
  } = ytdPrevYear || {};
  const { total: prevYearTotal } = prevYearDetails || {};
  const prevYearIntRate =
    prevYearTotal != 0
      ? ((prevYearInt && Number(prevYearInt)) /
          (prevYearTotal && Number(prevYearTotal))) *
        100
      : 0;
  const prevOfferedRate =
    ((prevYearOffered && Number(prevYearOffered)) /
      (prevYearInt && Number(prevYearInt))) *
      100 || 0;
  const prevYearAcceptanceRate =
    ((prevYearAccepted && Number(prevYearAccepted)) /
      (prevYearOffered && Number(prevYearOffered))) *
      100 || 0;
  return (
    <Fragment>
      <div className="d-none d-md-block">
        <StructuredListWrapper
          ariaLabel="Batch"
          className="mb-0"
          selection={false}>
          <StructuredListHead>
            <StructuredListRow head label={false} tabIndex={0}>
              <StructuredListCell head noWrap={false}>
                <h4>Selection Year</h4>
              </StructuredListCell>
              {/* <StructuredListCell head noWrap={false}>
              <h4 className="text-center"> Total to be interviewed</h4>
            </StructuredListCell> */}
              <StructuredListCell head noWrap={false}>
                <h4 className="text-center">
                  {selectedTab === 0 ? 'Interviewed' : 'Total Interviewed'}
                </h4>
              </StructuredListCell>
              <StructuredListCell head noWrap={false}>
                <h4 className="text-center">
                  {selectedTab === 0 ? 'Offered' : 'Total Offered'}
                </h4>
              </StructuredListCell>
              <StructuredListCell head noWrap={false}>
                <h4 className="text-center">
                  {selectedTab === 0 ? 'Accepted' : 'Total Accepted'}
                </h4>
              </StructuredListCell>
            </StructuredListRow>
          </StructuredListHead>
          {hideMainContent !== true ? (
            <StructuredListBody>
              <StructuredListRow head={false} label={false} tabIndex={0}>
                <StructuredListCell head={false} noWrap>
                  <div className="mr-1 bx--type-delta f-w-b font-weight-light xpa-link">
                    {currYr} {selectedTab === 0 ? '' : null}
                  </div>
                </StructuredListCell>
                {/* <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  {currTotal || 0}
                </div>
              </StructuredListCell> */}
                <StructuredListCell head={false} noWrap={false}>
                  <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                    {currInterviewed || 0}
                    {/* ({Math.round(currInterviewdRate) || 0}
                  %) */}
                  </div>
                </StructuredListCell>
                <StructuredListCell head={false} noWrap={false}>
                  <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                    <span>{currOffered || 0}</span>
                    <span className="pl-2 pr-2">
                      ({Math.round(currOfferedRate) || 0}%)
                    </span>
                    {currYr > 2020 && (
                      <TooltipIcon
                        tooltipText={
                          <div>
                            <div className="f-w-b mb-1">
                              Offered (vs Offered Rate,%)
                            </div>
                            Offered Rate = (Offered/Interviewed)*100
                          </div>
                        }
                        className="css-tooltip-fill">
                        {' '}
                        <Information20 />{' '}
                      </TooltipIcon>
                    )}
                  </div>
                </StructuredListCell>
                <StructuredListCell head={false} noWrap={false}>
                  <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                    <span>{currAccepted || 0}</span>
                    <span className="pl-2 pr-2">
                      (
                      {(currAcceptanceRate && Math.round(currAcceptanceRate)) ||
                        0}
                      %)
                    </span>
                    {currYr > 2020 && (
                      <TooltipIcon
                        tooltipText={
                          <div>
                            <div className="f-w-b mb-1">
                              Accepted (vs Acceptance Rate,%)
                            </div>
                            Acceptance Rate = (Accepted/Offered)*100
                          </div>
                        }
                        className="css-tooltip-fill">
                        {' '}
                        <Information20 />{' '}
                      </TooltipIcon>
                    )}
                  </div>
                </StructuredListCell>
              </StructuredListRow>
              {currYr > 2020 && (
                <StructuredListRow head={false} label={false} tabIndex={0}>
                  <StructuredListCell head={false} noWrap>
                    <div className="mr-1 bx--type-delta f-w-b font-weight-light xpa-link">
                      {prevYearFull} (Corresponding Stage)
                    </div>
                  </StructuredListCell>
                  {/* <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  {prevYearTotal}
                </div>
              </StructuredListCell> */}
                  <StructuredListCell head={false} noWrap={false}>
                    <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                      {prevYearInt}
                      {/* ({prevYearIntRate ? Number(prevYearIntRate) : 0}
                  %) */}
                    </div>
                  </StructuredListCell>
                  <StructuredListCell head={false} noWrap={false}>
                    <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                      <span>{prevYearOffered}</span>
                      <span className="pl-2 pr-2">
                        (
                        {prevOfferedRate
                          ? Math.round(Number(prevOfferedRate))
                          : 0}
                        %)
                      </span>
                      {currYr > 2020 && (
                        <TooltipIcon
                          tooltipText={
                            <div>
                              <div className="f-w-b mb-1">
                                Offered (vs Offered Rate,%)
                              </div>
                              Offered Rate = (Offered/Interviewed)*100
                            </div>
                          }
                          className="css-tooltip-fill">
                          {' '}
                          <Information20 />{' '}
                        </TooltipIcon>
                      )}
                    </div>
                  </StructuredListCell>
                  <StructuredListCell head={false} noWrap={false}>
                    <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                      <span>{prevYearAccepted}</span>
                      <span className="pl-2 pr-2">
                        (
                        {prevYearAcceptanceRate
                          ? Math.round(Number(prevYearAcceptanceRate))
                          : 0}
                        %)
                      </span>
                      {currYr > 2020 && (
                        <TooltipIcon
                          tooltipText={
                            <div>
                              <div className="f-w-b mb-1">
                                Accepted (vs Acceptance Rate,%)
                              </div>
                              Acceptance Rate = (Accepted/Offered)*100
                            </div>
                          }
                          className="css-tooltip-fill">
                          {' '}
                          <Information20 />{' '}
                        </TooltipIcon>
                      )}
                    </div>
                  </StructuredListCell>
                </StructuredListRow>
              )}
              {currYr > 2020 && (
                <StructuredListRow head={false} label={false} tabIndex={0}>
                  <StructuredListCell head={false} noWrap>
                    <div className="mr-1 bx--type-delta f-w-b font-weight-light xpa-link">
                      {prevYearFull || 0} (Full Year)
                    </div>
                  </StructuredListCell>
                  {/* <StructuredListCell head={false} noWrap={false}>
                <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                  {prevYearInterviewedSoFarDetails || 0}
                </div>
              </StructuredListCell> */}
                  <StructuredListCell head={false} noWrap={false}>
                    <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                      {prevYearFullInterviewed || 0}
                    </div>
                  </StructuredListCell>
                  <StructuredListCell head={false} noWrap={false}>
                    <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                      {prevYearFullOffered || 0}
                    </div>
                  </StructuredListCell>
                  <StructuredListCell head={false} noWrap={false}>
                    <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                      {prevYearFullAccepted || 0}
                    </div>
                  </StructuredListCell>
                </StructuredListRow>
              )}
            </StructuredListBody>
          ) : (
            <StructuredListBody>
              <StructuredListRow head={false} label={false} tabIndex={0}>
                <StructuredListCell head={false} noWrap>
                  <div className="mr-1 bx--type-delta f-w-b font-weight-light xpa-link">
                    {fullYear || 0} (Full Year)
                  </div>
                </StructuredListCell>
                <StructuredListCell
                  head={false}
                  noWrap={false}></StructuredListCell>
                <StructuredListCell head={false} noWrap={false}>
                  <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                    280
                  </div>
                </StructuredListCell>
                <StructuredListCell head={false} noWrap={false}>
                  <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                    100
                  </div>
                </StructuredListCell>
                <StructuredListCell head={false} noWrap={false}>
                  <div className="mr-1 bx--type-beta font-weight-light xpa-link text-center">
                    92
                  </div>
                </StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          )}
        </StructuredListWrapper>
      </div>
      <div className="mb-0 d-block d-md-none">
        {hideMainContent !== true ? (
          <div class="bx--tile mb-2 mt-3">
            <div class="bx--row align-items-center">
              <div class="bx--col-lg-3">
                <div>
                  <div class="xpa-name-overflow xpa-link mt-1">
                    <h4>
                      Selection Year {currYr} {selectedTab === 0 ? '' : null}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="bx--col-lg-8">
                <div class="bx--row justify-content-around pb-0 pl-0 pr-0 ml-0 mr-0">
                  <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                    <div class="mr-2 mb-2 datalist__cnt--total1">
                      <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                        {currInterviewed || 0}
                      </div>
                      <div class="text-dark">
                        {selectedTab === 0
                          ? 'Interviewed'
                          : 'Total Interviewed'}
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                    <div class="mr-2 mb-2 datalist__cnt--screen1">
                      <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                        <span>{currOffered || 0}</span>
                        <span className="pl-2 pr-2">
                          ({Math.round(currOfferedRate) || 0}%)
                        </span>
                        {currYr > 2020 && (
                          <TooltipIcon
                            tooltipText={
                              <div>
                                <div className="f-w-b mb-1">
                                  Offered (vs Offered Rate,%)
                                </div>
                                Offered Rate = (Offered/Interviewed)*100
                              </div>
                            }
                            className="css-tooltip-fill">
                            {' '}
                            <Information20 />{' '}
                          </TooltipIcon>
                        )}
                      </div>
                      <div class="text-dark">
                        {selectedTab === 0 ? 'Offered' : 'Total Offered'}
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                    <div class="mr-2 mb-2 datalist__cnt--hired1">
                      <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                        <span>{currAccepted || 0}</span>
                        <span className="pl-2 pr-2">
                          (
                          {(currAcceptanceRate &&
                            Math.round(currAcceptanceRate)) ||
                            0}
                          %)
                        </span>
                        {currYr > 2020 && (
                          <TooltipIcon
                            tooltipText={
                              <div>
                                <div className="f-w-b mb-1">
                                  Accepted (vs Acceptance Rate,%)
                                </div>
                                Acceptance Rate = (Accepted/Offered)*100
                              </div>
                            }
                            className="css-tooltip-fill">
                            {' '}
                            <Information20 />{' '}
                          </TooltipIcon>
                        )}
                      </div>
                      <div class="text-dark">
                        {selectedTab === 0 ? 'Accepted' : 'Total Accepted'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {currYr > 2020 && (
              <>
                <hr />
                <div class="bx--row align-items-center">
                  <div class="bx--col-lg-3">
                    <div>
                      <div class="xpa-name-overflow xpa-link mt-1">
                        <h4>
                          Selection Year {prevYearFull}
                          <div className="">(Corresponding Stage)</div>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="bx--col-lg-8">
                    <div class="bx--row justify-content-around pb-0 pl-0 pr-0 ml-0 mr-0">
                      <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                        <div class="mr-2 mb-2 datalist__cnt--total1">
                          <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                            {prevYearInt}
                          </div>
                          <div class="text-dark">
                            {selectedTab === 0
                              ? 'Interviewed'
                              : 'Total Interviewed'}
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                        <div class="mr-2 mb-2 datalist__cnt--screen1">
                          <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                            <span>{prevYearOffered}</span>
                            <span className="pl-2 pr-2">
                              (
                              {prevOfferedRate
                                ? Math.round(Number(prevOfferedRate))
                                : 0}
                              %)
                            </span>
                            {currYr > 2020 && (
                              <TooltipIcon
                                tooltipText={
                                  <div>
                                    <div className="f-w-b mb-1">
                                      Offered (vs Offered Rate,%)
                                    </div>
                                    Offered Rate = (Offered/Interviewed)*100
                                  </div>
                                }
                                className="css-tooltip-fill">
                                {' '}
                                <Information20 />{' '}
                              </TooltipIcon>
                            )}
                          </div>
                          <div class="text-dark">
                            {selectedTab === 0 ? 'Offered' : 'Total Offered'}
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                        <div class="mr-2 mb-2 datalist__cnt--hired1">
                          <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                            <span>{prevYearAccepted}</span>
                            <span className="pl-2 pr-2">
                              (
                              {prevYearAcceptanceRate
                                ? Math.round(Number(prevYearAcceptanceRate))
                                : 0}
                              %)
                            </span>
                            {currYr > 2020 && (
                              <TooltipIcon
                                tooltipText={
                                  <div>
                                    <div className="f-w-b mb-1">
                                      Accepted (vs Acceptance Rate,%)
                                    </div>
                                    Acceptance Rate = (Accepted/Offered)*100
                                  </div>
                                }
                                className="css-tooltip-fill">
                                {' '}
                                <Information20 />{' '}
                              </TooltipIcon>
                            )}
                          </div>
                          <div class="text-dark">
                            {selectedTab === 0 ? 'Accepted' : 'Total Accepted'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {currYr > 2020 && (
              <>
                <hr />
                <div class="bx--row align-items-center">
                  <div class="bx--col-lg-3">
                    <div>
                      <div class="xpa-name-overflow xpa-link mt-1">
                        <h4>Selection Year {prevYearFull || 0} (Full Year)</h4>
                      </div>
                    </div>
                  </div>

                  <div class="bx--col-lg-8">
                    <div class="bx--row justify-content-around pb-0 pl-0 pr-0 ml-0 mr-0">
                      <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                        <div class="mr-2 mb-2 datalist__cnt--total1">
                          <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                            {prevYearFullInterviewed || 0}
                          </div>
                          <div class="text-dark">
                            {selectedTab === 0
                              ? 'Interviewed'
                              : 'Total Interviewed'}
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                        <div class="mr-2 mb-2 datalist__cnt--screen1">
                          <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                            {prevYearFullOffered || 0}
                          </div>
                          <div class="text-dark">
                            {selectedTab === 0 ? 'Offered' : 'Total Offered'}
                          </div>
                        </div>
                      </div>
                      <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                        <div class="mr-2 mb-2 datalist__cnt--hired1">
                          <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                            {prevYearFullAccepted || 0}
                          </div>
                          <div class="text-dark">
                            {selectedTab === 0 ? 'Accepted' : 'Total Accepted'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div class="bx--tile mb-2 mt-3">
            <div class="bx--row align-items-center">
              <div class="bx--col-lg-3">
                <div>
                  <div class="xpa-name-overflow xpa-link mt-1">
                    <h4>Selection Year {fullYear || 0} (Full Year)</h4>
                  </div>
                </div>
              </div>
              <div class="bx--col-lg-8">
                <div class="bx--row justify-content-around pb-0 pl-0 pr-0 ml-0 mr-0">
                  <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                    <div class="mr-2 mb-2 datalist__cnt--total1">
                      <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                        280
                      </div>
                      <div class="text-dark">
                        {selectedTab === 0
                          ? 'Interviewed'
                          : 'Total Interviewed'}
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                    <div class="mr-2 mb-2 datalist__cnt--screen1">
                      <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                        100
                      </div>
                      <div class="text-dark">
                        {selectedTab === 0 ? 'Offered' : 'Total Offered'}
                      </div>
                    </div>
                  </div>
                  <div class="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-4">
                    <div class="mr-2 mb-2 datalist__cnt--hired1">
                      <div class="mr-1 bx--type-beta font-weight-light xpa-link">
                        92
                      </div>
                      <div class="text-dark">
                        {selectedTab === 0 ? 'Accepted' : 'Total Accepted'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CurrentBatchInfo;
