import {
  REQUEST_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA,
  SUCCESS_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA,
  ERROR_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA,
  REQUEST_GET_VIDEO_INTERVIEW_RATING_CRITERIA,
  SUCCESS_GET_VIDEO_INTERVIEW_RATING_CRITERIA,
  ERROR_GET_VIDEO_INTERVIEW_RATING_CRITERIA,
  REQUEST_CREATE_CANDIDATE_EVALUATION,
  SUCCESS_CREATE_CANDIDATE_EVALUATION,
  ERROR_CREATE_CANDIDATE_EVALUATION,
  REQUEST_GET_CANDIDATE_EVALUATION,
  SUCCESS_GET_CANDIDATE_EVALUATION,
  ERROR_GET_CANDIDATE_EVALUATION,
  REQUEST_UPSERT_CANDIDATE_EVALUATION,
  SUCCESS_UPSERT_CANDIDATE_EVALUATION,
  ERROR_UPSERT_CANDIDATE_EVALUATION,
  REQUEST_GET_VIDEO_INTERVIEW_DETAILS,
  SUCCESS_GET_VIDEO_INTERVIEW_DETAILS,
  ERROR_GET_VIDEO_INTERVIEW_DETAILS,
  REQUEST_ASSIGN_CSC_USER_VI,
  SUCCESS_ASSIGN_CSC_USER_VI,
  ERROR_ASSIGN_CSC_USER_VI,
  REQUEST_GET_ASSIGNED_CANDIDATE_VI_LIST,
  SUCCESS_GET_ASSIGNED_CANDIDATE_VI_LIST,
  ERROR_GET_ASSIGNED_CANDIDATE_VI_LIST,
  REQUEST_GET_GBA_DETAILS,
  SUCCESS_GET_GBA_DETAILS,
  ERROR_GET_GBA_DETAILS,
  REQUEST_UPSERT_CANDIDATE_DETAILS_BY_ID,
  SUCCESS_UPSERT_CANDIDATE_DETAILS_BY_ID,
  ERROR_UPSERT_CANDIDATE_DETAILS_BY_ID,
  REQUEST_GET_REPORT_TYPES_BY_APP_ID,
  SUCCESS_GET_REPORT_TYPES_BY_APP_ID,
  ERROR_GET_REPORT_TYPES_BY_APP_ID,
  REQUEST_UPLOAD_CANDIDATE_REPORTS,
  SUCCESS_UPLOAD_CANDIDATE_REPORTS,
  ERROR_UPLOAD_CANDIDATE_REPORTS,
  REQUEST_DELETE_CANDIDATE_REPORT,
  SUCCESS_DELETE_CANDIDATE_REPORT,
  ERROR_DELETE_CANDIDATE_REPORT,
  REQUEST_VIEW_UPLOADED_REPORTS,
  SUCCESS_VIEW_UPLOADED_REPORTS,
  ERROR_VIEW_UPLOADED_REPORTS,
  REQUEST_DOWNLOAD_CANDIDATE_REPORT,
  SUCCESS_DOWNLOAD_CANDIDATE_REPORT,
  ERROR_DOWNLOAD_CANDIDATE_REPORT,
  REQUEST_CREATE_CANDIDATE_EVALUATION_PS,
  SUCCESS_CREATE_CANDIDATE_EVALUATION_PS,
  ERROR_CREATE_CANDIDATE_EVALUATION_PS,
  REQUEST_GET_CANDIDATE_EVALUATION_PS,
  SUCCESS_GET_CANDIDATE_EVALUATION_PS,
  ERROR_GET_CANDIDATE_EVALUATION_PS,
  REQUEST_UPSERT_CANDIDATE_EVALUATION_PS,
  SUCCESS_UPSERT_CANDIDATE_EVALUATION_PS,
  ERROR_UPSERT_CANDIDATE_EVALUATION_PS,
  REQUEST_GET_PSAC_PEER_PS_DATA,
  SUCCESS_GET_PSAC_PEER_PS_DATA,
  ERROR_GET_PSAC_PEER_PS_DATA,
  REQUEST_GET_PSAC_PEER_PS_DATA_BY_ID,
  SUCCESS_GET_PSAC_PEER_PS_DATA_BY_ID,
  ERROR_GET_PSAC_PEER_PS_DATA_BY_ID,
  REQUEST_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT,
  SUCCESS_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT,
  ERROR_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT,
  REQUEST_UPDATE_VID_INT_COMMENTS,
  SUCCESS_UPDATE_VID_INT_COMMENTS,
  ERROR_UPDATE_VID_INT_COMMENTS,
  REQUEST_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA,
  SUCCESS_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA,
  ERROR_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA,
  REQUEST_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES,
  SUCCESS_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES,
  ERROR_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES,
  REQUEST_GET_CAND_ASSESS_STATUS,
  SUCCESS_GET_CAND_ASSESS_STATUS,
  ERROR_GET_CAND_ASSESS_STATUS,
  REQUEST_GET_LEAP_DETAILS,
  SUCCESS_GET_LEAP_DETAILS,
  ERROR_GET_LEAP_DETAILS,
  REQUEST_GET_ALL_SCORES,
  SUCCESS_GET_ALL_SCORES,
  ERROR_GET_ALL_SCORES,
  REQUEST_GET_PS_TSCORES_IMAGES,
  SUCCESS_GET_PS_TSCORES_IMAGES,
  ERROR_GET_PS_TSCORES_IMAGES,
  REQUEST_TRIGGER_RETAKE_FOR_VI,
  SUCCESS_TRIGGER_RETAKE_FOR_VI,
  ERROR_TRIGGER_RETAKE_FOR_VI,
  REQUEST_GET_PERSONAL_SUMMARY,
  SUCCESS_GET_PERSONAL_SUMMARY,
  ERROR_GET_PERSONAL_SUMMARY,
  REQUEST_SEARCH_AI_INSIGHTS,
  SUCCESS_SEARCH_AI_INSIGHTS,
  ERROR_SEARCH_AI_INSIGHTS,
  REQUEST_POST_FEEDBACK_AI_INSIGHTS,
  SUCCESS_POST_FEEDBACK_AI_INSIGHTS,
  ERROR_POST_FEEDBACK_AI_INSIGHTS,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  viCriteriaInfo: {},
  viCriteriaInfoList: [],
  evaluationList: [],
  videoInterviewRes: {},
  assignedCandidateList: [],
  assignedCount: 0,
  gbaDetails: {},
  panelInterviewers: [],
  reportTypes: [],
  psEvaluationList: [],
  psListData: [],
  psSummaryData: {},
  assessFeedbackResponseObj: {},
  assessStatusDetails: [],
  leapDetails: {},
  candScoresObj: {},
  personalSummary: {},
};

const CandidateInfo = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUCCESS_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        viCriteriaInfoList: payload && payload.list,
        viCriteriaInfo: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_CANDIDATE_EVALUATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_CANDIDATE_EVALUATION:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_CANDIDATE_EVALUATION:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CANDIDATE_EVALUATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_EVALUATION:
      return {
        ...state,
        evaluationList: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_EVALUATION:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_CANDIDATE_EVALUATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_CANDIDATE_EVALUATION:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_CANDIDATE_EVALUATION:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_VIDEO_INTERVIEW_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_VIDEO_INTERVIEW_DETAILS:
      return {
        ...state,
        videoInterviewRes: payload && payload.videoInterviewResponse,
        loading: false,
        error: null,
      };
    case ERROR_GET_VIDEO_INTERVIEW_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ASSIGN_CSC_USER_VI:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ASSIGN_CSC_USER_VI:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ASSIGN_CSC_USER_VI:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSIGNED_CANDIDATE_VI_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSIGNED_CANDIDATE_VI_LIST:
      return {
        ...state,
        assignedCandidateList: payload && payload.list,
        assignedCount: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSIGNED_CANDIDATE_VI_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_GBA_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_GBA_DETAILS:
      return {
        ...state,
        gbaDetails: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_GBA_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_CANDIDATE_DETAILS_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_CANDIDATE_DETAILS_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_CANDIDATE_DETAILS_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_REPORT_TYPES_BY_APP_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_REPORT_TYPES_BY_APP_ID:
      return {
        ...state,
        reportTypes: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_REPORT_TYPES_BY_APP_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_CANDIDATE_REPORTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_CANDIDATE_REPORTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_CANDIDATE_REPORTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_CANDIDATE_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_CANDIDATE_REPORT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_CANDIDATE_REPORT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_VIEW_UPLOADED_REPORTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUCCESS_VIEW_UPLOADED_REPORTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_VIEW_UPLOADED_REPORTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DOWNLOAD_CANDIDATE_REPORT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUCCESS_DOWNLOAD_CANDIDATE_REPORT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DOWNLOAD_CANDIDATE_REPORT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        psEvaluationList: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_CANDIDATE_EVALUATION_PS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_PSAC_PEER_PS_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_PSAC_PEER_PS_DATA:
      return {
        ...state,
        psListData: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_PSAC_PEER_PS_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_PSAC_PEER_PS_DATA_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_PSAC_PEER_PS_DATA_BY_ID:
      const { peerDetails: respPeerDetails, psacDetails: respPsacDetails } =
        payload || {};
      const { list: peerDetailsList, totalCount: peerDetailsListCount } =
        respPeerDetails || {};
      const { list: psacDetailsList, totalCount: psacDetailsListCount } =
        respPsacDetails || {};
      return {
        ...state,
        psSummaryData: payload,
        peerDetails: peerDetailsList,
        peerDetailsCount: peerDetailsListCount,
        psacDetails: psacDetailsList,
        psacDetailsCount: psacDetailsListCount,

        loading: false,
        error: null,
      };
    case ERROR_GET_PSAC_PEER_PS_DATA_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_VID_INT_COMMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_VID_INT_COMMENTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPDATE_VID_INT_COMMENTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES:
      return {
        ...state,
        assessFeedbackResponseObj: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CAND_ASSESS_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CAND_ASSESS_STATUS:
      return {
        ...state,
        assessStatusDetails: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_CAND_ASSESS_STATUS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_LEAP_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_LEAP_DETAILS:
      return {
        ...state,
        leapDetails: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_LEAP_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ALL_SCORES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ALL_SCORES:
      return {
        ...state,
        candScoresObj: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_ALL_SCORES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_PS_TSCORES_IMAGES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_GET_PS_TSCORES_IMAGES: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_GET_PS_TSCORES_IMAGES: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_TRIGGER_RETAKE_FOR_VI:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_TRIGGER_RETAKE_FOR_VI:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_TRIGGER_RETAKE_FOR_VI:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_PERSONAL_SUMMARY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_PERSONAL_SUMMARY:
      return {
        ...state,
        loading: false,
        personalSummary: payload?.response,
        error: null,
      };
    case ERROR_GET_PERSONAL_SUMMARY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_SEARCH_AI_INSIGHTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_SEARCH_AI_INSIGHTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_SEARCH_AI_INSIGHTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_POST_FEEDBACK_AI_INSIGHTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_POST_FEEDBACK_AI_INSIGHTS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_POST_FEEDBACK_AI_INSIGHTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default CandidateInfo;
