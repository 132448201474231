const psStaticDataEval = [
  {
    evalName: 'Leadership Qualities',
    evalDesc:
      'Demonstrates leadership qualities and accomplishments as a leader.',
    evalId: 1,
    evalPropName: 'leadership',
  },
  {
    evalName: 'Intellectual Qualities',
    evalDesc:
      'Displays good general knowledge (world events, issues and related matters); analyse issues objectively from multiple perspectives and form sound judgement.',
    evalId: 2,
    evalPropName: 'intellectualQualities',
  },
  {
    evalName: 'Character and Values ',
    evalDesc:
      'Exemplifies sound character and values aligned with Public Service ethos (integrity, service, excellence).',
    evalId: 3,
    evalPropName: 'characterValues',
  },
  {
    evalName: 'People Skills ',
    evalDesc:
      'Displays ability and drive to interact and work effectively with people from diverse backgrounds and in a variety of situations.',
    evalId: 4,
    evalPropName: 'peopleSkills',
  },
  {
    evalName: 'Personal Effectiveness ',
    evalDesc:
      'Able to perform and maintain effectiveness in the face of pressure and challenges.',
    evalId: 5,
    evalPropName: 'personalEffectiveness',
  },
  {
    evalName: 'Communication Skills ',
    evalDesc:
      'Conveys information effectively and responds meaningfully to others.',
    evalId: 6,
    evalPropName: 'communicationSkills',
  },
  {
    evalName: 'Community Involvement ',
    evalDesc:
      'Demonstrates dedication to serve the community and has thought through how the community involvement relates to his/her ambition and passion.',
    evalId: 7,
    evalPropName: 'communityInvolvement',
  },
];

export default psStaticDataEval;
