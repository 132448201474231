import React from 'react';
import _ from 'lodash';

//Functional Components
import NoContentBox from '../common/NoContentBox';

//Common Packages
import { Pagination } from 'carbon-components-react';
import { roundNumber } from '../../containers/common/common';

const highLightCandidate = (profile_id, peer_year_of_award) => {
  if (
    (profile_id == 2889 && peer_year_of_award == 2021) ||
    (profile_id == 14431 && peer_year_of_award == 2023) ||
    (profile_id == 1435 && peer_year_of_award == 2023)
  ) {
    return false;
  }
  return true;
};

const PSPeerAppraisalExecutiveSummary = (props) => {
  const {
    peerDetails,
    profileId,
    peerDetailsById,
    handlePeerPaginationChange,
    peerActivePage,
    peerItemsPerPage,
  } = props || {};
  const { peerRecords } = peerDetails || {};
  const { list, totalCount } = peerRecords || {};
  const { avgCohort, cvCohort, iqCohort, isCohort, lsCohort, stCohort } =
    peerDetailsById || {};
  const peerSkip =
    peerActivePage === 0 ? 0 : (peerActivePage - 1) * peerItemsPerPage;
  return (
    <div>
      <div className="h6 font-weight-bold mt-3 mb-3">
        Peer Rankings and Ratings of All Participants
      </div>
      <div className="f-w-b css-text-color mb-3">
        *President's Scholar nominees are highlighted
      </div>
      {list && Array.isArray(list) && list.length > 0 ? (
        <div className="overflow-scroll">
          <table className="table-border w-100 ">
            <thead>
              <th className="xpa-bg-color--gray" style={{ width: 250 }}></th>
              <th className="xpa-bg-color--gray"></th>
              <th className="xpa-bg-color--gray"></th>
              <th
                className="xpa-bg-color--gray"
                style={{ borderBottom: '1px solid black' }}
                colSpan={6}>
                MEAN PEER RATINGS ON 5 QUALITIES (MAX = 6.0)
              </th>
            </thead>
            <thead>
              <th className="xpa-bg-color--gray xpa-psac-title-fz csc-cand-name-exec-summ-w ">
                Candidate Name
              </th>
              <th className="xpa-bg-color--gray csc-traits-w" rowSpan={2}>
                <div className="xpa-psac-title-fz">PEER RANKING</div>
                <span style={{ fontSize: 14 }}>
                  (By peers in the same group)
                </span>
              </th>
              <th className="xpa-bg-color--gray csc-traits-w" rowSpan={2}>
                <div className="xpa-psac-title-fz">OVERALL MEAN RATING</div>
                <span style={{ fontSize: 14 }}>
                  (Cohort = {roundNumber(avgCohort)})
                </span>
              </th>
              <th className="xpa-bg-color--gray csc-traits-w">
                <div className="xpa-psac-title-fz">LEADERSHIP</div>{' '}
                <div style={{ fontSize: 14 }}>
                  (Cohort = {roundNumber(lsCohort)})
                </div>
              </th>
              <th
                className="xpa-bg-color--gray csc-traits-w"
                style={{
                  overflowWrap: 'anywhere',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}>
                <div className="xpa-psac-title-fz">INTELLECTUAL QUALITIES</div>{' '}
                <div style={{ fontSize: 14 }}>
                  (Cohort = {roundNumber(iqCohort)})
                </div>
              </th>
              <th
                className="xpa-bg-color--gray csc-traits-w"
                style={{
                  overflowWrap: 'anywhere',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}>
                <div className="xpa-psac-title-fz">INTERPERSONAL SKILLS</div>{' '}
                <div style={{ fontSize: 14 }}>
                  (Cohort = {roundNumber(isCohort)})
                </div>
              </th>
              <th
                style={{
                  overflowWrap: 'anywhere',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="xpa-bg-color--gray csc-traits-w">
                <div className="xpa-psac-title-fz">CHARACTER & VALUES</div>{' '}
                <div style={{ fontSize: 14 }}>
                  (Cohort = {roundNumber(cvCohort)})
                </div>
              </th>
              <th
                style={{
                  overflowWrap: 'anywhere',
                  wordBreak: 'break-word',
                  wordWrap: 'break-word',
                }}
                className="xpa-bg-color--gray csc-traits-w">
                <div className="xpa-psac-title-fz">
                  STRESS TOLERANCE & COPING ABILITY
                </div>{' '}
                <div style={{ fontSize: 14 }}>
                  (Cohort = {roundNumber(stCohort)})
                </div>
              </th>
            </thead>
            {list.map((res, index) => {
              const {
                fullName,
                peer_band,
                peer_avg_peer,
                peer_ls_peer: leadershipQualitiesRating,
                peer_io_peer: intellectualQualitiesRating,
                peer_cv_peer: characterValuesRating,
                peer_is_peer: interPersonalSkills,
                peer_st_peer: stressToleranceRating,
                profile_id,
                peer_nominations,
                ps_status,
                peer_year_of_award,
              } = res || {};
              return (
                <tbody className="table-border">
                  <tr
                    style={{
                      backgroundColor:
                        ps_status &&
                        ps_status.toString() &&
                        ps_status.toString().length > 0 &&
                        highLightCandidate(profile_id, peer_year_of_award)
                          ? '#deebf7'
                          : 'white',
                    }}>
                    <td className="text-left p-3">
                      {index + 1 + (peerSkip && Number(peerSkip))}.{' '}
                      <span className="f-w-b text-capitalize">{fullName}</span>{' '}
                      <span>{peer_nominations > 0 ? '#' : ''}</span>
                    </td>
                    <td>{peer_band || '-'}</td>
                    <td>{roundNumber(peer_avg_peer, '-')}</td>
                    <td> {roundNumber(leadershipQualitiesRating, '-')}</td>
                    <td>{roundNumber(intellectualQualitiesRating, '-')}</td>
                    <td> {roundNumber(interPersonalSkills, '-')}</td>
                    <td>{roundNumber(characterValuesRating, '-')}</td>
                    <td> {roundNumber(stressToleranceRating, '-')}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
          {totalCount > 0 && (
            <Pagination
              onChange={handlePeerPaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              page={peerActivePage}
              totalItems={totalCount}
              pageSize={peerItemsPerPage}
            />
          )}
          {totalCount > 0 && (
            <div className="f-w-b css-text-color mt-3 mb-3">
              # Also nominated by peers from other groups
            </div>
          )}
        </div>
      ) : (
        <NoContentBox
          hideTile
          message="There are no details available for your current selection"
        />
      )}
    </div>
  );
};

export default PSPeerAppraisalExecutiveSummary;
