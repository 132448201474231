import React from 'react';

const ColoredCirclePercentage = ({ text, color, score }) => {
  return (
    <div className="w-25 mb-3 d-flex flex-column align-items-center">
      <div
        className="text-center bold mt-1 mr-1"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: color,
          borderRadius: '50%',
          width: '90px',
          height: '90px',
          border: `2px solid ${color}`, 
          fontWeight: 'bold',
        }}>
        <span> {score === 'Moderate' ? 'Medium' : score}</span>
        {/* <span>{score === 'Moderate' ? 'Medium' : score}</span> */}
      </div>
      <span style={{ fontWeight: 'bold' }} className="mt-2">
        {/* {text}   */}
        {text}
      </span>
    </div>
  );
};

export default ColoredCirclePercentage;
