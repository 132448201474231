import callApi from './callApi';

//Common urls for api calls
import { baseUrl, v2BaseUrl } from '../utils/config';

// xyz

//Action types for all api calls
import {
  REQUEST_GET_ADMIN_USERS_LIST,
  REQUEST_CREATE_NEW_USER,
  REQUEST_DELETE_CREATED_USER,
  REQUEST_USER_REGISTER,
  REQUEST_CREATE_VI_PANEL_USERS,
  REQUEST_UPSERT_VI_PANEL_USERS,
  REQUEST_DELETE_VI_PANEL_USERS,
  REQUEST_GET_VI_PANEL_USERS,
  REQUEST_UPSERT_USER_INFO_DETAILS_BY_ID,
  REQUEST_RESET_PANEL_MEM_PASSWORD,
  REQUEST_GET_USER_MAPPINGS,
  REQUEST_UPSERT_USER_MAPPINGS,
  REQUEST_GENERATE_YEARLY_APPL_DATA,
} from './actionTypes';

//Get call for admin list information
const getAdminUsersList = (data) => (dispatch) => {
  const {
    activePage = 1,
    itemsPerPage = 10,
    searchKey,
    roleId,
    noLimit,
  } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/userinviteemailmap?_=${new Date().getTime()}`;
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }
  if (noLimit) {
    url += `&noLimit=${noLimit}`;
  }
  if (roleId) {
    url += `&filter[roleId]=[${roleId}]`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ADMIN_USERS_LIST,
  };
  return callApi(params);
};

const createNewUser = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${baseUrl}/userinviteemailmap?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CREATE_NEW_USER,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const deleteCreatedUser = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${baseUrl}/userinviteemailmap/delete?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_DELETE_CREATED_USER,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const registerUser = (data) => (dispatch) => {
  const { password, firstname, lastname } = data;
  if (!password || !firstname || !lastname) {
    const err = {
      error: {
        message: 'Missing required data',
      },
    };
    return Promise.resolve(err);
  }
  const url = `${baseUrl}/userinfos`;
  const params = {
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_USER_REGISTER,
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  };
  return callApi(params);
};

const createVIPanelUser = (data) => (dispatch) => {
  const { userId } = data || {};
  if (!userId) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${baseUrl}/videointerviewpanelusers?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CREATE_VI_PANEL_USERS,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const upsertVIPanelUser = (data, panelId) => (dispatch) => {
  const { userId } = data || {};
  if (!userId) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  if (!panelId) {
    return Promise.reject(new Error('Invalid panel details.'));
  }
  const url = `${baseUrl}/videointerviewpanelusers/patch/${panelId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_VI_PANEL_USERS,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const deleteVIPanelUser = (panelId) => (dispatch) => {
  if (!panelId) {
    return Promise.reject(new Error('Invalid panel details.'));
  }
  const url = `${baseUrl}/videointerviewpanelusers/delete/${panelId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_DELETE_VI_PANEL_USERS,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

//Get call for VI Panel members
const getVIPanelUsersList = (data) => (dispatch) => {
  const { activePage, itemsPerPage } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/videointerviewpanelusers?_=${new Date().getTime()}`;
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_VI_PANEL_USERS,
  };
  return callApi(params);
};

const upsertUserInfoDetailsById = (data, userId) => (dispatch) => {
  if (!userId) {
    return Promise.reject(new Error('Invalid user details.'));
  }
  const url = `${baseUrl}/userinfos/patch/edit?_=${new Date().getTime()}&userId=${userId}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_USER_INFO_DETAILS_BY_ID,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const resetPanelMemPassword = (data) => (dispatch) => {
  const url = `${baseUrl}/userinfos/internal-reset-password?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_RESET_PANEL_MEM_PASSWORD,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getUserMappings = (data) => (dispatch) => {
  const {
    activePage = 1,
    itemsPerPage = 10,
    searchKey,
    roleIds,
    userIds,
    noLimit,
  } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/userinfo?_=${new Date().getTime()}`;
  if (roleIds && Array.isArray(roleIds) && roleIds.length > 0) {
    url += `&roleIds=[${roleIds}]`;
  }
  if (userIds && Array.isArray(userIds) && userIds.length > 0) {
    url += `&userIds=[${userIds}]`;
  }
  if (searchKey) {
    url += `&searchKey="${searchKey}"`;
  }
  url += `&limit=${itemsPerPage}`;
  url += `&skip=${skip}`;
  if (noLimit) url += `&noLimit=${noLimit}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_USER_MAPPINGS,
  };
  return callApi(params);
};

const upsertUserMappings = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/usermapping`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_USER_MAPPINGS,
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const generateYearlyApplData = (data) => (dispatch) => {
  const url = `${baseUrl}/applications/exportData`;
  const params = {
    dispatch,
    actionType: REQUEST_GENERATE_YEARLY_APPL_DATA,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

export {
  getAdminUsersList,
  createNewUser,
  deleteCreatedUser,
  registerUser,
  createVIPanelUser,
  upsertVIPanelUser,
  deleteVIPanelUser,
  getVIPanelUsersList,
  upsertUserInfoDetailsById,
  resetPanelMemPassword,
  getUserMappings,
  upsertUserMappings,
  generateYearlyApplData,
};
