import React from 'react';

import {
  CandidateInternship,
  CandidateAchievements,
  CandidateNonAcademicQualifications,
  CandidateCCATier,
} from './CandidateAcvIntCCA';
import NoContentBox from '../../components/common/NoContentBox';

const CandCCAInternship = (props) => {
  const {
    internship,
    achievementDetails,
    nonAcademic,
    ccatiering,
    handleOnSupervisorsCommentChange,
    handleSubmitSupervisorComments,
    state,
    roleId,
    schoolDetails,
  } = props;

  return (
    <div>
      <div className="mt-2 h6 font-weight-bold">
        CCA, Achievements, Non-Academic Qualifications and Internships
      </div>
      {(internship && Array.isArray(internship) && internship.length > 0) ||
      (ccatiering && Array.isArray(ccatiering) && ccatiering.length > 0) ||
      (nonAcademic && Array.isArray(nonAcademic) && nonAcademic.length > 0) ||
      (achievementDetails &&
        Array.isArray(achievementDetails) &&
        achievementDetails.length > 0) ? (
        <hr />
      ) : (
        <NoContentBox
          hideTile
          message="There are no CCA / Achievements for your current selection"
        />
      )}
      <CandidateCCATier ccatiering={ccatiering} schoolDetails={schoolDetails} />
      {achievementDetails &&
        Array.isArray(achievementDetails) &&
        achievementDetails.length > 0 && (
          <CandidateAchievements achievementDetails={achievementDetails} />
        )}

      {nonAcademic && Array.isArray(nonAcademic) && nonAcademic.length > 0 && (
        <CandidateNonAcademicQualifications nonAcademic={nonAcademic} />
      )}
      {internship && Array.isArray(internship) && internship.length > 0 && (
        <CandidateInternship
          internship={internship}
          handleOnSupervisorsCommentChange={handleOnSupervisorsCommentChange}
          state={state}
          handleSubmitSupervisorComments={handleSubmitSupervisorComments}
          roleId={roleId}
        />
      )}
    </div>
  );
};

export default CandCCAInternship;
