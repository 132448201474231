import React, { Component } from 'react';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currTime: Date.now(),
    };
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ currTime: Date.now() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { currTime } = this.state;
    const { finalName, latestEndTime } = this.props;
    const timeDiff = latestEndTime - currTime;
    const countingDown = timeDiff > 0;
    const minutes = Math.abs(Math.trunc((timeDiff / (1000 * 60)) % 60));
    const seconds = new Date(Math.abs(timeDiff)).getSeconds();

    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          color: countingDown ? 'black' : 'white',
          backgroundColor: countingDown
            ? minutes <= 4
              ? '#ffc200'
              : 'white'
            : 'red',
          width: '300px',
          height: '59px',
        }}>
        <div className="mt-1 text-center d-flex align-items-center justify-content-center mr-2">
          <div
            className="mt-2 f-w-b text-capitalize"
            style={{
              fontSize: '1rem',
              marginBottom: '0.5rem',
              lineHeight: 1.2,
              fontFamily: `'Roboto', sans-serif`,
            }}>
            {finalName}
          </div>
          <div
            style={{ fontSize: '20px', fontWeight: 'bold' }}
            className={`ml-2 ${!countingDown && minutes >= 5 ? 'blink' : ''}`}>
            {minutes < 10 ? '0' : ''}
            {minutes}:{seconds < 10 ? '0' : ''}
            {seconds}
          </div>
        </div>
      </div>
    );
  }
}

export default Timer;
