import React from 'react';
import { connect } from 'react-redux';
import { Tile, Button } from 'carbon-components-react';
import queryString from 'query-string';
import './UserActivationMsg.css';

const UserActivationMsg = (props) => {
  const { isUserLoggedIn, location, history } = props || {};
  const { push } = history || {};
  const { search } = location || {};
  let parsedQuery = {};
  if (search) {
    parsedQuery = queryString.parse(search);
  }
  const { success } = parsedQuery || {};
  return (
    <div className="d-flex justify-content-center align-items-center csc-h-100">
      <Tile className="p-5">
        {isUserLoggedIn === true ? (
          <div className="mt-2 f-w-b h4">
            {success === 'true' ? (
              <div>
                <div className="csc-success-msg">
                  User has been activated successfully, Please click 'Okay' to
                  return to the users page.
                </div>
                <div className="text-center mt-4">
                  <Button
                    kind="tertiary"
                    size="small"
                    onClick={() => {
                      if (push) {
                        push('/app/csc/admin/users');
                      }
                    }}>
                    Okay
                  </Button>
                </div>
              </div>
            ) : (
              <div className="mt-2 f-w-b h4">
                <div className="csc-error-msg">
                  There was an error while activating the user, Please visit
                  again.
                </div>
                <div className="text-center mt-4">
                  <Button
                    kind="tertiary"
                    size="small"
                    onClick={() => {
                      if (push) {
                        push('/app/csc/admin/users');
                      }
                    }}>
                    Back to users list
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="mt-2 f-w-b h4">
              <div className="">
                Itseems that you are not logged in. Please login and revisit
                this link again.
              </div>
              <div className="text-center mt-4">
                <Button
                  kind="tertiary"
                  size="small"
                  onClick={() => {
                    if (push) {
                      push('/');
                    }
                  }}>
                  Login
                </Button>
              </div>
            </div>
          </div>
        )}
      </Tile>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserActivationMsg);
