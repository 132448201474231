import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/es/storage';
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [routerMiddleware(history), thunkMiddleware];

// const persistedReducer = persistReducer(config, createRootReducer(history));

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "router",
    "jobCreation",
    "jobs",
    "roboroyDashboard",
    "roboroyApplications",
    "enterprise",
    "roboroyTalentPool",
    "resumeUpload",
    "taskStatus",
    "user",
    // "x0paDuser"
  ],
};
const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history), 
);

export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );
  const persistor = persistStore(store);
  return {store, persistor};
}
