import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavItems from '../../headers/NavItemsList';
import { Modal } from 'carbon-components-react';

export const NavigationModal = (props) => {
  const { roleId, handleOpenNavigation } = props;
  const finalNavItems = NavItems();
  return (
    <div>
      <Modal
        open={true}
        modalHeading={<div className="text-capitalize">Navigate</div>}
        modalLabel="Action"
        secondaryButtonText="Cancel"
        iconDescription="Close the modal"
        onRequestClose={() => handleOpenNavigation(false)}
        passiveModal>
        <div>
          {finalNavItems &&
            Array.isArray(finalNavItems) &&
            finalNavItems.length > 0 &&
            finalNavItems.map((items) => {
              const { label, path, subItems, accessRoles } = items || {};
              return subItems && Array.isArray(subItems) && subItems.length <= 0
                ? (accessRoles &&
                  Array.isArray(accessRoles) &&
                  accessRoles.length > 0
                    ? accessRoles.includes(roleId && Number(roleId))
                    : true) && (
                    <div className="mt-2">
                      <Link
                        onClick={() => handleOpenNavigation(false)}
                        to={path}>
                        {label}
                      </Link>
                    </div>
                  )
                : subItems &&
                    Array.isArray(subItems) &&
                    subItems.length > 0 &&
                    (accessRoles &&
                    Array.isArray(accessRoles) &&
                    accessRoles.length > 0
                      ? accessRoles.includes(roleId && Number(roleId))
                      : true) && (
                      <div title={label} className="mt-3">
                        <div className="font-weight-bold">{label}</div>
                        <div className="ml-3">
                          {subItems.map((subIt) => {
                            const { subLabel, subItemsPath, subAccessRoles } =
                              subIt || {};
                            return (
                              (subAccessRoles &&
                              Array.isArray(subAccessRoles) &&
                              subAccessRoles.length > 0
                                ? subAccessRoles.includes(
                                    roleId && Number(roleId)
                                  )
                                : true) && (
                                <div className="mt-2">
                                  <Link
                                    onClick={() => handleOpenNavigation(false)}
                                    to={subItemsPath}>
                                    {subLabel}
                                  </Link>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    );
            })}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationModal);
