import React from 'react';

//Functional Components
import NivoBarChart from '../../components/common/NivoBarChart';
import NoContentBox from '../common/NoContentBox';

//Common Packages
import { Accordion, AccordionItem } from 'carbon-components-react';
import {
  roundNumber,
  roundNumberFromTwoDecimals,
} from '../../containers/common/common';

const PSPeerAppraisalReport = (props) => {
  const { peerDetails, psSummaryData } = props || {};
  const peerObj =
    (peerDetails &&
      Array.isArray(peerDetails) &&
      peerDetails.length > 0 &&
      peerDetails[0]) ||
    {};
  const peerKeys = peerObj && Object.keys(peerObj);
  let additionalCommentsKeys = [];
  if (peerKeys && Array.isArray(peerKeys) && peerKeys.length > 0) {
    peerKeys.forEach((key, index) => {
      if (peerObj && peerObj[`c${index + 1}`]) {
        const finalValue = key && peerObj[`c${index + 1}`];
        additionalCommentsKeys.push(finalValue);
      }
    });
  }
  const { avgCohort, iqCohort, lsCohort, isCohort, cvCohort, stCohort } =
    psSummaryData || {};
  const {
    band,
    nominations,
    avgPeer,
    avgSelf,

    ioPeer,
    iqSelf,

    lsPeer,
    lsSelf,

    isPeer,
    isSelf,

    cvPeer,
    cvSelf,

    stPeer,
    stSelf,
  } = peerObj || {};
  return (
    <div>
      {peerDetails && Array.isArray(peerDetails) && peerDetails.length > 0 ? (
        <div>
          <div class="mt-3 mb-2 h6 text-uppercase font-weight-bold">
            Ranking
          </div>
          <div className="mb-2">
            Peer Ranking:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {(band && Math.round(Number(band))) || '-'}
            </span>
          </div>
          <div className="">
            No. of Nominations Received From Participants Outside of Group:{' '}
            <span style={{ fontWeight: 'bold' }}>{nominations || '-'}</span>
          </div>
          <div class="mt-3 mb-3 h6 text-uppercase font-weight-bold">
            Ratings
          </div>
          <div
            style={{ border: '1px solid black' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div className="font-weight-bold mb-2">
                  Overall Mean Ratings
                </div>
                <div className="mb-2">Averaged across all 5 qualities</div>
                <div>(maximum rating = 6.0)</div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: roundNumber(avgSelf),
                  },
                  {
                    key: 'Peer',
                    Peer: roundNumber(avgPeer),
                  },
                  {
                    key: 'Cohort',
                    Cohort: roundNumber(avgCohort),
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div class="mt-3 mb-3 h6 text-uppercase font-weight-bold">
            Mean Ratings of Individual Qualities (maximum rating = 6.0)
          </div>
          <div
            style={{ border: '1px solid black', borderBottom: '0px' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">Leadership</div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach in which you take charge of
                  situations, provide directions, implement plans, and motivate
                  others.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: roundNumber(lsSelf),
                  },
                  {
                    key: 'Peer',
                    Peer: roundNumber(lsPeer),
                  },
                  {
                    key: 'Cohort',
                    Cohort: roundNumber(lsCohort),
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div
            style={{ border: '1px solid black', borderBottom: '0px' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">
                  Intellectual Qualities
                </div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach in which you learn new skills and
                  analyse problems to aid decision making and develop solutions.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: roundNumber(iqSelf),
                  },
                  {
                    key: 'Peer',
                    Peer: roundNumber(ioPeer),
                  },
                  {
                    key: 'Cohort',
                    Cohort: roundNumber(iqCohort),
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div
            style={{ border: '1px solid black', borderBottom: '0px' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">Interpersonal Skills</div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach in which you communicate, build
                  relationships, and collaborate with others.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: roundNumber(isSelf),
                  },
                  {
                    key: 'Peer',
                    Peer: roundNumber(isPeer),
                  },
                  {
                    key: 'Cohort',
                    Cohort: roundNumber(isCohort),
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div
            style={{ border: '1px solid black', borderBottom: '0px' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">Character & Values</div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach which you adopt towards achieving
                  goals and producing quality outcomes.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: roundNumber(cvSelf),
                  },
                  {
                    key: 'Peer',
                    Peer: roundNumber(cvPeer),
                  },
                  {
                    key: 'Cohort',
                    Cohort: roundNumber(cvCohort),
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div
            style={{ border: '1px solid black' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">
                  Stress Tolerance & Coping Ability
                </div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach in which you deal with challenges and
                  perform under difficult situations.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: roundNumber(stSelf),
                  },
                  {
                    key: 'Peer',
                    Peer: roundNumber(stPeer),
                  },
                  {
                    key: 'Cohort',
                    Cohort: roundNumber(stCohort),
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div className="mt-3 mb-3 h6 font-weight-bold text-uppercase">
            additional comments
          </div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Click To View
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="mt-2 f-w-b mb-2">
                The comments offered by peers are as follows:
              </div>

              <div style={{ lineHeight: 1.2, fontSize: 'larger' }}>
                {additionalCommentsKeys &&
                Array.isArray(additionalCommentsKeys) &&
                additionalCommentsKeys.length > 0 ? (
                  additionalCommentsKeys.map((comments) => (
                    <div className="mt-2 mb-2 d-flex align-items-baseline">
                      <div className="csc-comments-circles" />
                      <div className="ml-2">{comments}</div>
                    </div>
                  ))
                ) : (
                  <NoContentBox
                    hideTile
                    message="There are no comments available for your current selection"
                  />
                )}
              </div>
            </AccordionItem>
          </Accordion>
          <div className="mt-3 mb-3 h6 font-weight-bold text-uppercase">
            How Rankings and Ratings Are Computed
          </div>

          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Click To View
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify mt-2">
                <div className="mb-3">
                  The participants were divided into groups during the
                  Preparatory Course.
                </div>
                <div className="mb-3">
                  They were required to rank the peers within their respective
                  groups according to who they thought would make an outstanding
                  leader. Points were allocated to the participants according to
                  the rankings they received within their groups. One point was
                  allocated to the participant each time he/she was ranked 1
                  <sup className="small">st</sup> by his/her peers, two points
                  were allocated each time he/she was ranked 2
                  <sup className="small">nd</sup>, and so on. Peer Rankings were
                  derived by ranking the participants within their own groups
                  based on the average of the total points received. The lower
                  the points, the higher the participant is ranked within
                  his/her group.
                </div>
                <div className="mb-3">
                  They could also nominate one peer who was not from their own
                  groups if they deemed the person to be an outstanding leader.
                  They were also required to provide general comments for peers
                  in their own groups and the peer that they had nominated as an
                  outstanding leader from the other groups.
                </div>
                <div className="mb-3">
                  The participants were also asked to rate themselves, as well
                  as the peers within the group, on the five qualities based on
                  the behaviours observed on a 6-point rating scale. The higher
                  the rating, the stronger the participant is on the quality.
                </div>
              </div>
            </AccordionItem>
          </Accordion>
          <div className="mt-3 mb-3 h6 font-weight-bold text-uppercase">
            Note for Peer Feedback Exercise
          </div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Click To View
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify mt-2 mb-2">
                As part of the Preparatory Course, participants undergo a peer
                feedback exercise following a series of team-building and
                personal development activities. Participants are asked to rate
                their peers on five qualities -- Leadership, Intellectual
                Qualities, Interpersonal Skills, Character & Values, and Stress
                Tolerance & Coping Ability; in addition to ranking their peers
                and nominating outstanding leaders within the cohort. The
                team-building activities were a series of game activities, along
                with a 3-day Outward Bound School component.
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      ) : (
        <NoContentBox
          hideTile
          message="There are no details available for your current selection"
        />
      )}
    </div>
  );
};

export default PSPeerAppraisalReport;
