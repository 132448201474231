import React, { Component } from 'react';
import { connect } from 'react-redux';

//API
import {
  getCompareApplnFields,
  createCompareApplnFields,
} from '../../actions/CompareApplications';

//Common Components
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import {
  handleGetGBAfitScores,
  handleGetPsychologicalScores,
} from '../../components/private/CandiateMetricColorDetails';

//Common Packages
import { Button, Tile, Select, SelectItem } from 'carbon-components-react';

//CSS
import './CompareApplications.css';

const compareOtherTraitsList = [
  {
    label: 'Performance Based',
    type: '(GBA)',
    value: 'gba_performance_based',
  },
  {
    label: 'Future Driven',
    type: '(GBA)',
    value: 'gba_future_driven',
  },
  {
    label: 'Award',
    type: '(GBA)',
    value: 'gba_award',
  },
  {
    label: 'Average',
    type: '(GBA)',
    value: 'gba_average',
  },
  {
    label: 'Leadership Qualities',
    type: '(Psychological Rating)',
    value: 'psy_leadership',
  },
  {
    label: 'Intellectual Qualities',
    type: '(Psychological Rating)',
    value: 'psy_intellectual',
  },
  {
    label: 'Resilience',
    type: '(Psychological Rating)',
    value: 'psy_resilience',
  },
  {
    label: 'Character and Values',
    type: '(Psychological Rating)',
    value: 'psy_character_vals',
  },
  {
    label: 'Interpersonal Skills',
    type: '(Psychological Rating)',
    value: 'psy_interpersonal_skills',
  },
];

class CompareApplications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      compareList: ['gender', 'country', 'course', 'career'],
      candidateList: [],
      candidateComparision: [],
      compareTrait: '',
      fields: ['preference_all'],
    };
  }

  componentDidMount() {
    const appIds = localStorage.getItem('application-ids') || null;
    const parsedAppIds = appIds ? JSON.parse(appIds) : [];
    const { fields } = this.state || {};
    if (
      parsedAppIds &&
      Array.isArray(parsedAppIds) &&
      parsedAppIds.length > 0
    ) {
      this.props
        .createCompareApplnFields({
          applicationIds: parsedAppIds,
          fields: ['preference_all'],
        })
        .then((res) => {
          if (res && !res.error) {
            this.handleGetCompareCandsData();
          }
        })
        .catch((e) => console.log('Error', e));
    }
  }

  handleGetCompareCandsData = () => {
    const { list } = this.props || {};
    const { compareList } = this.state;
    let candidateList = [];
    let candidateValues = new Map();
    if (list && Array.isArray(list) && list.length > 0) {
      list.forEach((cand) => {
        const {
          application,
          CareerPreference,
          CountryPreference,
          CoursePreference,
          gbaInfo,
          psychologicalReports,
        } = cand || {};
        const { career } =
          (CareerPreference &&
            Array.isArray(CareerPreference) &&
            CareerPreference.length > 0 &&
            CareerPreference[0]) ||
          {};
        const { displayName: careerName } = career || {};
        const { country } =
          (CountryPreference &&
            Array.isArray(CountryPreference) &&
            CountryPreference.length > 0 &&
            CountryPreference[0]) ||
          {};
        const { displayName: countryName } = country || {};
        const { subject } =
          (CoursePreference &&
            Array.isArray(CoursePreference) &&
            CoursePreference.length > 0 &&
            CoursePreference[0]) ||
          {};
        const { displayName: courseName } = subject || {};
        const { details } =
          (gbaInfo &&
            Array.isArray(gbaInfo) &&
            gbaInfo.length > 0 &&
            gbaInfo[0]) ||
          {};
        const { assessment_results } = details || {};
        let gbaFitScores = [];
        if (
          assessment_results &&
          Array.isArray(assessment_results) &&
          assessment_results.length > 0
        ) {
          gbaFitScores = assessment_results.filter(
            (r) => r && r.trait === 'Fit'
          );
        }
        const psychologicalScores = handleGetPsychologicalScores(
          psychologicalReports
        );
        const fitScores = handleGetGBAfitScores(gbaFitScores);
        const {
          award,
          futureDriven,
          performanceBased,
          average,
          averageColor,
          awardColor,
          performanceBasedColor,
          futureDrivenColor,
        } = fitScores || {};
        const {
          characterAndValues,
          intellectualQualities,
          interpersonalSkills,
          leadershipQualities,
          resilience,
          characterAndValuesColor,
          intellectualQualitiesColor,
          interpersonalSkillsColor,
          leadershipQualitiesColor,
          resilienceColor,
        } = psychologicalScores || {};
        const { profile } =
          (application &&
            Array.isArray(application) &&
            application.length > 0 &&
            application[0]) ||
          {};
        const { fullName, gender } = profile || {};
        const { genderName } = gender || {};
        if (fullName) {
          candidateList.push({
            fullName,
          });
        }
        const finalObj = {
          career: careerName,
          course: courseName,
          country: countryName,
          gender: genderName,
        };
        if (compareList.includes('Performance Based')) {
          finalObj['Performance Based'] = (
            <div className="w-100 d-flex justify-content-center">
              <div
                className={`csc-th--block border-radius-50 ${performanceBasedColor}`}>
                <div className="csc-pos--top-c">{performanceBased}</div>
              </div>
            </div>
          );
        }
        if (compareList.includes('Award')) {
          finalObj['Award'] = (
            <div className="w-100 d-flex justify-content-center">
              <div className={`csc-th--block border-radius-50 ${awardColor}`}>
                <div className="csc-pos--top-c">{award}</div>
              </div>
            </div>
          );
        }
        if (compareList.includes('Future Driven')) {
          finalObj['Future Driven'] = (
            <div className="w-100 d-flex justify-content-center">
              <div
                className={`csc-th--block border-radius-50 ${futureDrivenColor}`}>
                <div className="csc-pos--top-c">{futureDriven}</div>
              </div>
            </div>
          );
        }
        if (compareList.includes('Average')) {
          finalObj['Average'] = (
            <div className="w-100 d-flex justify-content-center">
              <div className={`csc-th--block border-radius-50 ${averageColor}`}>
                <div className="csc-pos--top-c">{average}</div>
              </div>
            </div>
          );
        }

        if (compareList.includes('Leadership Qualities')) {
          finalObj['Leadership Qualities'] = (
            <div className="w-100 d-flex justify-content-center">
              <div
                className={`csc-th--block border-radius-50 ${leadershipQualitiesColor}`}>
                <div className="csc-pos--top-c">{leadershipQualities}</div>
              </div>
            </div>
          );
        }
        if (compareList.includes('Intellectual Qualities')) {
          finalObj['Intellectual Qualities'] = (
            <div className="w-100 d-flex justify-content-center">
              <div
                className={`csc-th--block border-radius-50 ${intellectualQualitiesColor}`}>
                <div className="csc-pos--top-c">{intellectualQualities}</div>
              </div>
            </div>
          );
        }
        if (compareList.includes('Resilience')) {
          finalObj['Resilience'] = (
            <div className="w-100 d-flex justify-content-center">
              <div
                className={`csc-th--block border-radius-50 ${resilienceColor}`}>
                <div className="csc-pos--top-c">{resilience}</div>
              </div>
            </div>
          );
        }
        if (compareList.includes('Character and Values')) {
          finalObj['Character and Values'] = (
            <div className="w-100 d-flex justify-content-center">
              <div
                className={`csc-th--block border-radius-50 ${characterAndValuesColor}`}>
                <div className="csc-pos--top-c">{characterAndValues}</div>
              </div>
            </div>
          );
        }
        if (compareList.includes('Interpersonal Skills')) {
          finalObj['Interpersonal Skills'] = (
            <div className="w-100 d-flex justify-content-center">
              <div
                className={`csc-th--block border-radius-50 ${interpersonalSkillsColor}`}>
                <div className="csc-pos--top-c">{interpersonalSkills}</div>
              </div>
            </div>
          );
        }
        candidateValues.set(fullName, finalObj);
      });
    }
    let finalData = new Map();
    if (compareList && Array.isArray(compareList) && compareList.length > 0) {
      compareList.forEach((key) => {
        if (
          key &&
          candidateList &&
          Array.isArray(candidateList) &&
          candidateList.length > 0
        ) {
          const obj = {};
          candidateList.forEach((cand) => {
            const { fullName } = cand || {};
            const currCandData = candidateValues.get(fullName);
            obj[fullName] = currCandData[key];
            finalData.set(key, obj);
          });
        }
      });
    }
    const candidateFinalD = [];

    if (compareList && Array.isArray(compareList) && compareList.length > 0) {
      compareList.forEach((key) => {
        if (key) {
          candidateFinalD.push(finalData.get(key));
        }
      });
    }
    this.setState({
      candidateList,
      candidateComparision: candidateFinalD,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleAddParameter = () => {
    const { compareTrait, compareList } = this.state;
    const currCompObj = compareOtherTraitsList.find(
      (r) => r && r.value === compareTrait
    );
    const { label } = currCompObj || {};
    let filteredCompareList = false;
    if (compareList && Array.isArray(compareList) && compareList.length > 0) {
      filteredCompareList = compareList.includes(label);
    }
    this.setState(
      {
        isValidCompareTrait: compareTrait ? false : true,
        isValidCompareTraitDup: filteredCompareList,
      },
      () => {
        const { isValidCompareTrait, isValidCompareTraitDup } = this.state;
        if (!isValidCompareTrait && !isValidCompareTraitDup) {
          const appIds = localStorage.getItem('application-ids') || null;
          const parsedAppIds = appIds ? JSON.parse(appIds) : [];

          if (
            parsedAppIds &&
            Array.isArray(parsedAppIds) &&
            parsedAppIds.length > 0
          ) {
            const finalFields = this.state.fields || [];
            finalFields.push(compareTrait);
            this.props
              .createCompareApplnFields({
                applicationIds: parsedAppIds,
                fields: finalFields,
              })
              .then((res) => {
                if (res && !res.error) {
                  const compareListData = this.state.compareList || [];
                  compareListData.push(label);
                  this.setState(
                    {
                      compareList: compareListData,
                      finalFields,
                    },
                    () => {
                      this.handleGetCompareCandsData();
                    }
                  );
                }
              })
              .catch((e) => console.log('Error', e));
          }
        }
      }
    );
  };

  render() {
    const {
      compareList,
      candidateList,
      candidateComparision,
      compareTrait,
      isValidCompareTrait,
      isValidCompareTraitDup,
    } = this.state || {};
    const { loading } = this.props;
    return (
      <Tile>
        <h4 className="">Candidate Comparison</h4>
        <hr />
        {candidateList &&
        Array.isArray(candidateList) &&
        candidateList.length > 0 ? (
          <div className="d-flex">
            <div className="">
              <table className="w-100 text-capitalize">
                <thead>
                  <th className="text-center css-table-min-h">
                    <div className="mr-2 h4 f-w-b text-center text-black"></div>
                  </th>
                </thead>
                <tbody className="table-border">
                  {compareList &&
                    Array.isArray(compareList) &&
                    compareList.length > 0 &&
                    compareList.map((trait) => (
                      <tr className="css-table-data-w-h">
                        <td className="f-w-b">{trait}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="w-85 overflow-scroll">
              <div className="">
                <table className="w-100 text-capitalize">
                  <thead>
                    {candidateList.map((cand) => {
                      const { fullName } = cand || {};
                      return (
                        <th className="css-table-min-h text-center">
                          <div className="h4 f-w-b">{fullName}</div>
                        </th>
                      );
                    })}
                  </thead>
                  <tbody className="table-border">
                    {candidateComparision &&
                      Array.isArray(candidateComparision) &&
                      candidateComparision.length > 0 &&
                      candidateComparision.map((compObj) => {
                        return (
                          <tr className="css-table-data-w-h">
                            {candidateList.map((cand) => {
                              const { fullName } = cand || {};
                              return <td>{compObj[fullName]}</td>;
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <NoContentBox
              hideTile
              message="There are no candidates available for your current selection"
            />
          </div>
        )}
        {candidateList &&
          Array.isArray(candidateList) &&
          candidateList.length > 0 && (
            <div className="d-flex align-items-center mt-3">
              <div>
                <Button onClick={this.handleAddParameter}>Add Parameter</Button>
              </div>
              <div className="ml-2">
                <Select
                  name="compareTrait"
                  id="compareTrait"
                  onChange={this.handleChange}
                  value={compareTrait}
                  hideLabel
                  invalid={isValidCompareTrait || isValidCompareTraitDup}
                  invalidText={
                    isValidCompareTraitDup
                      ? 'This trait is already selected, Please select a valid option'
                      : 'Please select atleast one option'
                  }
                  className="xpa-width w-100"
                  labelText="Select Test">
                  <SelectItem text="Select" key={0} disabled />
                  {compareOtherTraitsList.map((r) => {
                    const { label, value, type } = r || {};
                    return (
                      <SelectItem
                        text={`${label} ${type}`}
                        key={value}
                        id={value}
                        value={value}
                      />
                    );
                  })}
                </Select>
              </div>
            </div>
          )}
        <JDProcessOverlay show={loading} message="processing..." />
      </Tile>
    );
  }
}

const mapStateToProps = (state) => ({
  list: state.CompareApplications.list,
  loading: state.CompareApplications.loading,
});

const mapDispatchToProps = {
  getCompareApplnFields,
  createCompareApplnFields,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareApplications);
