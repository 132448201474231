import React, { Fragment } from 'react';

const BatchCounts = (props) => {
  const {
    accepted,
    interviewed,
    offered,
    total,
    acceptanceRate,
    push,
    redirectToMock,
    selectedTab,
  } = props || {};
  return (
    <Fragment>
      <div className="bx--row justify-content-around pb-0 pl-0 pr-0 ml-0 mr-0">
        <div className="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-3">
          <div className="mr-2 mb-2 datalist__cnt--total1">
            <div
              onClick={() => {
                push &&
                  push(
                    redirectToMock === true
                      ? '/public/list/candidate'
                      : '/app/csc/candidate/list/all'
                  );
              }}
              className="mr-1 bx--type-beta font-weight-light xpa-link">
              {interviewed || 0}
            </div>
            <div className="text-dark">
              {selectedTab === 0 ? 'Interviewed' : 'Total Interviewed'}
            </div>
          </div>
        </div>
        <div className="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-3 ">
          <div className="mr-2 mb-2 datalist__cnt--screen1">
            <div
              onClick={() => {
                push &&
                  push(
                    redirectToMock
                      ? '/public/list/candidate'
                      : '/app/csc/candidate/list/all'
                  );
              }}
              className="mr-1 bx--type-beta font-weight-light xpa-link">
              {offered || 0}
            </div>
            <div className="text-dark">
              {selectedTab === 0 ? 'Offered' : 'Total Offered'}
            </div>
          </div>{' '}
        </div>
        <div className="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-3 ">
          <div className="mr-2 mb-2 datalist__cnt--hired1">
            <div
              onClick={() => {
                push &&
                  push(
                    redirectToMock
                      ? '/public/list/candidate'
                      : '/app/csc/candidate/list/all'
                  );
              }}
              className="mr-1 bx--type-beta font-weight-light xpa-link">
              {accepted || 0}
            </div>
            <div className="text-dark">
              {selectedTab === 0 ? 'Accepted' : 'Total Accepted'}
            </div>
          </div>{' '}
        </div>
        {/* <div className="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-3">
          <div className="mr-2 mb-2 datalist__cnt--total1">
            <div
              onClick={() => {
                push &&
                  push(
                    redirectToMock
                      ? '/public/list/candidate'
                      : '/app/csc/candidate/list/all'
                  );
              }}
              className="mr-1 bx--type-beta font-weight-light xpa-link">
              {total || 0}
            </div>
            <div className="text-dark">Total</div>
          </div>
        </div> */}
        <div className="text-center mb-0 bx--col-xs-12 bx--col-sm-12 bx--col-md-4 bx--col-lg-3 pr-0">
          <div className="mr-2 mb-2 datalist__cnt--sho1rt border1">
            <div className="mr-1 bx--type-beta font-weight-light xpa-link">
              {(acceptanceRate && Math.round(Number(acceptanceRate))) || 0} %
            </div>
            <div className="text-dark mb-1">Acceptance Rate</div>
          </div>{' '}
        </div>
      </div>
    </Fragment>
  );
};

export default BatchCounts;
