import React from 'react';

const MainLegend = ({ showNeoPIR, scenario, selectionYear }) => (
  <div>
    <h5 className="ml-1">Legend</h5>
    <div className=" bx--row ml-1 mr-1 mb-2 ">
      <div className="bx--col-xl-3 mt-2 bx--col-md-8 bx--col-lg-6 pl-0">
        <div className="mt-2">
          <h6 className="text-black mb-2 mt-2">
            Critical Thinking / Numerical Reasoning
          </h6>
          <div className="d-flex align-items-center ">
            <div className="d-flex align-items-center ">
              <div className="csc-th--h border-radius-50 small-cir mt-2 mt-2 ml-0" />
              <div className="mt-2">Strongly Meets</div>
            </div>
            <div className="d-flex align-items-center ml-2">
              <div className="csc-th--l border-radius-50 small-cir mt-2" />
              <div className="mt-2">Meets</div>
            </div>
            <div className="d-flex align-items-center ml-2">
              <div className="csc-th--vl border-radius-50 small-cir mt-2" />
              <div className="mt-2">Does not meet</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bx--col-xl-2 pr-0 mt-2 bx--col-md-8 bx--col-lg-6 pl-0">
        <h6 className="text-black mb-2 mt-2">
          {selectionYear > 2023 ? 'LEAP' : 'GBA'}
        </h6>
        <div className="d-flex align-items-center ">
          <div className="d-flex align-items-center">
            <div className="csc-th--h border-radius-50 small-cir mt-2 ml-0" />
            <div className="mt-2">
              {selectionYear > 2023 ? 'Desired' : 'Good Fit'}
            </div>
          </div>
          <div className="d-flex align-items-center ml-2">
            <div className="csc-th--l border-radius-50 small-cir mt-2" />
            <div className="mt-2">
              {selectionYear > 2023 ? 'Acceptable' : 'Moderate Fit'}
            </div>
          </div>
          <div className="d-flex align-items-center ml-2">
            <div className="csc-th--vl border-radius-50 small-cir mt-2" />
            <div className="mt-2">
              {selectionYear > 2023 ? 'Weak' : 'Poor Fit'}
            </div>
          </div>
        </div>
      </div>
      <div className="bx--col-xl-3 mt-2 bx--col-md-8 bx--col-lg-6 pl-0 pr-0 ">
        <h6 className="text-black mb-2 mt-2">Psychological Interview Rating</h6>

        <div className="d-flex align-items-center ">
          <div className="d-flex align-items-center ">
            <div className="csc-th--h border-radius-50 small-cir mt-2 mt-2 ml-0" />
            <div className="mt-2">Excellent</div>
          </div>
          <div className="d-flex align-items-center ml-2">
            <div className="csc-th--h border-radius-50 small-cir mt-2 ml-0" />
            <div className="mt-2">Strong</div>
          </div>
          <div className="d-flex align-items-center ml-2">
            <div className="csc-th--l border-radius-50 small-cir mt-2" />
            <div className="mt-2">Moderate</div>
          </div>
          <div className="d-flex align-items-center ml-2">
            <div className="csc-th--vl border-radius-50 small-cir mt-2" />
            <div className="mt-2">Weak</div>
          </div>
          <div className="d-flex align-items-center ml-2">
            <div className="csc-th--vl border-radius-50 small-cir mt-2" />
            <div className="mt-2">Very Weak</div>
          </div>
        </div>
      </div>
      {/* {showNeoPIR === true && (
        <div className="bx--col-xl-3 mt-2 bx--col-md-8 bx--col-lg-6 pl-0 pr-0 ">
          <h6 className="text-black mb-2 mt-2">NEO PI-R</h6>

          <div className="d-flex align-items-center ">
            <div className="d-flex align-items-center">
              <div className="csc-th--h border-radius-50 small-cir mt-2 ml-0" />
              <div className="mt-2">Very High</div>
            </div>
            <div className="d-flex align-items-center ml-2">
              <div className="csc-th--h border-radius-50 small-cir mt-2 ml-0" />
              <div className="mt-2">High</div>
            </div>
            <div className="d-flex align-items-center ml-2">
              <div className="csc-th--l border-radius-50 small-cir mt-2" />
              <div className="mt-2">Moderate</div>
            </div>
            <div className="d-flex align-items-center ml-2">
              <div className="csc-th--vl border-radius-50 small-cir mt-2" />
              <div className="mt-2">Low</div>
            </div>
            <div className="d-flex align-items-center ml-2">
              <div className="csc-th--vl border-radius-50 small-cir mt-2" />
              <div className="mt-2">Very Low</div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  </div>
);

export default MainLegend;
