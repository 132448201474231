import React, { Component } from 'react';
import { connect } from 'react-redux';
//Api Actions
import { getAssignedCandidates } from '../../actions/CandidateInfo';
import { getJobApplFilters } from '../../actions/CandidateList';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import Avatar from '../../components/common/Avatar';

//Common packages
import {
  Button,
  Pagination,
  Select,
  SelectItem,
} from 'carbon-components-react';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';

class VIAssignedCandidates extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'candidates', name: 'Assigned Candidates', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      status: 'Not Completed',
    };
  }

  componentDidMount() {
    this.props.getJobApplFilters();
    this.handleGetAssignedAppl();
  }

  handleGetAssignedAppl = () => {
    const { activePage, itemsPerPage, searchKey, status, year } = this.state;
    const { user } = this.props;
    const { currentYear } = user || {};
    const data = {
      activePage,
      itemsPerPage,
      searchKey,
      completed: status === 'Completed',
      year: year ?? currentYear,
    };
    this.props
      .getAssignedCandidates(data)
      .then((res) => {
        if (res && !res.error) {
          this.setState({
            year: data.year,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetAssignedAppl();
    });
  };

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ searchKey: event.target.value, isLoading: true });
    this.searchTimer = setTimeout(() => {
      this.handleGetAssignedAppl();
    }, 1000);
  };

  handleChangeDropdown = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.handleGetAssignedAppl();
      }
    );
  };

  render() {
    const { status, year } = this.state;
    const {
      assignedCandidateList,
      assignedCount,
      activePage,
      history,
      loading,
      user,
      jobApplFilters,
    } = this.props;
    const { currentYear } = user || {};
    const { push } = history || {};

    let finalJobApplFilterData = [];
    if (
      jobApplFilters &&
      Array.isArray(jobApplFilters) &&
      jobApplFilters.length > 0
    ) {
      finalJobApplFilterData = jobApplFilters.sort((a, b) => {
        if (a && b) {
          if (a.year < b.year) {
            return 1;
          }
          if (a.year > b.year) {
            return -1;
          }
          return 0;
        }
      });
    }

    return (
      <div>
        <BackRow paths={this.paths} />
        <div>
          <div>
            <GenericDataTable
              otherActions={[
                <div className="mr-3">
                  <Select
                    name="status"
                    id="status"
                    onChange={this.handleChangeDropdown}
                    value={status}
                    hideLabel
                    className="xpa-width w-100"
                    labelText="">
                    <SelectItem
                      text="Completed"
                      key="Completed"
                      id="Completed"
                      value="Completed"
                    />
                    <SelectItem
                      text="Not Completed"
                      key="Not Completed"
                      id="Not Completed"
                      value="Not Completed"
                    />
                  </Select>
                </div>,
                <div>
                  <Select
                    name="year"
                    id="year"
                    onChange={this.handleChangeDropdown}
                    value={year}
                    hideLabel
                    className="xpa-width w-100"
                    labelText="Select Test">
                    <SelectItem text="Select" key={0} disabled />
                    {finalJobApplFilterData &&
                      Array.isArray(finalJobApplFilterData) &&
                      finalJobApplFilterData.length > 0 &&
                      finalJobApplFilterData.map((r, idx) => {
                        const { year: propYear, batch_id } = r || {};
                        return (
                          idx <= 3 && (
                            <SelectItem
                              text={`Selection Year ${propYear}`}
                              key={batch_id}
                              id={batch_id}
                              value={propYear}
                            />
                          )
                        );
                      })}
                  </Select>
                </div>,
              ]}
              searchable
              tbodyClass="candidate-background-color"
              onSearchInputChange={this.handleSearchSubmit}
              rows={
                assignedCandidateList &&
                Array.isArray(assignedCandidateList) &&
                assignedCandidateList.length > 0
                  ? assignedCandidateList.map((data, idx) => {
                      const {
                        app_id,
                        // email_sent_date,
                        full_name,
                        email,
                        status,
                        video_completion_status,
                      } = data;
                      let statusColor = 'bx--tag bx--tag--yellow';
                      if (status === 'Completed') {
                        statusColor = 'bx--tag bx--tag--green';
                      } else if (status === 'Not Completed') {
                        statusColor = 'bx--tag bx--tag--cool-gary';
                      }
                      return {
                        isExpanded: false,
                        id: `${app_id}-${idx}`,
                        header_0: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Name:
                            </div>
                            <div className="d-flex align-items-center bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <div className="xpa-avatar-width">
                                <Avatar
                                  user={{
                                    firstname: full_name,
                                    lastname: null,
                                    picture: null,
                                  }}
                                  classNameInfo="rounded-circle"
                                  size="30"
                                />
                              </div>
                              <div className="ml-2 text-capitalize bx--type-zeta">
                                {full_name}
                              </div>
                            </div>
                          </div>
                        ),
                        header_1: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Email:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <div>{email}</div>
                            </div>
                          </div>
                        ),
                        header_2: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Status:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {status ? (
                                <div className={`${status ? statusColor : ''}`}>
                                  {status}{' '}
                                  {video_completion_status &&
                                    `(${video_completion_status})`}{' '}
                                </div>
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                        ),
                        header_3: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Action:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <Button
                                onClick={() => {
                                  push &&
                                    push(
                                      `/app/csc/candidate/${app_id}/batch/${currentYear}/status/personal-info`
                                    );
                                }}
                                kind="primary"
                                size="small">
                                View
                              </Button>
                            </div>
                          </div>
                        ),
                      };
                    })
                  : []
              }
              headers={[
                {
                  key: 'header_0',
                  header: 'Candidate Name',
                },
                {
                  key: 'header_1',
                  header: 'Email',
                },
                {
                  key: 'header_2',
                  header: 'Status',
                },
                {
                  key: 'header_3',
                  header: 'Action',
                },
              ]}
            />
          </div>
          {assignedCount > 0 && (
            <Pagination
              onChange={this.handlePaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              page={activePage}
              totalItems={assignedCount}
            />
          )}
          {assignedCount <= 0 && (
            <NoContentBox message="There are no interviews assigned for your current selection" />
          )}
        </div>
        <JDProcessOverlay show={loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assignedCandidateList: state.CandidateInfo.assignedCandidateList,
  assignedCount: state.CandidateInfo.assignedCount,
  loading: state.CandidateInfo.loading,
  user: state.x0paDuser.user,
  jobApplFilters: state.CandidateList.jobApplFilters,
});

const mapDispatchToProps = {
  getAssignedCandidates,
  getJobApplFilters,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VIAssignedCandidates);
