import React, { memo, Fragment } from 'react';
import { ResponsivePie } from '@nivo/pie';
import XPAErrorBoundary from '../../containers/common/XPAErrorBoundary';
import './CarbonGraphsStyles.css';

const NivoPieChart = (props) => {
  const { data, heading, margin, legendTranslateY, headingClass = '' } = props;
  let finalData = [];
  const colors = [
    '#118DFF',
    '#12239E',
    '#E66C37',
    '#6B007B',
    '#E044A7',
    '#744EC2',
    '#D9B300',
    '#D64550',
  ];
  if (data && Array.isArray(data) && data.length > 0) {
    finalData = data.map((res, idx) => {
      const obj = {
        ...res,
        color: colors[idx],
      };
      return obj;
    });
  }
  return (
    <Fragment>
      <h4 className={`text-capitalize ${headingClass}`}>{heading}</h4>
      <div className="bx--row ml-0 mt-2">
        {finalData &&
          Array.isArray(finalData) &&
          finalData.length > 0 &&
          finalData.map((res) => {
            return (
              <div className="d-flex align-items-center mr-2 mb-2">
                <div
                  style={{ backgroundColor: res && res.color }}
                  className="legend-box"
                />
                <div className="small ml-1 text-capitalize">
                  {res && res.label}
                </div>
              </div>
            );
          })}
      </div>
      <XPAErrorBoundary
        user={{}}
        message="Unable to retrieve analytics right now. Please check later.">
        <ResponsivePie
          data={finalData}
          margin={margin}
          padAngle={0.7}
          cornerRadius={0}
          colors={(d) => {
            return d && d.data && d.data.color;
          }}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={0}
          innerRadius={0}
          radialLabelsTextColor="black"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={5}
          radialLabelsLinkHorizontalLength={15}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor={{ from: 'color' }}
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor="white"
          animate={true}
          sortByValue
          enableRadialLabels={true}
          motionStiffness={90}
          sliceLabel={(d) => `${d.value}`}
          motionDamping={15}
          legends={[]}
        />
      </XPAErrorBoundary>
    </Fragment>
  );
};

export default memo(NivoPieChart);
