import React from 'react';
import { CandidateStenGraph, CandidateZScoreGraph } from './CandidateStenGraph';
import { Accordion, AccordionItem } from 'carbon-components-react';

const CandidateGBAStenDetails = (props) => {
  const { gbaMainTraitsData, handleOpenAccr, isOpenAccrVal, handleBarOnClick } =
    props || {};

  return (
    <div>
      <Accordion>
        <AccordionItem
          open={isOpenAccrVal === 'Stens'}
          onClick={() => handleOpenAccr('Stens')}
          className="content-padding mt-2"
          title={
            <div className="h6 font-weight-bold">
              Personality Profile (Stens)
            </div>
          }>
          <CandidateStenGraph
            handleBarOnClick={handleBarOnClick}
            gbaMainTraitsData={gbaMainTraitsData}
            isMainGBAData
          />
        </AccordionItem>
        <AccordionItem
          className="content-padding mt-2"
          open={isOpenAccrVal === 'Z Score'}
          onClick={() => handleOpenAccr('Z Score')}
          title={
            <div className="h6 font-weight-bold">
              Personality Profile (Z-Score)
            </div>
          }>
          <CandidateZScoreGraph
            handleBarOnClick={handleBarOnClick}
            gbaMainTraitsData={gbaMainTraitsData}
          />
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CandidateGBAStenDetails;
