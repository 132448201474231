import React from 'react';
import { WarningAlt32 } from '@carbon/icons-react';
const CandidateReportNoResponse = () => {
  return (
    <div className="css-web-height d-flex flex-column justify-content-center align-items-center">
      <div
        style={{ width: '40%', height: 300 }}
        className="bx--tile d-flex flex-column justify-content-center align-items-center">
        <div>
          <div className="mb-3 text-center">
            <WarningAlt32 fill="red" width="48px" height="48px" />
          </div>
          <div className="h5 f-w-b">
            Document may have been deleted. Please refresh your page now.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateReportNoResponse;
