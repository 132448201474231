import {
  REQUEST_X0PA_DUSER,
  SUCCESS_X0PA_DUSER,
  ERROR_X0PA_DUSER,
  REQUEST_AUTH_STATE,
  SUCCESS_AUTH_STATE,
  ERROR_AUTH_STATE,
  REQUEST_VERIFY_EMAIL_DATA,
  SUCCESS_VERIFY_EMAIL_DATA,
  ERROR_VERIFY_EMAIL_DATA,
} from '../actions/actionTypes';

const initState = {
  loading: false,
  error: null,
  user: {},
  isUserLoggedIn: null,
  xpaCurrentCompanyId: null,
};

const x0paDuser = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_X0PA_DUSER:
      return {
        loading: true,
        error: null,
        ...state,
      };
    case SUCCESS_X0PA_DUSER: {
      let userLoggedIn = false;
      if (payload && !payload.error) {
        userLoggedIn = true;
      } else {
        userLoggedIn = false;
      }
      const { meta = {} } = payload;
      const { currentCompany } = meta || {};
      const { companyId } = currentCompany || {};
      return {
        ...state,
        isUserLoggedIn: userLoggedIn,
        user: meta,
        xpaCurrentCompanyId: companyId,
        loading: false,
        error: null,
      };
    }
    case ERROR_X0PA_DUSER:
      return {
        ...state,
        loading: false,
        isUserLoggedIn: false,
        error: payload.error,
      };

    case REQUEST_AUTH_STATE: {
      return {
        loading: true,
        isUserLoggedIn: false,
        error: null,
        ...state,
      };
    }
    case SUCCESS_AUTH_STATE: {
      return {
        ...state,
        isUserLoggedIn: payload.u || false,
        loading: false,
      };
    }
    case ERROR_AUTH_STATE: {
      return {
        ...state,
        loading: false,
        isUserLoggedIn: false,
        error: payload.error,
      };
    }
    case REQUEST_VERIFY_EMAIL_DATA: {
      return {
        loading: true,
        error: null,
        ...state,
      };
    }
    case SUCCESS_VERIFY_EMAIL_DATA: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_VERIFY_EMAIL_DATA: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default:
      return state;
  }
};

export default x0paDuser;
