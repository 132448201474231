import React, { Component, Fragment } from 'react';
import GenericTabs from '../../components/common/GenericTabs';
import GenericDataTable from '../../components/common/GenericDataTable';
import PublicHeaderMock from './PublicHeaderMock';

import DashboardInterviewsMock from '../../components/private/DashboardInterviewsMock';
import DashboardInterviewMock1 from '../../components/private/DashboardInterviewMock1';
//Carbon components
import {
  Tile,
  TextInput,
  Button,
  Modal,
  InlineNotification,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';
import MainLegend from '../../components/common/MainLegend';
import moment from 'moment';

class AgendaInterviewsMock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSideNavExpanded: false,
      selectedTab: 0,
    };
  }

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  handleTabClick = (tabNumber, name) => {
    this.setState({
      [name]: tabNumber,
    });
  };
  render() {
    const { history } = this.props;
    const { push } = history || {};
    const interviewsData = [
      {
        firstName: 'Isaiah Chia',
        schoolName: 'Singapore Polytechnic',
        gender: 'Male',
        preferences: ['Public Administration', 'Engineering', 'Legal service'],
        coursePref: ['Physics', 'Applied Physics', 'Chemistry'],
        countryPref: [
          'University of Chicago (USA)',
          'University of Oxford (UK)',
          'National University of Singapore (SG)',
        ],
        ccaTier: {
          l: 1,
          r: 2,
        },
        psychometricRes: {
          wag: 22,
          anra: 5,
        },
        gba: {
          performance: 88,
          future: 82,
          award: 65,
          average: 78,
        },
        academicResults: {
          psc: 4.0 / 4.0,
          gp: 8,
          pw: 7,
          mtl: 5,
        },
        remarks: 'Very good',
        rank: 'Top 3%',
        criteria: 'Average Fit',
        faculty: 'Faculty',
        time: '2:00',
      },
      {
        firstName: 'Wee Baey Kok',
        schoolName: 'National Junior College',
        gender: 'Male',
        preferences: [
          'Public Administration',
          'Legal service',
          'Foreign service',
        ],
        coursePref: ['Physics', 'Applied Physics', 'Chemistry'],
        countryPref: [
          'University of Chicago (USA)',
          'University of Oxford (UK)',
          'National University of Singapore (SG)',
        ],
        ccaTier: {
          l: 3,
          r: 3,
        },
        psychometricRes: {
          wag: 32,
          anra: 19,
        },
        gba: {
          performance: 88,
          future: 82,
          award: 65,
          average: 78,
        },
        academicResults: {
          psc: 9,
          gp: 8,
          pw: 7,
          mtl: 5,
        },
        remarks: 'Very good',
        rank: '18 / 523',
        criteria: 'Good Fit',
        faculty: null,
        time: '2:30',
      },
      {
        firstName: 'Jacob Lim',
        schoolName: 'National University of Singapore',
        preferences: [
          'Public Administration',
          'Engineering',
          'Foreign service',
        ],
        coursePref: ['Physics', 'Applied Physics', 'Chemistry'],
        countryPref: [
          'University of Chicago (USA)',
          'University of Oxford (UK)',
          'National University of Singapore (SG)',
        ],
        gender: 'Male',
        ccaTier: {
          l: 1,
          r: 3,
        },
        psychometricRes: {
          wag: 70,
          anra: 92,
        },
        gba: {
          performance: 88,
          future: 82,
          award: 65,
          average: 78,
        },
        academicResults: {
          psc: 9,
          gp: 8,
          pw: 7,
          mtl: 5,
        },
        remarks: 'Very good',
        rank: 'Top 6 / 162',
        criteria: 'Average Fit',
        faculty: null,
        time: '3:00',
      },
      {
        firstName: 'Sophia Wong',
        schoolName: 'St. Josephs Institution',
        preferences: [
          'Public Administration',
          'Engineering',
          'Foreign service',
        ],
        coursePref: ['Physics', 'Applied Physics', 'Chemistry'],
        countryPref: [
          'University of Chicago (USA)',
          'University of Oxford (UK)',
          'National University of Singapore (SG)',
        ],
        gender: 'Female',
        ccaTier: {
          l: 3,
          r: 3,
        },
        psychometricRes: {
          wag: 63,
          anra: 24,
        },
        gba: {
          performance: 88,
          future: 82,
          award: 65,
          average: 78,
        },
        academicResults: {
          psc: 9,
          gp: 8,
          pw: 7,
          mtl: 5,
        },
        remarks: 'Very good',
        rank: 'Top 16%',
        criteria: 'Average Fit',
        faculty: null,
        time: '3:30',
      },
    ];
    const { isSideNavExpanded, selectedTab } = this.state;
    const currPath = window.location.pathname;
    let showDashboard1 = false;
    if (currPath.includes('public/list/agenda/interviews')) {
      showDashboard1 = true;
    } else {
      showDashboard1 = false;
    }
    return (
      <div>
        <PublicHeaderMock
          onClickSideNavExpand={this.onClickSideNavExpand}
          isSideNavExpanded={isSideNavExpanded}
          push={push}
          history={history}
        />
        <div className="bx--grid mt-5 pt-3">
          <GenericTabs
            className=""
            selected={selectedTab}
            tabContent={[
              {
                label: 'Agenda',
                value: '',
                onClick: () => {
                  this.handleTabClick(0, 'selectedTab');
                },
              },
              {
                label: 'My Interviews',
                value: '',
                onClick: () => {
                  this.handleTabClick(1, 'selectedTab');
                },
              },
              {
                label: 'All Interviews',
                value: '',
                onClick: () => {
                  this.handleTabClick(2, 'selectedTab');
                },
              },
            ]}
          />
          <Accordion>
            {selectedTab === 1 && (
              <Fragment>
                <AccordionItem
                  className="content-padding"
                  open
                  title={
                    <div className="h5 f-w-b bx--ty-gamma font-weight-light">
                      My Upcoming Interviews
                    </div>
                  }>
                  <div className="mb-3">
                    <GenericTabs
                      className=""
                      selected={this.state.myUpcomingTab}
                      tabContent={[
                        {
                          label: 'Panel',
                          value: '',
                          onClick: () => {
                            this.handleTabClick(0, 'myUpcomingTab');
                          },
                        },
                        {
                          label: "President's Scholarship",
                          value: '',
                          onClick: () => {
                            this.handleTabClick(1, 'myUpcomingTab');
                          },
                        },
                      ]}
                    />
                  </div>
                  {interviewsData &&
                  Array.isArray(interviewsData) &&
                  interviewsData.length > 0 ? (
                    <Fragment className="">
                      {showDashboard1 === false ? (
                        <DashboardInterviewsMock
                          showColors
                          showDate
                          interviewsData={interviewsData}
                          push={push}
                        />
                      ) : (
                        <DashboardInterviewMock1
                          showColors
                          showDate
                          interviewsData={interviewsData}
                          push={push}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Tile className="mt-2">
                      <div>
                        There are no intevriews for your current selection
                      </div>
                    </Tile>
                  )}
                </AccordionItem>
                <AccordionItem
                  className="content-padding"
                  title={
                    <div className="h5 f-w-b bx--ty-gamma font-weight-light">
                      My Past Interviews (Past 14 days)
                    </div>
                  }>
                  <div className="mb-3">
                    <GenericTabs
                      className=""
                      selected={this.state.myPastTab}
                      tabContent={[
                        {
                          label: 'Panel',
                          value: '',
                          onClick: () => {
                            this.handleTabClick(0, 'myPastTab');
                          },
                        },
                        {
                          label: "President's Scholarship",
                          value: '',
                          onClick: () => {
                            this.handleTabClick(1, 'myPastTab');
                          },
                        },
                      ]}
                    />
                  </div>
                  {interviewsData &&
                  Array.isArray(interviewsData) &&
                  interviewsData.length > 0 ? (
                    <Fragment className="">
                      {showDashboard1 === false ? (
                        <DashboardInterviewsMock
                          showColors
                          showDate
                          interviewsData={interviewsData}
                          push={push}
                        />
                      ) : (
                        <DashboardInterviewMock1
                          showColors
                          showDate
                          interviewsData={interviewsData}
                          push={push}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Tile className="mt-2">
                      <div>
                        There are no intevriews for your current selection
                      </div>
                    </Tile>
                  )}
                </AccordionItem>
              </Fragment>
            )}
            {selectedTab === 0 && (
              <Fragment>
                <AccordionItem
                  open
                  className="content-padding"
                  title={
                    <div className="h5 f-w-b bx--ty-gamma font-weight-light">
                      2020 PSC Scholarships Selection Board
                    </div>
                  }>
                  <Tile>
                    <h6>Day 23: {moment().format('dddd, D MMMM YYYY')}</h6>
                    <hr />
                    <div className="bx--row">
                      <div className="bx--col-lg-2">
                        <h5 className="mt-2 mb-2">Attending :</h5>
                      </div>
                      <div className="bx--col-lg-7">
                        <div className="mt-2 mb-2">
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Lee Tzu Yang
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Chairman
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Kwa Chong Seng
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Dy Chairman
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Michael Lim
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Commission Member
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Edward D'Silva
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Commission Member
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Alan Chan
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Commission Member
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Christopher Ng
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              MHA Rep
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mrs Elaine Ng Poh Choo
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              MINDEF Rep
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="bx--row">
                      <div className="bx--col-lg-2">
                        <h6 className="mt-2 mb-2">Not Attending :</h6>
                      </div>
                      <div className="bx--col-lg-7">
                        <div className="mt-2 mb-2">
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Ms Chua Sock Koong
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Dy Chairman
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Po'ad Mattar
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Commission Member
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Prof Lily Kong
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Commission Member
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Philip Jeyaretnam
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Commission Member
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Prof Tan Ser Kiat
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Commission Member
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Dr Sudha Nair
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Commission Member
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Mr Puah Kok Keong
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              MHA Rep
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="bx--row">
                      <div className="bx--col-lg-2">
                        <h6 className="mt-2 mb-2">
                          Secretariat In Attendance :
                        </h6>
                      </div>
                      <div className="bx--col-lg-7">
                        <div className="mt-2 mb-2">
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Ms Ong Toon Hui
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Secretary, PSC
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Dr Ng Li Sa
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Director, PSC Secretariat
                            </div>
                          </div>
                          <div className="bx--row mt-3 mb-3">
                            <div className="bx--col-lg-5 css-text-color">
                              Ms Samantha Wong
                            </div>
                            <div className="bx--col-lg-5 css-text-color">
                              Senior Executive, PSC Secretariat
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tile>
                </AccordionItem>

                <AccordionItem
                  className="content-padding"
                  title={
                    <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                      Agenda
                    </div>
                  }>
                  <Tile>
                    <h6 className="mb-2">
                      Interviews of Candidates for PSC Scholarship
                    </h6>
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'S/No',
                        },
                        {
                          key: 'header_1',
                          header: 'Name',
                        },
                        {
                          key: 'header_2',
                          header: 'Time of Interview',
                        },
                        {
                          key: 'header_3',
                          header: 'Interviewer',
                        },
                      ]}
                      rows={[
                        {
                          id: 1,
                          name: 'Isaiah Chia',
                          time: '2.00 pm',
                          interviewer: 'Mr. Lee Tzu Yang',
                        },
                        {
                          id: 2,
                          name: 'Wee Baey Kok',
                          time: '2.30 pm',
                          interviewer: 'Mr. Kwa Chong Seng',
                        },
                        {
                          id: 3,
                          name: 'Jacob Lim',
                          time: '3.00 pm',
                          interviewer: 'Ms. Chua Sock Koong',
                        },
                        {
                          id: 4,
                          name: 'Sophia Wong',
                          time: '3.30 pm',
                          interviewer: 'Mr. Michael Lim',
                        },
                        {
                          id: <hr />,
                          name: <hr />,
                          time: (
                            <div className="d-flex align-items-center">
                              <div className="mr-2">
                                Break (3:30 pm - 3:45 pm)
                              </div>
                              <div className="w-50 ml-4">
                                <hr />
                              </div>
                            </div>
                          ),
                          interviewer: <hr />,
                        },
                        {
                          id: 5,
                          name: 'Li wei',
                          time: '4.00 pm',
                          interviewer: "Mr Edward D'Silva",
                        },
                        {
                          id: 6,
                          name: 'Wang Fang',
                          time: '4.30 pm',
                          interviewer: 'Mr Alan Chan',
                        },
                        {
                          id: 7,
                          name: 'Chris Chong',
                          time: '5.00 pm',
                          interviewer: "Mr Po'ad Mattar",
                        },
                      ].map((res, idx) => {
                        const { id, name, time, interviewer } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx + 1}`,
                          header_0: <div>{id}</div>,
                          header_1: <div>{name}</div>,
                          header_2: <div>{time}</div>,
                          header_3: <div>{interviewer}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </Tile>
                </AccordionItem>
                <AccordionItem
                  className="content-padding"
                  title={
                    <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                      Today's Interviewees
                    </div>
                  }>
                  <Tile>
                    {interviewsData &&
                    Array.isArray(interviewsData) &&
                    interviewsData.length > 0 ? (
                      <Fragment className="">
                        {showDashboard1 === false ? (
                          <DashboardInterviewsMock
                            showColors
                            showDate={false}
                            interviewsData={interviewsData}
                            push={push}
                          />
                        ) : (
                          <DashboardInterviewMock1
                            showColors
                            showDate={false}
                            interviewsData={interviewsData}
                            push={push}
                            showTime
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Tile className="mt-2">
                        <div>
                          There are no intevriews for your current selection
                        </div>
                      </Tile>
                    )}
                  </Tile>
                </AccordionItem>
              </Fragment>
            )}
            {selectedTab === 2 && (
              <Fragment>
                <AccordionItem
                  open
                  className="content-padding"
                  title={
                    <div className="h5 f-w-b bx--ty-gamma font-weight-light">
                      All Upcoming Interviews
                    </div>
                  }>
                  <div className="mb-3">
                    <GenericTabs
                      className=""
                      selected={this.state.allUpcoming}
                      tabContent={[
                        {
                          label: 'Panel',
                          value: '',
                          onClick: () => {
                            this.handleTabClick(0, 'allUpcomingTab');
                          },
                        },
                        {
                          label: "President's Scholarship",
                          value: '',
                          onClick: () => {
                            this.handleTabClick(1, 'allUpcomingTab');
                          },
                        },
                      ]}
                    />
                  </div>
                  {interviewsData &&
                  Array.isArray(interviewsData) &&
                  interviewsData.length > 0 ? (
                    <Fragment className="">
                      {showDashboard1 === false ? (
                        <DashboardInterviewsMock
                          showColors
                          showDate={false}
                          interviewsData={interviewsData}
                          push={push}
                        />
                      ) : (
                        <DashboardInterviewMock1
                          showColors
                          showDate={false}
                          interviewsData={interviewsData}
                          push={push}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Tile className="mt-2">
                      <div>
                        There are no intevriews for your current selection
                      </div>
                    </Tile>
                  )}
                </AccordionItem>
                <AccordionItem
                  className="content-padding"
                  title={
                    <div className="h5 f-w-b bx--ty-gamma font-weight-light">
                      All Past Interviews (Past 14 days)
                    </div>
                  }>
                  <div className="mb-3">
                    <GenericTabs
                      className=""
                      selected={this.state.myPastTab}
                      tabContent={[
                        {
                          label: 'Panel',
                          value: '',
                          onClick: () => {
                            this.handleTabClick(0, 'allPastTab');
                          },
                        },
                        {
                          label: "President's Scholarship",
                          value: '',
                          onClick: () => {
                            this.handleTabClick(1, 'allPastTab');
                          },
                        },
                      ]}
                    />
                  </div>
                  {interviewsData &&
                  Array.isArray(interviewsData) &&
                  interviewsData.length > 0 ? (
                    <Fragment className="">
                      {showDashboard1 === false ? (
                        <DashboardInterviewsMock
                          showColors
                          showDate={false}
                          interviewsData={interviewsData}
                          push={push}
                        />
                      ) : (
                        <DashboardInterviewMock1
                          showColors
                          showDate={false}
                          interviewsData={interviewsData}
                          push={push}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Tile className="mt-2">
                      <div>
                        There are no intevriews for your current selection
                      </div>
                    </Tile>
                  )}
                </AccordionItem>
              </Fragment>
            )}
          </Accordion>
          <Tile>
            <MainLegend showNeoPIR={false} />
          </Tile>
        </div>
      </div>
    );
  }
}

export default AgendaInterviewsMock;
