import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Tile, ToggleSmall } from 'carbon-components-react';
import GenericTabs from '../../../components/common/GenericTabs';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import {
  getAsessmentCandidateInfo,
  updateAsessmentCandidate,
  updateCandAssessLinkSatus,
} from '../../../actions/Assessments';
import AssessAddCandidate from './AssessAddCandidate';
import '../CandidateInfo.css';

class AssessCandidateInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      selectedTestResult: 0,
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    this.handleGetCandidateInfo();
  }

  handleGetCandidateInfo = () => {
    const { match } = this.props;
    const { params } = match || {};
    const { candId } = params || {};

    this.props.getAsessmentCandidateInfo({ candidateId: candId });
  };

  handleTabClick = (type) => {
    let selectedTab = 0;
    let status = 'assess-links';
    switch (type) {
      case 'assess-links':
        selectedTab = 0;
        status = 'assess-links';
        break;
      case 'assess-status':
        selectedTab = 1;
        status = 'assess-status';
        break;
      case 'assess-results':
        selectedTab = 2;
        status = 'assess-results';
        break;
      case 'assess-feedback':
        selectedTab = 3;
        status = 'assess-feedback';
        break;
      default:
        status = 'assess-links';
        selectedTab = 0;
        break;
    }
    this.setState({
      selectedTab,
      status,
    });
  };

  handleTestResultTabClick = (tab) => {
    this.setState({ selectedTestResult: tab });
  };

  activeEditCandInfo = () => {
    this.setState({ isOpenEditCandInfoModal: true });
  };

  dismissEditCandModal = () => {
    this.setState({ isOpenEditCandInfoModal: false });
  };

  handleUpdateCandInfo = (data) => {
    const { match } = this.props;
    const { params } = match || {};
    const { candId } = params || {};
    this.setState({ isSubmitting: true }, () => {
      this.props
        .updateAsessmentCandidate([data])
        .then((res) => {
          const { isSuccess, error } = (res && res[candId]) || {};
          const { message } = error || {};
          this.dismissEditCandModal();
          if (isSuccess) {
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Candidate Info has been updated successfully',
                isSubmitting: false,
              },
              () => {
                this.handleGetCandidateInfo();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
              errMsg: message,
              isSubmitting: false,
            });
          }
        })
        .catch((e) => {
          this.props.dismissAddCandModal();
          this.setState({
            errorNotif: true,
            isSubmitting: false,
          });
        });
    });
  };

  handleToggleClick = (e, assessName, testName, publicUuid) => {
    this.setState({ [`${assessName}-${testName}`]: e }, () => {
      const data = { publicUuid: publicUuid, [`${testName}`]: e };
      this.props.updateCandAssessLinkSatus([data]);
    });
  };

  render() {
    const {
      selectedTab,
      selectedTestResult,
      isOpenEditCandInfoModal,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      isSubmitting,
    } = this.state;
    const { assessCandInfo } = this.props;
    const {
      firstName,
      lastName,
      fullName = '',
      nric,
      Company,
      assessmentInfo = [],
    } = assessCandInfo || {};
    const { displayName: schoolName } = Company || {};
    const tabContent = [];
    tabContent.push(
      {
        label: 'Assessment Links',
        value: '',
        onClick: () => {
          this.handleTabClick('assess-links');
        },
      },
      {
        label: 'Assessment Status',
        value: '',
        onClick: () => {
          this.handleTabClick('assess-status');
        },
      }
      // {
      //   label: 'Assessment Results',
      //   value: '',
      //   onClick: () => {
      //     this.handleTabClick('assess-results');
      //   },
      // },
      // {
      //   label: 'Assessment Feedback',
      //   value: '',
      //   onClick: () => {
      //     this.handleTabClick('assess-feedback');
      //   },
      // }
    );

    return (
      <div>
        <div className="mt-2 csc-cand-card-fixed">
          <Tile>
            <div>
              <div className="d-flex justify-content-between">
                <div className="">
                  <div
                    className="h5 font-weight-bold text-capitalize"
                    style={{ fontSize: '24px' }}>
                    {fullName}{' '}
                    <span className="text-uppercase">
                      {nric ? `(${nric})` : null}
                    </span>
                  </div>
                  <div className="d-flex ml-0 mb-2">
                    {schoolName && (
                      <div className="css-default-text-color f-w-b">
                        {schoolName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="">
                  <div>
                    <Button
                      onClick={() => this.activeEditCandInfo()}
                      kind="tertiary"
                      size="small">
                      Edit
                    </Button>
                  </div>
                  {isOpenEditCandInfoModal && (
                    <AssessAddCandidate
                      dismissAddCandModal={this.dismissEditCandModal}
                      handleUpdateCandInfo={this.handleUpdateCandInfo}
                      isSubmitting={isSubmitting}
                    />
                  )}
                </div>
              </div>
            </div>
          </Tile>
        </div>
        <div className="cand-card-margin-top">
          <input
            style={{ height: 0, opacity: 0 }}
            type="text"
            ref={this.handleRedirect}
          />
          <SuccErrNotification
            successNotif={successNotif}
            errorNotif={errorNotif}
            notifMsg={notifMsg}
            errMsg={errMsg}
            handleCloseNotifBtn={this.handleCloseNotifBtn}
          />
        </div>

        <Tile className="mt-2 css-vertical-min-h">
          <div className="bx--row">
            <div className="bx--col-lg-2 bx--col-md-2 cand-info-tabs">
              <GenericTabs
                className="xpa-cand-info--tabs xpa-c-tabs--vertical m-0"
                selected={selectedTab}
                tabContent={tabContent}
              />
            </div>
            <div className="bx--col-lg-10 bx--col-md-6 pr-1 pl-0">
              {selectedTab === 0 && (
                <div className="">
                  <div
                    style={{ fontSize: 13 }}
                    className="bx--row m-0 text-uppercase  text-secondary font-weight-bold">
                    <div className="bx--col-lg-3 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 d-lg-block d-md-block d-none">
                      Assessment
                    </div>
                    <div className="bx--col-lg-5 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 d-lg-block d-md-block d-none">
                      Link
                    </div>
                    {/* <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 d-lg-block d-md-block d-none">
                      Action
                    </div> */}
                  </div>
                  <hr className="mb-2 mt-2" />
                  {assessmentInfo.map((res) => {
                    const { Scenariotestmap, assessmentLink } = res || {};
                    const { Test } = Scenariotestmap || {};
                    const { displayName = '' } = Test || {};
                    return (
                      <Fragment>
                        <div className=" bx--row align-items-center m-0">
                          <div className="font-weight-bold d-flex align-items-center text-capitalize bx--col-lg-3 bx--col-md-2 bx--col-sm-12 bx--col-xs-12">
                            {displayName}
                          </div>
                          <div
                            title={assessmentLink}
                            className=" ml-0 bx--col-lg-9 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 xpa-link">
                            {assessmentLink ?? '-'}
                          </div>
                          {/* <div className=" text-dark ml-0 bx--col-lg-4 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 text-capitalize">
                            <div className="d-flex">
                              <div className="">
                                <Button size="small" onClick={() => {}}>
                                  Copy
                                </Button>
                              </div>
                              <div className="ml-3">
                                <Button size="small" onClick={() => {}}>
                                  Send Link via Email
                                </Button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <hr className="mb-2 mt-2" />
                      </Fragment>
                    );
                  })}
                </div>
              )}
              {selectedTab === 1 && (
                <div className="">
                  <div
                    style={{ fontSize: 13 }}
                    className="bx--row m-0 text-uppercase  text-secondary font-weight-bold">
                    <div className="bx--col-lg-3 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 d-lg-block d-md-block d-none">
                      Assessment
                    </div>
                    <div className="bx--col-lg-5 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 d-lg-block d-md-block d-none">
                      Status
                    </div>
                    <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 d-lg-block d-md-block d-none">
                      Action
                    </div>
                  </div>
                  <hr className="mb-2 mt-2" />
                  {assessmentInfo &&
                    Array.isArray(assessmentInfo) &&
                    assessmentInfo.length > 0 &&
                    assessmentInfo.map((res) => {
                      const {
                        Scenariotestmap,
                        publicUuid,
                        markAsExpired,
                        assessmentStatus,
                      } = res || {};
                      const { Test } = Scenariotestmap || {};
                      const { displayName = '' } = Test || {};
                      return (
                        <Fragment>
                          <div className=" bx--row  m-0">
                            <div className="font-weight-bold d-flex align-items-center text-capitalize bx--col-lg-3 bx--col-md-2 bx--col-sm-12 bx--col-xs-12">
                              {displayName}
                            </div>
                            <div className=" ml-0 bx--col-lg-5 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 xpa-name-overflow">
                              {assessmentStatus}
                            </div>
                            <div className=" text-dark ml-0 bx--col-lg-4 bx--col-md-2 bx--col-sm-12 bx--col-xs-12 text-capitalize">
                              <div
                                className=""
                                style={{
                                  marginTop: '-1rem',
                                }}>
                                <ToggleSmall
                                  id={`${displayName}-markAsExpired`}
                                  name={`${displayName}-markAsExpired`}
                                  className=""
                                  labelText=""
                                  labelA="Active"
                                  labelB="Expired"
                                  toggled={
                                    this.state[
                                      `${displayName}-markAsExpired`
                                    ] ?? markAsExpired
                                  }
                                  onToggle={(e) =>
                                    this.handleToggleClick(
                                      e,
                                      displayName,
                                      'markAsExpired',
                                      publicUuid
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <hr className="mb-2 mt-2" />
                        </Fragment>
                      );
                    })}
                </div>
              )}
              {selectedTab === 2 && (
                <div className="">
                  <GenericTabs
                    className="xpa-psc-appr-w"
                    selected={selectedTestResult}
                    tabContent={[
                      {
                        label: 'ucognify',
                        value: '',
                        onClick: () => {
                          this.handleTestResultTabClick(0);
                        },
                      },
                      {
                        label: 'Neo Profile',
                        value: '',
                        onClick: () => {
                          this.handleTestResultTabClick(1);
                        },
                      },
                    ]}
                  />
                  <div className="mt-2">
                    {selectedTestResult === 0 && (
                      <div className="h6">uCognify Test Result : 61</div>
                    )}
                  </div>
                </div>
              )}
              {selectedTab === 3 && <div className="">Feedback Tab</div>}
            </div>
          </div>
        </Tile>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
  assessCandInfo: state.Assessments.assessCandInfo,
});

const mapDispatchToProps = {
  getAsessmentCandidateInfo,
  updateAsessmentCandidate,
  updateCandAssessLinkSatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessCandidateInfo);
