import React from 'react';

import flaskLoaderImg from '../../assets/images/loader.svg';

const FlaskLoader = () => (
  <div className="xpa-loader-wrap">
    <img
      style={{ width: '100px', height: '100px' }}
      src={flaskLoaderImg}
      d={flaskLoaderImg}
      alt="Application Loader"
    />
  </div>
);

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="feather feather-edit">
      <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
      <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
    </svg>
  );
};

export { FlaskLoader, EditIcon };
