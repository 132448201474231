import React from 'react';
import { connect } from 'react-redux';
import { InlineNotification } from 'carbon-components-react';
import { passwordStrength } from 'check-password-strength';
import {
  defaultPassOptions,
  validatePassword,
} from '../../containers/common/common';
class ResetPassword extends React.Component {
  constructor() {
    super();
  }
  state = { token: '' };

  componentDidMount() {
    let token = this.getQueryParams('token');
    if (token && token.length > 0) this.setState({ token });
  }

  handleOnChange = (name, value) => {
    let passwordErrMsg;
    if (name === 'password') {
      let passwordComplexity = passwordStrength(value, defaultPassOptions);
      passwordErrMsg = validatePassword(passwordComplexity);
    }

    this.setState({
      [name]: value,
      passwordErrMsg,
      confirmPasswordErrMsg: null,
    });
  };

  changeAndValidate = (name, value) => {
    const { password } = this.state;
    let passwordErrMsg;
    let confirmPasswordErrMsg;
    if (name === 'confirmpassword') {
      let passwordComplexity = passwordStrength(password, defaultPassOptions);
      passwordErrMsg = validatePassword(passwordComplexity);
      if (value && value.trim().length > 0) {
        confirmPasswordErrMsg = null;
      }
    }

    this.setState({
      [name]: value,
      passwordErrMsg,
      confirmPasswordErrMsg,
    });
  };

  getQueryParams = (key) => {
    var qstring = window.location.href
      .slice(window.location.href.indexOf('?') + 1)
      .split('&');
    for (var i = 0; i < qstring.length; i++) {
      var urlparam = qstring[i].split('=');
      if (urlparam[0] == key) {
        return urlparam[1];
      }
    }
  };

  updatePassword = () => {
    const { password, confirmpassword } = this.state;
    let token = this.getQueryParams('token');
    let passwordErrMsg;
    let confirmPasswordErrMsg;
    let passwordComplexity = passwordStrength(password, defaultPassOptions);
    passwordErrMsg = validatePassword(passwordComplexity);
    confirmPasswordErrMsg =
      password !== confirmpassword ? 'Must match your new password.' : null;
    this.setState({
      passwordErrMsg,
      confirmPasswordErrMsg,
      token: token && token.length > 0 ? token : null,
    });
    if (passwordErrMsg || confirmPasswordErrMsg) {
      return;
    }

    const eData = {
      newPassword: this.state.password,
    };

    fetch('/roboroy/api/v1/userinfos/update-password?access_token=' + token, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(eData),
    })
      .then(function (response) {
        return response.json();
      })
      .then((resJSON) => {
        if (resJSON && resJSON.error) {
          this.setState({
            validationError: {
              message:
                resJSON.error.message ||
                'There was an error in processing. Please try again later.',
            },
          });
        } else {
          alert('Password reset was successful. Redirecting to login page.');
          window.sessionStorage.setItem('loggedout', true);
          window.location.href = '/auth/logout';
        }
      })
      .catch((err) =>
        this.setState({
          validationError: {
            message:
              'There was an error in processing. Please try again later.',
          },
        })
      );
  };

  render() {
    const { passwordErrMsg, confirmPasswordErrMsg } = this.state;
    const { isUserLoggedIn } = this.props;
    const isLoggedOutCase =
      window.location.href.toLowerCase().indexOf('token') > -1;
    return (
      <div
        style={{ height: '100vh' }}
        className=" login-page container css-forgot-pwd-pd">
        <div className="bx--row justify-content-center">
          <div className="bx--col-xl-10 bx--col-lg-12 bx--col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div style={{ minHeight: '400px' }} className="card-body p-0">
                <div style={{ minHeight: '400px' }} className=" bx--row ">
                  <div className="bx--col-lg-6 d-none d-lg-block left-side p-0">
                    <div
                      className="col m-0 p-0 d-flex justify-content-center align-items-center"
                      style={{
                        background: '#4281cc',
                        height: '100%',
                        color: 'white',
                      }}>
                      <div className="m-3 text-center p-2">
                        <div
                          style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '2.5rem',
                          }}
                          className="mb-2">
                          {' '}
                          Changing your password?
                        </div>
                        <p class="mb-4">
                          Just enter your new password and submit to change your
                          existing password.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bx--col-lg-6">
                    {(isUserLoggedIn || isLoggedOutCase) && (
                      <div
                        style={{ minHeight: '400px' }}
                        className=" bx--row align-items-center justify-content-center">
                        <div className="bx--col-md-12 mb-4">
                          <form
                            className=""
                            onSubmit={(e) => {
                              e.preventDefault();
                              this.updatePassword();
                              this.setState({ isLogging: true });
                            }}>
                            <div className="  pt-5 pl-2 pb-5 pr-3">
                              {/* <div class="text-center">
                                <a href={window.location.origin || '#'}>
                                  <img
                                    className="mb-4 mx-auto"
                                    src={require('../../assets/images/csc-image.png')}
                                    alt=""
                                    width="25%"
                                  />
                                </a>
                              </div> */}

                              <div className="bx--type-delta pb-2 ml-3">
                                Change Password
                              </div>
                              {this.state.validationError && (
                                <InlineNotification
                                  lowContrast
                                  className="mt-0"
                                  title={
                                    this.state.validationError &&
                                    this.state.validationError.message
                                  }
                                  subtitle=""
                                  iconDescription="describes the close button"
                                  kind="error"
                                  hideCloseButton
                                />
                              )}
                              <div
                                className="pb-2 ml-3"
                                style={{ maxWidth: '400px' }}>
                                <div>
                                  <input
                                    type="password"
                                    name="password"
                                    required
                                    autofocus
                                    size="52"
                                    placeholder="New password (atleast 12 characters)"
                                    className="bx--text-input mb-2"
                                    onChange={(e) =>
                                      this.handleOnChange(
                                        'password',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {passwordErrMsg && (
                                  <div className="mb-3 text-small text-danger">
                                    {passwordErrMsg}
                                  </div>
                                )}
                                <div>
                                  <input
                                    type="password"
                                    name="confirmpassword"
                                    required
                                    size="52"
                                    placeholder="Confirm password"
                                    className="bx--text-input mb-2 "
                                    onChange={(e) =>
                                      this.changeAndValidate(
                                        'confirmpassword',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {confirmPasswordErrMsg && (
                                  <div className="mb-2 text-small text-danger">
                                    {confirmPasswordErrMsg}
                                  </div>
                                )}
                                <div>
                                  <button
                                    className="bx--btn bx--btn--primary "
                                    disabled={
                                      passwordErrMsg || confirmPasswordErrMsg
                                        ? 'disabled'
                                        : ''
                                    }
                                    type="submit">
                                    Submit
                                  </button>
                                </div>
                              </div>
                              {this.state.token &&
                              this.state.token.length > 0 ? (
                                <a className="mt-2 ml-3" href="/">
                                  &larr; Go back to login page
                                </a>
                              ) : (
                                <a
                                  className="mt-2 ml-3"
                                  href="/app/csc/admin/profile">
                                  &larr; Go back to previous page
                                </a>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                    {!isUserLoggedIn && !isLoggedOutCase && (
                      <div className=" bx--row ">
                        <div className="bx--col-md-12 mb-4">
                          <form className="text-center">
                            <div className="  pt-5 pl-2 pb-5 pr-3">
                              {/* <div class="text-center">
                                <a href={window.location.origin || '#'}>
                                  <img
                                    className="mb-4 mx-auto"
                                    src={require('../../assets/images/csc-image.png')}
                                    alt=""
                                    width="25%"
                                  />
                                </a>
                              </div> */}

                              <div class="text-center">
                                Please login to reset your password.
                              </div>
                              <a className="pt-2" href="/auth/logout">
                                &larr; Go back to previous page
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
