import React, { Component } from 'react';
import { connect } from 'react-redux';

//Api Actions
import { getAuditLogsData } from '../../actions/AuditLogs';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';

//Functional common components
import AuditLogsFilterFacets from '../../components/private/AuditLogsFilterFacets';

//Common packages
import {
  Button,
  Pagination,
  Select,
  SelectItem,
  InlineNotification,
  Tile,
  Search,
  Form,
} from 'carbon-components-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../../components/common/Dropdown';

const headers = [
  {
    key: 'header_0',
    header: 'Date',
  },
  {
    key: 'header_1',
    header: 'Time',
  },
  {
    key: 'header_2',
    header: 'Who',
  },
  {
    key: 'header_3',
    header: 'Role',
  },
  {
    key: 'header_4',
    header: 'Candidate',
  },
  {
    key: 'header_5',
    header: 'What Function',
  },
  {
    key: 'header_6',
    header: 'IP Address',
  },
  {
    key: 'header_7',
    header: 'Device',
  },
  {
    key: 'header_8',
    header: 'OS',
  },
  {
    key: 'header_9',
    header: 'Browser',
  },
];

const searchByDropdownList = [
  { label: 'Date' },
  { label: 'Time' },
  { label: 'Who' },
  { label: 'Role' },
  { label: 'Candidate' },
  { label: 'What Function' },
];

const filterByDropdownList = [
  { label: 'Users', value: 'users' },
  { label: 'Candidates', value: 'candidates' },
  { label: 'Roles', value: 'roles' },
];

const getCandDetails = (profileId, candidateName) => {
  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
          Profile Id:
        </div>
        <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
          {profileId || '-'}
        </div>
      </div>
      <div className="bx--row mt-2">
        <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
          Candidate Name:
        </div>
        <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
          {candidateName || '-'}
        </div>
      </div>
    </>
  );
};

const getReasonDetails = (reason) => {
  return (
    <div className="bx--row mt-2">
      <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
        Reason for Failure:
      </div>
      <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
        {reason || '-'}
      </div>
    </div>
  );
};

const getCMSMeetingInfo = (purpose, startDate, meetingType) => {
  return (
    <div>
      <div className="bx--row">
        <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
          Purpose:
        </div>
        <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
          {purpose || '-'}
        </div>
      </div>
      <div className="bx--row mt-2">
        <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
          Date:
        </div>
        <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
          {startDate ? moment(startDate).format('MM/DD/YYYY') : '-'}
        </div>
      </div>
      <div className="bx--row mt-2">
        <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
          Meeting Type:
        </div>
        <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
          {meetingType || '-'}
        </div>
      </div>
    </div>
  );
};

class AuditLogs extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'audit-logs', name: 'Audit Logs', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      searchKey: null,
      activePage: 1,
      itemsPerPage: 10,
      totalCount: 0,
      startTime: moment().subtract(10, 'days').toDate(),
      endTime: moment().toDate(),
      isStartEndDatesInvalid: false,
      userIdList: [],
      roleIdList: [],
      candidateIdList: [],
      filterByDropdownValue: 'select',
      currFilterOptions: [],
      users: [],
      userRoles: [],
      candidates: [],
      leftFilterType: 'login',
    };
  }

  componentDidMount() {
    this.handleGetAuditLogs();
  }

  handleGetAuditLogs = () => {
    const {
      startTime,
      endTime,
      searchKey,
      activePage,
      itemsPerPage,
      selectedFilterList,
      filterByDropdownValue,
      leftFilterType,
    } = this.state;

    const data = {
      type: leftFilterType,
      startTime: startTime && new Date(startTime).getTime(),
      endTime: endTime && new Date(endTime).getTime(),
      searchKey,
      activePage,
      itemsPerPage,
    };

    if (
      selectedFilterList &&
      Array.isArray(selectedFilterList) &&
      selectedFilterList.length > 0
    ) {
      const finalFilterList = selectedFilterList.map((itm) => itm?.value);
      if (filterByDropdownValue === 'users') {
        data.userIdList = finalFilterList;
      } else if (filterByDropdownValue === 'candidates') {
        data.candidateIdList = finalFilterList;
      } else if (filterByDropdownValue === 'roles') {
        data.roleIdList = finalFilterList;
      }
    }

    this.props.getAuditLogsData(data).then((res) => {
      const { facets, list, totalCount = 0 } = res || {};
      const { users, userroles: userRoles, candidates } = facets || {};
      let currFilterOptions = [];
      if (filterByDropdownValue === 'users') {
        currFilterOptions = this.getOptions(users);
      } else if (filterByDropdownValue === 'candidates') {
        currFilterOptions = this.getOptions(candidates);
      } else if (filterByDropdownValue === 'roles') {
        currFilterOptions = this.getOptions(userRoles);
      }
      this.setState({
        auditLogsList: list,
        totalCount: totalCount && Number(totalCount),
        currFilterOptions,
      });
    });
  };

  handleChange = (e) => {
    const { name, value } = e?.target || {};
    this.setState(
      {
        [name]: value,
        selectedFilterList: null,
      },
      () => this.handleGetAuditLogs()
    );
  };

  handleOnChangeCheckboxFilter = (
    e,
    filterData,
    filterIdx,
    idx,
    auditLogKey
  ) => {
    let leftFilterValues = [];
    let leftFilterSection = null;
    let type = null;
    if (e === true) {
      leftFilterValues = filterData.map((itm, fIndex) => {
        const { key, values } = filterData[fIndex] || {};
        return values.map((ele, index) => {
          if (fIndex === filterIdx && index === idx) {
            leftFilterSection = key;
            type = auditLogKey;
            return true;
          } else {
            return false;
          }
        });
      });
    } else if (e === false) {
      leftFilterValues = filterData.map((itm, fIndex) => {
        const { values } = filterData[fIndex] || {};
        return values.map((ele) => false);
      });
    }
    this.setState(
      {
        leftFilterValues,
        leftFilterSection,
        leftFilterType: type,
        filterByDropdownValue: 'select',
        currFilterOptions: [],
        selectedFilterList: [],
      },
      () => {
        this.handleGetAuditLogs();
      }
    );
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetAuditLogs();
    });
  };

  onDatePickerChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleSubmitDates = () => {
    this.setState(
      {
        isStartEndDatesInvalid: this.state.startTime > this.state.endTime,
      },
      () => {
        if (!this.state.isStartEndDatesInvalid) {
          this.handleGetAuditLogs();
        }
      }
    );
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState(
      {
        [name]: metaValue,
      },
      () => {
        this.handleGetAuditLogs();
      }
    );
  };

  getOptions = (filterByList) => {
    const { filterByDropdownValue: val } = this.state;
    let optionList = [];

    if (
      filterByList &&
      Array.isArray(filterByList) &&
      filterByList.length > 0
    ) {
      if (val === 'users') {
        optionList = filterByList.map((itm) => {
          const { userId, userName } = itm || {};
          return {
            label: userName || '',
            value: userId || '',
          };
        });
      } else if (val === 'candidates') {
        optionList = filterByList.map((itm) => {
          const { profileId, candidateName } = itm || {};
          return {
            label: candidateName || '',
            value: profileId || '',
          };
        });
      } else if (val === 'roles') {
        optionList = filterByList.map((itm) => {
          const { roleId, roleName } = itm || {};
          return {
            label: roleName || '',
            value: roleId || '',
          };
        });
      }
    }

    return optionList;
  };

  render() {
    const {
      searchByDropDownValue,
      searchKey,
      filterByDropdownValue,
      activePage,
      totalCount,
      auditLogsList,
      startTime,
      endTime,
      isStartEndDatesInvalid,
      currFilterOptions,
      selectedFilterList,
      leftFilterValues,
      leftFilterType,
      leftFilterSection,
    } = this.state;

    // const marr = moment().toArray();
    // const minTime = moment([marr[0], marr[1], marr[2], '00']).toDate();
    // const maxTime = moment([
    //   marr[0],
    //   marr[1],
    //   marr[2],
    //   new Date().getHours(),
    // ]).toDate();

    return (
      <div>
        <BackRow paths={this.paths} />
        <div className="bx--row">
          <div className="bx--col-lg-3 mt-2 bx--col-md-3 bx--col-xs-12 bx--col-sm-12">
            <AuditLogsFilterFacets
              handleOnChangeCheckboxFilter={this.handleOnChangeCheckboxFilter}
              state={this.state}
              handleClearFilters={this.handleClearFilters}
              leftFilterValues={leftFilterValues}
              leftFilterType={leftFilterType}
            />
          </div>
          <div className="bx--col-lg-9 bx--col-md-5 bx--col-xs-12 bx--col-sm-12 mt-2">
            <Tile className="css-search-height">
              {/* <h4 className="">Search Logs</h4>
              <Form onSubmit={this.handleSearchSubmit}>
                <div className="bx--row">
                  <div className="bx--col-lg-10 mt-2">
                    <div className="d-flex">
                      <div>
                        <Select
                          onChange={this.handleChange}
                          name="searchByDropDownValue"
                          value={searchByDropDownValue}
                          hideLabel
                          size="xl"
                          light>
                          <SelectItem text="Select" disabled />
                          {searchByDropdownList &&
                            Array.isArray(searchByDropdownList) &&
                            searchByDropdownList.length > 0 &&
                            searchByDropdownList.map((itm) => {
                              const { label } = itm || {};
                              return (
                                <SelectItem
                                  text={label}
                                  id={label}
                                  value={label}
                                  key={label}
                                />
                              );
                            })}
                        </Select>
                      </div>
                      <div className="w-100">
                        <Search
                          id="search-1"
                          labelText="Search"
                          name="searchKey"
                          onChange={this.handleChange}
                          placeHolderText="Search for Logs"
                          size="xl"
                          type="text"
                          value={searchKey}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bx--col-lg-2 mt-2">
                    <Button onClick={this.handleSearchSubmit} kind="primary">
                      Submit
                    </Button>
                  </div>
                </div>
              </Form> */}
              {isStartEndDatesInvalid && (
                <InlineNotification
                  lowContrast
                  onCloseButtonClick={() => {}}
                  className="mt-1 mb-1"
                  title={'Please select a valid start date and end date.'}
                  subtitle=""
                  hideCloseButton
                  iconDescription="describes the close button"
                  kind="error"
                />
              )}
              <div className="bx-row">
                <div className="d-flex flex-column flex-lg-row">
                  <div className="">
                    <div className="bx--label mb-2">From:</div>
                    <div className="">
                      <DatePicker
                        className="css-h-d-input"
                        selected={startTime}
                        onChange={(date) =>
                          this.onDatePickerChange(date, 'startTime')
                        }
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy, h:mm aa"
                        maxDate={moment().toDate()}
                      />
                    </div>
                  </div>
                  <div className="ml-lg-2 mt-2 mt-lg-0">
                    <div className="bx--label mb-2">To:</div>
                    <div className="">
                      <DatePicker
                        className="css-h-d-input"
                        selected={endTime}
                        onChange={(date) =>
                          this.onDatePickerChange(date, 'endTime')
                        }
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="dd/MM/yyyy, h:mm aa"
                        maxDate={moment().toDate()}
                        // minTime={minTime}
                        // maxTime={maxTime}
                      />
                    </div>
                  </div>
                  <div className="ml-lg-2 mt-2 mt-lg-4 pt-2">
                    <Button
                      onClick={this.handleSubmitDates}
                      className="mr-2"
                      size="small">
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
              <hr />
              <div className="bx--row px-3">
                <div className="d-flex w-100">
                  <div>
                    <Select
                      onChange={this.handleChange}
                      name="filterByDropdownValue"
                      value={filterByDropdownValue}
                      hideLabel
                      size="md"
                      light>
                      <SelectItem text="Select" value="select" disabled />
                      {filterByDropdownList.map((itm) => {
                        const { label, value } = itm || {};
                        return (
                          <SelectItem
                            text={label}
                            id={label}
                            value={value}
                            key={label}
                          />
                        );
                      })}
                    </Select>
                  </div>
                  <div className="ml-3 w-100" style={{ marginTop: '0.08rem' }}>
                    <Dropdown
                      title=""
                      mainClassName="bx--form-item mb-0"
                      titleClass=""
                      labelClass=""
                      isMulti
                      onInputChange={() => {}}
                      className="w-100"
                      options={currFilterOptions}
                      onChange={(value, e, name) =>
                        this.handleDropdownChange(value, e, name)
                      }
                      name="selectedFilterList"
                      selectedValue={selectedFilterList}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                {auditLogsList &&
                  Array.isArray(auditLogsList) &&
                  auditLogsList.length > 0 && (
                    <div className="">
                      <GenericDataTable
                        className=""
                        tbodyClass="candidate-background-color"
                        otherActions={[]}
                        headers={headers}
                        rows={auditLogsList.map((log, idx) => {
                          const {
                            timestamp,
                            activity_done_by_name,
                            activity_done_by_id,
                            details,
                            activity_type,
                            ip_address,
                            device,
                            os_info,
                            browser,
                            role,
                          } = log || {};
                          const { candidateName } = details || {};
                          const { roleName } = role || {};
                          return {
                            isExpanded: false,
                            id: `row_${idx + 1}`,
                            header_0: (
                              <div>
                                {timestamp
                                  ? moment(timestamp).format('MM/DD/YYYY')
                                  : '-'}
                              </div>
                            ),
                            header_1: (
                              <div style={{ minWidth: '80px' }}>
                                {timestamp
                                  ? moment(timestamp).format('hh:mm:ss a')
                                  : '-'}
                              </div>
                            ),
                            header_2: (
                              <div style={{ minWidth: '120px' }}>
                                {activity_done_by_name ||
                                  activity_done_by_id ||
                                  '-'}
                              </div>
                            ),
                            header_3: <div>{roleName || '-'}</div>,
                            header_4: <div>{candidateName || '-'}</div>,
                            header_5: (
                              <div className="text-capitalize">
                                {activity_type || '-'}
                              </div>
                            ),
                            header_6: <div>{ip_address || '-'}</div>,
                            header_7: (
                              <div className="text-capitalize">
                                {device || '-'}
                              </div>
                            ),
                            header_8: <div>{os_info || '-'}</div>,
                            header_9: <div>{browser || '-'}</div>,
                          };
                        })}
                        expandedRows={auditLogsList.map((log) => {
                          const {
                            is_successful,
                            details,
                            user_affected_id,
                            user_affected_name,
                            comments,
                            activity_done_by_name,
                          } = log || {};
                          const {
                            reason,
                            list,
                            uploadedFileName,
                            candidateName,
                            profileId,
                            reportName,
                            assignedUserEmail,
                            assignedUserFName = '',
                            assignedUserLName = '',
                            oldAssignedUserEmail,
                            validPayload = [],
                            changesMade = [],
                            purpose,
                            startDate,
                            meetingType,
                          } = details || {};
                          const assignedUserFullName =
                            assignedUserFName + ' ' + assignedUserLName;
                          return (
                            <Tile>
                              {leftFilterSection === 'logged-info' ? (
                                <>
                                  {is_successful === false &&
                                    getReasonDetails(reason)}
                                </>
                              ) : leftFilterSection ===
                                'user-panel-activity' ? (
                                <>
                                  <div className="bx--row">
                                    <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                      {user_affected_name
                                        ? 'User Affected Name:'
                                        : 'User Affected Id:'}
                                    </div>
                                    <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
                                      {user_affected_name ||
                                        user_affected_id ||
                                        '-'}
                                    </div>
                                  </div>
                                  {is_successful === false &&
                                    getReasonDetails(reason)}
                                </>
                              ) : leftFilterSection === 'selection-board' ? (
                                <div className="">
                                  <GenericDataTable
                                    className=""
                                    tbodyClass="candidate-background-color"
                                    otherActions={[]}
                                    headers={[
                                      {
                                        key: 'header_0',
                                        header: 'Interviewer',
                                        className: 'w-25',
                                      },
                                      {
                                        key: 'header_1',
                                        header: 'Designation',
                                        className: 'w-25',
                                      },
                                      {
                                        key: 'header_2',
                                        header: 'Attendance',
                                        className: 'w-25',
                                      },
                                    ]}
                                    rows={
                                      list &&
                                      Array.isArray(list) &&
                                      list.length > 0
                                        ? list.map((res, idx) => {
                                            const { action, info } = res || {};
                                            const {
                                              aliasName,
                                              designation,
                                              userInfo,
                                            } = info || {};
                                            const {
                                              firstname = '',
                                              lastname = '',
                                            } = userInfo || {};

                                            return {
                                              isExpanded: true,
                                              id: `row_${idx}`,
                                              key: `row_${idx}`,
                                              header_0: (
                                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                  <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                    Interviewer:
                                                  </div>
                                                  <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                    {aliasName
                                                      ? aliasName
                                                      : firstname +
                                                        ' ' +
                                                        lastname}
                                                  </div>
                                                </div>
                                              ),
                                              header_1: (
                                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                  <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                    Designation:
                                                  </div>
                                                  <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                    {designation || ''}
                                                  </div>
                                                </div>
                                              ),
                                              header_2: (
                                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                  <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                    Attendance:
                                                  </div>
                                                  <div className="d-flex align-items-center bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                    <div>
                                                      {action === true ? (
                                                        <span className="bx--tag bx--tag--green text-uppercase">
                                                          Yes
                                                        </span>
                                                      ) : (
                                                        <span className="bx--tag bx--tag--red text-uppercase">
                                                          No
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              ),
                                            };
                                          })
                                        : []
                                    }
                                    selectable={false}
                                    searchable={false}
                                    onSearchInputChange={() => {}}
                                    expandable={false}
                                    batchActions={false}
                                  />
                                </div>
                              ) : leftFilterSection === 'file-upload' ? (
                                <div className="">
                                  {getCandDetails(profileId, candidateName)}
                                  {leftFilterType ===
                                    'reports-tab-file-upload' ||
                                  leftFilterType ===
                                    'reports-tab-file-deleted' ? (
                                    <div className="bx--row mt-2">
                                      <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                        Report Name:
                                      </div>
                                      <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
                                        {reportName || '-'}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="bx--row mt-2">
                                      <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                        Uploaded File Name:
                                      </div>
                                      <div className="bx--col-lg-3 bx--col-md-3 text-capitalize bx--col-sm-12 bx--col-xs-12">
                                        {uploadedFileName || '-'}
                                      </div>
                                    </div>
                                  )}
                                  {is_successful === false &&
                                    getReasonDetails(reason)}
                                </div>
                              ) : leftFilterSection === 'gba-reports' ? (
                                <div className="">
                                  {getCandDetails(profileId, candidateName)}
                                  {is_successful === false &&
                                    getReasonDetails(reason)}
                                </div>
                              ) : leftFilterSection === 'video-presentation' ||
                                leftFilterSection === 'evaluation-form' ? (
                                <div className="">
                                  {getCandDetails(profileId, candidateName)}
                                  {is_successful === false &&
                                    getReasonDetails(reason)}
                                </div>
                              ) : leftFilterSection === 'secretary-comments' ||
                                leftFilterSection === 'private-comments' ? (
                                <div className="">
                                  {getCandDetails(profileId, candidateName)}
                                  {comments && (
                                    <div className="bx--row mt-2">
                                      <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                        Comments:
                                      </div>
                                      <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                        {comments || '-'}
                                      </div>
                                    </div>
                                  )}
                                  {is_successful === false &&
                                    getReasonDetails(reason)}
                                </div>
                              ) : leftFilterSection ===
                                'interview-assignment' ? (
                                <div className="">
                                  {oldAssignedUserEmail && assignedUserEmail ? (
                                    <div className="">
                                      {activity_done_by_name} update{' '}
                                      {candidateName} interview to{' '}
                                      {assignedUserFullName}
                                    </div>
                                  ) : !oldAssignedUserEmail ? (
                                    <div>
                                      {activity_done_by_name} assign{' '}
                                      {candidateName} interview to{' '}
                                      {assignedUserFullName}
                                    </div>
                                  ) : !assignedUserEmail ? (
                                    <div>
                                      {activity_done_by_name} deletes{' '}
                                      {candidateName} interview
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {is_successful === false && (
                                    <div className="bx--row mt-2">
                                      <div className="bx--col-lg-2 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                        Reason for Failure:
                                      </div>
                                      <div className="bx--col-lg-3 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                        {reason || '-'}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : leftFilterSection === 'cms-meetings' ? (
                                <div>
                                  {leftFilterType === 'cms-meeting-creation' ? (
                                    <GenericDataTable
                                      className=""
                                      tbodyClass="candidate-background-color"
                                      otherActions={[]}
                                      headers={[
                                        {
                                          key: 'header_0',
                                          header: 'Purpose',
                                        },
                                        {
                                          key: 'header_1',
                                          header: 'Date',
                                        },
                                        {
                                          key: 'header_2',
                                          header: 'Start Time',
                                        },
                                        {
                                          key: 'header_3',
                                          header: 'End Time',
                                        },
                                        {
                                          key: 'header_4',
                                          header: 'Time Period',
                                        },
                                        {
                                          key: 'header_5',
                                          header: 'Meeting Type',
                                        },
                                      ]}
                                      rows={
                                        validPayload &&
                                        Array.isArray(validPayload) &&
                                        validPayload.length > 0
                                          ? validPayload.map((res, idx) => {
                                              const {
                                                endTime,
                                                purpose,
                                                startDate,
                                                startTime,
                                                timePeriod,
                                                meetingType,
                                              } = res || {};
                                              return {
                                                isExpanded: true,
                                                id: `row_${idx}`,
                                                key: `row_${idx}`,
                                                header_0: (
                                                  <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                    <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                      Purpose:
                                                    </div>
                                                    <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                      {purpose}
                                                    </div>
                                                  </div>
                                                ),
                                                header_1: (
                                                  <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                    <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                      Start Date:
                                                    </div>
                                                    <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                      {startDate
                                                        ? moment(
                                                            startDate
                                                          ).format('MM/DD/YYYY')
                                                        : '-'}
                                                    </div>
                                                  </div>
                                                ),
                                                header_2: (
                                                  <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                    <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                      Start Time:
                                                    </div>
                                                    <div className="d-flex align-items-center bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                      <div>
                                                        {startTime
                                                          ? moment(
                                                              startTime
                                                            ).format('hh:mm')
                                                          : '-'}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ),
                                                header_3: (
                                                  <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                    <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                      End Time:
                                                    </div>
                                                    <div className="d-flex align-items-center bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                      <div>
                                                        {endTime
                                                          ? moment(
                                                              endTime
                                                            ).format('hh:mm')
                                                          : '-'}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ),
                                                header_4: (
                                                  <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                    <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                      Time Period:
                                                    </div>
                                                    <div className="d-flex align-items-center bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                      <div>{timePeriod}</div>
                                                    </div>
                                                  </div>
                                                ),
                                                header_5: (
                                                  <div className="bx--row align-items-center mt-2 mt-xl-0">
                                                    <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                                      Meeting Type:
                                                    </div>
                                                    <div className="d-flex align-items-center bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                                      <div>{meetingType}</div>
                                                    </div>
                                                  </div>
                                                ),
                                              };
                                            })
                                          : []
                                      }
                                      selectable={false}
                                      searchable={false}
                                      onSearchInputChange={() => {}}
                                      expandable={false}
                                      batchActions={false}
                                    />
                                  ) : leftFilterType ===
                                      'cms-meeting-updation' ||
                                    leftFilterType ===
                                      'cms-meeting-cancellation' ? (
                                    <div>
                                      <div className="mb-3">
                                        {getCMSMeetingInfo(
                                          purpose,
                                          startDate,
                                          meetingType
                                        )}
                                      </div>
                                      {changesMade &&
                                        Array.isArray(changesMade) &&
                                        changesMade.length > 0 && (
                                          <>
                                            <div className="mb-2">
                                              Changes Made:
                                            </div>
                                            <div className="custom-zebra-table">
                                              {changesMade?.map(
                                                (change, idx) => {
                                                  return (
                                                    <div className="custom-zebra-row">
                                                      <div className="ml-2">
                                                        {change}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  ) : leftFilterType ===
                                    'cms-meeting-deletion' ? (
                                    <>
                                      {getCMSMeetingInfo(
                                        purpose,
                                        startDate,
                                        meetingType
                                      )}
                                    </>
                                  ) : (
                                    '-'
                                  )}
                                </div>
                              ) : (
                                '-'
                              )}
                            </Tile>
                          );
                        })}
                        searchable={false}
                        selectable={false}
                        onSearchInputChange={() => {}}
                        expandable
                        batchActions={false}
                      />
                      <Pagination
                        onChange={this.handlePaginationChange}
                        pageSizes={[10, 20, 30, 40, 50]}
                        page={activePage}
                        totalItems={totalCount}
                      />
                    </div>
                  )}
              </div>
            </Tile>
          </div>
        </div>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.AuditLogs.loading,
  auditLogsData: state.AuditLogs.auditLogsData,
});

const mapDispatchToProps = {
  getAuditLogsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogs);
