import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCandidateAssessFeedbackResponses } from '../../actions/CandidateInfo';
import NoContentBox from '../../components/common/NoContentBox';
import {
  Accordion,
  AccordionItem,
  Modal,
  TextArea,
  TextInput,
  RadioButtonGroup,
  Button,
  RadioButton,
} from 'carbon-components-react';
class CandidateAssessFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  populateFeedbackResp = (assessName, assessObj) => {
    const {
      scorecardTemplateConfiguration: configuration,
      scorecardResponses: scorecardratingmap,
    } = assessObj || {};

    const finalAnsObj = {};
    if (
      configuration &&
      Array.isArray(configuration) &&
      configuration.length > 0
    ) {
      configuration.forEach((config) => {
        const { evalCriteria } = config || {};
        if (
          evalCriteria &&
          Array.isArray(evalCriteria) &&
          evalCriteria.length > 0
        ) {
          evalCriteria.forEach((evalCrit, idx) => {
            const { ratingCriteriaId, ratingCriteriaTypeId } = evalCrit || {};
            let currRatingCriteriaMap = {};
            if (
              scorecardratingmap &&
              Array.isArray(scorecardratingmap) &&
              scorecardratingmap.length > 0
            ) {
              currRatingCriteriaMap = scorecardratingmap.find((critmap) => {
                const { ratingCriteriaId: critMapId } = critmap || {};
                if (critMapId == ratingCriteriaId) {
                  return true;
                }
                return false;
              });
            }
            const { textValue, starValue, numberValue } =
              currRatingCriteriaMap || {};
            if (ratingCriteriaTypeId == 1) {
              finalAnsObj[
                `${assessName}-answer-${idx + 1}-${ratingCriteriaId}`
              ] = textValue;
            } else if (ratingCriteriaTypeId == 2) {
              finalAnsObj[
                `${assessName}-answer-${idx + 1}-${ratingCriteriaId}`
              ] = starValue?.toString();
            } else {
              finalAnsObj[
                `${assessName}-answer-${idx + 1}-${ratingCriteriaId}`
              ] = numberValue;
            }
          });
        }
      });
    }
    this.setState({ ...finalAnsObj });
  };

  RadioSelectedOptions = (opt, id, optionId) => {
    return (
      <RadioButton
        className="m-2"
        key={`${opt}-${id}`}
        id={`${opt}-${id}`}
        labelText={opt}
        value={optionId}
        disabled
      />
    );
  };

  renderOnMount = () => {
    const { profileId } = this.props;
    if (profileId) {
      this.props
        .getCandidateAssessFeedbackResponses(profileId)
        .then((res) => {
          if (res && !res.error) {
            if (Object.keys(res)?.length > 0) {
              Object.keys(res).forEach((assess) =>
                this.populateFeedbackResp(assess, res[assess])
              );
            }
            this.setState({ feedbackResponseObj: res });
          }
        })
        .catch((e) => console.log('Error', e));
    }
  };

  render() {
    const { feedbackResponseObj } = this.state;

    return (
      <div>
        <div className="mt-2 h6 font-weight-bold">
          Assessment Feedback Responses
        </div>
        <hr />
        {feedbackResponseObj && Object.keys(feedbackResponseObj)?.length > 0 ? (
          <>
            <Accordion className="" align='start'>
              {Object.keys(feedbackResponseObj).map((assessName) => {
                const {
                  displayName,
                  feedbackType,
                  status,
                  scorecardTemplateConfiguration: configuration,
                } = feedbackResponseObj?.[assessName] || {};
                return (
                  <AccordionItem
                    className="gba-accor-content mt-2"
                    title={
                      <div className="bx--row align-items-center">
                        <div className="h6 font-weight-bold mb-1 ml-3">
                          {displayName ?? feedbackType} Status: ({status})
                        </div>
                      </div>
                    }>
                    {configuration &&
                      Array.isArray(configuration) &&
                      configuration.length > 0 && (
                        <div>
                          {configuration.map((group) => {
                            const { groupName, evalCriteria } = group;
                            return (
                              <div className="mt-3">
                                <h4 className="">{groupName}</h4>
                                {evalCriteria &&
                                  Array.isArray(evalCriteria) &&
                                  evalCriteria.length > 0 &&
                                  evalCriteria.map((a, idx) => {
                                    return (
                                      <Fragment>
                                        <div className="bx--row align-items-center mt-2">
                                          <div className="bx--col-lg-10">
                                            {a.ratingCriteriaName
                                              ? a.ratingCriteriaName
                                              : ''}
                                          </div>
                                          <div className="bx--col-lg-6">
                                            {a.ratingCriteriaType &&
                                              a.ratingCriteriaType ===
                                                'text' && (
                                                <TextArea
                                                  disabled
                                                  className="mt-2 mb-1"
                                                  key={
                                                    a.ratingCriteriaId
                                                      ? a.ratingCriteriaId
                                                      : ''
                                                  }
                                                  name={`${assessName}-answer-${
                                                    idx + 1
                                                  }-${
                                                    a.ratingCriteriaId
                                                      ? a.ratingCriteriaId
                                                      : ''
                                                  }`}
                                                  labelText=""
                                                  hideLabel
                                                  placeholder="Enter you feedback"
                                                  invalid={
                                                    this.state[
                                                      `valid-${a.ratingCriteriaId}`
                                                    ]
                                                  }
                                                  invalidText="Please enter valid answer"
                                                  id={`${assessName}-answer-${
                                                    idx + 1
                                                  }-${
                                                    a.ratingCriteriaId
                                                      ? a.ratingCriteriaId
                                                      : ''
                                                  }`}
                                                  value={
                                                    this.state[
                                                      `${assessName}-answer-${
                                                        idx + 1
                                                      }-${
                                                        a.ratingCriteriaId
                                                          ? a.ratingCriteriaId
                                                          : ''
                                                      }`
                                                    ]
                                                  }
                                                />
                                              )}
                                            {a.ratingCriteriaType &&
                                              a.ratingCriteriaType ===
                                                'number' && (
                                                <TextInput
                                                  disabled
                                                  type="number"
                                                  className="mt-2 mb-1"
                                                  key={
                                                    a.ratingCriteriaId
                                                      ? a.ratingCriteriaId
                                                      : ''
                                                  }
                                                  name={`${assessName}-answer-${
                                                    idx + 1
                                                  }-${
                                                    a.ratingCriteriaId
                                                      ? a.ratingCriteriaId
                                                      : ''
                                                  }`}
                                                  labelText=""
                                                  hideLabel
                                                  invalid={
                                                    this.state[
                                                      `valid-${a.ratingCriteriaId}`
                                                    ]
                                                  }
                                                  invalidText="Please enter valid answer"
                                                  id={`${assessName}-answer-${
                                                    idx + 1
                                                  }-${
                                                    a.ratingCriteriaId
                                                      ? a.ratingCriteriaId
                                                      : ''
                                                  }`}
                                                  value={
                                                    this.state[
                                                      `${assessName}-answer-${
                                                        idx + 1
                                                      }-${
                                                        a.ratingCriteriaId
                                                          ? a.ratingCriteriaId
                                                          : ''
                                                      }`
                                                    ]
                                                  }
                                                />
                                              )}
                                            {a.ratingCriteriaType &&
                                              a.ratingCriteriaType ===
                                                'rating' &&
                                              a.questionConfig && (
                                                <>
                                                  <RadioButtonGroup
                                                    disabled
                                                    valueSelected={
                                                      this.state[
                                                        `${assessName}-answer-${
                                                          idx + 1
                                                        }-${
                                                          a.ratingCriteriaId
                                                            ? a.ratingCriteriaId
                                                            : ''
                                                        }`
                                                      ]
                                                    }
                                                    labelPosition="right"
                                                    legend=""
                                                    id={`${assessName}-answer-${
                                                      idx + 1
                                                    }-${
                                                      a.ratingCriteriaId
                                                        ? a.ratingCriteriaId
                                                        : ''
                                                    }`}
                                                    name={`${assessName}-answer-${
                                                      idx + 1
                                                    }-${
                                                      a.ratingCriteriaId
                                                        ? a.ratingCriteriaId
                                                        : ''
                                                    }`}
                                                    orientation="horizontal">
                                                    {a?.questionConfig?.options?.map(
                                                      (option) => {
                                                        return this.RadioSelectedOptions(
                                                          option.optionName,
                                                          a.ratingCriteriaId,
                                                          option.optionId
                                                        );
                                                      }
                                                    )}
                                                  </RadioButtonGroup>
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </>
        ) : (
          <NoContentBox message="There are no feedback responses available." />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assessFeedbackResponseObj: state.CandidateInfo.assessFeedbackResponseObj,
});

const mapDispatchToProps = {
  getCandidateAssessFeedbackResponses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateAssessFeedback);
