import React from 'react';

import { RadioButton } from 'carbon-components-react';

const CandidateEvaluationRatings = (props) => {
  const {
    evalStaticData,
    handleRadioBtnChange,
    isFormSubmitted,
    userId,
    evaluationId,
    isEditForm,
    state,
    response,
    selectionYear,
  } = props || {};
  return (
    <div className={!isEditForm && isFormSubmitted && 'csc-radio-btn-disabled'}>
      <table className="table-border w-100 table-zoom-auto">
        <thead className="text-center heading-align">
          <th className="color-blue css-s-no-w font-size-x-lg">No.</th>
          <th className="color-blue font-size-x-lg trait-min-width">
            {selectionYear > 2023 ? (
              <div className="font-size-x-lg">Competencies</div>
            ) : (
              <>
                <div className="font-size-x-lg">Trait</div>
                <div>(Behavioural statement)</div>
              </>
            )}
          </th>
          <th className="csc-th--vl results-min-width">
            <div>1</div>
            <small>Very Weak</small>
          </th>
          <th className="csc-th--l results-min-width">
            <div>2</div>
            <small>Weak</small>
          </th>
          <th className="csc-th--m results-min-width">
            <div>3</div>
            <small>Moderate</small>
          </th>
          <th className="csc-th--h results-min-width">
            <div>4</div>
            <small>Strong</small>
          </th>
          <th className="csc-th--vh results-min-width">
            <div>5</div>
            <small>Very Strong</small>
          </th>
          <th className="color-gray results-min-width">
            <div>Not able to tell</div>
          </th>
        </thead>
        <tbody className="table-border">
          {evalStaticData.map((res) => {
            const { evalDesc, evalId, evalName, evalPropName } = res || {};
            return (
              <tr className="text-left">
                <td>{evalId}</td>
                <td>
                  <div className="font-weight-bold mb-2 h5">{evalName}</div>
                  <div style={{ fontSize: 'large', fontStyle: 'italic' }}>
                    {evalDesc}
                  </div>
                </td>
                <td className="csc-th--vl-light csc-radio-btn-m">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'veryWeak');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-veryWeak`
                        : `${evalId}-${evaluationId}-${evalName}-veryWeak`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-veryWeak`
                        : `${evalId}-${evaluationId}-${evalName}-veryWeak`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'veryWeak'
                        : response && response[evalPropName] === 'veryWeak'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--l-light csc-radio-btn-m">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'weak');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-weak`
                        : `${evalId}-${evaluationId}-${evalName}-weak`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-weak`
                        : `${evalId}-${evaluationId}-${evalName}-weak`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'weak'
                        : response && response[evalPropName] === 'weak'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--m-light csc-radio-btn-m">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'moderate');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-moderate`
                        : `${evalId}-${evaluationId}-${evalName}-moderate`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-moderate`
                        : `${evalId}-${evaluationId}-${evalName}-moderate`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'moderate'
                        : response && response[evalPropName] === 'moderate'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--h-light csc-radio-btn-m">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'strong');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-strong`
                        : `${evalId}-${evaluationId}-${evalName}-strong`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-strong`
                        : `${evalId}-${evaluationId}-${evalName}-strong`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'strong'
                        : response && response[evalPropName] === 'strong'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--vh-light csc-radio-btn-m">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'veryStrong');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-veryStrong`
                        : `${evalId}-${evaluationId}-${evalName}-veryStrong`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-veryStrong`
                        : `${evalId}-${evaluationId}-${evalName}-veryStrong`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'veryStrong'
                        : response && response[evalPropName] === 'veryStrong'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--na-light csc-radio-btn-m">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'none');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-none`
                        : `${evalId}-${evaluationId}-${evalName}-none`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-${userId}-none`
                        : `${evalId}-${evaluationId}-${evalName}-none`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'none'
                        : response && response[evalPropName] === 'none'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CandidateEvaluationRatings;
