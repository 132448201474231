import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Modal,
  Pagination,
  Tile,
  FileUploaderButton,
  InlineNotification,
  HeaderPanel,
  SelectItem,
  Select,
} from 'carbon-components-react';
import {
  getCMSMeetings,
  uploadCMSMeetingsData,
  downloadMetingTemplate,
  deletCMSMeetingById,
  exportCMSMeetings,
  bulkDeletCMSMeetingByIds,
  upsertCMSMeeting,
} from '../../../actions/CMSMeetings';
import _, { truncate, update } from 'lodash';
import GenericDataTable from '../../../components/common/GenericDataTable';
import NoContentBox from '../../../components/common/NoContentBox';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import BackRow from '../../../components/common/BackRow';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import moment from 'moment';
import CMSMeetingsUploadTemplate from '../../../assets/files/CMS_Meetings_Upload_Template.xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../../../components/common/Dropdown';
import {
  meetingTypeOptions,
  meetingStatusOptions,
  attendanceStatusOptions,
} from '../../../staticdata/CMSStaticData';
import { ViewFilled24, Close24 } from '@carbon/icons-react';

const CMSMeetingsQuickView = ({ meetingData, handleClosePanel }) => {
  const { purpose, startDate, Usermeetingmaps } = meetingData || {};
  const commissionMemList =
    (Usermeetingmaps &&
      Array.isArray(Usermeetingmaps) &&
      Usermeetingmaps.map((itm) => {
        const { attendanceStatus, remarks, Selectionboard, Userinfo } =
          itm || {};
        const { aliasName } = Selectionboard || {};
        const { userId, firstname, lastname } = Userinfo || {};
        return {
          userId,
          aliasName,
          firstname,
          lastname,
          attendanceStatus,
          remarks,
        };
      })) ||
    [];

  const totalCount =
    commissionMemList &&
    Array.isArray(commissionMemList) &&
    commissionMemList.length > 0 &&
    commissionMemList.filter((itm) => itm?.attendanceStatus == 'Scheduled')
      ?.length;
  return (
    <Fragment>
      <div>
        <div className="d-flex justify-content-end mt-2 mr-3">
          <Close24
            className="cursor-pointer"
            onClick={() => handleClosePanel()}
          />
        </div>
        <Tile className="m-2">
          <div className="text-capitalize font-weight-bold">
            {purpose ?? '-'}{' '}
            {startDate
              ? `(${moment(startDate).local().format('DD/MM/YYYY')})`
              : ''}
          </div>
        </Tile>
        <div className="d-flex flex-column mt-3">
          <div className="font-weight-bold ml-3 mb-2">
            Total commission members (Scheduled) : {totalCount}
          </div>
          <GenericDataTable
            tbodyClass="candidate-background-color"
            otherActions={[]}
            selectable={false}
            searchable={false}
            onSearchInputChange={() => {}}
            expandable={false}
            batchActions={false}
            headers={[
              {
                key: 'header_0',
                header: 'Commission Members',
              },
              {
                key: 'header_1',
                header: 'Attendance Status',
              },
            ]}
            rows={
              commissionMemList &&
              Array.isArray(commissionMemList) &&
              commissionMemList.length > 0
                ? commissionMemList.map((res) => {
                    const {
                      userId,
                      aliasName,
                      firstname,
                      lastname,
                      attendanceStatus,
                    } = res || {};
                    return {
                      id: `row_${userId}`,
                      key: `row_${userId}`,
                      header_0: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Commission Member:
                          </div>
                          <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {aliasName ?? `${firstname} ${lastname}`}
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Attendance Status:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {attendanceStatus ?? '-'}
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }></GenericDataTable>
        </div>
      </div>
    </Fragment>
  );
};

class CMSMeetings extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'meetings', name: 'Meetings', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      startDate: moment().toDate(),
      endDate: moment().add(6, 'months').toDate(),
      isOpenDelMeetingModal: false,
      notifMsg: '',
      selectedMeetingTypeList: [],
      selectedMeetingStatusList: [],
      isOpenQuickView: false,
      bulkUploadType: 'yearlyUpload',
      isOpenViewErrorsModal: false,
    };
  }

  componentDidMount() {
    const filterObj = JSON.parse(localStorage.getItem('cms-meetings-filters'));
    const { activePage, itemsPerPage, startDate, endDate } = filterObj || {};
    if (!_.isEmpty(filterObj)) {
      this.setState(
        {
          ...filterObj,
          activePage: (activePage && Number(activePage)) || 1,
          itemsPerPage: (itemsPerPage && Number(itemsPerPage)) || 10,
          startDate: startDate && new Date(startDate),
          endDate: endDate && new Date(endDate),
        },
        () => {
          this.handleGetCMSMeetings();
        }
      );
    } else {
      this.handleGetCMSMeetings();
    }

    document.addEventListener('keydown', this.handleKeyClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyClick);
  }

  handleKeyClick = (event) => {
    const loadOverFlow = document.getElementsByClassName('bx--loading-overlay');
    const loadOverflowRun = loadOverFlow && loadOverFlow.length !== 0;
    if (event.key === 'Escape' && !loadOverflowRun) this.handleClosePanel();
  };

  handleOutsideClick = (e) => {
    const loadOverFlow = document.getElementsByClassName('bx--loading-overlay');
    const loadOverflowRun = loadOverFlow && loadOverFlow.length !== 0;
    if (e.target === e.currentTarget && !loadOverflowRun)
      this.handleClosePanel();
  };

  handleGetCMSMeetings = () => {
    const {
      startDate,
      endDate,
      searchKey,
      activePage,
      itemsPerPage,
      selectedMeetingTypeList,
      selectedMeetingStatusList,
    } = this.state;
    const data = {
      startDate: `"${moment(startDate).format('YYYY-MM-DD')}"`,
      endDate: `"${moment(endDate).format('YYYY-MM-DD')}"`,
      searchKey,
      activePage,
      itemsPerPage,
    };
    if (
      selectedMeetingTypeList &&
      Array.isArray(selectedMeetingTypeList) &&
      selectedMeetingTypeList.length > 0 &&
      selectedMeetingTypeList?.[0]?.value != 'All'
    ) {
      data['meetingTypeList'] = selectedMeetingTypeList.map(
        (itm) => itm?.value
      );
    }

    if (
      selectedMeetingStatusList &&
      Array.isArray(selectedMeetingStatusList) &&
      selectedMeetingStatusList.length > 0 &&
      selectedMeetingStatusList?.[0]?.value != 'All'
    ) {
      data['meetingStatusList'] = selectedMeetingStatusList.map(
        (itm) => itm?.value
      );
    }
    this.props.getCMSMeetings(data).then((res) => {
      if (res && !res.error) {
        const { list, count = 0 } = res || {};
        this.setState({ meetingsList: list, count }, () => {
          setTimeout(() => {
            localStorage.setItem('cms-meetings-filters', null);
          }, 2000);
        });
      }
    });
  };

  activeAddMeetingModal = () => {
    const { history: { push } = {} } = this.props;
    push('/app/csc/cms/create/new/meeting');
  };

  activeEditMeetingModal = (meetingId) => {
    const { history: { push } = {} } = this.props;
    const {
      activePage,
      itemsPerPage,
      searchKey,
      startDate,
      endDate,
      selectedMeetingTypeList,
      selectedMeetingStatusList,
    } = this.state;
    const data = {
      activePage,
      itemsPerPage,
      searchKey,
      startDate,
      endDate,
      selectedMeetingTypeList,
      selectedMeetingStatusList,
    };
    localStorage.setItem('cms-meetings-filters', JSON.stringify(data));
    localStorage.setItem(
      'paths',
      JSON.stringify({ prevPath: '/app/csc/cms/meetings' })
    );
    push(`/app/csc/cms/create/new/meeting/${meetingId}`);
  };

  activeDelMeetingModal = (meetingId) => {
    this.setState({ isOpenDelMeetingModal: true, meetingId });
  };

  dissmissDelMeetingModal = () => {
    this.setState({
      isOpenDelMeetingModal: false,
      meetingId: null,
      meetingIds: [],
      isBulkDelete: false,
    });
  };

  handleSubmitDeleteMeeting = () => {
    const { meetingId, isBulkDelete, meetingIds } = this.state;
    if (isBulkDelete) {
      this.props
        .bulkDeletCMSMeetingByIds(meetingIds)
        .then((res) => {
          if (res && !res.error) {
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Meeting(s) have been deleted successfully',
              },
              () => {
                this.dissmissDelMeetingModal();
                this.handleGetCMSMeetings();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.dissmissDelMeetingModal();
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.dissmissDelMeetingModal();
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.props
        .deletCMSMeetingById(meetingId)
        .then((res) => {
          if (res && !res.error) {
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Meeting has been deleted successfully',
              },
              () => {
                this.dissmissDelMeetingModal();
                this.handleGetCMSMeetings();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.dissmissDelMeetingModal();
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((error) => console.log('Error', error));
    }
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetCMSMeetings();
    });
  };

  handleUploadMeetings = (element) => {
    const { bulkUploadType } = this.state;
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    // currFile.append('uploadType', bulkUploadType);
    currFile.append('excel', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadCMSMeetingsData(currFile)
        .then((res) => {
          const { validPayload = [], inValidPayload = [] } = res || {};
          if (res && !res.error) {
            const updateState = {};

            if (inValidPayload?.length > 0) {
              updateState.errorNotif = true;
              updateState.errMsg = (
                <span>
                  Unable to process data for some of the records. Please{' '}
                  <span
                    className="xpa-link"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('event', e);
                      this.setState({
                        isOpenViewErrorsModal: true,
                        failedRecordsList: inValidPayload,
                      });
                    }}>
                    click here
                  </span>{' '}
                  to view all the failed records{' '}
                </span>
              );
            } else if (validPayload?.length > 0) {
              updateState.successNotif = true;
              updateState.notifMsg = 'File uploaded successfully!';
            }

            this.setState(
              {
                ...updateState,
              },
              () => {
                this.dissmissUploadMeetingsModal();
                this.handleGetCMSMeetings();
                if (updateState?.successNotif) {
                  setTimeout(() => {
                    this.setState({
                      notifMsg: '',
                      successNotif: false,
                    });
                  }, 5000);
                }
              }
            );
          } else {
            this.dissmissUploadMeetingsModal();
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.dissmissUploadMeetingsModal();
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.dissmissUploadMeetingsModal();
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  onDatePickerChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleSubmitDates = () => {
    this.setState(
      {
        isStartEndDatesInvalid: this.state.startDate > this.state.endDate,
      },
      () => {
        if (!this.state.isStartEndDatesInvalid) {
          this.handleGetCMSMeetings();
        }
      }
    );
  };

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState(
      {
        searchKey: event.target.value,
        activePage: 1,
        itemsPerPage: 10,
      },
      () => {
        this.searchTimer = setTimeout(() => {
          this.handleGetCMSMeetings();
        }, 1000);
      }
    );
  };

  batchActionClickHandler = (actionItems, actionState) => {
    const actionItemsIds = actionItems.map(({ id = '' }) => {
      return { meetingId: Number(id) };
    });

    if (actionState === 'delete-meetings') {
      this.setState({
        isOpenDelMeetingModal: true,
        isBulkDelete: true,
        meetingIds: actionItemsIds,
      });
    } else if (actionState === 'update-attendance-status') {
      this.setState(
        {
          meetingIds: actionItemsIds,
        },
        () => this.OpenUpdateAttdStatusModal()
      );
    }
  };

  downloadMetingTemplate = () => {
    this.props.downloadMetingTemplate();
  };

  handleDropdownChange = (v, metaValue, name) => {
    let finalVal = metaValue;
    if (
      name == 'selectedMeetingTypeList' ||
      name == 'selectedMeetingStatusList'
    ) {
      if (metaValue && Array.isArray(metaValue) && metaValue.length > 0) {
        const drpDownContainsAll = metaValue.some((obj) => obj.value == 'All');
        finalVal = drpDownContainsAll
          ? [{ label: 'All', value: 'All' }]
          : metaValue;
      }
    }
    this.setState(
      {
        [name]: finalVal,
      },
      () => {
        if (name != 'selectedCMAttendanceStatus') {
          this.handleGetCMSMeetings();
        }
      }
    );
  };

  handleExportCMSMeetings = () => {
    const {
      startDate,
      endDate,
      searchKey,
      activePage,
      itemsPerPage,
      selectedMeetingTypeList,
      selectedMeetingStatusList,
    } = this.state;
    const data = {
      startDate: `"${moment(startDate).format('YYYY-MM-DD')}"`,
      endDate: `"${moment(endDate).format('YYYY-MM-DD')}"`,
      searchKey,
      activePage,
      itemsPerPage,
    };
    if (
      selectedMeetingTypeList &&
      Array.isArray(selectedMeetingTypeList) &&
      selectedMeetingTypeList.length > 0
    ) {
      data['meetingTypeList'] = selectedMeetingTypeList.map(
        (itm) => itm?.value
      );
    }
    if (
      selectedMeetingStatusList &&
      Array.isArray(selectedMeetingStatusList) &&
      selectedMeetingStatusList.length > 0
    ) {
      data['meetingStatusList'] = selectedMeetingStatusList.map(
        (itm) => itm?.value
      );
    }

    this.props
      .exportCMSMeetings(data)
      .then((res) => {
        const { fileUrl, error } = res || {};
        const { message } = error || {};
        if (res && !res.error && fileUrl) {
          window.open(fileUrl, '_self');
        } else {
          this.setState({
            errorNotif: true,
            errMsg:
              message ??
              'An Error has ocurred while processing your request, Please try again later',
          });
          setTimeout(() => {
            this.setState({
              errMsg: '',
              errorNotif: false,
            });
          }, 5000);
        }
      })
      .catch((e) => {
        this.setState({
          errorNotif: true,
        });
      });
  };

  toggleQuickView = (val, data) => {
    this.setState({
      isOpenQuickView: val,
      meetingData: data,
    });
  };

  handleClosePanel = () => {
    this.setState({
      isOpenQuickView: false,
      meetingData: {},
    });
  };

  OpenUpdateAttdStatusModal = () => {
    this.setState({
      isOpenBulkUpdateAttdModal: true,
    });
  };

  dissmissUpdateAttdStatusModal = () => {
    this.setState({
      isOpenBulkUpdateAttdModal: false,
      meetingIds: [],
    });
  };

  handleSubmitUpdateAttdStatus = () => {
    const { meetingIds, selectedCMAttendanceStatus } = this.state;
    const { userId } = this.getCommissionMemInfo() || {};

    const payload =
      meetingIds &&
      Array.isArray(meetingIds) &&
      meetingIds.length > 0 &&
      meetingIds.map((itm) => {
        const { meetingId } = itm || {};
        return {
          meetingId,
          users: [
            {
              userId,
              attendanceStatus: selectedCMAttendanceStatus?.value,
            },
          ],
        };
      });

    this.props
      .upsertCMSMeeting(payload)
      .then((res) => {
        if (res && !res.error) {
          this.setState(
            {
              successNotif: true,
              notifMsg: 'Attendance Status has been uploaded successfully',
            },
            () => {
              this.dissmissUpdateAttdStatusModal();
              this.handleGetCMSMeetings();
              setTimeout(() => {
                this.setState({
                  notifMsg: '',
                  successNotif: false,
                });
              }, 15000);
            }
          );
        } else {
          this.dissmissUpdateAttdStatusModal();
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        this.dissmissUpdateAttdStatusModal();
        this.setState({
          errorNotif: true,
        });
      });
  };

  openUploadMeetingsModal = () => {
    this.setState({
      isOpenUploadMeetingsModal: true,
    });
  };

  dissmissUploadMeetingsModal = () => {
    this.setState({
      isOpenUploadMeetingsModal: false,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getCommissionMemInfo = () => {
    const { meetingsList } = this.state;
    const commissionMemberInfo =
      (meetingsList &&
        Array.isArray(meetingsList) &&
        meetingsList.length > 0 &&
        meetingsList?.[0]) ||
      {};

    const { Usermeetingmaps } = commissionMemberInfo || {};
    const { Selectionboard, Userinfo } =
      (Usermeetingmaps &&
        Array.isArray(Usermeetingmaps) &&
        Usermeetingmaps.length > 0 &&
        Usermeetingmaps?.[0]) ||
      {};
    const { aliasName } = Selectionboard || {};
    const { firstname, lastname, userId } = Userinfo || {};
    return {
      aliasName,
      firstname,
      lastname,
      userId,
    };
  };

  handleCloseNotifBtn = () => {
    this.setState({ successNotif: false, errorNotif: false });
  };

  render() {
    const {
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      activePage,
      meetingsList,
      count,
      isStartEndDatesInvalid,
      startDate,
      endDate,
      isOpenDelMeetingModal,
      selectedMeetingTypeList,
      selectedMeetingStatusList,
      searchKey,
      isOpenQuickView,
      meetingData,
      isOpenBulkUpdateAttdModal,
      isBulkDelete,
      isOpenUploadMeetingsModal,
      bulkUploadType,
      selectedCMAttendanceStatus,
      isOpenViewErrorsModal,
      failedRecordsList,
    } = this.state;

    const { user } = this.props;
    const { roleId } = user || {};
    const hideUserInputButtons =
      roleId == 2 || roleId == 7 || roleId == 12 || roleId == 13;

    const hideQuickView = roleId == 7 || roleId == 13;
    const editBtnText = roleId == 12 ? 'View' : 'View/Edit';

    const otherActionsBtn = [];
    if (!hideUserInputButtons) {
      otherActionsBtn.push(
        <Button kind="primary" onClick={this.activeAddMeetingModal}>
          Create Meeting
        </Button>
      );
    }

    let cmsMeetingHeaders = [
      {
        key: 'header_0',
        header: 'Purpose',
        className: 'w-25',
      },
      {
        key: 'header_1',
        header: 'Meeting Date',
      },
      {
        key: 'header_2',
        header: 'Meeting Type',
      },
      {
        key: 'header_3',
        header: 'Meeting Start Time',
      },
      {
        key: 'header_4',
        header: 'Meeting Status',
      },
    ];

    if (roleId == 7 || roleId == 13) {
      cmsMeetingHeaders.push({
        key: 'header_5',
        header: 'Attendance Status',
      });
    }

    cmsMeetingHeaders.push({
      key: 'header_6',
      header: 'Action',
    });

    let batchActions = [];
    if (!hideUserInputButtons) {
      batchActions.push({
        label: 'Delete Meeting(s)',
        key: 'delete-meetings',
      });
    }

    batchActions.push({
      label: 'Update Attendance Status',
      key: 'update-attendance-status',
    });

    // Hide Scheduled Opt for Panel & PA
    let finalAttendanceStatusOpts = attendanceStatusOptions;
    if (roleId == 7 || roleId == 13) {
      finalAttendanceStatusOpts = attendanceStatusOptions?.filter(
        (itm) => itm?.value != 'Scheduled'
      );
    }

    const { aliasName, firstname, lastname } =
      this.getCommissionMemInfo() || {};

    return (
      <div className="">
        <BackRow paths={this.paths} />
        <div className="grid-lg">
          {(successNotif || errorNotif) && (
            <SuccErrNotification
              successNotif={successNotif}
              errorNotif={errorNotif}
              notifMsg={notifMsg}
              errMsg={errMsg}
              handleCloseNotifBtn={this.handleCloseNotifBtn}
            />
          )}

          <div className="d-flex justify-content-end mt-3 mt-lg-0">
            {!hideUserInputButtons && (
              <>
                <div className="text-right">
                  <Button
                    kind="primary"
                    onClick={() => this.openUploadMeetingsModal()}>
                    Upload
                  </Button>
                </div>
                <div className="ml-2">
                  <Button
                    className="txt-dec-none"
                    kind="primary"
                    href={CMSMeetingsUploadTemplate}>
                    Download Template
                  </Button>
                </div>
              </>
            )}
            {/* <div className="ml-4">
              <Button
                kind="primary"
                onClick={() => this.handleExportCMSMeetings()}>
                Export
              </Button>
            </div> */}
          </div>

          {isStartEndDatesInvalid && (
            <InlineNotification
              lowContrast
              onCloseButtonClick={() => {}}
              className="mt-1 mb-1"
              title={'Please select a valid start date and end date.'}
              subtitle=""
              hideCloseButton
              iconDescription="describes the close button"
              kind="error"
            />
          )}
          <div className="bx-row mb-3">
            <div className="d-flex flex-column flex-lg-row">
              <div className="">
                <div className="bx--label mb-2">From:</div>
                <div className="">
                  <DatePicker
                    className="css-h-d-input"
                    selected={startDate}
                    onChange={(date) =>
                      this.onDatePickerChange(date, 'startDate')
                    }
                    // showTimeSelect
                    // timeFormat="HH:mm"
                    // timeIntervals={15}
                    // timeCaption="time"
                    // dateFormat="dd/MM/yyyy, h:mm aa"
                    dateFormat="dd/MM/yyyy"
                    maxDate={moment().toDate()}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="ml-lg-2 mt-2 mt-lg-0">
                <div className="bx--label mb-2">To:</div>
                <div className="">
                  <DatePicker
                    className="css-h-d-input"
                    selected={endDate}
                    onChange={(date) =>
                      this.onDatePickerChange(date, 'endDate')
                    }
                    autoComplete="off"
                    // showTimeSelect
                    // timeFormat="HH:mm"
                    // timeIntervals={15}
                    // timeCaption="time"
                    // dateFormat="dd/MM/yyyy, h:mm aa"
                    dateFormat="dd/MM/yyyy"
                    // maxDate={moment().toDate()}
                    // minTime={minTime}
                    // maxTime={maxTime}
                  />
                </div>
              </div>
              <div className="ml-lg-2 mt-2 mt-lg-4 pt-2">
                <Button
                  onClick={this.handleSubmitDates}
                  className="mr-2"
                  size="small">
                  Submit
                </Button>
              </div>
            </div>
            <hr />
            <div className="d-flex">
              <div className="mr-3">
                <Dropdown
                  placeholder="Select Meeting Type..."
                  mainClassName="bx--form-item mb-0"
                  titleClass=""
                  labelClass=""
                  isMulti={selectedMeetingTypeList?.[0]?.value != 'All'}
                  onInputChange={() => {}}
                  className="cms-filter-width"
                  options={meetingTypeOptions}
                  onChange={(value, e, name) =>
                    this.handleDropdownChange(value, e, name)
                  }
                  name="selectedMeetingTypeList"
                  selectedValue={selectedMeetingTypeList}
                />
              </div>
              <div className="mr-3">
                <Dropdown
                  placeholder="Select Meeting Status..."
                  title=""
                  mainClassName="bx--form-item mb-0"
                  titleClass=""
                  labelClass=""
                  isMulti={selectedMeetingStatusList?.[0]?.value != 'All'}
                  onInputChange={() => {}}
                  className="cms-filter-width"
                  options={meetingStatusOptions}
                  onChange={(value, e, name) =>
                    this.handleDropdownChange(value, e, name)
                  }
                  name="selectedMeetingStatusList"
                  selectedValue={selectedMeetingStatusList}
                />
              </div>
            </div>
          </div>

          <GenericDataTable
            placeHolderText="Type to search meetings"
            headers={cmsMeetingHeaders}
            otherActions={otherActionsBtn}
            selectable={roleId == 7 || roleId == 13}
            batchActions={batchActions}
            batchActionClick={this.batchActionClickHandler}
            searchable={true}
            onSearchInputChange={this.handleSearchSubmit}
            rows={
              meetingsList &&
              Array.isArray(meetingsList) &&
              meetingsList.length > 0
                ? meetingsList.map((data, idx) => {
                    const {
                      meetingId,
                      meetingTitle,
                      agenda,
                      startDate,
                      startTime,
                      timePeriod,
                      meetingType,
                      purpose,
                      internalRemarks,
                      status,
                      attendanceType,
                      Usermeetingmaps,
                    } = data;
                    let isMeetingExpired = false;
                    if (meetingId) {
                      const finalStartTime =
                        startDate &&
                        new Date(startDate).setHours(23, 59, 59, 0);
                      isMeetingExpired = new Date().getTime() > finalStartTime;
                    }

                    const commissionMemListOuter =
                      (Usermeetingmaps &&
                        Array.isArray(Usermeetingmaps) &&
                        Usermeetingmaps.map((itm) => {
                          const {
                            attendanceStatus,
                            remarks,
                            Selectionboard,
                            Userinfo,
                          } = itm || {};
                          const { aliasName } = Selectionboard || {};
                          const { userId, firstname, lastname } =
                            Userinfo || {};
                          return {
                            userId,
                            aliasName,
                            firstname,
                            lastname,
                            attendanceStatus,
                            remarks,
                          };
                        })) ||
                      [];

                    const { aliasName, firstname, lastname, attendanceStatus } =
                      (commissionMemListOuter &&
                        Array.isArray(commissionMemListOuter) &&
                        commissionMemListOuter.length > 0 &&
                        commissionMemListOuter?.[0]) ||
                      {};

                    return {
                      isExpanded: false,
                      id: meetingId,
                      header_0: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Purpose:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="ml-2 text-capitalize bx--type-zeta">
                                {purpose ?? '-'}
                              </div>
                              {!hideQuickView && (
                                <span
                                  id="quick-view-link"
                                  data-tooltip="Quick View"
                                  className="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block xpa-tooltip"
                                  onClick={() =>
                                    this.toggleQuickView(true, data)
                                  }
                                  style={{
                                    zIndex: 0,
                                  }}>
                                  <ViewFilled24
                                    style={{
                                      position: 'relative',
                                      // top: '-1px',
                                      marginLeft: 3,
                                      fill: '#8EC9E4',
                                      cursor: 'pointer',
                                      zIndex: 0,
                                    }}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Meeting Date:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>
                              {startDate
                                ? moment(startDate).local().format('DD/MM/YYYY')
                                : '-'}
                            </div>
                          </div>
                        </div>
                      ),
                      header_2: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Meeting Type:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>{meetingType}</div>
                          </div>
                        </div>
                      ),
                      header_3: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Meeting Start Time:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>
                              {startTime
                                ? moment(startTime).local().format('h:mm a')
                                : '-'}
                            </div>
                          </div>
                        </div>
                      ),

                      header_4: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Meeting Status:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {status}
                          </div>
                        </div>
                      ),
                      header_5: (roleId == 7 || roleId == 13) && (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Attendance Status:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div className="text-capitalize">
                              {attendanceStatus ?? '-'}
                            </div>
                          </div>
                        </div>
                      ),
                      header_6: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Action:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div className="d-flex">
                              <div className="mr-2">
                                <Button
                                  onClick={() => {
                                    this.activeEditMeetingModal(meetingId);
                                  }}
                                  kind="tertiary"
                                  size="small">
                                  {editBtnText}
                                </Button>
                              </div>

                              {!hideUserInputButtons && !isMeetingExpired && (
                                <Button
                                  onClick={() =>
                                    this.activeDelMeetingModal(meetingId)
                                  }
                                  kind="danger"
                                  size="small">
                                  Delete
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
          />

          {meetingsList &&
          Array.isArray(meetingsList) &&
          meetingsList.length > 0 ? (
            <Pagination
              onChange={this.handlePaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              page={activePage}
              totalItems={count}
            />
          ) : (
            <NoContentBox message="There are no meetings to show." />
          )}

          {isOpenQuickView && (
            <div
              className="cms-meeting-quick-view x-portal-overlay"
              onClick={this.handleOutsideClick}>
              <HeaderPanel
                expanded
                aria-label="cms-meeting-view"
                style={{ overflowY: 'auto', overflowX: 'hidden' }}
                className="cms-meetings-side-panel">
                <CMSMeetingsQuickView
                  meetingData={meetingData}
                  handleClosePanel={this.handleClosePanel}
                />
              </HeaderPanel>
            </div>
          )}
        </div>

        {isOpenDelMeetingModal && (
          <Modal
            open={isOpenDelMeetingModal}
            modalHeading="Delete Meeting"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            danger
            iconDescription="Close the modal"
            onRequestClose={this.dissmissDelMeetingModal}
            onRequestSubmit={this.handleSubmitDeleteMeeting}>
            {`Are you sure you want to delete ${
              isBulkDelete ? 'the selected meeting(s)' : 'this meeting'
            }`}
            ?
          </Modal>
        )}

        {isOpenBulkUpdateAttdModal && (
          <Modal
            className="modal-height"
            open={isOpenBulkUpdateAttdModal}
            modalHeading="Update Attendance Status"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            danger
            iconDescription="Close the modal"
            onRequestClose={this.dissmissUpdateAttdStatusModal}
            onRequestSubmit={this.handleSubmitUpdateAttdStatus}>
            <InlineNotification
              lowContrast
              title="Note: Attendance status for the past meetings and cancelled meetings will not be updated."
              iconDescription="describes the close button"
              kind="info"
              hideCloseButton
            />
            <div className="bx--row mb-5 pb-5">
              <div className="text-capitalize bx--col-lg-4 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
                {aliasName ?? `${firstname} ${lastname}`}
              </div>
              <div className="bx--col-lg-6 bx--col-md-6 bx--col-sm-12 bx--col-xs-12">
                <Dropdown
                  isClearable={false}
                  id="cmAttendanceStatus"
                  title=""
                  custom
                  mainClassName="bx--form-item mb-0"
                  className="w-100"
                  titleClass="mb-2"
                  labelClass="pt-2"
                  onInputChange={() => {}}
                  options={finalAttendanceStatusOpts}
                  name="selectedCMAttendanceStatus"
                  placeholder="Attendance Status"
                  onChange={(value, e, name) =>
                    this.handleDropdownChange(value, e, name)
                  }
                  selectedValue={selectedCMAttendanceStatus}
                />
              </div>
            </div>
          </Modal>
        )}

        {isOpenUploadMeetingsModal && (
          <Modal
            open={isOpenUploadMeetingsModal}
            modalHeading="Upload Meetings"
            modalLabel="Action"
            passiveModal
            onRequestClose={this.dissmissUploadMeetingsModal}
            onRequestSubmit={(e) => {}}>
            <div>
              {/* <div>
                <Select
                  name="bulkUploadType"
                  id="bulkUploadType"
                  onChange={this.handleChange}
                  value={bulkUploadType}
                  labelText="Upload Type">
                  <SelectItem
                    text="Yearly Upload"
                    key="yearlyUpload"
                    value="yearlyUpload"></SelectItem>
                  <SelectItem
                    text="Others"
                    key="others"
                    value="others"></SelectItem>
                </Select>
              </div> */}
              <div className="mt-3">
                <FileUploaderButton
                  buttonLabel="Upload"
                  labelText="Upload"
                  buttonKind="primary"
                  accept={['.xlsx']}
                  disableLabelChanges
                  name="file"
                  onChange={(e) => this.handleUploadMeetings(e)}
                />
                <div className="small mt-2">.xlsx format is allowed</div>
              </div>
            </div>
          </Modal>
        )}

        {isOpenViewErrorsModal && (
          <Modal
            className="modal-height-xl"
            open={isOpenViewErrorsModal}
            modalHeading="Failed Records"
            modalLabel="Action"
            passiveModal
            onRequestClose={() =>
              this.setState({ isOpenViewErrorsModal: false })
            }
            onRequestSubmit={(e) => {}}>
            <div>
              <GenericDataTable
                headers={[
                  {
                    key: 'header_0',
                    header: 'Purpose',
                    className: 'w-25',
                  },
                  {
                    key: 'header_1',
                    header: 'Meeting Date',
                  },
                  {
                    key: 'header_2',
                    header: 'Meeting Start Time',
                  },
                  {
                    key: 'header_3',
                    header: 'Reason for failure',
                  },
                ]}
                searchable={false}
                rows={
                  failedRecordsList &&
                  Array.isArray(failedRecordsList) &&
                  failedRecordsList.length > 0
                    ? failedRecordsList.map((data, idx) => {
                        const {
                          startDate,
                          startTime,
                          meetingType,
                          meta,
                          status,
                          Usermeetingmaps,
                          invalidMessages,
                        } = data;
                        const { Purpose } = meta || {};

                        return {
                          isExpanded: false,
                          id: `${idx}_${Purpose}`,
                          header_0: (
                            <div className="bx--row align-items-center mt-2 mt-xl-0">
                              <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                Purpose:
                              </div>
                              <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="ml-2 text-capitalize bx--type-zeta">
                                    {Purpose ?? '-'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ),
                          header_1: (
                            <div className="bx--row align-items-center mt-2 mt-xl-0">
                              <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                Meeting Date:
                              </div>
                              <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                <div>
                                  {startDate
                                    ? moment(startDate)
                                        .local()
                                        .format('DD/MM/YYYY')
                                    : '-'}
                                </div>
                              </div>
                            </div>
                          ),
                          header_2: (
                            <div className="bx--row align-items-center mt-2 mt-xl-0">
                              <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                Meeting Start Time:
                              </div>
                              <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                <div>
                                  {startTime
                                    ? moment(startTime).local().format('h:mm a')
                                    : '-'}
                                </div>
                              </div>
                            </div>
                          ),

                          header_3: (
                            <div className="bx--row align-items-center mt-2 mt-xl-0">
                              <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                Reason for Failure:
                              </div>
                              <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                <div className="text-capitalize">
                                  {invalidMessages &&
                                  Array.isArray(invalidMessages) &&
                                  invalidMessages.length > 0 ? (
                                    <div className="d-flex flex-column">
                                      <ul>
                                        {invalidMessages.map((res, idx) => {
                                          return (
                                            <li>
                                              <div>{res}</div>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  ) : (
                                    '-NA-'
                                  )}
                                </div>
                              </div>
                            </div>
                          ),
                        };
                      })
                    : []
                }
              />
            </div>
          </Modal>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.CMSMeetings.loading,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getCMSMeetings,
  uploadCMSMeetingsData,
  downloadMetingTemplate,
  deletCMSMeetingById,
  exportCMSMeetings,
  bulkDeletCMSMeetingByIds,
  upsertCMSMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(CMSMeetings);
