import callApi from './callApi';
import { baseUrl } from '../utils/config';
import { REQUEST_GET_AUDIT_LOGS } from './actionTypes';

const getAuditLogsData = (data) => (dispatch) => {
  const {
    activePage = 1,
    itemsPerPage = 10,
    searchKey,
    type,
    startTime,
    endTime,
    userIdList,
    roleIdList,
    candidateIdList,
    appId,
    noLimit = false,
  } = data || {};

  let url = `${baseUrl}/activitylogs?_=${new Date().getTime()}`;

  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;

  if (type) url += `&type=${type}`;

  if (startTime) url += `&startTime=${startTime}`;

  if (endTime) url += `&endTime=${endTime}`;

  if (userIdList && Array.isArray(userIdList) && userIdList.length > 0)
    url += `&userId=${userIdList}`;

  if (roleIdList && Array.isArray(roleIdList) && roleIdList.length > 0)
    url += `&roleId=${roleIdList}`;

  if (
    candidateIdList &&
    Array.isArray(candidateIdList) &&
    candidateIdList.length > 0
  ) {
    url += `&candidateId=${candidateIdList}`;
  }

  if (!noLimit) {
    url += `&filter[limit]=${itemsPerPage}`;
    url += `&filter[skip]=${skip}`;
  }

  if (searchKey) url += `&searchKey=${searchKey}`;

  if (appId) url += `&appId=${appId}`;

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    actionType: REQUEST_GET_AUDIT_LOGS,
    url,
  };
  return callApi(params);
};

export { getAuditLogsData };
