import React, { Component, Fragment } from 'react';
import PublicHeaderMock from './PublicHeaderMock';
import GenericTabs from '../../components/common/GenericTabs';
import GenericDataTable from '../../components/common/GenericDataTable';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Tile,
  RadioButton,
  Button,
  Modal,
  TextInput,
} from 'carbon-components-react';
import moment from 'moment';

class AgendaInfoMock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isSideNavExpanded: false,
    };
  }

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  handleTabClick = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  handleSetCountDown = (name) => {
    this.setState({
      showCountDown: true,
      candidateName: name,
    });
  };

  handleStopCountdown = (name) => {
    this.setState({
      showCountDown: false,
      [name]: true,
    });
  };

  render() {
    const { history } = this.props;
    const { push } = history || {};
    const {
      isSideNavExpanded,
      selectedTab,
      showCountDown,
      candidateName,
      hideButtons,
    } = this.state;
    const localizer = momentLocalizer(moment);
    const currDate = Date.now();
    const currDay = moment().format('D');
    const currMonth = moment().format('M');
    const currYear = moment().format('Y');
    const eventData = [
      {
        title: 'Interview - Sophia Wong',
        start: new Date(2020, 8, currDay, 14, 0, 0),
        end: new Date(2020, 8, currDay, 14, 30, 0),
        interviewer: 'Mr. Lee Tzu Yang',
      },

      {
        title: 'Interview - Jacob Lim',
        start: new Date(2020, 8, currDay, 13, 0, 0),
        end: new Date(2020, 8, currDay, 13, 30, 0),
        interviewer: 'Mr. Lee Tzu Yang',
      },
      {
        title: 'Interview - Isaiah Chia',
        start: new Date(2020, 8, currDay, 17, 0, 0),
        end: new Date(2020, 8, currDay, 17, 30, 0),
        interviewer: 'Mr. Lee Tzu Yang',
      },
      {
        title: 'Interview - Wee Baey Kok',
        start: new Date(2020, 8, currDay, 18, 30, 0),
        end: new Date(2020, 8, currDay, 19, 0, 0),
        interviewer: 'Mr. Lee Tzu Yang',
      },
      {
        title: 'Break',
        start: new Date(currYear, 8, currDay, 15, 30, 0),
        end: new Date(currYear, 8, currDay, 15, 45, 0),
        interviewer: 'Mr. Lee Tzu Yang',
      },
    ];
    return (
      <div>
        <PublicHeaderMock
          onClickSideNavExpand={this.onClickSideNavExpand}
          isSideNavExpanded={isSideNavExpanded}
          push={push}
          showCountDown={showCountDown}
          history={history}
          candidateName={candidateName}
        />
        <div className="bx--grid mt-5 xpa--ht-70">
          <GenericTabs
            selected={selectedTab}
            tabContent={[
              {
                label: 'Selection Board',
                value: '',
                onClick: () => {
                  this.handleTabClick(0);
                },
              },
              {
                label: 'Interviews',
                value: '',
                onClick: () => {
                  this.handleTabClick(1);
                },
              },
              {
                label: 'Events',
                value: '',
                onClick: () => {
                  this.handleTabClick(2);
                },
              },
            ]}
          />
          {selectedTab === 0 && (
            <div>
              <h6 className="mb-2">Panel Members</h6>
              <GenericDataTable
                className=""
                tbodyClass="candidate-background-color"
                otherActions={[]}
                headers={[
                  {
                    key: 'header_0',
                    header: 'Interviewer',
                  },
                  {
                    key: 'header_1',
                    header: 'Designation',
                  },
                  {
                    key: 'header_2',
                    header: 'Action',
                  },
                ]}
                rows={[
                  {
                    id: 1,
                    designation: 'Chairman',
                    interviewer: 'Mr. Lee Tzu Yang',
                  },
                  {
                    id: 2,
                    designation: 'Dy Chairman',
                    interviewer: 'Mr. Kwa Chong Seng',
                  },

                  {
                    id: 3,
                    designation: 'Commission Member',
                    interviewer: 'Mr. Michael Lim',
                  },
                  {
                    id: 4,
                    designation: 'Commission Member',
                    interviewer: "Mr Edward D'Silva",
                  },
                  {
                    id: 5,
                    designation: 'Commission Member',
                    interviewer: 'Mr Alan Chan',
                  },
                  {
                    id: 6,
                    designation: 'MHA Rep',
                    interviewer: 'Mr Christopher Ng ',
                  },

                  {
                    id: 8,
                    designation: 'MINDEF Rep',
                    interviewer: 'Mrs Elaine Ng Poh Choo',
                  },
                  {
                    id: 8,
                    designation: 'Dy Chairman',
                    interviewer: 'Ms Chua Sock Koong',
                  },
                  {
                    id: 9,
                    designation: 'Commission Member',
                    interviewer: "Mr Po'ad Mattar",
                  },
                  {
                    id: 10,
                    designation: 'Commission Member',
                    interviewer: 'Prof Lily Kong',
                  },
                  {
                    id: 11,
                    designation: 'Commission Member',
                    interviewer: 'Mr Philip Jeyaretnam',
                  },
                  {
                    id: 12,
                    designation: 'Commission Member',
                    interviewer: 'Prof Tan Ser Kiat',
                  },
                  {
                    id: 13,
                    designation: 'Commission Member',
                    interviewer: 'Dr Sudha Nair',
                  },
                  {
                    id: 14,
                    designation: 'MHA Rep',
                    interviewer: 'Mr Puah Kok Keong',
                  },
                  {
                    id: 15,
                    designation: 'MHA Rep',
                    interviewer: 'DC Jerry See',
                  },
                  {
                    id: 16,
                    designation: 'MINDEF Rep',
                    interviewer: 'Mr Lee Chung Wei ',
                  },
                  {
                    id: 17,
                    designation: 'MINDEF Rep',
                    interviewer: 'COL Lau Boon Ping',
                  },
                  {
                    id: 18,
                    designation: 'MINDEF Rep',
                    interviewer: 'COL Goh Pei Ming',
                  },
                ].map((res, idx) => {
                  const { id, interviewer, designation } = res || {};
                  return {
                    isExpanded: true,
                    id: `row_${id}`,
                    header_0: <div>{interviewer}</div>,
                    header_1: <div>{designation}</div>,
                    header_2: (
                      <div className="d-flex align-items-center">
                        <div className="ml-2">
                          <RadioButton
                            name={interviewer}
                            id={`yes-${idx}`}
                            labelText="Yes"
                          />
                        </div>
                        <div className="ml-2">
                          <RadioButton
                            name={interviewer}
                            id={`no-${idx}`}
                            labelText="No"
                          />
                        </div>
                      </div>
                    ),
                  };
                })}
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
              <h6 className="mt-3 mb-3">Secretariat Members</h6>
              <GenericDataTable
                className=""
                tbodyClass="candidate-background-color"
                otherActions={[
                  <div>
                    <Button
                      onClick={() => {
                        this.setState({ isOpenGuestModal: true });
                      }}>
                      Add Guest
                    </Button>
                  </div>,
                ]}
                headers={[
                  {
                    key: 'header_0',
                    header: 'Interviewer',
                  },
                  {
                    key: 'header_1',
                    header: 'Designation',
                  },
                  {
                    key: 'header_2',
                    header: 'Action',
                  },
                ]}
                rows={[
                  {
                    id: 1,
                    designation: 'Secretary, PSC',
                    interviewer: 'Ms Ong Toon Hu',
                  },
                  {
                    id: 2,
                    designation: 'Director, PSC Secretariat',
                    interviewer: 'Dr Ng Li Sa',
                  },
                  {
                    id: 3,
                    designation: 'Senior Executive, PSC Secretariat',
                    interviewer: 'Ms Samantha Wong',
                  },
                ].map((res, idx) => {
                  const { id, interviewer, designation } = res || {};
                  return {
                    isExpanded: true,
                    id: `row_${id}`,
                    header_0: <div>{interviewer}</div>,
                    header_1: <div>{designation}</div>,
                    header_2: (
                      <div className="d-flex align-items-center">
                        <div className="ml-2">
                          <RadioButton
                            name={interviewer}
                            id={`yes-${idx}`}
                            labelText="Yes"
                          />
                        </div>
                        <div className="ml-2">
                          <RadioButton
                            name={interviewer}
                            id={`no-${idx}`}
                            labelText="No"
                          />
                        </div>
                      </div>
                    ),
                  };
                })}
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
              <div className="mt-2 text-center">
                <Button>Submit</Button>
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <div>
              <GenericDataTable
                className=""
                tbodyClass="candidate-background-color"
                otherActions={[]}
                headers={[
                  {
                    key: 'header_0',
                    header: 'S/No',
                  },
                  {
                    key: 'header_1',
                    header: 'Name',
                  },
                  {
                    key: 'header_2',
                    header: 'Time of Interview',
                  },
                  {
                    key: 'header_3',
                    header: 'Interviewer',
                  },
                  {
                    key: 'header_4',
                    header: 'Action',
                    className: 'w-20',
                  },
                ]}
                rows={[
                  {
                    id: 1,
                    name: 'Isaiah Chia',
                    time: '2.00 pm',
                    interviewer: 'Mr. Lee Tzu Yang',
                  },
                  {
                    id: 2,
                    name: 'Wee Baey Kok',
                    time: '2.30 pm',
                    interviewer: 'Mr. Kwa Chong Seng',
                  },
                  {
                    id: 3,
                    name: 'Jacob Lim',
                    time: '3.00 pm',
                    interviewer: 'Ms. Chua Sock Koong',
                  },
                  {
                    id: 4,
                    name: 'Sophia Wong',
                    time: '3.30 pm',
                    interviewer: 'Mr. Michael Lim',
                  },
                  {
                    id: 5,
                    name: 'Li wei',
                    time: '4.00 pm',
                    interviewer: "Mr Edward D'Silva",
                  },
                  {
                    id: 6,
                    name: 'Wang Fang',
                    time: '4.30 pm',
                    interviewer: 'Mr Alan Chan',
                  },
                  {
                    id: 8,
                    name: 'Chris Chong',
                    time: '5.00 pm',
                    interviewer: "Mr Po'ad Mattar",
                  },
                ].map((res, idx) => {
                  const { id, name, time, interviewer } = res || {};
                  return {
                    isExpanded: true,
                    id: `row_${id}`,
                    header_0: <div>{id}</div>,
                    header_1: <div>{name}</div>,
                    header_2: <div>{time}</div>,
                    header_3: <div>{interviewer}</div>,
                    header_4:
                      this.state[name] === true ? (
                        <div className="bx--tag bx--tag--green">Completed</div>
                      ) : (
                        <div className="d-flex">
                          <Button
                            size="small"
                            onClick={() => {
                              this.handleSetCountDown(name);
                            }}>
                            Start
                          </Button>
                          {candidateName === name && (
                            <Button
                              kind="danger"
                              size="small"
                              className="ml-2"
                              onClick={() => {
                                this.handleStopCountdown(name);
                              }}>
                              Stop
                            </Button>
                          )}
                        </div>
                      ),
                  };
                })}
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
            </div>
          )}
          {selectedTab === 2 && (
            <Fragment>
              <Tile>
                <div style={{ height: '700px' }}>
                  <Calendar
                    localizer={localizer}
                    events={eventData || []}
                    views={['day']}
                    defaultView="day"
                    step={60}
                    startAccessor="start"
                    endAccessor="end"
                  />
                </div>
              </Tile>
            </Fragment>
          )}
        </div>
        <Modal
          open={this.state.isOpenGuestModal}
          modalHeading="Invite User"
          modalLabel="Action"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          iconDescription="Close the modal"
          onRequestClose={() => {
            this.setState({ isOpenGuestModal: false });
          }}
          onRequestSubmit={() => {}}>
          <div>
            <TextInput labelText="User Name" />
          </div>
        </Modal>
      </div>
    );
  }
}

export default AgendaInfoMock;