import React, { Component, Fragment } from 'react';
//Redux
import { connect } from 'react-redux';
import {
  createPresidentScholarshipEval,
  upsertPresidentScholarshipEval,
  getPresidentScholarshipEval,
  upsertCandidateDetailsById,
} from '../../actions/CandidateInfo';
import { downloadHighLevelReports } from '../../actions/HighLevelReports';

//Functional Components
import PSEvalFormTable from '../../components/private/PSEvalFormTable';
import CombinedEvaluationForm from '../../components/private/CombinedEvaluationForm';
import psStaticDataEval from '../../staticdata/EvaluationFormStaticData';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import NoContentBox from '../../components/common/NoContentBox';
//Common Packages
import {
  RadioButton,
  TextArea,
  Button,
  InlineNotification,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';
import moment from 'moment';

class PresidentScholarshipEvalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openAccr: 0,
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    this.handleGetEvalAPi();
    this.props.handleGetCandidateInfoAPI();
  }

  componentDidUpdate(prevProps) {
    const { applicationInfo: prevPropsApplInfo } = prevProps || {};
    const { psFinalOutcomeComments: prevPropsComments } =
      prevPropsApplInfo || {};
    const { applicationInfo } = this.props || {};
    const { psFinalOutcomeComments } = applicationInfo || {};
    if (psFinalOutcomeComments !== prevPropsComments) {
      this.setState({
        psFinalOutcomeComments,
      });
    }
  }

  handleGetEvalAPi = () => {
    const { appId, user, applicationInfo } = this.props || {};
    const { userId } = user || {};
    this.props
      .getPresidentScholarshipEval(appId)
      .then((res) => {
        if (res && !res.error) {
          const { psEvaluationList } = this.props || {};
          let filterEvalList = {};
          if (
            psEvaluationList &&
            Array.isArray(psEvaluationList) &&
            psEvaluationList.length > 0
          ) {
            filterEvalList = psEvaluationList.find(
              (data) => data && data.userId == userId
            );
          }
          const {
            psFinalOutcomeEval,
            psFinalOutcomeComments,
            psFinalOutcomeEval2,
          } = applicationInfo || {};
          this.setState(
            {
              ...filterEvalList,
              openAccr: userId,
              psFinalOutcomeEval,
              psFinalOutcomeComments,
              psFinalOutcomeEval2,
            },
            () => {
              this.handleCombinedEvalForm();
            }
          );
        }
      })
      .catch((err) => console.log('Err'));
  };

  handleApiRes = (res) => {
    const { error } = res || {};
    const { message } = error || {};
    if (res && !res.error) {
      this.setState({
        successNotif: true,
        notifMsg: 'Your changes have been updated successfully',
        errorNotif: false,
        isEditForm: false,
      });
      this.handleGetEvalAPi();
    } else {
      this.setState({
        errorNotif: true,
        isEditForm: false,
        errMsg: message
          ? message
          : 'An Error has ocurred while processing your request, Please try again later.',
      });
    }
  };

  handleRadioBtnChange = (name, value) => {
    this.setState({
      [name]: value,
      psFinalOutcomeEval2: name === 'psFinalOutcomeEval2' ? value : '',
    });
  };

  handleCommisionRadioBtnChange = (value) => {
    this.setState({
      psIndvidualRating: value,
    });
  };

  handleSubmitFinalOutcome = () => {
    const { psFinalOutcomeEval, psFinalOutcomeComments, psFinalOutcomeEval2 } =
      this.state;
    const { appId } = this.props;
    this.setState(
      {
        isValidFinalOutcomeBtns: psFinalOutcomeEval ? false : true,
      },
      () => {
        const { isValidFinalOutcomeBtns } = this.state;
        if (!isValidFinalOutcomeBtns) {
          const data = {
            psFinalOutcomeEval,
            psFinalOutcomeComments,
            appId,
            psFinalOutcomeEval2,
          };
          this.props
            .upsertCandidateDetailsById(data)
            .then((res) => {
              if (res && !res.error) {
                this.props.handleGetCandidateInfoAPI();
                this.handleRedirect &&
                  this.handleRedirect.current &&
                  this.handleRedirect.current.focus &&
                  this.handleRedirect.current.focus();
                this.setState(
                  {
                    successNotif: true,
                    notifMsg: 'Your details have been updated successfully',
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        successNotif: false,
                        notifMsg: '',
                      });
                    }, 5000);
                  }
                );
              } else {
                this.handleRedirect &&
                  this.handleRedirect.current &&
                  this.handleRedirect.current.focus &&
                  this.handleRedirect.current.focus();
                this.setState({
                  errorNotif: true,
                });
              }
            })
            .catch((e) => console.log('Error', e));
        }
      }
    );
  };

  handleSubmitEvalForm = () => {
    const {
      leadership,
      intellectualQualities,
      characterValues,
      peopleSkills,
      personalEffectiveness,
      communicationSkills,
      communityInvolvement,
      psEvalId,
      psIndvidualRating,
      comments,
    } = this.state;
    let isValidEvalTraits = false;
    if (
      !leadership ||
      !intellectualQualities ||
      !characterValues ||
      !peopleSkills ||
      !communicationSkills ||
      !personalEffectiveness ||
      !communityInvolvement
    ) {
      isValidEvalTraits = true;
      this.handleRedirect &&
        this.handleRedirect.current &&
        this.handleRedirect.current.focus &&
        this.handleRedirect.current.focus();
    } else {
      isValidEvalTraits = false;
    }
    this.setState(
      {
        isValidEvalTraits,
        isValidCommRating: psIndvidualRating ? false : true,
      },
      () => {
        const { isValidEvalTraits: stateValidTraits, isValidCommRating } =
          this.state;
        if (!stateValidTraits && !isValidCommRating) {
          const { appId } = this.props || {};
          const data = {
            appId,
            leadership,
            intellectualQualities,
            characterValues,
            peopleSkills,
            personalEffectiveness,
            communicationSkills,
            communityInvolvement,
            psIndvidualRating,
            comments,
          };
          if (!psEvalId) {
            this.props
              .createPresidentScholarshipEval(data)
              .then((res) => {
                this.handleApiRes(res);
              })
              .catch((e) => {
                console.log('Error', e);
                this.setState({
                  errorNotif: true,
                });
              });
          } else {
            this.props
              .upsertPresidentScholarshipEval(data, psEvalId)
              .then((res) => {
                this.handleApiRes(res);
              })
              .catch((e) => {
                console.log('Error', e);
                this.setState({
                  errorNotif: true,
                });
              });
          }
        }
      }
    );
  };

  handleCloseCommNotif = () => {
    this.setState({
      isValidCommRating: false,
    });
  };

  handleAccrClick = (userId) => {
    this.setState({
      openAccr: userId,
    });
  };

  handleEditForm = (psEvalId) => {
    this.setState({
      isEditForm: true,
      psEvalId,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCombinedEvalForm = () => {
    const { psEvaluationList } = this.props || {};
    let finalOutComeObj = {
      leadership: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      intellectualQualities: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      characterValues: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      peopleSkills: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      personalEffectiveness: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      communicationSkills: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      communityInvolvement: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
    };
    let usersList = [];
    let unsuitableCount = 0;
    let shortlistCount = 0;
    let awardCount = 0;
    let offerCount = 0;
    let unsuitableArr = [];
    let shortlistArr = [];
    let awardArr = [];
    let offerArr = [];
    if (
      psEvaluationList &&
      Array.isArray(psEvaluationList) &&
      psEvaluationList.length > 0
    ) {
      psEvaluationList.forEach((res, idx) => {
        const {
          userinfo,
          leadership,
          intellectualQualities,
          characterValues,
          peopleSkills,
          personalEffectiveness,
          communicationSkills,
          communityInvolvement,
          psEvalId,
          psIndvidualRating,
        } = res || {};
        const { firstname, lastname } = userinfo || {};
        const fullName = `${
          firstname || lastname ? `${firstname} ${lastname}` : 'Not Available'
        }`;
        if (usersList && Array.isArray(usersList) && usersList.length > 0) {
          usersList.push(fullName);
        } else {
          usersList = [fullName];
        }
        if (psIndvidualRating === 'unsuitable') {
          unsuitableCount += 1;
          unsuitableArr.push(idx + 1);
        }
        if (psIndvidualRating === 'shortlist') {
          shortlistCount += 1;
          shortlistArr.push(idx + 1);
        }
        if (psIndvidualRating === 'award') {
          awardCount += 1;
          awardArr.push(idx + 1);
        }
        if (psIndvidualRating === 'offer') {
          offerCount += 1;
          offerArr.push(idx + 1);
        }
        this.handleEvalFormVals(
          leadership,
          finalOutComeObj,
          'leadership',
          idx + 1
        );
        this.handleEvalFormVals(
          intellectualQualities,
          finalOutComeObj,
          'intellectualQualities',
          idx + 1
        );
        this.handleEvalFormVals(
          characterValues,
          finalOutComeObj,
          'characterValues',
          idx + 1
        );
        this.handleEvalFormVals(
          communicationSkills,
          finalOutComeObj,
          'communicationSkills',
          idx + 1
        );
        this.handleEvalFormVals(
          peopleSkills,
          finalOutComeObj,
          'peopleSkills',
          idx + 1
        );
        this.handleEvalFormVals(
          personalEffectiveness,
          finalOutComeObj,
          'personalEffectiveness',
          idx + 1
        );
        this.handleEvalFormVals(
          communityInvolvement,
          finalOutComeObj,
          'communityInvolvement',
          idx + 1
        );
      });
    }
    this.setState({
      combinedEvalForm: finalOutComeObj,
      usersList,
      unsuitableCount,
      shortlistCount,
      awardCount,
      offerCount,
      unsuitableArr,
      shortlistArr,
      awardArr,
      offerArr,
    });
  };

  handleEvalFormVals = (value, finalOutComeObj, type, arrVal) => {
    if (value === 'moderate') {
      finalOutComeObj[type].moderate.push(arrVal);
    } else if (value === 'weak') {
      finalOutComeObj[type].weak.push(arrVal);
    } else if (value === 'veryWeak') {
      finalOutComeObj[type].veryWeak.push(arrVal);
    } else if (value === 'strong') {
      finalOutComeObj[type].strong.push(arrVal);
    } else if (value === 'veryStrong') {
      finalOutComeObj[type].veryStrong.push(arrVal);
    } else if (value === 'none') {
      finalOutComeObj[type].none.push(arrVal);
    }
    return finalOutComeObj;
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleExportCandidateEvaluationForm = () => {
    const { psEvaluationList, applicationInfo } = this.props || {};
    const { profile } = applicationInfo || {};
    const {
      combinedEvalForm,
      usersList,
      unsuitableCount,
      shortlistCount,
      awardCount,
      offerCount,
      unsuitableArr,
      shortlistArr,
      awardArr,
      offerArr,
      psFinalOutcomeEval,
      psFinalOutcomeEval2,
      psFinalOutcomeComments,
    } = this.state;
    const { nric, fullName } = profile || {};
    this.props
      .downloadHighLevelReports('candidate-evaluation-ps', {
        fullName,
        nric,
        combinedEvalForm,
        usersList,
        evalStaticData: psStaticDataEval,
        unsuitableCount,
        shortlistCount,
        awardCount,
        offerCount,
        unsuitableArr,
        shortlistArr,
        awardArr,
        offerArr,
        evaluationList: psEvaluationList,
        psFinalOutcomeEval,
        psFinalOutcomeEval2,
        psFinalOutcomeComments,
      })
      .then((pdf) => {
        if (pdf && !pdf.error) {
          const pdfDoc = URL.createObjectURL(pdf);

          const a = document.createElement('a');
          a.href = pdfDoc;
          a.download = `Candidate-Evaluation-${moment().format('MM/DD/YYYY')}`;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(pdfDoc);
          }, 1000);
        }
      })
      .catch((e) => console.log('Error', e));
  };

  render() {
    const { psEvaluationList, user } = this.props || {};
    const {
      roleId,
      firstname: loggedInFName,
      lastname: loggedInLName,
      userId,
    } = user || {};
    const {
      isValidEvalTraits,
      isValidCommRating,
      isEditForm,
      openAccr,
      usersList,
      combinedEvalForm,
      unsuitableCount,
      shortlistCount,
      awardCount,
      offerCount,
      unsuitableArr,
      shortlistArr,
      awardArr,
      offerArr,
      psFinalOutcomeEval,
      isValidFinalOutcomeBtns,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      psFinalOutcomeEval2,
    } = this.state;
    const finalOutcomeEvalTraits = [
      'unsuitable',
      'shortlist',
      'award ps',
      'offer provisional ps',
    ];
    return (
      <div>
        <div className="d-flex justify-content-between">
          <div class="mt-2 h6 font-weight-bold">
            President’s Scholarship Candidate Evaluation Form{' '}
          </div>
        </div>
        {psEvaluationList &&
          Array.isArray(psEvaluationList) &&
          psEvaluationList.length > 0 &&
          (roleId === 6 || roleId === 2) && (
            <div className="text-right mt-2 mb-2">
              <Button onClick={this.handleExportCandidateEvaluationForm}>
                Export
              </Button>
            </div>
          )}
        {((psEvaluationList &&
          Array.isArray(psEvaluationList) &&
          psEvaluationList.length > 0) ||
          roleId === 7) && (
          <Fragment>
            <div style={{ lineHeight: 1.3 }} className="text-justify mt-2 mb-2">
              The Candidate Evaluation Form for the use of PSC members at the
              President’s Scholarship Selection Board serves to move towards a
              more structured decision-making process, focusing on the key
              attributes desired in a President’s Scholar.
            </div>
            <div style={{ lineHeight: 1.3 }} className="text-justify mt-2 mb-2">
              It is recommended that Members evaluate candidates holistically by
              integrating assessment data available in the candidate dossier,
              including the Assessment Centre and Peer Appraisal reports, and
              how they present themselves at the panel interview. The Candidate
              Evaluation Form will be reviewed periodically in order to ensure
              its continued relevance and ease of use. Members’ views on the
              Candidate Evaluation Form are welcome.
            </div>
            <div style={{ lineHeight: 1.3 }} className="text-justify mt-2 mb-2">
              Please assist to fill up this evaluation form for all the
              candidates. For interview(s) you did not attend or had joined/left
              mid-way, you do not need to complete the evaluation form.
            </div>
          </Fragment>
        )}

        <input
          style={{ height: 0, opacity: 0 }}
          type="text"
          ref={this.handleRedirect}
        />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        {isValidEvalTraits === true && (
          <InlineNotification
            lowContrast
            title="Please select valid rating for each trait"
            iconDescription="describes the close button"
            kind="error"
            onCloseButtonClick={() => {
              this.setState({ isValidEvalTraits: false });
            }}
          />
        )}
        {psEvaluationList &&
          Array.isArray(psEvaluationList) &&
          psEvaluationList.length <= 0 &&
          roleId === 7 &&
          !finalOutcomeEvalTraits.includes(psFinalOutcomeEval) && (
            <Accordion>
              <AccordionItem
                open
                className="content-padding"
                title={
                  <h5 className="font-weight-bold text-black text-capitalize pt-1">
                    {loggedInFName || loggedInLName
                      ? `${loggedInFName} ${loggedInLName}`
                      : 'Not Available'}
                  </h5>
                }>
                <div class="mt-2 h6 f-w-b">Key Attributes</div>
                <div
                  style={{ lineHeight: 1.3 }}
                  className="mt-2 mb-3 text-justify font-style-italic">
                  Please select the ratings based on a holistic assessment of
                  the candidate on each attribute, taking into consideration
                  ratings that the candidate has received for the same
                  attributes in the Assessment Centre/Peer Appraisal exercises
                  (applicable for the first six attributes).
                </div>
                <PSEvalFormTable
                  handleRadioBtnChange={this.handleRadioBtnChange}
                  userId={userId}
                  state={this.state}
                  handleCommisionRadioBtnChange={
                    this.handleCommisionRadioBtnChange
                  }
                  isValidCommRating={isValidCommRating}
                  handleCloseCommNotif={this.handleCloseCommNotif}
                  handleChange={this.handleChange}
                />
                <div className="mt-2 text-center">
                  <Button
                    kind="primary"
                    size="small"
                    onClick={this.handleSubmitEvalForm}>
                    Submit
                  </Button>
                </div>
              </AccordionItem>
            </Accordion>
          )}
        {psEvaluationList &&
        Array.isArray(psEvaluationList) &&
        psEvaluationList.length > 0 ? (
          <Fragment>
            <Accordion>
              {psEvaluationList.map((evalD) => {
                const {
                  userinfo,
                  isFormSubmitted,
                  psEvalId,
                  userId: propUserId,
                } = evalD || {};
                const { firstname: propFirstName, lastname: propLastName } =
                  userinfo || {};
                return (
                  <AccordionItem
                    className="content-padding"
                    open={openAccr == propUserId}
                    onClick={() => this.handleAccrClick(propUserId)}
                    title={
                      <h5 className="font-weight-bold text-black text-capitalize pt-1">
                        {propFirstName || propLastName
                          ? `${propFirstName} ${propLastName}`
                          : 'Not Available'}
                      </h5>
                    }>
                    <div>
                      {userId == propUserId &&
                        roleId === 7 &&
                        !psFinalOutcomeEval && (
                          <div class="text-right mb-3">
                            <Button
                              kind="tertiary"
                              onClick={() => {
                                this.handleEditForm(psEvalId);
                              }}>
                              Edit
                            </Button>
                          </div>
                        )}
                    </div>
                    <PSEvalFormTable
                      handleRadioBtnChange={this.handleRadioBtnChange}
                      state={this.state}
                      handleCommisionRadioBtnChange={
                        this.handleCommisionRadioBtnChange
                      }
                      userId={propUserId}
                      psEvalId={psEvalId}
                      response={evalD}
                      isValidCommRating={isValidCommRating}
                      handleCloseCommNotif={this.handleCloseCommNotif}
                      isFormSubmitted={isFormSubmitted}
                      isEditForm={isEditForm}
                      handleChange={this.handleChange}
                    />
                    {userId == propUserId && isEditForm === true && (
                      <div class="text-center mt-3">
                        <Button
                          kind="primary"
                          onClick={this.handleSubmitEvalForm}>
                          Submit
                        </Button>
                      </div>
                    )}
                  </AccordionItem>
                );
              })}
            </Accordion>
            <div className="bx--row align-items-center mt-2">
              <div className="bx--row align-items-center bx--col-lg-8 bx--col-md-12">
                {usersList &&
                  Array.isArray(usersList) &&
                  usersList.length > 0 &&
                  usersList.map((vals, idx) => (
                    <div className="bx--col-lg-3 bx--col-md-3 mt-2 mb-2">
                      <i
                        className={`fa fa-circle trait-color-${idx + 1}`}
                        aria-hidden="true"
                      />{' '}
                      <span className="font-weight-bold text-capitalize">
                        {vals}
                      </span>
                    </div>
                  ))}
              </div>
              <div className="d-flex align-items-center bx--col-lg-4 justify-content-end pr-xl-0 pr-4 bx--col-md-12">
                <div className="h6">Consolidated Evaluation Form</div>
                <div className="ml-2">
                  <Button kind="tertiary" onClick={this.handleGetEvalAPi}>
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-2">
              <h5 className="mb-2">
                President’s Scholarship Combined Evaluation Form
              </h5>
              <div>
                <CombinedEvaluationForm
                  evalStaticData={psStaticDataEval}
                  combinedEvalForm={combinedEvalForm}
                />
              </div>
              <div>
                <h5 className="mb-2 mt-2">Combined Overall Rating</h5>
                <div className="font-weight-bold mb-2">Overall Rating</div>
                <div className="mt-2 mb-2">
                  <div className="d-flex justify-content-left mt-2">
                    <span style={{ fontWeight: 'bold' }} className="pr-2">
                      {unsuitableCount}
                    </span>{' '}
                    Unsuitable&nbsp;
                    {unsuitableArr &&
                      Array.isArray(unsuitableArr) &&
                      unsuitableArr.length > 0 &&
                      unsuitableArr.map((res) => (
                        <span className="pr-2">
                          <i
                            className={`fa fa-circle trait-color-${res}`}
                            aria-hidden="true"
                          />
                        </span>
                      ))}
                  </div>
                  <div className="d-flex justify-content-left mt-2">
                    <span style={{ fontWeight: 'bold' }} className="pr-2">
                      {shortlistCount}
                    </span>{' '}
                    Shortlist for further deliberation after all candidates have
                    been interviewed
                    {shortlistArr &&
                      Array.isArray(shortlistArr) &&
                      shortlistArr.length > 0 &&
                      shortlistArr.map((res) => (
                        <span className="pr-2">
                          <i
                            className={`fa fa-circle trait-color-${res}`}
                            aria-hidden="true"
                          />
                        </span>
                      ))}
                  </div>
                  <div className="d-flex justify-content-left mt-2">
                    <span style={{ fontWeight: 'bold' }} className="pr-2">
                      {awardCount}
                    </span>{' '}
                    To Award President’s Scholarship (proceeding for studies
                    this year)&nbsp;
                    {awardArr &&
                      Array.isArray(awardArr) &&
                      awardArr.length > 0 &&
                      awardArr.map((res) => (
                        <span className="pr-2">
                          <i
                            className={`fa fa-circle trait-color-${res}`}
                            aria-hidden="true"
                          />
                        </span>
                      ))}
                  </div>
                  <div className="d-flex justify-content-left mt-2">
                    <span style={{ fontWeight: 'bold' }} className="pr-2">
                      {offerCount}
                    </span>{' '}
                    To Offer Provisional President’s Scholarship (deferring
                    studies)&nbsp;
                    {offerArr &&
                      Array.isArray(offerArr) &&
                      offerArr.length > 0 &&
                      offerArr.map((res) => (
                        <span className="pr-2">
                          <i
                            className={`fa fa-circle trait-color-${res}`}
                            aria-hidden="true"
                          />
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <h5 className="mb-2 mt-2">Combined Overall Comments</h5>
              {psEvaluationList &&
                Array.isArray(psEvaluationList) &&
                psEvaluationList.length > 0 &&
                psEvaluationList.map((data, idx) => {
                  const { comments, userinfo } = data || {};
                  const { firstname, lastname } = userinfo || {};
                  return (
                    <div className="text-left bx--row mt-2 mb-2">
                      <div className="text-capitalize f-w-b bx--col-lg-2 bx--col-md-3">
                        <i
                          className={`fa fa-circle trait-color-${idx + 1}`}
                          aria-hidden="true"
                        />{' '}
                        {firstname} {lastname}:
                      </div>
                      <div
                        style={{ lineHeight: 1.5 }}
                        className="bx--col-lg-9 bx--col-md-5">
                        {comments || '-'}
                      </div>
                    </div>
                  );
                })}
            </div>
            <hr />
            {roleId !== 8 && roleId !== 9 && roleId !== 10 && (
              <div>
                {isValidFinalOutcomeBtns === true && (
                  <div className="small invalid-color">
                    <InlineNotification
                      lowContrast
                      title="Please select valid options"
                      iconDescription="describes the close button"
                      kind="error"
                      onCloseButtonClick={() => {
                        this.setState({ isValidFinalOutcomeBtns: false });
                      }}
                    />
                  </div>
                )}
                <h5 className="mb-2 mt-2">Final Outcome Section</h5>
                <div
                  className={`mt-2 mb-2 ${
                    roleId !== 6 && roleId !== 2 && 'csc-radio-btn-disabled'
                  }`}>
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      name="psFinalOutcomeEval"
                      disabled={roleId !== 6 && roleId !== 2}
                      labelText="Unsuitable"
                      value="unsuitable"
                      checked={psFinalOutcomeEval === 'unsuitable'}
                      onChange={() =>
                        this.handleRadioBtnChange(
                          'psFinalOutcomeEval',
                          'unsuitable'
                        )
                      }
                    />
                  </div>
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      name="psFinalOutcomeEval"
                      disabled={roleId !== 6 && roleId !== 2}
                      labelText="Shortlist for further deliberation after all candidates have been interviewed"
                      value="shortlist"
                      checked={psFinalOutcomeEval === 'shortlist'}
                      onChange={() =>
                        this.handleRadioBtnChange(
                          'psFinalOutcomeEval',
                          'shortlist'
                        )
                      }
                    />
                  </div>
                  {psFinalOutcomeEval === 'shortlist' && (
                    <div>
                      <div className="d-flex justify-content-left mt-2 ml-5">
                        <RadioButton
                          name="psFinalOutcomeEval2"
                          disabled={roleId !== 6 && roleId !== 2}
                          labelText="To Award President’s Scholarship (proceeding for studies this year)"
                          value="award ps"
                          checked={psFinalOutcomeEval2 === 'award ps'}
                          onChange={() =>
                            this.handleRadioBtnChange(
                              'psFinalOutcomeEval2',
                              'award ps'
                            )
                          }
                        />
                      </div>
                      <div className="d-flex justify-content-left mt-2 ml-5">
                        <RadioButton
                          name="psFinalOutcomeEval2"
                          disabled={roleId !== 6 && roleId !== 2}
                          labelText="To Offer Provisional President’s Scholarship (deferring studies)"
                          value="offer provisional ps"
                          checked={
                            psFinalOutcomeEval2 === 'offer provisional ps'
                          }
                          onChange={() =>
                            this.handleRadioBtnChange(
                              'psFinalOutcomeEval2',
                              'offer provisional ps'
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      name="psFinalOutcomeEval"
                      disabled={roleId !== 6 && roleId !== 2}
                      labelText="To Award President’s Scholarship (proceeding for studies this year)"
                      value="award ps"
                      checked={psFinalOutcomeEval === 'award ps'}
                      onChange={() =>
                        this.handleRadioBtnChange(
                          'psFinalOutcomeEval',
                          'award ps'
                        )
                      }
                    />
                  </div>
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      name="psFinalOutcomeEval"
                      disabled={roleId !== 6 && roleId !== 2}
                      labelText="To Offer Provisional President’s Scholarship (deferring studies)"
                      value="offer provisional ps"
                      onChange={() =>
                        this.handleRadioBtnChange(
                          'psFinalOutcomeEval',
                          'offer provisional ps'
                        )
                      }
                      checked={psFinalOutcomeEval === 'offer provisional ps'}
                    />
                  </div>
                  {roleId !== 7 && (
                    <div className="mt-2">
                      <div className="mt-2 mb-2 font-weight-bold">Comments</div>
                      <TextArea
                        name="psFinalOutcomeComments"
                        id="psFinalOutcomeComments"
                        disabled={roleId !== 6 && roleId !== 2}
                        value={this.state.psFinalOutcomeComments}
                        labelText=""
                        hideLabel
                        onChange={this.handleChange}
                        placeholder="Please enter your comments"
                      />
                    </div>
                  )}

                  {(roleId === 6 || roleId === 2) && (
                    <div className="mt-2">
                      <Button
                        size="small"
                        onClick={this.handleSubmitFinalOutcome}>
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          roleId !== 7 && (
            <NoContentBox
              hideTile
              showButtons
              buttonName="Refresh"
              handleOnClickButtons={this.handleGetEvalAPi}
              message="There are no ratings available for your current selection"
            />
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  psEvaluationList: state.CandidateInfo.psEvaluationList,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  createPresidentScholarshipEval,
  upsertPresidentScholarshipEval,
  getPresidentScholarshipEval,
  upsertCandidateDetailsById,
  downloadHighLevelReports,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PresidentScholarshipEvalForm);
