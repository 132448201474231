import React, { Component, Suspense, useState, useEffect } from 'react';
//Router Dom
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

//Redux
import { connect } from 'react-redux';

//API Calls
import { fetchUserLoginState, getAuthState } from '../actions/UserLogin';

//Common Components
import NotFound from '../components/common/NotFound';
import JDProcessOverlay from '../components/common/JDProcessOverlay';
import XPAErrorBoundary from '../containers/common/XPAErrorBoundary';
import { FlaskLoader } from '../components/common/common';

//Main Functional Components
import PublicAmpPlayer from '../components/public/PublicAmpPlayer';
import ScheduleMaintenance from '../components/public/ScheduleMaintenance';

//Main Components (UI)
import MainApp from './MainApp';
import Login from '../components/public/Login';
import CandidateReportNoResponse from '../components/public/CandidateReportNoResponse';
import CscLogin from '../containers/public/CscLogin';
import Registration from '../containers/public/Registration';
import MockUI2 from '../containers/public/MockUi2';
import Reports from '../containers/private/Reports';
import Page from '../containers/public/ForgotPassword';
import ResetPassword from '../containers/public/ResetPassword';
import DashboardMock from '../containers/public/DashboardMock';
import BatchInfMock from '../containers/public/BatchInfMock';
import CandidateListMock from '../containers/public/CandidateListMock';
import SearchCandidatesMock from '../containers/public/SearchCandidatesMock';
import ReportsMockUi from '../containers/public/ReportsMockUi';
import AgendaMock from '../containers/public/AgendaMock';
import AgendaInfoMock from '../containers/public/AgendaInfoMock';
import AgendaInterviewsMock from '../containers/public/AgendaInterviewsMock';
import MockReports from '../containers/public/MockReports';
import CandidatePeerAppraisalReport from '../containers/public/CandidatePeerAppraisalReport';
import GBAReportExport from '../containers/private/GBAReportExport';
import { allowedRoutesFor3rdPartyRoles } from './RestrictedRoutes';

const Routes = (props) => {
  const { user, history, match, isUserLoggedIn } = props;
  const { push } = history || {};
  const { userId, roleId, isScheduledMaintainance } = user || {};
  const [stateObj, setStateObj] = useState({
    showSubItems: false,
    isLogging: false,
    isSideNavExpanded: false,
    isOpenNavModal: false,
  });
  const {
    showSubItems,
    isLogging,
    isSideNavExpanded,
    subItems,
    label,
    description,
    isOpenNavModal,
  } = stateObj;

  useEffect(() => {
    props.getAuthState().then((res) => {
      const { error, u, c } = res || {};
      if (!error && u) {
        props
          .fetchUserLoginState(u, c)
          .then((data) => {
            if (data && !data.error) {
              const { forcedPassChange } = user || {};
              if (forcedPassChange === true) {
                if (push) {
                  push('/reset-password');
                }
              }
            }
          })
          .catch((e) => console.log('Error', e));
      } else {
        sessionStorage.setItem('loggedout', true);
        localStorage.removeItem('application-ids');
      }
    });
  }, []);

  const setLogging = (isLogging) => {
    setStateObj({ ...stateObj, isLogging });
  };

  const handleMainApp = () => {
    return isScheduledMaintainance === true ? (
      <ScheduleMaintenance user={user} history={history} />
    ) : (
      <MainApp
        showSubItems={showSubItems}
        label={label}
        description={description}
        handleShowSubMenu={handleShowSubMenu}
        subItems={subItems}
        isSideNavExpanded={isSideNavExpanded}
        onClickSideNavExpand={onClickSideNavExpand}
        userId={userId}
        roleId={roleId}
        history={history}
        user={user}
        handleOpenNavigation={handleOpenNavigation}
        isOpenNavModal={isOpenNavModal}
        match={match}
      />
    );
  };

  const handleShowSubMenu = (subItems, label, description, value) => {
    const stateSubItems = stateObj?.subItems;
    const stateLabel = stateObj?.label;
    if (value === true) {
      if (
        stateSubItems &&
        Array.isArray(stateSubItems) &&
        stateSubItems.length > 0 &&
        label !== stateLabel
      ) {
        setStateObj({
          ...stateObj,
          showSubItems: true,
          subItems,
          label,
          description,
        });
      } else {
        setStateObj({
          ...stateObj,
          showSubItems: !showSubItems,
          subItems,
          label,
          description,
        });
      }
    } else {
      setStateObj({
        ...stateObj,
        showSubItems: false,
        subItems: [],
        label: '',
        description: '',
      });
    }
  };

  const onClickSideNavExpand = (hideSideNav) => {
    setStateObj({
      ...stateObj,
      isSideNavExpanded: hideSideNav === true ? false : !isSideNavExpanded,
    });
  };

  const handleOpenNavigation = (openNavModal) => {
    setStateObj({
      ...stateObj,
      isOpenNavModal: openNavModal,
    });
  };

  let rpath = '/';
  if (isUserLoggedIn === true) {
    if (roleId === 8 || roleId === 9 || roleId === 10) {
      rpath = '/app/csc/calendar/interviews';
    } else if (roleId === 7) {
      rpath = '/app/csc/panel/interviews/list';
    } else if (roleId == 11 || roleId == 12 || roleId == 13) {
      rpath = '/app/csc/cms/meetings';
    } else if (roleId == 14) {
      rpath = allowedRoutesFor3rdPartyRoles?.[0];
    } else {
      rpath = '/app/csc';
    }
  } else {
    rpath = '/';
  }
  const getSessionStorage = sessionStorage.getItem('loggedout');

  return (
    <div>
      <XPAErrorBoundary user={props.user}>
        <Suspense fallback={<FlaskLoader />}>
          <Switch>
            <Route
              exact
              path="/"
              render={(routeProps) => {
                if (isUserLoggedIn === true && !getSessionStorage) {
                  return (
                    <Redirect
                      to={{
                        pathname: rpath,
                        state: { xfrom: props.location },
                      }}
                    />
                  );
                }
                return isUserLoggedIn === false ? (
                  <Login {...routeProps} />
                ) : (
                  <Login {...routeProps} />
                );
              }}
            />
            <Route
              path="/app"
              render={(routerProps) => handleMainApp({ ...routerProps })}
            />
            <Route path="/registration/:data" component={Registration} />
            <Route
              exact
              path="/public/csc/login"
              render={(routeProps) => {
                if (isUserLoggedIn === true && !getSessionStorage) {
                  return (
                    <Redirect
                      to={{
                        pathname: rpath,
                        state: { xfrom: props.location },
                      }}
                    />
                  );
                }
                return <CscLogin {...routeProps} setLogging={setLogging} />;
              }}
            />
            <Route
              exact
              path={`/public/amp/player`}
              component={PublicAmpPlayer}
            />
            <Route
              exact
              path="/public/csc/reports/:status"
              component={Reports}
            />
            <Route
              exact
              path="/public/csc/gba/report/export/:profileId/:type/:token"
              component={GBAReportExport}
            />
            <Route
              exact
              path="/public/csc/peer/appraisal/:profileId"
              component={CandidatePeerAppraisalReport}
            />
            <Route path="/forgot-password" component={Page} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route exact path="/public/dashboard/1" component={DashboardMock} />
            <Route exact path="/public/reports" component={ReportsMockUi} />
            <Route exact path="/public/candidate/:name" component={MockUI2} />
            <Route exact path="/public/batchinfo" component={BatchInfMock} />
            <Route exact path="/public/agenda" component={AgendaMock} />
            <Route
              exact
              path="/public/agenda/info"
              component={AgendaInfoMock}
            />
            <Route
              exact
              path="/public/list/candidate"
              component={CandidateListMock}
            />
            <Route
              exact
              path="/public/candidate/no/report"
              component={CandidateReportNoResponse}
            />
            <Route
              exact
              path="/public/list/candidate/search"
              component={SearchCandidatesMock}
            />
            <Route
              exact
              path="/public/list/agenda/interviews"
              component={AgendaInterviewsMock}
            />
            <Route exact path="/public/reports/mock" component={MockReports} />
            <Route path="/*" component={NotFound} />
          </Switch>
          <JDProcessOverlay show={isLogging} message="processing..." />
        </Suspense>
      </XPAErrorBoundary>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  fetchUserLoginState,
  getAuthState,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
