import React, { Fragment } from 'react';

//Common Components
import GenericDataTable from '../common/GenericDataTable';

//Common packages
import moment from 'moment';
import { TextInput, Pagination, TooltipIcon } from 'carbon-components-react';
import NoContentBox from '../common/NoContentBox';
import {
  handleGetGBAfitScores,
  handleGetCognitiveScores,
  handleGetPsychologicalScores,
  handleGetGbaTypes,
  handleGetNeopirScoes,
} from './CandiateMetricColorDetails';
import {
  getCandidatePreferences,
  getCCATierData,
} from './CandidatePersonalDetails';
//Images
import MaleImg from '../../assets/images/male.png';
import {
  LeadershipTooltip,
  RepresentationTooltip,
  PSCPoints,
  PolytechnicGPA,
  IBScore,
  NUSCap,
} from './Tooltips';
import FemaleImg from '../../assets/images/female.png';
import CandidateInsights from './CandidateInsights';

const CandidateInterviewsList = (props) => {
  const {
    data,
    push,
    handlePaginationChange,
    count,
    activePage,
    showTime,
    handleChange,
    state,
    handleSubmitCriteria,
    roleId,
    recordType,
  } = props || {};

  const gbaKeys = [
    {
      key: 'performanceBased',
      label: 'Performance Based',
    },
    {
      key: 'futureDriven',
      label: 'Future Driven',
    },
  ];
  if (roleId != 7 && roleId !== 8 && roleId !== 9 && roleId !== 10) {
    gbaKeys.push(
      {
        key: 'award',
        label: 'Award',
      },
      {
        key: 'average',
        label: 'Average',
      }
    );
  }

  return (
    <div>
      {data && Array.isArray(data) && data.length > 0 ? (
        <GenericDataTable
          tbodyClass="candidate-background-color css-align-top"
          otherActions={[]}
          searchable={false}
          onSearchInputChange={() => {}}
          rows={
            data && Array.isArray(data) && data.length > 0
              ? data.map((interview, idx) => {
                  const {
                    application,
                    schoolDetails,
                    ccatiering,
                    psychologicalReports,
                    polyResult,
                    CareerPreference,
                    CoursePreference,
                    gbaInfo,
                    mainInterviewer,
                    ibDiploma,
                    nusHighSchool,
                    aLevelResult,
                    oLevelResult,
                    universityAppln,
                    wgiiiScoreInfo,
                    wgctaScoreInfo,
                    ranraInfo,
                    psMainInterviewer,
                    uCognifyScore,
                    leapScores,
                    taskResult,
                  } = interview || {};
                  //O Level
                  const { semDetails: oLevelSemDetails } =
                    (oLevelResult && oLevelResult[0]) || {};
                  const { examyear } =
                    (oLevelSemDetails &&
                      Array.isArray(oLevelSemDetails) &&
                      oLevelSemDetails.length > 0 &&
                      oLevelSemDetails[0]) ||
                    {};

                  //A Level
                  const { psc70App } =
                    (aLevelResult &&
                      Array.isArray(aLevelResult) &&
                      aLevelResult.length > 0 &&
                      aLevelResult[0]) ||
                    {};
                  let generalPaperObj = {};
                  if (
                    aLevelResult &&
                    Array.isArray(aLevelResult) &&
                    aLevelResult.length > 0
                  ) {
                    aLevelResult.forEach((alevel) => {
                      const { semDetails } = alevel || {};
                      if (
                        semDetails &&
                        Array.isArray(semDetails) &&
                        semDetails.length > 0
                      ) {
                        semDetails.forEach((alevelSem) => {
                          const { subDetails } = alevelSem || {};
                          if (
                            subDetails &&
                            Array.isArray(subDetails) &&
                            subDetails.length > 0
                          ) {
                            subDetails.forEach((alevelSubj) => {
                              const { subject: subjObj, grade: gradeObj } =
                                alevelSubj || {};
                              const { displayName: grade } = gradeObj || {};
                              const { subject } = subjObj || {};
                              if (subject === 'general paper') {
                                generalPaperObj.gpGrade = grade;
                              }
                              if (subject === 'project work') {
                                generalPaperObj.pwGrade = grade;
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                  const { gpGrade, pwGrade } = generalPaperObj || {};
                  //NUS High School
                  const { cap } =
                    (nusHighSchool &&
                      Array.isArray(nusHighSchool) &&
                      nusHighSchool.length > 0 &&
                      nusHighSchool[0]) ||
                    {};
                  //IB Diploma
                  const { totalScore } =
                    (ibDiploma &&
                      Array.isArray(ibDiploma) &&
                      ibDiploma.length > 0 &&
                      ibDiploma[0]) ||
                    {};

                  //Polytechnic Details
                  const {
                    cumulativeGpa,
                    qualification: poluQualification,
                    qualObtainedYear,
                    semDetails,
                    subject: polySubject,
                  } = (polyResult &&
                    Array.isArray(polyResult) &&
                    polyResult.length > 0 &&
                    polyResult[0]) ||
                  {};
                  const { displayName: polyCourseName } = polySubject || {};
                  const { displayName: polyQualName } = poluQualification || {};
                  const { yearOfStudy: polyStudeYear } =
                    (semDetails &&
                      Array.isArray(semDetails) &&
                      semDetails.length > 0 &&
                      semDetails[0]) ||
                    {};

                  //School Details
                  let filterdSchoolDetails = [];
                  if (
                    schoolDetails &&
                    Array.isArray(schoolDetails) &&
                    schoolDetails.length > 0
                  ) {
                    filterdSchoolDetails = schoolDetails.filter((sd) => {
                      const { schoollevel } = sd || {};
                      const { schoollevel: currSchoolLevel } =
                        schoollevel || {};
                      if (
                        currSchoolLevel === 'university' ||
                        currSchoolLevel === 'other institution'
                      ) {
                        return false;
                      }
                      return true;
                    });
                  }

                  const {
                    company: school,
                    schoollevel,
                    companyRel,
                  } = (filterdSchoolDetails &&
                    Array.isArray(filterdSchoolDetails) &&
                    filterdSchoolDetails.length > 0 &&
                    filterdSchoolDetails[0]) ||
                  {};
                  const { schoollevel: currSchoolLevel } = schoollevel || {};
                  const { displayName: schoolName } = school || {};
                  const { displayName: schoolName2 } = companyRel || {};
                  //CCA Tier
                  const ccaData = {
                    ccatiering,
                    schoolDetails,
                  };
                  const finalCCAOrderArr = getCCATierData(ccaData);
                  const { leadershipTier, representationTier } =
                    (finalCCAOrderArr &&
                      Array.isArray(finalCCAOrderArr) &&
                      finalCCAOrderArr.length > 0 &&
                      finalCCAOrderArr[0]) ||
                    {};
                  // Preferences
                  const data = {
                    CareerPreference,
                    CoursePreference,
                    universityAppln,
                  };

                  const preferencesData = getCandidatePreferences(data);
                  const {
                    finalCareerPreferences,
                    finalCoursePreferences,
                    finalUniversityPreferences,
                  } = preferencesData || {};
                  //Application
                  const {
                    profile,
                    appId,
                    selectionYear,
                    remarks,
                    nominationRanking,
                    criteria,
                    pscSessionSlotStartTimeStamp,
                  } =
                    (application &&
                      Array.isArray(application) &&
                      application.length > 0 &&
                      application[0]) ||
                    [];
                  const { overallScore: uCogOverallScore } =
                    uCognifyScore || {};
                  const { overallScores } = leapScores || {};
                  //Profile
                  const { fullName, genderId } = profile || {};
                  const cognitiveNumericalSc = handleGetCognitiveScores(
                    taskResult,
                    { wgiiiScoreInfo, wgctaScoreInfo, ranraInfo }
                  );
                  const neopirSc = handleGetNeopirScoes(taskResult);
                  const psychologicalScores =
                    handleGetPsychologicalScores(psychologicalReports);

                  //GBA Results
                  const { details } =
                    (gbaInfo &&
                      Array.isArray(gbaInfo) &&
                      gbaInfo.length > 0 &&
                      gbaInfo[0]) ||
                    {};
                  const { assessment_results } = details || {};
                  let gbaFitScores = [];
                  if (
                    assessment_results &&
                    Array.isArray(assessment_results) &&
                    assessment_results.length > 0
                  ) {
                    gbaFitScores = assessment_results.filter(
                      (r) => r && r.trait === 'Fit'
                    );
                  }
                  const fitScores = handleGetGBAfitScores(gbaFitScores);
                  const {
                    award,
                    futureDriven,
                    performanceBased,
                    average,
                    averageColor,
                    awardColor,
                    performanceBasedColor,
                    futureDrivenColor,
                  } = fitScores || {};
                  //Cognitivie Ability Scores
                  const {
                    criticalThinkingColor,
                    numericalReasoningColor,
                    criticalThinking,
                    numericalReasoning,
                  } = cognitiveNumericalSc || {};
                  const {
                    characterAndValues,
                    intellectualQualities,
                    interpersonalSkills,
                    leadershipQualities,
                    resilience,
                    characterAndValuesColor,
                    intellectualQualitiesColor,
                    interpersonalSkillsColor,
                    leadershipQualitiesColor,
                    resilienceColor,
                  } = psychologicalScores || {};

                  //Main Interviewer
                  const { selectionboard, createdAt } =
                    (mainInterviewer &&
                      Array.isArray(mainInterviewer) &&
                      mainInterviewer.length > 0 &&
                      mainInterviewer[0]) ||
                    {};
                  const { aliasName } = selectionboard || {};

                  //PS Main Interviewer
                  const {
                    selectionboard: psSelectionBoard,
                    createdAt: psCreatedAt,
                  } =
                    (psMainInterviewer &&
                      Array.isArray(psMainInterviewer) &&
                      psMainInterviewer.length > 0 &&
                      psMainInterviewer[0]) ||
                    {};
                  const { aliasName: psAliasName } = psSelectionBoard || {};
                  return {
                    isExpanded: false,
                    id: `row_${idx}`,
                    header_0: (
                      <div>
                        <div className="d-flex ml-2">
                          <div
                            style={{ fontWeight: 'bold' }}
                            className="h5 css-main-text-c mb-0 xpa-link text-capitalize">
                            <a
                              className="css-cand-name"
                              href={`/app/csc/candidate/${appId}/batch/${selectionYear}/status/personal-info`}>
                              {fullName}
                            </a>
                          </div>
                          <div className="ml-1">
                            {genderId == 1 ? (
                              <img
                                style={{ width: '20px' }}
                                src={MaleImg}
                                alt="Male"
                              />
                            ) : null}
                            {genderId == 2 ? (
                              <img
                                style={{ width: '20px' }}
                                src={FemaleImg}
                                alt="female"
                              />
                            ) : null}
                          </div>
                        </div>
                        {schoolName && (
                          <div className="mt-1 mb-1 ml-2 css-text-color">
                            {schoolName === 'Others' ||
                            schoolName === 'OtherSchool'
                              ? schoolName2
                              : schoolName}
                          </div>
                        )}
                        <div className="ml-2 mb-1 d-flex mt-2">
                          <h6>School Rank:</h6>{' '}
                          {nominationRanking ? (
                            <div className="css-text-color ml-2">
                              {nominationRanking}
                            </div>
                          ) : (
                            <div className="css-text-color ml-2">-</div>
                          )}
                        </div>
                        {/* <hr /> */}
                        {/* <div className="ml-2 mb-1">
                          <h6 className="mb-2">Criteria:</h6>{' '}
                          {criteria ? (
                            <div className="css-text-color">{criteria}</div>
                          ) : (
                            <Fragment>
                              {state && state[`error-${appId}`] && (
                                <div className="small invalid-color">
                                  An error occurred while processing your
                                  request, please try later
                                </div>
                              )}
                              {state && state[`success-${appId}`] && (
                                <div className="small css-color-green">
                                  Your details are updadated successfully
                                </div>
                              )}
                              <div className="d-flex align-items-center">
                                <div>
                                  <TextInput
                                    name={`criteria-${appId}`}
                                    id={appId}
                                    value={state && state[`criteria-${appId}`]}
                                    onChange={(e) => handleChange(e)}
                                    labelText=""
                                    hideLabel
                                    placeholder="Please enter the criteria"
                                    invalid={state && state[`invalid-${appId}`]}
                                    invalidText="Please enter valid criteria"
                                  />
                                </div>
                                <div className="ml-2">
                                  <Button
                                    onClick={() => handleSubmitCriteria(appId)}
                                    size="small"
                                    kind="ghost">
                                    Save
                                  </Button>
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div> */}
                        <hr />
                        {recordType === 'PSC' && (
                          <div className="d-flex mt-2 mb-2 ml-2">
                            <div>
                              <h6 className="css-main-text-c">
                                Main Interviewer
                              </h6>
                              <div
                                title="Main Interviewer"
                                className="mt-1 css-text-color text-capitalize">
                                {aliasName ? aliasName : '-'}
                              </div>
                            </div>
                            <div className="ml-4">
                              <div>
                                <h6 className="css-main-text-c">
                                  Interview Time
                                </h6>
                                <div
                                  title="Main Interviewer"
                                  className="mt-1 css-text-color">
                                  {showTime === true ? (
                                    <span>
                                      {createdAt &&
                                        moment(createdAt).format('h:mm a')}
                                    </span>
                                  ) : (
                                    <span>
                                      {createdAt
                                        ? moment(createdAt).format(
                                            'DD/MM/YYYY, h:mm a'
                                          )
                                        : pscSessionSlotStartTimeStamp
                                        ? moment(
                                            pscSessionSlotStartTimeStamp
                                          ).format('DD/MM/YYYY, h:mm a')
                                        : '-'}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {recordType === 'PS' && (
                          <div className="d-flex mt-2 mb-2 ml-2">
                            <div>
                              <h6 className="css-main-text-c">
                                Main Interviewer
                              </h6>
                              <div
                                title="Main Interviewer"
                                className="mt-1 css-text-color text-capitalize">
                                {psAliasName ? psAliasName : '-'}
                              </div>
                            </div>
                            <div className="ml-4">
                              <div>
                                <h6 className="css-main-text-c">
                                  Interview Time
                                </h6>
                                <div
                                  title="Main Interviewer"
                                  className="mt-1 css-text-color">
                                  {showTime === true ? (
                                    <span>
                                      {psCreatedAt &&
                                        moment(psCreatedAt).format('h:mm a')}
                                    </span>
                                  ) : (
                                    <span>
                                      {psCreatedAt
                                        ? moment(psCreatedAt).format(
                                            'DD/MM/YYYY, h:mm a'
                                          )
                                        : pscSessionSlotStartTimeStamp
                                        ? moment(
                                            pscSessionSlotStartTimeStamp
                                          ).format('DD/MM/YYYY, h:mm a')
                                        : '-'}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <hr />
                        <div className="mt-3 mb-2 pb-1 ml-2">
                          <h6 className="css-main-text-c mb-1">Remarks</h6>
                          <small>
                            {remarks ? (
                              <div className="css-text-color">{remarks}</div>
                            ) : (
                              <div className="css-text-color">-</div>
                            )}
                          </small>
                        </div>
                      </div>
                    ),
                    header_1: (
                      <div>
                        <div className="bx--row">
                          <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 ml-3 ml-lg-0 ml-xl-0 pl-0">
                            <h6 className="ml-2 css-traits-text-c f-w-b">
                              CCA Tier
                            </h6>
                            <div className="bx--row mt-2 pt-1">
                              <div className="bx--col-lg-6 bx--col-md-3 pl-4">
                                <div className="d-flex">
                                  <TooltipIcon
                                    tooltipText={<LeadershipTooltip />}
                                    className="css-tooltip-fill">
                                    {' '}
                                    <div
                                      className={` csc-th--block css-traits-score d-flex justify-content-center align-items-center`}>
                                      <div className="csc-f-sz-14">
                                        {!leadershipTier
                                          ? 'NA'
                                          : leadershipTier}
                                      </div>
                                    </div>
                                  </TooltipIcon>

                                  <div className="mt-1">
                                    <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                      <TooltipIcon
                                        tooltipText={<LeadershipTooltip />}
                                        className="css-tooltip-fill">
                                        {' '}
                                        <span className="h6 css-traits-text-c">
                                          Leadership
                                        </span>{' '}
                                      </TooltipIcon>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0">
                                <div className="d-flex ml-2">
                                  <TooltipIcon
                                    tooltipText={<RepresentationTooltip />}
                                    className="css-tooltip-fill">
                                    {' '}
                                    <div
                                      className={` csc-th--block css-traits-score d-flex justify-content-center align-items-center`}>
                                      <div className="csc-f-sz-14">
                                        {!representationTier
                                          ? 'NA'
                                          : representationTier}
                                      </div>
                                    </div>
                                  </TooltipIcon>

                                  <div className="mt-1">
                                    <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                      <TooltipIcon
                                        tooltipText={<RepresentationTooltip />}
                                        className="css-tooltip-fill">
                                        {' '}
                                        <span className="h6 css-traits-text-c">
                                          Representation
                                        </span>{' '}
                                      </TooltipIcon>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <h6 className="ml-2 mb-2 mt-3 css-traits-text-c">
                              Academic Results
                            </h6>
                            {currSchoolLevel === 'secondary school' && (
                              <div>
                                <div>GCE 'O' Level, {examyear}</div>
                              </div>
                            )}
                            {currSchoolLevel !== 'junior college/ip school' &&
                              currSchoolLevel !== 'polytechnic' &&
                              currSchoolLevel !== 'ib school' &&
                              currSchoolLevel !== 'secondary school' &&
                              currSchoolLevel !== 'nus high school' && (
                                <div className="mt-2 pt-1 ml-2 css-traits-text-c">
                                  There are no academic results
                                </div>
                              )}
                            {currSchoolLevel === 'junior college/ip school' && (
                              <div className="bx--row">
                                <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                  <div className="d-flex">
                                    <TooltipIcon
                                      tooltipText={<PSCPoints />}
                                      className="css-tooltip-fill">
                                      {' '}
                                      <div className=" csc-th--block css-traits-score d-flex justify-content-center align-items-center">
                                        <div className="csc-f-sz-14">
                                          {(psc70App &&
                                            Math.round(Number(psc70App))) ||
                                            0}
                                        </div>
                                      </div>
                                    </TooltipIcon>

                                    <div className="mt-1">
                                      <div className="csc-pos--top ml-1 ">
                                        <TooltipIcon
                                          tooltipText={<PSCPoints />}
                                          className="css-tooltip-fill">
                                          {' '}
                                          <span className="h6 css-traits-text-c">
                                            PSC Points
                                          </span>{' '}
                                        </TooltipIcon>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2 bx--col-lg-6 bx--col-md-4 pl-4 ml-3 ml-lg-0">
                                  <div className="d-flex">
                                    <div className=" csc-th--block css-traits-score d-flex justify-content-center align-items-center">
                                      <div className="">
                                        {gpGrade
                                          ? gpGrade === 'A1' || gpGrade === 'V'
                                            ? 'A'
                                            : gpGrade
                                          : 'NA'}
                                      </div>
                                    </div>
                                    <div className="">
                                      <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                        GP
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2 bx--col-lg-6 pl-4 bx--col-md-3">
                                  <div className="d-flex">
                                    <div className=" csc-th--block css-traits-score d-flex justify-content-center align-items-center">
                                      <div className="">
                                        {pwGrade
                                          ? pwGrade === 'A1' || pwGrade === 'V'
                                            ? 'A'
                                            : pwGrade
                                          : 'NA'}
                                      </div>
                                    </div>
                                    <div className="">
                                      <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                        PW
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {currSchoolLevel === 'polytechnic' && (
                              <div className="bx--row">
                                <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                  <div className="d-flex">
                                    <TooltipIcon
                                      tooltipText={<PolytechnicGPA />}
                                      className="css-tooltip-fill">
                                      {' '}
                                      <div
                                        className={` csc-th--block css-traits-score d-flex justify-content-center align-items-center`}>
                                        <div className="csc-f-sz-14">
                                          {(cumulativeGpa &&
                                            Math.round(cumulativeGpa)) ||
                                            0}
                                        </div>
                                      </div>
                                    </TooltipIcon>

                                    <div className="mt-1">
                                      <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                        <TooltipIcon
                                          tooltipText={<PolytechnicGPA />}
                                          className="css-tooltip-fill">
                                          {' '}
                                          <span className="h6 css-traits-text-c">
                                            GPA
                                          </span>{' '}
                                        </TooltipIcon>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0 pr-0">
                                  <div className=" text-left ml-2 css-traits-text-c">
                                    <div>
                                      {polyCourseName && (
                                        <Fragment>
                                          {polyCourseName &&
                                          polyCourseName.toString() &&
                                          polyCourseName
                                            .toString()
                                            .includes('Diploma in')
                                            ? ''
                                            : 'Diploma in'}{' '}
                                          {polyCourseName}{' '}
                                          {qualObtainedYear ? ',' : ''}{' '}
                                          {qualObtainedYear}
                                        </Fragment>
                                      )}
                                    </div>
                                    {examyear && (
                                      <div>GCE 'O' Level, {examyear}</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                            {currSchoolLevel === 'ib school' && (
                              <div className="bx--row">
                                <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                  <div className="d-flex align-items-center">
                                    <TooltipIcon
                                      tooltipText={<IBScore />}
                                      className="css-tooltip-fill">
                                      {' '}
                                      <div className=" csc-th--block css-traits-score d-flex justify-content-center align-items-center">
                                        <div className="csc-f-sz-14">
                                          {totalScore || 0}
                                        </div>
                                      </div>
                                    </TooltipIcon>

                                    <div className="mt-1">
                                      <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                        <TooltipIcon
                                          tooltipText={<IBScore />}
                                          className="css-tooltip-fill">
                                          {' '}
                                          <span className="h6 css-traits-text-c">
                                            IB Score
                                          </span>{' '}
                                        </TooltipIcon>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {currSchoolLevel === 'nus high school' && (
                              <div className="bx--row">
                                <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                  <div className="d-flex">
                                    <TooltipIcon
                                      tooltipText={<NUSCap />}
                                      className="css-tooltip-fill">
                                      {' '}
                                      <div className=" csc-th--block css-traits-score d-flex justify-content-center align-items-center">
                                        <div className="">{cap || 0}</div>
                                      </div>
                                    </TooltipIcon>

                                    <div className="mt-1">
                                      <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                        <TooltipIcon
                                          tooltipText={<NUSCap />}
                                          className="css-tooltip-fill">
                                          {' '}
                                          <span className="h6 css-traits-text-c">
                                            CAP
                                          </span>{' '}
                                        </TooltipIcon>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="mt-3 mb-2 pb-1 ml-2">
                              <div className="mt-2">
                                <h6 className="css-traits-text-c f-w-b">
                                  Top 3 Preferences
                                </h6>
                              </div>
                              <div className="mt-2 pt-1 bx--row">
                                <div className="bx--col-lg-4 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                  <div className="f-w-b css-default-text-color mb-2 pb-1">
                                    Career
                                  </div>
                                  {finalCareerPreferences &&
                                  Array.isArray(finalCareerPreferences) &&
                                  finalCareerPreferences.length > 0 ? (
                                    finalCareerPreferences.map((res, index) => {
                                      const { career } = res || {};
                                      const { displayName } = career || {};
                                      return (
                                        index <= 2 && (
                                          <div className="mt-1 css-traits-text-c d-flex">
                                            <div>{index + 1}.</div>{' '}
                                            <div className="ml-1">
                                              {displayName}
                                            </div>
                                          </div>
                                        )
                                      );
                                    })
                                  ) : (
                                    <div className="small css-traits-text-c">
                                      No Career Preferences
                                    </div>
                                  )}
                                </div>
                                <div className="bx--col-lg-4 bx--col-md-3 bx--col-sm-12 bx--col-xs-12">
                                  <div className="f-w-b css-default-text-color mb-2 pb-1">
                                    Course
                                  </div>
                                  {finalCoursePreferences &&
                                  Array.isArray(finalCoursePreferences) &&
                                  finalCoursePreferences.length > 0 ? (
                                    finalCoursePreferences.map(
                                      (courseRes, index) => {
                                        const { subject, otherCourseId } =
                                          courseRes || {};
                                        const { displayName, subject: course } =
                                          subject || {};
                                        const { displayName: otherCourse } =
                                          otherCourseId || {};
                                        let finalDisplayName = '';
                                        if (course === 'others') {
                                          finalDisplayName = otherCourse;
                                        } else {
                                          finalDisplayName = displayName;
                                        }
                                        return (
                                          index <= 2 && (
                                            <div className="mt-1 css-traits-text-c d-flex">
                                              <div>{index + 1}.</div>{' '}
                                              <div className="ml-1">
                                                {finalDisplayName}
                                              </div>
                                            </div>
                                          )
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="small css-traits-text-c">
                                      No Course Preferences
                                    </div>
                                  )}
                                </div>
                                <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-12 bx--col-xs-12">
                                  <div className="f-w-b css-default-text-color mb-2 pb-1">
                                    University
                                  </div>
                                  {finalUniversityPreferences &&
                                  Array.isArray(finalUniversityPreferences) &&
                                  finalUniversityPreferences.length > 0 ? (
                                    finalUniversityPreferences.map(
                                      (univ, index) => {
                                        const { company } = univ || {};
                                        const { displayName } = company || {};
                                        return (
                                          index <= 2 && (
                                            <div className="mt-1 css-traits-text-c d-flex">
                                              <div>{index + 1}.</div>{' '}
                                              <div className="ml-1">
                                                {displayName}
                                              </div>
                                            </div>
                                          )
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="small css-traits-text-c">
                                      No university preferences
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 ml-lg-0 ml-xl-0 ml-3 pl-2">
                            {/* <h6>Candidate Insights</h6> */}
                            <div className="mb-2 pb-1 mt-2 pt-1">
                              <div className="mb-3">
                                <CandidateInsights
                                  fromPanelIntvPage={true}
                                  scores={cognitiveNumericalSc}
                                  label="Cognitive Ability Scores (Percentile)"
                                  keys={[
                                    {
                                      key: 'numericalReasoning',
                                      label: 'Numerical Reasoning',
                                    },
                                    {
                                      key: 'criticalThinking',
                                      label: 'Critical Thinking',
                                    },
                                  ]}
                                />
                                {selectionYear > 2023 && (
                                  <div className="mt-2">
                                    <div className="d-flex align-items-center">
                                      <div
                                        className={`csc-th--block border-radius-50 color-gray d-flex justify-content-center align-items-center`}>
                                        <div className="">
                                          {uCogOverallScore ?? 'NA'}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="css-trait-top ml-1 h6">
                                          Problem Solving & Numeracy Skills
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="mb-3">
                                {selectionYear > 2023 ? (
                                  <Fragment>
                                    <div>
                                      <div
                                        className="font-weight-bold mb-2 mt-2"
                                        title="Leadership Enabling Preferences (LEAP)">
                                        LEAP Scores
                                      </div>
                                    </div>
                                    <div className="bx--row">
                                      {overallScores &&
                                        Array.isArray(overallScores) &&
                                        overallScores.length > 0 &&
                                        overallScores.map((res) => {
                                          const {
                                            label,
                                            value,
                                            colourCode,
                                            stenScore,
                                          } = res || {};

                                          return (
                                            <div className="bx--col-lg-3 bx--col-md-3">
                                              <div className="mt-2">
                                                <div className="d-flex align-items-center">
                                                  <div
                                                    className={`csc-th--block border-radius-50 color-gray d-flex justify-content-center align-items-center`}
                                                    style={{
                                                      background: colourCode,
                                                    }}>
                                                    <div className="">
                                                      {stenScore ?? 'NA'}
                                                    </div>
                                                  </div>
                                                  <div className="">
                                                    <div className="css-trait-top ml-1 h6 text-capitalize">
                                                      {label}{' '}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </Fragment>
                                ) : (
                                  <CandidateInsights
                                    fromPanelIntvPage={true}
                                    scores={fitScores}
                                    label="GBA Scores"
                                    hideFutureDrivenAward={
                                      roleId == 7 ||
                                      roleId == 8 ||
                                      roleId == 9 ||
                                      roleId == 10
                                    }
                                    keys={gbaKeys}
                                  />
                                )}
                              </div>

                              <div className="mb-3">
                                <CandidateInsights
                                  fromPanelIntvPage={true}
                                  scores={psychologicalScores}
                                  label="Psychological Interview Rating"
                                  keys={[
                                    {
                                      key: 'leadershipQualities',
                                      label: 'Leadership Qualities',
                                    },
                                    {
                                      key: 'intellectualQualities',
                                      label: 'Intellectual Qualities',
                                    },
                                    {
                                      key: 'characterAndValues',
                                      label: 'Character and Values',
                                    },
                                    {
                                      key: 'interpersonalSkills',
                                      label: 'Interpersonal Skills',
                                    },
                                    {
                                      key: 'resilience',
                                      label: 'Resilience',
                                    },
                                  ]}
                                />
                              </div>

                              <div className="mb-3">
                                {selectionYear <= 2023 && (
                                  <CandidateInsights
                                    fromPanelIntvPage={true}
                                    scores={neopirSc}
                                    label="NEO PI-R"
                                    keys={[
                                      {
                                        key: 'neuroticism',
                                        label: 'Neuroticism',
                                      },
                                      {
                                        key: 'extraversion',
                                        label: 'Extraversion',
                                      },
                                      {
                                        key: 'openness',
                                        label: 'Openness',
                                      },
                                      {
                                        key: 'agreeableness',
                                        label: 'Agreeableness',
                                      },
                                      {
                                        key: 'conscientiousness',
                                        label: 'conscientiousness',
                                      },
                                    ]}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  };
                })
              : []
          }
          headers={[
            {
              key: 'header_0',
              header: "Candidate's Information",
              className: 'w-25',
            },
            { key: 'header_1', header: 'Summary' },
          ]}
        />
      ) : (
        <NoContentBox
          hideTile
          message="There are no interviews for your current selection"
        />
      )}
      {count > 0 && (
        <Pagination
          onChange={handlePaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={activePage}
          totalItems={count}
        />
      )}
    </div>
  );
};

export default CandidateInterviewsList;
