import React, { Fragment } from 'react';
import GenericDataTable from '../common/GenericDataTable';
import NoContentBox from '../common/NoContentBox';
import { Button, Pagination, ToggleSmall } from 'carbon-components-react';
import moment from 'moment';

const TodaysAgendaInterviews = (props) => {
  const {
    handlePaginationChange,
    push,
    activePage,
    count,
    data,
    hideActionColumn,
    activeTimerModal,
    userId,
    roleId,
    handleToggleClick,
    propDate,
    recordType,
    timerObj,
  } = props || {};

  const headers = [
    {
      key: 'header_0',
      header: 'S/No',
    },
    {
      key: 'header_1',
      header: 'Name',
      className: hideActionColumn ? 'w-25' : '',
    },
    {
      key: 'header_2',
      header: 'Time of Interview',
      className: hideActionColumn ? 'w-25 px-0' : '',
    },
    {
      key: 'header_3',
      header: 'Interviewer',
      className: hideActionColumn ? 'w-25' : '',
    },
    {
      key: 'header_4',
      header: 'Scholarship Type',
      className: hideActionColumn ? 'w-25' : '',
    },
  ];
  if (hideActionColumn !== true) {
    if (roleId === 2 || roleId === 6) {
      headers.push({
        key: 'header_5',
        header: 'Timer',
      });
    }
    if (roleId == 6 || roleId == 1) {
      headers.push(
        {
          key: 'header_6',
          header: 'MINDEF',
        },
        {
          key: 'header_7',
          header: 'MHA',
        },
        {
          key: 'header_8',
          header: 'Guest',
        }
      );
    }
  }

  let currStatusArr = [];
  if (data && Array.isArray(data) && data.length > 0) {
    currStatusArr = data.filter((res) => {
      const { application } = res || {};
      const { calenderInterviewAction, psCalenderInterviewAction } =
        (application &&
          Array.isArray(application) &&
          application.length > 0 &&
          application[0]) ||
        {};
      if (
        calenderInterviewAction === 'ongoing' ||
        psCalenderInterviewAction === 'ongoing'
      ) {
        return true;
      }
      return false;
    });
  }
  const disableStartBtn =
    currStatusArr && Array.isArray(currStatusArr) && currStatusArr.length > 0;
  const currHost = window.location.host;
  let finalEnv = '';
  if (currHost.includes('localhost')) {
    finalEnv = 'local';
  } else if (currHost.includes('cscstag')) {
    finalEnv = 'staging';
  } else {
    finalEnv = 'live';
  }
  const timerData = localStorage.getItem(`timer-data-${finalEnv}`);
  const parsedData = timerData && JSON.parse(timerData);
  const {
    actionPerformedUserId: lsActionPerfUserId,
    startTime: lsStartTime,
    schloarshipType,
  } = parsedData || {};
  const {
    actionPerformedUserId: profInfoActionPerfUserId,
    pscStartTime,
    psStartTime,
    calenderInterviewAction,
  } = timerObj || {};

  // Set Start & End times based on the current interview type.
  let profInfoStartTime;
  if (schloarshipType === 'PSC') {
    profInfoStartTime = pscStartTime;
  } else if (schloarshipType === 'PS') {
    profInfoStartTime = psStartTime;
  } else {
    if (calenderInterviewAction !== 'completed')
      profInfoStartTime = pscStartTime;
    else profInfoStartTime = psStartTime;
  }

  // Disable Reset & Stop Btns based on the latest Start & End times from the API resp & LocalStorage
  let disableResetStopBtns = false;
  if (
    (profInfoStartTime &&
      lsStartTime &&
      moment(Number(profInfoStartTime)) > moment(Number(lsStartTime))) ||
    !lsStartTime
  ) {
    disableResetStopBtns = profInfoActionPerfUserId != userId;
  } else {
    disableResetStopBtns = lsActionPerfUserId != userId;
  }

  // compare the dates excluding the time.
  const finalPropDate = propDate && new Date(Number(propDate));
  const currDate = new Date();
  if (finalPropDate) {
    finalPropDate.setHours(0, 0, 0, 0);
  }
  currDate.setHours(0, 0, 0, 0);
  const showStartBtn =
    finalPropDate && finalPropDate.getTime() === currDate.getTime();

  const breakIdx =
    data &&
    Array.isArray(data) &&
    data.length > 0 &&
    data.findIndex((itm) => itm?.showBreak);

  return (
    <div>
      <GenericDataTable
        className=""
        tbodyClass="candidate-background-color"
        otherActions={[]}
        headers={headers}
        rows={
          data && Array.isArray(data) && data.length > 0
            ? data.map((appl, idx) => {
                const {
                  application,
                  mainInterviewer,
                  assignedRoles,
                  showBreak,
                  psMainInterviewer,
                  serialNo,
                  startTime,
                  endTime,
                  displayName: breakTimeTitle = 'BREAK',
                } = appl || {};
                const {
                  profile,
                  appId,
                  selectionYear,
                  calenderInterviewAction,
                  applicationNumber,
                  psCalenderInterviewAction,
                } =
                  (application &&
                    Array.isArray(application) &&
                    application.length > 0 &&
                    application[0]) ||
                  {};
                const { fullName } = profile || {};
                const { createdAt, selectionboard } =
                  (mainInterviewer &&
                    Array.isArray(mainInterviewer) &&
                    mainInterviewer.length > 0 &&
                    mainInterviewer[0]) ||
                  {};
                const { aliasName } = selectionboard || {};
                let scholarshipType = '-';
                if (applicationNumber) {
                  if (applicationNumber.includes('U')) {
                    scholarshipType = 'Undergraduate';
                  } else if (applicationNumber.includes('M')) {
                    scholarshipType = 'Master’s';
                  } else if (applicationNumber.includes('P')) {
                    scholarshipType = 'Mid-Term';
                  }
                }
                const {
                  selectionboard: psSelectionBoard,
                  createdAt: psCreatedAt,
                } =
                  (psMainInterviewer &&
                    Array.isArray(psMainInterviewer) &&
                    psMainInterviewer.length > 0 &&
                    psMainInterviewer[0]) ||
                  {};
                const { aliasName: psAliasName } = psSelectionBoard || {};

                let finalCalenderInterviewAction = calenderInterviewAction;
                if (recordType === 'PS') {
                  finalCalenderInterviewAction = psCalenderInterviewAction;
                }

                return {
                  isExpanded: true,
                  id: `row_${idx + 1}_${appId}`,
                  header_0: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        S/No:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {showBreak ? <div /> : serialNo}
                      </div>
                    </div>
                  ),
                  header_1:
                    showBreak === true ? (
                      <div />
                    ) : (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Name:
                        </div>
                        <div className="text-capitalize xpa-link f-w-b bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          <a
                            className="css-cand-name"
                            href={`/app/csc/candidate/${appId}/batch/${selectionYear}/status/personal-info`}>
                            {fullName}
                          </a>
                        </div>
                      </div>
                    ),
                  header_2:
                    showBreak === true ? (
                      <div className="f-w-b mt-1 text-center">
                        {recordType === 'PSC' ? (
                          'BREAK'
                        ) : (
                          <span className="text-capitalize">
                            {breakTimeTitle}
                          </span>
                        )}
                        :{' '}
                        {startTime
                          ? moment(startTime).format('h:mm a')
                          : '3:30 pm'}{' '}
                        -{' '}
                        {endTime ? moment(endTime).format('h:mm a') : '4:00 pm'}
                      </div>
                    ) : (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Time of Interview:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          {recordType === 'PSC' ? (
                            <div>
                              {createdAt
                                ? moment(createdAt).format('h:mm a')
                                : '-'}
                            </div>
                          ) : (
                            <div>
                              {psCreatedAt
                                ? moment(psCreatedAt).format('h:mm a')
                                : '-'}
                            </div>
                          )}
                        </div>
                      </div>
                    ),
                  header_3:
                    showBreak === true ? (
                      <div />
                    ) : (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Interviewer:
                        </div>
                        <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          {recordType === 'PSC' ? (
                            <div>{aliasName ? aliasName : '-'}</div>
                          ) : (
                            <div>{psAliasName ? psAliasName : '-'}</div>
                          )}
                        </div>
                      </div>
                    ),
                  header_4:
                    showBreak === true ? (
                      <div />
                    ) : (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Scholarship Type:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                          {scholarshipType}
                        </div>
                      </div>
                    ),
                  header_5: (roleId === 2 || roleId === 6) &&
                    hideActionColumn !== true && (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Timer
                        </div>
                        <div className="d-flex bx--col-lg-12 bx--col-md-8 bx--col-sm-6 bx--col-xs-6">
                          {roleId == 6 || roleId == 2 ? (
                            <Fragment>
                              {finalCalenderInterviewAction === 'completed' ? (
                                <div className="bx--tag bx--tag--green text-uppercase">
                                  {finalCalenderInterviewAction}
                                </div>
                              ) : (
                                <Fragment>
                                  {showStartBtn === true && (
                                    <div>
                                      <Button
                                        disabled={disableStartBtn}
                                        onClick={() =>
                                          activeTimerModal(
                                            appId,
                                            fullName,
                                            'start'
                                          )
                                        }
                                        size="small">
                                        Start
                                      </Button>
                                    </div>
                                  )}

                                  {finalCalenderInterviewAction ===
                                    'ongoing' && (
                                    <>
                                      <div className="ml-2">
                                        <Button
                                          onClick={() =>
                                            activeTimerModal(
                                              appId,
                                              fullName,
                                              'reset'
                                            )
                                          }
                                          disabled={disableResetStopBtns}
                                          kind="tertiary"
                                          size="small">
                                          Reset
                                        </Button>
                                      </div>
                                      <div className="ml-2">
                                        <Button
                                          onClick={() =>
                                            activeTimerModal(
                                              appId,
                                              fullName,
                                              'stop'
                                            )
                                          }
                                          disabled={disableResetStopBtns}
                                          kind="danger"
                                          size="small">
                                          Stop
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </Fragment>
                              )}
                            </Fragment>
                          ) : (
                            <div>
                              {finalCalenderInterviewAction ? (
                                <div
                                  className={`${
                                    finalCalenderInterviewAction === 'ongoing'
                                      ? 'bx--tag bx--tag--yellow'
                                      : null
                                  } ${
                                    finalCalenderInterviewAction === 'completed'
                                      ? 'bx--tag bx--tag--green'
                                      : null
                                  }`}>
                                  {finalCalenderInterviewAction}
                                </div>
                              ) : (
                                '-'
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ),
                  header_6: (roleId === 6 || roleId === 1) && (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        MINDEF:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        <ToggleSmall
                          toggled={
                            assignedRoles &&
                            Array.isArray(assignedRoles) &&
                            assignedRoles.length > 0
                              ? assignedRoles.includes(9)
                              : false
                          }
                          id={`mindef-${appId}`}
                          labelA="No"
                          labelText=""
                          className="toggle-remv-margin"
                          onToggle={() =>
                            handleToggleClick(
                              9,
                              appId,
                              !(assignedRoles &&
                              Array.isArray(assignedRoles) &&
                              assignedRoles.length > 0
                                ? assignedRoles.includes(9)
                                : false)
                            )
                          }
                          labelB="Yes"
                        />
                      </div>
                    </div>
                  ),
                  header_7: (roleId === 6 || roleId === 1) && (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        MHA:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        <ToggleSmall
                          toggled={
                            assignedRoles &&
                            Array.isArray(assignedRoles) &&
                            assignedRoles.length > 0
                              ? assignedRoles.includes(8)
                              : false
                          }
                          labelA="No"
                          className="toggle-remv-margin"
                          labelText=""
                          onToggle={() =>
                            handleToggleClick(
                              8,
                              appId,
                              !(assignedRoles &&
                              Array.isArray(assignedRoles) &&
                              assignedRoles.length > 0
                                ? assignedRoles.includes(8)
                                : false)
                            )
                          }
                          id={`mha-${appId}`}
                          labelB="Yes"
                        />
                      </div>
                    </div>
                  ),
                  header_8: (roleId === 6 || roleId === 1) && (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Guest:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        <ToggleSmall
                          toggled={
                            assignedRoles &&
                            Array.isArray(assignedRoles) &&
                            assignedRoles.length > 0
                              ? assignedRoles.includes(10)
                              : false
                          }
                          labelA="No"
                          id={`guest-${appId}`}
                          className="toggle-remv-margin"
                          labelText=""
                          onToggle={() =>
                            handleToggleClick(
                              10,
                              appId,
                              !(assignedRoles &&
                              Array.isArray(assignedRoles) &&
                              assignedRoles.length > 0
                                ? assignedRoles.includes(10)
                                : false)
                            )
                          }
                          labelB="Yes"
                        />
                      </div>
                    </div>
                  ),
                };
              })
            : []
        }
        selectable={false}
        searchable={false}
        onSearchInputChange={() => {}}
        expandable={false}
        batchActions={false}
      />
      {count > 0 && (
        <Pagination
          onChange={handlePaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={activePage}
          totalItems={count}
        />
      )}
      {((data && Array.isArray(data) && data.length <= 0) || !data) && (
        <NoContentBox message="There are no interviews available for your current selection" />
      )}
    </div>
  );
};

export default TodaysAgendaInterviews;
