import {
  REQUEST_CANDIDATE_REPORT_EMAILS,
  SUCCESS_CANDIDATE_REPORT_EMAILS,
  ERROR_CANDIDATE_REPORT_EMAILS,
  REQUEST_PSC_PEER_FEEDBACK_EMAILS,
  SUCCESS_PSC_PEER_FEEDBACK_EMAILS,
  ERROR_PSC_PEER_FEEDBACK_EMAILS,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
};

const Emails = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_CANDIDATE_REPORT_EMAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CANDIDATE_REPORT_EMAILS:
      return {
        ...state,
        loading: false,
      };
    case ERROR_CANDIDATE_REPORT_EMAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_PSC_PEER_FEEDBACK_EMAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_PSC_PEER_FEEDBACK_EMAILS:
      return {
        ...state,
        loading: false,
      };
    case ERROR_PSC_PEER_FEEDBACK_EMAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default Emails;
