import { Select, SelectItem } from 'carbon-components-react';
import _ from 'lodash';

export const SelectionYearDropdown = ({
  currentYear,
  selectionYear,
  mainClassName,
  titleClassName,
  handleChange,
  showAllSelectionYears = false,
}) => {
  return (
    <div className={mainClassName}>
      <Select
        name="selectionYear"
        id="selectionYear"
        onChange={handleChange}
        className={titleClassName}
        labelText="Selection Year"
        value={selectionYear}>
        <SelectItem text="Select" disabled key="0" />
        {showAllSelectionYears ? (
          <>
            {_.range(2020, currentYear + 1).map((year, idx) => {
              return (
                <SelectItem
                  text={`Selection Year ${year}`}
                  key={year}
                  id={year}
                  value={year}
                />
              );
            })}
          </>
        ) : (
          <>
            {_.range(currentYear, currentYear + 2).map((year, idx) => {
              return (
                <SelectItem
                  text={`For ${year} selection year (for award by June ${year})`}
                  key={year}
                  id={year}
                  value={year}
                />
              );
            })}
          </>
        )}
      </Select>
    </div>
  );
};
