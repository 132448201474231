import React, { Fragment } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import './CarbonGraphsStyles.css';
import { axisTop } from 'd3';

const getColor = (bar, barColors) => {
  const { id } = bar || {};
  return barColors[id];
};

const NivoBarChart = (props) => {
  const colors = [
    '#118DFF',
    '#33ae81',
    '#f6b44a',
    '#DC5B57',
    '#33AE81',
    '#95C8F0',
    '#DD915F',
    '#9A64A0',
    '#FD625E',
    '#F2C80F',
    '#5F6B6D',
    '#8AD4EB',
    '#FE9666',
    '#A66999',
    '#3599B8',
    '#DFBFBF',
    '#01B8AA',
    '#374649',
    '#4e79a7',
    '#f28e2b',
    '#e15759',
    '#76b7b2',
    '#59a14e',
    '#edc949',
    '#b07aa2',
    '#ff9da7',
    '#9c755f',
    '#bab0ac',
  ];
  const {
    heading,
    data,
    keys,
    tickRotation,
    margin,
    legendDirection,
    axisLeft,
    axisBottom,
    axisRight,
    axisTop,
    showLegend,
    layout = 'vertical',
    padding = 0.5,
    innerPadding = 2,
    showSubMessage,
    message,
    userColors = null,
    groupMode = 'grouped',
    enableLabel = false,
    maxValue = 'auto',
    minValue = 'auto',
    subHeaderLegend,
    enableGridX = false,
    enableGridY = true,
    targetNeg = false,
    showDefModal = false,
    handleBarOnClick,
    enableTooltipKey = true,
  } = props;
  let finalColors = [];
  if (heading === 'By Scholarship Scheme') {
    finalColors = ['#4C5D8A', '#4A8DDC', '#33ae81', '#f6b44a', '#DC5B57'];
  } else if (heading === 'By Country') {
    finalColors = ['#4C5D8A', '#4A8DDC'];
  } else if (heading === 'By Course of Study') {
    finalColors = ['#4A8DDC', '#DD915F', '#9A64A0'];
  } else if (
    heading === 'By Offered Country (Others)' ||
    heading === 'By School'
  ) {
    finalColors = [
      '#4A8DDC',
      '#12239E',
      '#E66C37',
      '#6B007B',
      '#E044A7',
      '#744EC2',
      '#D9B300',
      '#D64550',
    ];
  } else {
    finalColors = ['#6c6c93', '#4A8DDC', '#9c755f', '#bab0ac'];
  }
  const barColors = {};
  if (keys && Array.isArray(keys) && keys.length > 0) {
    keys.map((res, idx) => {
      if (
        userColors &&
        res !== 'Concentration' &&
        res !== 'Resilience' &&
        res !== 'Sensitivity to reward' &&
        res !== 'Sensitivity To Reward' &&
        res !== 'Self-discipline' &&
        res !== 'Self Discipline' &&
        res !== 'Proactivity' &&
        res !== 'Creativity' &&
        res !== 'Curiosity' &&
        res !== 'Novelty Seeking' &&
        res !== 'Novelty Seeking' &&
        res !== 'Optimism' &&
        res !== 'Altruism' &&
        res !== 'Authenticity' &&
        res !== 'Self-Belief' &&
        res !== 'Self-Belief' &&
        res !== 'Emotion Recognition' &&
        res !== 'Deliberation'
      ) {
        barColors[res] = '#00008b9e';
      } else if (
        res === 'Concentration' ||
        res === 'Resilience' ||
        res === 'Sensitivity to reward' ||
        res === 'Sensitivity To Reward' ||
        res === 'Self-discipline' ||
        res === 'Self Discipline' ||
        res === 'Proactivity' ||
        res === 'Creativity' ||
        res === 'Curiosity' ||
        res === 'Novelty Seeking' ||
        res === 'Novelty Seeking' ||
        res === 'Optimism' ||
        res === 'Altruism' ||
        res === 'Authenticity' ||
        res === 'Self-Belief' ||
        res === 'Self-Belief' ||
        res === 'Emotion Recognition' ||
        res === 'Deliberation'
      ) {
        barColors[res] = 'darkgray';
      } else if (targetNeg === true ? res !== 'Teaching Service' : true) {
        barColors[res] = finalColors[idx];
      } else {
        barColors[res] = 'green';
      }
    });
  }
  const barKeys = barColors && Object.keys(barColors);
  let finalData = [];
  if (data && Array.isArray(data) && data.length > 0) {
    finalData = data.map((res, idx) => {
      const {} = res || {};
      const obj = {
        ...res,
        color: finalColors[idx],
      };
      return obj;
    });
  }
  return (
    <Fragment>
      <h4 className="text-capitalize">{heading}</h4>
      {showSubMessage === true && (
        <div className="f-w-b mb-2 mt-2">{message}</div>
      )}
      {showLegend === true && (
        <div className="bx--row ml-0 mt-2 font-size-l">
          {barKeys &&
            Array.isArray(barKeys) &&
            barKeys.length > 0 &&
            barKeys.map((res) => {
              return (
                <div className="d-flex align-items-center mr-2 mb-2">
                  <div
                    style={{ backgroundColor: barColors[res] }}
                    className="legend-box"
                  />
                  <div className="small ml-1 text-capitalize">
                    {res} {subHeaderLegend ? subHeaderLegend : null}{' '}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <ResponsiveBar
        data={finalData}
        keys={keys}
        groupMode={groupMode}
        indexBy="key"
        margin={margin}
        padding={padding}
        maxValue={maxValue}
        minValue={minValue}
        innerPadding={1}
        colors={
          !targetNeg
            ? (bar) => getColor(bar, barColors)
            : (d) =>
                d && d.data && d.data.key === 'Teaching Service'
                  ? 'green'
                  : '#4A8DDC'
        }
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={axisTop}
        axisRight={axisRight}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        enableLabel={enableLabel}
        label="value"
        tooltip={(tooltipObj) => {
          const { id, value, color, indexValue } = tooltipObj || {};
          return (
            <div className="d-flex align-items-center">
              <div style={{ background: color }} className="tooltip-box" />
              <div className="ml-2">
                {enableTooltipKey !== false && (
                  <span className="text-capitalize f-w-b">{indexValue} - </span>
                )}
                <span className="text-capitalize">{id}</span>:{' '}
                <span className="f-w-b">{value}</span>
              </div>
            </div>
          );
        }}
        enableGridX={enableGridX}
        enableGridY={enableGridY}
        labelTextColor="white"
        labelSkipWidth={12}
        onClick={
          showDefModal === true
            ? (data) => handleBarOnClick(data && data.data, data)
            : () => {}
        }
        layout={layout}
        labelSkipHeight={12}
        legends={[]}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
      />
    </Fragment>
  );
};

export default NivoBarChart;
