import {
  REQUEST_GET_SELECTION_BOARD_USERS,
  SUCCESS_GET_SELECTION_BOARD_USERS,
  ERROR_GET_SELECTION_BOARD_USERS,
  REQUEST_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE,
  SUCCESS_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE,
  ERROR_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE,
  REQUEST_GET_ASSIGNED_PANEL_INTERVIEWERS,
  SUCCESS_GET_ASSIGNED_PANEL_INTERVIEWERS,
  ERROR_GET_ASSIGNED_PANEL_INTERVIEWERS,
  REQUEST_ASSIGN_BOARD_USERS_FOR_A_DATE,
  SUCCESS_ASSIGN_BOARD_USERS_FOR_A_DATE,
  ERROR_ASSIGN_BOARD_USERS_FOR_A_DATE,
  REQUEST_ASSIGN_CANDIDATES_TO_OTHER_ROLES,
  SUCCESS_ASSIGN_CANDIDATES_TO_OTHER_ROLES,
  ERROR_ASSIGN_CANDIDATES_TO_OTHER_ROLES,
  REQUEST_GET_NEXT_INTERVIEW_DATE,
  SUCCESS_GET_NEXT_INTERVIEW_DATE,
  ERROR_GET_NEXT_INTERVIEW_DATE,
  REQUEST_CREATE_BREAK_TIME,
  SUCCESS_CREATE_BREAK_TIME,
  ERROR_CREATE_BREAK_TIME,
  REQUEST_DELETE_BREAK_TIME,
  SUCCESS_DELETE_BREAK_TIME,
  ERROR_DELETE_BREAK_TIME,
  REQUEST_GET_BREAK_TIME,
  SUCCESS_GET_BREAK_TIME,
  ERROR_GET_BREAK_TIME,
  REQUEST_UPDATE_TIMER_FOR_APPLS,
  SUCCESS_UPDATE_TIMER_FOR_APPLS,
  ERROR_UPDATE_TIMER_FOR_APPLS,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  panelInterviewers: [],
  mainInterviewer: {},
  selectionBoardUsers: [],
};

const CalendarSelectionBoard = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_UPDATE_TIMER_FOR_APPLS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_TIMER_FOR_APPLS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPDATE_TIMER_FOR_APPLS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_BREAK_TIME:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_BREAK_TIME:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_BREAK_TIME:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_CREATE_BREAK_TIME:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_BREAK_TIME:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_BREAK_TIME:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_BREAK_TIME:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_BREAK_TIME:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_BREAK_TIME:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_NEXT_INTERVIEW_DATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_NEXT_INTERVIEW_DATE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_NEXT_INTERVIEW_DATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_SELECTION_BOARD_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_SELECTION_BOARD_USERS:
      return {
        ...state,
        panelInterviewers: payload && payload.list,
        loading: false,
        error: null,
      };
    case ERROR_GET_SELECTION_BOARD_USERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSIGNED_PANEL_INTERVIEWERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSIGNED_PANEL_INTERVIEWERS:
      return {
        ...state,
        mainInterviewer: payload && payload.mainInterviewer,
        selectionBoardUsers:
          payload && payload.selectionBoard && payload.selectionBoard.list,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSIGNED_PANEL_INTERVIEWERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_ASSIGN_BOARD_USERS_FOR_A_DATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ASSIGN_BOARD_USERS_FOR_A_DATE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ASSIGN_BOARD_USERS_FOR_A_DATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_ASSIGN_CANDIDATES_TO_OTHER_ROLES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ASSIGN_CANDIDATES_TO_OTHER_ROLES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ASSIGN_CANDIDATES_TO_OTHER_ROLES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default CalendarSelectionBoard;
