import React, { Component } from 'react';
import { connect } from 'react-redux';

//Api Actions
import { getPsacandPeerAppraisalDataById } from '../../actions/CandidateInfo';

//Functional Components
import NivoBarChart from '../../components/common/NivoBarChart';
import queryString from 'query-string';
//CSS Files
import './CandidatePeerAppraisalReport.css';

class CandidatePeerAppraisalReport extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const parsedQuery = queryString.parse(this.props.location.search);
    const { avgVals, lsVals, isVals, cvVals, stVals, intVals } =
      parsedQuery || {};
    const avgValsArr =
      avgVals &&
      decodeURIComponent(avgVals) &&
      decodeURIComponent(avgVals).split(',');
    const lsValsArr =
      lsVals &&
      decodeURIComponent(lsVals) &&
      decodeURIComponent(lsVals).split(',');
    const intValsArr =
      intVals &&
      decodeURIComponent(intVals) &&
      decodeURIComponent(intVals).split(',');
    const isValsArr =
      isVals &&
      decodeURIComponent(isVals) &&
      decodeURIComponent(isVals).split(',');
    const cvValsArr =
      cvVals &&
      decodeURIComponent(cvVals) &&
      decodeURIComponent(cvVals).split(',');
    const stValsArr =
      stVals &&
      decodeURIComponent(stVals) &&
      decodeURIComponent(stVals).split(',');
    let avgCohort = 0;
    let cvCohort = 0;
    let iqCohort = 0;
    let isCohort = 0;
    let lsCohort = 0;
    let stCohort = 0;
    let avgPeer = 0;
    let avgSelf = 0;

    let ioPeer = 0;
    let iqSelf = 0;

    let lsPeer = 0;
    let lsSelf = 0;

    let isPeer = 0;
    let isSelf = 0;

    let cvPeer = 0;
    let cvSelf = 0;

    let stPeer = 0;
    let stSelf = 0;
    if (avgValsArr && Array.isArray(avgValsArr) && avgValsArr.length > 0) {
      avgSelf = avgValsArr[0];
      avgPeer = avgValsArr[1];
      avgCohort = avgValsArr[2];
    }

    if (lsValsArr && Array.isArray(lsValsArr) && lsValsArr.length > 0) {
      lsSelf = lsValsArr[0];
      lsPeer = lsValsArr[1];
      lsCohort = lsValsArr[2];
    }

    if (intValsArr && Array.isArray(intValsArr) && intValsArr.length > 0) {
      iqSelf = intValsArr[0];
      ioPeer = intValsArr[1];
      iqCohort = intValsArr[2];
    }

    if (isValsArr && Array.isArray(isValsArr) && isValsArr.length > 0) {
      isSelf = isValsArr[0];
      isPeer = isValsArr[1];
      isCohort = isValsArr[2];
    }

    if (cvValsArr && Array.isArray(cvValsArr) && cvValsArr.length > 0) {
      cvSelf = cvValsArr[0];
      cvPeer = cvValsArr[1];
      cvCohort = cvValsArr[2];
    }

    if (stValsArr && Array.isArray(stValsArr) && stValsArr.length > 0) {
      stSelf = stValsArr[0];
      stPeer = stValsArr[1];
      stCohort = stValsArr[2];
    }

    this.setState({
      avgSelf,
      avgPeer,
      avgCohort,
      lsSelf,
      lsPeer,
      lsCohort,
      iqSelf,
      ioPeer,
      iqCohort,
      isSelf,
      isPeer,
      isCohort,
      cvSelf,
      cvPeer,
      cvCohort,
      stSelf,
      stPeer,
      stCohort,
    });
  }

  render() {
    const {
      avgSelf,
      avgPeer,
      avgCohort,
      lsSelf,
      lsPeer,
      lsCohort,
      iqSelf,
      ioPeer,
      iqCohort,
      isSelf,
      isPeer,
      isCohort,
      cvSelf,
      cvPeer,
      cvCohort,
      stSelf,
      stPeer,
      stCohort,
    } = this.state || {};
    return (
      <div
        id="peer-appraisal-report"
        className="csc-peer-app-g-w bx--tile mt-1">
        <div
          style={{ border: '2px solid black' }}
          className="bx--row align-items-center ml-0 mr-0">
          <div
            style={{ borderRight: '2px solid black', height: 130 }}
            className="bx--col-lg-6 d-flex justify-content-center align-items-center">
            <div>
              <div className="font-weight-bold mb-2 csc-main-headings">
                Overall Mean Ratings
              </div>
              <div className="mb-2 csc-sub-headings">
                Averaged across all 5 qualities
              </div>
              <div className="csc-sub-headings">(maximum rating = 6.0)</div>
            </div>
          </div>
          <div className="bx--col-lg-6" style={{ height: 130 }}>
            <NivoBarChart
              heading=""
              data={[
                {
                  key: 'Self',
                  Self: (avgSelf && Number(avgSelf).toFixed(1)) || 0,
                },
                {
                  key: 'Peer',
                  Peer: (avgPeer && Number(avgPeer).toFixed(1)) || 0,
                },
                {
                  key: 'Cohort',
                  Cohort: (avgCohort && Number(avgCohort).toFixed(1)) || 0,
                },
              ].reverse()}
              margin={{
                top: 10,
                right: 20,
                bottom: 10,
                left: 50,
              }}
              keys={['Self', 'Peer', 'Cohort']}
              legendDirection="bottom-left"
              enableGridY={false}
              axisTop={null}
              padding={0.2}
              maxValue={6}
              enableLabel
              groupMode="stacked"
              innerPadding={0}
              showLegend={false}
              axisRight={null}
              axisBottom={null}
              layout="horizontal"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
            />
          </div>
        </div>

        <div
          className="d-flex align-items-center justify-content-center mt-4 "
          style={{
            border: '2px solid black',
            borderBottom: '0px',
            height: 40,
          }}>
          <div className="csc-main-headings">
            <span className="f-w-b">Mean Ratings of Individual Qualities</span>{' '}
            (maximum rating = 6.0)
          </div>
        </div>
        <div
          style={{ border: '2px solid black', borderBottom: '0px' }}
          className="bx--row align-items-center ml-0 mr-0">
          <div
            style={{ borderRight: '2px solid black', height: 130 }}
            className="bx--col-lg-6 d-flex">
            <div className="bx--row justify-content-between mt-3">
              <div className="f-w-b csc-graph-content pr-0 bx--col-lg-1">
                I.
              </div>
              <div className="bx--col-lg-11 pl-0">
                <div class="mb-2 csc-main-headings">Leadership</div>
                <div
                  style={{ lineHeight: 1.2 }}
                  className="mb-2 text-justify csc-graph-content">
                  The manner and approach in which you take charge of
                  situations, provide directions, implement plans, and motivate
                  others.
                </div>
              </div>
            </div>
          </div>
          <div className="bx--col-lg-6" style={{ height: 130 }}>
            <NivoBarChart
              heading=""
              data={[
                {
                  key: 'Self',
                  Self: (lsSelf && Number(lsSelf).toFixed(1)) || 0,
                },
                {
                  key: 'Peer',
                  Peer: (lsPeer && Number(lsPeer).toFixed(1)) || 0,
                },
                {
                  key: 'Cohort',
                  Cohort: (lsCohort && Number(lsCohort).toFixed(1)) || 0,
                },
              ].reverse()}
              margin={{
                top: 10,
                right: 20,
                bottom: 10,
                left: 50,
              }}
              keys={['Self', 'Peer', 'Cohort']}
              legendDirection="bottom-left"
              enableGridY={false}
              axisTop={null}
              padding={0.2}
              maxValue={6}
              enableLabel
              groupMode="stacked"
              innerPadding={0}
              showLegend={false}
              axisRight={null}
              axisBottom={null}
              layout="horizontal"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
            />
          </div>
        </div>
        <div
          style={{ border: '2px solid black', borderBottom: '0px' }}
          className="bx--row align-items-center ml-0 mr-0">
          <div
            style={{ borderRight: '2px solid black', height: 130 }}
            className="bx--col-lg-6 d-flex">
            <div className="bx--row justify-content-between mt-3">
              <div className="f-w-b csc-graph-content pr-0 bx--col-lg-1">
                II.
              </div>
              <div className="bx--col-lg-11 pl-0">
                <div class="mb-2 csc-main-headings">Intellectual Qualities</div>
                <div
                  style={{ lineHeight: 1.2 }}
                  className="mb-2 text-justify csc-graph-content">
                  The manner and approach in which you learn new skills and
                  analyse problems to aid decision making and develop solutions.
                </div>
              </div>
            </div>
          </div>
          <div className="bx--col-lg-6" style={{ height: 130 }}>
            <NivoBarChart
              heading=""
              data={[
                {
                  key: 'Self',
                  Self: (iqSelf && Number(iqSelf).toFixed(1)) || 0,
                },
                {
                  key: 'Peer',
                  Peer: (ioPeer && Number(ioPeer).toFixed(1)) || 0,
                },
                {
                  key: 'Cohort',
                  Cohort: (iqCohort && Number(iqCohort).toFixed(1)) || 0,
                },
              ].reverse()}
              margin={{
                top: 10,
                right: 20,
                bottom: 10,
                left: 50,
              }}
              keys={['Self', 'Peer', 'Cohort']}
              legendDirection="bottom-left"
              enableGridY={false}
              axisTop={null}
              padding={0.2}
              maxValue={6}
              enableLabel
              groupMode="stacked"
              innerPadding={0}
              showLegend={false}
              axisRight={null}
              axisBottom={null}
              layout="horizontal"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
            />
          </div>
        </div>
        <div
          style={{ border: '2px solid black', borderBottom: '0px' }}
          className="bx--row align-items-center ml-0 mr-0">
          <div
            style={{ borderRight: '2px solid black', height: 130 }}
            className="bx--col-lg-6 d-flex">
            <div className="bx--row justify-content-between mt-3">
              <div className="f-w-b csc-graph-content pr-0 bx--col-lg-1">
                III.
              </div>
              <div className="bx--col-lg-11 pl-0">
                <div class="mb-2 csc-main-headings">Interpersonal Skills</div>
                <div
                  style={{ lineHeight: 1.2 }}
                  className="mb-2 text-justify csc-graph-content">
                  The manner and approach in which you communicate, build
                  relationships, and collaborate with others.
                </div>
              </div>
            </div>
          </div>
          <div className="bx--col-lg-6" style={{ height: 130 }}>
            <NivoBarChart
              heading=""
              data={[
                {
                  key: 'Self',
                  Self: (isSelf && Number(isSelf).toFixed(1)) || 0,
                },
                {
                  key: 'Peer',
                  Peer: (isPeer && Number(isPeer).toFixed(1)) || 0,
                },
                {
                  key: 'Cohort',
                  Cohort: (isCohort && Number(isCohort).toFixed(1)) || 0,
                },
              ].reverse()}
              margin={{
                top: 10,
                right: 20,
                bottom: 10,
                left: 50,
              }}
              keys={['Self', 'Peer', 'Cohort']}
              legendDirection="bottom-left"
              enableGridY={false}
              axisTop={null}
              padding={0.2}
              maxValue={6}
              enableLabel
              groupMode="stacked"
              innerPadding={0}
              showLegend={false}
              axisRight={null}
              axisBottom={null}
              layout="horizontal"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
            />
          </div>
        </div>
        <div
          style={{ border: '2px solid black', borderBottom: '0px' }}
          className="bx--row align-items-center ml-0 mr-0">
          <div
            style={{ borderRight: '2px solid black', height: 130 }}
            className="bx--col-lg-6 d-flex">
            <div className="bx--row justify-content-between mt-3">
              <div className="f-w-b csc-graph-content pr-0 bx--col-lg-1">
                IV.
              </div>
              <div className="bx--col-lg-11 pl-0">
                <div class="mb-2 csc-main-headings">Character & Values</div>
                <div
                  style={{ lineHeight: 1.2 }}
                  className="mb-2 text-justify csc-graph-content">
                  The manner and approach which you adopt towards achieving
                  goals and producing quality outcomes.
                </div>
              </div>
            </div>
          </div>
          <div className="bx--col-lg-6" style={{ height: 130 }}>
            <NivoBarChart
              heading=""
              data={[
                {
                  key: 'Self',
                  Self: (cvSelf && Number(cvSelf).toFixed(1)) || 0,
                },
                {
                  key: 'Peer',
                  Peer: (cvPeer && Number(cvPeer).toFixed(1)) || 0,
                },
                {
                  key: 'Cohort',
                  Cohort: (cvCohort && Number(cvCohort).toFixed(1)) || 0,
                },
              ].reverse()}
              margin={{
                top: 10,
                right: 20,
                bottom: 10,
                left: 50,
              }}
              keys={['Self', 'Peer', 'Cohort']}
              legendDirection="bottom-left"
              enableGridY={false}
              axisTop={null}
              padding={0.2}
              maxValue={6}
              enableLabel
              groupMode="stacked"
              innerPadding={0}
              showLegend={false}
              axisRight={null}
              axisBottom={null}
              layout="horizontal"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
            />
          </div>
        </div>
        <div
          style={{ border: '2px solid black' }}
          className="bx--row align-items-center ml-0 mr-0">
          <div
            style={{ borderRight: '2px solid black', height: 130 }}
            className="bx--col-lg-6 d-flex">
            <div className="bx--row justify-content-between mt-3">
              <div className="f-w-b csc-graph-content pr-0 bx--col-lg-1">
                V.
              </div>
              <div className="bx--col-lg-11 pl-0">
                <div class="mb-2 csc-main-headings">
                  Stress Tolerance & Coping Ability
                </div>
                <div
                  style={{ lineHeight: 1.2 }}
                  className="mb-2 text-justify csc-graph-content">
                  The manner and approach in which you deal with challenges and
                  perform under difficult situations.
                </div>
              </div>
            </div>
          </div>
          <div className="bx--col-lg-6" style={{ height: 130 }}>
            <NivoBarChart
              heading=""
              data={[
                {
                  key: 'Self',
                  Self: (stSelf && Number(stSelf).toFixed(1)) || 0,
                },
                {
                  key: 'Peer',
                  Peer: (stPeer && Number(stPeer).toFixed(1)) || 0,
                },
                {
                  key: 'Cohort',
                  Cohort: (stCohort && Number(stCohort).toFixed(1)) || 0,
                },
              ].reverse()}
              margin={{
                top: 10,
                right: 20,
                bottom: 10,
                left: 50,
              }}
              keys={['Self', 'Peer', 'Cohort']}
              legendDirection="bottom-left"
              enableGridY={false}
              axisTop={null}
              padding={0.2}
              maxValue={6}
              enableLabel
              groupMode="stacked"
              innerPadding={0}
              showLegend={false}
              axisRight={null}
              axisBottom={null}
              layout="horizontal"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  psSummaryData: state.CandidateInfo.psSummaryData,
});

const mapDispatchToProps = {
  getPsacandPeerAppraisalDataById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidatePeerAppraisalReport);
