import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal, Pagination } from 'carbon-components-react';
import {
  getAssessmentTypes,
  getScorecardTemplates,
  deletScorecardTemplateById,
  createScorecardTemplate,
} from '../../actions/FeedbackTemplates';
import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import AddEditFeedbackTemplate from './AddEditFeedbackTemplate';
import BackRow from '../../components/common/BackRow';
import SuccErrNotification from '../../components/private/SuccErrNotification';

const feedbackOptionConfig = [
  { label: 'GBA', value: 'gba' },
  {
    label: 'PSC Application Process',
    value: 'psc-application-process',
  },
  { label: 'Custom Feedback', value: 'custom-feedback' },
];

const ScorecardTemplateTable = ({
  count,
  activePage,
  itemsPerPage,
  push,
  scorecardtemplates,
  otherActionsBtn,
  columnWithFilter,
  handlePaginationChange,
  activeDeleteScorecardModal,
  activeAddScorecardModal,
}) => {
  return (
    <Fragment>
      <GenericDataTable
        title=""
        otherActions={otherActionsBtn}
        rowClass="p-0"
        headers={columnWithFilter.map((data, index) => ({
          key: `header_${index}`,
          header: data.title,
          className: data.className,
          colpropName: data.propName,
        }))}
        rows={
          scorecardtemplates &&
          Array.isArray(scorecardtemplates) &&
          scorecardtemplates.length > 0
            ? scorecardtemplates.map((data, idx) => {
                const {
                  scorecardTemplateId,
                  scorecardTemplateName,
                  isCustomFeedback,
                  Test,
                  feedbackType,
                } = data || {};
                const { testId, displayName: testDisplayName } = Test || {};
                const selectedFeedbackType = feedbackOptionConfig.find(
                  (itm) => itm?.value === feedbackType
                );
                const { label: feedbackTypeDisplayName } =
                  selectedFeedbackType || {};
                return {
                  isExpanded: false,
                  id: scorecardTemplateId,
                  header_0: (
                    <div>
                      <div className="bx--row">
                        <div className="bx--col-lg-12 font-weight-bold xpa-name-overflow">
                          <h4
                            title={scorecardTemplateName}
                            onClick={() => {
                              push &&
                                push(
                                  `/app/csc/admin/feedback/${scorecardTemplateId}/groups`
                                );
                            }}
                            className="xpa-link">
                            {scorecardTemplateName &&
                            scorecardTemplateName.toString().length > 40
                              ? `${scorecardTemplateName.substring(0, 40)}...`
                              : scorecardTemplateName}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ),
                  header_1: (
                    <div className="d-flex flex-row align-start">
                      {feedbackTypeDisplayName || '-'}
                    </div>
                  ),
                  header_2: (
                    <div className="d-flex flex-row align-start">
                      <Button
                        kind="primary"
                        onClick={() =>
                          activeAddScorecardModal(
                            scorecardTemplateId,
                            scorecardTemplateName,
                            testId,
                            testDisplayName,
                            isCustomFeedback,
                            selectedFeedbackType
                          )
                        }
                        size="small">
                        Edit
                      </Button>
                      <Button
                        kind="danger"
                        className="ml-3"
                        onClick={() =>
                          activeDeleteScorecardModal(scorecardTemplateId)
                        }
                        size="small">
                        Delete
                      </Button>
                    </div>
                  ),
                };
              })
            : []
        }
        searchable={false}
        selectable={false}
        expandable={false}
        customSorting={() => {}}
        batchActions={false}
      />
      {scorecardtemplates &&
        Array.isArray(scorecardtemplates) &&
        scorecardtemplates.length > 0 && (
          <Pagination
            onChange={handlePaginationChange}
            pageSizes={[10, 20, 30, 40, 50]}
            page={activePage}
            totalItems={count}
          />
        )}
      {scorecardtemplates && scorecardtemplates.length <= 0 && (
        <NoContentBox message="There are no feedback templates currently associated." />
      )}
    </Fragment>
  );
};

class FeedbackTemplates extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'feeback-templates', name: 'Feedback Templates', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      isOpenDeleteModal: false,
      scorecardTemplateId: 0,
      notifMsg: '',
      showSuccessNotif: false,
    };
  }

  componentDidMount() {
    this.props.getAssessmentTypes();
    this.handleGetScorecardsApi();
  }

  handleGetScorecardsApi = () => {
    const { activePage, itemsPerPage } = this.state;
    const data = {
      activePage,
      itemsPerPage,
    };
    this.props.getScorecardTemplates(data);
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetScorecardsApi();
    });
  };

  activeDeleteScorecardModal = (scorecardTemplateId) => {
    this.setState({
      isOpenDeleteModal: true,
      scorecardTemplateId,
    });
  };

  activeAddScorecardModal = (
    scorecardTemplateId,
    scorecardTemplateName,
    testId,
    testDisplayName,
    isCustomFeedback,
    selectedFeedbackType
  ) => {
    this.setState({
      isOpenAddModal: true,
      templateName: scorecardTemplateName,
      scorecardTemplateId,
      testObj: { label: testDisplayName, value: testId },
      isCustomFeedback,
      selectedFeedbackType,
    });
  };

  dismissAddScorecardModal = () => {
    this.setState({
      isOpenAddModal: false,
      scorecardTemplateId: null,
    });
  };

  dismissDeleteScorecardModal = () => {
    this.setState({
      isOpenDeleteModal: false,
      scorecardTemplateId: null,
    });
  };

  handleDeleteGrp = () => {
    const { scorecardTemplateId } = this.state;
    this.props
      .deletScorecardTemplateById(scorecardTemplateId)
      .then((res) => {
        if (res && !res.error) {
          this.dismissDeleteScorecardModal();
          this.setState(
            {
              notifMsg: 'Feedback Template Deleted Successfully!',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  showSuccessNotif: false,
                });
              }, 5000);
            }
          );
          this.handleGetScorecardsApi();
        }
      })
      .catch((e) => {
        console.log('Error:::::', e);
        this.dismissDeleteScorecardModal();
        this.setState({
          showErr: true,
        });
      });
  };

  handleCreteScorecardTemplate = (data) => {
    const { scorecardTemplateId } = data || {};
    this.props
      .createScorecardTemplate(data)
      .then((res) => {
        const { isSuccess, error } = res || {};
        const { message } = error || {};
        if (res && isSuccess) {
          this.dismissAddScorecardModal();
          this.setState(
            {
              successNotif: true,
              notifMsg: `Feedback Template ${
                scorecardTemplateId ? 'Updated' : 'Added'
              } Successfully!`,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  successNotif: false,
                });
              }, 5000);
            }
          );
          this.handleGetScorecardsApi();
        } else {
        }
      })
      .catch((e) => {
        console.log('Error:::::', e);
        this.dismissAddScorecardModal();
        this.setState({
          showErr: true,
        });
      });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  render() {
    const {
      activePage,
      isOpenDeleteModal,
      itemsPerPage,
      isOpenAddModal,
      scorecardTemplateId,
      successNotif,
      notifMsg,
      errorNotif,
      errMsg,
      templateName,
      testObj,
      isCustomFeedback,
      selectedFeedbackType,
    } = this.state;
    const { count, scorecardtemplates, history, assessTestTypes } = this.props;

    const { push } = history;

    const columnWithFilter = [
      {
        title: 'Feedback Templates',
        className: 'text-left',
        name: 'scorecardTempName',
        propName: 'scorecardTempName',
      },
      {
        title: 'Feedback Type',
        className: 'text-left',
        name: 'feedbackType',
        propName: 'feedbackType',
      },
      {
        title: 'Actions',
        className: 'text-left',
        name: 'actions',
        propName: 'actions',
      },
    ];
    const otherActionsBtn = [
      <Button
        onClick={() => this.activeAddScorecardModal()}
        className="bx--btn bx--btn--sm bx--btn--primary">
        Add Feedback Template
      </Button>,
    ];
    return (
      <div>
        <BackRow paths={this.paths} />
        <div className="grid-lg">
          <SuccErrNotification
            successNotif={successNotif}
            errorNotif={errorNotif}
            notifMsg={notifMsg}
            errMsg={errMsg}
            handleCloseNotifBtn={this.handleCloseNotifBtn}
          />
          <ScorecardTemplateTable
            count={count}
            activePage={activePage}
            otherActionsBtn={otherActionsBtn}
            columnWithFilter={columnWithFilter}
            scorecardtemplates={scorecardtemplates}
            push={push}
            activeDeleteScorecardModal={this.activeDeleteScorecardModal}
            handlePaginationChange={this.handlePaginationChange}
            itemsPerPage={itemsPerPage}
            activeAddScorecardModal={this.activeAddScorecardModal}
          />
          {isOpenAddModal && (
            <AddEditFeedbackTemplate
              isOpenAddModal={true}
              scorecardId={scorecardTemplateId}
              testObj={testObj}
              isCustomFeedback={isCustomFeedback}
              templateName={templateName}
              assessTestTypes={assessTestTypes}
              selectedFeedbackType={selectedFeedbackType}
              feedbackOptionConfig={feedbackOptionConfig}
              dismissAddEditScorecardModal={this.dismissAddScorecardModal}
              handleCreteScorecardTemplate={this.handleCreteScorecardTemplate}
            />
          )}

          {isOpenDeleteModal && (
            <Modal
              open={isOpenDeleteModal}
              modalHeading="Action"
              modalLabel="Delete Feedback Template"
              primaryButtonText="Submit"
              secondaryButtonText="Cancel"
              danger
              iconDescription="Close the modal"
              onRequestClose={this.dismissDeleteScorecardModal}
              onRequestSubmit={this.handleDeleteGrp}>
              <div className="">
                Are you sure, you want to delete this feedback template?
              </div>
            </Modal>
          )}
        </div>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
  scorecardtemplates: state.FeedbackTemplates.scorecardtemplates,
  loading: state.FeedbackTemplates.loading,
  count: state.FeedbackTemplates.count,
  assessTestTypes: state.Assessments.assessTestTypes,
});

const mapDispatchToProps = {
  getAssessmentTypes,
  getScorecardTemplates,
  deletScorecardTemplateById,
  createScorecardTemplate,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackTemplates)
);
