import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextInput, Modal, ToggleSmall } from 'carbon-components-react';
import { withRouter } from 'react-router-dom';
import {
  createScorecardTemplate,
  getScorecardTemplateById,
} from '../../actions/FeedbackTemplates';
import Dropdown from '../../components/common/Dropdown';

class AddEditFeedbackTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateName: '',
      isTemplateNameInvalid: false,
    };
  }

  componentDidMount() {
    const {
      templateName,
      scorecardId,
      testObj,
      isCustomFeedback,
      selectedFeedbackType,
    } = this.props;
    if (scorecardId) {
      this.setState({
        templateName,
        selectedTest: testObj,
        isCustomFeedback,
        selectedFeedbackType,
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleValidateScorecardDetails = () => {
    const { templateName } = this.state;
    this.setState(
      {
        isTemplateNameInvalid: !templateName ? true : false,
      },
      () => {
        this.handleSubmitScorecardDetails();
      }
    );
  };

  handleSubmitScorecardDetails = () => {
    const {
      templateName,
      isTemplateNameInvalid,
      selectedTest,
      isCustomFeedback,
      selectedFeedbackType,
    } = this.state;

    if (!isTemplateNameInvalid) {
      const data = {
        scorecardTemplateName: templateName,
        feedbackType: selectedFeedbackType?.value || null,
        // testId: selectedTest?.value,
        // isCustomFeedback,
      };
      const { scorecardId } = this.props || {};
      if (scorecardId) {
        data['scorecardTemplateId'] = scorecardId;
      }
      this.props.handleCreteScorecardTemplate(data);
    }
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    });
  };

  handleToggleClick = (e, toggleName) => {
    const updateState = { [`${toggleName}`]: e };
    if (e == true) {
      updateState.isLinkAssessDisabled = true;
      updateState.selectedTest = null;
    } else {
      updateState.isLinkAssessDisabled = false;
    }
    this.setState({ ...updateState });
  };

  render() {
    const {
      loading,
      user,
      isOpenAddModal,
      scorecardId,
      assessTestTypes,
      feedbackOptionConfig,
    } = this.props;

    const {
      templateName,
      isTemplateNameInvalid,
      selectedTest,
      isCustomFeedback,
      isLinkAssessDisabled,
      selectedFeedbackType,
    } = this.state;
    return (
      <div>
        <Modal
          open={isOpenAddModal}
          modalHeading="Action"
          modalLabel={`${scorecardId ? 'Edit' : 'Add'} Feedback Template`}
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          iconDescription="Close the modal"
          onRequestClose={this.props.dismissAddEditScorecardModal}
          onRequestSubmit={this.handleValidateScorecardDetails}>
          <div className="mt-2">
            <TextInput
              id="templateName"
              name="templateName"
              labelText="Feedback Template Name"
              placeholder="Enter Feedback Template Name"
              hideLabel={false}
              onChange={(e) => this.handleChange(e)}
              value={templateName}
              invalid={isTemplateNameInvalid}
              invalidText="Please enter valid template name"
            />
          </div>
          {/* <div className="mt-2">
            <Dropdown
              id="link-assessment"
              title="Link Assessment"
              custom
              mainClassName="bx--form-item mb-0"
              className="w-100"
              titleClass="mb-2"
              labelClass="pt-2"
              onInputChange={() => {}}
              options={
                assessTestTypes &&
                Array.isArray(assessTestTypes) &&
                assessTestTypes.map((itm) => {
                  const { testId, displayName } = itm || {};
                  return {
                    label: displayName,
                    value: testId,
                  };
                })
              }
              name="selectedTest"
              placeholder="Assessment"
              onChange={(value, e, name) =>
                this.handleDropdownChange(value, e, name)
              }
              selectedValue={selectedTest}
              disabled={isLinkAssessDisabled}
            />
          </div>
          <div className="mt-3">
            <ToggleSmall
              id="isCustomFeedback"
              name="isCustomFeedback"
              className=""
              labelText="Custom Feedback"
              labelA="No"
              labelB="Yes"
              toggled={isCustomFeedback}
              onToggle={(e) => this.handleToggleClick(e, 'isCustomFeedback')}
            />
          </div> */}
          <div className="mt-2 mb-4">
            <Dropdown
              id="feedback-type"
              title="Feedback Type"
              custom
              mainClassName="bx--form-item mb-0"
              className="w-50 mb-2"
              titleClass="mb-2"
              labelClass="pt-2"
              onInputChange={() => {}}
              options={feedbackOptionConfig}
              name="selectedFeedbackType"
              placeholder="Feedback Type"
              onChange={(value, e, name) =>
                this.handleDropdownChange(value, e, name)
              }
              selectedValue={selectedFeedbackType}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  createScorecardTemplate,
  getScorecardTemplateById,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEditFeedbackTemplate)
);
