import React, { Component } from 'react';
import { connect } from 'react-redux';

//Api Actions
import {
  getAdminUsersList,
  createNewUser,
  deleteCreatedUser,
  upsertUserInfoDetailsById,
  getUserMappings,
  upsertUserMappings,
} from '../../actions/AdminSection';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericDataTable from '../../components/common/GenericDataTable';
import GenericTabs from '../../components/common/GenericTabs';
import Avatar from '../../components/common/Avatar';
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import UpdateUserDetails from '../../components/private/UpdateUserDetails';
import Dropdown from '../../components/common/Dropdown';

//Functional common components

//Common packages
import {
  Button,
  Pagination,
  Modal,
  TextInput,
  Select,
  SelectItem,
  InlineNotification,
} from 'carbon-components-react';
import { CheckmarkOutline32 } from '@carbon/icons-react';
import nricValidator from 'nric';
import { isInValid, lowerAndTrim } from '../../utils/helpers';

class UsersList extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'users', name: 'Users', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      isOpenAddUserModal: false,
      isOpenDelUserModal: false,
      roleId: 0,
      isValidUserName: false,
      isValidRole: false,
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
      isOpenMsgModal: false,
    };
  }

  componentDidMount() {
    this.handleGetUsersApi();
  }

  handleGetUsersApi = () => {
    const { activePage, itemsPerPage, searchKey } = this.state;
    const data = {
      activePage,
      itemsPerPage,
      searchKey,
    };
    this.props.getAdminUsersList(data);
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetUsersApi();
    });
  };

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ searchKey: event.target.value, isLoading: true });
    this.searchTimer = setTimeout(() => {
      this.handleGetUsersApi();
    }, 1000);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitAddUser = () => {
    const { emailId, roleId } = this.state;
    this.setState(
      {
        isValidUserName: emailId ? false : true,
        isValidRole: roleId > 0 ? false : true,
      },
      () => {
        const { isValidUserName, isValidRole } = this.state;
        if (!isValidUserName && !isValidRole) {
          // create user
          const obj = {
            emailId,
            roleId,
          };
          if (roleId === 10) {
            obj.firstName = 'Guest';
            obj.lastName = 'User';
          }
          const data = [obj];
          this.props
            .createNewUser(data)
            .then((res) => {
              const { isSuccess } = res || {};
              if (res && !res.error && isSuccess) {
                this.setState({
                  successNotif: true,
                  notifMsg: 'An email is sent successfully',
                });

                const userEmailId = emailId?.toString()?.toLowerCase()?.trim();
                const { userId: parentUserId } = res?.[userEmailId] || {};

                // upsert user mapping
                const { selectedPAList, selectedPanelMemObj } = this.state;
                let payload = [
                  {
                    roleId,
                    parentUserId,
                    addedChildUserIds: [],
                    removedChildUserIds: [],
                  },
                ];
                if (roleId == 7) {
                  if (
                    selectedPAList &&
                    Array.isArray(selectedPAList) &&
                    selectedPAList.length > 0
                  ) {
                    payload = [
                      {
                        roleId,
                        parentUserId,
                        addedChildUserIds: selectedPAList?.map(
                          (itm) => itm?.value
                        ),
                        removedChildUserIds: [],
                      },
                    ];
                  }
                } else if (roleId == 13) {
                  if (selectedPanelMemObj && selectedPanelMemObj.value) {
                    payload = [
                      {
                        roleId,
                        parentUserId,
                        addedChildUserIds: [selectedPanelMemObj?.value],
                        removedChildUserIds: [],
                      },
                    ];
                  }
                }

                this.props
                  .upsertUserMappings(payload)
                  .then((res) => {
                    if (res && !res.error) {
                      this.dismissAddUserModal();
                      this.handleGetUsersApi();
                    } else {
                      this.dismissAddUserModal();
                      this.setState({
                        errorNotif: true,
                      });
                    }
                  })
                  .catch((e) => {
                    console.log('Error', e);
                    this.dismissAddUserModal();
                    this.setState({
                      errorNotif: true,
                    });
                  });
              } else {
                this.dismissAddUserModal();
                this.setState({
                  errorNotif: true,
                });
              }
            })
            .catch((e) => {
              console.log('Error', e);
              this.dismissAddUserModal();
              this.setState({
                errorNotif: true,
              });
            });
        }
      }
    );
  };

  activeAddUserModal = () => {
    this.setState({
      isOpenAddUserModal: true,
    });
  };

  dismissAddUserModal = () => {
    this.setState({
      isOpenAddUserModal: false,
      emailId: '',
      roleId: 0,
      selectedPAList: [],
      selectedPanelMemObj: {},
    });
  };

  activeDelUserModal = (emailId) => {
    this.setState({
      isOpenDelUserModal: true,
      emailId,
    });
  };

  dismissDelUserModal = () => {
    this.setState({
      isOpenDelUserModal: false,
    });
  };

  handleSubmitDelUser = () => {
    const { emailId } = this.state;
    this.props
      .deleteCreatedUser([{ emailId }])
      .then((res) => {
        if (res && !res.error) {
          this.setState(
            {
              successNotif: true,
              notifMsg: 'The user details have been updated successfully',
              errorNotif: false,
              isOpenDelUserModal: false,
            },
            () => {
              this.handleGetUsersApi();
            }
          );
        } else {
          this.dismissDelUserModal();
          this.setState({
            errorNotif: true,
            successNotif: false,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.dismissDelUserModal();
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  activeEditUserModal = (data) => {
    this.setState({
      isOpenEditUserModal: true,
      ...data,
    });
  };

  dismissEditUserModal = () => {
    this.setState({
      isOpenEditUserModal: false,
      userId: 0,
      firstname: '',
      lastname: '',
      roleId: 0,
      active: false,
      nric: '',
    });
  };

  handleSubmitEditUser = (data) => {
    const {
      firstname,
      lastname,
      roleId,
      active,
      updateEmailMsg,
      selectedPAList,
      selectedPanelMemObj,
      nric,
    } = data || {};

    this.setState(
      {
        isValidFirstName: isInValid(firstname, 'text'),
        isValidLastName: isInValid(lastname, 'text'),
        isValidRole: roleId > 0 ? false : true,
        isValidNric:
          roleId == 7 && lowerAndTrim(nric)
            ? !nricValidator.validate(nric?.toString()?.trim())
            : false,
      },
      () => {
        const {
          isValidFirstName,
          isValidLastName,
          isValidRole,
          userId,
          childMappings = [],
          parentMappings = [],
          isValidNric,
        } = this.state || {};
        if (
          !isValidFirstName &&
          !isValidLastName &&
          !isValidRole &&
          !isValidNric
        ) {
          // upsert user mapping
          let payload = [
            {
              roleId,
              parentUserId: userId,
              addedChildUserIds: [],
              removedChildUserIds: [],
            },
          ];
          if (roleId == 7) {
            const finalChildMappings =
              childMappings?.map((itm) => itm?.childUserId) || [];
            const finalSelectedPAList =
              selectedPAList?.map((itm) => itm?.value) || [];

            payload = [
              {
                roleId,
                parentUserId: userId,
                addedChildUserIds:
                  finalSelectedPAList?.filter(
                    (itm) => !finalChildMappings?.includes(itm)
                  ) || [],
                removedChildUserIds:
                  finalChildMappings?.filter(
                    (itm) => !finalSelectedPAList?.includes(itm)
                  ) || [],
                nric,
              },
            ];
          } else if (roleId == 13) {
            const finalParentMappings =
              parentMappings?.map((itm) => itm?.parentUserId) || [];
            const finalSelectedPanelMemList =
              (selectedPanelMemObj?.value && [selectedPanelMemObj?.value]) ||
              [];

            payload = [
              {
                roleId,
                parentUserId: userId,
                addedChildUserIds:
                  finalSelectedPanelMemList?.filter(
                    (itm) => !finalParentMappings?.includes(itm)
                  ) || [],
                removedChildUserIds:
                  finalParentMappings?.filter(
                    (itm) => !finalSelectedPanelMemList?.includes(itm)
                  ) || [],
              },
            ];
          }

          this.props.upsertUserMappings(payload).then((res) => {
            if (res && !res.error) {
              const finalData = {
                firstname,
                lastname,
                roleId,
                active,
                nric,
              };
              this.props
                .upsertUserInfoDetailsById(finalData, userId)
                .then((res) => {
                  if (res && !res.error) {
                    this.setState(
                      {
                        successNotif: true,
                        notifMsg:
                          'The user details have been updated successfully',
                        errorNotif: false,
                        isOpenMsgModal: updateEmailMsg === true ? true : false,
                      },
                      () => {
                        this.dismissEditUserModal();
                        this.handleGetUsersApi();
                      }
                    );
                  } else {
                    this.dismissEditUserModal();
                    this.setState({
                      errorNotif: true,
                      successNotif: false,
                    });
                  }
                })
                .catch((e) => {
                  console.log('Error', e);
                  this.dismissEditUserModal();
                  this.setState({
                    errorNotif: true,
                  });
                });
            }
          });
        }
      }
    );
  };

  dismissMsgModal = () => {
    this.setState({
      isOpenMsgModal: false,
    });
  };

  handleGetUserMappings = (value, roleId) => {
    const data = {
      searchKey: value,
      activePage: 1,
      itemsPerPage: 5,
    };
    if (roleId == 7) {
      data['roleIds'] = [13];
    } else if (roleId == 13) {
      data['roleIds'] = [7];
    }
    this.props
      .getUserMappings(data)
      .then((res) => {
        if (res && !res.error) {
          const { list } = res || {};
          const updateObj = {};
          if (roleId == 7) {
            updateObj['paUsersList'] = list;
          } else {
            updateObj['panelMemUsersList'] = list;
          }
          this.setState({ ...updateObj });
        }
      })
      .catch((e) => {
        console.log('Error', e);
      });
  };

  onInputUserMappingsChange = (value, cb, roleId) => {
    if (value && typeof value === 'string') value = value.trimStart();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[ a-zA-Z0-9!()#+.]{1,300}/.test(value)) {
      this.handleGetUserMappings(value, roleId);
      return value;
    }
  };

  handleDropdownChange = (v, metaValue, name, actionType, roleId) => {
    const { action } = actionType || {};
    this.setState(
      {
        [name]: metaValue,
      },
      () => {
        if (action === 'clear') {
          this.handleGetUserMappings(null, roleId);
        }
      }
    );
  };

  render() {
    const {
      selectedTab,
      activePage,
      isValidUserName,
      emailId,
      isOpenDelUserModal,
      isOpenAddUserModal,
      roleId,
      successNotif,
      isValidRole,
      errorNotif,
      notifMsg,
      email,
      firstname,
      lastname,
      nric,
      isValidNric,
      isOpenEditUserModal,
      isValidFirstName,
      isValidLastName,
      userId,
      active,
      paUsersList = [],
      selectedPAList = [],
      panelMemUsersList = [],
      selectedPanelMemObj = {},
    } = this.state;
    const { usersList, usersCount, roles, user, loading } = this.props;
    const { userId: propUserId, roleId: propRoleId } = user || {};
    const otherActionsBtn = [];
    otherActionsBtn.push(
      <Button kind="primary" onClick={this.activeAddUserModal}>
        Add User
      </Button>
    );
    let finalRoles = [];
    if (roles && Array.isArray(roles) && roles.length > 0) {
      if (this.state.userId > 0) {
        finalRoles = roles.filter(
          (r) => r && r.roleId !== 8 && r.roleId !== 9 && r.roleId !== 10
        );
      } else {
        finalRoles = roles;
      }
    }
    return (
      <div>
        <BackRow paths={this.paths} />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        <div>
          <div>
            <GenericDataTable
              otherActions={otherActionsBtn}
              searchable
              onSearchInputChange={this.handleSearchSubmit}
              rows={
                usersList && Array.isArray(usersList) && usersList.length > 0
                  ? usersList.map((data, idx) => {
                      const {
                        email,
                        firstname,
                        lastname,
                        userId,
                        userrole,
                        active: propActive,
                        childMappings,
                        parentMappings,
                        nric,
                      } = data;
                      const { roleName: propRoleName, roleId } = userrole || {};
                      let selectedPanelMemObj = {};
                      if (
                        parentMappings &&
                        Array.isArray(parentMappings) &&
                        parentMappings.length > 0
                      ) {
                        const { parentUserinfo } = parentMappings?.[0] || {};
                        const { firstname, lastname, userId, isTagged } =
                          parentUserinfo || {};
                        selectedPanelMemObj = {
                          label: `${firstname} ${lastname} ${
                            isTagged ? '- Is already tagged' : ''
                          }`,
                          value: userId,
                        };
                      }
                      return {
                        isExpanded: false,
                        id: userId,
                        header_0: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Name:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <div className="d-flex align-items-center">
                                <div className="xpa-avatar-width">
                                  <Avatar
                                    user={{
                                      firstname: firstname
                                        ? firstname
                                        : 'Not Available',
                                      lastname,
                                      picture: null,
                                    }}
                                    classNameInfo="rounded-circle"
                                    size="30"
                                  />
                                </div>
                                <div className="ml-2 text-capitalize bx--type-zeta">
                                  {firstname
                                    ? `${firstname} ${lastname}`
                                    : 'Not Available'}{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                        header_1: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Email:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <div>{email}</div>
                            </div>
                          </div>
                        ),
                        header_2: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Role:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <div>{propRoleName}</div>
                            </div>
                          </div>
                        ),
                        header_3: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              User Mappings
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {roleId == 7 ? (
                                <div>
                                  {childMappings &&
                                  Array.isArray(childMappings) &&
                                  childMappings.length > 0 ? (
                                    <div>
                                      <div>Personal Asssistants:</div>
                                      {childMappings?.map((itm) => {
                                        const { childUserinfo } = itm || {};
                                        const { firstname, lastname } =
                                          childUserinfo || {};
                                        return (
                                          <div className="d-flex mr-2 flex-wrap">
                                            {firstname} {lastname}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    '-'
                                  )}
                                </div>
                              ) : roleId == 13 ? (
                                <div>
                                  {parentMappings &&
                                  Array.isArray(parentMappings) &&
                                  parentMappings.length > 0 ? (
                                    <div>
                                      <div>Panel Members:</div>
                                      {parentMappings?.map((itm) => {
                                        const { parentUserinfo } = itm || {};
                                        const { firstname, lastname } =
                                          parentUserinfo || {};
                                        return (
                                          <div className="d-flex mr-2 flex-wrap">
                                            {firstname} {lastname}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    '-'
                                  )}
                                </div>
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                        ),
                        header_4: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Action:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <div className="d-flex">
                                {propRoleId == 1 &&
                                  roleId !== 10 &&
                                  roleId !== 8 &&
                                  roleId !== 9 && (
                                    <div>
                                      <Button
                                        onClick={() => {
                                          this.activeEditUserModal({
                                            email,
                                            firstname,
                                            lastname,
                                            userId,
                                            roleId,
                                            nric,
                                            active: propActive,
                                            childMappings,
                                            parentMappings,
                                            selectedPAList:
                                              childMappings?.map((itm) => {
                                                const { childUserinfo } = itm;
                                                const {
                                                  firstname,
                                                  lastname,
                                                  userId,
                                                  isTagged,
                                                } = childUserinfo || {};
                                                return {
                                                  label: `${firstname} ${lastname} ${
                                                    isTagged
                                                      ? '- Is already tagged'
                                                      : ''
                                                  }`,
                                                  value: userId,
                                                };
                                              }) || [],
                                            selectedPanelMemObj,
                                          });
                                        }}
                                        kind="tertiary"
                                        size="small">
                                        Edit
                                      </Button>
                                    </div>
                                  )}
                                {propUserId != userId && (
                                  <div
                                    className={
                                      roleId === 10 ||
                                      roleId === 8 ||
                                      roleId === 9
                                        ? ''
                                        : 'ml-2'
                                    }>
                                    <Button
                                      onClick={() =>
                                        this.activeDelUserModal(email)
                                      }
                                      kind="danger"
                                      size="small">
                                      Delete
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ),
                      };
                    })
                  : []
              }
              headers={[
                {
                  key: 'header_0',
                  header: 'Name',
                },
                {
                  key: 'header_1',
                  header: 'Email',
                },
                {
                  key: 'header_2',
                  header: 'Role',
                },
                {
                  key: 'header_3',
                  header: 'User Mappings',
                },
                {
                  key: 'header_4',
                  header: 'Action',
                },
              ]}
            />
          </div>
          {usersCount > 0 && (
            <Pagination
              onChange={this.handlePaginationChange}
              pageSizes={[10, 20, 30, 40, 50]}
              page={activePage}
              totalItems={usersCount}
            />
          )}
          {usersCount <= 0 && (
            <NoContentBox message="There are no user created for your current selection" />
          )}
        </div>
        <JDProcessOverlay show={loading} message="processing..." />
        {isOpenAddUserModal && (
          <Modal
            className="modal-height"
            open={isOpenAddUserModal}
            modalHeading="Create User"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissAddUserModal}
            onRequestSubmit={this.handleSubmitAddUser}>
            <div>
              <TextInput
                name="emailId"
                id="emailId"
                value={emailId}
                placeholder="jane@gmail.com"
                onChange={this.handleChange}
                invalid={isValidUserName}
                invalidText="Please enter valid user name"
                labelText="Email"
              />
            </div>
            <div className="d-flex mt-2 w-100">
              <div className="">
                <Select
                  name="roleId"
                  id="roleId"
                  onChange={this.handleChange}
                  value={roleId}
                  invalid={isValidRole}
                  invalidText="Please select valid role"
                  labelText="Select Role">
                  <SelectItem text="Select" key={0} />
                  {roles &&
                    Array.isArray(roles) &&
                    roles.length > 0 &&
                    roles.map((role) => {
                      const { roleId: propRoleId, roleName } = role || {};
                      return (
                        <SelectItem
                          text={roleName}
                          key={propRoleId}
                          id={propRoleId}
                          value={propRoleId}
                        />
                      );
                    })}
                </Select>
              </div>
              {roleId == 7 && (
                <div className="ml-3 w-100">
                  <div className="bx--label">Select Personal Assistant/s</div>
                  <Dropdown
                    id="choose-personal-assisst-dd"
                    title=""
                    mainClassName="bx--form-item mb-0"
                    titleClass=""
                    labelClass=""
                    custom
                    isMulti
                    label=""
                    className="w-100"
                    options={
                      paUsersList &&
                      Array.isArray(paUsersList) &&
                      paUsersList.length > 0
                        ? paUsersList.map((res) => {
                            const { userId, firstname, lastname, isTagged } =
                              res || {};
                            return {
                              label: `${firstname} ${lastname} ${
                                isTagged ? '- Is already tagged' : ''
                              }`,
                              value: userId,
                            };
                          })
                        : []
                    }
                    placeholder="Start typing to choose PA's"
                    onChange={(value, e, name, action) =>
                      this.handleDropdownChange(value, e, name, action, roleId)
                    }
                    onInputChange={(value, cb) => {
                      this.onInputUserMappingsChange(value, cb, roleId);
                    }}
                    name="selectedPAList"
                    selectedValue={selectedPAList}
                  />
                </div>
              )}
              {roleId == 13 && (
                <div className="ml-3 w-100">
                  <div className="bx--label">Select Panel Members</div>
                  <Dropdown
                    id="choose-panel-mem-dd"
                    title=""
                    mainClassName="bx--form-item mb-0"
                    titleClass=""
                    labelClass=""
                    custom
                    // isMulti
                    label=""
                    className="w-100"
                    options={
                      panelMemUsersList &&
                      Array.isArray(panelMemUsersList) &&
                      panelMemUsersList.length > 0
                        ? panelMemUsersList.map((res) => {
                            const { userId, firstname, lastname, isTagged } =
                              res || {};
                            return {
                              label: `${firstname} ${lastname} ${
                                isTagged ? '- Is already tagged' : ''
                              }`,
                              value: userId,
                            };
                          })
                        : []
                    }
                    placeholder="Start typing to choose Panel members"
                    onChange={(value, e, name, action) =>
                      this.handleDropdownChange(value, e, name, action, roleId)
                    }
                    onInputChange={(value, cb) => {
                      this.onInputUserMappingsChange(value, cb, roleId);
                    }}
                    name="selectedPanelMemObj"
                    selectedValue={selectedPanelMemObj}
                  />
                </div>
              )}
            </div>
          </Modal>
        )}
        {isOpenEditUserModal === true && (
          <UpdateUserDetails
            firstname={firstname}
            lastname={lastname}
            active={active}
            email={email}
            roleId={roleId}
            roles={roles}
            isValidFirstName={isValidFirstName}
            isValidLastName={isValidLastName}
            handleSubmitEditUser={this.handleSubmitEditUser}
            isOpenEditUserModal={isOpenEditUserModal}
            dismissEditUserModal={this.dismissEditUserModal}
            finalRoles={finalRoles}
            showActiveNotifMsg={this.state.showActiveNotifMsg}
            isValidRole={this.state.isValidRole}
            paUsersList={paUsersList}
            selectedPAList={selectedPAList}
            panelMemUsersList={panelMemUsersList}
            selectedPanelMemObj={selectedPanelMemObj}
            handleDropdownChange={this.handleDropdownChange}
            onInputUserMappingsChange={this.onInputUserMappingsChange}
            nric={nric}
            isValidNric={isValidNric}
          />
        )}

        {this.state.isOpenMsgModal === true && (
          <Modal
            open={this.state.isOpenMsgModal}
            modalHeading="Reactivation Message"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            passiveModal
            onRequestClose={this.dismissMsgModal}>
            <div className="text-center mb-2">
              <CheckmarkOutline32 fill="green" width="48px" height="48px" />
            </div>
            <div className="h4 text-center f-w-b">
              An email will be sent to you shortly for your concurrence before
              the account can be reactivated
            </div>
          </Modal>
        )}

        {isOpenDelUserModal && (
          <Modal
            open={isOpenDelUserModal}
            modalHeading="Delete User"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            danger
            iconDescription="Close the modal"
            onRequestClose={this.dismissDelUserModal}
            onRequestSubmit={this.handleSubmitDelUser}>
            Are you sure you want to delete this user?
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usersList: state.AdminSection.usersList,
  usersCount: state.AdminSection.usersCount,
  roles: state.AdminSection.roles,
  user: state.x0paDuser.user,
  loading: state.AdminSection.loading,
});

const mapDispatchToProps = {
  getAdminUsersList,
  createNewUser,
  deleteCreatedUser,
  upsertUserInfoDetailsById,
  getUserMappings,
  upsertUserMappings,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
