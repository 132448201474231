import React, { Fragment } from 'react';

import GenericDataTable from '../../components/common/GenericDataTable';
import moment from 'moment';
import NoContentBox from '../../components/common/NoContentBox';
import {
  ALevelResults,
  OLevelResults,
  NusHighSchoolResults,
  PolyResults,
  SATDetails,
  IBDiplomaResults,
  UniversityResults,
  OtherInstitutionResults,
} from '../../components/private/CandidateAcademics';

const CandAcademicDetails = (props) => {
  const {
    satDetails,
    schoolDetails,
    polyResult,
    oLevelResult,
    nusHighSchool,
    ibDiploma,
    aLevelResult,
    universityDetails,
    satSubTests,
    otherInstResult,
  } = props || {};
  let academicResOrder = [
    'university',
    'oInst',
    'ib',
    'poly',
    'alevel',
    'nus',
    'olevel',
  ];
  let yearsArr = [];
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    schoolDetails.forEach((school) => {
      const { schoollevel: schoollevelObj, startDate } = school || {};
      const { schoollevel } = schoollevelObj || {};
      const finalObj = {};
      const finalStartDate = startDate && moment(startDate).format('YYYY');

      if (schoollevel === 'junior college/ip school') {
        finalObj.type = 'alevel';
        finalObj.year = finalStartDate;
        yearsArr.push(finalObj);
      } else if (schoollevel === 'other institution') {
        finalObj.type = 'oInst';
        finalObj.year = finalStartDate;
        yearsArr.push(finalObj);
      } else if (schoollevel === 'secondary school') {
        finalObj.type = 'olevel';
        finalObj.year = finalStartDate;
        yearsArr.push(finalObj);
      } else if (schoollevel === 'nus high school') {
        finalObj.type = 'nus';
        finalObj.year = finalStartDate;
        yearsArr.push(finalObj);
      } else if (schoollevel === 'polytechnic') {
        finalObj.type = 'poly';
        finalObj.year = finalStartDate;
        yearsArr.push(finalObj);
      } else if (schoollevel === 'ib school') {
        finalObj.type = 'ib';
        finalObj.year = finalStartDate;
        yearsArr.push(finalObj);
      } else if (schoollevel === 'university') {
        finalObj.type = 'university';
        finalObj.year = finalStartDate;
        yearsArr.push(finalObj);
      }
    });
  }
  let finalSortArr = [];
  if (yearsArr && Array.isArray(yearsArr) && yearsArr.length > 0) {
    finalSortArr = yearsArr.sort((a, b) => {
      const finalAYear = a && a.year && Number(a.year);
      const finalBYear = b && b.year && Number(b.year);
      if (finalAYear > finalBYear) {
        return 1;
      } else if (finalAYear > finalBYear) {
        return -1;
      }
      return 0;
    });
  }
  if (finalSortArr && Array.isArray(finalSortArr) && finalSortArr.length > 0) {
    const sortedData = finalSortArr.map((arr) => arr && arr.type);
    if (sortedData && Array.isArray(sortedData) && sortedData.length > 0) {
      academicResOrder = [...new Set(sortedData)];
    }
  }
  return (
    <div>
      <div className="mt-2 h6 font-weight-bold">Academic Results</div>
      {(ibDiploma && Array.isArray(ibDiploma) && ibDiploma.length > 0) ||
      (nusHighSchool &&
        Array.isArray(nusHighSchool) &&
        nusHighSchool.length > 0) ||
      (aLevelResult &&
        Array.isArray(aLevelResult) &&
        aLevelResult.length > 0) ||
      (oLevelResult &&
        Array.isArray(oLevelResult) &&
        oLevelResult.length > 0) ||
      (satDetails && Array.isArray(satDetails) && satDetails.length > 0) ? (
        <hr />
      ) : (
        <NoContentBox
          hideTile
          message="There are no academic results for your current selection"
        />
      )}
      {academicResOrder &&
        Array.isArray(academicResOrder) &&
        academicResOrder.length > 0 &&
        academicResOrder.map((order) => (
          <Fragment>
            {order === 'university' && (
              <UniversityResults
                schoolDetails={schoolDetails}
                universityDetails={universityDetails}
              />
            )}
            {order === 'ib' && (
              <IBDiplomaResults
                ibDiploma={ibDiploma}
                schoolDetails={schoolDetails}
              />
            )}
            {order === 'oInst' && (
              <OtherInstitutionResults
                otherInstResult={otherInstResult}
                schoolDetails={schoolDetails}
              />
            )}
            {order === 'poly' && (
              <Fragment>
                <PolyResults
                  polyResult={polyResult}
                  schoolDetails={schoolDetails}
                />
              </Fragment>
            )}
            {order === 'alevel' && (
              <ALevelResults
                aLevelResult={aLevelResult}
                schoolDetails={schoolDetails}
              />
            )}
            {order === 'nus' && (
              <Fragment>
                <NusHighSchoolResults
                  nusHighSchool={nusHighSchool}
                  schoolDetails={schoolDetails}
                />
              </Fragment>
            )}
            {order === 'olevel' && (
              <Fragment>
                <OLevelResults
                  oLevelResult={oLevelResult}
                  schoolDetails={schoolDetails}
                />
              </Fragment>
            )}
          </Fragment>
        ))}
      {!academicResOrder.includes('university') &&
        universityDetails &&
        Array.isArray(universityDetails) &&
        universityDetails.length > 0 && (
          <UniversityResults
            schoolDetails={schoolDetails}
            universityDetails={universityDetails}
          />
        )}
      {!academicResOrder.includes('ib') &&
        ibDiploma &&
        Array.isArray(ibDiploma) &&
        ibDiploma.length > 0 && (
          <IBDiplomaResults
            ibDiploma={ibDiploma}
            schoolDetails={schoolDetails}
          />
        )}
      {otherInstResult &&
        Array.isArray(otherInstResult) &&
        !academicResOrder.includes('oInst') &&
        otherInstResult.length > 0 && (
          <OtherInstitutionResults
            otherInstResult={otherInstResult}
            schoolDetails={schoolDetails}
          />
        )}
      {polyResult &&
        Array.isArray(polyResult) &&
        polyResult.length > 0 &&
        !academicResOrder.includes('poly') && (
          <Fragment>
            <PolyResults
              polyResult={polyResult}
              schoolDetails={schoolDetails}
            />
          </Fragment>
        )}
      {aLevelResult &&
        Array.isArray(aLevelResult) &&
        aLevelResult.length > 0 &&
        !academicResOrder.includes('alevel') && (
          <ALevelResults
            aLevelResult={aLevelResult}
            schoolDetails={schoolDetails}
          />
        )}
      {nusHighSchool &&
        Array.isArray(nusHighSchool) &&
        nusHighSchool.length > 0 &&
        !academicResOrder.includes('nus') && (
          <Fragment>
            <NusHighSchoolResults
              nusHighSchool={nusHighSchool}
              schoolDetails={schoolDetails}
            />
          </Fragment>
        )}
      {oLevelResult &&
        Array.isArray(oLevelResult) &&
        oLevelResult.length > 0 &&
        !academicResOrder.includes('olevel') && (
          <Fragment>
            <OLevelResults
              oLevelResult={oLevelResult}
              schoolDetails={schoolDetails}
            />
          </Fragment>
        )}
      {satDetails && Array.isArray(satDetails) && satDetails.length > 0 && (
        <Fragment>
          <SATDetails satDetails={satDetails} satSubTests={satSubTests} />
        </Fragment>
      )}
    </div>
  );
};

export default CandAcademicDetails;
