import React, { Component } from 'react';
import { connect } from 'react-redux';
import NoContentBox from '../../components/common/NoContentBox';
import {
  Accordion,
  AccordionItem,
  InlineLoading,
  Loading,
  Modal,
  TextArea,
} from 'carbon-components-react';
import {
  getPersonalSummary,
  searchAiInsights,
  getAiInsightsStatusById,
  postFeedbackForAiInsights,
} from '../../actions/CandidateInfo';
import { BsStars } from 'react-icons/bs';
import { lowerAndTrim } from '../../utils/helpers';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import ChatGPTSearchBox from '../../components/common/ChatGPTSearchBox';

const renderHtml = (html) => {
  return (
    <div
      style={{ lineHeight: 1.3 }}
      className="xpa-ul pr-3"
      dangerouslySetInnerHTML={{ __html: html.replace(/\n/g, '<br />') }}
    />
  );
};

const inLineLoader = (
  <InlineLoading
    status="active"
    iconDescription="Loading"
    description={<div style={{ color: 'black' }}>Searching...</div>}
  />
);

class CandidatePersonalSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
      isAIGeneratedPromptAvail: false,
      taskId: null,
      isOpenFeedbackModal: false,
    };
    this.resultRef = React.createRef();
  }

  attempts = 0;

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.taskId && this.state.taskId !== prevState.taskId) {
      this.handleGetTaskStatus();
    }

    if (
      prevState.evaluationResult !== this.state.evaluationResult &&
      this.resultRef.current
    ) {
      this.resultRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
    this.attempts = 12;
  }

  renderOnMount = () => {
    const { appId } = this.props;
    if (appId) this.props.getPersonalSummary(appId);

    this.setState({ searchKey: '' });
  };

  handleOpenAccr = (name) => {
    this.setState({
      isOpenAccrVal: name,
    });
  };

  handleChange = (e) => {
    const { name, value } = e?.target || {};
    this.setState({
      [name]: value,
    });
  };

  handleGetTaskStatus = async () => {
    const { taskId } = this.state;
    if (!taskId) return;

    const maxAttempts = 20;
    this.attempts = 0;

    while (this.attempts < maxAttempts) {
      this.attempts++;
      try {
        const res = await this.props.getAiInsightsStatusById(taskId);
        if (res && !res.error) {
          const { task_status, task_result } = res || {};
          const { custom_evaluation } = task_result || {};
          const { evaluation_result } = custom_evaluation || {};
          const finalTaskStatus = lowerAndTrim(task_status);
          if (finalTaskStatus === 'success') {
            this.setState({
              evaluationResult: evaluation_result,
              isAIGeneratedPromptAvail: true,
              taskId: null,
              errorNotif: false,
              successNotif: true,
              notifMsg: 'Generated Successfully!',
            });
            break;
          } else if (finalTaskStatus !== 'success') {
            await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 seconds delay
          } else {
            this.setState({
              taskId: null,
              errorNotif: true,
              successNotif: false,
              errMsg: 'We have encountered something, Please try again.',
              isAIGeneratedPromptAvail: false,
            });
            break;
          }
        } else {
          this.setState({
            taskId: null,
            errorNotif: true,
            successNotif: false,
            errMsg:
              res?.error?.message ||
              'Failed to Generate. Please try again later',
            isAIGeneratedPromptAvail: false,
          });
          break;
        }
      } catch (error) {
        console.error('ERROR::', error);
        this.setState({
          taskId: null,
          errorNotif: true,
          successNotif: false,
          errMsg:
            error?.message || 'Failed to Generate. Please try again later',
        });
        break;
      }
    }

    if (this.attempts >= maxAttempts) {
      this.setState({
        taskId: null,
        errorNotif: true,
        successNotif: false,
        errMsg:
          'Oops! It looks like this is taking longer than usual. Please try again later',
      });
    }
  };

  handleSubmitPrompt = (prompt) => {
    const { appId } = this.props || {};
    const data = {
      appId,
      prompt,
    };
    this.props
      .searchAiInsights(data)
      .then((res) => {
        const { task_id } = res || {};
        this.setState({
          taskId: task_id,
          isAIGeneratedPromptAvail: false,
          successNotif: false,
          errorNotif: false,
        });
      })
      .catch((error) => console.log('Error', error));
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  dismissFeedbackModal = () => {
    this.setState({
      isOpenFeedbackModal: false,
      recruiterFeedback: '',
    });
  };

  handleSubmitRecruiterFeedback = () => {
    const { recruiterFeedback } = this.state;
    this.setState(
      {
        isRecFeedbackInvalid:
          !recruiterFeedback || lowerAndTrim(recruiterFeedback)?.length <= 0,
      },
      () => {
        const { appId } = this.props || {};
        const data = {
          appId,
          type: 'feedback-on-profile-insights',
          feedback: this.state.recruiterFeedback,
        };
        if (!this.state.isRecFeedbackInvalid) {
          this.props
            .postFeedbackForAiInsights(data)
            .then((res) => {
              this.dismissFeedbackModal();
              if (res && !res.error) {
                this.setState({
                  successNotifGeneric: true,
                  errorNotifGeneric: false,
                  notifMsgGeneric: 'Feeback has been submitted successfully!',
                });
              } else {
                this.setState({
                  successNotifGeneric: false,
                  errorNotifGeneric: true,
                  errMsgGeneric:
                    'Something went wrong, Please try again later!',
                });
              }
            })
            .catch((error) => console.log('Error', error));
        }
      }
    );
  };

  render() {
    const {
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      taskId,
      evaluationResult,
      isAIGeneratedPromptAvail,
      isOpenFeedbackModal,
      recruiterFeedback,
      isRecFeedbackInvalid,
      successNotifGeneric,
      errorNotifGeneric,
      notifMsgGeneric,
      errMsgGeneric,
    } = this.state;
    const { personalSummary } = this.props;
    const { insights_result } = personalSummary || {};
    const { gems } = insights_result || {};

    return (
      <div>
        <div className="d-flex">
          <div className="">
            <div className="mt-2 h6 font-weight-bold">
              Candidate Overview - Key Traits
            </div>
          </div>

          <span className="xpa-gen-ai ml-3" onClick={() => {}}>
            <BsStars
              size={20}
              style={{ color: 'rgb(255 191 0)', marginRight: '2px' }}
            />
            Generated using AI
          </span>
        </div>
        <hr />
        {(successNotifGeneric || errorNotifGeneric) && (
          <SuccErrNotification
            successNotif={successNotifGeneric}
            errorNotif={errorNotifGeneric}
            notifMsg={notifMsgGeneric}
            errMsg={errMsgGeneric}
          />
        )}

        <div className="mt-2 mb-3">
          <div style={{ fontWeight: 600, fontSize: 'medium' }}>
            Active Beta Testing
          </div>
          <div className="mt-2">
            <span style={{ fontWeight: 600, fontSize: 'small' }}>
              Disclaimer
            </span>{' '}
            -{' '}
            <span style={{ color: 'red', fontStyle: 'italic' }}>
              Vetted by AI. Please note that during beta phase, information
              presented may not be fully represented and is subject to further
              improvements. Please provide your feedback{' '}
              <span
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    isOpenFeedbackModal: true,
                  });
                }}>
                here
              </span>
              .
            </span>
          </div>
        </div>

        {gems && Array.isArray(gems) && gems.length > 0 ? (
          <Accordion className="" align="start">
            {gems.map((itm) => {
              const { gem_name, evaluation_result } = itm || {};
              return (
                <AccordionItem
                  className="gba-accor-content mt-2"
                  title={
                    <div className="bx--row align-items-center">
                      <div className="h6 font-weight-bold mb-1 bx--col-lg-12 bx--col-md-12">
                        {gem_name}
                      </div>
                    </div>
                  }>
                  <div style={{ lineHeight: 1.3 }}>{evaluation_result}</div>
                </AccordionItem>
              );
            })}
          </Accordion>
        ) : (
          <NoContentBox
            hideTile
            message="There are no AI Insights for your current selection"
          />
        )}

        {gems && Array.isArray(gems) && gems.length > 0 && (
          <>
            <hr />
            <div className="mb-5">
              <SuccErrNotification
                successNotif={successNotif}
                errorNotif={errorNotif}
                notifMsg={notifMsg}
                handleCloseNotifBtn={this.handleCloseNotifBtn}
                errMsg={errMsg}
              />
              <div className="mb-2 font-weight-bold h2">
                What would you like to know about this candidate?
              </div>
              <ChatGPTSearchBox
                handleSubmit={this.handleSubmitPrompt}
                taskId={taskId}
                isAIGeneratedPromptAvail={isAIGeneratedPromptAvail}
              />

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {taskId && (
                  <>
                    {/* Carbon Loading component */}
                    <Loading
                      description="Active loading indicator"
                      small={true} // Adjust size for inline
                      withOverlay={false} // Prevent full screen overlay
                      style={{ marginRight: '10px' }}
                    />
                    <span>Loading data, please wait...</span>
                  </>
                )}
              </div>

              <div ref={this.resultRef} style={{ scrollMarginTop: 400 }}>
                {evaluationResult && (
                  <div className="mb-5 mt-3">
                    <h5 className="h6 mb-1 font-weight-bold">
                      Generative AI analysis of your query about this candidate
                      :
                    </h5>
                    <div>{renderHtml(evaluationResult)}</div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {isOpenFeedbackModal && (
          <Modal
            open={isOpenFeedbackModal}
            modalHeading="Provide Feedback"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissFeedbackModal}
            onRequestSubmit={this.handleSubmitRecruiterFeedback}>
            <TextArea
              hideLabel
              name="recruiterFeedback"
              value={recruiterFeedback}
              id="recruiterFeedback"
              placeholder="Enter your feedback here"
              onChange={this.handleChange}
              invalid={isRecFeedbackInvalid}
              invalidText="Please enter valid feedback"
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  personalSummary: state.CandidateInfo.personalSummary,
});

const mapDispatchToProps = {
  getPersonalSummary,
  searchAiInsights,
  getAiInsightsStatusById,
  postFeedbackForAiInsights,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidatePersonalSummary);
