import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'carbon-components-react';
import './BackRow.css';

const finalRow = (i, paths) => i === paths.length - 1;

const BackRow = ({
  showBackButton,
  handleBackClick,
  paths,
  history: { push },
}) => (
  <div className="mt-1">
    <Breadcrumb className="xpa-c-backrow">
      {showBackButton && (
        <BreadcrumbItem
          className="text-uppercase"
          onClick={handleBackClick}
          href="javascript:void(0)">
          <span>&#171;&nbsp;back to previous page</span>
        </BreadcrumbItem>
      )}
      {paths &&
        paths.map((path, i) =>
          !finalRow(i, paths) ? (
            <span />
          ) : (
            <span className="d-flex xpa-c-backrow--item" key={path.id}>
              <span
                key={path.id}
                onClick={!finalRow(i, paths) ? () => push(path.href) : (f) => f}
                className="text-uppercase">
                {path.name}
              </span>
              &nbsp;
            </span>
          )
        )}
    </Breadcrumb>
    <hr className="mt-2 mb-2 ml-0 mr-0" />
  </div>
);

export default withRouter(BackRow);
