import {
  REQUEST_GET_ASSESSMENT_SCENARIOS,
  SUCCESS_GET_ASSESSMENT_SCENARIOS,
  ERROR_GET_ASSESSMENT_SCENARIOS,
  REQUEST_UPDATE_ASSESSMENT_SCENARIOS,
  SUCCESS_UPDATE_ASSESSMENT_SCENARIOS,
  ERROR_UPDATE_ASSESSMENT_SCENARIOS,
  REQUEST_GET_ASSESSMENT_CANDIDATES,
  SUCCESS_GET_ASSESSMENT_CANDIDATES,
  ERROR_GET_ASSESSMENT_CANDIDATES,
  REQUEST_ADD_ASSESSMENT_CANDIDATE,
  SUCCESS_ADD_ASSESSMENT_CANDIDATE,
  ERROR_ADD_ASSESSMENT_CANDIDATE,
  REQUEST_UPDATE_ASSESSMENT_CANDIDATE,
  SUCCESS_UPDATE_ASSESSMENT_CANDIDATE,
  ERROR_UPDATE_ASSESSMENT_CANDIDATE,
  REQUEST_ALL_COUNTRIES,
  SUCCESS_ALL_COUNTRIES,
  ERROR_ALL_COUNTRIES,
  REQUEST_UPLOAD_ASSESS_CANDIDATES_DATA,
  SUCCESS_UPLOAD_ASSESS_CANDIDATES_DATA,
  ERROR_UPLOAD_ASSESS_CANDIDATES_DATA,
  REQUEST_GET_ASSESSMENT_CANDIDATE_INFO,
  SUCCESS_GET_ASSESSMENT_CANDIDATE_INFO,
  ERROR_GET_ASSESSMENT_CANDIDATE_INFO,
  REQUEST_GET_ASSESS_COMPANY_TYPES,
  SUCCESS_GET_ASSESS_COMPANY_TYPES,
  ERROR_GET_ASSESS_COMPANY_TYPES,
  REQUEST_GET_ASSESS_COMPANIES,
  SUCCESS_GET_ASSESS_COMPANIES,
  ERROR_GET_ASSESS_COMPANIES,
  REQUEST_CREATE_ASSESS_COMPANY,
  SUCCESS_CREATE_ASSESS_COMPANY,
  ERROR_CREATE_ASSESS_COMPANY,
  REQUEST_GET_ASSESSMENT_TYPES,
  SUCCESS_GET_ASSESSMENT_TYPES,
  ERROR_GET_ASSESSMENT_TYPES,
  REQUEST_UPDATE_CAND_ASSESS_LINK_STATUS,
  SUCCESS_UPDATE_CAND_ASSESS_LINK_STATUS,
  ERROR_UPDATE_CAND_ASSESS_LINK_STATUS,
  REQUEST_UPLOAD_SCHOOLS_LIST,
  SUCCESS_UPLOAD_SCHOOLS_LIST,
  ERROR_UPLOAD_SCHOOLS_LIST,
  REQUEST_GET_ASSESS_REPORT,
  SUCCESS_GET_ASSESS_REPORT,
  ERROR_GET_ASSESS_REPORT,
  REQUEST_GET_ASSESS_REPORT_STATUS,
  SUCCESS_GET_ASSESS_REPORT_STATUS,
  ERROR_GET_ASSESS_REPORT_STATUS,
  REQUEST_GET_ASSESS_REPORT_DROPDOWN_INFO,
  SUCCESS_GET_ASSESS_REPORT_DROPDOWN_INFO,
  ERROR_GET_ASSESS_REPORT_DROPDOWN_INFO,
  REQUEST_UPLOAD_EMAIL_DOMAINS,
  SUCCESS_UPLOAD_EMAIL_DOMAINS,
  ERROR_UPLOAD_EMAIL_DOMAINS,
  REQUEST_GET_EMAIL_DOMAINS,
  SUCCESS_GET_EMAIL_DOMAINS,
  ERROR_GET_EMAIL_DOMAINS,
  REQUEST_EXPORT_ASSESSMENT_CANDIDATES,
  SUCCESS_EXPORT_ASSESSMENT_CANDIDATES,
  ERROR_EXPORT_ASSESSMENT_CANDIDATES,
  REQUEST_UPLOAD_CAND_NRIC,
  SUCCESS_UPLOAD_CAND_NRIC,
  ERROR_UPLOAD_CAND_NRIC,
  REQUEST_GET_CAND_ASSESS_STATUS_THIRD_PARTY,
  SUCCESS_GET_CAND_ASSESS_STATUS_THIRD_PARTY,
  ERROR_GET_CAND_ASSESS_STATUS_THIRD_PARTY,
  REQUEST_UPLOAD_TEST_BOOKING_FILE,
  SUCCESS_UPLOAD_TEST_BOOKING_FILE,
  ERROR_UPLOAD_TEST_BOOKING_FILE,
  REQUEST_EXPORT_CAND_TEST_BOOKING,
  SUCCESS_EXPORT_CAND_TEST_BOOKING,
  ERROR_EXPORT_CAND_TEST_BOOKING,
  REQUEST_UPLOAD_TEST_BOOKING_USAGE_REPORT,
  SUCCESS_UPLOAD_TEST_BOOKING_USAGE_REPORT,
  ERROR_UPLOAD_TEST_BOOKING_USAGE_REPORT,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  assessScenarios: [],
  assessScenarioLocations: [],
  assessCandidates: [],
  assessCandidatesCount: 0,
  countries: [],
  assessCandInfo: {},
  assessCompanyTypes: [],
  assessTestTypes: [],
  summaryStats: {},
};

const Assessments = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: false,
        assessScenarios: payload?.data || [],
        assessScenarioLocations: payload?.additionalInfo?.locationValues || [],
      };
    case ERROR_GET_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: false,
      };
    case ERROR_UPDATE_ASSESSMENT_SCENARIOS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESSMENT_CANDIDATES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESSMENT_CANDIDATES:
      return {
        ...state,
        loading: false,
        assessCandidates: payload?.data || [],
        assessCandidatesCount: payload?.count,
        summaryStats: payload?.stats || {},
      };
    case ERROR_GET_ASSESSMENT_CANDIDATES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ADD_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ADD_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: false,
      };
    case ERROR_ADD_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPDATE_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: false,
      };
    case ERROR_UPDATE_ASSESSMENT_CANDIDATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ALL_COUNTRIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ALL_COUNTRIES:
      return {
        ...state,
        countries: payload,
        loading: false,
        error: null,
      };
    case ERROR_ALL_COUNTRIES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_ASSESS_CANDIDATES_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_ASSESS_CANDIDATES_DATA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_ASSESS_CANDIDATES_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESSMENT_CANDIDATE_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESSMENT_CANDIDATE_INFO:
      return {
        ...state,
        assessCandInfo: payload?.data?.[0] || {},
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESSMENT_CANDIDATE_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESS_COMPANY_TYPES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESS_COMPANY_TYPES:
      return {
        ...state,
        assessCompanyTypes: payload?.data || [],
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESS_COMPANY_TYPES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESS_COMPANIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESS_COMPANIES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESS_COMPANIES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_ASSESS_COMPANY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_ASSESS_COMPANY:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_ASSESS_COMPANY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_ASSESSMENT_TYPES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESSMENT_TYPES:
      return {
        ...state,
        assessTestTypes: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESSMENT_TYPES:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case REQUEST_UPDATE_CAND_ASSESS_LINK_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPDATE_CAND_ASSESS_LINK_STATUS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPDATE_CAND_ASSESS_LINK_STATUS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case REQUEST_UPLOAD_SCHOOLS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_SCHOOLS_LIST:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_SCHOOLS_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_ASSESS_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESS_REPORT:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESS_REPORT:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    // case REQUEST_GET_ASSESS_REPORT_STATUS:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    // case SUCCESS_GET_ASSESS_REPORT_STATUS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //   };
    // case ERROR_GET_ASSESS_REPORT_STATUS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: payload.error,
    //   };
    case REQUEST_GET_ASSESS_REPORT_DROPDOWN_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ASSESS_REPORT_DROPDOWN_INFO:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_ASSESS_REPORT_DROPDOWN_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_UPLOAD_EMAIL_DOMAINS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_EMAIL_DOMAINS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_EMAIL_DOMAINS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_EMAIL_DOMAINS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_EMAIL_DOMAINS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_EMAIL_DOMAINS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_EXPORT_ASSESSMENT_CANDIDATES: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_EXPORT_ASSESSMENT_CANDIDATES: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_EXPORT_ASSESSMENT_CANDIDATES: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_UPLOAD_CAND_NRIC: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_UPLOAD_CAND_NRIC: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_UPLOAD_CAND_NRIC: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_GET_CAND_ASSESS_STATUS_THIRD_PARTY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_GET_CAND_ASSESS_STATUS_THIRD_PARTY: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_GET_CAND_ASSESS_STATUS_THIRD_PARTY: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_UPLOAD_TEST_BOOKING_FILE: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_UPLOAD_TEST_BOOKING_FILE: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_UPLOAD_TEST_BOOKING_FILE: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_EXPORT_CAND_TEST_BOOKING: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_EXPORT_CAND_TEST_BOOKING: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_EXPORT_CAND_TEST_BOOKING: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_UPLOAD_TEST_BOOKING_USAGE_REPORT: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_UPLOAD_TEST_BOOKING_USAGE_REPORT: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_UPLOAD_TEST_BOOKING_USAGE_REPORT: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default:
      return state;
  }
};

export default Assessments;
