import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput,
  Modal,
  Tile,
  Button,
  InlineNotification,
} from 'carbon-components-react';
import GenericDataTable from '../../../components/common/GenericDataTable';
import Dropdown from '../../../components/common/Dropdown';
import moment from 'moment';
import BackRow from '../../../components/common/BackRow';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import { getMeetingsReportInfo } from '../../../actions/CMSMeetings';
import isEmail from 'validator/lib/isEmail';
import nric from 'nric';
import NoContentBox from '../../../components/common/NoContentBox';
import { getUserMappings } from '../../../actions/AdminSection';
import { lowerAndTrim } from '../../../utils/helpers';
class CMSReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      isExporting: false,
      startDate: null,
      endDate: null,
      selectedReportType: { label: 'Meeting Report', value: 'meetingReport' },
      selectedAttendanceStatusList: [],
      selectedMeetingStatusList: [],
      selectedMeetingTypeList: [],
      excludedPanelMembers: [],
    };
  }

  componentDidMount() {
    this.handleGetUserMappings();
  }

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleDropdownChange = (v, metaValue, name, actionType) => {
    const { action } = actionType || {};
    this.setState(
      {
        [name]: metaValue,
      },
      () => {
        const {
          selectedPanelMemList,
          selectedReportType,
          excludedPanelMembers,
        } = this.state;
        if (name === 'selectedReportType') {
          if (
            selectedReportType?.value === 'paymentReport' &&
            selectedPanelMemList &&
            Array.isArray(selectedPanelMemList) &&
            selectedPanelMemList.length > 0
          ) {
            const excludedPanelMemUserIds =
              excludedPanelMembers &&
              Array.isArray(excludedPanelMembers) &&
              excludedPanelMembers.length > 0 &&
              excludedPanelMembers?.map(({ userId }) => userId);
            this.setState({
              selectedPanelMemList: selectedPanelMemList?.filter(
                ({ value }) => !excludedPanelMemUserIds.includes(value)
              ),
            });
          }
          this.handleGetUserMappings(null);
        } else if (name === 'selectedPanelMemList' && action === 'clear') {
          this.handleGetUserMappings(null);
        }
      }
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  onDatePickerChange = (date, inputName) => {
    this.setState({ [`${inputName}`]: date, isDateRangeInvalid: false });
  };

  validateInputs = () => {
    const {
      selectedReportType,
      startDate,
      endDate,
      selectedAttendanceStatusList,
      selectedMeetingStatusList,
      selectedMeetingTypeList,
    } = this.state;

    const invalidObj = {};
    invalidObj.isReportTypeInvalid = selectedReportType?.value == null;
    invalidObj.isStartDateInvalid = startDate == null;
    invalidObj.isEndDateInvalid = endDate == null;
    invalidObj.isDateRangeInvalid =
      (startDate && endDate && startDate > endDate) || false;
    this.setState({ ...invalidObj });
    return !!Object.values(invalidObj).every((val) => val === false);
  };

  handlePreview = (exportData, shareReportViaEmail) => {
    const areAllInputsValid = this.validateInputs();
    this.setState({ areAllInputsValid }, () => {
      if (areAllInputsValid) {
        const {
          activePage,
          itemsPerPage,
          selectedReportType,
          startDate,
          endDate,
          selectedAttendanceStatusList,
          selectedMeetingStatusList,
          selectedMeetingTypeList,
          selectedPanelMemList,
          excludedPanelMembers,
        } = this.state;
        const data = {
          activePage,
          itemsPerPage,
          reportType: selectedReportType?.value,
          startDate: startDate && `"${moment(startDate).format('YYYY-MM-DD')}"`,
          endDate: endDate && `"${moment(endDate).format('YYYY-MM-DD')}"`,
          attendanceStatus: selectedAttendanceStatusList?.map(
            (itm) => itm?.value
          ),
          meetingStatus: selectedMeetingStatusList?.map((itm) => itm?.value),
          meetingType: selectedMeetingTypeList?.map((itm) => itm?.value),
          panelUserIds: selectedPanelMemList?.map((itm) => itm?.value),
          excludedPanelUserIds:
            selectedReportType?.value === 'paymentReport' &&
            excludedPanelMembers.map((itm) => itm?.userId),
        };

        const updateState = {};
        if (exportData) {
          data.exportData = true;
          updateState.isExporting = true;
        }

        this.setState({ ...updateState }, () => {
          this.props
            .getMeetingsReportInfo(data)
            .then((res) => {
              const { error } = res || {};
              if (res && !res.error) {
                if (exportData) {
                  const { fileUrl } = res || {};
                  if (fileUrl) {
                    if (selectedReportType?.value === 'paymentReport')
                      window.open(fileUrl);
                    else window.open(fileUrl, '_self');
                    this.setState({ isExporting: false });
                  }
                } else {
                  const { list } = res || {};
                  this.setState({
                    previewDataList: list || [],
                  });
                }
              } else {
                this.setState({
                  isExporting: false,
                  errorNotif: true,
                  errMsg:
                    error?.message ||
                    'An error has ocurred while processing your request. Please try again later.',
                });
              }
            })
            .catch((error) => {
              console.error('error', error);
              this.setState({
                isExporting: false,
                errorNotif: true,
              });
            });
        });
      }
    });
  };

  handleGetUserMappings = (value) => {
    const data = {
      searchKey: value,
      roleIds: [7],
      noLimit: true,
    };

    this.props
      .getUserMappings(data)
      .then((res) => {
        if (res && !res.error) {
          const { list, excludedMembersInCmsPaymentReport } = res || {};
          this.setState({
            panelMemUsersList: list,
            excludedPanelMembers: excludedMembersInCmsPaymentReport,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
      });
  };

  onInputUserMappingsChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimStart();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[ a-zA-Z0-9!()#+.]{1,300}/.test(value)) {
      this.handleGetUserMappings(value);
      return value;
    }
  };

  render() {
    const {
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      isReportTypeInvalid,
      selectedReportType,
      selectedMeetingTypeList,
      selectedAttendanceStatusList,
      selectedMeetingStatusList,
      previewDataList = [],
      isOpenSendRepViaEmailModal,
      isEmailIdInValid,
      shareToAddress,
      isExporting,
      areAllInputsValid = true,
      startDate,
      endDate,
      isStartDateInvalid,
      isEndDateInvalid,
      isDateRangeInvalid,
      panelMemUsersList,
      selectedPanelMemList,
      excludedPanelMembers = [],
    } = this.state || {};

    const { user } = this.props;
    const { roleId } = user || {};

    let cmsMeetingHeaders = [
      {
        key: 'header_0',
        header: 'Purpose',
        className: 'w-25',
      },
      {
        key: 'header_1',
        header: 'Meeting Date',
      },
      {
        key: 'header_2',
        header: 'Meeting Type',
      },
      {
        key: 'header_3',
        header: 'Meeting Start Time',
      },
      {
        key: 'header_4',
        header: 'Meeting Status',
      },
    ];

    if (roleId == 7 || roleId == 13) {
      cmsMeetingHeaders.push({
        key: 'header_5',
        header: 'Attendance Status',
      });
    }

    let meetingReportTypes = [
      { label: 'Meeting Report', value: 'meetingReport' },
    ];

    if (roleId == 1 || roleId == 6 || roleId == 11) {
      meetingReportTypes.push({
        label: 'Payment Report',
        value: 'paymentReport',
      });
    }

    meetingReportTypes.push({
      label: 'Attendance Report',
      value: 'attendanceReport',
    });

    let finalPanelMemUsersList = [];

    if (
      panelMemUsersList &&
      Array.isArray(panelMemUsersList) &&
      panelMemUsersList.length > 0
    ) {
      if (selectedReportType?.value === 'paymentReport') {
        const excludedPanelMemUserIds =
          excludedPanelMembers &&
          Array.isArray(excludedPanelMembers) &&
          excludedPanelMembers.length > 0 &&
          excludedPanelMembers?.map(({ userId }) => userId);
        finalPanelMemUsersList = panelMemUsersList.filter(
          ({ userId }) =>
            !excludedPanelMemUserIds.includes(lowerAndTrim(userId))
        );
      } else {
        finalPanelMemUsersList = panelMemUsersList;
      }
    }

    return (
      <div>
        <BackRow
          paths={[
            { id: 'csc', name: 'ARS', href: '/app/csc' },
            {
              id: 'cms-reports',
              name: 'Meetings Reports',
              href: '',
            },
          ]}
        />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        <Tile>
          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Report Type <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <Dropdown
                id="reportType"
                title=""
                custom
                mainClassName="bx--form-item mb-0"
                className={`w-100 ${isReportTypeInvalid ? 'invalid' : ''}`}
                titleClass="mb-2"
                labelClass="pt-2"
                onInputChange={() => {}}
                options={meetingReportTypes}
                name="selectedReportType"
                placeholder="Report Type"
                onChange={(value, e, name) =>
                  this.handleDropdownChange(value, e, name)
                }
                selectedValue={selectedReportType}
              />
              {isReportTypeInvalid && (
                <div className="xpa-error-msg mt-1">
                  Please select report type
                </div>
              )}
            </div>
          </div>

          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Start Date <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <DatePicker
                selected={startDate}
                name="startDate"
                id="startDate"
                onChange={(date) => this.onDatePickerChange(date, 'startDate')}
                dateFormat="dd/MM/yyyy"
                placeholderText="Start Date"
                className="css-h-d-input"
                isClearable
                autoComplete="off"
                maxDate={
                  selectedReportType?.value == 'paymentReport'
                    ? new Date()
                    : null
                }
              />
              {isStartDateInvalid && (
                <div className="xpa-error-msg mt-1">
                  Please select Start Date
                </div>
              )}
            </div>
          </div>

          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              End Date <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <div className="d-flex flex-column">
                <div>
                  <DatePicker
                    selected={endDate}
                    name="endDate"
                    id="endDate"
                    onChange={(date) =>
                      this.onDatePickerChange(date, 'endDate')
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    className="css-h-d-input"
                    isClearable
                    autoComplete="off"
                    maxDate={
                      selectedReportType?.value == 'paymentReport'
                        ? new Date()
                        : null
                    }
                  />
                  {isEndDateInvalid && (
                    <div className="xpa-error-msg mt-1">
                      Please select End Date
                    </div>
                  )}
                </div>

                {isDateRangeInvalid && (
                  <div className="xpa-error-msg mt-1">
                    Please select a valid date. Start date cannot be greater
                    than end date.
                  </div>
                )}
              </div>
            </div>
          </div>

          {(roleId == 7 || roleId == 13) && (
            <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
              <div className="bx--col-lg-4">Attendance Status</div>
              <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                <Dropdown
                  placeholder="Attendance Status"
                  mainClassName="bx--form-item mb-0"
                  titleClass=""
                  labelClass=""
                  isMulti
                  onInputChange={() => {}}
                  className="w-100"
                  options={[
                    {
                      label: 'Available',
                      value: 'Available',
                    },
                    {
                      label: 'Not Available',
                      value: 'Not Available',
                    },
                    {
                      label: 'To Be Confirmed',
                      value: 'To Be Confirmed',
                    },
                    {
                      label: 'Scheduled',
                      value: 'Scheduled',
                    },
                  ]}
                  onChange={(value, e, name) =>
                    this.handleDropdownChange(value, e, name)
                  }
                  name="selectedAttendanceStatusList"
                  selectedValue={selectedAttendanceStatusList}
                />
              </div>
            </div>
          )}

          {selectedReportType?.value !== 'attendanceReport' && (
            <>
              <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                <div className="bx--col-lg-4">Meeting Status</div>
                <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                  <Dropdown
                    placeholder="Meeting Status"
                    mainClassName="bx--form-item mb-0"
                    titleClass=""
                    labelClass=""
                    isMulti
                    onInputChange={() => {}}
                    className="w-100"
                    options={[
                      {
                        label: 'Tentative',
                        value: 'Tentative',
                      },
                      {
                        label: 'Confirmed',
                        value: 'Confirmed',
                      },
                      {
                        label: 'Completed',
                        value: 'Completed',
                      },
                      {
                        label: 'Cancelled',
                        value: 'Cancelled',
                      },
                    ]}
                    onChange={(value, e, name) =>
                      this.handleDropdownChange(value, e, name)
                    }
                    name="selectedMeetingStatusList"
                    selectedValue={selectedMeetingStatusList}
                  />
                </div>
              </div>

              <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
                <div className="bx--col-lg-4">Meeting Type</div>
                <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                  <Dropdown
                    placeholder="Meeting Type"
                    mainClassName="bx--form-item mb-0"
                    titleClass=""
                    labelClass=""
                    isMulti
                    onInputChange={() => {}}
                    className="w-100"
                    options={[
                      {
                        label: 'PSSB',
                        value: 'PSSB',
                      },
                      {
                        label: 'Commission',
                        value: 'Commission',
                      },
                      {
                        label: 'PSSB/Commission',
                        value: 'PSSB/Commission',
                      },
                      {
                        label: 'Event',
                        value: 'Event',
                      },
                      {
                        label: 'PS',
                        value: 'PS',
                      },
                    ]}
                    onChange={(value, e, name) =>
                      this.handleDropdownChange(value, e, name)
                    }
                    name="selectedMeetingTypeList"
                    selectedValue={selectedMeetingTypeList}
                  />
                </div>
              </div>
            </>
          )}

          {roleId != 7 && roleId != 13 && (
            <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
              <div className="bx--col-lg-4">Panel Members</div>
              <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
                <Dropdown
                  id="choose-panel-mem-dd"
                  title=""
                  mainClassName="bx--form-item mb-0"
                  titleClass=""
                  labelClass=""
                  custom
                  isMulti
                  label=""
                  className="w-100"
                  options={finalPanelMemUsersList?.map((res) => {
                    const { userId, firstname, lastname } = res || {};
                    return {
                      label: `${firstname} ${lastname}`,
                      value: userId,
                    };
                  })}
                  placeholder="Start typing to choose Panel members"
                  onChange={(value, e, name, action) =>
                    this.handleDropdownChange(value, e, name, action)
                  }
                  onInputChange={(value, cb) => {
                    this.onInputUserMappingsChange(value, cb, roleId);
                  }}
                  name="selectedPanelMemList"
                  selectedValue={selectedPanelMemList}
                />
              </div>
            </div>
          )}

          <div className="bx--row mb-2 mt-3">
            {/* {selectedReportType?.value === 'meetingReport' && (
              <div className="mx-2">
                <Button kind="tertiary" onClick={() => this.handlePreview()}>
                  Preview
                </Button>
              </div>
            )} */}
            <div className="mx-2">
              <Button
                kind="primary"
                onClick={() => this.handlePreview(true)}
                disabled={isExporting}>
                {isExporting ? 'Exporting...' : 'Export'}
              </Button>
            </div>
            {/* <div className="mx-2">
              <Button
                kind="primary"
                onClick={() => this.handlePreview(false, true)}>
                Send Report via Email
              </Button>
            </div> */}
          </div>

          {!areAllInputsValid && (
            <div className="xpa-error-msg">
              Please check each field and fix any errors that are showing.
            </div>
          )}
        </Tile>

        {/* {selectedReportType?.value === 'meetingReport' && (
          <div className="mt-2">
            {previewDataList &&
            Array.isArray(previewDataList) &&
            previewDataList.length > 0 ? (
              <GenericDataTable
                tableWrapClassName="x-table-scroll y-table-scroll-hidden"
                className="attendees-table"
                tbodyClass="candidate-background-color"
                otherActions={[]}
                headers={cmsMeetingHeaders}
                rows={
                  previewDataList?.slice(0, 5)?.map((data, idx) => {
                    const {
                      meetingId,
                      meetingTitle,
                      agenda,
                      startDate,
                      startTime,
                      timePeriod,
                      meetingType,
                      purpose,
                      internalRemarks,
                      status,
                      attendanceType,
                      Usermeetingmaps,
                    } = data;

                    const commissionMemListOuter =
                      (Usermeetingmaps &&
                        Array.isArray(Usermeetingmaps) &&
                        Usermeetingmaps.map((itm) => {
                          const {
                            attendanceStatus,
                            remarks,
                            Selectionboard,
                            Userinfo,
                          } = itm || {};
                          const { aliasName } = Selectionboard || {};
                          const { userId, firstname, lastname } =
                            Userinfo || {};
                          return {
                            userId,
                            aliasName,
                            firstname,
                            lastname,
                            attendanceStatus,
                            remarks,
                          };
                        })) ||
                      [];

                    const { aliasName, firstname, lastname, attendanceStatus } =
                      (commissionMemListOuter &&
                        Array.isArray(commissionMemListOuter) &&
                        commissionMemListOuter.length > 0 &&
                        commissionMemListOuter?.[0]) ||
                      {};

                    return {
                      isExpanded: false,
                      id: meetingId,
                      header_0: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Purpose:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="ml-2 text-capitalize bx--type-zeta">
                                {purpose ?? '-'}
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Meeting Date:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>
                              {startDate
                                ? moment(startDate).local().format('DD/MM/YYYY')
                                : '-'}
                            </div>
                          </div>
                        </div>
                      ),
                      header_2: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Meeting Type:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>{meetingType}</div>
                          </div>
                        </div>
                      ),
                      header_3: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Meeting Start Time:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div>
                              {startTime
                                ? moment(startTime).local().format('h:mm a')
                                : '-'}
                            </div>
                          </div>
                        </div>
                      ),

                      header_4: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Meeting Status:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {status}
                          </div>
                        </div>
                      ),
                      header_5: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Attendance Status:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <div className="text-capitalize">
                              {attendanceStatus ?? '-'}
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  }) || []
                }
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
            ) : (
              <NoContentBox message="There are no meetings for your current selection." />
            )}
          </div>
        )} */}

        {isOpenSendRepViaEmailModal && (
          <Modal
            open={isOpenSendRepViaEmailModal}
            modalHeading="Export Report Via Email"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissSendRepViaEmailModal}
            onRequestSubmit={this.handleSendReportViaEmail}>
            <div className="">
              <TextInput
                id="emailshare"
                className="mb-2"
                labelText="To:"
                name="shareToAddress"
                invalid={isEmailIdInValid}
                invalidText="Please enter valid email address"
                placeholder="Enter comma separated email address"
                onChange={(ev) => {
                  this.setState({
                    [ev.target.name]: ev.target.value,
                    isEmailIdValid: false,
                  });
                }}
                value={shareToAddress}
              />
            </div>
          </Modal>
        )}

        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
  loading: state.CMSMeetings.loading || state.AdminSection.loading,
});

const mapDispatchToProps = {
  getMeetingsReportInfo,
  getUserMappings,
};

export default connect(mapStateToProps, mapDispatchToProps)(CMSReports);
