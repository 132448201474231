import React from 'react';
import { Tile, Button } from 'carbon-components-react';
import NoContentImg from '../../assets/images/NoContentImg.png';

const NoContentBox = ({
  message,
  name,
  showButtons,
  buttonName,
  handleOnClickButtons,
  secondaryMessage,
  hideTile = false,
}) => (
  <div className={hideTile ? '' : 'bx--tile'}>
    <div className="card-body text-center">
      <div className="empty-icon">
        {/* <img
          style={{ width: '290px' }}
          src={NoContentImg}
          alt="No content image"
        /> */}
      </div>
      <p className="empty-title h5">{message}</p>
      <p className="empty-title text-dark">{secondaryMessage}</p>
      {showButtons === true && (
        <Button kind="tertiary" onClick={handleOnClickButtons}>
          {buttonName}
        </Button>
      )}
    </div>
  </div>
);

export default NoContentBox;
