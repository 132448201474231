import React, { Component } from 'react';
import { connect } from 'react-redux';

import BackRow from '../../../components/common/BackRow';
import GenericDataTable from '../../../components/common/GenericDataTable';
import NoContentBox from '../../../components/common/NoContentBox';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import {
  Button,
  Pagination,
  FileUploaderButton,
  Tile,
  InlineNotification,
  Select,
  SelectItem,
} from 'carbon-components-react';

import {
  getAsessmentCandidates,
  addAsessmentCandidate,
  getAllCountries,
  getAsessmentScenarios,
  uploadAssessCandidatesData,
  getAsessCompanyTypes,
  getAsessCompaniesById,
  createAssessCompany,
  exportAsessmentCandidates,
  getAsessCandReportStatusByUuid,
} from '../../../actions/Assessments';
import GenericTabs from '../../../components/common/GenericTabs';
import moment from 'moment';
import SampleAssessCandidateTemp from '../../../assets/files/Assess_Candidates_Upload_Template_ARS.xlsx';
import AssessAddCandidate from './AssessAddCandidate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const headers = [
  {
    key: 'header_0',
    header: 'Name',
  },
  {
    key: 'header_1',
    header: 'NRIC',
  },
  {
    key: 'header_2',
    header: 'Organization/School',
  },
  {
    key: 'header_3',
    header: 'Email',
  },
  {
    key: 'header_4',
    header: 'Contact Number',
  },
  {
    key: 'header_5',
    header: 'Scenario',
  },
  {
    key: 'header_6',
    header: 'Personality Test Status',
  },
];

class AssessCandidateList extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'assess-candidates', name: 'Candidates', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      activePage: 1,
      itemsPerPage: 10,
      searchKey: '',
      countryId: 65,
      startDate: moment().subtract(6, 'months').toDate(),
      endDate: moment().toDate(),
      isExporting: false,
      scenario: 'Scenario',
    };
  }

  componentDidMount() {
    // this.props.getAllCountries();
    this.props.getAsessmentScenarios();
    this.props.getAsessCompanyTypes({ ctypeIds: [1, 2] });
    this.handleGetCandidates();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getPayload = () => {
    const {
      activePage,
      itemsPerPage,
      searchKey,
      startDate,
      endDate,
      scenario,
    } = this.state;
    return {
      activePage,
      itemsPerPage,
      searchKey,
      startDate: `"${moment(startDate).format('YYYY-MM-DD')}"`,
      endDate: `"${moment(endDate).format('YYYY-MM-DD')}"`,
      scenarioId:
        scenario && scenario !== 'All' && scenario !== 'Scenario'
          ? scenario
          : null,
    };
  };

  handleGetCandidates = () => {
    const data = this.getPayload();
    this.props.getAsessmentCandidates(data);
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetCandidates();
    });
  };

  dismissAddCandModal = () => {
    this.setState({
      isAddCandModalOpen: false,
    });
  };

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState(
      {
        searchKey: event.target.value,
        isLoading: true,
        activePage: 1,
        itemsPerPage: 10,
      },
      () => {
        this.searchTimer = setTimeout(() => {
          this.handleGetCandidates();
        }, 1000);
      }
    );
  };

  openAddCandModal = () => {
    this.setState({ isAddCandModalOpen: true });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleUploadCandidates = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('excel', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadAssessCandidatesData(currFile)
        .then((res) => {
          if (res && !res.error) {
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                this.handleGetCandidates();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  dismissAddCandModal = () => {
    this.setState({ isAddCandModalOpen: false });
  };

  handleAddCandInfo = (data) => {
    const { email } = data || {};
    this.setState({ isSubmitting: true }, () => {
      this.props
        .addAsessmentCandidate([data])
        .then((res) => {
          const { isSuccess, error } = (res && res[email]) || {};
          const { message } = error || {};
          this.dismissAddCandModal();
          if (!!isSuccess) {
            this.setState(
              {
                successNotif: true,
                notifMsg: 'Candidate has been added successfully',
                isSubmitting: false,
              },
              () => {
                this.handleGetCandidates();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
              errMsg: message,
              isSubmitting: false,
            });
          }
        })
        .catch((e) => {
          this.dismissAddCandModal();
          this.setState({
            errorNotif: true,
            isSubmitting: false,
          });
        });
    });
  };

  onDatePickerChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleSubmitDates = () => {
    this.setState(
      {
        isStartEndDatesInvalid: this.state.startDate > this.state.endDate,
      },
      () => {
        if (!this.state.isStartEndDatesInvalid) {
          this.handleGetCandidates();
        }
      }
    );
  };

  handleTabClick = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  handleExportCands = () => {
    const data = this.getPayload();
    data.exportData = true;

    this.setState({ isExporting: true }, () => {
      this.props
        .exportAsessmentCandidates(data)
        .then((res) => {
          if (res && !res.error) {
            const { exportUuid } = res || {};
            this.interval = setInterval(() => {
              this.props
                .getAsessCandReportStatusByUuid(exportUuid)
                .then((res) => {
                  if (res && !res.error) {
                    const { url } = res || {};
                    if (url) {
                      window.open(url, '_self');
                      clearInterval(this.interval);
                      this.setState({ isExporting: false });
                    }
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      isExporting: false,
                      errorNotif: true,
                      errMsg:
                        'An Error has ocurred while processing your request, Please try again later',
                    });
                    setTimeout(() => {
                      this.setState({
                        errMsg: '',
                        errorNotif: false,
                      });
                    }, 5000);
                  }
                })
                .catch((e) => {
                  this.setState({
                    isExporting: false,
                    errorNotif: true,
                  });
                });
            }, 4000);
          }
        })
        .catch((error) => {
          console.log('Error', error);
          this.setState({
            isExporting: false,
            errorNotif: true,
          });
        });
    });
  };

  handleFilterDropDown = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.handleGetCandidates();
      }
    );
  };

  render() {
    const {
      assessCandidates,
      assessCandidatesCount,
      summaryStats,
      assessScenarios,
    } = this.props;
    const {
      successNotif,
      errorNotif,
      notifMsg,
      activePage,
      isAddCandModalOpen,
      errMsg,
      selectedTab,
      isSubmitting,
      startDate,
      endDate,
      isStartEndDatesInvalid,
      isExporting,
      scenario,
    } = this.state;

    const { neo } = summaryStats || {};

    const otherActionButtons = [
      <div className="mr-3">
        <Select
          placeHolderText="Scenario"
          name="scenario"
          id="scenario"
          onChange={this.handleFilterDropDown}
          value={scenario}
          hideLabel
          className="xpa-width w-100">
          <SelectItem
            text="Scenario"
            key="Scenario"
            value="Scenario"
            disabled
            hidden
          />
          <SelectItem text="Select" disabled />
          {assessScenarios &&
            Array.isArray(assessScenarios) &&
            assessScenarios.length > 0 &&
            assessScenarios.map((itm, idx) => {
              const { scenario, scenarioId } = itm || {};
              return (
                <SelectItem
                  text={scenario}
                  key={scenarioId}
                  id={scenarioId}
                  value={scenarioId}
                />
              );
            })}
          <SelectItem text="All" key="All" id="All" value="All" />
        </Select>
      </div>,
      <Button
        className=""
        kind="primary"
        onClick={() => this.openAddCandModal()}>
        Add Candidate
      </Button>,
    ];

    return (
      <div>
        <BackRow paths={this.paths} />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />

        <div className="d-flex justify-content-end mt-3 mt-lg-0">
          <div className="text-right">
            <FileUploaderButton
              buttonLabel="Upload"
              labelText="Upload"
              buttonKind="primary"
              accept={['.xlsx']}
              disableLabelChanges
              name="file"
              onChange={(e) => this.handleUploadCandidates(e)}
            />
            <div className="small mt-2">.xlsx format is allowed</div>
          </div>
          <div className="ml-2">
            <Button
              className="txt-dec-none"
              kind="primary"
              href={SampleAssessCandidateTemp}>
              Download Template
            </Button>
          </div>
          <div className="ml-4">
            <Button onClick={this.handleExportCands} disabled={isExporting}>
              {isExporting ? 'Exporting...' : 'Export'}
            </Button>
          </div>
        </div>

        <div className="mb-3">
          <GenericTabs
            selected={selectedTab}
            tabContent={[
              {
                label: 'Details',
                value: '',
                onClick: () => {
                  this.handleTabClick(0);
                },
              },
              {
                label: 'Summary',
                value: '',
                onClick: () => {
                  this.handleTabClick(1);
                },
              },
            ]}
          />
        </div>

        {selectedTab === 0 && (
          <>
            {isStartEndDatesInvalid && (
              <InlineNotification
                lowContrast
                onCloseButtonClick={() => {}}
                className="mt-1 mb-1"
                title="Please select a valid start date and end date."
                subtitle=""
                hideCloseButton
                iconDescription="describes the close button"
                kind="error"
              />
            )}
            <div className="bx-row my-2">
              <div className="d-flex flex-column flex-lg-row">
                <div className="">
                  <div className="bx--label mb-2">From:</div>
                  <div className="">
                    <DatePicker
                      selected={startDate}
                      name="startDate"
                      id="startDate"
                      onChange={(date) =>
                        this.onDatePickerChange(date, 'startDate')
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Start Date"
                      className="css-h-d-input"
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="ml-lg-2 mt-2 mt-lg-0">
                  <div className="bx--label mb-2">To:</div>
                  <div className="">
                    <DatePicker
                      selected={endDate}
                      name="endDate"
                      id="endDate"
                      onChange={(date) =>
                        this.onDatePickerChange(date, 'endDate')
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="End Date"
                      className="css-h-d-input"
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="ml-lg-2 mt-2 mt-lg-4 pt-2">
                  <Button
                    onClick={this.handleSubmitDates}
                    className="mr-2"
                    size="small">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
            <GenericDataTable
              tbodyClass="candidate-background-color"
              placeHolderText="Search by Name / NRIC"
              searchable
              otherActions={otherActionButtons}
              onSearchInputChange={this.handleSearchSubmit}
              headers={headers}
              rows={
                assessCandidates &&
                Array.isArray(assessCandidates) &&
                assessCandidates.length > 0
                  ? assessCandidates.map((interview, idx) => {
                      const {
                        nric,
                        firstName = '',
                        lastName = '',
                        fullName = '',
                        gender,
                        email,
                        countryCode,
                        contactNumber,
                        candidateId,
                        Company,
                        Scenario,
                        assessmentInfo = [],
                      } = interview || {};
                      const nricSubStr =
                        nric &&
                        nric.toString() &&
                        nric.toString().length >= 6 &&
                        nric.toString().substring(1, 5);
                      const finalNric =
                        nric &&
                        nricSubStr &&
                        nric.toString().replace(nricSubStr, 'xxxx');

                      const { displayName: schoolName } = Company || {};
                      const { displayName: scenarioName } = Scenario || {};

                      const { assessmentStatus: personalityTestStatus } =
                        assessmentInfo?.find(
                          (itm) =>
                            itm?.Scenariotestmap?.Test?.testName ===
                            'Neo Profile'
                        ) || {};
                      return {
                        isExpanded: false,
                        id: `row_${idx}`,
                        header_0: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Name:
                            </div>
                            <div className="xpa-link f-w-b h6 mb-0 text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              <a
                                className="css-cand-name"
                                href={`/app/csc/assessments/candidate/${candidateId}`}>
                                {fullName}
                              </a>
                            </div>
                          </div>
                        ),
                        header_1: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              NRIC:
                            </div>
                            <div className="text-uppercase bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {nric}
                            </div>
                          </div>
                        ),
                        header_2: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Organization/School:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {schoolName}
                            </div>
                          </div>
                        ),
                        header_3: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Email:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {email}
                            </div>
                          </div>
                        ),
                        header_4: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Contact Number:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {contactNumber &&
                                `+${countryCode} ${contactNumber}`}
                            </div>
                          </div>
                        ),
                        header_5: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Scenario:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {scenarioName}
                            </div>
                          </div>
                        ),
                        header_6: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Personality Test Status:
                            </div>
                            <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                              {personalityTestStatus || '-'}
                            </div>
                          </div>
                        ),
                      };
                    })
                  : []
              }
            />
            {((assessCandidates &&
              Array.isArray(assessCandidates) &&
              assessCandidates.length <= 0) ||
              !assessCandidates) && (
              <NoContentBox message="There are no candidates available for your current selection" />
            )}
            {assessCandidatesCount > 0 && (
              <Pagination
                onChange={this.handlePaginationChange}
                pageSizes={[10, 20, 30, 40, 50]}
                page={activePage}
                totalItems={assessCandidatesCount}
              />
            )}
          </>
        )}

        {selectedTab === 1 && (
          <Tile>
            <div className="font-weight-bold">
              Total Number of candidates : {neo?.total || 'N/A'}
            </div>
            <div className="mt-3 font-weight-bold">
              Total Number of candidates completed : {neo?.completed || 'N/A'}
            </div>
            <div className="mt-3 font-weight-bold">
              Total Number of PSC Scholarship applicants completed :{' '}
              {neo?.pscCompleted || 'N/A'}
            </div>
          </Tile>
        )}

        {isAddCandModalOpen && (
          <AssessAddCandidate
            dismissAddCandModal={this.dismissAddCandModal}
            handleAddCandInfo={this.handleAddCandInfo}
            isSubmitting={isSubmitting}
          />
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
  assessCandidates: state.Assessments.assessCandidates,
  assessCandidatesCount: state.Assessments.assessCandidatesCount,
  summaryStats: state.Assessments.summaryStats,
  countries: state.Assessments.countries,
  assessScenarios: state.Assessments.assessScenarios,
  assessCompanyTypes: state.Assessments.assessCompanyTypes,
});

const mapDispatchToProps = {
  getAsessmentCandidates,
  addAsessmentCandidate,
  getAllCountries,
  getAsessmentScenarios,
  uploadAssessCandidatesData,
  getAsessCompanyTypes,
  getAsessCompaniesById,
  createAssessCompany,
  exportAsessmentCandidates,
  getAsessCandReportStatusByUuid,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessCandidateList);
