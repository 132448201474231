import React from 'react';
import GenericDataTable from '../common/GenericDataTable';
import NoContentBox from '../common/NoContentBox';
//Common Packages
import { Select, SelectItem } from 'carbon-components-react';

const SecondRounders = (props) => {
  const { data, push, selectedTab, handleToggleShortlistValue, roleId } =
    props || {};
  const headers = [
    {
      key: 'header_0',
      header: 'S/No',
      className: 'w-10',
    },
    {
      key: 'header_1',
      header: 'Name',
      className: 'w-15',
    },
    {
      key: 'header_2',
      header: 'Sex',
      className: 'w-15',
    },
    {
      key: 'header_3',
      header: 'Scholarship Scheme',
      className: 'w-15',
    },
    {
      key: 'header_4',
      header: 'Offered Course',
      className: 'w-15',
    },
    {
      key: 'header_5',
      header: 'Offered Country',
      className: 'w-15',
    },
    {
      key: 'header_6',
      header: 'School',
      className: 'w-15',
    },
  ];
  if (selectedTab === 0) {
    headers.push({
      key: 'header_7',
      header: 'Shortlisted',
      className: 'w-15',
    });
  }
  return (
    <div>
      <GenericDataTable
        className=""
        tbodyClass="candidate-background-color"
        otherActions={[]}
        headers={headers}
        rows={
          data && Array.isArray(data) && data.length > 0
            ? data.map((res, idx) => {
                const {
                  fullName,
                  genderName,
                  scheme,
                  course,
                  country,
                  school,
                  appId,
                  selectionYear,
                  shortlisted,
                } = res || {};
                let finalScheme = '';
                if (scheme) {
                  finalScheme =
                    scheme.toString() &&
                    scheme.toString().includes('public service commission')
                      ? scheme
                          .toString()
                          .replace('public service commission', 'PSC')
                      : scheme;
                }
                let finalCountry = undefined;
                if (country === 'united states of america') {
                  finalCountry = 'United States of America';
                }
                return {
                  isExpanded: false,
                  id: `row_${idx + 1}`,
                  header_0: (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        S/No:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {idx + 1}
                      </div>
                    </div>
                  ),
                  header_1: (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Name:
                      </div>
                      <div
                        className={`text-capitalize f-w-b bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6 ${
                          selectedTab === 0 ? 'xpa-link' : ''
                        }`}>
                        {selectedTab === 0 ? (
                          <a
                            className="css-cand-name"
                            href={`/app/csc/candidate/${appId}/batch/${selectionYear}/status/personal-info`}>
                            {fullName}
                          </a>
                        ) : (
                          <div>{fullName}</div>
                        )}
                      </div>
                    </div>
                  ),
                  header_2: (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Sex:
                      </div>
                      <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {genderName || '-'}
                      </div>
                    </div>
                  ),
                  header_3: (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Scholarship Scheme:
                      </div>
                      <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {finalScheme || '-'}
                      </div>
                    </div>
                  ),
                  header_4: (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Offered Course:
                      </div>
                      <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {course || '-'}
                      </div>
                    </div>
                  ),
                  header_5: (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Offered Country:
                      </div>
                      <div
                        className={`${
                          finalCountry ? '' : 'text-capitalize'
                        } bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6`}>
                        {(finalCountry ? finalCountry : country) || '-'}
                      </div>
                    </div>
                  ),
                  header_6: (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        School:
                      </div>
                      <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {school || '-'}
                      </div>
                    </div>
                  ),
                  header_7: selectedTab === 0 && (
                    <div className="bx--row align-items-center">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Shortlisted:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6 text-center">
                        {roleId === 6 ? (
                          <div className="text-capitalize mr-2">
                            <Select
                              onChange={(e) =>
                                handleToggleShortlistValue(e, appId)
                              }
                              id={`${appId}-shortlisted`}
                              name={`${appId}-shortlisted`}
                              value={shortlisted}
                              hidelabel
                              labelText="">
                              <SelectItem text="TBD" />
                              <SelectItem text="Yes" value="Yes" />
                              <SelectItem text="No" value="No" />
                              <SelectItem
                                text="Yes (Withdrew)"
                                value="Yes (Withdrew)"
                              />
                              <SelectItem
                                text="Yes (Declined)"
                                value="Yes (Declined)"
                              />
                            </Select>
                          </div>
                        ) : (
                          <div
                            style={{ fontSize: '10px' }}
                            className={`text-uppercase small p-3 f-w-b ${
                              shortlisted === 'Yes'
                                ? 'bx--tag bx--tag--green'
                                : shortlisted === 'No'
                                ? 'bx--tag bx--tag--red'
                                : shortlisted === 'Yes (Declined)' ||
                                  shortlisted === 'Yes (Withdrew)'
                                ? 'bx--tag bx--tag--orange'
                                : 'bx--tag bx--tag--dark-gray'
                            }`}>
                            {shortlisted === 'Yes (Declined)' ||
                            shortlisted === 'Yes (Withdrew)' ? (
                              <div className="text-center">
                                <div>Yes</div>
                                <div className="">
                                  {shortlisted === 'Yes (Declined)'
                                    ? '(Declined)'
                                    : '(Withdrew)'}
                                </div>
                              </div>
                            ) : (
                              <span>{shortlisted || '-'}</span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ),
                };
              })
            : []
        }
        selectable={false}
        searchable={false}
        onSearchInputChange={() => {}}
        expandable={false}
        batchActions={false}
      />
      {((data && Array.isArray(data) && data.length <= 0) || !data) && (
        <NoContentBox
          hideTile
          message="There are no candidates available for current selection"
        />
      )}
    </div>
  );
};

export default SecondRounders;
