import React, { Fragment } from 'react';
import {
  Header,
  HeaderName,
} from 'carbon-components-react';
import './Header.css';

const PublicHeader = (props) => {
  const {
    push,
  } = props || {};
  return (
    <Header aria-label="CSC">
      <HeaderName onClick={() => { push && push('/') }} href="javascript:void(0)" prefix="">
        <h2 className="text-light font-weight-bold">ARS</h2>
      </HeaderName>
    </Header>
  );
};

export default PublicHeader;