import React from 'react';
import './Tooltips.css';

const LeadershipTooltip = () => {
  return (
    <div>
      <div className="p-3">
        <div className="f-w-b mb-1">Leadership Indicator</div>
        <div className="mt-2">Tier 1:</div>
        <div className="d-flex my-2">
          <div>·</div>{' '}
          <div className="ml-4">President & VP of Student Council</div>
        </div>
        <div className="d-flex my-2">
          <div>·</div>{' '}
          <div className="ml-4">Pinnacle achievement or badge in UG</div>
        </div>
        <div className="d-flex my-2">
          <div>·</div> <div className="ml-4">CCA Captain or Chairperson</div>
        </div>
        <div className="mt-2">Tier 2:</div>
        <div className="d-flex my-2">
          <div>·</div> <div className="ml-4">House Captain</div>
        </div>
        <div className="d-flex my-2">
          <div>·</div> <div className="ml-4">Student Council EXCO member</div>
        </div>
        <div className="d-flex my-2">
          <div>·</div> <div className="ml-4">UG: Warrant Officer or equiv.</div>
        </div>
        <div className="mt-2">Tier 3:</div>
        <div className="d-flex my-2">
          <div>·</div> <div className="ml-4">CCA or House EXCO Member</div>
        </div>
        <div className="d-flex my-2">
          <div>·</div> <div className="ml-4">Student Councillor</div>
        </div>
        <div className="d-flex my-2">
          <div>·</div> <div className="ml-4">UG: Staff Sergeant or equiv.</div>
        </div>
        <div className="mt-2">
          SOTA uses a different tiering which takes into consideration the
          student’s arts-based project, and level of community involvement.
        </div>
      </div>
    </div>
  );
};

const RepresentationTooltip = () => {
  return (
    <div>
      <div className="p-3">
        <div className="f-w-b mb-1">Representation Indicator</div>
        <div className="mt-2">Tier 1:</div>
        <div className="d-flex my-2">
          <div>·</div>{' '}
          <div className="ml-4">
            Represented SG at international competitions
          </div>
        </div>
        <div className="mt-2">Tier 2:</div>
        <div className="d-flex my-2">
          <div>·</div>{' '}
          <div className="ml-4">
            Represented SG or School at recognised international/regional
            competitions
          </div>
        </div>
        <div className="mt-2">Tier 3:</div>
        <div className="d-flex my-2">
          <div>·</div>{' '}
          <div className="ml-4">
            Represented school at recognised national competition/activities
          </div>
        </div>
        <div className="mt-2">
          SOTA uses a different tiering which takes into consideration the
          student’s arts-based project, and level of community involvement.
        </div>
      </div>
    </div>
  );
};

const PSCPoints = () => {
  return (
    <div>
      <div className="p-3">
        <div className="f-w-b mb-1">PSC Points</div>
        <div className="mt-2">The maximum attainable PSC Points is 70.</div>
      </div>
    </div>
  );
};

const PolytechnicGPA = () => {
  return (
    <div>
      <div className="p-3">
        <div className="f-w-b mb-1">GPA</div>
        <div className="mt-2">
          The maximum attainable GPA is 4.0 for Polytechnics and the Singapore
          Management University (SMU) and 5.0 for the remaining local Institutes
          of Higher Learning.
        </div>
      </div>
    </div>
  );
};

const IBScore = () => {
  return (
    <div>
      <div className="p-3">
        <div className="f-w-b mb-1">IB Score</div>
        <div className="mt-2">The maximum attainable IB Score is 45.</div>
      </div>
    </div>
  );
};

const NUSCap = () => {
  return (
    <div>
      <div className="p-3">
        <div className="f-w-b mb-1">CAP</div>
        <div className="mt-2">
          The maximum attainable NUSHS Diploma CAP is 5.
        </div>
      </div>
    </div>
  );
};

const LeadershipTooltipCCA = () => {
  return (
    <div>
      <div className="py-3">
        <table className="table-border-cca w-100 ">
          <thead>
            <tr>
              <th className="text-center" colspan="2">
                Level of Representation/ Participation/ Involvement
              </th>
            </tr>
          </thead>
          <thead>
            <th>JC/JC Equivalent</th>
            <th>SOTA</th>
          </thead>
          <thead>
            <th>Tier 1</th>
            <th>Tier 1</th>
          </thead>
          <tbody className="table-border-cca">
            <tr>
              <td className="text-left ">
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">President & VP of Student Council</div>
                </div>
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">
                    Pinnacle achievement or badge in UG.
                  </div>
                </div>
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">CCA Captain or Chairperson</div>
                </div>
              </td>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  Leader of major arts-based project; and
                </div>
                <div className="my-2 ml-2">
                  Leader of an overseas and local community service project
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-left ">Tier 2</th>
              <th className="text-left ">Tier 2</th>
            </tr>
            <tr>
              <td className="text-left ">
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">House Captain</div>
                </div>
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">Student Council EXCO member</div>
                </div>
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">UG: Warrant Officer or equiv.</div>
                </div>
              </td>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  Leader of major arts-based project; or
                </div>
                <div className="my-2 ml-2">
                  Leader of an overseas/local community service project.
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-left ">Tier 3</th>
              <th className="text-left ">Tier 3</th>
            </tr>
            <tr>
              <td className="text-left ">
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">CCA or House EXCO Member</div>
                </div>
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">Student Councillor</div>
                </div>
                <div className="d-flex my-2">
                  <div>·</div>
                  <div className="ml-2">UG: Staff Sergeant or equiv.</div>
                </div>
              </td>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  Co-Leader/section head of major arts-based project; or
                </div>
                <div className="my-2 ml-2">
                  Co-Leader/section head of an overseas/local community service
                  project.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const RepresentationTooltipCCA = () => {
  return (
    <div>
      <div className="py-3">
        <table className="table-border-cca w-100 ">
          <thead>
            <tr>
              <th className="text-center" colspan="2">
                Level of Representation/ Participation/ Involvement
              </th>
            </tr>
          </thead>
          <thead>
            <th>JC/JC Equivalent</th>
            <th>SOTA</th>
          </thead>
          <thead>
            <th>Tier 1</th>
            <th>Tier 1</th>
          </thead>
          <tbody className="table-border-cca">
            <tr>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  Represented SG at international competitions
                </div>
              </td>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  <u>Representation/Excellence</u>
                  <div>
                    Outstanding achievement at international competition; or{' '}
                  </div>
                  <div> Performer/exhibitor in a prestigious event; and</div>
                  <div>Recipient of SOTA Arts Award </div>
                </div>
                <div className="my-3 ml-2">OR</div>
                <div className="my-2 ml-2">
                  <u>Professional Engagement </u>
                  <div>Engagement with art professional; or</div>
                  <div>Participation in overseas arts programme.</div>
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-left ">Tier 2</th>
              <th className="text-left ">Tier 2</th>
            </tr>
            <tr>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  Represented SG or School at recognised international/regional
                  competitions
                </div>
              </td>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  <u>Representation/Excellence</u>
                  <div>
                    Represented SG or SOTA at international competition; or
                  </div>
                  <div>Performer/exhibitor in a prestigious event; and </div>
                  <div>Recipient of SOTA Arts Award</div>
                </div>
                <div className="my-3 ml-2">OR</div>
                <div className="my-2 ml-2">
                  <u>Professional Engagement</u>
                  <div>Engagement with art professional</div>
                </div>
              </td>
            </tr>
            <tr>
              <th className="text-left ">Tier 3</th>
              <th className="text-left ">Tier 3</th>
            </tr>
            <tr>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  Represented school at recognised national
                  competition/activities
                </div>
              </td>
              <td className="text-left ">
                <div className="my-2 ml-2">
                  <u>Representation/Excellence</u>
                  <div>
                    Represented SOTA at recognised national competitions or
                    events
                  </div>
                </div>
                <div className="my-3 ml-2">OR</div>
                <div className="my-2 ml-2">
                  <u>Professional Engagement</u>
                  <div>Engagement with art professional</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export {
  LeadershipTooltip,
  RepresentationTooltip,
  PSCPoints,
  PolytechnicGPA,
  IBScore,
  NUSCap,
  LeadershipTooltipCCA,
  RepresentationTooltipCCA,
};
