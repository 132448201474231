import callApi from './callApi';

//Common urls for api calls
import { baseUrl, regularUrl, v2BaseUrl } from '../utils/config';

//Action types for all api calls
import {
  REQUEST_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA,
  REQUEST_GET_VIDEO_INTERVIEW_RATING_CRITERIA,
  REQUEST_CREATE_CANDIDATE_EVALUATION,
  REQUEST_UPSERT_CANDIDATE_EVALUATION,
  REQUEST_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA,
  REQUEST_GET_CANDIDATE_EVALUATION,
  REQUEST_GET_VIDEO_INTERVIEW_DETAILS,
  REQUEST_ASSIGN_CSC_USER_VI,
  REQUEST_GET_ASSIGNED_CANDIDATE_VI_LIST,
  REQUEST_GET_GBA_DETAILS,
  REQUEST_UPSERT_CANDIDATE_DETAILS_BY_ID,
  REQUEST_GET_REPORT_TYPES_BY_APP_ID,
  REQUEST_UPLOAD_CANDIDATE_REPORTS,
  REQUEST_DELETE_CANDIDATE_REPORT,
  REQUEST_VIEW_UPLOADED_REPORTS,
  REQUEST_DOWNLOAD_CANDIDATE_REPORT,
  REQUEST_CREATE_CANDIDATE_EVALUATION_PS,
  REQUEST_GET_CANDIDATE_EVALUATION_PS,
  REQUEST_UPSERT_CANDIDATE_EVALUATION_PS,
  REQUEST_GET_PSAC_PEER_PS_DATA,
  REQUEST_GET_PSAC_PEER_PS_DATA_BY_ID,
  REQUEST_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT,
  REQUEST_UPDATE_VID_INT_COMMENTS,
  REQUEST_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES,
  REQUEST_GET_CAND_ASSESS_STATUS,
  REQUEST_GET_LEAP_DETAILS,
  REQUEST_GET_ALL_SCORES,
  REQUEST_GET_PS_TSCORES_IMAGES,
  REQUEST_TRIGGER_RETAKE_FOR_VI,
  REQUEST_GET_PERSONAL_SUMMARY,
  REQUEST_SEARCH_AI_INSIGHTS,
  REQUEST_POST_FEEDBACK_AI_INSIGHTS,
} from './actionTypes';

const getVideoInterviewDetails = (appId) => (dispatch) => {
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/videointerviewresponse/${appId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_VIDEO_INTERVIEW_DETAILS,
  };
  return callApi(params);
};

const getVideoInterviewratingCriteria =
  (appId, isCscRatingsInOldFormat) => (dispatch) => {
    if (!appId) {
      const error = {
        msg: 'Invalid data passed',
      };
      return Promise.reject(error);
    }

    let url = `${baseUrl}/videointerviewratingcriteria/${appId}?_=${new Date().getTime()}`;

    if (isCscRatingsInOldFormat) {
      url += `&isCscRatingsInOldFormat=${isCscRatingsInOldFormat}`;
    }
    const params = {
      headers: {
        'content-type': 'application/json',
      },
      dispatch,
      method: 'GET',
      url,
      actionType: REQUEST_GET_VIDEO_INTERVIEW_RATING_CRITERIA,
    };
    return callApi(params);
  };

const upsertVideoRatingCriteria =
  (data, videoInterviewCriteriaId) => (dispatch) => {
    const { appId } = data || {};
    if (!appId) {
      const error = {
        msg: 'Invalid data passed',
      };
      return Promise.reject(error);
    }
    const url = `${baseUrl}/videointerviewratingcriteria/patch/${videoInterviewCriteriaId}?_=${new Date().getTime()}`;
    const params = {
      dispatch,
      actionType: REQUEST_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA,
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return callApi(params);
  };

const createVideoRatingCriteria = (data) => (dispatch) => {
  const { appId } = data || {};
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/videointerviewratingcriteria?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const createCandidateEvalDetails = (data) => (dispatch) => {
  const { appId } = data || {};
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/candidateevaluation?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CREATE_CANDIDATE_EVALUATION,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const upsertCandidateEvaluationDetails = (data, evaluationId) => (dispatch) => {
  const { appId } = data || {};
  if (!appId || !evaluationId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/candidateevaluation/patch/${evaluationId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_CANDIDATE_EVALUATION,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getCandidateEvaluationDetails = (appId) => (dispatch) => {
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/candidateevaluation/${appId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CANDIDATE_EVALUATION,
  };
  return callApi(params);
};

const assignCandidateToCSCUser = (data) => (dispatch) => {
  const { appId } = data || {};
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/uservideointerviewapplnmaps?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_ASSIGN_CSC_USER_VI,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getAssignedCandidates = (data, userId) => (dispatch) => {
  const { activePage, itemsPerPage, searchKey, completed, year } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/uservideointerviewapplnmaps?_=${new Date().getTime()}`;
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;
  url += `&completed=${completed}`;
  if (searchKey) {
    url += `&searchKey=${searchKey}`;
  }
  if (year) url += `&year=${year}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSIGNED_CANDIDATE_VI_LIST,
  };
  return callApi(params);
};

const getGBADetails = (profileId, token) => (dispatch) => {
  if (!profileId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  let url = `${baseUrl}/gbadetails/${profileId}?_=${new Date().getTime()}`;
  if (token) {
    url += `&token=${token}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_GBA_DETAILS,
  };
  return callApi(params);
};

const upsertCandidateDetailsById = (data) => (dispatch) => {
  const { appId } = data || {};
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/applications/patch?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_CANDIDATE_DETAILS_BY_ID,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getReportTypesByAppId = (appId) => (dispatch) => {
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/reporttypes/${appId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_REPORT_TYPES_BY_APP_ID,
  };
  return callApi(params);
};

const uploadCandidateReports = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/uploadreport?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_CANDIDATE_REPORTS,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const deleteCandidateReports = (appId, reportId) => (dispatch) => {
  if (!appId || !reportId) {
    return Promise.reject(new Error('Invalid details.'));
  }
  let url = `${baseUrl}/reporttypes/delete?_=${new Date().getTime()}`;
  if (appId) {
    url += `&appId=${appId}`;
  }
  if (reportId) {
    url += `&reportId=${reportId}`;
  }
  const params = {
    dispatch,
    actionType: REQUEST_DELETE_CANDIDATE_REPORT,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

const getViewReports = (appId, reporttypeId) => (dispatch) => {
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/reporttypes/view?_=${new Date().getTime()}&appId=${appId}&reportId=${reporttypeId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_VIEW_UPLOADED_REPORTS,
  };
  return callApi(params);
};

const downloadCandidateReports = (data, appId) => (dispatch) => {
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/applications/view?_=${new Date().getTime()}&appId=${appId}`;
  const params = {
    dispatch,
    actionType: REQUEST_DOWNLOAD_CANDIDATE_REPORT,
    url,
    method: 'POST',
    encoding: 'binary',
    body: JSON.stringify(data),
    responseType: 'application/pdf',
    headers: {
      'content-type': 'application/json',
    },
  };
  return callApi(params);
};

const downloadCandidatePsychReport = (data, appId) => (dispatch) => {
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/applications/psycInterviewReportsExport?_=${new Date().getTime()}&appId=${appId}`;
  const params = {
    dispatch,
    actionType: REQUEST_DOWNLOAD_CANDIDATE_REPORT,
    url,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  };
  return callApi(params);
};

const createPresidentScholarshipEval = (data) => (dispatch) => {
  const { appId } = data || {};
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/presidentscholarshipeval?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CREATE_CANDIDATE_EVALUATION_PS,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const upsertPresidentScholarshipEval = (data, psEvalId) => (dispatch) => {
  const { appId } = data || {};
  if (!appId || !psEvalId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/presidentscholarshipeval/patch/${psEvalId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_CANDIDATE_EVALUATION_PS,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getPresidentScholarshipEval = (appId) => (dispatch) => {
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/presidentscholarshipeval/${appId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CANDIDATE_EVALUATION_PS,
  };
  return callApi(params);
};

const getPsacandPeerAppraisalData = (data) => (dispatch) => {
  const {
    psacItemsPerPage,
    peerItemsPerPage,
    psyItemsPerPage,
    psacActivePage,
    peerActivePage,
    psyActivePage,
    getSummaryPageDetails,
    fetchLatestData,
    year,
  } = data || {};
  let url = `${baseUrl}/applications/getPSSummaryList?_=${new Date().getTime()}`;
  const psacSkip =
    psacActivePage === 0 ? 0 : (psacActivePage - 1) * psacItemsPerPage;
  const peerSkip =
    peerActivePage === 0 ? 0 : (peerActivePage - 1) * peerItemsPerPage;
  const psySkip =
    psyActivePage === 0 ? 0 : (psyActivePage - 1) * psyItemsPerPage;
  if (psacItemsPerPage) {
    url += `&filterPSAC[limit]=${psacItemsPerPage}&filterPSAC[skip]=${psacSkip}`;
  }
  if (peerItemsPerPage) {
    url += `&filterPeer[limit]=${peerItemsPerPage}&filterPeer[skip]=${peerSkip}`;
  }
  if (psyItemsPerPage) {
    url += `&filterPsy[limit]=${psyItemsPerPage}&filterPsy[skip]=${psySkip}`;
  }
  if (getSummaryPageDetails) {
    url += `&getSummaryPageDetails=${getSummaryPageDetails}`;
  }
  if (year) {
    url += `&year=${year}`;
  }
  if (fetchLatestData) {
    url += `&filter[fetchLatestData]=true`;
    url += `&filterPSAC[fetchLatestData]=true`;
    url += `&filterPeer[fetchLatestData]=true`;
    url += `&filterPsy[fetchLatestData]=true`;
  }

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PSAC_PEER_PS_DATA,
  };
  return callApi(params);
};

const getPsacandPeerAppraisalDataById =
  (profileId, year, psSubTabName) => (dispatch) => {
    let url = `${baseUrl}/applications/getPSSummaryById?profileId=${profileId}&_=${new Date().getTime()}`;
    if (year) {
      url += `&year=${year}`;
    }
    if (psSubTabName) {
      url += `&psSubTabName=${psSubTabName}`;
    }
    const params = {
      headers: {
        'content-type': 'application/json',
      },
      dispatch,
      method: 'GET',
      url,
      actionType: REQUEST_GET_PSAC_PEER_PS_DATA_BY_ID,
    };
    return callApi(params);
  };

const downloadSelectionNoteReport = (year, appId) => (dispatch) => {
  if (!appId || !year) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/applications/viewSelectionNoteExport?_=${new Date().getTime()}&appId=${appId}&year=${year}`;
  const params = {
    dispatch,
    actionType: REQUEST_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT,
    url,
    method: 'GET',
    encoding: 'binary',
    responseType: 'application/pdf',
    headers: {
      'content-type': 'application/json',
    },
  };
  return callApi(params);
};

const updateVideoIntComments =
  (data, videointerviewcriteriaId) => (dispatch) => {
    const { appId } = data || {};
    if (!appId) {
      const error = {
        msg: 'Invalid data passed',
      };
      return Promise.reject(error);
    }
    const url = `${baseUrl}/videointerviewratingcriteria/patch/${videointerviewcriteriaId}?_=${new Date().getTime()}`;
    const params = {
      dispatch,
      actionType: REQUEST_UPDATE_VID_INT_COMMENTS,
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return callApi(params);
  };

const getCandidateAssessFeedbackResponses = (profileId) => (dispatch) => {
  if (!profileId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${v2BaseUrl}/assessmentfeedback/response/${profileId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES,
  };
  return callApi(params);
};

const getCandidateAssessStatus = (profileId, selectionYear) => (dispatch) => {
  if (!profileId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${v2BaseUrl}/assessment/status?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_GET_CAND_ASSESS_STATUS,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ profileId, selectionYear }),
  };
  return callApi(params);
};

const getLEAPDetails = (profileId) => (dispatch) => {
  if (!profileId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  let url = `${v2BaseUrl}/assessment/result/${profileId}?_=${new Date().getTime()}&test=personality`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_LEAP_DETAILS,
  };
  return callApi(params);
};

const getAllScoresForCandInsights = (profileId) => (dispatch) => {
  if (!profileId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  let url = `${v2BaseUrl}/assessment/result/${profileId}/all?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ALL_SCORES,
  };
  return callApi(params);
};

const getPSTScoresImages = (year) => (dispatch) => {
  let url = `${baseUrl}/applications/getPsTScoreImage?_=${new Date().getTime()}`;
  if (year) {
    url += `&year=${year}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PS_TSCORES_IMAGES,
  };
  return callApi(params);
};

const reTriggerInsights = (data) => (dispatch) => {
  const url = `${baseUrl}/videointerviewresponse/regenerate?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: 'REQUEST_BULK_RETAKE_TEST',
  };
  return callApi(params);
};

const triggerRetakeForVidIntv = (data) => (dispatch) => {
  let url = `${baseUrl}/videointerview/trigger-retake?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    actionType: REQUEST_TRIGGER_RETAKE_FOR_VI,
    url,
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getPersonalSummary = (appId) => (dispatch) => {
  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  let url = `${v2BaseUrl}/ai-insights?_=${new Date().getTime()}`;
  if (appId) {
    url += `&appId=${appId}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_PERSONAL_SUMMARY,
  };
  return callApi(params);
};

const searchAiInsights = (data) => (dispatch) => {
  let url = `${v2BaseUrl}/ai-insights/search`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    actionType: REQUEST_SEARCH_AI_INSIGHTS,
    url,
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const getAiInsightsStatusById = (taskId) => (dispatch) => {
  if (!taskId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  let url = `${v2BaseUrl}/ai-insights/task-status/${taskId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_AI_INSIGHTS_STATUS_BY_ID',
  };
  return callApi(params);
};

const postFeedbackForAiInsights = (data) => (dispatch) => {
  if (!data?.appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  let url = `${v2BaseUrl}/ai-insights/feedback`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    actionType: REQUEST_POST_FEEDBACK_AI_INSIGHTS,
    url,
    body: JSON.stringify(data),
  };
  return callApi(params);
};

export {
  createVideoRatingCriteria,
  getVideoInterviewratingCriteria,
  createCandidateEvalDetails,
  upsertCandidateEvaluationDetails,
  getCandidateEvaluationDetails,
  getVideoInterviewDetails,
  assignCandidateToCSCUser,
  getAssignedCandidates,
  getGBADetails,
  upsertCandidateDetailsById,
  getReportTypesByAppId,
  uploadCandidateReports,
  deleteCandidateReports,
  getViewReports,
  downloadCandidateReports,
  createPresidentScholarshipEval,
  upsertPresidentScholarshipEval,
  getPresidentScholarshipEval,
  getPsacandPeerAppraisalData,
  getPsacandPeerAppraisalDataById,
  downloadSelectionNoteReport,
  downloadCandidatePsychReport,
  updateVideoIntComments,
  upsertVideoRatingCriteria,
  getCandidateAssessFeedbackResponses,
  getCandidateAssessStatus,
  getLEAPDetails,
  getAllScoresForCandInsights,
  getPSTScoresImages,
  reTriggerInsights,
  triggerRetakeForVidIntv,
  getPersonalSummary,
  searchAiInsights,
  getAiInsightsStatusById,
  postFeedbackForAiInsights,
};
