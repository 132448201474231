import callApi from './callApi';

//Common urls for api calls
import { baseUrl } from '../utils/config';

//Action types for all api calls
import {
  REQUEST_GET_SELECTION_BOARD_USERS,
  REQUEST_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE,
  REQUEST_GET_ASSIGNED_PANEL_INTERVIEWERS,
  REQUEST_ASSIGN_BOARD_USERS_FOR_A_DATE,
  REQUEST_ASSIGN_CANDIDATES_TO_OTHER_ROLES,
  REQUEST_GET_NEXT_INTERVIEW_DATE,
  REQUEST_CREATE_BREAK_TIME,
  REQUEST_DELETE_BREAK_TIME,
  REQUEST_GET_BREAK_TIME,
  REQUEST_UPDATE_TIMER_FOR_APPLS,
} from './actionTypes';

const getNextInterviewDate = (recordType) => (dispatch) => {
  const url = `${baseUrl}/applications/retrieveNextInterviewDate?recordType=${recordType}&_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_SELECTION_BOARD_USERS,
  };
  return callApi(params);
};

const getAllSelectionBoardUsers = () => (dispatch) => {
  const url = `${baseUrl}/selectionboard?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_SELECTION_BOARD_USERS,
  };
  return callApi(params);
};

const getAssignedPanelInterviewers = (data) => (dispatch) => {
  const { date, appId, recordType } = data || {};
  let url = `${baseUrl}/interviewassignedgraph?_=${new Date().getTime()}&recordType=${recordType}`;
  if (date) {
    url += `&date=${date}`;
  }
  if (appId) {
    url += `&appId=${appId}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSIGNED_PANEL_INTERVIEWERS,
  };
  return callApi(params);
};

const assignPanelMembersToCand = (data) => (dispatch) => {
  const { appId, boardId, date, unAssigned, recordType } = data || {};

  if (!appId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const finalData = {
    appId,
  };
  if (boardId) {
    finalData.boardId = boardId;
  }
  if (unAssigned) {
    finalData.unAssigned = unAssigned;
  }

  if (recordType) {
    finalData.recordType = recordType;
  }

  if (date) {
    let currStartDate = new Date(date);
    currStartDate.setHours(0, 0, 0, 0);
    const startDate = new Date(currStartDate).getTime();
    let currEndDate = new Date(date);
    currEndDate.setHours(23, 59, 59, 999);
    const endDate = new Date(currEndDate).getTime();
    finalData.date = date;
    finalData.startDate = startDate;
    finalData.endDate = endDate;
  } else {
    finalData.date = null;
  }
  const url = `${baseUrl}/interviewassignedgraph?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(finalData),
  };
  return callApi(params);
};

const assignPanelMembersForADate = (data) => (dispatch) => {
  if (!data || (data && Array.isArray(data) && data.length <= 0)) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/interviewassignedgraph/patch?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_ASSIGN_BOARD_USERS_FOR_A_DATE,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const assignCandidatesToOtherRoles = (data) => (dispatch) => {
  const { appId, roleId } = data || {};
  if (!appId || !roleId) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const url = `${baseUrl}/Applicationaccessibility?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_ASSIGN_CANDIDATES_TO_OTHER_ROLES,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const createBreakPoints = (data) => (dispatch) => {
  const url = `${baseUrl}/Breakpoints/create?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CREATE_BREAK_TIME,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const deleteBreakPoints = (breakpointId) => (dispatch) => {
  const url = `${baseUrl}/Breakpoints/delete?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_DELETE_BREAK_TIME,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ bpId: breakpointId }),
  };
  return callApi(params);
};

const getBreakStartEndTime = (startTime, endTime, recordType) => (dispatch) => {
  let url = `${baseUrl}/Breakpoints/getBreakPoint?_=${new Date().getTime()}`;
  if (startTime) {
    url += `&startTime=${startTime}`;
  }
  if (endTime) {
    url += `&endTime=${endTime}`;
  }
  if (recordType) {
    url += `&recordType=${recordType}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_BREAK_TIME,
  };
  return callApi(params);
};

const updateTimerForAppls = (data) => (dispatch) => {
  if (!data) {
    const error = {
      msg: 'Invalid data passed',
    };
    return Promise.reject(error);
  }
  const baseDomain = `${window.location.protocol}//${window.location.host}`;
  const url = `${baseDomain}/api/v2/applications/patchTimer?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPDATE_TIMER_FOR_APPLS,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

export {
  getAllSelectionBoardUsers,
  assignPanelMembersToCand,
  getAssignedPanelInterviewers,
  assignPanelMembersForADate,
  assignCandidatesToOtherRoles,
  getNextInterviewDate,
  createBreakPoints,
  getBreakStartEndTime,
  updateTimerForAppls,
  deleteBreakPoints,
};
