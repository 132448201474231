import React, { Component } from 'react';
//Common Components
import GenericDataTable from '../../components/common/GenericDataTable';

//Common packages
import { Button } from 'carbon-components-react';

//Images
import PublicHeaderMock from './PublicHeaderMock';
import NoContentBox from '../../components/common/NoContentBox';

class CandidateListMock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSideNavExpanded: false,
      candidatesList: [
        {
          fullName: 'Isaiah Chia',
          nric: 'Txxxx516I',
          batch: '2020',
          school: 'Singapore Polytechnic',
        },
        {
          fullName: 'Wee Baey Kok',
          nric: 'Txxxx532L',
          batch: '2020',
          school: 'National Junior College',
        },
        {
          fullName: 'Jacob Lim',
          nric: 'Txxxx531J',
          batch: '2019',
          school: 'NUS High School of Math and Science',
        },
        {
          fullName: 'Sophia Wong',
          nric: 'Txxxx377K',
          batch: '2018',
          school: 'St. Josephs Institution',
        },
      ],
    };
  }

  handleSearchSubmit = (e) => {
    const { candidatesList } = this.state;
    const value = e && e.target && e.target.value;
    if (value) {
      const filtereddata = candidatesList.filter(
        (res) =>
          (res &&
            res.fullName &&
            res.fullName.toString().toLowerCase().indexOf(value) >= 0) ||
          (res &&
            res.nric &&
            res.nric.toString().toLowerCase().indexOf(value) >= 0)
      );
      this.setState({
        candidatesList: filtereddata,
      });
    } else {
      this.setState({
        candidatesList: [
          {
            fullName: 'Isaiah Chia',
            nric: 'Txxxx516I',
            batch: '2020',
            school: 'Singapore Polytechnic',
          },
          {
            fullName: 'Wee Baey Kok',
            nric: 'Txxxx532L',
            batch: '2020',
            school: 'National Junior College',
          },
          {
            fullName: 'Jacob Lim',
            nric: 'Txxxx531J',
            batch: '2019',
            school: 'NUS High School of Math and Science',
          },
          {
            fullName: 'Sophia Wong',
            nric: 'Txxxx377K',
            batch: '2018',
            school: 'St. Josephs Institution',
          },
        ],
      });
    }
  };

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  render() {
    const { candidatesList, isSideNavExpanded } = this.state;
    const { showDate, showColors, history } = this.props || {};
    const { push } = history || {};
    return (
      <div>
        <PublicHeaderMock
          onClickSideNavExpand={this.onClickSideNavExpand}
          isSideNavExpanded={isSideNavExpanded}
          push={push}
          history={history}
        />
        <div className=" bx--grid mt-5 xpa--ht-70">
          <h4 className="mt-2 mb-2">Candidates</h4>
          <div className=" mb-2">
            <GenericDataTable
              otherActions={[]}
              tbodyClass="candidate-background-color"
              searchable
              onSearchInputChange={this.handleSearchSubmit}
              rows={
                candidatesList &&
                Array.isArray(candidatesList) &&
                candidatesList.length > 0
                  ? candidatesList.map((interview, idx) => {
                      const { fullName, nric, batch, school } = interview;
                      return {
                        isExpanded: false,
                        id: `row_${idx}`,
                        header_0: (
                          <div
                            className="xpa-link f-w-b h6"
                            onClick={() => {
                              push &&
                                push(
                                  `/public/candidate/${encodeURIComponent(
                                    fullName
                                  )}`
                                );
                            }}>
                            {fullName}
                          </div>
                        ),
                        header_1: <div>{nric}</div>,
                        header_2: <div>{school}</div>,
                        header_3: <div>{batch}</div>,
                        header_4: <Button size="small">Add to Compare</Button>,
                      };
                    })
                  : []
              }
              headers={[
                { key: 'header_0', header: 'Name' },
                { key: 'header_1', header: 'NRIC' },
                { key: 'header_2', header: 'School' },
                { key: 'header_3', header: 'Batch' },
                { key: 'header_4', header: 'Action' },
              ]}
            />
          </div>
          {candidatesList &&
            Array.isArray(candidatesList) &&
            candidatesList.length <= 0 && (
              <NoContentBox message="There are no applicants for your current selection" />
            )}
        </div>
      </div>
    );
  }
}

export default CandidateListMock;
