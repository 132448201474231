import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getGBADetails } from '../../actions/CandidateInfo';
import { CandidateStenGraph } from '../../components/private/CandidateStenGraph';
import './GBAReportExport.css';

class GBAReportExport extends Component {
  componentDidMount() {
    const { match } = this.props;
    const { params } = match || {};
    const { profileId, token } = params || {};
    this.props.getGBADetails(profileId, token);
  }

  render() {
    const { gbaDetails, match } = this.props || {};
    const { details } = gbaDetails || {};
    const { assessment_results } = details || {};
    let gbaMainTraitsData = [];
    if (
      assessment_results &&
      Array.isArray(assessment_results) &&
      assessment_results.length > 0
    ) {
      gbaMainTraitsData = assessment_results.filter(
        (r) => r && r.type !== 'percentile'
      );
    }
    const { params } = match || {};
    const { type } = params || {};
    return (
      <div>
        {type === 'cognitive-drive' &&
          gbaMainTraitsData &&
          Array.isArray(gbaMainTraitsData) &&
          gbaMainTraitsData.length > 0 && (
            <div
              id="gba-export-report-cognitive-drive"
              className="csc-bg-white">
              <div className="csc-gba-width ">
                <CandidateStenGraph
                  handleBarOnClick={() => {}}
                  gbaMainTraitsData={gbaMainTraitsData}
                  type={type}
                  isMainGBAData={false}
                />
              </div>
            </div>
          )}
        {type === 'thinking-style' &&
          gbaMainTraitsData &&
          Array.isArray(gbaMainTraitsData) &&
          gbaMainTraitsData.length > 0 && (
            <div id="gba-export-report-thinking-style" className="csc-bg-white">
              <div className="csc-gba-width ">
                <CandidateStenGraph
                  handleBarOnClick={() => {}}
                  gbaMainTraitsData={gbaMainTraitsData}
                  type={type}
                  isMainGBAData={false}
                />
              </div>
            </div>
          )}
        {type === 'interpersonal-personal-style' &&
          gbaMainTraitsData &&
          Array.isArray(gbaMainTraitsData) &&
          gbaMainTraitsData.length > 0 && (
            <div
              id="gba-export-report-interpersonal-personal-style"
              className="csc-bg-white">
              <div className="csc-gba-width ">
                <CandidateStenGraph
                  handleBarOnClick={() => {}}
                  gbaMainTraitsData={gbaMainTraitsData}
                  type={type}
                  isMainGBAData={false}
                />
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gbaDetails: state.CandidateInfo.gbaDetails,
});

const mapDispatchToProps = {
  getGBADetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(GBAReportExport);
