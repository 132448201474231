import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
//Api Actions
import BatchCountMock from './BatchCountMock';
//Common Components
import Legend from '../../components/common/Legend';
import MainLegend from '../../components/common/MainLegend';
import PublicHeaderMock from './PublicHeaderMock';
import PublicHeader from '../../headers/PublicHeader';

import GenericDataTable from '../../components/common/GenericDataTable';
//Functional common components
import DashboardAnalytics from '../../components/private/DashboardAnalytics';
import GenericTabs from '../../components/common/GenericTabs';
import NivoPieChart from '../../components/common/NivoPieChart';

//Carbon components
import {
  Tile,
  TextInput,
  Button,
  Modal,
  InlineNotification,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';
import CscImage from '../../assets/images/x0pa-brown.png';
import './MockUi2.css';

class DashboardMock extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'dashboard', name: 'Dashboard', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      isSideNavExpanded: false,
      selectedTab: 0,
      attendingVals: [],
      notAttendingVals: [],
      secInAtt: [],
    };
    this.handleRedirectToTop = React.createRef();
  }

  componentDidMount() {
    this.handleAddAttendingVals();
    this.handleAddNotAttendingVals();
    this.handleAddSecAttendingVals();
    const isUserLoggedIn = sessionStorage.getItem('is-user-loggedin');

    this.setState({
      showLoginForm: !isUserLoggedIn,
    });
  }

  handleGetPreviousBatchDetails = () => {
    const { batchListData } = this.props;
    const prevYear =
      batchListData &&
      Array.isArray(batchListData) &&
      batchListData.length > 0 &&
      batchListData[0] &&
      batchListData[0].year &&
      Number(batchListData[0].year) - 1;
    const data = {
      year: prevYear,
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
    };
    this.handleRedirectToTop &&
      this.handleRedirectToTop.current &&
      this.handleRedirectToTop.current.focus &&
      this.handleRedirectToTop.current.focus();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  handleTabClick = (tabNumber) => {
    this.setState({
      selectedTab: tabNumber,
    });
  };

  handleAddAttendingVals = () => {
    this.setState({
      attendingVals: this.state.attendingVals.concat({ name: '' }),
    });
  };

  handleAddNotAttendingVals = () => {
    this.setState({
      notAttendingVals: this.state.notAttendingVals.concat({ name: '' }),
    });
  };

  handleAddSecAttendingVals = () => {
    this.setState({
      secInAtt: this.state.secInAtt.concat({ name: '' }),
    });
  };

  handleBarOnClick = (data, obj) => {
    const { key } = data || {};
    const { id } = obj || {};
    let pieChartData = [];
    const keys = [
      {
        value: `midTerm${id}`,
        label: 'Mid Term',
      },
      {
        value: `underGraduate${id}`,
        label: 'Undergraduates',
      },
      {
        value: `masters${id}`,
        label: 'Masters',
      },
    ];
    keys.forEach((r) => {
      const { value, label } = r || {};
      pieChartData.push({
        id: label,
        label,
        value: data && data[value],
      });
    });
    this.setState({
      isOpenCatModal: true,
      currBar: id,
      pieChartData,
      scholarshipLabel: key,
    });
  };

  render() {
    const { history, hideCurrBatch } = this.props;
    const { pieChartData, currBar, isOpenCatModal, scholarshipLabel } =
      this.state;

    const analyticsInfo = {
      dwellingType: [
        {
          key: '1-2-rm',
          interviewed: 25,
          offered: 12,
        },
        {
          key: '3-rm flats',
          interviewed: 27,
          offered: 10,
        },
        {
          key: '4-rm flats',
          interviewed: 32,
          offered: 12,
        },
        {
          key: '5-rm flats & exec flats',
          interviewed: 45,
          offered: 8,
        },
        {
          key: 'Condominium',
          interviewed: 82,
          offered: 40,
        },
        {
          key: 'Landed properties & other housing',
          interviewed: 37,
          offered: 14,
        },
      ],
      genderData: [
        {
          key: 'Female',
          interviewed: 125,
          offered: 50,
        },
        {
          key: 'Male',
          interviewed: 125,
          offered: 50,
        },
      ],
      householfFA: [
        {
          key: 'Non FA',
          'interviewed (not offered)': 200,
          offered: 70,
        },
        {
          key: 'FA',
          'interviewed (not offered)': 50,
          offered: 25,
        },
      ],
      ethnicityData: [
        {
          key: 'Chinese',
          interviewed: 100,
          offered: 70,
        },
        {
          key: 'Malay',
          interviewed: 40,
          offered: 23,
        },
        {
          key: 'Indian',
          interviewed: 40,
          offered: 5,
        },
        {
          key: 'Others',
          interviewed: 12,
          offered: 2,
        },
      ],
      houseHoldIncome: [
        {
          interviewed: 20,
          key: '$5999 & below',
          offered: 19,
        },
        {
          interviewed: 30,
          key: '$6000 - $11999',
          offered: 29,
        },
        {
          interviewed: 50,
          key: '$12000 & above',
          offered: 48,
        },
      ],
      scholarshipScheme: [
        {
          key: 'PSC Scholarship',
          offered: 15,
          accepted: 15,
          target: 35,
          pending: 5,
          declined: 2,
          midTermtarget: 5,
          underGraduatetarget: 3,
          masterstarget: 2,
          mastersaccepted: 2,
          midTermaccepted: 3,
          underGraduateaccepted: 4,
          masterspending: 1,
          midTermpending: 1,
          underGraduatepending: 1,
          mastersdeclined: 1,
          midTermdeclined: 1,
          underGraduatedeclined: 0,
        },
        {
          key: 'Singapore Armed Forces',
          offered: 15,
          accepted: 15,
          target: 20,
          pending: 2,
          declined: 1,
          midTermtarget: 5,
          underGraduatetarget: 3,
          masterstarget: 2,
          mastersaccepted: 2,
          midTermaccepted: 3,
          underGraduateaccepted: 4,
          masterspending: 1,
          midTermpending: 1,
          underGraduatepending: 0,
          mastersdeclined: 1,
          midTermdeclined: 0,
          underGraduatedeclined: 0,
        },
        {
          key: 'Singapore Police Force',
          offered: 15,
          accepted: 15,
          target: 35,
          pending: 1,
          declined: 1,
          midTermtarget: 5,
          underGraduatetarget: 3,
          masterstarget: 2,
          mastersaccepted: 2,
          midTermaccepted: 3,
          underGraduateaccepted: 4,
          masterspending: 1,
          midTermpending: 0,
          underGraduatepending: 0,
          mastersdeclined: 1,
          midTermdeclined: 0,
          underGraduatedeclined: 0,
        },
        {
          key: 'Engineering',
          offered: 10,
          accepted: 10,
          target: 20,
          pending: 1,
          declined: 1,
          midTermtarget: 4,
          underGraduatetarget: 5,
          masterstarget: 3,
          mastersaccepted: 2,
          midTermaccepted: 3,
          underGraduateaccepted: 4,
          masterspending: 1,
          midTermpending: 0,
          underGraduatepending: 0,
          mastersdeclined: 1,
          midTermdeclined: 0,
          underGraduatedeclined: 0,
        },
        {
          key: 'Foreign Service',
          offered: 10,
          accepted: 10,
          target: 20,
          pending: 1,
          declined: 0,
          midTermtarget: 4,
          underGraduatetarget: 3,
          masterstarget: 2,
          mastersaccepted: 1,
          midTermaccepted: 2,
          underGraduateaccepted: 3,
          masterspending: 1,
          midTermpending: 0,
          underGraduatepending: 0,
          mastersdeclined: 0,
          midTermdeclined: 0,
          underGraduatedeclined: 0,
        },
        {
          key: 'Legal Service',
          offered: 5,
          accepted: 2,
          target: 20,
          pending: 0,
          declined: 0,
          midTermtarget: 4,
          underGraduatetarget: 5,
          masterstarget: 3,
          mastersaccepted: 1,
          midTermaccepted: 0,
          underGraduateaccepted: 0,
          masterspending: 0,
          midTermpending: 0,
          underGraduatepending: 0,
          mastersdeclined: 0,
          midTermdeclined: 0,
          underGraduatedeclined: 0,
        },
        {
          key: 'Medicine/Dentistry',
          offered: 5,
          accepted: 2,
          target: 20,
          pending: 0,
          declined: 0,
          midTermtarget: 3,
          underGraduatetarget: 5,
          masterstarget: 4,
          mastersaccepted: 1,
          midTermaccepted: 0,
          underGraduateaccepted: 0,
          masterspending: 0,
          midTermpending: 0,
          underGraduatepending: 0,
          mastersdeclined: 0,
          midTermdeclined: 0,
          underGraduatedeclined: 0,
        },
        {
          key: 'Public Finance',
          offered: 15,
          accepted: 15,
          target: 20,
          pending: 0,
          declined: 0,
          midTermtarget: 3,
          underGraduatetarget: 4,
          masterstarget: 2,
          mastersaccepted: 2,
          midTermaccepted: 4,
          underGraduateaccepted: 2,
          masterspending: 0,
          midTermpending: 0,
          underGraduatepending: 0,
          mastersdeclined: 0,
          midTermdeclined: 0,
          underGraduatedeclined: 0,
        },
        {
          key: 'Teaching Service',
          offered: 5,
          accepted: 5,
          target: 2,
          pending: 0,
          declined: 0,
          midTermtarget: 1,
          underGraduatetarget: 0,
          masterstarget: 1,
          mastersaccepted: 1,
          midTermaccepted: 1,
          underGraduateaccepted: 0,
          masterspending: 0,
          midTermpending: 0,
          underGraduatepending: 0,
          mastersdeclined: 0,
          midTermdeclined: 0,
          underGraduatedeclined: 0,
        },
      ],
      candidateCourseData: [
        {
          key: 'Humanities Social Sciences/Liberal Arts',
          male: 20,
          female: 16,
          total: 36,
        },
        {
          key: 'Science / Mathematics',
          male: 16,
          female: 9,
          total: 25,
        },
        {
          key: 'Engineering & ICT',
          male: 10,
          female: 6,
          total: 4,
        },
        {
          key: 'Law',
          male: 2,
          female: 0,
          total: 2,
        },
        {
          key: 'Economics / PPE',
          male: 4,
          female: 3,
          total: 7,
        },
        {
          key: 'Finance / Business / Accountancy',
          male: 4,
          female: 4,
          total: 8,
        },
        {
          key: 'Medicine / Dentistry',
          male: 1,
          female: 1,
          total: 2,
        },
        {
          key: 'Others',
          male: 6,
          female: 4,
          total: 10,
        },
      ],
      ethnicityOtherData: [
        {
          id: 'Interviewed',
          color: '#4A8DDC',
          data: [
            {
              y: 2,
              x: 'Eurasian',
            },
            {
              y: 2,
              x: 'Javanese',
            },
            {
              y: 2,
              x: 'Filipino',
            },
            {
              y: 1,
              x: 'Burmese',
            },
            {
              y: 1,
              x: 'South Asian',
            },
            {
              y: 1,
              x: 'European',
            },
            {
              y: 1,
              x: 'korean',
            },
          ],
        },
        {
          id: 'Offered',
          color: '#FD625E',
          data: [
            {
              y: 1,
              x: 'Eurasian',
            },
            {
              y: 1,
              x: 'Javanese',
            },
            {
              y: 0,
              x: 'Filipino',
            },
            {
              y: 0,
              x: 'Burmese',
            },
            {
              y: 0,
              x: 'South Asian',
            },
            {
              y: 0,
              x: 'European',
            },
            {
              y: 0,
              x: 'korean',
            },
          ],
        },
      ],
      candidateCountryData: [
        {
          id: 'Target',
          color: '#4A8DDC',
          data: [
            {
              y: 52,
              x: 'Singapore',
            },
            {
              y: 50,
              x: 'United Kingdom',
            },
            {
              y: 50,
              x: 'USA',
            },
            {
              y: 40,
              x: 'Others',
            },
          ],
        },
        {
          id: 'Offered',
          color: '#FD625E',
          data: [
            {
              y: 25,
              x: 'Singapore',
            },
            {
              y: 29,
              x: 'United Kingdom',
            },
            {
              y: 30,
              x: 'USA',
            },
            {
              y: 16,
              x: 'Others',
            },
          ],
        },
      ],
      ytdComparision: [
        {
          key: 2017,
          interviewed: 120,
          offered: 150,
          accepted: 45,
        },
        {
          key: 2018,
          interviewed: 135,
          offered: 50,
          accepted: 48,
        },
        {
          key: 2019,
          interviewed: 140,
          offered: 50,
          accepted: 48,
        },
        {
          key: 2020,
          interviewed: 125,
          offered: 50,
          accepted: 47,
        },
      ],
      yoyComparision: [
        {
          key: 2017,
          interviewed: 240,
          offered: 100,
          accepted: 90,
        },
        {
          key: 2018,
          interviewed: 270,
          offered: 100,
          accepted: '95',
        },
        {
          key: 2019,
          interviewed: 280,
          offered: 100,
          accepted: '96',
        },
        {
          key: 2020,
          interviewed: 250,
          offered: 100,
          accepted: 94,
        },
      ],
      offeredBySchool: [
        {
          id: 'HCI',
          value: 28,
          label: 'HCI',
        },
        {
          id: 'RI',
          value: 32,
          label: 'RI',
        },
        {
          id: 'Other IP/Specialised Schools',
          value: 20,
          label: 'Other IP/Specialised Schools',
        },
        {
          id: 'Non-IP Schools',
          value: 13,
          label: 'Non-IP Schools',
        },
        {
          id: 'Polytechnics',
          value: 8,
          label: 'Polytechnics',
        },
        {
          id: 'Other Institutions',
          value: 4,
          label: 'Other Institutions',
        },
      ],
      candCountryData: [
        {
          id: 'South Korea',
          label: 'South Korea',
          value: 2,
        },
        {
          id: 'France',
          label: 'France',
          value: 5,
        },
        {
          id: 'Germany',
          label: 'Germany',
          value: 3,
        },
        {
          id: 'Denmark',
          value: 3,
          label: 'Denmark',
        },
        {
          id: 'Australia',
          value: 3,
          label: 'Australia',
        },
      ],
      candCourseData: [
        {
          id: 'Humanities Social Sciences/Liberal Arts',
          label: 'Humanities Social Sciences/Liberal Arts',
          value: 36,
        },
        {
          id: 'Science / Mathematics',
          label: 'Science / Mathematics',
          value: 25,
        },
        {
          id: 'Engineering & ICT',
          label: 'Engineering & ICT',
          value: 10,
        },
        {
          id: 'Law',
          value: 2,
          label: 'Law',
        },
        {
          id: 'Economics / PPE',
          value: 7,
          label: 'Economics / PPE',
        },
        {
          id: 'Finance / Business / Accountancy',
          label: 'Finance / Business / Accountancy',
          value: 8,
        },
        {
          id: 'Medicine / Denistry',
          label: 'Medicine / Denistry',
          value: 2,
        },
        {
          id: 'Others',
          label: 'Others',
          value: 10,
        },
      ],
    };
    const { push } = history || {};
    const {
      showLoginForm,
      isSideNavExpanded,
      selectedTab,
      attendingVals,
      notAttendingVals,
      secInAtt,
    } = this.state;

    return (
      <Fragment>
        <PublicHeader push={push} />
        {showLoginForm === true ? (
          <div style={{ height: '100vh' }} className="bx--row">
            <div
              style={{ backgroundColor: 'white' }}
              className="bx--col-lg-6 d-flex flex-column align-items-center justify-content-center">
              <div>
                <img style={{ width: 200 }} src={CscImage} alt="CSC Image" />
              </div>
            </div>
            <div className="bx--col-lg-6 d-flex flex-column align-items-center justify-content-center">
              <div className=" w-50">
                <h2 className="text-center mb-2">Welcome</h2>
                {this.state.showErr === true && (
                  <InlineNotification
                    lowContrast
                    title="Please enter valid email / password"
                    iconDescription="describes the close button"
                    kind="error"
                    onCloseButtonClick={() => {
                      this.setState({ showErr: false });
                    }}
                  />
                )}
                {this.state.showOtpInput !== true ? (
                  <Fragment>
                    <div className="mt-2">
                      <TextInput
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        placeholder="Email"
                      />
                    </div>
                    <div className="mt-2">
                      <TextInput
                        name="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                        placeholder="Password"
                        type="password"
                      />
                    </div>
                    <div className="h6 mt-2 xpa-link text-center">
                      Forgot Password
                    </div>
                    <div className="mt-2 text-center">
                      <Button
                        onClick={() => {
                          if (!this.state.email || !this.state.password) {
                            this.setState({
                              showErr: true,
                            });
                          } else {
                            this.setState({
                              showOtpInput: true,
                            });
                          }
                        }}
                        kind="primary">
                        Submit
                      </Button>
                    </div>
                  </Fragment>
                ) : (
                  <div>
                    <div className="mt-2">
                      <TextInput
                        name="otp"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.otp}
                        placeholder="OTP"
                      />
                    </div>
                    <div className="mt-2 text-center">
                      <Button
                        onClick={() => {
                          sessionStorage.setItem('is-user-loggedin', true);
                          this.setState({
                            showLoginForm: false,
                          });
                        }}
                        kind="primary">
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            <PublicHeaderMock
              onClickSideNavExpand={this.onClickSideNavExpand}
              isSideNavExpanded={isSideNavExpanded}
              push={push}
              history={history}
            />

            <div className={hideCurrBatch === true ? '' : 'bx--grid mt-5'}>
              <input
                style={{ height: 0, opacity: 0 }}
                type="text"
                ref={this.handleRedirectToTop}
              />
              <GenericTabs
                selected={selectedTab}
                tabContent={[
                  {
                    label: 'Current Batch (2021)',
                    value: '',
                    onClick: () => {
                      this.handleTabClick(0);
                    },
                  },
                  {
                    label: '2020',
                    value: '',
                    onClick: () => {
                      this.handleTabClick(1);
                    },
                  },
                  {
                    label: '2019',
                    value: '',
                    onClick: () => {
                      this.handleTabClick(2);
                    },
                  },
                ]}
              />
              {selectedTab === 0 && (
                <Fragment>
                  <div className="h5 f-w-b bx--ty-gamma font-weight-light mb-3 mt-3">
                    Current Batch Information
                  </div>
                  <Tile>
                    <BatchCountMock />
                  </Tile>
                </Fragment>
              )}
              {selectedTab === 1 && (
                <Fragment>
                  <div className="h5 f-w-b bx--ty-gamma font-weight-light mb-3 mt-3">
                    Current Batch Information
                  </div>
                  <Tile>
                    <BatchCountMock hideMainContent fullYear={2020} />
                  </Tile>
                </Fragment>
              )}
              {selectedTab === 2 && (
                <Fragment>
                  <div className="h5 f-w-b bx--ty-gamma font-weight-light mb-3 mt-3">
                    Current Batch Information
                  </div>
                  <Tile>
                    <BatchCountMock hideMainContent fullYear={2019} />
                  </Tile>
                </Fragment>
              )}

              <div className="h5 f-w-b bx--ty-gamma font-weight-light mb-3 mt-3">
                Key Batch Statistics
              </div>
              <DashboardAnalytics
                analyticsInfo={analyticsInfo}
                handleBarOnClick={this.handleBarOnClick}
              />
              <Accordion className="mt-2 mb-3" align="start" >
                <AccordionItem
                  className="content-padding"
                  title={
                    <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                      Second Rounders
                    </div>
                  }>
                  <Tile>
                    <h5 className="mb-2">Confirmed Candidates</h5>
                    <GenericDataTable
                      className=""
                      tbodyClass="candidate-background-color"
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'S/No',
                          className: 'w-5',
                        },
                        {
                          key: 'header_1',
                          header: 'Name',
                          className: 'w-15',
                        },
                        {
                          key: 'header_2',
                          header: 'Sex',
                          className: 'w-15',
                        },
                        {
                          key: 'header_3',
                          header: 'Scholarship',
                          className: 'w-15',
                        },
                        {
                          key: 'header_4',
                          header: 'Course',
                          className: 'w-15',
                        },
                        {
                          key: 'header_5',
                          header: 'Country',
                          className: 'w-15',
                        },
                        {
                          key: 'header_6',
                          header: 'School',
                          className: 'w-15',
                        },
                      ]}
                      rows={[
                        {
                          id: 1,
                          name: 'Zhang Li',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Engineering & ICT',
                          school: 'TJC',
                        },
                        {
                          id: 2,
                          name: 'Li Jie ',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Science / Mathematics',
                          school: 'VJC',
                        },
                        {
                          id: 3,
                          name: 'Zhang Yong',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Liberal Arts',
                          school: 'ACJC',
                        },
                        {
                          id: 4,
                          name: 'Wang Yong',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Science / Mathematics',
                          school: 'ASRJC',
                        },
                        {
                          id: 5,
                          name: ' Liu Yang',
                          scholarship: 'Engineering',
                          gender: 'Female',
                          country: 'SG',
                          course: 'Science / Mathematics',
                          school: 'CJC',
                        },
                      ].map((res, idx) => {
                        const {
                          id,
                          scholarship,
                          gender,
                          country,
                          school,
                          course,
                          name,
                        } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${id}`,
                          header_0: <div>{id}</div>,
                          header_1: <div>{name}</div>,
                          header_2: <div>{gender}</div>,
                          header_3: <div>{scholarship}</div>,
                          header_4: <div>{course}</div>,
                          header_5: <div>{country}</div>,
                          header_6: <div>{school}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                    <h5 className="mt-2 mb-2">Reserved Candidates</h5>
                    <GenericDataTable
                      className=""
                      tbodyClass="candidate-background-color"
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'S/No',
                          className: 'w-5',
                        },
                        {
                          key: 'header_1',
                          header: 'Name',
                          className: 'w-15',
                        },
                        {
                          key: 'header_2',
                          header: 'Sex',
                          className: 'w-15',
                        },
                        {
                          key: 'header_3',
                          header: 'Scholarship',
                          className: 'w-15',
                        },
                        {
                          key: 'header_4',
                          header: 'Course',
                          className: 'w-15',
                        },
                        {
                          key: 'header_5',
                          header: 'Country',
                          className: 'w-15',
                        },
                        {
                          key: 'header_6',
                          header: 'School',
                          className: 'w-15',
                        },
                      ]}
                      rows={[
                        {
                          id: 1,
                          name: 'Isaiah Chia',
                          scholarship: 'PSC Scholarship',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Humanities Social Sciences ',
                          school: 'Singapore Polytechnic',
                        },
                        {
                          id: 2,
                          name: 'Wee Baey Kok',
                          scholarship: 'Singapore Police Force',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Liberal Arts ',
                          school: 'HCI',
                        },
                        {
                          id: 3,
                          name: 'Jacob Lim',
                          scholarship: 'Singapore Armed Forces',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Science / Mathematics',
                          school: 'RI',
                        },
                        {
                          id: 4,
                          name: 'Sophia Wong',
                          scholarship: 'Engineering',
                          gender: 'Female',
                          country: 'SG',
                          course: 'Engineering & ICT',
                          school: 'ACS (I)',
                        },
                        {
                          id: 5,
                          name: 'Li wei',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Science / Mathematics',
                          school: 'DHS',
                        },
                        {
                          id: 6,
                          name: 'Wang Fang',
                          scholarship: 'Engineering',
                          gender: 'Female',
                          country: 'SG',
                          course: 'Liberal Arts',
                          school: 'EJC',
                        },
                        {
                          id: 7,
                          name: 'Chris Chong',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Science / Mathematics',
                          school: 'NJC',
                        },
                        {
                          id: 8,
                          name: 'Wang Xiu Ying',
                          scholarship: 'Foreign Service',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Law',
                          school: 'RI',
                        },
                        {
                          id: 9,
                          name: 'Wee Baey Kok',
                          scholarship: 'Foreign Service',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Humanities Social Sciences',
                          school: 'HCI',
                        },
                        {
                          id: 10,
                          name: 'Li Na',
                          scholarship: 'Medicine / Dentistry',
                          gender: 'Female',
                          country: 'SG',
                          course: 'Medicine / Dentistry',
                          school: 'VJC',
                        },
                        {
                          id: 11,
                          name: 'Zhang Xiu Ying',
                          scholarship: 'Engineering',
                          gender: 'Female',
                          country: 'SG',
                          course: 'Engineering & ICT',
                          school: 'NUSHS',
                        },
                        {
                          id: 12,
                          name: 'Liu Wei ',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Science / Mathematics',
                          school: 'RVHS',
                        },
                        {
                          id: 13,
                          name: 'Li Qiang',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Liberal Arts',
                          school: 'SJI',
                        },
                        {
                          id: 14,
                          name: 'Li Jun',
                          scholarship: 'Engineering',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Science / Mathematics',
                          school: 'SOTA',
                        },
                        {
                          id: 15,
                          name: 'Bingwen',
                          scholarship: 'Medicine / Dentistry',
                          gender: 'Male',
                          country: 'SG',
                          course: 'Medicine / Dentistry',
                          school: 'SSS',
                        },
                      ].map((res, idx) => {
                        const {
                          id,
                          scholarship,
                          gender,
                          country,
                          school,
                          course,
                          name,
                        } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${id}`,
                          header_0: <div>{id}</div>,
                          header_1: <div>{name}</div>,
                          header_2: <div>{gender}</div>,
                          header_3: <div>{scholarship}</div>,
                          header_4: <div>{course}</div>,
                          header_5: <div>{country}</div>,
                          header_6: <div>{school}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </Tile>
                </AccordionItem>
              </Accordion>

              {/* <div className="bx--row bx--tile pl-1 ml-1 mr-1 mb-2 align-items-center justify-content-center">
                <div
                  className="xpa-link"
                  onClick={this.handleGetPreviousBatchDetails}>
                  Click here to view previous batch details
                </div>
              </div> */}
              {/* <Tile className="mt-2">
                {showDashboard1 === false ? (
                  <Legend showNeoPIR={false} />
                ) : (
                  <MainLegend showNeoPIR={false} />
                )}
              </Tile> */}
            </div>
            <Modal
              open={isOpenCatModal}
              modalHeading={
                <div className="text-capitalize">
                  {scholarshipLabel} - {currBar}
                </div>
              }
              modalLabel="Action"
              primaryButtonText="Submit"
              secondaryButtonText="Cancel"
              passiveModal
              iconDescription="Close the modal"
              onRequestClose={() => {
                this.setState({ isOpenCatModal: false, currBar: '' });
              }}
              onRequestSubmit={() => {}}>
              <div style={{ height: 350 }}>
                <NivoPieChart
                  margin={{ top: 50, right: 10, bottom: 50, left: 10 }}
                  heading=""
                  data={pieChartData}
                  legendTranslateY={106}
                />
              </div>
            </Modal>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMock);
