import React from 'react';
import { Accordion, AccordionItem, DataTable } from 'carbon-components-react';
import { CSVLink } from 'react-csv';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} = DataTable;

const CsvTableData = (props) => {
  const { headers, data, csvHeader, downloadFileName } = props;
  return (
    <div style={{ overflow: 'hidden' }}>
      {data && Array.isArray(data) && data.length > 0 && (
        <div>
          <div className="text-right">
            <CSVLink
              data={data}
              headers={csvHeader}
              filename={`${downloadFileName}.csv`}
              className="bx--btn bx--btn--sm bx--btn--primary"
              target="_blank">
              Download
            </CSVLink>
          </div>
          <DataTable
            rows={data}
            headers={headers}
            render={({ rows, headers, getHeaderProps }) => {
              return (
                <TableContainer title="">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow className={row.className || ''} key={row.id}>
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CsvTableData;
