// Restricting URLS based on Role Ids
// New CMS Roles - CS (11), CSV (12), PA (13)
export const allowedRoutesForNewCMSRoles = [
  '/app/csc/calendar/interviews',
  '/app/csc/cms/meetings',
  '/app/csc/cms/create/new/meeting',
  '/app/csc/admin/profile',
  '/app/csc/cms/reports',
];

// Restricting URLS based on Role Id
// New 3rd party Role - Agency Member (14)
export const allowedRoutesFor3rdPartyRoles = [
  '/app/csc/high/level/reports',
  '/app/csc/assessments/reports',
  '/app/csc/admin/profile',
];
