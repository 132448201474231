import React from 'react';
import './CandidatePsychometricTests.css';

const CandidatePsychometricTests = (props) => {
  const { taskDetails } = props || {};
  const { neopi } = taskDetails || {};
  const neopiKeys = neopi && Object.keys(neopi);
  let neopiData = [];
  const neuroticismData = [];
  const extraversionData = [];
  const opennessData = [];
  const agreeablenessData = [];
  const conscientiousnessData = [];
  if (neopiKeys && Array.isArray(neopiKeys) && neopiKeys.length > 0) {
    neopiKeys.forEach((key) => {
      if (key === 'E') {
        extraversionData.push({
          lowDesc: '-',
          metric: 'Overall',
          fillColor: 'csc-th--na-light',
          highDesc: '-',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
          name: 'Extraversion',
        });
      } else if (key === 'E1') {
        extraversionData.push({
          lowDesc: 'Reserved, Distant, Cool',
          metric: 'Friendliness',
          highDesc: 'Friendly, Warm, Affectionate',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'E2') {
        extraversionData.push({
          lowDesc: 'Introverted, Prefers Privacy',
          metric: 'Sociability',
          highDesc: 'Gregarious, Prefers Company',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'E3') {
        extraversionData.push({
          lowDesc: 'Non-Assertive, Acquiescent, Passive',
          metric: 'Assertiveness',
          highDesc: 'Assertive, Forceful, Dominant',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'E4') {
        extraversionData.push({
          lowDesc: 'Sedentary, Slow-Paced, Inactive',
          metric: 'Pace of Living ',
          highDesc: 'Active, Energetic, Fast-Paced',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'E5') {
        extraversionData.push({
          lowDesc: 'Conventional, Uncomplicated Needs, Avoids Risk',
          metric: 'Excitement Seeking',
          highDesc: 'Adventurous, Daring, Pleasure Seeking',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'E6') {
        extraversionData.push({
          lowDesc: 'Serious, Sober, Lacking in Enthusiasm',
          metric: 'Positive Outlook',
          highDesc: 'Cheerful, Positive Exuberant',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'A') {
        agreeablenessData.push({
          lowDesc: '-',
          metric: 'Overall',
          fillColor: 'csc-th--na-light',
          highDesc: '-',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
          name: 'Agreeableness',
        });
      } else if (key === 'A1') {
        agreeablenessData.push({
          lowDesc: 'Suspicious, cynical,distrusting',
          metric: 'Trust',
          highDesc: 'Accepting, forgiving, trusting',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'A2') {
        agreeablenessData.push({
          lowDesc: 'Cautious, guarded, indirect, manipulative',
          metric: 'Straightforwardness',
          highDesc: 'Straightforward, frank, forthright',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'A3') {
        agreeablenessData.push({
          lowDesc: 'Self-absorbed, high self-interest',
          metric: 'Altruism',
          highDesc: 'Considerate, helpful, aware of others’ needs',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'A4') {
        agreeablenessData.push({
          lowDesc: 'Competitive, headstrong, contentious',
          metric: 'Compliance',
          highDesc: 'Deferent, compliant, cooperative',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'A5') {
        agreeablenessData.push({
          lowDesc:
            'Feels superior, considered arrogant, or conceited by others',
          metric: 'Modesty',
          highDesc: 'Humble, modest, self-effacing',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'A6') {
        agreeablenessData.push({
          lowDesc: 'Hard-headed, tough-minded, coldly logical',
          metric: 'Compassion',
          highDesc: 'Understanding, sympathetic, compassionate',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'O') {
        opennessData.push({
          lowDesc: '-',
          metric: 'Overall',
          fillColor: 'csc-th--na-light',
          highDesc: '-',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
          name: 'Openness',
        });
      } else if (key === 'O1') {
        opennessData.push({
          lowDesc: 'Concrete, unimaginative',
          metric: 'Openness to Imagination',
          highDesc: 'Abstract, creative, imaginative',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'O2') {
        opennessData.push({
          lowDesc: 'Prosaic, lack of interest in the arts',
          metric: 'Openness to Aesthetics',
          highDesc: 'Artistic, stylish, aesthetic',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'O3') {
        opennessData.push({
          lowDesc: 'Emotions are unimportant, limited in range, controlled',
          metric: 'Openness to Feelings',
          highDesc: 'Emotions are important, responsive, wide-ranging',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'O4') {
        opennessData.push({
          lowDesc:
            'Prefers status quo, narrow range of activities, predictable',
          metric: 'Openness to New Activities',
          highDesc:
            'Versatile, wide range of interests, willing to try new things',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'O5') {
        opennessData.push({
          lowDesc: 'Limited curiosity, narrowly focused',
          metric: 'Openness to Ideas',
          highDesc: 'Inquisitive, curious, open-minded',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'O6') {
        opennessData.push({
          lowDesc: 'Tradition-bound, conventional, dogmatic',
          metric: 'Openness to Values',
          highDesc:
            'Unconventional, open to a variety of behaviours and viewpoints',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'C') {
        conscientiousnessData.push({
          lowDesc: '-',
          metric: 'Overall',
          fillColor: 'csc-th--na-light',
          highDesc: '-',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
          name: 'Conscientiousness',
        });
      } else if (key === 'C1') {
        conscientiousnessData.push({
          lowDesc: 'Unsure, inefficient, unprepared',
          metric: 'Sense of Mastery',
          highDesc: 'Self-confident, resourceful',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'C2') {
        conscientiousnessData.push({
          lowDesc: 'Disorganized, unmethodical, careless',
          metric: 'Order',
          highDesc: 'Neat, tidy, orderly, well-organized',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'C3') {
        conscientiousnessData.push({
          lowDesc: 'Unreliable, undependable, irresponsible',
          metric: 'Dutifulness',
          highDesc: 'Dependable, principled, responsible',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'C4') {
        conscientiousnessData.push({
          lowDesc: 'Lackadaisical, complacent, aimless',
          metric: 'Achievement Striving',
          highDesc: 'Ambitious, goal oriented, enterprising',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'C5') {
        conscientiousnessData.push({
          lowDesc: 'Distractible, lack of follow-through, procrastinating',
          metric: 'Self-discipline',
          highDesc: 'Inner-motivated, persevering, completes what is started',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'C6') {
        conscientiousnessData.push({
          lowDesc: 'Hasty, impatient, spontaneous',
          metric: 'Deliberation',
          highDesc: 'Cautious, deliberate, thorough',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'N') {
        neuroticismData.push({
          lowDesc: '-',
          metric: 'Overall',
          fillColor: 'csc-th--na-light',
          highDesc: '-',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
          name: 'Neuroticism',
        });
      } else if (key === 'N1') {
        neuroticismData.push({
          lowDesc: 'Calm, Relaxed, Carefree',
          metric: 'Anxiety',
          highDesc: 'Apprehensive. Worried, Tense',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'N2') {
        neuroticismData.push({
          lowDesc: 'Patient, Even-Tempered, EasyGoing ',
          metric: 'Frustration',
          highDesc: 'Short-Tempered, Irritable, Intense',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'N3') {
        neuroticismData.push({
          lowDesc: 'Contented, Upbeat, Hopeful',
          metric: 'Despondency',
          highDesc: 'Discontented, Pessimistic ',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'N4') {
        neuroticismData.push({
          lowDesc: 'Socially Confident, Self-Assured',
          metric: 'Self Consciousness',
          highDesc: 'Shy, Inhibited, Self-Conscious',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'N5') {
        neuroticismData.push({
          lowDesc: 'Self-Denying, Stoic, Resists Temptation',
          metric: 'Impulsivity',
          highDesc: 'Self-Indulgent, easily tempted',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      } else if (key === 'N6') {
        neuroticismData.push({
          lowDesc: 'Self-Confident, Poised, ClearThinking, Calm Under Fire',
          metric: 'Stress Prone',
          highDesc: 'Confused, Panicky, Dependent',
          veryLow: neopi[key] === 'Very Low' ? 'x' : '',
          low: neopi[key] === 'Low' ? 'x' : '',
          average: neopi[key] === 'Average' ? 'x' : '',
          high: neopi[key] === 'High' ? 'x' : '',
          veryHigh: neopi[key] === 'Very High' ? 'x' : '',
        });
      }
    });
  }
  neopiData.push(...extraversionData);
  neopiData.push(...agreeablenessData);
  neopiData.push(...opennessData);
  neopiData.push(...conscientiousnessData);
  neopiData.push(...neuroticismData);
  return (
    <div>
      <div className="mt-2 h6 font-weight-bold text-capitalize">
        Psychometric test results
      </div>
      <hr />
      <div className="mt-2 mb-2">
        <div className="h6 font-weight-bold">
          Personality Profile (NEO PI-R)
        </div>
      </div>
      <div className="d-flex">
        <div className="overflow-scroll">
          <table className="table-border w-100 table-zoom-auto">
            <thead>
              <th className="css-domain-w">Domain</th>
              <th className="css-scale-w">Scale</th>
              <th className="css-desc-w ">Low Score Descriptors</th>
              <th colspan="5">Individual Results </th>
              <th className="css-desc-w ">High Score Descriptors</th>
            </thead>
            <thead>
              <th className="css-domain-w"></th>
              <th className="css-scale-w"></th>
              <th className="css-desc-w "></th>
              <th className="color-gray results-min-width">
                <div>1</div>
                <small>Very Low</small>
              </th>
              <th className="color-gray results-min-width">
                <div>2</div>
                <small>Low</small>
              </th>
              <th className="color-gray results-min-width">
                <div>3</div>
                <small>Average</small>
              </th>
              <th className="color-gray results-min-width">
                <div>4</div>
                <small>High</small>
              </th>
              <th className="color-gray results-min-width">
                <div>5</div>
                <small>Very High</small>
              </th>
              <th className="css-desc-w "></th>
            </thead>
            <tbody className="table-border text-left">
              {neopiData &&
                Array.isArray(neopiData) &&
                neopiData.length > 0 &&
                neopiData.map((data) => {
                  const {
                    lowDesc,
                    metric,
                    highDesc,
                    veryLow,
                    low,
                    average,
                    high,
                    veryHigh,
                    name,
                    fillColor,
                  } = data || {};
                  return (
                    <tr className="css-neo-row-h">
                      {name && (
                        <td
                          rowspan="7"
                          className="h6 font-weight-bold text-center">
                          {name}
                        </td>
                      )}

                      <td className={fillColor}>{metric}</td>
                      <td className={fillColor}>{lowDesc}</td>
                      <td
                        className={`${
                          fillColor && veryLow ? 'csc-th--na' : fillColor
                        } f-w-b ${
                          !fillColor && veryLow === 'x'
                            ? 'color-gray-light'
                            : ''
                        } text-center`}>
                        {veryLow}
                      </td>
                      <td
                        className={`${
                          fillColor && low ? 'csc-th--na' : fillColor
                        } f-w-b ${
                          !fillColor && low === 'x' ? 'color-gray-light' : ''
                        } text-center`}>
                        {low}
                      </td>
                      <td
                        className={`${
                          fillColor && average ? 'csc-th--na' : fillColor
                        } f-w-b ${
                          !fillColor && average === 'x'
                            ? 'color-gray-light'
                            : ''
                        } text-center`}>
                        {average}
                      </td>
                      <td
                        className={`${
                          fillColor && high ? 'csc-th--na' : fillColor
                        } f-w-b ${
                          !fillColor && high === 'x' ? 'color-gray-light' : ''
                        } text-center`}>
                        {high}
                      </td>
                      <td
                        className={`${
                          fillColor && veryHigh ? 'csc-th--na' : fillColor
                        } f-w-b ${
                          !fillColor && veryHigh === 'x'
                            ? 'color-gray-light'
                            : ''
                        } text-center`}>
                        {veryHigh}
                      </td>
                      <td className={`${fillColor} text-center`}>{highDesc}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CandidatePsychometricTests;
