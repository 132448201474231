import React, { Component } from 'react';
import { connect } from 'react-redux';

//API
import { getCandidateListData } from '../../actions/CandidateList';
import { upsertCandidateDetailsById } from '../../actions/CandidateInfo';
import {
  getAssignedPanelInterviewers,
  getNextInterviewDate,
  getBreakStartEndTime,
} from '../../actions/CalendarSelectionBoard';
import { downloadHighLevelReports } from '../../actions/HighLevelReports';
import {
  REQUEST_GET_CANDIDATE_INTERVIEWS_TODAY,
  REQUEST_GET_MY_UPCOMING_INTERVIEWS,
  REQUEST_GET_MY_PAST_INTERVIEWS,
  REQUEST_GET_ALL_UPCOMING_INTERVIEWS,
  REQUEST_GET_ALL_PAST_INTERVIEWS,
  REQUEST_GET_CANDIDATE_LIST,
} from '../../actions/actionTypes';

//Common Components
import BackRow from '../../components/common/BackRow';
import GenericTabs from '../../components/common/GenericTabs';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';

//Common Functional Components
import CandidateInterviewsList from '../../components/private/CandidateInterviewsList';
import AgendaMetrics from '../../components/private/AgendaMetrics';
import TodaysAgendaInterviews from '../../components/private/TodaysAgendaInterviews';

//Common Packages
import {
  Accordion,
  AccordionItem,
  Button,
  Tile,
  InlineNotification,
  RadioButton,
} from 'carbon-components-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class AgendaPanelInterviews extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'applicants', name: 'Panel Interviews', href: '' },
  ];

  constructor(props) {
    super(props);
    let upcomingStartDate = new Date();
    upcomingStartDate.setHours(0, 0, 0, 0);
    let upcomingEndDate = new Date(moment().add(30, 'd'));
    upcomingEndDate.setHours(23, 59, 59, 999);
    const dates = this.handleGetPastInterviewStartEndDates();
    const { interviewStartDate, interviewEndDate } = dates || {};
    this.state = {
      selectedTab: 0,
      todayIntActivePage: 1,
      todayIntItemsPerPage: 10,
      myUpcomingIntActivePage: 1,
      myUpcomingIntItemsPerPage: 10,
      myPastIntActivePage: 1,
      myPastIntItemsPerPage: 10,
      allUpcomingIntActivePage: 1,
      allUpcomingIntItemsPerPage: 10,
      allPastIntActivePage: 1,
      allPastIntItemsPerPage: 10,
      activePage: 1,
      itemsPerPage: 10,
      myUpcomingIntRecordType: 'PSC',
      myPastInterviewsRecordType: 'PSC',
      allUpcomingIntRecordType: 'PSC',
      allPastInterviewsRecordType: 'PSC',
      myUpcomingTab: 0,
      myPastTab: 0,
      allUpcomingTab: 0,
      allPastTab: 0,
      currentInterviewType: 'panel',
      upcomingStartDate: new Date(upcomingStartDate),
      upcomingEndDate: new Date(upcomingEndDate),
      pastStartDate: new Date(interviewStartDate),
      pastEndDate: new Date(interviewEndDate),
      breakTimeList: [],
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    this.handleRedirect &&
      this.handleRedirect.current &&
      this.handleRedirect.current.focus &&
      this.handleRedirect.current.focus();
    this.handleGetUnixTimeStamp();
  }

  handleGetUnixTimeStamp = () => {
    const { currentInterviewType } = this.state;
    this.props
      .getNextInterviewDate(currentInterviewType === 'panel' ? 'PSC' : 'PS')
      .then((res) => {
        const { unixDate } = res || {};
        this.setState(
          {
            unixDate: unixDate ? unixDate : new Date().getTime(),
          },
          () => {
            this.handleGetTodaysInterviewees();
            this.handleGetTodaysAgendaInterviews();
            this.handleGetBreakTime();
          }
        );
      });
  };

  handleGetBreakTime = () => {
    const { unixDate: date, currentInterviewType } = this.state;
    let startDate = new Date(date && Number(date));
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(date && Number(date));
    endDate.setHours(23, 59, 59, 999);
    this.props
      .getBreakStartEndTime(
        startDate && new Date(startDate).getTime(),
        endDate && new Date(endDate).getTime(),
        currentInterviewType === 'panel' ? 'PSC' : 'PS'
      )
      .then((res) => {
        if (res && !res.error) {
          const { list } = res || {};
          this.setState({
            breakTimeList: list || [],
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleGetTodaysInterviewees = () => {
    const {
      todayIntActivePage,
      todayIntItemsPerPage,
      currentInterviewType,
      unixDate,
    } = this.state;
    const { user } = this.props;
    const { currentYear } = user || {};
    let startDate = new Date(unixDate);
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(unixDate);
    endDate.setHours(23, 59, 59, 999);
    const interviewStartDate = new Date(startDate).getTime();
    const interviewEndDate = new Date(endDate).getTime();
    const data = {
      activePage: todayIntActivePage,
      itemsPerPage: todayIntItemsPerPage,
      searchKey: null,
      interviewStartDate,
      interviewEndDate,
      isInterviewList: true,
      year: currentYear,
      isTodaysInterview: true,
      recordType: currentInterviewType === 'panel' ? 'PSC' : 'PS',
    };
    this.props
      .getCandidateListData(data, null, REQUEST_GET_CANDIDATE_INTERVIEWS_TODAY)
      .then((res) => {
        if (res && !res.error) {
          this.handleGetTodaysAgendaBoardMembers();
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleGetmyUpcomingInterviews = () => {
    const {
      myUpcomingIntActivePage,
      myUpcomingIntItemsPerPage,
      myUpcomingIntRecordType,
      upcomingStartDate,
      upcomingEndDate,
    } = this.state;
    if (upcomingStartDate > upcomingEndDate) {
      this.setState({
        showMyUpcomingInterviewErr: true,
      });
      return;
    }
    this.setState(
      {
        showMyUpcomingInterviewErr: false,
      },
      () => {
        let startDate = new Date();
        const { user } = this.props;
        const { currentYear } = user || {};
        startDate.setHours(0, 0, 0, 0);
        const interviewStartDate = new Date(startDate).getTime();
        const endDate = moment().add(30, 'y');
        const interviewEndDate = new Date(endDate).getTime();
        const data = {
          activePage: myUpcomingIntActivePage,
          itemsPerPage: myUpcomingIntItemsPerPage,
          searchKey: null,
          isInterviewList: true,
          interviewStartDate: upcomingStartDate
            ? new Date(upcomingStartDate).getTime()
            : interviewStartDate,
          interviewEndDate: upcomingEndDate
            ? new Date(upcomingEndDate).getTime()
            : interviewEndDate,
          isCompleted: false,
          isCurrentUserMainInterviewer: true,
          year: currentYear,
          recordType: myUpcomingIntRecordType,
        };
        this.props.getCandidateListData(
          data,
          null,
          REQUEST_GET_MY_UPCOMING_INTERVIEWS
        );
      }
    );
  };

  handleGetTodaysAgendaInterviews = () => {
    const { activePage, itemsPerPage, currentInterviewType, unixDate } =
      this.state;
    let startDate = new Date(unixDate);
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(unixDate);
    endDate.setHours(23, 59, 59, 999);
    const interviewStartDate = new Date(startDate).getTime();
    const interviewEndDate = new Date(endDate).getTime();
    const { user } = this.props;
    const { currentYear } = user || {};
    const data = {
      activePage,
      itemsPerPage,
      searchKey: null,
      interviewStartDate,
      interviewEndDate,
      isInterviewList: true,
      year: currentYear,
      isTodaysInterview: true,
      recordType: currentInterviewType === 'panel' ? 'PSC' : 'PS',
    };
    this.props.getCandidateListData(data, null, REQUEST_GET_CANDIDATE_LIST);
  };

  handleGetTodaysAgendaBoardMembers = () => {
    const { currentInterviewType } = this.state;
    const currDate = new Date().setHours(0, 0, 0, 0);
    const date = new Date(currDate).getTime();
    const { interviewsToday } = this.props;
    let finalInterviewsTodayList = [];
    if (
      interviewsToday &&
      Array.isArray(interviewsToday) &&
      interviewsToday.length > 0
    ) {
      finalInterviewsTodayList = interviewsToday.filter((res) => {
        const { mainInterviewer, psMainInterviewer } = res || {};
        const { createdAt } =
          (mainInterviewer &&
            Array.isArray(mainInterviewer) &&
            mainInterviewer.length > 0 &&
            mainInterviewer[0]) ||
          {};
        const { createdAt: psCreatedAt } =
          (psMainInterviewer &&
            Array.isArray(psMainInterviewer) &&
            psMainInterviewer.length > 0 &&
            psMainInterviewer[0]) ||
          {};
        if (createdAt && currentInterviewType === 'panel') {
          return true;
        }
        if (psCreatedAt && currentInterviewType === 'president-scholarship') {
          return true;
        }
        return false;
      });
    }
    const { application } =
      (finalInterviewsTodayList &&
        Array.isArray(finalInterviewsTodayList) &&
        finalInterviewsTodayList.length > 0 &&
        finalInterviewsTodayList[0]) ||
      {};
    const { appId } =
      (application &&
        Array.isArray(application) &&
        application.length > 0 &&
        application[0]) ||
      {};
    this.props
      .getAssignedPanelInterviewers({
        appId,
        recordType: currentInterviewType === 'panel' ? 'PSC' : 'PS',
      })
      .then((res) => {
        if (res && !res.error) {
          const { selectionBoard } = res || {};
          const { list } = selectionBoard || {};
          this.setState({
            selectionBoardUsers: list,
          });
        } else {
          this.setState({
            selectionBoardUsers: [],
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleGetTodaysAgendaInterviewsPag = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetTodaysAgendaInterviews();
    });
  };

  handleGetMyPastInterviews = () => {
    const { user } = this.props;
    const { currentYear } = user || {};
    const {
      myPastIntActivePage,
      myPastIntItemsPerPage,
      myPastInterviewsRecordType,
      pastStartDate,
      pastEndDate,
    } = this.state;
    if (pastStartDate > pastEndDate) {
      this.setState({
        showMyPastInterViewErr: true,
      });
      return;
    }
    this.setState(
      {
        showMyPastInterViewErr: false,
      },
      () => {
        const dates = this.handleGetPastInterviewStartEndDates();
        const { interviewStartDate, interviewEndDate } = dates || {};
        const data = {
          activePage: myPastIntActivePage,
          itemsPerPage: myPastIntItemsPerPage,
          searchKey: null,
          isInterviewList: true,
          isCompleted: true,
          isCurrentUserMainInterviewer: true,
          interviewStartDate: pastStartDate
            ? new Date(pastStartDate).getTime()
            : interviewStartDate,
          interviewEndDate: pastEndDate
            ? new Date(pastEndDate).getTime()
            : interviewEndDate,
          year: currentYear,
          recordType: myPastInterviewsRecordType,
        };
        this.props.getCandidateListData(
          data,
          null,
          REQUEST_GET_MY_PAST_INTERVIEWS
        );
      }
    );
  };

  handleGetPastInterviewStartEndDates = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = moment().format('MM');
    const currentDay = moment().format('DD');
    let finalCurrentDay = 0;
    let finalCurrMonth = 0;
    if (currentDay == '01') {
      if (['01', '03', '05', '07', '8', '10', '12'].includes(currentMonth)) {
        const currentMonthNow = moment(
          `${currentYear}-${currentMonth}-${currentDay}`
        );
        const previousDay = moment(currentMonthNow).subtract(1, 'months');
        finalCurrentDay = 30;
        finalCurrMonth = moment(previousDay).format('MM');
      } else {
        finalCurrentDay = 31;
        finalCurrMonth = currentMonth;
      }
    } else {
      const currentDate = moment(
        `${currentYear}-${currentMonth}-${currentDay}`
      );
      const previousDay = moment(currentDate).subtract(1, 'days');
      finalCurrentDay = moment(previousDay).format('DD');
      finalCurrMonth = currentMonth;
    }
    let startDate = new Date(
      `${
        currentYear && Number(currentYear)
      }-${finalCurrMonth}-${finalCurrentDay}`
    );
    startDate.setHours(23, 59, 59, 999);
    const interviewEndDate = new Date(startDate).getTime();
    const fixedEndDate = new Date(
      `${currentYear && Number(currentYear - 1)}-08-01`
    ).toISOString();
    const endDate = new Date(fixedEndDate);
    endDate.setHours(0, 0, 0, 0);
    const interviewStartDate = new Date(endDate).getTime();
    return {
      interviewStartDate,
      interviewEndDate,
    };
  };

  handleGetAllUpcomingInterviews = () => {
    const {
      allUpcomingIntActivePage,
      allUpcomingIntItemsPerPage,
      allUpcomingIntRecordType,
      upcomingStartDate,
      upcomingEndDate,
    } = this.state;
    if (upcomingStartDate > upcomingEndDate) {
      this.setState({
        showAllUpcomingStartDateErr: true,
      });
      return;
    }

    this.setState(
      {
        showAllUpcomingStartDateErr: false,
      },
      () => {
        let startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        const interviewStartDate = new Date(startDate).getTime();
        const endDate = moment().add(30, 'y');
        const interviewEndDate = new Date(endDate).getTime();
        const { user } = this.props;
        const { currentYear } = user || {};
        const data = {
          activePage: allUpcomingIntActivePage,
          itemsPerPage: allUpcomingIntItemsPerPage,
          searchKey: null,
          isInterviewList: true,
          interviewStartDate: upcomingStartDate
            ? new Date(upcomingStartDate).getTime()
            : interviewStartDate,
          interviewEndDate: upcomingEndDate
            ? new Date(upcomingEndDate).getTime()
            : interviewEndDate,
          isCompleted: false,
          isCurrentUserMainInterviewer: false,
          year: currentYear,
          recordType: allUpcomingIntRecordType,
        };

        this.props.getCandidateListData(
          data,
          null,
          REQUEST_GET_ALL_UPCOMING_INTERVIEWS
        );
      }
    );
  };

  handleGetAllPastInterviews = () => {
    const {
      allPastIntActivePage,
      allPastIntItemsPerPage,
      allPastInterviewsRecordType,
      pastEndDate,
      pastStartDate,
    } = this.state;
    if (pastStartDate > pastEndDate) {
      this.setState({
        showAllPastInterviewsErr: true,
      });
      return;
    }
    this.setState(
      {
        showAllPastInterviewsErr: false,
      },
      () => {
        const { user } = this.props;
        const { currentYear } = user || {};
        const dates = this.handleGetPastInterviewStartEndDates();
        const { interviewStartDate, interviewEndDate } = dates || {};
        const data = {
          activePage: allPastIntActivePage,
          itemsPerPage: allPastIntItemsPerPage,
          searchKey: null,
          isInterviewList: true,
          isCompleted: true,
          isCurrentUserMainInterviewer: false,
          interviewStartDate: pastStartDate
            ? new Date(pastStartDate).getTime()
            : interviewStartDate,
          interviewEndDate: pastEndDate
            ? new Date(pastEndDate).getTime()
            : interviewEndDate,
          year: currentYear,
          recordType: allPastInterviewsRecordType,
        };
        this.props.getCandidateListData(
          data,
          null,
          REQUEST_GET_ALL_PAST_INTERVIEWS
        );
      }
    );
  };

  handleAllPastInterviewsPag = (data) => {
    const allPastIntActivePage = data.page || 1;
    const allPastIntItemsPerPage = data.pageSize || 10;
    this.setState({ allPastIntActivePage, allPastIntItemsPerPage }, () => {
      this.handleGetAllPastInterviews();
    });
  };

  handleAllUpcomingInterviewsPag = (data) => {
    const allUpcomingIntActivePage = data.page || 1;
    const allUpcomingIntItemsPerPage = data.pageSize || 10;
    this.setState(
      { allUpcomingIntActivePage, allUpcomingIntItemsPerPage },
      () => {
        this.handleGetAllUpcomingInterviews();
      }
    );
  };

  handleMyPastInterviewsPag = (data) => {
    const myPastIntActivePage = data.page || 1;
    const myPastIntItemsPerPage = data.pageSize || 10;
    this.setState({ myPastIntActivePage, myPastIntItemsPerPage }, () => {
      this.handleGetMyPastInterviews();
    });
  };

  handlemyUpcomingInterviewsPag = (data) => {
    const myUpcomingIntActivePage = data.page || 1;
    const myUpcomingIntItemsPerPage = data.pageSize || 10;
    this.setState(
      { myUpcomingIntActivePage, myUpcomingIntItemsPerPage },
      () => {
        this.handleGetmyUpcomingInterviews();
      }
    );
  };

  handleTodaysIntervieweesPag = (data) => {
    const todayIntActivePage = data.page || 1;
    const todayIntItemsPerPage = data.pageSize || 10;
    this.setState({ todayIntActivePage, todayIntItemsPerPage }, () => {
      this.handleGetTodaysInterviewees();
    });
  };

  handleTabClick = (tabNumber, name, recordType, recordName) => {
    this.setState(
      {
        [name]: tabNumber,
        [recordName]: recordType,
      },
      () => {
        if (name === 'selectedTab') {
          this.handleTriggerApplData();
        } else if (name === 'myUpcomingTab') {
          this.handleGetmyUpcomingInterviews();
        } else if (name === 'myPastTab') {
          this.handleGetMyPastInterviews();
        } else if (name === 'allUpcomingTab') {
          this.handleGetAllUpcomingInterviews();
        } else if (name === 'allPastTab') {
          this.handleGetAllPastInterviews();
        }
      }
    );
  };

  handleTriggerApplData = () => {
    const { user } = this.props;
    const { roleId } = user || {};
    const { selectedTab } = this.state;
    if (selectedTab === 0) {
      this.handleGetTodaysInterviewees();
    } else if (roleId == 7 && selectedTab === 1) {
      this.handleGetmyUpcomingInterviews();
      this.handleGetMyPastInterviews();
    } else if (selectedTab === 2 || (roleId !== 7 && selectedTab === 1)) {
      this.handleGetAllUpcomingInterviews();
      this.handleGetAllPastInterviews();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitCriteria = (appId) => {
    this.setState(
      {
        [`invalid-${appId}`]: this.state[`criteria-${appId}`] ? false : true,
      },
      () => {
        if (!this.state[`invalid-${appId}`]) {
          const data = {
            appId,
            criteria: this.state[`criteria-${appId}`],
          };
          this.props
            .upsertCandidateDetailsById(data)
            .then((res) => {
              if (res && !res.error) {
                this.setState(
                  {
                    [`success-${appId}`]: true,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        [`success-${appId}`]: false,
                      });
                    }, 5000);
                  }
                );
                this.handleTriggerApplData();
              } else {
                this.setState(
                  {
                    [`error-${appId}`]: true,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        [`error-${appId}`]: false,
                      });
                    }, 5000);
                  }
                );
              }
            })
            .catch((e) => console.log('Error', e));
        }
      }
    );
  };

  handlExportTodaysAgendaToPdf = (data) => {
    this.props
      .downloadHighLevelReports('todays-agenda', data)
      .then((pdf) => {
        if (pdf && !pdf.error) {
          const pdfDoc = URL.createObjectURL(pdf);

          const a = document.createElement('a');
          a.href = pdfDoc;
          a.download = `Agenda-${moment().format('MM/DD/YYYY')}`;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(pdfDoc);
          }, 1000);
        }
      })
      .catch((e) => {
        console.log('Error', e);
      });
  };

  handleToggleClick = () => {
    const { currentInterviewType } = this.state;
    this.setState(
      {
        currentInterviewType:
          currentInterviewType === 'panel' ? 'president-scholarship' : 'panel',
      },
      () => {
        this.handleGetUnixTimeStamp();
      }
    );
  };

  onDatePickerChange = (date, name) => {
    this.setState({ [name]: date });
  };

  nextDates = (date) => {
    const { pastEndDate } = this.state;
    return moment(date) < moment(pastEndDate);
  };

  render() {
    const {
      selectedTab,
      todayIntActivePage,
      myUpcomingIntActivePage,
      myPastIntActivePage,
      allUpcomingIntActivePage,
      allPastIntActivePage,
      activePage,
      showSuccessNotif,
      currentInterviewType,
      unixDate,
      upcomingStartDate,
      upcomingEndDate,
      breakTimeList,
    } = this.state;

    const {
      interviewsToday,
      interviewsTodayCount,
      history,
      myUpcomingInterviewsCount,
      myUpcomingInterviews,
      myPastInterviewsCount,
      myPastInterviews,
      allUpcomingInterviews,
      allUpcomingInterviewsCount,
      allPastInterviews,
      allPastInterviewsCount,
      loading,
      candidateData,
      candidateCount,
      selectionBoardLoading,
      user,
    } = this.props;
    const { selectionBoardUsers } = this.state;
    let finalInterviewsTodayList = [];
    if (
      interviewsToday &&
      Array.isArray(interviewsToday) &&
      interviewsToday.length > 0
    ) {
      finalInterviewsTodayList = interviewsToday.filter((res) => {
        const { mainInterviewer, psMainInterviewer } = res || {};
        const { createdAt } =
          (mainInterviewer &&
            Array.isArray(mainInterviewer) &&
            mainInterviewer.length > 0 &&
            mainInterviewer[0]) ||
          {};
        const { createdAt: psCreatedAt } =
          (psMainInterviewer &&
            Array.isArray(psMainInterviewer) &&
            psMainInterviewer.length > 0 &&
            psMainInterviewer[0]) ||
          {};
        if (createdAt && currentInterviewType === 'panel') {
          return true;
        }
        if (psCreatedAt && currentInterviewType === 'president-scholarship') {
          return true;
        }
        return false;
      });
    }

    let finalCandidateData = [];
    if (
      candidateData &&
      Array.isArray(candidateData) &&
      candidateData.length > 0
    ) {
      finalCandidateData = candidateData.filter((res) => {
        const { mainInterviewer, psMainInterviewer } = res || {};
        const { createdAt } =
          (mainInterviewer &&
            Array.isArray(mainInterviewer) &&
            mainInterviewer.length > 0 &&
            mainInterviewer[0]) ||
          {};
        const { createdAt: psCreatedAt } =
          (psMainInterviewer &&
            Array.isArray(psMainInterviewer) &&
            psMainInterviewer.length > 0 &&
            psMainInterviewer[0]) ||
          {};
        if (createdAt && currentInterviewType === 'panel') {
          return true;
        }
        if (psCreatedAt && currentInterviewType === 'president-scholarship') {
          return true;
        }
        return false;
      });

      finalCandidateData = finalCandidateData.map((res, idx) => {
        return { serialNo: idx + 1, ...res };
      });
    }

    let finalCandidateArr = finalCandidateData || [];

    if (
      breakTimeList &&
      Array.isArray(breakTimeList) &&
      breakTimeList.length > 0
    ) {
      breakTimeList.forEach((breakTime) => {
        const { startTime } = breakTime || {};
        const finalStartTime = startTime && moment(startTime);
        let candidateIndexBr = finalCandidateArr.findIndex((appl) => {
          const {
            mainInterviewer,
            psMainInterviewer,
            showBreak,
            createdAt: breakStartTime,
          } = appl || {};
          const { createdAt } =
            (mainInterviewer &&
              Array.isArray(mainInterviewer) &&
              mainInterviewer.length > 0 &&
              mainInterviewer[0]) ||
            {};

          const { createdAt: psCreatedAt } =
            (psMainInterviewer &&
              Array.isArray(psMainInterviewer) &&
              psMainInterviewer.length > 0 &&
              psMainInterviewer[0]) ||
            {};

          let currTime;
          if (showBreak) {
            currTime = breakStartTime && moment(breakStartTime);
          } else {
            if (createdAt && currentInterviewType === 'panel') {
              currTime = createdAt && moment(createdAt);
            }
            if (
              psCreatedAt &&
              currentInterviewType === 'president-scholarship'
            ) {
              currTime = psCreatedAt && moment(psCreatedAt);
            }
          }

          if (finalStartTime < currTime) {
            return true;
          }
          return false;
        });

        if (candidateIndexBr >= 0) {
          finalCandidateArr.splice(candidateIndexBr, 0, {
            showBreak: true,
            ...breakTime,
            createdAt: startTime,
          });
        } else {
          finalCandidateArr.push({
            showBreak: true,
            ...breakTime,
            createdAt: startTime,
          });
        }
      });
    }

    let finalPSCInterviewDate = '';
    let finalPSInterviewDate = '';
    if (
      finalCandidateData &&
      Array.isArray(finalCandidateData) &&
      finalCandidateData.length > 0
    ) {
      finalCandidateData.forEach((res) => {
        const { application } = res || {};
        const { psInterviewDay, pscInterviewDay } =
          (application &&
            Array.isArray(application) &&
            application.length > 0 &&
            application[0]) ||
          {};
        if (pscInterviewDay > 0) {
          finalPSCInterviewDate = pscInterviewDay;
        }
        if (psInterviewDay > 0) {
          finalPSInterviewDate = psInterviewDay;
        }
      });
    }
    const { roleId, currentYear, timerObj } = user || {};
    const tabContent = [
      {
        label: 'Upcoming Agenda',
        value: '',
        tabSelected: 0,
        onClick: () => {
          this.handleTabClick(0, 'selectedTab');
        },
      },
    ];
    if (roleId == 7) {
      tabContent.push(
        {
          label: 'My Interviews',
          value: '',
          tabSelected: 1,
          onClick: () => {
            this.handleTabClick(1, 'selectedTab');
          },
        },
        {
          label: 'All Interviews',
          value: '',
          tabSelected: 2,
          onClick: () => {
            this.handleTabClick(2, 'selectedTab');
          },
        }
      );
    } else {
      tabContent.push({
        label: 'All Interviews',
        value: '',
        tabSelected: 1,
        onClick: () => {
          this.handleTabClick(1, 'selectedTab');
        },
      });
    }
    const { push } = history || {};
    const attendingMembers = [];
    const notAttendingMembers = [];
    const secInAttendanceMembers = [];
    if (
      selectionBoardUsers &&
      Array.isArray(selectionBoardUsers) &&
      selectionBoardUsers.length > 0
    ) {
      selectionBoardUsers.forEach((member) => {
        const { selectionboard, action } = member || {};
        const { userinfo, aliasName, designation } = selectionboard || {};
        const { roleId: propRoleId, firstname, lastname } = userinfo || {};
        let finalFullName = '';
        const finalAliasName =
          (aliasName && aliasName.toString() && aliasName.toString().trim()) ||
          '';
        if (finalAliasName && finalAliasName.length > 0) {
          finalFullName = finalAliasName;
        } else {
          finalFullName = `${firstname} ${lastname}`;
        }
        const interviewObj = {
          fullName:
            propRoleId === 10 ? `${firstname} ${lastname}` : finalFullName,
          designation,
        };
        if ((propRoleId == 6 || propRoleId == 2) && action === true) {
          secInAttendanceMembers.push(interviewObj);
        } else if (
          propRoleId == 7 ||
          propRoleId == 8 ||
          propRoleId == 9 ||
          propRoleId == 10
        ) {
          if (action === true) {
            attendingMembers.push(interviewObj);
          } else if (
            (action === false || action === null) &&
            propRoleId != 10
          ) {
            notAttendingMembers.push(interviewObj);
          }
        }
      });
    }

    return (
      <div>
        <BackRow paths={this.paths} />
        <input
          style={{ height: 0, opacity: 0 }}
          type="text"
          ref={this.handleRedirect}
        />
        <div className="mb-3">
          <GenericTabs selected={selectedTab} tabContent={tabContent} />
        </div>
        {selectedTab === 0 && (
          <div>
            <Tile className="mt-2 mb-2">
              <div>
                <div className="f-w-b h6 mb-2">Interview Type</div>
                <div className="d-flex">
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      name="currentInterviewType"
                      labelText="PSSB"
                      value="panel"
                      onChange={this.handleToggleClick}
                      checked={currentInterviewType === 'panel'}
                    />
                  </div>
                  <div className="d-flex justify-content-left mt-2 ml-4">
                    <RadioButton
                      name="currentInterviewType"
                      labelText="President's Scholarship"
                      value="president-scholarship"
                      onChange={this.handleToggleClick}
                      checked={currentInterviewType === 'president-scholarship'}
                    />
                  </div>
                </div>
              </div>
            </Tile>
            {(roleId === 6 || roleId === 2) && (
              <div className="text-right mt-2 mb-2">
                <Button
                  kind="primary"
                  onClick={() =>
                    this.handlExportTodaysAgendaToPdf({
                      pscInterviewDay:
                        currentInterviewType === 'panel'
                          ? finalPSCInterviewDate
                          : finalPSInterviewDate,
                      attendingMembers,
                      notAttendingMembers,
                      secInAttendanceMembers,
                      finalCandidateArr,
                      finalInterviewsTodayList,
                      roleId,
                    })
                  }>
                  Export
                </Button>
              </div>
            )}
            <Accordion>
              <AccordionItem
                className="content-padding"
                open
                title={
                  <div className="h5 f-w-b bx--ty-gamma font-weight-light">
                    {currentYear}{' '}
                    {currentInterviewType === 'panel'
                      ? 'PSC Scholarships Selection Board'
                      : "President's Scholarship Selection Board"}
                  </div>
                }>
                <Tile>
                  <h5>
                    Day{' '}
                    {currentInterviewType === 'panel'
                      ? finalPSCInterviewDate
                      : finalPSInterviewDate}
                    : {unixDate && moment(unixDate).format('dddd, D MMMM YYYY')}
                  </h5>
                  <hr />
                  <AgendaMetrics selectionBoardUsers={selectionBoardUsers} />
                </Tile>
              </AccordionItem>
              <AccordionItem
                className="content-padding"
                title={
                  <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                    Agenda
                  </div>
                }>
                <Tile>
                  {/* <h6 className="mb-2">
                    Interviews of Candidates for PSC Scholarships
                  </h6> */}
                  <TodaysAgendaInterviews
                    push={push}
                    data={finalCandidateArr}
                    count={candidateCount}
                    handlePaginationChange={
                      this.handleGetTodaysAgendaInterviewsPag
                    }
                    activePage={activePage}
                    hideActionColumn
                    recordType={currentInterviewType === 'panel' ? 'PSC' : 'PS'}
                    timerObj={timerObj}
                  />
                </Tile>
              </AccordionItem>
              <AccordionItem
                className="content-padding"
                title={
                  <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                    Candidates
                  </div>
                }>
                {showSuccessNotif === true && (
                  <InlineNotification
                    lowContrast
                    title="The profiles have been downloaded successfully."
                    iconDescription="describes the close button"
                    kind="success"
                    onCloseButtonClick={() => {
                      this.setState({
                        showSuccessNotif: false,
                      });
                    }}
                  />
                )}
                <Tile>
                  <CandidateInterviewsList
                    data={finalInterviewsTodayList}
                    count={interviewsTodayCount}
                    push={push}
                    handlePaginationChange={this.handleTodaysIntervieweesPag}
                    activePage={todayIntActivePage}
                    showTime
                    state={this.state}
                    handleChange={this.handleChange}
                    handleSubmitCriteria={this.handleSubmitCriteria}
                    roleId={roleId}
                    recordType={currentInterviewType === 'panel' ? 'PSC' : 'PS'}
                  />
                </Tile>
              </AccordionItem>
            </Accordion>
          </div>
        )}
        {roleId == 7 && selectedTab === 1 && (
          <Accordion>
            <AccordionItem
              className="content-padding"
              title={
                <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                  My Upcoming Interviews
                </div>
              }>
              <div className="d-flex justify-content-end align-items-center">
                <div>
                  {this.state.showMyUpcomingInterviewErr === true && (
                    <div style={{ color: 'red' }} className="mb-2">
                      Please select a valid date. Start date cannot be greater
                      than end date.
                    </div>
                  )}
                  <div className="d-flex">
                    <div>
                      <div className="bx--label mb-2">From:</div>
                      <div>
                        <DatePicker
                          selected={new Date(upcomingStartDate)}
                          onChange={(date) =>
                            this.onDatePickerChange(date, 'upcomingStartDate')
                          }
                          showTimeSelect={false}
                          className="css-h-d-input"
                          timeCaption="time"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="ml-2">
                      <div className="bx--label mb-2">To:</div>
                      <div>
                        <DatePicker
                          selected={new Date(upcomingEndDate)}
                          onChange={(date) =>
                            this.onDatePickerChange(date, 'upcomingEndDate')
                          }
                          showTimeSelect={false}
                          className="css-h-d-input"
                          timeCaption="time"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="ml-2 mt-4 pt-2">
                      <Button
                        size="small"
                        onClick={this.handleGetmyUpcomingInterviews}>
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 pb-2">
                <GenericTabs
                  className=""
                  selected={this.state.myUpcomingTab}
                  tabContent={[
                    {
                      label: 'PSSB',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(
                          0,
                          'myUpcomingTab',
                          'PSC',
                          'myUpcomingIntRecordType'
                        );
                      },
                    },
                    {
                      label: "President's Scholarship",
                      value: '',
                      onClick: () => {
                        this.handleTabClick(
                          1,
                          'myUpcomingTab',
                          'PS',
                          'myUpcomingIntRecordType'
                        );
                      },
                    },
                  ]}
                />
              </div>
              <Tile>
                <CandidateInterviewsList
                  data={myUpcomingInterviews}
                  count={myUpcomingInterviewsCount}
                  push={push}
                  handlePaginationChange={this.handlemyUpcomingInterviewsPag}
                  activePage={myUpcomingIntActivePage}
                  state={this.state}
                  handleChange={this.handleChange}
                  handleSubmitCriteria={this.handleSubmitCriteria}
                  roleId={roleId}
                  recordType={this.state.myUpcomingIntRecordType}
                />
              </Tile>
            </AccordionItem>
            <AccordionItem
              className="content-padding"
              title={
                <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                  My Past Interviews
                </div>
              }>
              <div className="d-flex justify-content-end align-items-center">
                <div>
                  {this.state.showMyPastInterViewErr === true && (
                    <div style={{ color: 'red' }} className="mb-2">
                      Please select a valid date. Start date cannot be greater
                      than end date.
                    </div>
                  )}
                  <div className="d-flex">
                    <div>
                      <div className="bx--label mb-2">From:</div>
                      <div>
                        <DatePicker
                          selected={new Date(this.state.pastStartDate)}
                          onChange={(date) =>
                            this.onDatePickerChange(date, 'pastStartDate')
                          }
                          showTimeSelect={false}
                          className="css-h-d-input"
                          timeCaption="time"
                          filterDate={this.nextDates}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="ml-2">
                      <div className="bx--label mb-2">To:</div>
                      <div>
                        <DatePicker
                          selected={new Date(this.state.pastEndDate)}
                          onChange={(date) =>
                            this.onDatePickerChange(date, 'pastEndDate')
                          }
                          showTimeSelect={false}
                          className="css-h-d-input"
                          timeCaption="time"
                          filterDate={this.nextDates}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="ml-2 mt-4 pt-2">
                      <Button
                        size="small"
                        onClick={this.handleGetMyPastInterviews}>
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 pb-2">
                <GenericTabs
                  className=""
                  selected={this.state.myPastTab}
                  tabContent={[
                    {
                      label: 'PSSB',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(
                          0,
                          'myPastTab',
                          'PSC',
                          'myPastInterviewsRecordType'
                        );
                      },
                    },
                    {
                      label: "President's Scholarship",
                      value: '',
                      onClick: () => {
                        this.handleTabClick(
                          1,
                          'myPastTab',
                          'PS',
                          'myPastInterviewsRecordType'
                        );
                      },
                    },
                  ]}
                />
              </div>
              <Tile>
                <CandidateInterviewsList
                  data={myPastInterviews}
                  count={myPastInterviewsCount}
                  push={push}
                  handlePaginationChange={this.handleMyPastInterviewsPag}
                  activePage={myPastIntActivePage}
                  state={this.state}
                  handleChange={this.handleChange}
                  handleSubmitCriteria={this.handleSubmitCriteria}
                  roleId={roleId}
                  recordType={this.state.myPastInterviewsRecordType}
                />
              </Tile>
            </AccordionItem>
          </Accordion>
        )}
        {((roleId == 7 && selectedTab === 2) ||
          (roleId !== 7 && selectedTab === 1)) && (
          <Accordion>
            <AccordionItem
              className="content-padding"
              title={
                <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                  All Upcoming Interviews
                </div>
              }>
              <div className="d-flex justify-content-end align-items-center">
                <div className="mt-2">
                  {this.state.showAllUpcomingStartDateErr === true && (
                    <div style={{ color: 'red' }} className="mb-2">
                      Please select a valid date. Start date cannot be greater
                      than end date.
                    </div>
                  )}
                  <div className="d-flex">
                    <div>
                      <div className="bx--label mb-2">From:</div>
                      <div>
                        <DatePicker
                          selected={new Date(upcomingStartDate)}
                          onChange={(date) =>
                            this.onDatePickerChange(date, 'upcomingStartDate')
                          }
                          showTimeSelect={false}
                          className="css-h-d-input"
                          timeCaption="time"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="ml-2">
                      <div className="bx--label mb-2">To:</div>
                      <div>
                        <DatePicker
                          selected={new Date(upcomingEndDate)}
                          onChange={(date) =>
                            this.onDatePickerChange(date, 'upcomingEndDate')
                          }
                          showTimeSelect={false}
                          className="css-h-d-input"
                          timeCaption="time"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="ml-2 mt-4 pt-2">
                      <Button
                        size="small"
                        onClick={this.handleGetAllUpcomingInterviews}>
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 pb-2">
                <GenericTabs
                  className=""
                  selected={this.state.allUpcomingTab}
                  tabContent={[
                    {
                      label: 'PSSB',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(
                          0,
                          'allUpcomingTab',
                          'PSC',
                          'allUpcomingIntRecordType'
                        );
                      },
                    },
                    {
                      label: "President's Scholarship",
                      value: '',
                      onClick: () => {
                        this.handleTabClick(
                          1,
                          'allUpcomingTab',
                          'PS',
                          'allUpcomingIntRecordType'
                        );
                      },
                    },
                  ]}
                />
              </div>
              <Tile>
                <CandidateInterviewsList
                  data={allUpcomingInterviews}
                  count={allUpcomingInterviewsCount}
                  push={push}
                  handlePaginationChange={this.handleAllUpcomingInterviewsPag}
                  activePage={allUpcomingIntActivePage}
                  state={this.state}
                  handleChange={this.handleChange}
                  handleSubmitCriteria={this.handleSubmitCriteria}
                  roleId={roleId}
                  recordType={this.state.allUpcomingIntRecordType}
                />
              </Tile>
            </AccordionItem>
            <AccordionItem
              className="content-padding"
              title={
                <div className="h5 f-w-b bx--ty-gamma font-weight-light text-capitalize">
                  All Past Interviews
                </div>
              }>
              <div className="d-flex justify-content-end align-items-center">
                <div>
                  {this.state.showAllPastInterviewsErr === true && (
                    <div style={{ color: 'red' }} className="mb-2">
                      Please select a valid date. Start date cannot be greater
                      than end date.
                    </div>
                  )}
                  <div className="d-flex">
                    <div>
                      <div className="bx--label mb-2">From:</div>
                      <div>
                        <DatePicker
                          selected={new Date(this.state.pastStartDate)}
                          onChange={(date) =>
                            this.onDatePickerChange(date, 'pastStartDate')
                          }
                          showTimeSelect={false}
                          className="css-h-d-input"
                          timeCaption="time"
                          filterDate={this.nextDates}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="ml-2">
                      <div className="bx--label mb-2">To:</div>
                      <div>
                        <DatePicker
                          selected={new Date(this.state.pastEndDate)}
                          onChange={(date) =>
                            this.onDatePickerChange(date, 'pastEndDate')
                          }
                          showTimeSelect={false}
                          filterDate={this.nextDates}
                          className="css-h-d-input"
                          timeCaption="time"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="ml-2 mt-4 pt-2">
                      <Button
                        size="small"
                        onClick={this.handleGetAllPastInterviews}>
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 pb-2">
                <GenericTabs
                  className=""
                  selected={this.state.allPastTab}
                  tabContent={[
                    {
                      label: 'PSSB',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(
                          0,
                          'allPastTab',
                          'PSC',
                          'allPastInterviewsRecordType'
                        );
                      },
                    },
                    {
                      label: "President's Scholarship",
                      value: '',
                      onClick: () => {
                        this.handleTabClick(
                          1,
                          'allPastTab',
                          'PS',
                          'allPastInterviewsRecordType'
                        );
                      },
                    },
                  ]}
                />
              </div>
              <Tile>
                <CandidateInterviewsList
                  data={allPastInterviews}
                  count={allPastInterviewsCount}
                  push={push}
                  handlePaginationChange={this.handleAllPastInterviewsPag}
                  activePage={allPastIntActivePage}
                  state={this.state}
                  handleChange={this.handleChange}
                  handleSubmitCriteria={this.handleSubmitCriteria}
                  roleId={roleId}
                  recordType={this.state.allPastInterviewsRecordType}
                />
              </Tile>
            </AccordionItem>
          </Accordion>
        )}
        <JDProcessOverlay
          show={loading || selectionBoardLoading}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.CandidateList.loading,
  interviewsToday: state.CandidateList.interviewsToday,
  interviewsTodayCount: state.CandidateList.interviewsTodayCount,
  myUpcomingInterviews: state.CandidateList.myUpcomingInterviews,
  myUpcomingInterviewsCount: state.CandidateList.myUpcomingInterviewsCount,
  myPastInterviews: state.CandidateList.myPastInterviews,
  myPastInterviewsCount: state.CandidateList.myPastInterviewsCount,
  allUpcomingInterviews: state.CandidateList.allUpcomingInterviews,
  allUpcomingInterviewsCount: state.CandidateList.allUpcomingInterviewsCount,
  allPastInterviews: state.CandidateList.allPastInterviews,
  allPastInterviewsCount: state.CandidateList.allPastInterviewsCount,
  candidateData: state.CandidateList.candidateData,
  candidateCount: state.CandidateList.candidateCount,
  selectionBoardLoading: state.CalendarSelectionBoard.loading,
  selectionBoardUsers: state.CalendarSelectionBoard.selectionBoardUsers,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getCandidateListData,
  getAssignedPanelInterviewers,
  upsertCandidateDetailsById,
  downloadHighLevelReports,
  getNextInterviewDate,
  getBreakStartEndTime,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgendaPanelInterviews);
