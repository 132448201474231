import callApi from './callApi';

import { v2BaseUrl } from '../utils/config';

import {
  REQUEST_GET_SCORECARD_TEMPLATES,
  REQUEST_CREATE_SCORECARD_TEMPLATE,
  REQUEST_GET_SCORECARD_TEMPLATE_BY_ID,
  REQUEST_DELETE_SCORECARD_TEMPLATE_BY_ID,
  REQUEST_CREATE_SCORECARD_TEMPLATE_CONF_DATA,
  REQUEST_CREATE_RATING_CRITERIA_SCORECARD,
  REQUEST_DELETE_RATING_CRITERIA_SCORECARD,
  REQUEST_CREATE_APPLICANT_SCORECARD,
  REQUEST_GET_APPLICANT_SCORECARD_BY_ID,
  REQUEST_GET_ASSESSMENT_TYPES,
} from './actionTypes';

const createScorecardTemplate = (data) => (dispatch) => {
  const { scorecardTemplateId } = data || {};
  let url = `${v2BaseUrl}/scorecardtemplates`;
  if (scorecardTemplateId) {
    url += `/${scorecardTemplateId}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: scorecardTemplateId ? 'PATCH' : 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_SCORECARD_TEMPLATE,
  };

  return callApi(params);
};

const getScorecardTemplates = (data) => (dispatch) => {
  const { activePage = 1, itemsPerPage = 10 } = data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${v2BaseUrl}/scorecardtemplates?_=${new Date().getTime()}&`;

  url += `limit=${itemsPerPage}&`;
  url += `skip=${skip}&`;

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_SCORECARD_TEMPLATES,
  };
  return callApi(params);
};

const getScorecardTemplateById = (scorecardTemplateId) => (dispatch) => {
  if (!scorecardTemplateId) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${v2BaseUrl}/scorecardtemplates/${scorecardTemplateId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_SCORECARD_TEMPLATE_BY_ID,
  };
  return callApi(params);
};

const deletScorecardTemplateById = (scorecardTemplateId) => (dispatch) => {
  if (!scorecardTemplateId) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${v2BaseUrl}/scorecardtemplates/${scorecardTemplateId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: REQUEST_DELETE_SCORECARD_TEMPLATE_BY_ID,
  };
  return callApi(params);
};

const createScorecardTemplateConfData =
  (data, scorecardTemplateId) => (dispatch) => {
    if (!scorecardTemplateId) {
      const error = {
        msg: 'Invalid id passed',
      };
      return Promise.reject(error);
    }
    let url = `${v2BaseUrl}/scorecardtemplates/${scorecardTemplateId}?_=${new Date().getTime()}`;
    const params = {
      headers: {
        'content-type': 'application/json',
      },
      dispatch,
      method: 'PATCH',
      url,
      body: JSON.stringify(data),
      actionType: REQUEST_CREATE_SCORECARD_TEMPLATE_CONF_DATA,
    };

    return callApi(params);
  };

const createRatingCriteria = (data, ratingCriteriaId) => (dispatch) => {
  let url = `${v2BaseUrl}/ratingcriterias`;
  if (ratingCriteriaId) {
    url += `/${ratingCriteriaId}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: ratingCriteriaId ? 'PATCH' : 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_CREATE_RATING_CRITERIA_SCORECARD,
  };
  return callApi(params);
};

const deleteRatingCriteria = (data, ratingCriteriaId) => (dispatch) => {
  if (!ratingCriteriaId) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  let url = `${v2BaseUrl}/ratingcriterias/${ratingCriteriaId}`;

  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'DELETE',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_DELETE_RATING_CRITERIA_SCORECARD,
  };
  return callApi(params);
};

const upsertApplicantScorecard =
  (data, isRFeedbackForm = false, xuserid = '') =>
  (dispatch) => {
    const { scorecardId } = data || {};
    let url = `${v2BaseUrl}/scorecards`;
    if (scorecardId) {
      url += `/${scorecardId && Number(scorecardId)}`;
    }
    const params = {
      headers: {
        xuserid: xuserid,
        'content-type': 'application/json',
      },
      actionType: 'REQUEST_CREATE_APPLICANT_SCORECARD',
      dispatch,
      url,
      method: scorecardId ? 'PATCH' : 'POST',
      body: JSON.stringify(data),
      canUseToken: true,
    };

    return callApi(params);
  };

const getApplicantScorecardById = (scorecardId) => (dispatch) => {
  if (!scorecardId) {
    const error = {
      msg: 'Invalid id passed',
    };
    return Promise.reject(error);
  }
  const url = `${v2BaseUrl}/scorecards/${scorecardId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_APPLICANT_SCORECARD_BY_ID,
  };
  return callApi(params);
};

const getAssessmentTypes = () => (dispatch) => {
  const url = `${v2BaseUrl}/tests?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_ASSESSMENT_TYPES,
  };
  return callApi(params);
};

export {
  getScorecardTemplates,
  createScorecardTemplate,
  getScorecardTemplateById,
  deletScorecardTemplateById,
  createScorecardTemplateConfData,
  createRatingCriteria,
  deleteRatingCriteria,
  upsertApplicantScorecard,
  getApplicantScorecardById,
  getAssessmentTypes,
};
