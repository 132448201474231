import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Select,
  SelectItem,
  FileUploaderButton,
  Button,
} from 'carbon-components-react';

//Api Actions
import {
  getCandidateListData,
  getJobApplFilters,
  assignApplsToIntStage,
  getSaticApplFilters,
} from '../../actions/CandidateList';
import { REQUEST_GET_CANDIDATE_LIST } from '../../actions/actionTypes';

//Common Components
import BackRow from '../../components/common/BackRow';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import GenericTabs from '../../components/common/GenericTabs';
import Dropdown from '../../components/common/Dropdown';

//Functional common components
import CandidateListCommon from '../../components/private/CandidateListCommon';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import CandidateListStages from '../../components/private/CandidateListStages';

const getSelectItems = (currRecipientList) => {
  if (
    currRecipientList &&
    Array.isArray(currRecipientList) &&
    currRecipientList.length > 0
  ) {
    return currRecipientList.map((itm) => {
      const { userId, userinfo } = itm || {};
      const { firstname = '', lastname = '' } = userinfo || {};
      return (
        <SelectItem
          text={`${firstname} ${lastname}`}
          key={userId}
          id={userId}
          value={userId}
        />
      );
    });
  }
};

class CandidateList extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'applicants', name: "Candidate's Information", href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 10,
      searchKey: null,
      showLoader: false,
      selectedTab: 0,
      isOpenSubmitCandModal: false,
      videoStatus: 'Video Status',
      assignedTo: 'Assigned To',
      scenario: 'Scenario',
      selRecipientStage2: 'Select',
      selRecipientStage3: 'Select',
      proceedToStage3: false,
      isOpenConcurrencyErrMsgModal: false,
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    this.props.getJobApplFilters();
    this.props.getSaticApplFilters();
    this.setState(
      {
        showLoader: true,
      },
      () => {
        this.handleGetCandidateListApi();
      }
    );
    this.handleRedirect &&
      this.handleRedirect.current &&
      this.handleRedirect.current.focus &&
      this.handleRedirect.current.focus();
  }

  handleGetCandidateListApi = (appIdsToBeFiltered) => {
    const {
      activePage,
      itemsPerPage,
      searchKey,
      year,
      selectedTab,
      videoStatus,
      scenario,
      assignedTo,
    } = this.state;
    const { user } = this.props;
    const { currentYear } = user || {};
    const data = {
      activePage,
      itemsPerPage,
      searchKey,
      getOnlyNecessaryDetail: true,
      appIds: appIdsToBeFiltered,
    };
    if (year) {
      data.year = year;
    } else {
      data.year = currentYear;
    }

    if (selectedTab === 0) {
      if (videoStatus && videoStatus !== 'Video Status') {
        data.videoStatusSearch = videoStatus;
      }
      if (assignedTo && assignedTo !== 'Assigned To') {
        data.assignedToSearchId = assignedTo;
      }
      if (scenario && scenario !== 'Scenario' && scenario !== 'All') {
        data.scenarioId = scenario;
      }
    } else {
      data.stageId = selectedTab;
    }

    this.props
      .getCandidateListData(data, null, REQUEST_GET_CANDIDATE_LIST)
      .then((res) => {
        if (res && !res.error) {
          this.setState({
            showLoader: false,
            year: data.year,
          });
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handlePaginationChange = (data) => {
    const activePage = data.page || 1;
    const itemsPerPage = data.pageSize || 10;
    this.setState({ activePage, itemsPerPage }, () => {
      this.handleGetCandidateListApi();
    });
  };

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState(
      {
        searchKey: event.target.value,
        isLoading: true,
        activePage: 1,
        itemsPerPage: 10,
      },
      () => {
        this.searchTimer = setTimeout(() => {
          this.handleGetCandidateListApi();
        }, 1000);
      }
    );
  };

  handleFilterDropDown = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.handleGetCandidateListApi();
      }
    );
  };

  handleChangeDropdown = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleAddCandidatesToCompareAppl = (appId) => {
    const localStorageItem = localStorage.getItem('application-ids');
    const parsedLocalStorageItem =
      (localStorageItem && JSON.parse(localStorageItem)) || [];
    if (
      parsedLocalStorageItem &&
      Array.isArray(parsedLocalStorageItem) &&
      parsedLocalStorageItem.length > 0
    ) {
      parsedLocalStorageItem.push(appId);
      localStorage.setItem(
        'application-ids',
        JSON.stringify(parsedLocalStorageItem)
      );
    } else {
      localStorage.setItem('application-ids', JSON.stringify([appId]));
    }
    this.setState(
      {
        successNotif: true,
        notifMsg: 'The candidate is added successfully to the compared list.',
      },
      () => {
        this.handleGetCandidateListApi();
        setTimeout(() => {
          this.setState({
            successNotif: false,
            notifMsg: null,
          });
        }, 5000);
      }
    );
  };

  handleRemoveCandidatesFromCompare = (appId) => {
    const localStorageItem = localStorage.getItem('application-ids');
    const parsedLocalStorageItem =
      (localStorageItem && JSON.parse(localStorageItem)) || [];
    if (
      parsedLocalStorageItem &&
      Array.isArray(parsedLocalStorageItem) &&
      parsedLocalStorageItem.length > 0
    ) {
      let finalParsedAPpIds = parsedLocalStorageItem.filter(
        (res) => res !== appId
      );
      localStorage.setItem(
        'application-ids',
        JSON.stringify(finalParsedAPpIds)
      );
    } else {
      localStorage.removeItem('application-ids');
    }
    this.setState(
      {
        successNotif: true,
        notifMsg:
          'The candidate is successfully removed from the compared list.',
      },
      () => {
        this.handleGetCandidateListApi();
        setTimeout(() => {
          this.setState({
            successNotif: false,
            notifMsg: null,
          });
        }, 5000);
      }
    );
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleTabClick = (tab) => {
    const updateState = { selectedTab: tab, searchKey: null };
    if (tab === 0) {
      updateState.videoStatus = 'Video Status';
      updateState.assignedTo = 'Assigned To';
      updateState.scenario = 'Scenario';
    }
    this.setState(updateState, () => this.handleGetCandidateListApi());
  };

  batchActionClickHandler = (actionItems, actionState) => {
    let appIds = [];
    const { selectedTab } = this.state;
    if (actionItems && Array.isArray(actionItems) && actionItems.length > 0) {
      appIds = actionItems.map(({ id = '' }) => Number(id));
    }
    if (
      actionState === 'assign-candidates' ||
      actionState === 'submit-candidates-pi'
    ) {
      this.setState({
        appIds,
        isOpenSubmitCandModal: true,
        proceedToStage3:
          selectedTab == 4 && actionState === 'assign-candidates',
      });
    }
  };

  dismissSubmitCandModal = () => {
    this.setState({
      isOpenSubmitCandModal: false,
      selRecipientStage2: 'Select',
      isSelRecepStage2Invalid: false,
      selRecipientStage3: 'Select',
      isSelRecepStage3Invalid: false,
      proceedToStage3: false,
    });
  };

  handleAssignCandToInterviewStage = (currRecipientList) => {
    const { candidateData } = this.props;
    const {
      appIds,
      selectedTab,
      selRecipientStage2,
      selRecipientStage3,
      proceedToStage3,
    } = this.state;

    let isSelRecepStage2Invalid =
      selectedTab === 2 &&
      (selRecipientStage2 === 'Select' || !selRecipientStage2);

    let isSelRecepStage3Invalid =
      selectedTab === 3 &&
      (selRecipientStage3 === 'Select' || !selRecipientStage3);

    this.setState({ isSelRecepStage2Invalid, isSelRecepStage3Invalid });

    const data = { appIds };
    if (selectedTab === 1) {
      data.stageId = 1;
      data.roleId = 6;
    } else if (selectedTab === 2) {
      data.stageId = 2;
      if (selRecipientStage2 === 'Both') {
        data.recipients =
          currRecipientList &&
          Array.isArray(currRecipientList) &&
          currRecipientList.length > 0 &&
          currRecipientList.map((itm) => itm?.userId);
      } else {
        data.recipients = [selRecipientStage2];
      }
    } else if (selectedTab === 3) {
      data.stageId = 3;
      data.recipients = [selRecipientStage3];
    } else if (selectedTab === 4) {
      data.stageId = 4;
      data.submitToPi = !proceedToStage3;
    }

    if (
      appIds &&
      Array.isArray(appIds) &&
      appIds.length > 0 &&
      candidateData &&
      Array.isArray(candidateData) &&
      candidateData.length > 0 &&
      (selectedTab === 1 ||
        (selectedTab === 2 && !isSelRecepStage2Invalid) ||
        (selectedTab === 3 && !isSelRecepStage3Invalid) ||
        selectedTab === 4)
    ) {
      this.props
        .assignApplsToIntStage(data)
        .then((res) => {
          this.dismissSubmitCandModal();
          if (res && !res.error) {
            this.setState(
              {
                successNotif: true,
                notifMsg: `The selected candidate(s) have been assigned to ${
                  selectedTab === 1
                    ? 'CSC'
                    : selectedTab === 2
                    ? 'Secy/Dir'
                    : selectedTab === 3
                    ? 'Chairman/CM'
                    : 'PI'
                } successfully`,
              },
              () => {
                this.handleGetCandidateListApi();
                setTimeout(() => {
                  this.handleCloseNotifBtn();
                }, 5000);
              }
            );
          } else {
            this.setState(
              {
                errorNotif: true,
                errMsg: res?.error?.message,
                isOpenConcurrencyErrMsgModal: selectedTab === 4,
              },
              () => {
                setTimeout(() => {
                  this.handleCloseNotifBtn();
                }, 5000);
              }
            );
          }
        })
        .catch((e) => {
          console.log('Error', e);
          this.setState(
            {
              errorNotif: true,
            },
            () => {
              setTimeout(() => {
                this.handleCloseNotifBtn();
              }, 5000);
            }
          );
        });
    }
  };

  handleMultiDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    });
  };

  handleClearAllFilters = () => {
    this.setState(
      {
        activePage: 1,
        itemsPerPage: 10,
        searchKey: null,
        videoStatus: 'Video Status',
        assignedTo: 'Assigned To',
        scenario: 'Scenario',
      },
      () => this.handleGetCandidateListApi()
    );
  };

  dismissConcurrencyErrMsgModal = () => {
    this.setState({ isOpenConcurrencyErrMsgModal: false });
  };

  render() {
    const {
      candidateData,
      history,
      candidateCount,
      loading,
      jobApplFilters,
      user,
      staticApplFilterInfo,
    } = this.props;
    const { roleId } = user || {};
    const { push } = history || {};
    const { assignedToList, videoStatusList, scenariosList } =
      staticApplFilterInfo || {};
    const {
      activePage,
      year,
      showLoader,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      selectedTab,
      isOpenSubmitCandModal,
      videoStatus,
      assignedTo,
      scenario,
      selRecipientStage2,
      isSelRecepStage2Invalid,
      selRecipientStage3,
      isSelRecepStage3Invalid,
      proceedToStage3,
      isOpenConcurrencyErrMsgModal,
    } = this.state;

    let currRecipientList = [];
    if (
      assignedToList &&
      Array.isArray(assignedToList) &&
      assignedToList.length > 0
    ) {
      currRecipientList = assignedToList.filter(
        (itm) => itm?.videointerviewstages?.stageId === selectedTab
      );
    }

    let tabContent = [
      {
        label: 'Candidates List',
        value: '',
        onClick: () => {
          this.handleTabClick(0);
        },
      },
    ];

    if (roleId == 6) {
      tabContent.push(
        {
          label: 'CSC',
          value: '',
          onClick: () => {
            this.handleTabClick(1);
          },
        },
        {
          label: 'Secy/Dir',
          value: '',
          onClick: () => {
            this.handleTabClick(2);
          },
        },
        {
          label: 'Chairman/CM',
          value: '',
          onClick: () => {
            this.handleTabClick(3);
          },
        },
        {
          label: 'PSC Sect',
          value: '',
          onClick: () => {
            this.handleTabClick(4);
          },
        }
      );
    }

    return (
      <div>
        <BackRow paths={this.paths} />
        <input
          style={{ height: 0, opacity: 0 }}
          type="text"
          ref={this.handleRedirect}
        />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
          errMsg={errMsg}
        />
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-3">
          <GenericTabs
            selected={selectedTab}
            className="mb-3"
            tabContent={tabContent}
          />
          {selectedTab === 0 && (
            <div className="d-flex mt-2 mt-lg-0">
              <div className="ml-lg-3" style={{ minWidth: '170px' }}>
                <Button
                  size="small"
                  onClick={() => this.handleClearAllFilters()}>
                  Clear all filters
                </Button>
              </div>
            </div>
          )}
        </div>

        {selectedTab === 0 && (
          <CandidateListCommon
            candidateData={candidateData}
            activePage={activePage}
            handleSearchSubmit={this.handleSearchSubmit}
            candidateCount={candidateCount}
            handlePaginationChange={this.handlePaginationChange}
            push={push}
            year={year}
            jobApplFilters={jobApplFilters}
            handleFilterDropDown={this.handleFilterDropDown}
            handleAddCandidatesToCompareAppl={
              this.handleAddCandidatesToCompareAppl
            }
            handleRemoveCandidatesFromCompare={
              this.handleRemoveCandidatesFromCompare
            }
            roleId={roleId}
            videoStatusList={videoStatusList}
            assignedToList={assignedToList}
            videoStatus={videoStatus}
            assignedTo={assignedTo}
            scenariosList={scenariosList}
            scenario={scenario}
          />
        )}

        {(selectedTab === 1 ||
          selectedTab === 2 ||
          selectedTab === 3 ||
          selectedTab === 4) && (
          <CandidateListStages
            selectedTab={selectedTab}
            candidateData={candidateData}
            activePage={activePage}
            candidateCount={candidateCount}
            handlePaginationChange={this.handlePaginationChange}
            batchActionClickHandler={this.batchActionClickHandler}
            handleSearchSubmit={this.handleSearchSubmit}
          />
        )}

        {isOpenSubmitCandModal && (
          <Modal
            className={
              selectedTab === 2 || selectedTab === 3 ? 'modal-height' : ''
            }
            open={isOpenSubmitCandModal}
            modalHeading={`${
              selectedTab === 4 && !proceedToStage3 ? 'Submit' : 'Assign'
            } Candidates`}
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onSecondarySubmit={this.dismissSubmitCandModal}
            onRequestClose={this.dismissSubmitCandModal}
            onRequestSubmit={() =>
              this.handleAssignCandToInterviewStage(currRecipientList)
            }>
            <div className="mt-2 mb-2">
              {`Are you sure you want to ${
                selectedTab === 4 ? 'submit' : 'assign'
              } the selected candidates to `}
              {selectedTab === 1
                ? 'CSC'
                : selectedTab === 2
                ? 'Secy/Dir'
                : selectedTab === 3 || (selectedTab === 4 && proceedToStage3)
                ? 'Chairman/CM'
                : 'PI'}
              ?
            </div>
            {selectedTab === 2 && (
              <div className="mt-2">
                <Select
                  className="w-50"
                  name="selRecipientStage2"
                  id="selRecipientStage2"
                  onChange={this.handleChangeDropdown}
                  value={selRecipientStage2}
                  invalid={isSelRecepStage2Invalid}
                  invalidText="Please select valid recipient(s)"
                  labelText="Select Recipient(s)">
                  <SelectItem
                    text="Select"
                    key="Select"
                    value="Select"
                    disabled
                  />
                  {currRecipientList &&
                    Array.isArray(currRecipientList) &&
                    currRecipientList.length === 2 && (
                      <SelectItem text="Both" key="Both" value="Both" />
                    )}
                  {getSelectItems(currRecipientList)}
                </Select>
              </div>
            )}
            {selectedTab === 3 && (
              <div className="mt-2">
                <Select
                  className="w-50"
                  name="selRecipientStage3"
                  id="selRecipientStage3"
                  onChange={this.handleChangeDropdown}
                  value={selRecipientStage3}
                  invalid={isSelRecepStage3Invalid}
                  invalidText="Please select valid recipient"
                  labelText="Select Recipient">
                  <SelectItem
                    text="Select"
                    key="Select"
                    value="Select"
                    disabled
                  />
                  {getSelectItems(currRecipientList)}
                </Select>
              </div>
            )}
          </Modal>
        )}

        {isOpenConcurrencyErrMsgModal && (
          <Modal
            open={isOpenConcurrencyErrMsgModal}
            modalHeading="Error Message Info"
            modalLabel="Action"
            primaryButtonText="Ok"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onSecondarySubmit={this.dismissConcurrencyErrMsgModal}
            onRequestClose={this.dismissConcurrencyErrMsgModal}
            onRequestSubmit={this.dismissConcurrencyErrMsgModal}>
            <div className="mt-2 mb-2 css-color-red">
              {errMsg || 'There was an error while processing your request.'}
            </div>
          </Modal>
        )}

        <JDProcessOverlay
          show={loading || showLoader}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.CandidateList.filters,
  candidateData: state.CandidateList.candidateData,
  candidateCount: state.CandidateList.candidateCount,
  loading: state.CandidateList.loading,
  user: state.x0paDuser.user,
  jobApplFilters: state.CandidateList.jobApplFilters,
  staticApplFilterInfo: state.CandidateList.staticApplFilterInfo,
});

const mapDispatchToProps = {
  getCandidateListData,
  getJobApplFilters,
  assignApplsToIntStage,
  getSaticApplFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateList);
