import React, { useEffect, useState } from 'react';

const loadGMaps = (props, callback) => {
  const { url, jwt, id } = props || {};

  const existingScript = document.getElementById('googleMaps');
  if (!existingScript) {
    const script = document.createElement('script');
    script.innerHTML = `
    var myOptions = {
      techOrder: ["azureHtml5JS", "flashSS", "html5FairPlayHLS","silverlightSS", "html5"],
      "logo": {
          "enabled": false
      },
          autoplay: false,
          controls: true,
          muted: false,
          "nativeControlsForTouch": false,
          width: "480",
          height: "360"
      };
      var myPlayer = amp('${id}', myOptions);
      myPlayer.src([{ src: "${url}", type: "application/vnd.ms-sstr+xml", protectionInfo: [{ type: "AES", authenticationToken: "${jwt}" }] }, ]);
    `;
    script.id = 'googleMaps';
    const style = document.createElement('style');
    style.innerHTML = `.amp-default-skin {
      overflow: hidden;
    }`;
    document.head.appendChild(style);
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const AmpLoader = (props) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    loadGMaps(props, () => {
      setLoaded(true);
    });
  });

  const { url, jwt, id } = props || {};

  return (
    <video
      id={id}
      class="azuremediaplayer amp-default-skin amp-big-play-centered"
      tabindex="0">
      {' '}
    </video>
  );
};
export default AmpLoader;
