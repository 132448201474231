import React, { Component } from 'react';
import { connect } from 'react-redux';

//actions
import {
  getCandidateNotesListByAppId,
  createNewNote,
  deleteNotesData,
  upsertNotesData,
} from '../../actions/CandidateNotes';

//Functional Components
import SuccErrNotification from '../../components/private/SuccErrNotification';
import GenericDataTable from '../../components/common/GenericDataTable';

//Common Packages
import {
  Accordion,
  AccordionItem,
  Button,
  Tile,
  Modal,
} from 'carbon-components-react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import {
  EditorState,
  convertToRaw,
  ContentState,
  Modifier,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment';
import './CandidateNotes.css';

const renderHtml = (htmlObject) => {
  return (
    <div className="csc-cand-notes-css" dangerouslySetInnerHTML={htmlObject} />
  );
};

class CandidateNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: EditorState.createEmpty(),
      editNotes: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    this.handleGetNotesAPi();
  }

  handleGetNotesAPi = () => {
    const { appId } = this.props;
    this.props.getCandidateNotesListByAppId(appId);
  };

  onEditorStateChange = (editorState) => {
    this.setState({ notes: editorState });
  };

  onUpsertEditorStateChange = (editorState) => {
    this.setState({ editNotes: editorState });
  };

  handleSubmitNotes = () => {
    const { notes } = this.state;
    const content = notes.getCurrentContent();
    const lengthOfTrimmedContent = content.getPlainText().trim().length;
    this.setState(
      {
        isNoteValid:
          !content.hasText() || !lengthOfTrimmedContent ? true : false,
      },
      () => {
        const { isNoteValid } = this.state;
        if (!isNoteValid) {
          const { appId } = this.props || {};
          const data = {
            appId,
            notes: draftToHtml(convertToRaw(notes.getCurrentContent())),
          };
          this.props
            .createNewNote(data)
            .then((res) => {
              this.handleApiRes(res);
            })
            .catch((error) => console.log('Error', error));
        }
      }
    );
  };

  handleApiRes = (res) => {
    if (res && !res.error) {
      this.setState({
        successNotif: true,
        notifMsg: 'Your changes have been updated successfully',
        errorNotif: false,
        isEditForm: false,
        notes: EditorState.createEmpty(),
      });
      this.handleGetNotesAPi();
    } else {
      this.setState({
        errorNotif: true,
        isEditForm: false,
      });
    }
  };

  activeEditNotes = (data) => {
    const { noteId, notes } = data || {};
    const html = notes;
    const contentBlock = htmlToDraft(html);
    let editorState = EditorState.createEmpty();
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editorState = EditorState.createWithContent(contentState);
    }
    this.setState({
      isOpenEditNoteModal: true,
      noteId,
      editNotes: editorState,
    });
  };

  dismissEditNoteModal = () => {
    this.setState({
      isOpenEditNoteModal: false,
      noteId: 0,
      editNotes: EditorState.createEmpty(),
    });
  };

  handleSubmitEditNote = () => {
    const { editNotes, noteId } = this.state;
    const content = editNotes.getCurrentContent();
    const lengthOfTrimmedContent = content.getPlainText().trim().length;
    this.setState(
      {
        isNoteValid:
          !content.hasText() || !lengthOfTrimmedContent ? true : false,
      },
      () => {
        const { isNoteValid } = this.state;
        if (!isNoteValid) {
          const { appId } = this.props || {};
          const data = {
            appId,
            notes: draftToHtml(convertToRaw(editNotes.getCurrentContent())),
          };
          this.props
            .upsertNotesData(noteId, data)
            .then((res) => {
              this.dismissEditNoteModal();
              this.handleApiRes(res);
            })
            .catch((error) => console.log('Error', error));
        }
      }
    );
  };

  activeDeleteNotes = (noteId) => {
    this.setState({
      isOpenDeleteNotesModal: true,
      noteId,
    });
  };

  dismissDelNoteModal = () => {
    this.setState({
      isOpenDeleteNotesModal: false,
      noteId: 0,
    });
  };

  handleSubmitDelNote = () => {
    const { noteId } = this.state;
    this.props
      .deleteNotesData(noteId)
      .then((res) => {
        this.dismissDelNoteModal();
        this.handleApiRes(res);
      })
      .catch((error) => console.log('Error', error));
  };

  render() {
    const {
      notes,
      isNoteValid,
      successNotif,
      errorNotif,
      notifMsg,
      isOpenDeleteNotesModal,
      isOpenEditNoteModal,
      editNotes,
    } = this.state;
    const { candidateNotes } = this.props || {};

    return (
      <div>
        <Accordion>
          <AccordionItem
            className="csc-cand-notes-accr gba-accor-content mt-2"
            title={
              <div className="h6 font-weight-bold mt-1 bx--col-lg-4 bx--col-md-5 pl-0">
                Private Comments
              </div>
            }>
            {isNoteValid === true ? (
              <SuccErrNotification
                successNotif={false}
                errorNotif
                errMsg="Please enter valid notes."
                handleCloseNotifBtn={this.handleCloseNotifBtn}
              />
            ) : (
              <SuccErrNotification
                successNotif={successNotif}
                errorNotif={errorNotif}
                notifMsg={notifMsg}
                handleCloseNotifBtn={this.handleCloseNotifBtn}
              />
            )}

            <Tile>
              <div className="mb-2 font-weight-bold">Notes:</div>
              <Editor
                editorState={notes}
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    // 'image',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'link',
                  ],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
              <div className="mt-2 mb-2">
                <Button onClick={this.handleSubmitNotes}>Add Note</Button>
              </div>
              {candidateNotes &&
                Array.isArray(candidateNotes) &&
                candidateNotes.length > 0 && (
                  <GenericDataTable
                    otherActions={[]}
                    searchable={false}
                    onSearchInputChange={() => {}}
                    className="csc-cand-notes-td-bg"
                    rows={
                      candidateNotes &&
                      Array.isArray(candidateNotes) &&
                      candidateNotes.length > 0
                        ? candidateNotes.map((data, idx) => {
                            const { notes, createdAt, noteId, application } =
                              data || {};
                            const { profile } = application || {};
                            const { fullName = '' } = profile || {};
                            return {
                              isExpanded: false,
                              id: noteId,
                              header_0: (
                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                  <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                    Notes:
                                  </div>
                                  <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                    <div className="">
                                      {renderHtml({ __html: notes })}
                                    </div>
                                  </div>
                                </div>
                              ),
                              header_1: (
                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                  <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                    Candidate Name:
                                  </div>
                                  <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6 text-capitalize">
                                    <div>{fullName}</div>
                                  </div>
                                </div>
                              ),
                              header_2: (
                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                  <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                    Created At:
                                  </div>
                                  <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                    <div>
                                      {createdAt
                                        ? moment(createdAt).fromNow()
                                        : '-'}
                                    </div>
                                  </div>
                                </div>
                              ),
                              header_3: (
                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                  <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                    Action:
                                  </div>
                                  <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                                    <div className="d-flex">
                                      <div>
                                        <Button
                                          onClick={() => {
                                            this.activeEditNotes({
                                              notes,
                                              noteId,
                                            });
                                          }}
                                          kind="tertiary"
                                          size="small">
                                          Edit
                                        </Button>
                                      </div>
                                      <div className="ml-4">
                                        <Button
                                          onClick={() =>
                                            this.activeDeleteNotes(noteId)
                                          }
                                          kind="danger"
                                          size="small">
                                          Delete
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ),
                            };
                          })
                        : []
                    }
                    headers={[
                      {
                        key: 'header_0',
                        header: 'Notes',
                        className: 'w-50',
                      },
                      {
                        key: 'header_1',
                        header: 'Candidate Name',
                        className: 'w-15',
                      },
                      {
                        key: 'header_2',
                        header: 'Created At',
                        className: 'w-15',
                      },
                      {
                        key: 'header_3',
                        header: 'Action',
                      },
                    ]}
                  />
                )}
            </Tile>
          </AccordionItem>
        </Accordion>
        {isOpenEditNoteModal === true && (
          <Modal
            open={isOpenEditNoteModal}
            modalHeading="Edit Note"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissEditNoteModal}
            onRequestSubmit={this.handleSubmitEditNote}>
            <Editor
              editorState={editNotes}
              onEditorStateChange={this.onUpsertEditorStateChange}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'fontFamily',
                  // 'image',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </Modal>
        )}
        {isOpenDeleteNotesModal === true && (
          <Modal
            open={isOpenDeleteNotesModal}
            modalHeading="Delete Note"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            danger
            iconDescription="Close the modal"
            onRequestClose={this.dismissDelNoteModal}
            onRequestSubmit={this.handleSubmitDelNote}>
            Are you sure you want to delete this note?
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  candidateNotes: state.CandidateNotes.candidateNotes,
});

const mapDispatchToProps = {
  getCandidateNotesListByAppId,
  createNewNote,
  deleteNotesData,
  upsertNotesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateNotes);
