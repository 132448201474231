import React from 'react';

//Common packages
import {
  Tile,
  Checkbox,
  Accordion,
  AccordionItem,
  Button,
} from 'carbon-components-react';
import { lab } from 'd3';

const FilterFacets = (props) => {
  const filterData = [
    {
      mainLabel: 'CCA Tier',
      subItems: [
        {
          label: 'Leadership',
          key: 'leadership_ccatier',
          values: [
            {
              traitLabel: 1,
              traitKey: 1,
            },
            {
              traitLabel: 2,
              traitKey: 2,
            },
            {
              traitLabel: 3,
              traitKey: 3,
            },
            {
              traitLabel: 4,
              traitKey: 4,
            },
          ],
        },
        {
          label: 'Representation',
          key: 'representation_ccatier',
          values: [
            {
              traitLabel: 1,
              traitKey: 1,
            },
            {
              traitLabel: 2,
              traitKey: 2,
            },
            {
              traitLabel: 3,
              traitKey: 3,
            },
            {
              traitLabel: 4,
              traitKey: 4,
            },
          ],
        },
      ],
    },
    {
      mainLabel: 'Cognitive Ability Scores',
      subItems: [
        {
          label: 'Critical Thinking',
          key: 'pm_wg',
          values: [
            {
              traitLabel: 'Strongly Meets',
              traitKey: 'strongly_meets',
            },
            {
              traitLabel: 'Meets',
              traitKey: 'meets',
            },
            {
              traitLabel: 'Does not Meet',
              traitKey: 'does_not_meet',
            },
          ],
        },
        {
          label: 'Numerical Reasoning',
          key: 'pm_ranra',
          values: [
            {
              traitLabel: 'Strongly Meets',
              traitKey: 'strongly_meets',
            },
            {
              traitLabel: 'Meets',
              traitKey: 'meets',
            },
            {
              traitLabel: 'Does not Meet',
              traitKey: 'does_not_meet',
            },
          ],
        },
      ],
    },
    {
      mainLabel: 'GBA',
      subItems: [
        {
          label: 'Performance Based',
          key: 'pb_gba',
          values: [
            {
              traitLabel: 'High Fit',
              traitKey: 'high_fit',
            },
            {
              traitLabel: 'Moderate Fit',
              traitKey: 'moderate_fit',
            },
            {
              traitLabel: 'Poor Fit',
              traitKey: 'poor_fit',
            },
          ],
        },
        {
          label: 'Future Driven',
          key: 'fd_gba',
          values: [
            {
              traitLabel: 'High Fit',
              traitKey: 'high_fit',
            },
            {
              traitLabel: 'Moderate Fit',
              traitKey: 'moderate_fit',
            },
            {
              traitLabel: 'Poor Fit',
              traitKey: 'poor_fit',
            },
          ],
        },
        {
          label: 'Award',
          key: 'award_gba',
          values: [
            {
              traitLabel: 'High Fit',
              traitKey: 'high_fit',
            },
            {
              traitLabel: 'Moderate Fit',
              traitKey: 'moderate_fit',
            },
            {
              traitLabel: 'Poor Fit',
              traitKey: 'poor_fit',
            },
          ],
        },
        {
          label: 'Average',
          key: 'avg_gba',
          values: [
            {
              traitLabel: 'High Fit',
              traitKey: 'high_fit',
            },
            {
              traitLabel: 'Moderate Fit',
              traitKey: 'moderate_fit',
            },
            {
              traitLabel: 'Poor Fit',
              traitKey: 'poor_fit',
            },
          ],
        },
      ],
    },
    {
      mainLabel: 'Psychological Interview Rating',
      subItems: [
        {
          label: 'Leadership Qualities',
          key: 'pi_leadership_qualities',
          values: [
            {
              traitLabel: 'Excellent',
              traitKey: 'excellent',
            },
            {
              traitLabel: 'Strong',
              traitKey: 'strong',
            },
            {
              traitLabel: 'Moderate',
              traitKey: 'moderate',
            },
            {
              traitLabel: 'Weak',
              traitKey: 'weak',
            },
            {
              traitLabel: 'Very Weak',
              traitKey: 'very_weak',
            },
          ],
        },
        {
          label: 'Intellectual Qualities',
          key: 'pi_intellectual_qualities',
          values: [
            {
              traitLabel: 'Excellent',
              traitKey: 'excellent',
            },
            {
              traitLabel: 'Strong',
              traitKey: 'strong',
            },
            {
              traitLabel: 'Moderate',
              traitKey: 'moderate',
            },
            {
              traitLabel: 'Weak',
              traitKey: 'weak',
            },
            {
              traitLabel: 'Very Weak',
              traitKey: 'very_weak',
            },
          ],
        },
        {
          label: 'Resilience',
          key: 'pi_resilience',
          values: [
            {
              traitLabel: 'Excellent',
              traitKey: 'excellent',
            },
            {
              traitLabel: 'Strong',
              traitKey: 'strong',
            },
            {
              traitLabel: 'Moderate',
              traitKey: 'moderate',
            },
            {
              traitLabel: 'Weak',
              traitKey: 'weak',
            },
            {
              traitLabel: 'Very Weak',
              traitKey: 'very_weak',
            },
          ],
        },
        {
          label: 'Character and Values',
          key: 'pi_character_and_values',
          values: [
            {
              traitLabel: 'Excellent',
              traitKey: 'excellent',
            },
            {
              traitLabel: 'Strong',
              traitKey: 'strong',
            },
            {
              traitLabel: 'Moderate',
              traitKey: 'moderate',
            },
            {
              traitLabel: 'Weak',
              traitKey: 'weak',
            },
            {
              traitLabel: 'Very Weak',
              traitKey: 'very_weak',
            },
          ],
        },
        {
          label: 'Interpersonal Skills',
          key: 'pi_interpersonal_skills',
          values: [
            {
              traitLabel: 'Excellent',
              traitKey: 'excellent',
            },
            {
              traitLabel: 'Strong',
              traitKey: 'strong',
            },
            {
              traitLabel: 'Moderate',
              traitKey: 'moderate',
            },
            {
              traitLabel: 'Weak',
              traitKey: 'weak',
            },
            {
              traitLabel: 'Very Weak',
              traitKey: 'very_weak',
            },
          ],
        },
      ],
    },
  ];
  const { handleOnChangeCheckboxFilter, state, handleClearFilters } =
    props || {};
  return (
    <Tile>
      <div className="font-weight-bold mb-2">Filters</div>
      <hr />
      {filterData.map((filter) => {
        const { mainLabel, subItems } = filter || {};
        return (
          <div className="mt-3 mb-3">
            <div className="h6 f-w-b mt-1">{mainLabel}</div>

            <Accordion>
              {subItems.map((subFilter) => {
                const { label, key, values } = subFilter || {};
                return (
                  <AccordionItem
                    title={<h5 className="mt-1 text-dark">{label}</h5>}>
                    {values.map((val) => {
                      const { traitLabel, traitKey } = val || {};
                      return (
                        <Checkbox
                          id={`${key}-${traitKey}`}
                          key={`${key}-${traitKey}`}
                          name={`${key}-${traitKey}`}
                          labelText={
                            <div className="h6 font-weight-light">
                              {traitLabel}
                            </div>
                          }
                          onChange={(e) =>
                            handleOnChangeCheckboxFilter(e, traitKey, key)
                          }
                          checked={
                            state &&
                            state[key] &&
                            Array.isArray(state[key]) &&
                            state[key].length > 0
                              ? state[key].includes(traitKey)
                              : false
                          }
                        />
                      );
                    })}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        );
      })}
      <div className="mt-3 text-center">
        <Button onClick={handleClearFilters} size="small">
          Clear
        </Button>
      </div>
    </Tile>
  );
};

export default FilterFacets;
