import React, { useState } from 'react';
import GenericTabs from '../../components/common/GenericTabs';
import { Accordion, AccordionItem, Button } from 'carbon-components-react';
import NivoBarChart from '../../components/common/NivoBarChart';
import PSCandidateEvalMock from './PSCandidateEvalMock';
import NivoScatterGraph from '../../components/common/NivoScatterGraph';
import './MockUi2.css';

const PsacPeerAppraisalMock = (props) => {
  const { fullName } = props || {};
  const [selectedTab, handleSelectedTab] = useState(0);

  const handleTabClick = (tabType) => {
    handleSelectedTab(tabType);
  };

  return (
    <div>
      <GenericTabs
        className="xpa-psc-appr-w"
        selected={selectedTab}
        tabContent={[
          {
            label: 'Summary of Scores',
            value: '',
            onClick: () => {
              handleTabClick(0);
            },
          },
          {
            label: 'Assessment Centre Report',
            value: '',
            onClick: () => {
              handleTabClick(1);
            },
          },
          {
            label: 'Peer Appraisal Report',
            value: '',
            onClick: () => {
              handleTabClick(2);
            },
          },
          {
            label: 'Peer Appraisal Executive Summary',
            value: '',
            onClick: () => {
              handleTabClick(3);
            },
          },
          {
            label: 'Candidate Evaluation Form',
            value: '',
            onClick: () => {
              handleTabClick(4);
            },
          },
          {
            label: 'Selection Note Export',
            value: '',
            onClick: () => {
              handleTabClick(5);
            },
          },
        ]}
      />
      {selectedTab === 5 && (
        <div className="mt-3">
          <Button>Selection Note Export</Button>
        </div>
      )}
      {selectedTab === 4 && <PSCandidateEvalMock />}
      {selectedTab === 0 && (
        <div>
          <div className="scatter-plot-height mt-2">
            <NivoScatterGraph
              showLegend
              heading="Mean Scores for Psychological Interview, Assessment Centre and Peer Appraisal"
              data={[
                {
                  id: 'Psych Interview (PI)',
                  color: '#118DFF',
                  data: [
                    {
                      x: 'Aaron Ong',
                      y: 5.0,
                      color: '#118DFF',
                    },
                    {
                      x: 'Abby Ong',
                      y: 5.0,
                      color: '#118DFF',
                    },
                    {
                      x: 'Jennifer Lim',
                      y: 5.0,
                      color: '#118DFF',
                    },
                    {
                      x: 'Cayden Lim',
                      y: 5.0,
                      color: '#118DFF',
                    },
                    {
                      x: 'Alex Tan',
                      y: 5.0,
                      color: '#118DFF',
                    },
                  ],
                },
                {
                  id: 'Assessment Centre (AC)',
                  color: '#33ae81',
                  data: [
                    {
                      x: 'Aaron Ong',
                      y: 6.0,
                      color: '#33ae81',
                    },
                    {
                      x: 'Abby Ong',
                      y: 4.67,
                      color: '#33ae81',
                    },
                    {
                      x: 'Jennifer Lim',
                      y: 6.67,
                      color: '#33ae81',
                    },
                    {
                      x: 'Cayden Lim',
                      y: 3.17,
                      color: '#33ae81',
                    },
                    {
                      x: 'Alex Tan',
                      y: 5.67,
                      color: '#33ae81',
                    },
                  ],
                },
                {
                  id: 'Peer Appraisal (PA)',
                  color: '#f6b44a',
                  data: [
                    {
                      x: 'Aaron Ong',
                      y: 5.1,
                      color: '#f6b44a',
                    },
                    {
                      x: 'Abby Ong',
                      y: 5.1,
                      color: '#f6b44a',
                    },
                    {
                      x: 'Jennifer Lim',
                      y: 5.4,
                      color: '#f6b44a',
                    },
                    {
                      x: 'Cayden Lim',
                      y: 5.4,
                      color: '#f6b44a',
                    },
                    {
                      x: 'Alex Tan',
                      y: 5.4,
                      color: '#f6b44a',
                    },
                  ],
                },
                {
                  id: 'Average',
                  color: '#DC5B57',
                  data: [
                    {
                      x: 'Aaron Ong',
                      y: 16.1,
                      color: '#DC5B57',
                    },
                    {
                      x: 'Abby Ong',
                      y: 14.77,
                      color: '#DC5B57',
                    },
                    {
                      x: 'Jennifer Lim',
                      y: 17.07,
                      color: '#DC5B57',
                    },
                    {
                      x: 'Cayden Lim',
                      y: 18.97,
                      color: '#DC5B57',
                    },
                    {
                      x: 'Alex Tan',
                      y: 16.07,
                      color: '#DC5B57',
                    },
                  ],
                },
              ]}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 46,
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Mean Score',
                legendPosition: 'middle',
                legendOffset: -60,
              }}
              axisTop={null}
              axisRight={null}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 0, max: 20 }}
              nodeSize={12}
              margin={{ top: 30, right: 60, bottom: 80, left: 80 }}
            />
          </div>
          <hr />
          <div class="mt-3">
            <h4 class="mt-2 mb-2">Psychological interview</h4>
            <div class="mt-2 mb-3">
              (Ranked by Mean Psychological Interview Score)
            </div>
            <table class="css-table-batch-info w-100 css-statistics-zoom-auto">
              <thead class="css-total-bg" style={{ verticalAlign: 'middle' }}>
                <tr>
                  <th
                    class="css-batch-metric-border-r css-metric-table-head"
                    rowspan="2"
                    style={{ width: 10, verticalAlign: 'middle' }}>
                    Rank
                  </th>
                  <th
                    class="css-batch-metric-border-r css-metric-table-head text-left pl-2"
                    rowspan="2"
                    style={{ width: 400, verticaAlign: 'middle' }}>
                    Name
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Leadership
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Intellectual
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Character &amp; Values
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Inter-personal
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Resilience
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Total
                  </th>
                  <th
                    class="css-metric-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Mean
                  </th>
                </tr>
              </thead>
              <tbody class="css-table-batch-info text-center">
                <tr
                  class="text-center vertical-align-middle"
                  style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    1
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div class="">Aaron Ong</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    25
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.0</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    2
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Abby Ong</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    25
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.0</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    3
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Jennifer Lim</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    25
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.0</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    4
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Cayden Lim</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    25
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.0</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Alex Tan</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    25
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.0</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mb-2 mt-3 text-justify" style={{ lineHeight: 1.3 }}>
              <span class="f-w-b">Banding Scale</span> - 5 (Excellent), 4
              (Strong), 3 (Moderate), 2(Weak), 1 (Very Weak).
            </div>
            <div class="css-default-text-color f-w-b mb-2 mt-2">
              Ratings of 2 and below are shaded.
            </div>
          </div>
          <hr />
          <div class="">
            <h4 class="mt-2 mb-2">Assessment Centre</h4>
            <div class="mt-2 mb-3">(Ranked by Mean AC Score)</div>
            <table class="css-table-batch-info w-100 css-statistics-zoom-auto">
              <thead class="css-total-bg" style={{ verticalAlign: 'middle' }}>
                <tr>
                  <th
                    class="css-batch-metric-border-r css-metric-table-head"
                    rowspan="2"
                    style={{ width: 10, verticalAlign: 'middle' }}>
                    Rank
                  </th>
                  <th
                    class="css-batch-metric-border-r css-metric-table-head text-left pl-2"
                    rowspan="2"
                    style={{ width: 400, verticaAlign: 'middle' }}>
                    Name
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Leadership
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Intellectual
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Character &amp; Values
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    People
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Personal Effectiveness
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Comms
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Total
                  </th>
                  <th
                    class="css-metric-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    Mean
                  </th>
                </tr>
              </thead>
              <tbody class="css-table-batch-info text-center">
                <tr
                  class="text-center vertical-align-middle"
                  style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    1
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div class="">Aaron Ong</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    6
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    6
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    6
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    6
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    36
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>6.00</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    2
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Abby Ong</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    4
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    3
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    6
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    28
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>4.67</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    3
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Jennifer Lim</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    6
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    4
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    3
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    28
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>4.67</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    4
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Cayden Lim</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    2
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    3
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    4
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    3
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    4
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    3
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    19
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>3.17</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Alex Tan</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    6
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                    }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    6
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    34
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.67</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mb-2 mt-3 text-justify" style={{ lineHeight: 1.3 }}>
              <span class="f-w-b">Banding Scale</span> - 7 (Very Strong), 6
              (Strong), 5 (More than Satisfactory), 4 (Satisfactory), 3 (Less
              than Satisfactory), 2 (Weak), 1 (Very Weak)
            </div>
            <div class="css-default-text-color f-w-b mb-2 mt-2">
              Ratings of 3 and below are shaded.
            </div>
          </div>
          <hr className="mt-3" />
          <div class="mt-1">
            <h4 class="mt-2 mb-2">Peer Ratings</h4>
            <div class="mt-2 mb-3">(Ranked by Mean Ratings, Max = 6.0)</div>
            <table class="css-table-batch-info w-100 css-statistics-zoom-auto">
              <thead class="css-total-bg" style={{ verticalAlign: 'middle' }}>
                <tr>
                  <th
                    class="css-batch-metric-border-r css-metric-table-head"
                    rowspan="2"
                    style={{ width: 10, verticalAlign: 'middle' }}>
                    Rank
                  </th>
                  <th
                    class="css-batch-metric-border-r css-metric-table-head text-left pl-2"
                    rowspan="2"
                    style={{ width: 400, verticaAlign: 'middle' }}>
                    Name
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    <div>
                      <div>Leadership</div>
                      <div class="mt-1">(4.5)</div>
                    </div>
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    <div>
                      <div>Intellectual</div>
                      <div class="mt-1">(4.9)</div>
                    </div>
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    <div>
                      <div>Interpersonal</div>
                      <div class="mt-1">(4.8)</div>
                    </div>
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    <div>
                      <div>Character &amp; Values</div>
                      <div class="mt-1">(4.9)</div>
                    </div>
                  </th>
                  <th
                    class="css-batch-table-execercise-w css-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    <div>
                      <div>Stress Tolerance &amp; Coping Ability</div>
                      <div class="mt-1">(4.9)</div>
                    </div>
                  </th>

                  <th
                    class="css-metric-table-head"
                    style={{
                      width: 100,
                      verticalAlign: 'middle',
                      minWidth: 100,
                      maxWidth: 100,
                    }}>
                    <div>
                      <div>Mean</div>
                      <div class="mt-1">(4.8)</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="css-table-batch-info text-center">
                <tr
                  class="text-center vertical-align-middle"
                  style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    1
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div class="">Aaron Ong</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.0
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    4.7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.2
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.0
                  </td>

                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.1</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    2
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Abby Ong</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.2
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.0
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.3
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.0
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    4.8
                  </td>

                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.1</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    3
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Jennifer Lim</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.0
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    5.5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.3
                  </td>

                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.4</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    4
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Cayden Lim</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.0
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    5.5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.3
                  </td>

                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.4</div>
                  </td>
                </tr>
                <tr class="" style={{ height: 40 }}>
                  <td
                    class="css-batch-metric-border css-batch-metric-border-r"
                    style={{ verticalAlign: 'middle' }}>
                    5
                  </td>
                  <td
                    class="css-batch-metric-border css-total-f-w pl-2 text-left"
                    style={{ verticalAlign: 'middle' }}>
                    <div>Alex Tan</div>
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.0
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.7
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#c4c6ca',
                    }}>
                    5.5
                  </td>
                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    5.3
                  </td>

                  <td
                    class="css-total-f-w css-batch-metric-border"
                    style={{ verticalAlign: 'middle' }}>
                    <div>5.4</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="css-default-text-color f-w-b mb-2 mt-3 text-justify"
              style={{ lineHeight: 1.3 }}>
              Refers to cohort mean. Ratings below cohort mean are shaded.
            </div>
          </div>
        </div>
      )}
      {selectedTab === 1 && (
        <div className="mr-2">
          {' '}
          <div class="mt-3 mb-3 h6 font-weight-bold">Summary</div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Assessment Summary
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify mt-2">
                {fullName}'s key strengths are in his intellectual capabilities.
                He is mature and sensible in his analysis and is able to view
                issues from different perspectives. This allows him to quickly
                derive robust and innovative solutions to complex issues posed
                to him. However, he has occasional lapses in missing out
                important details. As a result, the quality of his analysis may
                at times be inconsistent and greatly dependent on the quality of
                the information that he was given. That said, he is mentally
                nimble enough to refine his views and recommendations with
                further discourse and new information given to him.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                His strong communication skills further complement his
                intellect, as he is able to break down the issues and explain
                them clearly with his strong command of English. Whether in
                verbal or written form, his words are chosen carefully, and he
                is concise in conveying his thoughts and ideas.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                His potential for exercising stronger leadership is however
                limited by his comparative weakness in people skills, as he is
                not naturally warm and engaging towards others. While he is
                generally courteous and shows engagement in listening to the
                concerns of others, his cool and calm demeanour sometimes
                creates a sense of distance and it is hard for others to connect
                with him on a personal or emotional level. This, together with
                his tendency to miss opportunities to help others see how their
                own objectives align with that of the organisation, affects his
                ability to persuade them beyond his logical arguments and
                inspire them to achieve broader goals.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} exudes a sense of steady confidence and remains
                composed under pressure. However, there are times when he could
                have pushed himself (and others) a bit more in seeking to
                achieve more ambitious and better outcomes. His drive for
                excellence appears to be inconsistent across the various
                exercises, and it seems that he is more motivated when he finds
                an intellectual challenge to the task. Where the scenario
                appears simple in terms of policy implications, he has a
                tendency to under-estimate the task and misses the opportunity
                to exercise and display his abilities in the other areas such as
                managing inter-personal relationships or building teams.
              </div>
            </AccordionItem>
          </Accordion>
          <div class="mt-3 mb-3 h6 font-weight-bold">
            PSAC Competencies Ratings
          </div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3 text-uppercase">
                    Leadership Qualities
                  </div>
                  <div className="d-flex justify-content-center mb-1">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          res === 4
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">Satisfactory (4)</div>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} demonstrated satisfactory leadership qualities. He
                was confident in communicating his views and was able to
                influence others to his point of view by framing his ideas in a
                compelling manner. In the Multi-Agency Exercise (MAE), he
                contributed views that helped move the group forward and
                positively influenced the outcome of the discussion. For
                example, he convinced each member to consider the merit of the
                others’ proposals in relation to their own key demographics,
                which helped break an impasse and elevated discussions to a
                level for consensus building.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                However, he was not particularly strong in empowering his
                members and motivating them towards broader team objectives. In
                the Meeting with a Team Member (MTM) exercise, he acknowledged
                the team member’s concerns but chose to defer taking decisive
                actions. He also did not articulate, let alone advocate the
                organisation’s mandate, and help the team member to see how it
                was relevant to him. As a result of these, he missed several
                opportunities to motivate and empower the team member to achieve
                even more for the community through the organisation.
              </div>
              <hr className="mt-2 mb-2" />
            </AccordionItem>

            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3 text-uppercase">
                    Intellectual Qualities
                  </div>
                  <div className="d-flex justify-content-center mb-1">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          res === 5
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">More Than Satisfactory (5)</div>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} demonstrated relatively strong intellectual
                qualities, but his performance seemed inconsistent across the
                various exercises in the assessment centre. Where there were
                complex policy issues, he was able to analyse a large amount of
                information and make creative and sensible recommendations based
                on existing or new information. However, when issues were
                relatively straightforward, he skimmed through information and
                overlooked important details, which reflected occasional lapses
                in judgment when processing the information and affected his
                ability to come up with effective solutions.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                For instance, he did very well in the Policy Presentation
                Exercise (PPE), as he was able to digest a wide range of
                materials to fully grasp and break down the issue. He proposed a
                sophisticated solution that would draw on relevant precedent
                policies and his recommendations reflected his maturity and
                ability to view the issue from multiple angles, taking into
                consideration larger national interests as well as citizen
                perceptions. When faced with difficult questions, he was also
                able to respond quickly and make appropriate adjustments to his
                recommendations with good judgment, thus reflecting his
                cognitive flexibility in adjusting his views to changing
                circumstances.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                However, {fullName} made missteps on relatively simple issues in
                the Meeting with a Citizen (MWC) and MTM exercises. For example,
                he was unclear about the fees or fines in the MWC exercise that
                he could waive for the citizen. In the MTM, he also missed out
                on obvious cues for probing questions that would allow him to
                find out more about the situation. As a result, his suggested
                course of action in the MTM was simplistic and unlikely to be
                feasible.
              </div>
              <hr className="mt-2 mb-2" />
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3 text-uppercase">
                    Character and Values
                  </div>
                  <div className="d-flex justify-content-center mb-1">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          res === 4
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">Satisfactory (4)</div>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} demonstrated consistency in his words and actions and
                strived for fairness. For instance, he did not over-promise on
                what was not within his control in the MWC and maintained
                impartiality with regard to the opposing views between his team
                members in the MTM.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} showed some concern for and articulated a clear
                understanding of national interests. In the PPE, he articulated
                national values and was extremely passionate about the need to
                resolve inequalities in the education system. In the MWC
                exercise, he defended the government against certain allegations
                by the citizen, for example, that the organisation profited from
                the fees charged. However, he may miss opportunities to align
                objectives with broader national interests, such as in the MAE
                discussion, where he was not particularly active in helping the
                team to find cross-agency synergies in serving Singapore and
                Singaporeans after his own agency’s proposal was accepted by
                them.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} was not consistent in showing drive and motivation
                for excellence through the exercises, especially when
                interpersonal as opposed to intellectual challenges were
                involved. In the PPE, he saw the need to address the issue
                urgently and was able to articulate the importance for
                stakeholders to take action, but in the MAE, when it was clear
                that his proposal was already well received by the others, he
                was relatively passive in the subsequent discourse and did not
                challenge his team members on their proposals. In the MTM
                exercise, he avoided raising difficult issues or deferred them
                to a later conversation. Such behaviours might point to a lack
                of commitment to achieving the best possible overall outcome in
                every exercise.
              </div>
              <hr className="mt-2 mb-2" />
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3 text-uppercase">
                    People Skills
                  </div>
                  <div className="d-flex justify-content-center mb-1">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          res === 1
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">Very Weak (1)</div>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} was relatively quiet and did not have a particularly
                outgoing or warm personality. In a group setting such as the
                MAE, he was courteous towards others and in his interactions
                with the others, demonstrated that he listened and was
                considerate of others’ views. He was also tactful when raising
                suggestions to the group or when responding to queries from the
                others. However, given his quiet nature, he was not particularly
                proactive in engaging others in the group on their proposals or
                in encouraging team cohesiveness.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                Across all exercises, he tended to not show much emotion on his
                face, whether positive or negative. This made it easier for him
                to calm an agitated citizen down, but harder for others (e.g.,
                peers or team members) to relate to him. It also reflected
                rigidity in the way that he behaved and expressed himself, as he
                remained unmoved even in situations that warranted a more
                emotional or empathetic reaction.
              </div>
              <hr className="mt-2 mb-2" />
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3 text-uppercase">
                    Communication Skills
                  </div>
                  <div className="d-flex justify-content-center mb-1">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          res === 6
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">Strong (6)</div>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} had strong communication skills. He spoke clearly and
                succinctly with appropriate tone and volume in the various
                exercises. He was articulate and able to convey his ideas in a
                myriad of ways that facilitated understanding. His writing was
                well-structured, clear in meaning and free of grammatical
                errors. While he had a strong command of the English language,
                he could do better in tailoring the language and words used to
                the situation. For example, in the MWC exercise, he used complex
                words and phrases such as “mitigating factors” and “ignorance of
                (the) law” when speaking to the citizen, which was not
                appropriate and could confuse and irritate the citizen.
              </div>
              <hr className="mt-2 mb-2" />
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3 text-uppercase">
                    Personal Effectiveness
                  </div>
                  <div className="d-flex justify-content-center mb-1">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          res === 5
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">More Than Satisfactory (5)</div>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                {fullName} was generally able to maintain composure and remain
                engaged and focused through the various exercises. In the MWC
                exercise, for example, he was calm and appeared to be at ease
                even when faced with provocative remarks from the citizen. He
                focused firmly on the key issues and did not seem personally
                affected by the citizen’s negative emotions. When his ideas were
                challenged in the PPE, he showed enthusiasm for further
                refinement to his recommendation and did not appear defensive or
                nervous.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                That said, {fullName} might not have felt personally vested or
                challenged enough in most of the exercises to have warranted any
                major emotional response. For example, he did not face much
                resistance in the MAE exercise as his proposal was quickly
                accepted by the group and he did not exert further effort to
                strive for a more ambitious outcome. Within the realm of his
                conservatively defined goals, he was effective and relatively
                comfortable.
              </div>
              <hr className="mt-2 mb-2" />
            </AccordionItem>
          </Accordion>
          <div class="mt-3 mb-3 h6 font-weight-bold">
            PSAC Competencies Reference
          </div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Leadership Qualities
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The drive and ability to set direction and influence the course
                of events by persuading, inspiring, involving, and developing
                people.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Directing and Motivating Others:</span>{' '}
                <span>
                  Effectively communicates strategy and translates it into
                  action; Communicates clear direction, expectations, structure,
                  and feedback to the team; Motivates and inspires team members
                  by recognising, involving, consulting, and empowering team
                  members.
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Impact and Influence:</span>{' '}
                <span>
                  Asserts/defends position the face of opposition; Presents
                  views with confidence, enthusiasm and conviction; Frames ideas
                  and opinions in compelling and persuasive ways that influence
                  the thinking of others; Captures people’s attention; Has an
                  impact on people and events.
                </span>
              </div>
            </AccordionItem>

            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Intellectual Qualities
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The ability to accurately analyse complex information, to make
                prompt and effective decisions, to develop innovative solutions,
                and to change approaches when necessary.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Analysis:</span>{' '}
                <span>
                  Understands complex information; Identifies trends, patterns
                  and linkages; Separates the critical from the peripheral
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">
                  Judgement, Strategic Perspective and Innovativeness:
                </span>{' '}
                <span>
                  Develops solutions that effectively address issues over the
                  long-term and which are creative and efficient in the use of
                  resources; Considers broad and long-term implications and
                  consequences (including impacts on multiple stakeholders);
                  Considers multiple alternatives; Plans for contingencies
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Cognitive Flexibility:</span>{' '}
                <span>
                  Able to deal with ambiguity and incomplete information; Able
                  to quickly adjust views and inferences as new information
                  becomes available.
                </span>
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Character and Values
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                Demonstrating and being a model of integrity, commitment, and
                conscientiousness.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Civic Mindedness:</span>{' '}
                <span>
                  Selflessness; Committed to serving the organisation and nation
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Drive (Action Orientation):</span>{' '}
                <span>
                  Acts decisively and deliberately without wavering; Applies
                  full effort to tasks; Takes initiative and shows willingness
                  to take appropriate risks and act independently
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Integrity:</span>{' '}
                <span>
                  Acts in a fair, ethical, and honest manner; consistency of
                  words and actions
                </span>
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  People Skills
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The ability and drive to interact and work effectively with
                people from diverse backgrounds and in a variety of situations.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Interpersonal Skills:</span>{' '}
                <span>
                  Puts others at ease; Interacts smoothly and comfortably with
                  others; Builds relationships and networks; Manages
                  disagreement and potential conflict with others
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Sensitivity and Empathy:</span>{' '}
                <span>
                  Understands and respects the needs, priorities, and views of
                  others; Deals with others in a tactful and supportive manner.
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Teamwork:</span>{' '}
                <span>
                  Cooperates with and supports the team; Builds and encourages
                  team cohesiveness; Ensures involvement of all team members;
                  Confronts, mediates and resolves conflict among others
                  (identifies common ground, etc.)
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Behavioural Flexibility:</span>{' '}
                <span>
                  Adjust behaviour appropriately to the situation and people
                  present
                </span>
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Communication Skills
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The ability to convey information effectively and respond
                meaningfully to others.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Written Communication:</span>{' '}
                <span>
                  Expresses self clearly, accurately, and concisely in written
                  form.
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Verbal Communication:</span>{' '}
                <span>
                  Expresses self clearly, accurately, and concisely in speech.
                </span>
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Personal Effectiveness
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The ability to perform and maintain effectiveness in the face of
                pressure, challenges, and setbacks.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Composure:</span>{' '}
                <span>
                  Shows no signs of stress or nervousness; Appropriately
                  expresses and manages emotion; presents a positive and
                  enthusiastic outlook in difficult circumstances; maintains
                  composure under stress and avoids emotional outbursts (without
                  being emotionally ‘flat’)
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Resilience:</span>{' '}
                <span>
                  Maintains motivation and performance in the face of challenges
                  and setbacks
                </span>
              </div>
            </AccordionItem>
          </Accordion>
          <div class="mt-3 mb-3 h6 font-weight-bold">
            Assessment Center Exercises
          </div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Exercises
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <div className="f-w-b mt-2 mb-2">Multi-Agency Exercise:</div>{' '}
                <div>
                  In this exercise, the candidates meet as a group of peers (4
                  to 6) to arrive at a consensus on an issue. The scenario is
                  that each of them represents a different agency that is vying
                  for a dividend fund offered by the government for a use that
                  will benefit the public and nation. They receive information
                  about the objectives and proposed projects of the agency they
                  have been assigned to represent. The candidates have to find a
                  balance between asserting the interests of the group they
                  represent and reaching a consensus for the broader good. In
                  addition, an unexpected request for the fund is received from
                  another organisation near the end of discussion, the intent
                  being to test the candidates’ flexibility and coping skills.
                  This exercise provides evidence for elements of all of the
                  competencies.
                </div>
                <hr className="mt-2 mb-2" />
                <div className="f-w-b mt-2 mb-2">
                  Policy Presentation Exercise:
                </div>{' '}
                <div>
                  In this exercise, the candidate individually analyses
                  information concerning a complex issue affecting Singapore and
                  offers recommendations as to how it should be addressed. The
                  issue confronting the candidate is fictitious but realistic,
                  involving demands from certain constituencies that poorer
                  Singaporeans be given preferential treatment in university
                  admissions and the award of government scholarships.
                  Information concerning this issue is presented to the
                  candidate through a series of simulated documents: newspaper
                  articles, op-ed pieces, online forums, encyclopaedia entries,
                  statutes, etc. The candidate reviews these materials, makes a
                  formal presentation to an assessor on their analysis and
                  policy recommendations, and then is questioned by the assessor
                  to clarify their understanding, assumptions and intent.
                </div>
                <hr className="mt-2 mb-2" />
                <div className="f-w-b mt-2 mb-2">
                  Meeting with a Citizen:
                </div>{' '}
                <div>
                  In this exercise, the candidate meets individually with an
                  actor who is in the role of an irate citizen. The scenario is
                  that the citizen was found to have an out-of-date driver’s
                  license and had his vehicle seized pending payment of a fine.
                  He came to the candidate’s office to lodge a complaint about
                  the penalty imposed and was met with poor service by the
                  candidate’s subordinate. The candidate’s task is to meet with
                  the citizen and handle his queries, complaints and
                  dissatisfaction in an effective manner. This exercise provides
                  evidence for intellectual qualities, character and values,
                  people skills, communication skills, and personal
                  effectiveness.
                </div>
                <hr className="mt-2 mb-2" />
                <div className="f-w-b mt-2 mb-2">
                  Meeting with a Team Member:
                </div>{' '}
                <div>
                  In this exercise, the candidate is placed in the role of
                  leader of a fictitious community group and has to meet with a
                  team member who has performance issues and concerns about the
                  organisation. The candidate, as leader, meets with him to
                  explore how the team member’s talents can be put to best use
                  within the organisation and identify any actions that need to
                  be taken with respect to the team member and/or the
                  organisation. In this case, the candidate must show judgement,
                  empathy, and commitment in developing an understanding of the
                  situation, managing the interaction, and arriving at a
                  solution that is fair to multiple stakeholders. This exercise
                  provides evidence for elements of all of the competencies.
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      )}
      {selectedTab === 2 && (
        <div className="mr-2">
          <div class="mt-3 mb-2 h6 text-uppercase font-weight-bold">
            Ranking
          </div>
          <div className="mb-2">
            Peer Ranking: <span style={{ fontWeight: 'bold' }}>7</span>
          </div>
          <div className="">
            No. of Nominations Received From Participants Outside of Group:{' '}
            <span style={{ fontWeight: 'bold' }}>0</span>
          </div>
          <div class="mt-3 mb-3 h6 text-uppercase font-weight-bold">
            Ratings
          </div>
          <div
            style={{ border: '1px solid black' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div className="font-weight-bold mb-2">
                  Overall Mean Ratings
                </div>
                <div className="mb-2">Averaged across all 5 qualities</div>
                <div>(maximum rating = 6.0)</div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: 3.8,
                  },
                  {
                    key: 'Peer',
                    Peer: 3.7,
                  },
                  {
                    key: 'Cohort',
                    Cohort: 4.8,
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort', 'Processing Speed']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div class="mt-3 mb-3 h6 text-uppercase font-weight-bold">
            Mean Ratings of Individual Qualities (maximum rating = 6.0)
          </div>
          <div
            style={{ border: '1px solid black', borderBottom: '0px' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">Leadership</div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach in which you take charge of
                  situations, provide directions, implement plans, and motivate
                  others.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: 3.0,
                  },
                  {
                    key: 'Peer',
                    Peer: 3.3,
                  },
                  {
                    key: 'Cohort',
                    Cohort: 3.6,
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort', 'Processing Speed']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div
            style={{ border: '1px solid black', borderBottom: '0px' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">
                  Intellectual Qualities
                </div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach in which you learn new skills and
                  analyse problems to aid decision making and develop solutions.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: 4.0,
                  },
                  {
                    key: 'Peer',
                    Peer: 3.8,
                  },
                  {
                    key: 'Cohort',
                    Cohort: 4.9,
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort', 'Processing Speed']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div
            style={{ border: '1px solid black', borderBottom: '0px' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">Interpersonal Skills</div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach in which you communicate, build
                  relationships, and collaborate with others.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: 4.0,
                  },
                  {
                    key: 'Peer',
                    Peer: 3.2,
                  },
                  {
                    key: 'Cohort',
                    Cohort: 4.7,
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort', 'Processing Speed']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div
            style={{ border: '1px solid black', borderBottom: '0px' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">Character & Values</div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach which you adopt towards achieving
                  goals and producing quality outcomes.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: 4.0,
                  },
                  {
                    key: 'Peer',
                    Peer: 4.0,
                  },
                  {
                    key: 'Cohort',
                    Cohort: 5.0,
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort', 'Processing Speed']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div
            style={{ border: '1px solid black' }}
            className="bx--row align-items-center ml-0 mr-0">
            <div
              style={{ borderRight: '1px solid black', height: 150 }}
              className="bx--col-lg-6 d-flex justify-content-center align-items-center">
              <div>
                <div class="mb-2 h6 font-weight-bold">
                  Stress Tolerance & Coping Ability
                </div>
                <div
                  style={{ lineHeight: 1.2, fontSize: 'larger' }}
                  className="mb-2">
                  The manner and approach in which you deal with challenges and
                  perform under difficult situations.
                </div>
              </div>
            </div>
            <div className="bx--col-lg-6" style={{ height: 150 }}>
              <NivoBarChart
                heading=""
                data={[
                  {
                    key: 'Self',
                    Self: 4.0,
                  },
                  {
                    key: 'Peer',
                    Peer: 4.0,
                  },
                  {
                    key: 'Cohort',
                    Cohort: 4.9,
                  },
                ].reverse()}
                margin={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                keys={['Self', 'Peer', 'Cohort', 'Processing Speed']}
                legendDirection="bottom-left"
                enableGridY={false}
                axisTop={null}
                padding={0.2}
                maxValue={7}
                enableLabel
                groupMode="stacked"
                innerPadding={0}
                showLegend={false}
                axisRight={null}
                axisBottom={null}
                layout="horizontal"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -40,
                }}
              />
            </div>
          </div>
          <div className="mt-3 mb-3 h6 font-weight-bold text-uppercase">
            additional comments
          </div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Click To View
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="mt-2 f-w-b mb-2">
                The comments offered by peers are as follows:
              </div>

              <div style={{ lineHeight: 1.2, fontSize: 'larger' }}>
                {fullName} be a quiet and soft-spoken individual but he has
                proven to be proactive and helpful, especially during OBS
                activities like tent-pitching and cooking. She definitely has
                her ideas and perceptions and can thus afford to air her views
                more actively and frequently. As one gets to better know her, he
                is definitely trustworthy and supportive and can be a good
                motivation for the team.
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      )}
      {selectedTab === 3 && (
        <div>
          <div className="h6 font-weight-bold mt-3 mb-3">
            Peer Rankings and Ratings of All Participants
          </div>
          <div className="mr-2">
            <table className="table-border w-100 ">
              <thead>
                <th className="xpa-bg-color--gray" style={{ width: 250 }}></th>
                <th className="xpa-bg-color--gray"></th>
                <th className="xpa-bg-color--gray"></th>
                <th
                  className="xpa-bg-color--gray"
                  style={{ borderBottom: '1px solid black' }}
                  colSpan={6}>
                  MEAN PEER RATINGS ON 5 QUALITIES (MAX = 6.0)
                </th>
              </thead>
              <thead>
                <th className="xpa-bg-color--gray xpa-psac-title-fz">
                  Candidate Name
                </th>
                <th
                  className="xpa-bg-color--gray"
                  style={{ width: 50 }}
                  rowSpan={2}>
                  <div className="xpa-psac-title-fz">PEER RANKING</div>
                  <span style={{ fontSize: 14 }}>
                    (By peers in the same group)
                  </span>
                </th>
                <th
                  className="xpa-bg-color--gray"
                  style={{ width: 50 }}
                  rowSpan={2}>
                  <div className="xpa-psac-title-fz">OVERALL MEAN RATING</div>
                  <span style={{ fontSize: 14 }}>(Cohort = 4.8)</span>
                </th>
                <th
                  className="xpa-bg-color--gray"
                  style={{ width: 110, maxWidth: 110 }}>
                  <div className="xpa-psac-title-fz">LEADERSHIP</div>{' '}
                  <div style={{ fontSize: 14 }}>(Cohort = 4.6)</div>
                </th>
                <th
                  className="xpa-bg-color--gray"
                  style={{
                    width: 110,
                    maxWidth: 110,
                    overflowWrap: 'anywhere',
                  }}>
                  <div className="xpa-psac-title-fz">
                    INTELLECTUAL QUALITIES
                  </div>{' '}
                  <div style={{ fontSize: 14 }}>(Cohort = 4.9)</div>
                </th>
                <th
                  className="xpa-bg-color--gray"
                  style={{
                    width: 110,
                    maxWidth: 110,
                    overflowWrap: 'anywhere',
                  }}>
                  <div className="xpa-psac-title-fz">INTERPERSONAL SKILLS</div>{' '}
                  <div style={{ fontSize: 14 }}>(Cohort = 4.7)</div>
                </th>
                <th
                  className="xpa-bg-color--gray"
                  style={{ width: 110, maxWidth: 110 }}>
                  <div className="xpa-psac-title-fz">CHARACTER & VALUES</div>{' '}
                  <div style={{ fontSize: 14 }}>(Cohort = 5.0)</div>
                </th>
                <th
                  className="xpa-bg-color--gray"
                  style={{ width: 110, maxWidth: 110 }}>
                  <div className="xpa-psac-title-fz">
                    STRESS TOLERANCE & COPING ABILITY
                  </div>{' '}
                  <div style={{ fontSize: 14 }}>(Cohort = 4.9)</div>
                </th>
              </thead>
              <tbody className="table-border">
                <tr style={{ backgroundColor: '#deebf7' }}>
                  <td className="text-left p-3">
                    1. <span className="f-w-b">{fullName}</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr style={{ backgroundColor: 'blanchedalmond' }}>
                  <td className="text-left p-3">
                    2. <span className="f-w-b">Ivan Lee #</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr style={{ backgroundColor: 'blanchedalmond' }}>
                  <td className="text-left p-3">
                    3. <span className="f-w-b">Jennifer Lim #</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr style={{ backgroundColor: 'blanchedalmond' }}>
                  <td className="text-left p-3">
                    4. <span className="f-w-b">Cayden Lim #</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr style={{ backgroundColor: 'blanchedalmond' }}>
                  <td className="text-left p-3">
                    5. <span className="f-w-b">Aaron Ong #</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr>
                  <td className="text-left p-3">
                    6. <span className="f-w-b">Janet Lee</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr>
                  <td className="text-left p-3">
                    7. <span className="f-w-b">Cindy Ong</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr>
                  <td className="text-left p-3">
                    8. <span className="f-w-b">Terrence Lee</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr>
                  <td className="text-left p-3">
                    9. <span className="f-w-b">Darren Lee</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
                <tr>
                  <td className="text-left p-3">
                    10. <span className="f-w-b">Yan Qian Ping</span>
                  </td>
                  <td>1</td>
                  <td>5.7</td>
                  <td>5.8</td>
                  <td>5.7</td>
                  <td>5.3</td>
                  <td>5.8</td>
                  <td>5.8</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PsacPeerAppraisalMock;
