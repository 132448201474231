import callApi from './callApi';
//Common urls for api calls
import { baseUrl, regularUrl } from '../utils/config';

//Action types for all api calls
import {
  REQUEST_X0PA_DUSER,
  REQUEST_USER_LOGIN,
  REQUEST_AUTH_STATE,
  REQUEST_VERIFY_EMAIL_DATA,
} from './actionTypes';

const fetchUserLoginState = (userId, companyId) => (dispatch) => {
  let url = `${baseUrl}/userinfo-base/profileInformation?_=${new Date().getTime()}&ad=y&qr=y&`;
  if (userId && companyId) {
    url += `xuid=${userId}&xcid=${companyId}`;
  }

  const params = {
    dispatch,
    actionType: REQUEST_X0PA_DUSER,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getAuthState = () => (dispatch) => {
  const url = `${baseUrl}/userinfos/a/i?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_AUTH_STATE,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const userLogin = (username, password, otpValue, sessionId) => (dispatch) => {
  if (!password || !username || !otpValue) {
    return Promise.reject(new Error('Login failed.'));
  }
  const formattedUsername = (username && username.toLowerCase().trim()) || 'x';
  const formattedPassword = (password && password.trim()) || 'x';
  const formattedOtpVal = (otpValue && otpValue.trim()) || 0;
  const data = {
    username: formattedUsername,
    password: formattedPassword,
    otpValue: formattedOtpVal,
    sessionId,
  };
  const url = `${regularUrl}/login?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_USER_LOGIN,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const verifyEmailData = (data) => (dispatch) => {
  const { email } = data || {};
  if (!email) {
    return Promise.reject(new Error("Invalid email / Email Doesn't exists."));
  }
  const url = `${regularUrl}/emailVerify?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_VERIFY_EMAIL_DATA,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

export { fetchUserLoginState, getAuthState, userLogin, verifyEmailData };
