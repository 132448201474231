import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//Api Actions
import {
  createCandidateEvalDetails,
  upsertCandidateEvaluationDetails,
  getCandidateEvaluationDetails,
} from '../../actions/CandidateInfo';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import {
  RadioButton,
  TextArea,
  Button,
  InlineNotification,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';
import '../private/CandidateInfo.css';
import './MockUi2.css';

const evalStaticData = [
  {
    evalName: 'Leadership Qualities',
    evalDesc:
      'Demonstrates leadership qualities and accomplishments as a leader.',
    evalId: 1,
    evalPropName: 'commitment',
  },
  {
    evalName: 'Intellectual Qualities',
    evalDesc:
      'Displays good general knowledge (world events, issues and related matters); analyse issues objectively from multiple perspectives and form sound judgement.',
    evalId: 2,
    evalPropName: 'knowledge',
  },
  {
    evalName: 'Character and Values ',
    evalDesc:
      'Exemplifies sound character and values aligned with Public Service ethos (integrity, service, excellence).',
    evalId: 3,
    evalPropName: 'innovativeness',
  },
  {
    evalName: 'People Skills ',
    evalDesc:
      'Displays ability and drive to interact and work effectively with people from diverse backgrounds and in a variety of situations.',
    evalId: 4,
    evalPropName: 'eqEmpathy',
  },
  {
    evalName: 'Personal Effectiveness ',
    evalDesc:
      'Able to perform and maintain effectiveness in the face of pressure and challenges.',
    evalId: 5,
    evalPropName: 'communicationSkills',
  },
  {
    evalName: 'Communication Skills ',
    evalDesc:
      'Conveys information effectively and responds meaningfully to others.',
    evalId: 6,
    evalPropName: 'integrity',
  },
  {
    evalName: 'Community Involvement ',
    evalDesc:
      'Demonstrates dedication to serve the community and has thought through how the community involvement relates to his/her ambition and passion.',
    evalId: 7,
    evalPropName: 'personalImpact',
  },
];

const EvaluationForm = (props) => {
  const {
    handleRadioBtnChange,
    state,
    handleChange,
    handleCommisionRadioBtnChange,
    isFormSubmitted,
    response,
    evaluationId,
    isEditForm,
    userId,
  } = props || {};
  const {
    comments,
    veryUnsuitable,
    unsuitable,
    referMinistryAgencies,
    awardPsc,
    considerPsc,
    reservePsc,
    isValidComments,
    isValidCommisionVals,
    isValidAwardPscSch,
  } = state || {};
  return (
    <div>
      <table className="table-border w-100 table-zoom-auto">
        <thead className="text-center heading-align">
          <th className="color-blue font-size-x-lg">No.</th>
          <th className="color-blue font-size-x-lg">
            <div className="font-size-x-lg">Trait</div>
            <div>(Behavioural Statement)</div>
          </th>
          <th className="csc-th--vl results-min-width">
            <div>1</div>
            <small>Very weak</small>
          </th>
          <th className="csc-th--l results-min-width">
            <div>2</div>
            <small>Weak</small>
          </th>
          <th className="csc-th--m results-min-width">
            <div>3</div>
            <small>Moderate</small>
          </th>
          <th className="csc-th--h results-min-width">
            <div>4</div>
            <small>Strong</small>
          </th>
          <th className="csc-th--vh results-min-width">
            <div>5</div>
            <small>Very Strong</small>
          </th>
          <th className="color-gray results-min-width">
            <div>Not able to tell</div>
          </th>
        </thead>
        <tbody className="table-border">
          {evalStaticData.map((res) => {
            const { evalDesc, evalId, evalName, evalPropName } = res || {};
            return (
              <tr className="text-left">
                <td>{evalId}</td>
                <td>
                  <div className="font-weight-bold mb-2 h5">{evalName}</div>
                  <div
                    className="font-style-italic"
                    style={{ fontSize: 'large' }}>
                    {evalDesc}
                  </div>
                </td>
                <td className="csc-th--vl-light">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'veryWeak');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-veryWeak`
                        : `${evalId}-${evaluationId}-${evalName}-veryWeak`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-veryWeak`
                        : `${evalId}-${evaluationId}-${evalName}-veryWeak`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'veryWeak'
                        : response && response[evalPropName] === 'veryWeak'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--l-light">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'weak');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-weak`
                        : `${evalId}-${evaluationId}-${evalName}-weak`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-weak`
                        : `${evalId}-${evaluationId}-${evalName}-weak`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'weak'
                        : response && response[evalPropName] === 'weak'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--m-light">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'moderate');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-moderate`
                        : `${evalId}-${evaluationId}-${evalName}-moderate`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-moderate`
                        : `${evalId}-${evaluationId}-${evalName}-moderate`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'moderate'
                        : response && response[evalPropName] === 'moderate'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--h-light">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'strong');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-strong`
                        : `${evalId}-${evaluationId}-${evalName}-strong`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-strong`
                        : `${evalId}-${evaluationId}-${evalName}-strong`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'strong'
                        : response && response[evalPropName] === 'strong'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--vh-light">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'veryStrong');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-veryStrong`
                        : `${evalId}-${evaluationId}-${evalName}-veryStrong`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-veryStrong`
                        : `${evalId}-${evaluationId}-${evalName}-veryStrong`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'veryStrong'
                        : response && response[evalPropName] === 'veryStrong'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
                <td className="csc-th--na-light">
                  {' '}
                  <RadioButton
                    className="mt-2"
                    onChange={() => {
                      handleRadioBtnChange(evalPropName, 'none');
                    }}
                    labelText=""
                    name={
                      !isFormSubmitted || isEditForm === true
                        ? `${userId}-${evalPropName}`
                        : `${evaluationId}-${evalPropName}`
                    }
                    id={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-none`
                        : `${evalId}-${evaluationId}-${evalName}-none`
                    }
                    key={
                      !isFormSubmitted || isEditForm === true
                        ? `${evalId}-${evalName}-none`
                        : `${evalId}-${evaluationId}-${evalName}-none`
                    }
                    value={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName]
                        : response && response[evalPropName]
                    }
                    checked={
                      !isFormSubmitted || isEditForm === true
                        ? state && state[evalPropName] === 'none'
                        : response && response[evalPropName] === 'none'
                    }
                    disabled={!isEditForm ? isFormSubmitted : false}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="color-blue text-center table-border mt-2 p-3">
        Overall rating on the candidate’s suitability for a President’s
        Scholarship
      </div>
      <div className="mt-2">
        <div className=" mb-2">
          Each Commission Member should rate this section based on his/her
          independent opinion of the candidate.{' '}
          <span className="f-w-b">
            The ratings should be based on all the information available on the
            candidate, and not solely on how they have presented themselves at
            the interview.
          </span>{' '}
          The rating may differ from the final selection outcome
        </div>
      </div>
      {isValidCommisionVals === true && (
        <div className="small invalid-color">Please select valid options</div>
      )}
      {isValidAwardPscSch === true && (
        <div className="small invalid-color">
          Please select atleast one option for president scholarship
        </div>
      )}
      <div className="mt-2 mb-2">
        <div className="d-flex justify-content-left mt-2">
          <RadioButton
            name={
              !isFormSubmitted || isEditForm === true
                ? `${userId}-radio-eval`
                : `${evaluationId}-radio-val`
            }
            id={
              !isFormSubmitted || isEditForm === true
                ? 'veryUnsuitable'
                : `${evaluationId}-radio-val`
            }
            key={
              !isFormSubmitted || isEditForm === true
                ? 'veryUnsuitable'
                : `${evaluationId}-radio-val`
            }
            value={
              !isFormSubmitted || isEditForm === true
                ? veryUnsuitable
                : response && response.veryUnsuitable
            }
            checked={
              !isFormSubmitted || isEditForm === true
                ? veryUnsuitable
                : response && response.veryUnsuitable
            }
            labelText="Unsuitable"
            onChange={() => {
              handleCommisionRadioBtnChange('veryUnsuitable', true);
            }}
            disabled={!isEditForm ? isFormSubmitted : false}
          />
        </div>
        <div className="d-flex justify-content-left mt-2">
          <RadioButton
            name={
              !isFormSubmitted || isEditForm === true
                ? `${userId}-radio-eval`
                : `${evaluationId}-radio-val`
            }
            id={
              !isFormSubmitted || isEditForm === true
                ? 'unsuitable'
                : `${evaluationId}-radio-val`
            }
            key={
              !isFormSubmitted || isEditForm === true
                ? 'unsuitable'
                : `${evaluationId}-radio-val`
            }
            value={
              !isFormSubmitted || isEditForm === true
                ? unsuitable
                : response && response.unsuitable
            }
            checked={
              !isFormSubmitted || isEditForm === true
                ? unsuitable
                : response && response.unsuitable
            }
            labelText="Shortlist for further deliberation after all candidates have been interviewed*"
            onChange={() => {
              handleCommisionRadioBtnChange('unsuitable', true);
            }}
            disabled={!isEditForm ? isFormSubmitted : false}
          />
        </div>
        <div className="d-flex justify-content-left mt-2">
          <RadioButton
            name={
              !isFormSubmitted || isEditForm === true
                ? `${userId}-radio-eval`
                : `${evaluationId}-radio-val`
            }
            id={
              !isFormSubmitted || isEditForm === true
                ? 'referMinistryAgencies'
                : `${evaluationId}-radio-val`
            }
            key={
              !isFormSubmitted || isEditForm === true
                ? 'referMinistryAgencies'
                : `${evaluationId}-radio-val`
            }
            value={
              !isFormSubmitted || isEditForm === true
                ? referMinistryAgencies
                : response && response.referMinistryAgencies
            }
            checked={
              !isFormSubmitted || isEditForm === true
                ? referMinistryAgencies
                : response && response.referMinistryAgencies
            }
            labelText="To Award President’s Scholarship (proceeding for studies this year)"
            onChange={() => {
              handleCommisionRadioBtnChange('referMinistryAgencies', true);
            }}
            disabled={!isEditForm ? isFormSubmitted : false}
          />
        </div>
        <div className="mt-2">
          <div className="d-flex justify-content-left">
            <RadioButton
              name={
                !isFormSubmitted || isEditForm === true
                  ? `${userId}-radio-eval`
                  : `${evaluationId}-radio-val`
              }
              id={
                !isFormSubmitted || isEditForm === true
                  ? 'awardPsc'
                  : `${evaluationId}-radio-val`
              }
              key={
                !isFormSubmitted || isEditForm === true
                  ? 'awardPsc'
                  : `${evaluationId}-radio-val`
              }
              value={
                !isFormSubmitted || isEditForm === true
                  ? awardPsc
                  : response && response.awardPsc
              }
              checked={
                !isFormSubmitted || isEditForm === true
                  ? awardPsc
                  : response && response.awardPsc
              }
              labelText="To Offer Provisional President’s Scholarship (deferring studies)"
              onChange={() => {
                handleCommisionRadioBtnChange('awardPsc', true);
              }}
              disabled={!isEditForm ? isFormSubmitted : false}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 mb-2 f-w-b">
        *applicable if the interviews span over two days
      </div>
      <div className="mt-2 mb-2 font-weight-bold">Comments</div>
      <div>
        <TextArea
          name={
            !isFormSubmitted || isEditForm === true
              ? 'comments'
              : `${evaluationId}-comments`
          }
          id={
            !isFormSubmitted || isEditForm === true
              ? 'comments'
              : `${evaluationId}-comments`
          }
          value={
            !isFormSubmitted || isEditForm === true
              ? comments
              : response && response.comments
          }
          labelText=""
          hideLabel
          disabled={!isEditForm ? isFormSubmitted : false}
          onChange={handleChange}
          placeholder="Please enter your comments"
          invalid={isValidComments}
          invalidText="Please enter valid comments"
        />
      </div>
    </div>
  );
};

const CombinedEvaluationForm = (props) => {
  const { combinedEvalForm } = props || {};
  return (
    <Fragment>
      <table className="table-border w-100 table-zoom-auto">
        <thead>
          <th className="">
            <div className="font-size-x-lg">Trait</div>
          </th>
          <th className="results-min-width">
            <div>1</div>
            <small>Very weak</small>
          </th>
          <th className="results-min-width">
            <div>2</div>
            <small>Weak</small>
          </th>
          <th className="results-min-width">
            <div>3</div>
            <small>Moderate</small>
          </th>
          <th className="results-min-width">
            <div>4</div>
            <small>Strong</small>
          </th>
          <th className="results-min-width">
            <div>5</div>
            <small>Very Strong</small>
          </th>
          <th className="results-min-width">
            <div>Not able to tell</div>
          </th>
        </thead>
        <tbody className="table-border">
          {evalStaticData.map((values) => {
            const { evalName, evalDesc, evalPropName } = values || {};
            const currObj = combinedEvalForm && combinedEvalForm[evalPropName];
            const veryWeakArr = currObj && currObj.veryWeak;
            const weakArr = currObj && currObj.weak;
            const moderateArr = currObj && currObj.moderate;
            const strongArr = currObj && currObj.strong;
            const veryStrongArr = currObj && currObj.veryStrong;
            const noneArr = currObj && currObj.none;
            return (
              <tr>
                <td className="text-left">
                  <div className="font-weight-bold mb-2 h5">{evalName}</div>
                  <div
                    className="font-style-italic"
                    style={{ fontSize: 'large' }}>
                    {evalDesc}
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    {veryWeakArr &&
                      Array.isArray(veryWeakArr) &&
                      veryWeakArr.length > 0 &&
                      veryWeakArr.map((d, idx) => (
                        <div className="mr-2">
                          <i
                            className={`fa fa-circle trait-color-${d}`}
                            aria-hidden="true"
                          />
                        </div>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    {weakArr &&
                      Array.isArray(weakArr) &&
                      weakArr.length > 0 &&
                      weakArr.map((d, idx) => (
                        <div className="mr-2">
                          <i
                            className={`fa fa-circle trait-color-${d}`}
                            aria-hidden="true"
                          />
                        </div>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    {moderateArr &&
                      Array.isArray(moderateArr) &&
                      moderateArr.length > 0 &&
                      moderateArr.map((d, idx) => (
                        <div className="mr-2">
                          <i
                            className={`fa fa-circle trait-color-${d}`}
                            aria-hidden="true"
                          />
                        </div>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    {strongArr &&
                      Array.isArray(strongArr) &&
                      strongArr.length > 0 &&
                      strongArr.map((d, idx) => (
                        <div className="mr-2">
                          <i
                            className={`fa fa-circle trait-color-${d}`}
                            aria-hidden="true"
                          />
                        </div>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    {veryStrongArr &&
                      Array.isArray(veryStrongArr) &&
                      veryStrongArr.length > 0 &&
                      veryStrongArr.map((d, idx) => (
                        <div className="mr-2">
                          <i
                            className={`fa fa-circle trait-color-${d}`}
                            aria-hidden="true"
                          />
                        </div>
                      ))}
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    {noneArr &&
                      Array.isArray(noneArr) &&
                      noneArr.length > 0 &&
                      noneArr.map((d, idx) => (
                        <div className="mr-2">
                          <i
                            className={`fa fa-circle trait-color-${d}`}
                            aria-hidden="true"
                          />
                        </div>
                      ))}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

class PSCandidateEvalMock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditForm: false,
      openAccr: 0,
      usersList: [],
      combinedEvalForm: {},
    };
    this.handleRedirect = React.createRef();
  }

  componentDidMount() {
    const getEvalData = sessionStorage.getItem('eval-data');
    const evaluationList = JSON.parse(getEvalData);
    let filterEvalList = {};
    if (
      evaluationList &&
      Array.isArray(evaluationList) &&
      evaluationList.length > 0
    ) {
      filterEvalList = evaluationList.find((data) => data && data.userId == 17);
    }
    this.setState(
      {
        ...filterEvalList,
        openAccr: 17,
      },
      () => {
        this.handleCombinedEvalForm();
      }
    );
  }

  handleCombinedEvalForm = () => {
    const getEvalData = sessionStorage.getItem('eval-data');
    const evaluationList = JSON.parse(getEvalData);
    let finalOutComeObj = {
      commitment: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      knowledge: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      innovativeness: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      eqEmpathy: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      communicationSkills: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      integrity: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      personalImpact: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      performanceUnderPressure: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      selfBelief: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
      selfMonitoring: {
        veryWeak: [],
        weak: [],
        moderate: [],
        strong: [],
        veryStrong: [],
        none: [],
      },
    };
    let usersList = [];
    let veryUnsuitableCount = 0;
    let unsuitableCount = 0;
    let referMinistryAgenciesCount = 0;
    let awardPscCount = 0;
    let considerPscCount = 0;
    let reservePscCount = 0;
    let veryUnsuitableArr = [];
    let unsuitableArr = [];
    let referMinistryAgenciesArr = [];
    let awardPscArr = [];
    let considerPscArr = [];
    let reservePscArr = [];
    if (
      evaluationList &&
      Array.isArray(evaluationList) &&
      evaluationList.length > 0
    ) {
      evaluationList.forEach((res, idx) => {
        const {
          userinfo,
          commitment,
          knowledge,
          innovativeness,
          eqEmpathy,
          communicationSkills,
          integrity,
          personalImpact,
          performanceUnderPressure,
          selfBelief,
          selfMonitoring,
          veryUnsuitable,
          unsuitable,
          referMinistryAgencies,
          awardPsc,
          considerPsc,
          reservePsc,
        } = res || {};
        const { firstname, lastname } = userinfo || {};
        const fullName = `${firstname} ${lastname}`;
        if (usersList && Array.isArray(usersList) && usersList.length > 0) {
          usersList.push(fullName);
        } else {
          usersList = [fullName];
        }
        if (veryUnsuitable === true) {
          veryUnsuitableCount += 1;
          veryUnsuitableArr.push(idx + 1);
        }
        if (unsuitable === true) {
          unsuitableCount += 1;
          unsuitableArr.push(idx + 1);
        }
        if (referMinistryAgencies === true) {
          referMinistryAgenciesCount += 1;
          referMinistryAgenciesArr.push(idx + 1);
        }
        if (awardPsc === true) {
          awardPscCount += 1;
          awardPscArr.push(idx + 1);
        }
        if (considerPsc === true) {
          considerPscCount += 1;
          considerPscArr.push(idx + 1);
        }
        if (reservePsc === true) {
          reservePscCount += 1;
          reservePscArr.push(idx + 1);
        }
        this.handleEvalFormVals(
          commitment,
          finalOutComeObj,
          'commitment',
          idx + 1
        );
        this.handleEvalFormVals(
          knowledge,
          finalOutComeObj,
          'knowledge',
          idx + 1
        );
        this.handleEvalFormVals(
          innovativeness,
          finalOutComeObj,
          'innovativeness',
          idx + 1
        );
        this.handleEvalFormVals(
          eqEmpathy,
          finalOutComeObj,
          'eqEmpathy',
          idx + 1
        );
        this.handleEvalFormVals(
          communicationSkills,
          finalOutComeObj,
          'communicationSkills',
          idx + 1
        );
        this.handleEvalFormVals(
          integrity,
          finalOutComeObj,
          'integrity',
          idx + 1
        );
        this.handleEvalFormVals(
          personalImpact,
          finalOutComeObj,
          'personalImpact',
          idx + 1
        );
        this.handleEvalFormVals(
          performanceUnderPressure,
          finalOutComeObj,
          'performanceUnderPressure',
          idx + 1
        );
        this.handleEvalFormVals(
          selfBelief,
          finalOutComeObj,
          'selfBelief',
          idx + 1
        );
        this.handleEvalFormVals(
          selfMonitoring,
          finalOutComeObj,
          'selfMonitoring',
          idx + 1
        );
      });
    }
    this.setState({
      combinedEvalForm: finalOutComeObj,
      usersList,
      veryUnsuitableCount,
      unsuitableCount,
      referMinistryAgenciesCount,
      awardPscCount,
      considerPscCount,
      reservePscCount,
      veryUnsuitableArr,
      unsuitableArr,
      referMinistryAgenciesArr,
      awardPscArr,
      considerPscArr,
      reservePscArr,
    });
  };

  handleEvalFormVals = (value, finalOutComeObj, type, arrVal) => {
    if (value === 'moderate') {
      finalOutComeObj[type].moderate.push(arrVal);
    } else if (value === 'weak') {
      finalOutComeObj[type].weak.push(arrVal);
    } else if (value === 'veryWeak') {
      finalOutComeObj[type].veryWeak.push(arrVal);
    } else if (value === 'strong') {
      finalOutComeObj[type].strong.push(arrVal);
    } else if (value === 'veryStrong') {
      finalOutComeObj[type].veryStrong.push(arrVal);
    } else if (value === 'none') {
      finalOutComeObj[type].none.push(arrVal);
    }
    return finalOutComeObj;
  };

  handleEditForm = () => {
    this.setState({
      isEditForm: true,
    });
  };

  handleGetEvalAPi = () => {
    const { appId } = this.props;
    this.props
      .getCandidateEvaluationDetails(appId)
      .then((res) => {
        if (res && !res.error) {
          this.handleCombinedEvalForm();
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          errorNotif: true,
        });
      });
  };

  handleRadioBtnChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCommisionRadioBtnChange = (name, value) => {
    let veryUnsuitable = null;
    let unsuitable = null;
    let referMinistryAgencies = null;
    let awardPsc = null;
    let considerPsc = null;
    let reservePsc = null;
    if (name === 'veryUnsuitable') {
      veryUnsuitable = true;
      unsuitable = false;
      referMinistryAgencies = false;
      awardPsc = false;
      considerPsc = false;
      reservePsc = false;
    } else if (name === 'unsuitable') {
      veryUnsuitable = false;
      unsuitable = true;
      referMinistryAgencies = false;
      awardPsc = false;
      considerPsc = false;
      reservePsc = false;
    } else if (name === 'referMinistryAgencies') {
      veryUnsuitable = false;
      unsuitable = false;
      referMinistryAgencies = true;
      awardPsc = false;
      considerPsc = false;
      reservePsc = false;
    } else if (name === 'awardPsc') {
      veryUnsuitable = false;
      unsuitable = false;
      referMinistryAgencies = false;
      awardPsc = true;
      considerPsc = false;
      reservePsc = false;
    } else if (name === 'considerPsc') {
      considerPsc = true;
      reservePsc = false;
      awardPsc = true;
    } else if (name === 'reservePsc') {
      considerPsc = false;
      reservePsc = true;
      awardPsc = true;
    }
    this.setState({
      veryUnsuitable,
      unsuitable,
      referMinistryAgencies,
      awardPsc,
      considerPsc,
      reservePsc,
    });
  };

  handleSubmitEvalForm = () => {
    const {
      commitment,
      knowledge,
      innovativeness,
      eqEmpathy,
      communicationSkills,
      integrity,
      personalImpact,
      performanceUnderPressure,
      selfBelief,
      selfMonitoring,
      comments,
      veryUnsuitable,
      unsuitable,
      referMinistryAgencies,
      awardPsc,
      considerPsc,
      reservePsc,
      evaluationId,
    } = this.state;
    let isValidEvalTraits = false;
    let isValidCommisionVals = false;
    let isValidAwardPscSch = false;
    if (
      !commitment ||
      !knowledge ||
      !innovativeness ||
      !eqEmpathy ||
      !communicationSkills ||
      !integrity ||
      !personalImpact ||
      !performanceUnderPressure ||
      !selfBelief ||
      !selfMonitoring
    ) {
      isValidEvalTraits = true;
      this.handleRedirect &&
        this.handleRedirect.current &&
        this.handleRedirect.current.focus &&
        this.handleRedirect.current.focus();
    } else {
      isValidEvalTraits = false;
    }
    if (!veryUnsuitable && !unsuitable && !referMinistryAgencies && !awardPsc) {
      isValidCommisionVals = true;
    } else {
      isValidCommisionVals = false;
    }
    if (awardPsc === true && !considerPsc && !reservePsc) {
      isValidAwardPscSch = true;
    } else {
      isValidAwardPscSch = false;
    }
    this.setState(
      {
        isValidEvalTraits,
        isValidCommisionVals,
      },
      () => {
        const {
          isValidEvalTraits: stateValidTraits,
          isValidComments: stateValidComments,
          isValidCommisionVals: stateValidCommVals,
          isValidAwardPscSch: stateValidAwardPsc,
        } = this.state;
        if (
          !stateValidTraits &&
          !stateValidComments &&
          !stateValidCommVals &&
          !stateValidAwardPsc
        ) {
          const { appId } = this.props || {};
          const data = {
            evaluationId: 4,
            appId,
            userId: 17,
            commitment,
            knowledge,
            innovativeness,
            eqEmpathy,
            communicationSkills,
            integrity,
            personalImpact,
            performanceUnderPressure,
            selfBelief,
            selfMonitoring,
            comments,
            veryUnsuitable,
            unsuitable,
            referMinistryAgencies,
            awardPsc,
            considerPsc,
            reservePsc,
            createdAt: '2020-07-21T11:54:25.000Z',
            updatedAt: null,
            isFormSubmitted: true,
            userinfo: {
              userId: 17,
              firstname: 'Ms. Chua',
              lastname: 'Sock Koong',
              email: 'admin@csc.sg',
            },
          };
          const candidateEvaldata = [
            {
              evaluationId: 2,
              appId: '59',
              userId: 15,
              commitment: 'weak',
              knowledge: 'weak',
              innovativeness: 'weak',
              eqEmpathy: 'weak',
              communicationSkills: 'weak',
              integrity: 'weak',
              personalImpact: 'weak',
              performanceUnderPressure: 'weak',
              selfBelief: 'weak',
              selfMonitoring: 'weak',
              veryUnsuitable: false,
              unsuitable: true,
              referMinistryAgencies: false,
              awardPsc: false,
              considerPsc: false,
              reservePsc: false,
              createdAt: '2020-07-21T11:54:25.000Z',
              updatedAt: null,
              comments: 'sads',
              isFormSubmitted: true,
              userinfo: {
                userId: 15,
                firstname: 'Mr. Lee',
                lastname: 'Tzu Yang',
                email: 'admin@csc.sg',
              },
            },
            {
              evaluationId: 3,
              appId: '59',
              userId: 16,
              commitment: 'veryWeak',
              knowledge: 'weak',
              innovativeness: 'moderate',
              eqEmpathy: 'weak',
              communicationSkills: 'weak',
              integrity: 'veryWeak',
              personalImpact: 'weak',
              performanceUnderPressure: 'veryWeak',
              selfBelief: 'weak',
              selfMonitoring: 'moderate',
              veryUnsuitable: false,
              unsuitable: true,
              referMinistryAgencies: false,
              awardPsc: false,
              considerPsc: false,
              reservePsc: false,
              createdAt: '2020-07-21T11:54:25.000Z',
              updatedAt: null,
              comments: 'sads',
              isFormSubmitted: true,
              userinfo: {
                userId: 16,
                firstname: 'Mr. Kwa',
                lastname: ' Chong Seng',
                email: 'admin@csc.sg',
              },
            },
          ];
          candidateEvaldata.push(data);
          sessionStorage.setItem(
            'eval-data',
            JSON.stringify(candidateEvaldata)
          );
          this.handleCombinedEvalForm();
        }
      }
    );
  };

  handleApiRes = (res) => {
    if (res && !res.error) {
      this.setState({
        successNotif: true,
        notifMsg: 'Your changes have been updated successfully',
        errorNotif: false,
        isEditForm: false,
      });
      this.handleGetEvalAPi();
    } else {
      this.setState({
        errorNotif: true,
        isEditForm: false,
      });
    }
  };

  handleAccrClick = (userId) => {
    this.setState({
      openAccr: userId,
    });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  render() {
    const getEvalData = sessionStorage.getItem('eval-data');
    const evaluationList = JSON.parse(getEvalData);
    const { user } = this.props;
    const { firstname = 'Mr. Lee', lastname = 'Tzu Yang', userId } = user || {};
    const {
      isValidEvalTraits,
      successNotif,
      errorNotif,
      notifMsg,
      isEditForm,
      openAccr,
      combinedEvalForm,
      usersList,
      veryUnsuitableCount,
      unsuitableCount,
      referMinistryAgenciesCount,
      awardPscCount,
      considerPscCount,
      reservePscCount,
      veryUnsuitableArr,
      unsuitableArr,
      referMinistryAgenciesArr,
      awardPscArr,
      considerPscArr,
      reservePscArr,
    } = this.state;
    return (
      <div>
        <div className="d-flex justify-content-between">
          <div class="mt-2 h6 font-weight-bold">
            President’s Scholarship Candidate Evaluation Form{' '}
          </div>
        </div>
        <div style={{ lineHeight: 1.3 }} className="text-justify mt-2 mb-2">
          The Candidate Evaluation Form for the use of PSC members at the
          President’s Scholarship Selection Board serves to move towards a more
          structured decision-making process, focusing on the key attributes
          desired in a President’s Scholar.
        </div>
        <div style={{ lineHeight: 1.3 }} className="text-justify mt-2 mb-2">
          It is recommended that Members evaluate candidates holistically by
          integrating assessment data available in the candidate dossier,
          including the Assessment Centre and Peer Appraisal reports, and how
          they present themselves at the panel interview. The Candidate
          Evaluation Form will be reviewed periodically in order to ensure its
          continued relevance and ease of use. Members’ views on the Candidate
          Evaluation Form are welcome.
        </div>
        <div style={{ lineHeight: 1.3 }} className="text-justify mt-2 mb-2">
          Please assist to fill up this evaluation form for all the candidates.
          For interview(s) you did not attend or had joined/left mid-way, you do
          not need to complete the evaluation form.
        </div>
        <input
          style={{ height: 0, opacity: 0 }}
          type="text"
          ref={this.handleRedirect}
        />
        {isValidEvalTraits === true && (
          <InlineNotification
            lowContrast
            title="Please select valid rating for each trait"
            iconDescription="describes the close button"
            kind="error"
            onCloseButtonClick={() => {
              this.setState({ isValidEvalTraits: false });
            }}
          />
        )}
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        {(evaluationList &&
          Array.isArray(evaluationList) &&
          evaluationList.length <= 0) ||
        !evaluationList ? (
          <Accordion>
            <AccordionItem
              open
              className="content-padding"
              title={
                <h5 className="font-weight-bold text-black text-capitalize">
                  {firstname} {lastname}
                </h5>
              }>
              <div class="mt-2 h6 f-w-b">Key Attributes</div>
              <div
                style={{ lineHeight: 1.3 }}
                className="mt-2 mb-2 text-justify font-style-italic">
                Please select the ratings based on a holistic assessment of the
                candidate on each attribute, taking into consideration ratings
                that the candidate has received for the same attributes in the
                Assessment Centre/Peer Appraisal exercises (applicable for the
                first six attributes).
              </div>
              <EvaluationForm
                handleRadioBtnChange={this.handleRadioBtnChange}
                state={this.state}
                handleChange={this.handleChange}
                handleCommisionRadioBtnChange={
                  this.handleCommisionRadioBtnChange
                }
                isFormSubmitted={false}
                isEditForm={isEditForm}
                userId={userId}
              />
              <div className="mt-2 text-center">
                <Button
                  kind="primary"
                  size="small"
                  onClick={this.handleSubmitEvalForm}>
                  Submit
                </Button>
              </div>
            </AccordionItem>
          </Accordion>
        ) : (
          evaluationList &&
          Array.isArray(evaluationList) &&
          evaluationList.length > 0 && (
            <div>
              <Accordion>
                {evaluationList.map((res) => {
                  const {
                    userinfo,
                    isFormSubmitted,
                    evaluationId,
                    userId: propUserId,
                  } = res || {};
                  const { firstname: propFirstName, lastname: propLastName } =
                    userinfo || {};
                  return (
                    <AccordionItem
                      className="content-padding"
                      open={openAccr == propUserId}
                      onClick={() => this.handleAccrClick(propUserId)}
                      title={
                        <h5 className="font-weight-bold text-black">
                          {propFirstName} {propLastName}
                        </h5>
                      }>
                      <div>
                        {/* {userId == propUserId && (
                          <div class="text-right mb-3">
                            <Button
                              kind="tertiary"
                              onClick={this.handleEditForm}>
                              Edit
                            </Button>
                          </div>
                        )} */}
                      </div>
                      <EvaluationForm
                        handleRadioBtnChange={this.handleRadioBtnChange}
                        state={this.state}
                        handleChange={this.handleChange}
                        handleCommisionRadioBtnChange={
                          this.handleCommisionRadioBtnChange
                        }
                        userId={propUserId}
                        evaluationId={evaluationId}
                        response={res}
                        isFormSubmitted={isFormSubmitted}
                        isEditForm={isEditForm}
                      />
                      {userId == propUserId && isEditForm === true && (
                        <div class="text-center mt-3">
                          <Button
                            kind="primary"
                            onClick={this.handleSubmitEvalForm}>
                            Submit
                          </Button>
                        </div>
                      )}
                    </AccordionItem>
                  );
                })}
              </Accordion>
              <div className="mt-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex align-items-center mt-3">
                      {usersList &&
                        Array.isArray(usersList) &&
                        usersList.length > 0 &&
                        usersList.map((vals, idx) => (
                          <div className="mr-2">
                            <i
                              className={`fa fa-circle trait-color-${idx + 1}`}
                              aria-hidden="true"
                            />{' '}
                            <span className="font-weight-bold">{vals}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div>
                    <Button kind="ghost" onClick={this.handleGetEvalAPi}>
                      Refresh
                    </Button>
                  </div>
                </div>
                <hr />
                <h5 className="mb-2">Combined Evaluation Form</h5>
                <div>
                  <CombinedEvaluationForm combinedEvalForm={combinedEvalForm} />
                </div>
                <div>
                  <h5 className="mb-2 mt-2">Combined Overall Rating</h5>
                  <div className="font-weight-bold mb-2">Overall Rating</div>
                  <div className="mt-2 mb-2">
                    <div className="d-flex justify-content-left mt-2">
                      <span style={{ fontWeight: 'bold' }} className="pr-2">
                        {veryUnsuitableCount}
                      </span>{' '}
                      Unsuitable&nbsp;
                      {veryUnsuitableArr &&
                        Array.isArray(veryUnsuitableArr) &&
                        veryUnsuitableArr.length > 0 &&
                        veryUnsuitableArr.map((res) => (
                          <span className="pr-2">
                            <i
                              className={`fa fa-circle trait-color-${res}`}
                              aria-hidden="true"
                            />
                          </span>
                        ))}
                    </div>
                    <div className="d-flex justify-content-left mt-2">
                      <span style={{ fontWeight: 'bold' }} className="pr-2">
                        {unsuitableCount}
                      </span>{' '}
                      Shortlist for further deliberation after all candidates
                      have been interviewed* &nbsp;
                      {unsuitableArr &&
                        Array.isArray(unsuitableArr) &&
                        unsuitableArr.length > 0 &&
                        unsuitableArr.map((res) => (
                          <span className="pr-2">
                            <i
                              className={`fa fa-circle trait-color-${res}`}
                              aria-hidden="true"
                            />
                          </span>
                        ))}
                    </div>
                    <div className="d-flex justify-content-left mt-2">
                      <span style={{ fontWeight: 'bold' }} className="pr-2">
                        {referMinistryAgenciesCount}
                      </span>{' '}
                      To Award President’s Scholarship (proceeding for studies
                      this year)&nbsp;
                      {referMinistryAgenciesArr &&
                        Array.isArray(referMinistryAgenciesArr) &&
                        referMinistryAgenciesArr.length > 0 &&
                        referMinistryAgenciesArr.map((res) => (
                          <span className="pr-2">
                            <i
                              className={`fa fa-circle trait-color-${res}`}
                              aria-hidden="true"
                            />
                          </span>
                        ))}
                    </div>
                    <div className="mt-2">
                      <div className="d-flex justify-content-left">
                        <span style={{ fontWeight: 'bold' }} className="pr-2">
                          {awardPscCount}
                        </span>{' '}
                        To Offer Provisional President’s Scholarship (deferring
                        studies)&nbsp;
                        {awardPscArr &&
                          Array.isArray(awardPscArr) &&
                          awardPscArr.length > 0 &&
                          awardPscArr.map((res) => (
                            <span className="pr-2">
                              <i
                                className={`fa fa-circle trait-color-${res}`}
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <h6 className="mt-2 mb-2">Final Outcome Section</h6>
                <div className="mt-2 mb-2">
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      onChange={() => {}}
                      name="psStatusLevel2"
                      labelText="Unsuitable"
                    />
                  </div>
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      name="psStatusLevel2"
                      onChange={() => {}}
                      labelText="Shortlist for further deliberation after all candidates have been interviewed*"
                    />
                  </div>
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      onChange={() => {}}
                      name="psStatusLevel2"
                      labelText="To Award President’s Scholarship (proceeding for studies this year)"
                    />
                  </div>
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      onChange={() => {}}
                      name="psStatusLevel2"
                      labelText="To Offer Provisional President’s Scholarship (deferring studies)"
                    />
                  </div>
                </div>
                <div className="font-weight-bold mt-2 mb-2">Comments</div>
                <TextArea rows={3} placeholder="Please give overall comments" />
                <div className="text-center mt-2 d-flex align-items-center">
                  <div>
                    <Button
                      onClick={() => {
                        this.setState({ outcomeDet: true });
                      }}>
                      Submit
                    </Button>
                  </div>
                  {this.state.outcomeDet == true && (
                    <div style={{ color: 'green' }} className="mt-2 ml-2">
                      Your details have been submitted.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  createCandidateEvalDetails,
  upsertCandidateEvaluationDetails,
  getCandidateEvaluationDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PSCandidateEvalMock);
