import React, { Fragment } from 'react';

//Common Components
import GenericDataTable from '../common/GenericDataTable';

//Common packages
import moment from 'moment';
import { TextInput } from 'carbon-components-react';

//Images
import MaleImg from '../../assets/images/male-img.png';
import FemaleImg from '../../assets/images/female-img.png';
import { Progress } from 'react-sweet-progress';

const DashboardInterviewMock1 = (props) => {
  const { interviewsData, showDate, showColors, push, showTime } = props || {};
  if (showTime === true) {
    interviewsData.push(
      {
        firstName: 'Li wei',
        schoolName: 'Singapore Polytechnic',
        gender: 'Male',
        preferences: ['Public Administration', 'Engineering', 'Legal service'],
        coursePref: ['Physics', 'Applied Physics', 'Chemistry'],
        countryPref: [
          'University of Chicago (USA)',
          'University of Oxford (UK)',
          'National University of Singapore (SG)',
        ],
        ccaTier: {
          l: 1,
          r: 2,
        },
        psychometricRes: {
          wag: 22,
          anra: 5,
        },
        gba: {
          performance: 88,
          future: 82,
          award: 65,
          average: 78,
        },
        academicResults: {
          psc: 4.0 / 4.0,
          gp: 8,
          pw: 7,
          mtl: 5,
        },
        remarks: 'Very good',
        rank: 'Top 3%',
        criteria: 'Average Fit',
        faculty: 'Faculty',
        time: '4:00',
      },
      {
        firstName: 'Wang Fang',
        schoolName: 'National Junior College',
        gender: 'Female',
        preferences: [
          'Public Administration',
          'Legal service',
          'Foreign service',
        ],
        coursePref: ['Physics', 'Applied Physics', 'Chemistry'],
        countryPref: [
          'University of Chicago (USA)',
          'University of Oxford (UK)',
          'National University of Singapore (SG)',
        ],
        ccaTier: {
          l: 3,
          r: 3,
        },
        psychometricRes: {
          wag: 32,
          anra: 19,
        },
        gba: {
          performance: 88,
          future: 82,
          award: 65,
          average: 78,
        },
        academicResults: {
          psc: 9,
          gp: 8,
          pw: 7,
          mtl: 5,
        },
        remarks: 'Very good',
        rank: '18 / 523',
        criteria: 'Good Fit',
        faculty: null,
        time: '4:30',
      },
      {
        firstName: 'Chris Chong',
        schoolName: 'National University of Singapore',
        preferences: [
          'Public Administration',
          'Engineering',
          'Foreign service',
        ],
        coursePref: ['Physics', 'Applied Physics', 'Chemistry'],
        countryPref: [
          'University of Chicago (USA)',
          'University of Oxford (UK)',
          'National University of Singapore (SG)',
        ],
        gender: 'Male',
        ccaTier: {
          l: 1,
          r: 3,
        },
        psychometricRes: {
          wag: 70,
          anra: 92,
        },
        gba: {
          performance: 88,
          future: 82,
          award: 65,
          average: 78,
        },
        academicResults: {
          psc: 9,
          gp: 8,
          pw: 7,
          mtl: 5,
        },
        remarks: 'Very good',
        rank: 'Top 6 / 162',
        criteria: 'Average Fit',
        faculty: null,
        time: '5:00',
      }
    );
  }
  return (
    <Fragment>
      <div className=" bx--row ">
        <div className="bx--col-xs-12 bx--col-sm-12 bx--col-md-12 bx--col-lg-12 mb-2">
          <GenericDataTable
            otherActions={[]}
            tbodyClass={showColors ? 'candidate-background-color' : ''}
            searchable={false}
            rows={
              interviewsData &&
              Array.isArray(interviewsData) &&
              interviewsData.length > 0
                ? interviewsData.map((interview, idx) => {
                    const {
                      firstName,
                      lastName,
                      ccaTier,
                      gba,
                      academicResults,
                      remarks,
                      rank,
                      schoolName,
                      psychometricRes,
                      preferences,
                      gender,
                      criteria,
                      countryPref,
                      coursePref,
                      time,
                    } = interview;
                    const { wag, anra } = psychometricRes || {};
                    const { l, r } = ccaTier || {};
                    const { performance, future, award, average } = gba || {};
                    const { psc, gp, pw, mtl } = academicResults || {};
                    let wagColor = '';
                    if (wag > 50) {
                      wagColor = ' css-th-green-l';
                    } else if (wag >= 31 && wag <= 45) {
                      wagColor = 'csc-th--h';
                    } else {
                      wagColor = 'csc-th--vl';
                    }
                    let anraColor = '';
                    if (anra > 90) {
                      anraColor = 'css-th-green-l';
                    } else if (anra >= 25 && anra <= 89) {
                      anraColor = 'csc-th--h';
                    } else {
                      anraColor = 'csc-th--vl';
                    }
                    const fullName = `${firstName} ${lastName}`;
                    return {
                      isExpanded: false,
                      id: `row_${idx}`,
                      header_0: (
                        <div>
                          <div className="d-flex ml-2">
                            <div
                              onClick={() => {
                                push &&
                                  push(
                                    `/public/candidate/${encodeURIComponent(
                                      fullName
                                    )}`
                                  );
                              }}
                              style={{ fontWeight: 'bold' }}
                              className="h5 css-main-text-c mb-0 xpa-link">
                              {firstName} {lastName}
                            </div>
                            <div title={gender} className="ml-2">
                              {gender &&
                              gender.toString().toLowerCase() === 'male' ? (
                                <img
                                  style={{ width: '20px' }}
                                  src={MaleImg}
                                  alt="Male"
                                />
                              ) : null}
                              {gender &&
                              gender.toString().toLowerCase() === 'female' ? (
                                <img
                                  style={{ width: '20px' }}
                                  src={FemaleImg}
                                  alt="female"
                                />
                              ) : null}
                            </div>
                          </div>

                          <div className="mt-1 mb-1 ml-2 css-text-color">
                            {schoolName}
                          </div>
                          <div className="w-50 ml-2 mb-1 d-flex">
                            <h6>School Rank:</h6>{' '}
                            <div className="css-text-color ml-2">{rank}</div>
                          </div>
                          <div className="w-50 ml-2 mb-1 d-flex">
                            <h6>Criteria:</h6>{' '}
                            <div className="css-text-color ml-2">
                              {criteria}
                            </div>
                          </div>
                          {/* {showDate === true && (
                            <div className="ml-2 mb-1 d-flex">
                              <h6>Interview Date:</h6>{' '}
                              <div className="css-text-color ml-2">
                                {moment()
                                  .add(idx + 1, 'd')
                                  .format('DD/MM/YYYY')}
                              </div>
                            </div>
                          )} */}
                          <hr />
                          <div className="d-flex mt-2 mb-2 ml-2">
                            {!showDate && (
                              <div className="">
                                {idx === 0 && (
                                  <div>
                                    <h6 className="css-main-text-c">
                                      Main Interviewer
                                    </h6>
                                    <div
                                      title="Main Interviewer"
                                      className="mt-1 css-text-color">
                                      Mr. Lee Tzu Yang
                                    </div>
                                  </div>
                                )}
                                {idx === 1 && (
                                  <div>
                                    <h6 className="css-main-text-c">
                                      Main Interviewer
                                    </h6>
                                    <div
                                      title="Main Interviewer"
                                      className="mt-1 css-text-color">
                                      Mr. Kwa Chong Seng
                                    </div>
                                  </div>
                                )}
                                {idx === 2 && (
                                  <div>
                                    <h6 className="css-main-text-c">
                                      Main Interviewer
                                    </h6>
                                    <div
                                      title="Main Interviewer"
                                      className="mt-1 css-text-color">
                                      Ms. Chua Sock Koong
                                    </div>
                                  </div>
                                )}

                                {idx === 3 && (
                                  <div>
                                    <h6 className="css-main-text-c">
                                      Main Interviewer
                                    </h6>
                                    <div
                                      title="Main Interviewer"
                                      className="mt-1 css-text-color">
                                      Mr. Michael Lim
                                    </div>
                                  </div>
                                )}
                                {idx === 4 && (
                                  <div>
                                    <h6 className="css-main-text-c">
                                      Main Interviewer
                                    </h6>
                                    <div
                                      title="Main Interviewer"
                                      className="mt-1 css-text-color">
                                      Mr Edward D'Silva
                                    </div>
                                  </div>
                                )}
                                {idx === 5 && (
                                  <div>
                                    <h6 className="css-main-text-c">
                                      Main Interviewer
                                    </h6>
                                    <div
                                      title="Main Interviewer"
                                      className="mt-1 css-text-color">
                                      Mr Alan Chan
                                    </div>
                                  </div>
                                )}

                                {idx === 6 && (
                                  <div>
                                    <h6 className="css-main-text-c">
                                      Main Interviewer
                                    </h6>
                                    <div
                                      title="Main Interviewer"
                                      className="mt-1 css-text-color">
                                      Mr Po'ad Mattar
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {showTime === true && (
                              <div className="ml-4">
                                <div>
                                  <h6 className="css-main-text-c">
                                    Interview Time
                                  </h6>
                                  <div
                                    title="Main Interviewer"
                                    className="mt-1 css-text-color">
                                    {time}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {showDate === true && (
                            <div className="mt-2 mb-2 ml-2">
                              <div>
                                <h6 className="css-main-text-c">
                                  Main Interviewer
                                </h6>
                                <div
                                  title="Main Interviewer"
                                  className="mt-1 css-text-color">
                                  Mr. Michael Lim
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="mt-3 mb-3 ml-2">
                            <h6 className="css-main-text-c mb-1">Remarks</h6>
                            <small>
                              <div>
                                <div className="css-text-color">
                                  On track to being awarded Honours (Highest
                                  Distinction)
                                </div>
                                <div className="mt-1 css-text-color">
                                  Referred to ministries in 2019
                                </div>
                                <div className="mt-1 css-text-color">
                                  French (O Level): A1
                                </div>
                              </div>
                            </small>
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div>
                          <div className="bx--row">
                            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 ml-3 ml-lg-0 ml-xl-0 pl-0">
                              <h6 className="ml-2 css-traits-text-c f-w-b">
                                CCA Tier
                              </h6>
                              <div className="">
                                <div className="bx--row">
                                  <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                    <div className="d-flex">
                                      <div
                                        className={` csc-th--block css-traits-score`}>
                                        <div className="csc-pos--top">{l}</div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                          Leadership{' '}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0">
                                    <div className="d-flex ml-2">
                                      <div
                                        className={` csc-th--block css-traits-score`}>
                                        <div className="csc-pos--top">{r}</div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                          Representation
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <h6 className="ml-2 mb-2 mt-3 css-traits-text-c">
                                Academic Results
                              </h6>
                              {idx === 0 ? (
                                <Fragment>
                                  <div className="bx--row">
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                      <div className="d-flex">
                                        <div
                                          className={` csc-th--block css-traits-score`}>
                                          <div className="csc-pos--top">
                                            4.0
                                          </div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            GPA{' '}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0 pr-0">
                                      <div className=" text-left ml-2 css-traits-text-c">
                                        <div>
                                          Diploma in Infocomm and Security, 2020
                                        </div>
                                        <div>GCE 'O' Level, 2017</div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="d-flex ml-2">
                                  <div className=" text-center">
                                    <div className="h5 text-black mt-2">
                                      4.0/4.0
                                    </div>
                                    <strong className="">GPA</strong>
                                  </div>
                                  <div className=" text-left ml-2">
                                    <div>
                                      Diploma in Infocomm and Security, 2020
                                    </div>
                                    <div>GCE 'O' Level, 2017</div>
                                    <div>English Language: D7</div>
                                    <div>Chinese Language: C6</div>
                                  </div>
                                </div> */}
                                </Fragment>
                              ) : null}
                              {idx === 1 ? (
                                <Fragment>
                                  <div className="bx--row">
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                      <div className="d-flex">
                                        <div className=" csc-th--block css-traits-score">
                                          <div className="csc-pos--top">69</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            PSC Points
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-4 pl-4 ml-3 ml-lg-0">
                                      <div className="d-flex">
                                        <div className=" csc-th--block css-traits-score">
                                          <div className="csc-pos--top">B</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            GP
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 pl-4 bx--col-md-3">
                                      <div className="d-flex">
                                        <div className=" csc-th--block css-traits-score">
                                          <div className="csc-pos--top">A</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            PW
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className=" text-center">
                                    <div className="h5 text-black mb-0">69</div>
                                    <strong className="">PSC Points</strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">B</div>
                                    <strong className="">GP</strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">A</div>
                                    <strong className="">PW</strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">B3</div>
                                    <strong className="">HMTL</strong>
                                  </div> */}
                                  </div>
                                </Fragment>
                              ) : null}
                              {idx === 2 ? (
                                <Fragment>
                                  <div className="bx--row">
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                      <div className="d-flex">
                                        <div className=" csc-th--block css-traits-score">
                                          <div className="csc-pos--top">69</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            PSC Points
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0 pl-4">
                                      <div className="d-flex">
                                        <div className=" csc-th--block css-traits-score">
                                          <div className="csc-pos--top">B</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            GP
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                      <div className="d-flex">
                                        <div className=" csc-th--block css-traits-score">
                                          <div className="csc-pos--top">A</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            PW
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0 pl-4">
                                      <div className="d-flex">
                                        <div className=" csc-th--block css-traits-score">
                                          <div className="csc-pos--top">B</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            HMTL
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                    {/* <div className="d-flex ml-2">
                                  <div className=" text-center">
                                    <div className="h6 text-black mb-1">
                                      4.9 / 5.0
                                    </div>
                                    <strong className="">CAP</strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">-</div>
                                    <strong className="">GP</strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">-</div>
                                    <strong className="">PW</strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">D</div>
                                    <strong className="">MTL</strong>
                                  </div>
                                </div> */}
                                  </div>
                                </Fragment>
                              ) : null}
                              {idx === 3 ? (
                                <Fragment>
                                  <div className="bx--row">
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-3 pl-4">
                                      <div className="d-flex">
                                        <div className=" csc-th--block css-traits-score">
                                          <div className="csc-pos--top">42</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6 css-traits-text-c">
                                            IB Score
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="d-flex ml-2">
                                  <div className=" text-center">
                                    <div className="h6 text-black mb-1">42</div>
                                    <strong className="">IB Score </strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">-</div>
                                    <strong className="">GP</strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">-</div>
                                    <strong className="">PW</strong>
                                  </div>
                                  <div className=" text-center ml-2">
                                    <div className="h5 text-black mb-0">6</div>
                                    <strong className="">CB</strong>
                                  </div>
                                </div> */}
                                </Fragment>
                              ) : null}
                              <div className="mt-3 mb-3 ml-2">
                                <div className="mt-2">
                                  <h6 className="css-traits-text-c f-w-b">
                                    Preferences
                                  </h6>
                                </div>
                                <div className="mt-2 bx--row">
                                  <div className="bx--col-lg-4">
                                    <div className="f-w-b css-default-text-color">
                                      Career
                                    </div>
                                    {preferences &&
                                      Array.isArray(preferences) &&
                                      preferences.length > 0 &&
                                      preferences.map((res, index) => (
                                        <div className="mt-1 css-traits-text-c">
                                          {res}{' '}
                                        </div>
                                      ))}
                                  </div>
                                  <div className="bx--col-lg-3">
                                    <div className="f-w-b css-default-text-color">
                                      Course
                                    </div>
                                    {coursePref &&
                                      Array.isArray(coursePref) &&
                                      coursePref.length > 0 &&
                                      coursePref.map((res, index) => (
                                        <div className="mt-1 css-traits-text-c">
                                          {res}{' '}
                                        </div>
                                      ))}
                                  </div>
                                  <div className="bx--col-lg-5 pl-0 pr-0">
                                    <div className="f-w-b css-default-text-color">
                                      University
                                    </div>
                                    {countryPref &&
                                      Array.isArray(countryPref) &&
                                      countryPref.length > 0 &&
                                      countryPref.map((res, index) => (
                                        <div className="mt-1 css-traits-text-c">
                                          {res}{' '}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 ml-lg-0 ml-xl-0 ml-3 pl-2">
                              <h6>Psychometric Test Results</h6>
                              <div className="mb-3">
                                <div className="bx--row">
                                  <div className="mt-2 bx--col-lg-6 bx--col-md-3">
                                    <div className="d-flex">
                                      <div
                                        className={`border-radius-50 csc-th--block ${wagColor}`}>
                                        <div className="csc-pos--top">
                                          {wag}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Critical Thinking{' '}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0">
                                    <div className="d-flex">
                                      <div
                                        className={`border-radius-50 csc-th--block ${anraColor}`}>
                                        <div className="csc-pos--top">
                                          {anra}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Numerical Reasoning
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <h6>GBA Profile Results</h6>
                              <div className="">
                                <div className="bx--row">
                                  <div className="mt-2 bx--col-lg-6 bx--col-md-3">
                                    <div className="d-flex">
                                      <div className="border-radius-50 csc-th--block csc-th--h">
                                        <div className="csc-pos--top">
                                          {idx < 1 ? performance : 90}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Performance Based{' '}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0">
                                    <div className="d-flex">
                                      <div
                                        className={`border-radius-50 csc-th--block ${
                                          idx < 1 ? 'csc-th--h' : ' csc-th--l'
                                        }`}>
                                        <div className="csc-pos--top">
                                          {idx < 1 ? future : 65}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Future Driven
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 bx--col-lg-6 bx--col-md-3">
                                    <div className="d-flex">
                                      <div className="border-radius-50 csc-th--block csc-th--l">
                                        <div className="csc-pos--top">
                                          {idx < 1 ? award : 59}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Award
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0">
                                    <div className="d-flex">
                                      <div className="border-radius-50 csc-th--block csc-th--h">
                                        <div className="csc-pos--top">
                                          {idx < 1 ? 78 : 71}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Average
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h6>Psychological Interview Rating</h6>

                                <div className="">
                                  <div className="bx--row">
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-3">
                                      <div className="d-flex">
                                        <div className="border-radius-50 csc-th--block csc-th--h">
                                          <div className="csc-pos--top">5</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6">
                                            Leadership Qualities{' '}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0">
                                      <div className="d-flex">
                                        <div className="border-radius-50 csc-th--block csc-th--h">
                                          <div className="csc-pos--top">4</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6">
                                            Intellectual Qualities
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-3">
                                      <div className="d-flex">
                                        <div className="border-radius-50 csc-th--block csc-th--l">
                                          <div className="csc-pos--top">3</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6">
                                            Resilience
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-4 ml-3 ml-lg-0">
                                      <div className="d-flex">
                                        <div className="border-radius-50 csc-th--block csc-th--vl">
                                          <div className="csc-pos--top">2</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6">
                                            Character and Values
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-2 bx--col-lg-6 bx--col-md-3">
                                      <div className="d-flex">
                                        <div className="border-radius-50 csc-th--block csc-th--vl">
                                          <div className="csc-pos--top">1</div>
                                        </div>
                                        <div className="">
                                          <div className="csc-pos--top ml-1 h6">
                                            Interpersonal Skills
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="bx--row align-items-center">
                                  <div className="bx--col-lg-6">
                                    <div className="border-radius-50 csc-th--block csc-th--h">
                                      <div className="csc-pos--top">{5}</div>
                                    </div>
                                    <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                      Leadership Qualities
                                    </div>
                                  </div>
                                  <div className="bx--col-lg-6">
                                    <div className="border-radius-50 csc-th--block csc-th--h">
                                      <div className="csc-pos--top">{4}</div>
                                    </div>
                                    <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                      Intellectual Qualities
                                    </div>
                                  </div>
                                </div>
                                <div className="bx--row align-items-center">
                                  <div className="bx--col-lg-6">
                                    <div className="d-flex align-items-center">
                                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                        Resilience
                                      </div>
                                      <div className="border-radius-50 csc-th--block csc-th--l">
                                        <div className="csc-pos--top">{3}</div>
                                      </div>
                                      <div className="ml-2">(Moderate)</div>
                                    </div>
                                  </div>
                                  <div className="bx--col-lg-6">
                                    <div className="d-flex align-items-center">
                                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                        Character and Values
                                      </div>
                                      <div className="border-radius-50 csc-th--block csc-th--l">
                                        <div className="csc-pos--top">{2}</div>
                                      </div>
                                      <div className="ml-2">(Moderate)</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bx--row align-items-center">
                                  <div className="bx--col-lg-6">
                                    <div className="d-flex align-items-center">
                                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                        Interpersonal Skills
                                      </div>
                                      <div className="border-radius-50 csc-th--block csc-th--vl">
                                        <div className="csc-pos--top">{1}</div>
                                      </div>
                                      <div className="ml-2">(Very Low)</div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
            headers={[
              { key: 'header_0', header: 'Candidate Info', className: 'w-25' },
              { key: 'header_1', header: 'Summary' },
            ]}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardInterviewMock1;
