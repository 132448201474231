import {
  REQUEST_GET_AUDIT_LOGS,
  SUCCESS_GET_AUDIT_LOGS,
  ERROR_GET_AUDIT_LOGS,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  auditLogsData: {},
};

const AuditLogs = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_AUDIT_LOGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_AUDIT_LOGS:
      return {
        ...state,
        auditLogsData: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_AUDIT_LOGS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default AuditLogs;
