import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//Api Actions
import {
  createVideoRatingCriteria,
  getVideoInterviewratingCriteria,
  upsertVideoRatingCriteria,
  assignCandidateToCSCUser,
  getVideoInterviewDetails,
} from '../../actions/CandidateInfo';
import { getCandidateListData } from '../../actions/CandidateList';
import { getVIPanelUsersList } from '../../actions/AdminSection';
import { REQUEST_GET_CANDIDATE_INFO } from '../../actions/actionTypes';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import {
  RadioButton,
  TextArea,
  Button,
  InlineNotification,
  Modal,
  Select,
  SelectItem,
} from 'carbon-components-react';
import NoContentBox from '../../components/common/NoContentBox';
import { AzureMP } from 'react-azure-mp';

class CandVidIntOldFeedbackFormat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clarityYes: null,
      clarityKiv: null,
      clarityNo: null,
      clarity: null,
      engaging: null,
      engagingYes: null,
      engagingNo: null,
      engagingKiv: null,
      passionToServe1: null,
      passionToServe1Yes: null,
      passionToServe1No: null,
      passionToServe1Kiv: null,
      passionToServe2: null,
      passionToServe2Yes: null,
      passionToServe2No: null,
      passionToServe2Kiv: null,
      isValidComment: false,
      isOpenAssignToCscModal: false,
    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  renderOnMount = () => {
    const { appId, isCscRatingsInOldFormat } = this.props || {};

    this.setState(
      {
        showLoader: true,
      },
      () => {
        this.props
          .getVideoInterviewratingCriteria(appId, isCscRatingsInOldFormat)
          .then((res) => {
            if (res && !res.error) {
              const { viCriteriaInfo } = this.props;
              const {
                clarity,
                engaging,
                passionToServe1,
                passionToServe2,
                comments,
              } = viCriteriaInfo || {};
              this.setState(
                {
                  clarity: clarity && Number(clarity),
                  engaging: engaging && Number(engaging),
                  passionToServe1: passionToServe1 && Number(passionToServe1),
                  passionToServe2: passionToServe2 && Number(passionToServe2),
                  comments,
                  showLoader: false,
                },
                () => {
                  this.handleGetApiData();
                }
              );
            } else {
              this.setState({
                showLoader: false,
              });
            }
          })
          .catch((e) => {
            console.log('Error', e);
            this.setState({
              errorNotif: true,
              showLoader: false,
            });
          });
      }
    );
  };

  handleGetApiData = () => {
    const data = {
      activePage: 1,
      itemsPerPage: 1000,
    };
    this.props.getVIPanelUsersList(data);
  };

  handleGetVIRatingCriteria = () => {
    const { appId, isCscRatingsInOldFormat } = this.props || {};
    this.props.getVideoInterviewratingCriteria(appId, isCscRatingsInOldFormat);
  };

  handleChangeRadioBtn = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitVICriteria = () => {
    const { clarity, engaging, passionToServe1, passionToServe2, comments } =
      this.state;
    const { appId, viCriteriaInfo, isCscRatingsInOldFormat } = this.props;
    const { videoInterviewCriteriaId } = viCriteriaInfo || {};
    this.setState(
      {
        // isValidComment: comments ? false : true,
        isValidTraits: this.handleValidateTraits(),
      },
      () => {
        const { isValidComment, isValidTraits } = this.state;
        if (!isValidTraits) {
          const data = {
            clarity,
            engaging,
            passionToServe1,
            passionToServe2,
            comments,
            appId,
            isCscRatingsInOldFormat,
          };
          if (!videoInterviewCriteriaId) {
            this.props
              .createVideoRatingCriteria(data)
              .then((res) => {
                this.handleApiRes(res);
              })
              .catch((e) => {
                console.log('Error', e);
                this.setState({
                  errorNotif: true,
                });
              });
          } else {
            this.props
              .upsertVideoRatingCriteria(data, videoInterviewCriteriaId)
              .then((res) => {
                this.handleApiRes(res);
              })
              .catch((e) => {
                console.log('Error', e);
                this.setState({
                  errorNotif: true,
                });
              });
          }
        }
      }
    );
  };

  handleApiRes = (res) => {
    if (res && !res.error) {
      this.setState({
        successNotif: true,
        notifMsg: "The rating criteria's are updated successfully",
      });
      this.handleGetVIRatingCriteria();
    } else {
      this.setState({
        errorNotif: true,
      });
    }
  };

  handleValidateTraits = () => {
    const { clarity, engaging, passionToServe1, passionToServe2 } = this.state;
    let isValidTraits = false;
    if (
      clarity === null ||
      clarity === undefined ||
      engaging === null ||
      engaging === undefined ||
      passionToServe1 === null ||
      passionToServe1 === undefined ||
      passionToServe2 === null ||
      passionToServe2 === undefined
    ) {
      isValidTraits = true;
    } else {
      isValidTraits = false;
    }
    return isValidTraits;
  };

  //   activeOpenAssignCSCModal = () => {
  //     this.setState({
  //       isOpenAssignToCscModal: true,
  //     });
  //   };

  //   dismissOpenAssignCSCModal = () => {
  //     this.setState({
  //       isOpenAssignToCscModal: false,
  //     });
  //   };

  //   handleSubmitAssignUsers = () => {
  //     const { appId, isCscRatingsInOldFormat } = this.props;
  //     const data = {
  //       appId,
  //       isCscRatingsInOldFormat,
  //     };
  //     this.props
  //       .assignCandidateToCSCUser(data)
  //       .then((res) => {
  //         if (res && !res.error) {
  //           this.dismissOpenAssignCSCModal();
  //           this.setState(
  //             {
  //               successNotif: true,
  //               notifMsg: 'The user has been assigned successfully',
  //               errorNotif: false,
  //             },
  //             () => {
  //               this.handleGetCandidateInfoAPI();
  //             }
  //           );
  //         } else {
  //           this.setState({
  //             errorNotif: true,
  //           });
  //         }
  //       })
  //       .catch((e) => {
  //         console.log('Error', e);
  //         this.setState({
  //           errorNotif: true,
  //         });
  //       });
  //   };

  //   handleGetCandidateInfoAPI = () => {
  //     const { match, isCscRatingsInOldFormat } = this.props;
  //     const { params } = match || {};
  //     const { appId, jobId } = params || {};
  //     const data = {
  //       batchId: jobId,
  //       activePage: 1,
  //       itemsPerPage: 1000,
  //       searchKey: null,
  //       isCscRatingsInOldFormat
  //     };
  //     this.props.getCandidateListData(data, appId, REQUEST_GET_CANDIDATE_INFO);
  //   };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  render() {
    const {
      viCriteriaInfo,
      panelMembers,
      user,
      loading,
      videoInterviewRes,
      finalVideoIntRes,
    } = this.props || {};

    console.log('videoInterviewRes ', videoInterviewRes);
    console.log('finalVideoIntRes ', finalVideoIntRes);

    const { showLoader } = this.state || {};
    const { videoInterviewCriteriaId, overallRating } = viCriteriaInfo || {};
    const { userId: propUserId, roleId } = user || {};
    let panelUsers = [];
    if (
      panelMembers &&
      Array.isArray(panelMembers) &&
      panelMembers.length > 0
    ) {
      panelUsers = panelMembers.map(
        (res) => res && res.userId && Number(res.userId)
      );
    }
    const {
      clarityYes,
      clarityNo,
      clarityKiv,
      clarity,
      engaging,
      engagingNo,
      engagingYes,
      engagingKiv,
      passionToServe1,
      passionToServe1Yes,
      passionToServe1No,
      passionToServe1Kiv,
      passionToServe2,
      passionToServe2Yes,
      passionToServe2No,
      passionToServe2Kiv,
      isValidComment,
      comments,
      successNotif,
      errorNotif = false,
      isValidTraits,
      notifMsg,
      assignedUserId,
      isValidAssignedUserId,
      isOpenAssignToCscModal,
    } = this.state;
    return (
      <div>
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />

        {/* {assigned === false && (roleId == 1 || roleId == 6) && (
          <div className="text-center">
            <Button onClick={this.handleSubmitAssignUsers}>
              Assign To CSC
            </Button>
          </div>
        )} */}

        {finalVideoIntRes &&
          Array.isArray(finalVideoIntRes) &&
          finalVideoIntRes.length > 0 &&
          (panelUsers.includes(propUserId && Number(propUserId)) ||
            roleId > 0) && (
            <Fragment>
              <hr />
              {isValidTraits === true && (
                <InlineNotification
                  lowContrast
                  title="Please select valid traits"
                  iconDescription="describes the close button"
                  kind="error"
                  onCloseButtonClick={() => {
                    this.setState({ isValidTraits: false });
                  }}
                />
              )}
              <div className="h6 mt-2 f-w-b mb-2">
                Candidate Video Presentation Evaluation (Comments by CSC only,
                where applicable)
              </div>
              <div>
                <h6 className="mb-2">COMMUNICATION SKILLS</h6>
                <div className="mb-2">
                  1. <span style={{ fontWeight: 'bold' }}>Clarity</span>:
                  Presents views in a well-organised and focused manner; clear
                  and comprehensible
                </div>
                <div className="d-flex mt-1 mb-2">
                  <div className="ml-2">
                    <RadioButton
                      id="clarity-yes"
                      value={clarityYes}
                      name="clarity"
                      labelText="Yes"
                      // disabled={roleId !== 1 && roleId !== 3}
                      disabled={true}
                      onChange={() => {
                        this.handleChangeRadioBtn('clarity', 1);
                      }}
                      checked={clarity === 1}
                    />
                  </div>
                  <div className="ml-2">
                    <RadioButton
                      id="clarity-kiv"
                      value={clarityKiv}
                      name="clarity"
                      labelText="KIV"
                      disabled={true}
                      onChange={() => {
                        this.handleChangeRadioBtn('clarity', 0);
                      }}
                      checked={clarity === 0}
                    />
                  </div>
                  <div className="ml-2">
                    <RadioButton
                      id="clarity-no"
                      value={clarityNo}
                      name="clarity"
                      disabled={true}
                      labelText="No"
                      onChange={() => {
                        this.handleChangeRadioBtn('clarity', -1);
                      }}
                      checked={clarity === -1}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  2. <span style={{ fontWeight: 'bold' }}>Engaging</span>:
                  Presents views in a confident, compelling manner; Displays
                  appropriate non-verbal behaviors to engage listener
                </div>
                <div className="d-flex mb-2">
                  <div className="ml-2">
                    <RadioButton
                      id="engaging-yes"
                      value={engagingYes}
                      name="engaging"
                      labelText="Yes"
                      disabled={true}
                      onChange={() => {
                        this.handleChangeRadioBtn('engaging', 1);
                      }}
                      checked={engaging === 1}
                    />
                  </div>
                  <div className="ml-2">
                    <RadioButton
                      id="engaging-kiv"
                      value={engagingKiv}
                      name="engaging"
                      disabled={true}
                      labelText="KIV"
                      onChange={() => {
                        this.handleChangeRadioBtn('engaging', 0);
                      }}
                      checked={engaging === 0}
                    />
                  </div>
                  <div className="ml-2">
                    <RadioButton
                      id="engaging-no"
                      value={engagingNo}
                      name="engaging"
                      disabled={true}
                      labelText="No"
                      onChange={() => {
                        this.handleChangeRadioBtn('engaging', -1);
                      }}
                      checked={engaging === -1}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <h6 className="mb-2">CHARACTER AND VALUES</h6>
                <div className="mb-2">
                  3.{' '}
                  <span style={{ fontWeight: 'bold' }}>Passion to serve</span>:
                  Committed to serving the Public Service or nation
                </div>
                <div className="d-flex mb-2">
                  <div className="ml-2">
                    <RadioButton
                      id="passionToServe1-yes"
                      value={passionToServe1Yes}
                      name="passionToServe1"
                      disabled={true}
                      labelText="Yes"
                      onChange={() => {
                        this.handleChangeRadioBtn('passionToServe1', 1);
                      }}
                      checked={passionToServe1 === 1}
                    />
                  </div>
                  <div className="ml-2">
                    <RadioButton
                      id="passionToServe1-kiv"
                      value={passionToServe1Kiv}
                      name="passionToServe1"
                      labelText="KIV"
                      disabled={true}
                      onChange={() => {
                        this.handleChangeRadioBtn('passionToServe1', 0);
                      }}
                      checked={passionToServe1 === 0}
                    />
                  </div>
                  <div className="ml-2">
                    <RadioButton
                      id="passionToServe1-no"
                      value={passionToServe1No}
                      name="passionToServe1"
                      labelText="No"
                      disabled={true}
                      onChange={() => {
                        this.handleChangeRadioBtn('passionToServe1', -1);
                      }}
                      checked={passionToServe1 === -1}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  4.{' '}
                  <span style={{ fontWeight: 'bold' }}>Passion to serve</span>:
                  Committed to creating impact with the community or improve the
                  lives of others through career in the public or private
                  sector; Dedication to helping others
                </div>
                <div className="d-flex mb-2">
                  <div className="ml-2">
                    <RadioButton
                      id="passionToServe2-yes"
                      value={passionToServe2Yes}
                      name="passionToServe2"
                      labelText="Yes"
                      disabled={true}
                      onChange={() => {
                        this.handleChangeRadioBtn('passionToServe2', 1);
                      }}
                      checked={passionToServe2 === 1}
                    />
                  </div>
                  <div className="ml-2">
                    <RadioButton
                      id="passionToServe2-kiv"
                      value={passionToServe2Kiv}
                      name="passionToServe2"
                      labelText="KIV"
                      disabled={true}
                      onChange={() => {
                        this.handleChangeRadioBtn('passionToServe2', 0);
                      }}
                      checked={passionToServe2 === 0}
                    />
                  </div>
                  <div className="ml-2">
                    <RadioButton
                      id="passionToServe2-no"
                      value={passionToServe2No}
                      name="passionToServe2"
                      labelText="No"
                      disabled={true}
                      onChange={() => {
                        this.handleChangeRadioBtn('passionToServe2', -1);
                      }}
                      checked={passionToServe2 === -1}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="font-weight-bold mb-2">Comments</div>
              <div clarity="mt-2">
                <TextArea
                  name="comments"
                  id="comments"
                  value={comments}
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  labelText=""
                  hideLabel
                  disabled={true}
                  placeholder="Please enter the comments"
                  invalid={isValidComment}
                  invalidText="Please enter valid comments"
                />
              </div>

              <div className="mt-2">
                {overallRating !== null && overallRating !== undefined && (
                  <div>
                    <span className="font-weight-bold mb-2">
                      Overall Rating:{' '}
                    </span>
                    {overallRating == 1 ? (
                      <span className="font-weight-bold">Yes</span>
                    ) : null}
                    {overallRating == 0 ? (
                      <span className="font-weight-bold">KIV</span>
                    ) : null}
                    {overallRating == -1 ? (
                      <span className="font-weight-bold">No</span>
                    ) : null}
                  </div>
                )}
              </div>
              {roleId != 6 && roleId != 7 && (
                <div className="text-center mt-3">
                  <Button
                    kind={videoInterviewCriteriaId ? 'tertiary' : 'primary'}
                    size="small"
                    onClick={this.handleSubmitVICriteria}
                    disabled={true}>
                    Submit Evaluation
                  </Button>
                </div>
              )}
            </Fragment>
          )}
        {/* {(!finalVideoIntRes ||
          (finalVideoIntRes &&
            Array.isArray(finalVideoIntRes) &&
            finalVideoIntRes.length <= 0)) && (
          <NoContentBox
            hideTile
            message="There are no video interviews for the candidate."
          />
        )} */}
        {/* {isOpenAssignToCscModal && (
          <Modal
            open={isOpenAssignToCscModal}
            modalHeading="Assign TO CSC Users"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissOpenAssignCSCModal}
            onRequestSubmit={this.handleSubmitAssignUsers}>
            <div className="d-flex">
              <div className="w-100">
                <Select
                  name="assignedUserId"
                  id="assignedUserId"
                  onChange={this.handleChange}
                  value={assignedUserId}
                  className="w-100 xpa-width"
                  invalid={isValidAssignedUserId}
                  invalidText="Please select valid user"
                  labelText="Select User">
                  <SelectItem text="Select" key={0} />
                  {panelMembers &&
                    Array.isArray(panelMembers) &&
                    panelMembers.length > 0 &&
                    panelMembers.map((data) => {
                      const {
                        userinfo,
                        panelId: propPanelId,
                        percentage,
                        userId,
                      } = data;
                      const { firstname, lastname } = userinfo || {};
                      return (
                        <SelectItem
                          text={`${firstname} ${lastname} (${percentage})`}
                          key={propPanelId}
                          id={propPanelId}
                          value={userId}
                        />
                      );
                    })}
                </Select>
              </div>
            </div>
          </Modal>
        )} */}
        <JDProcessOverlay
          show={loading || showLoader}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viCriteriaInfo: state.CandidateInfo.viCriteriaInfo,
  videoInterviewRes: state.CandidateInfo.videoInterviewRes,
  panelMembers: state.AdminSection.panelMembers,
  assignedCandidateList: state.CandidateInfo.assignedCandidateList,
  user: state.x0paDuser.user,
  loading: state.CandidateInfo.loading,
});

const mapDispatchToProps = {
  createVideoRatingCriteria,
  getVideoInterviewratingCriteria,
  upsertVideoRatingCriteria,
  getVIPanelUsersList,
  assignCandidateToCSCUser,
  getCandidateListData,
  getVideoInterviewDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandVidIntOldFeedbackFormat);
