import {
  REQUEST_GET_CMS_MEETINGS,
  SUCCESS_GET_CMS_MEETINGS,
  ERROR_GET_CMS_MEETINGS,
  REQUEST_GET_CMS_MEETING_BY_ID,
  SUCCESS_GET_CMS_MEETING_BY_ID,
  ERROR_GET_CMS_MEETING_BY_ID,
  REQUEST_CREATE_CMS_MEETING,
  SUCCESS_CREATE_CMS_MEETING,
  ERROR_CREATE_CMS_MEETING,
  REQUEST_DELETE_CMS_MEETING_BY_ID,
  SUCCESS_DELETE_CMS_MEETING_BY_ID,
  ERROR_DELETE_CMS_MEETING_BY_ID,
  REQUEST_UPLOAD_CMS_MEETINGS_DATA,
  SUCCESS_UPLOAD_CMS_MEETINGS_DATA,
  ERROR_UPLOAD_CMS_MEETINGS_DATA,
  REQUEST_GET_COMM_MEM_BY_ID,
  SUCCESS_GET_COMM_MEM_BY_ID,
  ERROR_GET_COMM_MEM_BY_ID,
  REQUEST_DOWNLOAD_MEETING_TEMP,
  SUCCESS_DOWNLOAD_MEETING_TEMP,
  ERROR_DOWNLOAD_MEETING_TEMP,
  REQUEST_EXPORT_CMS_MEETINGS,
  SUCCESS_EXPORT_CMS_MEETINGS,
  ERROR_EXPORT_CMS_MEETINGS,
  REQUEST_BULK_DELETE_CMS_MEETING_BY_IDS,
  SUCCESS_BULK_DELETE_CMS_MEETING_BY_IDS,
  ERROR_BULK_DELETE_CMS_MEETING_BY_IDS,
  REQUEST_GET_MEETINGS_REPORT,
  SUCCESS_GET_MEETINGS_REPORT,
  ERROR_GET_MEETINGS_REPORT,
} from '../actions/actionTypes';

const initialState = {
  cmsMeetings: [],
  count: 0,
  loading: false,
  error: null,
};

const CMS = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_CMS_MEETINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CMS_MEETINGS:
      return {
        ...state,
        loading: false,
        cmsMeetings: payload,
        error: null,
      };
    case ERROR_GET_CMS_MEETINGS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CMS_MEETING_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CMS_MEETING_BY_ID:
      return {
        ...state,
        loading: false,
        cmsMeetingInfo: payload,
      };
    case ERROR_GET_CMS_MEETING_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_CMS_MEETING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_CMS_MEETING:
      return {
        ...state,
        loading: false,
      };
    case ERROR_CREATE_CMS_MEETING:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_CMS_MEETING_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_CMS_MEETING_BY_ID:
      return {
        ...state,
        loading: false,
      };
    case ERROR_DELETE_CMS_MEETING_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_CMS_MEETINGS_DATA: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_UPLOAD_CMS_MEETINGS_DATA: {
      return {
        ...state,
        loading: false,
      };
    }
    case ERROR_UPLOAD_CMS_MEETINGS_DATA: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_GET_COMM_MEM_BY_ID: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_GET_COMM_MEM_BY_ID: {
      return {
        ...state,
        loading: false,
      };
    }
    case ERROR_GET_COMM_MEM_BY_ID: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    case REQUEST_DOWNLOAD_MEETING_TEMP: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_DOWNLOAD_MEETING_TEMP: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_DOWNLOAD_MEETING_TEMP: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_EXPORT_CMS_MEETINGS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_EXPORT_CMS_MEETINGS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_EXPORT_CMS_MEETINGS: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_BULK_DELETE_CMS_MEETING_BY_IDS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_BULK_DELETE_CMS_MEETING_BY_IDS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_BULK_DELETE_CMS_MEETING_BY_IDS: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_GET_MEETINGS_REPORT: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_GET_MEETINGS_REPORT: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_GET_MEETINGS_REPORT: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default:
      return state;
  }
};

export default CMS;
