import React from 'react';

const AgendaMetrics = (props) => {
  const { selectionBoardUsers } = props || {};
  const attendingMembers = [];
  const notAttendingMembers = [];
  const secInAttendanceMembers = [];
  if (
    selectionBoardUsers &&
    Array.isArray(selectionBoardUsers) &&
    selectionBoardUsers.length > 0
  ) {
    selectionBoardUsers.forEach((member) => {
      const { selectionboard, action } = member || {};
      const { userinfo, aliasName, designation } = selectionboard || {};
      const { roleId: propRoleId, firstname, lastname, email } = userinfo || {};
      let finalFullName = '';
      const finalAliasName =
        (aliasName && aliasName.toString() && aliasName.toString().trim()) ||
        '';
      if (finalAliasName && finalAliasName.length > 0) {
        finalFullName = finalAliasName;
      } else {
        finalFullName = `${firstname} ${lastname}`;
      }
      const interviewObj = {
        fullName:
          propRoleId === 10 ? `${firstname} ${lastname}` : finalFullName,
        designation,
      };
      if ((propRoleId == 6 || propRoleId == 2) && action === true) {
        secInAttendanceMembers.push(interviewObj);
      } else if (
        propRoleId == 7 ||
        propRoleId == 8 ||
        propRoleId == 9 ||
        propRoleId == 10
      ) {
        if (
          action === true &&
          email !== 'tan_ser_kiat@psc.gov.sg' &&
          email !== 'juliuskoh@gmail.com'
        ) {
          attendingMembers.push(interviewObj);
        } else if (
          (action === false || action === null) &&
          propRoleId != 10 &&
          email !== 'tan_ser_kiat@psc.gov.sg' &&
          email !== 'juliuskoh@gmail.com'
        ) {
          notAttendingMembers.push(interviewObj);
        }
      }
    });
  }
  return (
    <div>
      <div className="bx--row">
        <div className="bx--col-lg-2 bx--col-md-2 bx--col-xs-12 bx--col-sm-12">
          <h5 className="mt-2 mb-2">Attending :</h5>
        </div>
        <div className="bx--col-lg-7 bx--col-md-6 bx--col-xs-12 bx--col-sm-12">
          <div className="mt-2 mb-2">
            {attendingMembers &&
            Array.isArray(attendingMembers) &&
            attendingMembers.length > 0 ? (
              attendingMembers.map((memb) => {
                const { fullName, designation } = memb || {};
                return (
                  <div className="bx--row mt-2 pt-1 mb-3">
                    <div className="bx--col-lg-5 css-text-color text-capitalize bx--col-md-4">
                      {fullName}
                    </div>
                    <div className="bx--col-lg-5 css-text-color text-capitalize bx--col-md-4">
                      {designation}
                    </div>
                  </div>
                );
              })
            ) : (
              <div class="mt-2 h6">
                There are no details for your current selection
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="bx--row">
        <div className="bx--col-lg-2 bx--col-md-2 bx--col-xs-12 bx--col-sm-12">
          <h5 className="mt-2 mb-2">Not Attending :</h5>
        </div>
        <div className="bx--col-lg-7 bx--col-md-6 bx--col-xs-12 bx--col-sm-12">
          <div className="mt-2 mb-2">
            {notAttendingMembers &&
            Array.isArray(notAttendingMembers) &&
            notAttendingMembers.length > 0 ? (
              notAttendingMembers.map((memb) => {
                const { fullName, designation } = memb || {};
                return (
                  <div className="bx--row mt-2 pt-1 mb-3">
                    <div className="bx--col-lg-5 css-text-color text-capitalize bx--col-md-4">
                      {fullName}
                    </div>
                    <div className="bx--col-lg-5 css-text-color text-capitalize bx--col-md-4">
                      {designation}
                    </div>
                  </div>
                );
              })
            ) : (
              <div class="mt-2 h6">
                There are no details for your current selection
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="bx--row">
        <div className="bx--col-lg-2 bx--col-md-2 bx--col-xs-12 bx--col-sm-12">
          <h5 className="mt-2 mb-2">Secretariat In Attendance :</h5>
        </div>
        <div className="bx--col-lg-7 bx--col-md-6 bx--col-xs-12 bx--col-sm-12">
          <div className="mt-2 mb-2">
            {secInAttendanceMembers &&
            Array.isArray(secInAttendanceMembers) &&
            secInAttendanceMembers.length > 0 ? (
              secInAttendanceMembers.map((memb) => {
                const { fullName, designation } = memb || {};
                return (
                  <div className="bx--row mt-2 pt-1 mb-3">
                    <div className="bx--col-lg-5 css-text-color text-capitalize bx--col-md-4">
                      {fullName}
                    </div>
                    <div className="bx--col-lg-5 css-text-color text-capitalize bx--col-md-4">
                      {designation}
                    </div>
                  </div>
                );
              })
            ) : (
              <div class="mt-2 h6">
                There are no details for your current selection
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgendaMetrics;
