import React, { Fragment } from 'react';

//Common Components
import GenericDataTable from '../common/GenericDataTable';
import NoContentBox from '../common/NoContentBox';

//Common Packages
import { Toggle } from 'carbon-components-react';

const PanelPscSelectionBoard = (props) => {
  const {
    data,
    otherActionsBtn,
    heading,
    handleOnSelectBoardMemberToggle,
    state,
    roleId,
  } = props || {};
  let filteredData = [];
  if (data && Array.isArray(data) && data.length > 0) {
    filteredData = data.filter((res) => {
      const { selectionboard } = res || {};
      const { userinfo } = selectionboard || {};
      const { email } = userinfo || {};
      if (
        email === 'tan_ser_kiat@psc.gov.sg' ||
        email === 'juliuskoh@gmail.com'
      ) {
        return false;
      }
      return true;
    });
  }
  return (
    <div className="mb-3">
      <h6 className="mb-3">{heading}</h6>
      <GenericDataTable
        className=""
        tbodyClass="candidate-background-color"
        otherActions={otherActionsBtn}
        headers={[
          {
            key: 'header_0',
            header: 'Interviewer',
            className: 'w-25',
          },
          {
            key: 'header_1',
            header: 'Designation',
            className: 'w-25',
          },
          {
            key: 'header_2',
            header: 'Attendance',
            className: 'w-25',
          },
        ]}
        rows={
          filteredData && Array.isArray(filteredData) && filteredData.length > 0
            ? filteredData.map((res, idx) => {
                const { selectionboard, assignedId, action, boardId } =
                  res || {};
                const { aliasName, designation, userinfo } =
                  selectionboard || {};
                const { firstname, lastname, roleId: propRoleId } =
                  userinfo || {};
                const { assignBoardMemData } = state || {};
                let currSelectedInterviewer = {};
                if (
                  assignBoardMemData &&
                  Array.isArray(assignBoardMemData) &&
                  assignBoardMemData.length > 0
                ) {
                  currSelectedInterviewer = assignBoardMemData.find((r) => {
                    const { assignedId: stAssignedId } = r || {};
                    if (stAssignedId === assignedId) {
                      return true;
                    } else {
                      return false;
                    }
                  });
                }
                const { action: stAction } = currSelectedInterviewer || {};
                let finalFullName = '';
                const finalAliasName =
                  (aliasName &&
                    aliasName.toString() &&
                    aliasName.toString().trim()) ||
                  '';
                if (finalAliasName && finalAliasName.length > 0) {
                  finalFullName = finalAliasName;
                } else {
                  finalFullName = `${firstname} ${lastname}`;
                }
                return {
                  isExpanded: true,
                  id: `row_${assignedId}`,
                  key: `row_${assignedId}`,
                  header_0: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Interviewer:
                      </div>
                      <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {(propRoleId === 10
                          ? `${firstname} ${lastname}`
                          : finalFullName) || '-'}
                      </div>
                    </div>
                  ),
                  header_1: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Designation:
                      </div>
                      <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {designation || '-'}
                      </div>
                    </div>
                  ),
                  header_2: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Attendance:
                      </div>
                      <div className="d-flex align-items-center bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {roleId == 7 ||
                        roleId == 8 ||
                        roleId == 9 ||
                        roleId == 10 ? (
                          <div>
                            {action === true ? (
                              <span className="bx--tag bx--tag--green text-uppercase">
                                Yes
                              </span>
                            ) : null}
                            {action === false || action === null ? (
                              <span className="bx--tag bx--tag--red text-uppercase">
                                No
                              </span>
                            ) : null}
                          </div>
                        ) : (
                          <Fragment>
                            <div>
                              <Toggle
                                toggled={stAction}
                                labelA="No"
                                id={`interviewer-${assignedId}`}
                                className="toggle-remv-margin"
                                labelText=""
                                onToggle={() =>
                                  handleOnSelectBoardMemberToggle(
                                    assignedId,
                                    stAction === null ||
                                      stAction === false ||
                                      stAction === undefined
                                      ? true
                                      : false,
                                    boardId
                                  )
                                }
                                labelB="Yes"
                              />
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  ),
                };
              })
            : []
        }
        selectable={false}
        searchable={false}
        onSearchInputChange={() => {}}
        expandable={false}
        batchActions={false}
      />
      {((data && Array.isArray(data) && data.length <= 0) || !data) && (
        <NoContentBox message="There are no members available for your current selection" />
      )}
    </div>
  );
};

export default PanelPscSelectionBoard;
