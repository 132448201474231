import {
  REQUEST_GET_SCORECARD_TEMPLATES,
  SUCCESS_GET_SCORECARD_TEMPLATES,
  ERROR_GET_SCORECARD_TEMPLATES,
  REQUEST_CREATE_SCORECARD_TEMPLATE,
  SUCCESS_CREATE_SCORECARD_TEMPLATE,
  ERROR_CREATE_SCORECARD_TEMPLATE,
  REQUEST_GET_SCORECARD_TEMPLATE_BY_ID,
  SUCCESS_GET_SCORECARD_TEMPLATE_BY_ID,
  ERROR_GET_SCORECARD_TEMPLATE_BY_ID,
  REQUEST_DELETE_SCORECARD_TEMPLATE_BY_ID,
  SUCCESS_DELETE_SCORECARD_TEMPLATE_BY_ID,
  ERROR_DELETE_SCORECARD_TEMPLATE_BY_ID,
  REQUEST_CREATE_SCORECARD_TEMPLATE_CONF_DATA,
  SUCCESS_CREATE_SCORECARD_TEMPLATE_CONF_DATA,
  ERROR_CREATE_SCORECARD_TEMPLATE_CONF_DATA,
  REQUEST_CREATE_RATING_CRITERIA_SCORECARD,
  SUCCESS_CREATE_RATING_CRITERIA_SCORECARD,
  ERROR_CREATE_RATING_CRITERIA_SCORECARD,
  REQUEST_DELETE_RATING_CRITERIA_SCORECARD,
  SUCCESS_DELETE_RATING_CRITERIA_SCORECARD,
  ERROR_DELETE_RATING_CRITERIA_SCORECARD,
  REQUEST_CREATE_APPLICANT_SCORECARD,
  SUCCESS_CREATE_APPLICANT_SCORECARD,
  ERROR_CREATE_APPLICANT_SCORECARD,
  REQUEST_GET_APPLICANT_SCORECARD_BY_ID,
  SUCCESS_GET_APPLICANT_SCORECARD_BY_ID,
  ERROR_GET_APPLICANT_SCORECARD_BY_ID,
} from '../actions/actionTypes';

const initialState = {
  scorecardtemplates: [],
  count: 0,
  loading: false,
  error: null,
};

const FeedbackTemplates = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_CREATE_SCORECARD_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_SCORECARD_TEMPLATE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_SCORECARD_TEMPLATE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_SCORECARD_TEMPLATES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_SCORECARD_TEMPLATES:
      return {
        ...state,
        scorecardtemplates: payload && payload.data,
        count: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_GET_SCORECARD_TEMPLATES:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_SCORECARD_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_SCORECARD_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_SCORECARD_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_DELETE_SCORECARD_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_SCORECARD_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_SCORECARD_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_SCORECARD_TEMPLATE_CONF_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_SCORECARD_TEMPLATE_CONF_DATA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_SCORECARD_TEMPLATE_CONF_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_RATING_CRITERIA_SCORECARD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_RATING_CRITERIA_SCORECARD:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_RATING_CRITERIA_SCORECARD:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_RATING_CRITERIA_SCORECARD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_RATING_CRITERIA_SCORECARD:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_RATING_CRITERIA_SCORECARD:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_APPLICANT_SCORECARD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_APPLICANT_SCORECARD:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_APPLICANT_SCORECARD:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_APPLICANT_SCORECARD_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_APPLICANT_SCORECARD_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_APPLICANT_SCORECARD_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default FeedbackTemplates;
