import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput,
  Modal,
  ToggleSmall,
  Tile,
  Button,
  TextArea,
} from 'carbon-components-react';
import GenericDataTable from '../../../components/common/GenericDataTable';

import {
  upsertCMSMeeting,
  getCMSMeetingById,
  getCommissionMemByRoleId,
} from '../../../actions/CMSMeetings';
import { withRouter } from 'react-router-dom';
import Dropdown from '../../../components/common/Dropdown';
import moment, { invalid } from 'moment';
import BackRow from '../../../components/common/BackRow';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import { update } from 'lodash';
import SuccErrNotification from '../../../components/private/SuccErrNotification';
import {
  timeperiodOptions,
  meetingTypeOptions,
  meetingStatusOptions,
  attendanceTypeOptions,
  attendanceStatusOptions,
  carParkingFeeOptions,
} from '../../../staticdata/CMSStaticData';
class CMSAddEditMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendEmailNotifToggle: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match || {};
    const { meetingId } = params || {};
    if (meetingId) {
      this.props
        .getCMSMeetingById({ meetingIds: [meetingId] })
        .then((res) => {
          if (res && !res.error) {
            const { list } = res || {};
            const meetingDetails = (list && list?.[0]) || {};
            const {
              meetingTitle,
              agenda,
              startDate,
              startTime,
              endTime,
              timePeriod,
              meetingType,
              purpose,
              internalRemarks,
              status,
              attendanceType,
              sendEmailNotification,
              Usermeetingmaps = [],
            } = meetingDetails || {};
            const commissionMemList =
              (Usermeetingmaps &&
                Array.isArray(Usermeetingmaps) &&
                Usermeetingmaps.map((itm) => {
                  const {
                    attendanceStatus,
                    remarks,
                    Selectionboard,
                    Userinfo,
                    carParkingFee,
                    attendanceTimePeriod,
                  } = itm || {};
                  const { aliasName } = Selectionboard || {};
                  const { userId, firstname, lastname } = Userinfo || {};
                  return {
                    userId,
                    aliasName,
                    firstname,
                    lastname,
                    attendanceStatus,
                    carParkingFee,
                    attendanceTimePeriod,
                    remarks,
                  };
                })) ||
              [];
            const updateStateObj = {};
            commissionMemList?.forEach((itm) => {
              const {
                userId,
                attendanceStatus,
                carParkingFee,
                attendanceTimePeriod,
                remarks,
              } = itm || {};
              updateStateObj[`selected-cmAttendanceStatus-${userId}`] = {
                label: attendanceStatus ?? 'Select',
                value: attendanceStatus,
              };
              updateStateObj[`selected-carParkingFee-${userId}`] = {
                label: carParkingFee ?? 'Select',
                value: carParkingFee == null ? null : '' + carParkingFee,
              };
              updateStateObj[`selected-attendanceTimePeriod-${userId}`] = {
                label: attendanceTimePeriod ?? 'Select',
                value: attendanceTimePeriod,
              };
              updateStateObj[`remarks-${userId}`] = remarks;
            });
            this.setState({
              title: meetingTitle,
              agenda,
              startDate: moment(startDate).toDate(),
              startTime,
              endTime,
              selectedTimePeriod: { label: timePeriod, value: timePeriod },
              selectedMeetingType: { label: meetingType, value: meetingType },
              purpose,
              internalRemarks,
              selectedMeetingStatus: { label: status, value: status },
              selectedAttendanceType: {
                label: attendanceType,
                value: attendanceType,
              },
              sendEmailNotifToggle: sendEmailNotification,
              commissionMemList,
              ...updateStateObj,
            });
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    } else {
      const noLimit = true;
      this.props
        .getCommissionMemByRoleId([7], noLimit)
        .then((res) => {
          if (res && !res.error) {
            const { list: commissionMemList } = res || {};
            const updateStateObj = {};
            commissionMemList?.forEach((itm) => {
              const { userId } = itm || {};
              updateStateObj[`selected-cmAttendanceStatus-${userId}`] = {
                label: 'Available',
                value: 'Available',
              };
            });
            this.setState({ commissionMemList, ...updateStateObj });
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  onDatePickerChange = (date, inputName) => {
    this.setState({ [`${inputName}`]: date });
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState(
      {
        [name]: metaValue,
      },
      () => {
        const { selectedMeetingStatus, commissionMemList, selectedTimePeriod } =
          this.state;
        if (
          name === 'selectedMeetingStatus' &&
          selectedMeetingStatus?.value === 'Cancelled'
        ) {
          const updateStateObj = {};
          commissionMemList?.forEach((itm) => {
            const { userId } = itm || {};
            updateStateObj[`selected-cmAttendanceStatus-${userId}`] = {
              label: 'Select',
              value: null,
            };
            updateStateObj[`selected-carParkingFee-${userId}`] = {
              label: 'Select',
              value: null,
            };
            updateStateObj[`selected-attendanceTimePeriod-${userId}`] = {
              label: 'Select',
              value: null,
            };
          });
          this.setState({ ...updateStateObj });
        } else if (name === 'selectedTimePeriod') {
          const { value } = selectedTimePeriod || {};
          const carParkingFeeObj =
            value === 'AM' || value === 'PM'
              ? { label: '1', value: '1' }
              : value === 'Full'
              ? { label: '2', value: '2' }
              : { label: '0', value: '0' };
          const updateStateObj = {};
          commissionMemList?.forEach((itm) => {
            const { userId } = itm || {};
            updateStateObj[`selected-carParkingFee-${userId}`] =
              carParkingFeeObj;
            updateStateObj[`selected-attendanceTimePeriod-${userId}`] =
              timeperiodOptions?.find((itm) => itm?.value === value);
          });
          this.setState({ ...updateStateObj });
        } else if (name?.includes('selected-cmAttendanceStatus')) {
          const currUserId = name?.toString()?.split('-')?.[2];
          if (
            ['Not Available', 'To Be Confirmed', null]?.includes(
              this.state[`selected-cmAttendanceStatus-${currUserId}`]?.value
            )
          ) {
            this.setState({
              [`selected-carParkingFee-${currUserId}`]: {
                label: 'Select',
                value: null,
              },
              [`selected-attendanceTimePeriod-${currUserId}`]: {
                label: 'Select',
                value: null,
              },
            });
          }
        }
      }
    );
  };

  handleToggleClick = (e, inputName) => {
    this.setState({ [`${inputName}`]: e });
  };

  isInputInValid = (input) => {
    if (!input || (input && !input?.toString()?.trim())) {
      return true;
    }
    return false;
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  handleGoBack = () => {
    const pathsObj = JSON.parse(localStorage.getItem('paths'));
    const { prevPath } = pathsObj || {};
    if (prevPath) {
      this.props.history.push(prevPath);
    }
  };

  handleAddEditMeeting = () => {
    const { match } = this.props;
    const { params } = match || {};
    const { meetingId } = params || {};
    const {
      title,
      agenda,
      purpose,
      internalRemarks,
      startDate,
      startTime,
      endTime,
      selectedTimePeriod,
      selectedMeetingType,
      selectedMeetingStatus,
      selectedAttendanceType,
      sendEmailNotifToggle,
      commissionMemList,
    } = this.state;

    const invalidObj = {};
    // invalidObj.isMeetingTitleInvalid = this.isInputInValid(title);
    invalidObj.isPurposeInvalid = this.isInputInValid(purpose);
    invalidObj.isStartDateInvalid = startDate == null;
    invalidObj.isStartTimeInvalid = startTime == null;
    invalidObj.isEndTimeInvalid = endTime == null;
    invalidObj.isStartEndTimeInvalid = startTime > endTime;
    invalidObj.isTimePeriodInvalid = selectedTimePeriod?.value == null;
    invalidObj.isMeetingTypeInvalid = selectedMeetingType?.value == null;
    invalidObj.isMeetingStatusInvalid = selectedMeetingStatus?.value == null;
    invalidObj.isAttendanceTypeInvalid = selectedAttendanceType?.value == null;
    // invalidObj.isEmailNotifToggInvalid = sendEmailNotifToggle == null;

    this.setState({ ...invalidObj }, () => {
      const areAllInputsValid = Object.values(invalidObj).every(
        (val) => val === false
      );
      if (areAllInputsValid) {
        const data = {
          // meetingTitle: title,
          agenda,
          startDate: moment(startDate).toDate(),
          startTime: startTime && new Date(startTime),
          endTime: endTime && new Date(endTime),
          timePeriod: selectedTimePeriod?.value,
          meetingType: selectedMeetingType?.value,
          purpose: purpose?.toString()?.trim(),
          internalRemarks,
          status: selectedMeetingStatus?.value,
          attendanceType: selectedAttendanceType?.value,
          sendEmailNotification: sendEmailNotifToggle,
          users: [],
        };

        if (
          commissionMemList &&
          Array.isArray(commissionMemList) &&
          commissionMemList.length > 0
        ) {
          data['users'] = commissionMemList.map((itm) => {
            const { userId } = itm || {};
            const { value: attendanceStatusVal } =
              this.state[`selected-cmAttendanceStatus-${userId}`];
            const { value: carParkingFeeVal } =
              this.state[`selected-carParkingFee-${userId}`];
            const { value: attendanceTimePeriodVal } =
              this.state[`selected-attendanceTimePeriod-${userId}`];
            return {
              userId,
              attendanceStatus: attendanceStatusVal,
              carParkingFee: carParkingFeeVal,
              attendanceTimePeriod: attendanceTimePeriodVal,
              remarks: this.state[`remarks-${userId}`],
            };
          });
        }

        if (meetingId) {
          data.meetingId = meetingId;
        }
        this.props
          .upsertCMSMeeting([data])
          .then((res) => {
            window.scrollTo(0, 0);
            if (res && !res.error) {
              const { inValidPayload = [] } = res || {};
              if (inValidPayload?.length > 0) {
                const { invalidMessages = [] } = inValidPayload?.[0] || {};

                this.setState({
                  errorNotif: true,
                  successNotif: false,
                  errMsg:
                    invalidMessages?.length > 0
                      ? invalidMessages.join(' ')
                      : 'An Error has ocurred while processing your request, Please try again later',
                });
              } else {
                this.setState(
                  {
                    successNotif: true,
                    errorNotif: false,
                    notifMsg: meetingId
                      ? 'Meeting details have been updated successfully.'
                      : 'Meeting has been created successfully.',
                  },
                  () => {
                    setTimeout(() => {
                      this.handleCloseNotifBtn();
                      this.handleGoBack();
                    }, 2000);
                  }
                );
              }
            }
          })
          .catch((error) => {
            window.scrollTo(0, 0);
            console.log('Error', error);
            this.setState({
              errorNotif: true,
              successNotif: false,
            });
          });
      }
    });
  };

  render() {
    const { match } = this.props;
    const { params } = match || {};
    const { meetingId } = params || {};
    const {
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      title,
      isMeetingTitleInvalid,
      isPurposeInvalid,
      startDate,
      isStartDateInvalid,
      isStartTimeInvalid,
      isEndTimeInvalid,
      isTimePeriodInvalid,
      isMeetingTypeInvalid,
      isMeetingStatusInvalid,
      isAttendanceTypeInvalid,
      isEmailNotifToggInvalid,
      isStartEndTimeInvalid,
      commissionMemList,
      selectedMeetingStatus,
    } = this.state;

    let isMeetingExpired = false;
    if (meetingId) {
      const finalStartTime =
        startDate && new Date(startDate).setHours(23, 59, 59, 0);
      isMeetingExpired = new Date().getTime() > finalStartTime;
    }

    const { user } = this.props;
    const { roleId } = user || {};

    let headersList = [
      {
        key: 'header_0',
        header: 'Commission Members',
      },
      {
        key: 'header_1',
        header: 'Attendance Status',
      },
    ];

    if (roleId == 6 || roleId == 11) {
      headersList.push(
        {
          key: 'header_2',
          header: 'Car Parking Count',
        },
        {
          key: 'header_3',
          header: 'Attendance Time Period',
        }
      );
    }

    headersList.push({
      key: 'header_4',
      header: 'Remarks',
    });

    // MeetingInfoInputs - all inputs excluding attendance status
    const disableMeetInfoInpByRoleId = roleId == 7 || roleId == 13;
    // all user inputs
    const disableAllInputsByRoleId = roleId == 2 || roleId == 12;
    const disableUserInput = isMeetingExpired || disableAllInputsByRoleId;

    // For Panel & PA
    let finalAttendanceStatusOpts = attendanceStatusOptions;
    let disableFinalSubmit = false;
    let showSendEmailNotifInput = true;

    if (roleId == 7 || roleId == 13) {
      // Hide Scheduled Option.
      finalAttendanceStatusOpts = attendanceStatusOptions?.filter(
        (itm) => itm?.value !== 'Scheduled'
      );

      // Disable submit btn if the meeting status is cancelled.
      disableFinalSubmit = selectedMeetingStatus?.value === 'Cancelled';
    }

    if (roleId == 7 || roleId == 12 || roleId == 13) {
      // Hide Send Email Notification Input
      showSendEmailNotifInput = false;
    }

    return (
      <div>
        <BackRow
          paths={[
            { id: 'csc', name: 'ARS', href: '/app/csc' },
            {
              id: 'create-meeting',
              name: `${meetingId ? 'Edit' : 'Create'} Meeting`,
              href: '',
            },
          ]}
        />
        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />
        <Tile>
          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Purpose <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <TextInput
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                name="purpose"
                id="purpose"
                value={this.state.purpose}
                onChange={this.handleChange}
                labelText=""
                className="text-capitalize"
                hideLabel
                type="text"
                placeholder="Purpose"
                invalid={isPurposeInvalid}
                invalidText="Please enter purpose"
              />
            </div>
          </div>
          {/* <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Meeting Title <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <TextInput
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                name="title"
                id="title"
                value={title}
                onChange={this.handleChange}
                labelText=""
                className="text-capitalize"
                hideLabel
                type="text"
                placeholder="Meeting Title"
                invalid={isMeetingTitleInvalid}
                invalidText="Please enter meeting title"
              />
            </div>
          </div> */}
          {/* <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">Agenda</div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <TextInput
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                name="agenda"
                id="agenda"
                value={this.state.agenda}
                onChange={this.handleChange}
                labelText=""
                className="text-capitalize"
                hideLabel
                type="text"
                placeholder="Agenda"
              />
            </div>
          </div> */}
          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Date <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <DatePicker
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                selected={this.state.startDate}
                name="startDate"
                id="startDate"
                onChange={(date) => this.onDatePickerChange(date, 'startDate')}
                dateFormat="dd/MM/yyyy"
                placeholderText="Meeting Date"
                className="css-h-d-input"
                minDate={new Date()}
                autoComplete="off"
              />
              {isStartDateInvalid && (
                <div className="text-danger mt-1">Please select valid date</div>
              )}
            </div>
          </div>
          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Start Time <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <DatePicker
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                selected={
                  this.state.startTime && new Date(this.state.startTime)
                }
                onChange={(date) => this.onDatePickerChange(date, 'startTime')}
                showTimeSelect
                showTimeSelectOnly
                timeFormat="h:mm a"
                timeIntervals={15}
                className="css-h-d-input"
                timeCaption="time"
                dateFormat="h:mm a"
                placeholderText="Start Time"
                autoComplete="off"
              />
              {isStartTimeInvalid && (
                <div className="text-danger mt-1">
                  Please select valid start time
                </div>
              )}
            </div>
          </div>
          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              End Time <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <DatePicker
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                selected={this.state.endTime && new Date(this.state.endTime)}
                onChange={(date) => this.onDatePickerChange(date, 'endTime')}
                showTimeSelect
                showTimeSelectOnly
                timeFormat="h:mm a"
                timeIntervals={15}
                className="css-h-d-input"
                timeCaption="time"
                dateFormat="h:mm a"
                placeholderText="End Time"
                autoComplete="off"
              />
              {isEndTimeInvalid ? (
                <div className="text-danger mt-1">
                  Please select valid end time
                </div>
              ) : isStartEndTimeInvalid ? (
                <div className="text-danger mt-1">
                  Start time cannot be greater than end time
                </div>
              ) : null}
            </div>
          </div>
          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Time Period <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <Dropdown
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                id="timePeriod"
                title=""
                custom
                mainClassName="bx--form-item mb-0"
                className="w-100"
                titleClass="mb-2"
                labelClass="pt-2"
                onInputChange={() => {}}
                options={timeperiodOptions?.filter(
                  (itm) => itm?.label !== 'Select'
                )}
                name="selectedTimePeriod"
                placeholder="Time Period"
                onChange={(value, e, name) =>
                  this.handleDropdownChange(value, e, name)
                }
                selectedValue={this.state.selectedTimePeriod}
              />
              {isTimePeriodInvalid && (
                <div className="text-danger mt-1">
                  Please select valid start time
                </div>
              )}
            </div>
          </div>

          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Meeting Type <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <Dropdown
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                id="meetingType"
                title=""
                custom
                mainClassName="bx--form-item mb-0"
                className="w-100"
                titleClass="mb-2"
                labelClass="pt-2"
                onInputChange={() => {}}
                options={meetingTypeOptions}
                name="selectedMeetingType"
                placeholder="Meeting Type"
                onChange={(value, e, name) =>
                  this.handleDropdownChange(value, e, name)
                }
                selectedValue={this.state.selectedMeetingType}
              />
              {isMeetingTypeInvalid && (
                <div className="text-danger mt-1">
                  Please select meeting type
                </div>
              )}
            </div>
          </div>

          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">Internal Remarks</div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <TextInput
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                name="internalRemarks"
                id="internalRemarks"
                value={this.state.internalRemarks}
                onChange={this.handleChange}
                labelText=""
                className="text-capitalize"
                hideLabel
                type="text"
                placeholder="Internal Remarks"
              />
            </div>
          </div>

          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Meeting Status <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <Dropdown
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                id="meetingStatus"
                title=""
                custom
                mainClassName="bx--form-item mb-0"
                className="w-100"
                titleClass="mb-2"
                labelClass="pt-2"
                onInputChange={() => {}}
                options={
                  meetingId
                    ? meetingStatusOptions
                    : meetingStatusOptions?.filter(
                        (itm) => itm?.value !== 'Completed'
                      )
                }
                name="selectedMeetingStatus"
                placeholder="Meeting Status"
                onChange={(value, e, name) =>
                  this.handleDropdownChange(value, e, name)
                }
                selectedValue={selectedMeetingStatus}
              />
              {isMeetingStatusInvalid && (
                <div className="text-danger mt-1">
                  Please select meeting status
                </div>
              )}
            </div>
          </div>

          <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">
              Meeting Format <span className="text-danger">*</span>
            </div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <Dropdown
                disabled={disableUserInput || disableMeetInfoInpByRoleId}
                id="attendanceType"
                title=""
                custom
                mainClassName="bx--form-item mb-0"
                className="w-100"
                titleClass="mb-2"
                labelClass="pt-2"
                onInputChange={() => {}}
                options={attendanceTypeOptions}
                name="selectedAttendanceType"
                placeholder="AttendanceType"
                onChange={(value, e, name) =>
                  this.handleDropdownChange(value, e, name)
                }
                selectedValue={this.state.selectedAttendanceType}
              />
              {isAttendanceTypeInvalid && (
                <div className="text-danger mt-1">
                  Please select meeting format
                </div>
              )}
            </div>
          </div>

          {showSendEmailNotifInput && (
            <div className="bx--row align-items-center mb-3 mt-sm-3 mt-lg-0">
              <div className="bx--col-lg-4">Send Email Notification</div>
              <div
                className="bx--col-lg-6 mt-sm-2 mt-lg-0"
                style={{ marginLeft: '-1rem' }}>
                <ToggleSmall
                  disabled={disableUserInput || disableMeetInfoInpByRoleId}
                  toggled={this.state.sendEmailNotifToggle}
                  id="sendEmailNotifToggle"
                  className="col-md"
                  onToggle={(e) =>
                    this.handleToggleClick(e, 'sendEmailNotifToggle')
                  }
                  labelA="No"
                  labelB="Yes"
                />
                {isEmailNotifToggInvalid && (
                  <div className="text-danger mt-1">
                    Please select email Notification
                  </div>
                )}
              </div>
            </div>
          )}

          {/* <div className="bx--row align-items-center mb-2 mt-sm-3 mt-lg-0">
            <div className="bx--col-lg-4">Parking Fee</div>
            <div className="bx--col-lg-6 mt-sm-2 mt-lg-0">
              <Dropdown
                id="parkingFee"
                title=""
                custom
                mainClassName="bx--form-item mb-0"
                className="w-100"
                titleClass="mb-2"
                labelClass="pt-2"
                onInputChange={() => {}}
                options={[
                  { label: '0', value: '0' },
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                ]}
                name="selectedParkingFee"
                placeholder="Parking Fee"
                onChange={(value, e, name) =>
                  this.handleDropdownChange(value, e, name)
                }
                selectedValue={this.state.selectedParkingFee}
              />
            </div>
          </div> */}

          <GenericDataTable
            className="attendees-table"
            tbodyClass="candidate-background-color"
            otherActions={[]}
            headers={headersList}
            rows={
              commissionMemList &&
              Array.isArray(commissionMemList) &&
              commissionMemList.length > 0
                ? commissionMemList.map((itm) => {
                    const { userId, aliasName, firstname, lastname } =
                      itm || {};
                    return {
                      id: `row_${userId}`,
                      key: `row_${userId}`,
                      header_0: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Commission Members:
                          </div>
                          <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            {aliasName ?? `${firstname} ${lastname}`}
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Attendance Status:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <Dropdown
                              disabled={
                                disableUserInput ||
                                selectedMeetingStatus?.value == 'Cancelled'
                              }
                              isClearable={false}
                              id={`cmAttendanceStatus-${userId}`}
                              title=""
                              custom
                              mainClassName="bx--form-item mb-0"
                              className="w-100"
                              titleClass="mb-2"
                              labelClass="pt-2"
                              onInputChange={() => {}}
                              options={finalAttendanceStatusOpts}
                              name={`selected-cmAttendanceStatus-${userId}`}
                              placeholder="Attendance Status"
                              onChange={(value, e, name) =>
                                this.handleDropdownChange(value, e, name)
                              }
                              selectedValue={
                                this.state[
                                  `selected-cmAttendanceStatus-${userId}`
                                ]
                              }
                            />
                          </div>
                        </div>
                      ),
                      header_2: (roleId == 6 || roleId == 11) && (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Car Parking Count:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <Dropdown
                              disabled={
                                disableUserInput ||
                                ['Completed', 'Cancelled']?.includes(
                                  selectedMeetingStatus?.value
                                )
                              }
                              isClearable={false}
                              id={`carParkingFee-${userId}`}
                              title=""
                              custom
                              mainClassName="bx--form-item mb-0"
                              className="w-100"
                              titleClass="mb-2"
                              labelClass="pt-2"
                              onInputChange={() => {}}
                              options={carParkingFeeOptions}
                              name={`selected-carParkingFee-${userId}`}
                              placeholder="Car Parking Count"
                              onChange={(value, e, name) =>
                                this.handleDropdownChange(value, e, name)
                              }
                              selectedValue={
                                this.state[`selected-carParkingFee-${userId}`]
                              }
                            />
                          </div>
                        </div>
                      ),
                      header_3: (roleId == 6 || roleId == 11) && (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Attendance Time Period:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <Dropdown
                              disabled={disableUserInput}
                              isClearable={false}
                              id={`attendanceTimePeriod-${userId}`}
                              title=""
                              custom
                              mainClassName="bx--form-item mb-0"
                              className="w-100"
                              titleClass="mb-2"
                              labelClass="pt-2"
                              onInputChange={() => {}}
                              options={timeperiodOptions}
                              name={`selected-attendanceTimePeriod-${userId}`}
                              placeholder="Attendance Time Period"
                              onChange={(value, e, name) =>
                                this.handleDropdownChange(value, e, name)
                              }
                              selectedValue={
                                this.state[
                                  `selected-attendanceTimePeriod-${userId}`
                                ]
                              }
                            />
                          </div>
                        </div>
                      ),
                      header_4: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Remarks:
                          </div>
                          <div className="text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                            <TextArea
                              disabled={disableUserInput}
                              className=""
                              key={`remarks-${userId}`}
                              name={`remarks-${userId}`}
                              labelText=""
                              hideLabel
                              placeholder="Enter your remarks"
                              id={`remarks-${userId}`}
                              value={this.state[`remarks-${userId}`]}
                              onChange={this.handleChange}
                              rows={1}
                            />
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
            selectable={false}
            searchable={false}
            onSearchInputChange={() => {}}
            expandable={false}
            batchActions={false}
          />

          <div className="bx--row align-items-center mb-2 mt-3">
            <div className="bx--col-lg-4"></div>
            <div className="bx--col-lg-6 d-flex">
              <div className="mr-2">
                <Button
                  kind="ghost"
                  size="small"
                  onClick={() => this.handleGoBack()}>
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  disabled={disableUserInput || disableFinalSubmit}
                  kind="primary"
                  size="small"
                  onClick={this.handleAddEditMeeting}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Tile>
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.CMSMeetings.loading,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  upsertCMSMeeting,
  getCMSMeetingById,
  getCommissionMemByRoleId,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CMSAddEditMeeting)
);
