import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//Api Actions
import {
  createVideoRatingCriteria,
  getVideoInterviewratingCriteria,
  assignCandidateToCSCUser,
  getVideoInterviewDetails,
  updateVideoIntComments,
  reTriggerInsights,
  triggerRetakeForVidIntv,
  postFeedbackForAiInsights,
} from '../../actions/CandidateInfo';
import {
  getCandidateListData,
  getSaticApplFilters,
} from '../../actions/CandidateList';
import { getVIPanelUsersList } from '../../actions/AdminSection';
import { REQUEST_GET_CANDIDATE_INFO } from '../../actions/actionTypes';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import {
  RadioButton,
  TextArea,
  Button,
  InlineNotification,
  Modal,
  Select,
  SelectItem,
  Accordion,
  AccordionItem,
  RadioButtonGroup,
  CheckboxGroup,
  Checkbox,
  TextInput,
} from 'carbon-components-react';
import NoContentBox from '../../components/common/NoContentBox';
import CandVidIntOldFeedbackFormat from './CandVidIntOldFeedbackFormat';
import ColoredCirclePercentage from '../../components/common/ColoredCirclePercentage';
import ColoredRectangle from '../../components/common/ColoredRectangle';
import { lowerAndTrim } from '../../utils/helpers';
import { arrMap, reactMap } from '../../utils/reactMapUtils';
import { getAuditLogsData } from '../../actions/AuditLogs';
import moment from 'moment';
import { BsStars } from 'react-icons/bs';
import { toast } from 'react-toastify';

const allTraits = ['clarity', 'engaging', 'passionToServe1', 'passionToServe2'];
const allTraitOptions = ['yes', 'kiv', 'no'];

const getRadioBttons = (traitName, traitIndex, enableInput) => {
  if (
    allTraitOptions &&
    Array.isArray(allTraitOptions) &&
    allTraitOptions.length > 0
  ) {
    return allTraitOptions.map((traitOpt) => {
      return (
        <RadioButton
          className="ml-2"
          key={`${traitName}-${traitOpt}-${traitIndex}`}
          id={`${traitName}-${traitOpt}-${traitIndex}`}
          labelText={
            <div
              className={
                traitOpt === 'kiv' ? 'text-uppercase' : 'text-capitalize'
              }>
              {traitOpt}
            </div>
          }
          value={traitOpt}
          disabled={!enableInput}
        />
      );
    });
  }
};

const getColorForInsights = (aiInsightVal, colorMapping, defaultColor) => {
  return lowerAndTrim(aiInsightVal).includes('high')
    ? colorMapping?.high
    : lowerAndTrim(aiInsightVal).includes('moderate') ||
      lowerAndTrim(aiInsightVal).includes('medium')
    ? colorMapping?.moderate
    : lowerAndTrim(aiInsightVal).includes('low')
    ? colorMapping?.low
    : lowerAndTrim(aiInsightVal).includes('no')
    ? colorMapping?.no
    : lowerAndTrim(aiInsightVal).includes('yes')
    ? colorMapping?.yes
    : lowerAndTrim(aiInsightVal).includes('positive')
    ? colorMapping?.positive
    : lowerAndTrim(aiInsightVal).includes('negative')
    ? colorMapping?.negative
    : lowerAndTrim(aiInsightVal).includes('neutral')
    ? colorMapping?.neutral
    : defaultColor;
};

const getDisplayTextForInsights = (aiInsightVal) => {
  return lowerAndTrim(aiInsightVal).includes('high')
    ? 'High'
    : lowerAndTrim(aiInsightVal).includes('moderate')
    ? 'Moderate'
    : lowerAndTrim(aiInsightVal).includes('medium')
    ? 'Medium'
    : lowerAndTrim(aiInsightVal).includes('low')
    ? 'Low'
    : lowerAndTrim(aiInsightVal).includes('no')
    ? 'No'
    : lowerAndTrim(aiInsightVal).includes('yes')
    ? 'Yes'
    : lowerAndTrim(aiInsightVal).includes('positive')
    ? 'Positive'
    : lowerAndTrim(aiInsightVal).includes('negative')
    ? 'Negative'
    : lowerAndTrim(aiInsightVal).includes('neutral')
    ? 'Neutral'
    : 'NA';
};

const renderInsightsText = (insight) => {
  if (!insight || insight === 'NA') return;
  return (
    <div className="bx--row mb-1">
      <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
        <span
          className="p mb-0 d-inline-block"
          style={{
            fontSize: 14,
            textAlign: 'justify',
          }}>
          {insight}
        </span>
      </div>
    </div>
  );
};

const renderRetakeIntvLogTable = (status, arr) => {
  return (
    <div>
      {arrMap(arr).length > 0 && (
        <div className="mb-2">
          <div className="mb-2 f-w-600">
            {status === 'pass'
              ? 'Successfull Update Log :'
              : 'Unsuccessfull Update Log :'}
          </div>
          <div className="overflow-scroll">
            <table className="css-table-batch-info w-100 css-statistics-zoom-auto">
              <thead className="css-total-bg vertical-align-middle">
                <tr>
                  <th className="csc-scores-rank-w css-batch-metric-border-r css-metric-table-head vertical-align-middle">
                    S.No
                  </th>
                  <th className="css-batch-metric-border-r css-metric-table-head vertical-align-middle csc-cand-name-w-peer">
                    Question
                  </th>
                  <th className="css-batch-metric-border-r css-metric-table-head vertical-align-middle csc-cand-name-w-peer">
                    Number of Re-attempts
                  </th>
                  {status === 'fail' && (
                    <th className="css-batch-metric-border-r css-metric-table-head vertical-align-middle csc-cand-name-w-peer">
                      Error Message
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {reactMap(arr, (itm, idx) => {
                  const { ques, noOfRetakesAllowed, error, message } =
                    itm || {};
                  const renderHtml = (htmlObject) => {
                    return <div dangerouslySetInnerHTML={htmlObject} />;
                  };
                  var div = document.createElement('div');
                  div.innerHTML = ques;
                  const finalQuest = div.textContent || div.innerText || '';
                  return (
                    <tr className="text-center vertical-align-middle css-table-row-h">
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r">
                        {idx + 1}
                      </td>
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r">
                        <div>{renderHtml({ __html: finalQuest })}</div>
                      </td>
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r">
                        {noOfRetakesAllowed}
                      </td>
                      {error && status === 'fail' && (
                        <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r">
                          {noOfRetakesAllowed}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

class CandidateVideoInterview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clarity: null,
      engaging: null,
      passionToServe1: null,
      passionToServe2: null,
      isValidComment: false,
      isOpenAssignToCscModal: false,
      selectedMember: 'Select',
      isSelectedMemberInvalid: false,
      isOpenSubmitVICriteriaModal: false,
      isOpenConcurrencyErrMsgModal: false,
      isCscRatingsInOldFormat: false,
      insightsLoading: false,
      retakeQuesList: [],
      retakeVidIntvLogs: [],
    };
  }
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
  }

  renderOnMount = (currAppId) => {
    const { appId, selectionYear } = this.props || {};
    const finalAppId = currAppId || appId;
    this.setState(
      {
        showLoader: true,
      },
      () => {
        this.props.getSaticApplFilters();
        this.props
          .getVideoInterviewratingCriteria(finalAppId)
          .then((res) => {
            const { isCscRatingsInOldFormat = false } = res || {};
            if (res && !res.error) {
              this.setState(
                {
                  isCscRatingsInOldFormat,
                },
                () => {
                  if (isCscRatingsInOldFormat) {
                    this?.childCandVidIntOld?.renderOnMount();
                    this.handleGetVideoInterviewDetails(finalAppId);
                  } else {
                    this.populateComments(res);
                    this.handleGetVideoInterviewDetails(finalAppId);
                  }
                }
              );
            } else {
              this.setState({
                showLoader: false,
              });
            }
          })
          .catch((e) => {
            console.log('Error', e);
            this.setState({
              errorNotif: true,
              showLoader: false,
            });
          });
      }
    );
  };

  handleGetVideoInterviewDetails = (finalAppId) => {
    this.props
      .getVideoInterviewDetails(finalAppId)
      .then((res) => {
        if (res && !res.error) {
          this.handleGetApiData();
          const { videoInterviewResponse } = res;
          this.setState(
            {
              videoInterviewRes: videoInterviewResponse,
              showLoader: false,
            },
            () => this.handleGetVidIntRetakeLogs()
          );
        } else {
          this.setState({
            showLoader: false,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          showLoader: false,
        });
      });
  };

  handleGetApiData = () => {
    const data = {
      activePage: 1,
      itemsPerPage: 1000,
    };
    this.props.getVIPanelUsersList(data);
  };

  populateComments = (res) => {
    const { appId } = this.props || {};
    const { list: viCriteriaInfoList } = res || {};
    if (
      viCriteriaInfoList &&
      Array.isArray(viCriteriaInfoList) &&
      viCriteriaInfoList.length > 0
    ) {
      const updateState = {};
      viCriteriaInfoList.forEach((viCriteriaInfo, idx) => {
        const { videointerviewratingcriteria: viRatingCriteria } =
          viCriteriaInfo || {};
        const { comments: userComments } = viRatingCriteria || {};
        updateState[`comments-${appId}-${idx}`] = userComments || '';
      });
      this.setState({ ...updateState });
    }
  };

  handleGetVidIntRetakeLogs = () => {
    this.props
      .getAuditLogsData({
        type: 'video-interview-retake',
        appId: this.props.appId,
        noLimit: true,
      })
      .then((res) => {
        const { list } = res || {};
        this.setState({
          retakeVidIntvLogs: list,
        });
      });
  };

  handleGetVIRatingCriteria = () => {
    const { appId } = this.props || {};
    this.props
      .getVideoInterviewratingCriteria(appId)
      .then((res) => this.populateComments(res));
  };

  handleChangeRadioBtn = (traitName, value, idx, isNotCSCMem) => {
    this.setState(
      {
        [`${traitName}-${idx}`]: value,
      },
      () => this.getFinalStatus(idx, isNotCSCMem)
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleUpdateComments = (idx) => {
    const { viCriteriaInfoList, appId } = this.props;
    const { videoInterviewCriteriaId } = viCriteriaInfoList?.[idx] || {};
    const payload = {
      appId,
      comments: this.state[`comments-${appId}-${idx}`],
    };
    this.props
      .updateVideoIntComments(payload, videoInterviewCriteriaId)
      .then((res) => {
        if (res && !res.error) {
          this.setState({
            successNotif: true,
            notifMsg: 'The comments are updated successfully',
            errorNotif: false,
          });
          this.handleGetVIRatingCriteria();
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          errorNotif: true,
          successNotif: false,
        });
      });
  };

  handleValidateVICriteria = (idx) => {
    const { viCriteriaInfoList, user } = this.props;
    const { userId: propUserId } = user || {};
    const { stageId, assignedTo, videointerviewratingcriteria } =
      viCriteriaInfoList?.[idx] || {};
    const { finalStatus: userFinalStatus } = videointerviewratingcriteria || {};
    const { selectedMember, finalStatus } = this.state;
    const areCommentsBeingUpdated =
      propUserId == assignedTo && userFinalStatus != null;

    if (areCommentsBeingUpdated) {
      this.handleUpdateComments(idx);
    } else {
      const isFirstCSCRator =
        viCriteriaInfoList?.length == 1 && assignedTo == 0;
      const areTraitsInvalid =
        stageId == 1
          ? this.handleValidateTraits(idx)
          : this.state[`rating-${idx}`]
          ? false
          : true;
      const isSelectedMemberInvalid =
        finalStatus === 'yes' ||
        viCriteriaInfoList?.length > 1 ||
        !isFirstCSCRator
          ? false
          : !selectedMember || selectedMember === 'Select';

      this.setState({
        // isValidComment: comments ? false : true,
        [`areTraitsInvalid-${idx}`]: areTraitsInvalid,
        isSelectedMemberInvalid,
        isOpenSubmitVICriteriaModal:
          !areTraitsInvalid && !isSelectedMemberInvalid,
        currEvaluationFormIdx: idx,
      });
    }
  };

  getFinalStatus = (idx, isNotCSCMem) => {
    let finalScore = 0;
    allTraits.forEach((trait) => {
      const value = this.state[`${trait}-${idx}`];
      if (value === 'yes') {
        finalScore += 2;
      } else if (value === 'kiv') {
        finalScore += 1;
      }
    });

    let finalStatus = 'yes';
    if (isNotCSCMem) {
      finalStatus = this.state[`rating-${idx}`];
    } else {
      if (finalScore >= 0 && finalScore <= 3) {
        finalStatus = 'no';
      } else if (finalScore >= 4 && finalScore <= 6) {
        finalStatus = 'kiv';
      } else if (finalScore >= 7 && finalScore <= 8) {
        finalStatus = 'yes';
      }
    }

    this.setState({
      finalStatus,
    });
    return finalStatus;
  };

  getTraitKey = (trait) => {
    return trait === 'yes'
      ? 1
      : trait === 'no'
      ? -1
      : trait === 'kiv'
      ? 0
      : null;
  };

  getTraitValue = (trait) => {
    return trait == 1 ? 'yes' : trait == -1 ? 'no' : trait == 0 ? 'kiv' : null;
  };

  handleSubmitVICriteria = () => {
    const { selectedMember, currEvaluationFormIdx } = this.state;
    const { appId, viCriteriaInfoList = [] } = this.props;
    const { stageId, videoInterviewCriteriaId, assignedTo } =
      (viCriteriaInfoList && viCriteriaInfoList[currEvaluationFormIdx]) || {};
    const isCSCMember = stageId == 1;

    const data = {
      clarity: isCSCMember
        ? this.getTraitKey(this.state[`clarity-${currEvaluationFormIdx}`])
        : 0,
      engaging: isCSCMember
        ? this.getTraitKey(this.state[`engaging-${currEvaluationFormIdx}`])
        : 0,
      passionToServe1: isCSCMember
        ? this.getTraitKey(
            this.state[`passionToServe1-${currEvaluationFormIdx}`]
          )
        : 0,
      passionToServe2: isCSCMember
        ? this.getTraitKey(
            this.state[`passionToServe2-${currEvaluationFormIdx}`]
          )
        : 0,
      comments: this.state[`comments-${appId}-${currEvaluationFormIdx}`],
      appId,
      stageId,
      finalStatus: this.getFinalStatus(currEvaluationFormIdx, !isCSCMember),
    };

    if (viCriteriaInfoList?.length == 1 && assignedTo == 0)
      data.isFirstCSCRator = true;

    if (
      viCriteriaInfoList?.length < 2 &&
      selectedMember &&
      selectedMember !== 'Select' &&
      data?.finalStatus !== 'yes'
    ) {
      data.secondCSCMember = selectedMember;
    }

    this.props
      .createVideoRatingCriteria(data)
      .then((res) => {
        this.dismissSubmitVICriteriaModal();
        this.handleApiRes(res);
      })
      .catch((e) => {
        console.log('Error', e);
        this.dismissSubmitVICriteriaModal();
        this.setState({
          errorNotif: true,
          successNotif: false,
        });
      });
  };

  handleApiRes = (res) => {
    if (res && !res.error) {
      this.setState({
        successNotif: true,
        notifMsg: "The rating criteria's are updated successfully",
        errorNotif: false,
      });
      this.handleGetVIRatingCriteria();
    } else {
      const { currEvaluationFormIdx } = this.state;
      const isOpenConcurrencyErrMsgModal = currEvaluationFormIdx == 0;
      this.setState({
        errorNotif: true,
        errMsg: res?.error?.message,
        successNotif: false,
        isOpenConcurrencyErrMsgModal,
      });
    }
  };

  handleValidateTraits = (idx) => {
    let finalTraitResp = [];
    allTraits.forEach((trait) => {
      finalTraitResp.push(this.state[`${trait}-${idx}`]);
    });

    return finalTraitResp.some((resp) => resp === null || resp === undefined);
  };

  // activeOpenAssignCSCModal = () => {
  //   this.setState({
  //     isOpenAssignToCscModal: true,
  //   });
  // };

  // dismissOpenAssignCSCModal = () => {
  //   this.setState({
  //     isOpenAssignToCscModal: false,
  //   });
  // };

  // handleSubmitAssignUsers = () => {
  //   const { appId } = this.props;
  //   const data = {
  //     appId,
  //   };
  //   this.props
  //     .assignCandidateToCSCUser(data)
  //     .then((res) => {
  //       if (res && !res.error) {
  //         this.dismissOpenAssignCSCModal();
  //         this.setState(
  //           {
  //             successNotif: true,
  //             notifMsg: 'The user has been assigned successfully',
  //             errorNotif: false,
  //           },
  //           () => {
  //             this.handleGetCandidateInfoAPI();
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           errorNotif: true,
  //           successNotif: false,
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       console.log('Error', e);
  //       this.setState({
  //         errorNotif: true,
  //         successNotif: false,
  //       });
  //     });
  // };

  // handleGetCandidateInfoAPI = () => {
  //   const { match } = this.props;
  //   const { params } = match || {};
  //   const { appId, jobId } = params || {};
  //   const data = {
  //     batchId: jobId,
  //     activePage: 1,
  //     itemsPerPage: 1000,
  //     searchKey: null,
  //   };
  //   this.props.getCandidateListData(data, appId, REQUEST_GET_CANDIDATE_INFO);
  // };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
      successNotifRetake: false,
      errorNotifRetake: false,
      notifMsgRetake: '',
      errMsgRetake: '',
    });
  };

  dismissSubmitVICriteriaModal = () => {
    this.setState({ isOpenSubmitVICriteriaModal: false });
  };

  dismissConcurrencyErrMsgModal = () => {
    this.setState({ isOpenConcurrencyErrMsgModal: false });
  };

  handleRetrigger = (interviewId, qid) => {
    this.setState({
      showLoader: true,
    });
    const data = {
      videoInterviewId: interviewId,
      interviewQuestionId: qid,
      language: 'en',
      triggerDsAnalysis: true,
      triggerTranscript: true,
    };
    this.props.reTriggerInsights(data).then((res) => {
      this.setState({
        showLoader: false,
        insightsLoading: true,
      });
    });
  };

  onChangeRetakeQues = (e, questionId) => {
    const { retakeQuesList = [] } = this.state;

    let finalList = [...retakeQuesList];
    const currIdx = finalList.findIndex((v) => v?.questionId == questionId);

    if (currIdx !== -1) {
      finalList[currIdx] = { ...finalList[currIdx], isChecked: e };
    } else {
      finalList.push({ questionId, isChecked: e });
    }

    this.setState({
      retakeQuesList: finalList,
    });
  };

  onChangeRetakeQuesCount = (val, questionId) => {
    const { retakeQuesList = [] } = this.state;

    let finalList = [...retakeQuesList];
    const currIdx = finalList.findIndex((v) => v?.questionId == questionId);

    if (currIdx !== -1) {
      finalList[currIdx] = { ...finalList[currIdx], noOfRetakesAllowed: val };
    } else {
      finalList.push({ questionId, noOfRetakesAllowed: val });
    }

    this.setState({
      retakeQuesList: finalList,
    });
  };

  handleSubmitRetakeInfo = () => {
    const { appId } = this.props;
    const {
      retakeQuesList,
      reasonForRetakeRequest = '',
      videoInterviewRes,
    } = this.state;
    const { questionConfig } = videoInterviewRes || {};
    const { idata } = questionConfig || {};

    if (!arrMap(retakeQuesList).find((itm) => itm?.isChecked === true)) {
      this.setState({
        errorNotifRetake: true,
        errMsgRetake: 'Please select atleast one question for retake.',
      });
      return;
    }

    if (
      arrMap(retakeQuesList).length > 0 &&
      arrMap(retakeQuesList).find(
        ({ noOfRetakesAllowed, isChecked }) =>
          isChecked &&
          (noOfRetakesAllowed == null || Number(noOfRetakesAllowed) <= 0)
      )
    ) {
      this.setState({
        errorNotifRetake: true,
        errMsgRetake: 'Please select valid number of retakes',
      });
      return;
    }

    const data = {
      appId,
      questionsArr: arrMap(
        retakeQuesList.filter((v) => v?.isChecked === true),
        (v) => ({
          questionId: v?.questionId,
          noOfRetakesAllowed: v?.noOfRetakesAllowed,
        })
      ),
      reasonForRetakeRequest,
      videoInterviewId: arrMap(idata)?.[0]?.answer?.videointerviewId,
    };

    this.props
      .triggerRetakeForVidIntv(data)
      .then((res) => {
        const { passedArr, failedArr, inviteResp } = res || {};
        const { error, message } = inviteResp || {};
        if (res && !res.error && arrMap(passedArr).length > 0) {
          this.setState({
            successNotifRetake: true,
            notifMsgRetake: `Retake request info updated successfully! ${message}`,
            errorNotifRetake: false,
            retakeQuesList: [],
            reasonForRetakeRequest: '',
          });
          this.handleGetVidIntRetakeLogs();
        } else {
          this.setState({
            errorNotifRetake: true,
            errMsgRetake: res?.error?.message,
            successNotifRetake: false,
          });
        }
      })
      .catch((e) => {
        console.log('Error', e);
        this.setState({
          errorNotifRetake: true,
          errMsgRetake: 'Something went wrong, Please try again later!',
        });
      });
  };

  dismissFeedbackModal = () => {
    this.setState({
      isOpenFeedbackModal: false,
      recruiterFeedback: '',
      selQuesId: null,
      selQuesIdx: null,
    });
  };

  handleSubmitRecruiterFeedback = () => {
    const { recruiterFeedback, selQuesId, selQuesIdx } = this.state;
    this.setState(
      {
        isRecFeedbackInvalid:
          !recruiterFeedback || lowerAndTrim(recruiterFeedback)?.length <= 0,
      },
      () => {
        const { appId } = this.props || {};
        const data = {
          appId,
          type: 'feedback-on-video-insights',
          feedback: {
            [`q${selQuesIdx + 1}`]: this.state.recruiterFeedback,
          },
        };
        if (!this.state.isRecFeedbackInvalid) {
          this.props
            .postFeedbackForAiInsights(data)
            .then((res) => {
              this.dismissFeedbackModal();
              if (res && !res.error) {
                toast.success('Feeback has been submitted successfully!');
              } else {
                toast.error(
                  res?.error?.message ||
                    'Something went wrong, Please try again later!'
                );
              }
            })
            .catch((error) => console.log('Error', error));
        }
      }
    );
  };

  render() {
    const {
      viCriteriaInfoList,
      panelMembers,
      user,
      loading,
      staticApplFilterInfo,
      appId,
      selectionYear,
    } = this.props || {};
    const {
      isValidComment,
      successNotif,
      notifMsg,
      errorNotif,
      errMsg,
      assignedUserId,
      isValidAssignedUserId,
      isOpenAssignToCscModal,
      selectedMember,
      isSelectedMemberInvalid,
      videoInterviewRes,
      showLoader,
      isOpenSubmitVICriteriaModal,
      finalStatus,
      isOpenConcurrencyErrMsgModal,
      isCscRatingsInOldFormat,
      retakeQuesList,
      reasonForRetakeRequest,
      successNotifRetake,
      errorNotifRetake,
      notifMsgRetake,
      errMsgRetake,
      retakeVidIntvLogs,
      isOpenFeedbackModal,
      recruiterFeedback,
      isRecFeedbackInvalid,
    } = this.state;

    const { questionConfig } = videoInterviewRes || {};
    const { questions, idata } = questionConfig || {};
    const finalVideoIntRes =
      idata && Array.isArray(idata) && idata.length > 0 ? idata : questions;
    const filteredVideoIntRes = arrMap(finalVideoIntRes).filter(
      (itm) => itm?.qid !== 'overall-score'
    );
    const {
      userId: propUserId,
      roleId,
      firstname: loggedInUserFirstName,
      lastname: loggedInUserLastName,
      isValidStage1Member = false,
    } = user || {};
    const { assignedToList } = staticApplFilterInfo || {};

    return (
      <div>
        {roleId !== 7 && (
          <div class="mt-2 h6 font-weight-bold">Video Presentation</div>
        )}

        {filteredVideoIntRes &&
        Array.isArray(filteredVideoIntRes) &&
        filteredVideoIntRes.length > 0 ? (
          <div className="bx--row mb-5">
            {filteredVideoIntRes.map((res, idx) => {
              const { qtext, answer, qid, qtype } = res || {};
              const {
                answerUrl,
                streaming,
                answer2Url,
                answerMp4Url,
                textSimilarityScore,
                transcriptionAnalysis,
                videoTranscript,
                videointerviewId,
              } = answer || {};
              const { url, jwt } = streaming || {};
              const renderHtml = (htmlObject) => {
                return <div dangerouslySetInnerHTML={htmlObject} />;
              };
              var div = document.createElement('div');
              div.innerHTML = qtext;
              const finalQuest = div.textContent || div.innerText || '';
              const {
                GrammaticalCorrectness,
                StyleAndTone = 'NA',
                TextSummarization,
                GrammaticalCorrectnessAnalysis,
                StyleAndToneAnalysis,
                BiasFlag,
                BiasAnalysis,
                ProfanityFlag,
                ProfanityAnalysis,
                Sentiment,
                SentimentAnalysis,
                Emotion: {
                  Confidence,
                  Engagement,
                  Stress,
                  Enthusiasm,
                  Nervousness,
                  Openness,
                } = {},
                EmotionAnalysis,
                Similarity,
                SimilarityAnalysis,
                Completeness,
                CompletenessAnalysis,
              } = transcriptionAnalysis || {};
              return (
                <div className="bx--col-lg-12 bx--col-md-12 bx--row align-items-center mt-3">
                  <div className="h5 f-w-b mb-2 mt-2 d-flex align-items-baseline bx--col-lg-6 bx--col-md-6">
                    <div>Q{idx + 1}.</div>{' '}
                    <div>{renderHtml({ __html: finalQuest })}</div>
                  </div>
                  <div className="bx--col-lg-6 bx--col-md-6">
                    <div className="d-flex flex-column">
                      <div>
                        {this.state[`showAlternateVidSrc-${idx}`] ? (
                          answerUrl ? (
                            <div>
                              <iframe
                                src={`/public/amp/player?url=${encodeURIComponent(
                                  url
                                )}&jwt=${encodeURIComponent(
                                  jwt
                                )}&id=${`vid-${qid}`}`}
                                width="400"
                                height="365"></iframe>
                            </div>
                          ) : (
                            <div className="2url">
                              <video
                                src={answer2Url}
                                type="video/webm"
                                id={`vid-${qid}-answer2Url`}
                                class="azuremediaplayer amp-default-skin"
                                controls
                                style={{
                                  aspect: 1.32,
                                  width: 400,
                                  maxWidth: 485,
                                  height: 365,
                                }}
                                data-setup='{"nativeControlsForTouch": false, techOrder: ["azureHtml5JS", "flashSS", "html5FairPlayHLS","silverlightSS", "html5"]}'></video>
                            </div>
                          )
                        ) : (
                          <div className="4url">
                            <video
                              src={answerMp4Url}
                              type="video/mp4"
                              id={`vid-${qid}-answerMp4Url`}
                              class="azuremediaplayer amp-default-skin"
                              controls
                              style={{
                                aspect: 1.32,
                                width: 400,
                                maxWidth: 485,
                                height: 365,
                              }}
                              data-setup='{"nativeControlsForTouch": false, techOrder: ["azureHtml5JS", "flashSS", "html5FairPlayHLS","silverlightSS", "html5"]}'></video>
                          </div>
                        )}
                      </div>
                      <div className="mt-2">
                        <div>
                          <Button
                            kind="tertiary"
                            size="small"
                            onClick={() =>
                              this.setState({
                                [`showAlternateVidSrc-${idx}`]: true,
                              })
                            }
                            disabled={
                              (!answerUrl && !answer2Url) ||
                              this.state[`showAlternateVidSrc-${idx}`]
                            }>
                            Click here if video does not load
                          </Button>
                        </div>
                      </div>
                    </div>
                    {!answerUrl && !answer2Url && (
                      <div className="small bx--type-zeta">
                        Waiting for the video interview response
                      </div>
                    )}
                  </div>

                  {roleId == 1 && qtype === 'video' && (
                    <div className="bx--row mb-3 mt-3 w-100 xpa-video-transcript">
                      {videoTranscript && (
                        <div>
                          <div className="d-flex bx--col align-items-center mb-2">
                            <h3 className="font-weight-bold">
                              Video Transcript
                            </h3>
                            <span
                              className="xpa-gen-ai ml-3"
                              onClick={() => {}}>
                              <BsStars
                                size={20}
                                style={{
                                  color: 'rgb(255 191 0)',
                                  marginRight: '2px',
                                }}
                              />
                              Generated using AI
                            </span>
                          </div>

                          <div className="mt-2 mb-3 bx--col">
                            <div
                              style={{ fontWeight: 600, fontSize: 'medium' }}>
                              Active Beta Testing
                            </div>
                            <div className="mt-2">
                              <span
                                style={{ fontWeight: 600, fontSize: 'small' }}>
                                Disclaimer
                              </span>{' '}
                              -{' '}
                              <span
                                style={{ color: 'red', fontStyle: 'italic' }}>
                                Vetted by AI. Please note that during beta
                                phase, information presented may not be fully
                                represented and is subject to further
                                improvements. Please provide your feedback{' '}
                                <span
                                  key={`${qid}-${idx}`}
                                  id={`${qid}-${idx}`}
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      isOpenFeedbackModal: true,
                                      selQuesId: qid,
                                      selQuesIdx: idx,
                                    });
                                  }}>
                                  here
                                </span>
                                .
                              </span>
                            </div>
                          </div>

                          <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                            <span
                              className="pb-2"
                              style={{ textAlign: 'justify', fontSize: 14 }}>
                              {videoTranscript}{' '}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-3">
                        <div className="bx--row bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12  ">
                          <h3 className="text-capitalize f-w-b">AI insights</h3>
                          <>
                            {/* {videointerviewId && qid && (
                                <span
                                  class="text-capitalize ml-2 mt-1"
                                  style={{
                                    color: '#002072',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    this.handleRetrigger(videointerviewId, qid);
                                  }}>
                                  {`(Regenerate Insights)`}
                                </span>
                              )} */}
                          </>
                        </div>

                        {(!TextSummarization ||
                          TextSummarization == '' ||
                          this.state.insightsLoading) && (
                          <p className="mt-1 mb-1">
                            AI Insights are being generated for this video and
                            will be visible here shortly
                          </p>
                        )}
                        {!this.state.insightsLoading && TextSummarization && (
                          <div className="mt-1adde  mb-2 bx--tile ">
                            <div className="mt-2 mb-2 bx--col-lg-12 bx--col-md-12 ">
                              <div className="bx--row mt-1 mb-1">
                                <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Summary
                                  </span>
                                </div>
                              </div>
                              <div className="bx--row mb-1">
                                <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                                  <span
                                    className="p mb-0 d-inline-block"
                                    style={{
                                      fontSize: 14,
                                      textAlign: 'justify',
                                    }}>
                                    {TextSummarization}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="bx--row bx--col-lg-12 mt-4 "
                              style={{
                                justifyContent: 'space-between',
                              }}>
                              <div className="  bx--col-lg-5 bx--col-md-7">
                                <div className=" mt-1 mb-1">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Grammatical Correctness
                                  </span>
                                </div>
                                {GrammaticalCorrectness && (
                                  <ColoredRectangle
                                    color={getColorForInsights(
                                      GrammaticalCorrectness,
                                      {
                                        high: '#7BF39C',
                                        moderate: '#EFB132',
                                        low: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text={getDisplayTextForInsights(
                                      GrammaticalCorrectness
                                    )}
                                  />
                                )}
                                {renderInsightsText(
                                  GrammaticalCorrectnessAnalysis
                                )}
                              </div>{' '}
                              <div className="  bx--col-lg-5 bx--col-md-7 ">
                                <div className=" mt-1 mb-1">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Style and Tone Analysis
                                  </span>
                                </div>
                                {StyleAndTone && (
                                  <ColoredRectangle
                                    color={getColorForInsights(
                                      StyleAndTone,
                                      {
                                        high: '#7BF39C',
                                        moderate: '#EFB132',
                                        low: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text={getDisplayTextForInsights(
                                      StyleAndTone
                                    )}
                                  />
                                )}
                                {renderInsightsText(StyleAndToneAnalysis)}
                              </div>{' '}
                            </div>
                            <div
                              className=" bx--row bx--col-lg-12 mt-4 "
                              style={{
                                justifyContent: 'space-between',
                              }}>
                              <div className="  bx--col-lg-5 bx--col-md-7 ">
                                <div className=" mt-1 mb-1">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Bias Analysis
                                  </span>
                                </div>
                                {BiasFlag && (
                                  <ColoredRectangle
                                    color={getColorForInsights(
                                      BiasFlag,
                                      {
                                        no: '#7BF39C',
                                        moderate: '#EFB132',
                                        yes: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text={getDisplayTextForInsights(BiasFlag)}
                                  />
                                )}
                                {renderInsightsText(BiasAnalysis)}
                              </div>{' '}
                              <div className="  bx--col-lg-5 bx--col-md-7 ">
                                <div className=" mt-1 mb-1">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Profanity Analysis
                                  </span>
                                </div>
                                {ProfanityFlag && (
                                  <ColoredRectangle
                                    color={getColorForInsights(
                                      ProfanityFlag,
                                      {
                                        no: '#7BF39C',
                                        moderate: '#EFB132',
                                        yes: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text={getDisplayTextForInsights(
                                      ProfanityFlag
                                    )}
                                  />
                                )}
                                {renderInsightsText(ProfanityAnalysis)}
                              </div>{' '}
                            </div>
                            <div
                              className="bx--row bx--col-lg-12 mt-4"
                              style={{
                                justifyContent: 'space-between',
                              }}>
                              <div className="  bx--col-lg-5 bx--col-md-7 ">
                                <div className=" mt-1 mb-1">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Sentiment Analysis
                                  </span>
                                </div>
                                {Sentiment && (
                                  <ColoredRectangle
                                    color={getColorForInsights(
                                      Sentiment,
                                      {
                                        positive: '#7BF39C',
                                        negative: '#fa9196',
                                        neutral: '#EFB132',
                                      },
                                      'lightgrey'
                                    )}
                                    text={getDisplayTextForInsights(Sentiment)}
                                  />
                                )}
                                {renderInsightsText(SentimentAnalysis)}
                              </div>{' '}
                              <div className=" bx--col-lg-5 bx--col-md-7 ">
                                <div className=" mt-1 mb-1">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Emotion Analysis
                                  </span>
                                </div>
                                <div
                                  className="bx--row bx--col-lg-12"
                                  style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(3, 1fr)',
                                    gridTemplateRows: 'repeat(2, 100px)',
                                    gap: '10px',
                                    justifyContent: 'space-around',
                                  }}>
                                  <ColoredCirclePercentage
                                    color={getColorForInsights(
                                      Confidence,
                                      {
                                        high: '#7BF39C',
                                        moderate: '#EFB132',
                                        low: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text="Confidence"
                                    score={Confidence}
                                  />

                                  <ColoredCirclePercentage
                                    color={getColorForInsights(
                                      Engagement,
                                      {
                                        high: '#7BF39C',
                                        moderate: '#EFB132',
                                        low: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text="Engagement"
                                    score={Engagement}
                                  />
                                  <ColoredCirclePercentage
                                    color={getColorForInsights(
                                      Stress,
                                      {
                                        high: '#fa9196',
                                        moderate: '#EFB132',
                                        low: '#7BF39C',
                                      },
                                      'lightgrey'
                                    )}
                                    text="Stress"
                                    score={Stress}
                                  />
                                  <ColoredCirclePercentage
                                    color={getColorForInsights(
                                      Enthusiasm,
                                      {
                                        high: '#7BF39C',
                                        moderate: '#EFB132',
                                        low: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text="Enthusiasm"
                                    score={Enthusiasm}
                                  />

                                  <ColoredCirclePercentage
                                    color={getColorForInsights(
                                      Nervousness,
                                      {
                                        high: '#fa9196',
                                        moderate: '#EFB132',
                                        low: '#7BF39C',
                                      },
                                      'lightgrey'
                                    )}
                                    text="Nervousness"
                                    score={Nervousness}
                                  />

                                  <ColoredCirclePercentage
                                    color={getColorForInsights(
                                      Openness,
                                      {
                                        high: '#7BF39C',
                                        moderate: '#EFB132',
                                        low: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text="Openness"
                                    score={Openness}
                                  />
                                </div>
                                {renderInsightsText(EmotionAnalysis)}
                              </div>{' '}
                            </div>
                            <div
                              className="bx--row bx--col-lg-12 mt-4"
                              style={{
                                justifyContent: 'space-between',
                              }}>
                              <div className="  bx--col-lg-5 bx--col-md-7 ">
                                <div className=" mt-1 mb-1">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Similarity Analysis
                                  </span>
                                </div>
                                {Similarity && (
                                  <ColoredRectangle
                                    color={getColorForInsights(
                                      Similarity,
                                      {
                                        high: '#7BF39C',
                                        moderate: '#EFB132',
                                        low: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text={getDisplayTextForInsights(Similarity)}
                                  />
                                )}
                                {renderInsightsText(SimilarityAnalysis)}
                              </div>{' '}
                              <div className="  bx--col-lg-5 bx--col-md-7 ">
                                <div className=" mt-1 mb-1">
                                  <span className="h6 mb-0 d-inline-block f-w-b">
                                    {' '}
                                    Answer Completeness
                                  </span>
                                </div>
                                {Completeness && (
                                  <ColoredRectangle
                                    color={getColorForInsights(
                                      Completeness,
                                      {
                                        high: '#7BF39C',
                                        moderate: '#EFB132',
                                        low: '#fa9196',
                                      },
                                      'lightgrey'
                                    )}
                                    text={getDisplayTextForInsights(
                                      Completeness
                                    )}
                                  />
                                )}
                                {renderInsightsText(CompletenessAnalysis)}
                              </div>{' '}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            {roleId == 1 && (
              <>
                <SuccErrNotification
                  successNotif={successNotifRetake}
                  errorNotif={errorNotifRetake}
                  notifMsg={notifMsgRetake}
                  errMsg={errMsgRetake}
                  handleCloseNotifBtn={this.handleCloseNotifBtn}
                />
                <hr />
                <div className="mt-3 mb-4">
                  <div className="mb-2 h6 f-w-b">
                    Found any issue with the question responses?
                  </div>
                  <div className="mb-2 f-w-b">
                    Select Questions that candidates needs to retake:
                  </div>

                  {filteredVideoIntRes.map((res, idx) => {
                    const { qtext, qid, answer } = res || {};
                    const { videointerviewId, videoRetakesRemaining } =
                      answer || {};
                    const renderHtml = (htmlObject) => {
                      return <div dangerouslySetInnerHTML={htmlObject} />;
                    };
                    var div = document.createElement('div');
                    div.innerHTML =
                      qtext +
                      ` (Retakes Remaining : ${
                        videoRetakesRemaining ?? '-NA-'
                      })`;
                    const finalQuest = div.textContent || div.innerText || '';
                    const isCurrQuesChecked = arrMap(retakeQuesList).find(
                      (v) => v?.questionId == qid
                    )?.isChecked;
                    return (
                      <div className="mt-1">
                        <Checkbox
                          id={`${qid}-${idx}-retake`}
                          name={`${qid}-${idx}-retake`}
                          key={`${qid}-${idx}-retake`}
                          labelText={
                            <div className="d-flex">
                              <div>Q{idx + 1}.</div>{' '}
                              <div>{renderHtml({ __html: finalQuest })}</div>
                            </div>
                          }
                          onChange={(e) => this.onChangeRetakeQues(e, qid)}
                          checked={isCurrQuesChecked}
                        />
                        {isCurrQuesChecked && (
                          <div className="ml-5 d-flex align-items-center">
                            <div>
                              How many re-attempts would you like to provide?
                            </div>
                            <div className="ml-2" style={{ maxWidth: 100 }}>
                              <TextInput
                                name={`${qid}-${idx}-retake-count`}
                                id={`${qid}-${idx}-retake-count`}
                                value={
                                  arrMap(retakeQuesList).find(
                                    (v) => v?.questionId == qid
                                  )?.noOfRetakesAllowed
                                }
                                onChange={(e) =>
                                  this.onChangeRetakeQuesCount(
                                    e.target.value,
                                    qid
                                  )
                                }
                                type="number"
                                size="sm"
                                min={1}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <div className="mt-3">
                    <div className="mb-2 f-w-b">
                      Remarks for retake request (included in the email sent to
                      the candidate):
                    </div>
                    <TextArea
                      hideLabel
                      name="reasonForRetakeRequest"
                      value={reasonForRetakeRequest}
                      id="reasonForRetakeRequest"
                      placeholder="Please enter remarks."
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="mt-1">
                    <Button
                      kind="primary"
                      size="small"
                      onClick={this.handleSubmitRetakeInfo}>
                      Submit
                    </Button>
                  </div>
                </div>
                {arrMap(retakeVidIntvLogs).length > 0 && (
                  <div style={{ width: '90%' }}>
                    <div className="h6 mt-2 f-w-b mb-2">
                      Video Interview questions retake request logs :
                    </div>
                    {reactMap(retakeVidIntvLogs, (v, idx) => {
                      const { details, activity_done_by_name, timestamp } =
                        v || {};
                      const {
                        appId,
                        passedArr,
                        failedArr,
                        reasonForRetakeRequest,
                      } = details || {};
                      return (
                        <Accordion key={`${appId}-${idx}`} align="start">
                          <AccordionItem
                            className="content-padding"
                            title={
                              <div className="d-flex align-items-center">
                                <h5 className="font-weight-bold text-black text-capitalize">
                                  {activity_done_by_name || ''}
                                </h5>
                                <div className="ml-2">
                                  {timestamp &&
                                    `(${moment(timestamp).format(
                                      'DD/MM/YYYY, h:mm a'
                                    )})`}
                                </div>
                              </div>
                            }>
                            <div>
                              {renderRetakeIntvLogTable('pass', passedArr)}
                              {renderRetakeIntvLogTable('fail', failedArr)}
                              {reasonForRetakeRequest && (
                                <div className="d-flex">
                                  <div className="f-w-600">
                                    Remarks for retake request :
                                  </div>
                                  <div className="ml-2">
                                    {reasonForRetakeRequest.toString().trim()}
                                  </div>
                                </div>
                              )}
                            </div>
                          </AccordionItem>
                        </Accordion>
                      );
                    })}
                  </div>
                )}
                <hr />
              </>
            )}
          </div>
        ) : (
          <NoContentBox
            hideTile
            message="There are no video interviews for the candidate."
          />
        )}

        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />

        {isCscRatingsInOldFormat ? (
          <CandVidIntOldFeedbackFormat
            appId={appId}
            finalVideoIntRes={finalVideoIntRes}
            isCscRatingsInOldFormat={isCscRatingsInOldFormat}
            onRef={(ref) => (this.childCandVidIntOld = ref)}
          />
        ) : (
          <>
            {viCriteriaInfoList &&
              Array.isArray(viCriteriaInfoList) &&
              viCriteriaInfoList.length > 0 && (
                <>
                  <div className="mb-5">
                    <div className="h6 mt-2 f-w-b mb-2">
                      Candidate Video Presentation Evaluation (Comments by CSC
                      only, where applicable)
                    </div>
                    {viCriteriaInfoList.map((viCriteriaInfo, idx) => {
                      const {
                        assignedTo,
                        assignedToInfo,
                        videoInterviewCriteriaId,
                        videointerviewratingcriteria: viRatingCriteria,
                        stageId,
                      } = viCriteriaInfo || {};
                      const {
                        comments: userComments = '',
                        finalStatus: userFinalStatus,
                      } = viRatingCriteria || {};
                      const { firstname, lastname } = assignedToInfo || {};
                      const enableInput =
                        (propUserId == assignedTo &&
                          videoInterviewCriteriaId == null) ||
                        (viCriteriaInfoList.length === 1 &&
                          assignedTo == 0 &&
                          videoInterviewCriteriaId == null &&
                          isValidStage1Member);

                      const propClarity = this.getTraitValue(
                        viRatingCriteria?.clarity
                      );
                      const propEngaging = this.getTraitValue(
                        viRatingCriteria?.engaging
                      );
                      const propPassionToServe1 = this.getTraitValue(
                        viRatingCriteria?.passionToServe1
                      );
                      const propPassionToServe2 = this.getTraitValue(
                        viRatingCriteria?.passionToServe2
                      );

                      const isFirstCSCRator =
                        viCriteriaInfoList?.length == 1 && assignedTo == 0;

                      const enableCommentsInput =
                        Number(assignedTo) === 0 || propUserId == assignedTo;

                      return (
                        <Accordion key={`${appId}-${idx}`} align="start">
                          <AccordionItem
                            open
                            className="content-padding"
                            title={
                              <h5 className="font-weight-bold text-black text-capitalize">
                                {assignedTo == 0 && enableInput
                                  ? `${loggedInUserFirstName || ''} ${
                                      loggedInUserLastName || ''
                                    }`
                                  : assignedTo != 0
                                  ? `${firstname || ''} ${lastname || ''}`
                                  : ''}
                              </h5>
                            }>
                            {this.state[`areTraitsInvalid-${idx}`] && (
                              <InlineNotification
                                lowContrast
                                title="Please select valid traits"
                                iconDescription="describes the close button"
                                kind="error"
                                onCloseButtonClick={() => {
                                  this.setState({
                                    [`areTraitsInvalid-${idx}`]: false,
                                  });
                                }}
                              />
                            )}
                            {stageId == 1 ? (
                              <>
                                <div>
                                  <h6 className="mb-2">COMMUNICATION SKILLS</h6>
                                  <div className="mb-1">
                                    1.{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      Clarity
                                    </span>
                                    : Presents views in a well-organised and
                                    focused manner; clear and comprehensible
                                  </div>
                                  <div className="mb-2">
                                    <RadioButtonGroup
                                      onChange={(val) =>
                                        this.handleChangeRadioBtn(
                                          'clarity',
                                          val,
                                          idx
                                        )
                                      }
                                      valueSelected={
                                        enableInput
                                          ? this.state[`clarity-${idx}`]
                                          : propClarity
                                      }
                                      labelPosition="right"
                                      legend=""
                                      id={`clarity-${idx}`}
                                      name={`clarity-${idx}`}
                                      orientation="horizontal">
                                      {getRadioBttons(
                                        'clarity',
                                        idx,
                                        enableInput
                                      )}
                                    </RadioButtonGroup>
                                  </div>
                                  <div className="mb-1">
                                    2.{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      Engaging
                                    </span>
                                    : Presents views in a confident, compelling
                                    manner; Displays appropriate non-verbal
                                    behaviors to engage listener
                                  </div>
                                  <div className="mb-2">
                                    <RadioButtonGroup
                                      onChange={(val) =>
                                        this.handleChangeRadioBtn(
                                          'engaging',
                                          val,
                                          idx
                                        )
                                      }
                                      valueSelected={
                                        enableInput
                                          ? this.state[`engaging-${idx}`]
                                          : propEngaging
                                      }
                                      labelPosition="right"
                                      legend=""
                                      id={`engaging-${idx}`}
                                      name={`engaging-${idx}`}
                                      orientation="horizontal">
                                      {getRadioBttons(
                                        'engaging',
                                        idx,
                                        enableInput
                                      )}
                                    </RadioButtonGroup>
                                  </div>
                                </div>
                                <hr />
                                <div>
                                  <h6 className="mb-2">CHARACTER AND VALUES</h6>
                                  <div className="mb-1">
                                    3.{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      Passion to serve
                                    </span>
                                    : Committed to serving the Public Service or
                                    nation
                                  </div>
                                  <div className="mb-2">
                                    <RadioButtonGroup
                                      onChange={(val) =>
                                        this.handleChangeRadioBtn(
                                          'passionToServe1',
                                          val,
                                          idx
                                        )
                                      }
                                      valueSelected={
                                        enableInput
                                          ? this.state[`passionToServe1-${idx}`]
                                          : propPassionToServe1
                                      }
                                      labelPosition="right"
                                      legend=""
                                      id={`passionToServe1-${idx}`}
                                      name={`passionToServe1-${idx}`}
                                      orientation="horizontal">
                                      {getRadioBttons(
                                        'passionToServe1',
                                        idx,
                                        enableInput
                                      )}
                                    </RadioButtonGroup>
                                  </div>
                                  <div className="mb-1">
                                    4.{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      Passion to serve
                                    </span>
                                    : Committed to creating impact with the
                                    community or improve the lives of others
                                    through career in the public or private
                                    sector; Dedication to helping others
                                  </div>
                                  <div className="mb-2">
                                    <RadioButtonGroup
                                      onChange={(val) =>
                                        this.handleChangeRadioBtn(
                                          'passionToServe2',
                                          val,
                                          idx
                                        )
                                      }
                                      valueSelected={
                                        enableInput
                                          ? this.state[`passionToServe2-${idx}`]
                                          : propPassionToServe2
                                      }
                                      labelPosition="right"
                                      legend=""
                                      id={`passionToServe2-${idx}`}
                                      name={`passionToServe2-${idx}`}
                                      orientation="horizontal">
                                      {getRadioBttons(
                                        'passionToServe2',
                                        idx,
                                        enableInput
                                      )}
                                    </RadioButtonGroup>
                                  </div>
                                </div>

                                {finalStatus !== 'yes' &&
                                  enableInput &&
                                  viCriteriaInfoList.length < 2 &&
                                  isFirstCSCRator && (
                                    <>
                                      <hr />
                                      <div className="font-weight-bold mb-2">
                                        Select CSC Member
                                      </div>
                                      <div className="mt-2">
                                        <Select
                                          className="w-50"
                                          name="selectedMember"
                                          id={`selectedMember-${idx}`}
                                          onChange={this.handleChange}
                                          value={selectedMember}
                                          invalid={isSelectedMemberInvalid}
                                          invalidText="Please select valid member"
                                          labelText=""
                                          hideLabel
                                          disabled={!enableInput}>
                                          <SelectItem
                                            text="Select"
                                            key="Select"
                                            value="Select"
                                            disabled
                                          />
                                          {assignedToList &&
                                            Array.isArray(assignedToList) &&
                                            assignedToList.length > 0 &&
                                            assignedToList
                                              .filter(
                                                (itm) =>
                                                  propUserId != itm?.userId &&
                                                  itm?.stageId == 1
                                              )
                                              .map((itm) => {
                                                const { userId, userinfo } =
                                                  itm || {};
                                                const {
                                                  firstname = '',
                                                  lastname = '',
                                                } = userinfo || {};
                                                return (
                                                  <SelectItem
                                                    text={`${firstname} ${lastname}`}
                                                    key={userId}
                                                    id={userId}
                                                    value={userId}
                                                  />
                                                );
                                              })}
                                        </Select>
                                      </div>
                                    </>
                                  )}
                              </>
                            ) : (
                              <div>
                                <h6 className="mb-2">RATING</h6>
                                <div className="mb-2">
                                  <RadioButtonGroup
                                    onChange={(val) =>
                                      this.handleChangeRadioBtn(
                                        'rating',
                                        val,
                                        idx,
                                        true
                                      )
                                    }
                                    valueSelected={
                                      enableInput
                                        ? this.state[`rating-${idx}`]
                                        : userFinalStatus
                                    }
                                    labelPosition="right"
                                    legend=""
                                    id={`rating-${idx}`}
                                    name={`rating-${idx}`}
                                    orientation="horizontal">
                                    <RadioButton
                                      className="ml-2"
                                      key={`rating-yes-${idx}`}
                                      id={`rating-yes-${idx}`}
                                      labelText="Yes"
                                      value="yes"
                                      disabled={!enableInput}
                                    />
                                    <RadioButton
                                      className="ml-2"
                                      key={`rating-no-${idx}`}
                                      id={`rating-no-${idx}`}
                                      labelText="No"
                                      value="no"
                                      disabled={!enableInput}
                                    />
                                  </RadioButtonGroup>
                                </div>
                              </div>
                            )}

                            <hr />
                            <div className="font-weight-bold mb-2">
                              Comments
                            </div>
                            <div className="mt-2">
                              <TextArea
                                name={`comments-${appId}-${idx}`}
                                id={`comments-${appId}-${idx}`}
                                value={
                                  enableCommentsInput
                                    ? this.state[`comments-${appId}-${idx}`] ||
                                      ''
                                    : userComments || ''
                                }
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                                labelText=""
                                hideLabel
                                disabled={
                                  enableCommentsInput ? false : !enableInput
                                }
                                placeholder="Please enter the comments"
                                // invalid={isValidComment}
                                // invalidText="Please enter valid comments"
                              />
                            </div>

                            <div className="mt-2">
                              {userFinalStatus && (
                                <div>
                                  <span className="font-weight-bold mb-2">
                                    Overall Rating:{' '}
                                  </span>
                                  <span
                                    className={`font-weight-bold ${
                                      userFinalStatus === 'kiv'
                                        ? 'text-uppercase'
                                        : 'text-capitalize'
                                    }`}>
                                    {userFinalStatus}
                                  </span>
                                </div>
                              )}
                            </div>
                            {(enableInput || enableCommentsInput) && (
                              <div className="text-center mt-3">
                                <Button
                                  kind={
                                    videoInterviewCriteriaId
                                      ? 'tertiary'
                                      : 'primary'
                                  }
                                  size="small"
                                  onClick={() =>
                                    this.handleValidateVICriteria(idx)
                                  }>
                                  Submit Evaluation
                                </Button>
                              </div>
                            )}
                          </AccordionItem>
                        </Accordion>
                      );
                    })}
                  </div>
                </>
              )}
          </>
        )}

        {/* {isOpenAssignToCscModal && (
          <Modal
            open={isOpenAssignToCscModal}
            modalHeading="Assign TO CSC Users"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissOpenAssignCSCModal}
            onRequestSubmit={this.handleSubmitAssignUsers}>
            <div className="d-flex">
              <div className="w-100">
                <Select
                  name="assignedUserId"
                  id="assignedUserId"
                  onChange={this.handleChange}
                  value={assignedUserId}
                  className="w-100 xpa-width"
                  invalid={isValidAssignedUserId}
                  invalidText="Please select valid user"
                  labelText="Select User">
                  <SelectItem text="Select" key={0} />
                  {panelMembers &&
                    Array.isArray(panelMembers) &&
                    panelMembers.length > 0 &&
                    panelMembers.map((data) => {
                      const {
                        userinfo,
                        panelId: propPanelId,
                        percentage,
                        userId,
                      } = data;
                      const { firstname, lastname } = userinfo || {};
                      return (
                        <SelectItem
                          text={`${firstname} ${lastname} (${percentage})`}
                          key={propPanelId}
                          id={propPanelId}
                          value={userId}
                        />
                      );
                    })}
                </Select>
              </div>
            </div>
          </Modal>
        )} */}

        {isOpenSubmitVICriteriaModal && (
          <Modal
            open={isOpenSubmitVICriteriaModal}
            modalHeading="Submit Evaluation"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onSecondarySubmit={this.dismissSubmitVICriteriaModal}
            onRequestClose={this.dismissSubmitVICriteriaModal}
            onRequestSubmit={this.handleSubmitVICriteria}>
            <div className="mt-2 mb-2">
              Once submitted, the next process will be triggered and you will
              not be able to change your submission.
            </div>
          </Modal>
        )}

        {isOpenConcurrencyErrMsgModal && (
          <Modal
            open={isOpenConcurrencyErrMsgModal}
            modalHeading="Error Message Info"
            modalLabel="Action"
            primaryButtonText="Ok"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onSecondarySubmit={this.dismissConcurrencyErrMsgModal}
            onRequestClose={this.dismissConcurrencyErrMsgModal}
            onRequestSubmit={this.dismissConcurrencyErrMsgModal}>
            <div className="mt-2 mb-2 css-color-red">
              {errMsg || 'There was an error while processing your request.'}
            </div>
          </Modal>
        )}

        <JDProcessOverlay
          show={loading || showLoader}
          message="processing..."
        />

        {isOpenFeedbackModal && (
          <Modal
            open={isOpenFeedbackModal}
            modalHeading="Provide Feedback"
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissFeedbackModal}
            onRequestSubmit={this.handleSubmitRecruiterFeedback}>
            <TextArea
              hideLabel
              name="recruiterFeedback"
              value={recruiterFeedback}
              id="recruiterFeedback"
              placeholder="Enter your feedback here"
              onChange={this.handleChange}
              invalid={isRecFeedbackInvalid}
              invalidText="Please enter valid feedback"
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viCriteriaInfoList: state.CandidateInfo.viCriteriaInfoList,
  videoInterviewRes: state.CandidateInfo.videoInterviewRes,
  panelMembers: state.AdminSection.panelMembers,
  assignedCandidateList: state.CandidateInfo.assignedCandidateList,
  user: state.x0paDuser.user,
  loading: state.CandidateInfo.loading || state.AuditLogs.loading,
  staticApplFilterInfo: state.CandidateList.staticApplFilterInfo,
  auditLogsData: state.AuditLogs.auditLogsData,
});

const mapDispatchToProps = {
  createVideoRatingCriteria,
  getVideoInterviewratingCriteria,
  getVIPanelUsersList,
  assignCandidateToCSCUser,
  getCandidateListData,
  getVideoInterviewDetails,
  getSaticApplFilters,
  updateVideoIntComments,
  reTriggerInsights,
  triggerRetakeForVidIntv,
  getAuditLogsData,
  postFeedbackForAiInsights,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateVideoInterview)
);
