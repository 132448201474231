import React, { Fragment } from 'react';

//Functional Components
import NoContentBox from '../../components/common/NoContentBox';

//Common Packages
import { Accordion, AccordionItem } from 'carbon-components-react';

//CSS Files
import './PSSummaryOfScores.css';

import { lowerAndTrim } from '../../utils/helpers';

const handleAssessmentReportResponse = (rating) => {
  let finalRating = '';
  if (rating == 1) {
    finalRating = 'Very Weak';
  } else if (rating == 2) {
    finalRating = 'Weak';
  } else if (rating == 3) {
    finalRating = 'Less than Satisfactory';
  } else if (rating == 4) {
    finalRating = 'Satisfactory';
  } else if (rating == 5) {
    finalRating = 'More than Satisfactory';
  } else if (rating == 6) {
    finalRating = 'Strong';
  } else if (rating == 7) {
    finalRating = 'Very Strong';
  }
  return finalRating;
};

const isReportWriter = (currUserName, reportWriter) => {
  return lowerAndTrim(currUserName) === lowerAndTrim(reportWriter)
    ? `*: ${currUserName}`
    : `  : ${currUserName}`;
};

const PSAssessmentCenterReport = (props) => {
  const { psacDetails, selectionYear } = props || {};
  const {
    assessmentSummaryText,
    leadershipQualitiesRating,
    intellectualQualitiesRating,
    characterValuesRating,
    peopleSkillsRating,
    personalEffectivenessRating,
    communicationSkillsRating,
    characterValuesText,
    communicationSkillsText,
    intellectualQualitiesText,
    leadershipQualitiesText,
    peopleSkillsText,
    personalEffectivenessText,
    assessor1,
    assessor2,
    assessor3,
    assessor4,
    assessor5,
    assessor6,
    assessor7,
    assessor8,
    reportWriter,
  } =
    (psacDetails &&
      Array.isArray(psacDetails) &&
      psacDetails.length > 0 &&
      psacDetails[0]) ||
    {};
  let assessmentSummaryTextArr = [assessmentSummaryText];
  if (
    assessmentSummaryText &&
    assessmentSummaryText.toString() &&
    assessmentSummaryText.toString().includes('\r\n')
  ) {
    assessmentSummaryTextArr = assessmentSummaryText.toString().split('\r\n');
  }
  let leadershipQualitiesTextArr = [leadershipQualitiesText];
  if (
    leadershipQualitiesText &&
    leadershipQualitiesText.toString() &&
    leadershipQualitiesText.toString().includes('\r\n')
  ) {
    leadershipQualitiesTextArr = leadershipQualitiesText
      .toString()
      .split('\r\n');
  }
  let intellectualQualitiesTextArr = [intellectualQualitiesText];
  if (
    intellectualQualitiesText &&
    intellectualQualitiesText.toString() &&
    intellectualQualitiesText.toString().includes('\r\n')
  ) {
    intellectualQualitiesTextArr = intellectualQualitiesText
      .toString()
      .split('\r\n');
  }

  let characterValuesTextArr = [characterValuesText];
  if (
    characterValuesText &&
    characterValuesText.toString() &&
    characterValuesText.toString().includes('\r\n')
  ) {
    characterValuesTextArr = characterValuesText.toString().split('\r\n');
  }

  let peopleSkillsTextArr = [peopleSkillsText];
  if (
    peopleSkillsText &&
    peopleSkillsText.toString() &&
    peopleSkillsText.toString().includes('\r\n')
  ) {
    peopleSkillsTextArr = peopleSkillsText.toString().split('\r\n');
  }

  let communicationSkillsTextArr = [communicationSkillsText];
  if (
    communicationSkillsText &&
    communicationSkillsText.toString() &&
    communicationSkillsText.toString().includes('\r\n')
  ) {
    communicationSkillsTextArr = communicationSkillsText
      .toString()
      .split('\r\n');
  }

  let personalEffectivenessTextArr = [personalEffectivenessText];
  if (
    personalEffectivenessText &&
    personalEffectivenessText.toString() &&
    personalEffectivenessText.toString().includes('\r\n')
  ) {
    personalEffectivenessTextArr = personalEffectivenessText
      .toString()
      .split('\r\n');
  }

  return (
    <div>
      {psacDetails && Array.isArray(psacDetails) && psacDetails.length > 0 ? (
        <Fragment>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Assessor Info
                </div>
              }>
              <div style={{ whiteSpace: 'break-spaces' }}>
                <div className="bx--row mb-2">
                  <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12">
                    {assessor1 && (
                      <div className="d-flex font-weight-bold">
                        Assessor 1 {isReportWriter(assessor1, reportWriter)}
                      </div>
                    )}
                  </div>
                  <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12">
                    {assessor5 && (
                      <div className="d-flex font-weight-bold">
                        Assessor 5 {isReportWriter(assessor5, reportWriter)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="bx--row mb-2">
                  <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12">
                    {assessor2 && (
                      <div className="d-flex font-weight-bold">
                        Assessor 2 {isReportWriter(assessor2, reportWriter)}
                      </div>
                    )}
                  </div>
                  <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12">
                    {assessor6 && (
                      <div className="d-flex font-weight-bold">
                        Assessor 6 {isReportWriter(assessor6, reportWriter)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="bx--row mb-2">
                  <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12">
                    {assessor3 && (
                      <div className="d-flex font-weight-bold">
                        Assessor 3 {isReportWriter(assessor3, reportWriter)}
                      </div>
                    )}
                  </div>
                  <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12">
                    {assessor7 && (
                      <div className="d-flex font-weight-bold">
                        Assessor 7 {isReportWriter(assessor7, reportWriter)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="bx--row mb-2">
                  <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12">
                    {assessor4 && (
                      <div className="d-flex font-weight-bold">
                        Assessor 4 {isReportWriter(assessor4, reportWriter)}
                      </div>
                    )}
                  </div>
                  <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-12 bx--col-xs-12">
                    {assessor8 && (
                      <div className="d-flex font-weight-bold">
                        Assessor 8 {isReportWriter(assessor8, reportWriter)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <span className="font-weight-bold">(*)</span> represents the
                  Report Writer
                </div>
              </div>
            </AccordionItem>
          </Accordion>

          <div class="mt-3 mb-3 h6 font-weight-bold">Summary</div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Assessment Summary
                </div>
              }>
              <div className="csc-assessment-center-summary text-justify">
                {assessmentSummaryTextArr &&
                  Array.isArray(assessmentSummaryTextArr) &&
                  assessmentSummaryTextArr.length > 0 &&
                  assessmentSummaryTextArr.map((text) => (
                    <div className="mt-2">{text}</div>
                  ))}
              </div>
            </AccordionItem>
          </Accordion>
          <div class="mt-3 mb-3 h6 font-weight-bold">Competencies Ratings</div>
          <Accordion className="css-gba-accr-z" align='start'>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-5 text-uppercase">
                    Leadership Qualities
                  </div>
                  <div className="d-flex justify-content-center mb-1 ml-3">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          leadershipQualitiesRating == res
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">
                  {handleAssessmentReportResponse(leadershipQualitiesRating)} (
                  {leadershipQualitiesRating})
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="text-justify csc-assessment-center-summary ">
                {leadershipQualitiesTextArr &&
                  Array.isArray(leadershipQualitiesTextArr) &&
                  leadershipQualitiesTextArr.length > 0 &&
                  leadershipQualitiesTextArr.map((text) => (
                    <div className="mt-2">{text}</div>
                  ))}
              </div>
            </AccordionItem>

            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-5 text-uppercase">
                    Intellectual Qualities
                  </div>
                  <div className="d-flex justify-content-center mb-1 ml-3">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          intellectualQualitiesRating == res
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">
                  {handleAssessmentReportResponse(intellectualQualitiesRating)}{' '}
                  ({intellectualQualitiesRating})
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="text-justify csc-assessment-center-summary ">
                {intellectualQualitiesTextArr &&
                  Array.isArray(intellectualQualitiesTextArr) &&
                  intellectualQualitiesTextArr.length > 0 &&
                  intellectualQualitiesTextArr.map((text) => (
                    <div className="mt-2">{text}</div>
                  ))}
              </div>
              <hr className="mt-2 mb-2" />
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-5 text-uppercase">
                    Character and Values
                  </div>
                  <div className="d-flex justify-content-center mb-1 ml-3">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          characterValuesRating == res
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">
                  {handleAssessmentReportResponse(characterValuesRating)} (
                  {characterValuesRating})
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="text-justify csc-assessment-center-summary ">
                {characterValuesTextArr &&
                  Array.isArray(characterValuesTextArr) &&
                  characterValuesTextArr.length > 0 &&
                  characterValuesTextArr.map((text) => (
                    <div className="mt-2">{text}</div>
                  ))}
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-5 text-uppercase">
                    People Skills
                  </div>
                  <div className="d-flex justify-content-center mb-1 ml-3">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          peopleSkillsRating == res
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">
                  {handleAssessmentReportResponse(peopleSkillsRating)} (
                  {peopleSkillsRating})
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="text-justify csc-assessment-center-summary ">
                {peopleSkillsTextArr &&
                  Array.isArray(peopleSkillsTextArr) &&
                  peopleSkillsTextArr.length > 0 &&
                  peopleSkillsTextArr.map((text) => (
                    <div className="mt-2">{text}</div>
                  ))}
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-5 text-uppercase">
                    Communication Skills
                  </div>
                  <div className="d-flex justify-content-center mb-1 ml-3">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          communicationSkillsRating == res
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">
                  {handleAssessmentReportResponse(communicationSkillsRating)} (
                  {communicationSkillsRating})
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="text-justify csc-assessment-center-summary ">
                {communicationSkillsTextArr &&
                  Array.isArray(communicationSkillsTextArr) &&
                  communicationSkillsTextArr.length > 0 &&
                  communicationSkillsTextArr.map((text) => (
                    <div className="mt-2">{text}</div>
                  ))}
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="bx--row align-items-center">
                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-5 text-uppercase">
                    Personal Effectiveness
                  </div>
                  <div className="d-flex justify-content-center mb-1 ml-3">
                    {[1, 2, 3, 4, 5, 6, 7].map((res) => (
                      <div
                        className={
                          personalEffectivenessRating == res
                            ? `csc-th--block csc-psac-color--${res} mr-2`
                            : 'csc-th--block css-gba-color mr-2'
                        }>
                        <div className="csc-pos--top">{res}</div>
                      </div>
                    ))}
                  </div>
                </div>
              }>
              <div className="bx--row font-weight-bold mt-2">
                <div className=" bx--col-lg-2">Rating</div>
                <div className=" bx--col-lg-9">
                  {handleAssessmentReportResponse(personalEffectivenessRating)}{' '}
                  ({personalEffectivenessRating})
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="text-justify csc-assessment-center-summary ">
                {personalEffectivenessTextArr &&
                  Array.isArray(personalEffectivenessTextArr) &&
                  personalEffectivenessTextArr.length > 0 &&
                  personalEffectivenessTextArr.map((text) => (
                    <div className="mt-2">{text}</div>
                  ))}
              </div>
            </AccordionItem>
          </Accordion>
          <div class="mt-3 mb-3 h6 font-weight-bold">
            Competencies Reference
          </div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Leadership Qualities
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The drive and ability to set direction and influence the course
                of events by persuading, inspiring, involving, and developing
                people.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Directing and Motivating Others:</span>{' '}
                <span>
                  Effectively communicates strategy and translates it into
                  action; Communicates clear direction, expectations, structure,
                  and feedback to the team; Motivates and inspires team members
                  by recognising, involving, consulting, and empowering team
                  members.
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Impact and Influence:</span>{' '}
                <span>
                  Asserts/defends position the face of opposition; Presents
                  views with confidence, enthusiasm and conviction; Frames ideas
                  and opinions in compelling and persuasive ways that influence
                  the thinking of others; Captures people’s attention; Has an
                  impact on people and events.
                </span>
              </div>
            </AccordionItem>

            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Intellectual Qualities
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The ability to accurately analyse complex information, to make
                prompt and effective decisions, to develop innovative solutions,
                and to change approaches when necessary.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Analysis:</span>{' '}
                <span>
                  Understands complex information; Identifies trends, patterns
                  and linkages; Separates the critical from the peripheral
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">
                  Judgement, Strategic Perspective and Innovativeness:
                </span>{' '}
                <span>
                  Develops solutions that effectively address issues over the
                  long-term and which are creative and efficient in the use of
                  resources; Considers broad and long-term implications and
                  consequences (including impacts on multiple stakeholders);
                  Considers multiple alternatives; Plans for contingencies
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Cognitive Flexibility:</span>{' '}
                <span>
                  Able to deal with ambiguity and incomplete information; Able
                  to quickly adjust views and inferences as new information
                  becomes available.
                </span>
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Character and Values
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                Demonstrating and being a model of integrity, commitment, and
                conscientiousness.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Civic Mindedness:</span>{' '}
                <span>
                  Selflessness; Committed to serving the organisation and nation
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Drive (Action Orientation):</span>{' '}
                <span>
                  Acts decisively and deliberately without wavering; Applies
                  full effort to tasks; Takes initiative and shows willingness
                  to take appropriate risks and act independently
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Integrity:</span>{' '}
                <span>
                  Acts in a fair, ethical, and honest manner; consistency of
                  words and actions
                </span>
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  People Skills
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The ability and drive to interact and work effectively with
                people from diverse backgrounds and in a variety of situations.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Interpersonal Skills:</span>{' '}
                <span>
                  Puts others at ease; Interacts smoothly and comfortably with
                  others; Builds relationships and networks; Manages
                  disagreement and potential conflict with others
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Sensitivity and Empathy:</span>{' '}
                <span>
                  Understands and respects the needs, priorities, and views of
                  others; Deals with others in a tactful and supportive manner.
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Teamwork:</span>{' '}
                <span>
                  Cooperates with and supports the team; Builds and encourages
                  team cohesiveness; Ensures involvement of all team members;
                  Confronts, mediates and resolves conflict among others
                  (identifies common ground, etc.)
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Behavioural Flexibility:</span>{' '}
                <span>
                  Adjust behaviour appropriately to the situation and people
                  present
                </span>
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Communication Skills
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The ability to convey information effectively and respond
                meaningfully to others.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Written Communication:</span>{' '}
                <span>
                  Expresses self clearly, accurately, and concisely in written
                  form.
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Verbal Communication:</span>{' '}
                <span>
                  Expresses self clearly, accurately, and concisely in speech.
                </span>
              </div>
            </AccordionItem>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  Personal Effectiveness
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                The ability to perform and maintain effectiveness in the face of
                pressure, challenges, and setbacks.
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Composure:</span>{' '}
                <span>
                  Shows no signs of stress or nervousness; Appropriately
                  expresses and manages emotion; presents a positive and
                  enthusiastic outlook in difficult circumstances; maintains
                  composure under stress and avoids emotional outbursts (without
                  being emotionally ‘flat’)
                </span>
              </div>
              <hr className="mt-2 mb-2" />
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <span className="f-w-b">Resilience:</span>{' '}
                <span>
                  Maintains motivation and performance in the face of challenges
                  and setbacks
                </span>
              </div>
            </AccordionItem>
          </Accordion>
          <div class="mt-3 mb-3 h6 font-weight-bold">
            Assessment Centre Exercises
          </div>
          <Accordion>
            <AccordionItem
              className="gba-accor-content mt-2"
              title={
                <div className="h6 font-weight-bold mt-1 text-uppercase">
                  PSAC Exercises
                </div>
              }>
              <div
                style={{ lineHeight: 1.2, fontSize: 'larger' }}
                className="text-justify">
                <div className="f-w-b mt-2 mb-2">
                  {selectionYear > 2023
                    ? 'Multi-Agency Exercise:'
                    : 'Community Council Exercise:'}
                </div>{' '}
                <div>
                  {selectionYear > 2023
                    ? `In this exercise, the candidates meet as a group of peers (4 to 7) to arrive at a consensus on an issue. The scenario is that each of them represents a different agency that is vying for a dividend fund offered by the government for a use that will benefit the public and nation. They receive information about the objectives and proposed projects of the agency they have been assigned to represent. The candidates have to find a balance between asserting the interests of the group they represent and reaching a consensus for the broader good. In addition, an unexpected request for the fund is received from another organisation near the end of discussion, the intent being to test the candidates’ flexibility and coping skills. This exercise provides evidence for elements of all the competencies.`
                    : `In this exercise, the candidates meet as a group of peers (4 to 7) to arrive at a consensus on an issue. The scenario is
                  that each of them represents a different community group that
                  is vying for a plot of prime land offered by the government
                  for a use that will benefit the public and nation. They
                  receive information about the site itself as well as the
                  activities and objectives of the community group they have
                  been assigned to represent. The candidates have to find a
                  balance between asserting the interests of the group they
                  represent and reaching a consensus for the broader good. In
                  addition, an unexpected request for the land is received from
                  a government ministry near the end of discussion, the intent
                  being to test the candidates’ flexibility and coping skills.
                  This exercise provides evidence for elements of all the
                  competencies.`}
                </div>
                <hr className="mt-2 mb-2" />
                <div className="f-w-b mt-2 mb-2">
                  Policy Presentation Exercise:
                </div>{' '}
                <div>
                  In this exercise, the candidate individually analyses
                  information concerning a complex issue affecting Singapore and
                  offers recommendations as to how it should be addressed. The
                  issue confronting the candidate is fictitious but realistic,
                  involving either a new religious group that has a number of
                  controversial (and potentially illegal) practices or demands
                  from certain constituencies that poorer Singaporeans be given
                  preferential treatment in university admissions and the award
                  of government scholarships. Information concerning this issue
                  is presented to the candidate through a series of simulated
                  documents: newspaper articles, op-ed pieces, online forums,
                  encyclopaedia entries, statutes, academic articles, etc. The
                  candidate reviews these materials, makes a formal presentation
                  to an assessor on their analysis and policy recommendations,
                  and then is questioned by the assessor to clarify their
                  understanding, assumptions and intent.
                </div>
                <hr className="mt-2 mb-2" />
                <div className="f-w-b mt-2 mb-2">
                  Meeting with a Citizen:
                </div>{' '}
                <div>
                  {selectionYear > 2023
                    ? `In this exercise, the candidate meets individually with an actor who is in the role of an irate citizen. The scenario is that the citizen was found to have an out-of-date performance license and had his equipment seized pending payment of a fine. He came to the candidate’s office to lodge a complaint about the penalty imposed and was met with poor service by the candidate’s subordinate. The candidate’s task is to meet with the citizen and handle his queries, complaints and dissatisfaction in an effective manner. This exercise provides evidence for intellectual qualities, character and values, people skills, communication skills, and personal effectiveness.`
                    : `In this exercise, the candidate meets individually with an
                  actor who is in the role of an irate citizen. The scenario is
                  that the citizen was found to have an out-of-date driver’s
                  license and had his vehicle seized pending payment of a fine.
                  He came to the candidate’s office to lodge a complaint about
                  the penalty imposed and was met with poor service by the
                  candidate’s subordinate. The candidate’s task is to meet with
                  the citizen and handle his queries, complaints and
                  dissatisfaction in an effective manner. This exercise provides
                  evidence for intellectual qualities, character and values,
                  people skills, communication skills, and personal
                  effectiveness.`}
                </div>
                <hr className="mt-2 mb-2" />
                <div className="f-w-b mt-2 mb-2">
                  Meeting with a Team Member:
                </div>{' '}
                <div>
                  In this exercise, the candidate is placed in the role of
                  leader of a fictitious community group and has to meet with a
                  team member who has performance issues and concerns about the
                  organisation. The candidate, as leader, meets with him to
                  explore how the team member’s talents can be put to best use
                  within the organisation and identify any actions that need to
                  be taken with respect to the team member and/or the
                  organisation. In this case, the candidate must show judgement,
                  empathy, and commitment in developing an understanding of the
                  situation, managing the interaction, and arriving at a
                  solution that is fair to multiple stakeholders. This exercise
                  provides evidence for elements of all the competencies.
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        </Fragment>
      ) : (
        <NoContentBox
          hideTile
          message="There are no details available for your current selection."
        />
      )}
    </div>
  );
};

export default PSAssessmentCenterReport;
