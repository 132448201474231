import React from 'react';
import { Link } from 'react-router-dom';
import {
  EventsAlt32,
  Compare32,
  Events32,
  Video32,
  UserAvatar32,
  Collaborate32,
  Group32,
  ShareKnowledge32,
  Account32,
  Password32,
  Settings32,
  Report32,
  DataTable32,
  GroupPresentation32,
} from '@carbon/icons-react';
import './Header.css';

const CarbonIconsHeader = (props) => {
  const { label } = props || {};
  let currIcon = '';
  switch (label) {
    case 'All Applicants':
      currIcon = <EventsAlt32 />;
      break;
    case 'Compare':
      currIcon = <Compare32 />;
      break;
    case 'Panel Interviews':
      currIcon = <Events32 />;
      break;
    case 'Video Interviews':
      currIcon = <Video32 />;
      break;
    case 'Profile':
      currIcon = <UserAvatar32 />;
      break;
    case 'Role & Access Management':
      currIcon = <Collaborate32 />;
      break;
    case 'Users':
      currIcon = <Group32 />;
      break;
    case 'Video Interview Settings':
      currIcon = <ShareKnowledge32 />;
      break;
    case 'Audit Logs':
      currIcon = <Account32 />;
      break;
    case 'Reset Panel Password':
      currIcon = <Password32 />;
      break;
    case 'Candidates':
      currIcon = <EventsAlt32 />;
      break;
    case 'Reports':
      currIcon = <Report32 />;
      break;
    case 'Settings':
      currIcon = <Settings32 />;
      break;
    case 'Feedback Templates':
      currIcon = <DataTable32 />;
      break;
    case 'Meetings':
      currIcon = <GroupPresentation32 />;
      break;
    default:
      break;
  }
  return currIcon;
};

const SubItemsHeader = (props) => {
  const { label, description, subItems, handleShowSubMenu, roleId } = props;
  return (
    <div className="bx--tile subitems-card  bx--row  p-0 m-0 justify-content-around align-items-center mt-lg-4 mt-md-5 mt-sm-5 mt-xs-5">
      <div className="bx--col-lg-3 d-flex--wrap mt-3 mb-0">
        <div className="h3 font-weight-bolder font-family-secondary">
          {label}
        </div>
        <h5 className="font-weight-light text-black">{description}</h5>
      </div>
      <div
        className="bx--col-lg-8 d-flex d-flex--wrap mt-4 mb-3 xpa-bdr--left"
        style={{ flexWrap: 'wrap' }}>
        {subItems &&
          Array.isArray(subItems) &&
          subItems.length > 0 &&
          subItems.map((res, index) => {
            const { subLabel, subItemsPath, subItemDesc, subAccessRoles } =
              res || {};
            return (
              <div>
                {subAccessRoles &&
                  Array.isArray(subAccessRoles) &&
                  subAccessRoles.length > 0 &&
                  subAccessRoles.includes(roleId) && (
                    <div
                      className={`d-flex ml-4 ${
                        index > 2 ? '' : ''
                      } xpa-width--200px`}>
                      <div>
                        <CarbonIconsHeader label={subLabel} />
                      </div>
                      <div>
                        <div className="ml-3 h6 text-dark">
                          <Link
                            className=""
                            onClick={() =>
                              handleShowSubMenu([], null, null, false)
                            }
                            to={subItemsPath}>
                            {subLabel}
                          </Link>
                          <div className="small w-100 mt-1 font-weight-light text-dark">
                            {subItemDesc}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SubItemsHeader;
