import React from 'react';
import NoContentBox from '../../components/common/NoContentBox';
import GenericDataTable from '../../components/common/GenericDataTable';
import { Pagination } from 'carbon-components-react';

const CandidateListStages = (props) => {
  const {
    selectedTab,
    candidateData,
    activePage,
    handlePaginationChange,
    candidateCount,
    batchActionClickHandler,
    handleSearchSubmit
  } = props || {};

  let headers = [
    { key: 'header_0', header: 'Name' },
    { key: 'header_1', header: 'NRIC' },
  ];

  let batchActions = [
    {
      label: 'Assign Candidates',
      key: 'assign-candidates',
    },
  ];

  if (selectedTab === 4) {
    batchActions.push({
      label: 'Submit to PI',
      key: 'submit-candidates-pi',
    });
  }

  return (
    <div>
      <GenericDataTable
        tbodyClass="candidate-background-color"
        placeHolderText="Search by Name / NRIC"
        otherActions={[]}
        searchable={true}
        onSearchInputChange={handleSearchSubmit}
        selectable
        batchActions={batchActions}
        batchActionClick={batchActionClickHandler}
        headers={headers}
        rows={
          candidateData &&
          Array.isArray(candidateData) &&
          candidateData.length > 0
            ? candidateData.map((interview, idx) => {
                const { application } = interview || {};
                const { profile, appId, selectionYear } =
                  (application &&
                    Array.isArray(application) &&
                    application.length > 0 &&
                    application[0]) ||
                  [];
                const { fullName, nric } = profile || {};
                const nricSubStr =
                  nric &&
                  nric.toString() &&
                  nric.toString().length >= 6 &&
                  nric.toString().substring(1, 5);
                const finalNric =
                  nric &&
                  nricSubStr &&
                  nric.toString().replace(nricSubStr, 'xxxx');

                return {
                  isExpanded: false,
                  id: `${appId}`,
                  key: `${appId}`,
                  header_0: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Name:
                      </div>
                      <div className="xpa-link f-w-b h6 mb-0 text-capitalize bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        <a
                          className="css-cand-name"
                          href={`/app/csc/candidate/${appId}/batch/${selectionYear}/status/personal-info`}>
                          {fullName}
                        </a>
                      </div>
                    </div>
                  ),
                  header_1: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-2 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        NRIC:
                      </div>
                      <div className="text-uppercase bx--col-lg-12 bx--col-md-6 bx--col-sm-6 bx--col-xs-6">
                        {nric}
                      </div>
                    </div>
                  ),
                };
              })
            : []
        }
      />
      {((candidateData &&
        Array.isArray(candidateData) &&
        candidateData.length <= 0) ||
        !candidateData) && (
        <NoContentBox message="There are no candidates available for your current selection" />
      )}
      {candidateCount > 0 && (
        <Pagination
          onChange={handlePaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={activePage}
          totalItems={candidateCount}
        />
      )}
    </div>
  );
};

export default CandidateListStages;
