import React from 'react';
import { Tabs, Tab } from 'carbon-components-react';

const GenericTabs = (props) => {
  const { tabContent, selected, className, type = 'default' } = props;
  return (
    <Tabs
      className={`${className}`}
      triggerHref="#anotherAnchor"
      type={type}
      selected={selected || 0}>
      {tabContent.map((tab, tabIdx) => (
        <Tab
          id={tab && tab.tabSelected ? tab.tabSelected : tabIdx}
          key={tab && tab.tabSelected ? tab.tabSelected : tabIdx}
          onClick={() => {
            if (tab.onClick) tab.onClick();
          }}
          className={tab.className}
          onKeyDown={tab.onKeyDown ? tab.onKeyDown : () => {}}
          label={tab.label}
          title={tab?.title}>
          {tab && tab.value && <div className="some-content">{tab.value}</div>}
        </Tab>
      ))}
    </Tabs>
  );
};

export default GenericTabs;
