const getCandidatePreferences = (data) => {
  const {
    CareerPreference,
    CountryPreference,
    CoursePreference,
    universityAppln,
  } = data || {};
  let finalCareerPreferences = [];
  if (
    CareerPreference &&
    Array.isArray(CareerPreference) &&
    CareerPreference.length > 0
  ) {
    finalCareerPreferences = CareerPreference.filter((careerRes) => {
      const { career } = careerRes || {};
      const { displayName } = career || {};
      if (displayName) {
        return true;
      }
      return false;
    });
  }
  let finalUniversityPreferences = [];
  if (
    universityAppln &&
    Array.isArray(universityAppln) &&
    universityAppln.length > 0
  ) {
    finalUniversityPreferences = universityAppln.filter((univ, index) => {
      const { company } = univ || {};
      const { displayName } = company || {};
      if (displayName) {
        return true;
      }
      return false;
    });
  }
  let finalCoursePreferences = [];
  if (
    CoursePreference &&
    Array.isArray(CoursePreference) &&
    CoursePreference.length > 0
  ) {
    finalCoursePreferences = CoursePreference.filter((courseRes, index) => {
      const { subject } = courseRes || {};
      const { displayName } = subject || {};
      if (displayName) {
        return true;
      }
      return false;
    });
  }

  let finalCountryPreferences = [];
  if (
    CountryPreference &&
    Array.isArray(CountryPreference) &&
    CountryPreference.length
  ) {
    finalCountryPreferences = CountryPreference.filter((countryRes, index) => {
      const { country } = countryRes || {};
      const { displayName } = country || {};
      if (displayName) {
        return true;
      }
      return false;
    });
  }
  return {
    finalCareerPreferences,
    finalCountryPreferences,
    finalCoursePreferences,
    finalUniversityPreferences,
  };
};

const getCCATierData = (data) => {
  const { ccatiering, schoolDetails } = data || {};
  const schoolLevelDefArr = [
    'IB School',
    'NUS High School',
    'Junior College/IP School',
    'Polytechnic',
  ];

  let filteredSchoolDetailsList = [];
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    filteredSchoolDetailsList = schoolDetails.filter((school) => {
      const { schoollevel } = school || {};
      const { displayName } = schoollevel || {};
      if (schoolLevelDefArr.includes(displayName)) {
        return true;
      }
      return false;
    });
  }
  let latestSchoolLevelOrder = [];
  if (
    filteredSchoolDetailsList &&
    Array.isArray(filteredSchoolDetailsList) &&
    filteredSchoolDetailsList.length > 0
  ) {
    latestSchoolLevelOrder = filteredSchoolDetailsList.map((school) => {
      const { company } = school || {};
      const { displayName } = company || {};
      return displayName;
    });
  }
  const finalCCAOrderArr = [];
  if (
    latestSchoolLevelOrder &&
    Array.isArray(latestSchoolLevelOrder) &&
    latestSchoolLevelOrder.length > 0
  ) {
    latestSchoolLevelOrder.forEach((latestSchoolLevel) => {
      if (ccatiering && Array.isArray(ccatiering) && ccatiering.length > 0) {
        ccatiering.forEach((tier) => {
          const { company } = tier;
          const { displayName } = company || {};
          if (latestSchoolLevel === displayName) {
            finalCCAOrderArr.push(tier);
          }
        });
      }
    });
  }
  return finalCCAOrderArr;
};

export { getCandidatePreferences, getCCATierData };
