import React from 'react';

const CombinedEvaluationForm = (props) => {
  const { combinedEvalForm, evalStaticData, selectionYear } = props || {};
  return (
    <div className="d-flex">
      <div className="overflow-scroll w-100">
        <table className="table-border w-100 table-zoom-auto">
          <thead>
            <th className="trait-min-width">
              <div className="font-size-x-lg">
                {selectionYear > 2023 ? 'Competencies' : 'Trait'}
              </div>
            </th>
            <th className="results-min-width">
              <div>1</div>
              <small>Very Weak</small>
            </th>
            <th className="results-min-width">
              <div>2</div>
              <small>Weak</small>
            </th>
            <th className="results-min-width">
              <div>3</div>
              <small>Moderate</small>
            </th>
            <th className="results-min-width">
              <div>4</div>
              <small>Strong</small>
            </th>
            <th className="results-min-width">
              <div>5</div>
              <small>Very Strong</small>
            </th>
            <th className="results-min-width">
              <small className="f-w-b">Not able to tell</small>
            </th>
          </thead>
          <tbody className="table-border">
            {evalStaticData &&
              Array.isArray(evalStaticData) &&
              evalStaticData.length > 0 &&
              evalStaticData.map((values) => {
                const { evalName, evalDesc, evalPropName } = values || {};
                const currObj =
                  combinedEvalForm && combinedEvalForm[evalPropName];
                const veryWeakArr = currObj && currObj.veryWeak;
                const weakArr = currObj && currObj.weak;
                const moderateArr = currObj && currObj.moderate;
                const strongArr = currObj && currObj.strong;
                const veryStrongArr = currObj && currObj.veryStrong;
                const noneArr = currObj && currObj.none;
                return (
                  <tr>
                    <td className="text-left">
                      <div className="font-weight-bold mb-2 h5">{evalName}</div>
                      <div style={{ fontSize: 'large' }}>{evalDesc}</div>
                    </td>
                    <td className="css-table-row-data">
                      <div
                        className={`${
                          veryWeakArr &&
                          Array.isArray(veryWeakArr) &&
                          veryWeakArr.length > 3
                            ? ''
                            : 'justify-content-center'
                        } bx--row align-items-center`}>
                        {veryWeakArr &&
                          Array.isArray(veryWeakArr) &&
                          veryWeakArr.length > 0 &&
                          veryWeakArr.map((d, idx) => (
                            <div
                              className={`bx--col-lg-1 bx--col-md-1 mt-1 mb-1 ${
                                veryWeakArr &&
                                Array.isArray(veryWeakArr) &&
                                veryWeakArr.length === 1
                                  ? 'pl-0'
                                  : ''
                              }`}>
                              <i
                                className={`fa fa-circle trait-color-${d}`}
                                aria-hidden="true"
                              />
                            </div>
                          ))}
                      </div>
                    </td>
                    <td className="css-table-row-data">
                      <div
                        className={`${
                          weakArr &&
                          Array.isArray(weakArr) &&
                          weakArr.length > 3
                            ? ''
                            : 'justify-content-center'
                        } bx--row align-items-center`}>
                        {weakArr &&
                          Array.isArray(weakArr) &&
                          weakArr.length > 0 &&
                          weakArr.map((d, idx) => (
                            <div
                              className={`bx--col-lg-1 bx--col-md-1 mt-1 mb-1 ${
                                weakArr &&
                                Array.isArray(weakArr) &&
                                weakArr.length === 1
                                  ? 'pl-0'
                                  : ''
                              }`}>
                              <i
                                className={`fa fa-circle trait-color-${d}`}
                                aria-hidden="true"
                              />
                            </div>
                          ))}
                      </div>
                    </td>
                    <td className="css-table-row-data">
                      <div
                        className={`${
                          moderateArr &&
                          Array.isArray(moderateArr) &&
                          moderateArr.length > 3
                            ? ''
                            : 'justify-content-center'
                        } bx--row align-items-center`}>
                        {moderateArr &&
                          Array.isArray(moderateArr) &&
                          moderateArr.length > 0 &&
                          moderateArr.map((d, idx) => (
                            <div
                              className={`bx--col-lg-1 bx--col-md-1 mt-1 mb-1 ${
                                moderateArr &&
                                Array.isArray(moderateArr) &&
                                moderateArr.length === 1
                                  ? 'pl-0'
                                  : ''
                              }`}>
                              <i
                                className={`fa fa-circle trait-color-${d}`}
                                aria-hidden="true"
                              />
                            </div>
                          ))}
                      </div>
                    </td>
                    <td className="css-table-row-data">
                      <div
                        className={`${
                          strongArr &&
                          Array.isArray(strongArr) &&
                          strongArr.length > 3
                            ? ''
                            : 'justify-content-center'
                        } bx--row align-items-center`}>
                        {strongArr &&
                          Array.isArray(strongArr) &&
                          strongArr.length > 0 &&
                          strongArr.map((d, idx) => (
                            <div
                              className={`bx--col-lg-1 bx--col-md-1 mt-1 mb-1 ${
                                strongArr &&
                                Array.isArray(strongArr) &&
                                strongArr.length === 1
                                  ? 'pl-0'
                                  : ''
                              }`}>
                              <i
                                className={`fa fa-circle trait-color-${d}`}
                                aria-hidden="true"
                              />
                            </div>
                          ))}
                      </div>
                    </td>
                    <td className="css-table-row-data">
                      <div
                        className={`${
                          veryStrongArr &&
                          Array.isArray(veryStrongArr) &&
                          veryStrongArr.length > 3
                            ? ''
                            : 'justify-content-center'
                        } bx--row align-items-center`}>
                        {veryStrongArr &&
                          Array.isArray(veryStrongArr) &&
                          veryStrongArr.length > 0 &&
                          veryStrongArr.map((d, idx) => (
                            <div
                              className={`bx--col-lg-1 bx--col-md-1 mt-1 mb-1 ${
                                veryStrongArr &&
                                Array.isArray(veryStrongArr) &&
                                veryStrongArr.length === 1
                                  ? 'pl-0'
                                  : ''
                              }`}>
                              <i
                                className={`fa fa-circle trait-color-${d}`}
                                aria-hidden="true"
                              />
                            </div>
                          ))}
                      </div>
                    </td>
                    <td className="css-table-row-data">
                      <div
                        className={`${
                          noneArr &&
                          Array.isArray(noneArr) &&
                          noneArr.length > 3
                            ? ''
                            : 'justify-content-center'
                        } bx--row align-items-center`}>
                        {noneArr &&
                          Array.isArray(noneArr) &&
                          noneArr.length > 0 &&
                          noneArr.map((d, idx) => (
                            <div
                              className={`bx--col-lg-1 bx--col-md-1 mt-1 mb-1 ${
                                noneArr &&
                                Array.isArray(noneArr) &&
                                noneArr.length === 1
                                  ? 'pl-0'
                                  : ''
                              }`}>
                              <i
                                className={`fa fa-circle trait-color-${d}`}
                                aria-hidden="true"
                              />
                            </div>
                          ))}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CombinedEvaluationForm;
