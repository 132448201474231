import React, { Fragment } from 'react';

import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import Chart from 'react-google-charts';
import './CarbonGraphsStyles.css';

const NivoScatterGraph = (props) => {
  const {
    data,
    showLegend,
    heading,
    yScale,
    xScale,
    margin,
    axisBottom,
    axisTop,
    axisLeft,
    axisRight,
    nodeSize,
    max,
    title,
    hideGraph,
  } = props || {};
  const finalObj = {};
  const finalColors = [];
  const candidateDataArr = ['Candidate'];
  if (data && Array.isArray(data) && data.length > 0) {
    data.forEach((res) => {
      const { id, data: candidateData, color } = res || {};
      candidateDataArr.push(id);
      finalColors.push(color);
      if (
        candidateData &&
        Array.isArray(candidateData) &&
        candidateData.length > 0
      ) {
        candidateData.forEach((cand) => {
          const { x, y } = cand || {};
          const finalYValue = y && Number(y).toFixed(2);
          if (x) {
            if (finalObj && finalObj[x]) {
              finalObj[x].push(finalYValue && Number(finalYValue));
            } else {
              finalObj[x] = [finalYValue && Number(finalYValue)];
            }
          }
        });
      }
    });
  }
  const finalData = [candidateDataArr];
  const keys = finalObj && Object.keys(finalObj);
  if (keys && Array.isArray(keys) && keys.length > 0) {
    keys.forEach((key) => {
      const finalArr = [key];
      finalArr.push(...finalObj[key]);
      finalData.push(finalArr);
    });
  }
  const options = {
    title: '',
    curveType: 'function',
    legend: 'none',
    vAxis: { title, viewWindow: { min: 0, max: max } },
    enableInteractivity: true,
    colors: finalColors,
  };
  return (
    <Fragment>
      <h4 className="text-capitalize">{heading}</h4>
      {showLegend === true && (
        <div className="bx--row ml-0 mt-2">
          {data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((res) => {
              const { id, color } = res || {};
              return (
                <div className="d-flex align-items-center mr-2 mb-2">
                  <div
                    style={{ backgroundColor: color }}
                    className="legend-box"
                  />
                  <div className="small ml-1 text-capitalize">
                    {id} {id === 'Average' ? '(PI+AC+PA)' : ''}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {hideGraph !== true && (
        <Chart
          chartType="ScatterChart"
          width="100%"
          height="400px"
          data={finalData}
          options={options}
          legendToggle
          chartEvents={[
            {
              eventName: 'ready',
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                google.visualization.events.addListener(
                  chart,
                  'onmouseover',
                  (e) => {
                    const { row, column } = e;
                    console.warn('MOUSE OVER ', { row, column });
                  }
                );
                google.visualization.events.addListener(
                  chart,
                  'onmouseout',
                  (e) => {
                    const { row, column } = e;
                    console.warn('MOUSE OUT ', { row, column });
                  }
                );
              },
            },
          ]}
        />
      )}

      {/* <ResponsiveScatterPlot
        data={data}
        margin={margin}
        xScale={xScale}
        yScale={yScale}
        axisTop={axisTop}
        axisRight={axisRight}
        colors={(d) => d.color}
        blendMode="normal"
        axisBottom={axisBottom}
        tooltip={(tooltipObj) => {
          const { node } = tooltipObj || {};
          const { data } = node || {};
          const { y: yValue } = data || {};
          return (
            <div className="tooltip-sc-box tooltip-box-bg d-flex justify-content-center align-items-center">
              <div>{(yValue && Number(yValue).toFixed(1)) || 0}</div>
            </div>
          );
        }}
        axisLeft={axisLeft}
        animate={false}
        nodeSize={nodeSize}
        legends={[]}
        enableGridX={false}
        enableGridY
      /> */}
    </Fragment>
  );
};

export default NivoScatterGraph;
