import React from 'react';

const SignedInErrorView = () => (
  <div className="bx--tile p-5">
    <div className="">
      <div className=" bx--row  flex-column align-items-center">
        <div className="empty-icon">
          <i className="h1 icon-flag" style={{ fontSize: '60px' }} />
        </div>
        <p className="bx--type-beta pt-4 pb-4 mb-0">You are already signed in as an user</p>
        <p>Please <a href="/auth/logout">logout</a> and re-visit this link to continue.</p>
      </div>
    </div>
  </div>
);

const SignedInSuccessView = () => (
  <div className="card-invite">
    <div className="card card-content">
      <div className=" bx--row  flex-column align-items-center">
        <div className="empty-icon">
          <i className="h1 icon-flag" style={{ fontSize: '60px' }} />
        </div>
        <p className="bx--type-beta p-4 pb-4 mb-0 text-center">You are successfully associated with the company, please sign in to continue.</p>
        <p>Please login to continue.</p>
        <a href="/" className="bx--btn bx--btn--tertiary ">Login</a>
      </div>
    </div>
  </div>
);

const InviteErrorView = () => (
  <div className="card-invite">
    <div className="card card-content">
      <div className=" bx--row  flex-column align-items-center">
        <div className="empty-icon">
          <i className="h1 icon-flag" style={{ fontSize: '60px' }} />
        </div>
        <p className="bx--type-beta p-4 pb-4 mb-0 text-center">Invitation link is expired.</p>
        <p>This can be because you already accepted the invitation or the link is revoked.</p>
      </div>
    </div>
  </div>
);

export {
  SignedInErrorView,
  SignedInSuccessView,
  InviteErrorView,
};