import {
  REQUEST_GET_DASHBOARD_ANALYTICS,
  SUCCESS_GET_DASHBOARD_ANALYTICS,
  ERROR_GET_DASHBOARD_ANALYTICS,
  REQUEST_GET_SECOND_ROUNDERS,
  SUCCESS_GET_SECOND_ROUNDERS,
  ERROR_GET_SECOND_ROUNDERS,
  REQUEST_PBI_TOKENS,
  SUCCESS_PBI_TOKENS,
  ERROR_PBI_TOKENS,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  batchStatistics: {},
  reservedCandidates: [],
  confirmedCandidates: [],
  recommendedByAgency: [],
  potentialCandidates: [],
  pastCandidates: [],
  pbitokens: {},
};

const Dashboard = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_DASHBOARD_ANALYTICS:
      return {
        ...state,

        loading: true,
        error: null,
      };
    case SUCCESS_GET_DASHBOARD_ANALYTICS:
      return {
        ...state,
        batchStatistics: payload && payload.statistics,
        loading: false,
        error: null,
      };
    case ERROR_GET_DASHBOARD_ANALYTICS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_SECOND_ROUNDERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_SECOND_ROUNDERS:
      const {
        reserved,
        confirmed,
        recommendedByAgency,
        potential,
        pastCandidates: pastCandsList,
      } = payload || {};
      return {
        ...state,
        reservedCandidates: reserved,
        confirmedCandidates: confirmed,
        recommendedByAgency: recommendedByAgency,
        potentialCandidates: potential,
        pastCandidates: pastCandsList,
        loading: false,
        error: null,
      };
    case ERROR_GET_SECOND_ROUNDERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_PBI_TOKENS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case ERROR_PBI_TOKENS: {
      return {
        ...state,
        ...payload,
        error: true,
        loading: false,
      };
    }
    case SUCCESS_PBI_TOKENS: {
      return {
        ...state,
        pbitokens: payload,
        error: null,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default Dashboard;
