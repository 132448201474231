import React from 'react';

//Functional Components
import CandidateEvaluationRatings from './CandidateEvaluationRatings';

import {
  RadioButton,
  TextArea,
  Button,
  InlineNotification,
  Accordion,
  AccordionItem,
} from 'carbon-components-react';
import moment from 'moment';
import psStaticDataEval from '../../staticdata/EvaluationFormStaticData';

const PSEvalFormTable = (props) => {
  const {
    handleRadioBtnChange,
    isFormSubmitted,
    userId,
    psEvalId,
    isEditForm,
    state,
    response,
    handleCommisionRadioBtnChange,
    isValidCommRating,
    handleCloseCommNotif,
    handleChange,
  } = props || {};
  const { psIndvidualRating, comments, isValidComments } = state || {};
  return (
    <div>
      <div className="d-flex">
        <div className="overflow-scroll w-100">
          <CandidateEvaluationRatings
            evalStaticData={psStaticDataEval}
            handleRadioBtnChange={handleRadioBtnChange}
            isFormSubmitted={isFormSubmitted}
            userId={userId}
            evaluationId={psEvalId}
            isEditForm={isEditForm}
            state={state}
            response={response}
          />
        </div>
      </div>
      <div className="color-blue text-center table-border mt-2 p-3">
        Overall rating on the candidate’s suitability for President’s
        Scholarship
      </div>
      <div className="mt-2">
        <div className="font-weight-bold mb-2">
          Each Commission member should rate this section based on his/her
          independent opinion of the candidate. It may differ from the final
          selection outcome.
        </div>
      </div>
      {isValidCommRating === true && (
        <InlineNotification
          lowContrast
          title="Please select valid rating for each trait"
          iconDescription="describes the close button"
          kind="error"
          onCloseButtonClick={handleCloseCommNotif}
        />
      )}
      <div
        className={`mt-2 mb-2 ${
          !isEditForm && isFormSubmitted && 'csc-radio-btn-disabled'
        }`}>
        <div className="d-flex justify-content-left mt-2">
          <RadioButton
            name={
              !isFormSubmitted || isEditForm === true
                ? `${userId}-radio-eval`
                : `${psEvalId}-radio-val`
            }
            id={
              !isFormSubmitted || isEditForm === true
                ? `unsuitable-${userId}`
                : `${psEvalId}-radio-val`
            }
            key={
              !isFormSubmitted || isEditForm === true
                ? `unsuitable-${userId}`
                : `${psEvalId}-radio-val`
            }
            value={
              !isFormSubmitted || isEditForm === true
                ? psIndvidualRating
                : response && response.unsuitable
            }
            checked={
              !isFormSubmitted || isEditForm === true
                ? psIndvidualRating === 'unsuitable'
                : response && response.psIndvidualRating === 'unsuitable'
            }
            labelText="Unsuitable"
            onChange={() => {
              handleCommisionRadioBtnChange('unsuitable');
            }}
            disabled={!isEditForm ? isFormSubmitted : false}
          />
        </div>
        <div className="d-flex justify-content-left mt-2">
          <RadioButton
            name={
              !isFormSubmitted || isEditForm === true
                ? `${userId}-radio-eval`
                : `${psEvalId}-radio-val`
            }
            id={
              !isFormSubmitted || isEditForm === true
                ? `shortlist-${userId}`
                : `${psEvalId}-radio-val`
            }
            key={
              !isFormSubmitted || isEditForm === true
                ? `shortlist-${userId}`
                : `${psEvalId}-radio-val`
            }
            value={
              !isFormSubmitted || isEditForm === true
                ? psIndvidualRating
                : response && response.shortlist
            }
            checked={
              !isFormSubmitted || isEditForm === true
                ? psIndvidualRating === 'shortlist'
                : response && response.psIndvidualRating === 'shortlist'
            }
            labelText="Shortlist for further deliberation after all candidates have been interviewed"
            onChange={() => {
              handleCommisionRadioBtnChange('shortlist');
            }}
            disabled={!isEditForm ? isFormSubmitted : false}
          />
        </div>
        <div className="d-flex justify-content-left mt-2">
          <RadioButton
            name={
              !isFormSubmitted || isEditForm === true
                ? `${userId}-radio-eval`
                : `${psEvalId}-radio-val`
            }
            id={
              !isFormSubmitted || isEditForm === true
                ? `award-${userId}`
                : `${psEvalId}-radio-val`
            }
            key={
              !isFormSubmitted || isEditForm === true
                ? `award-${userId}`
                : `${psEvalId}-radio-val`
            }
            value={
              !isFormSubmitted || isEditForm === true
                ? psIndvidualRating
                : response && response.award
            }
            checked={
              !isFormSubmitted || isEditForm === true
                ? psIndvidualRating === 'award'
                : response && response.psIndvidualRating === 'award'
            }
            labelText="To Award President’s Scholarship (proceeding for studies this year)"
            onChange={() => {
              handleCommisionRadioBtnChange('award');
            }}
            disabled={!isEditForm ? isFormSubmitted : false}
          />
        </div>
        <div className="d-flex justify-content-left mt-2">
          <RadioButton
            name={
              !isFormSubmitted || isEditForm === true
                ? `${userId}-radio-eval`
                : `${psEvalId}-radio-val`
            }
            id={
              !isFormSubmitted || isEditForm === true
                ? `offer-${userId}`
                : `${psEvalId}-radio-val`
            }
            key={
              !isFormSubmitted || isEditForm === true
                ? `offer-${userId}`
                : `${psEvalId}-radio-val`
            }
            value={
              !isFormSubmitted || isEditForm === true
                ? psIndvidualRating
                : response && response.offer
            }
            checked={
              !isFormSubmitted || isEditForm === true
                ? psIndvidualRating === 'offer'
                : response && response.psIndvidualRating === 'offer'
            }
            labelText="To Offer Provisional President’s Scholarship (deferring studies)"
            onChange={() => {
              handleCommisionRadioBtnChange('offer');
            }}
            disabled={!isEditForm ? isFormSubmitted : false}
          />
        </div>
        <div className="mt-2">
          <div className="mt-2 mb-2 font-weight-bold">Comments</div>
          <TextArea
            name={
              !isFormSubmitted || isEditForm === true
                ? 'comments'
                : `${psEvalId}-comments`
            }
            id={
              !isFormSubmitted || isEditForm === true
                ? 'comments'
                : `${psEvalId}-comments`
            }
            value={
              !isFormSubmitted || isEditForm === true
                ? comments
                : response && response.comments
            }
            labelText=""
            hideLabel
            disabled={!isEditForm ? isFormSubmitted : false}
            onChange={handleChange}
            placeholder="Please enter your comments"
            invalid={isValidComments}
            invalidText="Please enter valid comments"
          />
        </div>
      </div>
    </div>
  );
};

export default PSEvalFormTable;
