import React, { memo } from "react";
import hex from "text-hex";

const defaultAvatarLetter = (firstname, lastname) => {
  return firstname
    ? firstname.toUpperCase().charAt(0)
    : lastname
    ? lastname.toUpperCase().charAt(0)
    : "-";
};

const defaultAvatar = (firstname, lastname, size, classNameInfo) => {
  return size > 0 ? (
    <span
      className={`img${size}_${size} xp-avatar-${size}  ${classNameInfo}`}
      style={{
        background: hex(firstname + " " + lastname),
        color: "white",
        lineHeight: `${size}px`,
        paddingTop: 0
      }}
    >
      {defaultAvatarLetter(firstname, lastname)}
    </span>
  ) : (
    <span
      className={`${classNameInfo}`}
      style={{ background: hex(firstname + " " + lastname), color: "white" }}
    >
      {defaultAvatarLetter(firstname, lastname)}
    </span>
  );
};

const Avatar = props => {
  const {
    user: { picture, firstname = "", lastname = "" } = {},
    isCompanyLogo = false
  } = props;
  let image = picture;
  let imageprefix = `https://x0pa-profile-images.s3.ap-southeast-1.amazonaws.com`;
  if (isCompanyLogo) {
    imageprefix = `https://s3-ap-southeast-1.amazonaws.com/x0pa-companies`;
  }
  if (
    picture &&
    picture.indexOf &&
    !(picture.indexOf("http:") >= 0 || picture.indexOf("https:") >= 0)
  ) {
    image = `${imageprefix}/${picture}`;
  }
  const imgClass = `img${props.size}_${props.size}`;
  return image ? (
    <img
      src={image}
      alt=""
      className={`${props.relaxSize ? '' : imgClass} ${
        props.classNameInfo ? props.classNameInfo : ""
      }`}
      onError={e =>
        (e.target.src =
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7")
      }
    />
  ) : (
    defaultAvatar(firstname, lastname, props.size, props.classNameInfo)
  );
};

export default memo(Avatar);
