import React from 'react';

//Functional Components
import NoContentBox from '../common/NoContentBox';

//Common Packages
import { Pagination } from 'carbon-components-react';
import { roundNumber } from '../../containers/common/common';

//CSS Files
import './PSSummaryOfScores.css';

const PSPsychologicalInterviewsData = (props) => {
  const {
    psListData,
    handlePsyPaginationChange,
    psyActivePage,
    psyItemsPerPage,
  } = props || {};
  const { psyRecords } = psListData || {};
  const { list, totalCount } = psyRecords || {};
  let finalPSListData = [];
  if (list && Array.isArray(list) && list.length > 0) {
    finalPSListData = list.filter((res) => {
      const {
        psy_leadership_qualities: leadershipQualitiesRating,
        psy_intellectual_qualities: intellectualQualitiesRating,
        psy_character_and_values: characterValuesRating,
        psy_interpersonal_skills: interPersonalSkills,
        psy_resilience: resilience,
      } = res || {};
      if (
        leadershipQualitiesRating > 0 ||
        intellectualQualitiesRating > 0 ||
        characterValuesRating > 0 ||
        interPersonalSkills > 0 ||
        resilience > 0
      ) {
        return true;
      }
      return false;
    });
  }
  const psySkip =
    psyActivePage === 0 ? 0 : (psyActivePage - 1) * psyItemsPerPage;
  return (
    <div className="mt-5 mt-xl-2">
      <h4 class="mt-2 mb-2">Psychological Interview</h4>
      <div class="mt-2 mb-3">
        (Ranked by Mean Psychological Interview Score)
      </div>
      <div className="overflow-scroll">
        <table className="css-table-batch-info w-100 css-statistics-zoom-auto">
          <thead className="css-total-bg vertical-align-middle">
            <tr>
              <th
                className="csc-scores-rank-w css-batch-metric-border-r css-metric-table-head vertical-align-middle"
                rowspan="2">
                Rank
              </th>
              <th
                className="css-batch-metric-border-r css-metric-table-head text-left pl-2 vertical-align-middle csc-cand-name-w"
                rowspan="2">
                Name
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Leadership
              </th>
              <th className="css-batch-metric-border-r css-traits-border-b   css-table-head csc-traits-w vertical-align-middle">
                Intellectual
              </th>
              <th className="css-batch-metric-border-r css-traits-border-b   css-table-head csc-traits-w vertical-align-middle">
                Character &amp; Values
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Inter-personal
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Resilience
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Total
              </th>
              <th className="css-batch-metric-border-r  css-traits-border-b  css-table-head csc-traits-w vertical-align-middle">
                Mean
              </th>
            </tr>
          </thead>
          {finalPSListData &&
            Array.isArray(finalPSListData) &&
            finalPSListData.length > 0 && (
              <tbody>
                {finalPSListData.map((res, index) => {
                  const {
                    fullName,
                    psy_leadership_qualities: leadershipQualitiesRating,
                    psy_intellectual_qualities: intellectualQualitiesRating,
                    psy_character_and_values: characterValuesRating,
                    psy_interpersonal_skills: interPersonalSkills,
                    psy_resilience: resilience,
                    customRank,
                  } = res || {};
                  const total =
                    (leadershipQualitiesRating &&
                      Number(leadershipQualitiesRating)) +
                      (intellectualQualitiesRating &&
                        Number(intellectualQualitiesRating)) +
                      (characterValuesRating && Number(characterValuesRating)) +
                      (interPersonalSkills && Number(interPersonalSkills)) +
                      (resilience && Number(resilience)) || 0;
                  const mean = total > 0 ? total / 5 : 0;
                  return (
                    <tr className="text-center vertical-align-middle css-table-row-h">
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r">
                        {customRank}
                      </td>
                      <td className="css-batch-metric-border vertical-align-middle css-batch-metric-border-r css-total-f-w pl-2 text-left">
                        <div className="csc-cand-name-f-sz text-capitalize">
                          {fullName || '-'}
                        </div>
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(leadershipQualitiesRating) <= 2 &&
                          leadershipQualitiesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(leadershipQualitiesRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(intellectualQualitiesRating) <= 2 &&
                          intellectualQualitiesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(intellectualQualitiesRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(characterValuesRating) <= 2 &&
                          characterValuesRating > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(characterValuesRating, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(interPersonalSkills) <= 2 &&
                          interPersonalSkills > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(interPersonalSkills, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(resilience) <= 2 && resilience > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(resilience, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(total) <= 2 && total > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(total, '-')}
                      </td>
                      <td
                        className={`css-batch-metric-border vertical-align-middle css-batch-metric-border-r ${
                          roundNumber(mean) <= 2 && mean > 0
                            ? 'csc-shaded-bg'
                            : ''
                        }`}>
                        {roundNumber(mean, '-')}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
        </table>
      </div>
      {((finalPSListData &&
        Array.isArray(finalPSListData) &&
        finalPSListData.length <= 0) ||
        !finalPSListData) && (
        <NoContentBox
          hideTile
          message="There are no details available for your current selection"
        />
      )}
      {totalCount > 0 && (
        <Pagination
          onChange={handlePsyPaginationChange}
          pageSizes={[10, 20, 30, 40, 50]}
          page={psyActivePage}
          totalItems={totalCount}
          pageSize={psyItemsPerPage}
        />
      )}
      {finalPSListData &&
        Array.isArray(finalPSListData) &&
        finalPSListData.length > 0 && (
          <div>
            <div class="mb-2 mt-3 text-justify" style={{ lineHeight: 1.3 }}>
              <span class="f-w-b">Banding Scale</span> - 5 (Excellent), 4
              (Strong), 3 (Moderate), 2(Weak), 1 (Very Weak).
            </div>
            <div class="css-default-text-color f-w-b mb-2 mt-2">
              Ratings of 2 and below are shaded.
            </div>
          </div>
        )}

      <hr />
    </div>
  );
};

export default PSPsychologicalInterviewsData;
