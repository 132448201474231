import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select, SelectItem, Modal, TextInput } from 'carbon-components-react';
import Dropdown from '../../../components/common/Dropdown';
import DropDownBoxWithCreate from '../../../components/common/DropDownBoxWithCreate';
import nric from 'nric';
import isEmail from 'validator/lib/isEmail';
import phone from 'phone';
import { withRouter } from 'react-router-dom';

import {
  addAsessmentCandidate,
  updateAsessmentCandidate,
  getAllCountries,
  getAsessmentScenarios,
  getAsessCompanyTypes,
  getAsessCompaniesById,
  createAssessCompany,
} from '../../../actions/Assessments';

class AssessAddCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryId: 65,
    };
  }

  componentDidMount() {
    const { assessCandInfo = {}, match, assessCompanyTypes } = this.props;
    const { params } = match || {};
    const { candId } = params || {};
    if (candId) {
      const { email, Company, Gender, contactNumber, Scenario } =
        assessCandInfo || {};
      const { displayName: orgDisplayName, companyId, ctypeId } = Company || {};
      const filteredCmpTypeList = assessCompanyTypes?.filter(
        (cmpType) => cmpType?.ctypeId == ctypeId
      );
      const { ctypeName } = filteredCmpTypeList?.[0] || {};
      const { genderName, genderId } = Gender || {};
      const { displayName: scenariodisplayName, scenarioId } = Scenario || {};
      this.setState({
        ...assessCandInfo,
        emailId: email,
        selectedOrgType: { label: ctypeName, value: ctypeId },
        selectedOrgNameValue: { label: orgDisplayName, value: companyId },
        selectedScenario: { label: scenariodisplayName, value: scenarioId },
        selectedGender: { label: genderName, value: genderId },
        candidatePhoneNumber: contactNumber,
      });
    }
  }

  handleChange = (event) => {
    const { target: { name, value } = {} } = event;
    if (name === 'nric') {
      this.setState({ isNRICInvalid: !nric.validate(value) });
    }
    this.setState({
      [name]: value,
    });
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState(
      {
        [name]: metaValue,
      },
      () => {
        if (name === 'selectedOrgType') {
          this.setState({ selectedOrgNameValue: null });
        }
      }
    );
  };

  isInputInValid = (input) => {
    if (!input || (input && !input?.toString()?.trim())) {
      return true;
    }
    return false;
  };

  isPhoneNumberInvalid = (phoneNumber, countryId) => {
    const inputPhoneNumber = phoneNumber?.toString()?.trim();
    if (!inputPhoneNumber || inputPhoneNumber?.length == 8) {
      return false;
    }
    return true;
  };

  handleSubmitAddCand = () => {
    const { match } = this.props;
    const { params } = match || {};
    const { candId } = params || {};
    const {
      nric: nricVal,
      fullName,
      firstName,
      lastName,
      selectedGender,
      emailId,
      countryId,
      candidatePhoneNumber,
      organisation,
      selectedOrgType,
      selectedScenario,
      selectedOrgNameValue,
    } = this.state;
    const { value: selectedScenarioId } = selectedScenario || {};
    const invalidObj = {};
    invalidObj.isScenarioInvalid = selectedScenarioId == null;
    invalidObj.isEmailInvalid =
      this.isInputInValid(emailId) || !isEmail(emailId?.trim());
    invalidObj.isFullNameInvalid = this.isInputInValid(fullName);
    // invalidObj.isFirstNameInvalid = this.isInputInValid(firstName);
    // invalidObj.isLastNameInvalid = this.isInputInValid(lastName);
    if (Number(selectedScenarioId) !== 1) {
      invalidObj.isNRICInvalid = !nric.validate(nricVal);
    }
    // invalidObj.isGenderInvalid = selectedGender?.value == null;
    // invalidObj.isCountryCodeInvalid = this.isInputInValid(countryId);
    invalidObj.isCandidatePhoneInvalid = this.isPhoneNumberInvalid(
      candidatePhoneNumber,
      countryId
    );

    // invalidObj.isOrgTypeInvalid = selectedOrgType?.value == null;
    // invalidObj.isOrgNameInvalid = selectedOrgNameValue?.value == null;

    this.setState({ ...invalidObj }, () => {
      const areAllInputsValid = Object.values(invalidObj).every(
        (val) => val === false
      );
      if (areAllInputsValid) {
        const data = {
          nric: nricVal,
          email: emailId?.trim()?.toLowerCase(),
          fullName,
          // firstName,
          // lastName,
          schoolTypeId: selectedOrgType?.value,
          schoolId: selectedOrgNameValue?.value,
          gender: selectedGender?.value || null,
          countryCode: candidatePhoneNumber ? '65' : null,
          contactNumber: candidatePhoneNumber,
          scenarioId: selectedScenario?.value,
          testCentre: 'X0PA_PLATFORM',
        };

        if (candId) {
          data.candidateId = candId;
        }
        if (candId) {
          this.props.handleUpdateCandInfo(data);
        } else {
          this.props.handleAddCandInfo(data);
        }
      }
    });
  };

  onCreateNewOrgName = (name, value) => {
    const { selectedOrgType } = this.state;
    if (value && typeof value === 'string' && value.trimLeft().length === 0)
      return;
    if (value && typeof value === 'string') value = value.trimLeft();
    const data = {
      companyName: value,
      ctypeId: selectedOrgType?.value,
    };
    this.setState({ isLoading: true }, () => {
      this.props
        .createAssessCompany([data])
        .then((res) => {
          if (res && !res.error) {
            const { companyId, displayName } = res?.[0] || {};
            const options = {
              label: displayName,
              value: companyId,
            };
            this.setState({
              isLoading: false,
              selectedOrgNameValue: options,
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((e) => {
          console.log('Error:::::', e);
          this.setState({
            isLoading: false,
          });
        });
    });
  };

  handleInputChange = (value) => {
    if (value && typeof value === 'string') value = value.trimStart();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[ a-zA-Z0-9!()#+.]{1,300}/.test(value)) {
      const { selectedOrgType } = this.state;
      const data = {
        activePage: 1,
        itemsPerPage: 5,
        ctypeId: selectedOrgType?.value,
        searchKey: value,
      };

      this.props.getAsessCompaniesById(data).then((res) => {
        if (res && !res.error) {
          const { data = [] } = res || {};
          const options = data.map((one) => ({
            label: one.displayName,
            value: one.companyId,
          }));
          this.setState({ orgNameArr: options });
        }
      });
    }
  };

  onInputOrgNameChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[ a-zA-Z0-9!()#+.]{1,300}/.test(value)) {
      const { selectedOrgType } = this.state;
      const data = {
        activePage: 1,
        itemsPerPage: 5,
        ctypeId: selectedOrgType?.value,
        searchKey: value,
      };

      this.props.getAsessCompaniesById(data).then((res) => {
        if (res && !res.error) {
          const { data = [] } = res || {};
          const options = data.map((one) => ({
            label: one.displayName,
            value: one.companyId,
          }));
          cb(options);
        }
      });
      return value;
    }
  };

  handleOrgNameDropdownChange = (selectedOption, name, e) => {
    const { action } = e || {};
    if (action === 'clear') {
      this.setState({
        [name]: null,
      });
    } else {
      this.setState({
        [name]: selectedOption,
      });
    }
  };

  render() {
    const {
      countries,
      assessScenarios,
      assessCompanyTypes,
      match,
      isSubmitting,
    } = this.props;
    const { params } = match || {};
    const { candId } = params || {};
    const {
      nric,
      isNRICInvalid = false,
      fullName,
      isFullNameInvalid,
      firstName,
      isFirstNameInvalid,
      lastName,
      isLastNameInvalid,
      selectedGender,
      isGenderInvalid,
      emailId,
      isEmailInvalid,
      countryId,
      isCountryCodeInvalid,
      candidatePhoneNumber,
      isCandidatePhoneInvalid,
      isOrgTypeInvalid,
      selectedOrgType,
      organisation,
      isOrgInvalid,
      selectedScenario,
      isScenarioInvalid,
      selectedOrgNameValue,
      isOrgNameInvalid,
      orgNameArr = [],
    } = this.state;

    const { value: selectedScenarioId } = selectedScenario || {};

    return (
      <div>
        <Modal
          open={true}
          modalHeading={`${candId ? 'Edit' : 'Add'} Candidate`}
          modalLabel="Action"
          primaryButtonText={isSubmitting ? 'Submitting...' : 'Submit'}
          secondaryButtonText="Cancel"
          iconDescription="Close the modal"
          onRequestClose={this.props.dismissAddCandModal}
          onRequestSubmit={this.handleSubmitAddCand}>
          <div className="p-3">
            <div className="bx--row">
              <div className="bx--col-sm-12 bx--col-lg-6">
                <div className="" style={{ marginTop: '-0.5rem' }}>
                  <Dropdown
                    id="scenario"
                    title="Scenario"
                    required
                    custom
                    mainClassName="bx--form-item mb-0"
                    className={`w-100 ${isScenarioInvalid ? 'invalid' : ''}`}
                    titleClass="mb-2"
                    labelClass="pt-2"
                    onInputChange={() => {}}
                    options={
                      assessScenarios &&
                      Array.isArray(assessScenarios) &&
                      assessScenarios.length > 0 &&
                      assessScenarios
                        .filter((itm) => itm?.scenarioId != 5)
                        .map((res) => {
                          const { scenarioId, displayName } = res || {};
                          return {
                            label: displayName,
                            value: scenarioId,
                          };
                        })
                    }
                    name="selectedScenario"
                    placeholder="Scenario"
                    onChange={(value, e, name) =>
                      this.handleDropdownChange(value, e, name)
                    }
                    selectedValue={selectedScenario}
                    disabled={candId}
                  />
                </div>
                {isScenarioInvalid && (
                  <div className="bx--form-requirement mb-2">
                    Please select scenario
                  </div>
                )}
              </div>
              <div className="bx--col-sm-12 bx--col-lg-6">
                <TextInput
                  required
                  name="emailId"
                  id="emailId"
                  value={emailId}
                  placeholder="janedoe@gmail.com"
                  onChange={(ev) => {
                    this.handleChange(ev);
                  }}
                  invalid={isEmailInvalid}
                  invalidText="Please enter valid email"
                  labelText={
                    <div>
                      Email <span className="text-danger">*</span>
                    </div>
                  }
                />
              </div>
            </div>
            <div className=" bx--row mt-3">
              <div className="bx--col-sm-12 bx--col-lg-6">
                <TextInput
                  required
                  id="fullName"
                  name="fullName"
                  labelText={
                    <div>
                      Full Name <span className="text-danger">*</span>
                    </div>
                  }
                  placeholder="Full Name"
                  onChange={(ev) => {
                    this.handleChange(ev);
                  }}
                  value={fullName}
                  maxLength={200}
                  invalid={isFullNameInvalid}
                  invalidText="Please enter valid Full Name"
                />
              </div>
              {/* <div className="bx--col-sm-12 bx--col-lg-6">
                <TextInput
                  required
                  id="firstName"
                  name="firstName"
                  labelText={
                    <div>
                      First Name <span className="text-danger">*</span>
                    </div>
                  }
                  placeholder="First Name"
                  onChange={(ev) => {
                    this.handleChange(ev);
                  }}
                  value={firstName}
                  maxLength={15}
                  invalid={isFirstNameInvalid}
                  invalidText="Please enter valid First Name"
                />
              </div>
              <div className="bx--col-sm-12 bx--col-lg-6">
                <TextInput
                  required
                  id="lastName"
                  name="lastName"
                  labelText={
                    <div>
                      Last Name <span className="text-danger">*</span>
                    </div>
                  }
                  placeholder="Last Name"
                  onChange={(ev) => {
                    this.handleChange(ev);
                  }}
                  value={lastName}
                  maxLength={15}
                  invalid={isLastNameInvalid}
                  invalidText="Please enter valid Last Name"
                />
              </div> */}
            </div>

            <div className=" bx--row mt-3">
              {Number(selectedScenarioId) !== 1 && (
                <div className="bx--col-sm-12 bx--col-lg-6">
                  <TextInput
                    disabled={candId}
                    required
                    id="nric"
                    name="nric"
                    labelText={
                      <div>
                        NRIC <span className="text-danger">*</span>
                      </div>
                    }
                    placeholder="T1234567A"
                    onChange={(ev) => {
                      this.handleChange(ev);
                    }}
                    value={nric}
                    invalid={isNRICInvalid}
                    invalidText="Please enter valid NRIC"
                  />
                </div>
              )}
              <div className="bx--col-sm-12 bx--col-lg-6">
                <div className="" style={{ marginTop: '-0.5rem' }}>
                  <Dropdown
                    id="Sex"
                    title="Sex"
                    custom
                    mainClassName="bx--form-item mb-0"
                    className="w-100"
                    titleClass="mb-2"
                    labelClass="pt-2"
                    onInputChange={() => {}}
                    options={[
                      { label: 'Male', value: 1 },
                      { label: 'Female', value: 2 },
                      { label: 'Undisclosed', value: 3 },
                    ]}
                    name="selectedGender"
                    placeholder="Sex"
                    onChange={(value, e, name) =>
                      this.handleDropdownChange(value, e, name)
                    }
                    selectedValue={selectedGender}
                  />
                </div>
              </div>
            </div>

            {Number(selectedScenarioId) !== 1 &&
              Number(selectedScenarioId) !== 2 &&
              Number(selectedScenarioId) !== 3 && (
                <div className="bx--row mt-sm-2 mt-lg-3">
                  <div className="bx--col-sm-12 bx--col-lg-6">
                    <div className="" style={{ marginTop: '-0.5rem' }}>
                      <div className="bx--label">School/Organisation</div>
                      <Dropdown
                        id="organisation"
                        title=""
                        // required
                        custom
                        mainClassName="bx--form-item mb-0"
                        className={`w-100 ${isOrgTypeInvalid ? 'invalid' : ''}`}
                        titleClass="mb-2"
                        labelClass="pt-2"
                        onInputChange={() => {}}
                        options={
                          assessCompanyTypes &&
                          Array.isArray(assessCompanyTypes) &&
                          assessCompanyTypes.length > 0 &&
                          assessCompanyTypes.map((res) => {
                            const { ctypeId, ctypeName } = res || {};
                            return {
                              label: ctypeName,
                              value: ctypeId,
                            };
                          })
                        }
                        name="selectedOrgType"
                        placeholder="School/Organisation"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        selectedValue={selectedOrgType}
                      />
                      {isOrgTypeInvalid && (
                        <div className="bx--form-requirement mb-2">
                          Please select school/orgnisation
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bx--col-sm-12 bx--col-lg-6 mt-sm-3 mt-lg-0">
                    <div className="" style={{ marginTop: '-0.5rem' }}>
                      <div className="bx--label">
                        Name of School/Organisation
                      </div>
                      <Dropdown
                        id="org-name-dd"
                        custom
                        className={`w-100 ${isOrgNameInvalid ? 'invalid' : ''}`}
                        title=""
                        titleClass="mb-2"
                        isAsync
                        isClearable
                        // required
                        mainClassName="mb-0"
                        name="selectedOrgNameValue"
                        onInputChange={this.handleInputChange}
                        options={orgNameArr}
                        pattern="^[a-zA-Z ]*$"
                        placeholder="Start typing to see options"
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        // onChange={(value, name, e) =>
                        //   this.handleOrgNameDropdownChange(value, name, e)
                        // }
                        // newOptionCreator={(name, value) =>
                        //   this.onCreateNewOrgName(name, value)
                        // }
                        isLoading={this.state.isLoading}
                        inputProps={{
                          maxLength: '50',
                        }}
                        selectedValue={selectedOrgNameValue}
                        disabled={selectedOrgType?.value == null}
                      />
                    </div>
                    {isOrgNameInvalid && (
                      <div className="bx--form-requirement mb-2">
                        Please enter name of school/organisation
                      </div>
                    )}
                  </div>
                </div>
              )}

            {Number(selectedScenarioId) !== 1 && (
              <div className="bx--row mt-3">
                <div className="bx--col-sm-12 bx--col-lg-6">
                  <Select
                    disabled
                    className="xpa-width w-100"
                    name="countryId"
                    labelText="Country Code"
                    id="countryId"
                    onChange={(e) => this.handleChange(e)}
                    value={countryId}
                    invalid={
                      countryId?.length > 0 ? false : isCountryCodeInvalid
                    }
                    invalidText="Please select valid country code.">
                    <SelectItem text="Singapore (+65)" value="65" />
                    {/* <SelectItem text={'Select'} />
                        {countries &&
                          Array.isArray(countries) &&
                          countries.map((b) => (
                            <SelectItem
                              key={b.countryId}
                              text={`${b.countryFull} (${b.countryCode})`}
                              value={`${b.countryId}`}
                            />
                          ))} */}
                  </Select>
                </div>
                <div className="bx--col-sm-12 bx--col-lg-6">
                  <TextInput
                    labelText="Mobile Number"
                    name="candidatePhoneNumber"
                    id="candidatePhoneNumber"
                    placeholder="62315890"
                    type="number"
                    onChange={(e) => this.handleChange(e)}
                    value={candidatePhoneNumber || ''}
                    invalid={isCandidatePhoneInvalid}
                    invalidText="Please enter valid number"
                  />
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
  assessCandidates: state.Assessments.assessCandidates,
  assessCandidatesCount: state.Assessments.assessCandidatesCount,
  countries: state.Assessments.countries,
  assessScenarios: state.Assessments.assessScenarios,
  assessCompanyTypes: state.Assessments.assessCompanyTypes,
  assessCandInfo: state.Assessments.assessCandInfo,
});

const mapDispatchToProps = {
  addAsessmentCandidate,
  updateAsessmentCandidate,
  getAllCountries,
  getAsessmentScenarios,
  getAsessCompanyTypes,
  getAsessCompaniesById,
  createAssessCompany,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssessAddCandidate)
);
