import { Toggle } from 'carbon-components-react';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { lowerAndTrim } from '../../utils/helpers';
import { upsertCandidateDetailsById } from '../../actions/CandidateInfo';
import SuccErrNotification from '../../components/private/SuccErrNotification';

class CandidateSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAttendanceConfirmed: false,
    };
  }

  componentDidMount() {
    this.renderOnMount();
  }

  componentDidUpdate(prevProps) {
    const { applicationInfo } = this.props;
    const { pscSchedulingStatus } = applicationInfo || {};
    const { applicationInfo: prevPropApplInfo } = prevProps;
    const { pscSchedulingStatus: prevPscSchedulingStatus } =
      prevPropApplInfo || {};
    if (pscSchedulingStatus !== prevPscSchedulingStatus) {
      this.setState({
        isAttendanceConfirmed:
          lowerAndTrim(pscSchedulingStatus) ===
          'confirmed attendance for psc interview',
      });
    }
  }

  renderOnMount = () => {
    const { applicationInfo } = this.props;
    const { pscSchedulingStatus } = applicationInfo || {};

    if (
      lowerAndTrim(pscSchedulingStatus) ===
      'confirmed attendance for psc interview'
    ) {
      this.setState({ isAttendanceConfirmed: true });
    }
  };

  handleUpdatePscSchedulingStatus = () => {
    const { isAttendanceConfirmed } = this.state;
    const { appId } = this.props;
    const pscSchedulingStatus = isAttendanceConfirmed
      ? 'Confirmed attendance for PSC Interview'
      : null;
    this.props
      .upsertCandidateDetailsById({ appId, pscSchedulingStatus })
      .then((res) => {
        if (res && !res.error) {
          this.props.handleGetCandidateInfoAPI();
          this.handleSuccessNotif();
        } else {
          this.handleErrorNotif(res);
        }
      })
      .catch((e) => console.log('Error', e));
  };

  handleSuccessNotif = () => {
    this.setState(
      {
        successNotif: true,
        errorNotif: false,
        notifMsg: 'Details have been updated successfully',
      },
      () => {
        setTimeout(() => {
          this.setState({
            successNotif: false,
            notifMsg: '',
          });
        }, 5000);
      }
    );
  };

  handleErrorNotif = (res) => {
    this.setState({
      successNotif: false,
      errorNotif: true,
      errMsg: res?.error?.message,
    });
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  render() {
    const {
      isAttendanceConfirmed,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
    } = this.state;

    return (
      <div>
        <div className="d-flex justify-content-between">
          <div class="mt-2 h6 font-weight-bold">Settings</div>
        </div>

        <SuccErrNotification
          successNotif={successNotif}
          errorNotif={errorNotif}
          notifMsg={notifMsg}
          errMsg={errMsg}
          handleCloseNotifBtn={this.handleCloseNotifBtn}
        />

        <div>
          <div className="mt-2 bx--row align-items-center mb-3">
            <div className="mb-2 bx--col-lg-3">
              <div className="bx--label">PSSB CM View</div>
              <div>
                <small>
                  By enabling this, PSSB CM will be able to see the candidate.
                </small>
              </div>
            </div>
            <div className="bx--col-lg-7" style={{ marginTop: -12 }}>
              <Toggle
                id="pscSchedulingStatus"
                onToggle={(val) =>
                  this.setState({ isAttendanceConfirmed: val }, () =>
                    this.handleUpdatePscSchedulingStatus()
                  )
                }
                toggled={isAttendanceConfirmed}
                labelA="No"
                labelB="Yes"
              />
            </div>
          </div>
          <hr />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  upsertCandidateDetailsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSettings);
