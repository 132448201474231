import React, { Fragment } from 'react';
import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import moment from 'moment';
import './CandidateAcademics.css';

const ALevelResults = (props) => {
  const { aLevelResult, schoolDetails } = props || {};
  let currSchoolLevelArr = [];
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    currSchoolLevelArr = schoolDetails.filter((sch) => {
      const { schoollevel: schoollevelObj } = sch || {};
      const { schoollevel } = schoollevelObj || {};
      if (schoollevel === 'junior college/ip school') {
        return true;
      }
      return false;
    });
  }

  const { totalAcademicsUnit, semDetails, psc70App } =
    (aLevelResult &&
      Array.isArray(aLevelResult) &&
      aLevelResult.length > 0 &&
      aLevelResult[0]) ||
    {};
  let filteredSemDetails = [];
  let aLevelInstitutions = [];
  if (semDetails && Array.isArray(semDetails) && semDetails.length > 0) {
    filteredSemDetails = semDetails.filter((res) => {
      const { institutionId } = res || {};
      if (institutionId > 0) {
        return true;
      }
      return false;
    });
    semDetails.forEach((res) => {
      const { company } = res || {};
      const { companyName } = company || {};
      if (companyName) {
        aLevelInstitutions.push(companyName);
      }
    });
  }

  let currSchoolLevelObj = {};
  let schoolData = [];
  if (
    aLevelInstitutions &&
    Array.isArray(aLevelInstitutions) &&
    aLevelInstitutions.length > 0
  ) {
    currSchoolLevelObj = currSchoolLevelArr.find((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (aLevelInstitutions.includes(currSchool)) {
        return true;
      }
      return false;
    });
    schoolData = currSchoolLevelArr.filter((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (aLevelInstitutions.includes(currSchool)) {
        return false;
      }
      return true;
    });
  }
  const { company, startDate, endDate } = currSchoolLevelObj || {};
  const { displayName } = company || {};
  return (
    <div>
      {schoolData &&
        Array.isArray(schoolData) &&
        schoolData.length > 0 &&
        schoolData.map((res) => {
          const {
            company: currcomp,
            startDate: otherStartDate,
            endDate: otherEndDate,
          } = res || {};
          const { displayName: currInst } = currcomp || {};
          return (
            currInst && (
              <div className="mt-3 h6 font-weight-bold mb-2">
                {currInst}
                {otherStartDate || otherEndDate ? ',' : null}{' '}
                {otherStartDate ? moment(otherStartDate).format('YYYY') : null}{' '}
                {otherEndDate ? '-' : null}{' '}
                {otherEndDate ? moment(otherEndDate).format('YYYY') : null}
              </div>
            )
          );
        })}
      {filteredSemDetails &&
        Array.isArray(filteredSemDetails) &&
        filteredSemDetails.length > 0 &&
        filteredSemDetails.map((semester, index) => {
          const { company: semComp, subDetails, examyear } = semester || {};
          const { displayName: semCompName } = semComp || {};
          let filteredSubjDetails = [];
          if (
            subDetails &&
            Array.isArray(subDetails) &&
            subDetails.length > 0
          ) {
            filteredSubjDetails = subDetails.filter((subjD) => {
              const { subjectId } = subjD || {};
              if (subjectId) {
                return true;
              }
              return false;
            });
          }
          return (
            <div>
              {semCompName && (
                <Fragment>
                  <div className="mt-3 h6 font-weight-bold mb-2">
                    {semCompName}
                    {semCompName === displayName ? (
                      <span>
                        {startDate || endDate ? ',' : null}{' '}
                        {startDate ? moment(startDate).format('YYYY') : null}{' '}
                        {endDate ? '-' : null}{' '}
                        {endDate ? moment(endDate).format('YYYY') : null}
                      </span>
                    ) : null}
                  </div>
                  {examyear && (
                    <div className="mt-2 mb-2">GCE A-Level, {examyear}</div>
                  )}
                  {index === 0 && (
                    <Fragment>
                      <div className="mt-2">
                        No. of Academic Units:{' '}
                        {totalAcademicsUnit > 0 ? totalAcademicsUnit : '-'}
                      </div>
                      <div className="mb-2 mt-2">
                        PSC Point (max = {70}): {psc70App || '-'}
                      </div>
                    </Fragment>
                  )}
                  <GenericDataTable
                    className=""
                    otherActions={[]}
                    headers={[
                      {
                        key: 'header_0',
                        header: 'Subject',
                        className: 'w-25',
                      },
                      {
                        key: 'header_1',
                        header: 'Subject Level',
                        className: 'w-25',
                      },
                      {
                        key: 'header_3',
                        header: 'Oral',
                        className: 'w-25',
                      },
                      {
                        key: 'header_2',
                        header: 'Grade',
                        className: '25',
                      },
                    ]}
                    rows={
                      filteredSubjDetails &&
                      Array.isArray(filteredSubjDetails) &&
                      filteredSubjDetails.length > 0
                        ? filteredSubjDetails.map((res, idx) => {
                            const {
                              oralaural,
                              alevelsubdetailsId,
                              subject,
                              grade,
                              subjectLevel,
                            } = res || {};
                            const { displayName: diplomaDisplayName } =
                              oralaural || {};
                            const { displayName: subjDisplayName } =
                              subject || {};
                            const { displayName: gradeDispName } = grade || {};
                            return {
                              isExpanded: true,
                              id: `row_${alevelsubdetailsId}`,
                              header_0: (
                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                    Subject:
                                  </div>
                                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                    <div className="text-capitalize">
                                      <div>{subjDisplayName}</div>
                                    </div>
                                  </div>
                                </div>
                              ),
                              header_3: (
                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                    Oral
                                  </div>
                                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                    <div className=" ">
                                      <div>
                                        {diplomaDisplayName === '-' ||
                                        !diplomaDisplayName
                                          ? 'N.A.'
                                          : diplomaDisplayName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ),
                              header_1: (
                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                    Subject Level:
                                  </div>
                                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                    <div className=" ">
                                      <div>{subjectLevel}</div>
                                    </div>
                                  </div>
                                </div>
                              ),
                              header_2: (
                                <div className="bx--row align-items-center mt-2 mt-xl-0">
                                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                    Grade:
                                  </div>
                                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                    <div className=" ">
                                      <div>
                                        {gradeDispName === 'A1' ||
                                        gradeDispName === 'V'
                                          ? 'A'
                                          : gradeDispName}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ),
                            };
                          })
                        : []
                    }
                    selectable={false}
                    searchable={false}
                    onSearchInputChange={() => {}}
                    expandable={false}
                    batchActions={false}
                  />
                  {(!filteredSubjDetails ||
                    (filteredSubjDetails &&
                      Array.isArray(filteredSubjDetails) &&
                      filteredSubjDetails.length <= 0)) && (
                    <NoContentBox
                      hideTile
                      message="There are no subjects results for your current selection"
                    />
                  )}
                </Fragment>
              )}
            </div>
          );
        })}
      {((schoolData && Array.isArray(schoolData) && schoolData.length > 0) ||
        (filteredSemDetails &&
          Array.isArray(filteredSemDetails) &&
          filteredSemDetails.length > 0)) && <div className="academic-hr-l" />}
    </div>
  );
};

const OLevelResults = (props) => {
  const { oLevelResult, schoolDetails } = props || {};

  let currSchoolLevelArr = [];
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    currSchoolLevelArr = schoolDetails.filter((sch) => {
      const { schoollevel: schoollevelObj } = sch || {};
      const { schoollevel } = schoollevelObj || {};
      if (schoollevel === 'secondary school') {
        return true;
      }
      return false;
    });
  }
  let oLevelInstitutions = [];
  if (oLevelResult && Array.isArray(oLevelResult) && oLevelResult.length > 0) {
    oLevelInstitutions = oLevelResult.map((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currInst } = currcomp || {};
      return currInst;
    });
  }
  let schoolData = [];
  let currSchoolLevelObj = {};
  if (
    currSchoolLevelArr &&
    Array.isArray(currSchoolLevelArr) &&
    currSchoolLevelArr.length > 0
  ) {
    currSchoolLevelObj = currSchoolLevelArr.find((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (oLevelInstitutions.includes(currSchool)) {
        return true;
      }
      return false;
    });
    schoolData = currSchoolLevelArr.filter((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (oLevelInstitutions.includes(currSchool)) {
        return false;
      }
      return true;
    });
  }
  const { company: oLvlCompany, startDate, endDate } = currSchoolLevelObj || {};
  const { displayName: oLvlCompDName } = oLvlCompany || {};
  let filteredOLevel = [];
  if (oLevelResult && Array.isArray(oLevelResult) && oLevelResult.length > 0) {
    filteredOLevel = oLevelResult.filter((ol) => {
      const { ccaGrade, company: currcomp, semDetails } = ol || {};
      const { displayName: currInst } = currcomp || {};
      if (currInst) {
        return true;
      }
      return false;
    });
  }
  return (
    <div>
      {schoolData &&
        Array.isArray(schoolData) &&
        schoolData.length > 0 &&
        schoolData.map((res) => {
          const {
            company: currcomp,
            startDate: otherStartDate,
            endDate: otherEndDate,
          } = res || {};
          const { displayName: currInst } = currcomp || {};
          return (
            currInst && (
              <div className="mt-3 h6 font-weight-bold mb-2">
                {currInst}
                {otherStartDate || otherEndDate ? ',' : null}{' '}
                {otherStartDate ? moment(otherStartDate).format('YYYY') : null}{' '}
                {otherEndDate ? '-' : null}{' '}
                {otherEndDate ? moment(otherEndDate).format('YYYY') : null}
              </div>
            )
          );
        })}
      {oLevelResult &&
        Array.isArray(oLevelResult) &&
        oLevelResult.length > 0 &&
        oLevelResult.map((oLevel) => {
          const { ccaGrade, company: currcomp, semDetails } = oLevel || {};
          const { displayName: currInst } = currcomp || {};
          return (
            <div>
              {currInst && (
                <Fragment>
                  <div className="mt-3 h6 font-weight-bold mb-2">
                    {currInst}
                    {currInst === oLvlCompDName && (
                      <span>
                        {startDate || endDate ? ', ' : null}
                        {startDate
                          ? moment(startDate).format('YYYY')
                          : null}{' '}
                        {endDate ? '-' : null}{' '}
                        {endDate ? moment(endDate).format('YYYY') : null}
                      </span>
                    )}
                  </div>
                  {ccaGrade && (
                    <div className="mt-2">CCA Grade: {ccaGrade}</div>
                  )}
                </Fragment>
              )}
              {semDetails &&
                Array.isArray(semDetails) &&
                semDetails.length > 0 &&
                semDetails.map((semester) => {
                  const { examyear, subDetails } = semester || {};
                  return (
                    <div>
                      {examyear && (
                        <div className="mt-2">GCE O-Level, {examyear}</div>
                      )}
                      {subDetails &&
                        Array.isArray(subDetails) &&
                        subDetails.length > 0 && (
                          <div className="mt-3 mb-3">
                            <GenericDataTable
                              className=""
                              otherActions={[]}
                              headers={[
                                {
                                  key: 'header_0',
                                  header: 'Subject',
                                  className: 'w-50',
                                },

                                {
                                  key: 'header_2',
                                  header: 'Oral',
                                  className: 'w-25',
                                },
                                {
                                  key: 'header_1',
                                  header: 'Grade',
                                  className: 'w-25',
                                },
                              ]}
                              rows={
                                subDetails &&
                                Array.isArray(subDetails) &&
                                subDetails.length > 0
                                  ? subDetails.map((res) => {
                                      const {
                                        subject,
                                        grade,
                                        olevelsubdetailsId,
                                        oralaural,
                                      } = res || {};
                                      const { displayName: subjDisplayName } =
                                        subject || {};
                                      const { displayName: gradeDispName } =
                                        grade || {};
                                      const {
                                        displayName: diplomaDisplayName,
                                      } = oralaural || {};
                                      return {
                                        isExpanded: true,
                                        id: `row_${olevelsubdetailsId}`,
                                        header_0: (
                                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                                            <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                              Subject:
                                            </div>
                                            <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                              <div className="text-capitalize">
                                                <div>{subjDisplayName}</div>
                                              </div>
                                            </div>
                                          </div>
                                        ),

                                        header_1: (
                                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                                            <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                              Grade:
                                            </div>
                                            <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                              <div className=" ">
                                                <div>{gradeDispName}</div>
                                              </div>
                                            </div>
                                          </div>
                                        ),
                                        header_2: (
                                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                                            <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                              Oral:
                                            </div>
                                            <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                              <div className=" ">
                                                <div>
                                                  {diplomaDisplayName === '-'
                                                    ? 'N.A.'
                                                    : diplomaDisplayName}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ),
                                      };
                                    })
                                  : []
                              }
                              selectable={false}
                              searchable={false}
                              onSearchInputChange={() => {}}
                              expandable={false}
                              batchActions={false}
                            />
                          </div>
                        )}
                    </div>
                  );
                })}
            </div>
          );
        })}
      {((schoolData && Array.isArray(schoolData) && schoolData.length > 0) ||
        (filteredOLevel &&
          Array.isArray(filteredOLevel) &&
          filteredOLevel.length > 0)) && <div className="academic-hr-l" />}
    </div>
  );
};

const NusHighSchoolResults = (props) => {
  const { nusHighSchool, schoolDetails } = props || {};
  const { cap, maxGpaCpa, oralaural, yearOfFinalExam, subjectDetails } =
    (nusHighSchool && nusHighSchool[0]) || {};
  let schoollevelObj = {};
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    schoollevelObj = schoolDetails.find((sch) => {
      const { schoollevel: schoollevelObj } = sch || {};
      const { schoollevel } = schoollevelObj || {};
      if (schoollevel === 'nus high school') {
        return true;
      }
      return false;
    });
  }
  const { company, endDate, startDate } = schoollevelObj || {};
  const { displayName } = company || {};
  const { displayName: oralDisName } = oralaural || {};
  return (
    <div>
      {displayName && (
        <Fragment>
          <div className="mt-3 h6 font-weight-bold mb-2">
            {displayName}
            {startDate || endDate ? (
              <span>
                {startDate ? ',' : null}{' '}
                {startDate ? moment(startDate).format('YYYY') : null}{' '}
                {endDate ? ' - ' : null}
                {endDate ? moment(endDate).format('YYYY') : null}
              </span>
            ) : null}
          </div>
          {yearOfFinalExam && (
            <div className="mb-2">
              NUS High School Diploma,{' '}
              {yearOfFinalExam ? yearOfFinalExam : null}
            </div>
          )}
          <div className="mb-2">
            Cumulative CAP: {cap} / {maxGpaCpa}{' '}
            {oralDisName ? `(${oralDisName})` : null}
          </div>

          <div className="mt-3">
            <GenericDataTable
              className=""
              otherActions={[]}
              headers={[
                {
                  key: 'header_0',
                  header: 'Subject',
                  className: 'w-50',
                },
                {
                  key: 'header_1',
                  header: '',
                  className: 'w-25',
                },
                {
                  key: 'header_2',
                  header: 'CAP',
                  className: '25',
                },
                ,
              ]}
              rows={
                subjectDetails &&
                Array.isArray(subjectDetails) &&
                subjectDetails.length > 0
                  ? subjectDetails.map((res, idx) => {
                      const { subject, overallCap } = res || {};
                      const { displayName: subjDisplayName } = subject || {};
                      return {
                        isExpanded: true,
                        id: `row_${idx}`,
                        header_0: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              Subject:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                              <div className="text-capitalize">
                                <div>{subjDisplayName}</div>
                              </div>
                            </div>
                          </div>
                        ),
                        header_1: <div></div>,
                        header_2: (
                          <div className="bx--row align-items-center mt-2 mt-xl-0">
                            <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                              CAP:
                            </div>
                            <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                              <div className=" ">
                                <div>{overallCap}</div>
                              </div>
                            </div>
                          </div>
                        ),
                      };
                    })
                  : []
              }
              selectable={false}
              searchable={false}
              onSearchInputChange={() => {}}
              expandable={false}
              batchActions={false}
            />
            {(!subjectDetails ||
              (subjectDetails &&
                Array.isArray(subjectDetails) &&
                subjectDetails.length <= 0)) && (
              <NoContentBox
                hideTile
                message="There are no subjects results for your current selection"
              />
            )}
          </div>
        </Fragment>
      )}
      {(displayName ||
        (subjectDetails &&
          Array.isArray(subjectDetails) &&
          subjectDetails.length > 0)) && <div className="academic-hr-l" />}
    </div>
  );
};

const PolyResults = (props) => {
  const { polyResult, schoolDetails } = props || {};
  let currSchoolLevelArr = [];
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    currSchoolLevelArr = schoolDetails.filter((sch) => {
      const { schoollevel: schoollevelObj } = sch || {};
      const { schoollevel } = schoollevelObj || {};
      if (schoollevel === 'polytechnic') {
        return true;
      }
      return false;
    });
  }

  let polytechnicData = [];
  if (polyResult && Array.isArray(polyResult) && polyResult.length > 0) {
    polytechnicData = polyResult.map((res) => {
      const { company: currComp } = res || {};
      const { companyName } = currComp || {};
      return companyName;
    });
  }
  let schoollevelObj = {};
  let polyInst = [];
  if (
    currSchoolLevelArr &&
    Array.isArray(currSchoolLevelArr) &&
    currSchoolLevelArr.length > 0
  ) {
    schoollevelObj = currSchoolLevelArr.find((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (polytechnicData.includes(currSchool)) {
        return true;
      }
      return false;
    });
    polyInst = currSchoolLevelArr.filter((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (polytechnicData.includes(currSchool)) {
        return false;
      }
      return true;
    });
  }
  const { company, startDate, endDate } = schoollevelObj || {};
  const { displayName } = company || {};
  let filteredPolyInst = [];
  if (polyResult && Array.isArray(polyResult) && polyResult.length > 0) {
    filteredPolyInst = polyResult.filter((res) => {
      const { company: currcomp } = res || {};
      const { displayName: currInst } = currcomp || {};
      if (currInst) {
        return true;
      }
      return false;
    });
  }
  return (
    <div>
      {polyInst &&
        Array.isArray(polyInst) &&
        polyInst.length > 0 &&
        polyInst.map((res) => {
          const {
            company: currcomp,
            startDate: otherStartDate,
            endDate: otherEndDate,
          } = res || {};
          const { displayName: currInst } = currcomp || {};
          return (
            currInst && (
              <div className="mt-3 h6 font-weight-bold mb-2">
                {currInst}
                {otherStartDate || otherEndDate ? ',' : null}{' '}
                {otherStartDate ? moment(otherStartDate).format('YYYY') : null}{' '}
                {otherEndDate ? '-' : null}{' '}
                {otherEndDate ? moment(otherEndDate).format('YYYY') : null}
              </div>
            )
          );
        })}
      {polyResult &&
        Array.isArray(polyResult) &&
        polyResult.length > 0 &&
        polyResult.map((poly) => {
          const {
            company: currComp,
            qualification,
            qualObtainedYear,
            maxGpa,
            cumulativeGpa,
            ismeritordistinction,
            subject,
          } = poly || {};
          const { displayName: courseDisplayName } = subject || {};
          const { displayName: qualDisplayName } = qualification || {};
          const { displayName: currPoly } = currComp || {};
          return (
            currPoly && (
              <div>
                <div className="mt-3 h6 font-weight-bold text-capitalize mb-2">
                  {currPoly}
                  {currPoly === displayName ? (
                    <span>
                      {startDate || endDate ? ',' : null}{' '}
                      {startDate ? moment(startDate).format('YYYY') : null}{' '}
                      {endDate ? ' - ' : null}
                      {endDate ? moment(endDate).format('YYYY') : null}
                    </span>
                  ) : null}
                </div>
                {(qualObtainedYear || courseDisplayName) && (
                  <div className="mb-2">
                    Course: {courseDisplayName}
                    {qualObtainedYear ? ',' : null} {qualObtainedYear}
                  </div>
                )}

                {/* <div className="mb-2 css-text-underline">
                  {ismeritordistinction === 'Yes' ? 'Merit / Distinction' : ''}
                </div> */}
                <div className="mb-2">
                  Cumulative CAP: {cumulativeGpa} / {maxGpa}
                </div>
              </div>
            )
          );
        })}
      {((polyInst && Array.isArray(polyInst) && polyInst.length > 0) ||
        (filteredPolyInst &&
          Array.isArray(filteredPolyInst) &&
          filteredPolyInst.length > 0)) && <div className="academic-hr-l" />}
    </div>
  );
};

const SATDetails = (props) => {
  const { satDetails, satSubTests } = props || {};
  const {
    readingWritingScore,
    readingWritingPercentile,
    mathScore,
    mathPercentile,
    essayPercentile,
    essayScore,
    criticalReadingPercentile,
    totalScore,
    criticalReadingScore,
    satDateTaken,
    writingScore,
    writingPercentile,
  } = (satDetails && satDetails[0]) || {};
  let filteredSatSubTens = [];
  if (satSubTests && Array.isArray(satSubTests) && satSubTests.length > 0) {
    satSubTests.forEach((subs) => {
      const { subjectId } = subs || {};
      if (subjectId) {
        filteredSatSubTens.push(subs);
      }
    });
  }
  let finalArr = [];
  if (criticalReadingScore) {
    finalArr = [
      {
        subj: 'Critical Reading',
        score: criticalReadingScore || '-',
        percent: criticalReadingPercentile || '-',
        currScore: criticalReadingScore || '-',
      },
      {
        subj: 'Writing',
        score: writingScore || '-',
        percent: writingPercentile || '-',
        currScore: writingScore || '-',
      },
      {
        subj: 'Math',
        score: mathScore || '-',
        percent: mathPercentile || '-',
        currScore: mathScore || '-',
      },
      {
        subj: <div style={{ fontWeight: 'bold' }}>Total</div>,
        score: <div style={{ fontWeight: 'bold' }}>{totalScore || '-'}</div>,
        percent: '-',
        currScore: totalScore || '-',
      },
    ];
  } else {
    finalArr = [
      {
        subj: 'Evidence-Based Reading and Writing',
        score: readingWritingScore || '-',
        percent: readingWritingPercentile || '-',
        currScore: readingWritingScore || '-',
      },
      {
        subj: 'Math',
        score: mathScore || '-',
        percent: mathPercentile || '-',
        currScore: mathScore || '-',
      },
      {
        subj: 'Essay (Optional)',
        score: essayScore || '-',
        percent: essayPercentile || '-',
        currScore: essayScore || '-',
      },
      {
        subj: <div style={{ fontWeight: 'bold' }}>Total</div>,
        score: <div style={{ fontWeight: 'bold' }}>{totalScore || '-'}</div>,
        percent: '-',
        currScore: totalScore || '-',
      },
    ];
  }
  let filteredSatData = finalArr.filter((r) => r.currScore !== '-');
  return (
    filteredSatData &&
    Array.isArray(filteredSatData) &&
    filteredSatData.length > 0 && (
      <div>
        <div className="mt-3 mb-2 h6 font-weight-bold mb-2">SAT Results</div>
        {satDateTaken && (
          <div className="mb-3">
            SAT, {satDateTaken && moment(satDateTaken).format('YYYY')}
          </div>
        )}
        <div className="mt-3">
          <GenericDataTable
            className=""
            otherActions={[]}
            headers={[
              {
                key: 'header_0',
                header: 'SAT',
                className: 'w-50',
              },
              {
                key: 'header_1',
                header: 'Score',
                className: 'w-25',
              },
              { key: 'header_2', header: 'Percentile', className: 'w-25' },
            ]}
            rows={
              finalArr && Array.isArray(finalArr) && finalArr.length > 0
                ? finalArr.map((res, idx) => {
                    const { subj, score, percent } = res || {};
                    return {
                      isExpanded: true,
                      id: `row_${idx}`,
                      header_0: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            SAT:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                            <div className=" ">
                              <div>{subj}</div>
                            </div>
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Score:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                            <div className=" ">
                              <div>{score}</div>
                            </div>
                          </div>
                        </div>
                      ),
                      header_2: (
                        <div className="bx--row align-items-center mt-2 mt-xl-0">
                          <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                            Percent:
                          </div>
                          <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                            <div className=" ">
                              <div>{percent}</div>
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
            selectable={false}
            searchable={false}
            onSearchInputChange={() => {}}
            expandable={false}
            batchActions={false}
          />
        </div>
        {filteredSatSubTens &&
        Array.isArray(filteredSatSubTens) &&
        filteredSatSubTens.length > 0 ? (
          <div className="mt-3">
            <GenericDataTable
              className=""
              otherActions={[]}
              headers={[
                {
                  key: 'header_0',
                  header: 'SAT Subject Tests',
                  className: 'w-50',
                },
                {
                  key: 'header_1',
                  header: 'Score',
                  className: 'w-25',
                },
                { key: 'header_2', header: 'Percentile', className: 'w-25' },
              ]}
              rows={filteredSatSubTens.map((res, idx) => {
                const {
                  satsubjectdetailsId,
                  subject,
                  score,
                  nationalPercentile,
                } = res || {};
                const { displayName } = subject || {};
                return {
                  isExpanded: true,
                  id: satsubjectdetailsId,
                  header_0: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        SAT Subject Tests:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div className="text-capitalize">
                          <div>{displayName}</div>
                        </div>
                      </div>
                    </div>
                  ),
                  header_1: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Score:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div className=" ">
                          <div>{score}</div>
                        </div>
                      </div>
                    </div>
                  ),
                  header_2: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Percent:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div className=" ">
                          <div>{nationalPercentile}</div>
                        </div>
                      </div>
                    </div>
                  ),
                };
              })}
              selectable={false}
              searchable={false}
              onSearchInputChange={() => {}}
              expandable={false}
              batchActions={false}
            />
          </div>
        ) : null}
      </div>
    )
  );
};

const IBDiplomaResults = (props) => {
  const { ibDiploma, schoolDetails } = props || {};

  let currSchoolLevelArr = [];
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    currSchoolLevelArr = schoolDetails.filter((sch) => {
      const { schoollevel: schoollevelObj } = sch || {};
      const { schoollevel } = schoollevelObj || {};
      if (schoollevel === 'ib school') {
        return true;
      }
      return false;
    });
  }
  let ibInsts = [];
  if (ibDiploma && Array.isArray(ibDiploma) && ibDiploma.length > 0) {
    ibInsts = ibDiploma.map((ib) => {
      const { company: ibDiplomaCompany } = ib || {};
      const { companyName } = ibDiplomaCompany || {};
      return companyName;
    });
  }
  let schoollevelObj = {};
  let schoolData = [];
  if (
    currSchoolLevelArr &&
    Array.isArray(currSchoolLevelArr) &&
    currSchoolLevelArr.length > 0
  ) {
    schoollevelObj = currSchoolLevelArr.find((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (ibInsts.includes(currSchool)) {
        return true;
      }
      return false;
    });
    schoolData = currSchoolLevelArr.filter((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (ibInsts.includes(currSchool)) {
        return false;
      }
      return true;
    });
  }
  let filteredIBDiploma = [];
  if (ibDiploma && Array.isArray(ibDiploma) && ibDiploma.length > 0) {
    filteredIBDiploma = ibDiploma.filter((res) => {
      const { company: ibComp } = res || {};
      const { companyName: ibSch } = ibComp || {};
      if (ibSch) {
        return true;
      }
      return false;
    });
  }
  const { company, startDate, endDate } = schoollevelObj || {};
  const { displayName } = company || {};

  return (
    <div>
      {schoolData &&
        Array.isArray(schoolData) &&
        schoolData.length > 0 &&
        schoolData.map((res) => {
          const {
            company: currcomp,
            startDate: otherStartDate,
            endDate: otherEndDate,
          } = res || {};
          const { displayName: currInst } = currcomp || {};
          return (
            currInst && (
              <div className="mt-3 h6 font-weight-bold mb-2">
                {currInst}
                {otherStartDate || otherEndDate ? ',' : null}{' '}
                {otherStartDate ? moment(otherStartDate).format('YYYY') : null}{' '}
                {otherEndDate ? '-' : null}{' '}
                {otherEndDate ? moment(otherEndDate).format('YYYY') : null}
              </div>
            )
          );
        })}
      {ibDiploma &&
        Array.isArray(ibDiploma) &&
        ibDiploma.length > 0 &&
        ibDiploma.map((ib) => {
          const {
            company: ibDiplomaCompany,
            yearOfFinalExam: ibDipYear,
            totalScore: ibTotalScore,
            subjectDetails: ibSubjDetails,
            tokEePoints,
            otherSchoolId,
          } = ib || {};
          const { displayName: ibDipDisplayName } = ibDiplomaCompany || {};
          const { displayName: otherIbSchoolName } = otherSchoolId || {};
          let finalIbSchool = '';
          if (ibDipDisplayName === 'Others') {
            finalIbSchool = otherIbSchoolName;
          } else {
            finalIbSchool = ibDipDisplayName;
          }
          return (
            ibDiplomaCompany && (
              <div>
                <div className="mt-3 h6 font-weight-bold text-capitalize mb-2">
                  {finalIbSchool}
                  {finalIbSchool === displayName ? (
                    <span>
                      {startDate || endDate ? ',' : null}{' '}
                      {startDate ? moment(startDate).format('YYYY') : null}{' '}
                      {endDate ? ' - ' : null}
                      {endDate ? moment(endDate).format('YYYY') : null}
                    </span>
                  ) : null}
                </div>
                {ibDipYear && (
                  <div className="mb-2">
                    International Baccalaureate Diploma{','} {ibDipYear}
                  </div>
                )}
                <div className="mb-2">EE/TOK Points: {tokEePoints}</div>
                <div className="mb-2">
                  Total Points (max = 45): {ibTotalScore}
                </div>

                <GenericDataTable
                  className=""
                  otherActions={[]}
                  headers={[
                    {
                      key: 'header_0',
                      header: 'Subject',
                      className: 'w-50',
                    },
                    {
                      key: 'header_1',
                      header: 'Subject Level',
                      className: 'w-25',
                    },
                    {
                      key: 'header_2',
                      header: 'Grade',
                      className: '25',
                    },
                  ]}
                  rows={
                    ibSubjDetails &&
                    Array.isArray(ibSubjDetails) &&
                    ibSubjDetails.length > 0
                      ? ibSubjDetails.map((res, idx) => {
                          const {
                            diplomalevel,
                            levelId,
                            subject,
                            pointGrades,
                            otherSubjectIdRel,
                            ibdrId,
                            ibdsdId,
                          } = res || {};
                          const { displayName: diplomaDisplayName } =
                            diplomalevel || {};
                          const {
                            displayName: subjDisplayName,
                            subject: subjectName,
                          } = subject || {};
                          const { displayName: otherSubjName } =
                            otherSubjectIdRel || {};
                          let finalSubj = '';
                          if (subjectName === 'others') {
                            finalSubj = otherSubjName;
                          } else {
                            finalSubj = subjDisplayName;
                          }
                          return {
                            isExpanded: true,
                            id: `row_${levelId}_${ibdrId}_${idx}_${ibdsdId}`,
                            header_0: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  Subject:
                                </div>
                                <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                  <div className="text-capitalize">
                                    <div>{finalSubj}</div>
                                  </div>
                                </div>
                              </div>
                            ),
                            header_1: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  Subject Level:
                                </div>
                                <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                  <div className=" ">
                                    <div>{diplomaDisplayName}</div>
                                  </div>
                                </div>
                              </div>
                            ),
                            header_2: (
                              <div className="bx--row align-items-center mt-2 mt-xl-0">
                                <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                                  Grade:
                                </div>
                                <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                                  <div className=" ">
                                    <div>{pointGrades}</div>
                                  </div>
                                </div>
                              </div>
                            ),
                          };
                        })
                      : []
                  }
                  selectable={false}
                  searchable={false}
                  onSearchInputChange={() => {}}
                  expandable={false}
                  batchActions={false}
                />
                {(!ibSubjDetails ||
                  (ibSubjDetails &&
                    Array.isArray(ibSubjDetails) &&
                    ibSubjDetails.length <= 0)) && (
                  <NoContentBox
                    hideTile
                    message="There are no subjects results for your current selection"
                  />
                )}
              </div>
            )
          );
        })}
      {((schoolData && Array.isArray(schoolData) && schoolData.length > 0) ||
        (filteredIBDiploma &&
          Array.isArray(filteredIBDiploma) &&
          filteredIBDiploma.length > 0)) && <div className="academic-hr-l" />}
    </div>
  );
};

const UniversityResults = (props) => {
  const { schoolDetails, universityDetails } = props || {};

  let currSchoolLevelArr = [];
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    currSchoolLevelArr = schoolDetails.filter((sch) => {
      const { schoollevel: schoollevelObj } = sch || {};
      const { schoollevel } = schoollevelObj || {};
      if (schoollevel === 'university') {
        return true;
      }
      return false;
    });
  }

  const { subjectDetails } =
    (universityDetails &&
      Array.isArray(universityDetails) &&
      universityDetails.length > 0 &&
      universityDetails[0]) ||
    {};
  let universityData = [];
  if (
    subjectDetails &&
    Array.isArray(subjectDetails) &&
    subjectDetails.length > 0
  ) {
    universityData = subjectDetails.map((subjs) => {
      const { company: subjComp, otherUniversityId } = subjs || {};
      const { companyName } = subjComp || {};
      const { companyName: otherInstName } = otherUniversityId || {};
      let finalUniName = '';
      if (companyName === 'others') {
        finalUniName = otherInstName;
      } else {
        finalUniName = companyName;
      }
      return finalUniName;
    });
  }
  let currSchoolObj = {};
  let schoolData = [];
  if (
    currSchoolLevelArr &&
    Array.isArray(currSchoolLevelArr) &&
    currSchoolLevelArr.length > 0
  ) {
    currSchoolObj = currSchoolLevelArr.find((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (universityData.includes(currSchool)) {
        return true;
      }
      return false;
    });
    schoolData = currSchoolLevelArr.filter((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (universityData.includes(currSchool)) {
        return false;
      }
      return true;
    });
  }
  const { startDate, endDate, company } = currSchoolObj || {};
  const { displayName } = company || {};
  return (
    <div>
      {schoolData &&
        Array.isArray(schoolData) &&
        schoolData.length > 0 &&
        schoolData.map((res) => {
          const {
            company: currcomp,
            startDate: otherStartDate,
            endDate: otherEndDate,
          } = res || {};
          const { displayName: currInst } = currcomp || {};
          return (
            currInst && (
              <div className="mt-3 h6 font-weight-bold mb-2">
                {currInst}
                {otherStartDate || otherEndDate ? ',' : null}{' '}
                {otherStartDate ? moment(otherStartDate).format('YYYY') : null}{' '}
                {otherEndDate ? '-' : null}{' '}
                {otherEndDate ? moment(otherEndDate).format('YYYY') : null}
              </div>
            )
          );
        })}
      {subjectDetails &&
        Array.isArray(subjectDetails) &&
        subjectDetails.length > 0 &&
        subjectDetails.map((subjs) => {
          const {
            company: subjComp,
            classOfDegreeObtainedExpected,
            qualificationObtained,
            yearOfQualificationObtained,
            maxGpa,
            cumulativeGpa,
            courseId,
            otherUniversityId,
            otherCourseId,
          } = subjs || {};
          const { displayName: course, subject } = courseId || {};
          const { displayName: subjInstName, companyName } = subjComp || {};
          const { displayName: otherInstName } = otherUniversityId || {};
          const { displayName: otherCourseName } = otherCourseId || {};
          let finalUniName = '';
          if (companyName === 'others') {
            finalUniName = otherInstName;
          } else {
            finalUniName = subjInstName;
          }
          let finalCourseName = '';
          if (subject === 'others') {
            finalCourseName = otherCourseName;
          } else {
            finalCourseName = course;
          }
          return (
            <div>
              <div className="mt-3 h6 font-weight-bold mb-2">
                {finalUniName}
                {finalUniName === displayName ? (
                  <span>
                    {startDate || endDate ? ', ' : null}
                    {startDate ? moment(startDate).format('YYYY') : null}{' '}
                    {endDate ? '-' : null}{' '}
                    {endDate ? moment(endDate).format('YYYY') : null}
                  </span>
                ) : null}
              </div>
              {/* {qualificationObtained && (
                <div className="mb-2">
                  {qualificationObtained}, {yearOfQualificationObtained}
                </div>
              )} */}
              {finalCourseName && (
                <div className="mb-2">Course: {finalCourseName}</div>
              )}
              {classOfDegreeObtainedExpected && (
                <div className="mb-2">
                  Degree Obtained or Expected: {classOfDegreeObtainedExpected}
                </div>
              )}

              {(cumulativeGpa || maxGpa) && (
                <div className="mb-2">
                  Cumulative CAP: {cumulativeGpa} / {maxGpa}
                </div>
              )}
            </div>
          );
        })}
      {((schoolData && Array.isArray(schoolData) && schoolData.length > 0) ||
        (subjectDetails &&
          Array.isArray(subjectDetails) &&
          subjectDetails.length > 0)) && <div className="academic-hr-l" />}
    </div>
  );
};

const OtherInstitutionResults = (props) => {
  const { otherInstResult, schoolDetails } = props || {};

  let currSchoolLevelArr = [];
  if (
    schoolDetails &&
    Array.isArray(schoolDetails) &&
    schoolDetails.length > 0
  ) {
    currSchoolLevelArr = schoolDetails.filter((sch) => {
      const { schoollevel: schoollevelObj } = sch || {};
      const { schoollevel } = schoollevelObj || {};
      if (schoollevel === 'other institution') {
        return true;
      }
      return false;
    });
  }

  let otherInstData = [];
  if (
    otherInstResult &&
    Array.isArray(otherInstResult) &&
    otherInstResult.length > 0
  ) {
    otherInstData = otherInstResult.map((res) => {
      const { company: filtComp } = res || {};
      const { companyName } = filtComp || {};
      return companyName;
    });
  }
  let currSchoolLevelObj = {};
  let schoolData = [];
  if (
    currSchoolLevelArr &&
    Array.isArray(currSchoolLevelArr) &&
    currSchoolLevelArr.length > 0
  ) {
    currSchoolLevelObj = currSchoolLevelArr.find((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (otherInstData.includes(currSchool)) {
        return true;
      }
      return false;
    });
    schoolData = currSchoolLevelArr.filter((res) => {
      const { company: currcomp } = res || {};
      const { companyName: currSchool } = currcomp || {};
      if (otherInstData.includes(currSchool)) {
        return false;
      }
      return true;
    });
  }
  const { company, startDate, endDate } = currSchoolLevelObj || {};
  const { displayName } = company || {};
  let filteredOtherInstitutions = [];
  if (
    otherInstResult &&
    Array.isArray(otherInstResult) &&
    otherInstResult.length > 0
  ) {
    filteredOtherInstitutions = otherInstResult.filter((res) => {
      const { company: otherInstComp } = res || {};
      const { displayName: otherInstName } = otherInstComp || {};
      if (otherInstName) {
        return true;
      }
      return false;
    });
  }
  return (
    <div>
      {schoolData &&
        Array.isArray(schoolData) &&
        schoolData.length > 0 &&
        schoolData.map((res) => {
          const {
            company: currcomp,
            startDate: otherStartDate,
            endDate: otherEndDate,
          } = res || {};
          const { displayName: currInst } = currcomp || {};
          return (
            currInst && (
              <div className="mt-3 h6 font-weight-bold mb-2">
                {currInst}
                {otherStartDate || otherEndDate ? ',' : null}{' '}
                {otherStartDate ? moment(otherStartDate).format('YYYY') : null}{' '}
                {otherEndDate ? '-' : null}{' '}
                {otherEndDate ? moment(otherEndDate).format('YYYY') : null}
              </div>
            )
          );
        })}
      {otherInstResult &&
        Array.isArray(otherInstResult) &&
        otherInstResult.length > 0 &&
        otherInstResult.map((res) => {
          const {
            company: otherInstComp,
            qualification,
            qualObtainedYear,
            subject,
            maxGpa,
            cumulativeGpa,
          } = res || {};
          const { displayName: otherInstName } = otherInstComp || {};
          const { displayName: qualificationName } = qualification || {};
          const { displayName: courseName } = subject || {};
          return (
            <div>
              <div className="mt-3 h6 font-weight-bold mb-2">
                {otherInstName}
                {otherInstName === displayName ? (
                  <span>
                    {startDate || endDate ? ', ' : null}
                    {startDate ? moment(startDate).format('YYYY') : null}{' '}
                    {endDate ? '-' : null}{' '}
                    {endDate ? moment(endDate).format('YYYY') : null}
                  </span>
                ) : null}
              </div>
              {qualificationName && (
                <div className="mb-2">
                  {qualificationName}
                  {qualObtainedYear ? ',' : ''} {qualObtainedYear}
                </div>
              )}
              {courseName && <div className="mb-2">Course: {courseName}</div>}
              {maxGpa && cumulativeGpa && (
                <div className="mb-2">
                  Cumulative CAP: {cumulativeGpa} / {maxGpa}
                </div>
              )}
            </div>
          );
        })}
      {((schoolData && Array.isArray(schoolData) && schoolData.length > 0) ||
        (filteredOtherInstitutions &&
          Array.isArray(filteredOtherInstitutions) &&
          filteredOtherInstitutions.length > 0)) && (
        <div className="academic-hr-l" />
      )}
    </div>
  );
};

export {
  ALevelResults,
  OLevelResults,
  NusHighSchoolResults,
  PolyResults,
  SATDetails,
  IBDiplomaResults,
  UniversityResults,
  OtherInstitutionResults,
};
