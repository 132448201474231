import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavItems from './NavItemsList';
import {
  SideNav,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SideNavMenuItem,
} from 'carbon-components-react';

class SideNavContent extends Component {
  render() {
    const { isSideNavExpanded, roleId, onClickSideNavExpand } = this.props;
    const finalNavItems = NavItems();
    return (
      <SideNav aria-label="Side navigation" expanded={isSideNavExpanded}>
        <SideNavItems>
          {finalNavItems &&
            Array.isArray(finalNavItems) &&
            finalNavItems.length > 0 &&
            finalNavItems.map((items) => {
              const {
                label,
                path,
                subItems,
                desc,
                selectedPaths,
                accessRoles,
              } = items || {};
              return subItems && Array.isArray(subItems) && subItems.length <= 0
                ? (accessRoles &&
                  Array.isArray(accessRoles) &&
                  accessRoles.length > 0
                    ? accessRoles.includes(roleId && Number(roleId))
                    : true) && (
                    <SideNavMenuItem
                      className="labels-color"
                      element={Link}
                      onClick={() => onClickSideNavExpand(true)}
                      to={path}>
                      {label}
                    </SideNavMenuItem>
                  )
                : subItems &&
                    Array.isArray(subItems) &&
                    subItems.length > 0 &&
                    (accessRoles &&
                    Array.isArray(accessRoles) &&
                    accessRoles.length > 0
                      ? accessRoles.includes(roleId && Number(roleId))
                      : true) && (
                      <SideNavMenu title={label}>
                        {subItems.map((subIt) => {
                          const { subLabel, subItemsPath, subAccessRoles } =
                            subIt || {};
                          return (
                            (subAccessRoles &&
                            Array.isArray(subAccessRoles) &&
                            subAccessRoles.length > 0
                              ? subAccessRoles.includes(
                                  roleId && Number(roleId)
                                )
                              : true) && (
                              <SideNavMenuItem
                                onClick={() => onClickSideNavExpand(true)}
                                element={Link}
                                to={subItemsPath}>
                                {subLabel}
                              </SideNavMenuItem>
                            )
                          );
                        })}
                      </SideNavMenu>
                    );
            })}
        </SideNavItems>
      </SideNav>
    );
  }
}

export default SideNavContent;
