import callApi from './callApi';

//Common urls for api calls
import { baseUrl } from '../utils/config';

//Action types for all api calls
import {
  REQUEST_GET_COMPARE_APPL_FIELDS,
  REQUEST_UPSERT_COMPARE_APPL_FIELDS,
} from './actionTypes';

//Get call for admin list information
const getCompareApplnFields = () => (dispatch) => {
  const url = `${baseUrl}/applications/compareApplns?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_COMPARE_APPL_FIELDS,
  };
  return callApi(params);
};

const createCompareApplnFields = (data) => (dispatch) => {
  const { applicationIds } = data || {};
  if (
    !applicationIds ||
    (applicationIds &&
      Array.isArray(applicationIds) &&
      applicationIds.length <= 0)
  ) {
    return Promise.reject(new Error('Invalid application ids.'));
  }
  const url = `${baseUrl}/applications/compareApplns?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_COMPARE_APPL_FIELDS,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

export { getCompareApplnFields, createCompareApplnFields };
