import React, { Fragment } from 'react';
import { handleGetGbaTypes } from '../../components/private/CandiateMetricColorDetails';

const CandidateInsights = (props) => {
  const {
    scores,
    keys,
    label,
    hideFutureDrivenAward,
    fromPanelIntvPage = false,
  } = props || {};
  return (
    <Fragment>
      <div>
        <div className="font-weight-bold mb-2 mt-2">{label}</div>
      </div>
      <div className={`${fromPanelIntvPage ? 'bx--row' : ''}`}>
        {keys &&
          Array.isArray(keys) &&
          keys.length > 0 &&
          keys.map((res) => {
            const { key, label } = res || {};
            let finalKey = '';
            if (scores) {
              if (scores[key] >= 0) {
                finalKey = scores[key];
              } else {
                finalKey = 'NA';
              }
            } else {
              finalKey = 'NA';
            }

            return (
              <div
                className={`${
                  fromPanelIntvPage ? 'bx--col-lg-6 bx--col-md-3' : ''
                }`}>
                <div className="mt-2">
                  <div className="d-flex align-items-center">
                    <div
                      className={`csc-th--block border-radius-50 ${
                        scores && scores[`${key}Color`]
                          ? scores[`${key}Color`]
                          : 'color-gray'
                      } d-flex justify-content-center align-items-center `}>
                      <div className="">
                        {hideFutureDrivenAward
                          ? handleGetGbaTypes(scores && scores[key])
                          : finalKey}
                      </div>
                    </div>
                    <div className="">
                      <div className="css-trait-top ml-1 h6 text-capitalize">
                        {label}{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Fragment>
  );
};

export default CandidateInsights;
