import React, { Component, Fragment } from 'react';
import { Button, Select, SelectItem } from 'carbon-components-react';
import PublicHeaderMock from './PublicHeaderMock';
import GenericTabs from '../../components/common/GenericTabs';
import {
  Tile,
  Accordion,
  AccordionItem,
  TextArea,
  RadioButton,
  Modal,
} from 'carbon-components-react';
import GenericDataTable from '../../components/common/GenericDataTable';
import { Home32, Globe32 } from '@carbon/icons-react';
// import {
//   Radar,
//   RadarChart,
//   PolarGrid,
//   PolarAngleAxis,
//   PolarRadiusAxis,
// } from 'recharts';
import NivoBarChart from '../../components/common/NivoBarChart';
import 'react-sweet-progress/lib/style.css';
import MainLegend from '../../components/common/MainLegend';
import './MockUi2.css';
//Images
import MaleImg from '../../assets/images/male-img.png';
import CandidateEvalFormMock from './CandidateEvalFormMock';
import DatePicker from 'react-datepicker';
import {
  handleGetBehavioralIndicators,
  handleGetInterviewQuestions,
  handleGetTraitDefinition,
} from '../../components/private/GBAQuesDef';
import 'react-datepicker/dist/react-datepicker.css';
import PsacPeerAppraisalMock from './PsacPeerAppraisalMock';

const rowsData1 = [
  {
    name: 'Jane Doe',
    age: '18 years',
    nric: '1',
    citizenship: 'Singaporean',
    grading: 'B3',
    enlisted: '20/06/2020',
    rank: 10,
    unit: 'Sector 1',
    id: 1,
  },
];

const headers1 = [
  {
    title: 'Name',
    className: 'text-left',
    name: 'name',
    propName: 'name',
  },
  {
    title: 'Age',
    className: 'text-left',
    name: 'age',
    propName: 'age',
  },
  {
    title: 'NRIC',
    className: 'text-left',
    name: 'nric',
    propName: 'nric',
  },
  {
    title: 'Citizenship',
    className: 'text-left',
    name: 'citizenship',
    propName: 'citizenship',
  },
  {
    title: 'NS Medical Grading',
    className: 'text-center ',
    name: 'grading',
    propName: 'grading',
  },
  {
    title: 'Date Enlisted',
    className: 'text-center ',
    name: 'enlisted',
    propName: 'enlisted',
  },
  {
    title: 'Rank',
    className: 'text-center ',
    name: 'rank',
    propName: 'rank',
  },
  {
    title: 'Unit',
    className: 'text-center ',
    name: 'unit',
    propName: 'unit',
  },
  {
    title: 'Vocation',
    className: 'text-center ',
    name: 'vocation',
    propName: 'vocation',
  },
];

const rowsData2 = [
  {
    subj: 'General Paper Work',
    score: 'H1',
    grade: 'A',
  },
  {
    subj: 'Project Work - Oral',
    score: 'H1',
    grade: 'A',
  },
  {
    subj: 'Physics',
    score: '-',
    grade: 'Distinction',
  },
  {
    subj: 'Economics',
    score: 'H2',
    grade: 'A',
  },
  {
    subj: 'Mathematics',
    score: 'H2',
    grade: 'A',
  },
  {
    subj: 'Chemistry',
    score: 'H2',
    grade: 'A',
  },
];

const rowsData3 = [
  {
    subj: 'Biology',
    grade: 'A1',
  },
  {
    subj: 'Chemistry',
    grade: 'A2',
  },
  {
    subj: 'Chemistry Honours',
    grade: 'B3',
  },
  {
    subj: 'English Language',
    grade: 'B4',
  },
  {
    subj: 'Humanities, Art and music',
    grade: 'B6',
  },
  {
    subj: 'Mathematics',
    grade: 'C7',
  },
  {
    subj: 'Mathematics Honours',
    grade: 'A1',
  },
  {
    subj: 'Mother Tongue (Higher Chinese)',
    grade: 'B3',
  },
  {
    subj: 'Physics',
    grade: 'B4',
  },
  {
    subj: 'Physics Honours',
    grade: 'A1',
  },
];

const rowsData5 = [
  {
    no: 1,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Commitment',
    desc: 'Displays a desire to contribute to the organisation or wide community and has thought though how to do this via his/her chosen career',
  },
  {
    no: 2,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Knowledge(& Intellectual Qualities)',
    desc: 'Displays good general Knowledge (world events, issues and related matters), analyse issues objectively from multiple perspectives and form sound judgement',
  },
  {
    no: 2,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Innovativeness',
    desc: 'Displays curiosity and develops innovative solutions',
  },
  {
    no: 4,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'EQ & Empathy',
    desc: 'Displays an awareness of others’ perspectives and reactions and responds to them empathetically.',
  },
  {
    no: 5,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Communication Skills',
    desc: 'Organises his/her thoughts and expresses them in a simple and clean manner',
  },
  {
    no: 6,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Integrity (Accountabillity and Courage)',
    desc: 'Takes responsibility for his/her own actions and stands up for his/her views',
  },
  {
    no: 7,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Personal Impact',
    desc: 'Projects leadership presence and confidence – able to persuade or convince others to support an idea, agenda or direction.',
  },
  {
    no: 8,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Performance Under Pressure',
    desc: 'Remains calm and composed in the face of difficult questions or when challenged during the interview.',
  },
  {
    no: 9,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Self-belief',
    desc: 'Feels assured about him/herself, abilities, potential and appears ready to take on challenges',
  },
  {
    no: 10,
    key1: null,
    key2: null,
    key3: null,
    key4: null,
    key5: null,
    key6: null,
    name: 'Self-monitoring',
    desc: 'Monitors his/her own words and behaviour and adjusts them appropriately for the situation.',
  },
];

const headers3 = [
  {
    title: 'Subject',
    className: 'text-left w-50',
    name: 'subj',
    propName: 'subj',
  },
  {
    title: 'Grade',
    className: 'text-left w-50',
    name: 'grade',
    propName: 'grade',
  },
];

const headers2 = [
  {
    title: 'Subject',
    className: 'text-left ',
    name: 'subj',
    propName: 'subj',
  },
  {
    title: 'Score',
    className: 'text-center ',
    name: 'score',
    propName: 'score',
  },
  {
    title: 'Grade',
    className: 'text-center ',
    name: 'grade',
    propName: 'grade',
  },
];

const headers4 = [
  {
    title: 'S.No',
    className: 'text-left',
    name: 'nso',
    propName: 'nso',
  },
  {
    title: 'University',
    className: 'text-left w-25',
    name: 'university',
    propName: 'university',
  },
  {
    title: 'Course',
    className: 'text-center w-25',
    name: 'course',
    propName: 'course',
  },
  {
    title: 'Status',
    className: 'text-center w-25',
    name: 'status',
    propName: 'status',
  },
  {
    title: 'Local/Overseas',
    className: 'text-center w-25',
    name: 'locOver',
    propName: 'locOver',
  },
];

const rowsData6 = [
  {
    university: 'Massachusetts Institute of Technology',
    course: 'Physics',
    status: 'Pending Application',
    locOver: 'Overseas',
  },
  {
    university: 'University of Cambridge',
    course: 'Physics',
    status: 'Pending Application',
    locOver: 'Overseas',
  },
  {
    university: 'University of Oxford',
    course: 'Physics',
    status: 'Pending Application',
    locOver: 'Overseas',
  },
  {
    university: 'National University of Singapore',
    course: 'Physics',
    status: 'Pending Application',
    locOver: 'local',
  },
  {
    university: 'Nanyang Technological University',
    course: 'Physics',
    status: 'Pending Application',
    locOver: 'local',
  },
];

const rowsData4 = [
  {
    no: 1,
    name: 'Candidate Name 1',
    nric: '-',
    gender: 'Female',
    test1: '36',
    oms1: 'M',
    sgs1: 'M',
    lms1: 'M',
    test2: '37',
    oms2: 'M',
    sgs2: 'M',
    lms2: 'M',
    overallOms: 'DM',
    overallSgs: 'M',
    overallLms: 'M',
  },
  {
    no: 2,
    name: 'Candidate Name 2',
    nric: '-',
    gender: 'Male',
    test1: '3',
    oms1: 'M',
    sgs1: 'M',
    lms1: 'M',
    test2: '37',
    oms2: 'M',
    sgs2: 'M',
    lms2: 'M',
    overallOms: 'DM',
    overallSgs: 'M',
    overallLms: 'M',
  },
  {
    no: 3,
    name: 'Jane Doe',
    nric: '-',
    gender: 'Female',
    test1: '3',
    oms1: 'M',
    sgs1: 'M',
    lms1: 'M',
    test2: '37',
    oms2: 'M',
    sgs2: 'M',
    lms2: 'M',
    overallOms: 'DM',
    overallSgs: 'M',
    overallLms: 'M',
  },
  {
    no: 4,
    name: 'Candidate Name 4',
    nric: '-',
    gender: 'Male',
    test1: '3',
    oms1: 'M',
    sgs1: 'M',
    lms1: 'M',
    test2: '37',
    oms2: 'M',
    sgs2: 'M',
    lms2: 'M',
    overallOms: 'DM',
    overallSgs: 'M',
    overallLms: 'M',
  },
  {
    no: 5,
    name: 'Candidate Name 5',
    nric: '-',
    gender: 'Female',
    test1: '3',
    oms1: 'M',
    sgs1: 'M',
    lms1: 'M',
    test2: '37',
    oms2: 'M',
    sgs2: 'M',
    lms2: 'M',
    overallOms: 'DM',
    overallSgs: 'M',
    overallLms: 'M',
  },
  {
    no: 6,
    name: 'Candidate Name 6',
    nric: '-',
    gender: 'Male',
    test1: '3',
    oms1: 'M',
    sgs1: 'M',
    lms1: 'M',
    test2: '37',
    oms2: 'M',
    sgs2: 'M',
    lms2: 'M',
    overallOms: 'DM',
    overallSgs: 'M',
    overallLms: 'M',
  },
  {
    no: 7,
    name: 'Candidate Name 7',
    nric: '-',
    gender: 'Female',
    test1: '3',
    oms1: 'M',
    sgs1: 'M',
    lms1: 'M',
    test2: '37',
    oms2: 'M',
    sgs2: 'M',
    lms2: 'M',
    overallOms: 'DM',
    overallSgs: 'M',
    overallLms: 'M',
  },
];

class MockUI2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      panelInterviewDate: new Date(),
      inshorizontalData: [
        {
          group: 'Realistic',
          value: 65000,
        },
        {
          group: 'Investigatve',
          value: 29123,
        },
        {
          group: 'Artistic',
          value: 35213,
        },
        {
          group: 'Social',
          value: 51213,
        },
        {
          group: 'Enterprising',
          value: 16932,
        },
        {
          group: 'Conventional',
          value: 16932,
        },
      ],
      inshorizontalOpts: {
        title: 'RIASEC',
        axes: {
          left: {
            mapsTo: 'group',
            scaleType: 'labels',
          },
          bottom: {
            mapsTo: 'value',
          },
        },
        height: '220px',
      },
      riasecData: [
        {
          subject: 'Realistic',
          A: 120,
          B: 110,
          fullMark: 150,
        },
        {
          subject: 'Investigatve',
          A: 98,
          B: 130,
          fullMark: 150,
        },
        {
          subject: 'Artistic',
          A: 86,
          B: 130,
          fullMark: 150,
        },
        {
          subject: 'Social',
          A: 99,
          B: 100,
          fullMark: 150,
        },
        {
          subject: 'Enterprising',
          A: 85,
          B: 90,
          fullMark: 150,
        },
        {
          subject: 'Conventional',
          A: 65,
          B: 85,
          fullMark: 150,
        },
      ],
      horzData: [
        {
          key: 'Altruism',
          Altruism: 9,
        },
        {
          key: 'Authenticity',
          Authenticity: 8,
        },
        {
          key: 'Concentration',
          Concentration: 5,
        },
        {
          key: 'Creativity',
          Creativity: 2,
        },
        {
          key: 'Curiosity',
          Curiosity: 6,
        },
        {
          key: 'Deliberation',
          Deliberation: 10,
        },
        {
          key: 'Determination',
          Determination: 4,
        },
        {
          key: 'Emotion Recognition',
          'Emotion Recognition': 8,
        },
        {
          key: 'Executive Function',
          'Executive Function': 9,
        },
        {
          key: 'Future Orientation',
          'Future Orientation': 4,
        },
        {
          key: 'Innovation Potential',
          'Innovation Potential': 5,
        },
        {
          key: 'Learning Agility',
          'Learning Agility': 9,
        },
        {
          key: 'Managing Uncertainity',
          'Managing Uncertainity': 2,
        },
        {
          key: 'Need For Structure',
          'Need For Structure': 8,
        },
        {
          key: 'Novelty Seeking',
          'Novelty Seeking': 4,
        },
        {
          key: 'Optimism',
          Optimism: 9,
        },
        {
          key: 'Ownership and Responsibility',
          'Ownership and Responsibility': 4,
        },
        {
          key: 'Performance under pressure',
          'Performance under pressure': 5,
        },
        {
          key: 'Politeness',
          Politeness: 8,
        },
        {
          key: 'Proactivity',
          Proactivity: 9,
        },
        {
          key: 'Processing Capacity',
          'Processing Capacity': 2,
        },
        {
          key: 'Processing Consistence',
          'Processing Consistence': 8,
        },
        {
          key: 'Processing Speed',
          'Processing Speed': 6,
        },
        {
          key: 'Rational Decision Making',
          'Rational Decision Making': 2,
        },
        {
          key: 'Resilience',
          Resilience: 8,
        },
        {
          key: 'Risk Propensity',
          'Risk Propensity': 2,
        },
        {
          key: 'Self Monitoring',
          'Self Monitoring': 5,
        },
        {
          key: 'Self-Belief',
          'Self-Belief': 4,
        },
        {
          key: 'Self Descipline',
          'Self Descipline': 9,
        },
        {
          key: 'Sensitivity to loss',
          'Sensitivity to loss': 4,
        },
        {
          key: 'Sensitivity to reward',
          'Sensitivity to reward': 6,
        },
        {
          key: 'Sociability',
          Sociability: 1,
        },
        {
          key: 'Social Dominance',
          'Social Dominance': 10,
        },
      ],
      horzOpts: {
        title: 'Personality Profile (Stens)',
        axes: {
          left: {
            mapsTo: 'group',
            scaleType: 'labels',
          },
          bottom: {
            mapsTo: 'value',
          },
        },
        height: '800px',
      },
      groupedData: [
        {
          key: 'Altruism',
          Altruism: 9,
        },
        {
          key: 'Authenticity',
          Authenticity: -8,
        },
        {
          key: 'Concentration',
          Concentration: -5,
        },
        {
          key: 'Creativity',
          Creativity: 2,
        },
        {
          key: 'Curiosity',
          Curiosity: -6,
        },
        {
          key: 'Deliberation',
          Deliberation: 10,
        },
        {
          key: 'Determination',
          Determination: 4,
        },
        {
          key: 'Emotion Recognition',
          'Emotion Recognition': -8,
        },
        {
          key: 'Executive Function',
          'Executive Function': 9,
        },
        {
          key: 'Future Orientation',
          'Future Orientation': 4,
        },
        {
          key: 'Innovation Potential',
          'Innovation Potential': 5,
        },
        {
          key: 'Learning Agility',
          'Learning Agility': -9,
        },
        {
          key: 'Managing Uncertainity',
          'Managing Uncertainity': 2,
        },
        {
          key: 'Need For Structure',
          'Need For Structure': 8,
        },
        {
          key: 'Novelty Seeking',
          'Novelty Seeking': -4,
        },
        {
          key: 'Optimism',
          Optimism: 9,
        },
        {
          key: 'Ownership and Responsibility',
          'Ownership and Responsibility': -4,
        },
        {
          key: 'Performance under pressure',
          'Performance under pressure': 5,
        },
        {
          key: 'Politeness',
          Politeness: 8,
        },
        {
          key: 'Proactivity',
          Proactivity: 9,
        },
        {
          key: 'Processing Capacity',
          'Processing Capacity': 2,
        },
        {
          key: 'Processing Consistence',
          'Processing Consistence': -8,
        },
        {
          key: 'Processing Speed',
          'Processing Speed': 6,
        },
        {
          key: 'Rational Decision Making',
          'Rational Decision Making': 2,
        },
        {
          key: 'Resilience',
          Resilience: 8,
        },
        {
          key: 'Risk Propensity',
          'Risk Propensity': 2,
        },
        {
          key: 'Self Monitoring',
          'Self Monitoring': -5,
        },
        {
          key: 'Self-Belief',
          'Self-Belief': 4,
        },
        {
          key: 'Self Descipline',
          'Self Descipline': 9,
        },
        {
          key: 'Sensitivity to loss',
          'Sensitivity to loss': 4,
        },
        {
          key: 'Sensitivity to reward',
          'Sensitivity to reward': -6,
        },
        {
          key: 'Sociability',
          Sociability: -1,
        },
        {
          key: 'Social Dominance',
          'Social Dominance': 10,
        },
      ],
      groupedOpts: {
        title: 'Personality Profile(Z-Score)',
        axes: {
          left: {
            scaleType: 'labels',
            mapsTo: 'key',
          },
          bottom: {
            mapsTo: 'value',
          },
        },
        height: '800px',
      },
      isSideNavExpanded: false,
    };
  }

  handleTabClick = (tabNumber) => {
    this.setState({
      selectedTab: tabNumber,
    });
  };

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  onDatePickerChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleBarOnClick = (data) => {
    const { definition, key } = data || {};
    this.setState({
      isOpenModal: true,
      definition,
      heading: key,
    });
  };

  render() {
    const wag = 95;
    const anra = 20;
    let wagColor = '';
    if (wag > 50) {
      wagColor = 'csc-th--h';
    } else if (wag >= 31 && wag <= 45) {
      wagColor = 'css-th-green-l';
    } else {
      wagColor = 'csc-th--vl';
    }
    let anraColor = '';
    if (anra > 90) {
      anraColor = 'csc-th--h';
    } else if (anra >= 21 && anra <= 89) {
      anraColor = 'css-th-green-l';
    } else {
      anraColor = 'csc-th--vl';
    }
    const { history, match } = this.props;
    const { push } = history || {};
    const { params } = match || {};
    const { name } = params || {};
    const fullName = decodeURIComponent(name);
    const finalFullName =
      fullName && fullName.toString().replace('undefined', '');
    const { selectedTab, isSideNavExpanded, panelInterviewDate } = this.state;
    return (
      <div>
        <PublicHeaderMock
          onClickSideNavExpand={this.onClickSideNavExpand}
          isSideNavExpanded={isSideNavExpanded}
          push={push}
          history={history}
        />
        <div className="bx--grid mt-5 xpa--ht-70">
          <Tile className="mt-2 mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex align-items-center">
                  <div
                    className="h5 font-weight-bold text-capitalize"
                    style={{ fontSize: '24px' }}>
                    {finalFullName}{' '}
                    <span className="text-uppercase">(SXXXX072E)</span>
                  </div>
                </div>
                <div className="d-flex ml-0 mb-2">
                  <div className="css-default-text-color f-w-b">
                    University of Oxford
                  </div>
                  <div className="ml-4">
                    <span className="css-default-text-color f-w-b">
                      School Rank:
                    </span>{' '}
                    {'-'}
                  </div>
                </div>
              </div>
              <div>
                <Button>Selection Note Export</Button>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <div>
                <div className="css-default-text-color f-w-b mb-2">
                  Panel Intevriew
                </div>
                <div className="d-flex align-items-center">
                  <div className="">
                    <Select disabled value={1} labelText="Main Interviewer">
                      <SelectItem text="Select" />
                      <SelectItem value={1} text="Mr. Lee Tzu Yang" />
                      <SelectItem text="Mr. Kwa Chong Seng" />
                      <SelectItem text="Ms. Chua Sock Koong" />
                      <SelectItem text="Mr. Michael Lim" />
                      <SelectItem text="Mr. Po'ad Mattar" />
                      <SelectItem text="Mr. Edward D'Silva" />
                      <SelectItem text="Prof. Lily Kong" />
                      <SelectItem text="Mr. Philip Jeyaretnam" />
                      <SelectItem text="Mr. Alan Chan" />
                      <SelectItem text="Prof. Tan Ser Kiat" />
                      <SelectItem text="Dr. Sudha Nair" />
                    </Select>
                  </div>
                  <div className="ml-3">
                    <div className="">
                      <label className="bx--label">Panel Interview</label>
                    </div>
                    <DatePicker
                      disabled
                      selected={panelInterviewDate}
                      onChange={(date) =>
                        this.onDatePickerChange(date, 'panelInterviewDate')
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      className="css-h-d-input"
                      timeCaption="time"
                      dateFormat="dd/MM/yyyy, h:mm aa"
                    />
                  </div>
                  <div className="ml-3 mt-3">
                    <Button disabled>Save</Button>
                  </div>
                </div>
              </div>
              <div>
                <div className="css-default-text-color f-w-b mb-2">
                  President's Scholarship
                </div>
                <div className="d-flex align-items-center">
                  <div className="">
                    <Select labelText="Main Interviewer">
                      <SelectItem text="Select" />
                      <SelectItem text="Mr. Lee Tzu Yang" />
                      <SelectItem text="Mr. Kwa Chong Seng" />
                      <SelectItem text="Ms. Chua Sock Koong" />
                      <SelectItem text="Mr. Michael Lim" />
                      <SelectItem text="Mr. Po'ad Mattar" />
                      <SelectItem text="Mr. Edward D'Silva" />
                      <SelectItem text="Prof. Lily Kong" />
                      <SelectItem text="Mr. Philip Jeyaretnam" />
                      <SelectItem text="Mr. Alan Chan" />
                      <SelectItem text="Prof. Tan Ser Kiat" />
                      <SelectItem text="Dr. Sudha Nair" />
                    </Select>
                  </div>
                  <div className="ml-3">
                    <div className="">
                      <label className="bx--label">Panel Interview</label>
                    </div>
                    <DatePicker
                      selected={panelInterviewDate}
                      onChange={(date) =>
                        this.onDatePickerChange(date, 'panelInterviewDate')
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      className="css-h-d-input"
                      timeCaption="time"
                      dateFormat="dd/MM/yyyy, h:mm aa"
                    />
                  </div>
                  <div className="ml-3 mt-3">
                    <Button>Save</Button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              Main Interviewer:{' '}
              <span
                title="Main Interviewer"
                className="font-weight-bold text-black">
                Lee Tzu Yang
              </span>
            </div>
            <div className="mt-2">Panel Interview: 23/06/2020</div> */}
            <hr />
            <div className="h6 font-weight-bold mt-2 mb-2">
              Candidate Insights
            </div>
            <div className="bx--row">
              <div className="bx--col-lg-3 bx--col-md-4">
                <div className="">
                  <div className="font-weight-bold mb-2 mt-2">
                    Cognitive Ability Scores (Percentile)
                  </div>
                  <div className="">
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div
                          className={`csc-th--block border-radius-50 ${anraColor}`}>
                          <div className="csc-pos--top-c">{anra}</div>
                        </div>
                        <div className="">
                          <div className="csc-pos--top-c ml-1 h6">
                            Numerical Reasoning
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div
                          className={`csc-th--block border-radius-50 ${wagColor}`}>
                          <div className="csc-pos--top-c">{wag}</div>
                        </div>
                        <div className="">
                          <div className="csc-pos--top-c ml-1 h6">
                            Critical Thinking{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx--col-lg-3 bx--col-md-4">
                <div className="">
                  <div className="font-weight-bold mb-2 mt-2">GBA Scores</div>
                  <div className="">
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="csc-th--block border-radius-50 csc-th--h">
                          <div className="csc-pos--top-c">{87}</div>
                        </div>
                        <div className="">
                          <div className="csc-pos--top-c ml-1 h6">
                            Performance Based{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="csc-th--block border-radius-50 csc-th--l">
                          <div className="csc-pos--top-c">{35}</div>
                        </div>
                        <div className="">
                          <div className="csc-pos--top-cp ml-1 h6 mt-2">
                            Future Driven
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="csc-th--block border-radius-50 csc-th--l">
                          <div className="csc-pos--top-c">{54}</div>
                        </div>
                        <div className="">
                          <div className="csc-pos--top-c ml-1 h6">Award</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="csc-th--block border-radius-50 csc-th--l">
                          <div className="csc-pos--top-c">{59}</div>
                        </div>
                        <div className="">
                          <div className="csc-pos--top-c ml-1 h6">Average</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx--col-lg-3 bx--col-md-4">
                <div className="">
                  <div className="font-weight-bold mb-2 mt-3 mt-xl-2">
                    NEO PI-R
                  </div>

                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--vl">
                        <div className="css-top-insights">{1}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Neuroticism / Emotional Stability
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--h">
                        <div className="css-top-insights">{5}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Extraversion
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--h">
                        <div className="css-top-insights">{4}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Openness
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--l">
                        <div className="css-top-insights">{3}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Agreeableness
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--vl">
                        <div className="css-top-insights">{2}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Conscientiousness
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx--col-lg-3 bx--col-md-4 mt-3 mt-xl-2">
                <div className="">
                  <div className="font-weight-bold mb-2">
                    Psychological Interview Rating
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--h">
                        <div className="css-top-insights">{5}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Leadership Qualities
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--h">
                        <div className="css-top-insights">{4}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Intellectual Qualities
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--vl">
                        <div className="css-top-insights">{2}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Character and Values
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--vl">
                        <div className="css-top-insights">{1}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Interpersonal Skills
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="csc-th--block border-radius-50 csc-th--l">
                        <div className="css-top-insights">{3}</div>
                      </div>
                      <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0 ml-2">
                        Resilience
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <MainLegend showNeoPIR />
            <hr />

            <div className="font-weight-bold mb-2">Remarks</div>
            <div className="w-100">
              <TextArea placeholder="Please enter your remarks" rows={3} />
            </div>
            <div className=" mt-2">
              <Button size="small">Save</Button>
            </div>
          </Tile>
          <Tile className="mt-2">
            <div className="h6 font-weight-bold mt-2 mb-2">
              Preferences (Top 3)
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-3 bx--col-md-2 mt-2">
                <div className="">
                  <div className="font-weight-bold">Career</div>
                  <div className="mt-2">1. Public Administration</div>
                  <div className="mt-2">2. Engineering</div>
                  <div className="mt-2">3. Foreign service</div>
                </div>
              </div>
              <div className="bx--col-lg-3 bx--col-md-2 mt-2">
                <div className="">
                  <div className="font-weight-bold">University</div>
                  <div className="mt-2">1. University of Cambridge</div>
                  <div className="mt-2">2. University of Oxford</div>
                  <div className="mt-2">
                    3. National University of Singapore
                  </div>
                </div>
              </div>
              <div className="bx--col-lg-3 bx--col-md-2 mt-2">
                <div className="">
                  <div className="font-weight-bold">Course</div>
                  <div className="mt-2">1. Physics</div>
                  <div className="mt-2">2. Applied Physics</div>
                  <div className="mt-2">3. Chemistry</div>
                </div>
              </div>
              <div className="bx--col-lg-3 bx--col-md-2 mt-2">
                <div className="">
                  <div className="font-weight-bold">Country</div>
                  <div className="mt-2">1. United States of America</div>
                  <div className="mt-2">2. United Kingdom</div>
                  <div className="mt-2">3. Singapore</div>
                </div>
              </div>
            </div>
          </Tile>
          <Tile style={{ minHeight: 430 }} className="mt-2">
            <div className="bx--row">
              <div className="bx--col-lg-2 bx--col-md-2">
                <GenericTabs
                  className="xpa-cand-info--tabs xpa-c-tabs--vertical m-0"
                  selected={selectedTab}
                  tabContent={[
                    {
                      label: 'Personal Information',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(0);
                      },
                    },
                    {
                      label: 'Applications',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(1);
                      },
                    },
                    {
                      label: 'Academic Results',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(2);
                      },
                    },
                    {
                      label: 'CCA & Achievements',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(3);
                      },
                    },
                    {
                      label: 'Psychometric Tests',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(4);
                      },
                    },
                    {
                      label: 'GBA',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(5);
                      },
                    },
                    {
                      label: 'Video Interview',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(6);
                      },
                    },
                    {
                      label: 'Reports',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(7);
                      },
                    },
                    {
                      label: 'Candidate Evaluation',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(8);
                      },
                    },
                    {
                      label: 'President’s Scholarship',
                      value: '',
                      onClick: () => {
                        this.handleTabClick(9);
                      },
                    },
                  ]}
                />
              </div>
              <div className="bx--col-lg-10 bx--col-md-6 pr-1 pl-0">
                {selectedTab === 0 && (
                  <Fragment>
                    <div className="some-content">
                      <div className="mt-2 h6 font-weight-bold">
                        Personal and Family Information
                      </div>
                      <hr />
                      <div>
                        <GenericDataTable
                          className=""
                          otherActions={[]}
                          expandedRows={[
                            <div className="bx--row">
                              <div className="bx--col-lg-6 bx--col-md-4">
                                <div className="font-weight-bold">
                                  <b>Father's Info</b>
                                </div>
                                <hr />
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">Name</div>
                                  <div className="bx--col-lg-4">Harry</div>
                                </div>
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">
                                    Citizenship
                                  </div>
                                  <div className="bx--col-lg-4">
                                    Singaporean
                                  </div>
                                </div>
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">
                                    Occupation
                                  </div>
                                  <div className="bx--col-lg-4">
                                    Lead Commander
                                  </div>
                                </div>
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">
                                    Marital Status
                                  </div>
                                  <div className="bx--col-lg-4">Married</div>
                                </div>
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">
                                    Scholarship Held
                                  </div>
                                  <div className="bx--col-lg-4">Yes</div>
                                </div>
                              </div>
                              <div className="bx--col-lg-6 bx--col-md-4">
                                <div className="font-weight-bold">
                                  <b>Mother's Info</b>
                                </div>
                                <hr />
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">Name</div>
                                  <div className="bx--col-lg-4">Gene</div>
                                </div>
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">
                                    Citizenship
                                  </div>
                                  <div className="bx--col-lg-4">Canadian</div>
                                </div>
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">
                                    Occupation
                                  </div>
                                  <div className="bx--col-lg-4">House wife</div>
                                </div>
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">
                                    Marital Status
                                  </div>
                                  <div className="bx--col-lg-4">Married</div>
                                </div>
                                <div className="bx--row mt-2">
                                  <div className="bx--col-lg-4 f-w-b">
                                    Scholarship Held
                                  </div>
                                  <div className="bx--col-lg-4">No</div>
                                </div>
                              </div>
                            </div>,
                          ]}
                          headers={headers1.map((data, index) => ({
                            key: `header_${index}`,
                            header: data.title,
                            className: data.className,
                            colpropName: data.propName,
                          }))}
                          rows={rowsData1.map((res) => {
                            const {
                              age,
                              name,
                              citizenship,
                              unit,
                              enlisted,
                              grading,
                              rank,
                              nric,
                            } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${res && res.id}`,
                              header_0: (
                                <div className="d-flex align-items-center">
                                  <div>
                                    <img
                                      style={{ width: '20px' }}
                                      src={MaleImg}
                                      alt="Male"
                                    />
                                  </div>
                                  <div className="ml-2">{name}</div>
                                </div>
                              ),
                              header_1: <div>{age}</div>,
                              header_2: <div>Txxxx516I</div>,
                              header_3: <div>{citizenship}</div>,
                              header_4: <div>{grading}</div>,
                              header_5: <div>{enlisted}</div>,
                              header_6: <div>{rank}</div>,
                              header_7: <div>{unit}</div>,
                              header_8: <div>-</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable
                          batchActions={false}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 1 && (
                  <Fragment>
                    <div className="some-content">
                      <div className="mt-2 h6 font-weight-bold">
                        Academic Information
                      </div>
                      <hr />
                      <div className="mt-2 mb-3 font-weight-bold">
                        University and Scholarship Applications
                      </div>
                      <div>
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={headers4.map((data, index) => ({
                            key: `header_${index}`,
                            header: data.title,
                            className: data.className,
                            colpropName: data.propName,
                          }))}
                          rows={rowsData6.map((res, idx) => {
                            const { university, course, status, locOver } =
                              res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{idx + 1}</div>,
                              header_1: <div>{university}</div>,
                              header_2: <div>{course}</div>,
                              header_3: <div>{status}</div>,
                              header_4: (
                                <div>
                                  {locOver === 'local' ? (
                                    <Home32 />
                                  ) : (
                                    <Globe32 />
                                  )}
                                </div>
                              ),
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                      <div className="mt-3 mb-3 font-weight-bold">
                        Other Scholarship Application
                      </div>
                      <div>
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              header: 'S.No',
                              className: 'text-left',
                              key: 'header_0',
                            },
                            {
                              key: 'header_1',
                              header: 'Organization',
                              className: 'w-25 text-left',
                            },
                            {
                              key: 'header_2',
                              header: 'Course',
                              className: 'w-25 text-center',
                            },
                            {
                              key: 'header_3',
                              header: 'Outcome',
                              className: 'w-25 text-center',
                            },
                            {
                              key: 'header_4',
                              header: '',
                              className: 'w-25 text-center',
                            },
                            {
                              key: 'header_5',
                              header: '',
                              className: 'w-25 text-center',
                            },
                          ]}
                          rows={[
                            {
                              scholarship: 'A * Star',
                              course: 'Physics',
                              status: 'Pending Outcome',
                            },
                            {
                              scholarship: 'LTA',
                              course: 'Physics',
                              status: 'Offered',
                            },
                          ].map((res, idx) => {
                            const { course, scholarship, status } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{idx + 1}</div>,
                              header_1: <div>{scholarship}</div>,
                              header_2: <div>{course}</div>,
                              header_3: <div>{status}</div>,
                              header_4: null,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 2 && (
                  <Fragment>
                    <div className="some-content">
                      <div className="mt-2 h6 font-weight-bold">
                        Academic Results
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                          <div className="h6 mb-2 font-weight-bold">
                            National University of Singapore, 2018-2022
                          </div>
                          <div>GCE A Level, 2019</div>
                          <div className="mb-2 mt-2">
                            No Of Academic Units: 11
                          </div>
                          <div className="mt-2 mb-2">
                            PSC Points (max = 70): 69
                          </div>
                          <div className="mt-1">
                            Honours (Highest Distinction)
                          </div>
                        </div>
                        <div className="">
                          <Button className="bx--btn-center-text">
                            <a
                              target="_blank"
                              className="css-color-w"
                              href="/public/reports">
                              View Transcript
                            </a>
                          </Button>
                        </div>
                      </div>
                      <div className="mt-3">
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'Subject',
                              className: 'w-50',
                            },
                            { key: 'header_1', header: 'Subject Level' },
                            { key: 'header_2', header: 'Grade' },
                          ]}
                          rows={rowsData2.map((res, idx) => {
                            const { subj, score, grade } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{subj}</div>,
                              header_1: <div>{score}</div>,
                              header_2: <div>{grade}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                      <div className="h6 font-weight-bold mt-3 mb-1">
                        NUS High School of Mathematics and science, 2015 - 2017
                      </div>
                      <div className="mt-2 mb-2">Cum CPA - 3.5/5.0</div>
                      <div className="mt-3 mb-3">
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'Subject',
                              className: 'text-left w-50',
                            },
                            {
                              key: 'header_1',
                              header: 'CAP',
                              className: 'text-left w-50',
                            },
                          ]}
                          rows={[
                            {
                              subj: 'English Language',
                              grade: '3.8',
                            },
                            {
                              subj: 'Oral',
                              grade: '3.7',
                            },
                            {
                              subj: 'Literature In English',
                              grade: '3.2',
                            },
                            {
                              subj: 'Combined Humanities (revised)',
                              grade: '3.7',
                            },
                            {
                              subj: 'Mathematics',
                              grade: '3.4',
                            },
                            {
                              subj: 'Additional Mathematics',
                              grade: '3.1',
                            },
                          ].map((res, idx) => {
                            const { subj, grade } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{subj}</div>,
                              header_1: <div>{grade}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                      <div className="h6 font-weight-bold mt-2 mb-1">
                        YY Secondary School, 2014 - 2015
                      </div>
                      <div className="mt-2 mb-2">GCE O-level, 2015</div>
                      <div className="mt-3 mb-3">
                        <GenericDataTable
                          className=""
                          otherActions={[]}
                          tbodyClass="candidate-background-color"
                          headers={headers3.map((data, index) => ({
                            key: `header_${index}`,
                            header: data.title,
                            className: data.className,
                            colpropName: data.propName,
                          }))}
                          rows={rowsData3.map((res, idx) => {
                            const { subj, grade } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{subj}</div>,
                              header_1: <div>{grade}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                      <div className="mt-2 h6 font-weight-bold mb02">
                        SAT Results
                      </div>
                      <div className="mt-3 mb-3">
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'Subject',
                              className: 'w-50',
                            },
                            {
                              key: 'header_1',
                              header: 'Score',
                              className: 'sat-score-wdth',
                            },
                            { key: 'header_2', header: 'Percentile' },
                          ]}
                          rows={[
                            {
                              subj: 'Evidence Based - Reading and Writing',
                              score: 720,
                              percent: 98,
                            },
                            {
                              subj: 'Math',
                              score: 700,
                              percent: 95,
                            },
                            {
                              subj: 'Essay',
                              score: '-',
                              percent: 98,
                            },
                            {
                              subj: (
                                <div style={{ fontWeight: 'bold' }}>Total</div>
                              ),
                              score: (
                                <div style={{ fontWeight: 'bold' }}>1420</div>
                              ),
                              percent: '-',
                            },
                          ].map((res, idx) => {
                            const { subj, score, percent } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{subj}</div>,
                              header_1: <div>{score}</div>,
                              header_2: <div>{percent}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 3 && (
                  <Fragment>
                    <div className="some-content">
                      <div className="mt-2 h6 font-weight-bold">
                        CCA, Achievements, Non-Academic Qualification and
                        Internships
                      </div>
                      <hr />
                      <div>
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'CCA',
                              className: 'w-50',
                            },
                            { key: 'header_1', header: 'Tier' },
                          ]}
                          rows={[
                            { name: 'Leadership', value: 'Tier 3' },
                            { name: 'Representation', value: 'Tier 3' },
                          ].map((res, idx) => {
                            const { name, value } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{name}</div>,
                              header_1: <div>{value}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                      <div className="mt-2">
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'Achievements',
                              className: 'w-50',
                            },
                            { key: 'header_1', header: 'Year' },
                          ]}
                          rows={[
                            {
                              name: 'Finalist at Singapore Science and Engineering Fair',
                              value: '2019',
                            },
                            {
                              name: 'A*STAR JS Science Award',
                              value: '2018-2019',
                            },
                            {
                              name: 'Singapore Chemistry Olymppiad Bronze Medal',
                              value: '2018',
                            },
                            {
                              name: '40th QueenStwon CC chess championship U-16 !st Place',
                              value: '2017',
                            },
                          ].map((res, idx) => {
                            const { name, value } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{name}</div>,
                              header_1: <div>{value}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                      <div className="mt-2">
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'Non-Academic Qualification',
                              className: 'w-50',
                            },
                            { key: 'header_1', header: 'Year' },
                          ]}
                          rows={[
                            { name: 'Kayaking 1-star', value: '2015' },
                            { name: 'Swinsafer Gold Award', value: '2010' },
                          ].map((res, idx) => {
                            const { name, value } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{name}</div>,
                              header_1: <div>{value}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                      <div className="mt-2">
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'Internship',
                              className: 'w-50',
                            },
                            { key: 'header_1', header: 'Details' },
                          ]}
                          rows={[
                            {
                              name: 'Organisation',
                              value: 'Singapore Airlines',
                            },
                            {
                              name: 'Department / Unit',
                              value: 'Human Resources',
                            },
                            {
                              name: 'Period',
                              value: '22 June 2020 to 06 July 2020',
                            },
                            {
                              name: 'Description',
                              value:
                                'Developing predictive analytics solution based on cabin crew data.',
                            },
                            {
                              name: "Supervisor's Comments",
                              value: (
                                <TextArea
                                  placeholder="Please enter your comments"
                                  rows={3}
                                />
                              ),
                            },
                          ].map((res, idx) => {
                            const { name, value } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{name}</div>,
                              header_1: <div>{value}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                      <div className="mt-2">
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            {
                              key: 'header_0',
                              header: 'Internship',
                              className: 'w-50',
                            },
                            { key: 'header_1', header: 'Details' },
                          ]}
                          rows={[
                            {
                              name: 'Organisation',
                              value: 'Singapore Telecommunications Limited',
                            },
                            { name: 'Department / Unit', value: 'StarHub' },
                            {
                              name: 'Period',
                              value: '21 June 2019 to 06 July 2019',
                            },
                            {
                              name: 'Description',
                              value:
                                'Worked on GSM, HSPA and LTE Mobile Network (3G/4G)',
                            },
                            {
                              name: "Supervisor's Comments",
                              value: (
                                <TextArea
                                  placeholder="Please enter your comments"
                                  rows={3}
                                />
                              ),
                            },
                          ].map((res, idx) => {
                            const { name, value } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: <div>{name}</div>,
                              header_1: <div>{value}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 4 && (
                  <Fragment>
                    <div className="some-content">
                      <div className="mt-2 h6 font-weight-bold">
                        Psychometric test results
                      </div>
                      <hr />
                      {/* <div className="h6 font-weight-bold">
                    Cognitive Ability Test Results
                  </div>
                  <div>
                    <table className="table-border mb-2 w-50">
                      <thead>
                        <th className="font-weight-bold text-left">Tests</th>
                        <th className="font-weight-bold">Percentile</th>
                      </thead>
                      <tbody className="table-border">
                        <tr>
                          <td className="text-left">
                            Watson-Glaser Critical Thinking Appraisal
                          </td>
                          <td className="csc-th--block csc-th--h">82nd</td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            Advanced Numerical Reasoning Appraisal
                          </td>
                          <td className="csc-th--block csc-th--l">37th</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="h6 font-weight-bold mt-2">GBA Scores</div>
                  <div>
                    <table className="table-border mb-2 w-50">
                      <thead>
                        <th className="font-weight-bold text-left">GBA</th>
                        <th className="font-weight-bold">Scores</th>
                      </thead>
                      <tbody className="table-border">
                        <tr>
                          <td className="text-left">Performance Based</td>
                          <td className="csc-th--block csc-th--h">87</td>
                        </tr>
                        <tr>
                          <td className="text-left">Future Driven</td>
                          <td className="csc-th--block csc-th--l">35</td>
                        </tr>
                        <tr>
                          <td className="text-left">Award</td>
                          <td className="csc-th--block csc-th--m">54</td>
                        </tr>
                        <tr>
                          <td className="text-left">Average</td>
                          <td className="csc-th--block csc-th--m">59</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                      <div>
                        {/* <div className="h6 font-weight-bold">Peer comparison</div>
                    <table className="table-border w-100">
                      <thead>
                        <th>No</th>
                        <th>Name</th>
                        <th>NRIC</th>
                        <th>Gender</th>
                        <th>Test 1 Percentile</th>
                        <th>OMS Status</th>
                        <th>SGS Status</th>
                        <th>LMS Status</th>
                        <th>Test 2 Percentile</th>
                        <th>OMS Status</th>
                        <th>SGS Status</th>
                        <th>LMS Status</th>
                        <th>Overall OMS Status</th>
                        <th>Overall SGS Status</th>
                        <th>Overall LMS Status</th>
                      </thead>
                      <tbody className="table-border">
                        {rowsData4.map((res, idx) => {
                          const {
                            no,
                            name,
                            gender,
                            test1,
                            oms1,
                            sgs1,
                            lms1,
                            test2,
                            oms2,
                            sgs2,
                            lms2,
                            overallLms,
                            overallOms,
                            overallSgs,
                          } = res;
                          return (
                            <tr
                              className={
                                idx == 2 ? 'joblist__cnt--screen' : ''
                              }>
                              <td>{no}</td>
                              <td className="text-left font-weight-bold">
                                <small>{name}</small>
                              </td>
                              <td>-</td>
                              <td>{gender}</td>
                              <td>{test1}</td>
                              <td>{oms1}</td>
                              <td>{sgs1}</td>
                              <td>{lms1}</td>
                              <td>{test2}</td>
                              <td>{oms2}</td>
                              <td>{sgs2}</td>
                              <td>{lms2}</td>
                              <td>{overallOms}</td>
                              <td>{overallSgs}</td>
                              <td>{overallLms}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table> */}
                      </div>
                      <div className="mt-2 mb-2">
                        <div className="h6 font-weight-bold">
                          Personality Profile (NEO PI-R)
                        </div>
                      </div>
                      <table className="table-border w-100 table-zoom-auto">
                        <thead>
                          <th>Domain</th>
                          <th>Scale</th>
                          <th>Low Score Descriptors</th>
                          <th colspan="5">Individual Results </th>
                          <th>High Score Descriptors</th>
                        </thead>
                        <thead>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th className="csc-th--vl results-min-width">
                            <div>1</div>
                            <small>Very Low</small>
                          </th>
                          <th className="csc-th--l results-min-width">
                            <div>2</div>
                            <small>Low</small>
                          </th>
                          <th className="csc-th--m results-min-width">
                            <div>3</div>
                            <small>Average</small>
                          </th>
                          <th className="csc-th--h results-min-width">
                            <div>4</div>
                            <small>High</small>
                          </th>
                          <th className="csc-th--vh results-min-width">
                            <div>5</div>
                            <small>Very High</small>
                          </th>
                          <th></th>
                        </thead>
                        <tbody className="table-border text-left">
                          <tr>
                            <td rowspan="7" className="h6 font-weight-bold">
                              Extraversion
                            </td>
                            <td className="csc-th--na-light">Overall</td>
                            <td className="csc-th--na-light">-</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na text-center">x</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                          </tr>
                          <tr>
                            <td>Friendliness</td>
                            <td>Reserved, distant, cool</td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Friendly, warm, affectionate</td>
                          </tr>
                          <tr>
                            <td>Sociability</td>
                            <td>Introverted, prefers privacy </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Gregarious, prefers company</td>
                          </tr>
                          <tr>
                            <td>Assertive</td>
                            <td>Non-assertive, acquiescent, passive </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Assertive, forceful, dominant</td>
                          </tr>
                          <tr>
                            <td>Pace of living</td>
                            <td>Sedentary, slow-paced, inactive </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Active, energetic, fast-paced</td>
                          </tr>
                          <tr>
                            <td>Excitement Seeking</td>
                            <td>
                              Conventinal, uncomplicated needs, avoids risks{' '}
                            </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Adventurous, daring, pleasure seeking</td>
                          </tr>
                          <tr>
                            <td>Positive outlook </td>
                            <td>Serious, Sober, lacking in enthusiasm </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Cheerful, positive, exuberant</td>
                          </tr>
                          <tr>
                            <td rowspan="7" className="h6 font-weight-bold">
                              Agreeableness
                            </td>
                            <td className="csc-th--na-light">Overall</td>
                            <td className="csc-th--na-light">-</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na text-center">x</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                          </tr>
                          <tr>
                            <td>Trust</td>
                            <td>Suspicious, Cynical, distrusting</td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Accepting, forgiving, trusting</td>
                          </tr>
                          <tr>
                            <td>Straightforwardness</td>
                            <td>Cautious, guarded, indirect, manipulative </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Straightforward, frank, forthright</td>
                          </tr>
                          <tr>
                            <td>Altruism</td>
                            <td>Self-absorbed, high, slef-interest </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>
                              Considerate, helpful, aware of other's needs
                            </td>
                          </tr>
                          <tr>
                            <td>Compliance</td>
                            <td>Competitive, headstrong, contentious </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Deferent, complaint, co-operative</td>
                          </tr>
                          <tr>
                            <td>Modesty</td>
                            <td>
                              Feels superior, considered arrogant, or conceited
                              by others{' '}
                            </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Humble, modest, self-effacing</td>
                          </tr>
                          <tr>
                            <td>Compassion</td>
                            <td>Hard-headed, tough-minded, coldly logical </td>
                            <td></td>
                            <td className="csc-th--l-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Understanding, sympathetic, compassionate</td>
                          </tr>
                          <tr>
                            <td rowspan="7" className="h6 font-weight-bold">
                              Openness
                            </td>
                            <td className="csc-th--na-light">Overall</td>
                            <td className="csc-th--na-light">-</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na text-center">x</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                          </tr>
                          <tr>
                            <td>Openness to imagination</td>
                            <td>Concrete, unimaginative</td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Abstract, creative, imaginative</td>
                          </tr>
                          <tr>
                            <td>Openness to Aesthetics</td>
                            <td>Prosaic, lacks of interest in the arts </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Artistic, Stylish, aesthetic</td>
                          </tr>
                          <tr>
                            <td>Openness to Feelings</td>
                            <td>
                              Emotions are unimportant, limited in range,
                              controlled{' '}
                            </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>
                              Emotions are important, responsive, wide-ranging
                            </td>
                          </tr>
                          <tr>
                            <td>Openness to new activities</td>
                            <td>
                              Prefers status quonarrow range of activities,
                              predictable{' '}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--h-light text-center">x</td>
                            <td></td>
                            <td>
                              Versatile, wide range of interests, willing to try
                              new things
                            </td>
                          </tr>
                          <tr>
                            <td>Openness to ideas</td>
                            <td>Limited curiosity, narrowly focused </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Inquisitive, curious, open-minded</td>
                          </tr>
                          <tr>
                            <td>Openness to values </td>
                            <td>Traditional-bound, conventional, dogmatic </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>
                              Unconventional, open to a variety of behaviours
                              and view points
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7" className="h6 font-weight-bold">
                              Conscientiousness
                            </td>
                            <td className="csc-th--na-light">Overall</td>
                            <td className="csc-th--na-light">-</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na text-center">x</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                          </tr>
                          <tr>
                            <td>Sense of mastery</td>
                            <td>Unsure, inefficient, unprepared</td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Self-confident, resourceful</td>
                          </tr>
                          <tr>
                            <td>Order</td>
                            <td>Disorganized, unmethodical, careless </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Neat, tidy, orderly, well-organized</td>
                          </tr>
                          <tr>
                            <td>Dutifulness</td>
                            <td>Unreliable, undependable, irresponsible </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Dependable, principled, responsible</td>
                          </tr>
                          <tr>
                            <td>Achievement striving</td>
                            <td>Lackadaisical, complacent, aimless </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Ambitious, goal oriented, enterprising</td>
                          </tr>
                          <tr>
                            <td>Self Discipline</td>
                            <td>
                              Distractible, lack of follow-through, pro
                              crastinationg{' '}
                            </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>
                              Inner-motivated, preserving, completes what is
                              started
                            </td>
                          </tr>
                          <tr>
                            <td>Deliberation</td>
                            <td>Hasty, impatient, spntaneous </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Cautious, deliberate, thorough</td>
                          </tr>
                          <tr>
                            <td
                              rowspan="7"
                              style={{ maxWidth: '20px' }}
                              className="h6 font-weight-bold">
                              Neuroticism / Emotional Stability
                            </td>
                            <td className="csc-th--na-light">Overall</td>
                            <td className="csc-th--na-light">-</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na text-center">x</td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                            <td className="csc-th--na-light"></td>
                          </tr>
                          <tr>
                            <td>Anxiety</td>
                            <td>Calm, relaxed, carefree</td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Apprehensive, worried, tense</td>
                          </tr>
                          <tr>
                            <td>Frustration</td>
                            <td>Patient, even-tempered, easy going </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Short-tempered, irritable, intense</td>
                          </tr>
                          <tr>
                            <td>Dependency</td>
                            <td>Contented, upbeat, hopeful </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Discontented, pessimistic</td>
                          </tr>
                          <tr>
                            <td>Self-consciousness</td>
                            <td>Socially confident, selft assured </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Shy, inhibited, self conscious</td>
                          </tr>
                          <tr>
                            <td>Impulsivity</td>
                            <td>Self-denying, stotic, resists temptation </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Self-undulgent, easily tempted</td>
                          </tr>
                          <tr>
                            <td>Stress prone</td>
                            <td>
                              Self-confident, poised, clear thinking, calm under
                              fire{' '}
                            </td>
                            <td></td>
                            <td></td>
                            <td className="csc-th--m-light text-center">x</td>
                            <td></td>
                            <td></td>
                            <td>Confused, panicky, dependent</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 5 && (
                  <Fragment>
                    <div>
                      <div className="mt-2 h6 font-weight-bold">GBA</div>
                      <hr />
                      <Accordion>
                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Processing Capacity
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 8
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">Processing Capacity</div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Processing Capacity
                              </span>{' '}
                              (Cognitive Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--8 ml-4`}>
                          <div className="csc-pos--top">8</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              The ability to work with large amounts of
                              information.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Accurately retains large amounts of information in
                            short-term memory. Enables better performance in
                            many cognitive tasks like learning and decision
                            making.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div>
                            No interview questions applicable for higher
                            Cognition traits
                          </div>
                        </AccordionItem>
                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Processing Consistency
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 2
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">Processing Consistency</div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Processing Consistency
                              </span>{' '}
                              (Cognitive Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--2 ml-4`}>
                          <div className="csc-pos--top">2</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              The ability to produce consistently high
                              performance when responding to incoming
                              information.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Consistent in responding accurately and quickly when
                            reacting to information to allow for quality
                            decision making across situations.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div>
                            No interview questions applicable for higher
                            Cognition traits
                          </div>
                        </AccordionItem>
                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Learning Agility
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 8
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">Learning Agility</div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Learning Agility
                              </span>{' '}
                              (Cognitive Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--8 ml-4`}>
                          <div className="csc-pos--top">8</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              The ability to absorb and learn new information
                              quickly and re-apply new learning effectively.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Acquires and retains information and skills at a
                            pace comparable to the average person in the norm
                            group. At times finds it difficult to apply this to
                            new situations.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Tell me about a time when you were
                            required to learn and apply a new skill in a short
                            period of time. What was the situation and the
                            outcome?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 1. What were the challenges you
                            faced and what do you think you could have done
                            better?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. What strategies did you use
                            and how effective do you think these strategies
                            were?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. Think about another instance
                            where you had a totally different outcome (i.e.,
                            failed/succeeded to learn quickly) and what do you
                            think contributed to the difference?
                          </div>
                        </AccordionItem>
                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Innovation Potential
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 9
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">Innovation Potential</div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Innovation Potential
                              </span>{' '}
                              (Thinking Style Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--9 ml-4`}>
                          <div className="csc-pos--top">9</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              The tendency to come up with new, effective and/or
                              efficient solutions or approaches to problems.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Takes an experimental approach. Explores a wide
                            range of perspectives to come up with novel and
                            efficient solutions.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Tell me about a time when you were
                            engaged in a task where you could take a new,
                            innovative approach or a tried and tested approach.
                            What was the situation and what did you do?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 1. If you were to face another
                            similar situation again, would you change your
                            approach? Please explain your decision.
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. (If candidate's response to
                            previous question is No, the approach will not
                            change) In what kinds of situations would you see
                            yourself changing the approach?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. What would inform your
                            decision (e.g., what kind of information would you
                            need) on the approach to use?
                          </div>
                        </AccordionItem>
                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Rational Decision Making
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 8
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">
                            Rational Decision Making
                          </div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Rational Decision Making
                              </span>{' '}
                              (Thinking Style Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--8 ml-4`}>
                          <div className="csc-pos--top">8</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              Thoughtful, objective and critical when making
                              decisions; decisions are made based on facts and
                              not intuition.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Takes a highly objective and critical approach to
                            decision-making by carefully weighing up the pros
                            and cons of a situation.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Tell me about a time where you had to
                            make an objective decision in a situation that had a
                            potentially huge impact on others. What was the
                            situation and how did you make the final decision?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 1. What information did you
                            gather and consider to make the decision?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. What strategies did you use
                            and how effective do you think these strategies
                            were?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. Imagine a situation whereby
                            you were tasked to make an important decision and
                            you instinctively already had a decision in mind.
                            What would you do in that situation and how would
                            you go about making a final decision?
                          </div>
                        </AccordionItem>

                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Performance Under Pressure
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 4
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">
                            Performance Under Pressure
                          </div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Performance Under Pressure
                              </span>{' '}
                              (Drive Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--4 ml-4`}>
                          <div className="csc-pos--top">4</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              Produces quality outcomes when operating under
                              pressure.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Achieves an acceptable level of quality when working
                            under pressure. Can cope with challenging tasks and
                            adversity to a moderate extent, but may occasionally
                            allow performance and quality to be compromised by
                            pressure faced.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Tell me about a time when you were
                            working on a challenging task and there was a lot of
                            pressure. What was the situation and the outcome?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 1. What challenges did you face
                            and how did you attempt to overcome them?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. How difficult was it for you
                            to manage the situation and how was your performance
                            affected?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. Think about another instance
                            where you had a totally different outcome (i.e.,
                            performance was affected/unaffected by pressure) and
                            what do you think contributed to the difference?
                          </div>
                        </AccordionItem>

                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Determination
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 8
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">Determination</div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Determination
                              </span>{' '}
                              (Drive Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--8 ml-4`}>
                          <div className="csc-pos--top">8</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              Remains driven over a long period of time;
                              reflected by a high degree of
                              achievement-striving, self-motivation, persistence
                              and self-efficacy.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Remains self-motivated and persistent when achieving
                            a difficult task in both the long and short term.
                            Exerts additional effort to overcome new challenges.
                            gives up or fails
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Tell me about a time where you were
                            faced with a difficult task or project, which would
                            last over an extended period of time. What was the
                            situation and what was the outcome?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 1. What were the challenges you
                            faced and what do you think you could have done
                            better?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. How did you remain motivated?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. Think about another instance
                            where you where you had a totally different outcome
                            (i.e., managed/failed to complete a difficult
                            project) and what do you think contributed to the
                            difference?
                          </div>
                        </AccordionItem>

                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Ownership & Responsibility
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 8
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">
                            Ownership & Responsibility
                          </div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Ownership & Responsibility
                              </span>{' '}
                              (Drive Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--8 ml-4`}>
                          <div className="csc-pos--top">8</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              The tendency to perceive an outcome as dependent
                              on own actions rather than external circumstances;
                              locus of control: accountability for own action
                              and a belief that one can influence events and
                              outcomes .
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Takes full responsibility of their mistakes. Makes
                            decisions and solves problems without depending on
                            others to correct them.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Tell me about a time when you made a
                            mistake. What was the situation and what did you do
                            after that?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 1. Who else did you involve in
                            that situation?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. What do you think you could
                            have done better in that situation?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. If you were to face another
                            similar situation again, would you choose to correct
                            the mistake yourself or would you seek the help of
                            others to do so? Explain your choice.
                          </div>
                        </AccordionItem>

                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Emotional Stability
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 8
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">Emotional Stability</div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Emotional Stability
                              </span>{' '}
                              (Personal Style Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--8 ml-4`}>
                          <div className="csc-pos--top">8</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              The ability to remain calm, balanced even under
                              stressful situations; infrequently experience
                              negative emotions.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Maintains control of their emotions when working in
                            challenging situations.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Tell me about a time when you were in
                            a stressful situation. What was the situation and
                            how did you feel?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 1. Did you do anything to manage
                            your emotions, and if so, what did you do?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. On hindsight, would you have
                            done things differently? How and why?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. Think about another instance
                            where you had a totally different outcome (i.e.,
                            emotions were managed well/poorly) and what do you
                            think contributed to the difference?
                          </div>
                        </AccordionItem>

                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Self-Monitoring
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 8
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">Self-Monitoring</div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Self-Monitoring
                              </span>{' '}
                              (Interpersonal Style Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--8 ml-4`}>
                          <div className="csc-pos--top">8</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              Actively adjusts one’s behaviour in order to fit
                              in with a social group and to avoid disapproval,
                              such that behaviors are aligned to the situation.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Demonstrates high self-awareness and actively
                            adjusts one's behaviour to align with social
                            situations where there is potential for group
                            disapproval.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Think about a time when you belonged
                            to a group which was important to you and you wanted
                            to fit in, but you had a differing idea from others
                            in the group. What was the situation and what did
                            you do?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            How did you decide whether or not to stand by your
                            opinion?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. How did you gather clues about
                            the situation and how did you react towards it?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. Think about another instance
                            where you had a totally different decision (i.e.,
                            decided against/for standing by your opinion) and
                            what do you think contributed to the difference?
                          </div>
                        </AccordionItem>

                        <AccordionItem
                          className="gba-accor-content mt-2"
                          title={
                            <div className="bx--row align-items-center">
                              <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                Social Dominance
                              </div>
                              <div className="d-flex justify-content-center mb-1">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                  <div
                                    className={
                                      res === 8
                                        ? `csc-th--block csc-score-color--${res} mr-2`
                                        : 'csc-th--block css-gba-color mr-2'
                                    }>
                                    <div className="csc-pos--top">{res}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          }>
                          <div className="h6 mb-2">Social Dominance</div>
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="font-weight-bold">
                                Social Dominance
                              </span>{' '}
                              (Interpersonal Style Score):
                            </div>{' '}
                            {/* <div
                          className={`csc-th--block csc-score-color--8 ml-4`}>
                          <div className="csc-pos--top">8</div>
                        </div> */}
                          </div>
                          <hr />
                          <div>
                            <div className="font-weight-bold mb-2">
                              Definition:
                            </div>{' '}
                            <div>
                              Level of self-assuredness when interacting with
                              others and the tendency to exert control in
                              groups; seeks leadership positions and the
                              tendency to direct others.
                            </div>
                          </div>
                          <div className="font-weight-bold mt-2">
                            Behavioural Indicators:
                          </div>
                          <div className="mt-2">
                            Self-assured and assertive. Directs the conversation
                            to allow decision to be challenged and remains
                            self-assured in the face of negative feedback.
                          </div>
                          <div className="font-weight-bold mt-2 mb-2">
                            Interview Questions
                          </div>
                          <div className="css-int-ques-line-h">
                            Main Question. Tell me about a time you were part of
                            a group and a decision needed to be made, but the
                            views of the group members varied. What was the
                            situation and what did you do?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 1. Did you have your own views in
                            that situation and what were they?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 2. Did you convey your views to
                            the group and how did you do so?
                          </div>
                          <div className="mt-2 css-int-ques-line-h">
                            Interview Question 3. What was the final outcome?
                          </div>
                        </AccordionItem>
                      </Accordion>
                      {/* <div className="h5 text-black">Profile Report</div>
                  <div className="heading-color text-center align-items-center ml-0 mr-0 mt-2 bx--row">
                    <div className="bx--col-lg-4">Behavioural Indicators</div>
                    <div className="bx--col-lg-4">Sten Score</div>
                    <div className="bx--col-lg-4">Behavioural Indicators</div>
                  </div>
                  <div className="d-flex">
                    <div className="profile-report-row">
                      <div className="text-trsf-270">Cognition</div>
                    </div>
                    <div className="bx--row w-100 gba-row-border ml-0 mr-0">
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Is easily overwhelmed when working with large
                              amounts of information. Has difficulties with
                              cognitively demanding tasks
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Processing Capacity
                            </div>
                            <div className="m-2 text-black h6">
                              The ability to manage complex information in order
                              to make more accurate and efficient decisions.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Is comfortable with large amounts of information.
                              Is able to complete tasks which are cognitively
                              demanded
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          No interview questions applicable for higher Cognition
                          traits
                        </div>
                      </div>
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Quality of the employee's outpu is more variable,
                              particularly in repetitive tasks, with error or
                              oversights appearing in their work
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Processing Consistency
                            </div>
                            <div className="m-2 text-black h6">
                              The ability to maintain consistent delivery of
                              appropriate responses to incoming information.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Completes more repetitive tasks in a regular
                              manner, maintaining a very consistent standar of
                              quality.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          No interview questions applicable for higher Cognition
                          trait
                        </div>
                      </div>
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Struggles to retain information and takes a long
                              time to acquire new skills. Fails to adapt ways of
                              working based on new Knowledge
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Learning Agility
                            </div>
                            <div className="m-2 text-black h6">
                              The tendency to absorb and learn new information
                              quickly and re-apply new learning effectively.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Acquires, retains and masters new skills and
                              Knowledge rapidly. Applies this to adapt ways of
                              working according to the situation
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          Interview Question 1: Tell me about a time when you
                          were required to learn ad apply a new skill in a short
                          period of time. What was the situation and how did you
                          approach it?
                        </div>
                      </div>
                    </div>
                  </div> */}

                      {/* <div className="d-flex">
                    <div className="profile-report-row">
                      <div className="text-trsf-270">Thinking</div>
                    </div>
                    <div className="bx--row w-100 gba-row-border ml-0 mr-0">
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Sticks to tried-and-tested approaches, fails to
                              consider different perspectives and unable to
                              create novel or more efficient solutions
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Innovation Potential
                            </div>
                            <div className="m-2 text-black h6">
                              The tendancy to come up eith new innovative
                              solutions to problems
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Takes an experimental approach and explores a wide
                              range of perspectives to come up with novel and
                              efficient solutions.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          <div>
                            Interview Question 1: Tell me about a time where you
                            had to make an objective decision in a situation
                            that had a potentially negative impact on others.
                            What was the situation and what did you do?
                          </div>
                          <div className="mt-1">
                            Interview Question 2: Tell me about a time when you
                            took a tried and tested approach to solving a
                            problem. What was the situation and what did you do?
                          </div>
                        </div>
                      </div>
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Makes impulsive decision based on intuition, fails
                              to take an objective approach to dicision making
                              by considering logic and reason
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Rational Decision Making
                            </div>
                            <div className="m-2 text-black h6">
                              The tendancy to be thoughtful, objective and
                              critical when making decisions.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Takes a highly objective and critical approach to
                              decision making by carefully wighing up the pros
                              and coris of a situation
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          Interview Question 1: Tell me about a time where you
                          had to nake an objective decision in a situation that
                          had a potentially negative impact on others. What was
                          the situation and what did you do?
                        </div>
                      </div>
                    </div>
                  </div> */}

                      {/* <div className="d-flex">
                    <div className="profile-report-row">
                      <div className="text-trsf-270">Divine</div>
                    </div>
                    <div className="bx--row w-100 gba-row-border ml-0 mr-0">
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Becomes anxious when faced with challenging or
                              adverse situations, makes error or allows quality
                              to be compromised
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Performance Under Pressure
                            </div>
                            <div className="m-2 text-black h6">
                              The tendancy to remain accurate when operation
                              under pressure.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Maintains optimal performance and quality under
                              pressure, copes with challenging tasks or moments
                              of adversity with ease.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          Interview Question 1: Tell me about a time when you
                          were suddenly faced with a heavier workload than
                          usual. What was the situation and what did you do?
                        </div>
                      </div>
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Lacks the motivation and persistence to complete
                              difficult tasks or overcome difficult challenges
                              gives up or fails
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Determination
                            </div>
                            <div className="m-2 text-black h6">
                              The tendency to remain driven over a long period
                              of time.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Remains a self-motivated, persistent and
                              achievement-striving over long periods of tme,
                              exerts additional effort to overcome difficult
                              challenges.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          Interview Question 1: Tell mw about a time where you
                          needed to persist over a long period of time to
                          complete a difficult task or project. WHat was the
                          situation and what did you do?
                        </div>
                      </div>
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Allows others to take responsibility for their
                              mistakes depends fully on others to correct the
                              mistakes.
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Ownership & Responsibility
                            </div>
                            <div className="m-2 text-black h6">
                              The tendency to perceive aan outcome as dependent
                              on own actions rather than external circumstances.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Takes full responsibility of their mistakes, makes
                              decisions and solves problems without depending on
                              others to correct them.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          <div>
                            Interview Question 1: Tell me about a time when you
                            took Ownership to correct a mistake you made. What
                            was the situation and what did you do?
                          </div>
                          <div className="mt-1">
                            Interview Question 2: Tell me about a time when you
                            needed to let others take responsibility for a
                            situation, over which you had limited control. What
                            was the situation and what did you do?
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                      {/* <div className="d-flex">
                    <div className="profile-report-row">
                      <div className="text-trsf-270">Personal</div>
                    </div>
                    <div className="bx--row w-100 gba-row-border ml-0 mr-0">
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Emotionally volatile when working in challenging
                              or stressful situations
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Emotional Stability
                            </div>
                            <div className="m-2 text-black h6">
                              The tendancy to remain calm, balanced and to
                              infrequently experience negative emotions.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Maintains high performance and control of their
                              emotions when working in challenging situations
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          Interview Question 1: Tell me about a time when you
                          had to perform under stressful conditions. What was
                          the situation and what did you do?
                        </div>
                      </div>
                    </div>
                  </div> */}

                      {/* <div className="d-flex">
                    <div className="profile-report-row">
                      <div className="text-trsf-270">Interpersonal</div>
                    </div>
                    <div className="bx--row w-100 gba-row-border ml-0 mr-0">
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Fails to adjust in a way that is aligned to the
                              environment or situation, presents as disruptive
                              or uncompromising
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Self-Monitoring
                            </div>
                            <div className="m-2 text-black h6">
                              The tendancy to actively adjust one's behaviour in
                              order o fit in with a social group and to avoid
                              disapproval from others
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Demonstrates high self-awareness and actively
                              adjusts one's behaviour to align with social
                              situations where there is potential for group
                              disapproval
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          <div>
                            Interview Question 1: Tell me about a time when you
                            needed to change your behaviour in order to avoid
                            group disapproval. What was the situation and what
                            did you do?
                          </div>
                          <div className="mt-1">
                            Interview Question 2: Tell me about a time where it
                            was important for you to stand by an opinion or idea
                            that differed from others on the group. What was the
                            situation and what did you do?
                          </div>
                        </div>
                      </div>
                      <div className="bx--col-lg-12 ml-0 bx--row dashed-border">
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className="">
                            <div className="m-5 h6 text-black ">
                              Lacks the confidence to share their opinion with
                              the group
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0 border-left-right">
                          <div className="">
                            <div className="bg-color-gba-score">
                              Social Dominance
                            </div>
                            <div className="m-2 text-black h6">
                              The tendancy to feel self-assured when interacting
                              with others, exerting control in groups.
                            </div>
                            <div className="d-flex justify-content-center m-3">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((res) => (
                                <div
                                  className={`csc-th--block csc-score-color--${res} mr-2`}>
                                  <div className="csc-pos--top">{res}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="bx--col-lg-4 pl-0 pr-0">
                          <div className=" pb-1">
                            <div className="m-5 h6 text-black ">
                              Self-assured and assertive directs the
                              conversation to allow decision to be challenging
                              and remians self-assured in the face of negative
                              feedback
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="m-3 h6 text-black">
                          <div>
                            Interview Question 1: Tell me about a time when you
                            challenged a group of decisions and provided an
                            alternative idea. What was the situation and what
                            did you do?
                          </div>
                          <div>
                            Interview Question 1: Tell me about a time you were
                            part of a group and a decision needted to be made,
                            but the views of the group members varies. What was
                            the situation and what did you do?
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                      <div className="h6 f-w-b mt-4 mb-4">GBA Scores</div>
                      <Accordion>
                        <AccordionItem
                          className="content-padding mt-2"
                          title={
                            <div className="h6 font-weight-bold">
                              Personality Profile (Stens)
                            </div>
                          }
                          open>
                          <div className="nivo-graph-height">
                            <NivoBarChart
                              heading="Cognitive"
                              data={[
                                {
                                  key: 'Learning Agility',
                                  'Learning Agility': 5,
                                  definition:
                                    'The ability to absorb and learn new information quickly and re-apply new learning effectively.',
                                },
                                {
                                  key: 'Processing Capacity',
                                  'Processing Capacity': 4,
                                  definition:
                                    'The ability to work with large amounts of information.',
                                },
                                {
                                  key: 'Processing Consistency',
                                  'Processing Consistency': 10,
                                  definition:
                                    'The ability to produce  consistently high performance when responding to incoming information.',
                                },
                                {
                                  key: 'Processing Speed',
                                  'Processing Speed': 1,
                                  definition:
                                    'The tendency to complete complex tasks accurately in less time.',
                                },

                                {
                                  key: 'Executive Function',
                                  'Executive Function': 6,
                                  definition:
                                    'The ability to shift accurately and rapidly between different objectives in complex tasks. Includes the ability to  manage complex information with ease.',
                                },
                                {
                                  key: 'Concentration',
                                  Concentration: 5,
                                  definition:
                                    'The ability to remain focused  and maintain optimal performance for a prolonged period of time.',
                                },
                              ].reverse()}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              keys={[
                                'Processing Capacity',
                                'Learning Agility',
                                'Processing Consistency',
                                'Processing Speed',
                                'Concentration',
                                'Executive Function',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              enableLabel
                              groupMode="stacked"
                              userColors="darkblue"
                              innerPadding={0}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                          <div className="nivo-graph-height mt-2">
                            <NivoBarChart
                              heading="Drive"
                              data={[
                                {
                                  key: 'Performance Under Pressure',
                                  'Performance Under Pressure': 3,
                                  definition:
                                    'Produces quality outcomes  when operating under pressure.',
                                },
                                {
                                  key: 'Ownership and Responsibility',
                                  'Ownership and Responsibility': 5,
                                  definition:
                                    'The tendency to perceive an outcome as dependent on own actions rather than external circumstances; locus of control: accountability for own action and a belief that one can influence events and outcomes .',
                                },
                                {
                                  key: 'Determination',
                                  Determination: 2,
                                  definition:
                                    'Remains driven over a long period of time; reflected by a high degree of achievement-striving, self-motivation, persistence and self-efficacy.',
                                },

                                {
                                  key: 'Sensitivity to loss',
                                  'Sensitivity to loss': 9,
                                  definition:
                                    'The tendency to be fearful or anxious of negative outcomes or reinforcement before taking action.',
                                },
                                {
                                  key: 'Resilience',
                                  Resilience: 6,
                                  definition:
                                    'The capacity to recover quickly from setbacks.',
                                },
                                {
                                  key: 'Sensitivity to reward',
                                  'Sensitivity to reward': 10,
                                  definition: 'Motivated by possible rewards.',
                                },
                                {
                                  key: 'Self-Descipline',
                                  'Self-Descipline': 2,
                                  definition:
                                    "Organised and disciplined in one's work; characterised by a great degree of consistency and consideration in approaching situations, and punctuality in meeting deadlines.",
                                },
                                {
                                  key: 'Proactivity',
                                  Proactivity: 6,
                                  definition:
                                    'The tendency to complete tasks without delay and with a consistent approach; demonstrates a greater degree of action-orientation as opposed to an inclination to procrastinate.',
                                },
                              ].reverse()}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              keys={[
                                'Performance Under Pressure',
                                'Proactivity',
                                'Self-Descipline',
                                'Sensitivity to reward',
                                'Sensitivity to loss',
                                'Resilience',
                                'Determination',
                                'Ownership and Responsibility',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              enableLabel
                              groupMode="stacked"
                              userColors="darkblue"
                              innerPadding={0}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                          <div className="thinking-style mt-2">
                            <NivoBarChart
                              heading="Thinking Style"
                              data={[
                                {
                                  key: 'Innovation Potential',
                                  'Innovation Potential': 5,
                                  definition:
                                    'The tendency to come up with new, effective and/or efficient solutions or approaches to problems.',
                                },
                                {
                                  key: 'Rational Decision Making',
                                  'Rational Decision Making': 4,
                                  definition:
                                    'Thoughtful, objective and critical when making decisions; decisions are made based on facts and not intuition.',
                                },
                                {
                                  key: 'Managing Uncertainity',
                                  'Managing Uncertainity': 6,
                                  definition:
                                    'The ability to remain productive in ambiguous situations; an ability to consider multiple perspectives, cope with conflicting directions and remain productive.',
                                },

                                {
                                  key: 'Future Orientation',
                                  'Future Orientation': 10,
                                  definition:
                                    'The extent to which one prioritises long-term benefits over short-term rewards in decision-making.',
                                },
                                {
                                  key: 'Need For Structure',
                                  'Need For Structure': 8,
                                  definition:
                                    'Preference for safety in ambiguious situations; tendency to be cautious and choose safer options in uncertain situations. ',
                                },
                                {
                                  key: 'Risk Propensity',
                                  'Risk Propensity': 10,
                                  definition:
                                    'Level of comfort in making decisions that have a risk component.',
                                },
                                {
                                  key: 'Creativity',
                                  Creativity: 6,
                                  definition:
                                    'Imaginative and being able to come up with new ideas, alternatives or possibilities; characterised by a vivid imagination, strong artistic interests, and an intense experience of a range of emotions.',
                                },
                                {
                                  key: 'Curiosity',
                                  Curiosity: 6,
                                  definition:
                                    'A tendency to seek intellectual stimulation and exploration of alternative and multiple perspectives; tolerant of differing points of view and preparedness to challenge assumptions.',
                                },
                                {
                                  key: 'Deliberation',
                                  Curiosity: 3,
                                  definition:
                                    'Carefully considers actions before responding which may require the management of multiple, complex ideas.',
                                },
                                {
                                  key: 'Novelty Seeking',
                                  'Novelty Seeking': 7,
                                  definition:
                                    'Strong drive to explore new and exciting activities; characterised by the tendency to become bored more quickly and will push the limits of a situation by taking risks.',
                                },
                                {
                                  key: 'Optimism',
                                  Optimism: 7,
                                  definition:
                                    'Views situations and choices with a positive outlook.',
                                },
                              ].reverse()}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              keys={[
                                'Innovation Potential',
                                'Rational Decision Making',
                                'Managing Uncertainity',
                                'Novelty Seeking',
                                'Creativity',
                                'Future Orientation',
                                'Curiosity',
                                'Need For Structure',
                                'Optimism',
                                'Deliberation',
                                'Risk Propensity',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              enableLabel
                              groupMode="stacked"
                              userColors="darkblue"
                              innerPadding={0}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                          <div className="nivo-graph-height mt-2">
                            <NivoBarChart
                              heading="Interpersonal"
                              data={[
                                {
                                  key: 'Self Monitoring',
                                  'Self Monitoring': 4,
                                  definition:
                                    'Actively adjusts one’s behaviour in order to fit in with a social group and to avoid disapproval, such that behaviors are aligned to the situation.',
                                },
                                {
                                  key: 'Social Dominance',
                                  'Social Dominance': 9,
                                  definition:
                                    'Level of self-assuredness when interacting with others and the tendency to exert control in groups; seeks leadership positions and the tendency to direct others.',
                                },

                                {
                                  key: 'Sociability',
                                  Sociability: 2,
                                  definition:
                                    'Feels energised and thrive in the presence of others; interested in social interaction and susceptible to boredom when lacking social stimulation.',
                                },
                                {
                                  key: 'Politeness',
                                  Politeness: 2,
                                  definition:
                                    'Acts with respect towards others and avoids conflict; preference for social harmony and respect for authority.',
                                },
                                {
                                  key: 'Altruism',
                                  Altruism: 6,
                                  definition:
                                    'Shows concern for others, reflected by kind gestures, active listening and interpersonal warmth.',
                                },
                                {
                                  key: 'Authenticity',
                                  Authenticity: 3,
                                  definition:
                                    'Conveys true internal values and beliefs consistently as opposed to actively managing impressions for self-beneficial goals.',
                                },
                                {
                                  key: 'Self-Belief',
                                  'Self-Belief': 5,
                                  definition:
                                    'Feels assured about own capabilities and potential; self-confidence.',
                                },
                              ].reverse()}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              keys={[
                                'Self Monitoring',
                                'Social Dominance',
                                'Altruism',
                                'Authenticity',
                                'Politeness',
                                'Self-Belief',
                                'Sociability',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              userColors="darkblue"
                              groupMode="stacked"
                              innerPadding={0}
                              enableLabel
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                          <div className="personal-graph-height mt-2">
                            <NivoBarChart
                              heading="Personal Style"
                              data={[
                                {
                                  key: 'Emotion Stability',
                                  'Emotion Stability': 10,
                                  definition:
                                    'The ability to remain calm, balanced even under stressful situations; infrequently experience negative emotions.',
                                },
                                {
                                  key: 'Emotion Recognition',
                                  'Emotion Recognition': 3,
                                  definition:
                                    'The ability to accurately identify and interpret facial expressions and emotions of others.',
                                },
                              ].reverse()}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              keys={[
                                'Emotion Stability',
                                'Emotion Recognition',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0}
                              userColors="darkblue"
                              enableLabel
                              innerPadding={0}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                        </AccordionItem>
                        <AccordionItem
                          className="content-padding mt-2"
                          title={
                            <div className="h6 font-weight-bold">
                              Personality Profile (Z-Score)
                            </div>
                          }>
                          <div className="nivo-graph-height">
                            <NivoBarChart
                              heading="Cognitive"
                              data={[
                                {
                                  key: 'Learning Agility',
                                  'Learning Agility': -3.5,
                                  definition:
                                    'The ability to absorb and learn new information quickly and re-apply new learning effectively.',
                                },
                                {
                                  key: 'Processing Capacity',
                                  'Processing Capacity': 1,
                                  definition:
                                    'The ability to work with large amounts of information.',
                                },
                                {
                                  key: 'Processing Consistency',
                                  'Processing Consistency': 3.5,
                                  definition:
                                    'The ability to produce  consistently high performance when responding to incoming information.',
                                },
                                {
                                  key: 'Processing Speed',
                                  'Processing Speed': 2,
                                  definition:
                                    'The tendency to complete complex tasks accurately in less time.',
                                },

                                {
                                  key: 'Executive Function',
                                  'Executive Function': 3,
                                  definition:
                                    'The ability to shift accurately and rapidly between different objectives in complex tasks. Includes the ability to  manage complex information with ease.',
                                },
                                {
                                  key: 'Concentration',
                                  Concentration: -2,
                                  definition:
                                    'The ability to remain focused  and maintain optimal performance for a prolonged period of time.',
                                },
                              ].reverse()}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              keys={[
                                'Executive Function',
                                'Concentration',
                                'Processing Speed',
                                'Processing Consistency',
                                'Learning Agility',
                                'Processing Capacity',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              enableLabel
                              groupMode="stacked"
                              userColors="darkblue"
                              innerPadding={0}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                          <div className="nivo-graph-height mt-2">
                            <NivoBarChart
                              heading="Drive"
                              data={[
                                {
                                  key: 'Performance Under Pressure',
                                  'Performance Under Pressure': 3,
                                  definition:
                                    'Produces quality outcomes  when operating under pressure.',
                                },
                                {
                                  key: 'Ownership and Responsibility',
                                  'Ownership and Responsibility': -1,
                                  definition:
                                    'The tendency to perceive an outcome as dependent on own actions rather than external circumstances; locus of control: accountability for own action and a belief that one can influence events and outcomes .',
                                },
                                {
                                  key: 'Determination',
                                  Determination: 2,
                                  definition:
                                    'Remains driven over a long period of time; reflected by a high degree of achievement-striving, self-motivation, persistence and self-efficacy.',
                                },

                                {
                                  key: 'Sensitivity to loss',
                                  'Sensitivity to loss': -2,
                                  definition:
                                    'The tendency to be fearful or anxious of negative outcomes or reinforcement before taking action.',
                                },
                                {
                                  key: 'Resilience',
                                  Resilience: -3.5,
                                  definition:
                                    'The capacity to recover quickly from setbacks.',
                                },
                                {
                                  key: 'Sensitivity to reward',
                                  'Sensitivity to reward': 1,
                                  definition: 'Motivated by possible rewards.',
                                },
                                {
                                  key: 'Self Descipline',
                                  'Self Descipline': 3.5,
                                  definition:
                                    "Organised and disciplined in one's work; characterised by a great degree of consistency and consideration in approaching situations, and punctuality in meeting deadlines.",
                                },
                                {
                                  key: 'Proactivity',
                                  Proactivity: 2,
                                  definition:
                                    'The tendency to complete tasks without delay and with a consistent approach; demonstrates a greater degree of action-orientation as opposed to an inclination to procrastinate.',
                                },
                              ].reverse()}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              keys={[
                                'Ownership and Responsibility',
                                'Determination',
                                'Resilience',
                                'Sensitivity to loss',
                                'Sensitivity to reward',
                                'Self Descipline',
                                'Proactivity',
                                'Performance Under Pressure',
                              ]}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              enableLabel
                              groupMode="stacked"
                              userColors="darkblue"
                              innerPadding={0}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>

                          <div className="thinking-style mt-2">
                            <NivoBarChart
                              heading="Thinking Style"
                              data={[
                                {
                                  key: 'Innovation Potential',
                                  'Innovation Potential': 3.5,
                                  definition:
                                    'The tendency to come up with new, effective and/or efficient solutions or approaches to problems.',
                                },
                                {
                                  key: 'Rational Decision Making',
                                  'Rational Decision Making': 2,
                                  definition:
                                    'Thoughtful, objective and critical when making decisions; decisions are made based on facts and not intuition.',
                                },
                                {
                                  key: 'Managing Uncertainity',
                                  'Managing Uncertainity': -2,
                                  definition:
                                    'The ability to remain productive in ambiguous situations; an ability to consider multiple perspectives, cope with conflicting directions and remain productive.',
                                },

                                {
                                  key: 'Deliberation',
                                  Deliberation: 3,
                                  definition:
                                    'Carefully considers actions before responding which may require the management of multiple, complex ideas.',
                                },
                                {
                                  key: 'Future Orientation',
                                  'Future Orientation': -3,
                                  definition:
                                    'The extent to which one prioritises long-term benefits over short-term rewards in decision-making.',
                                },
                                {
                                  key: 'Need For Structure',
                                  'Need For Structure': 2,
                                  definition:
                                    'Preference for safety in ambiguious situations; tendency to be cautious and choose safer options in uncertain situations. ',
                                },
                                {
                                  key: 'Risk Propensity',
                                  'Risk Propensity': 1,
                                  definition:
                                    'Level of comfort in making decisions that have a risk component.',
                                },
                                {
                                  key: 'Creativity',
                                  Creativity: -3.5,
                                  definition:
                                    'Imaginative and being able to come up with new ideas, alternatives or possibilities; characterised by a vivid imagination, strong artistic interests, and an intense experience of a range of emotions.',
                                },
                                {
                                  key: 'Curiosity',
                                  Curiosity: 1,
                                  definition:
                                    'A tendency to seek intellectual stimulation and exploration of alternative and multiple perspectives; tolerant of differing points of view and preparedness to challenge assumptions.',
                                },
                                {
                                  key: 'Novelty Seeking',
                                  'Novelty Seeking': 3,
                                  definition:
                                    'Strong drive to explore new and exciting activities; characterised by the tendency to become bored more quickly and will push the limits of a situation by taking risks.',
                                },
                                {
                                  key: 'Optimism',
                                  Optimism: -1,
                                  definition:
                                    'Views situations and choices with a positive outlook.',
                                },
                              ].reverse()}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              keys={[
                                'Innovation Potential',
                                'Rational Decision Making',
                                'Managing Uncertainity',
                                'Novelty Seeking',
                                'Creativity',
                                'Future Orientation',
                                'Curiosity',
                                'Need For Structure',
                                'Optimism',
                                'Deliberation',
                                'Risk Propensity',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              enableLabel
                              groupMode="stacked"
                              userColors="darkblue"
                              innerPadding={0}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                          <div className="nivo-graph-height mt-2">
                            <NivoBarChart
                              heading="Interpersonal"
                              data={[
                                {
                                  key: 'Self Monitoring',
                                  'Self Monitoring': -3,
                                  definition:
                                    'Actively adjusts one’s behaviour in order to fit in with a social group and to avoid disapproval, such that behaviors are aligned to the situation.',
                                },
                                {
                                  key: 'Social Dominance',
                                  'Social Dominance': -3.5,
                                  definition:
                                    'Level of self-assuredness when interacting with others and the tendency to exert control in groups; seeks leadership positions and the tendency to direct others.',
                                },

                                {
                                  key: 'Sociability',
                                  Sociability: -2,
                                  definition:
                                    'Feels energised and thrive in the presence of others; interested in social interaction and susceptible to boredom when lacking social stimulation.',
                                },
                                {
                                  key: 'Politeness',
                                  Politeness: 2,
                                  definition:
                                    'Acts with respect towards others and avoids conflict; preference for social harmony and respect for authority.',
                                },
                                {
                                  key: 'Altruism',
                                  Altruism: 2,
                                  definition:
                                    'Shows concern for others, reflected by kind gestures, active listening and interpersonal warmth.',
                                },
                                {
                                  key: 'Authenticity',
                                  Authenticity: 3.5,
                                  definition:
                                    'Conveys true internal values and beliefs consistently as opposed to actively managing impressions for self-beneficial goals.',
                                },
                                {
                                  key: 'Self-Belief',
                                  'Self-Belief': -2,
                                  definition:
                                    'Feels assured about own capabilities and potential; self-confidence.',
                                },
                              ].reverse()}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              keys={[
                                'Self Monitoring',
                                'Social Dominance',
                                'Altruism',
                                'Authenticity',
                                'Politeness',
                                'Self-Belief',
                                'Sociability',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              enableLabel
                              groupMode="stacked"
                              userColors="darkblue"
                              innerPadding={0}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                          <div className="personal-graph-height mt-2">
                            <NivoBarChart
                              heading="Personal Style"
                              data={[
                                {
                                  key: 'Emotion Stability',
                                  'Emotion Stability': -3.5,
                                  definition:
                                    'The ability to remain calm, balanced even under stressful situations; infrequently experience negative emotions.',
                                },
                                {
                                  key: 'Emotion Recognition',
                                  'Emotion Recognition': 3.5,
                                  definition:
                                    'The ability to accurately identify and interpret facial expressions and emotions of others.',
                                },
                              ].reverse()}
                              margin={{
                                top: 10,
                                right: 100,
                                bottom: 30,
                                left: 300,
                              }}
                              showDefModal
                              handleBarOnClick={this.handleBarOnClick}
                              keys={[
                                'Emotion Stability',
                                'Emotion Recognition',
                              ]}
                              legendDirection="bottom-left"
                              enableGridY={false}
                              axisTop={null}
                              padding={0.3}
                              enableLabel
                              groupMode="stacked"
                              userColors="darkblue"
                              innerPadding={8}
                              showLegend={false}
                              axisRight={null}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32,
                              }}
                              layout="horizontal"
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -40,
                              }}
                            />
                          </div>
                        </AccordionItem>
                        {/* <AccordionItem
                          className="content-padding mt-2"
                          title={
                            <div className="h6 font-weight-bold">
                              RIASEC Profile
                            </div>
                          }>
                          <div className="mt-2">
                            Enterprising(E) Realistic(R) Conventional(C)
                          </div>
                          <div className="text-center">
                            <RadarChart
                              cx={300}
                              cy={250}
                              className="w-100"
                              outerRadius={150}
                              width={600}
                              height={500}
                              data={this.state.riasecData}>
                              <PolarGrid />
                              <PolarAngleAxis dataKey="subject" />
                              <PolarRadiusAxis />
                              <Radar
                                name="Mike"
                                dataKey="A"
                                stroke="#8884d8"
                                fill="#8884d8"
                                fillOpacity={0.6}
                              />
                            </RadarChart>
                          </div>
                          <div className="mt-2 mb-2 font-weight-bold">
                            Description
                          </div>
                          <div className="mt-2">
                            <h5>Realistic:</h5>
                            <div className="mt-2 mb-2 desc-font">
                              Preference for a career that is more practical and
                              hands on. Results indicate a preference to work
                              with physical things as opposed to abstract
                              concepts and ideas. Likely to favour working on
                              well organised and logical tasks, and would
                              dislike working in uncertain and inconsistent work
                              environments.
                            </div>
                          </div>
                          <div className="mt-2">
                            <h5>Investigative:</h5>
                            <div className="mt-2 mb-2 desc-font">
                              Preference for a career that will allow
                              exploration of one’s intellectual curiosity.
                              Enjoys roles that allow one to analyse and
                              evaluate different things and explore new
                              concepts. More likely to prefer to work with
                              numbers instead of people and will intrinsically
                              enjoy exploring and discovering new things.
                            </div>
                          </div>
                          <div className="mt-2">
                            <h5>Artistic:</h5>
                            <div className="desc-font mt-2 mb-2">
                              Preference for more unconventional and creative
                              roles. Favours a less structured and more flexible
                              work environment where there is more freedom to
                              express oneself and use one’s imagination. May
                              prefer working with abstract ideas and concepts,
                              and are less likely to enjoy working with facts
                              and figures.
                            </div>
                          </div>
                          <div className="mt-2">
                            <h5>Social:</h5>
                            <div className="mt-2 mb-2 desc-font">
                              Preference for roles that will allow you to work
                              with others in a friendly environment. Likely to
                              enjoy communicating with others, and enjoy helping
                              people. Less suited to work in roles that require
                              a lot of isolation or roles that will require you
                              to work with ideas and abstract concepts instead
                              of other people.
                            </div>
                          </div>
                          <div className="mt-2">
                            <h5>Enterprising:</h5>
                            <div className="mt-2 mb-2 desc-font">
                              Preference for working in fast-paced andexciting
                              environments. Likely to enjoy taking charge and
                              persuading others. Preference for roles that
                              challenge one to keep improving with set goals and
                              targets. Tends to be more business orientated and
                              enjoy managing projects and business ventures.
                            </div>
                          </div>
                          <div className="mt-2">
                            <h5>Conventional:</h5>
                            <div className="mt-2 mb-2 desc-font">
                              Preference for work environments that arewell
                              structured and organised. May prefer roles where
                              clear instructions are given, and work follows a
                              well-defined and logical process. May feel more
                              uncomfortable with uncertain roles where you are
                              required to be more flexible. Likely to favour
                              roles that involves working with data/numbers
                              rather than abstract concepts.
                            </div>
                          </div>
                        </AccordionItem> */}
                      </Accordion>
                      <div className="h6 f-w-b mt-4 mb-4">
                        GBA Traits Reference
                      </div>
                      <Accordion>
                        {[
                          'Learning Agility',
                          'Processing Capacity',
                          'Processing Consistency',
                          'Processing Speed',
                          'Executive Function',
                          'Concentration',
                          'Performance under Pressure',
                          'Ownership and Responsibility',
                          'Determination',
                          'Sensitivity To Loss',
                          'Resilience',
                          'Sensitivity To Reward',
                          'Self-discipline',
                          'Proactivity',
                          'Innovation Potential',
                          'Rational Decision-Making Style',
                          'Managing Uncertainty',
                          'Deliberation',
                          'Future Orientation',
                          'Need For Structure',
                          'Risk propensity',
                          'Creativity',
                          'Curiosity',
                          'Novelty Seeking',
                          'Optimism',
                          'Self-monitoring',
                          'Social dominance',
                          'Sociability',
                          'Politeness',
                          'Altruism',
                          'Authenticity',
                          'Self-Belief',
                          'Emotional Stability',
                          'Emotion Recognition',
                        ].map((res) => {
                          return (
                            <AccordionItem
                              className="gba-accor-content mt-2"
                              title={
                                <div className="bx--row align-items-center">
                                  <div className="h6 font-weight-bold mb-1 bx--col-lg-4 bx--col-md-3">
                                    {res}
                                  </div>
                                </div>
                              }>
                              <div className="h6 mb-2">{res}</div>
                              <hr />
                              <div>
                                <div className="font-weight-bold mb-2">
                                  Definition:
                                </div>{' '}
                                <div>{handleGetTraitDefinition(res)}</div>
                              </div>
                              <div className="mt-2 mb-2">
                                <div className="font-weight-bold mb-2">
                                  Behavioural Indicators:
                                </div>{' '}
                                <div>
                                  {handleGetBehavioralIndicators(res, false)}
                                </div>
                              </div>
                              <div className="font-weight-bold mt-2 mb-2">
                                Interview Questions
                              </div>
                              {handleGetInterviewQuestions(res)}
                            </AccordionItem>
                          );
                        })}
                      </Accordion>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 6 && (
                  <Fragment>
                    <div className="some-content">
                      <div className="mt-2 h6 font-weight-bold">
                        Video Interviews
                      </div>
                      <hr />
                      {/* <div className="mt-2">
                    <div
                      style={{ backgroundColor: '#fff3d5' }}
                      className="d-flex align-items-center justify-content-between pl-2 pr-2">
                      <div className="d-flex">
                        <div>
                          <Video32 />
                        </div>{' '}
                        <div
                          title="Question category"
                          className="bx--tag small text-uppercase bx--tag--blue d-none">
                          Leadership
                        </div>{' '}
                        <div className="mt-2">How would you manage a team?</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="mr-1 text-secondary font-weight-bold">
                          Average rating:
                        </div>
                        <div title={3}>
                          <StarRatingComponent
                            starCount={5}
                            value={3}
                            starColor="#ffb400"
                            emptyStarColor="#ffb400"
                            renderStarIcon={(index, value) => {
                              return (
                                <span>
                                  <i
                                    className={
                                      index <= value
                                        ? 'fas fa-star'
                                        : 'far fa-star'
                                    }
                                  />
                                </span>
                              );
                            }}
                            editing={false}
                            renderStarIconHalf={() => {
                              return (
                                <span>
                                  <span
                                    style={{
                                      position: 'absolute',
                                    }}>
                                    <i className="far fa-star" />
                                  </span>
                                  <span>
                                    <i className="fas fa-star-half" />
                                  </span>
                                </span>
                              );
                            }}
                            onStarClick={() => {}}
                          />
                        </div>
                        <div className="ml-1">(3)</div>
                      </div>
                    </div>
                  </div> */}
                      {/* <div className="bx--row mb-2">
                    <div className="bx--col-lg-4 text-left h6">
                      Q1: How would you manage a team?
                    </div>
                    <div className="bx--col-lg-4 text-left h6">
                      Q2: Tell me about yourself
                    </div>
                    <div className="bx--col-lg-4 text-left h6">
                      Q3: How would you handle pressure?
                    </div>
                  </div> */}
                      <div className="d-xl-flex justify-content-between mt-2">
                        <div className="mt-2">
                          <div className="text-left h6">
                            Q1: We would like to get to know you as a person.
                            Tell us about yourself.
                          </div>
                          <video controls key={1} width="400px">
                            <source
                              src="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.webm?sp=r&st=2020-08-21T05:38:37Z&se=2021-10-01T13:38:37Z&spr=https&sv=2019-12-12&sr=b&sig=XeFd8I%2BBC6aFg5pCHsOMWJnUO%2BH3n60rA9iyVmTdP7A%3D"
                              type="video/webm"></source>
                            <source
                              src="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.mp4?sp=r&st=2020-08-21T05:40:43Z&se=2021-10-14T13:40:43Z&spr=https&sv=2019-12-12&sr=b&sig=d3bCom8OkWxYYhrvOqoOepx6PxhXtEOZBJvg70nXFW8%3D"
                              type="video/mp4"></source>
                            <p>
                              Your browser doesn't support HTML5 video. Here is
                              a
                              <a href="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.webm?sp=r&st=2020-08-21T05:38:37Z&se=2021-10-01T13:38:37Z&spr=https&sv=2019-12-12&sr=b&sig=XeFd8I%2BBC6aFg5pCHsOMWJnUO%2BH3n60rA9iyVmTdP7A%3D">
                                link to the video
                              </a>
                              instead.
                            </p>
                          </video>
                        </div>
                        <div className="mt-2 ml-lg-2 mr-lg-2">
                          <div className="text-left h6">
                            Q2: Why are you applying for a PSC Scholarship?
                          </div>
                          <div className="mt-lg-4 pt-lg-1">
                            <video controls key={2} width="400px">
                              <source
                                src="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.webm?sp=r&st=2020-08-21T05:38:37Z&se=2021-10-01T13:38:37Z&spr=https&sv=2019-12-12&sr=b&sig=XeFd8I%2BBC6aFg5pCHsOMWJnUO%2BH3n60rA9iyVmTdP7A%3D"
                                type="video/webm"></source>
                              <source
                                src="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.mp4?sp=r&st=2020-08-21T05:40:43Z&se=2021-10-14T13:40:43Z&spr=https&sv=2019-12-12&sr=b&sig=d3bCom8OkWxYYhrvOqoOepx6PxhXtEOZBJvg70nXFW8%3D"
                                type="video/mp4"></source>
                              <p>
                                Your browser doesn't support HTML5 video. Here
                                is a
                                <a href="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.webm?sp=r&st=2020-08-21T05:38:37Z&se=2021-10-01T13:38:37Z&spr=https&sv=2019-12-12&sr=b&sig=XeFd8I%2BBC6aFg5pCHsOMWJnUO%2BH3n60rA9iyVmTdP7A%3D">
                                  link to the video
                                </a>
                                instead.
                              </p>
                            </video>
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className=" text-left h6">
                            Q3: What are the career options you are considering
                            or may consider outside of the Public Sector?
                          </div>
                          <video controls key={3} width="400px">
                            <source
                              src="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.webm?sp=r&st=2020-08-21T05:38:37Z&se=2021-10-01T13:38:37Z&spr=https&sv=2019-12-12&sr=b&sig=XeFd8I%2BBC6aFg5pCHsOMWJnUO%2BH3n60rA9iyVmTdP7A%3D"
                              type="video/webm"></source>
                            <source
                              src="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.mp4?sp=r&st=2020-08-21T05:40:43Z&se=2021-10-14T13:40:43Z&spr=https&sv=2019-12-12&sr=b&sig=d3bCom8OkWxYYhrvOqoOepx6PxhXtEOZBJvg70nXFW8%3D"
                              type="video/mp4"></source>
                            <p>
                              Your browser doesn't support HTML5 video. Here is
                              a
                              <a href="https://viwaprodassets.blob.core.windows.net/97a95c96-c0e6-4f3d-96eb-5cbf12ea462f/in.webm?sp=r&st=2020-08-21T05:38:37Z&se=2021-10-01T13:38:37Z&spr=https&sv=2019-12-12&sr=b&sig=XeFd8I%2BBC6aFg5pCHsOMWJnUO%2BH3n60rA9iyVmTdP7A%3D">
                                link to the video
                              </a>
                              instead.
                            </p>
                          </video>
                        </div>
                        {/* <div className="bx--col-lg-6">
                      <Accordion>
                        <AccordionItem
                          title={
                            <div className="d-flex align-items-center">
                              <div className="font-weight-bold mb-2">
                                Jack Bill
                              </div>
                              <div className="ml-2">
                                {
                                  <StarRatingComponent
                                    starCount={5}
                                    value={3}
                                    starColor="#ffb400"
                                    emptyStarColor="#ffb400"
                                    renderStarIcon={(index, value) => {
                                      return (
                                        <span>
                                          <i
                                            className={
                                              index <= value
                                                ? 'fas fa-star'
                                                : 'far fa-star'
                                            }
                                          />
                                        </span>
                                      );
                                    }}
                                    editing={false}
                                    renderStarIconHalf={() => {
                                      return (
                                        <span>
                                          <span
                                            style={{
                                              position: 'absolute',
                                            }}>
                                            <i className="far fa-star" />
                                          </span>
                                          <span>
                                            <i className="fas fa-star-half" />
                                          </span>
                                        </span>
                                      );
                                    }}
                                    onStarClick={() => {}}
                                  />
                                }
                              </div>
                            </div>
                          }>
                          <div>
                            <div>Communication</div>
                            <StarRatingComponent
                              starCount={5}
                              value={3}
                              starColor="#ffb400"
                              emptyStarColor="#ffb400"
                              renderStarIcon={(index, value) => {
                                return (
                                  <span>
                                    <i
                                      className={
                                        index <= value
                                          ? 'fas fa-star'
                                          : 'far fa-star'
                                      }
                                    />
                                  </span>
                                );
                              }}
                              editing={false}
                              renderStarIconHalf={() => {
                                return (
                                  <span>
                                    <span
                                      style={{
                                        position: 'absolute',
                                      }}>
                                      <i className="far fa-star" />
                                    </span>
                                    <span>
                                      <i className="fas fa-star-half" />
                                    </span>
                                  </span>
                                );
                              }}
                              onStarClick={() => {}}
                            />
                            <div>
                              <TextArea value="Very good" />
                            </div>
                          </div>
                        </AccordionItem>
                      </Accordion>
                    </div> */}
                      </div>
                      <hr />
                      <div className="font-weight-bold mb-2">Traits</div>
                      <div>
                        <h6 className="mb-2">COMMUNICATION SKILLS</h6>
                        <div className="mb-2">
                          1. <span style={{ fontWeight: 'bold' }}>Clarity</span>
                          : Presents views in a well-organised and focused
                          manner; clear and comprehensible
                        </div>
                        <div className="d-flex align-items-center mt-2 mb-2">
                          <div className="ml-2">
                            <RadioButton labelText="Yes" />
                          </div>
                          <div className="ml-2">
                            <RadioButton labelText="KIV" />
                          </div>
                          <div className="ml-2">
                            <RadioButton labelText="No" />
                          </div>
                        </div>
                        <div className="mb-2">
                          2.{' '}
                          <span style={{ fontWeight: 'bold' }}>Engaging</span>:
                          Presents views in a confident, compelling manner;
                          Displays appropriate non-verbal behaviors to engage
                          listener
                        </div>
                        <div className="d-flex align-items-center mt-2 mb-2">
                          <div className="ml-2">
                            <RadioButton labelText="Yes" />
                          </div>
                          <div className="ml-2">
                            <RadioButton labelText="KIV" />
                          </div>
                          <div className="ml-2">
                            <RadioButton labelText="No" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <h6 className="mb-2">CHARACTER AND VALUES</h6>
                        <div className="mb-2">
                          1.{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            Passion to serve
                          </span>
                          : Committed to serving the Public Service or nation
                        </div>
                        <div className="d-flex align-items-center mt-2 mb-2">
                          <div className="ml-2">
                            <RadioButton labelText="Yes" />
                          </div>
                          <div className="ml-2">
                            <RadioButton labelText="KIV" />
                          </div>
                          <div className="ml-2">
                            <RadioButton labelText="No" />
                          </div>
                        </div>
                        <div className="mb-2">
                          2.{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            Passion to serve
                          </span>
                          : Committed to creating impact with the community or
                          improve the lives of others through career in the
                          public or private sector; Dedication to helping others
                        </div>
                        <div className="d-flex align-items-center mt-2 mb-2">
                          <div className="ml-2">
                            <RadioButton labelText="Yes" />
                          </div>
                          <div className="ml-2">
                            <RadioButton labelText="KIV" />
                          </div>
                          <div className="ml-2">
                            <RadioButton labelText="No" />
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="font-weight-bold mb-2">Comments</div>
                      <div>
                        <TextArea rows={3} />
                      </div>
                      <hr />
                      <div className="font-weight-bold">Overall Rating</div>
                      <div className="mt-2">
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton onChange={() => {}} labelText="Yes" />
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton onChange={() => {}} labelText="KIV" />
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton onChange={() => {}} labelText="No" />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 7 && (
                  <Fragment>
                    <div className="some-content">
                      <div className="mt-2 h6 font-weight-bold">Reports</div>
                      <hr />
                      <div>
                        <GenericDataTable
                          className=""
                          tbodyClass="candidate-background-color"
                          otherActions={[]}
                          headers={[
                            { key: 'header_0', header: 'Report' },
                            {
                              key: 'header_1',
                              header: 'Action',
                            },
                          ]}
                          rows={[
                            {
                              name: 'School Assessment Report',
                              value: (
                                <div className="d-flex">
                                  <Button
                                    small
                                    className="bx--btn-center-text bx--btn--sm--center-text"
                                    onClick={() => {}}
                                    kind="primary mt-2 mb-2">
                                    <a
                                      target="_blank"
                                      className="css-color-w"
                                      href="/public/reports">
                                      View
                                    </a>
                                  </Button>
                                </div>
                              ),
                              showBold: false,
                            },
                            {
                              name: 'Psychological Interview Report',
                              value: (
                                <div className="d-flex">
                                  <div className="">
                                    <Button
                                      small
                                      className="bx--btn-center-text bx--btn--sm--center-text"
                                      onClick={() => {
                                        this.setState({
                                          isOpenPsychologicalModal: true,
                                        });
                                      }}
                                      kind="primary mt-2 mb-2">
                                      View
                                    </Button>
                                  </div>
                                  {/* <div className="ml-2">
                                    <Button
                                      small
                                      className="bx--btn-center-text bx--btn--sm--center-text"
                                      onClick={() => {}}
                                      kind="secondary mt-2 mb-2">
                                      Upload
                                    </Button>
                                  </div> */}
                                </div>
                              ),
                              showBold: false,
                            },
                            {
                              name: 'Essay',
                              value: (
                                <div className="d-flex">
                                  <Button
                                    small
                                    className="bx--btn-center-text bx--btn--sm--center-text"
                                    onClick={() => {}}
                                    kind="primary mt-2 mb-2">
                                    <a
                                      target="_blank"
                                      className="css-color-w"
                                      href="/public/reports">
                                      View
                                    </a>
                                  </Button>
                                </div>
                              ),
                              showBold: false,
                            },
                            {
                              name: 'University Academic Transcripts',
                              value: (
                                <div className="d-flex ">
                                  <Button
                                    small
                                    className="bx--btn-center-text bx--btn--sm--center-text"
                                    onClick={() => {}}
                                    kind="primary mt-2 mb-2">
                                    <a
                                      target="_blank"
                                      className="css-color-w"
                                      href="/public/reports">
                                      View
                                    </a>
                                  </Button>
                                </div>
                              ),
                              showBold: false,
                            },
                            {
                              name: 'Pre-Tertiary Academic Transcripts',
                              value: (
                                <div className="d-flex">
                                  <Button
                                    small
                                    className="bx--btn-center-text bx--btn--sm--center-text"
                                    onClick={() => {}}
                                    kind="primary mt-2 mb-2">
                                    <a
                                      target="_blank"
                                      className="css-color-w"
                                      href="/public/reports">
                                      View
                                    </a>
                                  </Button>
                                </div>
                              ),
                              showBold: false,
                            },
                            {
                              name: 'CCA and CIP records',
                              value: (
                                <div className="d-flex">
                                  <Button
                                    small
                                    className="bx--btn-center-text bx--btn--sm--center-text"
                                    onClick={() => {}}
                                    kind="primary mt-2 mb-2">
                                    <a
                                      target="_blank"
                                      className="css-color-w"
                                      href="/public/reports">
                                      View
                                    </a>
                                  </Button>
                                </div>
                              ),
                              showBold: false,
                            },
                            {
                              name: 'Psychometric test results',
                              value: (
                                <div className="d-flex">
                                  <Button
                                    small
                                    className="bx--btn-center-text bx--btn--sm--center-text"
                                    onClick={() => {}}
                                    kind="primary mt-2 mb-2">
                                    <a
                                      target="_blank"
                                      className="css-color-w"
                                      href="/public/reports">
                                      View
                                    </a>
                                  </Button>
                                </div>
                              ),
                              showBold: false,
                            },
                            {
                              name: 'GBA Candidate Report',
                              value: (
                                <div className="d-flex">
                                  <Button
                                    small
                                    className="bx--btn-center-text bx--btn--sm--center-text"
                                    onClick={() => {}}
                                    kind="primary mt-2 mb-2">
                                    <a
                                      target="_blank"
                                      className="css-color-w"
                                      href="/public/reports">
                                      View
                                    </a>
                                  </Button>
                                </div>
                              ),
                              showBold: false,
                            },
                            {
                              name: 'Other Supporting Documents',
                              value: (
                                <div className="d-flex">
                                  <div className="">
                                    <Button
                                      small
                                      className="bx--btn-center-text bx--btn--sm--center-text"
                                      onClick={() => {}}
                                      kind="primary mt-2 mb-2">
                                      <a
                                        target="_blank"
                                        className="css-color-w"
                                        href="/public/reports">
                                        View
                                      </a>
                                    </Button>
                                  </div>
                                  {/* <div className="ml-2">
                                    <Button
                                      small
                                      className="bx--btn-center-text bx--btn--sm--center-text"
                                      onClick={() => {}}
                                      kind="secondary mt-2 mb-2">
                                      Upload
                                    </Button>
                                  </div> */}
                                </div>
                              ),
                              showBold: false,
                            },

                            {
                              name: 'Testimonal',
                              value: (
                                <div className="d-flex">
                                  <div className="">
                                    <Button
                                      small
                                      className="bx--btn-center-text bx--btn--sm--center-text"
                                      onClick={() => {}}
                                      kind="primary mt-2 mb-2">
                                      <a
                                        target="_blank"
                                        className="css-color-w"
                                        href="/public/reports">
                                        View
                                      </a>
                                    </Button>
                                  </div>
                                  {/* <div className="ml-2">
                                    <Button
                                      small
                                      className="bx--btn-center-text bx--btn--sm--center-text"
                                      onClick={() => {}}
                                      kind="secondary mt-2 mb-2">
                                      Upload
                                    </Button>
                                  </div> */}
                                </div>
                              ),
                              showBold: false,
                            },

                            {
                              name: 'Secondary 4 School Academic Report',
                              value: (
                                <div className="d-flex">
                                  <div className="">
                                    <Button
                                      small
                                      className="bx--btn-center-text bx--btn--sm--center-text"
                                      onClick={() => {}}
                                      kind="primary mt-2 mb-2">
                                      <a
                                        target="_blank"
                                        className="css-color-w"
                                        href="/public/reports">
                                        View
                                      </a>
                                    </Button>
                                  </div>
                                  {/* <div className="ml-2">
                                    <Button
                                      small
                                      className="bx--btn-center-text bx--btn--sm--center-text"
                                      onClick={() => {}}
                                      kind="secondary mt-2 mb-2">
                                      Upload
                                    </Button>
                                  </div> */}
                                </div>
                              ),
                              showBold: false,
                            },
                          ].map((res, idx) => {
                            const { name, value, showBold } = res || {};
                            return {
                              isExpanded: true,
                              id: `row_${idx}`,
                              header_0: (
                                <div
                                  className={
                                    showBold ? 'font-weight-bold' : ''
                                  }>
                                  {name}
                                </div>
                              ),
                              header_1: <div>{value}</div>,
                            };
                          })}
                          selectable={false}
                          searchable={false}
                          onSearchInputChange={() => {}}
                          expandable={false}
                          batchActions={false}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
                {selectedTab === 8 && (
                  <Fragment>
                    <CandidateEvalFormMock
                      evaluationList={[]}
                      appId={3}
                      history={this.props.history}
                    />
                  </Fragment>
                )}
                {selectedTab === 9 && (
                  <PsacPeerAppraisalMock fullName={finalFullName} />
                )}
              </div>
            </div>
          </Tile>
          {/* <Tile className="mt-2">
            <Tabs
              ariaLabel="listbox"
              className="some-class"
              iconDescription="show menu options"
              role="navigation"
              selected={0}
              selectionMode="automatic"
              tabContentClassName="tab-content"
              triggerHref="#">
              <Tab
                href="#"
                id="tab-1"
                label="Personal Information"
                role="presentation"
                selected={false}
                tabIndex={0}>
                <div className="some-content">
                  <div className="mt-2 h6 font-weight-bold">
                    Personal and Family Information
                  </div>
                  <hr />
                  <div>
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      expandedRows={[
                        <div className="bx--row">
                          <div className="bx--col-lg-6">
                            <div className="font-weight-bold">
                              <b>Father's Info</b>
                            </div>
                            <hr />
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">Name</div>
                              <div className="bx--col-lg-4">Harry</div>
                            </div>
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">Citizenship</div>
                              <div className="bx--col-lg-4">‘Singaporean’</div>
                            </div>
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">Occupation</div>
                              <div className="bx--col-lg-4">Lead Commander</div>
                            </div>
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">Marital Status</div>
                              <div className="bx--col-lg-4">Married</div>
                            </div>
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">
                                Scholarship Held
                              </div>
                              <div className="bx--col-lg-4">Yes</div>
                            </div>
                          </div>
                          <div className="bx--col-lg-6">
                            <div className="font-weight-bold">
                              <b>Mother's Info</b>
                            </div>
                            <hr />
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">Name</div>
                              <div className="bx--col-lg-4">Gene</div>
                            </div>
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">Citizenship</div>
                              <div className="bx--col-lg-4">Canadian</div>
                            </div>
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">Occupation</div>
                              <div className="bx--col-lg-4">House wife</div>
                            </div>
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">Marital Status</div>
                              <div className="bx--col-lg-4">Married</div>
                            </div>
                            <div className="bx--row mt-2">
                              <div className="bx--col-lg-4">
                                Scholarship Held
                              </div>
                              <div className="bx--col-lg-4">No</div>
                            </div>
                          </div>
                        </div>,
                      ]}
                      headers={headers1.map((data, index) => ({
                        key: `header_${index}`,
                        header: data.title,
                        className: data.className,
                        colpropName: data.propName,
                      }))}
                      rows={rowsData1.map((res) => {
                        const {
                          age,
                          name,
                          citizenship,
                          unit,
                          enlisted,
                          grading,
                          rank,
                          nric,
                        } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${res && res.id}`,
                          header_0: (
                            <div className="d-flex align-items-center">
                              <div>
                                <img
                                  style={{ width: '20px' }}
                                  src={MaleImg}
                                  alt="Male"
                                />
                              </div>
                              <div className="ml-2">{name}</div>
                            </div>
                          ),
                          header_1: <div>{age}</div>,
                          header_2: <div>XXXX1715N</div>,
                          header_3: <div>{citizenship}</div>,
                          header_4: <div>{grading}</div>,
                          header_5: <div>{enlisted}</div>,
                          header_6: <div>{rank}</div>,
                          header_7: <div>{unit}</div>,
                          header_8: <div>-</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable
                      batchActions={false}
                    />
                  </div>
                </div>
              </Tab>
              <Tab
                href="#"
                id="tab-6"
                label="Applications"
                role="presentation"
                selected={false}
                tabIndex={0}>
                <div className="some-content">
                  <div className="mt-2 h6 font-weight-bold">
                    Academic Information
                  </div>
                  <hr />
                  <div className="mt-2 mb-3 font-weight-bold">
                    University and Scholarship Applications
                  </div>
                  <div>
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={headers4.map((data, index) => ({
                        key: `header_${index}`,
                        header: data.title,
                        className: data.className,
                        colpropName: data.propName,
                      }))}
                      rows={rowsData6.map((res, idx) => {
                        const { university, course, status, locOver } =
                          res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{university}</div>,
                          header_1: <div>{course}</div>,
                          header_2: <div>{status}</div>,
                          header_3: (
                            <div>
                              {locOver === 'local' ? <Home32 /> : <Globe32 />}
                            </div>
                          ),
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                  <div className="mt-3 mb-3 font-weight-bold">
                    Other Scholarship Application
                  </div>
                  <div>
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'Scholarship',
                          className: 'w-25 text-left',
                        },
                        {
                          key: 'header_1',
                          header: 'Course',
                          className: 'w-25 text-center',
                        },
                        {
                          key: 'header_2',
                          header: 'Status',
                          className: 'w-25 text-center',
                        },
                        {
                          key: 'header_3',
                          header: '',
                          className: 'w-25 text-center',
                        },
                      ]}
                      rows={[
                        {
                          scholarship: 'National University of Singapore',
                          course: 'Physics',
                          status: 'Pending Outcome',
                        },
                        {
                          scholarship: 'National Technological University',
                          course: 'Physics',
                          status: 'Offered',
                        },
                      ].map((res, idx) => {
                        const { course, scholarship, status } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{scholarship}</div>,
                          header_1: <div>{course}</div>,
                          header_2: <div>{status}</div>,
                          header_3: null,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                </div>
              </Tab>
              <Tab
                href="#"
                id="tab-2"
                label="Academic Results"
                role="presentation"
                selected={false}
                tabIndex={0}>
                <div className="some-content">
                  <div className="mt-2 h6 font-weight-bold">
                    Academic Results
                  </div>
                  <hr />
                  <div className="bx--row align-items-center">
                    <div className="bx--col-lg-6 bx--col-md-6">
                      <div className="h6 mb-2 font-weight-bold">
                        National University of Singapore, 2018-2022
                      </div>
                      <div>GCE A Level, 2019</div>
                      <div className="mb-2 mt-2">No Of Academic Units: 11</div>
                      <div className="mt-2 mb-2">PSC Points (max = 70): 69</div>
                      <div className="mt-1">Honours (Highest Distinction)</div>
                    </div>
                    <div className="bx--col-lg-6 bx--col-md-6">
                      <Button><a target="_blank" className="css-color-w" href="/public/reports">
                                      View
                                    </a> Transcript</Button>
                    </div>
                  </div>
                  <div className="mt-3">
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'Subject',
                          className: 'w-50',
                        },
                        { key: 'header_1', header: 'Subject Level' },
                        { key: 'header_2', header: 'Grade' },
                      ]}
                      rows={rowsData2.map((res, idx) => {
                        const { subj, score, grade } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{subj}</div>,
                          header_1: <div>{score}</div>,
                          header_2: <div>{grade}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                  <div className="h6 font-weight-bold mt-3 mb-1">
                    NUS High School of Mathematics and science, 2015 - 2017
                  </div>
                  <div className="mt-2 mb-2">Cum CPA - 3.5/5.0</div>
                  <div className="mt-3 mb-3">
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'Subject',
                          className: 'text-left w-50',
                        },
                        {
                          key: 'header_1',
                          header: 'CAP',
                          className: 'text-left w-50',
                        },
                      ]}
                      rows={[
                        {
                          subj: 'English Language',
                          grade: '3.8',
                        },
                        {
                          subj: 'Oral',
                          grade: '3.7',
                        },
                        {
                          subj: 'Literature In English',
                          grade: '3.2',
                        },
                        {
                          subj: 'Combined Humanities (revised)',
                          grade: '3.7',
                        },
                        {
                          subj: 'Mathematics',
                          grade: '3.4',
                        },
                        {
                          subj: 'Additional Mathematics',
                          grade: '3.1',
                        },
                      ].map((res, idx) => {
                        const { subj, grade } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{subj}</div>,
                          header_1: <div>{grade}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                  <div className="h6 font-weight-bold mt-2 mb-1">
                    YY Secondary School, 2014 - 2015
                  </div>
                  <div className="mt-2 mb-2">GCE O-level, 2015</div>
                  <div className="mt-3 mb-3">
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={headers3.map((data, index) => ({
                        key: `header_${index}`,
                        header: data.title,
                        className: data.className,
                        colpropName: data.propName,
                      }))}
                      rows={rowsData3.map((res, idx) => {
                        const { subj, grade } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{subj}</div>,
                          header_1: <div>{grade}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                  <div className="mt-2 h6 font-weight-bold mb02">
                    SAT Results
                  </div>
                  <div className="mt-3 mb-3">
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'Subject',
                          className: 'w-50',
                        },
                        {
                          key: 'header_1',
                          header: 'Score',
                          className: 'sat-score-wdth',
                        },
                        { key: 'header_2', header: 'Percentile' },
                      ]}
                      rows={[
                        {
                          subj: 'Evidence Based - Reading and Writing',
                          score: 720,
                          percent: 98,
                        },
                        {
                          subj: 'Math',
                          score: 700,
                          percent: 95,
                        },
                        {
                          subj: 'Essay',
                          score: '-',
                          percent: 98,
                        },
                        {
                          subj: <div style={{ fontWeight: 'bold' }}>Total</div>,
                          score: <div style={{ fontWeight: 'bold' }}>1420</div>,
                          percent: '-',
                        },
                      ].map((res, idx) => {
                        const { subj, score, percent } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{subj}</div>,
                          header_1: <div>{score}</div>,
                          header_2: <div>{percent}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                </div>
              </Tab>

              <Tab
                href="#"
                id="tab-6"
                label="CCA & Achievements"
                role="presentation"
                selected={false}
                tabIndex={0}>
                <div className="some-content">
                  <div className="mt-2 h6 font-weight-bold">
                    CCA, Achievements, Non-Academic Qualification and
                    Internships
                  </div>
                  <hr />
                  <div>
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        { key: 'header_0', header: 'CCA', className: 'w-50' },
                        { key: 'header_1', header: 'Tier' },
                      ]}
                      rows={[
                        { name: 'Leadership', value: 'Tier 3' },
                        { name: 'Representation', value: 'Tier 3' },
                      ].map((res, idx) => {
                        const { name, value } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{name}</div>,
                          header_1: <div>{value}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                  <div className="mt-2">
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'Achievements',
                          className: 'w-50',
                        },
                        { key: 'header_1', header: 'Year' },
                      ]}
                      rows={[
                        {
                          name:
                            'Finalist at Singapore Science and Engineering Fair',
                          value: '2019',
                        },
                        { name: 'A*STAR JS Science Award', value: '2018-2019' },
                        {
                          name: 'Singapore Chemistry Olymppiad Bronze Medal',
                          value: '2018',
                        },
                        {
                          name:
                            '40th QueenStwon CC chess championship U-16 !st Place',
                          value: '2017',
                        },
                      ].map((res, idx) => {
                        const { name, value } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{name}</div>,
                          header_1: <div>{value}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                  <div className="mt-2">
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'Non-Academic Qualification',
                          className: 'w-50',
                        },
                        { key: 'header_1', header: 'Year' },
                      ]}
                      rows={[
                        { name: 'Kayaking 1-star', value: '2015' },
                        { name: 'Swinsafer Gold Award', value: '2010' },
                      ].map((res, idx) => {
                        const { name, value } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{name}</div>,
                          header_1: <div>{value}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                  <div className="mt-2">
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'Internship',
                          className: 'w-50',
                        },
                        { key: 'header_1', header: 'Details' },
                      ]}
                      rows={[
                        { name: 'Organisation', value: 'Singapore Airlines' },
                        { name: 'Department / Unit', value: 'Human Resources' },
                        {
                          name: 'Period',
                          value: '22 June 2020 to 06 July 2020',
                        },
                        {
                          name: 'Description',
                          value:
                            'Developing predictive analytics solution based on cabin crew data.',
                        },
                        {
                          name: "Supervisor's Comments",
                          value: (
                            <TextArea
                              placeholder="Please enter your comments"
                              rows={3}
                            />
                          ),
                        },
                      ].map((res, idx) => {
                        const { name, value } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{name}</div>,
                          header_1: <div>{value}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                  <div className="mt-2">
                    <GenericDataTable
                      className=""
                      otherActions={[]}
                      headers={[
                        {
                          key: 'header_0',
                          header: 'Internship',
                          className: 'w-50',
                        },
                        { key: 'header_1', header: 'Details' },
                      ]}
                      rows={[
                        {
                          name: 'Organisation',
                          value: 'Singapore Telecommunications Limited',
                        },
                        { name: 'Department / Unit', value: 'StarHub' },
                        {
                          name: 'Period',
                          value: '21 June 2019 to 06 July 2019',
                        },
                        {
                          name: 'Description',
                          value:
                            'Worked on GSM, HSPA and LTE Mobile Network (3G/4G)',
                        },
                        {
                          name: "Supervisor's Comments",
                          value: (
                            <TextArea
                              placeholder="Please enter your comments"
                              rows={3}
                            />
                          ),
                        },
                      ].map((res, idx) => {
                        const { name, value } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${idx}`,
                          header_0: <div>{name}</div>,
                          header_1: <div>{value}</div>,
                        };
                      })}
                      selectable={false}
                      searchable={false}
                      onSearchInputChange={() => {}}
                      expandable={false}
                      batchActions={false}
                    />
                  </div>
                </div>
              </Tab>
              <Tab
                href="#"
                id="tab-3"
                label="Psychometric tests"
                role="presentation"
                selected={false}
                tabIndex={0}>
                <div className="some-content">
                  <div className="mt-2 h6 font-weight-bold">
                    Psychometric test results
                  </div>
                  <hr />
                  <div className="h6 font-weight-bold">
                    Cognitive Ability Test Results
                  </div>
                  <div>
                    <table className="table-border mb-2 w-50">
                      <thead>
                        <th className="font-weight-bold text-left">Tests</th>
                        <th className="font-weight-bold">Percentile</th>
                      </thead>
                      <tbody className="table-border">
                        <tr>
                          <td className="text-left">
                            Watson-Glaser Critical Thinking Appraisal
                          </td>
                          <td className="csc-th--block csc-th--h">82nd</td>
                        </tr>
                        <tr>
                          <td className="text-left">
                            Advanced Numerical Reasoning Appraisal
                          </td>
                          <td className="csc-th--block csc-th--l">37th</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="h6 font-weight-bold mt-2">GBA Scores</div>
                  <div>
                    <table className="table-border mb-2 w-50">
                      <thead>
                        <th className="font-weight-bold text-left">GBA</th>
                        <th className="font-weight-bold">Scores</th>
                      </thead>
                      <tbody className="table-border">
                        <tr>
                          <td className="text-left">Performance Based</td>
                          <td className="csc-th--block csc-th--h">87</td>
                        </tr>
                        <tr>
                          <td className="text-left">Future Driven</td>
                          <td className="csc-th--block csc-th--l">35</td>
                        </tr>
                        <tr>
                          <td className="text-left">Award</td>
                          <td className="csc-th--block csc-th--m">54</td>
                        </tr>
                        <tr>
                          <td className="text-left">Average</td>
                          <td className="csc-th--block csc-th--m">59</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="h6 font-weight-bold">Peer comparison</div>
                    <table className="table-border w-100">
                      <thead>
                        <th>No</th>
                        <th>Name</th>
                        <th>NRIC</th>
                        <th>Gender</th>
                        <th>Test 1 Percentile</th>
                        <th>OMS Status</th>
                        <th>SGS Status</th>
                        <th>LMS Status</th>
                        <th>Test 2 Percentile</th>
                        <th>OMS Status</th>
                        <th>SGS Status</th>
                        <th>LMS Status</th>
                        <th>Overall OMS Status</th>
                        <th>Overall SGS Status</th>
                        <th>Overall LMS Status</th>
                      </thead>
                      <tbody className="table-border">
                        {rowsData4.map((res, idx) => {
                          const {
                            no,
                            name,
                            gender,
                            test1,
                            oms1,
                            sgs1,
                            lms1,
                            test2,
                            oms2,
                            sgs2,
                            lms2,
                            overallLms,
                            overallOms,
                            overallSgs,
                          } = res;
                          return (
                            <tr
                              className={
                                idx == 2 ? 'joblist__cnt--screen' : ''
                              }>
                              <td>{no}</td>
                              <td className="text-left font-weight-bold">
                                <small>{name}</small>
                              </td>
                              <td>-</td>
                              <td>{gender}</td>
                              <td>{test1}</td>
                              <td>{oms1}</td>
                              <td>{sgs1}</td>
                              <td>{lms1}</td>
                              <td>{test2}</td>
                              <td>{oms2}</td>
                              <td>{sgs2}</td>
                              <td>{lms2}</td>
                              <td>{overallOms}</td>
                              <td>{overallSgs}</td>
                              <td>{overallLms}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2 mb-2">
                    <div className="h6 font-weight-bold">
                      Personality Profile (NEO PI-R)
                    </div>
                  </div>
                  <table className="table-border w-100">
                    <thead>
                      <th>Domain</th>
                      <th>Scale</th>
                      <th>Low Score Descriptors</th>
                      <th colspan="5">Individual Results </th>
                      <th>High Score Descriptors</th>
                    </thead>
                    <thead>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="csc-th--vl results-min-width">
                        <div>1</div>
                        <small>Very Low</small>
                      </th>
                      <th className="csc-th--l results-min-width">
                        <div>2</div>
                        <small>Low</small>
                      </th>
                      <th className="csc-th--m results-min-width">
                        <div>3</div>
                        <small>Average</small>
                      </th>
                      <th className="csc-th--h results-min-width">
                        <div>4</div>
                        <small>High</small>
                      </th>
                      <th className="csc-th--vh results-min-width">
                        <div>5</div>
                        <small>Very High</small>
                      </th>
                      <th></th>
                    </thead>
                    <tbody className="table-border text-left">
                      <tr>
                        <td rowspan="7" className="h6 font-weight-bold">
                          Extraversion
                        </td>
                        <td className="csc-th--na-light">Overall</td>
                        <td className="csc-th--na-light">-</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na text-center">x</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                      </tr>
                      <tr>
                        <td>Friendliness</td>
                        <td>Reserved, distant, cool</td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Friendly, warm, affectionate</td>
                      </tr>
                      <tr>
                        <td>Sociability</td>
                        <td>Introverted, prefers privacy </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Gregarious, prefers company</td>
                      </tr>
                      <tr>
                        <td>Assertive</td>
                        <td>Non-assertive, acquiescent, passive </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Assertive, forceful, dominant</td>
                      </tr>
                      <tr>
                        <td>Pace of living</td>
                        <td>Sedentary, slow-paced, inactive </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Active, energetic, fast-paced</td>
                      </tr>
                      <tr>
                        <td>Excitement Seeking</td>
                        <td>Conventinal, uncomplicated needs, avoids risks </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Adventurous, daring, pleasure seeking</td>
                      </tr>
                      <tr>
                        <td>Positive outlook </td>
                        <td>Serious, Sober, lacking in enthusiasm </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Cheerful, positive, exuberant</td>
                      </tr>
                      <tr>
                        <td rowspan="7" className="h6 font-weight-bold">
                          Agreeableness
                        </td>
                        <td className="csc-th--na-light">Overall</td>
                        <td className="csc-th--na-light">-</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na text-center">x</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                      </tr>
                      <tr>
                        <td>Trust</td>
                        <td>Suspicious, Cynical, distrusting</td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Accepting, forgiving, trusting</td>
                      </tr>
                      <tr>
                        <td>Straightforwardness</td>
                        <td>Cautious, guarded, indirect, manipulative </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Straightforward, frank, forthright</td>
                      </tr>
                      <tr>
                        <td>Altruism</td>
                        <td>Self-absorbed, high, slef-interest </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Considerate, helpful, aware of other's needs</td>
                      </tr>
                      <tr>
                        <td>Compliance</td>
                        <td>Competitive, headstrong, contentious </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Deferent, complaint, co-operative</td>
                      </tr>
                      <tr>
                        <td>Modesty</td>
                        <td>
                          Feels superior, considered arrogant, or conceited by
                          others{' '}
                        </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Humble, modest, self-effacing</td>
                      </tr>
                      <tr>
                        <td>Compassion</td>
                        <td>Hard-headed, tough-minded, coldly logical </td>
                        <td></td>
                        <td className="csc-th--l-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Understanding, sympathetic, compassionate</td>
                      </tr>
                      <tr>
                        <td rowspan="7" className="h6 font-weight-bold">
                          Openness
                        </td>
                        <td className="csc-th--na-light">Overall</td>
                        <td className="csc-th--na-light">-</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na text-center">x</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                      </tr>
                      <tr>
                        <td>Openness to imagination</td>
                        <td>Concrete, unimaginative</td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Abstract, creative, imaginative</td>
                      </tr>
                      <tr>
                        <td>Openness to Aesthetics</td>
                        <td>Prosaic, lacks of interest in the arts </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Artistic, Stylish, aesthetic</td>
                      </tr>
                      <tr>
                        <td>Openness to Feelings</td>
                        <td>
                          Emotions are unimportant, limited in range, controlled{' '}
                        </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>
                          Emotions are important, responsive, wide-ranging
                        </td>
                      </tr>
                      <tr>
                        <td>Openness to new activities</td>
                        <td>
                          Prefers status quonarrow range of activities,
                          predictable{' '}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--h-light text-center">x</td>
                        <td></td>
                        <td>
                          Versatile, wide range of interests, willing to try new
                          things
                        </td>
                      </tr>
                      <tr>
                        <td>Openness to ideas</td>
                        <td>Limited curiosity, narrowly focused </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Inquisitive, curious, open-minded</td>
                      </tr>
                      <tr>
                        <td>Openness to values </td>
                        <td>Traditional-bound, conventional, dogmatic </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>
                          Unconventional, open to a variety of behaviours and
                          view points
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="7" className="h6 font-weight-bold">
                          Conscientiousness
                        </td>
                        <td className="csc-th--na-light">Overall</td>
                        <td className="csc-th--na-light">-</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na text-center">x</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                      </tr>
                      <tr>
                        <td>Sense of mastery</td>
                        <td>Unsure, inefficient, unprepared</td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Self-confident, resourceful</td>
                      </tr>
                      <tr>
                        <td>Order</td>
                        <td>Disorganized, unmethodical, careless </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Neat, tidy, orderly, well-organized</td>
                      </tr>
                      <tr>
                        <td>Dutifulness</td>
                        <td>Unreliable, undependable, irresponsible </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Dependable, principled, responsible</td>
                      </tr>
                      <tr>
                        <td>Achievement striving</td>
                        <td>Lackadaisical, complacent, aimless </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Ambitious, goal oriented, enterprising</td>
                      </tr>
                      <tr>
                        <td>Self Discipline</td>
                        <td>
                          Distractible, lack of follow-through, pro
                          crastinationg{' '}
                        </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>
                          Inner-motivated, preserving, completes what is started
                        </td>
                      </tr>
                      <tr>
                        <td>Deliberation</td>
                        <td>Hasty, impatient, spntaneous </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Cautious, deliberate, thorough</td>
                      </tr>
                      <tr>
                        <td
                          rowspan="7"
                          style={{ maxWidth: '20px' }}
                          className="h6 font-weight-bold">
                          Neuroticism / Emotional Stability
                        </td>
                        <td className="csc-th--na-light">Overall</td>
                        <td className="csc-th--na-light">-</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na text-center">x</td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                        <td className="csc-th--na-light"></td>
                      </tr>
                      <tr>
                        <td>Anxiety</td>
                        <td>Calm, relaxed, carefree</td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Apprehensive, worried, tense</td>
                      </tr>
                      <tr>
                        <td>Frustration</td>
                        <td>Patient, even-tempered, easy going </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Short-tempered, irritable, intense</td>
                      </tr>
                      <tr>
                        <td>Dependency</td>
                        <td>Contented, upbeat, hopeful </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Discontented, pessimistic</td>
                      </tr>
                      <tr>
                        <td>Self-consciousness</td>
                        <td>Socially confident, selft assured </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Shy, inhibited, self conscious</td>
                      </tr>
                      <tr>
                        <td>Impulsivity</td>
                        <td>Self-denying, stotic, resists temptation </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Self-undulgent, easily tempted</td>
                      </tr>
                      <tr>
                        <td>Stress prone</td>
                        <td>
                          Self-confident, poised, clear thinking, calm under
                          fire{' '}
                        </td>
                        <td></td>
                        <td></td>
                        <td className="csc-th--m-light text-center">x</td>
                        <td></td>
                        <td></td>
                        <td>Confused, panicky, dependent</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>

              <Tab
                href="#"
                id="tab-7"
                label="GBA"
                role="presentation"
                selected={false}
                tabIndex={0}>
                
              </Tab>
              <Tab
                href="#"
                id="tab-4"
                label="Video Interview"
                role="presentation"
                selected={false}
                tabIndex={0}>
                
              </Tab>
              <Tab
                href="#"
                id="tab-5"
                label="Reports"
                role="presentation"
                selected={false}
                tabIndex={0}>
                
              </Tab>
              <Tab
                href="#"
                id="tab-5"
                label="Candidate Evaluation"
                role="presentation"
                selected={false}
                tabIndex={0}>
                

                <div className="some-content">
                  <div className="mt-2 h6 font-weight-bold">
                    Candidate Evaluation Form
                  </div>
                  <hr />
                  <Accordion>
                    <AccordionItem
                      className="content-padding"
                      title={
                        <div className="d-flex justify-content-between mt-2">
                          <div>
                            Rater:{' '}
                            <span className="font-weight-bold">Jack Bill</span>
                          </div>
                        </div>
                      }>
                      <table className="table-border w-100">
                        <thead>
                          <th className="color-blue font-size-x-lg">No.</th>
                          <th className="color-blue font-size-x-lg">
                            <div className="font-size-x-lg">Trait</div>
                            <div>(Behavioural Statement)</div>
                          </th>
                          <th className="csc-th--vl results-min-width">
                            <div>1</div>
                            <small>Very weak</small>
                          </th>
                          <th className="csc-th--l results-min-width">
                            <div>2</div>
                            <small>Weak</small>
                          </th>
                          <th className="csc-th--m results-min-width">
                            <div>3</div>
                            <small>Moderate</small>
                          </th>
                          <th className="csc-th--h results-min-width">
                            <div>4</div>
                            <small>Strong</small>
                          </th>
                          <th className="csc-th--vh results-min-width">
                            <div>5</div>
                            <small>Very Strong</small>
                          </th>
                          <th className="color-gray results-min-width">
                            <div>Not able to tell</div>
                          </th>
                        </thead>
                        <tbody className="table-border text-left">
                          {rowsData5.map((res) => {
                            const {
                              name,
                              desc,
                              no,
                              key1,
                              key2,
                              key3,
                              key4,
                              key5,
                              key6,
                            } = res || {};
                            return (
                              <tr className="text-left">
                                <td>{no}</td>
                                <td>
                                  <div className="font-weight-bold mb-2 h5">
                                    {name}
                                  </div>
                                  <div style={{ fontSize: 'large' }}>
                                    {desc}
                                  </div>
                                </td>
                                <td className="csc-th--vl-light">
                                  {' '}
                                  <RadioButton
                                    className="mt-2"
                                    onChange={() => {}}
                                    labelText=""
                                  />
                                </td>
                                <td className="csc-th--l-light">
                                  {' '}
                                  <RadioButton
                                    className="mt-2"
                                    onChange={() => {}}
                                    labelText=""
                                  />
                                </td>
                                <td className="csc-th--m-light">
                                  {' '}
                                  <RadioButton
                                    className="mt-2"
                                    onChange={() => {}}
                                    labelText=""
                                  />
                                </td>
                                <td className="csc-th--h-light">
                                  {' '}
                                  <RadioButton
                                    className="mt-2"
                                    onChange={() => {}}
                                    labelText=""
                                  />
                                </td>
                                <td className="csc-th--vh-light">
                                  {' '}
                                  <RadioButton
                                    className="mt-2"
                                    onChange={() => {}}
                                    labelText=""
                                  />
                                </td>
                                <td className="csc-th--na-light">
                                  {' '}
                                  <RadioButton
                                    className="mt-2"
                                    onChange={() => {}}
                                    labelText=""
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="color-blue text-center table-border mt-2 p-3">
                        Overall rating on the candidates suitability for a PSC
                        Scholarship
                      </div>
                      <div className="mt-2">
                        Each Commission member should rate this section based on
                        his/her independent opinion of the candidate. It may
                        differ from the final selection outcome.
                      </div>
                      <div className="mt-2 mb-2">
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton
                            onChange={() => {}}
                            labelText="Very Unsuitable - Courtesy Candidate"
                          />
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton
                            onChange={() => {}}
                            labelText="Unsuitable"
                          />
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton
                            onChange={() => {}}
                            labelText="Refer to Ministry/ Agencies"
                          />
                        </div>
                        <div className="mt-2">
                          <div className="d-flex justify-content-left">
                            <RadioButton
                              onChange={() => {}}
                              labelText="To Award PSC Scholarship"
                            />
                          </div>
                          <div className="d-flex justify-content-left mt-2 ml-5">
                            <RadioButton
                              onChange={() => {}}
                              labelText="To be considered for President's Scholarship"
                            />
                          </div>
                          <div className="d-flex justify-content-left mt-2 ml-5">
                            <RadioButton
                              onChange={() => {}}
                              labelText="Reserve Candidate for President's Scholarship"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 mb-2 font-weight-bold">Comments</div>
                      <div>
                        <TextArea placeholder="Please enter your comments" />
                      </div>
                      <div className="d-flex align-items-center">
                        <div>
                          <Button
                            small
                            onClick={() => {
                              this.setState({ showOtherContent: true });
                            }}
                            kind="primary mt-2 mb-2">
                            Submit
                          </Button>
                        </div>
                        {this.state.showOtherContent === true && (
                          <div className="ml-2" style={{ color: 'green' }}>
                            Jack Bill have been submitted successfully
                          </div>
                        )}
                      </div>
                    </AccordionItem>
                  </Accordion>
                  {this.state.showOtherContent === true && (
                    <div>
                      <h6 className="mt-2 mb-2">Combined Scatter Plot</h6>
                      <table className="table-border w-100">
                        <thead>
                          <th className="">
                            <div className="font-size-x-lg">Trait</div>
                          </th>
                          <th className="results-min-width">
                            <div>1</div>
                            <small>Very weak</small>
                          </th>
                          <th className="results-min-width">
                            <div>2</div>
                            <small>Weak</small>
                          </th>
                          <th className="results-min-width">
                            <div>3</div>
                            <small>Moderate</small>
                          </th>
                          <th className="results-min-width">
                            <div>4</div>
                            <small>Strong</small>
                          </th>
                          <th className="results-min-width">
                            <div>5</div>
                            <small>Very Strong</small>
                          </th>
                          <th className="results-min-width">
                            <div>Not able to tell</div>
                          </th>
                        </thead>
                        <tbody className="table-border">
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Commitment
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Displays a desire to contribute to the
                                organisation or wide community and has thought
                                though how to do this via his/her chosen career
                              </div>
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'blue' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'green' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'gray' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Knowledge(& Intellectual Qualities)
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Displays good general Knowledge (world events,
                                issues and related matters), analyse issues
                                objectively from multiple perspectives and form
                                sound judgement
                              </div>
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'blue' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'green' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'gray' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Innovativeness
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Displays curiosity and develops innovative
                                solutions
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <i
                                style={{ color: 'blue' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'green' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'gray' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                EQ & Empathy
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Displays an awareness of others perspectives and
                                reactios amd responds to them empathetically
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <i
                                style={{ color: 'blue' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'green' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'gray' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Communication Skills
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Organises his/her thoughts and expresses them in
                                a simple and clean manner
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <i
                                style={{ color: 'blue' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'green' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'gray' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Integrity (Accountabillity and Courage)
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Takes responsibility for his/her own actions and
                                stands up for his/her views
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <i
                                style={{ color: 'blue' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'green' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'gray' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Personal Impact
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Projects leadership presence and confidence -
                                able to persuade or convince others to support
                                an ideaa, agenda or direction
                              </div>
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'blue' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'green' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'gray' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Performance Under Pressure
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Remains calm and composed in the face of
                                difficult questions aor when challenged during
                                the interview
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <div>
                                  <i
                                    style={{ color: 'blue' }}
                                    className="fa fa-circle"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-2">
                                  <i
                                    style={{ color: 'green' }}
                                    className="fa fa-circle"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                <div className="mt-2">
                                  <i
                                    style={{ color: 'gray' }}
                                    className="fa fa-circle"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="mt-2 ml-2">
                                  <i
                                    style={{ color: 'yellow' }}
                                    className="fa fa-circle"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Self-belief
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Feels assured about him/herself, abilites,
                                potential and appears ready to take on
                                challenges
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <div>
                                <i
                                  style={{ color: 'blue' }}
                                  className="fa fa-circle"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-2">
                                <i
                                  style={{ color: 'gray' }}
                                  className="fa fa-circle"
                                  aria-hidden="true"
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                style={{ color: 'green' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="">
                            <td className="text-left">
                              <div className="font-weight-bold mb-2 h5">
                                Self-monitoring
                              </div>
                              <div style={{ fontSize: 'large' }}>
                                Monitors his/her own words and behavious and
                                adjusts them appropriately for the situation
                              </div>
                            </td>
                            <td></td>
                            <td>
                              <i
                                style={{ color: 'blue' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td>
                              <div>
                                <i
                                  style={{ color: 'green' }}
                                  className="fa fa-circle"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-2">
                                <i
                                  style={{ color: 'gray' }}
                                  className="fa fa-circle"
                                  aria-hidden="true"
                                />
                              </div>
                            </td>
                            <td>
                              <i
                                style={{ color: 'yellow' }}
                                className="fa fa-circle"
                                aria-hidden="true"
                              />
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="d-flex align-items-center mt-2">
                        <div>
                          <i
                            style={{ color: 'blue' }}
                            className="fa fa-circle"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="h6 ml-2">Vivian Tan</div>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <div>
                          <i
                            style={{ color: 'green' }}
                            className="fa fa-circle"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="h6 ml-2">Jonathan NP</div>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <div>
                          <i
                            style={{ color: 'gray' }}
                            className="fa fa-circle"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="h6 ml-2">Rebecca Salvester</div>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <div>
                          <i
                            style={{ color: 'yellow' }}
                            className="fa fa-circle"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="h6 ml-2">Noah Davis</div>
                      </div>
                    </div>
                  )}
                  <hr />
                  {this.state.showOtherContent === true && (
                    <div>
                      <h6>Final Rating</h6>
                      <div className="scatter-plot-height mt-2">
                        <NivoScatterGraph
                          data={[
                            {
                              id: 'Vivian Tan',
                              data: [
                                {
                                  x: 'Very Unsuitable - Courtesy Candidate',
                                  y: 0,
                                },
                                {
                                  x: 'Unsuitable',
                                  y: 1,
                                },
                                {
                                  x: 'Refer to Ministry/ Agencies',
                                  y: 0,
                                },
                                {
                                  x: 'To Award PSC Scholarship',
                                  y: 0,
                                },
                              ],
                            },
                            {
                              id: 'Jonathan Np',
                              data: [
                                {
                                  x: 'Very Unsuitable - Courtesy Candidate',
                                  y: 0,
                                },
                                {
                                  x: 'Unsuitable',
                                  y: 0,
                                },
                                {
                                  x: 'Refer to Ministry/ Agencies',
                                  y: 1,
                                },
                                {
                                  x: 'To Award PSC Scholarship',
                                  y: 0,
                                },
                              ],
                            },
                          ]}
                        />
                      </div>
                      <h5 className="mb-2 mt-2">Combined Overall Rating</h5>
                      <div className="font-weight-bold mb-2">
                        Overall Rating
                      </div>
                      <div className="mt-2 mb-2">
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            0
                          </span>{' '}
                          Very Unsuitable - Courtesy Candidate
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            2
                          </span>{' '}
                          Unsuitable &nbsp;
                          <i
                            style={{ color: 'green' }}
                            className="fa fa-circle"
                            aria-hidden="true"
                          />
                          &nbsp;{' '}
                          <i
                            style={{ color: 'gray' }}
                            className="fa fa-circle"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <span style={{ fontWeight: 'bold' }} className="pr-2">
                            2
                          </span>{' '}
                          Refer to Ministry/ Agencies &nbsp;
                          <i
                            style={{ color: 'blue' }}
                            className="fa fa-circle"
                            aria-hidden="true"
                          />
                          &nbsp;
                          <i
                            style={{ color: 'yellow' }}
                            className="fa fa-circle"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-2">
                          <div className="d-flex justify-content-left">
                            <span
                              style={{ fontWeight: 'bold' }}
                              className="pr-2">
                              0
                            </span>{' '}
                            To Award PSC Scholarship
                          </div>
                          <div className="d-flex justify-content-left mt-2 ml-5">
                            <span
                              style={{ fontWeight: 'bold' }}
                              className="pr-2">
                              0
                            </span>{' '}
                            To be considered for President's Scholarship
                          </div>
                          <div className="d-flex justify-content-left mt-2 ml-5">
                            <span
                              style={{ fontWeight: 'bold' }}
                              className="pr-2">
                              0
                            </span>{' '}
                            Reserve Candidate for President's Scholarship
                          </div>
                        </div>
                      </div>
                      <Accordion>
                        <AccordionItem
                          className="content-padding"
                          title={<h6>Vivian Tan</h6>}>
                          <div className="font-weight-bold mb-2">Comments</div>
                          <div>
                            <TextArea
                              rows={2}
                              value="Excellent Candidate, Very impressed"
                            />
                          </div>
                        </AccordionItem>
                        <AccordionItem
                          className="content-padding"
                          title={<h6>Jonathan NP</h6>}>
                          <div className="font-weight-bold mb-2">Comments</div>
                          <div>
                            <TextArea
                              rows={2}
                              value="Very good, impressed with his performance"
                            />
                          </div>
                        </AccordionItem>
                        <AccordionItem
                          className="content-padding"
                          title={<h6>Rebecca Salvatore</h6>}>
                          <div className="font-weight-bold mb-2">Comments</div>
                          <div>
                            <TextArea
                              rows={2}
                              value="Good, impressed with his performance"
                            />
                          </div>
                        </AccordionItem>
                        <AccordionItem
                          className="content-padding"
                          title={<h6>Noah Davis</h6>}>
                          <div className="font-weight-bold mb-2">Comments</div>
                          <div>
                            <TextArea
                              rows={2}
                              value="Good, impressed with his performance"
                            />
                          </div>
                        </AccordionItem>
                      </Accordion>
                      <h6 className="mt-2 mb-2">Final Outcome Section</h6>
                      <div className="mt-2 mb-2">
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton
                            onChange={() => {}}
                            labelText="Very Unsuitable - Courtesy Candidate"
                          />
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton
                            onChange={() => {}}
                            labelText="Unsuitable"
                          />
                        </div>
                        <div className="d-flex justify-content-left mt-2">
                          <RadioButton
                            onChange={() => {}}
                            labelText="Refer to Ministry/ Agencies"
                          />
                        </div>
                        <div className="mt-2">
                          <div className="d-flex justify-content-left">
                            <RadioButton
                              onChange={() => {}}
                              labelText="To Award PSC Scholarship"
                            />
                          </div>
                          <div className="d-flex justify-content-left mt-2 ml-5">
                            <RadioButton
                              onChange={() => {}}
                              labelText="To be considered for President's Scholarship"
                            />
                          </div>
                          <div className="d-flex justify-content-left mt-2 ml-5">
                            <RadioButton
                              onChange={() => {}}
                              labelText="Reserve Candidate for President's Scholarship"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="font-weight-bold">Comments</div>
                      <TextArea
                        rows={3}
                        placeholder="Please give overall comments"
                      />
                    </div>
                  )}
                </div>
              </Tab>
            </Tabs>
          </Tile> */}
        </div>
        <Modal
          open={this.state.isOpenModal}
          modalHeading={this.state.heading}
          modalLabel="Action"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          passiveModal
          iconDescription="Close the modal"
          onRequestClose={() => {
            this.setState({ isOpenModal: false, heading: '' });
          }}
          onRequestSubmit={() => {}}>
          <div>
            <h6>{this.state.definition}</h6>
          </div>
        </Modal>
        <Modal
          open={this.state.isOpenPsychologicalModal}
          modalHeading="Psychological Interview Report"
          modalLabel="Action"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          passiveModal
          iconDescription="Close the modal"
          onRequestClose={() => {
            this.setState({ isOpenPsychologicalModal: false });
          }}
          onRequestSubmit={() => {}}>
          <div className="font-weight-bold">Please view in a good reader</div>
        </Modal>
      </div>
    );
  }
}

export default MockUI2;
