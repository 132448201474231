import callApi from './callApi';

//Common urls for api calls
import { baseUrl } from '../utils/config';

//Action types for all api calls
import {
  REQUEST_GET_CANDIDATE_NOTES,
  REQUEST_CREATE_NEW_CANDIDATE_NOTE,
  REQUEST_UPSERT_CANDIDATE_NOTES,
  REQUEST_DELETE_CANDIDATE_NOTES,
} from './actionTypes';

//Get call for admin list information
const getCandidateNotesListByAppId = (appId) => (dispatch) => {
  if (!appId) {
    return Promise.reject(new Error('Invalid application id.'));
  }
  const url = `${baseUrl}/candidatenotes/${appId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_CANDIDATE_NOTES,
  };
  return callApi(params);
};

const createNewNote = (data) => (dispatch) => {
  const { notes, appId } = data || {};
  if (!notes || !appId) {
    return Promise.reject(new Error('Invalid details sent.'));
  }
  const url = `${baseUrl}/candidatenotes?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_CREATE_NEW_CANDIDATE_NOTE,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const deleteNotesData = (noteId) => (dispatch) => {
  if (!noteId) {
    return Promise.reject(new Error('Invalid note details.'));
  }
  const url = `${baseUrl}/candidatenotes/delete/${noteId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_DELETE_CANDIDATE_NOTES,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return callApi(params);
};

const upsertNotesData = (noteId, data) => (dispatch) => {
  if (!noteId) {
    return Promise.reject(new Error('Invalid panel details.'));
  }
  const url = `${baseUrl}/candidatenotes/patch/${noteId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPSERT_CANDIDATE_NOTES,
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(params);
};

export {
  getCandidateNotesListByAppId,
  createNewNote,
  deleteNotesData,
  upsertNotesData,
};
