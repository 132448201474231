import {
  REQUEST_GET_COMPARE_APPL_FIELDS,
  SUCCESS_GET_COMPARE_APPL_FIELDS,
  ERROR_GET_COMPARE_APPL_FIELDS,
  REQUEST_UPSERT_COMPARE_APPL_FIELDS,
  SUCCESS_UPSERT_COMPARE_APPL_FIELDS,
  ERROR_UPSERT_COMPARE_APPL_FIELDS,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  list: [],
};

const CompareApplications = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_COMPARE_APPL_FIELDS:
      return {
        ...state,

        loading: true,
        error: null,
      };
    case SUCCESS_GET_COMPARE_APPL_FIELDS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_COMPARE_APPL_FIELDS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_COMPARE_APPL_FIELDS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_COMPARE_APPL_FIELDS:
      return {
        ...state,
        list: payload,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_COMPARE_APPL_FIELDS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default CompareApplications;
