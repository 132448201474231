import React, { Fragment } from 'react';
import { WarningAlt32 } from '@carbon/icons-react';
import moment from 'moment';
import PublicHeader from '../../headers/PublicHeader';

const ScheduleMaintenance = (props) => {
  const { user, history } = props;
  const { scheduledmaintainanceDetails } = user || {};
  const { message, fromDate, toDate } = scheduledmaintainanceDetails || {};
  const { push } = history || {};
  return (
    <Fragment>
      <PublicHeader push={push} />
      <div className="d-flex justify-content-center align-items-center flex-column xpa-vh-100">
        <div
          style={{ height: '350px' }}
          className="bx--tile text-center p-4 w-50 d-flex justify-content-center align-items-center flex-column">
          <div>
            <div className="mb-3">
              <WarningAlt32 fill="red" width="48px" height="48px" />
            </div>
            <h3 className="f-w-b text-uppercase">Schedule Maintenance</h3>
            <h6 className="mt-3">
              Schedule maintenance from{' '}
              {fromDate && moment(fromDate).format('DD/MM/YYYY, h:mm a')} to{' '}
              {toDate && moment(toDate).format('DD/MM/YYYY, h:mm a')}
            </h6>
            <div className="mt-3 f-w-b">{message}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ScheduleMaintenance;
