import callApi from './callApi';

//Common urls for api calls
import { regularUrl, baseUrl, v2BaseUrl } from '../utils/config';

//Action types for all api calls
import {
  REQUEST_UPLOAD_REPORTS_CSV,
  REQUEST_GET_REPORT_LOGS,
  REQUEST_GENERATE_HIGH_LVL_REPORTS,
  REQUEST_GET_TEST_NORM_DETAILS,
  REQUEST_GET_TEST_DETAILS,
  REQUEST_GENERATE_CELERY_TASK_STATUS,
  REQUEST_DOWNLOAD_REPORTS_DATA,
  REQUEST_GET_DEFAULT_NORM_DETAILS,
  REQUEST_UPLOAD_CANDIDATE_NRICS,
  REQUEST_UPLOAD_PSYCHOLOGICAL_RATING,
  REQUEST_UPLOAD_ASSESSMENT_CENTER_DATA,
  REQUEST_UPLOAD_PEER_RATINGS_DATA,
  REQUEST_UPLOAD_PS_BRIEFING_DECK_DOC,
} from './actionTypes';

const uploadHighLevelReports = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/importcsc?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_REPORTS_CSV,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

//Get call for report list information
const getHighLevelReportLogs = (data) => (dispatch) => {
  const { activePage, itemsPerPage, searchKey, typeId, type, report_name } =
    data || {};
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  let url = `${baseUrl}/reportdocuments?_=${new Date().getTime()}`;
  url += `&filter[limit]=${itemsPerPage}`;
  url += `&filter[skip]=${skip}`;
  if (searchKey) url += `&searchKey=${searchKey}`;
  if (typeId) url += `&typeId=${typeId}`;
  if (type) url += `&type=${type}`;
  if (report_name) url += `&report_name=${report_name}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_REPORT_LOGS,
  };
  return callApi(params);
};

const generateHighLvlReports = (type, data, userId) => (dispatch) => {
  if (!type) {
    const error = {
      msg: 'Invalid report type',
    };
    return Promise.reject(error);
  }
  let url = `${baseUrl}/generatereports/${type}?_=${new Date().getTime()}&x0pa-user-id=${userId}`;
  const params = {
    headers: {
      'content-type': 'application/json',
      'x0pa-user-id': userId,
    },
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_GENERATE_HIGH_LVL_REPORTS,
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const generateCeleryTask = (taskId) => (dispatch) => {
  if (!taskId) {
    const error = {
      msg: 'Invalid report type',
    };
    return Promise.reject(error);
  }
  let url = `${baseUrl}/generatereports/status/${taskId}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GENERATE_CELERY_TASK_STATUS,
  };
  return callApi(params);
};

//Get call for report list information
const getTestNormDetails = () => (dispatch) => {
  let url = `${baseUrl}/testnorm?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_TEST_NORM_DETAILS,
  };
  return callApi(params);
};

//Get call for report list information
const getTestsDetails = () => (dispatch) => {
  let url = `${baseUrl}/tests?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_TEST_DETAILS,
  };
  return callApi(params);
};

const downloadHighLevelReports = (type, data) => (dispatch) => {
  if (!type) {
    const error = {
      msg: 'Invalid report type',
    };
    return Promise.reject(error);
  }
  let url = `${baseUrl}/generatereports/${type}?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    actionType: REQUEST_DOWNLOAD_REPORTS_DATA,
    body: JSON.stringify(data),
    responseType: 'application/pdf',
  };
  return callApi(params);
};

//Get call for defualt norm information
const getDefaultNorms = () => (dispatch) => {
  let url = `${baseUrl}/defaultnorms?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_GET_DEFAULT_NORM_DETAILS,
  };
  return callApi(params);
};

const uploadCandidateNrics = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/importnric?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_CANDIDATE_NRICS,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const uploadPsychologicalRatings = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/importpi?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_PSYCHOLOGICAL_RATING,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const uploadAssessmentCenterData = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/importac?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_ASSESSMENT_CENTER_DATA,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const uploadPeerRatingsData = (data) => (dispatch) => {
  const url = `${regularUrl}/upload/importpr?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_PEER_RATINGS_DATA,
    url,
    body: data,
    method: 'POST',
  };
  return callApi(params);
};

const uploadPSBriefingDeckDoc = (data) => (dispatch) => {
  const url = `${v2BaseUrl}/commonHelper/file/ps-briefing-deck?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    actionType: REQUEST_UPLOAD_PS_BRIEFING_DECK_DOC,
    url,
    method: 'POST',
    body: data,
  };
  return callApi(params);
};

export {
  uploadHighLevelReports,
  getHighLevelReportLogs,
  generateHighLvlReports,
  getTestNormDetails,
  getTestsDetails,
  generateCeleryTask,
  downloadHighLevelReports,
  getDefaultNorms,
  uploadCandidateNrics,
  uploadPsychologicalRatings,
  uploadAssessmentCenterData,
  uploadPeerRatingsData,
  uploadPSBriefingDeckDoc,
};
