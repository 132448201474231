import React from 'react';

import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import { Home32, Globe32 } from '@carbon/icons-react';

const CandUniversityScholaship = (props) => {
  const { universityAppln, scholarship } = props || {};
  let finalScholarshipData = [];
  if (scholarship && Array.isArray(scholarship) && scholarship.length > 0) {
    finalScholarshipData = scholarship.filter((data) => {
      const { outcome, company, subject } = data || {};
      const { displayName } = company || {};
      const { displayName: subjDisplayName } = subject || {};
      if (outcome || displayName || subjDisplayName) {
        return true;
      }
      return false;
    });
  }
  return (
    <div>
      <div>
        <div className="font-weight-bold h6 mt-3 mb-3">
          University and Scholarship Applications
        </div>
        <GenericDataTable
          otherActions={[]}
          searchable={false}
          rows={
            universityAppln &&
            Array.isArray(universityAppln) &&
            universityAppln.length > 0
              ? universityAppln.map((data, idx) => {
                  const {
                    applnOutcome,
                    company,
                    subject,
                    universityapplnId,
                    isOverseasUniversity,
                    rank,
                    course2Id,
                    otherCourse1Id,
                    otherCourse2Id,
                    otherUniversityIdRel,
                  } = data || {};
                  const { displayName, companyName } = company || {};
                  const { displayName: subjDisplayName, subject: course } =
                    subject || {};
                  const { displayName: otherCourse } = otherCourse1Id || {};
                  const { subject: course2, displayName: course2DisplayName } =
                    course2Id || {};
                  const { displayName: otherCourse2 } = otherCourse2Id || {};
                  const { displayName: otherUniversity } =
                    otherUniversityIdRel || {};
                  let finalCourse = '';
                  if (course === 'others') {
                    finalCourse = otherCourse;
                  } else {
                    finalCourse = subjDisplayName;
                  }
                  let finalCourse2 = '';
                  if (course2 === 'others') {
                    finalCourse2 = otherCourse2;
                  } else {
                    finalCourse2 = course2DisplayName;
                  }
                  let finalUniversityName = '';
                  if (companyName === 'others') {
                    finalUniversityName = otherUniversity;
                  } else {
                    finalUniversityName = displayName
                      ? displayName
                      : companyName;
                  }
                  return {
                    isExpanded: false,
                    id: universityapplnId,
                    header_0: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Rank:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className=" text-left">{rank || '-'}</div>
                        </div>
                      </div>
                    ),
                    header_1: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Local/Overseas:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div
                            title={
                              isOverseasUniversity === true
                                ? 'Overseas'
                                : isOverseasUniversity === false
                                ? 'Local'
                                : ''
                            }
                            className="text-lg-center">
                            {isOverseasUniversity === true ? (
                              <Globe32 />
                            ) : isOverseasUniversity === false ? (
                              <Home32 />
                            ) : (
                              '-'
                            )}
                          </div>
                        </div>
                      </div>
                    ),
                    header_2: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          University:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className="d-lg-flex justify-content-center align-items-center text-capitalize">
                            {finalUniversityName || '-'}
                          </div>
                        </div>
                      </div>
                    ),
                    header_3: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Course:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className=" text-lg-center text-capitalize">
                            {finalCourse || '-'}
                          </div>
                        </div>
                      </div>
                    ),
                    header_4: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Course 2:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className=" text-lg-center text-capitalize">
                            {finalCourse2 || '-'}
                          </div>
                        </div>
                      </div>
                    ),
                    header_5: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Application Outcome:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className=" text-lg-center text-capitalize">
                            {applnOutcome || '-'}
                          </div>
                        </div>
                      </div>
                    ),
                  };
                })
              : []
          }
          headers={[
            {
              key: 'header_0',
              header: 'Rank',
              className: 'text-left',
            },
            {
              key: 'header_1',
              header: 'Local/Overseas',
              className: ' text-center',
            },
            {
              key: 'header_2',
              header: 'University',
              className: ' text-center',
            },
            {
              key: 'header_3',
              header: 'Course',
              className: ' text-center',
            },
            {
              key: 'header_4',
              header: 'Course 2',
              className: ' text-center',
            },
            {
              key: 'header_5',
              header: 'Application Outcome',
              className: ' text-center',
            },
          ]}
        />
        {((universityAppln &&
          Array.isArray(universityAppln) &&
          universityAppln.length <= 0) ||
          !universityAppln) && (
          <NoContentBox message="There are no university scholarships for your current selection" />
        )}
      </div>
      <div>
        <div className="font-weight-bold mb-3 h6 mt-3">
          Other Scholarship Applications
        </div>
        <GenericDataTable
          otherActions={[]}
          searchable={false}
          rows={
            finalScholarshipData &&
            Array.isArray(finalScholarshipData) &&
            finalScholarshipData.length > 0
              ? finalScholarshipData.map((data, idx) => {
                  const {
                    outcome,
                    company,
                    subject,
                    otherscholarshipapplnId,
                    otherCourseId,
                    otherOrgId,
                  } = data || {};
                  const { displayName, companyName } = company || {};
                  const { displayName: subjDisplayName, subject: course } =
                    subject || {};
                  const { displayName: otherCourse } = otherCourseId || {};
                  const { displayName: otherUniv } = otherOrgId || {};
                  let finalCourse = '';
                  if (course === 'others') {
                    finalCourse = otherCourse;
                  } else {
                    finalCourse = subjDisplayName;
                  }
                  let finalUniv = '';
                  if (companyName === 'others') {
                    finalUniv = otherUniv;
                  } else {
                    finalUniv = displayName;
                  }
                  return {
                    isExpanded: false,
                    id: otherscholarshipapplnId,
                    header_0: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          S/No:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          {idx + 1}
                        </div>
                      </div>
                    ),
                    header_1: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Organisation:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className="d-lg-flex justify-content-center align-items-center text-capitalize">
                            {finalUniv || '-'}
                          </div>
                        </div>
                      </div>
                    ),
                    header_2: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Course:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className="text-lg-center text-capitalize">
                            {finalCourse || '-'}
                          </div>
                        </div>
                      </div>
                    ),
                    header_3: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Application Outcome:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6 text-capitalize">
                          <div className="text-lg-center text-capitalize">
                            {outcome || '-'}
                          </div>
                        </div>
                      </div>
                    ),
                  };
                })
              : []
          }
          headers={[
            {
              key: 'header_0',
              header: 'S/No',
              className: 'w-10  text-left',
            },
            {
              key: 'header_1',
              header: 'Organisation',
              className: 'w-25  text-center',
            },
            {
              key: 'header_2',
              header: 'Course',
              className: 'w-25  text-center',
            },
            {
              key: 'header_3',
              header: 'Application Outcome',
              className: 'w-25  text-center',
            },
          ]}
        />
        {((finalScholarshipData &&
          Array.isArray(finalScholarshipData) &&
          finalScholarshipData.length <= 0) ||
          !finalScholarshipData) && (
          <NoContentBox message="There are no other scholarship applications details for your current selection" />
        )}
      </div>
    </div>
  );
};

export default CandUniversityScholaship;
