import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//Api Actions
import {
  generateCeleryTask,
  downloadHighLevelReports,
} from '../../actions/HighLevelReports';

//Common packages
import queryString from 'query-string';

//Css Files
import './Reports.css';

const PsychologicalDataTable = (props) => {
  const { data, isOutcomeData, selectorId, showHeader } = props || {};
  return (
    <table className="w-100">
      {showHeader === true ? (
        <Fragment>
          <thead>
            <th className="css-pslp-neo-th-f-sz text-right pr-4">T Score</th>
            <th className="css-pslp-neo-th-f-sz">20</th>
            <th className="css-pslp-neo-trait-f-sz-sub">25</th>
            <th className="css-pslp-neo-th-f-sz ">30</th>
            <th className="css-pslp-neo-trait-f-sz-sub">35</th>
            <th className="css-pslp-neo-th-f-sz">40</th>
            <th className="css-pslp-neo-trait-f-sz-sub">45</th>
            <th className="css-pslp-neo-th-f-sz">50</th>
            <th className="css-pslp-neo-trait-f-sz-sub">55</th>
            <th className="css-pslp-neo-th-f-sz">60</th>
            <th className="css-pslp-neo-trait-f-sz-sub">65</th>
            <th className="css-pslp-neo-th-f-sz">70</th>
            <th className="css-pslp-neo-trait-f-sz-sub">75</th>
            <th className=" css-pslp-neo-th-f-sz">80</th>
          </thead>
          <thead>
            <th className="css-psp-neo-th-trait-w text-right pr-5 css-pslp-neo-th-f-sz"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-main-b"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-psp-neo-td-score-border-sub-s"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-main-b"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-psp-neo-td-score-border-sub-s"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-main-b"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-psp-neo-td-score-border-sub-s"></th>
            <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
            <th className=" css-pslp-neo-th-f-sz css-pslp-neo-th-main-b"></th>
          </thead>
        </Fragment>
      ) : (
        <thead>
          <th className="css-psp-neo-th-trait-w-sub text-right pr-5 css-pslp-neo-th-f-sz"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-main-b"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-psp-neo-td-score-border-sub-s"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-main-b"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-psp-neo-td-score-border-sub-s"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-main-b"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-psp-neo-td-score-border-sub-s"></th>
          <th className="css-psp-neo-th-score-w css-pslp-neo-th-f-sz css-pslp-neo-th-sub-b-dash"></th>
          <th className=" css-pslp-neo-th-f-sz css-pslp-neo-th-main-b"></th>
        </thead>
      )}

      <tbody>
        {data.map((res, idx) => {
          const { x, y } = res || {};
          let bgColor = 'white';
          if (isOutcomeData) {
            if (idx === 0 || idx === 2 || idx === 4) {
              bgColor = '#eff7fb';
            }
          } else {
            if (idx <= 5) {
              bgColor = '#eff7fb';
            } else if (idx > 11 && idx <= 17) {
              bgColor = '#eff7fb';
            } else if (idx > 23) {
              bgColor = '#eff7fb';
            }
          }

          return (
            <tr
              className="css-pslp-nro-row-h"
              style={{ backgroundColor: bgColor }}>
              <td className="text-right pr-4 css-pslp-neo-trait-f-sz vertical-align-middle">
                {y}
              </td>
              <td
                className={`css-psp-neo-td-score-border-s vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x <= 25 && (
                    <div
                      className={`${selectorId} ${
                        x === 25
                          ? 'css-psl-neo-l'
                          : x > 23
                          ? 'css-pslp-noe-plot-r'
                          : x < 22 && x !== 20
                          ? 'css-pslp-noe-plot-l'
                          : x === 22
                          ? 'css-pslp-noe-plot-l-one'
                          : x === 20
                          ? 'css-pslp-noe-plot-l-20'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-d vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 25 && x <= 30 && (
                    <div
                      className={`${selectorId} ${
                        x === 30
                          ? 'css-psl-neo-l'
                          : x > 28
                          ? 'css-pslp-noe-plot-r'
                          : x < 27
                          ? 'css-pslp-noe-plot-l'
                          : x === 27
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-sub-s vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 30 && x <= 35 && (
                    <div
                      className={`${selectorId} ${
                        x === 35
                          ? 'css-psl-neo-l'
                          : x > 33
                          ? 'css-pslp-noe-plot-r'
                          : x < 32
                          ? 'css-pslp-noe-plot-l'
                          : x === 32
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-d vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 35 && x <= 40 && (
                    <div
                      className={`${selectorId} ${
                        x === 40
                          ? 'css-psl-neo-l'
                          : x > 38
                          ? 'css-pslp-noe-plot-r'
                          : x < 37
                          ? 'css-pslp-noe-plot-l'
                          : x === 37
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>

              <td
                className={`css-psp-neo-td-score-border-s vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 40 && x <= 45 && (
                    <div
                      className={`${selectorId} ${
                        x === 45
                          ? 'css-psl-neo-l'
                          : x > 43
                          ? 'css-pslp-noe-plot-r'
                          : x < 42
                          ? 'css-pslp-noe-plot-l'
                          : x === 42
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-d vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 45 && x <= 50 && (
                    <div
                      className={`${selectorId} ${
                        x === 50
                          ? 'css-psl-neo-l'
                          : x > 48
                          ? 'css-pslp-noe-plot-r'
                          : x < 47
                          ? 'css-pslp-noe-plot-l'
                          : x === 47
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-sub-s vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 50 && x <= 55 && (
                    <div
                      className={`${selectorId} ${
                        x === 55
                          ? 'css-psl-neo-l'
                          : x > 53
                          ? 'css-pslp-noe-plot-r'
                          : x < 52
                          ? 'css-pslp-noe-plot-l'
                          : x === 52
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-d vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 55 && x <= 60 && (
                    <div
                      className={`${selectorId} ${
                        x === 60
                          ? 'css-psl-neo-l'
                          : x > 58
                          ? 'css-pslp-noe-plot-r'
                          : x < 57
                          ? 'css-pslp-noe-plot-l'
                          : x === 57
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-s vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 60 && x <= 65 && (
                    <div
                      className={`${selectorId} ${
                        x === 65
                          ? 'css-psl-neo-l'
                          : x > 63
                          ? 'css-pslp-noe-plot-r'
                          : x < 62
                          ? 'css-pslp-noe-plot-l'
                          : x === 62
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-d vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 65 && x <= 70 && (
                    <div
                      className={`${selectorId} ${
                        x === 70
                          ? 'css-psl-neo-l'
                          : x > 68
                          ? 'css-pslp-noe-plot-r'
                          : x < 67
                          ? 'css-pslp-noe-plot-l'
                          : x === '67'
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-sub-s vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 70 && x <= 75 && (
                    <div
                      className={`${selectorId} ${
                        x === 75
                          ? 'css-psl-neo-l'
                          : x > 73
                          ? 'css-pslp-noe-plot-r'
                          : x < 72
                          ? 'css-pslp-noe-plot-l'
                          : x === 72
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td
                className={`css-psp-neo-td-score-border-d vertical-align-middle text-center ${
                  idx === data.length - 1 && 'css-psp-neo-th-score-w'
                }`}>
                <div className="css-pslp-neo-td-b position-relative">
                  {x > 75 && (
                    <div
                      className={`${selectorId} ${
                        x === 80
                          ? 'css-psl-neo-l'
                          : x > 78
                          ? 'css-pslp-noe-plot-r'
                          : x < 77
                          ? 'css-pslp-noe-plot-l'
                          : x === 77
                          ? 'css-pslp-noe-plot-l-one'
                          : ''
                      } css-pos-abs-point`}>
                      <i
                        className="diamond-fill-points fa fa-circle-o"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </td>
              <td className=" css-psp-neo-td-score-border-s vertical-align-middle text-center">
                <div className=""></div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

// const personalityData = [
//   {
//     id: '',
//     color: '#4281cc',
//     data: [
//       {
//         x: 8,
//         y: 'Reserved, Distant Cool',
//       },
//       {
//         x: 7,
//         y: 'Introverted, Prefers, Privacy',
//       },
//       {
//         x: 4,
//         y: 'Non Assertive, A Friendly, Acquiescent, Passive',
//       },
//       {
//         x: 6,
//         y: 'Sedentary, slow-Paced, Inactive',
//       },
//       {
//         x: 7,
//         y: 'Conventional, Uncomplicated Needs, Avoid Risks',
//       },
//       {
//         x: 9,
//         y: 'Serious, Sober, Lacking in Enthusiasm',
//       },
//     ],
//   },
// ];

const personalityProfileData = {
  full_name: 'shubhankit x0pa',
  neopi_band: {
    A: 'VL',
    A1: 'ML',
    A2: 'VL',
    A3: 'VL',
    A4: 'ML',
    A5: 'ML',
    A6: 'VL',
    C: 'L',
    C1: 'ML',
    C2: 'L',
    C3: 'ML',
    C4: 'L',
    C5: 'L',
    C6: 'ML',
    E: 'ML',
    E1: 'ML',
    E2: 'MH',
    E3: 'ML',
    E4: 'MH',
    E5: 'ML',
    E6: 'ML',
    N: 'MH',
    N1: 'MH',
    N2: 'MH',
    N3: 'H',
    N4: 'ML',
    N5: 'MH',
    N6: 'MH',
    O: 'ML',
    O1: 'MH',
    O2: 'ML',
    O3: 'ML',
    O4: 'ML',
    O5: 'ML',
    O6: 'ML',
  },
  neopi_score: {
    A: 24,
    A1: 41,
    A2: 29,
    A3: 20,
    A4: 41,
    A5: 44,
    A6: 24,
    C: 38,
    C1: 41,
    C2: 39,
    C3: 42,
    C4: 38,
    C5: 36,
    C6: 50,
    E: 49,
    E1: 44,
    E2: 51,
    E3: 49,
    E4: 57,
    E5: 47,
    E6: 49,
    N: 56,
    N1: 51,
    N2: 59,
    N3: 62,
    N4: 46,
    N5: 54,
    N6: 55,
    O: 44,
    O1: 58,
    O2: 42,
    O3: 45,
    O4: 40,
    O5: 46,
    O6: 44,
  },
  test_date: '2020-11-23',
};

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.svgRef = React.createRef();
    this.pathRef = React.createRef();
    this.personalityPathRef = React.createRef();
    this.personalitySvgRef = React.createRef();
    this.cultSvgRef = React.createRef();
    this.cultPathRef = React.createRef();
  }

  componentDidMount() {
    const { location: { search } = {}, match } = this.props;
    const { params } = match || {};
    const { status } = params || {};
    const parsedQuery = search && queryString.parse(search);
    const { task_id } = parsedQuery || {};
    if (task_id) {
      if (
        status === 'psychological-profile' ||
        status === 'psychological-profile-outcome'
      ) {
        this.handleGetPsychologicalReport(task_id);
      } else if (status === 'cultural-report') {
        this.handleGetCulturalReport(task_id);
      } else {
        this.handleGetPersonalityProfileReport(task_id);
      }
    }
    this.handleGetRes = window.addEventListener(
      'resize',
      this.handleGetLineXPoints
    );
    this.handleGetPersRes = window.addEventListener(
      'resize',
      this.handleGetPersonalityXLinePoints
    );
    this.handleGetCultRes = window.addEventListener(
      'resize',
      this.handleGetCultXPoints
    );
    this.handleGetLineXPoints();
    this.handleGetPersonalityXLinePoints();
    this.handleGetCultXPoints();
  }

  componentWillUnmount() {
    if (this.handleGetRes) {
      window.removeEventListener(this.handleGetLineXPoints);
    } else if (this.handleGetPersRes) {
      window.removeEventListener(this.handleGetPersonalityXLinePoints);
    } else if (this.handleGetCultRes) {
      window.removeEventListener(this.handleGetCultXPoints);
    }
  }

  handleGetLineXPoints = () => {
    const xpoints = document.querySelectorAll('.x-row-point');
    let pathStr = '';
    xpoints.forEach((point, i) => {
      const { top, left } = this.getOffset(point);
      if (i === 0) {
        pathStr += `M ${left},${top} `;
      } else {
        pathStr += `L ${left},${top} `;
      }
    });
    // const { top: ddd } = this.getOffset(
    //   document.querySelector('.x-table-body')
    // );
    const svgDocSelec = this.svgRef.current;
    if (svgDocSelec) {
      svgDocSelec.setAttribute(
        'style',
        `height:100%;position: absolute;top: 9px;z-index: 1000;left: 4px;right: 0`
      );
    }
    const pathSelector = this.pathRef.current;
    if (pathSelector) {
      pathSelector.setAttribute('d', pathStr);
    }
  };

  handleGetCultXPoints = () => {
    const rowPoints = document.querySelectorAll('.cult-row-points-0');
    const rowPoints1 = document.querySelectorAll('.cult-row-points-1');
    const rowPoints2 = document.querySelectorAll('.cult-row-points-2');
    const rowPoints3 = document.querySelectorAll('.cult-row-points-3');
    const rowPoints4 = document.querySelectorAll('.cult-row-points-4');
    const rowPoints5 = document.querySelectorAll('.cult-row-points-5');
    let rowPathStr = '';
    const { top: zeroTop } = this.getOffset(rowPoints[0]);
    rowPoints.forEach((point, i) => {
      const { top, left } = this.getOffset(point);
      if (i === 0) {
        rowPathStr += `M ${left},${zeroTop} `;
      } else {
        rowPathStr += `L ${left},${zeroTop} `;
      }
    });
    const { top: oneTop } = this.getOffset(rowPoints1[0]);
    rowPoints1.forEach((point, i) => {
      const { top, left } = this.getOffset(point);
      if (i === 0) {
        rowPathStr += `M ${left},${oneTop} `;
      } else {
        rowPathStr += `L ${left},${oneTop} `;
      }
    });
    const { top: twoTop } = this.getOffset(rowPoints2[0]);
    rowPoints2.forEach((point, i) => {
      const { top, left } = this.getOffset(point);
      if (i === 0) {
        rowPathStr += `M ${left},${twoTop} `;
      } else {
        rowPathStr += `L ${left},${twoTop} `;
      }
    });
    const { top: threeTop } = this.getOffset(rowPoints3[0]);
    rowPoints3.forEach((point, i) => {
      const { top, left } = this.getOffset(point);
      if (i === 0) {
        rowPathStr += `M ${left},${threeTop} `;
      } else {
        rowPathStr += `L ${left},${threeTop} `;
      }
    });
    const { top: fourTop } = this.getOffset(rowPoints4[0]);
    rowPoints4.forEach((point, i) => {
      const { top, left } = this.getOffset(point);
      if (i === 0) {
        rowPathStr += `M ${left},${fourTop} `;
      } else {
        rowPathStr += `L ${left},${fourTop} `;
      }
    });
    const { top: fiveTop } = this.getOffset(rowPoints5[0]);
    rowPoints5.forEach((point, i) => {
      const { top, left } = this.getOffset(point);
      if (i === 0) {
        rowPathStr += `M ${left},${fiveTop} `;
      } else {
        rowPathStr += `L ${left},${fiveTop} `;
      }
    });
    const svgDocSelec = this.cultSvgRef.current;
    if (svgDocSelec) {
      svgDocSelec.setAttribute(
        'style',
        `height:100%;position: absolute;top: 9px;z-index: 1000;left: 0;right: 0`
      );
    }
    const pathSelector = this.cultPathRef.current;
    if (pathSelector) {
      pathSelector.setAttribute('d', rowPathStr);
    }
  };

  handleGetPersonalityXLinePoints = () => {
    const xpoints = document.querySelectorAll('.p-x-row-point');
    let pathStr = '';
    xpoints.forEach((point, i) => {
      const { top, left } = this.getOffset(point);
      if (i === 0) {
        pathStr += `M ${left},${top} `;
      } else {
        pathStr += `L ${left},${top} `;
      }
    });
    // const { top: ddd } = this.getOffset(
    //   document.querySelector('.x-table-body')
    // );
    const svgDocSelec = this.personalitySvgRef.current;
    if (svgDocSelec) {
      svgDocSelec.setAttribute(
        'style',
        `height:100%;position: absolute;top: 11px;z-index: 1000;left: 4px;right: 0`
      );
    }
    const personalityPathRef = this.personalityPathRef.current;
    if (personalityPathRef) {
      personalityPathRef.setAttribute('d', pathStr);
    }
  };

  getOffset = (el) => {
    if (!el) {
      return {};
    }
    const box = el.getBoundingClientRect();
    return {
      top: box.top,
      left: box.left,
    };
  };

  handleGetLineGraphData = (neopi_score, finalData) => {
    const keys = neopi_score && Object.keys(neopi_score);
    const neuroticismData = [];
    const extraversionData = [];
    const opennessData = [];
    const agreeablenessData = [];
    const conscientiousnessData = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      keys.forEach((graph) => {
        if (graph === 'N1') {
          neuroticismData.push({
            x: neopi_score[graph],
            y: `Anxiety (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'N2') {
          neuroticismData.push({
            x: neopi_score[graph],
            y: `Frustration (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'N3') {
          neuroticismData.push({
            x: neopi_score[graph],
            y: `Despondency (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'N4') {
          neuroticismData.push({
            x: neopi_score[graph],
            y: `Self-Consciousness (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'N5') {
          neuroticismData.push({
            x: neopi_score[graph],
            y: `Impulsivity (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'N6') {
          neuroticismData.push({
            x: neopi_score[graph],
            y: `Stress Prone (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'E1') {
          extraversionData.push({
            x: neopi_score[graph],
            y: `Friendliness (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'E2') {
          extraversionData.push({
            x: neopi_score[graph],
            y: `Sociability (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'E3') {
          extraversionData.push({
            x: neopi_score[graph],
            y: `Assertiveness (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'E4') {
          extraversionData.push({
            x: neopi_score[graph],
            y: `Pace of Living (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'E5') {
          extraversionData.push({
            x: neopi_score[graph],
            y: `Excitement Seeking (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'E6') {
          extraversionData.push({
            x: neopi_score[graph],
            y: `Positive Outlook (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'O1') {
          opennessData.push({
            x: neopi_score[graph],
            y: `Openness to Imagination (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'O2') {
          opennessData.push({
            x: neopi_score[graph],
            y: `Openness to Aesthetics (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'O3') {
          opennessData.push({
            x: neopi_score[graph],
            y: `Openness to Feelings (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'O4') {
          opennessData.push({
            x: neopi_score[graph],
            y: `Openness to New Activities (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'O5') {
          opennessData.push({
            x: neopi_score[graph],
            y: `Openness to Ideas (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'O6') {
          opennessData.push({
            x: neopi_score[graph],
            y: `Openness to Values (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'A1') {
          agreeablenessData.push({
            x: neopi_score[graph],
            y: `Trust (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'A2') {
          agreeablenessData.push({
            x: neopi_score[graph],
            y: `Straightforwardness (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'A3') {
          agreeablenessData.push({
            x: neopi_score[graph],
            y: `Altruism (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'A4') {
          agreeablenessData.push({
            x: neopi_score[graph],
            y: `Compliance (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'A5') {
          agreeablenessData.push({
            x: neopi_score[graph],
            y: `Modesty (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'A6') {
          agreeablenessData.push({
            x: neopi_score[graph],
            y: `Compassion (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'C1') {
          conscientiousnessData.push({
            x: neopi_score[graph],
            y: `Sense of Mastery (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'C2') {
          conscientiousnessData.push({
            x: neopi_score[graph],
            y: `Order (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'C3') {
          conscientiousnessData.push({
            x: neopi_score[graph],
            y: `Dutifulness (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'C4') {
          conscientiousnessData.push({
            x: neopi_score[graph],
            y: `Achievement Striving (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'C5') {
          conscientiousnessData.push({
            x: neopi_score[graph],
            y: `Self-Discipline (${graph} = ${neopi_score[graph]})`,
          });
        } else if (graph === 'C6') {
          conscientiousnessData.push({
            x: neopi_score[graph],
            y: `Deliberation (${graph} = ${neopi_score[graph]})`,
          });
        }
      });
    }
    finalData.data.push(...neuroticismData);
    finalData.data.push(...extraversionData);
    finalData.data.push(...opennessData);
    finalData.data.push(...agreeablenessData);
    finalData.data.push(...conscientiousnessData);
    return finalData;
  };

  handleGetCulturalReport = (task_id) => {
    const interval = setInterval(() => {
      this.props.generateCeleryTask(task_id).then((res) => {
        if (res && !res.error) {
          const { celeryData } = this.props;
          const { cpineo_desc, cpineo } = celeryData || {};
          const {
            Connection: connDesc,
            Harmony: harDesc,
            Innovation: innDesc,
            Outcomes: outDesc,
            Stability: stabDesc,
            Structure: strDesc,
          } = cpineo_desc || {};
          const {
            Connection,
            Harmony,
            Innovation,
            Outcomes,
            Stability,
            Structure,
          } = cpineo || {};
          const finalData = [
            {
              metric: 'Results-Focused',
              lowDesc:
                'Focus on processes over endresults, Pay attention to how outcomes are achieved, Flexible on deadlines and agreed deliverables',
              highDesc:
                'End-results over processes, Outcomes oriented, Strive for ambitious standards, Firm on deadlines and agreed deliverables',
              veryLowMinPointOne:
                Outcomes && Outcomes['min'] <= 25 ? Outcomes['min'] : '',
              veryLowMinPointTwo:
                Outcomes && Outcomes['min'] > 25 && Outcomes['min'] <= 30
                  ? Outcomes['min']
                  : '',
              lowMinPointOne:
                Outcomes && Outcomes['min'] > 30 && Outcomes['min'] <= 35
                  ? Outcomes['min']
                  : '',
              lowMinPointTwo:
                Outcomes && Outcomes['min'] > 35 && Outcomes['min'] <= 40
                  ? Outcomes['min']
                  : '',
              moderateLowMinPointOne:
                Outcomes && Outcomes['min'] > 40 && Outcomes['min'] <= 45
                  ? Outcomes['min']
                  : '',
              moderateLowMinPointTwo:
                Outcomes && Outcomes['min'] > 45 && Outcomes['min'] <= 50
                  ? Outcomes['min']
                  : '',
              moderateHighMinPointOne:
                Outcomes && Outcomes['min'] > 50 && Outcomes['min'] <= 55
                  ? Outcomes['min']
                  : '',
              moderateHighMinPointTwo:
                Outcomes && Outcomes['min'] > 55 && Outcomes['min'] <= 60
                  ? Outcomes['min']
                  : '',
              highMinPointOne:
                Outcomes && Outcomes['min'] > 60 && Outcomes['min'] <= 65
                  ? Outcomes['min']
                  : '',
              highMinPointTwo:
                Outcomes && Outcomes['min'] > 65 && Outcomes['min'] <= 70
                  ? Outcomes['min']
                  : '',
              veryHighMinPointOne:
                Outcomes && Outcomes['min'] > 70 && Outcomes['min'] <= 75
                  ? Outcomes['min']
                  : '',
              veryHighMinPointTwo:
                Outcomes && Outcomes['min'] > 75 ? Outcomes['min'] : '',
              band: Outcomes && Outcomes['band'],
              desc: outDesc,
              veryLowMaxPointOne:
                Outcomes && Outcomes['max'] <= 25 ? Outcomes['max'] : '',
              veryLowMaxPointTwo:
                Outcomes && Outcomes['max'] > 25 && Outcomes['max'] <= 30
                  ? Outcomes['max']
                  : '',
              lowMaxPointOne:
                Outcomes && Outcomes['max'] > 30 && Outcomes['max'] <= 35
                  ? Outcomes['max']
                  : '',
              lowMaxPointTwo:
                Outcomes && Outcomes['max'] > 35 && Outcomes['max'] <= 40
                  ? Outcomes['max']
                  : '',
              moderateLowMaxPointOne:
                Outcomes && Outcomes['max'] > 40 && Outcomes['max'] <= 45
                  ? Outcomes['max']
                  : '',
              moderateLowMaxPointTwo:
                Outcomes && Outcomes['max'] > 45 && Outcomes['max'] <= 50
                  ? Outcomes['max']
                  : '',
              moderateHighMaxPointOne:
                Outcomes && Outcomes['max'] > 50 && Outcomes['max'] <= 55
                  ? Outcomes['max']
                  : '',
              moderateHighMaxPointTwo:
                Outcomes && Outcomes['max'] > 55 && Outcomes['max'] <= 60
                  ? Outcomes['max']
                  : '',
              highMaxPointOne:
                Outcomes && Outcomes['max'] > 60 && Outcomes['max'] <= 65
                  ? Outcomes['max']
                  : '',
              highMaxPointTwo:
                Outcomes && Outcomes['max'] > 65 && Outcomes['max'] <= 70
                  ? Outcomes['max']
                  : '',
              veryHighMaxPointOne:
                Outcomes && Outcomes['max'] > 70 && Outcomes['max'] <= 75
                  ? Outcomes['max']
                  : '',
              veryHighMaxPointTwo:
                Outcomes && Outcomes['max'] > 75 ? Outcomes['max'] : '',
              veryLowTscoreOne:
                Outcomes && Outcomes['t_score'] <= 25
                  ? Outcomes['t_score']
                  : '',
              veryLowTscoreTwo:
                Outcomes &&
                Outcomes['t_score'] > 25 &&
                Outcomes['t_score'] <= 30
                  ? Outcomes['t_score']
                  : '',
              lowTscoreOne:
                Outcomes &&
                Outcomes['t_score'] > 30 &&
                Outcomes['t_score'] <= 35
                  ? Outcomes['t_score']
                  : '',
              lowTscoreTwo:
                Outcomes &&
                Outcomes['t_score'] > 35 &&
                Outcomes['t_score'] <= 40
                  ? Outcomes['t_score']
                  : '',
              moderateLowTScoreOne:
                Outcomes &&
                Outcomes['t_score'] > 40 &&
                Outcomes['t_score'] <= 45
                  ? Outcomes['t_score']
                  : '',
              moderateLowTScoreTwo:
                Outcomes &&
                Outcomes['t_score'] > 45 &&
                Outcomes['t_score'] <= 50
                  ? Outcomes['t_score']
                  : '',
              moderateHighTScoreOne:
                Outcomes &&
                Outcomes['t_score'] > 50 &&
                Outcomes['t_score'] <= 55
                  ? Outcomes['t_score']
                  : '',
              moderateHighTScoreTwo:
                Outcomes &&
                Outcomes['t_score'] > 55 &&
                Outcomes['t_score'] <= 60
                  ? Outcomes['t_score']
                  : '',
              highTScoreOne:
                Outcomes &&
                Outcomes['t_score'] > 60 &&
                Outcomes['t_score'] <= 65
                  ? Outcomes['t_score']
                  : '',
              highTScoreTwo:
                Outcomes &&
                Outcomes['t_score'] > 65 &&
                Outcomes['t_score'] <= 70
                  ? Outcomes['t_score']
                  : '',
              veryHighTScoreOne:
                Outcomes &&
                Outcomes['t_score'] > 70 &&
                Outcomes['t_score'] <= 75
                  ? Outcomes['t_score']
                  : '',
              veryHighTScoreTwo:
                Outcomes && Outcomes['t_score'] > 75 ? Outcomes['t_score'] : '',
            },
            {
              metric: 'Harmony',
              lowDesc:
                'Straightforward when raising disagreements, Unafraid of confrontations, Deliver blunt  feedback, Focus on facts over feelings of others',
              highDesc:
                'Harmony, Conflict avoidance, Team consensus, Sensitive to others, Careful to not hurt other’s feelings',
              veryLowMinPointOne:
                Harmony && Harmony['min'] <= 25 ? Harmony['min'] : '',
              veryLowMinPointTwo:
                Harmony && Harmony['min'] > 25 && Harmony['min'] <= 30
                  ? Harmony['min']
                  : '',
              lowMinPointOne:
                Harmony && Harmony['min'] > 30 && Harmony['min'] <= 35
                  ? Harmony['min']
                  : '',
              lowMinPointTwo:
                Harmony && Harmony['min'] > 35 && Harmony['min'] <= 40
                  ? Harmony['min']
                  : '',
              moderateLowMinPointOne:
                Harmony && Harmony['min'] > 40 && Harmony['min'] <= 45
                  ? Harmony['min']
                  : '',
              moderateLowMinPointTwo:
                Harmony && Harmony['min'] > 45 && Harmony['min'] <= 50
                  ? Harmony['min']
                  : '',
              moderateHighMinPointOne:
                Harmony && Harmony['min'] > 50 && Harmony['min'] <= 55
                  ? Harmony['min']
                  : '',
              moderateHighMinPointTwo:
                Harmony && Harmony['min'] > 55 && Harmony['min'] <= 60
                  ? Harmony['min']
                  : '',
              highMinPointOne:
                Harmony && Harmony['min'] > 60 && Harmony['min'] <= 65
                  ? Harmony['min']
                  : '',
              highMinPointTwo:
                Harmony && Harmony['min'] > 65 && Harmony['min'] <= 70
                  ? Harmony['min']
                  : '',
              veryHighMinPointOne:
                Harmony && Harmony['min'] > 70 && Harmony['min'] <= 75
                  ? Harmony['min']
                  : '',
              veryHighMinPointTwo:
                Harmony && Harmony['min'] > 75 ? Harmony['min'] : '',
              band: Harmony && Harmony['band'],
              desc: harDesc,
              veryLowMaxPointOne:
                Harmony && Harmony['max'] <= 25 ? Harmony['max'] : '',
              veryLowMaxPointTwo:
                Harmony && Harmony['max'] > 25 && Harmony['max'] <= 30
                  ? Harmony['max']
                  : '',
              lowMaxPointOne:
                Harmony && Harmony['max'] > 30 && Harmony['max'] <= 35
                  ? Harmony['max']
                  : '',
              lowMaxPointTwo:
                Harmony && Harmony['max'] > 35 && Harmony['max'] <= 40
                  ? Harmony['max']
                  : '',
              moderateLowMaxPointOne:
                Harmony && Harmony['max'] > 40 && Harmony['max'] <= 45
                  ? Harmony['max']
                  : '',
              moderateLowMaxPointTwo:
                Harmony && Harmony['max'] > 45 && Harmony['max'] <= 50
                  ? Harmony['max']
                  : '',
              moderateHighMaxPointOne:
                Harmony && Harmony['max'] > 50 && Harmony['max'] <= 55
                  ? Harmony['max']
                  : '',
              moderateHighMaxPointTwo:
                Harmony && Harmony['max'] > 55 && Harmony['max'] <= 60
                  ? Harmony['max']
                  : '',
              highMaxPointOne:
                Harmony && Harmony['max'] > 60 && Harmony['max'] <= 65
                  ? Harmony['max']
                  : '',
              highMaxPointTwo:
                Harmony && Harmony['max'] > 65 && Harmony['max'] <= 70
                  ? Harmony['max']
                  : '',
              veryHighMaxPointOne:
                Harmony && Harmony['max'] > 70 && Harmony['max'] <= 75
                  ? Harmony['max']
                  : '',
              veryHighMaxPointTwo:
                Harmony && Harmony['max'] > 75 ? Harmony['max'] : '',
              veryLowTscoreOne:
                Harmony && Harmony['t_score'] <= 25 ? Harmony['t_score'] : '',
              veryLowTscoreTwo:
                Harmony && Harmony['t_score'] > 25 && Harmony['t_score'] <= 30
                  ? Harmony['t_score']
                  : '',
              lowTscoreOne:
                Harmony && Harmony['t_score'] > 30 && Harmony['t_score'] <= 35
                  ? Harmony['t_score']
                  : '',
              lowTscoreTwo:
                Harmony && Harmony['t_score'] > 35 && Harmony['t_score'] <= 40
                  ? Harmony['t_score']
                  : '',
              moderateLowTScoreOne:
                Harmony && Harmony['t_score'] > 40 && Harmony['t_score'] <= 45
                  ? Harmony['t_score']
                  : '',
              moderateLowTScoreTwo:
                Harmony && Harmony['t_score'] > 45 && Harmony['t_score'] <= 50
                  ? Harmony['t_score']
                  : '',
              moderateHighTScoreOne:
                Harmony && Harmony['t_score'] > 50 && Harmony['t_score'] <= 55
                  ? Harmony['t_score']
                  : '',
              moderateHighTScoreTwo:
                Harmony && Harmony['t_score'] > 55 && Harmony['t_score'] <= 60
                  ? Harmony['t_score']
                  : '',
              highTScoreOne:
                Harmony && Harmony['t_score'] > 60 && Harmony['t_score'] <= 65
                  ? Harmony['t_score']
                  : '',
              highTScoreTwo:
                Harmony && Harmony['t_score'] > 65 && Harmony['t_score'] <= 70
                  ? Harmony['t_score']
                  : '',
              veryHighTScoreOne:
                Harmony && Harmony['t_score'] > 70 && Harmony['t_score'] <= 75
                  ? Harmony['t_score']
                  : '',
              veryHighTScoreTwo:
                Harmony && Harmony['t_score'] > 75 ? Harmony['t_score'] : '',
            },
            {
              metric: 'Connection',
              lowDesc:
                'Solitary, Independent, Minimal social interactions, Distinctions between work and personal relationship',
              highDesc:
                'Team spirit (camaraderie), Mutual support, Cares for others, Inclusive and ‘doing things together’, Personal bonds and connections',
              veryLowMinPointOne:
                Connection && Connection['min'] <= 25 ? Connection['min'] : '',
              veryLowMinPointTwo:
                Connection && Connection['min'] > 25 && Connection['min'] <= 30
                  ? Connection['min']
                  : '',
              lowMinPointOne:
                Connection && Connection['min'] > 30 && Connection['min'] <= 35
                  ? Connection['min']
                  : '',
              lowMinPointTwo:
                Connection && Connection['min'] > 35 && Connection['min'] <= 40
                  ? Connection['min']
                  : '',
              moderateLowMinPointOne:
                Connection && Connection['min'] > 40 && Connection['min'] <= 45
                  ? Connection['min']
                  : '',
              moderateLowMinPointTwo:
                Connection && Connection['min'] > 45 && Connection['min'] <= 50
                  ? Connection['min']
                  : '',
              moderateHighMinPointOne:
                Connection && Connection['min'] > 50 && Connection['min'] <= 55
                  ? Connection['min']
                  : '',
              moderateHighMinPointTwo:
                Connection && Connection['min'] > 55 && Connection['min'] <= 60
                  ? Connection['min']
                  : '',
              highMinPointOne:
                Connection && Connection['min'] > 60 && Connection['min'] <= 65
                  ? Connection['min']
                  : '',
              highMinPointTwo:
                Connection && Connection['min'] > 65 && Connection['min'] <= 70
                  ? Connection['min']
                  : '',
              veryHighMinPointOne:
                Connection && Connection['min'] > 70 && Connection['min'] <= 75
                  ? Connection['min']
                  : '',
              veryHighMinPointTwo:
                Connection && Connection['min'] > 75 ? Connection['min'] : '',
              band: Connection && Connection['band'],
              desc: connDesc,
              veryLowMaxPointOne:
                Connection && Connection['max'] <= 25 ? Connection['max'] : '',
              veryLowMaxPointTwo:
                Connection && Connection['max'] > 25 && Connection['max'] <= 30
                  ? Connection['max']
                  : '',
              lowMaxPointOne:
                Connection && Connection['max'] > 30 && Connection['max'] <= 35
                  ? Connection['max']
                  : '',
              lowMaxPointTwo:
                Connection && Connection['max'] > 35 && Connection['max'] <= 40
                  ? Connection['max']
                  : '',
              moderateLowMaxPointOne:
                Connection && Connection['max'] > 40 && Connection['max'] <= 45
                  ? Connection['max']
                  : '',
              moderateLowMaxPointTwo:
                Connection && Connection['max'] > 45 && Connection['max'] <= 50
                  ? Connection['max']
                  : '',
              moderateHighMaxPointOne:
                Connection && Connection['max'] > 50 && Connection['max'] <= 55
                  ? Connection['max']
                  : '',
              moderateHighMaxPointTwo:
                Connection && Connection['max'] > 55 && Connection['max'] <= 60
                  ? Connection['max']
                  : '',
              highMaxPointOne:
                Connection && Connection['max'] > 60 && Connection['max'] <= 65
                  ? Connection['max']
                  : '',
              highMaxPointTwo:
                Connection && Connection['max'] > 65 && Connection['max'] <= 70
                  ? Connection['max']
                  : '',
              veryHighMaxPointOne:
                Connection && Connection['max'] > 70 && Connection['max'] <= 75
                  ? Connection['max']
                  : '',
              veryHighMaxPointTwo:
                Connection && Connection['max'] > 75 ? Connection['max'] : '',
              veryLowTscoreOne:
                Connection && Connection['t_score'] <= 25
                  ? Connection['t_score']
                  : '',
              veryLowTscoreTwo:
                Connection &&
                Connection['t_score'] > 25 &&
                Connection['t_score'] <= 30
                  ? Connection['t_score']
                  : '',
              lowTscoreOne:
                Connection &&
                Connection['t_score'] > 30 &&
                Connection['t_score'] <= 35
                  ? Connection['t_score']
                  : '',
              lowTscoreTwo:
                Connection &&
                Connection['t_score'] > 35 &&
                Connection['t_score'] <= 40
                  ? Connection['t_score']
                  : '',
              moderateLowTScoreOne:
                Connection &&
                Connection['t_score'] > 40 &&
                Connection['t_score'] <= 45
                  ? Connection['t_score']
                  : '',
              moderateLowTScoreTwo:
                Connection &&
                Connection['t_score'] > 45 &&
                Connection['t_score'] <= 50
                  ? Connection['t_score']
                  : '',
              moderateHighTScoreOne:
                Connection &&
                Connection['t_score'] > 50 &&
                Connection['t_score'] <= 55
                  ? Connection['t_score']
                  : '',
              moderateHighTScoreTwo:
                Connection &&
                Connection['t_score'] > 55 &&
                Connection['t_score'] <= 60
                  ? Connection['t_score']
                  : '',
              highTScoreOne:
                Connection &&
                Connection['t_score'] > 60 &&
                Connection['t_score'] <= 65
                  ? Connection['t_score']
                  : '',
              highTScoreTwo:
                Connection &&
                Connection['t_score'] > 65 &&
                Connection['t_score'] <= 70
                  ? Connection['t_score']
                  : '',
              veryHighTScoreOne:
                Connection &&
                Connection['t_score'] > 70 &&
                Connection['t_score'] <= 75
                  ? Connection['t_score']
                  : '',
              veryHighTScoreTwo:
                Connection && Connection['t_score'] > 75
                  ? Connection['t_score']
                  : '',
            },
            {
              metric: 'Stability',
              lowDesc:
                'Not obligated to current way of doing things, Best practices serve as guide, Rules are not set in stone',
              highDesc:
                'Consistent and reliable delivery, Stewards and custodians of best practices, Avoid ‘rocking the boat’, Errs on the side of caution',
              veryLowMinPointOne:
                Stability && Stability['min'] <= 25 ? Stability['min'] : '',
              veryLowMinPointTwo:
                Stability && Stability['min'] > 25 && Stability['min'] <= 30
                  ? Stability['min']
                  : '',
              lowMinPointOne:
                Stability && Stability['min'] > 30 && Stability['min'] <= 35
                  ? Stability['min']
                  : '',
              lowMinPointTwo:
                Stability && Stability['min'] > 35 && Stability['min'] <= 40
                  ? Stability['min']
                  : '',
              moderateLowMinPointOne:
                Stability && Stability['min'] > 40 && Stability['min'] <= 45
                  ? Stability['min']
                  : '',
              moderateLowMinPointTwo:
                Stability && Stability['min'] > 45 && Stability['min'] <= 50
                  ? Stability['min']
                  : '',
              moderateHighMinPointOne:
                Stability && Stability['min'] > 50 && Stability['min'] <= 55
                  ? Stability['min']
                  : '',
              moderateHighMinPointTwo:
                Stability && Stability['min'] > 55 && Stability['min'] <= 60
                  ? Stability['min']
                  : '',
              highMinPointOne:
                Stability && Stability['min'] > 60 && Stability['min'] <= 65
                  ? Stability['min']
                  : '',
              highMinPointTwo:
                Stability && Stability['min'] > 65 && Stability['min'] <= 70
                  ? Stability['min']
                  : '',
              veryHighMinPointOne:
                Stability && Stability['min'] > 70 && Stability['min'] <= 75
                  ? Stability['min']
                  : '',
              veryHighMinPointTwo:
                Stability && Stability['min'] > 75 ? Stability['min'] : '',
              band: Stability && Stability['band'],
              desc: stabDesc,
              veryLowMaxPointOne:
                Stability && Stability['max'] <= 25 ? Stability['max'] : '',
              veryLowMaxPointTwo:
                Stability && Stability['max'] > 25 && Stability['max'] <= 30
                  ? Stability['max']
                  : '',
              lowMaxPointOne:
                Stability && Stability['max'] > 30 && Stability['max'] <= 35
                  ? Stability['max']
                  : '',
              lowMaxPointTwo:
                Stability && Stability['max'] > 35 && Stability['max'] <= 40
                  ? Stability['max']
                  : '',
              moderateLowMaxPointOne:
                Stability && Stability['max'] > 40 && Stability['max'] <= 45
                  ? Stability['max']
                  : '',
              moderateLowMaxPointTwo:
                Stability && Stability['max'] > 45 && Stability['max'] <= 50
                  ? Stability['max']
                  : '',
              moderateHighMaxPointOne:
                Stability && Stability['max'] > 50 && Stability['max'] <= 55
                  ? Stability['max']
                  : '',
              moderateHighMaxPointTwo:
                Stability && Stability['max'] > 55 && Stability['max'] <= 60
                  ? Stability['max']
                  : '',
              highMaxPointOne:
                Stability && Stability['max'] > 60 && Stability['max'] <= 65
                  ? Stability['max']
                  : '',
              highMaxPointTwo:
                Stability && Stability['max'] > 65 && Stability['max'] <= 70
                  ? Stability['max']
                  : '',
              veryHighMaxPointOne:
                Stability && Stability['max'] > 70 && Stability['max'] <= 75
                  ? Stability['max']
                  : '',
              veryHighMaxPointTwo:
                Stability && Stability['max'] > 75 ? Stability['max'] : '',
              veryLowTscoreOne:
                Stability && Stability['t_score'] <= 25
                  ? Stability['t_score']
                  : '',
              veryLowTscoreTwo:
                Stability &&
                Stability['t_score'] > 25 &&
                Stability['t_score'] <= 30
                  ? Stability['t_score']
                  : '',
              lowTscoreOne:
                Stability &&
                Stability['t_score'] > 30 &&
                Stability['t_score'] <= 35
                  ? Stability['t_score']
                  : '',
              lowTscoreTwo:
                Stability &&
                Stability['t_score'] > 35 &&
                Stability['t_score'] <= 40
                  ? Stability['t_score']
                  : '',
              moderateLowTScoreOne:
                Stability &&
                Stability['t_score'] > 40 &&
                Stability['t_score'] <= 45
                  ? Stability['t_score']
                  : '',
              moderateLowTScoreTwo:
                Stability &&
                Stability['t_score'] > 45 &&
                Stability['t_score'] <= 50
                  ? Stability['t_score']
                  : '',
              moderateHighTScoreOne:
                Stability &&
                Stability['t_score'] > 50 &&
                Stability['t_score'] <= 55
                  ? Stability['t_score']
                  : '',
              moderateHighTScoreTwo:
                Stability &&
                Stability['t_score'] > 55 &&
                Stability['t_score'] <= 60
                  ? Stability['t_score']
                  : '',
              highTScoreOne:
                Stability &&
                Stability['t_score'] > 60 &&
                Stability['t_score'] <= 65
                  ? Stability['t_score']
                  : '',
              highTScoreTwo:
                Stability &&
                Stability['t_score'] > 65 &&
                Stability['t_score'] <= 70
                  ? Stability['t_score']
                  : '',
              veryHighTScoreOne:
                Stability &&
                Stability['t_score'] > 70 &&
                Stability['t_score'] <= 75
                  ? Stability['t_score']
                  : '',
              veryHighTScoreTwo:
                Stability && Stability['t_score'] > 75
                  ? Stability['t_score']
                  : '',
            },
            {
              metric: 'Innovation',
              lowDesc:
                'Conventional and pragmatic towards change, Adapt rather than innovate, Minimise risks by experimenting within ‘safe’ boundaries',
              highDesc:
                'Drive innovation, Push boundaries, Take risks to experiment, Nothing ventured nothing gained, Sees possibilities',
              veryLowMinPointOne:
                Innovation && Innovation['min'] <= 25 ? Innovation['min'] : '',
              veryLowMinPointTwo:
                Innovation && Innovation['min'] > 25 && Innovation['min'] <= 30
                  ? Innovation['min']
                  : '',
              lowMinPointOne:
                Innovation && Innovation['min'] > 30 && Innovation['min'] <= 35
                  ? Innovation['min']
                  : '',
              lowMinPointTwo:
                Innovation && Innovation['min'] > 35 && Innovation['min'] <= 40
                  ? Innovation['min']
                  : '',
              moderateLowMinPointOne:
                Innovation && Innovation['min'] > 40 && Innovation['min'] <= 45
                  ? Innovation['min']
                  : '',
              moderateLowMinPointTwo:
                Innovation && Innovation['min'] > 45 && Innovation['min'] <= 50
                  ? Innovation['min']
                  : '',
              moderateHighMinPointOne:
                Innovation && Innovation['min'] > 50 && Innovation['min'] <= 55
                  ? Innovation['min']
                  : '',
              moderateHighMinPointTwo:
                Innovation && Innovation['min'] > 55 && Innovation['min'] <= 60
                  ? Innovation['min']
                  : '',
              highMinPointOne:
                Innovation && Innovation['min'] > 60 && Innovation['min'] <= 65
                  ? Innovation['min']
                  : '',
              highMinPointTwo:
                Innovation && Innovation['min'] > 65 && Innovation['min'] <= 70
                  ? Innovation['min']
                  : '',
              veryHighMinPointOne:
                Innovation && Innovation['min'] > 70 && Innovation['min'] <= 75
                  ? Innovation['min']
                  : '',
              veryHighMinPointTwo:
                Innovation && Innovation['min'] > 75 ? Innovation['min'] : '',
              band: Innovation && Innovation['band'],
              desc: innDesc,
              veryLowMaxPointOne:
                Innovation && Innovation['max'] <= 25 ? Innovation['max'] : '',
              veryLowMaxPointTwo:
                Innovation && Innovation['max'] > 25 && Innovation['max'] <= 30
                  ? Innovation['max']
                  : '',
              lowMaxPointOne:
                Innovation && Innovation['max'] > 30 && Innovation['max'] <= 35
                  ? Innovation['max']
                  : '',
              lowMaxPointTwo:
                Innovation && Innovation['max'] > 35 && Innovation['max'] <= 40
                  ? Innovation['max']
                  : '',
              moderateLowMaxPointOne:
                Innovation && Innovation['max'] > 40 && Innovation['max'] <= 45
                  ? Innovation['max']
                  : '',
              moderateLowMaxPointTwo:
                Innovation && Innovation['max'] > 45 && Innovation['max'] <= 50
                  ? Innovation['max']
                  : '',
              moderateHighMaxPointOne:
                Innovation && Innovation['max'] > 50 && Innovation['max'] <= 55
                  ? Innovation['max']
                  : '',
              moderateHighMaxPointTwo:
                Innovation && Innovation['max'] > 55 && Innovation['max'] <= 60
                  ? Innovation['max']
                  : '',
              highMaxPointOne:
                Innovation && Innovation['max'] > 60 && Innovation['max'] <= 65
                  ? Innovation['max']
                  : '',
              highMaxPointTwo:
                Innovation && Innovation['max'] > 65 && Innovation['max'] <= 70
                  ? Innovation['max']
                  : '',
              veryHighMaxPointOne:
                Innovation && Innovation['max'] > 70 && Innovation['max'] <= 75
                  ? Innovation['max']
                  : '',
              veryHighMaxPointTwo:
                Innovation && Innovation['max'] > 75 ? Innovation['max'] : '',
              veryLowTscoreOne:
                Innovation && Innovation['t_score'] <= 25
                  ? Innovation['t_score']
                  : '',
              veryLowTscoreTwo:
                Innovation &&
                Innovation['t_score'] > 25 &&
                Innovation['t_score'] <= 30
                  ? Innovation['t_score']
                  : '',
              lowTscoreOne:
                Innovation &&
                Innovation['t_score'] > 30 &&
                Innovation['t_score'] <= 35
                  ? Innovation['t_score']
                  : '',
              lowTscoreTwo:
                Innovation &&
                Innovation['t_score'] > 35 &&
                Innovation['t_score'] <= 40
                  ? Innovation['t_score']
                  : '',
              moderateLowTScoreOne:
                Innovation &&
                Innovation['t_score'] > 40 &&
                Innovation['t_score'] <= 45
                  ? Innovation['t_score']
                  : '',
              moderateLowTScoreTwo:
                Innovation &&
                Innovation['t_score'] > 45 &&
                Innovation['t_score'] <= 50
                  ? Innovation['t_score']
                  : '',
              moderateHighTScoreOne:
                Innovation &&
                Innovation['t_score'] > 50 &&
                Innovation['t_score'] <= 55
                  ? Innovation['t_score']
                  : '',
              moderateHighTScoreTwo:
                Innovation &&
                Innovation['t_score'] > 55 &&
                Innovation['t_score'] <= 60
                  ? Innovation['t_score']
                  : '',
              highTScoreOne:
                Innovation &&
                Innovation['t_score'] > 60 &&
                Innovation['t_score'] <= 65
                  ? Innovation['t_score']
                  : '',
              highTScoreTwo:
                Innovation &&
                Innovation['t_score'] > 65 &&
                Innovation['t_score'] <= 70
                  ? Innovation['t_score']
                  : '',
              veryHighTScoreOne:
                Innovation &&
                Innovation['t_score'] > 70 &&
                Innovation['t_score'] <= 75
                  ? Innovation['t_score']
                  : '',
              veryHighTScoreTwo:
                Innovation && Innovation['t_score'] > 75
                  ? Innovation['t_score']
                  : '',
            },
            {
              metric: 'Structure',
              lowDesc:
                'Structures and processes are lightly enforced, High level of autonomy, Empowered to challenge assumptions, Roles and responsibilities are loosely defined',
              highDesc:
                'Structured and organised processes, Well defined job roles and responsibilities, Accept and follow protocols / decisions, Hierarchical',
              veryLowMinPointOne:
                Structure && Structure['min'] <= 25 ? Structure['min'] : '',
              veryLowMinPointTwo:
                Structure && Structure['min'] > 25 && Structure['min'] <= 30
                  ? Structure['min']
                  : '',
              lowMinPointOne:
                Structure && Structure['min'] > 30 && Structure['min'] <= 35
                  ? Structure['min']
                  : '',
              lowMinPointTwo:
                Structure && Structure['min'] > 35 && Structure['min'] <= 40
                  ? Structure['min']
                  : '',
              moderateLowMinPointOne:
                Structure && Structure['min'] > 40 && Structure['min'] <= 45
                  ? Structure['min']
                  : '',
              moderateLowMinPointTwo:
                Structure && Structure['min'] > 45 && Structure['min'] <= 50
                  ? Structure['min']
                  : '',
              moderateHighMinPointOne:
                Structure && Structure['min'] > 50 && Structure['min'] <= 55
                  ? Structure['min']
                  : '',
              moderateHighMinPointTwo:
                Structure && Structure['min'] > 55 && Structure['min'] <= 60
                  ? Structure['min']
                  : '',
              highMinPointOne:
                Structure && Structure['min'] > 60 && Structure['min'] <= 65
                  ? Structure['min']
                  : '',
              highMinPointTwo:
                Structure && Structure['min'] > 65 && Structure['min'] <= 70
                  ? Structure['min']
                  : '',
              veryHighMinPointOne:
                Structure && Structure['min'] > 70 && Structure['min'] <= 75
                  ? Structure['min']
                  : '',
              veryHighMinPointTwo:
                Structure && Structure['min'] > 75 ? Structure['min'] : '',
              band: Structure && Structure['band'],
              desc: strDesc,
              veryLowMaxPointOne:
                Structure && Structure['max'] <= 25 ? Structure['max'] : '',
              veryLowMaxPointTwo:
                Structure && Structure['max'] > 25 && Structure['max'] <= 30
                  ? Structure['max']
                  : '',
              lowMaxPointOne:
                Structure && Structure['max'] > 30 && Structure['max'] <= 35
                  ? Structure['max']
                  : '',
              lowMaxPointTwo:
                Structure && Structure['max'] > 35 && Structure['max'] <= 40
                  ? Structure['max']
                  : '',
              moderateLowMaxPointOne:
                Structure && Structure['max'] > 40 && Structure['max'] <= 45
                  ? Structure['max']
                  : '',
              moderateLowMaxPointTwo:
                Structure && Structure['max'] > 45 && Structure['max'] <= 50
                  ? Structure['max']
                  : '',
              moderateHighMaxPointOne:
                Structure && Structure['max'] > 50 && Structure['max'] <= 55
                  ? Structure['max']
                  : '',
              moderateHighMaxPointTwo:
                Structure && Structure['max'] > 55 && Structure['max'] <= 60
                  ? Structure['max']
                  : '',
              highMaxPointOne:
                Structure && Structure['max'] > 60 && Structure['max'] <= 65
                  ? Structure['max']
                  : '',
              highMaxPointTwo:
                Structure && Structure['max'] > 65 && Structure['max'] <= 70
                  ? Structure['max']
                  : '',
              veryHighMaxPointOne:
                Structure && Structure['max'] > 70 && Structure['max'] <= 75
                  ? Structure['max']
                  : '',
              veryHighMaxPointTwo:
                Structure && Structure['max'] > 75 ? Structure['max'] : '',
              veryLowTscoreOne:
                Structure && Structure['t_score'] <= 25
                  ? Structure['t_score']
                  : '',
              veryLowTscoreTwo:
                Structure &&
                Structure['t_score'] > 25 &&
                Structure['t_score'] <= 30
                  ? Structure['t_score']
                  : '',
              lowTscoreOne:
                Structure &&
                Structure['t_score'] > 30 &&
                Structure['t_score'] <= 35
                  ? Structure['t_score']
                  : '',
              lowTscoreTwo:
                Structure &&
                Structure['t_score'] > 35 &&
                Structure['t_score'] <= 40
                  ? Structure['t_score']
                  : '',
              moderateLowTScoreOne:
                Structure &&
                Structure['t_score'] > 40 &&
                Structure['t_score'] <= 45
                  ? Structure['t_score']
                  : '',
              moderateLowTScoreTwo:
                Structure &&
                Structure['t_score'] > 45 &&
                Structure['t_score'] <= 50
                  ? Structure['t_score']
                  : '',
              moderateHighTScoreOne:
                Structure &&
                Structure['t_score'] > 50 &&
                Structure['t_score'] <= 55
                  ? Structure['t_score']
                  : '',
              moderateHighTScoreTwo:
                Structure &&
                Structure['t_score'] > 55 &&
                Structure['t_score'] <= 60
                  ? Structure['t_score']
                  : '',
              highTScoreOne:
                Structure &&
                Structure['t_score'] > 60 &&
                Structure['t_score'] <= 65
                  ? Structure['t_score']
                  : '',
              highTScoreTwo:
                Structure &&
                Structure['t_score'] > 65 &&
                Structure['t_score'] <= 70
                  ? Structure['t_score']
                  : '',
              veryHighTScoreOne:
                Structure &&
                Structure['t_score'] > 70 &&
                Structure['t_score'] <= 75
                  ? Structure['t_score']
                  : '',
              veryHighTScoreTwo:
                Structure && Structure['t_score'] > 75
                  ? Structure['t_score']
                  : '',
            },
          ];
          this.setState(
            {
              culturalGraph: finalData,
            },
            () => {
              this.handleGetCultXPoints();
            }
          );
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      });
    }, 5000);
    setTimeout(() => {
      clearTimeout(interval);
    }, 5000);
  };

  handleGetPersonalityProfileReport = (task_id) => {
    const interval = setInterval(() => {
      this.props.generateCeleryTask(task_id).then((res) => {
        if (res && !res.error) {
          const { celeryData } = this.props;
          const { neopi_score, neopi_band } = celeryData || {};
          let personalityData = {
            id: '',
            color: '#4281cc',
            data: [],
          };
          const keys = neopi_score && Object.keys(neopi_score);
          let outcomeGraphData = [];
          const neuroticismData = [];
          const extraversionData = [];
          const opennessData = [];
          const agreeablenessData = [];
          const conscientiousnessData = [];
          const personalityGraphData = [];
          const neuroticismOutcomeData = [];
          const extraversionOutcomeData = [];
          const opennessOutcomeData = [];
          const agreeablenessOutcomeData = [];
          const conscientiousnessOutcomeData = [];
          if (keys && Array.isArray(keys) && keys.length > 0) {
            keys.forEach((score) => {
              const outcomeObj = {};
              if (score === 'N1') {
                neuroticismData.push({
                  lowDesc: 'Calm, Relaxed, Carefree',
                  metric: 'Anxiety',
                  highDesc: 'Apprehensive. Worried, Tense',
                  finalOutcome: neopi_band && neopi_band['N1'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--neuro',
                  name: 'Neuroticism',
                });
              } else if (score === 'N2') {
                neuroticismData.push({
                  lowDesc: 'Patient, Even-Tempered, EasyGoing ',
                  metric: 'Frustration',
                  highDesc: 'Short-Tempered, Irritable, Intense',
                  finalOutcome: neopi_band && neopi_band['N2'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--neuro',
                  // name: 'Neuroticism',
                });
              } else if (score === 'N3') {
                neuroticismData.push({
                  lowDesc: 'Contented, Upbeat, Hopeful',
                  metric: 'Despondency',
                  highDesc: 'Discontented, Pessimistic ',
                  finalOutcome: neopi_band && neopi_band['N3'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--neuro',
                  // name: 'Neuroticism',
                });
              } else if (score === 'N4') {
                neuroticismData.push({
                  lowDesc: 'Socially Confident, Self-Assured',
                  metric: 'Self Consciousness',
                  highDesc: 'Shy, Inhibited, Self-Conscious',
                  finalOutcome: neopi_band && neopi_band['N4'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--neuro',
                  // name: 'Neuroticism',
                });
              } else if (score === 'N5') {
                neuroticismData.push({
                  lowDesc: 'Self-Denying, Stoic, Resists Temptation',
                  metric: 'Impulsivity',
                  highDesc: 'Self-Indulgent, easily tempted',
                  finalOutcome: neopi_band && neopi_band['N5'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--neuro',
                  // name: 'Neuroticism',
                });
              } else if (score === 'N6') {
                neuroticismData.push({
                  lowDesc:
                    'Self-Confident, Poised, ClearThinking, Calm Under Fire',
                  metric: 'Stress Prone',
                  highDesc: 'Confused, Panicky, Dependent',
                  finalOutcome: neopi_band && neopi_band['N6'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--neuro',
                  // name: 'Neuroticism',
                });
              }
              if (score === 'E1') {
                extraversionData.push({
                  lowDesc: 'Reserved, Distant, Cool',
                  metric: 'Friendliness',
                  highDesc: 'Friendly, Warm, Affectionate',
                  finalOutcome: neopi_band && neopi_band.E1,
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'heading-percentile',
                  name: 'Extraversion',
                });
              } else if (score === 'E2') {
                extraversionData.push({
                  lowDesc: 'Introverted, Prefers Privacy',
                  metric: 'Sociability',
                  highDesc: 'Gregarious, Prefers Company',
                  finalOutcome: neopi_band && neopi_band.E2,
                  veryLow: neopi_score['E2'] <= 30 ? neopi_score['E2'] : '',
                  low:
                    neopi_score['E2'] >= 31 && neopi_score['E2'] <= 40
                      ? neopi_score['E2']
                      : '',
                  moderateLow:
                    neopi_score['E2'] >= 41 && neopi_score['E2'] <= 50
                      ? neopi_score['E2']
                      : '',
                  moderateHigh:
                    neopi_score['E2'] >= 51 && neopi_score['E2'] <= 60
                      ? neopi_score['E2']
                      : '',
                  high:
                    neopi_score['E2'] >= 61 && neopi_score['E2'] <= 80
                      ? neopi_score['E2']
                      : '',
                  veryHigh:
                    neopi_score['E2'] >= 81 && neopi_score['E2'] <= 100
                      ? neopi_score['E2']
                      : '',
                  color: 'heading-percentile',
                  // name: 'Extraversion',
                });
              } else if (score === 'E3') {
                extraversionData.push({
                  lowDesc: 'Non-Assertive, Acquiescent, Passive',
                  metric: 'Assertiveness',
                  highDesc: 'Assertive, Forceful, Dominant',
                  finalOutcome: neopi_band && neopi_band.E3,
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'heading-percentile',
                  // name: 'Extraversion',
                });
              } else if (score === 'E4') {
                extraversionData.push({
                  lowDesc: 'Sedentary, Slow-Paced, Inactive',
                  metric: 'Pace of Living ',
                  highDesc: 'Active, Energetic, Fast-Paced',
                  finalOutcome: neopi_band && neopi_band.E4,
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'heading-percentile',
                  // name: 'Extraversion',
                });
              } else if (score === 'E5') {
                extraversionData.push({
                  lowDesc: 'Conventional, Uncomplicated Needs, Avoids Risk',
                  metric: 'Excitement Seeking',
                  highDesc: 'Adventurous, Daring, Pleasure Seeking',
                  finalOutcome: neopi_band && neopi_band.E5,
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'heading-percentile',
                  // name: 'Extraversion',
                });
              } else if (score === 'E6') {
                extraversionData.push({
                  lowDesc: 'Serious, Sober, Lacking in Enthusiasm',
                  metric: 'Positive Outlook',
                  highDesc: 'Cheerful, Positive Exuberant',
                  finalOutcome: neopi_band && neopi_band.E6,
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'heading-percentile',
                  // name: 'Extraversion',
                });
              } else if (score === 'O1') {
                opennessData.push({
                  lowDesc: 'Concrete, unimaginative',
                  metric: 'Openness to Imagination',
                  highDesc: 'Abstract, creative, imaginative',
                  finalOutcome: neopi_band && neopi_band['O1'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--openness',
                  name: 'Openness',
                });
              } else if (score === 'O2') {
                opennessData.push({
                  lowDesc: 'Prosaic, lack of interest in the arts',
                  metric: 'Openness to Aesthetics',
                  highDesc: 'Artistic, stylish, aesthetic',
                  finalOutcome: neopi_band && neopi_band['O2'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--openness',
                  // name: 'Openness',
                });
              } else if (score === 'O3') {
                opennessData.push({
                  lowDesc:
                    'Emotions are unimportant, limited in range, controlled',
                  metric: 'Openness to Feelings',
                  highDesc: 'Emotions are important, responsive, wide-ranging',
                  finalOutcome: neopi_band && neopi_band['O3'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--openness',
                  // name: 'Openness',
                });
              } else if (score === 'O4') {
                opennessData.push({
                  lowDesc:
                    'Prefers status quo, narrow range of activities, predictable',
                  metric: 'Openness to New Activities',
                  highDesc:
                    'Versatile, wide range of interests, willing to try new things',
                  finalOutcome: neopi_band && neopi_band['O4'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--openness',
                  // name: 'Openness',
                });
              } else if (score === 'O5') {
                opennessData.push({
                  lowDesc: 'Limited curiosity, narrowly focused',
                  metric: 'Openness to Ideas',
                  highDesc: 'Inquisitive, curious, open-minded',
                  finalOutcome: neopi_band && neopi_band['O5'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--openness',
                  // name: 'Openness',
                });
              } else if (score === 'O6') {
                opennessData.push({
                  lowDesc: 'Tradition-bound, conventional, dogmatic',
                  metric: 'Openness to Values',
                  highDesc:
                    'Unconventional, open to a variety of behaviours and viewpoints',
                  finalOutcome: neopi_band && neopi_band['O6'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--openness',
                  // name: 'Openness',
                });
              } else if (score === 'A1') {
                agreeablenessData.push({
                  lowDesc: 'Suspicious, cynical,distrusting',
                  metric: 'Trust',
                  highDesc: 'Accepting, forgiving, trusting',
                  finalOutcome: neopi_band && neopi_band['A1'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--aggree',
                  name: 'Agreeableness',
                });
              } else if (score === 'A2') {
                agreeablenessData.push({
                  lowDesc: 'Cautious, guarded, indirect, manipulative',
                  metric: 'Straightforwardness',
                  highDesc: 'Straightforward, frank, forthright',
                  finalOutcome: neopi_band && neopi_band['A2'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--aggree',
                  // name: 'Agreeableness',
                });
              } else if (score === 'A3') {
                agreeablenessData.push({
                  lowDesc: 'Self-absorbed, high self-interest',
                  metric: 'Altruism',
                  highDesc: 'Considerate, helpful, aware of others’ needs',
                  finalOutcome: neopi_band && neopi_band['A3'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--aggree',
                  // name: 'Agreeableness',
                });
              } else if (score === 'A4') {
                agreeablenessData.push({
                  lowDesc: 'Competitive, headstrong, contentious',
                  metric: 'Compliance',
                  highDesc: 'Deferent, compliant, cooperative',
                  finalOutcome: neopi_band && neopi_band['A4'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--aggree',
                  // name: 'Agreeableness',
                });
              } else if (score === 'A5') {
                agreeablenessData.push({
                  lowDesc:
                    'Feels superior, considered arrogant, or conceited by others',
                  metric: 'Modesty',
                  highDesc: 'Humble, modest, self-effacing',
                  finalOutcome: neopi_band && neopi_band['A5'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--aggree',
                  // name: 'Agreeableness',
                });
              } else if (score === 'A6') {
                agreeablenessData.push({
                  lowDesc: 'Hard-headed, tough-minded, coldly logical',
                  metric: 'Compassion',
                  highDesc: 'Understanding, sympathetic, compassionate',
                  finalOutcome: neopi_band && neopi_band['A6'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--aggree',
                  // name: 'Agreeableness',
                });
              } else if (score === 'C1') {
                conscientiousnessData.push({
                  lowDesc: 'Unsure, inefficient, unprepared',
                  metric: 'Sense of Mastery',
                  highDesc: 'Self-confident, resourceful',
                  finalOutcome: neopi_band && neopi_band['C1'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--consc',
                  name: 'Conscientiousness',
                });
              } else if (score === 'C2') {
                conscientiousnessData.push({
                  lowDesc: 'Disorganized, unmethodical, careless',
                  metric: 'Order',
                  highDesc: 'Neat, tidy, orderly, well-organized',
                  finalOutcome: neopi_band && neopi_band['C2'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--consc',
                  // name: 'Conscientiousness',
                });
              } else if (score === 'C3') {
                conscientiousnessData.push({
                  lowDesc: 'Unreliable, undependable, irresponsible',
                  metric: 'Dutifulness',
                  highDesc: 'Dependable, principled, responsible',
                  finalOutcome: neopi_band && neopi_band['C3'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--consc',
                  // name: 'Conscientiousness',
                });
              } else if (score === 'C4') {
                conscientiousnessData.push({
                  lowDesc: 'Lackadaisical, complacent, aimless',
                  metric: 'Achievement Striving',
                  highDesc: 'Ambitious, goal oriented, enterprising',
                  finalOutcome: neopi_band && neopi_band['C4'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--consc',
                  // name: 'Conscientiousness',
                });
              } else if (score === 'C5') {
                conscientiousnessData.push({
                  lowDesc:
                    'Distractible, lack of follow-through, procrastinating',
                  metric: 'Self-Discipline',
                  highDesc:
                    'Inner-motivated, persevering, completes what is started',
                  finalOutcome: neopi_band && neopi_band['C5'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--consc',
                  // name: 'Conscientiousness',
                });
              } else if (score === 'C6') {
                conscientiousnessData.push({
                  lowDesc: 'Hasty, impatient, spontaneous',
                  metric: 'Deliberation',
                  highDesc: 'Cautious, deliberate, thorough',
                  finalOutcome: neopi_band && neopi_band['C6'],
                  veryLow: neopi_score[score] <= 30 ? neopi_score[score] : '',
                  low:
                    neopi_score[score] >= 31 && neopi_score[score] <= 40
                      ? neopi_score[score]
                      : '',
                  moderateLow:
                    neopi_score[score] >= 41 && neopi_score[score] <= 50
                      ? neopi_score[score]
                      : '',
                  moderateHigh:
                    neopi_score[score] >= 51 && neopi_score[score] <= 60
                      ? neopi_score[score]
                      : '',
                  high:
                    neopi_score[score] >= 61 && neopi_score[score] <= 80
                      ? neopi_score[score]
                      : '',
                  veryHigh:
                    neopi_score[score] >= 81 && neopi_score[score] <= 100
                      ? neopi_score[score]
                      : '',
                  color: 'css-color--factor--consc',
                  // name: 'Conscientiousness',
                });
              }
              if (score === 'E') {
                outcomeObj.name = 'Extraversion';
                outcomeObj.veryLow =
                  neopi_score[score] <= 30 ? neopi_score[score] : '';
                outcomeObj.low =
                  neopi_score[score] >= 31 && neopi_score[score] <= 40
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateLow =
                  neopi_score[score] >= 41 && neopi_score[score] <= 50
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateHigh =
                  neopi_score[score] >= 51 && neopi_score[score] <= 60
                    ? neopi_score[score]
                    : '';
                outcomeObj.high =
                  neopi_score[score] >= 61 && neopi_score[score] <= 80
                    ? neopi_score[score]
                    : '';
                outcomeObj.veryHigh =
                  neopi_score[score] >= 81 && neopi_score[score] <= 100
                    ? neopi_score[score]
                    : '';
                outcomeObj.finalOutcome = neopi_band && neopi_band['E'];
                outcomeObj.color = 'heading-percentile';
                extraversionOutcomeData.push(outcomeObj);
              }
              if (score === 'A') {
                outcomeObj.name = 'Agreeableness';
                outcomeObj.veryLow =
                  neopi_score[score] <= 30 ? neopi_score[score] : '';
                outcomeObj.low =
                  neopi_score[score] >= 31 && neopi_score[score] <= 40
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateLow =
                  neopi_score[score] >= 41 && neopi_score[score] <= 50
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateHigh =
                  neopi_score[score] >= 51 && neopi_score[score] <= 60
                    ? neopi_score[score]
                    : '';
                outcomeObj.high =
                  neopi_score[score] >= 61 && neopi_score[score] <= 80
                    ? neopi_score[score]
                    : '';
                outcomeObj.veryHigh =
                  neopi_score[score] >= 81 && neopi_score[score] <= 100
                    ? neopi_score[score]
                    : '';
                outcomeObj.color = 'css-color--factor--aggree';
                outcomeObj.finalOutcome = neopi_band && neopi_band['A'];
                agreeablenessOutcomeData.push(outcomeObj);
              }
              if (score === 'O') {
                outcomeObj.name = 'Openness';
                outcomeObj.veryLow =
                  neopi_score[score] <= 30 ? neopi_score[score] : '';
                outcomeObj.low =
                  neopi_score[score] >= 31 && neopi_score[score] <= 40
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateLow =
                  neopi_score[score] >= 41 && neopi_score[score] <= 50
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateHigh =
                  neopi_score[score] >= 51 && neopi_score[score] <= 60
                    ? neopi_score[score]
                    : '';
                outcomeObj.high =
                  neopi_score[score] >= 61 && neopi_score[score] <= 80
                    ? neopi_score[score]
                    : '';
                outcomeObj.veryHigh =
                  neopi_score[score] >= 81 && neopi_score[score] <= 100
                    ? neopi_score[score]
                    : '';
                outcomeObj.color = 'css-color--factor--openness';
                outcomeObj.finalOutcome = neopi_band && neopi_band['O'];
                opennessOutcomeData.push(outcomeObj);
              }
              if (score === 'C') {
                outcomeObj.name = 'Conscientiousness';
                outcomeObj.veryLow =
                  neopi_score[score] <= 30 ? neopi_score[score] : '';
                outcomeObj.low =
                  neopi_score[score] >= 31 && neopi_score[score] <= 40
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateLow =
                  neopi_score[score] >= 41 && neopi_score[score] <= 50
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateHigh =
                  neopi_score[score] >= 51 && neopi_score[score] <= 60
                    ? neopi_score[score]
                    : '';
                outcomeObj.high =
                  neopi_score[score] >= 61 && neopi_score[score] <= 80
                    ? neopi_score[score]
                    : '';
                outcomeObj.veryHigh =
                  neopi_score[score] >= 81 && neopi_score[score] <= 100
                    ? neopi_score[score]
                    : '';
                outcomeObj.color = 'css-color--factor--consc';
                outcomeObj.finalOutcome = neopi_band && neopi_band['C'];
                conscientiousnessOutcomeData.push(outcomeObj);
              }
              if (score === 'N') {
                outcomeObj.name = 'Neuroticism';
                outcomeObj.veryLow =
                  neopi_score[score] <= 30 ? neopi_score[score] : '';
                outcomeObj.low =
                  neopi_score[score] >= 31 && neopi_score[score] <= 40
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateLow =
                  neopi_score[score] >= 41 && neopi_score[score] <= 50
                    ? neopi_score[score]
                    : '';
                outcomeObj.moderateHigh =
                  neopi_score[score] >= 51 && neopi_score[score] <= 60
                    ? neopi_score[score]
                    : '';
                outcomeObj.high =
                  neopi_score[score] >= 61 && neopi_score[score] <= 80
                    ? neopi_score[score]
                    : '';
                outcomeObj.veryHigh =
                  neopi_score[score] >= 81 && neopi_score[score] <= 100
                    ? neopi_score[score]
                    : '';
                outcomeObj.color = 'css-color--factor--neuro';
                outcomeObj.finalOutcome = neopi_band && neopi_band['N'];
                neuroticismOutcomeData.push(outcomeObj);
              }
            });
            personalityGraphData.push(...extraversionData);
            personalityGraphData.push(...agreeablenessData);
            personalityGraphData.push(...opennessData);
            personalityGraphData.push(...conscientiousnessData);
            personalityGraphData.push(...neuroticismData);
            outcomeGraphData.push(...extraversionOutcomeData);
            outcomeGraphData.push(...agreeablenessOutcomeData);
            outcomeGraphData.push(...opennessOutcomeData);
            outcomeGraphData.push(...conscientiousnessOutcomeData);
            outcomeGraphData.push(...neuroticismOutcomeData);
            this.setState(
              {
                personalityOutcomeData: outcomeGraphData,
                personalityGraphData,
              },
              () => {
                this.handleGetLineXPoints();
                this.handleGetPersonalityXLinePoints();
              }
            );
          }
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      });
    }, 5000);
    setTimeout(() => {
      clearTimeout(interval);
    }, 5000);
  };

  handleGetPsychologicalReport = (task_id) => {
    const interval = setInterval(() => {
      this.props.generateCeleryTask(task_id).then((res) => {
        if (res && !res.error) {
          const { celeryData } = this.props || {};
          const { neopi_score } = celeryData || {};
          let psychologicalData = {
            id: 'T Score',
            color: '#1f4e79',
            data: [],
          };
          let finalOutcomeData = {
            id: 'T Score',
            color: '#1f4e79',
            data: [],
          };
          const finalPsychologicalData = this.handleGetLineGraphData(
            neopi_score,
            psychologicalData
          );
          const { data } = finalPsychologicalData || {};
          const neuroticismData = [];
          const extraversionData = [];
          const opennessData = [];
          const agreeablenessData = [];
          const conscientiousnessData = [];
          const keys = neopi_score && Object.keys(neopi_score);
          if (keys && Array.isArray(keys) && keys.length > 0) {
            keys.forEach((graph) => {
              if (graph === 'N') {
                neuroticismData.push({
                  x: neopi_score[graph],
                  y: `Neuroticism (${graph} = ${neopi_score[graph]})`,
                });
              } else if (graph === 'E') {
                extraversionData.push({
                  x: neopi_score[graph],
                  y: `Extraversion (${graph} = ${neopi_score[graph]})`,
                });
              } else if (graph === 'O') {
                opennessData.push({
                  x: neopi_score[graph],
                  y: `Openness (${graph} = ${neopi_score[graph]})`,
                });
              } else if (graph === 'A') {
                agreeablenessData.push({
                  x: neopi_score[graph],
                  y: `Agreeableness (${graph} = ${neopi_score[graph]})`,
                });
              } else if (graph === 'C') {
                conscientiousnessData.push({
                  x: neopi_score[graph],
                  y: `Conscientiousness (${graph} = ${neopi_score[graph]})`,
                });
              }
            });
          }
          finalOutcomeData.data.push(...neuroticismData);
          finalOutcomeData.data.push(...extraversionData);
          finalOutcomeData.data.push(...opennessData);
          finalOutcomeData.data.push(...agreeablenessData);
          finalOutcomeData.data.push(...conscientiousnessData);
          const { data: outComeData } = finalOutcomeData;
          this.setState({
            psychologicalData: data,
            finalOutcomeData: outComeData,
          });
        } else {
          this.setState({
            errorNotif: true,
          });
        }
      });
    }, 5000);
    setTimeout(() => {
      clearTimeout(interval);
    }, 5000);
  };

  handleGetSvg = () => {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="15px"
        height="15px"
        viewBox="0 0 250 250"
        enable-background="new 0 0 250 250">
        <g id="Layer_1"></g>
        <g id="Layer_2">
          <polygon
            fill="#1f4e79"
            stroke="black"
            stroke-width="5"
            stroke-miterlimit="10"
            points="69.445,125 125,28.774 180.556,125 
                125,221.227 	"
          />
        </g>
      </svg>
    );
  };

  render() {
    const { match } = this.props;
    const { params } = match || {};
    const { status } = params || {};
    const {
      psychologicalData,
      finalOutcomeData,
      persFinalOutcome,
      personalityData,
      personalityOutcomeData,
      personalityGraphData,
      culturalGraph,
    } = this.state || {};
    return (
      <div
        className={
          status === 'psychological-profile' ||
          status === 'psychological-profile-outcome'
            ? 'pslc-neo-width'
            : 'report-print-view'
        }>
        {status === 'psychological-profile' &&
          psychologicalData &&
          Array.isArray(psychologicalData) &&
          psychologicalData.length > 0 && (
            <div id="psychological-report" className="report-graph-height">
              <PsychologicalDataTable
                data={psychologicalData}
                isOutcomeData={false}
                selectorId="x-row-point"
                showHeader
              />
              <svg
                ref={this.svgRef}
                style={{ height: '100%' }}
                className="w-100"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  ref={this.pathRef}
                  id="xrpath-1"
                  stroke="#1f4e79"
                  strokeWidth="2"
                  fill="transparent"
                />
              </svg>
            </div>
          )}
        {status === 'psychological-profile-outcome' &&
          finalOutcomeData &&
          Array.isArray(finalOutcomeData) &&
          finalOutcomeData.length > 0 && (
            <div
              id="psychological-report-outcome"
              className="pslp-neo-outcome-h">
              <PsychologicalDataTable
                data={finalOutcomeData}
                isOutcomeData
                selectorId="p-x-row-point"
                showHeader={false}
              />
              <svg
                ref={this.personalitySvgRef}
                style={{ height: '100%' }}
                className="w-100"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  ref={this.personalityPathRef}
                  id="xrpath-1"
                  stroke="#1f4e79"
                  strokeWidth="2"
                  fill="transparent"
                />
              </svg>
            </div>
          )}
        {status === 'cultural-report' &&
          culturalGraph &&
          Array.isArray(culturalGraph) &&
          culturalGraph.length > 0 && (
            <Fragment>
              <div
                id="cultural-preference-graph"
                className="css-cult-graph-h table-width w-100">
                <table className="w-100">
                  <thead className="">
                    <th></th>
                    <th></th>
                    <th
                      colSpan={2}
                      className="css-cultural-rating-w css-cult-rep-head-b text-center cult-rep-fsz">
                      Very Low
                    </th>
                    <th
                      colSpan={2}
                      className="css-cultural-rating-w css-cult-rep-head-b text-center cult-rep-fsz">
                      Low
                    </th>
                    <th
                      colSpan={4}
                      className="css-cultural-rating-w css-cult-rep-head-b text-center cult-rep-fsz">
                      Moderate
                    </th>
                    <th
                      colSpan={2}
                      className="css-cultural-rating-w css-cult-rep-head-b text-center cult-rep-fsz">
                      High
                    </th>
                    <th
                      colSpan={2}
                      className="css-cultural-rating-w css-cult-rep-head-b text-center cult-rep-fsz">
                      Very High
                    </th>
                    <th className=""></th>
                    <th></th>
                    <th className="css-behv-w css-behv-heading">
                      Supportive Managerial Behaviours
                    </th>
                  </thead>
                  <tbody className="x-table-body css-cilt-report-body">
                    {culturalGraph.map((res, idx) => {
                      const {
                        metric,
                        lowDesc,
                        highDesc,
                        veryLowMinPointOne,
                        veryLowMinPointTwo,
                        lowMinPointOne,
                        lowMinPointTwo,
                        moderateLowMinPointOne,
                        moderateLowMinPointTwo,
                        moderateHighMinPointOne,
                        moderateHighMinPointTwo,
                        highMinPointOne,
                        highMinPointTwo,
                        veryHighMinPointOne,
                        veryHighMinPointTwo,
                        band,
                        veryLowMaxPointOne,
                        veryLowMaxPointTwo,
                        lowMaxPointOne,
                        lowMaxPointTwo,
                        moderateLowMaxPointOne,
                        moderateLowMaxPointTwo,
                        moderateHighMaxPointOne,
                        moderateHighMaxPointTwo,
                        highMaxPointOne,
                        highMaxPointTwo,
                        veryHighMaxPointOne,
                        veryHighMaxPointTwo,
                        lowTscoreOne,
                        lowTscoreTwo,
                        veryLowTscoreOne,
                        veryLowTscoreTwo,
                        moderateLowTScoreOne,
                        moderateLowTScoreTwo,
                        moderateHighTScoreOne,
                        moderateHighTScoreTwo,
                        highTScoreOne,
                        highTScoreTwo,
                        veryHighTScoreOne,
                        veryHighTScoreTwo,
                        desc,
                      } = res;
                      return (
                        <tr style={{ height: '115px' }} className="">
                          <td
                            style={{ fontSize: '25px' }}
                            className="css-cultural-desc-w css-cult-rep-metric vertical-align-middle">
                            {metric}
                          </td>
                          <td className="css-cultural-desc-w vertical-align-middle cult-rep-fsz-desc text-dark text-center pr-2">
                            {lowDesc}
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w css-cult-rep-solid-b pt-0 '
                                : 'css-cultural-rating-w css-cult-rep-solid-b pt-0'
                            }>
                            {idx === 0 ? (
                              <div
                                style={{
                                  borderRight: '1px solid gray',
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                                className=""
                              />
                            ) : (
                              <div
                                style={{
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                              />
                            )}
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {veryLowTscoreOne > 0 ? (
                                <div
                                  className={
                                    veryLowTscoreOne === 25
                                      ? 'css-tscore-m-r-4'
                                      : veryLowTscoreOne === 21
                                      ? 'css-tscore-m-r'
                                      : veryLowTscoreOne === 22
                                      ? 'css-tscore-m-r-1'
                                      : veryLowTscoreOne === 23
                                      ? 'css-tscore-m-r-2'
                                      : veryLowTscoreOne === 24
                                      ? 'css-tscore-m-r-3'
                                      : veryLowTscoreOne === 20
                                      ? 'css-right-20-score'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {veryLowMinPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    veryLowMinPointOne === 25
                                      ? 'css-cult-rep-point-one'
                                      : veryLowMinPointOne > 23
                                      ? 'css-cult-rep-point-4 '
                                      : veryLowMinPointOne === 20
                                      ? 'css-left-20-score'
                                      : veryLowMinPointOne === 23
                                      ? 'css-left'
                                      : veryLowMinPointOne < 22
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {veryLowMaxPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    veryLowMaxPointOne === 25
                                      ? 'css-cult-rep-point-one'
                                      : veryLowMaxPointOne > 23
                                      ? 'css-cult-rep-point-4 '
                                      : veryLowMaxPointOne === 20
                                      ? 'css-left-20-score'
                                      : veryLowMaxPointOne === 23
                                      ? 'css-left'
                                      : veryLowMaxPointOne < 22
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w pt-0'
                                : 'css-cultural-rating-w pt-0'
                            }>
                            <div
                              style={{
                                height: '13px',
                                marginBottom: '80px',
                              }}
                            />
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {veryLowTscoreTwo > 0 ? (
                                <div
                                  className={
                                    veryLowTscoreTwo === 30
                                      ? 'css-tscore-m-l-4'
                                      : veryLowTscoreTwo === 29
                                      ? 'css-tscore-m-l'
                                      : veryLowTscoreTwo === 28
                                      ? 'css-tscore-m-l-1'
                                      : veryLowTscoreTwo === 27
                                      ? 'css-tscore-m-l-2'
                                      : veryLowTscoreTwo === 26
                                      ? 'css-tscore-m-l-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {veryLowMinPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    veryLowMinPointTwo === 30
                                      ? 'css-cult-rep-point'
                                      : veryLowMinPointTwo > 28
                                      ? 'css-cult-rep-point-4'
                                      : veryLowMinPointTwo < 27
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {veryLowMaxPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    veryLowMaxPointTwo === 30
                                      ? 'css-cult-rep-point'
                                      : veryLowMaxPointTwo > 28
                                      ? 'css-cult-rep-point-4'
                                      : veryLowMaxPointTwo < 27
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w css-cult-rep-dash-b pt-0'
                                : 'css-cultural-rating-w css-cult-rep-dash-b pt-0'
                            }>
                            {idx === 0 ? (
                              <div
                                style={{
                                  borderRight: '1px solid gray',
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                                className=""
                              />
                            ) : (
                              <div
                                style={{
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                              />
                            )}
                            <div className="w-100 d-flex css-bot position-relative">
                              {lowTscoreOne > 0 ? (
                                <div
                                  className={
                                    lowTscoreOne === 35
                                      ? 'css-tscore-m-r-4'
                                      : lowTscoreOne === 31
                                      ? 'css-tscore-m-r'
                                      : lowTscoreOne === 32
                                      ? 'css-tscore-m-r-1'
                                      : lowTscoreOne === 33
                                      ? 'css-tscore-m-r-2'
                                      : lowTscoreOne === 34
                                      ? 'css-tscore-m-r-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {lowMinPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    lowMinPointOne === 35
                                      ? 'css-cult-rep-point-one'
                                      : lowMinPointOne > 33
                                      ? 'css-cult-rep-point-4 '
                                      : lowMinPointOne < 32
                                      ? 'css-cult-rep-r'
                                      : lowMinPointOne === 33
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {lowMaxPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    lowMaxPointOne === 35
                                      ? 'css-cult-rep-point-one'
                                      : lowMaxPointOne > 33
                                      ? 'css-cult-rep-point-4 '
                                      : lowMaxPointOne < 32
                                      ? 'css-cult-rep-r'
                                      : lowMaxPointOne === 33
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w  pt-0 '
                                : 'css-cultural-rating-w  pt-0'
                            }>
                            <div
                              style={{
                                height: '13px',
                                marginBottom: '80px',
                              }}
                            />
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {lowTscoreTwo > 0 ? (
                                <div
                                  className={
                                    lowTscoreTwo === 40
                                      ? 'css-tscore-m-l-4'
                                      : lowTscoreTwo === 39
                                      ? 'css-tscore-m-l'
                                      : lowTscoreTwo === 38
                                      ? 'css-tscore-m-l-1'
                                      : lowTscoreTwo === 37
                                      ? 'css-tscore-m-l-2'
                                      : lowTscoreTwo === 36
                                      ? 'css-tscore-m-l-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {lowMinPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    lowMinPointTwo === 40
                                      ? 'css-cult-rep-point'
                                      : lowMinPointTwo > 38
                                      ? 'css-cult-rep-point-4'
                                      : lowMinPointTwo < 37
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {lowMaxPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    lowMaxPointTwo === 40
                                      ? 'css-cult-rep-point'
                                      : lowMaxPointTwo > 38
                                      ? 'css-cult-rep-point-4'
                                      : lowMaxPointTwo < 37
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w css-cult-rep-solid-b pt-0 '
                                : 'css-cultural-rating-w css-cult-rep-solid-b pt-0'
                            }>
                            {idx === 0 ? (
                              <div
                                style={{
                                  borderRight: '1px solid gray',
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                                className=""
                              />
                            ) : (
                              <div
                                style={{
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                              />
                            )}
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {moderateLowTScoreOne > 0 ? (
                                <div
                                  className={
                                    moderateLowTScoreOne === 45
                                      ? 'css-tscore-m-r-4'
                                      : moderateLowTScoreOne === 41
                                      ? 'css-tscore-m-r'
                                      : moderateLowTScoreOne === 42
                                      ? 'css-tscore-m-r-1'
                                      : moderateLowTScoreOne === 43
                                      ? 'css-tscore-m-r-2'
                                      : moderateLowTScoreOne === 44
                                      ? 'css-tscore-m-r-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond  css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {moderateLowMinPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    moderateLowMinPointOne === 45
                                      ? 'css-cult-rep-point-one'
                                      : moderateLowMinPointOne > 43
                                      ? 'css-cult-rep-point-4'
                                      : moderateLowMinPointOne < 42
                                      ? 'css-cult-rep-r'
                                      : moderateLowMinPointOne === 43
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {moderateLowMaxPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    moderateLowMaxPointOne === 45
                                      ? 'css-cult-rep-point-one'
                                      : moderateLowMaxPointOne > 43
                                      ? 'css-cult-rep-point-4'
                                      : moderateLowMaxPointOne < 42
                                      ? 'css-cult-rep-r'
                                      : moderateLowMaxPointOne === 43
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w  pt-0 '
                                : 'css-cultural-rating-w  pt-0 '
                            }>
                            <div
                              style={{
                                height: '13px',
                                marginBottom: '80px',
                              }}
                            />
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {moderateLowTScoreTwo > 0 ? (
                                <div
                                  className={
                                    moderateLowTScoreTwo === 50
                                      ? 'css-tscore-m-l-4'
                                      : moderateLowTScoreTwo === 49
                                      ? 'css-tscore-m-l'
                                      : moderateLowTScoreTwo === 48
                                      ? 'css-tscore-m-l-1'
                                      : moderateLowTScoreTwo === 47
                                      ? 'css-tscore-m-l-2'
                                      : moderateLowTScoreTwo === 46
                                      ? 'css-tscore-m-l-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {moderateLowMinPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    moderateLowMinPointTwo === 50
                                      ? 'css-cult-rep-point'
                                      : moderateLowMinPointTwo > 48
                                      ? 'css-cult-rep-point-4'
                                      : moderateLowMinPointTwo < 47
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {moderateLowMaxPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    moderateLowMaxPointTwo === 50
                                      ? 'css-cult-rep-point'
                                      : moderateLowMaxPointTwo > 48
                                      ? 'css-cult-rep-point-4'
                                      : moderateLowMaxPointTwo < 47
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w css-cult-rep-dash-b pt-0 '
                                : 'css-cultural-rating-w css-cult-rep-dash-b pt-0 '
                            }>
                            {idx === 0 ? (
                              <div
                                style={{
                                  borderRight: '1px solid gray',
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                                className=""
                              />
                            ) : (
                              <div
                                style={{
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                              />
                            )}
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {moderateHighTScoreOne > 0 ? (
                                <div
                                  className={
                                    moderateHighTScoreOne === 55
                                      ? 'css-tscore-m-r-4'
                                      : moderateHighTScoreOne === 51
                                      ? 'css-tscore-m-r'
                                      : moderateHighTScoreOne === 52
                                      ? 'css-tscore-m-r-1'
                                      : moderateHighTScoreOne === 53
                                      ? 'css-tscore-m-r-2'
                                      : moderateHighTScoreOne === 54
                                      ? 'css-tscore-m-r-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {moderateHighMinPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    moderateHighMinPointOne === 55
                                      ? 'css-cult-rep-point-one'
                                      : moderateHighMinPointOne > 53
                                      ? 'css-cult-rep-point-4 '
                                      : moderateHighMinPointOne < 52
                                      ? 'css-cult-rep-r'
                                      : moderateHighMinPointOne === 53
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {moderateHighMaxPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    moderateHighMaxPointOne === 55
                                      ? 'css-cult-rep-point-one'
                                      : moderateHighMaxPointOne > 53
                                      ? 'css-cult-rep-point-4 '
                                      : moderateHighMaxPointOne < 52
                                      ? 'css-cult-rep-r'
                                      : moderateHighMaxPointOne === 53
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w  pt-0'
                                : 'css-cultural-rating-w  pt-0'
                            }>
                            <div
                              style={{
                                height: '13px',
                                marginBottom: '80px',
                              }}
                            />
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {moderateHighTScoreTwo > 0 ? (
                                <div
                                  className={
                                    moderateHighTScoreTwo === 60
                                      ? 'css-tscore-m-l-4'
                                      : moderateHighTScoreTwo === 59
                                      ? 'css-tscore-m-l'
                                      : moderateHighTScoreTwo === 58
                                      ? 'css-tscore-m-l-1'
                                      : moderateHighTScoreTwo === 57
                                      ? 'css-tscore-m-l-2'
                                      : moderateHighTScoreTwo === 56
                                      ? 'css-tscore-m-l-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {moderateHighMinPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    moderateHighMinPointTwo === 60
                                      ? 'css-cult-rep-point'
                                      : moderateHighMinPointTwo > 58
                                      ? 'css-cult-rep-point-4'
                                      : moderateHighMinPointTwo < 57
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {moderateHighMaxPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    moderateHighMaxPointTwo === 60
                                      ? 'css-cult-rep-point'
                                      : moderateHighMaxPointTwo > 58
                                      ? 'css-cult-rep-point-4'
                                      : moderateHighMaxPointTwo < 57
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w css-cult-rep-solid-b pt-0 '
                                : 'css-cultural-rating-w css-cult-rep-solid-b pt-0 '
                            }>
                            {idx === 0 ? (
                              <div
                                style={{
                                  borderRight: '1px solid gray',
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                                className=""
                              />
                            ) : (
                              <div
                                style={{
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                              />
                            )}
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {highTScoreOne > 0 ? (
                                <div
                                  className={
                                    highTScoreOne === 65
                                      ? 'css-tscore-m-r-4'
                                      : highTScoreOne === 61
                                      ? 'css-tscore-m-r'
                                      : highTScoreOne === 62
                                      ? 'css-tscore-m-r-1'
                                      : highTScoreOne === 63
                                      ? 'css-tscore-m-r-2'
                                      : highTScoreOne === 64
                                      ? 'css-tscore-m-r-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {highMinPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    highMinPointOne === 65
                                      ? 'css-cult-rep-point-one'
                                      : highMinPointOne > 63
                                      ? 'css-cult-rep-point-4 '
                                      : highMinPointOne < 62
                                      ? 'css-cult-rep-r'
                                      : highMinPointOne === 63
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {highMaxPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    highMaxPointOne === 65
                                      ? 'css-cult-rep-point-one'
                                      : highMaxPointOne > 63
                                      ? 'css-cult-rep-point-4 '
                                      : highMaxPointOne < 62
                                      ? 'css-cult-rep-r'
                                      : highMaxPointOne === 63
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w  pt-0 '
                                : 'css-cultural-rating-w  pt-0 '
                            }>
                            <div
                              style={{
                                height: '13px',
                                marginBottom: '80px',
                              }}
                            />
                            <div
                              className={`w-100 d-flex justify-content-center css-bot position-relative`}>
                              {highTScoreTwo > 0 ? (
                                <div
                                  className={
                                    highTScoreTwo === 70
                                      ? 'css-tscore-m-l-4'
                                      : highTScoreTwo === 69
                                      ? 'css-tscore-m-l'
                                      : highTScoreTwo === 68
                                      ? 'css-tscore-m-l-1'
                                      : highTScoreTwo === 67
                                      ? 'css-tscore-m-l-2'
                                      : highTScoreTwo === 66
                                      ? 'css-tscore-m-l-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {highMinPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    highMinPointTwo === 70
                                      ? 'css-cult-rep-point'
                                      : highMinPointTwo > 68
                                      ? 'css-cult-rep-point-4'
                                      : highMinPointTwo < 67
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {highMaxPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    highMaxPointTwo === 70
                                      ? 'css-cult-rep-point'
                                      : highMaxPointTwo > 68
                                      ? 'css-cult-rep-point-4'
                                      : highMaxPointTwo < 67
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w css-cult-rep-dash-b pt-0 '
                                : 'css-cultural-rating-w css-cult-rep-dash-b pt-0 '
                            }>
                            {idx === 0 ? (
                              <div
                                style={{
                                  borderRight: '1px solid gray',
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                                className=""
                              />
                            ) : (
                              <div
                                style={{
                                  height: '13px',
                                  marginBottom: '80px',
                                }}
                              />
                            )}
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {veryHighTScoreOne > 0 ? (
                                <div
                                  className={
                                    veryHighTScoreOne === 75
                                      ? 'css-tscore-m-r-4'
                                      : veryHighTScoreOne === 71
                                      ? 'css-tscore-m-r'
                                      : veryHighTScoreOne === 72
                                      ? 'css-tscore-m-r-1'
                                      : veryHighTScoreOne === 73
                                      ? 'css-tscore-m-r-2'
                                      : veryHighTScoreOne === 74
                                      ? 'css-tscore-m-r-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {veryHighMinPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    veryHighMinPointOne === 75
                                      ? 'css-cult-rep-point-one'
                                      : veryHighMinPointOne > 73
                                      ? 'css-cult-rep-point-4 '
                                      : veryHighMinPointOne < 72
                                      ? 'css-cult-rep-r'
                                      : veryHighMinPointOne === 73
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {veryHighMaxPointOne > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    veryHighMaxPointOne === 75
                                      ? 'css-cult-rep-point-one'
                                      : veryHighMaxPointOne > 73
                                      ? 'css-cult-rep-point-4 '
                                      : veryHighMaxPointOne < 72
                                      ? 'css-cult-rep-r'
                                      : veryHighMaxPointOne === 73
                                      ? 'css-left'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td
                            className={
                              idx === 0
                                ? 'css-cultural-rating-w  pt-0 '
                                : 'css-cultural-rating-w  pt-0 '
                            }>
                            <div
                              style={{
                                height: '13px',
                                marginBottom: '80px',
                              }}
                            />
                            <div className="w-100 d-flex justify-content-center css-bot position-relative">
                              {veryHighTScoreTwo > 0 ? (
                                <div
                                  className={
                                    veryHighTScoreTwo === 80
                                      ? 'css-right-80-score'
                                      : veryHighTScoreTwo === 79
                                      ? 'css-tscore-m-l'
                                      : veryHighTScoreTwo === 78
                                      ? 'css-tscore-m-l-1'
                                      : veryHighTScoreTwo === 77
                                      ? 'css-tscore-m-l-2'
                                      : veryHighTScoreTwo === 76
                                      ? 'css-tscore-m-l-3'
                                      : ''
                                  }>
                                  <div
                                    className={`diamond css-t-score-color-${band}`}
                                  />
                                </div>
                              ) : null}
                              {veryHighMinPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    veryHighMinPointTwo === 80
                                      ? 'css-cult-rep-point'
                                      : veryHighMinPointTwo > 78
                                      ? 'css-cult-rep-point-4'
                                      : veryHighMinPointTwo < 77
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                              {veryHighMaxPointTwo > 0 ? (
                                <div
                                  className={`cult-row-points-${idx} ${
                                    veryHighMaxPointTwo === 80
                                      ? 'css-cult-rep-point'
                                      : veryHighMaxPointTwo > 78
                                      ? 'css-cult-rep-point-4'
                                      : veryHighMaxPointTwo < 77
                                      ? 'css-cult-rep-r'
                                      : ''
                                  } position-absolute`}
                                />
                              ) : null}
                            </div>
                          </td>
                          <td className="css-cultural-desc-w pl-2 vertical-align-middle cult-rep-fsz-desc text-dark text-center css-cult-rep-solid-b text-left">
                            {highDesc}
                          </td>
                          <td className="vertical-align-middle">
                            <div className="d-flex justify-content-center">
                              <div className="css-cult-rep-band-circle-o">
                                <div
                                  className={`css-cult-rep-band-${band} css-outcome-color-${band} cul-f-sz`}>
                                  {band}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="vertical-align-middle css-supp-behv-fsz">
                            {desc &&
                              Array.isArray(desc) &&
                              desc.length > 0 &&
                              desc.map((r) => (
                                <div style={{ maxWidth: 540 }} className="mt-1">
                                  {r}
                                </div>
                              ))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <svg
                  ref={this.cultSvgRef}
                  style={{ height: '100%' }}
                  className="w-100"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    ref={this.cultPathRef}
                    id="xrpath-1"
                    stroke="#80808054"
                    className="css-cul-svg-sw"
                    fill="transparent"
                  />
                </svg>
              </div>
            </Fragment>
          )}
        {status === 'personality-profile-outcome' &&
          personalityOutcomeData &&
          Array.isArray(personalityOutcomeData) &&
          personalityOutcomeData.length > 0 && (
            <Fragment>
              <div
                id="personality-profile-outcome"
                className="pslp-cpi-outcome-h pslp-cpi-table-width w-100 pr-2">
                <table className="w-100">
                  <thead>
                    <th></th>
                    <th className="personality-profile-table pl-2 header-width text-center">
                      Very Low
                    </th>
                    <th className="personality-profile-table pl-2 header-width text-center">
                      Low
                    </th>
                    <th
                      colSpan={2}
                      className="personality-profile-table pl-2 header-width text-center">
                      Moderate
                    </th>
                    <th className="personality-profile-table pl-2 header-width text-center">
                      {' '}
                      High
                    </th>
                    <th className="personality-profile-table pl-2 header-width text-center">
                      Very High
                    </th>
                    <th className="personality-profile-table pl-2 header-width"></th>
                    <th></th>
                  </thead>
                  <tbody className="x-table-body css-outcome-body">
                    {personalityOutcomeData.map((res, idx) => {
                      const {
                        name,
                        veryLow,
                        low,
                        moderateLow,
                        moderateHigh,
                        high,
                        veryHigh,
                        finalOutcome,
                        color,
                      } = res;
                      return (
                        <tr
                          style={{ height: '40px' }}
                          className={idx <= 3 && 'otcome-border-btm'}>
                          <td
                            className={`${color} outcome-table-name text-center css-desc-fsz`}>
                            {name}
                          </td>
                          <td
                            className={`outcome-table-solid-border pslp-count-w ${color}`}>
                            {veryLow > 0 ? (
                              <span className="x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-dashed-border  pslp-count-w ${color}`}>
                            {low > 0 ? (
                              <span className="x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-solid-border pslp-count-w ${color}`}>
                            {moderateLow > 0 ? (
                              <span className="x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-dashed-border pslp-count-w ${color}`}>
                            {moderateHigh > 0 ? (
                              <span className="x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-solid-border pslp-count-w ${color}`}>
                            {high > 0 ? (
                              <span className="x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-dashed-border pslp-count-w ${color}`}>
                            {veryHigh > 0 ? (
                              <span className="x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`font-weight-bold outcome-table-solid-border pslp-count-w outcome-b-r css-outcome-color-${finalOutcome}`}>
                            {finalOutcome}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <svg
                  ref={this.svgRef}
                  style={{ height: '100%' }}
                  className="w-100"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    ref={this.pathRef}
                    id="xrpath-1"
                    stroke="#1f4e79"
                    strokeWidth="2"
                    fill="transparent"
                  />
                </svg>
              </div>
            </Fragment>
          )}
        {status === 'personality-profile' &&
          personalityGraphData &&
          Array.isArray(personalityGraphData) &&
          personalityGraphData.length > 0 && (
            <Fragment>
              <div
                id="plsp-main-graph"
                className="pslp-graph-height table-width w-100">
                <table className="pslp-tb-w w-100 table-layout-fixed">
                  <thead className="css-outcome-head">
                    <th style={{ width: 60 }}></th>
                    <th className="css-pslp-metric-w"></th>
                    <th className="css-desc-fsz css-pslp-desc-w text-center text-dark">
                      Low Score Descriptors
                    </th>
                    <th className="css-rating-w"></th>
                    <th className="css-rating-w"></th>
                    <th className="css-rating-w"></th>
                    <th className="css-rating-w"></th>
                    <th className="css-rating-w"></th>
                    <th className="css-rating-w"></th>
                    <th className="css-desc-fsz css-pslp-desc-w text-center text-dark">
                      High Score Descriptors
                    </th>
                    <th></th>
                  </thead>
                  <tbody className="css-outcome-body">
                    {personalityGraphData.map((res, idx) => {
                      const {
                        metric,
                        lowDesc,
                        highDesc,
                        finalOutcome,
                        veryLow,
                        low,
                        moderateLow,
                        moderateHigh,
                        high,
                        veryHigh,
                        color,
                        name,
                      } = res || {};
                      return (
                        <tr
                          style={{ height: '40px' }}
                          className={`css-desc-fsz otcome-border-btm-b`}>
                          {name && (
                            <td
                              style={{ maxWidth: 60 }}
                              className={` metric-pslp-cpi-f-sz outcome-table-name css-pslp-desc-align ${color}`}
                              rowSpan={6}>
                              <div className="metric-trs mt-5">{name}</div>
                            </td>
                          )}

                          <td
                            className={`css-pslp-metric-w outcome-table-name css-pslp-desc-align ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {metric}
                          </td>
                          <td
                            className={`css-pslp-desc-w css-pslp-desc-align ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {lowDesc}
                          </td>
                          <td
                            className={`outcome-table-solid-border ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {veryLow > 0 ? (
                              <span className="p-x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-dashed-border ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {low > 0 ? (
                              <span className="p-x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-solid-border ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {moderateLow > 0 ? (
                              <span className="p-x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-dashed-border ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {moderateHigh > 0 ? (
                              <span className="p-x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-solid-border ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {high > 0 ? (
                              <span className="p-x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`outcome-table-dashed-border ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {veryHigh > 0 ? (
                              <span className="p-x-row-point">
                                <i
                                  className="circle-fill-points fa fa-circle-o"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </td>
                          <td
                            className={`css-pslp-desc-w outcome-table-solid-border css-pslp-desc-align ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } ${color}`}>
                            {highDesc}
                          </td>
                          <td
                            className={`font-weight-bold outcome-table-solid-border ${
                              idx != 5 &&
                              idx != 11 &&
                              idx != 17 &&
                              idx != 23 &&
                              idx != 29
                                ? 'otcome-border-btm'
                                : 'otcome-border-btm-b'
                            } css-outcome-color-${finalOutcome}`}>
                            {finalOutcome}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <svg
                  ref={this.personalitySvgRef}
                  style={{ height: '100%' }}
                  className="w-100"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    ref={this.personalityPathRef}
                    id="xrpath-1"
                    stroke="#1f4e79"
                    strokeWidth="2"
                    fill="transparent"
                  />
                </svg>
              </div>
            </Fragment>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  celeryData: state.HighLevelReports.celeryData,
});

const mapDispatchToProps = {
  generateCeleryTask,
  downloadHighLevelReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
