import React, { useEffect, useState } from 'react';
import { Loading } from 'carbon-components-react';
import { v2BaseUrl } from '../../utils/config';
import callApi from '../../actions/callApi';
import { useParams } from 'react-router-dom';
import { xapi } from '../../utils/xapi';

const getPSBriefingDeckDoc = (selectionYear) => {
  const url = `${v2BaseUrl}/commonHelper/file/ps-briefing-deck?selectionYear=${selectionYear}`;
  const params = {
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return xapi(params);
};

const PSBriefingDeck = (props) => {
  const { appId, jobId } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showLoader, setLoading] = useState(false);
  const uid = `${appId}-${jobId}`;

  const getDocData = async () => {
    if (!jobId) return;
    setLoading(true);

    await getPSBriefingDeckDoc(jobId)
      .then((resp) => {
        const { url } = resp ?? {};
        if (url) setPdfUrl(url);
      })
      .catch((err) => {
        console.error(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    getDocData();
  }, []);

  return (
    <div className="x-doc-outer-con">
      {showLoader !== true && pdfUrl && (
        <div id={`x-doc-wrapper-${uid}`}>
          <iframe
            style={{ height: '90vh' }}
            src={pdfUrl}
            height="100%"
            width="100%"
          />
        </div>
      )}
      {showLoader === true && (
        <div className="bx--row justify-content-around">
          <Loading withOverlay={false} />
        </div>
      )}
    </div>
  );
};

export default PSBriefingDeck;
