import React from 'react';

//Common Components
import GenericDataTable from '../common/GenericDataTable';

//Common packages
import moment from 'moment';
import { TextInput } from 'carbon-components-react';

//Images
import MaleImg from '../../assets/images/male-img.png';
import FemaleImg from '../../assets/images/female-img.png';
import { Progress } from 'react-sweet-progress';

const DashboardInterviewsMock = (props) => {
  const { interviewsData, showDate, showColors, push } = props || {};
  return (
    <div>
      <div className=" bx--row ">
        <div className="bx--col-xs-12 bx--col-sm-12 bx--col-md-12 bx--col-lg-12 mb-2">
          <GenericDataTable
            otherActions={[]}
            tbodyClass={showColors ? 'candidate-background-color' : ''}
            searchable={false}
            rows={
              interviewsData &&
              Array.isArray(interviewsData) &&
              interviewsData.length > 0
                ? interviewsData.map((interview, idx) => {
                    const {
                      firstName,
                      lastName,
                      ccaTier,
                      gba,
                      academicResults,
                      remarks,
                      rank,
                      schoolName,
                      psychometricRes,
                      preferences,
                      gender,
                      criteria,
                    } = interview;
                    const { wag, anra } = psychometricRes || {};
                    const { l, r } = ccaTier || {};
                    const { performance, future, award, average } = gba || {};
                    const { psc, gp, pw, mtl } = academicResults || {};
                    let wagColor = '';
                    if (wag > 50) {
                      wagColor = 'csc-th--h';
                    } else if (wag >= 31 && wag <= 45) {
                      wagColor = 'css-th-green-l';
                    } else {
                      wagColor = 'csc-th--vl';
                    }
                    let anraColor = '';
                    if (anra > 90) {
                      anraColor = 'csc-th--h';
                    } else if (anra >= 25 && anra <= 89) {
                      anraColor = 'css-th-green-l';
                    } else {
                      anraColor = 'csc-th--vl';
                    }
                    return {
                      isExpanded: false,
                      id: `row_${idx}`,
                      header_0: (
                        <div>
                          <div className="d-flex ml-2">
                            <div
                              onClick={() => {
                                push && push('/public/candidate');
                              }}
                              style={{ fontWeight: 'bold' }}
                              className="h5 mb-0 xpa-link">
                              {firstName} {lastName}
                            </div>
                            <div title={gender} className="ml-2">
                              {gender &&
                              gender.toString().toLowerCase() === 'male' ? (
                                <img
                                  style={{ width: '20px' }}
                                  src={MaleImg}
                                  alt="Male"
                                />
                              ) : null}
                              {gender &&
                              gender.toString().toLowerCase() === 'female' ? (
                                <img
                                  style={{ width: '20px' }}
                                  src={FemaleImg}
                                  alt="female"
                                />
                              ) : null}
                            </div>
                          </div>
                          {!showDate && (
                            <div className="mt-2 mb-2 ml-2">
                              {idx === 0 && (
                                <div>
                                  Main Interviewer:{' '}
                                  <strong title="Main Interviewer" className="">
                                    Mr. Lee Tzu Yang
                                  </strong>
                                </div>
                              )}
                              {idx === 1 && (
                                <div>
                                  Main Interviewer:{' '}
                                  <strong title="Main Interviewer" className="">
                                    Mr. Kwa Chong Seng
                                  </strong>
                                </div>
                              )}
                              {idx === 2 && (
                                <div>
                                  Main Interviewer:{' '}
                                  <strong title="Main Interviewer" className="">
                                    Ms. Chua Sock Koong
                                  </strong>
                                </div>
                              )}

                              {idx === 3 && (
                                <div>
                                  Main Interviewer:{' '}
                                  <strong title="Main Interviewer" className="">
                                    Mr. Michael Lim
                                  </strong>
                                </div>
                              )}
                            </div>
                          )}
                          {showDate === true && (
                            <div className="mt-2 mb-2 ml-2">
                              {idx === 0 && (
                                <div>
                                  Main Interviewer:{' '}
                                  <strong title="Main Interviewer" className="">
                                    Mr. Po'ad Mattar
                                  </strong>
                                </div>
                              )}
                              {idx === 1 && (
                                <div>
                                  Main Interviewer:{' '}
                                  <strong title="Main Interviewer" className="">
                                    Mr. Edward D'Silva
                                  </strong>
                                </div>
                              )}
                              {idx === 2 && (
                                <div>
                                  Main Interviewer:{' '}
                                  <strong title="Main Interviewer" className="">
                                    Ms. Chua Sock Koong
                                  </strong>
                                </div>
                              )}

                              {idx === 3 && (
                                <div>
                                  Main Interviewer:{' '}
                                  <strong title="Main Interviewer" className="">
                                    Mr. Michael Lim
                                  </strong>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="mt-2 mb-2">
                            <small className="ml-2 mt-0 bx--row">
                              {schoolName}
                            </small>
                          </div>
                          <div className="w-50 ml-2 mb-2">
                            School Rank: <strong>{rank}</strong>
                          </div>
                          <div className="w-50 ml-2 mb-2">
                            Criteria: <strong>{criteria}</strong>
                          </div>
                          {showDate === true && (
                            <div className="ml-2">
                              Interview Date:{' '}
                              {moment()
                                .add(idx + 1, 'd')
                                .format('DD/MM/YYYY')}
                            </div>
                          )}
                          <h6 className="ml-2 mt-2">CCA Tier</h6>
                          <div className="ml-2 mt-2 d-flex">
                            <div className="academic-results-card text-center mr-2">
                              <div className="h5 text-black mb-0">{l}</div>
                              <small className="">Leadership</small>
                            </div>
                            <div className="academic-results-card text-center mr-2">
                              <div className="h5 text-black mb-0">{r}</div>
                              <small className="">Representation</small>
                            </div>
                          </div>
                          <div className="mt-2 ml-2">
                            <div className="mt-2">
                              <h6>Preferences</h6>
                            </div>
                            <div className="">
                              {preferences &&
                                Array.isArray(preferences) &&
                                preferences.length > 0 &&
                                preferences.map((res, index) => (
                                  <span className="mt-1">{res} </span>
                                ))}
                            </div>
                          </div>
                        </div>
                      ),
                      header_1: (
                        <div>
                          <div className="bx--row">
                            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 ml-3 ml-lg-0 ml-xl-0 pl-2">
                              <h6>Psychometric Results</h6>
                              <div className="">
                                <div className="d-flex">
                                  <div className="mt-2">
                                    <div className="d-flex">
                                      <div
                                        className={`csc-th--block ${wagColor}`}>
                                        <div className="csc-pos--top">
                                          {wag}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Critical Thinking{' '}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 ml-2">
                                    <div className="d-flex">
                                      <div
                                        className={`csc-th--block ${anraColor}`}>
                                        <div className="csc-pos--top">
                                          {anra}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Numerical Reasoning
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 ml-3 ml-lg-0 ml-xl-0 pl-2">
                              <h6>GBA Profile Results</h6>
                              <div className="">
                                <div className="d-flex">
                                  <div className="mt-2">
                                    <div className="d-flex">
                                      <div className="csc-th--block csc-th--h">
                                        <div className="csc-pos--top">
                                          {idx < 1 ? performance : 90}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Performance Based{' '}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 ml-2">
                                    <div className="d-flex">
                                      <div className="csc-th--block csc-th--l">
                                        <div className="csc-pos--top">
                                          {idx < 1 ? future : 65}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Future Driven
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 ml-2">
                                    <div className="d-flex">
                                      <div className="csc-th--block csc-th--l">
                                        <div className="csc-pos--top">
                                          {idx < 1 ? award : 59}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Award
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-2 ml-2">
                                    <div className="d-flex">
                                      <div className="csc-th--block csc-th--l">
                                        <div className="csc-pos--top">
                                          {idx < 1 ? 78 : 71}
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="csc-pos--top ml-1 h6">
                                          Average
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bx--row mt-2">
                            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 ml-3 ml-lg-0 ml-xl-0 pl-0">
                              <h6 className="ml-2 mb-2">Academic Results</h6>
                              {idx === 0 ? (
                                <div className="d-flex ml-2">
                                  <div className="academic-results-card text-center">
                                    <div className="h5 text-black mt-2">
                                      4.0/4.0
                                    </div>
                                    <small className="">GPA</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div>
                                      Diploma in Infocomm and Security, 2020
                                    </div>
                                    <div>GCE 'O' Level, 2017</div>
                                    <div>English Language: D7</div>
                                    <div>Chinese Language: C6</div>
                                  </div>
                                </div>
                              ) : null}
                              {idx === 1 ? (
                                <div className="d-flex ml-2">
                                  <div className="academic-results-card text-center">
                                    <div className="h5 text-black mb-0">69</div>
                                    <small className="">PSC Points</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">B</div>
                                    <small className="">GP</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">A</div>
                                    <small className="">PW</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">B3</div>
                                    <small className="">HMTL</small>
                                  </div>
                                </div>
                              ) : null}
                              {idx === 2 ? (
                                <div className="d-flex ml-2">
                                  <div className="academic-results-card text-center">
                                    <div className="h6 text-black mb-1">
                                      4.9 / 5.0
                                    </div>
                                    <small className="">CAP</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">-</div>
                                    <small className="">GP</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">-</div>
                                    <small className="">PW</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">D</div>
                                    <small className="">MTL</small>
                                  </div>
                                </div>
                              ) : null}
                              {idx === 3 ? (
                                <div className="d-flex ml-2">
                                  <div className="academic-results-card text-center">
                                    <div className="h6 text-black mb-1">42</div>
                                    <small className="">IB Score </small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">-</div>
                                    <small className="">GP</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">-</div>
                                    <small className="">PW</small>
                                  </div>
                                  <div className="academic-results-card text-center ml-2">
                                    <div className="h5 text-black mb-0">6</div>
                                    <small className="">CB</small>
                                  </div>
                                </div>
                              ) : null}
                              <div className="mt-3 ml-2">
                                <h6>Remarks</h6>
                                <small>
                                  <div>
                                    <div>
                                      On track to being awarded Honours (Highest
                                      Distinction)
                                    </div>
                                    <div className="mt-1">
                                      Referred to ministries in 2019
                                    </div>
                                    <div className="mt-1">
                                      French (O Level): A1
                                    </div>
                                  </div>
                                </small>
                              </div>
                            </div>
                            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 ml-lg-0 ml-xl-0 ml-3 pl-2">
                              <div className="">
                                <h6>Psychological Interview Rating</h6>
                                <div className="mt-2">
                                  <div className="d-flex align-items-center">
                                    <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                      Leadership Qualities
                                    </div>
                                    <div className="csc-th--block csc-th--h">
                                      <div className="csc-pos--top">{5}</div>
                                    </div>
                                    <div className="ml-2">(Excellent)</div>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <div className="d-flex align-items-center">
                                    <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                      Intellectual Qualities
                                    </div>
                                    <div className="csc-th--block csc-th--h">
                                      <div className="csc-pos--top">{4}</div>
                                    </div>
                                    <div className="ml-2">(Excellent)</div>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <div className="d-flex align-items-center">
                                    <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                      Resilience
                                    </div>
                                    <div className="csc-th--block csc-th--l">
                                      <div className="csc-pos--top">{3}</div>
                                    </div>
                                    <div className="ml-2">(Moderate)</div>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <div className="d-flex align-items-center">
                                    <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                      Character and Values
                                    </div>
                                    <div className="csc-th--block csc-th--l">
                                      <div className="csc-pos--top">{2}</div>
                                    </div>
                                    <div className="ml-2">(Moderate)</div>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <div className="d-flex align-items-center">
                                    <div className="bx--col-lg-6 h6 bx--col-md-5 mt-2 pl-0">
                                      Interpersonal Skills
                                    </div>
                                    <div className="csc-th--block csc-th--vl">
                                      <div className="csc-pos--top">{1}</div>
                                    </div>
                                    <div className="ml-2">(Very Low)</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    };
                  })
                : []
            }
            headers={[
              { key: 'header_0', header: 'Candidate Info' },
              { key: 'header_1', header: 'Summary' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardInterviewsMock;
