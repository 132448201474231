const timeperiodOptions = [
  { label: 'Select', value: null },
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
  { label: 'Full', value: 'Full' },
];

const meetingTypeOptions = [
  { label: 'PS', value: 'PS' },
  { label: 'PSSB', value: 'PSSB' },
  { label: 'PSSB/Commission', value: 'PSSB/Commission' },
  { label: 'Commission', value: 'Commission' },
  { label: 'Event', value: 'Event' },
  // { label: 'All', value: 'All' },
];

const meetingStatusOptions = [
  { label: 'Tentative', value: 'Tentative' },
  { label: 'Confirmed', value: 'Confirmed' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Completed', value: 'Completed' },
  // { label: 'All', value: 'All' },
];

const attendanceTypeOptions = [
  { label: 'Physical', value: 'Physical' },
  { label: 'Virtual', value: 'Virtual' },
];

const attendanceStatusOptions = [
  { label: 'Select', value: null },
  { label: 'Available', value: 'Available' },
  {
    label: 'Not Available',
    value: 'Not Available',
  },
  {
    label: 'To Be Confirmed',
    value: 'To Be Confirmed',
  },
  { label: 'Scheduled', value: 'Scheduled' },
];

const carParkingFeeOptions = [
  { label: 'Select', value: null },
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

export {
  timeperiodOptions,
  meetingTypeOptions,
  meetingStatusOptions,
  attendanceTypeOptions,
  attendanceStatusOptions,
  carParkingFeeOptions,
};
