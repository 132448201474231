import React, { Fragment } from 'react';
import moment from 'moment';
import GenericDataTable from '../../components/common/GenericDataTable';
import { getCCATierData } from '../../components/private/CandidatePersonalDetails';
import NoContentBox from '../../components/common/NoContentBox';
import { TooltipIcon } from 'carbon-components-react';
import { LeadershipTooltip, RepresentationTooltip } from './Tooltips';
// import './CandidateAcvIntCCA.css';
import './CurrentBatchInfo.css';

const CandidateInternship = (props) => {
  const {
    internship,
    handleOnSupervisorsCommentChange,
    state,
    handleSubmitSupervisorComments,
    roleId,
  } = props;
  let finalInternshipDetails = [];
  if (internship && Array.isArray(internship) && internship.length > 0) {
    finalInternshipDetails = internship.filter((res) => {
      const { startDate, internshipDescription, supervisorName, endDate } =
        res || {};
      if (startDate || endDate || supervisorName || internshipDescription) {
        return true;
      }
      return false;
    });
  }
  return (
    <div className="mt-3 mb-3">
      {finalInternshipDetails &&
        Array.isArray(finalInternshipDetails) &&
        finalInternshipDetails.length > 0 &&
        finalInternshipDetails.map((res) => {
          const {
            company,
            department,
            startDate,
            internshipDescription,
            supervisorName,
            endDate,
            internshipdetailsId,
          } = res || {};
          const { displayName: compDisplayName } = company || {};
          const { displayName: deptDisplayName } = department || {};
          return (
            <div className="mt-3 mb-3">
              <GenericDataTable
                className=""
                otherActions={[]}
                headers={[
                  {
                    key: 'header_0',
                    header: 'Internship',
                    className: 'w-50',
                  },
                  { key: 'header_1', header: 'Details' },
                ]}
                rows={[
                  { name: 'Organisation', value: compDisplayName || '-' },
                  { name: 'Department / Unit', value: deptDisplayName || '-' },
                  {
                    name: 'Period',
                    value: (
                      <div>
                        {startDate || endDate ? (
                          <div>
                            {`${
                              startDate
                                ? moment(startDate).format('DD MMMM YYYY')
                                : '-'
                            } to ${
                              endDate
                                ? moment(endDate).format('DD MMMM YYYY')
                                : '-'
                            }`}
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    ),
                  },
                  {
                    name: 'Description',
                    value: internshipDescription || '-',
                  },
                  // {
                  //   name: `${supervisorName}\'s comments`,
                  //   value: (
                  //     <div>
                  //       {roleId == 1 || roleId == 6 ? (
                  //         <div>
                  //           {state &&
                  //             state[`success-${internshipdetailsId}`] ===
                  //               true && (
                  //               <div className="css-color-green">
                  //                 Your comments have been updated successfully.
                  //               </div>
                  //             )}
                  //           {state &&
                  //             state[`error-${internshipdetailsId}`] ===
                  //               true && (
                  //               <div className="css-color-red">
                  //                 There was an error while processing your
                  //                 request.
                  //               </div>
                  //             )}
                  //           <TextArea
                  //             placeholder="Please enter your comments"
                  //             rows={3}
                  //             name={`comment-${internshipdetailsId}`}
                  //             id={internshipdetailsId}
                  //             key={internshipdetailsId}
                  //             onChange={handleOnSupervisorsCommentChange}
                  //             value={
                  //               state && state[`comment-${internshipdetailsId}`]
                  //             }
                  //             invalid={
                  //               state && state[`invalid-${internshipdetailsId}`]
                  //             }
                  //             invalidText="Please enter valid comments"
                  //           />
                  //           <div className="mt-2 text-center">
                  //             <Button
                  //               onClick={() => {
                  //                 handleSubmitSupervisorComments(
                  //                   internshipdetailsId
                  //                 );
                  //               }}
                  //               size="small">
                  //               Save
                  //             </Button>
                  //           </div>
                  //         </div>
                  //       ) : (
                  //         <div>
                  //           {(state &&
                  //             state[`comment-${internshipdetailsId}`]) ||
                  //             '-'}
                  //         </div>
                  //       )}
                  //     </div>
                  //   ),
                  // },
                ].map((res, idx) => {
                  const { name, value } = res || {};
                  return {
                    isExpanded: true,
                    id: `row_${idx}`,
                    header_0: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Internship:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className=" ">
                            <div>{name}</div>
                          </div>
                        </div>
                      </div>
                    ),
                    header_1: (
                      <div className="bx--row align-items-center mt-2 mt-xl-0">
                        <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                          Details:
                        </div>
                        <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                          <div className=" ">
                            <div>{value}</div>
                          </div>
                        </div>
                      </div>
                    ),
                  };
                })}
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
            </div>
          );
        })}
      {(!finalInternshipDetails ||
        (finalInternshipDetails &&
          Array.isArray(finalInternshipDetails) &&
          finalInternshipDetails.length <= 0)) && (
        <Fragment>
          <GenericDataTable
            className=""
            otherActions={[]}
            headers={[
              {
                key: 'header_0',
                header: 'Internship',
                className: 'w-50',
              },
              { key: 'header_1', header: 'Details' },
            ]}
            rows={[]}
            selectable={false}
            searchable={false}
            onSearchInputChange={() => {}}
            expandable={false}
            batchActions={false}
          />
          <NoContentBox
            hideTile
            message="There are no internships available for your current selection"
          />
        </Fragment>
      )}
    </div>
  );
};

const CandidateAchievements = (props) => {
  const { achievementDetails } = props || {};
  let finalAchievements = [];
  if (
    achievementDetails &&
    Array.isArray(achievementDetails) &&
    achievementDetails.length > 0
  ) {
    finalAchievements = achievementDetails.filter((res) => {
      const { achievement, year } = res || {};
      if (achievement || year) {
        return true;
      }
      return false;
    });
  }
  return (
    <div className="mt-3 mb-3">
      <GenericDataTable
        className=""
        otherActions={[]}
        headers={[
          {
            key: 'header_0',
            header: 'Achievements',
            className: 'w-50',
          },
          { key: 'header_1', header: 'Year' },
        ]}
        rows={
          finalAchievements &&
          Array.isArray(finalAchievements) &&
          finalAchievements.length > 0
            ? finalAchievements.map((res, idx) => {
                const { achievementId, achievement, year } = res || {};
                return {
                  isExpanded: true,
                  id: achievementId,
                  header_0: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Achievements:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div className=" ">
                          <div className="text-capitalize">
                            {achievement || '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  header_1: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Year:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div className=" ">
                          <div>{year}</div>
                        </div>
                      </div>
                    </div>
                  ),
                };
              })
            : []
        }
        selectable={false}
        searchable={false}
        onSearchInputChange={() => {}}
        expandable={false}
        batchActions={false}
      />
      {(!finalAchievements ||
        (finalAchievements &&
          Array.isArray(finalAchievements) &&
          finalAchievements.length <= 0)) && (
        <NoContentBox
          hideTile
          message="There are no achievements available for your current selection"
        />
      )}
    </div>
  );
};

const CandidateNonAcademicQualifications = (props) => {
  const { nonAcademic } = props || {};
  let finalNonAcademicQuals = [];
  if (nonAcademic && Array.isArray(nonAcademic) && nonAcademic.length > 0) {
    finalNonAcademicQuals = nonAcademic.filter((res) => {
      const { qualification, year } = res || {};
      const { displayName } = qualification || {};
      if (year > 0 || displayName) {
        return true;
      }
      return false;
    });
  }
  return (
    <div className="mt-3 mb-3">
      <GenericDataTable
        className=""
        otherActions={[]}
        headers={[
          {
            key: 'header_0',
            header: 'Non-Academic Qualifications',
            className: 'w-50',
          },
          { key: 'header_1', header: 'Year' },
        ]}
        rows={
          finalNonAcademicQuals &&
          Array.isArray(finalNonAcademicQuals) &&
          finalNonAcademicQuals.length > 0
            ? finalNonAcademicQuals.map((res, idx) => {
                const { naqId, qualification, year } = res || {};
                const { displayName } = qualification || {};
                return {
                  isExpanded: true,
                  id: naqId,
                  header_0: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-4 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Non-Academic Qualifications:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-4 bx--col-sm-6 bx--col-xs-6">
                        <div className=" ">
                          <div className="text-capitalize">{displayName}</div>
                        </div>
                      </div>
                    </div>
                  ),
                  header_1: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-4 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Year:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-4 bx--col-sm-6 bx--col-xs-6">
                        <div className=" ">
                          <div>{year}</div>
                        </div>
                      </div>
                    </div>
                  ),
                };
              })
            : []
        }
        selectable={false}
        searchable={false}
        onSearchInputChange={() => {}}
        expandable={false}
        batchActions={false}
      />
      {(!finalNonAcademicQuals ||
        (finalNonAcademicQuals &&
          Array.isArray(finalNonAcademicQuals) &&
          finalNonAcademicQuals.length <= 0)) && (
        <NoContentBox
          hideTile
          message="There are no non-academic qualifications available for your current selection"
        />
      )}
    </div>
  );
};

const CandidateCCATier = (props) => {
  const { ccatiering, schoolDetails } = props || {};

  const data = {
    ccatiering,
    schoolDetails,
  };
  const finalCCAOrderArr = getCCATierData(data);
  const { leadershipTier, representationTier } =
    (finalCCAOrderArr &&
      Array.isArray(finalCCAOrderArr) &&
      finalCCAOrderArr.length > 0 &&
      finalCCAOrderArr[0]) ||
    {};
  let finalData = [];
  finalData = [
    {
      label: (
        <div>
          <TooltipIcon
            align="center"
            direction="right"
            className="css-tooltip-fill"
            tooltipText={<LeadershipTooltip />}>
            <div className="css-traits-text-c">Leadership</div>
          </TooltipIcon>{' '}
        </div>
      ),
      value: `${leadershipTier ? `Tier ${leadershipTier}` : '-'}`,
    },
    {
      label: (
        <div>
          <TooltipIcon
            className="css-tooltip-fill"
            align="center"
            direction="right"
            tooltipText={<RepresentationTooltip />}>
            <div className="css-traits-text-c">Representation</div>
          </TooltipIcon>{' '}
        </div>
      ),
      value: `${representationTier ? `Tier ${representationTier}` : '-'}`,
    },
  ];
  return (
    <div className="custom-data-table-tooltip">
      <GenericDataTable
        className=""
        otherActions={[]}
        headers={[
          {
            key: 'header_0',
            header: 'CCA',
            className: 'w-50',
          },
          { key: 'header_1', header: 'Tier' },
        ]}
        rows={finalData.map((res, idx) => {
          const { label, value } = res || {};
          return {
            isExpanded: true,
            id: idx + 1,
            header_0: (
              <div className="bx--row align-items-center mt-2 mt-xl-0">
                <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                  CCA:
                </div>
                <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                  <div className=" ">
                    <div>{label}</div>
                  </div>
                </div>
              </div>
            ),
            header_1: (
              <div className="bx--row align-items-center mt-2 mt-xl-0">
                <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                  Tier:
                </div>
                <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                  <div className=" ">
                    <div>{value}</div>
                  </div>
                </div>
              </div>
            ),
          };
        })}
        selectable={false}
        searchable={false}
        onSearchInputChange={() => {}}
        expandable={false}
        batchActions={false}
      />
      {(!finalData ||
        (finalData && Array.isArray(finalData) && finalData.length <= 0)) && (
        <NoContentBox
          hideTile
          message="There are no CCA details available for your current selection"
        />
      )}
    </div>
  );
};
export {
  CandidateInternship,
  CandidateAchievements,
  CandidateNonAcademicQualifications,
  CandidateCCATier,
};
