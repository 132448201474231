const candidateScreeningKeys = [
  'anra_validity_period',
  'wgcta_validity_period',
  'wgiii_validity_period',
  'neopir_validity_period',
  'remarks',
  'test_taken',
  'purpose',
  'nric_fin',
  'full_name',
  'gender',
  'dob',
  'ranra',
  'ranra_test_date',
  'wgcta',
  'wgcta_test_date',
  'WGIII_score',
  'wgiii_test_date',
  'neopir_test_date',

  'RN1',
  'RN2',
  'RN3',
  'RN4',
  'RN5',
  'RN6',
  'RN7',
  'RN8',
  'RN9',
  'RN10',
  'RN11',
  'RN12',
  'RN13',
  'RN14',
  'RN15',
  'RN16',
  'RN17',
  'RN18',
  'RN19',
  'RN20',
  'RN21',
  'RN22',
  'RN23',
  'RN24',
  'RN25',
  'RN26',
  'RN27',
  'RN28',
  'RN29',
  'RN30',
  'RN31',
  'RN32',
  'WG1',
  'WG2',
  'WG3',
  'WG4',
  'WG5',
  'WG6',

  'WG7',

  'WG8',

  'WG9',
  'WG10',
  'WG11',
  'WG12',
  'WG13',
  'WG14',
  'WG15',
  'WG16',
  'WG17',
  'WG18',
  'WG19',

  'WG20',
  'WG21',
  'WG22',
  'WG23',
  'WG24',
  'WG25',
  'WG26',
  'WG27',
  'WG28',
  'WG29',

  'WG30',
  'WG31',
  'WG32',
  'WG33',
  'WG34',
  'WG35',
  'WG36',
  'WG37',
  'WG38',
  'WG39',
  'WG40',
  'WG41',
  'WG42',
  'WG43',
  'WG44',
  'WG45',
  'WG46',
  'WG47',
  'WG48',
  'WG49',
  'WG50',
  'WG51',
  'WG52',
  'WG53',
  'WG54',
  'WG55',
  'WG56',
  'WG57',
  'WG58',
  'WG59',
  'WG60',
  'WG61',
  'WG62',
  'WG63',
  'WG64',
  'WG65',
  'WG66',
  'WG67',
  'WG68',
  'WG69',
  'WG70',
  'WG71',
  'WG72',
  'WG73',
  'WG74',
  'WG75',
  'WG76',
  'WG77',
  'WG78',
  'WG79',
  'WG80',
  'WG3_DAM_11_10_UK',
  'WG3_DAM_11_11_UK',
  'WG3_DAM_11_6_UK',
  'WG3_DAM_11_8_UK',
  'WG3_DAM_1_1_UK',
  'WG3_DAM_1_6_UK',
  'WG3_DAM_1_9_UK',
  'WG3_DAM_2_10_UK',
  'WG3_DAM_2_1_UK',
  'WG3_DAM_2_4_UK',
  'WG3_DAM_2_7_UK',
  'WG3_DAM_3_10_UK',
  'WG3_DAM_3_1_UK',
  'WG3_DAM_3_2_UK',
  'WG3_DAM_3_7_UK',
  'WG3_DAM_3_9_UK',
  'WG3_DAM_9_10_UK',
  'WG3_DAM_9_1_UK',
  'WG3_DAM_9_2_UK',
  'WG3_DAM_9_3_UK',
  'WG3_DAM_9_4_UK',
  'WG3_DAM_9_5_UK',
  'WG3_DAM_9_6_UK',
  'WG3_DAM_9_7_UK',
  'WG3_DNS_1_2_UK',
  'WG3_DNS_1_3_UK',
  'WG3_DNS_1_7_1_UK',
  'WG3_DNS_1_7_2_UK',
  'WG3_DNS_3_3_UK',
  'WG3_DNS_3_4_UK',
  'WG3_DNS_3_7_UK',
  'WG3_ECL_2_3_UK',
  'WG3_ECL_2_5_UK',
  'WG3_ECL_2_7_UK',
  'WG3_ECL_3_3_UK',
  'WG3_ECL_3_6_UK',
  'WG3_ECL_3_7_UK',
  'WG3_ECL_5_10_UK',
  'WG3_ECL_5_2_UK',
  'WG3_ECL_6_1_UK',
  'WG3_ECL_6_4_UK',
  'WG3_EHB_12_1_UK',
  'WG3_EHB_12_5_UK',
  'WG3_EHB_12_6_UK',
  'WG3_EHB_12_7_UK',
  'WG3_EHB_12_8_UK',
  'WG3_EHB_13_3_UK',
  'WG3_EHB_13_5_1_UK',
  'WG3_EHB_13_5_2_UK',
  'WG3_EHB_13_6_UK',
  'WG3_EHB_14_3_UK',
  'WG3_EHB_14_6_UK',
  'WG3_EHB_19_11_UK',
  'WG3_EHB_19_12_UK',
  'WG3_EHB_19_1_UK',
  'WG3_EHB_19_4_UK',
  'WG3_EHB_19_5_UK',
  'WG3_EHB_19_8_UK',
  'WG3_EHB_23_10_UK',
  'WG3_EHB_23_11_UK',
  'WG3_EHB_23_1_UK',
  'WG3_EHB_23_4_UK',
  'WG3_EHB_23_6_UK',
  'WG3_EHB_23_7_UK',
  'WG3_EHB_23_9_UK',
  'WG3_EHB_5_1_UK',
  'WG3_EHB_5_2_UK',
  'WG3_EHB_5_6_UK',
  'WG3_EHB_9_1_UK',
  'WG3_EHB_9_4_UK',
  'WG3_EHB_9_5_UK',
  'WG3_ENS_11_5_UK',
  'WG3_ENS_11_7_UK',
  'WG3_ENS_12_3_UK',
  'WG3_ENS_12_5_UK',
  'WG3_ENS_12_6_UK',
  'WG3_ENS_12_8_UK',
  'WG3_ENS_15_2_1_UK',
  'WG3_ENS_15_2_2_UK',
  'WG3_ENS_15_5_UK',
  'WG3_ENS_15_8_UK',
  'WG3_ENS_1_3_UK',
  'WG3_ENS_1_6_UK',
  'WG3_ENS_2_10_UK',
  'WG3_ENS_2_1_UK',
  'WG3_ENS_2_4_UK',
  'WG3_ENS_2_5_UK',
  'WG3_ENS_2_9_UK',
  'WG3_ENS_3_10_UK',
  'WG3_ENS_3_1_UK',
  'WG3_ENS_3_4_UK',
  'WG3_ENS_3_6_UK',
  'WG3_ENS_3_8_UK',
  'WG3_ENS_4_10_UK',
  'WG3_ENS_4_2_UK',
  'WG3_ENS_4_3_UK',
  'WG3_ENS_5_1_UK',
  'WG3_ENS_5_2_UK',
  'WG3_ENS_5_7_UK',
  'WG3_ENS_5_8_UK',
  'WG3_ENS_7_10_UK',
  'WG3_ENS_7_5_UK',
  'WG3_ENS_8_10_UK',
  'WG3_ENS_8_2_UK',
  'WG3_ENS_8_5_UK',
  'WG3_ENS_8_9_UK',
  'WG3_FORMC11_UK',
  'WG3_FORMC12_UK',
  'WG3_FORMC14_UK',
  'WG3_FORMC16_UK',
  'WG3_FORMC17_UK',
  'WG3_FORMC18_UK',
  'WG3_FORMC19_UK',
  'WG3_FORMC1_UK',
  'WG3_FORMC20_UK',
  'WG3_FORMC21_UK',
  'WG3_FORMC22_UK',
  'WG3_FORMC23_UK',
  'WG3_FORMC24_UK',
  'WG3_FORMC25_UK',
  'WG3_FORMC27_UK',
  'WG3_FORMC28_UK',
  'WG3_FORMC29_UK',
  'WG3_FORMC30_UK',
  'WG3_FORMC31_UK',
  'WG3_FORMC32_UK',
  'WG3_FORMC34_UK',
  'WG3_FORMC35_UK',
  'WG3_FORMC36_UK',
  'WG3_FORMC37_UK',
  'WG3_FORMC38_UK',
  'WG3_FORMC39_UK',
  'WG3_FORMC41_UK',
  'WG3_FORMC42_1_UK',
  'WG3_FORMC42_2_UK',
  'WG3_FORMC43_UK',
  'WG3_FORMC44_UK',
  'WG3_FORMC45_UK',
  'WG3_FORMC46_UK',
  'WG3_FORMC47_UK',
  'WG3_FORMC48_1_UK',
  'WG3_FORMC48_2_UK',
  'WG3_FORMC52_UK',
  'WG3_FORMC53_UK',
  'WG3_FORMC54_UK',
  'WG3_FORMC5_UK',
  'WG3_FORMC62_UK',
  'WG3_FORMC63_UK',
  'WG3_FORMC64_UK',
  'WG3_FORMC69_UK',
  'WG3_FORMC6_UK',
  'WG3_FORMC70_UK',
  'WG3_FORMC74_UK',
  'WG3_FORMC75_UK',
  'WG3_FORMC76_1_UK',
  'WG3_FORMC76_2_UK',
  'WG3_FORMC77_UK',
  'WG3_FORMC78_UK',
  'WG3_FORMC7_UK',
  'WG3_FORMC9_1_UK',
  'WG3_FORMC9_2_UK',
  'WG3_IAM_1_1_1_UK',
  'WG3_IAM_1_1_2_UK',
  'WG3_IAM_1_2_1_UK',
  'WG3_IAM_1_2_2_UK',
  'WG3_IAM_1_6_UK',
  'WG3_IAM_1_9_UK',
  'WG3_IAM_2_2_UK',
  'WG3_IAM_2_6_UK',
  'WG3_IAM_2_7_UK',
  'WG3_IAM_8_3_UK',
  'WG3_IAM_8_4_UK',
  'WG3_IAM_8_5_UK',
  'WG3_IAM_8_7_UK',
  'WG3_IAM_8_9_UK',
  'WG3_IAM_9_10_UK',
  'WG3_IAM_9_6_UK',
  'WG3_IAM_9_7_UK',
  'WG3_IAM_9_9_UK',
  'WG3_IAR_1_1_UK',
  'WG3_IAR_1_3_UK',
  'WG3_IAR_1_4_UK',
  'WG3_IAR_1_6_UK',
  'WG3_IAR_1_9_UK',
  'WG3_IAR_2_11_UK',
  'WG3_IAR_2_2_UK',
  'WG3_IAR_2_7_UK',
  'WG3_IBS_1_1_1_UK',
  'WG3_IBS_1_1_2_UK',
  'WG3_IBS_1_2_UK',
  'WG3_IBS_1_3_1_UK',
  'WG3_IBS_1_3_2_UK',
  'WG3_IBS_1_7_UK',
  'WG3_IHB_1_11_UK',
  'WG3_IHB_1_1_1_UK',
  'WG3_IHB_1_1_2_UK',
  'WG3_IHB_1_4_UK',
  'WG3_IHB_1_5_UK',
  'WG3_IHB_1_6_UK',
  'WG3_IHB_1_7_UK',
  'WG3_IHB_1_8_UK',
  'WG3_IVG_2_3_UK',
  'WG3_IVG_2_4_UK',
  'WG3_IVG_2_6_UK',
  'WG3_IVG_2_7_UK',
  'WG3_IVG_2_8_UK',
  'WG3_IVG_2_9_UK',
  'WG3_IVG_3_3_UK',
  'WG3_IVG_3_6_UK',
  'WG3_IVG_3_9_UK',
  'WG3_IVG_5_10_UK',
  'WG3_IVG_5_1_UK',
  'WG3_IVG_5_3_UK',
  'WG3_IVG_5_4_UK',
  'WG3_IVG_5_8_UK',
  'WG3_IVG_6_5_UK',
  'WG3_IVG_6_6_UK',
  'WG3_IVG_6_9_UK',
  'WG3_NAM_1_6_UK',
  'WG3_NAM_1_7_UK',
  'WG3_NAM_2_2_UK',
  'WG3_NAM_2_3_UK',
  'WG3_NAM_2_4_UK',
  'WG3_NAM_4_10_UK',
  'WG3_NAM_4_2_UK',
  'WG3_NAM_4_5_UK',
  'WG3_NAM_4_7_UK',
  'WG3_NAM_4_8_UK',
  'WG3_NAM_5_5_UK',
  'WG3_NAM_5_9_UK',
  'WG3_NNS_1_1_UK',
  'WG3_NNS_1_6_UK',
  'WG3_NNS_1_7_UK',
  'WG3_NNS_1_8_UK',
  'WG3_NNS_1_9_UK',
  'WG3_RCL_10_10_UK',
  'WG3_RCL_10_3_UK',
  'WG3_RCL_10_6_UK',
  'WG3_RCL_10_7_UK',
  'WG3_RCL_1_10_UK',
  'WG3_RCL_1_7_UK',
  'WG3_RCL_2_10_UK',
  'WG3_RCL_2_5_UK',
  'WG3_RCL_2_7_UK',
  'WG3_RCL_6_1_UK',
  'WG3_RCL_6_2_UK',
  'WG3_RCL_6_3_UK',
  'WG3_RCL_6_7_UK',
  'WG3_RCL_9_10_UK',
  'WG3_RCL_9_1_UK',
  'WG3_RCL_9_8_UK',
  'WG3_RCL_9_9_UK',
  'WG3_RHB_11_10_UK',
  'WG3_RHB_11_2_UK',
  'WG3_RHB_11_5_UK',
  'WG3_RHB_11_7_UK',
  'WG3_RHB_11_9_UK',
  'WG3_RHB_12_2_UK',
  'WG3_RHB_12_4_UK',
  'WG3_RHB_12_5_UK',
  'WG3_RHB_12_7_UK',
  'WG3_RHB_12_9_UK',
  'WG3_RHB_13_10_UK',
  'WG3_RHB_13_2_UK',
  'WG3_RHB_13_4_UK',
  'WG3_RHB_13_5_UK',
  'WG3_RHB_13_8_UK',
  'WG3_RHB_1_10_UK',
  'WG3_RHB_1_2_UK',
  'WG3_RHB_1_3_UK',
  'WG3_RHB_1_4_UK',
  'WG3_RHB_1_7_UK',
  'WG3_RHB_1_9_1_UK',
  'WG3_RHB_1_9_2_UK',
  'WG3_RHB_4_10_UK',
  'WG3_RHB_4_1_UK',
  'WG3_RHB_4_2_UK',
  'WG3_RHB_4_6_UK',
  'WG3_RHB_4_7_UK',
  'WG3_RHB_5_4_UK',
  'WG3_RHB_5_5_UK',
  'WG3_RHB_6_1_UK',
  'WG3_RHB_6_8_UK',
  'WG3_RHB_7_11_UK',
  'WG3_RHB_7_12_UK',
  'WG3_RHB_7_1_UK',
  'WG3_RHB_7_5_UK',
  'WG3_RHB_7_7_UK',
  'WG3_RHB_7_9_UK',
  'WG3_RHB_8_10_UK',
  'WG3_RHB_8_4_UK',
  'WG3_RHB_8_5_UK',
  'WG3_RHB_8_9_UK',
  'WG3_RHB_9_10_UK',
  'WG3_RHB_9_2_UK',
  'WG3_RHB_9_3_UK',
  'WG3_RHB_9_4_UK',
  'WG3_RHB_9_7_UK',
  'WG3_RVG_10_1_UK',
  'WG3_RVG_10_3_UK',
  'WG3_RVG_10_6_UK',
  'WG3_RVG_11_1_UK',
  'WG3_RVG_11_6_UK',
  'WG3_RVG_1_13_UK',
  'WG3_RVG_1_6_UK',
  'WG3_RVG_1_8_UK',
  'WG3_RVG_8_10_UK',
  'WG3_RVG_8_5_UK',
  'WG3_UK_DC_Perc',
  'WG3_UK_DC_Stanine',
  'WG3_UK_DC_Sten',
  'WG3_UK_DC_TOT',
  'WG3_UK_DC_Tscore',
  'WG3_UK_EA_Perc',
  'WG3_UK_EA_Stanine',
  'WG3_UK_EA_Sten',
  'WG3_UK_EA_TOT',
  'WG3_UK_EA_Tscore',
  'WG3_UK_RA_Perc',
  'WG3_UK_RA_Stanine',
  'WG3_UK_RA_Sten',
  'WG3_UK_RA_TOT',
  'WG3_UK_RA_Tscore',
  'WG3_UK_Stanine',
  'WG3_UK_Sten',
  'WG3_UK_TOT',
  'WG3_UK_TOT_Attempted',
  'WG3_UK_TOT_Correct',
  'WG3_UK_TOT_Perc',
  'WG3_UK_Tscore',
  'N',
  'E',
  'O',
  'A',
  'C',
  'N1',
  'N2',
  'N3',
  'N4',
  'N5',
  'N6',
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
  'E6',
  'O1',
  'O2',
  'O3',
  'O4',
  'O5',
  'O6',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'C6',

  'PT1',
  'PT2',
  'PT3',
  'PT4',
  'PT5',
  'PT6',
  'PT7',
  'PT8',
  'PT9',
  'PT10',
  'PT11',
  'PT12',
  'PT13',
  'PT14',
  'PT15',
  'PT16',
  'PT17',
  'PT18',
  'PT19',
  'PT20',
  'PT21',
  'PT22',
  'PT23',
  'PT24',
  'PT25',
  'PT26',
  'PT27',
  'PT28',
  'PT29',
  'PT30',
  'PT31',
  'PT32',
  'PT33',
  'PT34',
  'PT35',
  'PT36',
  'PT37',
  'PT38',
  'PT39',
  'PT40',
  'PT41',
  'PT42',
  'PT43',
  'PT44',
  'PT45',
  'PT46',
  'PT47',
  'PT48',
  'PT49',
  'PT50',
  'PT51',
  'PT52',
  'PT53',
  'PT54',
  'PT55',
  'PT56',
  'PT57',
  'PT58',
  'PT59',
  'PT60',
  'PT61',
  'PT62',
  'PT63',
  'PT64',
  'PT65',
  'PT66',
  'PT67',
  'PT68',
  'PT69',
  'PT70',
  'PT71',
  'PT72',
  'PT73',
  'PT74',
  'PT75',
  'PT76',
  'PT77',
  'PT78',
  'PT79',
  'PT80',
  'PT81',
  'PT82',
  'PT83',
  'PT84',
  'PT85',
  'PT86',
  'PT87',
  'PT88',
  'PT89',
  'PT90',
  'PT91',
  'PT92',
  'PT93',
  'PT94',
  'PT95',
  'PT96',
  'PT97',
  'PT98',
  'PT99',
  'PT100',
  'PT101',
  'PT102',
  'PT103',
  'PT104',
  'PT105',
  'PT106',
  'PT107',
  'PT108',
  'PT109',
  'PT110',
  'PT111',
  'PT112',
  'PT113',
  'PT114',
  'PT115',
  'PT116',
  'PT117',
  'PT118',
  'PT119',

  'PT120',
  'PT121',
  'PT122',
  'PT123',
  'PT124',
  'PT125',
  'PT126',
  'PT127',
  'PT128',
  'PT129',

  'PT130',
  'PT131',
  'PT132',
  'PT133',
  'PT134',
  'PT135',
  'PT136',
  'PT137',
  'PT138',
  'PT139',
  'PT140',
  'PT141',
  'PT142',
  'PT143',
  'PT144',
  'PT145',
  'PT146',
  'PT147',
  'PT148',
  'PT149',
  'PT150',
  'PT151',
  'PT152',
  'PT153',
  'PT154',
  'PT155',
  'PT156',
  'PT157',
  'PT158',
  'PT159',
  'PT160',
  'PT161',
  'PT162',
  'PT163',
  'PT164',
  'PT165',
  'PT166',
  'PT167',
  'PT168',
  'PT169',

  'PT170',
  'PT171',
  'PT172',
  'PT173',
  'PT174',
  'PT175',
  'PT176',
  'PT177',
  'PT178',
  'PT179',

  'PT180',
  'PT181',
  'PT182',
  'PT183',
  'PT184',
  'PT185',
  'PT186',
  'PT187',
  'PT188',
  'PT189',
  'PT190',
  'PT191',
  'PT192',
  'PT193',
  'PT194',
  'PT195',
  'PT196',
  'PT197',
  'PT198',
  'PT199',

  'PT200',
  'PT201',
  'PT202',
  'PT203',
  'PT204',
  'PT205',
  'PT206',
  'PT207',
  'PT208',
  'PT209',

  'PT210',
  'PT211',
  'PT212',
  'PT213',
  'PT214',
  'PT215',
  'PT216',
  'PT217',
  'PT218',
  'PT219',

  'PT220',
  'PT221',
  'PT222',
  'PT223',
  'PT224',
  'PT225',
  'PT226',
  'PT227',
  'PT228',
  'PT229',

  'PT230',
  'PT231',
  'PT232',
  'PT233',
  'PT234',
  'PT235',
  'PT236',
  'PT237',
  'PT238',
  'PT239',

  'PT240',
];

export default candidateScreeningKeys;
