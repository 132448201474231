import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextInput,
  Form,
  Button,
  InlineNotification,
  Modal,
  Loading,
  Tile,
} from 'carbon-components-react';

import {
  SignedInErrorView,
  SignedInSuccessView,
  InviteErrorView,
} from '../../components/common/ErrorViews';
import { registerUser } from '../../actions/AdminSection';

import './Registration.css';
import { isInValid } from '../../utils/helpers';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstname: '',
      lastname: '',
      password: '',
      repassword: '',
      hashData: '',
      isPasswordMatch: true,
      isError: false,
      isPasswordInvalid: false,
      isrePassInvalid: false,
      isFirstCase: true,
      suggestions: [],
      pwdLevel: ['weak', 'weak', 'good', 'strong', 'stronger'],
      score: 0,
      isSignupDisabled: true,
      errorMessage: 'Unknown error',
      registrationComplete: false,
      uLoading: true,
      userExists: false,
      isInviteError: false,
      signingUp: false,
    };
  }

  componentDidMount() {
    const { match: { params: { data } = {} } = {} } = this.props;
    this.setState({ hashData: data, uLoading: false });
  }

  handleChange = (event, type = '') => {
    const password = event.target.value;
    if (type == 'password') {
      if (password.length >= 12) {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSplCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
          password
        );
        const validationArray = [
          hasUpperCase,
          hasLowerCase,
          hasNumbers,
          hasSplCharacters,
        ];
        const isFirstCase =
          validationArray.filter((itm) => itm == true)?.length < 2;

        this.setState({ isFirstCase });
        import('zxcvbn').then((zxcvbn) => {
          const Result = zxcvbn.default(password, [
            this.state.username,
            this.state.firstname,
            this.state.lastname,
          ]);
          this.setState({
            suggestions: Result.feedback.suggestions,
            score: Result.score,
          });
          if (Result.score < 2 || isFirstCase)
            this.setState({ isPasswordInvalid: true });
          else
            this.setState({
              isPasswordInvalid: false,
              isSignupDisabled: false,
            });
        });
      } else
        this.setState({
          isPasswordInvalid: true,
          isFirstCase: true,
          isSignupDisabled: true,
        });
    }
    const { target: { name, value } = {} } = event;
    this.setState({
      [name]: value,
    });
  };

  registerUser = (ev) => {
    ev.preventDefault();
    const {
      password,
      repassword,
      hashData,
      firstname,
      lastname,
      isPasswordInvalid,
    } = this.state;

    this.setState(
      {
        isFirstNameInvalid: isInValid(firstname, 'text'),
        isLastNameInvalid: isInValid(lastname, 'text'),
      },
      () => {
        if (
          password === repassword &&
          !isPasswordInvalid &&
          !this.state.isFirstNameInvalid &&
          !this.state.isLastNameInvalid
        ) {
          const data = {
            firstname,
            lastname,
            password,
            data: hashData,
            roleId: 4,
          };
          this.setState({
            isError: false,
            isPasswordMatch: true,
            signingUp: true,
          });
          this.props
            .registerUser(data)
            .then((res) => {
              if (res && res.error) {
                this.setState({
                  isError: true,
                  errorMessage: res.error.message,
                  signingUp: false,
                });
              } else {
                this.setState(
                  { registrationComplete: true, signingUp: false },
                  () => {
                    setTimeout(() => {
                      this.props.history.push('/');
                    }, 3000);
                  }
                );
              }
            })
            .catch((e) => {
              console.error(e);
              this.setState({
                isError: true,
                errorMessage: 'Unknown error occured.',
                signingUp: false,
              });
            });
        } else {
          this.setState({ isPasswordMatch: false, isrePassInvalid: true });
        }
      }
    );
  };

  render() {
    const {
      username,
      firstname,
      lastname,
      isPasswordMatch,
      repassword,
      password,
      isError,
      errorMessage,
      isFirstCase,
      registrationComplete,
      isPasswordInvalid,
      pwdLevel,
      score,
      isSignupDisabled,
      isrePassInvalid,
      uLoading,
      userExists,
      isInviteError,
      signingUp,
      isFirstNameInvalid,
      isLastNameInvalid,
    } = this.state;
    const { isUserLoggedIn } = this.props;
    return (
      <div className="registraton-form">
        {uLoading && <Loading withOverlay />}
        {isInviteError && <InviteErrorView />}
        {!isInviteError &&
          !userExists &&
          !isUserLoggedIn &&
          isUserLoggedIn !== null && (
            <div className="bx--tile rf-wrap">
              <div className="text-center">
                <h4>Welcome to ARS!</h4>
                <p>Please fill this form to complete registration.</p>
              </div>
              {isPasswordMatch ? null : (
                <InlineNotification
                  lowContrast
                  kind="error"
                  role="alert"
                  className="mt-1 mb-1"
                  title="Password mismatch"
                  subtitle="Please retry the password."
                  iconDescription="describes the close button"
                  onCloseButtonClick={() =>
                    this.setState({ isPasswordMatch: true })
                  }
                />
              )}
              {isError ? (
                <InlineNotification
                  lowContrast
                  kind="error"
                  role="alert"
                  className="mt-1 mb-1"
                  title="Error occured"
                  subtitle={errorMessage}
                  iconDescription="describes the close button"
                  onCloseButtonClick={() => this.setState({ isError: false })}
                />
              ) : null}
              <div className="xpa--login">
                <div className="tab-content" id="xpaLoginTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="login"
                    role="tabpanel"
                    aria-labelledby="login-tab">
                    <Form onSubmit={(ev) => this.registerUser(ev)}>
                      <div className=" bx--row ">
                        <TextInput
                          required
                          id="firstname"
                          name="firstname"
                          labelText="First Name"
                          placeholder="First Name"
                          onChange={(ev) => {
                            this.handleChange(ev);
                          }}
                          value={firstname}
                          // maxLength={15}
                          disabled={signingUp}
                          invalid={isFirstNameInvalid}
                          invalidText="Please enter valid first name"
                        />
                        <TextInput
                          required
                          id="lastname"
                          name="lastname"
                          labelText="Last Name"
                          placeholder="Last Name"
                          onChange={(ev) => {
                            this.handleChange(ev);
                          }}
                          value={lastname}
                          // maxLength={15}
                          disabled={signingUp}
                          invalid={isLastNameInvalid}
                          invalidText="Please enter valid last name"
                        />
                      </div>
                      <div className=" bx--row ">
                        <TextInput
                          type="password"
                          required
                          id="password"
                          name="password"
                          labelText={`Password (${pwdLevel[score]})`}
                          placeholder="Password (atleast 12 characters)"
                          invalid={isPasswordInvalid}
                          invalidText={
                            isFirstCase
                              ? `Password must be at least 12 characters and contain at least two of the following four categories (Upper case, Lower case, Digits, Special Characters).`
                              : `Password Strength: ${pwdLevel[score]}`
                          }
                          onChange={(ev) => {
                            this.handleChange(ev, 'password');
                          }}
                          value={password}
                          minLength={12}
                          disabled={signingUp}
                        />
                        <TextInput
                          type="password"
                          required
                          id="repassword"
                          name="repassword"
                          labelText="Re-type Password"
                          placeholder="Password"
                          invalid={isrePassInvalid}
                          invalidText="password must be same as above"
                          onChange={(ev) => {
                            this.handleChange(ev);
                          }}
                          value={repassword}
                          minLength={12}
                          disabled={signingUp}
                        />
                      </div>
                      <div className="bx--row align-items-center">
                        <Button
                          type="submit"
                          className="rf-btn"
                          kind="primary"
                          disabled={isSignupDisabled}>
                          {!signingUp ? 'Sign up' : 'Signing up...'}
                        </Button>
                      </div>
                      <p className="text-dark small pt-4">
                        By signing up, you agree to Terms & Conditions and
                        &nbsp;
                        <a
                          href="https://www.x0pa.com/privacy/"
                          target="_blank"
                          rel="noopener noreferrer">
                          Privacy Policy
                        </a>
                        &nbsp;of X0PA.
                      </p>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}
        {!isInviteError && isUserLoggedIn && <SignedInErrorView />}
        {!isInviteError && userExists && !isUserLoggedIn && (
          <SignedInSuccessView />
        )}
        {registrationComplete && (
          <Modal
            modalHeading="Registration successful"
            passiveModal
            open={registrationComplete}
            onRequestClose={() => {
              this.props.history.push('/');
            }}>
            Your registration is complete, please sign in to continue
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
});

const mapDispatchToProps = {
  registerUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
