import React, { Fragment } from 'react';

//Common components
import NivoPieChart from '../../components/common/NivoPieChart';
import NivoBarChart from '../../components/common/NivoBarChart';
import NivoLineGraph from '../../components/common/NivoLineGraph';
import NivoScatterGraph from '../../components/common/NivoScatterGraph';
//Common carbon components
import { Tile } from 'carbon-components-react';

//CSS
import './Dashboard.css';

const DashboardAnalytics = (props) => {
  const {
    analyticsInfo,
    showYoyComparision,
    handleBarOnClick,
    selectedTab,
  } = props;
  const {
    candCourseData,
    ethnicityOtherData,
    candidateCountryData,
    offeredBySchool,
    dwellingType,
    scholarshipScheme,
    yoyComparision,
    genderData,
    ethnicityData,
    houseHoldIncome,
    candCountryData,
    candidateCourseData,
    ytdComparision,
    householfFA,
  } = analyticsInfo || {};
  let scholarshipSchemeTargetVal = [];
  let scholarshipSchemeMaxValue = 40;
  if (
    scholarshipScheme &&
    Array.isArray(scholarshipScheme) &&
    scholarshipScheme.length > 0
  ) {
    const scholarshipTargetScheme = scholarshipScheme.map(
      (scheme) => scheme && scheme.target && Number(scheme.target)
    );
    const scholarshipOfferedScheme = scholarshipScheme.map(
      (scheme) => scheme && scheme.offered && Number(scheme.offered)
    );
    const sortedTargetSchemes = scholarshipTargetScheme.sort((a, b) => a - b);

    const sortedOfferedSchemes = scholarshipOfferedScheme.sort((a, b) => a - b);
    const finalTargetValue =
      sortedTargetSchemes[scholarshipTargetScheme.length - 1];
    const finalOfferedValue =
      sortedOfferedSchemes[scholarshipOfferedScheme.length - 1];
    const finalValue =
      finalTargetValue > finalOfferedValue
        ? finalTargetValue
        : finalOfferedValue;
    scholarshipSchemeMaxValue = finalValue + 10;
  }
  let candidateCountryMaxVal = 55;
  if (
    candidateCountryData &&
    Array.isArray(candidateCountryData) &&
    candidateCountryData.length > 0
  ) {
    const candidateCountryTargetValue = candidateCountryData.map(
      (d) => d && d.target && Number(d.target)
    );
    const candidateCountryOfferedValue = candidateCountryData.map(
      (d) => d && d.offered && Number(d.offered)
    );
    const sortedTargetData = candidateCountryTargetValue.sort((a, b) => a - b);
    const sortedOfferedData = candidateCountryOfferedValue.sort(
      (a, b) => a - b
    );
    const finalTargetValue =
      sortedTargetData[candidateCountryOfferedValue.length - 1];
    const finalOfferedValue =
      sortedOfferedData[candidateCountryOfferedValue.length - 1];
    const finalValue =
      finalTargetValue > finalOfferedValue
        ? finalTargetValue
        : finalOfferedValue;
    candidateCountryMaxVal = finalValue + 10;
  }
  let otherCountryMaxVal = 40;

  if (
    candCountryData &&
    Array.isArray(candCountryData) &&
    candCountryData.length > 0
  ) {
    const candidateCountryTargetValue = candCountryData.map(
      (d) => d && d.offered && Number(d.offered)
    );
    const sortedData = candidateCountryTargetValue.sort((a, b) => a - b);
    otherCountryMaxVal =
      sortedData[candidateCountryTargetValue.length - 1] + 10;
  }

  let offeredBySchoolOtherMaxVal = 40;
  if (
    offeredBySchool &&
    Array.isArray(offeredBySchool) &&
    offeredBySchool.length > 0
  ) {
    const offeredBySchoolData = offeredBySchool.map(
      (d) => d && d.offered && Number(d.offered)
    );
    const sortedData = offeredBySchoolData.sort((a, b) => a - b);
    offeredBySchoolOtherMaxVal =
      sortedData[offeredBySchoolData.length - 1] + 10;
  }
  let coursesByGenderMaxVal = 40;
  if (
    candidateCourseData &&
    Array.isArray(candidateCourseData) &&
    candidateCourseData.length > 0
  ) {
    const candidateCourseDataArr = candidateCourseData.map(
      (r) => r && r.total && Number(r.total)
    );
    const sortedData = candidateCourseDataArr.sort((a, b) => a - b);
    coursesByGenderMaxVal = sortedData[candidateCourseDataArr.length - 1] + 10;
  }
  let genderDataMaxVal = 140;
  if (genderData && Array.isArray(genderData) && genderData.length > 0) {
    const genderDataArr = genderData.map(
      (r) => r && r.interviewed && Number(r.interviewed)
    );
    const sortedArr = genderDataArr.sort((a, b) => a - b);
    genderDataMaxVal = sortedArr[genderDataArr.length - 1] + 10;
  }
  let ethnicityDataMaxVal = 110;
  if (
    ethnicityData &&
    Array.isArray(ethnicityData) &&
    ethnicityData.length > 0
  ) {
    const ethnicityDataArr = ethnicityData.map(
      (r) => r && r.interviewed && Number(r.interviewed)
    );
    const sortedArr = ethnicityDataArr.sort((a, b) => a - b);
    ethnicityDataMaxVal = sortedArr[ethnicityDataArr.length - 1] + 10;
  }
  let ethnicityOtherDataMaxVal = 20;
  if (
    ethnicityOtherData &&
    Array.isArray(ethnicityOtherData) &&
    ethnicityOtherData.length > 0
  ) {
    const ethnicityDataArr = ethnicityOtherData.map(
      (r) => r && r.interviewed && Number(r.interviewed)
    );
    const sortedArr = ethnicityDataArr.sort((a, b) => a - b);
    ethnicityOtherDataMaxVal = sortedArr[ethnicityDataArr.length - 1] + 10;
  }
  let dwellingTypeMaxVal = 100;
  if (dwellingType && Array.isArray(dwellingType) && dwellingType.length > 0) {
    const dwellingTypeArr = dwellingType.map((r) => r && r.interviewed);
    const sortedArr = dwellingTypeArr.sort((a, b) => a - b);
    dwellingTypeMaxVal = sortedArr[dwellingTypeArr.length - 1] + 10;
  }
  let householfFAMaxVal = 280;
  if (householfFA && Array.isArray(householfFA) && householfFA.length > 0) {
    const interviewedHouseHoldArr = householfFA.map((r) => r && r.offered);
    const interviewSortedArr = interviewedHouseHoldArr.sort((a, b) => a - b);
    const offeredHouseHoldArr = householfFA.map(
      (r) => r && r['interviewed (but not offered)']
    );
    const offeredSortedArr = offeredHouseHoldArr.sort((a, b) => a - b);
    householfFAMaxVal =
      interviewSortedArr[interviewedHouseHoldArr.length - 1] +
      offeredSortedArr[offeredHouseHoldArr.length - 1] +
      10;
  }
  let houseHoldIncomeMaxVal = 60;
  if (
    houseHoldIncome &&
    Array.isArray(houseHoldIncome) &&
    houseHoldIncome.length > 0
  ) {
    const houseHoldIncomeArr = houseHoldIncome.map((r) => r && r.interviewed);
    const sortedArr = houseHoldIncomeArr.sort((a, b) => a - b);
    houseHoldIncomeMaxVal = sortedArr[houseHoldIncomeArr.length - 1] + 10;
  }
  let yoyComparisionMaxVal = 350;
  if (
    yoyComparision &&
    Array.isArray(yoyComparision) &&
    yoyComparision.length > 0
  ) {
    const yoyComparisionArr = yoyComparision.map((r) => r && r.interviewed);
    const sortedArr = yoyComparisionArr.sort((a, b) => a - b);
    yoyComparisionMaxVal = sortedArr[yoyComparisionArr.length - 1] + 10;
  }
  let ytdComparisionMaxVal = 160;
  if (
    ytdComparision &&
    Array.isArray(ytdComparision) &&
    ytdComparision.length > 0
  ) {
    const ytdComparisionArr = ytdComparision.map((r) => r && r.interviewed);
    const sortedArr = ytdComparisionArr.sort((a, b) => a - b);
    ytdComparisionMaxVal = sortedArr[ytdComparisionArr.length - 1] + 10;
  }
  return (
    <Fragment>
      {showYoyComparision !== true && (
        <Fragment>
          <div>
            <Tile className="mb-2">
              {scholarshipScheme &&
              Array.isArray(scholarshipScheme) &&
              scholarshipScheme.length > 0 ? (
                <div className="graphs-height">
                  <NivoBarChart
                    heading="By Scholarship Scheme"
                    data={scholarshipScheme}
                    margin={{ top: 50, right: 30, bottom: 150, left: 60 }}
                    keys={[
                      'target',
                      'offered',
                      'accepted',
                      'pending',
                      'declined / withdrew acceptance',
                    ]}
                    legendDirection="bottom-right"
                    enableLabel
                    axisTop={null}
                    showLegend
                    maxValue={scholarshipSchemeMaxValue}
                    axisRight={null}
                    showDefModal
                    handleBarOnClick={handleBarOnClick}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: -34,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                  />
                </div>
              ) : (
                <div>
                  <h4 className="text-capitalize mt-1">
                    By Scholarship Scheme
                  </h4>
                  <div className="mt-2 h6">
                    There is no data to show for your current selection
                  </div>
                </div>
              )}
              <div className="f-w-b css-text-color mt-2">
                Click on the bar to breakdown by level of study
              </div>
            </Tile>
          </div>

          <div className="bx--row ">
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 mb-2">
              <Tile className="ml-0">
                {candidateCountryData &&
                Array.isArray(candidateCountryData) &&
                candidateCountryData.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By Country"
                      data={candidateCountryData}
                      margin={{ top: 50, right: 30, bottom: 125, left: 60 }}
                      keys={['target', 'offered']}
                      legendDirection="top-left"
                      axisTop={null}
                      showLegend
                      enableLabel
                      maxValue={candidateCountryMaxVal}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -27,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <h4 className="text-capitalize mt-1">By Country</h4>
                    <div className="mt-2 h6">
                      There is no data to show for your current selection
                    </div>
                  </div>
                )}
                <div className="f-w-b css-text-color mt-2">
                  There is a target of {selectedTab === 0 ? 5 : 6} for offers to
                  study in France, Japan and Germany
                </div>
              </Tile>
            </div>
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 mb-2 pl-0 pl-xl-0 pl-md-3">
              <Tile>
                {candCountryData &&
                Array.isArray(candCountryData) &&
                candCountryData.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By Offered Country (Others)"
                      data={candCountryData}
                      margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                      keys={['offered']}
                      legendDirection="bottom-left"
                      axisTop={null}
                      axisRight={null}
                      showLegend
                      enableLabel
                      maxValue={otherCountryMaxVal}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h4 className="text-capitalize mt-1">
                        By Offered Country (Others)
                      </h4>
                      <div className="mt-2 h6">
                        There is no data to show for your current selection
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ color: 'white' }} className="f-w-b mt-2">
                  There is a target of 6 for offers to study in France, Japan
                  and Germany
                </div>
              </Tile>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
              <Tile>
                {candidateCourseData &&
                Array.isArray(candidateCourseData) &&
                candidateCourseData.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By Course of Study"
                      data={candidateCourseData}
                      margin={{ top: 10, right: 0, bottom: 170, left: 75 }}
                      keys={['offered', 'male', 'female']}
                      legendDirection="top-left"
                      axisTop={null}
                      showLegend
                      enableLabel
                      maxValue={coursesByGenderMaxVal}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -40,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -10,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h4 className="text-capitalize mt-1">
                        By Course of Study
                      </h4>
                      <div className="mt-2 h6">
                        There is no data to show for your current selection
                      </div>
                    </div>
                  </div>
                )}
              </Tile>
            </div>
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 mb-2 pl-0 pl-xl-0 pl-md-3">
              <Tile className="ml-0">
                {genderData &&
                Array.isArray(genderData) &&
                genderData.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By Sex"
                      data={genderData}
                      margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                      keys={['interviewed', 'offered']}
                      legendDirection="bottom-left"
                      axisTop={null}
                      axisRight={null}
                      showLegend
                      enableLabel
                      maxValue={genderDataMaxVal}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h4 className="text-capitalize mt-1">By Sex</h4>
                      <div className="mt-2 h6">
                        There is no data to show for your current selection
                      </div>
                    </div>
                  </div>
                )}
              </Tile>
            </div>
          </div>

          <div className="bx--row">
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
              <Tile className="ml-0">
                {ethnicityData &&
                Array.isArray(ethnicityData) &&
                ethnicityData.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By Ethnicity"
                      data={ethnicityData}
                      margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                      keys={['interviewed', 'offered']}
                      legendDirection="bottom-left"
                      axisTop={null}
                      axisRight={null}
                      showLegend
                      enableLabel
                      maxValue={ethnicityDataMaxVal}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h4 className="text-capitalize mt-1">By Ethnicity</h4>
                      <div className="mt-2 h6">
                        There is no data to show for your current selection
                      </div>
                    </div>
                  </div>
                )}
              </Tile>
            </div>
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 mb-2 pl-0 pl-xl-0 pl-md-3">
              <Tile className="ml-0">
                {ethnicityOtherData &&
                Array.isArray(ethnicityOtherData) &&
                ethnicityOtherData.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By Ethnicity (Others)"
                      data={ethnicityOtherData}
                      margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                      keys={['interviewed', 'offered']}
                      legendDirection="bottom-left"
                      axisTop={null}
                      axisRight={null}
                      showLegend
                      enableLabel
                      maxValue={ethnicityOtherDataMaxVal}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <h4 className="text-capitalize mt-1">
                      By Ethnicity (Others)
                    </h4>
                    <div className="mt-2 h6">
                      There is no data to show for your current selection
                    </div>
                  </div>
                )}
              </Tile>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
              <Tile>
                {offeredBySchool &&
                Array.isArray(offeredBySchool) &&
                offeredBySchool.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By School"
                      data={offeredBySchool}
                      margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                      keys={['offered']}
                      legendDirection="bottom-left"
                      axisTop={null}
                      axisRight={null}
                      showLegend
                      enableLabel
                      maxValue={offeredBySchoolOtherMaxVal}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -15,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -10,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h4 className="text-capitalize mt-1">By School</h4>
                      <div className="mt-2 h6">
                        There is no data to show for your current selection
                      </div>
                    </div>
                  </div>
                )}
              </Tile>
            </div>
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 mb-2 pl-0 pl-xl-0 pl-md-3">
              <Tile>
                {dwellingType &&
                Array.isArray(dwellingType) &&
                dwellingType.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By Dwelling Type"
                      data={dwellingType}
                      margin={{ top: 10, right: 30, bottom: 150, left: 60 }}
                      keys={['interviewed', 'offered']}
                      legendDirection="top-left"
                      axisTop={null}
                      showLegend
                      enableLabel
                      maxValue={dwellingTypeMaxVal}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -24,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h4 className="text-capitalize mt-1">By Dwelling Type</h4>
                      <div className="mt-2 h6">
                        There is no data to show for your current selection
                      </div>
                    </div>
                  </div>
                )}
              </Tile>
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
              <Tile className="ml-0">
                {householfFA &&
                Array.isArray(householfFA) &&
                householfFA.length > 0 ? (
                  <div style={{ height: 330 }}>
                    <NivoBarChart
                      heading="By Household Income"
                      data={householfFA}
                      margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                      keys={['interviewed (but not offered)', 'offered']}
                      legendDirection="bottom-left"
                      axisTop={null}
                      axisRight={null}
                      showSubMessage
                      message="(Non-Financial Assistance vs Financial Assistance)"
                      showLegend
                      enableLabel
                      groupMode="stacked"
                      maxValue={householfFAMaxVal}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h4 className="text-capitalize mt-1">
                        By Household Income
                      </h4>
                      <div className="f-w-b mb-2 mt-2">
                        (Non-Financial Assistance vs Financial Assistance)
                      </div>
                      <div className="mt-2 h6">
                        There is no data to show for your current selection
                      </div>
                    </div>
                  </div>
                )}
                <div className="small f-w-b css-text-color mt-2">
                  Household income less than S$9000 or per capita income less
                  than S$2250 qualifies for financial assistance
                </div>
              </Tile>
            </div>
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 mb-2 pl-0 pl-xl-0 pl-md-3">
              <Tile className="ml-0">
                {houseHoldIncome &&
                Array.isArray(houseHoldIncome) &&
                houseHoldIncome.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="By Household Income"
                      data={houseHoldIncome}
                      margin={{ top: 20, right: 30, bottom: 130, left: 60 }}
                      keys={['interviewed', 'offered']}
                      legendDirection="top-left"
                      axisTop={null}
                      showLegend
                      enableLabel
                      maxValue={houseHoldIncomeMaxVal}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -34,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h4 className="text-capitalize mt-1">
                        By Household Income
                      </h4>
                      <div className="mt-2 h6">
                        There is no data to show for your current selection
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="small f-w-b css-text-color mt-2">
                  Less than S$ XXX qualifies for Financial Assistance
                </div> */}
              </Tile>
            </div>
          </div>
          {/* <div className="bx--row">
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
              <Tile className="ml-0">
                {yoyComparision &&
                Array.isArray(yoyComparision) &&
                yoyComparision.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="YOY Comparision"
                      data={yoyComparision}
                      margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                      keys={['interviewed', 'offered', 'accepted']}
                      legendDirection="bottom-left"
                      axisTop={null}
                      showLegend
                      maxValue={yoyComparisionMaxVal}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <h4 className="text-capitalize mt-1">YOY Comparision</h4>
                    <div className="mt-2 h6">
                      There is no data to show for your current selection
                    </div>
                  </div>
                )}
              </Tile>
            </div>
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2 mb-2 pl-0 pl-xl-0 pl-md-3">
              <Tile className="ml-0">
                {ytdComparision &&
                Array.isArray(ytdComparision) &&
                ytdComparision.length > 0 ? (
                  <div className="graphs-height">
                    <NivoBarChart
                      heading="YTD Comparision"
                      data={ytdComparision}
                      margin={{ top: 50, right: 30, bottom: 130, left: 60 }}
                      keys={['interviewed', 'offered', 'accepted']}
                      legendDirection="bottom-left"
                      axisTop={null}
                      showLegend
                      maxValue={ytdComparisionMaxVal}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <h4 className="text-capitalize mt-1">YTD Comparision</h4>
                    <div className="mt-2 h6">
                      There is no data to show for your current selection
                    </div>
                  </div>
                )}
              </Tile>
            </div>
          </div> */}
        </Fragment>
      )}
    </Fragment>
  );
};

export default DashboardAnalytics;
