import React, { Component } from 'react';
import PublicHeaderMock from './PublicHeaderMock';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Tile } from 'carbon-components-react';
import moment from 'moment';

class AgendaMock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isSideNavExpanded: false,
    };
  }

  onClickSideNavExpand = () => {
    const { isSideNavExpanded } = this.state;
    this.setState({
      isSideNavExpanded: !isSideNavExpanded,
    });
  };

  eventStyleGetter = (data) => {
    const { start } = data || {};
    const currMonth = moment(start).format('MM');
    var backgroundColor = '#3174ad';
    if (currMonth === '06') {
      backgroundColor = 'green';
    }
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  render() {
    const { history } = this.props;
    const { push } = history || {};
    const { isSideNavExpanded } = this.state;
    const localizer = momentLocalizer(moment);
    const currDayStr = moment().format('DD');
    const currDayNo = currDayStr && Number(currDayStr);
    const eventData = [
      {
        title: 'Sophia Wong',
        start: new Date(2021, 2, currDayNo, 14, 0, 0),
        end: new Date(2021, 2, currDayNo, 14, 30, 0),
      },

      {
        title: 'Jacob Lim',
        start: new Date(2021, 2, currDayNo, 10, 30, 0),
        end: new Date(2021, 2, currDayNo, 11, 0, 0),
      },
      {
        title: 'Isaiah Chia',
        start: new Date(2021, 2, currDayNo, 17, 0, 0),
        end: new Date(2021, 2, currDayNo, 17, 30, 0),
      },
      {
        title: 'Li wei',
        start: new Date(2021, 2, currDayNo, 18, 30, 0),
        end: new Date(2021, 2, currDayNo, 19, 0, 0),
      },
      {
        title: 'Wang Fang',
        start: new Date(2021, 2, currDayNo + 1, 18, 30, 0),
        end: new Date(2021, 2, currDayNo + 1, 19, 0, 0),
      },
      {
        title: 'Chris Chong',
        start: new Date(2021, 2, currDayNo + 1, 18, 30, 0),
        end: new Date(2021, 2, currDayNo + 1, 19, 0, 0),
      },
      {
        title: 'Bingwen',
        start: new Date(2021, 2, currDayNo + 1, 18, 30, 0),
        end: new Date(2021, 2, currDayNo + 1, 19, 0, 0),
      },
      {
        title: 'Li Jun',
        start: new Date(2021, 2, currDayNo + 1, 18, 30, 0),
        end: new Date(2021, 2, currDayNo + 1, 19, 0, 0),
      },
      {
        title: 'Li Qiang',
        start: new Date(2021, 2, 7, 18, 30, 0),
        end: new Date(2021, 2, 7, 19, 0, 0),
      },
      {
        title: 'PS Interview - Sophia Wong',
        start: new Date(2021, 5, 15, 14, 0, 0),
        end: new Date(2021, 5, 15, 14, 30, 0),
      },

      {
        title: 'PS Interview - Jacob Lim',
        start: new Date(2021, 5, 15, 10, 30, 0),
        end: new Date(2021, 5, 15, 11, 0, 0),
      },
      {
        title: 'PS Interview - Isaiah Chia',
        start: new Date(2021, 5, 15, 17, 0, 0),
        end: new Date(2021, 5, 15, 17, 30, 0),
      },
      {
        title: 'PS Interview - Li wei',
        start: new Date(2021, 5, 15, 18, 30, 0),
        end: new Date(2021, 5, 15, 19, 0, 0),
      },
      {
        title: 'PS Interview - Wang Fang',
        start: new Date(2021, 5, 15 + 1, 18, 30, 0),
        end: new Date(2021, 5, 15 + 1, 19, 0, 0),
      },
      {
        title: 'PS Interview - Chris Chong',
        start: new Date(2021, 5, 15 + 1, 18, 30, 0),
        end: new Date(2021, 5, 15 + 1, 19, 0, 0),
      },
      {
        title: 'PS Interview - Bingwen',
        start: new Date(2021, 5, 15 + 1, 18, 30, 0),
        end: new Date(2021, 5, 15 + 1, 19, 0, 0),
      },
      {
        title: 'PS Interview - Li Jun',
        start: new Date(2021, 5, 15 + 1, 18, 30, 0),
        end: new Date(2021, 5, 15 + 1, 19, 0, 0),
      },
      {
        title: 'PS Interview - Li Qiang',
        start: new Date(2021, 5, 7, 18, 30, 0),
        end: new Date(2021, 5, 7, 19, 0, 0),
      },
    ];
    return (
      <div>
        <PublicHeaderMock
          onClickSideNavExpand={this.onClickSideNavExpand}
          isSideNavExpanded={isSideNavExpanded}
          push={push}
          history={history}
        />
        <div className="bx--grid mt-5 xpa--ht-70">
          <Tile className="mt-7">
            <div style={{ height: '800px' }}>
              <Calendar
                selectable
                localizer={localizer}
                events={eventData || []}
                views={['month']}
                defaultView="month"
                step={60}
                onSelectEvent={(e) => {
                  if (push) {
                    push(`/public/agenda/info`);
                  }
                }}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={this.eventStyleGetter}
              />
            </div>
          </Tile>
        </div>
      </div>
    );
  }
}

export default AgendaMock;
