import React, { Component } from 'react';
import { connect } from 'react-redux';

//API
import { getCandidateListData } from '../../actions/CandidateList';
import { REQUEST_GET_ALL_UPCOMING_INTERVIEWS } from '../../actions/actionTypes';
import { getCMSMeetings } from '../../actions/CMSMeetings';

//Common Components
import BackRow from '../../components/common/BackRow';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';

//Common Packages
import { Tile, RadioButton, Select, SelectItem } from 'carbon-components-react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { capitalCase } from 'capital-case';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { create } from 'lodash';
import Dropdown from '../../components/common/Dropdown';
import {
  meetingTypeOptions,
  meetingStatusOptions,
} from '../../staticdata/CMSStaticData';

const cmsLegendColorScheme = {
  amber: { bgColor: '#FFB302', color: 'black' },
  red: { bgColor: '#FF3838', color: 'black' },
  green: { bgColor: '#56FF00', color: 'black' },
  blue: { bgColor: '#2DCCFF', color: 'black' },
  black: { bgColor: '#000000', color: 'white' },
  // grey: '#A4ABB6',
  // darkGreen: '#006400',
};

const lightOrDark = (color) => {
  var r, g, b, hsp;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

class CalendarSelectionBoard extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'calendar-info', name: 'Calendar', href: '' },
  ];
  constructor(props) {
    const date = new Date();
    const firstDayOnMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).getTime();
    const lastDayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getTime();
    let lastDayWithLastHour = new Date(lastDayOfMonth);
    lastDayWithLastHour.setHours(23, 59, 59, 999);
    const finalLastDayOfMonth = new Date(lastDayWithLastHour).getTime();
    super(props);
    const { user } = this.props;
    const { roleId } = user || {};
    const hideInterviewsFeature = roleId == 11 || roleId == 12 || roleId == 13;
    const hideCMSFeature = roleId == 8 || roleId == 9 || roleId == 10;

    this.state = {
      interviewStartDate: firstDayOnMonth,
      interviewEndDate: finalLastDayOfMonth,
      currentInterviewType: hideCMSFeature || roleId == 7 ? 'panel' : 'cms',
      hideInterviewsFeature,
      hideCMSFeature,
      selectedMeetingTypeList:
        roleId == 7 ? [{ label: 'PSSB', value: 'PSSB' }] : [],
      selectedMeetingStatusList: [],
    };
  }

  componentDidMount() {
    const { currentInterviewType } = this.state || {};
    if (currentInterviewType === 'cms') {
      this.handleGetCMSCalendarDetailsForMonth();
    } else {
      this.handleGetCalendarDetailsForMonth();
    }
  }

  handleGetCalendarDetailsForMonth = () => {
    const { user } = this.props;
    const { currentYear } = user || {};
    const { interviewEndDate, interviewStartDate, currentInterviewType } =
      this.state;
    let recordType = 'PSC';
    if (currentInterviewType === 'president-scholarship') {
      recordType = 'PS';
    }
    const data = {
      activePage: 1,
      itemsPerPage: 1000,
      searchKey: null,
      interviewEndDate,
      interviewStartDate,
      isInterviewList: true,
      isCurrentUserMainInterviewer: false,
      year: currentYear,
      getOnlyNecessaryDetail: true,
      recordType,
    };
    this.props.getCandidateListData(
      data,
      null,
      REQUEST_GET_ALL_UPCOMING_INTERVIEWS
    );
  };

  handleGetCMSCalendarDetailsForMonth = () => {
    const { user } = this.props;
    const { currentYear } = user || {};
    const {
      interviewEndDate,
      interviewStartDate,
      currentInterviewType,
      selectedMeetingTypeList,
      selectedMeetingStatusList,
    } = this.state;
    const data = {
      activePage: 1,
      itemsPerPage: 1000,
      searchKey: null,
      startDate: `"${moment(interviewStartDate).format('YYYY-MM-DD')}"`,
      endDate: `"${moment(interviewEndDate).format('YYYY-MM-DD')}"`,
      year: currentYear,
    };
    if (
      selectedMeetingTypeList &&
      Array.isArray(selectedMeetingTypeList) &&
      selectedMeetingTypeList.length > 0 &&
      selectedMeetingTypeList?.[0]?.value != 'All'
    ) {
      data['meetingTypeList'] = selectedMeetingTypeList.map(
        (itm) => itm?.value
      );
    }
    if (
      selectedMeetingStatusList &&
      Array.isArray(selectedMeetingStatusList) &&
      selectedMeetingStatusList.length > 0 &&
      selectedMeetingStatusList?.[0]?.value != 'All'
    ) {
      data['meetingStatusList'] = selectedMeetingStatusList.map(
        (itm) => itm?.value
      );
    }
    this.props.getCMSMeetings(data).then((res) => {
      if (res && !res.error) {
        const { list, count = 0 } = res || {};
        console.log('list', list);
        this.setState({ meetingsList: list, count });
      }
    });
  };

  handleChangeRangeDetails = (range) => {
    const { start, end } = range || {};
    this.setState(
      {
        interviewStartDate: new Date(start).getTime(),
        interviewEndDate: new Date(end).getTime(),
      },
      () => {
        if (this.state.currentInterviewType == 'cms') {
          this.handleGetCMSCalendarDetailsForMonth();
        } else {
          this.handleGetCalendarDetailsForMonth();
        }
      }
    );
  };

  handleToggleClick = (intType) => {
    const { user } = this.props;
    const { roleId } = user || {};
    this.setState(
      {
        currentInterviewType: intType,
        selectedMeetingTypeList:
          roleId == 7 ? [{ label: 'PSSB', value: 'PSSB' }] : [],
        selectedMeetingStatusList: [],
      },
      () => {
        if (this.state.currentInterviewType == 'cms') {
          this.handleGetCMSCalendarDetailsForMonth();
        } else {
          this.handleGetCalendarDetailsForMonth();
        }
      }
    );
  };

  eventStyleGetter = () => {
    const { currentInterviewType } = this.state;
    let backgroundColor = '#3174ad';
    if (currentInterviewType === 'president-scholarship') {
      backgroundColor = 'green';
    }
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  checkByAttendStatusAndDesig = (
    Usermeetingmaps,
    attendStatus,
    desig,
    minCount
  ) => {
    const result =
      Usermeetingmaps.filter(
        (itm) =>
          itm?.attendanceStatus == attendStatus &&
          itm?.Selectionboard?.designation == desig
      )?.length >= minCount;

    return result;
  };

  eventStyleGetterCMS = (event, start, end, isSelected) => {
    const { Usermeetingmaps, roleId, status: meetingStatus } = event || {};

    let backgroundColor = 'white';

    if (
      Usermeetingmaps &&
      Array.isArray(Usermeetingmaps) &&
      Usermeetingmaps.length > 0
    ) {
      const atleastOneScheduledChairman = this.checkByAttendStatusAndDesig(
        Usermeetingmaps,
        'Scheduled',
        'Chairman',
        1
      );

      const atleastOneScheduledDyChairman = this.checkByAttendStatusAndDesig(
        Usermeetingmaps,
        'Scheduled',
        'Dy Chairman',
        1
      );

      const atleastTwoScheduledCommMem = this.checkByAttendStatusAndDesig(
        Usermeetingmaps,
        'Scheduled',
        'Commission Member',
        2
      );

      const atleastOneAvailableChairman = this.checkByAttendStatusAndDesig(
        Usermeetingmaps,
        'Available',
        'Chairman',
        1
      );

      const atleastOneAvailableDyChairman = this.checkByAttendStatusAndDesig(
        Usermeetingmaps,
        'Available',
        'Dy Chairman',
        1
      );

      const atleastTwoAvailableCommMem = this.checkByAttendStatusAndDesig(
        Usermeetingmaps,
        'Available',
        'Commission Member',
        2
      );

      const areAllNotAvailable = Usermeetingmaps.every(
        (itm) => itm?.attendanceStatus == 'Not Available'
      );

      const areToBeConfirmed = Usermeetingmaps.every(
        (itm) => itm?.attendanceStatus == 'Not Available'
      );

      if (
        (atleastOneScheduledChairman || atleastOneScheduledDyChairman) &&
        atleastTwoScheduledCommMem
      ) {
        backgroundColor = cmsLegendColorScheme?.['green']?.['bgColor'];
      } else if (
        (atleastOneAvailableChairman || atleastOneAvailableDyChairman) &&
        atleastTwoAvailableCommMem
      ) {
        backgroundColor = cmsLegendColorScheme?.['blue']?.['bgColor'];
      } else if (areAllNotAvailable) {
        backgroundColor = cmsLegendColorScheme?.['red']?.['bgColor'];
      } else if (areToBeConfirmed) {
        backgroundColor = cmsLegendColorScheme?.['amber']?.['bgColor'];
      }

      // if (roleId == 6 || roleId == 7) {
      //   const isAttendLessThanThree =
      //     Usermeetingmaps.filter(
      //       (itm) =>
      //         itm?.attendanceStatus == 'Available' ||
      //         itm?.attendanceStatus == 'Scheduled'
      //     )?.length < 3;
      //   if (
      //     isAttendLessThanThree &&
      //     (!atleastOneAvailableChairman || !atleastOneAvailableDyChairman)
      //   ) {
      //     backgroundColor = cmsLegendColorScheme['green'];
      //   }
      // }
    }

    if (meetingStatus == 'Completed') {
      backgroundColor = cmsLegendColorScheme?.['black']?.['bgColor'];
    }

    const color =
      cmsLegendColorScheme &&
      Object.values(cmsLegendColorScheme)?.find(
        (a) => a['bgColor'] == backgroundColor
      )?.color;
    var style = {
      backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: color ?? 'black',
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  handleFilterDropDown = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.currentInterviewType == 'cms') {
          this.handleGetCMSCalendarDetailsForMonth();
        }
      }
    );
  };

  handleDropdownChange = (v, metaValue, name) => {
    let finalVal = metaValue;
    if (
      name == 'selectedMeetingTypeList' ||
      name == 'selectedMeetingStatusList'
    ) {
      if (metaValue && Array.isArray(metaValue) && metaValue.length > 0) {
        const drpDownContainsAll = metaValue.some((obj) => obj.value == 'All');
        finalVal = drpDownContainsAll
          ? [{ label: 'All', value: 'All' }]
          : metaValue;
      }
    }
    this.setState(
      {
        [name]: finalVal,
      },
      () => {
        this.handleGetCMSCalendarDetailsForMonth();
      }
    );
  };

  render() {
    const { allUpcomingInterviews, history, loading, cmsLoading, user } =
      this.props;
    const { roleId } = user || {};
    const { push } = history || {};
    const localizer = momentLocalizer(moment);
    let eventData = [];
    const {
      currentInterviewType,
      meetingsList,
      meetingType = 'Select',
      hideInterviewsFeature,
      hideCMSFeature,
      selectedMeetingTypeList,
      selectedMeetingStatusList,
    } = this.state;
    if (
      allUpcomingInterviews &&
      Array.isArray(allUpcomingInterviews) &&
      allUpcomingInterviews.length > 0
    ) {
      eventData = allUpcomingInterviews.map((appl) => {
        const { application, mainInterviewer, psMainInterviewer } = appl || {};
        const { profile, appId } =
          (application &&
            Array.isArray(application) &&
            application.length > 0 &&
            application[0]) ||
          {};
        const { fullName } = profile || {};
        const { createdAt } =
          (mainInterviewer &&
            Array.isArray(mainInterviewer) &&
            mainInterviewer.length > 0 &&
            mainInterviewer[0]) ||
          {};
        const { createdAt: psCreatedAt } =
          (psMainInterviewer &&
            Array.isArray(psMainInterviewer) &&
            psMainInterviewer.length > 0 &&
            psMainInterviewer[0]) ||
          {};
        if (currentInterviewType === 'panel' && createdAt) {
          return {
            title: fullName && capitalCase(fullName),
            start: createdAt,
            end: createdAt && moment(createdAt).add(30, 'm').format(),
            appId,
          };
        } else if (
          currentInterviewType === 'president-scholarship' &&
          psCreatedAt
        ) {
          return {
            title: fullName && capitalCase(fullName),
            start: psCreatedAt,
            end: psCreatedAt && moment(psCreatedAt).add(30, 'm').format(),
            appId,
          };
        }
      });
    }

    let eventDataCMS = [];
    if (
      meetingsList &&
      Array.isArray(meetingsList) &&
      meetingsList.length > 0
    ) {
      eventDataCMS = meetingsList.map((meeting) => {
        const {
          Usermeetingmaps,
          attendanceType,
          createdAt,
          startDate,
          meetingId,
          meetingTitle,
          timePeriod,
          status,
          purpose,
        } = meeting || {};
        return {
          title: `(${timePeriod}) - ${
            purpose ?? (meetingTitle && capitalCase(meetingTitle))
          }`,
          start: startDate,
          end: startDate && moment(startDate).add(30, 'm').format(),
          meetingId,
          Usermeetingmaps,
          roleId,
          status,
        };
      });

      // panel - hide cancelled meetings
      if (roleId == 7) {
        eventDataCMS = eventDataCMS?.filter(
          (itm) => itm?.status !== 'Cancelled'
        );
      }
    }

    return (
      <div>
        <BackRow paths={this.paths} />
        <Tile className="mt-2 mb-2">
          <div>
            <div className="f-w-b h6 mb-2">Interview Type</div>
            <div className="d-flex align-items-center flex-wrap">
              {!hideInterviewsFeature && (
                <>
                  <div className="d-flex justify-content-left mt-2">
                    <RadioButton
                      name="currentInterviewType"
                      labelText="PSSB"
                      value="panel"
                      onChange={() => this.handleToggleClick('panel')}
                      checked={currentInterviewType === 'panel'}
                    />
                  </div>
                  <div className="d-flex justify-content-left mt-2 ml-4">
                    <RadioButton
                      name="currentInterviewType"
                      labelText="President's Scholarship"
                      value="president-scholarship"
                      onChange={() =>
                        this.handleToggleClick('president-scholarship')
                      }
                      checked={currentInterviewType === 'president-scholarship'}
                    />
                  </div>
                </>
              )}
              {!hideCMSFeature && (
                <div className="d-flex justify-content-left mt-2 ml-4">
                  <RadioButton
                    name="currentInterviewType"
                    labelText="All Meetings"
                    value="cms"
                    onChange={() => this.handleToggleClick('cms')}
                    checked={currentInterviewType === 'cms'}
                  />
                </div>
              )}
              {currentInterviewType === 'cms' && (
                <div className="d-flex justify-content-left ml-4">
                  <div className="d-flex mt-2">
                    <div className="mr-2">
                      <Dropdown
                        placeholder="Select Meeting Type..."
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        isMulti={selectedMeetingTypeList?.[0]?.value != 'All'}
                        onInputChange={() => {}}
                        className="cms-filter-width"
                        options={meetingTypeOptions}
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        name="selectedMeetingTypeList"
                        selectedValue={selectedMeetingTypeList}
                      />
                    </div>
                    <div className="mr-2">
                      <Dropdown
                        placeholder="Select Meeting Status..."
                        title=""
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        isMulti={selectedMeetingStatusList?.[0]?.value != 'All'}
                        onInputChange={() => {}}
                        className="cms-filter-width"
                        options={
                          roleId == 7
                            ? meetingStatusOptions?.filter(
                                (itm) => itm?.value !== 'Cancelled'
                              )
                            : meetingStatusOptions
                        }
                        onChange={(value, e, name) =>
                          this.handleDropdownChange(value, e, name)
                        }
                        name="selectedMeetingStatusList"
                        selectedValue={selectedMeetingStatusList}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {currentInterviewType === 'cms' && (
              <div>
                <ul
                  className="mt-3"
                  style={{
                    display: 'flex',
                    listStyleType: 'none',
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                  }}>
                  {/* <li className="mr-2">
                    <span className="bg-white small-cir" />
                    Blank (Default)
                  </li> */}
                  <li className="mr-2">
                    <span
                      className="small-cir"
                      style={{
                        backgroundColor:
                          cmsLegendColorScheme?.['green']?.['bgColor'],
                      }}
                    />
                    Scheduled
                  </li>
                  <li className="mr-2">
                    <span
                      className="small-cir"
                      style={{
                        backgroundColor:
                          cmsLegendColorScheme?.['blue']?.['bgColor'],
                      }}
                    />
                    Available
                  </li>
                  {/* {(roleId == 6 || roleId == 7) && (
                    <li className="mr-2">
                      <span
                        className="small-cir"
                        style={{
                          backgroundColor: cmsLegendColorScheme['green'],
                        }}
                      />
                      Available / Scheduled
                    </li>
                  )} */}
                  <li className="mr-2">
                    <span
                      className="small-cir"
                      style={{
                        backgroundColor:
                          cmsLegendColorScheme?.['amber']?.['bgColor'],
                      }}
                    />
                    To Be Confirmed
                  </li>
                  <li className="mr-2">
                    <span
                      className="small-cir"
                      style={{
                        backgroundColor:
                          cmsLegendColorScheme?.['red']?.['bgColor'],
                      }}
                    />
                    Not Available
                  </li>
                  <li className="mr-2">
                    <span
                      className="small-cir"
                      style={{
                        backgroundColor:
                          cmsLegendColorScheme?.['black']?.['bgColor'],
                      }}
                    />
                    Completed
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Tile>
        <Tile className="">
          <div style={{ height: '700px' }}>
            {currentInterviewType === 'cms' ? (
              <Calendar
                selectable
                localizer={localizer}
                events={eventDataCMS || []}
                views={['month']}
                defaultView="month"
                step={60}
                startAccessor="start"
                endAccessor="end"
                onDrillDown={(e) => {
                  let currDateEvent = {};
                  const currEvent = e && moment(e).format('DD/MM/YYYY');
                  if (
                    eventDataCMS &&
                    Array.isArray(eventDataCMS) &&
                    eventDataCMS.length > 0
                  ) {
                    currDateEvent = eventDataCMS.find((res) => {
                      const allEvents =
                        res &&
                        res.start &&
                        moment(res.start).format('DD/MM/YYYY');
                      if (allEvents === currEvent) {
                        return true;
                      }
                      return false;
                    });
                  }
                  const { meetingId } = currDateEvent || {};
                  if (push) {
                    localStorage.setItem(
                      'paths',
                      JSON.stringify({
                        prevPath: '/app/csc/calendar/interviews',
                      })
                    );
                    push(`/app/csc/cms/create/new/meeting/${meetingId}`);
                  }
                }}
                onRangeChange={(e) => {
                  this.handleChangeRangeDetails(e);
                }}
                eventPropGetter={this.eventStyleGetterCMS}
                onSelectEvent={(e) => {
                  const { meetingId } = e || {};
                  if (push) {
                    localStorage.setItem(
                      'paths',
                      JSON.stringify({
                        prevPath: '/app/csc/calendar/interviews',
                      })
                    );
                    push(`/app/csc/cms/create/new/meeting/${meetingId}`);
                  }
                }}
              />
            ) : (
              <Calendar
                selectable
                localizer={localizer}
                events={eventData || []}
                views={['month']}
                defaultView="month"
                step={60}
                startAccessor="start"
                endAccessor="end"
                onRangeChange={(e) => {
                  this.handleChangeRangeDetails(e);
                }}
                onDrillDown={(e) => {
                  let currDateEvent = {};
                  const currEvent = e && moment(e).format('DD/MM/YYYY');
                  if (
                    eventData &&
                    Array.isArray(eventData) &&
                    eventData.length > 0
                  ) {
                    currDateEvent = eventData.find((res) => {
                      const allEvents =
                        res &&
                        res.start &&
                        moment(res.start).format('DD/MM/YYYY');
                      if (allEvents === currEvent) {
                        return true;
                      }
                      return false;
                    });
                  }
                  const { appId: applId } = currDateEvent || {};
                  if (push) {
                    push({
                      pathname: `/app/csc/calendar/selection/board/${
                        e && new Date(e).getTime()
                      }/candidate/${applId}/type/${
                        currentInterviewType === 'panel' ? 'PSC' : 'PS'
                      }`,
                      state: { selectedTab: 1 },
                    });
                  }
                }}
                eventPropGetter={this.eventStyleGetter}
                onSelectEvent={(e) => {
                  const { start, appId } = e || {};

                  if (push) {
                    push(
                      `/app/csc/calendar/selection/board/${
                        start && new Date(start).getTime()
                      }/candidate/${appId}/type/${
                        currentInterviewType === 'panel' ? 'PSC' : 'PS'
                      }`
                    );
                  }
                }}
              />
            )}
          </div>
        </Tile>
        <JDProcessOverlay
          show={loading || cmsLoading}
          message="processing..."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.CandidateList.loading,
  cmsLoading: state.CMSMeetings.loading,
  allUpcomingInterviews: state.CandidateList.allUpcomingInterviews,
  user: state.x0paDuser.user,
});

const mapDispatchToProps = {
  getCandidateListData,
  getCMSMeetings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarSelectionBoard);
