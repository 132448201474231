import React, { Fragment } from 'react';

import GenericDataTable from '../../components/common/GenericDataTable';
import moment from 'moment';
import MaleImg from '../../assets/images/male.png';
import FemaleImg from '../../assets/images/female.png';
import { Button, TextInput } from 'carbon-components-react';
import _ from 'lodash';

const FamilyInformation = (props) => {
  const {
    mothersObj,
    stepMothersObj,
    fathersObj,
    stepFathersObj,
    brotherArr,
    sisterArr,
    guardianArr,
    spouseArr,
    childArr,
  } = props || {};
  const {
    motherName,
    mCountry,
    mOccupation,
    mMarStatus,
    mScholarshipHeld,
    mOrganisation,
  } = mothersObj || {};
  const {
    stepMotherName = '-',
    stepMCountry = '-',
    stepMOccupation = '-',
    stepMMarStatus = '-',
    stepMScholarshipHeld = '-',
    stepMOrganisation = '-',
  } = stepMothersObj || {};
  const {
    fatherName,
    fCountry,
    fOccupation,
    fMarStatus,
    fScholarshipHeld,
    fOrganisation,
  } = fathersObj || {};
  const {
    stepFatherName = '-',
    stepFCountry = '-',
    stepFOccupation = '-',
    stepFMarStatus = '-',
    stepFScholarshipHeld = '-',
    stepFOrganisation = '-',
  } = stepFathersObj || {};
  return (
    <div>
      <div>
        <div className="bx--row">
          <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
            <div className="font-weight-bold">
              <b>Father's Information</b>
            </div>
            <hr />
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Name
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {fatherName}
              </div>
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Citizenship
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {fCountry}
              </div>
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Occupation
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {fOccupation}
              </div>
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Organisation
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {fOrganisation}
              </div>
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Marital Status
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {fMarStatus}
              </div>
            </div>
            <div className="bx--row mt-2 mb-3">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Scholarship Held
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {fScholarshipHeld}
              </div>
            </div>
          </div>
          <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
            <div className="font-weight-bold">
              <b>Mother's Information</b>
            </div>
            <hr />
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Name
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {motherName}
              </div>
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Citizenship
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {mCountry}
              </div>
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Occupation
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {mOccupation}
              </div>
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Organisation
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {mOrganisation}
              </div>
            </div>
            <div className="bx--row mt-2">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Marital Status
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {mMarStatus}
              </div>
            </div>
            <div className="bx--row mt-2 mb-3">
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                Scholarship Held
              </div>
              <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                {mScholarshipHeld}
              </div>
            </div>
          </div>
          {!_.isEmpty(stepFathersObj) && (
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
              <div className="font-weight-bold">
                <b>Step Father's Information</b>
              </div>
              <hr />
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Name
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepFatherName}
                </div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Citizenship
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepFCountry}
                </div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Occupation
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepFOccupation}
                </div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Organisation
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepFOrganisation}
                </div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Marital Status
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepFMarStatus}
                </div>
              </div>
              <div className="bx--row mt-2 mb-3">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Scholarship Held
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepFScholarshipHeld}
                </div>
              </div>
            </div>
          )}
          {!_.isEmpty(stepMothersObj) && (
            <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
              <div className="font-weight-bold">
                <b>Step Mother's Information</b>
              </div>
              <hr />
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Name
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepMotherName}
                </div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Citizenship
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepMCountry}
                </div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Occupation
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepMOccupation}
                </div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Organisation
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepMOrganisation}
                </div>
              </div>
              <div className="bx--row mt-2">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Marital Status
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepMMarStatus}
                </div>
              </div>
              <div className="bx--row mt-2 mb-3">
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  Scholarship Held
                </div>
                <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                  {stepMScholarshipHeld}
                </div>
              </div>
            </div>
          )}
          {brotherArr &&
            Array.isArray(brotherArr) &&
            brotherArr.length > 0 &&
            brotherArr.map((brother) => {
              const {
                brotherName,
                bCountry,
                bOccupation,
                bMarStatus,
                bScholarshipHeld,
                bOrganisation,
              } = brother || {};
              return (
                <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
                  <div className="font-weight-bold">
                    <b>Brother's Information</b>
                  </div>
                  <hr />
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Name
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {brotherName}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Citizenship
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {bCountry}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Occupation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {bOccupation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Organisation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {bOrganisation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Marital Status
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {bMarStatus}
                    </div>
                  </div>
                  <div className="bx--row mt-2 mb-3">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Scholarship Held
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {bScholarshipHeld}
                    </div>
                  </div>
                </div>
              );
            })}
          {sisterArr &&
            Array.isArray(sisterArr) &&
            sisterArr.length > 0 &&
            sisterArr.map((sister) => {
              const {
                sisterName,
                sCountry,
                sOccupation,
                sMarStatus,
                sScholarshipHeld,
                sOrganisation,
              } = sister || {};
              return (
                <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
                  <div className="font-weight-bold">
                    <b>Sister's Information</b>
                  </div>
                  <hr />
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Name
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {sisterName}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Citizenship
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {sCountry}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Occupation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {sOccupation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Organisation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {sOrganisation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Marital Status
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {sMarStatus}
                    </div>
                  </div>
                  <div className="bx--row mt-2 mb-3">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Scholarship Held
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {sScholarshipHeld}
                    </div>
                  </div>
                </div>
              );
            })}
          {guardianArr &&
            Array.isArray(guardianArr) &&
            guardianArr.length > 0 &&
            guardianArr.map((guardian) => {
              const {
                guardianName,
                gCountry,
                gOccupation,
                gMarStatus,
                gScholarshipHeld,
                gOrganisation,
              } = guardian || {};
              return (
                <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
                  <div className="font-weight-bold">
                    <b>Guardian's Information</b>
                  </div>
                  <hr />
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Name
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {guardianName}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Citizenship
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {gCountry}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Occupation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {gOccupation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Organisation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {gOrganisation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Marital Status
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {gMarStatus}
                    </div>
                  </div>
                  <div className="bx--row mt-2 mb-3">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Scholarship Held
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {gScholarshipHeld}
                    </div>
                  </div>
                </div>
              );
            })}
          {spouseArr &&
            Array.isArray(spouseArr) &&
            spouseArr.length > 0 &&
            spouseArr.map((spouse) => {
              const {
                spouseName,
                spCountry,
                spOccupation,
                spMarStatus,
                spScholarshipHeld,
                spOrganisation,
              } = spouse || {};
              return (
                <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
                  <div className="font-weight-bold">
                    <b>Spouse's Information</b>
                  </div>
                  <hr />
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Name
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {spouseName}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Citizenship
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {spCountry}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Occupation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {spOccupation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Organisation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {spOrganisation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Marital Status
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {spMarStatus}
                    </div>
                  </div>
                  <div className="bx--row mt-2 mb-3">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Scholarship Held
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {spScholarshipHeld}
                    </div>
                  </div>
                </div>
              );
            })}
          {childArr &&
            Array.isArray(childArr) &&
            childArr.length > 0 &&
            childArr.map((child) => {
              const {
                childName,
                cCountry,
                cOccupation,
                cMarStatus,
                cScholarshipHeld,
                cOrganisation,
              } = child || {};
              return (
                <div className="bx--col-lg-6 bx--col-md-12 bx--col-sm-12 bx--col-xs-12 mt-2">
                  <div className="font-weight-bold">
                    <b>Child's Information</b>
                  </div>
                  <hr />
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Name
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {childName}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Citizenship
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {cCountry}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Occupation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {cOccupation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Organisation
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {cOrganisation}
                    </div>
                  </div>
                  <div className="bx--row mt-2">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Marital Status
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {cMarStatus}
                    </div>
                  </div>
                  <div className="bx--row mt-2 mb-3">
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      Scholarship Held
                    </div>
                    <div className="bx--col-lg-4 text-capitalize bx--col-md-4">
                      {cScholarshipHeld}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const CandidatePersonalInfo = (props) => {
  const {
    applicationInfo,
    appId,
    family,
    ns,
    psStatus2ndLevel,
    bmsUnit,
    handleChange,
    isBMSUnitValid,
    handleSubmitBMSUnit,
    roleId,
    showEditBmsUnitBtn,
    handleEditBMSUnit,
  } = props || {};
  const { profile, nominationRanking } = applicationInfo || {};
  const {
    fullName,
    country,
    nric,
    dob,
    genderId,
    ethnicity,
    otherEthinicIdRel,
  } = profile || {};
  const { ethnicityName } = ethnicity || {};
  const { ethnicityName: otherEthnicityName } = otherEthinicIdRel || {};
  const { displayName: idType } = country || {};
  let age = 0;
  if (dob) {
    const dobDate = new Date(dob);
    var diff_ms = Date.now() - dobDate.getTime();
    var age_dt = new Date(diff_ms);
    age = Math.abs(age_dt.getUTCFullYear() - 1970);
  } else {
    age = '-';
  }
  const nsHeaders = [
    {
      key: 'header_0',
      header: 'NS Medical Grading',
    },

    {
      key: 'header_1',
      header: 'Date Enlisted',
    },
    {
      header: 'NS ORD',
      key: 'header_2',
    },
    {
      key: 'header_3',
      header: 'Rank',
    },
    {
      key: 'header_4',
      header: 'Unit',
    },
    {
      key: 'header_5',
      header: 'Vocation',
    },
  ];
  if (
    psStatus2ndLevel &&
    psStatus2ndLevel.toString() &&
    psStatus2ndLevel.toString().length > 0
  ) {
    nsHeaders.push({
      key: 'header_6',
      header: 'BMT Ranking',
      className: 'w-25',
    });
  }
  const {
    grade,
    nsEnlistmentDate,
    nsrank,
    nsUnit,
    nsVocation,
    nsstatus,
    nsOrd,
  } = (ns && ns[0]) || {};
  const { nsstatusName, displayName: nsStatusDisplayName } = nsstatus || {};
  const { displayName: nsMedicalGrading } = grade || {};
  const finalData = {
    fullName,
    idType,
    nric,
    genderId,
    nominationRanking,
    ethnicityName:
      ethnicityName === 'others' ? otherEthnicityName : ethnicityName,
  };
  const tableData = [finalData];
  const nsTableData = [
    {
      nsMedicalGrading,
      nsEnlistmentDate,
      nsrank,
      nsUnit,
      nsVocation,
      nsstatusName,
      nsOrd,
      nsStatusDisplayName,
    },
  ];
  let fathersObj = {
    fatherName: '-',
    fCountry: '-',
    fOccupation: '-',
    fMarStatus: '-',
    fScholarshipHeld: '-',
    fOrganisation: '-',
  };
  let stepFathersObj = {};
  let mothersObj = {
    motherName: '-',
    mCountry: '-',
    mOccupation: '-',
    mMarStatus: '-',
    mScholarshipHeld: '-',
    mOrganisation: '-',
  };
  let stepMothersObj = {};
  const sisterArr = [];
  const brotherArr = [];
  const guardianArr = [];
  const spouseArr = [];
  const childArr = [];
  if (family && Array.isArray(family) && family.length > 0) {
    family.forEach((res) => {
      const {
        fullName,
        country,
        occupation,
        marriage,
        relationship,
        pscScholarshipHeld,
        company: organisation,
      } = res || {};
      const { displayName: countryDisplayName } = country || {};
      const { displayName: occDisplayName } = occupation || {};
      const { maritalStatus } = marriage || {};
      const { displayName: relationDisplayName } = relationship || {};
      const { displayName: organisationName } = organisation || {};
      if (relationDisplayName === 'Mother') {
        mothersObj.motherName = fullName || '-';
        mothersObj.mCountry = countryDisplayName || '-';
        mothersObj.mOccupation = occDisplayName || '-';
        mothersObj.mMarStatus = maritalStatus || '-';
        mothersObj.mScholarshipHeld = pscScholarshipHeld
          ? pscScholarshipHeld
          : '-';
        mothersObj.mOrganisation = organisationName ? organisationName : '-';
      } else if (relationDisplayName === 'Step-Mother') {
        stepMothersObj.stepMotherName = fullName || '-';
        stepMothersObj.stepMCountry = countryDisplayName || '-';
        stepMothersObj.stepMOccupation = occDisplayName || '-';
        stepMothersObj.stepMMarStatus = maritalStatus || '-';
        stepMothersObj.stepMScholarshipHeld = pscScholarshipHeld
          ? pscScholarshipHeld
          : '-';
        stepMothersObj.stepMOrganisation = organisationName
          ? organisationName
          : '-';
      } else if (relationDisplayName === 'Father') {
        fathersObj.fatherName = fullName || '-';
        fathersObj.fCountry = countryDisplayName || '-';
        fathersObj.fOccupation = occDisplayName || '-';
        fathersObj.fMarStatus = maritalStatus || '-';
        fathersObj.fScholarshipHeld = pscScholarshipHeld
          ? pscScholarshipHeld
          : '-';
        fathersObj.fOrganisation = organisationName ? organisationName : '-';
      } else if (relationDisplayName === 'Step-Father') {
        stepFathersObj.stepFatherName = fullName || '-';
        stepFathersObj.stepFCountry = countryDisplayName || '-';
        stepFathersObj.stepFOccupation = occDisplayName || '-';
        stepFathersObj.stepFMarStatus = maritalStatus || '-';
        stepFathersObj.stepFScholarshipHeld = pscScholarshipHeld
          ? pscScholarshipHeld
          : '-';
        stepFathersObj.stepFOrganisation = organisationName
          ? organisationName
          : '-';
      } else if (relationDisplayName === 'Sister') {
        sisterArr.push({
          sisterName: fullName || '-',
          sCountry: countryDisplayName || '-',
          sOccupation: occDisplayName || '-',
          sMarStatus: maritalStatus || '-',
          sScholarshipHeld: pscScholarshipHeld ? pscScholarshipHeld : '-',
          sOrganisation: organisationName ? organisationName : '-',
        });
      } else if (relationDisplayName === 'Brother') {
        brotherArr.push({
          brotherName: fullName || '-',
          bCountry: countryDisplayName || '-',
          bOccupation: occDisplayName || '-',
          bMarStatus: maritalStatus || '-',
          bScholarshipHeld: pscScholarshipHeld ? pscScholarshipHeld : '-',
          bOrganisation: organisationName ? organisationName : '-',
        });
      } else if (relationDisplayName === 'Guardian') {
        guardianArr.push({
          guardianName: fullName || '-',
          gCountry: countryDisplayName || '-',
          gOccupation: occDisplayName || '-',
          gMarStatus: maritalStatus || '-',
          gScholarshipHeld: pscScholarshipHeld ? pscScholarshipHeld : '-',
          gOrganisation: organisationName ? organisationName : '-',
        });
      } else if (relationDisplayName === 'Spouse') {
        spouseArr.push({
          spouseName: fullName || '-',
          spCountry: countryDisplayName || '-',
          spOccupation: occDisplayName || '-',
          spMarStatus: maritalStatus || '-',
          spScholarshipHeld: pscScholarshipHeld ? pscScholarshipHeld : '-',
          spOrganisation: organisationName ? organisationName : '-',
        });
      } else if (relationDisplayName === 'Child') {
        childArr.push({
          childName: fullName || '-',
          cCountry: countryDisplayName || '-',
          cOccupation: occDisplayName || '-',
          cMarStatus: maritalStatus || '-',
          cScholarshipHeld: pscScholarshipHeld ? pscScholarshipHeld : '-',
          cOrganisation: organisationName ? organisationName : '-',
        });
      }
    });
  }

  return (
    <div>
      <div className="mt-2 h6 font-weight-bold">
        Personal and Family Information
      </div>
      <hr />
      <div>
        <GenericDataTable
          className=""
          otherActions={[]}
          expandedRows={[
            <FamilyInformation
              fathersObj={fathersObj}
              stepFathersObj={stepFathersObj}
              mothersObj={mothersObj}
              stepMothersObj={stepMothersObj}
              brotherArr={brotherArr}
              sisterArr={sisterArr}
              guardianArr={guardianArr}
              spouseArr={spouseArr}
              childArr={childArr}
            />,
          ]}
          headers={[
            {
              key: 'header_0',
              header: 'Name',
              className: 'w-25',
            },
            {
              key: 'header_1',
              header: 'Age',
              className: 'w-20',
            },
            {
              key: 'header_2',
              header: 'NRIC',
              className: 'w-20',
            },
            {
              key: 'header_3',
              header: 'Citizenship',
              className: 'w-20',
            },
            {
              key: 'header_4',
              header: 'Ethnic Group',
              className: 'w-20',
            },
            // {
            //   key: 'header_5',
            //   header: 'School Rank',
            // },
          ]}
          rows={tableData.map((res) => {
            const { fullName, idType, nric, nominationRanking, ethnicityName } =
              res || {};
            const nricSubStr =
              nric &&
              nric.toString() &&
              nric.toString().length >= 6 &&
              nric.toString().substring(1, 5);
            const finalNric =
              nric && nricSubStr && nric.toString().replace(nricSubStr, 'xxxx');
            return {
              isExpanded: true,
              id: `row_${res && res.id}`,
              header_0: (
                <div className="bx--row align-items-center mt-2 mt-xl-0">
                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                    Name:
                  </div>
                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                    <div className="d-flex align-items-center">
                      <div className="ml-2 ml-md-0 ml-xs-0 ml-sm-0">
                        {genderId == 1 ? (
                          <img
                            style={{ width: '20px' }}
                            src={MaleImg}
                            alt="Male"
                          />
                        ) : null}
                        {genderId == 2 ? (
                          <img
                            style={{ width: '20px' }}
                            src={FemaleImg}
                            alt="female"
                          />
                        ) : null}
                      </div>
                      <div className="ml-2 text-capitalize">{fullName}</div>
                    </div>
                  </div>
                </div>
              ),
              header_1: (
                <div className="bx--row align-items-center mt-2 mt-xl-0">
                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                    Age:
                  </div>
                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                    <div>
                      {age !== null && age !== undefined && age !== ''
                        ? age
                        : '-'}
                    </div>
                  </div>
                </div>
              ),
              header_2: (
                <div className="bx--row align-items-center mt-2 mt-xl-0">
                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                    NRIC:
                  </div>
                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                    <div className="text-uppercase">
                      {nric ?? '-'}
                    </div>
                  </div>
                </div>
              ),
              header_3: (
                <div className="bx--row align-items-center mt-2 mt-xl-0">
                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                    Citizenship:
                  </div>
                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                    <div>{idType ? idType : '-'}</div>
                  </div>
                </div>
              ),
              header_4: (
                <div className="bx--row align-items-center mt-2 mt-xl-0">
                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                    Ethnicity:
                  </div>
                  <div className="text-capitalize bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                    {ethnicityName ? ethnicityName : '-'}
                  </div>
                </div>
              ),
              header_5: (
                <div className="bx--row align-items-center mt-2 mt-xl-0">
                  <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                    School Rank:
                  </div>
                  <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                    <div>{nominationRanking ? nominationRanking : '-'}</div>
                  </div>
                </div>
              ),
            };
          })}
          selectable={false}
          searchable={false}
          onSearchInputChange={() => {}}
          expandable
          batchActions={false}
        />
      </div>
      <div className="d-lg-none">
        <FamilyInformation
          fathersObj={fathersObj}
          mothersObj={mothersObj}
          brotherArr={brotherArr}
          sisterArr={sisterArr}
          guardianArr={guardianArr}
          spouseArr={spouseArr}
          childArr={childArr}
        />
      </div>
      <div className="mt-3 h6 font-weight-bold">
        National Service Information
      </div>
      <hr />
      <GenericDataTable
        otherActions={[]}
        searchable={false}
        expandable={false}
        rows={
          nsTableData && Array.isArray(nsTableData) && nsTableData.length > 0
            ? nsTableData.map((data, idx) => {
                const {
                  nsMedicalGrading,
                  nsEnlistmentDate,
                  nsrank,
                  nsUnit,
                  nsVocation,
                  nsstatusName,
                  nsOrd,
                } = data || {};
                const { displayName: nsRankId } = nsrank || {};
                return {
                  isExpanded: false,
                  id: idx + 1,

                  header_0: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        NS Medical Grading:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div>{nsMedicalGrading ? nsMedicalGrading : '-'}</div>
                      </div>
                    </div>
                  ),

                  header_1: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Date Enlisted:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div>
                          {nsEnlistmentDate
                            ? moment(nsEnlistmentDate).format('DD/MM/YYYY')
                            : '-'}
                        </div>
                      </div>
                    </div>
                  ),
                  header_2: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        NS ORD:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div>
                          {nsOrd ? moment(nsOrd).format('DD/MM/YYYY') : '-'}
                        </div>
                      </div>
                    </div>
                  ),
                  header_3: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Rank:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div>{nsRankId ? nsRankId : '-'}</div>
                      </div>
                    </div>
                  ),
                  header_4: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Unit:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div>{nsUnit ? nsUnit : '-'}</div>
                      </div>
                    </div>
                  ),
                  header_5: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        Vocation:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        <div>{nsVocation ? nsVocation : '-'}</div>
                      </div>
                    </div>
                  ),
                  header_6: (
                    <div className="bx--row align-items-center mt-2 mt-xl-0">
                      <div className="bx--col-md-3 bx--col-sm-3 bx--col-xs-3 bx--type-zeta d-xl-none d-lg-none d-xl-none">
                        BMT Ranking:
                      </div>
                      <div className="bx--col-lg-12 bx--col-md-5 bx--col-sm-6 bx--col-xs-6">
                        {roleId === 6 ? (
                          <Fragment>
                            {showEditBmsUnitBtn ? (
                              <div className="d-flex align-items-center">
                                <div>{bmsUnit || '-'}</div>
                                <div className="ml-2">
                                  <div className="ml-2">
                                    <Button
                                      size="small"
                                      kind="tertiary"
                                      onClick={handleEditBMSUnit}>
                                      Edit
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="d-xl-flex align-items-center">
                                <div>
                                  <TextInput
                                    id="bmsUnit"
                                    name="bmsUnit"
                                    value={bmsUnit}
                                    placeholder="BMT Ranking"
                                    onChange={handleChange}
                                    invalid={isBMSUnitValid}
                                    invalidText="Please enter valid value"
                                  />
                                </div>
                                <div className="ml-0 ml-xl-2 mt-2 mt-xl-0">
                                  <Button
                                    size="small"
                                    onClick={handleSubmitBMSUnit}>
                                    Save
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ) : (
                          <div>{bmsUnit || '-'}</div>
                        )}
                      </div>
                    </div>
                  ),
                };
              })
            : []
        }
        headers={nsHeaders}
      />
      <div
        style={{ background: 'rgb(244, 244, 244)' }}
        className="text-capitalize p-4">
        NS Status: {nsStatusDisplayName || '-'}
      </div>
    </div>
  );
};

export default CandidatePersonalInfo;
