import {
  REQUEST_UPLOAD_REPORTS_CSV,
  SUCCESS_UPLOAD_REPORTS_CSV,
  ERROR_UPLOAD_REPORTS_CSV,
  REQUEST_GET_REPORT_LOGS,
  SUCCESS_GET_REPORT_LOGS,
  ERROR_GET_REPORT_LOGS,
  REQUEST_GENERATE_HIGH_LVL_REPORTS,
  SUCCESS_GENERATE_HIGH_LVL_REPORTS,
  ERROR_GENERATE_HIGH_LVL_REPORTS,
  REQUEST_GET_TEST_NORM_DETAILS,
  SUCCESS_GET_TEST_NORM_DETAILS,
  ERROR_GET_TEST_NORM_DETAILS,
  REQUEST_GET_TEST_DETAILS,
  SUCCESS_GET_TEST_DETAILS,
  ERROR_GET_TEST_DETAILS,
  REQUEST_GENERATE_CELERY_TASK_STATUS,
  SUCCESS_GENERATE_CELERY_TASK_STATUS,
  ERROR_GENERATE_CELERY_TASK_STATUS,
  REQUEST_GET_DEFAULT_NORM_DETAILS,
  SUCCESS_GET_DEFAULT_NORM_DETAILS,
  ERROR_GET_DEFAULT_NORM_DETAILS,
  REQUEST_UPLOAD_CANDIDATE_NRICS,
  SUCCESS_UPLOAD_CANDIDATE_NRICS,
  ERROR_UPLOAD_CANDIDATE_NRICS,
  REQUEST_UPLOAD_PSYCHOLOGICAL_RATING,
  SUCCESS_UPLOAD_PSYCHOLOGICAL_RATING,
  ERROR_UPLOAD_PSYCHOLOGICAL_RATING,
  REQUEST_UPLOAD_ASSESSMENT_CENTER_DATA,
  SUCCESS_UPLOAD_ASSESSMENT_CENTER_DATA,
  ERROR_UPLOAD_ASSESSMENT_CENTER_DATA,
  REQUEST_UPLOAD_PEER_RATINGS_DATA,
  SUCCESS_UPLOAD_PEER_RATINGS_DATA,
  ERROR_UPLOAD_PEER_RATINGS_DATA,
  REQUEST_UPLOAD_PS_BRIEFING_DECK_DOC,
  SUCCESS_UPLOAD_PS_BRIEFING_DECK_DOC,
  ERROR_UPLOAD_PS_BRIEFING_DECK_DOC,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  reportLogs: [],
  count: 0,
  testNorms: [],
  task_id: '',
  testsDetails: [],
  celeryData: {},
  defaultNorms: [],
};

const HighLevelReports = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_UPLOAD_REPORTS_CSV:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_REPORTS_CSV:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_REPORTS_CSV:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_REPORT_LOGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_REPORT_LOGS:
      return {
        ...state,
        reportLogs: payload && payload.list,
        count: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_REPORT_LOGS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GENERATE_HIGH_LVL_REPORTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GENERATE_HIGH_LVL_REPORTS:
      return {
        ...state,
        task_id: payload && payload.task_id,
        loading: false,
        error: null,
      };
    case ERROR_GENERATE_HIGH_LVL_REPORTS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_TEST_NORM_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_TEST_NORM_DETAILS:
      return {
        ...state,
        testNorms: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_TEST_NORM_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_TEST_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_TEST_DETAILS:
      return {
        ...state,
        testsDetails: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_TEST_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GENERATE_CELERY_TASK_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GENERATE_CELERY_TASK_STATUS:
      return {
        ...state,
        celeryData: payload && payload.task_result,
        loading: false,
        error: null,
      };
    case ERROR_GENERATE_CELERY_TASK_STATUS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_DEFAULT_NORM_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_DEFAULT_NORM_DETAILS:
      return {
        ...state,
        defaultNorms: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_DEFAULT_NORM_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_CANDIDATE_NRICS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_CANDIDATE_NRICS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_CANDIDATE_NRICS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_PSYCHOLOGICAL_RATING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_PSYCHOLOGICAL_RATING:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_PSYCHOLOGICAL_RATING:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_ASSESSMENT_CENTER_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_ASSESSMENT_CENTER_DATA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_ASSESSMENT_CENTER_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_PEER_RATINGS_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_PEER_RATINGS_DATA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_PEER_RATINGS_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_PS_BRIEFING_DECK_DOC:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_PS_BRIEFING_DECK_DOC:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_PS_BRIEFING_DECK_DOC:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default HighLevelReports;
