import React from 'react';
import { Link } from 'react-router-dom';
class ForgotPassword extends React.Component {
  constructor() {
    super();
  }
  state = {};

  handleOnChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  forgotPassword = () => {
    const eData = {
      email:
        typeof this.state.email === 'string'
          ? this.state.email.toLowerCase()
          : '',
    };
    fetch('/roboroy/api/v1/userinfos/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(eData),
    })
      .then(function (response) {
        return response.json();
      })
      .then((resJSON) => {
        if (resJSON && resJSON.error) {
          this.setState({ error: true, errorMsg: resJSON.error.message });
        } else {
          this.setState({ error: false });
        }
      })
      .catch((err) => this.setState({ error: true }));
  };
  render() {
    return (
      <div
        style={{ height: '100vh' }}
        className=" login-page container css-forgot-pwd-pd">
        <div className="bx--row justify-content-center">
          <div className="bx--col-xl-10 bx--col-lg-12 bx--col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div style={{ minHeight: '400px' }} className="card-body p-0">
                <div style={{ minHeight: '400px' }} className=" bx--row ">
                  <div className="bx--col-lg-6 d-none d-lg-block left-side p-0">
                    <div
                      className="col m-0 p-0 d-flex justify-content-center align-items-center"
                      style={{
                        background: '#4281cc',
                        height: '100%',
                        color: 'white',
                      }}>
                      <div className="m-3 text-center p-2">
                        <div
                          style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '2.5rem',
                          }}
                          className="mb-2">
                          {' '}
                          Forgot your password?
                        </div>
                        <p class="mb-4">
                          We get it, stuff happens. Just enter your email
                          address below and we'll send you a link to reset your
                          password!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bx--col-lg-6">
                    <div
                      className="  pt-5 pl-2 pb-5 pr-3"
                      style={{
                        height: '100%',
                        justifyItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}>
                      <form
                        className="text-center p-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.forgotPassword();
                        }}>
                        {/* <div class="text-center">
                          <a href={window.location.origin || '#'}>
                            <img
                              className="mb-4 mx-auto"
                              src={require('../../assets/images/csc-image.png')}
                              alt=""
                              width="25%"
                            />
                          </a>
                        </div> */}
                        <div className="text-center bx--type-delta pb-2">
                          Forgot Password?
                        </div>
                        {this.state.error && (
                          <div className="m-1 text-danger xpa-f-sz--M ">
                            {this.state.errorMsg ||
                              ' There was an error in processing. Please try again later.'}
                          </div>
                        )}
                        {this.state.error === false && (
                          <div className="m-1 text-success xpa-f-sz--M ">
                            If an account exists with the provided email
                            address, an email will be sent to reset your
                            password.
                          </div>
                        )}
                        <div className="pb-2">
                          <input
                            type="email"
                            required
                            name="username"
                            placeholder="Enter your registered email address"
                            autoComplete="username"
                            className="bx--text-input w-75 mb-2"
                            onChange={(e) =>
                              this.handleOnChange('email', e.target.value)
                            }
                          />
                          <button
                            class="bx--btn bx--btn--primary"
                            type="submit">
                            Reset password
                          </button>
                        </div>
                        <Link className="mt-2" to="/">
                          &larr; Go back to login page
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Page = () => (
  <div className="page-forgot">
    <div className="main-body">
      <ForgotPassword />
    </div>
  </div>
);

export default Page;
