import {
  REQUEST_GET_CANDIDATE_LIST,
  SUCCESS_GET_CANDIDATE_LIST,
  ERROR_GET_CANDIDATE_LIST,
  REQUEST_GET_CANDIDATE_INFO,
  SUCCESS_GET_CANDIDATE_INFO,
  ERROR_GET_CANDIDATE_INFO,
  REQUEST_GET_CANDIDATE_INTERVIEWS_TODAY,
  SUCCESS_GET_CANDIDATE_INTERVIEWS_TODAY,
  ERROR_GET_CANDIDATE_INTERVIEWS_TODAY,
  REQUEST_GET_MY_UPCOMING_INTERVIEWS,
  SUCCESS_GET_MY_UPCOMING_INTERVIEWS,
  ERROR_GET_MY_UPCOMING_INTERVIEWS,
  REQUEST_GET_CANDIDATE_PROFILE_DETAILS,
  SUCCESS_GET_CANDIDATE_PROFILE_DETAILS,
  ERROR_GET_CANDIDATE_PROFILE_DETAILS,
  REQUEST_GET_JOB_APPL_FILTERS,
  SUCCESS_GET_JOB_APPL_FILTERS,
  ERROR_GET_JOB_APPL_FILTERS,
  REQUEST_GET_CANDIDATE_SEARCH_LIST,
  SUCCESS_GET_CANDIDATE_SEARCH_LIST,
  ERROR_GET_CANDIDATE_SEARCH_LIST,
  REQUEST_GET_MY_PAST_INTERVIEWS,
  SUCCESS_GET_MY_PAST_INTERVIEWS,
  ERROR_GET_MY_PAST_INTERVIEWS,
  REQUEST_GET_ALL_UPCOMING_INTERVIEWS,
  SUCCESS_GET_ALL_UPCOMING_INTERVIEWS,
  ERROR_GET_ALL_UPCOMING_INTERVIEWS,
  REQUEST_GET_ALL_PAST_INTERVIEWS,
  SUCCESS_GET_ALL_PAST_INTERVIEWS,
  ERROR_GET_ALL_PAST_INTERVIEWS,
  REQUEST_ASSIGN_CAND_TO_INT_STAGE,
  SUCCESS_ASSIGN_CAND_TO_INT_STAGE,
  ERROR_ASSIGN_CAND_TO_INT_STAGE,
  REQUEST_GET_STATIC_APPL_FILTERS,
  SUCCESS_GET_STATIC_APPL_FILTERS,
  ERROR_GET_STATIC_APPL_FILTERS,
  REQUEST_UPLOAD_CANDIDATES_DATA,
  SUCCESS_UPLOAD_CANDIDATES_DATA,
  ERROR_UPLOAD_CANDIDATES_DATA,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  candidateData: [],
  candidateCount: 0,
  filters: {},
  applicationInfo: {},
  candidateInfo: {},
  interviewsToday: [],
  interviewsTodayCount: 0,
  myUpcomingInterviews: [],
  myUpcomingInterviewsCount: 0,
  jobApplFilters: [],
  candidateSearchData: [],
  candidateSearchCount: 0,
  myPastInterviews: [],
  myPastInterviewsCount: 0,
  allUpcomingInterviews: [],
  allUpcomingInterviewsCount: 0,
  allPastInterviews: [],
  allPastInterviewsCount: 0,
  staticApplFilterInfo: {},
};

const CandidateList = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_CANDIDATE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_LIST:
      return {
        ...state,
        filters: payload && payload.filterFacets,
        candidateData: payload && payload.list,
        candidateCount: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CANDIDATE_INFO:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_INFO:
      const { list } = payload || {};
      const { application } = (list && list[0]) || {};
      return {
        ...state,
        candidateInfo: (list && list[0]) || {},
        applicationInfo: (application && application[0]) || {},
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_INFO:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CANDIDATE_INTERVIEWS_TODAY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_INTERVIEWS_TODAY:
      return {
        ...state,
        interviewsToday: payload && payload.list,
        interviewsTodayCount: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_INTERVIEWS_TODAY:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_MY_UPCOMING_INTERVIEWS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_MY_UPCOMING_INTERVIEWS:
      return {
        ...state,
        myUpcomingInterviews: payload && payload.list,
        myUpcomingInterviewsCount: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_MY_UPCOMING_INTERVIEWS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_MY_PAST_INTERVIEWS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_MY_PAST_INTERVIEWS:
      return {
        ...state,
        myPastInterviews: payload && payload.list,
        myPastInterviewsCount: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_MY_PAST_INTERVIEWS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_ALL_UPCOMING_INTERVIEWS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ALL_UPCOMING_INTERVIEWS:
      return {
        ...state,
        allUpcomingInterviews: payload && payload.list,
        allUpcomingInterviewsCount: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_ALL_UPCOMING_INTERVIEWS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_ALL_PAST_INTERVIEWS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ALL_PAST_INTERVIEWS:
      return {
        ...state,
        allPastInterviews: payload && payload.list,
        allPastInterviewsCount: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_ALL_PAST_INTERVIEWS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_CANDIDATE_PROFILE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_PROFILE_DETAILS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_PROFILE_DETAILS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_JOB_APPL_FILTERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_JOB_APPL_FILTERS:
      return {
        ...state,
        jobApplFilters: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_JOB_APPL_FILTERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_CANDIDATE_SEARCH_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_CANDIDATE_SEARCH_LIST:
      return {
        ...state,
        candidateSearchData: payload && payload.list,
        candidateSearchCount: payload && payload.totalCount,
        loading: false,
        error: null,
      };
    case ERROR_GET_CANDIDATE_SEARCH_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_ASSIGN_CAND_TO_INT_STAGE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_ASSIGN_CAND_TO_INT_STAGE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_ASSIGN_CAND_TO_INT_STAGE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_STATIC_APPL_FILTERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_STATIC_APPL_FILTERS:
      return {
        ...state,
        staticApplFilterInfo: payload,
        loading: false,
        error: null,
      };
    case ERROR_GET_STATIC_APPL_FILTERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPLOAD_CANDIDATES_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPLOAD_CANDIDATES_DATA:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPLOAD_CANDIDATES_DATA:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export default CandidateList;
