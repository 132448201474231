import {
  REQUEST_GET_ADMIN_USERS_LIST,
  SUCCESS_GET_ADMIN_USERS_LIST,
  ERROR_GET_ADMIN_USERS_LIST,
  REQUEST_CREATE_NEW_USER,
  SUCCESS_CREATE_NEW_USER,
  ERROR_CREATE_NEW_USER,
  REQUEST_DELETE_CREATED_USER,
  SUCCESS_DELETE_CREATED_USER,
  ERROR_DELETE_CREATED_USER,
  REQUEST_USER_REGISTER,
  SUCCESS_USER_REGISTER,
  ERROR_USER_REGISTER,
  REQUEST_CREATE_VI_PANEL_USERS,
  SUCCESS_CREATE_VI_PANEL_USERS,
  ERROR_CREATE_VI_PANEL_USERS,
  REQUEST_UPSERT_VI_PANEL_USERS,
  SUCCESS_UPSERT_VI_PANEL_USERS,
  ERROR_UPSERT_VI_PANEL_USERS,
  REQUEST_DELETE_VI_PANEL_USERS,
  SUCCESS_DELETE_VI_PANEL_USERS,
  ERROR_DELETE_VI_PANEL_USERS,
  REQUEST_GET_VI_PANEL_USERS,
  SUCCESS_GET_VI_PANEL_USERS,
  ERROR_GET_VI_PANEL_USERS,
  REQUEST_UPSERT_USER_INFO_DETAILS_BY_ID,
  SUCCESS_UPSERT_USER_INFO_DETAILS_BY_ID,
  ERROR_UPSERT_USER_INFO_DETAILS_BY_ID,
  REQUEST_RESET_PANEL_MEM_PASSWORD,
  SUCCESS_RESET_PANEL_MEM_PASSWORD,
  ERROR_RESET_PANEL_MEM_PASSWORD,
  REQUEST_GET_USER_MAPPINGS,
  SUCCESS_GET_USER_MAPPINGS,
  ERROR_GET_USER_MAPPINGS,
  REQUEST_UPSERT_USER_MAPPINGS,
  SUCCESS_UPSERT_USER_MAPPINGS,
  ERROR_UPSERT_USER_MAPPINGS,
  REQUEST_GENERATE_YEARLY_APPL_DATA,
  SUCCESS_GENERATE_YEARLY_APPL_DATA,
  ERROR_GENERATE_YEARLY_APPL_DATA,
} from '../actions/actionTypes';

const initalState = {
  loading: false,
  error: null,
  usersList: [],
  usersCount: 0,
  roles: [],
  panelMembers: [],
  panelMembersCount: 0,
};

const AdminSection = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_GET_ADMIN_USERS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_ADMIN_USERS_LIST:
      return {
        ...state,
        usersCount: payload && payload.count,
        usersList: payload && payload.users,
        roles: payload && payload.roles,
        loading: false,
        error: null,
      };
    case ERROR_GET_ADMIN_USERS_LIST:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_NEW_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_NEW_USER:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_NEW_USER:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_CREATED_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_CREATED_USER:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_CREATED_USER:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_USER_REGISTER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_USER_REGISTER:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_USER_REGISTER:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_CREATE_VI_PANEL_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_CREATE_VI_PANEL_USERS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_CREATE_VI_PANEL_USERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_VI_PANEL_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_VI_PANEL_USERS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_VI_PANEL_USERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_DELETE_VI_PANEL_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_DELETE_VI_PANEL_USERS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_DELETE_VI_PANEL_USERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_GET_VI_PANEL_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_GET_VI_PANEL_USERS:
      return {
        ...state,
        panelMembers: payload && payload.panelUsers,
        panelMembersCount: payload && payload.count,
        loading: false,
        error: null,
      };
    case ERROR_GET_VI_PANEL_USERS:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_UPSERT_USER_INFO_DETAILS_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_UPSERT_USER_INFO_DETAILS_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_UPSERT_USER_INFO_DETAILS_BY_ID:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case REQUEST_RESET_PANEL_MEM_PASSWORD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_RESET_PANEL_MEM_PASSWORD:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_RESET_PANEL_MEM_PASSWORD:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case REQUEST_GET_USER_MAPPINGS: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_GET_USER_MAPPINGS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_GET_USER_MAPPINGS: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case REQUEST_UPSERT_USER_MAPPINGS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case SUCCESS_UPSERT_USER_MAPPINGS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_UPSERT_USER_MAPPINGS: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    case REQUEST_GENERATE_YEARLY_APPL_DATA: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SUCCESS_GENERATE_YEARLY_APPL_DATA: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ERROR_GENERATE_YEARLY_APPL_DATA: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default:
      return state;
  }
};

export default AdminSection;
