import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  Tile,
  InlineNotification,
  TextInput,
  RadioButtonGroup,
  RadioButton,
  DataTable,
  Toggle,
} from 'carbon-components-react';
import {
  getScorecardTemplateById,
  createScorecardTemplateConfData,
  createRatingCriteria,
  deleteRatingCriteria,
} from '../../actions/FeedbackTemplates';
import _ from 'lodash';
import { Draggable20, TrashCan32, Add24 } from '@carbon/icons-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import GenericDataTable from '../../components/common/GenericDataTable';
import NoContentBox from '../../components/common/NoContentBox';
import JDProcessOverlay from '../../components/common/JDProcessOverlay';
import BackRow from '../../components/common/BackRow';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
} = DataTable;

class FeedbackTemplateDashboard extends Component {
  paths = [
    { id: 'csc', name: 'ARS', href: '/app/csc' },
    { id: 'feeback-template', name: 'Feedback Template', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      notifMsg: '',
      selectedTab: 0,
      currentGroup: null,
      isRatingOptionsInvalid: false,
      scorecardTemplateId: null,
      scorecardTemplateName: '',
      scorecardTemplateGroups: [],
      templateGroupName: '',
      isTemplateGroupNameInvalid: false,
      currentAction: '',
      prevGroupName: null,
      ratingCriteriaDetails: [],
      traitName: '',
      isTraitNameInvalid: false,
      isCompulsory: false,
      traitType: null,
      traitTypeInvalid: false,
      currentRatingCriteriaId: null,
      showSuccessNotif: false,
      isOpenGroupModal: false,
      isOpenEvaluationTraitsModal: false,
      isOpenDeleteGroupModal: false,
      ratingOptions: [
        {
          optionId: '1',
          optionName: '',
          maxScore: null,
        },
      ],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { scorecardId } = params;
    if (scorecardId) {
      this.handleGetScorecardGroupsApi();
    }
  }

  handleGetScorecardGroupsApi = () => {
    const { match } = this.props;
    const { params } = match;
    const { scorecardId } = params;
    this.props
      .getScorecardTemplateById(scorecardId)
      .then((res) => {
        if (res && !res.error) {
          const { data } = res || {};
          const {
            scorecardTemplateName = null,
            scorecardTemplateId = null,
            configuration = [],
            shared = false,
          } = data?.[0] || {};
          const ratingCriteriaDetails =
            configuration &&
            Array.isArray(configuration) &&
            configuration.length > 0 &&
            configuration.map((res) => {
              return res.evalCriteria ? [...res.evalCriteria] : [];
            });
          this.setState({
            scorecardTemplateName,
            scorecardTemplateId,
            scorecardTemplateGroups: configuration || [],
            ratingCriteriaDetails,
            scoreTempVisibilityTogg: shared === true ? 'public' : 'private',
          });
        }
      })
      .catch((e) => {
        console.log('Error:::::', e);
        this.setState({
          showErr: true,
        });
      });
  };

  handleReorder = (order) => {
    const { scorecardTemplateId, scorecardTemplateGroups, currentGroup } =
      this.state;
    let groups = scorecardTemplateGroups;
    const groupObjIdx = groups.findIndex(
      (obj) =>
        obj.groupName.toLowerCase() === currentGroup.groupName.toLowerCase()
    );
    if (groupObjIdx >= 0) {
      groups[groupObjIdx].evalCriteria = order;
    }
    const data = {
      configuration: groups,
    };

    this.props
      .createScorecardTemplateConfData(data, scorecardTemplateId)
      .then((val) => {
        if (val && !val.error) {
          this.handleGetScorecardGroupsApi();
        }
      })
      .catch((e) => console.log('Error', e));
  };

  onDragEnd = (result) => {
    const { destination, source, reason } = result;
    const { currentTraits: order } = this.state;

    // Not a thing to do...
    if (!destination || reason === 'CANCEL') {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const entities = Object.assign([], order);
    const quote = order[source.index];
    entities.splice(source.index, 1);
    entities.splice(destination.index, 0, quote);
    this.handleReorder(entities);
  };

  handleTabsChange = (tabType) => {
    let selectedTab = 0;
    if (tabType === 'groups') {
      selectedTab = 0;
    } else if (tabType === 'settings') {
      selectedTab = 1;
    }
    this.setState({
      selectedTab,
    });
  };

  activeEvaluationTraitsModal = () => {
    this.setState({
      isOpenEvaluationTraitsModal: true,
    });
  };

  dismissEvaluationTraitsModal = () => {
    this.setState({
      isOpenEvaluationTraitsModal: false,
      currentAction: '',
      traitName: '',
      isCompulsory: false,
      traitType: null,
      currentRatingCriteriaId: null,
      isTraitNameInvalid: false,
      isTraitTypeInvalid: false,
      isRatingOptionsInvalid: false,
      ratingOptions: [
        {
          optionId: '1',
          optionName: '',
          maxScore: null,
        },
      ],
    });
  };

  validateTraits = () => {
    const { traitName, traitType, ratingOptions } = this.state;
    const isRatingOptionsInvalid =
      traitType === 'rating'
        ? ratingOptions.some(
            (rating) =>
              !rating.optionName ||
              (!rating.maxScore && !(rating.maxScore == 0)) ||
              this.validateInput(parseInt(rating.maxScore), 1000, 0)
          )
        : false;

    this.setState(
      {
        isTraitNameInvalid: traitName ? false : true,
        isTraitTypeInvalid: traitType ? false : true,
        isRatingOptionsInvalid,
      },
      () => this.handleEvaluationTraitDetails()
    );
  };

  handleEvaluationTraitDetails = () => {
    const {
      isTraitNameInvalid,
      isTraitTypeInvalid,
      isRatingOptionsInvalid,
      traitName,
      isCompulsory,
      traitType,
      ratingOptions,
      currentGroup,
      currentAction,
      scorecardTemplateGroups,
      scorecardTemplateId,
      currentRatingCriteriaId,
    } = this.state;
    if (
      isTraitNameInvalid === false &&
      isTraitTypeInvalid === false &&
      isRatingOptionsInvalid === false &&
      scorecardTemplateGroups &&
      Array.isArray(scorecardTemplateGroups) &&
      scorecardTemplateGroups.length > 0
    ) {
      var data = {};
      let groups = scorecardTemplateGroups;
      const grpObjIndex = groups.findIndex(
        (obj) =>
          obj.groupName.toLowerCase() === currentGroup.groupName.toLowerCase()
      );

      if (currentAction === 'addTrait') {
        if (grpObjIndex >= 0) {
          let evalCriteria = groups[grpObjIndex].evalCriteria || [];
          const ratingCriteriaExists =
            evalCriteria.length > 0 &&
            evalCriteria.some(
              (obj) =>
                obj.ratingCriteriaName.toLowerCase() === traitName.toLowerCase()
            );
          if (ratingCriteriaExists) {
            this.dismissEvaluationTraitsModal();
            this.setState(
              {
                showErr: true,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    showErr: false,
                  });
                }, 4000);
              }
            );
          } else {
            data = {
              ratingCriteriaName: traitName,
              ratingCriteriaValidation: isCompulsory,
              ratingCriteriaTypeName: traitType,
            };
            if (traitType === 'rating') {
              data.questionConfig = {
                options: ratingOptions,
              };
            }
          }
        }
      } else if (currentAction === 'editTrait') {
        data = {
          ratingCriteriaName: traitName,
          ratingCriteriaValidation: isCompulsory,
        };
        if (traitType === 'rating') {
          data.questionConfig = {
            options: ratingOptions,
          };
        }
      }

      this.props
        .createRatingCriteria(data, currentRatingCriteriaId)
        .then((resp) => {
          const { isSuccess, record } = resp || {};
          if (resp && isSuccess) {
            var payload = {};
            if (currentAction === 'addTrait') {
              groups[grpObjIndex].evalCriteria.push({
                ratingCriteriaId: record.ratingCriteriaId,
              });
              payload = { configuration: groups };
            } else if (currentAction === 'editTrait') {
              groups[grpObjIndex].evalCriteria = groups[
                grpObjIndex
              ].evalCriteria.filter(
                (item) => item.ratingCriteriaId !== currentRatingCriteriaId
              );
              groups[grpObjIndex].evalCriteria.push({
                ratingCriteriaId: currentRatingCriteriaId,
              });
              payload = { configuration: groups };
            }
            this.props
              .createScorecardTemplateConfData(payload, scorecardTemplateId)
              .then((resp) => {
                const { isSuccess } = resp || {};
                if (resp && isSuccess) {
                  this.dismissEvaluationTraitsModal();
                  this.handleGetScorecardGroupsApi();
                  this.setState(
                    {
                      notifMsg:
                        currentAction === 'addTrait'
                          ? 'Question has been added Successfully!'
                          : 'Question has been updated Successfully!',
                      showSuccessNotif: true,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          showSuccessNotif: false,
                        });
                      }, 5000);
                    }
                  );
                } else {
                  this.dismissEvaluationTraitsModal();
                }
              })
              .catch((e) => {
                console.log('Error', e);
                this.dismissEvaluationTraitsModal();
              });
          }
        })
        .catch((e) => {
          console.log('Error', e);
          this.dismissEvaluationTraitsModal();
          this.setState({
            showErr: true,
          });
        });
    }
  };

  handleRemoveRatingCriteria = () => {
    const {
      currentRatingCriteriaId,
      currentAction,
      traitName,
      scorecardTemplateGroups,
      currentGroup,
      scorecardTemplateId,
    } = this.state;
    if (currentAction === 'deleteTrait' && currentRatingCriteriaId) {
      const data = {
        ratingCriteriaName: traitName,
      };
      let groups = scorecardTemplateGroups || [];
      const grpObjIndex = groups.findIndex(
        (obj) =>
          obj.groupName.toLowerCase() === currentGroup.groupName.toLowerCase()
      );

      groups[grpObjIndex].evalCriteria = groups[
        grpObjIndex
      ].evalCriteria.filter(
        (item) => item.ratingCriteriaId !== currentRatingCriteriaId
      );
      const payload = { configuration: groups };
      this.props
        .deleteRatingCriteria(data, currentRatingCriteriaId)
        .then((resp) => {
          const { isSuccess } = resp || {};
          if (resp && isSuccess) {
            this.props
              .createScorecardTemplateConfData(payload, scorecardTemplateId)
              .then((resp) => {
                const { isSuccess } = resp || {};
                if (resp && isSuccess) {
                  this.dissmissDeleteGroupModal();
                  this.handleGetScorecardGroupsApi();
                  this.setState(
                    {
                      notifMsg: 'Question has been deleted Successfully!',
                      showSuccessNotif: true,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          showSuccessNotif: false,
                        });
                      }, 5000);
                    }
                  );
                } else {
                  this.dissmissDeleteGroupModal();
                }
              })
              .catch((e) => {
                console.log('Error', e);
                this.dissmissDeleteGroupModal();
              });
          } else {
            this.dissmissDeleteGroupModal();
          }
        })
        .catch((e) => {
          console.log('Error', e);
          this.dissmissDeleteGroupModal();
          this.setState({
            showErr: true,
          });
        });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRadioButton = (e) => {
    this.setState({
      traitType: e,
    });
  };

  handleRatingOptionChange = (optionId, type, value) => {
    const { ratingOptions } = this.state;
    const optionToEdit = ratingOptions.find(
      (rating) => rating.optionId === optionId
    );
    if (optionToEdit) {
      if (type === 'name') {
        optionToEdit.optionName = value;
      } else if (type === 'maxScore') {
        optionToEdit.maxScore = value ? parseInt(value) : null;
      }
      this.setState({ ratingOptions });
    }
  };

  handleRatingOptionDelete = (optionId) => {
    const { ratingOptions } = this.state;
    const filteredOptions = ratingOptions.filter(
      (rating) => rating.optionId !== optionId
    );
    this.setState({
      ratingOptions: filteredOptions,
    });
  };

  validateInput = (value, max, min) => {
    if (!value && value != 0) {
      return true;
    }
    if (max && value > max) {
      return true;
    }
    if ((min || min == 0) && value < min) {
      return true;
    }
    if (value === '') {
      return true;
    }
    return false;
  };

  addRatingOption = () => {
    const { ratingOptions } = this.state || {};
    const { optionId } = ratingOptions[ratingOptions.length - 1];
    const object = {
      optionId: (parseInt(optionId) + 1).toString(),
      optionName: '',
      maxScore: null,
    };
    this.setState({
      ratingOptions: [...ratingOptions, object],
    });
  };

  activeGroupModal = () => {
    this.setState({ isOpenGroupModal: true });
  };

  dismissGroupModal = () => {
    this.setState({
      isOpenGroupModal: false,
      currentAction: '',
      templateGroupName: '',
      isTemplateGroupNameInvalid: false,
    });
  };

  dissmissDeleteGroupModal = () => {
    this.setState({
      isOpenDeleteGroupModal: false,
      currentAction: '',
      currentGroup: null,
      traitName: '',
      isCompulsory: false,
      traitType: null,
      currentRatingCriteriaId: null,
    });
  };

  handleSubmitGroupModal = () => {
    const {
      currentAction,
      templateGroupName,
      scorecardTemplateGroups: tempGroups,
    } = this.state;
    var data = {};
    if (currentAction === 'addGroup' || currentAction === 'editGroup') {
      const { isTemplateGroupNameInvalid } = this.state;
      if (
        isTemplateGroupNameInvalid === false &&
        tempGroups &&
        Array.isArray(tempGroups)
      ) {
        const groupExists = tempGroups.some(
          (group) =>
            group.groupName.toLowerCase() === templateGroupName.toLowerCase()
        );
        if (groupExists) {
          this.dismissGroupModal();
          this.setState(
            {
              showErr: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  showErr: false,
                });
              }, 4000);
            }
          );
        } else {
          let groups = tempGroups;
          if (currentAction === 'addGroup') {
            groups.push({
              groupName: templateGroupName,
            });
          } else if (currentAction === 'editGroup') {
            const { prevGroupName } = this.state;
            const objIndex = groups.findIndex(
              (obj) =>
                obj.groupName.toLowerCase() === prevGroupName.toLowerCase()
            );
            if (objIndex >= 0) groups[objIndex].groupName = templateGroupName;
          }
          data = {
            configuration: groups,
          };
        }
      }
    } else if (currentAction === 'deleteGroup') {
      if (tempGroups && Array.isArray(tempGroups) && tempGroups.length > 0) {
        let groups = tempGroups;
        const filteredGroups = groups.filter(
          (item) =>
            item.groupName.toLowerCase() !== templateGroupName.toLowerCase()
        );
        data = {
          configuration: filteredGroups,
        };
      }
    }
    if (_.isEmpty(data) !== true) {
      this.props
        .createScorecardTemplateConfData(data, this.state.scorecardTemplateId)
        .then((res) => {
          const { isSuccess } = res || {};
          if (res && isSuccess) {
            this.dismissGroupModal();
            if (currentAction === 'deleteGroup')
              this.dissmissDeleteGroupModal();
            this.handleGetScorecardGroupsApi();
            this.setState(
              {
                notifMsg:
                  currentAction === 'addGroup'
                    ? 'Template Group is added Successfully!'
                    : currentAction === 'editGroup'
                    ? 'Template Group is updated Successfully!'
                    : 'Template Group is deleted Successfully!',
                showSuccessNotif: true,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    showSuccessNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.dismissGroupModal();
            if (currentAction === 'deleteGroup')
              this.dissmissDeleteGroupModal();
          }
        })
        .catch((e) => {
          console.log('Error:::::', e);
          this.dismissGroupModal();
          this.setState({
            showErr: true,
          });
        });
    }
  };

  validateGroupModalData = () => {
    const { templateGroupName } = this.state;
    this.setState(
      {
        isTemplateGroupNameInvalid: templateGroupName ? false : true,
      },
      () => this.handleSubmitGroupModal()
    );
  };

  handleToggleClick = (e, name) => {
    this.setState({ [`${name}`]: e });
  };

  render() {
    const { history: { push } = {} } = this.props;
    const {
      showErr,
      showSuccessNotif,
      scorecardTemplateName,
      scorecardTemplateGroups,
      notifMsg,
      selectedTab,
      isOpenEvaluationTraitsModal,
      isOpenGroupModal,
      templateGroupName,
      isTemplateGroupNameInvalid,
      currentAction,
      isOpenDeleteGroupModal,
      ratingCriteriaDetails,
      traitName,
      isTraitNameInvalid,
      isCompulsory,
      traitType,
      isTraitTypeInvalid,
      ratingOptions,
      isRatingOptionsInvalid,
      isNumberInvalid,
    } = this.state;

    const traitsColumnWithFilter = [
      {
        title: 'Questions',
        className: 'text-left',
        name: 'ratingCriteria',
        propName: 'ratingCriteria',
      },
      {
        title: 'Actions',
        className: 'text-left',
        name: 'actions',
        propName: 'actions',
      },
    ];

    const groupsColumnWithFilter = [
      {
        title: 'Sections',
        className: 'text-left',
        name: 'templateGrp',
        propName: 'templateGrp',
      },
      {
        title: 'Actions',
        className: 'text-left',
        name: 'actions',
        propName: 'actions',
      },
    ];

    const groupsOtherActionsBtn = [
      <Button
        kind="primary"
        onClick={() =>
          this.setState({ currentAction: 'addGroup' }, () =>
            this.activeGroupModal()
          )
        }>
        Add Section
      </Button>,
    ];

    return (
      <div>
        <BackRow paths={this.paths} />
        <InlineNotification
          lowContrast
          onCloseButtonClick={() => {}}
          className="mt-1 mb-2"
          title="In this section, you can manage individual feedback groups."
          subtitle=""
          hideCloseButton
          iconDescription="Describes the close button"
          kind="info"
        />
        {showErr === true && (
          <InlineNotification
            lowContrast
            onCloseButtonClick={() => {}}
            className="mt-1 mb-1"
            title="An error has occurred, Please try again later"
            subtitle=""
            iconDescription="Describes the close button"
            kind="error"
          />
        )}
        {showSuccessNotif === true && (
          <InlineNotification
            lowContrast
            onCloseButtonClick={() => {
              this.setState({ showSuccessNotif: false });
            }}
            className="mt-1 mb-1"
            title={notifMsg}
            subtitle=""
            hideCloseButton={false}
            iconDescription="Describes the close button"
            kind="success"
          />
        )}
        <Tile className="mb-2">
          <div className="">
            <div className="mt-1 text-capitalize bx--row ml-2">
              <h5>{scorecardTemplateName} </h5>
            </div>
          </div>
        </Tile>

        <Tile>
          <div className="">
            <GenericDataTable
              className=""
              otherActions={groupsOtherActionsBtn}
              expandedRows={scorecardTemplateGroups.map((res, idx) => {
                const tempGroup = res;
                const groupTraits =
                  ratingCriteriaDetails &&
                  ratingCriteriaDetails.length > 0 &&
                  ratingCriteriaDetails[idx];
                return (
                  <Tile>
                    <DragDropContext
                      onDragEnd={(result) =>
                        this.setState(
                          {
                            currentGroup: tempGroup,
                            currentTraits: groupTraits,
                          },
                          () => this.onDragEnd(result)
                        )
                      }>
                      <DataTable
                        headers={traitsColumnWithFilter.map((data, index) => ({
                          key: `header_${index}`,
                          header: data.title,
                          className: data.className,
                          colpropName: data.propName,
                        }))}
                        rows={
                          groupTraits &&
                          Array.isArray(groupTraits) &&
                          groupTraits.length > 0
                            ? groupTraits.map((data, idx) => {
                                const {
                                  ratingCriteriaName,
                                  ratingCriteriaValidation,
                                  ratingCriteriaType,
                                  ratingCriteriaId,
                                  questionConfig = {},
                                } = data || {};
                                return {
                                  isExpanded: false,
                                  id: ratingCriteriaName,
                                  header_0: (
                                    <div className="bx--row">
                                      <div className="ml-lg-4 bx--col-lg-5">
                                        <Draggable20
                                          style={{
                                            marginRight: 10,
                                            marginLeft: -16,
                                            fill: 'grey',
                                          }}
                                        />
                                        {ratingCriteriaName &&
                                        ratingCriteriaName.toString().length >
                                          35
                                          ? `${ratingCriteriaName.substring(
                                              0,
                                              35
                                            )}...`
                                          : ratingCriteriaName}
                                      </div>
                                      <div className="ml-4 bx--col-lg-2">
                                        Type:
                                        <span className={'ml-2'}>
                                          {ratingCriteriaType}
                                        </span>
                                      </div>
                                    </div>
                                  ),
                                  header_1: (
                                    <div className="d-flex flex-row align-start">
                                      <Button
                                        kind="primary"
                                        size="small"
                                        onClick={() =>
                                          this.setState(
                                            {
                                              currentAction: 'editTrait',
                                              currentGroup: tempGroup,
                                              traitName: ratingCriteriaName,
                                              isCompulsory:
                                                ratingCriteriaValidation,
                                              traitType: ratingCriteriaType,
                                              currentRatingCriteriaId:
                                                ratingCriteriaId
                                                  ? ratingCriteriaId
                                                  : null,
                                              ratingOptions:
                                                ratingCriteriaType === 'rating'
                                                  ? questionConfig.options
                                                  : null,
                                            },
                                            () =>
                                              this.setState({
                                                isOpenEvaluationTraitsModal: true,
                                              })
                                          )
                                        }>
                                        Edit
                                      </Button>
                                      <Button
                                        kind="danger"
                                        className="ml-3"
                                        size="small"
                                        onClick={() =>
                                          this.setState(
                                            {
                                              currentAction: 'deleteTrait',
                                              currentGroup: tempGroup,
                                              traitName: ratingCriteriaName,
                                              isCompulsory:
                                                ratingCriteriaValidation,
                                              traitType: ratingCriteriaType,
                                              currentRatingCriteriaId:
                                                ratingCriteriaId
                                                  ? ratingCriteriaId
                                                  : null,
                                            },
                                            () =>
                                              this.setState({
                                                isOpenDeleteGroupModal: true,
                                              })
                                          )
                                        }>
                                        Delete
                                      </Button>
                                    </div>
                                  ),
                                };
                              })
                            : []
                        }
                        render={({
                          rows,
                          headers,
                          getHeaderProps,
                          getRowProps,
                          getTableProps,
                        }) => {
                          return (
                            <TableContainer>
                              <TableToolbar>
                                <TableToolbarContent>
                                  <Button
                                    kind="primary"
                                    onClick={() =>
                                      this.setState(
                                        {
                                          currentAction: 'addTrait',
                                          currentGroup: tempGroup,
                                        },
                                        () => {
                                          this.activeEvaluationTraitsModal();
                                        }
                                      )
                                    }>
                                    Add Question
                                  </Button>
                                </TableToolbarContent>
                              </TableToolbar>
                              <Table {...getTableProps()}>
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header) => (
                                      <TableHeader
                                        {...getHeaderProps({ header })}>
                                        {header.header}
                                      </TableHeader>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <Droppable droppableId="traitsTable">
                                  {(provided, snapshot) => (
                                    <tbody
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}>
                                      {rows.map((row, index) => (
                                        <Draggable
                                          draggableId={`${row.id}`}
                                          key={`${row.id}`}
                                          index={index}>
                                          {(provided, snapshot) => (
                                            <tr
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}>
                                              {row.cells.map(
                                                (cell, cellIndex) => (
                                                  <TableCell
                                                    colSpan={
                                                      headers[cellIndex] &&
                                                      headers[cellIndex].colSpan
                                                        ? headers[cellIndex]
                                                            .colSpan
                                                        : 1
                                                    }
                                                    key={cell.id}>
                                                    {cell.value}
                                                  </TableCell>
                                                )
                                              )}
                                            </tr>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </tbody>
                                  )}
                                </Droppable>
                              </Table>
                            </TableContainer>
                          );
                        }}
                      />
                    </DragDropContext>
                    {groupTraits && groupTraits.length <= 0 && (
                      <NoContentBox message="There are no questions currently associated to this group." />
                    )}
                    {groupTraits &&
                      Array.isArray(groupTraits) &&
                      groupTraits.length > 0 && (
                        <div className="mt-2">
                          <small>*You can drag rows to reorder items</small>
                        </div>
                      )}
                  </Tile>
                );
              })}
              headers={groupsColumnWithFilter.map((data, index) => ({
                key: `header_${index}`,
                header: data.title,
                className: data.className,
                colpropName: data.propName,
              }))}
              rows={
                scorecardTemplateGroups &&
                Array.isArray(scorecardTemplateGroups) &&
                scorecardTemplateGroups.length > 0
                  ? scorecardTemplateGroups.map((data, idx) => {
                      const { groupName } = data || {};
                      return {
                        isExpanded: false,
                        id: groupName,
                        header_0: (
                          <div className="bx--row">
                            <div className="bx--col-lg-12 font-weight-bold xpa-name-overflow">
                              <h4>
                                {groupName && groupName.toString().length > 20
                                  ? `${groupName.substring(0, 20)}...`
                                  : groupName}
                              </h4>
                            </div>
                          </div>
                        ),
                        header_1: (
                          <div className="d-flex flex-row align-start">
                            <Button
                              kind="primary"
                              size="small"
                              onClick={() =>
                                this.setState(
                                  {
                                    currentAction: 'editGroup',
                                    templateGroupName: groupName,
                                    prevGroupName: groupName,
                                  },
                                  () => this.activeGroupModal()
                                )
                              }>
                              Edit
                            </Button>
                            <Button
                              kind="danger"
                              className="ml-3"
                              size="small"
                              onClick={() =>
                                this.setState(
                                  {
                                    currentAction: 'deleteGroup',
                                    templateGroupName: groupName,
                                  },
                                  () =>
                                    this.setState({
                                      isOpenDeleteGroupModal: true,
                                    })
                                )
                              }>
                              Delete
                            </Button>
                          </div>
                        ),
                      };
                    })
                  : []
              }
              searchable={false}
              selectable={false}
              onSearchInputChange={() => {}}
              expandable
              batchActions={false}
            />
            {(!scorecardTemplateGroups ||
              (scorecardTemplateGroups &&
                Array.isArray(scorecardTemplateGroups) &&
                scorecardTemplateGroups.length <= 0)) && (
              <NoContentBox
                message="There are no sections to show in this scorecard"
                showButtons={false}
                buttonName="Add Group"
              />
            )}
          </div>
        </Tile>

        {isOpenEvaluationTraitsModal && (
          <Modal
            open={isOpenEvaluationTraitsModal}
            modalHeading={`${
              currentAction === 'addTrait' ? 'Add' : 'Edit'
            } Question`}
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestSubmit={() => this.validateTraits()}
            onRequestClose={() => this.dismissEvaluationTraitsModal()}>
            <div style={{ minHeight: 200 }}>
              <div className="mb-2">
                <div className="">
                  <TextInput
                    name="traitName"
                    id="traitName"
                    labelText="Question Name:"
                    placeholder="Question Name"
                    onChange={this.handleChange}
                    value={traitName}
                    invalid={isTraitNameInvalid}
                    invalidText="Please enter valid Trait Name"
                  />
                </div>
              </div>
              <div className="my-2">
                <div className="">
                  <Toggle
                    id="isCompulsory"
                    name="isCompulsory"
                    className=""
                    labelText="Is user input compulsory?"
                    labelA="Optional"
                    labelB="Compulsory"
                    toggled={isCompulsory}
                    onToggle={(e) => this.handleToggleClick(e, 'isCompulsory')}
                  />
                </div>
              </div>
              <div className="my-2">
                <div className="bx--label mb-3 mt-2">
                  How will the candidate score?
                </div>
                <RadioButtonGroup
                  onChange={this.handleChangeRadioButton}
                  valueSelected={traitType}
                  defaultSelected="select"
                  className="text-left bx--row"
                  name="traitType">
                  <RadioButton
                    value="text"
                    id="radio-1"
                    className="d-flex text-left mb-2 bx--col-xs-12 bx--col-sm-12 bx--col-md-2"
                    disabled={currentAction === 'editTrait' ? true : false}
                    labelText="Text"
                    key="text"
                  />
                  <RadioButton
                    value="number"
                    id="radio-2"
                    className="d-flex text-left mb-2 bx--col-xs-12 bx--col-sm-12 bx--col-md-2"
                    disabled={currentAction === 'editTrait' ? true : false}
                    labelText="Number"
                    key="number"
                  />
                  <RadioButton
                    value="rating"
                    id="radio-3"
                    className="d-flex text-left ml-3 mb-2 bx--col-xs-12 bx--col-sm-12 bx--col-md-2"
                    disabled={currentAction === 'editTrait' ? true : false}
                    labelText="Rating"
                    key="rating"
                  />
                </RadioButtonGroup>
                {isTraitTypeInvalid && (
                  <div className="text-danger mb-2">
                    Please select valid type
                  </div>
                )}
                <div className="mt-3">
                  {traitType === 'rating' && (
                    <div>
                      <h5 className="mt-3">Rating Options</h5>
                      <div className="bx--row mt-2">
                        <div className="bx--col-sm-6 bx--col-lg-6 bx--col-md-6">
                          <TextInput
                            name="rating-option-0"
                            id="rating-option-0"
                            labelText="Option text"
                            placeholder="Excellent!"
                            onChange={(e) =>
                              this.handleRatingOptionChange(
                                '1',
                                'name',
                                e.target.value
                              )
                            }
                            value={ratingOptions[0].optionName}
                          />
                        </div>
                        <div className="bx--col-sm-4 bx--col-lg-4 bx--col-md-4">
                          <TextInput
                            name="maxScore-0"
                            id="maxScore-0"
                            labelText="Score for this option (1-1000)"
                            type="number"
                            max={1000}
                            min={1}
                            placeholder="20"
                            onChange={(e) =>
                              this.handleRatingOptionChange(
                                '1',
                                'maxScore',
                                e.target.value
                              )
                            }
                            value={ratingOptions[0].maxScore}
                          />
                        </div>
                      </div>
                      {ratingOptions &&
                        Array.isArray(ratingOptions) &&
                        ratingOptions.length > 1 &&
                        ratingOptions.slice(1).map((option, idx) => {
                          const { optionName, optionId, maxScore } = option;
                          return (
                            <div className="bx--row mt-2">
                              <div className="bx--col-sm-6 bx--col-lg-6 bx--col-md-6">
                                <TextInput
                                  name={`rating-option-${idx + 1}`}
                                  id={`rating-option-${idx + 1}`}
                                  placeholder="Excellent!"
                                  onChange={(e) =>
                                    this.handleRatingOptionChange(
                                      optionId,
                                      'name',
                                      e.target.value
                                    )
                                  }
                                  value={optionName}
                                />
                              </div>
                              <div className="bx--col-sm-4 bx--col-lg-4 bx--col-md-4">
                                <TextInput
                                  name="maxScore"
                                  id="maxScore"
                                  type="number"
                                  max={1000}
                                  min={1}
                                  placeholder="20"
                                  onChange={(e) =>
                                    this.handleRatingOptionChange(
                                      optionId,
                                      'maxScore',
                                      e.target.value
                                    )
                                  }
                                  value={maxScore}
                                />
                              </div>
                              <div className="bx--col-sm-2 bx--col-lg-2 bx--col-md-2">
                                <TrashCan32
                                  fill="red"
                                  onClick={() =>
                                    this.handleRatingOptionDelete(optionId)
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      {isRatingOptionsInvalid && (
                        <div className="bx--form-requirement mb-2">
                          Please fill out all the fields with valid data
                        </div>
                      )}
                      <Button
                        kind="ghost"
                        onClick={this.addRatingOption}
                        className="d-flex align-items-center mt-2">
                        <Add24 fill="#0f62fe" />
                        Add message
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}
        {isOpenGroupModal && (
          <Modal
            open={isOpenGroupModal}
            modalHeading={`${
              currentAction === 'editGroup' ? 'Edit' : 'Add'
            } Section`}
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dismissGroupModal}
            onRequestSubmit={() => this.validateGroupModalData()}>
            <div className="">
              <TextInput
                name="templateGroupName"
                id="templateGroupName"
                labelText="Section Name:"
                placeholder="Template Group Name"
                onChange={this.handleChange}
                value={templateGroupName}
                invalid={isTemplateGroupNameInvalid}
                invalidText="Please enter valid Group Name"
              />
            </div>
          </Modal>
        )}
        {isOpenDeleteGroupModal === true && (
          <Modal
            open={isOpenDeleteGroupModal}
            modalHeading={
              currentAction === 'deleteTrait'
                ? 'Delete Question'
                : 'Delete Template Group'
            }
            modalLabel="Delete"
            danger
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            iconDescription="Close the modal"
            onRequestClose={this.dissmissDeleteGroupModal}
            onRequestSubmit={() =>
              currentAction === 'deleteTrait'
                ? this.handleRemoveRatingCriteria()
                : this.handleSubmitGroupModal()
            }>
            {currentAction === 'deleteTrait'
              ? 'Are you sure, you want to delete this Question'
              : 'Are you sure, you want to delete this Template Group'}
          </Modal>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.x0paDuser.isUserLoggedIn,
  user: state.x0paDuser.user,
  scorecardtemplates: state.FeedbackTemplates.scorecardtemplates,
  loading: state.FeedbackTemplates.loading,
  count: state.FeedbackTemplates.count,
});

const mapDispatchToProps = {
  getScorecardTemplateById,
  createScorecardTemplateConfData,
  createRatingCriteria,
  deleteRatingCriteria,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackTemplateDashboard);
